import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/rei/styles';
import * as modulesServices from '../../../calculations/modules-services';
import { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';

const marks = [];
for ( let i = 0; i < 6; i += 1 ) {
  marks.push( {
    value: i,
    label: i === 0 ? '0% of Purchase Price' : `${i}%`,
  } );
}

const RentalIncomeVacancyRate = ( props ) => {
  const {
    classes, inputData, updateData, handleDefinationPopup, percentageFirstVal, percentageSecondVal
  } = props;

  const purchasePrice = inputData.purchase_price !== undefined ? inputData.purchase_price : 0;
  const [propertyTaxes, setPropertyTaxes] = React.useState( 0 );
  const [propertyTaxesValue, setPropertyTaxesValue] = React.useState( 0 );

  useEffect( () => {
    let propertyVal = 0;
    if ( inputData.property_taxes_in_percentage !== undefined ) {
      propertyVal = inputData.property_taxes_in_percentage;
    } else {
      propertyVal = 1;
      updateData( 'property_taxes_in_percentage', propertyVal );
    }
    setPropertyTaxes( propertyVal );
    setPropertyTaxesValue( (( purchasePrice / 100 ) * propertyVal)/12 );

    // eslint-disable-next-line
  }, [] );

  const updateValue = ( e, value, field, mainField, type ) => {
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    switch ( field ) {
      case 'property': {
        setPropertyTaxes( newvalue );
        setPropertyTaxesValue( (( purchasePrice / 100 ) * newvalue)/12 );
        updateData( mainField, newvalue );
        break;
      }
      case 'property_input': {
        setPropertyTaxesValue( newvalue );
        let propertyVal = 0.0;
        if ( newvalue >= 0 && purchasePrice >= 0 ) {
          propertyVal = ( (newvalue*12) / purchasePrice ) * 100;
        }
        setPropertyTaxes( propertyVal );
        updateData( mainField, propertyVal );
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className={ classes.contentBlock }>
      <p>Property taxes can vary widely depending on where you live. Property taxes fund local infrastructure, education, garbage collection, sewer maintenance, and more.</p>
      <p>
        Property taxes are broken down into two categories: city and school taxes. For example,
        {' '}
        <a className={ classes.anchorStyle } href="https://www.phila.gov/services/payments-assistance-taxes/property-taxes/real-estate-tax/" target="_blank" rel="budget-fy2020 noopener noreferrer">click here</a>
        {' '}
        to see how the city of Philadelphia taxes real estate.
      </p>
      <p>
        Check out
        {' '}
        <a className={ classes.anchorStyle } href="https://wallethub.com/edu/states-with-the-highest-and-lowest-property-taxes/11585/" target="_blank" rel="budget-fy2020 noopener noreferrer">property taxes by state</a>
        {' '}
        and use
        {' '}
        <a className={ classes.anchorStyle } href="https://www.zillow.com/" target="_blank" rel="budget-fy2020 noopener noreferrer">Zillow</a>
        {' '}
        to research the exact property you want to buy, and the property taxes paid in the most recent year.
      </p>
      <div className={ classes.homeMainRowScroll }>
        <div className={ classes.homeMainRow }>
          <div className={ classes.pmileftSection }>
            <div className={ classNames( classes.sliderGraph, classes.pmiGraph ) }>
              <div className={ classes.graphLeft }>
                <div className={ classes.sliderBar }>
                  <span>&nbsp;</span>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.titleNew }><span>Monthly</span></div>
                <div className={ classes.titleNew }><span>Annual</span></div>
              </div>
            </div>
            <div className={ classes.flexDisplayNew }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider, classes.pmiRange ) }>
                <div>
                  <p>
                    <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Property Taxes: Home' ) } className={ classes.dottedText }>Property Taxes</span>
                  </p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      value={ propertyTaxes }
                      valueLabelFormat={ percentFormatWithTwoDecimal }
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      min={ 0 }
                      max={ 5 }
                      step={ 0.05 }
                      onChange={ ( e, value ) => updateValue( e, value, 'property', 'property_taxes_in_percentage', 'slider' ) }
                      marks={ marks }
                    />
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.firstDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <span className={ classes.dollarSymbol }>$</span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ propertyTaxesValue } onValueChange={ ( e ) => updateValue( e, '', 'property_input', 'property_taxes_in_percentage', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.lastDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <NumberFormat value={ propertyTaxesValue * 12 } decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin, classes.sliderHeightNew ) }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider ) }>
                <div>
                  <div className={ classes.pwisliderInputs }>
                    <p><b>Other Property Expenses</b></p>
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classNames( classes.firstDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -modulesServices.module14Data.reiTotalMonthlyExpenses - propertyTaxesValue } prefix="$" /> ) : ( <span>$0</span> )}
                  </div>
                </div>
                <div className={ classNames( classes.lastDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ (-modulesServices.module14Data.reiTotalMonthlyExpenses - propertyTaxesValue)*12 } prefix="$" /> ) : ( <span>$0</span> )}
                  </div>
                </div>
              </div>
            </div>
            <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin, classes.sliderHeightNew ) }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider ) }>
                <div>
                  <div className={ classes.pwisliderInputs }>
                    <p><b>Total Property Expenses</b></p>
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classNames( classes.firstDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -modulesServices.module14Data.reiTotalMonthlyExpenses } prefix="$" /> ) : ( <span>$0</span> )}
                  </div>
                </div>
                <div className={ classNames( classes.lastDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -modulesServices.module14Data.reiTotalMonthlyExpenses*12 } prefix="$" /> ) : ( <span>$0</span> )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.sliderChart }>
            <ul className={ classNames( classes.utilitiesChart, classes.utilitiesPMI ) }>
              <li className={ classes.chartBar }>
                <div className={ classes.chartText }>
                    Monthly Property Expenses
                    <span className={ classes.chartSpan }>
                      {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -modulesServices.module14Data.reiTotalMonthlyExpenses } prefix="$" /> ) : ( <span>$0</span> )}
                    </span>
                  </div>
                <span className={ classes.percentageBar } style={{height: `calc(${percentageFirstVal}% - 72px)`}} />
                <div className={ classes.graphPercentage }>
                  {percentageFirstVal !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageFirstVal } prefix={ percentageFirstVal >= 0 ? '' : '(' } suffix={ percentageFirstVal < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </div>
              </li>
              <li className={ classes.chartBar }>
                <div className={ classes.chartText }>
                  Monthly Adjusted Rental Income
                  <span className={ classNames( classes.chartSpan, classes.secondPercentageTextColor ) }>
                    {modulesServices.module14Data.adjustedRentalIncomeForGraph !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module14Data.adjustedRentalIncomeForGraph/12 } prefix="$" /> ) : ( <span>$0</span> )}
                  </span>
                </div>
                <span className={ classNames( classes.percentageBar, classes.secondPercentageBarColor ) } style={{height: `calc(${percentageSecondVal}% - 72px)`}} />
                <div className={ classNames( classes.graphPercentage, classes.secondPercentageTextColor ) }>
                  {percentageSecondVal !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageSecondVal } prefix={ percentageSecondVal >= 0 ? '' : '(' } suffix={ percentageSecondVal < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

RentalIncomeVacancyRate.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  percentageFirstVal: PropTypes.string.isRequired,
  percentageSecondVal: PropTypes.string.isRequired,
};

export default withStyles( styles )( RentalIncomeVacancyRate );
