import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import YouTube from 'react-youtube';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import seperatordots from '../../../../assets/img/pf101/dots.png';

const startTime = new Date();
const HealthInsurance = ( props ) => {
  const youtubeOpts = {
    host: 'https://www.youtube-nocookie.com',
    width: '640',
    height: '390',
    playerVars: {
      autoplay: 0,
      rel: '0',
      disablekb: '0',
      showinfo: '0',
      ccLoadPolicy: '0',
      ivLoadPolicy: '0',
      modestbranding: '1',
    },
  };

  let bodySection = useRef( null );

  const { classes, location } = props;
  const params = queryString.parse( location.search );

  const [section, setSection] = React.useState( ( params.type !== undefined && params.type === 'audio' ) ? 0 : 1 );
  const [buttonStatus, setButtonStatus] = React.useState( !( ( params.revisit !== undefined && params.revisit === 'yes' ) ) );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      <div className={ classes.containerFluid }>
        {section === 0 ? (
          <div className={ classes.videoPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>What is Health Insurance?</h3>
                    </div>
                    <div className={ classNames( classes.pfVideo, classes.iframeHeight ) }>
                      <YouTube videoId="-58VD3z7ZiQ" opts={ youtubeOpts } onPlay={ () => { setButtonStatus( false ); } } />
                    </div>
                    <div className={ classes.kickStart }>
                      <p>
                        <span>Kickstart this activity with a short video! Once you&nbsp;</span>
                        <span>watch the video in full, click continue to keep going&nbsp;</span>
                        <span> on this activity.</span>
                      </p>
                    </div>
                    <div className={ classes.seperatorDots }>
                      <img src={ seperatordots } alt="logo" />
                    </div>
                    <Button className={ classes.continueButton } disabled={ buttonStatus } onClick={ () => { setSection( 1 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } }>Continue</Button>
                    <p className={ classes.accessLink }>
                      Can&apos;t access this video?&nbsp;
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 2 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } } className={ classes.continueLink }>Click here to continue.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={ classes.contentPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                    <li>
                      <span>&nbsp;&lt;&nbsp;</span>
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 0 ); } } className={ classes.breadCrumbVideoLink }>Video</span>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfImage }>
                      <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/health-insurance.png" alt="template" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>What is Health Insurance?</h3>
                    </div>
                    <div className={ classes.pfContent }>
                      <div className={ classes.pfRow }>
                        <div className={ classes.pfSlideImage }>
                          <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/health-insurance-left.jpg" alt="template" />
                        </div>
                        <div className={ classes.pfText }>
                          <p className={ classes.coverAnything }>
                            Health insurance can be expensive, but you absolutely need it. Health insurance helps you pay for unexpected medical expenses like surgery on a broken bone. Without health insurance, a broken leg surgery could cost you $20,000. No one plans to break a bone but having health insurance will protect you financially from the high costs of health care.
                          </p>
                          <p className={ classes.noMarginBottom }>
                            According to the
                            {' '}
                            <a href="https://www.kff.org/report-section/the-burden-of-medical-debt-section-3-consequences-of-medical-bill-problems/" target="_blank" className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer">Kaiser Family Foundation</a>
                            , the #1 cause of bankruptcy for Americans is medical bills. That makes sense considering a normal deductible costs thousands of dollars. But what is a deductible? What are health insurance premiums, coinsurance and copayments? Let&apos;s dive into the language of health insurance.
                          </p>
                        </div>
                      </div>
                      <div className={ classes.pfRow }>
                        <div className={ classes.creditText }>
                          <p>
                            <b className={ classes.didYouKnow }>DID YOU KNOW:</b>
                            {' '}
                            According to
                            {' '}
                            <a href="https://www.ehealthinsurance.com/resources/affordable-care-act/much-obamacare-cost-2018" target="_blank" className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer">eHealth</a>
                            , the average monthly premium for an individual was $440 per month or $5,280 per year in 2018. For a family of four, $1,168 per month or $14,016 per year in monthly premiums.
                          </p>
                          <p>
                            <b>Deductible</b>
                            <br />
                            If you have medical bills, the deductible is the amount of money you would pay in a year before your health insurance provider steps in and covers your expenses. This is the most important aspect of health insurance to understand. Your insurance provider does not cover all of your medical expenses. A deductible is easiest to understand in an example.
                          </p>
                          <p>
                            Mike unfortunately breaks his arm playing in an intramural soccer game on his 27th birthday and needs surgery. Mike&apos;s health insurance coverage has a $1,500 deductible.
                            The surgery is going to cost $5,000. What is Mike&apos;s responsibility and what is his health insurance provider&apos;s responsibility? Mike will pay $1,500, which satisfies his deductible. His health insurance provider will pay the remaining $3,500 now that Mike paid his deductible for the year (assuming a coinsurance rate of 100%/0%).
                          </p>
                          <p>
                            When shopping for health insurance, pick a deductible that fits your financial picture, so you don&apos;t find yourself in financial ruin. The higher your deductible, the lower your monthly premium.
                            {' '}
                            <a href="https://www.ehealthinsurance.com/resources/affordable-care-act/much-obamacare-cost-2018" target="_blank" className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer">Why might that be?</a>
                          </p>
                          <p>
                            <b>Monthly health insurance premium</b>
                            <br />
                            The premium is the amount of money you pay your health insurance provider each month in exchange for health insurance. Think of health insurance premiums like rent. You pay your rent each month in exchange for a place to live. If you stop paying your rent, your landlord will ask you to leave. If you stop paying your monthly premiums, you will lose your health insurance coverage.
                          </p>
                          <p>
                            <b>Coinsurance</b>
                            <br />
                            After you pay your deductible for the year, the coinsurance percentage determines how much of your additional medical bills you share with your insurance provider. Sounds complex, but again, easiest to understand in our example of Mike.
                          </p>
                          <p>
                            One month after Mike breaks his arm and gets surgery, he cuts his hand preparing dinner and has to go to the hospital for stitches. His hospital bill is $1,000. Mike&apos;s coinsurance is 80%.
                            {' '}
                            <b>Since he has already used his deductible,</b>
                            {' '}
                            Mike would be responsible for 20% of this hospital bill, or $200, and his insurance provider would pay the remaining $800, or 80%.
                          </p>
                          <p>When selecting your health insurance policy, pay attention to the coinsurance percentage. A common split would be 80/20, just like Mike&apos;s example.</p>
                          <p>
                            <b>Copayments (copay)</b>
                            <br />
                            Copayments, or copay, is a fixed amount that you pay for a regular checkup, other common doctor visits, filling a prescription or seeing a specialist. Copayments are made at the time you receive the service. For example, when you go for a regular checkup with your doctor, your copay could be $15 - $20 that needs to be paid as you check in. Copays do not count towards your deductible!
                          </p>
                          <p>
                            <b>Out-of-Pocket Maximum</b>
                            <br />
                            After you pay your deductible, the coinsurance percentage kicks in on your healthcare costs. But there&apos;s also an out-of-pocket maximum with every health insurance plan required by law. Once you spend up to your out-of-pocket maximum, your insurance provider pays 100% of covered costs for the rest of the year.
                          </p>
                          <p>Health care can be very expensive. Having the right health insurance that fits your medical needs and your budget is crucial to financial independence. When it comes to selecting a health insurance policy, make sure you know your deductible and out-of-pocket maximum, monthly premium, coinsurance and common copays.</p>
                        </div>
                      </div>
                      <BottomContent />
                      <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level8/health-insurance/exercise" startTime={ startTime } level={ 8 } exercise={ 1 } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Typography>
  );
};
HealthInsurance.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( HealthInsurance );
