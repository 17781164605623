import { formatDollar } from './chartCommonFunctions';
import * as modulesServices from '../calculations/modules-services';
function formatPercentage(number) {
  const p = number.toFixed(2).split('.');
  return `${p[0].split('').reverse().reduce((acc, num, i) => (num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc), '')}%`;
}

export function networthGraph() {
  let dashboardNetWorthData = modulesServices.dashBoardCalcsCompleteData();
  //Initilize Dashboard Calculations
  let dashBoardOutputSheetCompleteData = [];
  let newWorthGrowth = 0;
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data']['Net Worth Graph'] !== 'undefined' && dashboardNetWorthData['Data']['Net Worth Graph'] !== '') {
    dashBoardOutputSheetCompleteData = dashboardNetWorthData['Data']['Net Worth Graph'];
    newWorthGrowth = parseFloat(dashboardNetWorthData['Data']['Net Worth Growth for Net Worth Chart']).toFixed(0);

  } else {
    dashBoardOutputSheetCompleteData = [];
    dashBoardOutputSheetCompleteData['Gross Income'] = [];
    dashBoardOutputSheetCompleteData['Taxes'] = [];
    dashBoardOutputSheetCompleteData['Total Expenses'] = [];
    dashBoardOutputSheetCompleteData['Net Worth'] = [];
    dashBoardOutputSheetCompleteData['Years List'] = [];
  }
  //Net Worth Graph
  let grossIncomeGraphData = dashBoardOutputSheetCompleteData['Gross Income'];
  let incomeAfterTaxesGraphData = dashBoardOutputSheetCompleteData['Taxes'];
  let totalExpensesGraphData = dashBoardOutputSheetCompleteData['Total Expenses'];
  let netWorthGraphData = dashBoardOutputSheetCompleteData['Net Worth'];
  let yearsList = dashBoardOutputSheetCompleteData['Years List'];

  return {
    chartValue: dashBoardOutputSheetCompleteData['Net Worth'][14],
    chartGrowthValue: newWorthGrowth,
    chartData: {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        // width:$scope.netWorthWidth,
        events: {
          load() {
            const chart = this;
            if (chart.options.chart.forExport) {
              chart.renderer.image('https://s3.us-east-2.amazonaws.com/inkwiry-images/PowerUp/logo2.png', 150, 23, 120, 40)
                .add();
            }


          }
        }
      },
      title: {
        text: ''
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: yearsList,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      },
      yAxis: [{ // Primary yAxis
        min: 0,
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Income & Expenses',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }, { // Tertiary yAxis
        /*min: 0,
        tickInterval: 10000,
        gridLineWidth: 0,*/
        title: {
          text: 'Net Worth',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }],
      tooltip: {
        formatter() {
          const chart = this;

          if (chart.y < 0) {
            return chart.series.name + '<br/>' + chart.x + ': <span style="color:red; font-weight:bold;">(' + formatDollar(-Math.round(chart.y)) + ')</span>';
          }
          else {
            return chart.series.name + '<br/>' + chart.x + ': <b>' + formatDollar(Math.round(chart.y)) + '</b>';
          }
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      series: [{
        name: 'Total Income',
        yAxis: 0,
        data: grossIncomeGraphData,
        stack: 'male',
        color: '#5B9BD5'
      }, {
        name: 'Total Expenses',
        yAxis: 0,
        data: totalExpensesGraphData,
        stack: 'female',
        color: '#FF0000'
      }, {
        name: 'Taxes',
        yAxis: 0,
        data: incomeAfterTaxesGraphData,
        stack: 'female',
        color: '#F4B183'
      }, {
        type: 'spline',
        name: 'Net Worth',
        yAxis: 1,
        data: netWorthGraphData,
        color: '#00B050'
      }]
    }
  }
}

export function assetsGraph() {
  let dashboardNetWorthData = modulesServices.dashBoardCalcsCompleteData();
  let assetsLiabilitiesGraph = [];
  let totalAssetsValue = 0;
  //Assets Value
  let endYearAssets = 0;
  let totalLiabilitiesValue = 0;
  if (typeof modulesServices.module1Data !== 'undefined' && modulesServices.module1Data.start_year !== '') {
    endYearAssets = parseInt(modulesServices.module1Data.start_year) + 14;
  }
  //Total Assets Value
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== '' && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data'][endYearAssets] !== 'undefined' && dashboardNetWorthData['Data'][endYearAssets] !== ''
    && typeof dashboardNetWorthData['Data'][endYearAssets]['Total Assets'] !== 'undefined' && dashboardNetWorthData['Data'][endYearAssets]['Total Assets'] !== '') {
    totalAssetsValue = dashboardNetWorthData['Data'][endYearAssets]['Total Assets'];
  }

  //Total Liabilities Value
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== '' && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data'][endYearAssets] !== 'undefined' && dashboardNetWorthData['Data'][endYearAssets] !== ''
    && typeof dashboardNetWorthData['Data'][endYearAssets]['Total Liabilities'] !== 'undefined' && dashboardNetWorthData['Data'][endYearAssets]['Total Liabilities'] !== '') {
    totalLiabilitiesValue = dashboardNetWorthData['Data'][endYearAssets]['Total Liabilities'];
  }
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data']['Assets Liabilities'] !== 'undefined' && dashboardNetWorthData['Data']['Assets Liabilities'] !== '') {
    assetsLiabilitiesGraph = dashboardNetWorthData['Data']['Assets Liabilities'];
  } else {
    assetsLiabilitiesGraph = [];
    assetsLiabilitiesGraph['Cash'] = [];
    assetsLiabilitiesGraph['Auto Property'] = [];
    assetsLiabilitiesGraph['Real Estate Property'] = [];
    assetsLiabilitiesGraph['401k'] = [];
    assetsLiabilitiesGraph['Roth Ira'] = [];
    assetsLiabilitiesGraph['Seph Ira'] = [];
    assetsLiabilitiesGraph['Pension'] = [];
    assetsLiabilitiesGraph['Short-Term Investments'] = [];
    assetsLiabilitiesGraph['Long-Term Investments'] = [];
    assetsLiabilitiesGraph['Existing Credit Card Debt'] = [];
    assetsLiabilitiesGraph['Student Loans'] = [];
    assetsLiabilitiesGraph['Car Loans'] = [];
    assetsLiabilitiesGraph['Mortgages'] = [];
    assetsLiabilitiesGraph['Net Worth'] = [];
  }

  return {
    chartValue: totalAssetsValue,
    chartLiabilities: totalLiabilitiesValue,
    chartData: {

      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        // width:$scope.netWorthWidth
      },
      title: {
        text: '',
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg',
                });
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf',
                });
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml',
                });
              },
            }],
          },
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
        categories: assetsLiabilitiesGraph['Years List'],
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      yAxis: [{ // Primary yAxis
        // min: 0,
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
            }
            return formatDollar(Math.round(chart.value));
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        title: {
          text: 'Assets & Liabilities',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      }, { // Tertiary yAxis
        /* min: 0,
        tickInterval: 10000,
        gridLineWidth: 0, */
        title: {
          text: 'Net Worth',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
            }
            return formatDollar(Math.round(chart.value));
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        opposite: true,
      }],
      tooltip: {
        formatter() {
          const chart = this;
          let lableTotalName = chart.series.name;
          let graphValue = 0;

          if (chart.series.stackKey === 'columnmale') {
            lableTotalName = 'Total Liabilities: ';
            graphValue = chart.point.stackTotal.toFixed(0);
            return `<b>${chart.x}</b><br/>${chart.series.name}: $${chart.y.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}<br/>${lableTotalName}$${graphValue.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
          } if (chart.series.stackKey === 'columnfemale') {
            lableTotalName = 'Total Assets: ';
            graphValue = chart.point.stackTotal.toFixed(0);
            return `<b>${chart.x}</b><br/>${chart.series.name}: $${chart.y.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}<br/>${lableTotalName}$${graphValue.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
          }
          if (chart.y < 0) {
            return `${chart.series.name}<br/>${chart.x}: <span style="color:red; font-weight:bold;">(${formatDollar(-Math.round(chart.y))})</span>`;
          }

          return `${chart.series.name}<br/>${chart.x}: <b>${formatDollar(Math.round(chart.y))}</b>`;
        },
      },
      legend: {
        itemStyle: {
          fontSize: '14px',
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      series: [{
        name: 'Cash',
        data: assetsLiabilitiesGraph['Cash'],
        stack: 'female',
        color: '#5B9BD5'
      }, {
        name: 'Other Valuables',
        data: assetsLiabilitiesGraph['Other Valuables'],
        stack: 'female',
        color: '#ed7d31'
      }, {
        name: 'Business',
        data: assetsLiabilitiesGraph['Business'],
        stack: 'female',
        color: '#4472c4'
      }, {
        name: 'Short-Term Investments',
        data: assetsLiabilitiesGraph['Short-Term Investments'],
        stack: 'female',
        color: '#9E480E'
      }, {
        name: 'Long-Term Investments',
        data: assetsLiabilitiesGraph['Long-Term Investments'],
        stack: 'female',
        color: '#636363'
      }, {
        name: 'Health Savings Account (HSA)',
        data: assetsLiabilitiesGraph['Health Savings Account (HSA)'],
        stack: 'female',
        color: '#f1975a'
      }, {
        name: 'Life Insurance Cash Value',
        data: assetsLiabilitiesGraph['Life Insurance Cash Value'],
        stack: 'female',
        color: '#b7b7b7'
      }, {
        name: '529 Plan',
        data: assetsLiabilitiesGraph['529 Plan'],
        stack: 'female',
        color: '#ffcd33'
      }, {
        name: '401(k)',
        data: assetsLiabilitiesGraph['401k'],
        stack: 'female',
        color: '#FFC000'
      }, {
        name: 'Roth 401(k)',
        data: assetsLiabilitiesGraph['Roth 401(k)'],
        stack: 'female',
        color: '#255e91'
      }, {
        name: 'Traditional IRA',
        data: assetsLiabilitiesGraph['Traditional IRA'],
        stack: 'female',
        color: '#9e480e'
      }, {
        name: 'Roth IRA',
        data: assetsLiabilitiesGraph['Roth Ira'],
        stack: 'female',
        color: '#4472C4'
      }, {
        name: 'SEP IRA',
        data: assetsLiabilitiesGraph['Seph Ira'],
        stack: 'female',
        color: '#70AD47'
      }, {
        name: 'Pension',
        data: assetsLiabilitiesGraph['Pension'],
        stack: 'female',
        color: '#255E91'
      }, {
        name: 'Auto Property',
        data: assetsLiabilitiesGraph['Auto Property'],
        stack: 'female',
        color: '#a5a5a5'
      }, {
        name: 'Real Estate Property',
        data: assetsLiabilitiesGraph['Real Estate Property'],
        stack: 'female',
        color: '#A5A5A5'
      }, {
        name: 'Car Loans',
        data: assetsLiabilitiesGraph['Car Loans'],
        stack: 'male',
        color: '#264478'
      }, {
        name: 'Existing Credit Card Debt',
        data: assetsLiabilitiesGraph['Existing Credit Card Debt'],
        type: 'spline',
        color: '#997300'
      }, {
        name: 'Existing Personal Loans',
        data: assetsLiabilitiesGraph['Existing Personal Loans'],
        type: 'spline',
        color: '#997300'
      }, {
        name: 'Student Loans',
        data: assetsLiabilitiesGraph['Student Loans'],
        type: 'spline',
        color: '#43682B'
      }, {
        name: 'Mortgages',
        data: assetsLiabilitiesGraph['Mortgages'],
        type: 'spline',
        color: '#7CAFDD'
      }, {
        name: 'Net Worth',
        data: assetsLiabilitiesGraph['Net Worth'],
        type: 'spline',
        color: '#00B050',
        yAxis: 1,
      }]
    }
  }
}

export function retirementAccounts() {
  let dashboardNetWorthData = modulesServices.dashBoardCalcsCompleteData();
  let dashBoardOutputSheetCompleteData = [];
  let k401Val = 0;
  let roth_ira_value = 0;
  let seph_ira_value = 0;
  let pension_value = 0;

  //Retirment Account
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data']['Assets Liabilities'] !== 'undefined' && dashboardNetWorthData['Data']['Assets Liabilities'] !== '') {
    dashBoardOutputSheetCompleteData = dashboardNetWorthData['Data']['Assets Liabilities'];
    k401Val = dashBoardOutputSheetCompleteData['401k'][14];
    roth_ira_value = dashBoardOutputSheetCompleteData['Roth Ira'][14];
    seph_ira_value = dashBoardOutputSheetCompleteData['Seph Ira'][14];
    pension_value = dashBoardOutputSheetCompleteData['Pension'][14];

  } else {
    dashBoardOutputSheetCompleteData = [];
    dashBoardOutputSheetCompleteData['401k'] = [];
    dashBoardOutputSheetCompleteData['Roth Ira'] = [];
    dashBoardOutputSheetCompleteData['Seph Ira'] = [];
    dashBoardOutputSheetCompleteData['Pension'] = [];
    dashBoardOutputSheetCompleteData['Years List'] = [];
  }

  //Retirment Account Graph
  let k401 = dashBoardOutputSheetCompleteData['401k'];
  let roth_ira = dashBoardOutputSheetCompleteData['Roth Ira'];
  let seph_ira = dashBoardOutputSheetCompleteData['Seph Ira'];
  let pension_ira = dashBoardOutputSheetCompleteData['Pension'];
  let yearsList = dashBoardOutputSheetCompleteData['Years List'];

  return {
    chart401: k401Val,
    chartroth: roth_ira_value,
    chartsep: seph_ira_value,
    chartpension: pension_value,
    chartData: {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        // width:$scope.netWorthWidth
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg',
                });
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf',
                });
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml',
                });
              },
            }],
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: yearsList,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      yAxis: [{ // Primary yAxis
        min: 0,
        tickInterval: 10000,
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
            }
            return formatDollar(Math.round(chart.value));
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        title: {
          text: '',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      }, {
        title: {
          text: '',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
            }
            return formatDollar(Math.round(chart.value));
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        opposite: true,
      }],
      tooltip: {
        formatter() {
          const chart = this;
          if (chart.y < 0) {
            return `${chart.series.name}<br/>${chart.x}: <span style="color:red; font-weight:bold;">(${formatDollar(-Math.round(chart.y))})</span>`;
          }

          return `${chart.series.name}<br/>${chart.x}: <b>${formatDollar(Math.round(chart.y))}</b>`;
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      legend: {
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: [{
        type: 'spline',
        name: '401(k)',
        yAxis: 1,
        data: k401,
        color: '#5F9BCF'
      }, {
        type: 'spline',
        name: 'Roth IRA',
        yAxis: 1,
        data: roth_ira,
        color: '#E77F35'
      }, {
        type: 'spline',
        name: 'Sep IRA',
        yAxis: 1,
        data: seph_ira,
        color: '#A5A5A5'
      }, {
        type: 'spline',
        name: 'Pension',
        yAxis: 1,
        data: pension_ira,
        color: '#FABE04'
      }]

    }
  }
}

export function totalGrossIncome() {
  let dashboardNetWorthData = modulesServices.dashBoardCalcsCompleteData();
  //Total Gross Income Breakdown Graph
  let grossIncomeBreakDownGraph = [];
  let totalGrossIncomeBreakDownValue = 0;
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data']['Total Gross Income Breakdown'] !== 'undefined' && dashboardNetWorthData['Data']['Total Gross Income Breakdown'] !== '') {
    grossIncomeBreakDownGraph = dashboardNetWorthData['Data']['Total Gross Income Breakdown'];
    totalGrossIncomeBreakDownValue = grossIncomeBreakDownGraph['All Total'];
  } else {
    grossIncomeBreakDownGraph = [];
    grossIncomeBreakDownGraph['Bonus/Tips/Commission'] = [];
    grossIncomeBreakDownGraph['Career Income'] = [];
    grossIncomeBreakDownGraph['Higher Education Income'] = [];
    grossIncomeBreakDownGraph['Net Rental Income'] = [];
    grossIncomeBreakDownGraph['Spouse Income'] = [];
    grossIncomeBreakDownGraph['Supplementary Income'] = [];
    grossIncomeBreakDownGraph['Total'] = [];
  }
  let yearsList = [];
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data']['Assets Liabilities'] !== 'undefined' && dashboardNetWorthData['Data']['Assets Liabilities'] !== '') {
    yearsList = dashboardNetWorthData['Data']['Assets Liabilities']['Years List'];
  }


  return {
    chartValue: totalGrossIncomeBreakDownValue,
    chartData: {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotShadow: false,
        type: 'column',
        // width:$scope.grosIncomeBreakWidth
      },
      title: {
        text: '',
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg',
                });
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf',
                });
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml',
                });
              },
            }],
          },
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: yearsList,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
          align: 'right',
        },
      },
      yAxis: [{ // Primary yAxis
        min: 0,
        labels: {
          formatter() {
            const chart = this;
            if (chart.value === 0) {
              return formatDollar(Math.round(chart.value));
            } if (chart.value < 0) {
              return `<span style="color:#bf0000;">(${formatDollar((-Math.round(chart.value)) / 1000)}k)</span>`;
            }
            return `${formatDollar((Math.round(chart.value)) / 1000)}k`;
          },
          style: {
            color: '#000000',
            fontSize: '13px',
          },
        },
        title: {
          text: '',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      }],
      tooltip: {
        headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
        crosshairs: true,
        shared: true,
        valueDecimals: 0,
        valuePrefix: '$',
        style: {
          color: '#000000',
          fontSize: '14px',
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      legend: {
        width: 500,
        itemStyle: {
          fontSize: '14px',
        },
      },

      series: [{
        name: 'Higher Education Income',
        yAxis: 0,
        data: grossIncomeBreakDownGraph['Higher Education Income'],
        stack: 'male',
        color: '#84A84D'
      }, {
        name: 'Career Income',
        yAxis: 0,
        data: grossIncomeBreakDownGraph['Career Income'],
        stack: 'male',
        color: '#0069AA'
      }, {
        name: 'Bonus / Tips / Commission',
        yAxis: 0,
        data: grossIncomeBreakDownGraph['Bonus/Tips/Commission'],
        stack: 'male',
        color: '#83D6FF'
      }, {
        name: 'Supplementary Income',
        yAxis: 0,
        data: grossIncomeBreakDownGraph['Supplementary Income'],
        stack: 'male',
        color: '#FFC000'
      }, {
        name: 'Spouse\'s Income',
        yAxis: 0,
        data: grossIncomeBreakDownGraph['Spouse Income'],
        stack: 'male',
        color: '#A5A5A5'
      }, {
        name: 'Total Rental Income',
        yAxis: 0,
        data: grossIncomeBreakDownGraph['Net Rental Income'],
        stack: 'male',
        color: '#203864'
      },
      {
        type: 'spline',
        name: 'Total',
        yAxis: 0,
        data: grossIncomeBreakDownGraph['Total'],
        color: '#0069AA'
      }
      ]

    }
  }

}

export function totalExpensesBreakdown() {
  let dashboardNetWorthData = modulesServices.dashBoardCalcsCompleteData();
  let expenseBreakDownGraph = [];
  let totalExpensesBreakDownValue = 0;
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data']['Total Expenses Breakdown'] !== 'undefined' && dashboardNetWorthData['Data']['Total Expenses Breakdown'] !== '') {
    expenseBreakDownGraph = dashboardNetWorthData['Data']['Total Expenses Breakdown'];
    // var totalExpenseValue = -expenseBreakDownGraph['Total'];
    totalExpensesBreakDownValue = expenseBreakDownGraph['All Total'];
  } else {
    expenseBreakDownGraph = [];
    expenseBreakDownGraph['Higher Education'] = [];
    expenseBreakDownGraph['Car'] = [];
    expenseBreakDownGraph['Children Pet'] = [];
    expenseBreakDownGraph['Home'] = [];
    expenseBreakDownGraph['Interest'] = [];
    expenseBreakDownGraph['Living'] = [];
    expenseBreakDownGraph['Total'] = [];
  }
  let yearsList = [];
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data']['Assets Liabilities'] !== 'undefined' && dashboardNetWorthData['Data']['Assets Liabilities'] !== '') {
    yearsList = dashboardNetWorthData['Data']['Assets Liabilities']['Years List'];
  }

  return {
    chartValue: totalExpensesBreakDownValue,
    chartData: {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotShadow: false,
        type: 'column',
        // width:$scope.grosExpensesWidth
      },
      title: {
        text: '',
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg',
                });
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf',
                });
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml',
                });
              },
            }],
          },
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: yearsList,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
          align: 'right',
        },
      },
      yAxis: [{ // Primary yAxis
        min: 0,
        labels: {
          formatter() {
            const chart = this;
            if (chart.value === 0) {
              return `${formatDollar(Math.round(chart.value))}  `;
            } if (chart.value < 0) {
              return `<span style="color:#bf0000;">(' + ${formatDollar((-Math.round(chart.value)) / 1000)} + 'k)</span>`;
            }
            return `${formatDollar((Math.round(chart.value)) / 1000)}k  `;
          },
          style: {
            color: '#000000',
            fontSize: '13px',
          },
        },
        title: {
          text: '',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      }],
      tooltip: {
        headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
        crosshairs: true,
        shared: true,
        valueDecimals: 0,
        valuePrefix: '$',
        style: {
          color: '#000000',
          fontSize: '14px',
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      legend: {
        width: 400,
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: [{
        name: 'Taxes',
        yAxis: 0,
        data: expenseBreakDownGraph['Taxes'],
        stack: 'male',
        color: '#F4B183'
      }, {
        name: 'Higher Education',
        yAxis: 0,
        data: expenseBreakDownGraph['Higher Education'],
        stack: 'male',
        color: '#84A84D'
      }, {
        name: 'Living',
        yAxis: 0,
        data: expenseBreakDownGraph['Living'],
        stack: 'male',
        color: '#FF0000'
      }, {
        name: 'Children & Pet',
        yAxis: 0,
        data: expenseBreakDownGraph['Children & Pet'],
        stack: 'male',
        color: '#A5A5A5'
      }, {
        name: 'Car',
        yAxis: 0,
        data: expenseBreakDownGraph['Car'],
        stack: 'male',
        color: '#ED7D31'
      }, {
        name: 'Real Estate',
        yAxis: 0,
        data: expenseBreakDownGraph['Real Estate'],
        stack: 'male',
        color: '#203864'
      }, {
        name: 'Loan Payments',
        yAxis: 0,
        data: expenseBreakDownGraph['Loan Payments'],
        stack: 'male',
        color: '#FFC000'
      }, {
        type: 'spline',
        name: 'Total',
        yAxis: 0,
        data: expenseBreakDownGraph['Total'],
        color: '#C00000'
      }]

    }
  }


}

export function totalAssests() {
  //Total Assets Graph
  let dashboardNetWorthData = modulesServices.dashBoardCalcsCompleteData();
  let totalAssetsGraphArray = [];
  let totalAssetsYearsList = [];
  let finalTotalAssetsValue = 0;
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== '' && typeof dashboardNetWorthData['Data']['Assets Liabilities'] !== 'undefined' && dashboardNetWorthData['Data']['Assets Liabilities'] !== '') {
    totalAssetsGraphArray = dashboardNetWorthData['Data']['Assets Liabilities']['Total Assets'];
    finalTotalAssetsValue = dashboardNetWorthData['Data']['Assets Liabilities']['Total Assets'][14];
    totalAssetsYearsList = dashboardNetWorthData['Data']['Assets Liabilities']['Years List'];
  }
  return {
    chartValue: finalTotalAssetsValue,
    chartData: {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotShadow: false,
        type: 'column',
        height: 260,
        borderWidth: 0,
        spacingTop: -10,
        // width:$scope.smallGraphWidth
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      xAxis: {
        visible: false,
        categories: totalAssetsYearsList,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      yAxis: [{ // Primary yAxis
        // visible: true,
        gridLineWidth: 3,
        minorGridLineWidth: 3,
        min: 0,
        tickInterval: 10000,
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
            }
            return formatDollar(Math.round(chart.value));
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        title: {
          text: '',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      }, {
        visible: false,
        title: {
          enabled: false,
          text: 'Net Worth',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
            }
            return formatDollar(Math.round(chart.value));
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        opposite: true,
      }],
      tooltip: {
        formatter() {
          const chart = this;
          if (chart.y < 0) {
            return `${chart.series.name}<br/>${chart.x}: <span style="color:red; font-weight:bold;">(${formatDollar(-Math.round(chart.y))})</span>`;
          }

          return `${chart.series.name}<br/>${chart.x}: <b>${formatDollar(Math.round(chart.y))}</b>`;
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      legend: {
        enabled: false,
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: [{
        type: 'spline',
        name: 'Total Assets',
        yAxis: 1,
        data: totalAssetsGraphArray,
        color: '#0070C0',
      }],

    }
  }

}

export function totalLiabilities() {

  let totalLiabilitiesGraphArray = [];
  let totalLiabilitiesYearsList = [];
  let finalTotalLiabilitiesValue = 0;
  //Total Liabilities Graph
  let dashboardNetWorthData = modulesServices.dashBoardCalcsCompleteData();

  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== '' && typeof dashboardNetWorthData['Data']['Assets Liabilities'] !== 'undefined' && dashboardNetWorthData['Data']['Assets Liabilities'] !== '') {
    totalLiabilitiesGraphArray = dashboardNetWorthData['Data']['Assets Liabilities']['Total Liabilities'];
    finalTotalLiabilitiesValue = dashboardNetWorthData['Data']['Assets Liabilities']['Total Liabilities'][14];
    totalLiabilitiesYearsList = dashboardNetWorthData['Data']['Assets Liabilities']['Years List'];
  }

  return {
    chartValue: finalTotalLiabilitiesValue,
    chartData: {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotShadow: false,
        type: 'column',
        height: 260,
        borderWidth: 0,
        spacingTop: -10,
        // width:$scope.smallGraphWidth
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        visible: false,
        categories: totalLiabilitiesYearsList,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      yAxis: [{ // Primary yAxis
        // visible: true,
        gridLineWidth: 3,
        minorGridLineWidth: 3,
        min: 0,
        tickInterval: 10000,
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
            }
            return formatDollar(Math.round(chart.value));
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        title: {
          text: '',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      }, { // Tertiary yAxis
        /* min: 0,
        tickInterval: 10000,
        gridLineWidth: 0, */
        visible: false,
        title: {
          enabled: false,
          text: 'Net Worth',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
            }
            return formatDollar(Math.round(chart.value));
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        opposite: true,
      }],
      tooltip: {
        formatter() {
          const chart = this;
          if (chart.y < 0) {
            return `${chart.series.name}<br/>${chart.x}: <span style="color:red; font-weight:bold;">(${formatDollar(-Math.round(chart.y))})</span>`;
          }

          return `${chart.series.name}<br/>${chart.x}: <b>${formatDollar(Math.round(chart.y))}</b>`;
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      legend: {
        enabled: false,
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: [{
        type: 'spline',
        name: 'Total Liabilities',
        yAxis: 1,
        data: totalLiabilitiesGraphArray,
        color: '#C00000',
      }],

    }
  }
}

export function totalBreakdown() {
  //Taxes Breakdown
  let dashboardNetWorthData = modulesServices.dashBoardCalcsCompleteData();
  let totalTaxesPaidValue = 0;
  let taxesBreakdownGraph = [];
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data']['Tax Summary'] !== 'undefined' && dashboardNetWorthData['Data']['Tax Summary'] !== '') {
    taxesBreakdownGraph = dashboardNetWorthData['Data']['Tax Summary'];
    totalTaxesPaidValue = -taxesBreakdownGraph['Total Taxes Paid'];
  } else {
    taxesBreakdownGraph = [];
    taxesBreakdownGraph['Federal Income'] = [];
    taxesBreakdownGraph['Social Security'] = [];
    taxesBreakdownGraph['Medicare'] = [];
    taxesBreakdownGraph['State Local'] = [];
    taxesBreakdownGraph['Total Taxes Paid'] = [];
    totalTaxesPaidValue = 0;
  }
  let totalValue;
  if (totalTaxesPaidValue < 0) {
    totalValue = '<span style="color:#bf0000;">(' + formatDollar(-Math.round(totalTaxesPaidValue)) + ')</span>';
  }if (totalTaxesPaidValue === 0) {
    totalValue = '<span style="color:#333;">(' + formatDollar(Math.round(totalTaxesPaidValue)) + ')</span>';
  }
   else {
    totalValue = formatDollar(Math.round(totalTaxesPaidValue));
  }
  let graphTitle;
  console.log('totalValue', Math.round(totalTaxesPaidValue));

  if (Math.round(totalTaxesPaidValue) !== 0) {
    graphTitle = '<span style="font-weight:bold; text-decoration:underline; font-size:15px;">Total</span><br>' + totalValue;
  } else if (Math.round(totalTaxesPaidValue) === 0) {
    graphTitle = '<span style="font-weight:bold; text-decoration:underline; font-size:15px;">Total</span><br>' + totalValue;   
  }else{
    graphTitle = '';
  }
  console.log('graphTitle', graphTitle);

  return {
    chart: {
      renderTo: 'chart',
      type: 'pie',
      height: 305,
      padding: 1,
      spacingLeft: 10,
      spacingTop: 0,
      // width:$scope.smallGraphWidth
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: graphTitle,
      align: 'center',
      verticalAlign: 'middle',
      y: -5,
      x: 0,
    },
    plotOptions: {
      pie: {
        shadow: false,
        borderWidth: 0,
        allowPointSelect: true,
        cursor: 'pointer',
      },
    },
    tooltip: {
      // eslint-disable-next-line
      pointFormat: '${point.y:,.0f} ({point.percentage:.0f}%)'
    },
    legend: {
      align: 'bottom',
      width: 300,
      itemWidth: 150,
      y: 25,
      itemStyle: {
        color: '#A5A5A5',
        fontWeight: 'normal',
        fontSize: '13px',
      },
    },
    series: [{
      data: [{
        name: 'Federal Income',
        y: taxesBreakdownGraph['Federal Income'],
        color: '#5B9BD5'
      }, {
        name: 'Social Security',
        y: taxesBreakdownGraph['Social Security'],
        color: '#ED7D31'
      }, {
        name: 'Medicare',
        y: taxesBreakdownGraph['Medicare'],
        color: '#A5A5A5'
      }, {
        name: 'State & Local',
        y: taxesBreakdownGraph['State Local'],
        color: '#FFC000'
      }
      ].filter(function (d) {
        return d['y'] > 0
      }),
      size: '90%',
      innerSize: '50%',
      showInLegend: true,
      dataLabels: {
        formatter() {
          return Math.round((this.percentage * 100) / 100) + '%';
        },
        distance: -19,
        color: '#000',
        align: 'center',
        style: {
          fontSize: '13px'
        }

      }
    }]

  }
}

export function netChangeCash() {
  let dashboardNetWorthData = modulesServices.dashBoardCalcsCompleteData();
  let netChangeInCashGraphArray = [];
  let totalNetChangeInCashYearsList = [];
  let startMainVal = 0
  let finalTotalNetChangeInCashValue = 0;
  //Net Change in cash graph
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data']['Assets Liabilities'] !== 'undefined' && dashboardNetWorthData['Data']['Assets Liabilities'] !== '') {
    netChangeInCashGraphArray = dashboardNetWorthData['Data']['Assets Liabilities']['Net Change In Cash'];
    totalNetChangeInCashYearsList = dashboardNetWorthData['Data']['Assets Liabilities']['Years List'];
    startMainVal = totalNetChangeInCashYearsList[0];
  }
  //For cash value(Net Change in cahs)
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data']['Assets Liabilities'] !== 'undefined' && dashboardNetWorthData['Data']['Assets Liabilities'] !== '') {
    finalTotalNetChangeInCashValue = dashboardNetWorthData['Data']['Assets Liabilities']['Cash'][14];
  }

  return {
    chartValue: finalTotalNetChangeInCashValue,
    chartData: {
      lang: {
        thousandsSep: ',',
      },
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: false,
        plotBorderWidth: 1,
        plotShadow: false,
        height: 260,
        borderWidth: 0,
        // width:$scope.smallGraphWidth
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: [{
        visible: false,
        categories: totalNetChangeInCashYearsList,
        crosshair: true,
      }],
      yAxis: [{ // Primary yAxis
        visible: false,
        opposite: true,
      }],
      tooltip: {
        headerFormat: '',
        pointFormatter() {
          const chart = this;
          if (chart.y < 0) {
            return `<table><tr><td style="padding:0">${chart.series.name}</td></tr><tr><td align="left">${chart.x + startMainVal}:<span style="color:red; font-weight:bold;">(${formatDollar(-Math.round(chart.y))})</span></td></tr></table>`;
          }

          return `<table><tr><td style="padding:0">${chart.series.name}</td></tr><tr><td align="left">${chart.x + startMainVal}: <b>${formatDollar(Math.round(chart.y))}</b></td></tr></table>`;
        },
        footerFormat: '',
        shared: true,
        useHTML: true,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          zones: [{
            value: 0, // Values up to 10 (not including) ...
            color: '#C00000', // ... have the color blue.
          }, {
            color: '#0070C0', // Values from 10 (including) and up have the color red
          }],
        },
        series: {
          pointWidth: 15,
        },
      },
      series: [{
        name: 'Net Change in Cash',
        type: 'column',
        yAxis: 0,
        data: netChangeInCashGraphArray,
      }],
    }
  }
}

export function debitRatio() {
  let dashboardNetWorthData = modulesServices.dashBoardCalcsCompleteData();
  let debtIncomeGraphArray = [];
  let debtIncomeYearsList = [];
  let finalDebtIncomeValue = 0;
  //Debt Income Ratio Graph
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data']['Input Sheet'] !== 'undefined' && dashboardNetWorthData['Data']['Input Sheet'] !== '') {
    debtIncomeGraphArray = dashboardNetWorthData['Data']['Input Sheet']['Debt To Income Ratio'];
    finalDebtIncomeValue = dashboardNetWorthData['Data']['Input Sheet']['Debt To Income Ratio'][14];
    debtIncomeYearsList = dashboardNetWorthData['Data']['Input Sheet']['Years List'];
  }

  return {
    chartValue: finalDebtIncomeValue,
    chartData: {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotShadow: false,
        type: 'column',
        height: 260,
        borderWidth: 0,
        spacingTop: -5,
        // width:$scope.smallGraphWidth
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        visible: false,
        categories: debtIncomeYearsList,
      },
      yAxis: [{ // Primary yAxis
        visible: false,
        gridLineWidth: 3,
        minorGridLineWidth: 3,
        tickInterval: 10000,
      }, {
        title: {
          text: 'Debt-to-Income Ratio',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        visible: false,
        opposite: false,
      }],
      tooltip: {
        formatter() {
          const chart = this;
          if (chart.y < 0) {
            return `${chart.series.name}<br/>${chart.x}: <span style="color:red; font-weight:bold;">(${formatPercentage(-Math.round(chart.y))})</span>`;
          }

          return `${chart.series.name}<br/>${chart.x}: <b>${formatPercentage(Math.round(chart.y))}</b>`;
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      legend: {
        enabled: false,
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: [{
        type: 'spline',
        name: 'Debt-to-Income Ratio',
        yAxis: 1,
        data: debtIncomeGraphArray,
        color: '#0070C0',
      }],


    }
  }
}

export function currentRatio() {
  let dashboardNetWorthData = modulesServices.dashBoardCalcsCompleteData();
  let currentRatioGraphArray = [];
  let currentRatioYearsList = [];
  let finalCurrentRatioValue = 0;
  //Current Ratio Graph
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data']['Input Sheet'] !== 'undefined' && dashboardNetWorthData['Data']['Input Sheet'] !== '') {
    currentRatioGraphArray = dashboardNetWorthData['Data']['Input Sheet']['Current Ratio'];
    finalCurrentRatioValue = dashboardNetWorthData['Data']['Input Sheet']['Current Ratio'][14];
    currentRatioYearsList = dashboardNetWorthData['Data']['Input Sheet']['Years List'];
  }
  return {
    chartValue: finalCurrentRatioValue,
    chartData: {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotShadow: false,
        type: 'column',
        height: 260,
        borderWidth: 0,
        spacingTop: -10,
        // width:$scope.smallGraphWidth
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        visible: false,
        categories: currentRatioYearsList,
      },
      yAxis: [{ // Primary yAxis
        visible: false,
        gridLineWidth: 3,
        minorGridLineWidth: 3,
        tickInterval: 10000,
      }, {
        visible: false,
        opposite: true,
      }],
      tooltip: {
        formatter() {
          const chart = this;
          if (chart.y < 0) {
            return `${chart.series.name}<br/>${chart.x}: <span style="color:red; font-weight:bold;">(${-parseFloat(chart.y).toFixed(1)}x)</span>`;
          }

          return `${chart.series.name}<br/>${chart.x}: <b>${parseFloat(chart.y).toFixed(1)}x</b>`;
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      legend: {
        enabled: false,
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: [{
        type: 'spline',
        name: 'Current Ratio',
        yAxis: 1,
        data: currentRatioGraphArray,
        color: '#0070C0',
      }],
    }
  }
}

export function savingRate() {
  let dashboardNetWorthData = modulesServices.dashBoardCalcsCompleteData();
  let savingsRateGraphArray = [];
  let savingsRateYearsList = [];
  let finalSavingsRateValue = 0;
  //Savings Rate Graph
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data']['Input Sheet'] !== 'undefined' && dashboardNetWorthData['Data']['Input Sheet'] !== '') {
    savingsRateGraphArray = dashboardNetWorthData['Data']['Input Sheet']['Savings Rate'];
    finalSavingsRateValue = dashboardNetWorthData['Data']['Input Sheet']['Savings Rate'][14];
    savingsRateYearsList = dashboardNetWorthData['Data']['Input Sheet']['Years List'];
  }

  return {
    chartValue: finalSavingsRateValue,
    chartData: {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotShadow: false,
        type: 'column',
        height: 260,
        borderWidth: 0,
        spacingTop: -10,
        // width:$scope.smallGraphWidth
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        visible: false,
        categories: savingsRateYearsList,
      },
      yAxis: [{ // Primary yAxis
        visible: false,
        gridLineWidth: 3,
        minorGridLineWidth: 3,
        tickInterval: 10000,
      }, {
        visible: false,
        opposite: true,
      }],
      tooltip: {
        formatter() {
          const chart = this;
          if (chart.y < 0) {
            return `${chart.series.name}<br/>${chart.x}: <span style="color:red; font-weight:bold;">(${formatPercentage(-Math.round(chart.y))})</span>`;
          }

          return `${chart.series.name}<br/>${chart.x}: <b>${formatPercentage(Math.round(chart.y))}</b>`;
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      legend: {
        enabled: false,
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: [{
        type: 'spline',
        name: 'Savings Rate',
        yAxis: 1,
        data: savingsRateGraphArray,
        color: '#0070C0',
      }],

    }
  }
}

export function totalAssestsandDebit() {
  let dashboardNetWorthData = modulesServices.dashBoardCalcsCompleteData();
  let totalAssetsDebtGraphArray = [];
  let totalAssetsDebtYearsList = [];
  let finalTotalAssetsDebtValue = 0;
  //Total Assets to Total Debt Graph
  if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
    && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
    && typeof dashboardNetWorthData['Data']['Input Sheet'] !== 'undefined' && dashboardNetWorthData['Data']['Input Sheet'] !== '') {
    totalAssetsDebtGraphArray = dashboardNetWorthData['Data']['Input Sheet']['Total Assets To Total Debt'];
    finalTotalAssetsDebtValue = dashboardNetWorthData['Data']['Input Sheet']['Total Assets To Total Debt'][14];
    totalAssetsDebtYearsList = dashboardNetWorthData['Data']['Input Sheet']['Years List'];
  }

  return {
    chartValue: finalTotalAssetsDebtValue,
    chartData: {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotShadow: false,
        type: 'column',
        height: 260,
        borderWidth: 0,
        spacingTop: -10,
        // width:$scope.smallGraphWidth
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        visible: false,
        categories: totalAssetsDebtYearsList,
      },
      yAxis: [{ // Primary yAxis
        visible: false,
        gridLineWidth: 3,
        minorGridLineWidth: 3,
        tickInterval: 10000,
      }, {
        visible: false,
        opposite: true,
      }],
      tooltip: {
        formatter() {
          const chart = this;
          if (chart.y < 0) {
            return `${chart.series.name}<br/>${chart.x}: <span style="color:red; font-weight:bold;">(${-parseFloat(chart.y).toFixed(1)}x)</span>`;
          }

          return `${chart.series.name}<br/>${chart.x}: <b>${parseFloat(chart.y).toFixed(1)}x</b>`;
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      legend: {
        enabled: false,
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: [{
        type: 'spline',
        name: 'Total Assets to Total Debt',
        yAxis: 1,
        data: totalAssetsDebtGraphArray,
        color: '#0070C0',
      }],

    }
  }
}
