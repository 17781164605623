import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});
HCExporting(Highcharts);

const SaveNowSaveLater = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>What If I Save Now vs Save Later</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div
              className={classNames(classes.pfBudgetPage, classes.blockTable)}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/save-now-save-later.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span> What If I Save Now vs Save Later
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/save-now-save-later-left.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          Have you ever heard the expression, &quot;Time is
                          money?&quot; There&apos;s a reason why and I&apos;m
                          going to show you with two different scenarios.
                        </p>
                      </div>
                    </div>
                    <div
                      className={classNames(classes.pfRow, classes.pfBlockRow)}>
                      <div className={classes.feText}>
                        <p>
                          Scenario A: Tomorrow, you start saving and investing
                          $10 a day for 10 years straight. After the tenth year,
                          you stop. You invest a total of $36,500. That
                          investment earns 7% per year for the next 40 years.
                        </p>
                        <p>
                          Scenario B: This time, you start saving and investing
                          10 years from now. You invest $15 a day for 10 years
                          straight. After the tenth year, you stop. You invest a
                          total of $54,750, or $18,000 more than Scenario A.
                          That investment also earns 7% per year for the next 40
                          years.
                        </p>
                        <p>
                          Would you be shocked if I told you that, in 40 years,
                          Scenario A has $110,000 more than Scenario B despite
                          investing $18,000 less? Check out the graph below and
                          see the power of compounding interest in action.
                        </p>

                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            chart: {
                              type: 'spline',
                              scrollablePlotArea: {
                                width: 600,
                                scrollPositionX: 0,
                              },
                              marginTop: 40,
                            },
                            navigation: {
                              menuItemStyle: {
                                fontSize: '14px',
                                textAlign: 'left',
                              },
                              menuItemHoverStyle: {
                                background: '#f5f5f5',
                                color: '#4c4c4c',
                                fontSize: '14px',
                              },
                              buttonOptions: {
                                height: 40,
                                width: 48,
                                symbolSize: 24,
                                symbolX: 24,
                                symbolY: 21,
                                symbolStrokeWidth: 2,
                                verticalAlign: 'bottom',
                                _titleKey: 'y',
                              },
                            },
                            exporting: {
                              buttons: {
                                contextButton: {
                                  menuItems: [
                                    {
                                      textKey: 'downloadPNG',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart();
                                      },
                                    },
                                    {
                                      textKey: 'downloadJPEG',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart({
                                          type: 'image/jpeg',
                                        });
                                      },
                                    },
                                    {
                                      textKey: 'downloadPDF',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart({
                                          type: 'application/pdf',
                                        });
                                      },
                                    },
                                    {
                                      textKey: 'downloadSVG',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart({
                                          type: 'image/svg+xml',
                                        });
                                      },
                                    },
                                  ],
                                },
                              },
                            },
                            credits: {
                              enabled: false,
                            },
                            title: {
                              text: '',
                              enabled: false,
                            },
                            xAxis: [
                              {
                                tickInterval: 1,
                                categories: [
                                  '0yrs',
                                  '5yrs',
                                  '10yrs',
                                  '15yrs',
                                  '20yrs',
                                  '25yrs',
                                  '30yrs',
                                  '35yrs',
                                  '40yrs',
                                ],
                                labels: {
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                              },
                            ],
                            yAxis: [
                              {
                                // Primary yAxis
                                labels: {
                                  formatter() {
                                    const chart = this;
                                    if (chart.value < 0) {
                                      return `<span style="color:#bf0000;">(${formatDollar(
                                        -Math.round(chart.value),
                                      )})</span>`;
                                    }
                                    return formatDollar(
                                      Math.round(chart.value),
                                    );
                                  },
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                                title: {
                                  text: '',
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                              },
                            ],
                            tooltip: {
                              headerFormat:
                                '<span style="font-size:14px">{point.key}</span><br/>',
                              crosshairs: true,
                              shared: true,
                              valueDecimals: 0,
                              valuePrefix: '$',
                              style: {
                                color: '#000000',
                                fontSize: '13px',
                              },
                            },
                            plotOptions: {
                              spline: {
                                lineWidth: 2,
                                states: {
                                  hover: {
                                    lineWidth: 2,
                                  },
                                },
                                marker: {
                                  enabled: true,
                                },
                              },
                            },
                            legend: {
                              itemStyle: {
                                fontSize: '14px',
                              },
                            },
                            series: [
                              {
                                name: 'Scenario A',
                                data: [
                                  0, 21849, 52853, 74999, 106426, 151020,
                                  214300, 304097, 431519,
                                ],
                                color: '#4472C4',
                              },
                              {
                                name: 'Scenario B',
                                data: [
                                  0, 0, 0, 32773, 79279, 112499, 159638, 226530,
                                  321451,
                                ],
                                color: '#Ed7D31',
                              },
                            ],
                          }}
                        />

                        <p>
                          That&apos;s why time is money. The earlier you start
                          saving, the sooner you can invest. The sooner you can
                          invest, the more time your money has to earn a return.
                          This is the power of compounding interest.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
SaveNowSaveLater.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SaveNowSaveLater);
