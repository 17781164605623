import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';

import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import scrollToComponent from 'react-scroll-to-component';
import _ from 'lodash';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import styles from './styles';
import ScrollToTop from '../../../components/ui/scroll-to-top/scrollToTop';
import Pageloader from '../../../components/ui/pageloader';
import powerUpLogo from '../../../assets/img/pf101-dashboard/powerup.png';

const SAVE_SURVEY = loader( '../../../graphql/schema/pf101/save-survey.graphql' );
const GET_SURVEY = loader( '../../../graphql/schema/pf101/get-survey-details.graphql' );

/* eslint react/prop-types: 0 */

const Pf101Survey = ( props ) => {
  const { classes } = props;
  const scrollOptions = { offset: -120, align: 'top', duration: 500 };

  const questionMainAns = [];
  const defaultReference = useRef( null );
  for ( let i = 0; i <= 9; i += 1 ) {
    const questionsObject = {
      id: i + 1, answer: '', scroll: defaultReference,
    };
    questionMainAns.push( questionsObject );
  }

  let quesionSection = useRef( null );

  const [questions, setQuestions] = useState( questionMainAns );
  const [progress, setProgress] = React.useState( 0 );
  const [myScorePopup, setMyScorePopup] = React.useState( false );
  const [unselectedQuestions, setUnselectedQuestions] = React.useState( '' );
  const [loading, setLoading] = React.useState( true );
  const [opacity, setOpacity] = React.useState( true );
  const [success, setSuccess] = React.useState( false );

  const [saveSurvey] = useMutation( SAVE_SURVEY, {
    onCompleted( ) {
      setLoading( false );
      setSuccess( true );
    },
    onError( ) {
      return false;
    },
  } );

  useQuery( GET_SURVEY, {
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      if ( response.getPf101SurveyDetails !== null ) {
        const updateQuestions = [...questions];
        Object.keys( response.getPf101SurveyDetails ).forEach( ( question ) => {
          let questionVal = question;
          questionVal = questionVal.replace( 'question_', '' );
          questionVal = parseInt( questionVal, 10 );
          // eslint-disable-next-line
          if ( !isNaN( questionVal ) ) {
            updateQuestions[questionVal - 1].answer = response.getPf101SurveyDetails[question];
          }
        } );
        setQuestions( updateQuestions );
      }
      setLoading( false );
    },
    onError( ) {
      setLoading( false );
    },
  } );

  useEffect( () => {
    let answerQuestions = _.reject( questions, ['answer', ''] );
    answerQuestions = _.reject( answerQuestions, ['id', 10] ).length;
    let progressBar = answerQuestions * ( 100 / 9 );
    progressBar = progressBar.toFixed( 0 );
    if ( progressBar > 99 ) {
      progressBar = 100;
    }
    setProgress( parseFloat( progressBar ) );
  }, [questions] );

  const handleAnswer = ( event, index ) => {
    const newQuesions = [...questions];
    newQuesions[index].answer = event.target.value;
    newQuesions[index].status = questions[index].correctAns === event.target.value ? 'C' : 'W';
    setQuestions( newQuesions );
  };

  const selectRating = ( rating, index ) => {
    const newQuesions = [...questions];
    newQuesions[index].answer = rating;
    setQuestions( newQuesions );
  };

  const handleMyScore = () => {
    let unAnswerQuestions = _.filter( questions, ['answer', ''] );
    unAnswerQuestions = _.reject( unAnswerQuestions, ['id', 10] );
    if ( unAnswerQuestions.length > 0 ) {
      setUnselectedQuestions( _.map( unAnswerQuestions, 'id' ).join( ', ' ) );
      setMyScorePopup( true );
    } else {
      setLoading( true );
      const saveObject = {};
      for ( let i = 0; i < 10; i += 1 ) {
        saveObject[`question_${i + 1}`] = questions[i].answer;
      }

      saveSurvey( {
        variables: {
          data: saveObject,
        },
      } );
    }
  };

  const handleMyScoreClose = () => {
    setMyScorePopup( false );
  };

  const handleQuestionScroll = ( questionNumber ) => {
    scrollToComponent( questionNumber, scrollOptions );
  };

  const elementInViewport = ( el ) => {
    let element = el;
    if ( typeof $ === 'function' && element instanceof $ ) {
      element = element[0];
    }

    if ( typeof element !== 'undefined' && element !== '' ) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 75
              && rect.left >= 0
              && rect.bottom <= ( window.innerHeight || document.documentElement.clientHeight )
              && rect.right <= ( window.innerWidth || document.documentElement.clientWidth )
      );
    }
    return false;
  };

  useEffect( () => {
    const onScroll = ( ) => {
      if ( quesionSection !== null && window.innerHeight - quesionSection.getBoundingClientRect().top >= 300 ) {
        document.getElementById( 'sticky_numbers' ).style.left = '30px';
      } else {
        document.getElementById( 'sticky_numbers' ).style.left = '-11em';
      }

      for ( let i = 0; i < 10; i += 1 ) {
        const element = $( questionMainAns[i].scroll );
        if ( ( elementInViewport( element ) === true || elementInViewport( element.find( 'ul' ) ) || elementInViewport( element.find( 'textarea' ) ) || elementInViewport( element.find( 'p' ) ) ) ) {
          $( '#sticky_numbers li' ).removeClass( 'stick_number_active_class' );
          $( `#sticky_numbers ul:first-child li:nth-child(${i + 1})` ).addClass( 'stick_number_active_class' );

          break;
        }
      }

      if ( ( quesionSection !== null && quesionSection.getBoundingClientRect() !== null && window.innerHeight - quesionSection.getBoundingClientRect().top < 300 ) ) {
        document.getElementById( 'progress-bar' ).style.display = 'none';
      } else {
        document.getElementById( 'progress-bar' ).style.display = 'block';
      }
    };
    window.addEventListener( 'scroll', onScroll );

    return () => window.removeEventListener( 'scroll', onScroll );
  } );
  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.awarenessBody }>

        <div className={ classes.awernessScoreSec }>
          <Typography className={ classes.awernessTitle } variant="div" component="div">
            <Link href="/pf101/dashboard">
              <img src={ powerUpLogo } alt="logo" />
            </Link>
          </Typography>
          <div className={ classes.container }>
            <div className={ classes.awernessBlock }>
              <Grid container spacing={ 2 }>

                <Grid item xs={ 12 } sm={ 12 }>
                  <div className={ classes.awernessBlockRight }>
                    <div className={ classes.moneyfreeRow }>
                      <p>Congratulations on finishing Personal Finance 101 and starting your journey to financial independence! This survey will ask for your feedback. It will take less than three minutes to complete.</p>
                      <p>We appreciate your honest feedback. Be assured that all answers you provide will be strictly anonymous.</p>
                      <p>Please click &apos;Start&apos; to begin and thank you for your time.</p>
                    </div>
                    <div className={ `${classes.whatsMyScore} ${classes.whatsMyScorePadding}` } id="my-score-button">
                      <Button onClick={ () => { setOpacity( false ); scrollToComponent( quesionSection, { offset: 0, align: 'top', duration: 500 } ); } }>Start</Button>
                    </div>

                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>

        <div className={ classes.awernessQuestionsSec } ref={ ( section ) => { quesionSection = section; } }>
          <div className={ classes.ChallengesQuizHead } id="awareness_title">
            <div className={ classes.container }>
              <Typography variant="h3" component="h3">Personal Finance 101 Experince </Typography>
            </div>
          </div>

          <div className={ classNames( classes.ChallengesQuizHead, classes.ChallengesQuizSub ) } id="awareness_title_sub">
            <div className={ classes.container }>
              <Typography variant="h3" component="h3">&nbsp;</Typography>
            </div>
          </div>

          <div id="sticky_numbers" className={ classes.ChallengesNumbers }>
            <ul>
              {
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map( ( value ) => (
                    <li key={ value }>
                      <Button onClick={ () => handleQuestionScroll( questionMainAns[value - 1].scroll ) } className={ classNames( classes.listNum, ( questions[value - 1].answer !== '' && classes.listNumRight ) ) }>
                        <span>
                          {value}
                          <i className="las la-check" />
                        </span>
                      </Button>
                    </li>
                  ) )
                }
            </ul>

            <div className={ classes.stickyBottom }>
              <Button onClick={ () => handleQuestionScroll( questionMainAns[0].scroll ) } className={ `${classes.listNumArrow} ${classes.arrowUp}` }>
                <span>
                  <i
                    className="las la-arrow-up"
                    data-for="returnStart"
                    data-tip="Return to start"
                  />
                </span>
                <ReactTooltip id="returnStart" place="right" type="info" effect="solid" className={ classes.tooltipInfo } />
              </Button>
            </div>
          </div>

          <div className={ classes.questionRow } ref={ ( section ) => { quesionSection = section; } }>
            <div className={ classNames( classes.container, opacity === true && classes.questionBlockOpacity ) }>
              <div ref={ ( section ) => { questionMainAns[0].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>1</span>
                  <p className={ classes.questionTitle }>
                    Personal Finance 101 improved my money skills and knowledge.
                  </p>
                  <div className={ classes.agreeNew }>
                    <span>Strongly Agree</span>
                    <span>Strongly Disagree</span>
                  </div>
                  <ul className={ `${classes.optionList} ${classes.optionListRating}` }>
                    { [10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map( ( data ) => (
                      <li className={ questions[0].answer === data && classes.activeClass } key={ data }>
                        <span role="button" tabIndex={ data } aria-hidden="true" onClick={ () => selectRating( data, 0 ) }>{data}</span>
                      </li>
                    ) )}
                  </ul>
                </div>
              </div>
              <div ref={ ( section ) => { questionMainAns[1].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>2</span>
                  <p className={ classes.questionTitle }>
                    I wish I could learn more about personal finance.
                  </p>
                  <div className={ classes.agreeNew }>
                    <span>Strongly Agree</span>
                    <span>Strongly Disagree</span>
                  </div>
                  <ul className={ `${classes.optionList} ${classes.optionListRating}` }>

                    { [10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map( ( data ) => (
                      <li className={ questions[1].answer === data && classes.activeClass } key={ data }>
                        <span role="button" tabIndex={ data } aria-hidden="true" onClick={ () => selectRating( data, 1 ) }>{data}</span>
                      </li>
                    ) )}
                  </ul>
                </div>
              </div>
              <div ref={ ( section ) => { questionMainAns[2].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>3</span>
                  <p className={ classes.questionTitle }>
                    I  believe other students would benefit from taking Personal Finance 101.
                  </p>
                  <div className={ classes.agreeNew }>
                    <span>Strongly Agree</span>
                    <span>Strongly Disagree</span>
                  </div>
                  <ul className={ `${classes.optionList} ${classes.optionListRating}` }>

                    { [10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map( ( data ) => (
                      <li className={ questions[2].answer === data && classes.activeClass } key={ data }>
                        <span role="button" tabIndex={ data } aria-hidden="true" onClick={ () => selectRating( data, 2 ) }>{data}</span>
                      </li>
                    ) )}
                  </ul>
                </div>
              </div>
              <div ref={ ( section ) => { questionMainAns[3].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>4</span>
                  <p className={ classes.questionTitle }>
                    Personal Finance 101 was engaging and easy-to-use.
                  </p>
                  <div className={ classes.agreeNew }>
                    <span>Strongly Agree</span>
                    <span>Strongly Disagree</span>
                  </div>
                  <ul className={ `${classes.optionList} ${classes.optionListRating}` }>

                    { [10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map( ( data ) => (
                      <li className={ questions[3].answer === data && classes.activeClass } key={ data }>
                        <span role="button" tabIndex={ data } aria-hidden="true" onClick={ () => selectRating( data, 3 ) }>{data}</span>
                      </li>
                    ) )}
                  </ul>
                </div>
              </div>
              <div ref={ ( section ) => { questionMainAns[4].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>5</span>
                  <p className={ classes.questionTitle }>
                    Would you recommend Personal Finance 101 to a friend?
                  </p>
                  <div className={ classes.agreeNew }>
                    <span>Very Likely</span>
                    <span>Not Likely</span>
                  </div>
                  <ul className={ `${classes.optionList} ${classes.optionListRating}` }>

                    { [10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map( ( data ) => (
                      <li className={ questions[4].answer === data && classes.activeClass } key={ data }>
                        <span role="button" tabIndex={ data } aria-hidden="true" onClick={ () => selectRating( data, 4 ) }>{data}</span>
                      </li>
                    ) )}
                  </ul>
                </div>
              </div>
              <div ref={ ( section ) => { questionMainAns[5].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>6</span>
                  <p className={ classes.questionTitle }>
                    I want to plan for higher education and for my career.
                  </p>
                  <div className={ classes.agreeNew }>
                    <span>Strongly Agree</span>
                    <span>Strongly Disagree</span>
                  </div>
                  <ul className={ `${classes.optionList} ${classes.optionListRating}` }>

                    { [10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map( ( data ) => (
                      <li className={ questions[5].answer === data && classes.activeClass } key={ data }>
                        <span role="button" tabIndex={ data } aria-hidden="true" onClick={ () => selectRating( data, 5 ) }>{data}</span>
                      </li>
                    ) )}
                  </ul>
                </div>
              </div>
              <div ref={ ( section ) => { questionMainAns[6].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>7</span>
                  <p className={ classes.questionTitle }>
                    I want to know what my net worth will be in 10 years.
                  </p>
                  <div className={ classes.agreeNew }>
                    <span>Strongly Agree</span>
                    <span>Strongly Disagree</span>
                  </div>
                  <ul className={ `${classes.optionList} ${classes.optionListRating}` }>

                    { [10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map( ( data ) => (
                      <li className={ questions[6].answer === data && classes.activeClass } key={ data }>
                        <span role="button" tabIndex={ data } aria-hidden="true" onClick={ () => selectRating( data, 6 ) }>{data}</span>
                      </li>
                    ) )}
                  </ul>
                </div>
              </div>
              <div ref={ ( section ) => { questionMainAns[7].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>8</span>
                  <p className={ classes.questionTitle }>
                    What was your favorite thing about PF101?
                  </p>

                  <ul className={ classes.optionList }>
                    <div className={ classes.formGroup }>
                      <textarea type="text" placeholder="" value={ questions[7].answer } onChange={ ( e ) => handleAnswer( e, 7 ) } />
                    </div>
                  </ul>
                </div>
              </div>
              <div ref={ ( section ) => { questionMainAns[8].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>9</span>
                  <p className={ classes.questionTitle }>
                    What was one thing you would change about PF101?
                  </p>

                  <ul className={ classes.optionList }>
                    <div className={ classes.formGroup }>
                      <textarea type="text" placeholder="" value={ questions[8].answer } onChange={ ( e ) => handleAnswer( e, 8 ) } />
                    </div>
                  </ul>
                </div>
              </div>
              <div ref={ ( section ) => { questionMainAns[9].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                <div className={ classes.question }>
                  <span className={ classes.questionNum }>10</span>
                  <p className={ classes.questionTitle }>
                    Do you have any other comments about how we can improve? (Optional)
                  </p>

                  <ul className={ classes.optionList }>
                    <div className={ classes.formGroup }>
                      <textarea type="text" placeholder="" value={ questions[9].answer } onChange={ ( e ) => handleAnswer( e, 9 ) } />
                    </div>
                  </ul>
                </div>
              </div>

              <div className={ classes.progressBar } id="progress-bar">
                <p>
                  {progress}
                  % complete
                </p>
                <div>
                  <span style={ { width: `${progress}%` } } />
                </div>
              </div>
              <div className={ classes.whatsMyScore } id="my-score-button">
                <Button onClick={ handleMyScore }>Finish</Button>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Dialog
        open={ success }
        onClose={ () => { setSuccess( false ); } }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ `${classes.pendinglistPopup} ${classes.pendingNumListPopupMain}` }
      >
        <DialogContent>
          <div className={ `${classes.pendingNumListPopup} ${classes.pendingNumListPopupNew}` }>
            <p>
              <i className="la la-check" />
              <span>Responses submitted. Thank you!</span>
            </p>
          </div>
        </DialogContent>
        <DialogActions className={ classes.buttonOk }>
          <Link href="/pf101/level10/financial-life-toolkit?revisit=final" color="primary" autoFocus>
            CONTINUE
          </Link>
        </DialogActions>
      </Dialog>

      <Dialog
        open={ myScorePopup }
        onClose={ handleMyScoreClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ classes.pendinglistPopup }
      >
        <DialogContent>
          <div className={ classes.pendingNumList }>
            <h3>What&apos;s My Score?</h3>
            <p>
              Please answer all the questions and provide an importance rating to access your score. Questions remaining:
              {' '}
              {unselectedQuestions}
              .
            </p>
          </div>
        </DialogContent>
        <DialogActions className={ classes.buttonOk }>
          <Button onClick={ handleMyScoreClose } color="primary" autoFocus>
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>
      <ScrollToTop />
    </Typography>
  );
};

Pf101Survey.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Pf101Survey );
