import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruFive = (props) => {
    const {
        classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide,handleWalkThrusBack, walkThruNo

    } = props;

    return (
        <div className={classNames(classes.worksheetWalkthru, classes.worksheetActiveWalkthru, classes.walkthruFive)}>
            <h4>Managing Your Scenarios</h4>
            <p>As you build a plan for financial independence by entering your details below, you can save those details as a scenario. Use the 'CLEAR' button to clear all inputs below and start fresh. 'LOAD' button to load scenarios that you have already created and saved. 'SAVE' button to save the current inputs below as a scenario. 'DOWNLOAD' button to download your scenario as a PDF. Hover on any of the buttons and a tool tip will appear.</p>
            <div className={classes.walkthruBottom}>
            <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />

                <div className={classes.walkThruBtns}>
                    <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(5) }}>Back</Button>
                    <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(5); }}>Next</Button>
                    <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                </div>
            </div>
        </div>
    );
};

WalkThruFive.propTypes = {
    classes: PropTypes.object.isRequired,
    handleWalkThrus: PropTypes.func.isRequired,
    handleWalkThruClose: PropTypes.func.isRequired,
    completedWalkThruSlides: PropTypes.object.isRequired,
    activeWalkThruSlide: PropTypes.string.isRequired,
    handleWalkThrusBack: PropTypes.func.isRequired,
    walkThruNo: PropTypes.string.isRequired,

};

export default withStyles(styles)(WalkThruFive);