const styles = ( theme ) => ( {
  modalHeader: {
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      margin: 0,
      fontSize: '18px',
      color: '#0069aa'
    }
  },
  summayModalHeader: {
    padding: '0px',
    '& h2 > div': {
      borderBottom: '1px solid #e5e5e5',
      padding: '16px 24px'
    },
    '& p': {
      paddingTop: '10px',
      fontSize: "19px",
      lineHeight: 1.42857143,
      color: '#333',
      borderBottom: '2px solid #337ab7',
      margin: '0 24px !important',
      marginBottom: '10px !important',
      '& sup': {
        fontSize: '10px',
        paddingLeft: '1px',
      }
    }
  },
  logoPic: {
    '& img': {
      width: '150px',
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      verticalAlign: 'middle',
    },
  },
  incomePopupClose: {
    position: 'absolute',
    right: '15px',
    top: '18px',
    marginTop: '-2px',
    padding: '0',
    background: 'transparent',
    border: '0',
    float: 'right',
    fontSize: '21px',
    lineHeight: '1',
    textShadow: '0 1px 0 #fff',
    fontWeight: '700',
    fontFamily: 'MuseoSans-300',
    minWidth: 'auto',
    '&:hover': {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
      filter: 'alpha(opacity=50)',
      opacity: '.5',
      background: 'transparent',
    },
  },
  modalFooter: {
    position: 'relative',
    padding: '7px 15px',
    textAlign: 'right',
    boxSizing: 'border-box',
    width: '100%',
    '& h3': {
      color: '#0069aa',
      fontSize: '14px',
    },
  },
  footerButton: {
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '6px 12px',
    textTransform: 'uppercase',
  },
  cancelButton: {
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#ccc',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  modalBody: {
    position: 'relative',
    padding: '15px',
  },
  tableResponsive: {
    minHeight: '.01%',
    overflowX: 'auto',
    fontFamily: '"MuseoSans-300"',
    '& p': {
      fontSize: '17px',
      position: 'relative',
      margin: '0 0 10px',
      color: '#000',
      '&::before': {
        borderBottom: '2px solid #337ab7',
        bottom: '0',
        content: '""',
        left: '0',
        position: 'absolute',
        width: '100%',
        zIndex: '999',
      },
    },
  },
  federalLoanTable: {
    maxHeight: '457px',
    height: '100%',
    overflowY: 'scroll',
    fontFamily: '"MuseoSans-300"',
  },
  loanRepayTable: {
    padding: '0 15px 20px',
    borderLeft: '15px solid #0070c0',
    borderRight: '15px solid #0070c0',
    borderBottom: '4px solid #b5afaf',
    '& table': {
      transform: 'scale(1)',
      width: '100%',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '& tbody': {
        '& tr': {
          '& td': {
            color: '#000',
            padding: '0 3px',
            position: 'static',
            verticalAlign: 'bottom',
            backgroundColor: '#fff',
            textAlign: 'center',
            fontSize: '17px',
            lineHeight: '1.42857143',
            boxSizing: 'border-box',
          },
        },
      },
    },
  },
  valueOne: {
    padding: '0',
    borderRight: '0',
    width: '33.33%',
    '& table': {
      width: '284px !important',
    },
  },
  loanRetailTable: {
    '& td': {
      '& table': {
        border: '2px solid #000',
        width: 'auto',
        borderSpacing: '0',
        borderCollapse: 'collapse',
        '& tr': {
          '& td': {
            verticalAlign: 'bottom',
            backgroundColor: '#fff',
            padding: '0 3px',
            position: 'static',
            fontSize: '17px',
            textAlign: 'left',
            '&:last-child': {
              textAlign: 'right',
            },
          },
        },
      },
    },
  },
  loanRow: {
    borderBottom: '2px solid #000',
  },
  middleRow: {
    width: '4%',
  },
  intrestTable: {
    width: '25%',
    padding: '0 !important',
    verticalAlign: 'top !important',
    '& table': {
      border: '2px solid #000',
      transform: 'scale(1)',
      float: 'right',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      // width: '240px !important',
      width: '280px !important',
      '& tr': {
        '& td': {
          verticalAlign: 'bottom',
          backgroundColor: '#fff',
          padding: '0 3px',
          position: 'static',
          fontSize: '17px',
          textAlign: 'left',
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
  },
  fixedData: {
    '& table': {
      width: '100%',
      borderSpacing: '0px',
      borderCollapse: 'collapse',
      '& tr': {
        '& td': {
          verticalAlign: 'bottom',
          padding: '0 3px',
          position: 'static',
          fontSize: '17px',
          textAlign: 'left',
        },
      },
    },
  },
  fixedTable: {
    padding: '0px !important',
    '& table': {
      '& tr': {
        '& td': {
          background: '#0070c0 !important',
          color: '#fff !important',
          '&:nth-child(1)': {
            width: '2%',
          },
          '&:nth-child(2)': {
            width: '4%',
          },
          '&:nth-child(3)': {
            width: '11%',
          },
          '&:nth-child(4)': {
            width: '8%',
          },
          '&:nth-child(5)': {
            width: '8%',
          },
          '&:nth-child(6)': {
            width: '10%',
          },
          '&:nth-child(7)': {
            width: '12%',
          },
          '&:nth-child(8)': {
            width: '11%',
          },
          '&:nth-child(9)': {
            width: '8%',
          },
        },
      },
    },
  },
  borderLine: {
    borderBottom: '2px solid #000',
  },
  tableMainData: {
    '& td': {
      '& table': {
        '& tbody': {
          '& tr': {
            '& td': {
              textAlign: 'right',
              '&:nth-child(1)': {
                width: '2%',
                textAlign: 'left',
              },
              '&:nth-child(2)': {
                width: '3%',
              },
              '&:nth-child(3)': {
                width: '7%',
              },
              '&:nth-child(4)': {
                width: '5%',
              },
              '&:nth-child(5)': {
                width: '6%',
              },
              '&:nth-child(6)': {
                width: '7%',
              },
              '&:nth-child(7)': {
                width: '8%',
              },
              '&:nth-child(8)': {
                width: '8%',
              },
              '&:nth-child(9)': {
                width: '8%',
              },
            },
          },
        },
      },
    },
  },
  leftBorder: {
    borderLeft: '2px solid #000',
    borderRight: '2px solid #000',
  },
  bottomBorder: {
    borderBottom: '2px solid #000',
  },
  noPadding: {
    padding: '0px !important',
  },
  piText: {
    display: 'block',
  },
})

export default styles;