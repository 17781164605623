import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const FundsAltInvestments = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Funds and Alternative Investments</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/funds-alternative-investments.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Funds and Alternative Investments
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/funds-alternative-inv-300px.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Other than stocks, bonds, savings accounts, and cash,
                          there are many different investments you can make.
                          Rather than picking individual stocks and bonds, you
                          can invest in mutual funds or exchange-traded funds.
                        </p>
                        <p className={classes.smallText}>
                          Think of funds as a basket. In that basket can be a
                          mix of stocks, bonds and alternative investments. You
                          invest in the basket rather than one stock or one bond
                          to lower your risk while gaining exposure to many
                          different investments. This is called diversifying
                          your investments to lower your risk. Put simply,
                          diversifying is the same as saying, &quot;don&apos;t
                          put all your eggs in one basket.&quot;
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p className={classes.noTopMargin}>
                          <b>Mutual funds</b>
                          <br />A mutual fund is a pool of money that is
                          actively managed by a fund manager. The fund manager
                          receives cash from investors and invests that cash
                          into stocks, bonds, and other financial assets that
                          hopefully earn a return. A fund&apos;s goal could be
                          to take more risk looking for greater reward or take
                          very little risk and earn a lower, but more
                          predictable return. Make sure you know the fund&apos;s
                          goal before investing and the historical performance
                          of the fund.
                        </p>
                        <p>
                          The benefit of mutual funds is that they are actively
                          managed by a professional. You don&apos;t have to
                          spend time researching different stocks and bonds and
                          managing your investments daily. The fund manager does
                          this for you in exchange for a fee. Before you invest
                          in any mutual fund, make sure you know the fees
                          associated with that investment!
                        </p>
                        <p>
                          <b>Exchange-traded funds (ETFs)</b>
                          <br />
                          ETFs are similar to mutual funds in that they are a
                          basket of investments, but they are bought and sold
                          like a stock. ETFs can be bought on a stock exchange,
                          trade every second of every trading day, and change
                          value by the second just like a stock. On the other
                          hand, mutual funds can only be bought and sold at the
                          end of each trading day and are bought and sold
                          directly from the fund manager or from a financial
                          institution.
                        </p>
                        <p>
                          ETFs are diversified like a mutual fund and are easier
                          to trade like a stock. Usually, ETFs have lower fees
                          than mutual funds. The average ETF has a{' '}
                          <a
                            href="https://www.napa-net.org/news-info/daily-news/mutual-fund-expense-ratios-continue-descent"
                            target="_blank"
                            rel="noopener noreferrer">
                            0.20% expense ratio
                          </a>
                          , which means you pay $2.00 in annual fees for every
                          $1,000 you invest. The average actively managed mutual
                          fund has a{' '}
                          <a
                            href="https://www.napa-net.org/news-info/daily-news/mutual-fund-expense-ratios-continue-descent"
                            target="_blank"
                            rel="noopener noreferrer">
                            0.76% expense ratio
                          </a>
                          , or $7.60 in annual fees for every $1,000 you invest.
                          Mutual funds usually require a minimum amount of money
                          to invest and charge higher fees than ETFs because
                          they are actively managed by an investment
                          professional, which could lead to better returns.
                        </p>
                        <p>
                          <b>Alternative investments</b>
                          <br />
                          Alternative investments include financial assets that
                          are not stocks, bonds, or cash. Since alternative
                          investments are not traditional assets, they tend to
                          be more complex and are riskier. For example,
                          investing in a hedge fund that trades oil and gas
                          futures or placing a value on and selling a{' '}
                          <a
                            href="https://www.motor1.com/news/29456/rare-1955-mercedes-benz-300-sl-alloy-gullwing-sells-for-462m/"
                            target="_blank"
                            rel="noopener noreferrer">
                            1955 Mercedes 300SL Gullwing
                          </a>{' '}
                          or a{' '}
                          <a
                            href="https://www.goodhousekeeping.com/life/money/g3844/most-expensive-barbies-ever/"
                            target="_blank"
                            rel="noopener noreferrer">
                            1999 De Beers 40th Anniversary Barbie
                          </a>{' '}
                          would be difficult for the average investor.
                          Alternative investments are harder to buy and sell and
                          trade much less often than stocks, bonds, or funds.
                        </p>
                        <p className={classes.noBottomMargin}>
                          Some alternative investments are:
                        </p>
                        <ul className={classes.noTopMargin}>
                          <li>Real estate</li>
                          <li>Hedge funds</li>
                          <li>Private equity funds</li>
                          <li>
                            Commodities such as gold, coffee, or crude oil
                          </li>
                          <li>Art, antiques, and collectibles</li>
                        </ul>
                        <p>
                          Alternative investments can have high rewards and help
                          diversify your investments. But they usually require
                          large minimum investments, charge much higher fees,
                          and can be very complex for the average investor. Make
                          sure you do your research or consult professional
                          guidance before considering alternative investments.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
FundsAltInvestments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FundsAltInvestments);
