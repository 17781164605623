import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Elements, StripeProvider } from 'react-stripe-elements';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import BillingInformation from './billing-information';

const stripeKey = process.env.REACT_APP_STRIPE_API_KEY;

const BillingInfo = ( ) => (
  <Typography variant="body1" component="div">
    <StripeProvider apiKey={ stripeKey }>
      <div className="example">
        <Elements>
          <BillingInformation />
        </Elements>
      </div>
    </StripeProvider>
    <ScrollToTop />
  </Typography>
);

export default ( BillingInfo );