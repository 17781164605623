import imagesCareerPathDown from '../../assets/img/blu-print/stepping-stones-graphic-cylinder-colors.png';
import fiImage from '../../assets/img/blu-print/financial_indep.png';
import heImage from '../../assets/img/blu-print/higher_education.png';
import cpImage from '../../assets/img/blu-print/career_path.png';
import caImage from '../../assets/img/blu-print/calvin_m_light_blue_icon.png';
import mheImage from '../../assets/img/blu-print/more_high_edu.png';
import acaImage from '../../assets/img/blu-print/additional_career.png';
import investImage from '../../assets/img/blu-print/calvin_m_yellow_icon.png';
import familyImage from '../../assets/img/blu-print/calvin_m_light_green_icon.png';
import vacationImage from '../../assets/img/blu-print/calvin_m_redicon.png';
import carImage from '../../assets/img/blu-print/calvin_m_yellow_car_icon.png';
import homeImage from '../../assets/img/blu-print/calvin_m_yellow_home_icon.png';
import reiTwo from '../../assets/img/blu-print/rei-3.png';
import fheImage from '../../assets/img/blu-print/fur_higher_bg_new.png';
import fcaImage from '../../assets/img/blu-print/further-career-adv-3.png';
import existingIcon from '../../assets/img/blu-print/calvin_m_gray_icon.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
    '& img': {
      maxWidth: '100%',
    },
    '@media (max-width: 767px)': {
      '& h3': {
        fontSize: '25px !important',
        lineHeight: '30px !important',
      },
      '& h2': {
        fontSize: '25px !important',
        lineHeight: '30px !important',
      },
      '& h4': {
        fontSize: '25px !important',
        lineHeight: '30px !important',
      },
      '& p': {
        fontSize: '17px !important',
        lineHeight: '28px !important',
      },
    },
  },
  containerRow: {
    maxWidth: '1250px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
    '& img': {
      maxWidth: '100%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      '& h3': {
        fontSize: '25px !important',
        lineHeight: '28px !important',
      },
      '& h2': {
        fontSize: '25px !important',
        lineHeight: '28px !important',
      },
      '& h4': {
        fontSize: '25px !important',
        lineHeight: '28px !important',
      },
      '& p': {
        fontSize: '17px !important',
        lineHeight: '28px !important',
      },
    },
  },
  assestBoxMain: {
    padding: '0 30px 0 15px',
  },
  BluePrintBanner: {
    position: 'relative',
    '& img': {
      width: '100%',
    },
  },
  BluePrintBannerContent: {
    position: 'absolute',
    left: '0',
    right: '0',
    margin: 'auto',
    top: '47%',
    textAlign: 'center',
    color: '#fff',
    '& h2': {
      fontSize: '4.2vw',
      color: '#fff',
      fontWeight: 'normal',
      margin: '0',
    },
    '& h3': {
      lineHeight: '40px',
      fontSize: '2.6vw',
      color: '#fff',
      marginTop: '0',
      fontWeight: 'normal',
      margin: '0',
    },
    [theme.breakpoints.down( 'sm' )]: {
      '& h3': {
        lineHeight: '20px',
      },
    },
  },
  financialLife: {
    margin: '50px 0',
    '& h3': {
      fontSize: '35px',
      paddingBottom: '10px',
      color: '#0069aa',
      margin: '0',
      fontWeight: 'normal',
      textAlign: 'center',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '25px',
        lineHeight: '28px',
      },
    },
    '& p': {
      fontSize: '22.5px',
      paddingTop: '25px',
      fontWeight: '100',
      fontFamily: 'MuseoSans-100',
      paddingRight: '25px',
      margin: '0',
      color: '#333',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '17px',
        padding: '0',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      margin: '20px 0',
    },
  },
  leftLife: {
    '& p': {
      paddingRight: '80px',
      '@media (max-width: 992px)': {
        paddingRight: '40px',
      }, 
      '& span': {
        fontSize: '27px',
        [theme.breakpoints.down( 'sm' )]: {
          fontSize: '22px',         
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '17px',
        padding: '15px 0 0',
      },
    },
  },
  financialLifeEvent: {
    '& h3': {
      fontSize: '35px',
      color: '#333',
      background: '#ebebeb',
      padding: '15px 0',
      fontWeight: 'normal',
      textAlign: 'center',
      marginBottom: '60px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
      },
      '@media (max-width: 320px)': {
        fontSize: '20px',
      }, 
    },
  },
  financialBlockBox: {
    padding: '15px',
    border: '4px solid #016547',
    borderRadius: '10px',
    position: 'relative',
    marginTop: '30px',
    backgroundColor: '#016547',
    zIndex: '9',
    cursor: 'pointer',
    [theme.breakpoints.down( 'md' )]: {
      padding: '10px',
    },
    '@media screen and (min-width: 350px) and (max-width: 540px)': {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },  
    '& h4': {
      fontSize: '34px',
      margin: '10px 0 0',
      lineHeight: '43px',
      color: '#fff',
      fontWeight: 'normal',
      textAlign: 'center',
      [theme.breakpoints.down( 'md' )]: {
        fontSize: '21px',
        lineHeight: '30px',
      },     
    },
    '& img': {
      position: 'absolute',
      top: '-15%',
      maxWidth: '70px',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      [theme.breakpoints.down( 'sm' )]: {
        maxWidth: '60px',
      },
    },
  },
  begainBlock: {
    marginTop: '0px',
    paddingRight: '30px',
    '& h2': {
      fontFamily: 'MuseoSans-100',
      fontSize: '40px',
      fontWeight: 'normal',
      textAlign: 'left',
      color: '#333',
      lineHeight: '50px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '25px',
        lineHeight: '30px',
      },
    },
  },
  begainBlockBottom: {
    paddingRight: '0',
  },
  fancialGraphic: {
    '& img': {
      width: '100%',
      display: 'flex',
    },

  },
  financialBlockBoxRight: {
    '& p': {
      fontSize: '23px',
      paddingLeft: '45px',
      paddingRight: '0',
      lineHeight: '28px',
      margin: '0',
      color: '#333',
      [theme.breakpoints.down( 'sm' )]: {
        paddingLeft: '25px',
      },
    },
  },
  ExitingAssets: {
    paddingTop: '70px',
    background: '#ebebeb',
    color: '#333',
    [theme.breakpoints.down( 'xs' )]: {
      paddingTop: '50px',
    },
    '& img': {
      width: '100%',
    },
    '& h2': {
      fontSize: '34px',
      lineHeight: '43px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',
      marginBottom: '15px',
    },
    '& p': {
      fontSize: '18px',
    },
  },
  ExitingAssetsRight: {
    '& img': {
      maxWidth: '200px',
      paddingTop: '30px',
      margin: '0 auto',
      display: 'inherit',
      paddingLeft: '25px',
      [theme.breakpoints.down( 'sm' )]: {
        maxWidth: '143px',
      },
    },
  },
  ExitingAssetsLeft: {
    '& img': {
      paddingTop: '40px',
      maxWidth: '85%',
      [theme.breakpoints.down( 'xs' )]: {
        paddingTop: '0',
        maxWidth: '100%',
      },
    },
  },
  assetsBox: {
    background: '#fff',
    borderColor: '#c7b48d',
    marginBottom: '30px',
    cursor: 'pointer',
    '& h4': {
      color: '#333',
    },
    '&:hover': {
      background: '#c7b48d',
      '& h4': {
        color: '#fff',
      },
    },

  },
  careerPath: {
    paddingBottom: '50px',
    color: '#333',
    position: 'relative',
    '& h3': {
      fontSize: '34.5px',
      margin: '20px 0 0',
      paddingBottom: '30px',
      lineHeight: ' 43px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',
    },
  },
  careerPathLeft: {
    maxWidth: '650px',
    marginTop: '100px',
    [theme.breakpoints.down( 'xs' )]: {
      marginTop: '50px',
    },
    '& img': {
      width: '100%',
    },
    '& ul': {
      padding: '0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#0069aa',
        },
      },
    },
  },
  careerPathRight: {
    '& > div': {
      marginTop: '80px',
    },
  },
  careerPathBg: {
    backgroundImage: `url(${imagesCareerPathDown})`,
    backgroundSize: '65%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom 30px left',
    [theme.breakpoints.down( 'xs' )]: {
      backgroundSize: '100%',
      backgroundPosition: 'bottom 0 left',
      paddingBottom: '50%',
    },
    '@media (min-width: 768px) and (max-width: 1279px)': {
      backgroundSize: '50%',
    }
  },
  HigherEducation: {
    padding: '70px 0 20px',
    background: '#ebebeb',
    position: 'relative',
  },
  HigherEducationLeft: {
    '& img': {
      width: '100%',
      [theme.breakpoints.down( 'md' )]: {
        objectFit: 'cover',
      },
      '@media screen and (min-width: 600px) and (max-width: 800px)': {
       minHeight: '600px',      
      },
      '@media screen and (min-width: 801px) and (max-width: 992px)': {
        minHeight: '500px',        
      },
      
    },
  },
  HigherEducationMiddleBox: {
    background: '#fff',
    '& h4': {
      color: '#333',
      marginTop: '0',
      '@media (max-width: 320px)': {
        fontSize: '20px !important',
      }, 
    },
    '& img': {
      left: '-17%',
      top: '50%',
      maxWidth: '110px',
      '@media screen and (min-width: 350px) and (max-width: 420px)': {
        left: '-13%',
      },
      '@media screen and (min-width: 421px) and (max-width: 540px)': {
        left: '-9%',
      },
      '@media screen and (min-width: 768px) and (max-width: 1024px)': {
        maxWidth: '78px',
      },
      '@media (max-width: 767px)': {
        maxWidth: '80px',
      },
    },
    '&:hover': {
      '& h4': {
        color: '#fff',
      },
    },
  },
  HigherEducationMiddle: {
    paddingLeft: '40px',
    '@media (max-width: 767px)': {
      paddingLeft: '40px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '70px',
    },
    '& > div': {
      margin: '57px 0 50px 15px',
      maxHeight: '120px',
      display: 'inline-block',
      [theme.breakpoints.down( 'xs' )]: {
        marginBottom: '10px',
      },
      '&:last-child': {
        margin: '35px 0 60px 15px',
      },
    },
  },
  HigherEducationRight: {
    paddingLeft: '100px',
    '@media screen and (min-width: 600px) and (max-width: 720px)': {
      paddingLeft: '30px',
    },
    '@media screen and (min-width: 721px) and (max-width: 767px)': {
      paddingLeft: '60px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '0',
    },
    '@media screen and (min-width: 768px) and (max-width: 992px)': {
      paddingLeft: '30px',
    },
    '@media screen and (min-width: 993px) and (max-width: 1024px)': {
      paddingLeft: '0px',
    },
    '& h3': {
      fontSize: '34.5px',
      lineHeight: ' 43px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-300',
      color: '#333',
      [theme.breakpoints.down( 'xs' )]: {
        marginTop: '0px',
      },
    },
    '& ul': {
      padding: '0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        color: '#333',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#8ba659',
        },
      },
    },
  },
  investments: {
    padding: '60px 0 0',
  },
  investTop: {
    '& h3': {
      fontSize: '34.5px',
      lineHeight: ' 43px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',
      paddingLeft: '55px',
      marginTop: '28px',
      color: '#333',
      [theme.breakpoints.down( 'xs' )]: {
        paddingLeft: '10px',
      },
    },
  },
  investBottom: {
    '& img': {
      marginTop: '-20%',
      display: 'flex',
      marginLeft: '45px',
      [theme.breakpoints.down( 'xs' )]: {
        marginTop: '0',
        marginLeft: '0px',
      },
    },
    '& ul': {
      padding: '0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        color: '#333',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#c7a458',
        },
      },
    },
  },
  familyGroup: {
    backgroundColor: '#ebebeb',
    paddingTop: '75px',
    paddingBottom: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      paddingTop: '50px',
    },
  },
  familyGroupTop: {
    '& h3': {
      fontSize: '34.5px',
      lineHeight: ' 43px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',
      paddingLeft: '20px',
      paddingRight: '20px',
      color: '#000',
      marginTop: '0',
      margin: '0',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        paddingLeft: '10px',
        paddingRight: '10px',
        fontSize: '20px',
      },      
    },
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
    },
    '& > div': {
      width: '34%',
      marginLeft: '50px',
      [theme.breakpoints.down( 'xs' )]: {
        width: 'auto',
        '& h3': {
          marginTop: '60px',
        },
      },
    },
  },
  investBottomRight: {
    maxWidth: '465px',
    margin: '50px 0 0 auto',
    '& ul': {
      padding: '0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#c7a458',
        },
      },
    },
  },
  VocationGroup: {
    padding: '20px 0 0',
    '& img': {
      display: 'flex',
    },
    '& h3': {
      fontSize: '34.5px',
      lineHeight: ' 43px',
      fontWeight: '200',
      fontFamily: 'MuseoSans-100',
      paddingLeft: '0',
      marginTop: '28px',
      margin: '0',
      color: '#333',
    },
    '& ul': {
      padding: '0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        color: '#333',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#bf4821',
        },
      },
    },
  },
  familyGroupLeft: {
    '& > div': {
      paddingRight: '50px',
      [theme.breakpoints.down( 'sm' )]: {
        paddingRight: '0',
      },
      [theme.breakpoints.down( 'xs' )]: {
        marginBottom: '60px',
      },
    },
  },
  BuyingCar: {
    padding: '60px 0',
    backgroundColor: '#ebebeb',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '20px 0 60px 0px',
    },
    '& h3': {
      fontSize: '34.5px',
      lineHeight: ' 43px',
      fontWeight: '200',
      fontFamily: 'MuseoSans-100',
      paddingLeft: '0',
      marginTop: '28px',
      margin: '0',
      color: '#333',
    },
    '& ul': {
      padding: '0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        color: '#333',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#f59331',
        },
      },
    },
  },
  BuyingHome: {
    padding: '60px 0 10px',
    backgroundColor: '#fff',
    '& h2': {
      fontSize: '30px',
      lineHeight: ' 40px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',
      paddingLeft: '0',
      marginTop: '28px',
      textAlign: 'center',
      margin: '0',
    },
    '& ul': {
      padding: '0 0 0 30px',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        color: '#333',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#ed6e36',
        },
      },
    },
  },
  BuyingHomeNew: {
    '& ul': {
      padding: '0 0 0 60px',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        padding: '0 0 0 20px',
      }, 
      [theme.breakpoints.down( 'xs' )]: {
        padding: '0 0 0 15px',
      }, 
    },
  },
  BuyingHomeFirst: {
    paddingRight: '30px',
  },
  buyingInvset: {
    paddingBottom: '0',
    '& ul': {
      padding: '25px 0 0 0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        color: '#333',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#004372',
        },
      },
    },
  },
  buyingInvsetRight: {
    position: 'relative',
    '@media screen and (min-width: 600px) and (max-width: 1279px)': {
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end',
    },
    '& h3': {
      position: 'absolute',
      left: '30%',
      textAlign: 'center',
      maxWidth: '165px',     
      [theme.breakpoints.down( 'md' )]: {
        left: '23%',
      }, 
      [theme.breakpoints.down( 'xs' )]: {
        left: '15%',
      }, 
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        left: '17%',
        bottom: '120px',
      },
      '@media screen and (min-width: 768px) and (max-width: 1279px)': {
        left: '24%',
        bottom: '130px',
      }
    },
    '& img': {
      maxWidth: '115%',
      marginLeft: '-30px',
      marginTop: '-75px',
      display: 'flex',
      [theme.breakpoints.down( 'md' )]: {
        maxWidth: '100% !important',
        marginLeft: '0px', 
      }, 
    },
  },
  assetsBoxOne: {
    borderColor: '#0069aa',
    '&:hover': {
      background: '#0069aa',
    },
  },
  assetsBoxTwo: {
    borderColor: '#1e96e0',
    '&:hover': {
      background: '#1e96e0',
    },
  },
  assetsBoxThree: {
    borderColor: '#79c3da',
    '&:hover': {
      background: '#79c3da',
    },
  },
  assetsBoxFour: {
    borderColor: '#8790b8',
    '&:hover': {
      background: '#8790b8',
    },
  },
  HigherEducationOne: {
    borderColor: '#84a84d',
    '&:hover': {
      background: '#84a84d',
      color: '#fff',
    },
  },
  HigherEducationTwo: {
    borderColor: '#489b71',
    '&:hover': {
      background: '#489b71',
      color: '#fff',
    },
  },
  HigherEducationThree: {
    borderColor: '#8fac81',
    '&:hover': {
      background: '#8fac81',
      color: '#fff',
    },
  },
  investBoxNew: {
    borderColor: '#cda349',
    background: '#fff',
    '& h4': {
      color: '#333',
    },
    '&:hover': {
      background: '#cda349',
      color: '#fff',
      '& h4': {
        color: '#fff',
      },
    },
  },
  familyBox: {
    borderColor: '#0299aa',
    background: '#fff',
    '& h4': {
      color: '#333',
    },
    '&:hover': {
      background: '#0299aa',
      color: '#fff',
      '& h4': {
        color: '#fff',
      },
    },
  },
  vocationTwo: {
    borderColor: '#bf4821',
    background: '#fff',
    marginTop: '55px',
    '& h4': {
      color: '#333',
    },
    '&:hover': {
      background: '#bf4821',
      color: '#fff',
      '& h4': {
        color: '#fff',
      },
    },
  },
  BuyingCarNew: {
    borderColor: '#f59331',
    background: '#fff',
    '& h4': {
      color: '#333',
    },
    '&:hover': {
      background: '#f59331',
      color: '#fff',
      '& h4': {
        color: '#fff',
      },
    },
  },
  buyingCarIconBox: {
    maxWidth: '350px',
    margin: '0 auto',
  },
  BuyingHomeIcon: {
    borderColor: '#ed6e36',
    background: '#fff',
    '& h4': {
      color: '#333',
    },
    '&:hover': {
      background: '#ed6e36',
      color: '#fff',
      '& h4': {
        color: '#fff',
      },
    },
  },
  buyingInvsetIcon: {
    borderColor: '#004372',
    background: '#fff',
    '& h4': {
      color: '#333',
    },
    '&:hover': {
      background: '#004372',
      color: '#fff',
      '& h4': {
        color: '#fff',
      },
    },
  },
  bluePrintClose: {
    padding: '15px 0',
    border: '2px solid #2b69a5',
    background: '#e1e1e1',    
    '& > div': {
      '& > div': {
        alignItems: 'center',
      },
    },
    '& h4': {
      margin: '0',
      fontSize: '18px',
      color: '#333',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px !important',
      }, 
    },
    '& p': {
      margin: '0',
      fontSize: '18px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px !important',
      }, 
    },
    '& img': {
      width: '80px',
      float: 'right',
    },
    '& button': {
      fontSize: '23px',
      color: '#fff',
      border: '1px solid #eb9e42',
      backgroundColor: '#eb9e42',
      padding: '16px 28px',
      borderRadius: '10px',
      lineHeight: '30px',
      fontFamily: 'MuseoSans-300',
      float: 'right',
      cursor: 'pointer',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
        display: 'flex',
        margin: '0 auto',
        float: 'none',
      }, 
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        padding: '10px 20px',
        fontSize: '13px',
      }, 
      '@media screen and (min-width: 768px) and (max-width: 992px)': {
        fontSize: '17px',
      }, 
      '&:hover': {
        border: '1px solid #f59331',
        backgroundColor: '#f59331',
      },
    },
  },
  dragClick: {
    position: 'fixed',
    bottom: '20px',
    zIndex: '999',
    width: '100%',
  },
  addingLinkModules: {
    zIndex: 0,
  },
  timeCloseAnimation: {
    opacity: '0 !important',
    marginLeft: '100%',
  },
  timeLineClose: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    width: 'auto',
    height: 'auto',
    cursor: 'pointer',
    zIndex: '9',
    [theme.breakpoints.down( 'xs' )]: {
      top: '5px',
    }, 
    '& span': {
      textDecoration: 'underline',
      '&:hover': {
        color: '#0069aa',
      },
    },
  },
  dragClickInner: {
    background: '#fff',
    // minWidth: '1170px',
    // maxWidth: '1170px',
    // padding: '15px 30px 30px 30px',
    borderRadius: '10px',
    boxShadow: '0 0 5px rgba(0,0,0,.5)',
    position: 'relative',
    WebkitAnimation: 'fadeIn',
    WebkitAnimationDuration: '1s',
    animation: 'fadeIn',
    animationDuration: '1s',
    transition: 'ease-in-out .3s',
    padding: '15px 30px 10px 0',
    opacity: '1',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '15px 5px 10px 5px',
    }, 
  },
  familyList: {
    '& ul': {
      padding: '0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        color: '#333',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#0299aa',
        },
      },
    },
  },
  fcWalkthru: {
    position: 'absolute',
    maxWidth: '640px',
    background: '#fff',
    zIndex: '999',
    border: '3px solid #0069aa',
    borderRadius: '40px',
    top: '135px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& h4': {
      width: '100%',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      fontSize: '20px',
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '10px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
      color: '#333',
      lineHeight: '1.1',
      boxSizing: 'border-box',
    },
    '& p': {
      color: '#000',
      fontSize: '16px',
      padding: '10px 20px',
      display: 'inline-block',
      lineHeight: '24px',
      margin: '0px 0px 10px',
      boxSizing: 'border-box',
    },
    '&::before': {
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
    '&::after': {
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
  },
  fcActiveWalkthru: {
    zIndex: '9999',
    pointerEvents: 'all',
    cursor: 'auto',
  },
  relativeBlock: {
    position: 'relative',
  },
  startWalkThru: {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    padding: '6px 12px',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#337ab7 !important',
      borderColor: '#204d74 !important',
    },
  },
  walkThruBtns: {
    maxWidth: '160px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    textAlign: 'right',
    marginBottom: '10px',
    float: 'right',
    paddingRight: '10px',
    '& button': {
      margin: '0px 2px',
      display: 'inline-block',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      userSelect: 'none',
    },
  },
  checkmarksScroll: {
    listStyle: 'none',
    padding: '0',
    display: 'inline-block',
    marginLeft: '0',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    paddingInlinStart: '35px',
    marginBottom: '10px',
    marginTop: '0px',
    boxSizing: 'border-box',
    '& li': {
      width: '15px',
      height: '15px',
      background: '#0069aa',
      display: 'inline-block',
      margin: '1px 2px',
      borderRadius: '50%',
      position: 'relative',
      lineHeight: '20px',
      float: 'left',
      cursor: 'pointer',
      fontSize: '18px',
      paddingBottom: '8px',
      listStyle: 'none',
      marginLeft: '2px',
      boxSizing: 'border-box',
      '&:last-child': {
        '&::before': {
          background: '0 0!important',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        margin: 'auto',
        left: 'auto',
        marginTop: 'auto',
        width: '7px',
        height: '3px',
        background: '#0069aa',
        display: 'inline-block',
        right: '-5px',
        zIndex: '-1',
        bottom: '0',
        boxSizing: 'border-box',
      },
      '&:hover': {
        opacity: '.8',
      },
    },
  },
  walkthruBottom: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0 25px 0 15px',
    boxSizing: 'border-box',
  },
  closeWalkThru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  fcWalkthruOne: {
    top: '124px',
    position: 'absolute',
    right: '0',
    left: 'auto',
    maxWidth: '450px',
    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
  },
  fcWalkthruTwo: {
    right: 'auto',
    top: 'auto',
    bottom: '160px',
    marginLeft: '40px',
    '&::before': {
      top: '-29px',
      left: '100px',
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '96px',
      right: 'auto',
    },
  },
  fcWalkthruThree: {
    right: 'auto',
    top: 'auto',
    bottom: '70px',
    marginLeft: '40px',
    maxWidth: '500px',
    '&::before': {
      top: '-29px',
      left: '100px',
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '96px',
      right: 'auto',
    },
  },
  fcWalkthruFour: {
    top: '0',
    right: '-70px',
    maxWidth: '550px',
    minWidth: '550px',
    textAlign: 'left',
    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
  },
  activeList: {
    background: '#84aa47 !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#84aa47',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '0',
      margin: 'auto',
      content: '""',
      height: '5px',
      width: '8px',
      borderWidth: '0 0 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      bottom: '2px',
      left: '-1px',
      right: '0',
      transform: 'rotate(-45deg)',
      boxSizing: 'border-box',
    },
  },
  currentTab: {
    background: '#f7941e !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#f7941e',
      },
    },
  },
  popupDivClick: {
    position: 'fixed',
    right: '0px',
    bottom: '195px',
    zIndex: 99,
    '& img': {
      borderRadius: '8px 0px 0px 8px',
      cursor: 'pointer',
      maxWidth: '56px',
    },
  },
  financialBlockBoxActive: {
    background: '#016547',
    '& h4': {
      color: '#fff',
    },
  },
  assetsBoxActive: {
    background: '#c7b48d',
    '& h4': {
      color: '#fff',
    },
  },
  careerBoxActive: {
    background: '#0069aa',
    '& h4': {
      color: '#fff',
    },
  },
  careerAdvBoxActive: {
    background: '#1e96e0',
    '& h4': {
      color: '#fff',
    },
  },
  addCareerAdvBoxActive: {
    background: '#79c3da',
    '& h4': {
      color: '#fff',
    },
  },
  furtherCareerAdvBoxActive: {
    background: '#8790b8',
    '& h4': {
      color: '#fff',
    },
  },
  HigherEducationOneActive: {
    background: '#84a84d',
    '& h4': {
      color: '#fff',
    },
  },
  HigherEducationTwoActive: {
    background: '#489b71',
    '& h4': {
      color: '#fff',
    },
  },
  HigherEducationThreeActive: {
    background: '#8fac81',
    '& h4': {
      color: '#fff',
    },
  },
  investBoxNewActive: {
    background: '#cda349',
    '& h4': {
      color: '#fff',
    },
  },
  familyBoxActive: {
    background: '#0299aa',
    '& h4': {
      color: '#fff',
    },
  },
  VocationTwoActive: {
    background: '#bf4821',
    '& h4': {
      color: '#fff',
    },
  },
  BuyingCarNewActive: {
    background: '#f59331',
    '& h4': {
      color: '#fff',
    },
  },
  BuyingHomeIconActive: {
    background: '#ed6e36',
    '& h4': {
      color: '#fff',
    },
  },
  buyingInvsetIconActive: {
    background: '#004372',
    '& h4': {
      color: '#fff',
    },
  },
  taskComplete: {
    position: 'absolute',
    top: '0',
    right: '0',
    '@media (max-width: 768px)': {
      right: '5px',
    }, 
    '& img': {
      width: '48px',
      height: '48px',
    },
  },
  updateTimelineBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down( 'xs' )]: {
      width: '85%',
      margin: '0 auto',
      justifyContent: 'center',
    },
    '& button': {
      marginTop: '10px',
      backgroundColor: '#f0b257',
      fontSize: '18px',
      padding: '7px 50px',
      borderRadius: '5px',
      fontWeight: '300',
      textTransform: 'uppercase',
      color: '#fff',
      border: 'none',
      lineHeight: '24px',
      boxSizing: 'border-box',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '15px',
        padding: '7px 20px',
      },
      '@media (max-width: 320px)': {
        fontSize: '13px',
      }, 
      '&:hover': {
        backgroundColor: '#f0b257',
        color: '#fff',
      },
    },
  },
  tooltipInfoTop: {
    maxWidth: '180px',
    background: '#0069aa !important',
    fontSize: '14px !important',
    padding: '3px 8px !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'initial',
    opacity: '1 !important',
    marginTop: '-5px !important',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',      
    },
  },
  tooltipLeft: {
    maxWidth: '180px',
    '&::after': {
      opacity: '1 !important',
      borderLeftColor: '#0069aa !important',
      borderTopColor: 'transparent !important',
    },
  },
  eventListHeader: {
    padding: '0 0 20px 30px',
    '& h3': {
      textAlign: 'center',
      margin: '0 auto',
      fontSize: '24px',
      color: '#0069aa',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px !important',
      }, 
      '@media (max-width: 767px)': {
        padding: '0 50px 20px 30px',
      }
    },
  },
  eventsList: {
    display: 'inline-block',
    width: '100%',
    marginTop: '0px',
    marginBottom: '10px',
    paddingLeft: '40px',
    boxSizing: 'border-box',
    '& li': {
      float: 'left',
      listStyle: 'none',
      position: 'relative',
      marginLeft: '-15px',
      '& span': {
        display: 'block',
        width: '85px',
        height: '85px',
        backgroundSize: 'cover',
        opacity: '.9',
        backgroundRepeat: 'no-repeat',
        '&::before': {
          height: '25px',
          bottom: '-25px',
          content: '""',
          position: 'absolute',
          background: '#828282',
          width: '1px',
          left: '50%',
        },
      },
    },
  },
  borderLine: {
    top: '11px',
    position: 'relative',
    background: '#828282',
    width: '100%',
    height: '1px',
    marginLeft: '15px',
    display: 'inline-block',
    left: '-25px',
    boxSizing: 'border-box',
    '&::before': {
      width: '12px',
      position: 'absolute',
      height: '12px',
      background: '#828282',
      borderRadius: '50%',
      top: '-6px',
      content: '""',
      boxSizing: 'border-box',
    },
    '&::after': {
      width: '12px',
      position: 'absolute',
      height: '12px',
      background: '#828282',
      borderRadius: '50%',
      top: '-6px',
      content: '""',
      right: '0',
      boxSizing: 'border-box',
    },
  },
  financialInde: {
    '& span': {
      backgroundImage: `url(${fiImage})`,
    },
  },
  heImage: {
    '& span': {
      backgroundImage: `url(${heImage})`,
    },
  },
  cpImage: {
    '& span': {
      backgroundImage: `url(${cpImage})`,
    },
  },
  caImage: {
    '& span': {
      backgroundImage: `url(${caImage})`,
    },
  },
  mheImage: {
    '& span': {
      backgroundImage: `url(${mheImage})`,
    },
  },
  acaImage: {
    '& span': {
      backgroundImage: `url(${acaImage})`,
    },
  },
  investImage: {
    '& span': {
      backgroundImage: `url(${investImage})`,
    },
  },
  familyImage: {
    '& span': {
      backgroundImage: `url(${familyImage})`,
    },
  },
  vacationImage: {
    '& span': {
      backgroundImage: `url(${vacationImage})`,
    },
  },
  carImage: {
    '& span': {
      backgroundImage: `url(${carImage})`,
    },
  },
  homeImage: {
    '& span': {
      backgroundImage: `url(${homeImage})`,
    },
  },
  reiTwo: {
    '& span': {
      backgroundImage: `url(${reiTwo})`,
    },
  },
  fheImage: {
    '& span': {
      backgroundImage: `url(${fheImage})`,
    },
  },
  fcaImage: {
    '& span': {
      backgroundImage: `url(${fcaImage})`,
    },
  },
  existingIcon: {
    '& span': {
      backgroundImage: `url(${existingIcon})`,
    },
  },
  eventPopup: {
    [theme.breakpoints.down( 'md' )]: {
      overflowX: 'auto',
      },   
    '& ul': {
      [theme.breakpoints.down( 'md' )]: {
      minWidth: '1100px',
      },
    },
  },
  getStartedPopupDialog: {
    position: 'relative',
    '& > div > div': {
      width: '100%',
      maxWidth: '1110px',
      maxHeight: 'inherit',
      transform: 'scale(.85)',
      [theme.breakpoints.down( 'sm' )]: {
        maxHeight: 'calc(100% - 64px)',
      },
      '@media (min-width: 1500px)': {
        height: 'auto',
      },
    },
    '& > div > div > div': {
      padding: '0',
      '&:first-child': {
        paddingTop: '0',
      },
    },
  },
  btnClose:{
    position: 'absolute',
    cursor: 'pointer',
    top: '15px',
    right: '20px',
    color: '#fff',
    fontSize: '20px',
    opacity: '0.8',
    '&:hover':{
      opacity: '1',
    },
    [theme.breakpoints.down( 'sm' )]: {
      color: '#000',
    }
  }
} );

export default styles;
