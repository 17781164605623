import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import classNames from 'classnames';
import confirmStyles from '../../../common-styles/confirm-custome-dialog-styles';


const LeaveDialogPopup = (props) => {

  const { classes, beforeLeavePopup, closeBeforeLeavePopup, clearScenario, saveScenarioCallBack, setLoading, history, getModuleData, setOpenPopup } = props;
  
  return (
    <Dialog
      open={ beforeLeavePopup.popupStatus === true }      
      // onBackdropClick={()=>{if(beforeLeavePopup.type === 'default_clear'){clearScenario()}}}
      disableBackdropClick={beforeLeavePopup.type === 'no_pwi_fi'}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={ classNames(classes.confirmDialogNew, (beforeLeavePopup.type === 'clear' || beforeLeavePopup.type === 'default_clear' || beforeLeavePopup.type === 'save-inkwiry-scenario') && classes.clearConfirmDialog, beforeLeavePopup.classVal !== undefined && classes[beforeLeavePopup.classVal]) }
    >
      <DialogTitle className={ classes.confirmDialogTitle }>
        {beforeLeavePopup.title !== undefined && beforeLeavePopup.title}
      </DialogTitle>
      <DialogContent className={ classes.confirmDialogContent }>
        <div className={ classes.pendingNumListNew }>
          <p>
            {beforeLeavePopup.content !== undefined && beforeLeavePopup.content}
          </p>
        </div>
      </DialogContent>
      {beforeLeavePopup.type === 'confirm' && (
        <DialogActions className={ classes.confirmDialogButtons }>
          <Button onClick={ () => {closeBeforeLeavePopup();saveScenarioCallBack()} } color="primary" className={classes.confirmBlueBtn}>
            SAVE AND CONTINUE
          </Button>
          {beforeLeavePopup.saveContinueBtn === true ? (
            <Button onClick={ () => {history.push(beforeLeavePopup.url)} } color="primary" className={classes.confirmGreenBtn}>
              CONTINUE WITHOUT SAVING
            </Button>
          ) : (
            <Button onClick={ closeBeforeLeavePopup } color="primary" className={classes.confirmGreenBtn}>
              CONTINUE WITHOUT SAVING
            </Button>
          )}
          <Button onClick={ closeBeforeLeavePopup } color="primary" >
            CANCEL
          </Button>
        </DialogActions>
      )}
      {beforeLeavePopup.type === 'load' && (
        <DialogActions className={ classes.confirmDialogButtons }>
          <Button onClick={ () => {closeBeforeLeavePopup();saveScenarioCallBack()} } color="primary" className={classes.confirmBlueBtn}>
            SAVE
          </Button>
          <Button onClick={ () => {closeBeforeLeavePopup();setOpenPopup( 'sight-load-scenario' );} } color="primary" className={classes.confirmGreenBtn}>
            CONTINUE
          </Button>
          <Button onClick={ closeBeforeLeavePopup } color="primary" >
            CANCEL
          </Button>
        </DialogActions>
      )}
      {beforeLeavePopup.type === 'clear' && (
        <DialogActions className={ classes.confirmDialogButtons }>
          <Button onClick={ clearScenario }  color="primary" className={classes.confirmGoldBtn}>
            CLEAR
          </Button>
          <Button onClick={ closeBeforeLeavePopup } color="primary" >
            CANCEL
          </Button>
        </DialogActions>
      )}
      {beforeLeavePopup.type === 'clear-worksheet' && (
        <DialogActions className={ classes.confirmDialogButtons }>
          <Button onClick={ clearScenario }  color="primary" className={classes.confirmBlueBtn}>
            YES
          </Button>
          <Button onClick={ closeBeforeLeavePopup } color="primary" >
            NO
          </Button>
        </DialogActions>
      )}
      {beforeLeavePopup.type === 'no_pwi_fi' && (
        <DialogActions className={ classes.confirmDialogButtons }>
          <Button onClick={ ()=>{history.push(beforeLeavePopup.url)} }  color="primary" className={classes.confirmBlueBtn}>
            GO TO FI MODULE
          </Button>
        </DialogActions>
      )}
      {beforeLeavePopup.type === 'default_clear' && (
        <DialogActions className={ classes.confirmDialogButtons }>
          <Button onClick={ ()=>{closeBeforeLeavePopup();setLoading(true);getModuleData()} }  color="primary" className={classes.confirmBlueBtn}>
            CONTINUE
          </Button>
          <Button onClick={ clearScenario } color="primary" >
            CLEAR
          </Button>
        </DialogActions>
      )}
      {beforeLeavePopup.type === 'save-inkwiry-scenario' && (
        <DialogActions className={ classes.confirmDialogButtons }>
          <Button onClick={ closeBeforeLeavePopup } color="primary" >
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

LeaveDialogPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  beforeLeavePopup: PropTypes.object.isRequired,
  closeBeforeLeavePopup: PropTypes.func.isRequired,
  clearScenario: PropTypes.func.isRequired,
  saveScenarioCallBack: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
  history: PropTypes.object,
  getModuleData: PropTypes.func.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
};
LeaveDialogPopup.defaultProps = {
  saveScenarioCallBack: null,
   history: null,
};

export default withStyles( confirmStyles )( LeaveDialogPopup );
