import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Table,
} from '@material-ui/core';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import YouTube from 'react-youtube';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import seperatordots from '../../../../assets/img/pf101/dots.png';

const GRAPH_DATE = loader( '../../../../graphql/schema/graph-data/data.graphql' );

const startTime = new Date();
const StocksAndBonds = ( props ) => {
  const youtubeOpts = {
    host: 'https://www.youtube-nocookie.com',
    width: '640',
    height: '390',
    playerVars: {
      autoplay: 0,
      rel: '0',
      disablekb: '0',
      showinfo: '0',
      ccLoadPolicy: '0',
      ivLoadPolicy: '0',
      modestbranding: '1',
    },
  };

  let bodySection = useRef( null );

  const { classes, location } = props;
  const params = queryString.parse( location.search );

  const [section, setSection] = React.useState( ( params.type !== undefined && params.type === 'audio' ) ? 0 : 1 );
  const [buttonStatus, setButtonStatus] = React.useState( !( ( params.revisit !== undefined && params.revisit === 'yes' ) ) );
  const [stockData, setStockData] = React.useState( '' );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  useQuery( GRAPH_DATE, {
    variables: {
      from: ['stocks_bonds'],
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      const stocksBondsIndex = _.findIndex( response.getIncomeGrowthGraphData.data, { name: 'stocks_bonds' } );
      if ( stocksBondsIndex >= 0 ) {
        setStockData( response.getIncomeGrowthGraphData.data[stocksBondsIndex] );
      }
    },
  } );

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      <div className={ classes.containerFluid }>
        {section === 0 ? (
          <div className={ classes.videoPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>Stock and Bonds</h3>
                    </div>
                    <div className={ classes.pfVideo }>
                      <YouTube videoId="l4TzfPfLMB4" opts={ youtubeOpts } onPlay={ () => { setButtonStatus( false ); } } />
                    </div>
                    <div className={ classes.kickStart }>
                      <p>
                        <span>Kickstart this activity with a short video! Once you&nbsp;</span>
                        <span>watch the video in full, click continue to keep going&nbsp;</span>
                        <span> on this activity.</span>
                      </p>
                    </div>
                    <div className={ classes.seperatorDots }>
                      <img src={ seperatordots } alt="logo" />
                    </div>
                    <Button className={ classes.continueButton } disabled={ buttonStatus } onClick={ () => { setSection( 1 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } }>Continue</Button>
                    <p className={ classes.accessLink }>
                      Can&apos;t access this video?&nbsp;
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 2 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } } className={ classes.continueLink }>Click here to continue.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={ classes.contentPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                    <li>
                      <span>&nbsp;&lt;&nbsp;</span>
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 0 ); } } className={ classes.breadCrumbVideoLink }>Video</span>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfImage }>
                      <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/stocks-bonds.png" alt="template" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>Stocks and Bonds</h3>
                    </div>
                    <div className={ classes.pfContent }>
                      <div className={ classes.pfRow }>
                        <div className={ classes.pfSlideImage }>
                          <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/stocks-bonds-300px.jpg" alt="template" />
                        </div>
                        <div className={ classes.pfText }>
                          <p className={ classes.coverAnything }>
                            Stocks and bonds are both securities, which is a financial asset that has monetary value and can be bought and sold by investors. Securities have the potential to earn investors a return on their money, but also have the risk of losing some of or all their money. Stocks are equity, which is a financial term for being an owner. And, bonds are debt. Let&apos;s explore stocks and bonds by starting with a short video.
                          </p>
                        </div>
                      </div>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          <b>Stocks</b>
                          <br />
                          A stock is a security that represents one share of ownership in a specific company. For example, let&apos;s say you bought 10 shares of Microsoft. Given Microsoft has 7.635 billion shares of stock outstanding, you would own 0.00000013% of the company.
                          Even though you own a small fraction of the company, those 10 shares have value because Microsoft is a huge company. How much value? On March 5, 2019, when you hypothetically made the purchase, one share of Microsoft stock was
                          {' '}
                          <a className={ classes.anchorStyle } href="https://finance.yahoo.com/quote/MSFT/" target="_blank" rel="budget-fy2020 noopener noreferrer">worth $112</a>
                          .
                          <p>10 shares of Microsoft x $112 share price = $1,120 value on March 5, 2019</p>
                          <p>
                            There are two ways to profit from investing in stocks: 1) the share price increases, or 2) the company pays a
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.thebalance.com/what-is-a-dividend-356103" target="_blank" rel="budget-fy2020 noopener noreferrer">dividend</a>
                            , which is when a company decides to pay a portion of their after-tax income to stockholders. Continuing our example, you sold your 10 shares of Microsoft on June 21, 2019.
                          </p>
                          <p>10 shares of Microsoft x $137 share price = $1,370 value on June 21, 2019 $1,370 sale price - $1,120 purchase price = $250 return before taxes and fees</p>
                          <p>Stocks are riskier investments when compared to bonds. Unlike bonds, stocks do not have periodic interest payments. Dividend payments are not guaranteed; the company chooses to pay a dividend when they want to. Stock prices are hard to predict and can quickly move up and down. Lastly, if a company goes bankrupt, the stock of that company usually becomes worthless and you will lose your investment.</p>
                          <p>
                            <b>Bonds</b>
                            <br />
                            A bond is a loan given to a company or government by an investor. In other words, when you buy a bond, you are lending money to a company in exchange for periodic interest payments (usually quarterly or twice a year) plus your money back at a later date. Let&apos;s look at an example.
                          </p>
                          <p>
                            The city of Philadelphia wants to rebuild the I-95 highway, so it issues bonds to raise money for the project. Each bond is a loan for $1,000, pays an annual interest rate of 4% (also called a
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.thebalance.com/what-is-a-bond-coupon-and-how-did-it-get-its-name-357374" target="_blank" rel="budget-fy2020 noopener noreferrer">coupon rate</a>
                            ), and has a maturity of 15 years. Maturity is a financial term that means how long until a loan gets repaid. Each year, a bond investor receives $40 of interest from the city of Philadelphia. And, after 15 years, the bond investor receives their original $1,000 back and the city has rebuilt the highway. A win-win solution.
                          </p>
                          <p>A good bond investing guideline is the higher the coupon rate, the riskier the investment.</p>
                          <p>Bonds are less risky investments when compared to stocks because they pay regularly scheduled interest payments and return the original investment at a specific date. With bonds, you run the risk of the borrower not having the cash to make interest payments to you. But, if a company does go bankrupt, bond investors are repaid before stockholders.</p>
                          <p>
                            <b>Return Comparison</b>
                            <br />
                            Remember, stocks are riskier so that should mean a higher, but less likely reward. How much higher reward? And, how much riskier?
                          </p>
                          <p>The tables and graphs below compare the S&P 500, which is an index that measures the performance of the 500 largest companies in the US stock market, and the 10-year US Treasury Bond.</p>
                        </p>
                      </div>
                      <div className={ classes.dividendTable }>
                        <Table>
                          <thead>
                            <tr>
                              <th />
                              <th>
                                S&P 500
                                <br />
                                {' '}
                                (including dividends)
                              </th>
                              <th>
                                10-yr US
                                <br />
                                {' '}
                                Treasury Bond
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                Average Annual
                                <br />
                                {' '}
                                Return from
                                <br />
                                {' '}
                                1928-2018
                              </td>
                              <td>11.36%</td>
                              <td>5.10%</td>
                            </tr>
                            <tr>
                              <td>Worst Year&apos;s Return</td>
                              <td>-43.84%</td>
                              <td>-11.12%</td>
                            </tr>
                            <tr>
                              <td>Best Year&apos;s Return</td>
                              <td>52.56%</td>
                              <td>32.81%</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className={ classes.pfRow }>
                        <p className={ classNames( classes.creditText, classes.noTopMargin, classes.noBottomMargin ) }>
                          <i>
                            Source:
                            <a className={ classes.anchorStyle } href="http://pages.stern.nyu.edu/~adamodar/New_Home_Page/datafile/histretSP.html" target="_blank" rel="budget-fy2020 noopener noreferrer">Aswath Damodaran, NYU Stern School of Business</a>
                          </i>
                        </p>
                      </div>

                      <div className={ classes.stockBondsGraph }>
                        <HighchartsReact
                          highcharts={ Highcharts }
                          options={ {
                            chart: {
                              type: 'spline',
                              scrollablePlotArea: {
                                scrollPositionX: 0,
                              },
                            },
                            credits: {
                              enabled: false,
                            },
                            title: {
                              text: 'Stocks v Bonds: Annual Returns',
                            },
                            exporting: {
                              enabled: false,
                            },
                            xAxis: [{
                              tickInterval: 5,
                              categories: stockData.dates,
                              labels: {
                                formatter() {
                                  const chart = this;
                                  const date = new Date( chart.value );
                                  return date.getFullYear();
                                },
                                style: {
                                  color: '#000000',
                                  fontSize: '13px',
                                },
                              },
                            }],
                            yAxis: [{ // Primary yAxis
                              labels: {
                                format: '{value:.1f}%',
                                style: {
                                  color: '#000000',
                                  fontSize: '14px',
                                },
                              },
                              title: {
                                text: '',
                                style: {
                                  color: '#000000',
                                  fontSize: '14px',
                                },
                              },
                            }],
                            tooltip: {
                              headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                              crosshairs: true,
                              shared: true,
                              valueDecimals: 1,
                              valueSuffix: '%',
                              style: {
                                color: '#000000',
                                fontSize: '14px',
                              },
                            },
                            legend: {
                              itemStyle: {
                                fontSize: '14px',
                              },
                            },
                            plotOptions: {
                              spline: {
                                lineWidth: 2,
                                states: {
                                  hover: {
                                    lineWidth: 2,
                                  },
                                },
                                marker: {
                                  enabled: false,
                                },

                              },
                            },
                            series: stockData.details,
                          } }
                        />
                      </div>

                      <div className={ classes.pfRow }>
                        <p className={ classNames( classes.creditText, classes.noTopMargin ) }>
                          <i>
                            Source:
                            <a className={ classes.anchorStyle } href="http://pages.stern.nyu.edu/~adamodar/New_Home_Page/datafile/histretSP.html" target="_blank" rel="budget-fy2020 noopener noreferrer">Aswath Damodaran, NYU Stern School of Business</a>
                          </i>
                        </p>
                      </div>
                      <div className={ classes.grayBox }>
                        <div className={ classes.pfRow }>
                          <div className={ classes.creditText }>
                            Main takeaways:
                            <ul className={ classes.noMargin }>
                              <li>Stocks are riskier than bonds as their returns fluctuate more in value</li>
                              <li>Bonds typically return less, which makes sense given they are less risky</li>
                              <li>A healthy mix of both stocks and bonds over the long run is usually a winning strategy</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <BottomContent />
                      <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level7/stock-and-bounds/exercise" startTime={ startTime } level={ 7 } exercise={ 2 } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Typography>
  );
};
StocksAndBonds.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( StocksAndBonds );
