import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
// import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import compose from 'recompose/compose';
import styles from './styles';
import logoImage from '../../../../assets/img/mcs/my-career-sketch.png';
import logoInkwiry from '../../../../assets/img/mcs/logo.png';
import Pageloader from '../../../../components/ui/pageloader';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import YouTube from 'react-youtube';
import tickMark from '../../../../assets/img/mcs/green-check-discovery.png';
import bookImage from '../../../../assets/img/mcs/icon-book-rev.png';
import { useQuery } from '@apollo/react-hooks';
import CareerImageOne from '../../../../assets/img/home_page/mike.jpg';
import CareerImageTwo from '../../../../assets/img/home_page/erica.jpg';
import CareerImageThree from '../../../../assets/img/home_page/santiago.jpg';
import CareerImageFour from '../../../../assets/img/home_page/monica.jpg';
import CareerImageFive from '../../../../assets/img/home_page/jeremy.jpg';
import CareerImageSix from '../../../../assets/img/home_page/jennifer.jpg';
import CareerImageSeven from '../../../../assets/img/home_page/sam.jpg';
import CareerImageEight from '../../../../assets/img/home_page/katelyn.jpg';
import CareerImageNine from '../../../../assets/img/home_page/aron.jpg';
import CareerImageTen from '../../../../assets/img/home_page/executive_chef1.jpg';
import CareerImageEleven from '../../../../assets/img/home_page/executive_chef2.jpg';
import CareerImageTweleve from '../../../../assets/img/home_page/executive_chef3.jpg';
import CareerImageThirteen from '../../../../assets/img/home_page/new_corporate_attr.jpg';
import CareerImageFourteen from '../../../../assets/img/home_page/brooke.jpg';
import CareerImageFifteen from '../../../../assets/img/home_page/calvin.jpg';
import CareerImageSixteen from '../../../../assets/img/home_page/car_salesman.jpg';
import CareerImageSeventeen from '../../../../assets/img/home_page/executive_chef4.jpg';
import CareerImageEighteen from '../../../../assets/img/home_page/executive_chef5.jpg';
import CareerImageNineteen from '../../../../assets/img/home_page/executive_chef6.jpg';
import CareerImageTwenty from '../../../../assets/img/home_page/taylor.jpg';
import CareerImageTwentyone from '../../../../assets/img/home_page/michelle.jpg';
import CareerImageTwentyTwo from '../../../../assets/img/home_page/david.jpg';
import CareerImageTwentyThree from '../../../../assets/img/home_page/laura.jpg';
import CareerImageTwentyFour from '../../../../assets/img/home_page/frank.jpg';
import CareerImageTwentyFive from '../../../../assets/img/home_page/katrina.jpg';
import CareerImageTwentySix from '../../../../assets/img/home_page/jay.jpg';
import CareerImageTwentySeven from '../../../../assets/img/home_page/amber.jpg';
import CareerImageTwentyEight from '../../../../assets/img/home_page/tim.jpg';
import infoIcon from '../../../../assets/img/info-icon.svg';

const MCS_LEVEL_ACTIVITY_DATA = loader('../../../../graphql/schema/career-sketch/mcs-level-activity.graphql');
const SAVEMYCAREERSKETCH = loader('../../../../graphql/schema/career-sketch/save-careersketch.graphql');
const youtubeOpts = {
    host: 'https://www.youtube-nocookie.com',
    playerVars: {
        autoplay: 1,
        rel: '0',
        disablekb: '0',
        showinfo: '0',
        ccLoadPolicy: '0',
        ivLoadPolicy: '0',
        modestbranding: '1',
    },
};
const McsLevelOneActivityOne = (props) => {
    const { classes } = props;

    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [watchIntro, setWatchIntro] = React.useState(false);
    const [watchVideo, setWatchVideo] = React.useState(false);
    const [bookSection, setBookSection]=React.useState(false);
    const [levelList, setLevelList] = React.useState([]);
    const [firstCarrerSketchClicked, setFirstCarrerSketchClicked] = React.useState(false);
    const { data: mcsLevelWiseData } = useQuery(MCS_LEVEL_ACTIVITY_DATA, {
        variables: {
            level_id: "1",
        },
        fetchPolicy: 'network-only',
        onCompleted() {
            setLevelList(mcsLevelWiseData.getMCSLevelActivityData.level_activity);
        },
        onError() {
          setLoading(false);
        },
      });

    const [submitFirstActivity] = useMutation(SAVEMYCAREERSKETCH, {
        onCompleted({
            saveMyCareerSketch: {
                status,
            },
        }) {
            if (status) {
                setLoading(false);
            }
        },
        onError(errors) {
            const formatedErrors = errors.graphQLErrors.map(({ message }) => (
                message
            ));
            setErrorMessage(formatedErrors);
            setLoading(false);
        },
    });

    const handleCompletedFirstActivity = (path) => {
        if (watchVideo && !firstCarrerSketchClicked) {
            setLoading(true);
            setFirstCarrerSketchClicked(true);
            submitFirstActivity({
                variables: {
                    data: {
                        level: "1",
                        option_id: "1",
                    },
                },
            });
        }
    }
    const handleWatchIntro = () => {
        setWatchIntro(true);
        setWatchVideo(true);
    };

    const handleWatchIntroClose = () => {
        setWatchIntro(false);
    };
    return (
        <Typography variant="body1" component="div">
            {loading && <Pageloader loading={loading} />}
            <div>
                <div className={classes.mcsDevelopVision }>                    
                    <div className={classes.topRow }>
                        <div className={classes.mcsLogoImage}>
                            <Link to="/my-career-sketch/dashboard">
                                <img src={logoImage} alt="logo" />
                            </Link>
                        </div>
                        <div className={ classes.activityStatus }>
                            <h4><span className={ classes.greenTick }><img src={ tickMark } alt="tick" /></span>ACTIVITY COMPLETE! BACK TO <a href="/my-career-sketch/level1">LEVEL 01</a></h4>
                        </div>
                        <ReactTooltip id="book" place="bottom" type="info" effect="solid" className={classes.tooltipInfoTop} />
                        <div className={ classes.bookBlock }>                            
                            <div className={ classes.activityImage } aria-hidden="true" onClick={()=>{setBookSection(true)}} data-for="book" data-tip="Activity Tracker">
                                <img src={ bookImage } alt="book" />                                                        
                            </div>
                            {bookSection && (
                            <div className={ classes.reviewLevels }>
                                <i className="fa fa-times" onClick={()=>{setBookSection(false)}} />
                                <div className={ classes.panelDefault }>
                                    <div className={ classes.panelBody }>
                                        <span className={ classes.titleName }>
                                            <span className={ classes.mcsNumber}>1.</span>
                                            <span>Understanding career sketches</span>
                                        </span>
                                        {levelList[0] && levelList[0].status === 1 && ( <span className={ classes.greentickMark }>
                                        <img src={ tickMark } alt="tickmark" />    
                                        </span>)}                                       
                                    </div>
                                    </div>
                                    <div className={ classes.panelDefault }>
                                    <div className={ classes.panelBody }>                                       
                                        <span className={ classes.titleName }>
                                            <span className={ classes.mcsNumber}>2.</span>
                                            <span>Four Powerful questions</span>
                                        </span>
                                        {levelList[1] && levelList[1].status === 1 && ( <span className={ classes.greentickMark }>
                                        <img src={ tickMark } alt="tickmark" />    
                                        </span>)}
                                           
                                    </div>
                                </div>
                            </div>  
                            )} 
                        </div>
                    </div>                   
                </div> 
                <div className={classes.fiContent}>
                    <div className={classes.mcsRow}>
                        <div className={ classes.backtoLevel }>
                        <Link to="/my-career-sketch/level1">
                                <span> {'<'} Back to level 01</span>
                            </Link>
                        </div>
                    </div>                
                    <div className={classes.fiHeader}>                    
                        <div className={classes.fiHeaderInner}>
                            <span className={classes.pwiActiveModule}>
                                <h3>Level 01: Smarter tomorrows</h3>
                            </span>
                        </div>                    
                    </div>
                    <div className={classes.stepOne}>
                        <div className={classes.fiContentHeader}>
                            <div className={classes.pwiWalkTitle}>
                                <div aria-hidden="true" className={classes.menuIcon}>
                                    <span />
                                    <span />
                                    <span />
                                </div>
                                <h3>Understanding career sketches</h3>
                            </div>
                        </div>
                        <div className={classes.ProgressBar}>
                            <div className={classes.ProgressBarInner} style={{ width: '100%' }} />
                        </div>
                        <div className={classes.contentBlock}>
                            <p className={classes.MarginTopZero}>
                                Inkwiry career sketch stories are the perfect place to begin to see how vision, stepping stones and the principles of smarter tomorrows are integrated into an actionable plan. Choose from Inkwiry&apos;s 22 career sketch to gain a greater understanding of how a career sketch comes together.
                            </p>
                            <p>
                                Just so you know — while the individuals in Inkwiry&apos;s career sketch stories are invented, the career paths are real, the insights and strategies are real and the data is real. Your challenge is to use similar insights and strategies to crystallize your vision and develop stepping stones to design your own career sketch story.
                            </p>
                            <p>
                                To complete this activity, watch the video and read at least one career sketch story.
                            </p>
                            <div className={ classes.careerSketchCards }>
                                <div>
                                <ul>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageOne } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Mike
                                            <span>Registered Architect</span>
                                        </h3>
                                        <span>$315,148</span>
                                        <div className={ classes.infoIcon }>
                                            <img
                                            src={ infoIcon }
                                            alt="infoicon"
                                            data-for="infoIconNew"
                                            data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Mike gets married, this figure includes his partner's income/expenses."
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageTwo } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Erica
                                            <span>Neuroradiologist</span>
                                        </h3>
                                        <span>$67,574</span>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageThree } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Santiago
                                            <span>Wall Street Banker</span>
                                        </h3>
                                        <span>$921,963</span>
                                        <div className={ classes.infoIcon }>
                                            <img
                                            src={ infoIcon }
                                            alt="infoicon"
                                            data-for="infoIconNew"
                                            data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Santiago gets married, this figure includes his partner's income/expenses."
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageFour } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Monica
                                            {' '}
                                            <span>Executive Chef</span>
                                        </h3>
                                        <span>$453,644</span>
                                        <div className={ classes.infoIcon }>
                                            <img
                                            src={ infoIcon }
                                            alt="infoicon"
                                            data-for="infoIconNew"
                                            data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Monica gets married, this figure includes her partner's income/expenses."
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageFive } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Jeremy
                                            <span>Real Estate Broker</span>
                                        </h3>
                                        <span>$442,791</span>
                                        <div className={ classes.infoIcon }>
                                            <img
                                            src={ infoIcon }
                                            alt="infoicon"
                                            data-for="infoIconNew"
                                            data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Jeremy gets married, this figure includes his partner's income/expenses."
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageSix } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Jennifer
                                            <span>Master Plumber</span>
                                        </h3>
                                        <span>$398,951</span>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageSeven } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Sam
                                            <span>Software Architect</span>
                                        </h3>
                                        <span>$1,344,293</span>
                                        <div className={ classes.infoIcon }>
                                            <img
                                            src={ infoIcon }
                                            alt="infoicon"
                                            data-for="infoIconNewRight"
                                            data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Sam gets married, this figure includes his partner's income/expenses."
                                            />
                                            <ReactTooltip id="infoLeftIcon" place="left" type="info" effect="solid" className={ classes.tooltipInfo } />
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageEight } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Katelyn
                                            <span>Fashion Designer</span>
                                        </h3>
                                        <span>$393,580</span>
                                        <div className={ classes.infoIcon }>
                                            <img
                                            src={ infoIcon }
                                            alt="infoicon"
                                            data-for="infoIconNew"
                                            data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Katelyn gets married, this figure includes her partner's income/expenses."
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageNine } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Aaron
                                            <span>Math Teacher</span>
                                        </h3>
                                        <span>$309,195</span>
                                        <div className={ classes.infoIcon }>
                                            <img
                                            src={ infoIcon }
                                            alt="infoicon"
                                            data-for="infoIconNew"
                                            data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Aaron gets married, this figure includes his partner's income/expenses."
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageTen } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Mike
                                            <span>Registered Architect</span>
                                        </h3>
                                        <span>$315,148</span>
                                        </div>
                                    </div>
                                    </li>

                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageEleven } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Mike
                                            <span>Registered Architect</span>
                                        </h3>
                                        <span>$315,148</span>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageTweleve } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Mike
                                            <span>Registered Architect</span>
                                        </h3>
                                        <span>$315,148</span>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageThirteen } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Anita
                                            <span>Corporate Attorney</span>
                                        </h3>
                                        <span>$1,306,826</span>
                                        <div className={ classes.infoIcon }>
                                            <img
                                            src={ infoIcon }
                                            alt="infoicon"
                                            data-for="infoIconNew"
                                            data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Anita gets married, this figure includes her partner's income/expenses."
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageFourteen } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Brooke
                                            <span>Army Engineer</span>
                                        </h3>
                                        <span>$433,549</span>
                                        <div className={ classes.infoIcon }>
                                            <img
                                            src={ infoIcon }
                                            alt="infoicon"
                                            data-for="infoIconNewRight"
                                            data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Brooke gets married, this figure includes her partner's income/expenses."
                                            />
                                            <ReactTooltip id="infoLeftIcon" place="left" type="info" effect="solid" className={ classes.tooltipInfo } />
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageFifteen } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Calvin
                                            <span>Master Electrician</span>
                                        </h3>
                                        <span>$710,218</span>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageSixteen } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Robert
                                            <span>Car Salesman</span>
                                        </h3>
                                        <span>$635,997</span>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageSeventeen } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Mike
                                            <span>Registered Architect</span>
                                        </h3>
                                        <span>$315,148</span>
                                        </div>
                                    </div>
                                    </li>

                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageEighteen } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Mike
                                            <span>Registered Architect</span>
                                        </h3>
                                        <span>$315,148</span>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageNineteen } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Mike
                                            <span>Registered Architect</span>
                                        </h3>
                                        <span>$315,148</span>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageTwenty } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Taylor
                                            <span>Dairy Farmer</span>
                                        </h3>
                                        <span>$664,239</span>
                                        <div className={ classes.infoIcon }>
                                            <img
                                            src={ infoIcon }
                                            alt="infoicon"
                                            data-for="infoIconNew"
                                            data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Taylor gets married, this figure includes her partner's income/expenses."
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageTwentyone } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Michelle
                                            <span>Financial Advisor</span>
                                        </h3>
                                        <span>$407,371</span>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageTwentyTwo } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            David
                                            <span>Navy Physician</span>
                                        </h3>
                                        <span>$247,410</span>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageTwentyThree } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Laura
                                            <span>Paramedic</span>
                                        </h3>
                                        <span>$262,693</span>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageTwentyFour } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Frank
                                            <span>Police Officer</span>
                                        </h3>
                                        <span>$764,152</span>
                                        <div className={ classes.infoIcon }>
                                            <img
                                            src={ infoIcon }
                                            alt="infoicon"
                                            data-for="infoIconNew"
                                            data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Frank gets married, this figure includes his partner's income/expenses."
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageTwentyFive } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Katrina
                                            <span>Graphic Designer</span>
                                        </h3>
                                        <span>$348,831</span>
                                        <div className={ classes.infoIcon }>
                                            <img
                                            src={ infoIcon }
                                            alt="infoicon"
                                            data-for="infoIconNew"
                                            data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Katrina gets married, this figure includes her partner's income/expenses."
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageTwentySix } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Jay
                                            <span>Construction Manager</span>
                                        </h3>
                                        <span>$350,740</span>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageTwentySeven } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Amber
                                            <span>Cosmetologist</span>
                                        </h3>
                                        <span>$238,354</span>
                                        </div>
                                    </div>
                                    </li>
                                    <li onClick = {handleCompletedFirstActivity}>
                                    <figure>
                                        <img src={ CareerImageTwentyEight } alt="" />
                                    </figure>
                                    <div className={ classes.careerCaption }>
                                        <div>
                                        <h3>
                                            Tim
                                            <span>Mechanical Engineer</span>
                                        </h3>
                                        <span>$363,497</span>
                                        </div>
                                    </div>
                                    </li>
                                </ul>
                                <div className={ classes.careerMiddleSecond }>
                                    <div className={ classes.orderListData }>
                                        <h3>Explore Inkwiry&apos;s Career Sketches</h3>
                                        <ol>
                                            <li>Watch the short &apos;SEE IT IN ACTION&apos; video</li>
                                            <li>Click on a Career Sketch that interests you</li>
                                            <li>Explore both sides of the Career Sketch card</li>
                                            <li>Interested in a sketch? Read the full story to learn more.</li>
                                        </ol>
                                    <div>
                                    <span role="button" className={ classes.seeButton }  aria-hidden="true" onClick={() => handleWatchIntro()}>SEE IT IN ACTION</span>
                                    </div>
                                    </div>
                                </div>
                                    <ReactTooltip id="infoIconNew" backgroundColor="#0069aa" place="right" type="info" effect="solid" className={ classes.tooltipInfoNew } />
                                    <ReactTooltip id="infoIconNewRight" backgroundColor="#0069aa" place="left" type="info" effect="solid" className={ classes.tooltipInfoNew } />
                                </div>
                                <div className={`${classes.popupContent} ${watchIntro && `${classes.watchIntroPopup}`}`}>
                                    <div className={classes.modalVideoInner}>
                                        <button type="button" onClick={handleWatchIntroClose} className={classes.closeIcon}><i className="la la-close" /></button>
                                        {watchIntro && <YouTube videoId="V6nRimAMD9c" opts={youtubeOpts} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {errorMessage && <span>{errorMessage}</span>}
                    <div className={classes.startButton}>
                        <Link to="/my-career-sketch/level1">
                            <button type="button">Back To Level 01</button>
                        </Link>
                    </div>
                </div>
                <div className={classes.mcsFooter}>
                    <div className={classes.container}>
                        <div className={classes.footerRow}>
                            <div className={classes.copyRight}>
                                <p>Copyright © 2022 // All rights reserved</p>
                            </div>
                            <div className={classNames(classes.copyRight, classes.informedText)}>
                                <p>
                                    Informed and Powered by
                                    <a href="/"><img src={logoInkwiry} alt="inkwiry" /></a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.mcsFluid}>
                        <p className={classes.termLinks}>
                            <a href="/">Terms of Service</a>
                            &nbsp;//&nbsp;
                            <a href="/">Privacy Policy</a>
                            &nbsp;//&nbsp;
                            <a href="/">Security</a>
                        </p>
                    </div>
                </div>
            </div>
        </Typography>
    );
};

McsLevelOneActivityOne.propTypes = {
    classes: PropTypes.object.isRequired,
};
const enhance = compose(
    withStyles(styles),
    withRouter,
);

export default enhance(McsLevelOneActivityOne);