import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const GapYear = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Gap Year</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/gap-year.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Gap Year
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/gap-year-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          About one in five students is unsure of plans to
                          re-enroll or has decided not to go to college this
                          fall, according to a{' '}
                          <a
                            href="https://www.acenet.edu/News-Room/Pages/AACRAO-ACE-Survey-Finds-Uncertainty-About-Current-College-Student-Fall-Enrollment-Plans-Optimism.aspx"
                            target="_blank"
                            rel="noopener noreferrer">
                            national survey
                          </a>{' '}
                          commissioned by the American Council on Education and
                          the American Association of Collegiate Registrars and
                          Admissions Officers.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          But how will postponing education affect the financial
                          future of those students who choose to take time off?
                        </p>
                        <p>
                          Have you ever considered taking a gap year? Have you
                          ever talked to someone who has taken a gap year? What
                          is a gap year anyways?
                        </p>
                        <p className={classes.noBottomMargin}>
                          A gap year could be one of the following:
                        </p>
                        <ul className={classes.noTopMargin}>
                          <li>Taking a year off before higher education</li>
                          <li>
                            Taking a year off and then returning to higher
                            education, or
                          </li>
                          <li>
                            Taking a year off after college before starting
                            graduate school.
                          </li>
                        </ul>
                        <p className={classes.noBottomMargin}>
                          What are some of the benefits of a gap year?
                        </p>
                        <ul className={classes.noTopMargin}>
                          <li>
                            Increase a student&apos;s higher education readiness
                            and maturity
                          </li>
                          <li>
                            Provide the opportunity to earn income, save and
                            invest for higher education
                          </li>
                          <li>
                            Build a resume with work, volunteer, or other
                            experience
                          </li>
                          <li>
                            Provide more time to refine higher education and
                            career choices.
                          </li>
                        </ul>
                        <p className={classes.noBottomMargin}>
                          Some of the cons of a gap year?
                        </p>
                        <ul className={classes.noTopMargin}>
                          <li>
                            Start higher education a year later, which means
                            graduate a year later
                          </li>
                          <li>
                            Work on higher education admissions while working
                          </li>
                          <li>
                            Might have to apply, receive admission to higher
                            education and then defer admission for a year
                          </li>
                          <li>
                            Fear of missing out (FOMO) as other friends start
                            higher education.
                          </li>
                        </ul>
                        <p>
                          A gap year has some great benefits and equally
                          challenging cons. On one hand, you can put money and
                          work experience in your pocket while on the other hand
                          you&apos;re delaying your education and ultimately
                          delaying your career path.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
GapYear.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GapYear);
