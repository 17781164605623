import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withRouter, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, TextField,
} from '@material-ui/core';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import styles from './styles';

const NetWorth = ( props ) => {
  const { classes } = props;

  const netWorthDefaultValues = {
    cash: 0,
    checkingAccount: 0,
    savingAccount: 0,
    retirementsAccount: 0,
    investmentsAccount: 0,
    automobiles: 0,
    realEstate: 0,
    businessOwnership: 0,
    others: 0,
    creditCardBalance: 0,
    personalLoans: 0,
    studentLoans: 0,
    autoLoans: 0,
    mortgages: 0,
    other_liabilities: 0,
    totalAssets: 0,
    totalLiabilities: 0,
    finalNetWorth: 0,
  };

  const [netWorthValues, setNetWorthValues] = useState( netWorthDefaultValues );
  const [calculatedValues, setCalculatedValues] = useState( {} );

  useEffect( () => {
    const netWorth = {};
    if ( typeof netWorthValues.cash !== 'undefined' && typeof netWorthValues.checkingAccount !== 'undefined' && typeof netWorthValues.savingAccount !== 'undefined' && typeof netWorthValues.retirementsAccount !== 'undefined' && typeof netWorthValues.investmentsAccount !== 'undefined' && typeof netWorthValues.automobiles !== 'undefined' && typeof netWorthValues.realEstate !== 'undefined' && typeof netWorthValues.businessOwnership !== 'undefined' && typeof netWorthValues.others !== 'undefined' ) {
      netWorth.totalAssets = parseInt( netWorthValues.cash, 10 )
                      + parseInt( netWorthValues.checkingAccount, 10 )
                      + parseInt( netWorthValues.savingAccount, 10 )
                      + parseInt( netWorthValues.retirementsAccount, 10 )
                      + parseInt( netWorthValues.investmentsAccount, 10 )
                      + parseInt( netWorthValues.automobiles, 10 )
                      + parseInt( netWorthValues.realEstate, 10 )
                      + parseInt( netWorthValues.businessOwnership, 10 )
                      + parseInt( netWorthValues.others, 10 );
    }

    if ( typeof netWorthValues.creditCardBalance !== 'undefined' && typeof netWorthValues.personalLoans !== 'undefined' && typeof netWorthValues.studentLoans !== 'undefined' && typeof netWorthValues.autoLoans !== 'undefined' && typeof netWorthValues.mortgages !== 'undefined' && typeof netWorthValues.other_liabilities !== 'undefined' ) {
      netWorth.totalLiabilities = parseInt( netWorthValues.creditCardBalance, 10 )
                      + parseInt( netWorthValues.personalLoans, 10 )
                      + parseInt( netWorthValues.studentLoans, 10 )
                      + parseInt( netWorthValues.autoLoans, 10 )
                      + parseInt( netWorthValues.mortgages, 10 )
                      + parseInt( netWorthValues.other_liabilities, 10 );
    }

    netWorth.finalNetWorth = parseInt( netWorth.totalAssets, 10 ) - parseInt( netWorth.totalLiabilities, 10 );

    setCalculatedValues( netWorth );
    // eslint-disable-next-line
  },[netWorthValues] );

  const updateValue = ( e, field ) => {
    let newValue = 0;
    const valuesUpdated = { ...netWorthValues };
    newValue = e.value ? e.value : 0;
    valuesUpdated[field] = newValue;
    setNetWorthValues( valuesUpdated );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.bondProfitPage }>
        <div className={ classes.formHorizontal }>
          <div className={ classes.container }>
            <ul className={ classes.breadCrumbs }>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>Net Worth</li>
            </ul>
            <div className={ classes.netWorthSection }>
              <div className={ classes.showMeContent }>
                <div className={ classes.showPortion }>
                  <div className={ classes.nwSection }>
                    <div className={ classes.nwSectionRow }>
                      <div className={ classes.netWorthContent }>
                        <ReactTooltip id="tooltip" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
                        <div className={ classes.tableRow }>
                          <table>
                            <tbody>
                              <tr>
                                <td>&nbsp;</td>
                                <td colSpan="2" className={ classes.textCenter }>
                                  {' '}
                                  <i>Net Worth</i>
                                  {' '}
                                </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td className={ classes.sectionTitle }> Assets </td>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>Cash</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.cash } onValueChange={ ( e ) => updateValue( e, 'cash' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>Checking Account</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.checkingAccount } onValueChange={ ( e ) => updateValue( e, 'checkingAccount' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>Savings Account</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.savingAccount } onValueChange={ ( e ) => updateValue( e, 'savingAccount' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className={ classes.infoIcon }>
                                    <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Include the value of the vested amount in your retirement accounts." />
                                  </div>
                                </td>
                                <td>Retirement Accounts</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.retirementsAccount } onValueChange={ ( e ) => updateValue( e, 'retirementsAccount' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className={ classes.infoIcon }>
                                    <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Include the value of Certificate of Deposits (CDs), stocks, bonds, and any other financial investments." />
                                  </div>
                                </td>
                                <td>Investment Accounts</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.investmentsAccount } onValueChange={ ( e ) => updateValue( e, 'investmentsAccount' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className={ classes.infoIcon }>
                                    <img src={ infoIcon } alt="" data-for="tooltip" data-tip="The value of an automobile is the market value (what that car could be sold for today) less the debt outstanding on that car. If you lease a vehicle, that is not an owned asset and should not be included." />
                                  </div>
                                </td>
                                <td>Automobiles</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.automobiles } onValueChange={ ( e ) => updateValue( e, 'automobiles' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className={ classes.infoIcon }>
                                    <img src={ infoIcon } alt="" data-for="tooltip" data-tip="The value of real estate is the market value (what that property could be sold for today) less the debt outstanding on that property." />
                                  </div>
                                </td>
                                <td>Real Estate</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.realEstate } onValueChange={ ( e ) => updateValue( e, 'realEstate' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className={ classes.infoIcon }>
                                    <img src={ infoIcon } alt="" data-for="tooltip" data-tip="When valuing the ownership in a business, it’s best to consult a valuation or tax advisor. The value of a business is the market value (what that business could be sold for today) less the debt outstanding that the business owes." />
                                  </div>
                                </td>
                                <td>Business Ownership</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.businessOwnership } onValueChange={ ( e ) => updateValue( e, 'businessOwnership' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>Other</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.others } onValueChange={ ( e ) => updateValue( e, 'others' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr className={ classes.totalAssets }>
                                <td>&nbsp;</td>
                                <td className={ classNames( classes.sectionTitle, classes.noUnderline ) }>Total Assets</td>
                                <td><div className={ classes.breakText }><NumberFormat allowNegative thousandSeparator value={ calculatedValues.totalAssets !== undefined ? calculatedValues.totalAssets : 0 } displayType="text" prefix="$" /></div></td>
                              </tr>
                              <tr className={ classes.emptyRow }>
                                <td colSpan="3">&nbsp;</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td className={ classes.sectionTitle }> Liabilities </td>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>Credit Cards Balance</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.creditCardBalance } onValueChange={ ( e ) => updateValue( e, 'creditCardBalance' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>Personal Loans</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.personalLoans } onValueChange={ ( e ) => updateValue( e, 'personalLoans' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>Student Loans</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.studentLoans } onValueChange={ ( e ) => updateValue( e, 'studentLoans' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>Auto Loans</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.autoLoans } onValueChange={ ( e ) => updateValue( e, 'autoLoans' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>Mortgages</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.mortgages } onValueChange={ ( e ) => updateValue( e, 'mortgages' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>Other</td>
                                <td>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ netWorthValues.other_liabilities } onValueChange={ ( e ) => updateValue( e, 'other_liabilities' ) } onFocus={ ( e ) => e.target.select() } />
                                </td>
                              </tr>
                              <tr className={ classes.totalAssets }>
                                <td>&nbsp;</td>
                                <td className={ classNames( classes.sectionTitle, classes.noUnderline ) }>Total Liabilities</td>
                                <td><NumberFormat allowNegative thousandSeparator value={ calculatedValues.totalLiabilities !== undefined ? calculatedValues.totalLiabilities : 0 } displayType="text" prefix="$" /></td>
                              </tr>
                              <tr className={ classes.emptyRow }>
                                <td colSpan="3">&nbsp;</td>
                              </tr>
                              <tr className={ classes.finalNetWorth }>
                                <td>&nbsp;</td>
                                <td>
                                  <b>Net Worth</b>
                                  {' '}
                                </td>
                                <td><b><td><NumberFormat allowNegative thousandSeparator value={ calculatedValues.finalNetWorth !== undefined ? calculatedValues.finalNetWorth : 0 } displayType="text" prefix="$" /></td></b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

NetWorth.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( NetWorth );
