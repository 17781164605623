import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruTen = (props) => {
    const {
        classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide, handleWalkThrusBack, walkThruNo

    } = props;

    return (
        <div className={classNames(classes.worksheetWalkthru, classes.worksheetActiveWalkthru, classes.walkthruTen)}>
            {walkThruNo === 1 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(10) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(10); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
             {walkThruNo === 2 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(11) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(11); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
             {walkThruNo === 3 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(11) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(11); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
             {walkThruNo === 4 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(10) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(10); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
             {walkThruNo === 5 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(10) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(10); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
             {walkThruNo === 6 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(10) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(10); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
             {walkThruNo === 7 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(10) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(10); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
             {walkThruNo === 8 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(11) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(11); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
             {walkThruNo === 9 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(10) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(10); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
             {walkThruNo === 10 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(10) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(10); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
             {walkThruNo === 11 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(10) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(10); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
             {walkThruNo === 12 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(10) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(10); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
             {walkThruNo === 13 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(9) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(9); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
             {walkThruNo === 14 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(10) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(10); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
             {walkThruNo === 15 ?
                <div>
                    <h4>Compare Saved Scenarios</h4>
                    <p>Once you’ve built a few scenarios, you can quickly compare scenarios side-by-side in the Compare Saved Scenarios tool. To save a comparison for later, use the 'SAVE' button. Then, you can come back to this section and use the ‘LOAD’ button to quickly access that saved comparison.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(11) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(11); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
        </div>
    );
};

WalkThruTen.propTypes = {
    classes: PropTypes.object.isRequired,
    handleWalkThrus: PropTypes.func.isRequired,
    handleWalkThruClose: PropTypes.func.isRequired,
    completedWalkThruSlides: PropTypes.object.isRequired,
    activeWalkThruSlide: PropTypes.string.isRequired,
    handleWalkThrusBack: PropTypes.func.isRequired,
    walkThruNo: PropTypes.string.isRequired,
};

export default withStyles(styles)(WalkThruTen);