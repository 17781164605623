import { PMT } from '../../utilities/commonFunctions';
// eslint-disable-next-line
  let summationData = {};

function reiMortgageSheetData( module1Data, module14Data ) {
  let mortgage2Data = {};
  let invPrepaidInterest = 0;
  let loanAmount = 0;
  // Identifier
  let identifier = 0;
  if ( module14Data.mortage !== undefined && module14Data.mortage > 0 ) {
    identifier = 1;
  } else {
    identifier = 0;
  }
  // Loan Amount
  if ( identifier === 1 && module14Data.mortage !== undefined && module14Data.mortage !== '' ) {
    loanAmount = module14Data.mortage;
  } else {
    loanAmount = 0;
  }

  // Terms in Years
  let termsOfYear = 0;
  if ( identifier === 1 && module14Data.term_of_mortgage !== undefined && module14Data.term_of_mortgage !== '' ) {
    termsOfYear = module14Data.term_of_mortgage;
  } else {
    termsOfYear = 0;
  }
  // Annual Interest Rate
  let annualInterestRate = 0;
  if ( identifier === 1 && module14Data.rate_of_mortgage_prcentage !== undefined && module14Data.rate_of_mortgage_prcentage !== '' ) {
    annualInterestRate = module14Data.rate_of_mortgage_prcentage;
  } else {
    annualInterestRate = 0;
  }
  // Original Payment
  let originalPayment = 0;
  if ( loanAmount > 0 && termsOfYear > 0 ) {
    originalPayment = PMT( ( parseFloat( annualInterestRate ) / 100 ) / 12, termsOfYear * 12, -( loanAmount ), 0, 0 );
  }
  // Total Interest Paid
  let totalInterestPaid = 0;

  // Total Paid
  let totalPaid = 0;

  // Tabular Data
  let startYear = 0;
  if ( module14Data.year_of_purchase !== undefined
                && module14Data.year_of_purchase !== '' ) {
    startYear = parseInt( module14Data.year_of_purchase, 10 );
  }
  if ( startYear > 0 ) {
    let year = parseInt( startYear, 10 );
    const yearLimit = year + parseFloat( termsOfYear );

    // Existing Student Loan Data
    mortgage2Data = {};
    mortgage2Data.Identifier = identifier;
    mortgage2Data['Loan Amount'] = loanAmount;
    mortgage2Data['Term in Years'] = termsOfYear;
    mortgage2Data['Annual Interest Rate'] = annualInterestRate;
    mortgage2Data['Original Payment'] = originalPayment;
    let Balance = loanAmount;

    const Payment = originalPayment;
    let Interest = 0;
    if ( annualInterestRate > 0 ) {
      Interest = Balance * ( ( annualInterestRate / 100 ) / 12 );
    }
    let Principal = Payment - Interest;
    let Equity = Principal;
    let TotalInterest = Interest;
    const TotalPayment = Equity + TotalInterest;

    const totalInterestPaidArray = [];
    const totalPaidArray = [];

    // For Loop from graduation year to +10 years
    let k = 0;
    for ( year; year < yearLimit; year += 1 ) {
      mortgage2Data[year] = {};

      mortgage2Data[year].LaggingInterest = 0;
      mortgage2Data[year].LaggingPrincipal = 0;
      mortgage2Data[year]['Months Total Balance'] = 0;

      // For Loop for 12 months
      for ( let month = 1; month <= 12; month += 1 ) {
        mortgage2Data[year][month] = {};
        if ( year === startYear && month === 1 ) {
          mortgage2Data[year][month].YearIdentifier = year;
          mortgage2Data[year][month].Balance = Balance;
          mortgage2Data[year][month].Payment = Payment;
          mortgage2Data[year][month].Interest = Interest;
          mortgage2Data[year][month].Principal = Principal;
          mortgage2Data[year][month].Equity = Equity;
          mortgage2Data[year][month].TotalInterest = TotalInterest;
          mortgage2Data[year][month].TotalPayment = TotalPayment;
        } else {
          // Year Identifier
          mortgage2Data[year][month].YearIdentifier = year;

          // Balance
          let tempBalance;
          if ( Balance === 0 ) {
            tempBalance = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempBalance = ( Balance - Principal );
          }
          mortgage2Data[year][month].Balance = tempBalance;

          // Payment
          mortgage2Data[year][month].Payment = Payment;

          // Interest
          let tempInterest;
          if ( Balance === 0 ) {
            tempInterest = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempInterest = tempBalance * ( ( annualInterestRate / 100 ) / 12 );
          }
          mortgage2Data[year][month].Interest = tempInterest;

          // Principal
          let tempPrincipal;
          if ( Balance === 0 ) {
            tempPrincipal = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempPrincipal = Payment - tempInterest;
          }
          mortgage2Data[year][month].Principal = tempPrincipal;

          // Equity
          let tempEquity;
          if ( Balance === 0 ) {
            tempEquity = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempEquity = Equity + tempPrincipal;
          }
          mortgage2Data[year][month].Equity = tempEquity;

          // Total Interest
          let tempTotalInterest;
          if ( Balance === 0 ) {
            tempTotalInterest = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempTotalInterest = TotalInterest + tempInterest;
          }
          mortgage2Data[year][month].TotalInterest = tempTotalInterest;

          // Total Payments
          let tempTotalPayment;
          if ( Balance === 0 ) {
            tempTotalPayment = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempTotalPayment = tempEquity + tempTotalInterest;
          }
          mortgage2Data[year][month].TotalPayment = tempTotalPayment;

          // Assign Values
          Balance = tempBalance;
          Principal = tempPrincipal;
          Equity = tempEquity;
          TotalInterest = tempTotalInterest;
        }

        // Total Interest Paid Array
        totalInterestPaidArray.push( mortgage2Data[year][month].TotalInterest );

        // Total Paid Array
        totalPaidArray.push( mortgage2Data[year][month].TotalPayment );

        mortgage2Data[year].LaggingInterest = parseFloat( mortgage2Data[year].LaggingInterest ) + parseFloat( mortgage2Data[year][month].Interest );
        mortgage2Data[year].LaggingPrincipal = parseFloat( mortgage2Data[year].LaggingPrincipal ) + parseFloat( mortgage2Data[year][month].Principal );
        mortgage2Data[year]['Months Total Balance'] = parseFloat( mortgage2Data[year]['Months Total Balance'] ) + parseFloat( mortgage2Data[year][month].Balance );
      }
      if ( k === 0 ) {
        invPrepaidInterest = parseFloat( mortgage2Data[year][1].Interest );
      }
      k += 1;
    }

    // Total Interest Paid
    if ( totalInterestPaidArray.length > 0 ) {
      totalInterestPaid = Math.max.apply( null, totalInterestPaidArray );
    }

    // Total Paid
    if ( totalPaidArray.length > 0 ) {
      totalPaid = Math.max.apply( null, totalPaidArray );
    }
  } else {
    mortgage2Data = {};
    mortgage2Data.Identifier = identifier;
    mortgage2Data['Loan Amount'] = loanAmount;
    mortgage2Data['Term in Years'] = termsOfYear;
    mortgage2Data['Annual Interest Rate'] = annualInterestRate;
    mortgage2Data['Original Payment'] = originalPayment;
  }

  // Summation Data
  if ( module1Data.start_year !== undefined
                && module1Data.start_year !== '' && parseInt( module1Data.start_year, 10 ) > 0 ) {
    let summationYear = parseInt( module1Data.start_year, 10 );
    const summationYearLimit = summationYear + 30;

    // Summation Data
    summationData = {};

    let summationBeginningBalance = 0;
    let summationLaggingInterest = 0;
    let summationLaggingPrincipal = 0;
    let summationEndingBalance = 0;

    // For Loop from Start year to +14 years
    let p = 0;
    for ( summationYear; summationYear <= summationYearLimit; summationYear += 1 ) {
      summationData[summationYear] = {};

      let yearwithTerm = 0;
      if ( module14Data.year_of_purchase !== undefined && module14Data.year_of_purchase !== ''
                         && module14Data.term_of_mortgage !== undefined && module14Data.term_of_mortgage !== '' ) {
        yearwithTerm = parseFloat( module14Data.year_of_purchase )
                        + parseFloat( module14Data.term_of_mortgage );
      }

      // Beginning Balance
      summationBeginningBalance = 0;
      if ( module14Data.year_of_sale !== undefined && module14Data.year_of_sale !== '' ) {
        if ( summationYear <= module14Data.year_of_sale ) {
          if ( module14Data.year_of_purchase !== undefined && module14Data.year_of_purchase !== '' ) {
            if ( ( p === 0 && summationYear < module14Data.year_of_purchase ) || ( p > 0 && summationYear < module14Data.year_of_purchase ) ) {
              summationBeginningBalance = 0;
            } else if ( summationYear >= module14Data.year_of_purchase ) {
              if ( summationYear <= yearwithTerm ) {
                if ( mortgage2Data !== undefined
                                                 && mortgage2Data[summationYear] !== undefined
                                                 && mortgage2Data[summationYear][1].Balance !== undefined ) {
                  summationBeginningBalance = mortgage2Data[summationYear][1].Balance;
                }
              }
            }
          }
        }
      }
      summationData[summationYear].BeginningBalance = summationBeginningBalance;
      p += 1;

      // Lagging 12Mo. Interest
      summationLaggingInterest = 0;
      if ( module14Data.year_of_sale !== undefined && module14Data.year_of_sale !== ''
                        && summationYear <= module14Data.year_of_sale
                        && summationData[summationYear].BeginningBalance > 1 ) {
        if ( mortgage2Data[summationYear] !== undefined && mortgage2Data[summationYear].LaggingInterest !== undefined ) {
          summationLaggingInterest = mortgage2Data[summationYear].LaggingInterest;
        }
      }
      if ( summationYear === module14Data.year_of_purchase ) {
        summationLaggingInterest -= invPrepaidInterest;
      }
      summationData[summationYear].LaggingInterest = summationLaggingInterest;

      // Total Interest
      summationData[summationYear].TotalInterest = summationLaggingInterest;

      // Lagging 12Mo. Principal
      summationLaggingPrincipal = 0;
      if ( module14Data.year_of_sale !== undefined && module14Data.year_of_sale !== ''
                        && summationYear <= module14Data.year_of_sale
                        && summationData[summationYear].BeginningBalance > 1 ) {
        if ( mortgage2Data[summationYear] !== undefined && mortgage2Data[summationYear].LaggingPrincipal !== undefined ) {
          summationLaggingPrincipal = mortgage2Data[summationYear].LaggingPrincipal;
        }
      }
      summationData[summationYear].LaggingPrincipal = summationLaggingPrincipal;

      // Total Principal
      summationData[summationYear].TotalPrincipal = summationLaggingPrincipal;

      // Ending Balance
      if ( module14Data.year_of_sale !== undefined && module14Data.year_of_sale !== ''
                        && summationYear <= module14Data.year_of_sale ) {
        summationEndingBalance = summationData[summationYear].BeginningBalance - summationData[summationYear].LaggingPrincipal;
      } else {
        summationEndingBalance = 0;
      }
      summationData[summationYear].EndingBalance = summationEndingBalance;
    }
  }
  // To return Car Loan Complete Data
  function mortgage2CompleteData() {
    const mortgageTwoFullData = {};
    mortgageTwoFullData.Mortage2 = mortgage2Data;
    mortgageTwoFullData['Summation Data'] = summationData;
    mortgageTwoFullData['Inv Prepaid Interest'] = parseFloat( invPrepaidInterest );
    mortgageTwoFullData['Total Paid'] = totalPaid;
    mortgageTwoFullData['Total Interest Paid'] = totalInterestPaid;
    mortgageTwoFullData.loanAmount = loanAmount;
    mortgageTwoFullData['Annual Interest Rate'] = annualInterestRate;
    mortgageTwoFullData['Original Payment'] = originalPayment;
	return mortgageTwoFullData;
  }
  return mortgage2CompleteData();
}
export default reiMortgageSheetData;
