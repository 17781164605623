import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import * as commonFunctions from '../../../utilities/commonFunctions';
import styles from '../../../screens/pwi-career-advancement/styles';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../../calculations/modules-services';
import CareerBottomTabs from './career-bottom-tab-graphs';

const arrayValuesMatching = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 4,
    scaledValue: 4,
    label: '4%',
  },
  {
    value: 8,
    scaledValue: 8,
    label: '8%',
  },
  {
    value: 12,
    scaledValue: 12,
    label: '12%',
  },
  {
    value: 16,
    scaledValue: 16,
    label: '16%',
  },
  {
    value: 20,
    scaledValue: 20,
    label: '20%',
  },
];
const HSAContributions = ( props ) => {
  const {
    classes, handleCareerObject, handleDefinationPopup, handleUpdatedObject,
  } = props;
  const [beginingBalannce, setBeginingBal] = React.useState( 0 );
  // const [annualExpected, setAnnualExpected] = React.useState( 5.00 );
  // const [iconStatus, setIconStatus] = React.useState( false );
  const [isFocus, setIsFocus] = React.useState('');

  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.hsa_personal_contribution !== undefined ) {
      setBeginingBal( handleCareerObject.hsa_personal_contribution );
    } else {
      updatedValues['hsa_personal_contribution'] = 0;
    }
    handleUpdatedObject(updatedValues);
    // eslint-disable-next-line
    },[]);

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'number' ) {
      newvalue = e.floatValue > 100 ?  100  : e.floatValue;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
    }

    setBeginingBal( newvalue );
    updatedValues[field] =newvalue;
    handleUpdatedObject(updatedValues);
  };

  const emptyIsFocus = () => {
    setIsFocus('')
  };

  return (
    <div className={ classes.contentBlock }>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />

      <p>
        A Health Savings Account, or HSA for short, allows you to set aside pre-tax money to pay for qualified medical expenses in the future. Qualified expenses typically include paying your deductible, copayments, and coinsurance. HSA funds typically cannot be used to pay your monthly premiums.
      </p>

      <p>HSAs have an advantage over a 401(k). When you withdraw your 401(k) money in retirement, you pay taxes on those withdrawals even if they're used to pay medical bills. Withdrawals from an HSA used to pay a medical bill are tax free. The money you put in was never taxed and the money you took out was never taxed.</p>
      <p>Check with your employer or your health insurance provider to see if you have access to an HSA. Do you plan on contributing to an HSA? If so, how much?</p>
      <div>
        <div className={ classes.AnnualIncomeGrowth }>
          <div>
            <p><span className={ classes.noDottedText }>Personal Contribution as a % of Base Income</span></p>
            <div className={ classes.annuvalIncomeGrowthGraph }>
              <PwiSlider
                ValueLabelComponent={ ValueLabelComponent }
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay="auto"
                marks={ arrayValuesMatching }
                min={ 0 }
                max={ 20 }
                step={ 1 }
                value={ beginingBalannce }
                valueLabelFormat={ `${commonFunctions.perFormatter(
                  beginingBalannce,
                )}` }
                onChange={ ( e, value ) => updateValue( e, value, 'hsa_personal_contribution', 'slider' ) }
              />
            </div>
            <div className={ classes.annuvalIncomeGrowthInput }>
              <NumberFormat
                customInput={ TextField }
                thousandSeparator
                value={ beginingBalannce }
                decimalScale = { isFocus!=='hsa_personal_contribution'?2:5 }
                fixedDecimalScale={isFocus!=='hsa_personal_contribution'}
                onValueChange={ ( e ) => updateValue( e, '', 'hsa_personal_contribution', 'number' ) }
                onFocus={ ( e ) => {
                  e.target.select();
                  setIsFocus('hsa_personal_contribution')
                } }
                onBlur={emptyIsFocus}
              />
              %
            </div>
          </div>
        </div>
      </div>
      <div className={ classes.retirementBottomPage }>
      <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockNew}` }>
        <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
          &nbsp;
        </div>
          <div className={ `${classes.annuvalIncomeTittle} ${classes.annuvalIncomeTittleSpace} ${classes.annuvalIncomeTittleSpaceXtra} ${classes.annuvalIncomeTittleFull}` }>
            {' '}
            <span>Monthly</span>
            {' '}
          </div><div className={ `${classes.annuvalIncomeTittle} ${classes.annuvalIncomeTittleSpace} ${classes.annuvalIncomeTittleSpaceXtra} ${classes.annuvalIncomeTittleFull}` }>
            {' '}
            <span>Annual</span>
            {' '}
          </div>
        </div>
        <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockNew}` }>
          <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
            <p className={ classes.textRight }>
              <span
                className={ classes.dottedText }
                aria-hidden="true"
                data-for="definationTitle"
                data-tip="Click for a definition."
                onClick={ () => handleDefinationPopup(
                  'Pension',
                ) }
              >
                Total HSA Contributions
                <br />
                (
                {moduleServices.module17Data.maxHsaContributionByLawFurtherCareer !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module17Data.maxHsaContributionByLawFurtherCareer } prefix={ moduleServices.module17Data.maxHsaContributionByLawFurtherCareer >= 0 ? '$' : '($' } suffix={ moduleServices.module17Data.maxHsaContributionByLawFurtherCareer < 0 && ')' } />
                ) : ( '$0' )}
                {' '}
                max contribution per year by law)
              </span>
            </p>
          </div>
          <div className={ classes.livingExpencesMothly }>
            <div className={ classes.annualInput }>
              <span>
                {moduleServices.module17Data.furtherCareerSEPIRAPersonalContributionCalcsNew !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module17Data.furtherCareerSEPIRAPersonalContributionCalcsNew / 12 } prefix={ moduleServices.module17Data.furtherCareerSEPIRAPersonalContributionCalcsNew >= 0 ? '$' : '($' } suffix={ moduleServices.module17Data.furtherCareerSEPIRAPersonalContributionCalcsNew < 0 && ')' } />
                ) : ( '$0' )}
              </span>
            </div>
          </div>
          <div className={ `${classes.livingExpencesMothly} ${classes.livingExpencesMothlyNoBefore}` }>
            <div className={ classes.annualInput }>

              <span>
                {moduleServices.module17Data.furtherCareerSEPIRAPersonalContributionCalcsNew !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module17Data.furtherCareerSEPIRAPersonalContributionCalcsNew } prefix={ moduleServices.module17Data.furtherCareerSEPIRAPersonalContributionCalcsNew >= 0 ? '$' : '($' } suffix={ moduleServices.module17Data.furtherCareerSEPIRAPersonalContributionCalcsNew < 0 && ')' } />
                ) : ( '$0' )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <CareerBottomTabs handleCareerObject={ handleCareerObject } />
    </div>

  );
};

HSAContributions.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  // setPensionConfirmation: PropTypes.func.isRequired,
  // pensionConfirmation: PropTypes.bool.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( HSAContributions );
