import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import styles from './styles';
import { Draggable, Droppable } from 'react-drag-and-drop';
import { Grid } from "@material-ui/core";
import Highcharts from 'highcharts';
import { loader } from 'graphql.macro';
import HighchartsReact from 'highcharts-react-official';
import {useLazyQuery} from '@apollo/react-hooks';
const INCOME_GROWTH_GRAPH_DATA = loader('../../../../graphql/schema/financial-independence/getIncomeGrowthGraphData.graphql')


const dropTexts = ['The amount of money you would pay in a year before your health insurance provider steps in and covers your expenses.','A fixed amount that you pay for a regular checkup, other common doctor visits, filling a prescription or seeing a specialist.', 'After you pay your deductible for the year, this percentage determines how much of your additional medical bills you share with your insurance provider.'];

const BitCoinPopups = (props) => {
  const { classes, challengeIndex, level, setChallengeAnswer, challengeAnswer, btnDisabled, setSelectedAnswer, selectedAnswer, setDraggedKeys, draggedKeys } = props;
  const dropZoneDetails = [
    {
      class: `${classes.risklist} ${classes.risklist1}`, text: 'Copayment', dropText: '', answer: '2', correct: false,
    },
    {
      class: `${classes.risklist} ${classes.risklist2}`, text: 'Coinsurance', dropText: '', answer: '3', correct: false,
    },
    {
      class: `${classes.risklist} ${classes.risklist3}`, text: 'Deductible', dropText: '', answer: '1', correct: false,
    },
  ];


  const [dropZoneData, setDropZoneData] = React.useState(dropZoneDetails);
  const [graphData, setGraphData] = React.useState([]);

  useEffect(()=>{
    if(level ===8){
      if(challengeIndex === 1){
        setSelectedAnswer('4');
      }else if(challengeIndex === 2){
        setSelectedAnswer('1');
      }else if(challengeIndex === 3){
        setSelectedAnswer('1');
      }
    }
    
  },[challengeIndex]);

  const [getGraphData] = useLazyQuery(INCOME_GROWTH_GRAPH_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      from: "stocks_bonds"
    },
    onCompleted(response) {
      if(response && response.getIncomeGrowthGraphData) {
        setGraphData(response.getIncomeGrowthGraphData.data[0]);
      }
    },
    onError() {
    },
  });

  useEffect(()=>{
    getGraphData();
  },[])

  const answerQuestion = (val) => {
    setChallengeAnswer(val.target.value);
  }

  const onDrop = (data, key) => {
    const dropText = dropTexts[parseInt(data.appropriate, 10) - 1];
    const droppableData = [...dropZoneData];
    const draggableKeys = [...draggedKeys]
    droppableData[key].dropText = dropText;
    if (droppableData[key].answer === data.appropriate) {
      droppableData[key].correct = true;
    } else {
      droppableData[key].correct = false;
    }
    draggableKeys[key] = droppableData[key].correct;
    setDraggedKeys(draggableKeys);
    setDropZoneData(droppableData);
  };

  return (
    <Typography variant="body1" component="div">
      { /* bitcoin XP questions  Popup */}
      {challengeIndex === 1 && (
        <div>
       
            <div className={classes.completePopupChallenge}>
              <h1>POWERUP CHALLENGE EXPLANATION</h1>
              <p className={classes.popupPowerupPara}>Mary just graduated from college and is saving and investing $500 per month in her first career move. In three years, she wants to go to graduate school and earn her master’s degree in supply chain management. She is looking to earn a return on her money while taking a moderate level of risk. What investment would you recommend to Mary to achieve her goals?</p>
              <ol className={classNames(classes.bitUiList, classes.bitUiListPopupEight)}>
                <li>Traditional Savings Account</li>
                <li>Stock ETFs</li>
                <li>Certificate of Deposit</li>
                <li>Bond ETFs</li>
              </ol>
              <div className={classNames(classes.assetsOptions, "assets-row")}>
                <ul className={classNames(classes.exerciseOptions, classes.exerciseOptionsPowerup)}>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="1" id="firstOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '1' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '1' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="firstOption">
                      1 and 3
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="2" id="secondOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, (challengeAnswer === '2' && challengeAnswer === '' && classes.ageChecked), (challengeAnswer === '2' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="secondOption">
                      2 and 4
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="3" disabled={btnDisabled} id="thirdOption" onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '3' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '3' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="thirdOption">
                      1 and 2
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="4" disabled={btnDisabled} id="fourthOption" onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '4' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '4' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="fourthOption">
                      1 and 4
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
         
        </div>
      )}


      {challengeIndex === 2 && (
        <div>
      
            <div className={classes.completePopupChallenge}>
              <h1>POWERUP CHALLENGE EXPLANATION</h1>
              <p className={classes.popupPowerupPara}>Understanding the key terms with health insurance is essential to selecting the right health insurance for you. Drag and drop the definitions on the right to the key terms below. Then, click Check Answer when you believe you have the right answers.</p>
            </div>
         
          <div className={classNames(classes.riskLadder, classes.riskLadderDragRow)}>
            <div className={classes.listedInvestments}>
              <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <div className={classNames(classes.listedInvestmentsLeft, classes.listedInvestmentsLeftNew)}>
                    <ul className={classes.riskList} >
                      {dropZoneData.map((data, index) => (
                        <div className={classes.dragDiv}>
                          <label>{data.text}</label>
                          {data.correct === true && data.dropText !== '' && btnDisabled && (<span className={classes.greenCheck}><i className="fa fa-check" /></span>)}
                              {data.correct === false && data.dropText !== '' && btnDisabled && (<span className={classes.redCross}><i className="fa fa-times" /></span>)}
                          <li>
                            <Droppable
                              types={['appropriate']}
                              onDrop={(e) => { onDrop(e, index); }}
                            >
                              <span className={classes.dragTextNew}>{data.dropText}</span>
                             
                            </Droppable>
                          </li>
                        </div>
                      ))}
                    </ul>
                  </div>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <ul className={classNames(classes.dragOptionDetails, classes.riskOptions, classes.riskOptionsNew, )}>
                    <Draggable type="appropriate" data="1"><li className={classes.listBonds}>The amount of money you would pay in a year before your health insurance provider steps in and covers your expenses.</li></Draggable>
                    <Draggable type="appropriate" data="2"><li className={classes.fundsList}>A fixed amount that you pay for a regular checkup, other common doctor visits, filling a prescription or seeing a specialist.</li></Draggable>
                    <Draggable type="appropriate" data="3"><li className={classes.stockList}>After you pay your deductible for the year, this percentage determines how much of your additional medical bills you share with your insurance provider.</li></Draggable>
                   
                  </ul>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      )}
      {challengeIndex === 3 && (
        <div>
         
            <div className={classes.completePopupChallenge}>
              <h1>POWERUP CHALLENGE EXPLANATION</h1>
              <p className={classes.popupPowerupPara}>Mary is looking at a graph that displays the returns of two different investments over the past 100 years. She’s trying to determine which one is the historical return of stocks and which one is the historical return of bonds. Using the context clues of the graph, which line is which?</p>
               
             
              <div className={classNames(classes.assetsOptions, "assets-row")}>
                <div className={classes.assetsOptionsGraph}>
                  <HighchartsReact
                      highcharts={ Highcharts }
                      options={ {
                        chart: {
                          type: 'spline',
                          scrollablePlotArea: {
                            scrollPositionX: 0
                          }
                        },
                        credits: {
                          enabled: false
                        },
                        title: {
                          text:  'Stocks v Bonds: Annual Returns',
                    style: {
                            color: '#000'
                          }
                        },
                  exporting: {
                    enabled: false
                    },
                        xAxis: [{
                          tickInterval: 5,
                          categories: graphData.dates,
                          labels: {
                            formatter: function () {
                              var date = new Date(this.value);
                              return date.getFullYear();
                            },
                            style: {
                              color: '#000',
                              fontSize: '13px'
                            }
                          }
                        }],
                        yAxis: [{ // Primary yAxis
                          labels: {
                            format: '{value:.1f}%',
                            style: {
                              color: '#000',
                              fontSize: '14px'
                            }
                          },
                          title: {
                            text: '',
                            style: {
                              color: '#000',
                              fontSize: '14px'
                            }
                          }
                        }],
                        tooltip: {
                    enabled: true,
                          headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                          crosshairs: true,
                          shared: true,
                          valueDecimals: 1,
                          valueSuffix: '%',
                          style: {
                            color: '#000',
                            fontSize: '14px'
                          }
                        },
                        legend: {
                    enabled: true,
                        },
                        plotOptions: {
                          spline: {
                            lineWidth: 2,
                            states: {
                              hover: {
                                lineWidth: 2
                              }
                            },
                            marker: {
                              enabled: false
                            },
            
                          }
                        },
                        series: graphData.details
                      }
                    }
                    />
                </div>
              <div>
				<p className={classes.popupPowerupPara}>Source: <span className={classes.clickHere}>Aswath Damodaran, NYU Stern School of Business</span></p>
			</div>
              <ul className={classNames(classes.exerciseOptions, classes.exerciseOptionsPowerup)}>
              <li>
                <p>
                  <input type="radio" name="equation" value="1" id="firstOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                  <label className={classNames(classes.spanRadio, challengeAnswer === '1' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '1' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="firstOption">
                  The blue line is stocks and the orange line is bonds
                  </label>
                </p>
              </li>
              <li>
                <p>
                  <input type="radio" name="equation" value="2" id="secondOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                  <label className={classNames(classes.spanRadio, (challengeAnswer === '2' && challengeAnswer === '' && classes.ageChecked), (challengeAnswer === '2' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="secondOption">
                  The orange line is stocks and the blue line is bonds
                  </label>
                </p>
              </li>
            </ul>
              </div>

            </div>
         
        </div>
      )}
    </Typography>
  );
};

BitCoinPopups.propTypes = {
  classes: PropTypes.object.isRequired,
  level:PropTypes.number.isRequired,
  setChallengeAnswer:PropTypes.func.isRequired,
  challengeAnswer:PropTypes.string.isRequired,
  btnDisabled:PropTypes.bool.isRequired,
  setSelectedAnswer:PropTypes.func.isRequired,
  selectedAnswer:PropTypes.string.isRequired

};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(BitCoinPopups);
