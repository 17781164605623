import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-family/styles';
import financialConfiguratorFamilyPDF from '../../../assets/img/news/news_pdfs/Inkwiry_FC_and_Taxes_Sep_2019.pdf';

const FederalIncomeTax = (props) => {
  const { classes, handleDefinationPopup } = props;

  return (
    <div className={classes.contentBlock}>
      <p>
        Filing your taxes as an individual versus married may have some tax benefits. According to the &nbsp;
        <a rel="noopener noreferrer" href="https://www.irs.gov/" target="_blank">Internal Revenue Service (IRS)</a>
        , if you and your spouse file a joint return, your taxes may be lower than filing separately.
      </p>
      <p>
        When you get married, Inkwiry automatically assumes that you file a joint return. Look at the different income tax brackets and &nbsp;
        <span className={classes.dottedText} aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Standard Deduction: Taxes')}>standard deductions</span>
        {' '}
        for single and married below. Want to learn how your taxes are calculated? Download &nbsp;
        <a rel="noopener noreferrer" href={financialConfiguratorFamilyPDF} download target="_blank">Inkwiry&apos;s Financial Configurator and Taxes</a>
        {' '}
        whitepaper.
      </p>
      <div>
        <table className={classes.federalIncomeTable}>
          <thead>
            <tr className={classes.trHeader}>
              <th>&nbsp;</th>
              <th colSpan="4">
                Federal Income Tax Brackets
              </th>
              <th>Year 2019</th>
            </tr>
            <tr>
              {' '}
              <th colSpan="6">&nbsp;</th>
              {' '}
            </tr>
            <tr>
              {' '}
              <th colSpan="6" className={classes.standarddeduction}><span>Standard Deduction</span></th>
              {' '}
            </tr>
            <tr className={classes.singleValue}>
              {' '}
              <th colSpan="5">Single</th>
              {' '}
              <th className={classes.textCenter}>$12,200</th>
              {' '}
            </tr>
            <tr className={classes.singleValue}>
              {' '}
              <th colSpan="5">Married</th>
              {' '}
              <th className={classes.textCenter}>$24,400</th>
              {' '}
            </tr>
            <tr>
              {' '}
              <th colSpan="6">&nbsp;</th>
              {' '}
            </tr>
            <tr className={classes.incomeRanges}>
              <th>&nbsp;</th>
              <th>Rate</th>
              <th colSpan="2">Income Ranges (Single)</th>
              <th colSpan="2">Income Ranges (Married)</th>
            </tr>
          </thead>
          <tbody>
            <tr className={classes.topBorder}>
              <td>&nbsp;</td>
              <td>10.00%</td>
              <td>$0.00</td>
              <td>$9,700.00</td>
              <td>$0.00</td>
              <td>$19,400.00</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>12.00%</td>
              <td>$9,700.00</td>
              <td>$39,475.00</td>
              <td>$19,400.00</td>
              <td>$78,950.00</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>22.00%</td>
              <td>$39,475.00</td>
              <td>$84,200.00</td>
              <td>$78,950.00</td>
              <td>$168,400.00</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>24.00%</td>
              <td>$84,200.00</td>
              <td>$160,725.00</td>
              <td>$168,400.00</td>
              <td>$321,450.00</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>32.00%</td>
              <td>$160,725.00</td>
              <td>$204,100.00</td>
              <td>$321,450.00</td>
              <td>$408,200.00</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>35.00%</td>
              <td>$204,100.00</td>
              <td>$510,300.00</td>
              <td>$408,200.00</td>
              <td>$612,350.00</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>37.00%</td>
              <td>$510,300.00</td>
              <td>&nbsp;</td>
              <td>$612,350.00</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
    </div>
  );
};

FederalIncomeTax.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};
export default withStyles(styles)(FederalIncomeTax);
