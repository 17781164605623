const styles = ( ) => ( {
  completeActivity: {
    '& > div > div':{
      background: 'transparent',
    },
    '& > div > div > div': {
      background: '#004376',
      color: '#fff',
      padding: '15px 40px',
      boxSizing: 'border-box',
      border: '1px solid rgba(0,0,0,.2)',
      borderRadius: '6px',
      WebkitBoxShadow: '0 5px 15px rgb(0 0 0 / 50%)',
      boxShadow: '0 5px 15px rgb(0 0 0 / 50%)',
    },
  },
  completeActivityFive:{
    '& > div > div':{
       width: '1020px',
      maxWidth: '1020px',
      // width: '1020px',
      // maxWidth: '1020px',
      margin: '30px auto',
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
    '& > div > div > div > div': {
      padding: '30px 80px',
    }
  },
  completePopup: {
    padding: '15px 40px',
    position: 'relative',
  },
  canvasAnimation:{
    position: 'absolute',
    top: '25%',
    width: '100%',
    left: '0px',
    textAlign: 'center',
    '& canvas':{
      maxWidth: '500px',
    }
  },
  graphIcon: {
    width: '230px',
    marginRight: '20px',
    marginBottom: '10px',
    '& img': {
      width: '100%',
    },
  },
  xpIcon: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontSize: '31px',
      lineHeight: '1.2',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      fontWeight: '300',
      marginBottom: '10px',
      marginTop: '0',
    },
  },
  niceWork: {
    fontSize: '28px',
    padding: '20px 0px',
    fontWeight: '300',
    lineHeight: '30px',
    textAlign: 'center',
    margin: '0 auto 10px auto',
  },
  activityDesc: {
    fontSize: '22px',
    lineHeight: '30px',
    textAlign: 'center',
    marginBottom: '10px',
    marginTop: '0',
    '& span': {
      display: 'inline-block',
      width: '100%',
    },
  },
  footerButton: {
    textAlign: 'center',
    paddingTop: '30px',
    display: 'block',
    '& button': {
      backgroundColor: '#7aaa3c',
      borderColor: '#7aaa3c',
      textDecoration: 'none',
      padding: '10px 50px',
      fontSize: '20px',
      borderRadius: '4px',
      color: '#fff',
      textTransform: 'uppercase',
      minWidth: '64px',
      display: 'inline-block',
      border: '2px solid #7aaa3c',
      lineHeight: '30px',
      '&:hover': {
        backgroundColor: '#7aaa3c',
        border: '2px solid #d5d5d5',
      },
    },
  },
  rankIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    '& p': {
      fontSize: '31px',
      lineHeight: '1.2',
      textAlign: 'left',
      fontWeight: '300',
      marginBottom: '10px',
      marginTop: '0',
      '&:nth-child(2)': {
        paddingTop: '30px',
      },
    },
  },
  rankImg: {
    width: 'auto',
    marginRight: '20px',
    marginBottom: '10px',
    '& img': {
      width: '100%',
    },
  },
  completePopupLogo:{
    width: '300px',
    display: 'block',
    margin: '0 0 0 340px',
    '& img':{
      width: '100%',
      verticalAlign: 'middle',
    }
  },
  mr0:{
    margin: '0px',
  },
  mrAuto:{
    margin: '0px auto',
  },
  xpPowerupGraphic:{
    display: 'flex',
    marginTop: '60px',
  },
  xpPowerupGraphicLogo:{
    width: '260px',
    marginRight: '50px',
    '& img':{
      width: '100%',
    }
  },
  bitCodeContent:{
    width: 'calc(100% - 310px)',
    '& p':{
      fontSize: '20px',
      lineHeight: '30px',
      marginBottom: '20px',
      marginTop: '0px',
    }
  },
  modalFooter:{
    textAlign: 'right',
    padding: '15px',
    position: 'relative',
    '& button':{
      padding: '7px 42px',
      fontSize: '20px',
      minWidth: '64px',
      textTransform: 'uppercase',
      border: '2px solid #84a84d',
      borderRadius: '10px',
      '&:first-child':{
        color: '#84a84d',
        background: '#fff',
        marginRight: '20px',
      },
      '&:last-child':{
        backgroundColor: '#84a84d',
        border: '2px solid #84a84d',
        marginLeft: '5px',
        color: '#fff',
        '&:hover':{
          backgroundColor: '#7aaa3c',
          border: '2px solid #d5d5d5',
        }
      }
    }
  },
  completePopupChallenge:{
    '& h1':{
      color: '#fff',
      padding: '20px 0',
      fontSize: '36px',
      lineHeight: '1.2',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      fontWeight: '300',
      margin: '20px 0px 10px',
    },
    '& p':{
      textAlign: 'center',
      fontWeight: '300',
      fontSize: '22px',
      lineHeight: '30px',
      margin: '0px 0px 20px',
    }
  },
  completePopupSlider:{
    position: 'relative',
    marginBottom: '75px',
    marginTop: '50px',
    display: 'flex',
    alignItems: 'center',
  },
  sliderLeft:{
    minWidth: '150px',
    maxWidth: '150px',
    position: 'relative',
    marginRight: '20px',
    top: '-10px',
    '& p':{
      margin: '0px',
      fontSize: '20px',
      lineHeight: '30px',
      textAlign: 'left',
    }
  },
  sliderRight:{
    width: 'calc(100% - 170px)',
  },
  textRed:{
    color: 'red',
  },
  textGreen:{
    color: 'green',
  },
  xpIconPowerup:{
    '& p':{
      fontSize: '20px',
      lineHeight: '30px',
      marginBottom: '20px',
    }
  },
  modalFooterBtnRow:{
    padding: '0px 0px 20px',
    '& button':{
      minWidth: '250px',
      '&:first-child':{
        marginRight: '0px',
      }
    }
  },
  mt30:{
    marginTop: '30px',
  },
  modalFooterBtnRow2:{
    padding: '0px 0px 0px',
    '& button':{
      minWidth: '250px',
      '&:first-child':{
        marginRight: '0px',
      }
    }
  },
  pcQuestStatus:{
    color: '#fff',
    fontSize: '20px',
    fontWeight: '300',
    position: 'absolute',
    right: '280px',
    top: '10px',
  }
} );

export default styles;
