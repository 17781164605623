const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  budgetValuesPage: {
    background: '#e5f0f6',
    display: 'block',
  },
  breadCrumbs: {
    listStyle: 'none',
    display: 'inline-flex',
    padding: '20px 0',
    marginBottom: '0',
    width: '100%',
    fontFamily: 'MuseoSans-100',
    fontSize: '14px',
    marginTop: '0',
    lineHeight: '1.42857143',
    '& li': {
      color: theme.palette.common.pureBlack,
      '& a': {
        color: '#333',
        transition: 'all 0.5s ease 0s',
        mozTransition: 'all 0.5s ease 0s',
        webkitTransition: 'all 0.5s ease 0s',
        border: '0',
        outline: '0',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    '& span': {
      padding: '0 5px',
      fontSize: '18px',
      lineHeight: '18px',
      boxSizing: 'border-box',
    },
  },
  powerContent: {
    display: 'table',
    margin: '0 auto',
    clear: 'both',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
    }, 
  },
  mainRow: {
    margin: '0px -15px',
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0px',
    }, 
  },
  powerRight: {
    background: '#fff',
    textAlign: 'left',
  },
  wonderRight: {
    width: '100%',   
  },
  contentWon: {
    padding: '10px 30px',
    height: 'auto',
    background: '0 0',
    fontSize: '16px',
    [theme.breakpoints.down( 'xs' )]: {
     padding: '10px',
    }, 
  },
  budgetTable: {
    width: '100%',
    border: '2px solid #0069aa',
    marginBottom: '20px',
    margin: '0 auto',
    padding: '10px',
    marginTop: '15px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      overflow: 'auto',
      marginTop: '0px',
      marginBottom: '0px',
    }, 
    '& table': {
      width: '100%',
      borderSpacing: '0px',
      borderCollapse: 'collapse',
      border: '0',
      backgroundColor: 'transparent',
      lineHeight: '1.42857143',
      '& tr': {
        '& th': {
          padding: '2px 5px',
          textAlign: 'center',
          minWidth: '7.8vw',
          fontSize: '18px',
          fontWeight: 'bold',
          color: '#000',
          [theme.breakpoints.down( 'sm' )]: {           
            fontSize: '16px',
          }, 
          [theme.breakpoints.down( 'xs' )]: {
            padding: '5px 5px',
            fontSize: '14px',
          }, 
          '&:first-child': {
            textAlign: 'left',
          },
          '& u': {
            [theme.breakpoints.down( 'xs' )]: {
             whiteSpace: 'nowrap',
            }, 
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            padding: '2px 5px',
            textAlign: 'right',
            minWidth: '7.8vw',
            fontSize: '18px',
            color: '#000',
            lineHeight: '1.42857143',
            boxSizing: 'border-box',
            [theme.breakpoints.down( 'sm' )]: {           
              fontSize: '16px',
            },
            [theme.breakpoints.down( 'xs' )]: {
              padding: '5px 5px',
              fontSize: '14px',
            }, 
            '&:first-child': {
              paddingLeft: '1vw',
              textAlign: 'left',
            },
            '&:last-child': {
              textAlign: 'center',
              fontStyle: 'italic',
            },
            '& input': {
              width: '135px',
              boxSizing: 'border-box',
              textAlign: 'right',
              lineHeight: '1.42857143',
              fontFamily: 'inherit',
              color: '#f00',
              height: '31px',
              border: '1px solid #000',
              padding: '1px 2px',
              fontSize: '18px',
              [theme.breakpoints.down( 'sm' )]: {           
                width: '115px',
              },
              [theme.breakpoints.down( 'xs' )]: {
                width: '80px',
                fontSize: '15px',
              }, 
              '&:focus': {
                outline: 'auto',
                outlineColor: '#000',
              },
            },
            '& span': {
              display: 'inline-block',
              padding: '0 2px',
              fontSize: '18px',
              [theme.breakpoints.down( 'xs' )]: {              
                fontSize: '14px',
              }, 
            },
            '& div': {
              [theme.breakpoints.down( 'xs' )]: {              
                alignItems: 'center',
                verticalAlign: 'middle',
              }, 
              '&::before': {
                border: 'none !important',
              },
              '&::after': {
                border: 'none !important',
              },
            },
          },
        },
      },
    },
  },
  topBorderRow: {
    borderTop: '1px solid #333',
    '& td': {
      fontWeight: 'bold',
      padding: '2px 5px !important',
    },
    '& th': {
      fontWeight: 'bold',
    },
  },
  emptyRow: {
    height: '20px',
    [theme.breakpoints.down( 'xs' )]: {              
      height: '10px',
    }, 
  },
  totalBorder: {
    border: '1px solid #333',
    '& td': {
      fontWeight: 'bold',
      padding: '2px 5px !important',
    },
  },
  totalBorderRow: {
    '& td': {
      backgroundColor: ' #d1e4f0',
    },
  },
  spaceRow: {
    '& th': {
      paddingBottom: '1.5vw',
    },
  },
  showMeContent: {
    padding: '0 35px',
    background: '#fff',
    '@media (max-width: 767px)': {
     padding: '0 25px',
    },
    [theme.breakpoints.down( 'xs' )]: {              
      padding: '0 10px',
    }, 
  },
  showPortion: {
    padding: '20px 0',
    [theme.breakpoints.down( 'xs' )]: {              
      padding: '10px 0px',
    }, 
  },
  hiGraph: {
    border: '2px solid #2f75b5',
    margin: '20px 0',
    padding: '15px 0',
    [theme.breakpoints.down( 'xs' )]: {              
      margin: '0px 0',
    },
  },
  graphHead: {
    marginBottom: '0',
    color: '#000',
    fontWeight: '600',
    fontSize: '22px',
    textAlign: 'center',
    marginTop: '20px',
    lineHeight: '1.1',
    '& strong': {
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
  },
  blueLine: {
    margin: '10px',
    borderTop: '2px solid #2f75b5',
    boxSizing: 'content-box',
    height: '0px',
  },
  loanProfitCalculator: {
    marginTop: '22px',
    paddingBottom: '20px',
  },
  careerInputs: {
    position: 'relative',
    minHeight: '1px',
    paddingRight: '15px',
    paddingLeft: '15px',
  },
  cpBackground: {
    width: '100%',
    justifyContent: 'center',
    // marginLeft: '7%',
    display: 'flex',
    margin: '0 auto',
    boxSizing: 'border-box',
    '@media (max-width: 600px)': {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
  },
  cpInput: {
    width: '170px',
    marginRight: '39px',
    marginTop: '30px',
    marginBottom: '30px',
    margin: '30px 30px 30px 0px',
    '@media (max-width: 767px)': {
      width: '350px',
      marginRight: '10px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      marginTop: '0px',
      marginRight: '0px',
      marginBottom: '10px',
    },
    '@media screen and (min-width: 768px) and (max-width: 992px)': {
      width: '135px',
      marginRight: '20px',
    },  
    '& label': {
      fontSize: '21px',
      fontWeight: '500',
      display: 'inline-block',
      maxWidth: '100%',
      marginBottom: '5px',
      color: '#000',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        fontSize: '14px',
      },    
      '@media screen and (min-width: 768px) and (max-width: 992px)': {
        fontSize: '14px',
      },    
    },
    '& div': {
      '&::before': {
        border: 'none !important',
      },
      '&::after': {
        border: 'none !important',
      },
    },
  },
  inputGroup: {
    position: 'relative',
    display: 'table',
    borderCollapse: 'separate',
    '& input': {
      borderLeftWidth: '0px',
      height: 'auto',
      fontSize: '20px',
      zIndex: '0',
      display: 'table-cell',
      position: 'relative',
      float: 'left',
      width: '100%',
      marginBottom: '0',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      padding: '6px 12px',
      backgroundImage: 'none',
      lineHeight: '1.42857143',
      color: '#555',
      boxSizing: 'border-box',
      '&:focus': {
        outline: '0',
      },
    },
  },
  dollarSymbol: {
    padding: '6px 6px',
    backgroundColor: 'white',
    boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
    fontSize: '20px',
    paddingRight: '0px',
    fontWeight: '400',
    lineHeight: '1',
    color: '#555',
    textAlign: 'center',
    border: '1px solid #ccc',
    borderRadius: '4px',
    width: '1%',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    display: 'table-cell',
    borderRight: '0',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  inputGroupRight: {
    '& input': {
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
      borderLeftWidth: '1px',
      borderRightWidth: '0px',
      paddingLeft: '6px',
    },
    '& span': {
      borderLeft: '0',
      paddingRight: '6px',
    },
  },
  cpButton: {
    paddingLeft: '0px',
    '& button': {
      backgroundColor: '#fd9840',
      fontSize: '21px',
      color: theme.palette.common.white,
      borderRadius: '3px',
      lineHeight: '19px',
      fontFamily: "'MuseoSans-300'",
      borderColor: '#fd9840',
      boxShadow: 'none',
      marginTop: '64px',
      marginLeft: '0',
      width: '160px',
      height: '39px',
      padding: '8px 20px',
      borderWidth: '2px',
      backgroundImage: 'none',
      outline: '0',
      transition: 'all .5s ease 0s',
      WebkitTransition: 'all .5s ease 0s',
      display: 'inline-block',
      marginBottom: '0',
      fontWeight: '400',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      userSelect: 'none',
      border: '1px solid transparent',
      '@media (max-width: 600px)': {
        marginTop: '7px',
      },
      '@media screen and (min-width: 601px) and (max-width: 767px)': {
        paddingBottom: '30px',
        marginTop: '50px',
        padding: '8px 10px',
        fontSize: '14px',
        width: '100px',
        height: '40px',
      }, 
      '@media screen and (min-width: 768px) and (max-width: 992px)': {
        width: '130px',
        fontSize: '15px',
        marginTop: '50px',
      },  
      '&:hover': {
        backgroundColor: '#f28735',
        borderColor: '#f28735',
      },
    },
  },
  listItems: {
    margin: '37px 0 0 100px',
    '@media (max-width: 767px)': {
      margin: '37px 0 0 10px',
      width: '100%',
    },
    '@media screen and (min-width: 768px) and (max-width: 992px)': {
      margin: '37px 0 0 20px',
    },  
    '& ul': {
      width: '100%',
      padding: '0',
      display: 'inline-block',
      verticalAlign: 'top',
      margin: '0',
      '& li': {
        display: 'block',
        padding: '0 0 8px',
        margin: '0',
        fontSize: '16px',
        color: '#000',
        '& p': {
          fontFamily: '"MuseoSans-300"',
          fontSize: '18px',
          lineHeight: '18px',
          padding: '0',
          margin: '0',
          '& span': {
            display: 'inline-block',
            verticalAlign: 'middle',
            fontFamily: 'MuseoSans-500',
            fontSize: '12px',
            lineHeight: '12px',
            padding: '4px 13px',
            margin: '0 14px 0 0',
            background: '#ebebeb',
            borderRadius: '12px',
            minWidth: '72px',
            textAlign: 'center',
            boxSizing: 'border-box',
            '@media (max-width: 767px)': {
              padding: '4px 13px',             
              minWidth: '70px',
            },
          },
          '& font': {
            width: 'auto',
            fontSize: '20px',
            display: 'inline-block',
            verticalAlign: 'middle',
            '@media (max-width: 767px)': {              
              fontSize: '14px',              
            },
            '& span': {
              fontSize: '20px',
              background: 'transparent',
              '@media (max-width: 767px)': {              
                fontSize: '16px !important',              
              },
            },
          },
        },
      },
    },
  },
  inputGroupLeft: {
    '& input': {
      borderTopLeftRadius: ' 0',
      borderBottomLeftRadius: '0',
    },
  },
  percentSymbol: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0px',
    borderLeft: '0px',
  },
  lightText: {
    fontWeight: '400 !important',
  },
  italicLightText: {
    fontWeight: '300 !important',
  },
  bottomRowSpace: {
    '& td': {
      paddingBottom: '10px !important',
    },
  },
  bottomText: {
    verticalAlign: 'bottom',
  },
  textRed: {
    color: '#f00 !important',
    '& span': {
      color: '#f00',
    },
  },
  textBlackInput: {
    [theme.breakpoints.down( 'xs' )]: {
      display: 'flex',
      alignItems: 'center',
    }, 
    '& input': {
      color: '#000 !important',
    },
  },
} );

export default styles;
