import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import { Link } from 'react-router-dom';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import aboutGraph from '../../assets/img/about-us/green-hand-drawn-line-login.png';
import aboutLogo from '../../assets/img/about-us/inkwiry-final-rev.png';
import questionsGraphic from '../../assets/img/about-us/questions-graphic.png';
import netWorthHomepage from '../../assets/img/about-us/net-worth-homepage.png';
import people from '../../assets/img/about-us/4-people.png';
import financeExplainedGreen from '../../assets/img/about-us/finance-explained-green.png';
import PF101IconSquare from '../../assets/img/about-us/PF101-icon-square.png';
import pwiPuzzle from '../../assets/img/about-us/pwi-puzzle.png';
import MCSIconSquare from '../../assets/img/about-us/MCS-icon-square.png';
import jamesDeluca from '../../assets/img/about-us/james-g-deluca.png';
import jaDeluca from '../../assets/img/about-us/ja-deluca.png';
import mas from '../../assets/img/about-us/mas.jpg';

const Team = ( props ) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.aboutPage }>
        <div className={ classes.aboutBanner }>
          <div className={ classes.bannerContent }>
            <figure>
              <img src={ aboutGraph } alt="" />
            </figure>
            <div className={ classes.bannerCaption }>
              <div className={ classes.container }>
                <div className={ classes.aboutLogo }>
                  <img src={ aboutLogo } alt="" />
                </div>
                <div className={ classes.bannerTitle }>
                  <Typography variant="h2" component="h2">We want to set the world <span> on a path to financial freedom</span></Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.empowerInnovation }>
          <div className={ classes.container }>
           <Typography variant="h2" component="h2">Empowering Innovation</Typography>
           <p><b>We envision a better world</b> where anyone, empowered by Inkwiry's business intelligence and methodology, can innovate and create transformative, win-win solutions to resolve the greater issues of today and tomorrow.</p>
           <p>We believe in the power of Inkwiry to solve problems, large and small, and together, anything is possible. Change can start with you.</p>
           <Typography variant="h4" component="h4">awareness + discovery + due diligence + strategies + financial modeling = solutions</Typography>
          </div>
        </div>
        <div className={ classes.awarenessImages }>
          <div className={ classes.container }>
            <div className={ classes.awarenessImagesBox }>
              <Grid container spacing={ 4 }>
                <Grid item xs={ 12 } sm={ 3 } md={ 3 } className={ classes.imageBox }>
                  <img src={ questionsGraphic } alt="" />
                  <p>Questions</p>
                </Grid>
                <Grid item xs={ 12 } sm={ 3 } md={ 3 } className={ classes.imageBox }>
                  <img src={ netWorthHomepage } alt="" />
                  <p>Challenges</p>
                </Grid>
                <Grid item xs={ 12 } sm={ 3 } md={ 3 } className={ classes.imageBox }>
                  <img src={ people } alt="" />
                  <p>Career Sketch Stories</p>
                </Grid>
                <Grid item xs={ 12 } sm={ 3 } md={ 3 } className={ classes.imageBox }>
                  <img src={ financeExplainedGreen } alt="" />
                  <p>Finance Explained</p>
                </Grid>
              </Grid>
              <Grid container spacing={ 3 } className={ classes.imageMargin }>
                <Grid item xs={ 12 } sm={ 3 } md={ 3 } className={ classes.imageBox }>
                  <img src={ PF101IconSquare } alt="" />
                  <p>Personal Finance 101</p>
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } md={ 6 } className={ classes.imageBox }>
                  <img src={ pwiPuzzle } alt="" />
                  <p>Plan With Inkwiry</p>
                </Grid>
                <Grid item xs={ 12 } sm={ 3 } md={ 3 } className={ classes.imageBox }>
                  <img src={ MCSIconSquare } alt="" />
                  <p>My Career Sketch</p>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className={ classes.inkwryTeam }>
          <div className={ classes.container }>
            <Typography variant="h3" component="h3">Our leadership team</Typography>
            <div className={ classes.leaderImage }>
              <img src={ jamesDeluca } alt="" />
            </div>
            <Typography variant="h3" component="h3">James G. Deluca</Typography>
            <Typography variant="h4" component="h4">CEO, Co-Founder</Typography>
            <p>James left a thriving career in investment banking to co-found Inkwiry in 2017. Over the past five years, he's led a small team to bring Inkwiry and the financial engine that powers the application to life. And if you've ever been on a live demo call with James, you understand just how dedicated he is to Inkwiry's mission of bringing financial life planning and financial freedom to all.</p>
            <p>James has seven years of experience in the finance industry including two years as an acquisition and leveraged finance investment banker in New York City. In his two year tenure, James raised more than $10 billion of debt for technology, consumer retail and industrial companies. He graduated from Villanova University in 2015 with a bachelor's degree in Finance. James' specialty is financial engineering - building financial models to solve complex problems.</p>
          </div>
        </div>
        <div className={ classes.teamMembers }>
          <div className={ classes.container }>
            <Grid container spacing={ 2 } className={ classes.teamRow }>
              <Grid item xs={ 12 } sm={ 12 } md={ 3 }>
                <div className={ classes.teamMembersLeft }>
                  <img src={ jaDeluca } alt="" />
                </div>
              </Grid>
              <Grid item xs={ 12 } sm={ 12 } md={ 9 }>
                <div className={ classes.teamMembersRight }>
                  <Typography variant="h2" component="h2">James A. DeLuca, P.E.</Typography>
                  <Typography variant="h3" component="h3">President, Co-Founder</Typography>
                  <p>A licensed Professional Engineer with 40+ years of experience in home building, land development, site planning, construction management, architectural design and construction technology, Jim has based a number of his businesses on Inkwiry's methodology. He developed an internal enterprise resource plan (ERP) and customer relationship management (CRM) systems to manage the production of 400+ homes annually. He also participated in the development, with a consortium of builders, to synchronize and automate architectural software, Bill of Materials (BOMs) creation, a sales configurator and manufacturing facilities to reduce costs and build time. Jim also is co-founder and president at PROGETI, LLC, an architecture and engineering firm, and president of DeLuca Construction.</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={ classes.teamMembers }>
          <div className={ classes.container }>
            <Grid container spacing={ 2 } className={ classes.teamRow }>
              <Grid item xs={ 12 } sm={ 12 } md={ 3 }>
                <div className={ classes.teamMembersLeft }>
                  <img src={ mas } alt="" />
                </div>
              </Grid>
              <Grid item xs={ 12 } sm={ 12 } md={ 9 }>
                <div className={ classes.teamMembersRight }>
                  <Typography variant="h2" component="h2">Mary Ann Sircely</Typography>
                  <Typography variant="h3" component="h3">CMO, Co-Founder</Typography>
                  <p>Mary Ann began working on Inkwiry's conceptual development in 2014. A seasoned marketing professional, she specializes in brand strategy, graphic design and conversion copywriting. For 30+ years, Mary Ann has served as principal and creative director of Sircely Marketing & Design, a full service marketing communications firm. Over time, she has served hundreds of businesses and nonprofits across the nation, producing award-winning materials for clients, including Waste Management's community outreach program. A graduate of Connecticut College, she studied Journalism and Studio Art at Lehigh University.</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

Team.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Team );
