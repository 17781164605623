import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import styles from './styles';
import _ from 'lodash';
// import fileIcon from '../../assets/img/sight/explorer_file_icon.png';

const SAVENOTES = loader( '../../graphql/schema/pwi/save-note.graphql' );
const SIGHTDETAILS = loader('../../graphql/schema/pwi/sight-data.graphql');

const MySavedNotes = ( props ) => {
  const {
    classes, handleSaveScenario, loadScenarioName, setLoadScenarioName,
  } = props;
  const [defaultNote, setDefaultNote] = React.useState( '' );
  const [instructionOpen, setInstructionOpen] = React.useState( false );
  const [saveMessage, setSaveSuccessMessage] = React.useState( '' );
  const [placeHolderNote, setPlaceholderNote] = React.useState( 'Click here and start typing to create a new note ...' );
  const [note, setNote] = React.useState('');
  const [sightData, setSightData] = React.useState([]);
  const [csData, setCsData] = React.useState(false);

  useEffect( () => {
    if ( loadScenarioName.content !== defaultNote ) {
      setNote( loadScenarioName.content );
      setDefaultNote( loadScenarioName.content );
    }
  }, [loadScenarioName.content, defaultNote] );

  useQuery(SIGHTDETAILS, {
    variables: {
      operation_type: 'save',
      type: 'scenarios',
    },
    fetchPolicy: 'network-only',
    onCompleted(response){
      setSightData(response.SightData.notes);         
    }
  });

  const myNotes = JSON.parse(localStorage.getItem('notes_data'));  
  React.useEffect(() => {
    if (myNotes !== '' && myNotes !== undefined && myNotes !== null) {
      setCsData(true);
    } else {
      setCsData(false);
    }
  }, [myNotes]);

  React.useEffect(() => {
    if (csData && sightData.length > 0) {      
      const notesData = _.find(sightData, { id: myNotes.data.id });
      setLoadScenarioName(notesData)
      localStorage.removeItem('notes_data') 
    }
  },[sightData]);

  const [submitSaveNotes] = useMutation( SAVENOTES, {
    onCompleted( {
      saveNote: {
        status,
        message,
      },
    } ) {
      if ( status ) {
        setSaveSuccessMessage( message );
        setInstructionOpen( true );
      }
    },
    onError( errors ) {
      const formatedErrors = errors.graphQLErrors.map( ( { message } ) => (
        <span key={ `login-error-${Math.random()}` }>{message}</span>

      ) );
      setSaveSuccessMessage( formatedErrors );
      setInstructionOpen( true );
    },
  } );
  const updatedSavedNotes = () => {
    let updatedObject = {};
    updatedObject = {
      selected_module: loadScenarioName.selected_module,
      id: loadScenarioName.id,
      note_title: loadScenarioName.note_title,
      content: note,
      directory_id: loadScenarioName.directory_id,
    };
    submitSaveNotes( {
      variables: {
        data: updatedObject,
      },
    } );
  };

  const handleSavedNotes = () => {
    if ( note === '' ) {
      setInstructionOpen( true );
    } else {
      props.handleSaveScenario( 'saveNotes' );
    }
  };

  const handlePlacholderFocus = ( event ) => {
    if ( !loadScenarioName || !event.target.value ) {
      setPlaceholderNote( '' );
    }
  };

  const handleTextChange = ( e ) => {
    setNote( e.target.value );
    const loadScenarioList = loadScenarioName;
    loadScenarioList.content = e.target.value;
    setLoadScenarioName( loadScenarioList );
  };

  React.useEffect( () => {
  }, [loadScenarioName] );
  return (
    // directory_id
    <div className={ classes.sectionContent }>
      <div className={ classes.panelRow }>
        <div className={ classNames( classes.savePanleInfo, classes.panelInfo ) }>
          <div className={ classes.panelHeading }>
            <div className={ classes.rowBlock }>
              <div className={ classes.notesBlock }>
                <div className={ classes.notesLeft }>
                  <ul className={ classes.listUnstyled }>
                    {loadScenarioName && loadScenarioName.note_title && (
                      <li>
                        <span>{loadScenarioName.note_title}</span>
                        {/* <span><img src={ fileIcon } alt="info" aria-hidden="true" onClick={ updatedSavedNotes } /></span> */}
                        <div className={ classes.primaryNotes } aria-hidden="true" onClick={ updatedSavedNotes }><i className="fa fa-floppy-o" aria-hidden="true" /></div>
                      </li>
                    )}

                  </ul>
                </div>
                <div className={ classes.notesRight }>
                  <ul className={ classes.listUnstyled }>
                    <li><Button className={ classNames( classes.loadButton, classes.btnWarning ) } onClick={ () => { handleSaveScenario( 'loadNotes' ); } }>Load</Button></li>
                    <li><Button className={ classNames( classes.saveButton, classes.btnWarning ) } onClick={ handleSavedNotes }>Save</Button></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.panelBody }>
            <div className={ classes.notesRow }>
              <div className={ classes.notesEditor }>
                <div className={ classes.notesEditorRow }>
                  <div className={ classes.textRow }>
                    <textarea value={ note } onChange={ ( e ) => { handleTextChange( e ); } } placeholder={ placeHolderNote } onFocus={ ( e ) => { handlePlacholderFocus( e ); } } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Dialog
          open={ instructionOpen }
          onClose={ () => { setInstructionOpen( false ); } }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className={ classNames( classes.videoPopup, classes.saveNotesPopup ) }>
            <div className={ classes.clearPopup }>
              {saveMessage && (
                <div className={ classes.clearHeader }>
                  <h3>My Saved Notes</h3>
                </div>
              )}

              <div className={ classes.clearContent }>
                {saveMessage && ( <p className={ classes.saveMesage }>{saveMessage}</p> )}
                {!saveMessage && (
                  <p>
                    Write Something before save the note.
                  </p>
                )}
              </div>
              <React.Fragment>
                <div className={ classes.clearFooter }>
                  <Button className={ classNames( classes.buttonOne, classes.closeButton, classes.smallFont ) } onClick={ () => { setInstructionOpen( false ); } }>
                    {' '}
                    {saveMessage ? 'Close' : 'OK'}
                  </Button>
                </div>
              </React.Fragment>
            </div>

          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

MySavedNotes.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSaveScenario: PropTypes.func.isRequired,
  loadScenarioName: PropTypes.object.isRequired,
  setLoadScenarioName: PropTypes.func.isRequired,
};
export default withStyles( styles )( MySavedNotes );
