const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  bondProfitPage: {
    background: '#e5f0f6',
    display: 'block',
  },
  breadCrumbs: {
    listStyle: 'none',
    display: 'inline-flex',
    padding: '20px 0',
    marginBottom: '0',
    width: '100%',
    fontFamily: 'MuseoSans-100',
    fontSize: '14px',
    marginTop: '0',
    lineHeight: '1.42857143',
    '& li': {
      color: '#000',
      '& a': {
        color: '#333',
        transition: 'all 0.5s ease 0s',
        mozTransition: 'all 0.5s ease 0s',
        webkitTransition: 'all 0.5s ease 0s',
        border: '0',
        outline: '0',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    '& span': {
      padding: '0 5px',
      fontSize: '18px',
      lineHeight: '18px',
      boxSizing: 'border-box',
    },
  },
  bondProfitCalculator: {
    marginBottom: '20px',
  },
  bondProfitSection: {
    minHeight: 'calc(100vh - 105px)',
    transition: 'all .3s ease-in-out',
  },
  showMeContent: {
    padding: '0px 35px',
    background: '#fff',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px',
    },
  },
  showMePortion: {
    padding: '20px 0',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px 0px',
    },
  },
  mainRow: {
    margin: '0px -15px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      boxSizing: 'border-box',
      margin: '20px 0px 0px 0px',
      [theme.breakpoints.down( 'xs' )]: {
        margin: '0px',
      },
    }, 
  },
  showSection: {
    padding: '0px 25px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px 10px',
    },
  },
  mainRowNew: {
    margin: '20px -15px',
    border: '2px solid #0069aa',
    display: 'flex',
    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0px',
    }, 
  },
  leftPanel: {
    padding: '20px 10px 10px',
    width: '24%',
    background: '#e7f1f9',
    borderRight: '2px solid #0069aa',
    display: 'block',
    verticalAlign: 'top',   
    '@media (max-width: 767px)': {
     width: '100%',
     borderRight: 'none',
    }, 
    '@media screen and (min-width: 768px) and (max-width: 1024px)': {
      width: '25%',
      paddingLeft: '6px',
      paddingRight: '6px',
     }, 
    '& table': {
      borderSpacing: '0px',
      borderCollapse: 'collapse',
      marginBottom: '0',
      width: '100%',
      backgroundColor: 'transparent',
      '& tr': {
        '& td': {
          paddingLeft: '0px',
          fontSize: '17px',
          paddingRight: '10px',
          lineHeight: '1.42857143',
          color: '#000',
          '@media screen and (min-width: 768px) and (max-width: 1024px)': {
           fontSize: '13px',
           paddingRight: '0px',
          }, 
        },
      },
    },
  },
  inputField: {
    display: 'flex',
    alignItems: 'center',
    height: '45px',
    marginBottom: '10px',
    fontWeight: '700',
    background: '0 0',
    padding: '5px 10px 5px 0px',
    position: 'relative',
    fontSize: '17px',
    boxSizing: 'border-box',
    '& input': {
      padding: '0 5px 0px 7px',
      height: '45px',
      maxWidth: '100%',
      marginLeft: '0',
      width: '100%',
      border: 'none',
      color: '#000',
      lineHeight: '1.42857143',
      boxSizing: 'border-box',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      '&:focus': {
        outline: '0',
      },
    },
  },
  fieldData: {
    display: 'flex',
    height: '45px',
    border: 'none',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '10px',
    boxSizing: 'border-box',
    fontWeight: '700',
    '& div': {
      width: '100%',
      '&::before': {
        border: 'none !important',
      },
      '&::after': {
        border: 'none !important',
      },
    },
    '& span': {
      position: 'absolute',
      left: '7px',
      top: 'inherit',
      marginTop: '0',
      zIndex: '1',
      color: '#000',
    },
    '& input': {
      padding: '0 5px 0px 5px !important',
      height: '45px',
      boxSizing: 'border-box',
      width: '100%',
      border: 'none',
      background: '#fff',
      fontSize: '17px',
      fontFamily: 'inherit',
      fontWeight: '700',
      '@media screen and (min-width: 768px) and (max-width: 1024px)': {
        fontSize: '14px',
       }, 
    },
  },
  percentField: {
    '& input': {
      padding: '0px 5px 0px 7px !important',
    },
    '& span': {
      left: 'auto',
      right: '7px !important',
    },
  },
  inputData: {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& div': {
      width: '100%',
    },
  },
  yearSymbol: {
    left: 'auto !important',
    right: '20px !important',
    textAlign: 'right',
    color: '#000',
    fontWeight: 'inherit',
    position: 'absolute',
    fontFamily: '"MuseoSans-300"',
    fontSize: '16px',
    marginTop: '0px',
    float: 'right',
    paddingLeft: '5px',
    '@media screen and (min-width: 768px) and (max-width: 1024px)': {
      fontSize: '13px',
    }, 
  },
  dollarSymbol: {
    position: 'absolute',
    left: '7px',
  },
  rightSection: {
    position: 'relative',
    padding: '0px 15px',
  },
  bondProfitTable: {
    width: '99%',
    paddingLeft: '1%',
    minHeight: '.01%',
    overflowX: 'auto',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      paddingLeft: '0', 
    }, 
    '& table': {
      width: '100%',
      marginBottom: '0',
      maxWidth: '100%',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '& thead': {
        '& tr': {
          '& td': {
            textAlign: 'center',
            fontStyle: 'italic',
            lineHeight: '1.42857143',
            verticalAlign: 'top',
            padding: '0 8px',
            fontSize: '16px',
            color: '#000',
            '&:first-child': {
              textAlign: 'left',
            },
          },
        },
      },
      '& tbody': {
        border: '2px solid #000',
        '& tr': {
          '& td': {
            position: 'relative',
            lineHeight: '1.42857143',
            verticalAlign: 'top',
            padding: '0 8px',
            fontSize: '16px',
            color: '#000',
            textAlign: 'right',
            boxSizing: 'border-box',
            '&:first-child': {
              borderRight: '1px solid #000',
              textAlign: 'left',
              width: '165px',
              display: 'block',
            },
          },
        },
      },
      '& tfoot': {
        '& tr': {
          '& td': {
            textAlign: 'right',
            fontStyle: 'italic',
            lineHeight: '1.42857143',
            verticalAlign: 'top',
            padding: '0 8px',
            fontSize: '16px',
            color: '#000',
            '&:first-child': {
              textAlign: 'center',
            },
          },
        },
      },
    },
  },
  rightPanel: {
    padding: '10px',
    width: '52%',
    boxSizing: 'border-box',   
    '@media (max-width: 767px)': {
      width: '100%',          
    },
    '@media screen and (min-width: 768px) and (max-width: 1024px)': {
      width: '50%',
     }, 
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px', 
    }, 
  },
  incomeAfterTax: {
    borderTop: '1px solid #000',
    fontWeight: '700',
    background: '#ddebf7',
  },
  totalIncomeTable: {
    border: '2px solid #000',
    width: '60%',
    marginTop: '50px',
    marginBottom: '15px',
    maxWidth: '100%',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      marginTop: '25px',
     }, 
    '& tbody': {
      '& tr': {
        '& td': {
          position: 'relative',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
          padding: '0 8px',
          fontSize: '16px',
          color: '#000',
          '&:last-child': {
            textAlign: 'right',
          },
        },
        '&:nth-child(3)': {
          '& td': {
            fontWeight: '700',
            background: '#e2efda',
            borderTop: '1px solid #000',
          },
        },
      },
    },
  },
  dollarField: {
    '& input': {
      paddingLeft: '17px !important',
      '@media screen and (min-width: 768px) and (max-width: 1024px)': {
        fontSize: '13px',
       }, 
    },
  },
  formHorizontal: {
    paddingBottom: '20px',
  },
} );

export default styles;
