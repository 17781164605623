export function formatDollar( number ) {
  const p = number.toFixed( 2 ).split( '.' );
  return `$${p[0].split( '' ).reverse().reduce( ( acc, num, i ) => ( num === '-' ? acc : num + ( i && !( i % 3 ) ? ',' : '' ) + acc ), '' )}`;
}

export function renderIcons() {
  // Move icon
  if ( !this.series[0].icon ) {
    this.series[0].icon = this.renderer.path( ['M', -8, 0, 'L', 8, 0, 'M', 0, -8, 'L', 8, 0, 0, 8] )
      .attr( {
        stroke: '#303030',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
        'stroke-width': 2,
        zIndex: 10,
      } )
      .add( this.series[0].group );
  }
  this.series[0].icon.translate(
    this.chartWidth / 2 - 10,
    this.plotHeight / 2 - this.series[0].points[0].shapeArgs.innerR
            - ( this.series[0].points[0].shapeArgs.r - this.series[0].points[0].shapeArgs.innerR ) / 2,
  );

  // Exercise icon
  if ( !this.series[1].icon ) {
    this.series[1].icon = this.renderer.path(
      ['M', -8, 0, 'L', 8, 0, 'M', 0, -8, 'L', 8, 0, 0, 8,
        'M', 8, -8, 'L', 16, 0, 8, 8],
    )
      .attr( {
        stroke: '#ffffff',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
        'stroke-width': 2,
        zIndex: 10,
      } )
      .add( this.series[1].group );
  }
  this.series[1].icon.translate(
    this.chartWidth / 2 - 10,
    this.plotHeight / 2 - this.series[1].points[0].shapeArgs.innerR
            - ( this.series[1].points[0].shapeArgs.r - this.series[1].points[0].shapeArgs.innerR ) / 2,
  );
}
