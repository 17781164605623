import fi from '../../assets/img/build-career-sketch/financial-independence.png';
import fiHover from '../../assets/img/build-career-sketch/fi_hover.png';
import eAssets from '../../assets/img/build-career-sketch/existing.png';
import eaHover from '../../assets/img/build-career-sketch/ea_hover.png';
import he from '../../assets/img/build-career-sketch/higher_edu_bg.png';
import heHover from '../../assets/img/build-career-sketch/higher_edu_bg_hover.png';
import mhe from '../../assets/img/build-career-sketch/more_higher_bg1_new.png';
import mheHover from '../../assets/img/build-career-sketch/more_higher_bg1_new_hover.png';
import fhe from '../../assets/img/build-career-sketch/higher_bg1.png';
import fheHover from '../../assets/img/build-career-sketch/higher_bg1_hover.png';
import cp from '../../assets/img/build-career-sketch/career_1_bg_new_hover.png';
import cpHover from '../../assets/img/build-career-sketch/career_1_bg_new.png';
import ca from '../../assets/img/build-career-sketch/career_2_bg_new.png';
import caHover from '../../assets/img/build-career-sketch/career_2_bg_new_hover.png';
import aca from '../../assets/img/build-career-sketch/career_3_bg_new.png';
import acaHover from '../../assets/img/build-career-sketch/career_3_bg_new_hover.png';
import fca from '../../assets/img/build-career-sketch/career_path_bg_4.png';
import fcaHover from '../../assets/img/build-career-sketch/career_path_bg_4_hover.png';
import invest from '../../assets/img/build-career-sketch/investment.png';
import investHover from '../../assets/img/build-career-sketch/invest_hover.png';
import family from '../../assets/img/build-career-sketch/family.png';
import familyHover from '../../assets/img/build-career-sketch/family_hover.png';
import vacation from '../../assets/img/build-career-sketch/vacation.png';
import vacationHover from '../../assets/img/build-career-sketch/vacation_hover.png';
import car from '../../assets/img/build-career-sketch/buy-car.png';
import carHover from '../../assets/img/build-career-sketch/car_hover.png';
import home from '../../assets/img/build-career-sketch/buy-home.png';
import homeHover from '../../assets/img/build-career-sketch/home_hover.png';
import rei from '../../assets/img/build-career-sketch/rei.png';
import reiHover from '../../assets/img/build-career-sketch/rei_hover.png';
import timelineDesc from '../../assets/img/build-career-sketch/timeline_desc.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    '&:before': {
      display: 'table',
      content: '" "',
    },
  },
  '*': {
    boxSizing: 'border-box',
  },
  img: {
    verticalAlign: 'middle',
  },
  timelineBanner: {
    '& p': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '35px',
      color: theme.palette.common.blue,
      textAlign: 'center',
      margin: '25px 0 10px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
      },
    },
  },
  lifeEventsBlock: {
    background: '#d1e4f0',
    padding: '10px 0px 0px 0px',
    display: 'block',
    textAlign: 'center',
  },
  lifeEventsContent: {
    position: 'relative',   
  },
  lifeEveentScroll: {
    [theme.breakpoints.down( 'sm' )]: {
      overflowX: 'auto',
     },
  },
  lifeEventsImages: {
    display: 'block',
    margin: '0 auto',
    width: '75%',
    padding: '0', 
    [theme.breakpoints.down( 'sm' )]: {
      width: '90%', 
      minWidth: '878px',     
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',    
      minWidth: '878px',
    },
    '& li': {
      display: 'inline-block',
      verticalAlign: 'middle',
      padding: '0',
      position: 'relative',
      zIndex: '3',
      width: '9%',
      margin: '0px 2px',     
      '& font': {
        display: 'block',
        border: '0',
        outline: '0',
        '& span': {
          backgroundPosition: 'center center',
          backgroundSize: 'contain',
          width: '80px',
          height: '80px',
          display: 'block',
          margin: '0 auto',
          backgroundRepeat: 'no-repeat',
          cursor: 'pointer',
        },
      },
      '& ul': {
        width: '100%',
        minHeight: '148px',
        position: 'relative',
        padding: '0px',
        margin: '0 auto',
        '& li,& div': {
          width: '100%',
        },
      },
    },
  },
  fiLifeEvent: {
    '& span': {
      backgroundImage: `url(${fi})`,
    },
    '&:hover': {
      '& span': {
        backgroundImage: `url(${fiHover})`,
      },
    },
  },
  noDropFi: {
    '& span': {
      backgroundImage: `url(${fiHover})`,
    },
  },
  existingEvent: {
    '& span': {
      backgroundImage: `url(${eAssets})`,
    },
    '&:hover': {
      '& span': {
        backgroundImage: `url(${eaHover})`,
      },
    },
  },
  noDropExisting: {
    '& span': {
      backgroundImage: `url(${eaHover})`,
    },
  },
  heLifeEventsList: {
    '& li': {
      '& font': {
        '& span': {
          height: '104px',
          marginTop: '11px',
          backgroundPosition: 'bottom',
        },
      },
    },
    '& div': {
      top: '0px',
    },
    '& div:first-child': {
      top: '0px',
    },
    '& div:nth-child(2)': {
      top: '-13px',
    },
    '& div:nth-child(3)': {
      top: '-26px',
    },
  },
  higherEducation: {
    '& font': {
      '& span': {
        backgroundImage: `url(${he})`,
      },
      '&:hover': {
        '& span': {
          backgroundImage: `url(${heHover})`,
        },
      },
    },
  },
  noDropHe: {
    '& span': {
      backgroundImage: `url(${heHover}) !important`,
    },
  },
  moreHigherEducation: {
    position: 'absolute !important',
    left: '0',
    zIndex: '2 !important',
    '& font': {
      '& span': {
        backgroundImage: `url(${mhe})`,
      },
      '&:hover': {
        '& span': {
          backgroundImage: `url(${mheHover})`,
        },
      },
    },
  },
  noDropMhe: {
    '& span': {
      backgroundImage: `url(${mheHover}) !important`,
    },
  },
  furtherHigherEducation: {
    position: 'absolute !important',
    left: '0px',
    zIndex: '1 !important',
    '& font': {
      '& span': {
        backgroundImage: `url(${fhe})`,
      },
      '&:hover': {
        '& span': {
          backgroundImage: `url(${fheHover})`,
        },
      },
    },
  },
  noDropFhe: {
    '& span': {
      backgroundImage: `url(${fheHover}) !important`,
    },
  },
  careerPathList: {
    '& li': {
      '& font': {
        '& span': {
          marginTop: '-22px',
          height: '135px',
          backgroundSize: 'contain',
          backgroundPosition: 'bottom',

        },
      },
    },
    '& div': {
      top: '0px',
    },
    '& div:first-child': {
      top: '0px',
      position: 'absolute',
      zIndex: 4,
    },
    '& div:nth-child(2)': {
      top: '-13px',
      zIndex: 3,
    },
    '& div:nth-child(3)': {
      top: '-26px',
      zIndex: 2,
    },
    '& div:nth-child(4)': {
      top: '-39px',
      zIndex: 1,
    },
  },
  careerPath: {
    '& span': {
      backgroundImage: `url(${cp})`,
    },
    '&:hover': {
      '& span': {
        backgroundImage: `url(${cpHover})`,
      },
    },
  },
  noDropCp: {
    '& span': {
      backgroundImage: `url(${cpHover}) !important`,
    },
  },
  careerAdvancement: {
    position: 'absolute !important',
    left: '0',
    '& span': {
      backgroundImage: `url(${ca})`,
    },
    '&:hover': {
      '& span': {
        backgroundImage: `url(${caHover})`,
      },
    },
  },
  noDropCa: {
    '& span': {
      backgroundImage: `url(${caHover}) !important`,
    },
  },
  addAdvancement: {
    position: 'absolute !important',
    left: '0',
    '& span': {
      backgroundImage: `url(${aca})`,
    },
    '&:hover': {
      '& span': {
        backgroundImage: `url(${acaHover})`,
      },
    },
  },
  noDropAca: {
    '& span': {
      backgroundImage: `url(${acaHover}) !important`,
    },
  },
  furtherAdvancement: {
    position: 'absolute !important',
    left: '0',
    '& span': {
      backgroundImage: `url(${fca})`,
    },
    '&:hover': {
      '& span': {
        backgroundImage: `url(${fcaHover})`,
      },
    },
  },
  noDropFca: {
    '& span': {
      backgroundImage: `url(${fcaHover}) !important`,
    },
  },
  investEvent: {
    '& span': {
      width: '80px',
      height: '80px',
      backgroundPosition: 'center center',
      backgroundSize: 'contain',
      backgroundImage: `url(${invest})`,
    },
    '&:hover': {
      '& span': {
        backgroundImage: `url(${investHover})`,
      },
    },
  },
  noDropInv: {
    '& span': {
      backgroundImage: `url(${investHover}) !important`,
    },
  },
  familyEvent: {
    '& span': {
      width: '80px',
      height: '80px',
      backgroundPosition: 'center center',
      backgroundSize: 'contain',
      backgroundImage: `url(${family})`,
    },
    '&:hover': {
      '& span': {
        backgroundImage: `url(${familyHover})`,
      },
    },
  },
  noDropFamily: {
    '& span': {
      backgroundImage: `url(${familyHover}) !important`,
    },
  },
  vacationEvent: {
    '& span': {
      width: '80px',
      height: '80px',
      backgroundPosition: 'center center',
      backgroundSize: 'contain',
      backgroundImage: `url(${vacation})`,
    },
    '&:hover': {
      '& span': {
        backgroundImage: `url(${vacationHover})`,
      },
    },
  },
  noDropVacation: {
    '& span': {
      backgroundImage: `url(${vacationHover}) !important`,
    },
  },
  carEvent: {
    '& span': {
      width: '80px',
      height: '80px',
      backgroundPosition: 'center center',
      backgroundSize: 'contain',
      backgroundImage: `url(${car})`,
    },
    '&:hover': {
      '& span': {
        backgroundImage: `url(${carHover})`,
      },
    },
  },
  noDropCar: {
    '& span': {
      backgroundImage: `url(${carHover}) !important`,
    },
  },
  homeEvent: {
    '& span': {
      width: '80px',
      height: '80px',
      backgroundPosition: 'center center',
      backgroundSize: 'contain',
      backgroundImage: `url(${home})`,
    },
    '&:hover': {
      '& span': {
        backgroundImage: `url(${homeHover})`,
      },
    },
  },
  noDropHome: {
    '& span': {
      backgroundImage: `url(${homeHover})`,
    },
  },
  reiEvent: {
    '& span': {
      width: '80px',
      height: '80px',
      backgroundPosition: 'center center',
      backgroundSize: 'contain',
      backgroundImage: `url(${rei})`,
    },
    '&:hover': {
      '& span': {
        backgroundImage: `url(${reiHover})`,
      },
    },
  },
  noDropRei: {
    '& span': {
      backgroundImage: `url(${reiHover})`,
    },
  },
  walkThru: {
    position: 'relative',
    '@media (max-width: 767px)': {
      pointerEvents: 'none',
      display: 'none',
    },
    '@media only screen and (min-device-width: 800px) and (max-device-width: 900px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      pointerEvents: 'none',
    },
    '& span': {
      padding: '6px 10px',
      display: 'inline-flex',
      '&:hover': {
        backgroundColor: '#333',
        borderRadius: '4px',
      },
    },
  },
  infoIcon: {
    display: 'flex',
    width: '25px',
    maxWidth: '25px',
    alignitems: 'center',
    '& img': {
      width: '100%',
      cursor: 'pointer',
    },
  },
  timelineEvents: {
    textAlign: 'center',
    position: 'relative',
    '& h3': {
      fontSize: '26.5px',
      lineHeight: '30px',
      margin: '0 0 5px 0',
      color: theme.palette.common.blue,
      padding: '0',
      fontFamily: '"MuseoSans-100"',
      fontWeight: 'normal',
      position: 'relative',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',        
      },
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        fontSize: '22px',        
      },
    },
    '& p': {
      fontSize: '19px',
      lineHeight: '30px',
      color: theme.palette.common.blue,
      padding: '0',
      margin: '0',
      fontFamily: '"MuseoSans-100"',
      fontWeight: 'normal',
      position: 'relative',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '15px',
        lineHeight: '25px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '14px',
      },
      '& span': {
        display: 'inline-block',
        width: '100%',
      },
    },
  },
  dragBlock: {
    padding: '30px 32px',
    background: theme.palette.common.white,
    textAlign: 'center',
    display: 'block',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '30px 0px',
    },
  },
  activeSelection: {
    marginTop: '-60px',
    [theme.breakpoints.down( 'md' )]: {
      marginTop: '-40px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '-20px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      marginTop: '0',
    },
    '& p': {
      fontSize: '24px',
      lineHeight: '30px',
      textAlign: 'right',
      marginBottom: '5px',
      color: theme.palette.common.blue,
      padding: '0',
      fontFamily: '"MuseoSans-100"',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
      },
    },
  },
  netWorthValues: {
    paddingTop: '5px',
    color: theme.palette.common.blue,
    textAlign: 'right',
    '& p': {
      fontSize: '20px',
      margin: '0',
      lineHeight: '26px',
      '& span': {
        fontSize: '18px',
        '&:first-child': {
          paddingRight: '8px',
        },
      },
    },
  },

  activeButton: {
    '& button': {
      backgroundColor: '#006f3c',
      borderColor: '#025830',
      borderRadius: '10px',
      borderWidth: '2px',
      marginBottom: '5px',
      padding: '5px 13px 5px 11px',
      fontSize: '14px',
      lineHeight: '14px',
      color: theme.palette.common.white,
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#006f3c',
        borderColor: '#025830',
      },
    },
  },
  redButton: {
    backgroundColor: 'red !important',
    borderColor: 'red !important',
    '&:hover': {
      backgroundColor: 'red',
      borderColor: '#e50000 !important',
    },
  },
  summaryDocsLinks: {
    outline: '0',
    outlineOffset: '0',
    marginRight: '0',
    padding: '0',
    border: '0',
    marginTop: '0px',
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'right',
    [theme.breakpoints.down( 'xs' )]: {
      marginTop: '20px',
    },
    '& img': {
      width: '40px',
    },
  },
  timelineArrow: {
    position: 'absolute',
    width: '230px',
    marginLeft: '180px',
    marginTop: '7px',
    [theme.breakpoints.down( 'xs' )]: {     
      marginLeft: '0px',
      marginTop: '44px',
    },
    '@media screen and (min-width: 600px) and (max-width: 767px)': {
      marginLeft: '0px',
    },
    '@media screen and (min-width: 768px) and (max-width: 992px)': {
      marginLeft: '40px',
    },
    '& img': {
      width: '100%',
    },
  },
  csTimeline: {
    display: 'block',
    padding: '0 0 160px 0',
    position: 'relative',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0 0 70px 0',
    },
    '& img': {
      width: '100%',
    },
  },
  redText: {
    color: '#f00',
  },
  tooltipInfo: {
    background: 'transparent !important',
    color: '#000',
    fontSize: '15px !important',
    maxWidth: '250px',
    padding: '3px 8px !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textShadow: 'none',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordBreak: 'normal',
    wordSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'normal',
    lineBreak: 'auto',
    textDecoration: 'none',
    '&:after': {
      display: 'none',
    },
  },
  walkThruIcon: {
    maxWidth: '400px',
    padding: '3px 8px !important',
    color: theme.palette.common.white,
    textAlign: 'center',
    backgroundColor: '#0069aa !important',
    borderRadius: '4px',
    fontSize: '14px !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textShadow: 'none',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordBreak: 'normal',
    wordSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'normal',
    lineBreak: 'auto',
    textDecoration: 'none',
    marginLeft: '20px !important',
  },
  timelineDropdown: {
    height: 'auto',
    width: '270px',
    position: 'absolute',
    // zIndex: '1',
    top: '16%',
    right: '50px',
    display: 'block',
    background: '#fff',
    zIndex: '999',
    borderRadius: '4px',
    backgroundClip: 'padding-box',
    // border: '1px solid #ccc',
    border: '1px solid rgba(0, 0, 0, .15)',
    WebkitBoxShadow: '0 6px 12px rgba(0, 0, 0, .175)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, .175)',
    '@media (max-width: 320px)': {
      top: '50px', 
      right: 'auto',   
    },
  },
  dropdownList: {
    textAlign: 'left',
    margin: '0',
    padding: '25px 0px 20px 0px',
    zIndex: '999',
    '& li': {
      fontFamily: '"MuseoSans-500"',
      fontSize: '16px',
      listStyleType: 'none',
    },
  },
  listName: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px 20px',
    clear: 'both',
    fontWeight: 'normal',
    lineHeight: '35px',
    color: '#333',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#e5e5e5',
    },
  },
  dropdownImage: {
    height: '25px',
    position: 'relative',
    width: '25px',
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    marginRight: '10px',
    '& img': {
      width: '100%',
      height: '100%',
      display: 'flex',
      margin: '0 auto',
    },
  },
  summaryTimeline: {
    position: 'absolute',
    left: 'auto',
    right: '100%',
    marginRight: '15px',
    height: 'auto',
    zIndex: '1',
    top: '-40px',
    display: 'block',
    background: '#fff',
    // zIndex: '999',
    minWidth: '150px',
    borderRadius: '4px',
    fontSize: '14px',
    backgroundClip: 'padding-box',
    border: '1px solid #ccc',
    padding: '15px 0px 5px 0px',
    // border: '1px solid rgba(0, 0, 0, .15)',
    WebkitBoxShadow: '0 6px 12px rgba(0, 0, 0, .175)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, .175)',
    [theme.breakpoints.down( 'xs' )]: {    
      paddingLeft: '40px',
      marginRight: '0px',
      border: 'none',
      top: '100%',
      left: 'auto',
      right: 'auto',
      position:'relative',
      boxShadow: 'none',
    },
    '& li': {
      fontFamily: '"MuseoSans-500"',
      '& p': {
        color: ' #4c4c4c',
        fontSize: '14px ',
        lineHeight: '1.42857143',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        clear: 'both',
        display: 'flex',
        padding: '3px 20px',
        margin: 0,
        '&:hover': {
          backgroundColor: '#e5e5e5',
        },
      },
    },
    '&:before': {
      borderLeft: '11px solid #fff',
      borderBottom: '9px solid transparent',
      borderRight: '9px solid transparent',
      content: '" "',
      borderTop: '9px solid transparent',
      height: '0',
      left: '100%',
      position: 'absolute',
      top: '4%',
      width: '0',
      boxSizing: 'border-box',
      [theme.breakpoints.down( 'xs' )]: {
       display: 'none',
      },
    },
  },
  summaryTimelinemenu: {
    position: 'relative',
  },

  menuHide: {
    display: 'none',
  },
  summaryDocs: {
    position: 'relative',
  },
  pwiWalkthru: {
    position: 'absolute',
    maxWidth: '640px',
    background: '#fff',
    zIndex: '999',
    border: '3px solid #0069aa',
    borderRadius: '40px',
    top: '135px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& h4': {
      width: '100%',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      fontSize: '20px',
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '10px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-300',
      color: '#0069aa',
      lineHeight: '1.1',
      boxSizing: 'border-box',
      '@media (max-width: 767px)': {
        fontSize:'16px',
        padding: '5px 10px',
      },
    },
    '& p': {
      color: '#000',
      fontSize: '16px',
      padding: '10px 20px',
      display: 'inline-block',
      lineHeight: '24px',
      margin: '0px 0px 10px',
      boxSizing: 'border-box',
      textAlign: 'left',
      fontFamily: 'MuseoSans-300',
      '@media (max-width: 767px)': {
        fontSize:'14px',
        lineHeight: '20px',
        padding: '0px 5px',
      },
    },
    '&::before': {
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',     
    },
    '&::after': {
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',     
    },
  },
  relativeBlock: {
    position: 'relative',
  },
  startWalkThru: {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    padding: '6px 12px',
    borderRadius: '4px',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',   
    '&:hover': {
      backgroundColor: '#337ab7 !important',
      borderColor: '#204d74 !important',
    },
    '& span': {
      color: '#fff !important',
    },
  },
  walkThruBtns: {
    maxWidth: '230px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    textAlign: 'right',
    marginBottom: '10px',
    float: 'right',
    paddingRight: '10px',
    '@media (max-width: 767px)': {
      maxWidth: '200px',
    },
    '@media (max-width: 479px)': {
      display: 'block',
      float: 'left',
      textAlign: 'left',
    },
    '& button': {
      margin: '0px 2px',
      display: 'inline-block',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      fontSize: '14px',
      userSelect: 'none',
      borderWidth: '1px',
      '@media (max-width: 767px)': {
        fontSize: '11px',
        padding: '6px',
      },
    },
  },
  checkmarksScroll: {
    listStyle: 'none',
    padding: '0',
    display: 'inline-block',
    marginLeft: '0',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    paddingInlinStart: '35px',
    marginBottom: '10px',
    marginTop: '0px',
    boxSizing: 'border-box',
    '@media (max-width: 767px)': {
      display: 'flex',
    },
    '& li': {
      width: '15px',
      height: '15px',
      background: '#0069aa',
      display: 'inline-block',
      margin: '1px 2px',
      borderRadius: '50%',
      position: 'relative',
      lineHeight: '20px',
      float: 'left',
      cursor: 'pointer',
      fontSize: '18px',
      paddingBottom: '8px',
      listStyle: 'none',
      marginLeft: '2px',
      boxSizing: 'border-box',
      '@media (max-width: 767px)': {
        width: '14px',
        height: '14px',
      },
      '&:last-child': {
        '&::before': {
          background: '0 0!important',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        margin: 'auto',
        left: 'auto',
        marginTop: 'auto',
        width: '7px',
        height: '3px',
        background: '#0069aa',
        display: 'inline-block',
        right: '-5px',
        zIndex: '-1',
        bottom: '0',
        boxSizing: 'border-box',
      },
      '&:hover': {
        opacity: '.8',
      },
    },
  },
  walkthruBottom: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0 25px 0 15px',
    boxSizing: 'border-box',
    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
    },
  },
  closeWalkThru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  skipWalkthru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },

  activeList: {
    background: '#84aa47 !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#84aa47',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '0',
      margin: 'auto',
      content: '""',
      height: '5px',
      width: '8px',
      borderWidth: '0 0 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      bottom: '2px',
      left: '-1px',
      right: '0',
      transform: 'rotate(-45deg)',
      boxSizing: 'border-box',
    },
  },
  currentTab: {
    background: '#f7941e !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#f7941e',
      },
    },
  },
  pwiAnimation: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'fixed',
      left: '0',
      top: '0',
      width: '100%',
      height: '100vh',
      background: 'rgba(0,0,0,.7)',
      zIndex: '9999',
      display: 'none',
      boxSizing: 'border-box',
    },
  },
  pwiAnimationActive: {
    '&::before': {
      display: 'block',
    },
  },
  pwiActiveWalkthru: {
    display: 'block !important',
    zIndex: '9999',
    pointerEvents: 'all',
    cursor: 'auto',
  },
  pwiActiveWalkIcon: {
    display: 'block !important',
    zIndex: '9999',
    pointerEvents: 'all',
    cursor: 'auto',   
    '& span': {
      pointerEvents: 'none',
      cursor: 'none',
    },
  },
  pwiActiveBlock: {
    pointerEvents: 'none',
    '& img': {
      position: 'relative',
      zIndex: '9999',
      cursor: 'none',
    },
  },
  pwiWalkThruButton: {
    pointerEvents: 'none',
    position: 'relative',
    zIndex: '9999',
    cursor: 'none',
  },
  pwiWalkTitle: {
    background: '#fff',
    zIndex: '9999',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    top: '0',
    padding: '3px 0px 3px 7px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    width: '98%',
  },
  pwiActiveModule: {
    zIndex: '9999',
    position: 'relative',
    background: '#fff',
    padding: '10px',
    marginTop: '-20px',
    borderRadius: '15px',
    cursor: 'none',
    pointerEvents: 'none',
    '& img': {
      cursor: 'none',
      pointerEvents: 'none',
    },
    '& h3': {
      cursor: 'none',
      pointerEvents: 'none',
    },
  },
  pwiWalkthruOne: {
    top: '-74px',
    left: '105px',
    right: 'auto',
    display: 'display !important', 
    '@media (max-width: 768px)': {
      top: '-90px',
      left: '85px',
    },
    '&::before': {
      top: '-54px',
      right: 'auto',
      bottom: '0',
      transform: 'rotate(-90deg)',
      left: '-44px', 
      
    },
    '&::after': {
      top: '-54px',
      bottom: '0',
      transform: 'rotate(-90deg)',
      left: '-51px',
      right: 'auto',     
    },
  },
  nomarginBottom: {
    marginBottom: '0px !important',
  },
  pwiWalkthruTwo: {
    left: '185px',
    top: '150px',
    right: '60px',
    display: 'none',
    '@media (max-width: 768px)': {
      left: 'auto',
      right: 'auto',
    },
    '&::before': {     
      left: '40.7%',     
      right: 'auto',
      top: '-29px',
      transform: 'none',    
      bottom: 'auto',     
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      left: '40%',
      right: 'auto',
      transform: 'none',     
    },
  },
  pwiWalkthruThree: {
    top: '-20px',
    right: 'auto',
    left: '185px',
    display: 'none',   
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '-80px',
      bottom: '0',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '-80px',
      bottom: '0',
    },
  },
  pwiWalkthruFour: {   
    right: 'auto',
    display: 'none',
    zIndex: '9999',
    top: '160px',
    left: '310px',
    '@media (max-width: 768px)': {
      left: '150px',
      right: 'auto',
    },
    '&::before': {
      top: '-29px',
      left: '39px',
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
    },
  },
  pwiWalkthruFive: {
    right: 'auto',
    display: 'none',
    zIndex: '9999',
    top: '160px',
    left: '390px',
    '@media (max-width: 768px)': {
     left: '250px',     
    },
    '&::before': {
      top: '-29px',
      left: '39px',
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
    },
  },
  pwiWalkthruSix: {
    top: '150px',
    left: '430px',
    display: 'none', 
    '@media (max-width: 768px)': {
      left: 'auto',
    },
    '&::before': {
     display: 'none',
    },
    '&::after': {
      display: 'none',
    }, 
  },
  pwiWalkthruSeven: {
    top: '150px',
    left: '65px',  
    display: 'none',
    zIndex: '9999',  
    '&::before': {
      top: '-29px',
      left: '39px',    
      transform: 'none', 
      bottom:'auto',
      right: 'auto',   
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
    },
  },
  pwiWalkthruEight: {    
    display: 'none',
    top: '-270px',
    left: '260px',
    '@media (max-width: 768px)': {
     left: '230px',
     top: '-300px',
     right: 'auto',
    },
    '&::before': {
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px',
      left: '60px',
      right: 'auto',
      top: 'auto',
      transform: 'none',
    },
    '&::after': {
      borderWidth: '32px 31px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px',
      left: '60px',
      right: 'auto',
      top: 'auto',
      transform: 'none',
    },
  },
  pwiWalkthruNine: {
    left: '441px',
    top: '-240px',
    right: 'auto',
    '@media (max-width: 767px)': {
      display: 'none',
    },
    '&::before': {
      top: '0',
      right: 'auto',
      bottom: '-90px',
      transform: 'rotate(-90deg)',
      left: '-44px',
    },
    '&::after': {
      top: '0',
      bottom: '-90px',
      transform: 'rotate(-90deg)',
      left: '-51px',
      right: 'auto',
    },
  },
  pwiWalkthruTen: {
    left: 'auto',
    top: '-22px',
    right: '380px',
    '@media (max-width: 768px)': {
     right: '355px'
    },
    '&::before': {
      top: '-108px',
      right: '-44px',
      bottom: '0',
      transform: 'rotate(90deg)',
      left: 'auto',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
    },
    '&::after': {
      top: '-108px',
      bottom: '0',
      transform: 'rotate(90deg)',
      left: 'auto',
      right: '-51px',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
    },
  },
  pwiWalkthruEleven: {
    left: 'auto',
    top: '100px',
    right: '380px',
    '@media (max-width: 768px)': {
      top: '44px',
      right: '350px',
    },
    '&::before': {
      top: '-85px',
      right: '-44px',
      bottom: '0',
      transform: 'rotate(90deg)',
      left: 'auto',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
    },
    '&::after': {
      top: '-85px',
      bottom: '0',
      transform: 'rotate(90deg)',
      left: 'auto',
      right: '-51px',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
    },
  },
  pwiWalkthruTwelve: {
    top: '-74px',
    left: '105px',
    right: 'auto',
    '@media (max-width: 768px)': {
      top: '-90px',
      left: '85px',
    },
    '&::before': {
      top: '-54px',
      right: 'auto',
      bottom: '0',
      transform: 'rotate(-90deg)',
      left: '-44px',
    },
    '&::after': {
      top: '-54px',
      bottom: '0',
      transform: 'rotate(-90deg)',
      left: '-51px',
      right: 'auto',
    },
  },
  timelineDesc: {
    backgroundImage: `url(${timelineDesc})`,
    zIndex: '9999',
    backgroundSize: '100%',
    width: '259px',
    height: '227px',
    left: '150px',
    position: 'absolute',
    top: '-208px',
    display: 'none',
  },
  timelineDescActive: {
    display: 'block',
  },
  disablePointerevents: {
    pointerEvents: 'none',
  },
  timelineSpacing: {
    padding: '0 15px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px',
    },
  },
  timelineClose: {
    '& span': {
      position: 'absolute',
      right: '30px',
      zIndex: '9999',
      top: '12px',
      cursor: 'pointer',
      '&::before': {
        position: 'absolute',
        left: '8px',
        content: '""',
        height: '18px',
        width: '2px',
        backgroundColor: '#333',
        transform: 'rotate(45deg)',
      },
      '&::after': {
        transform: 'rotate(-45deg)',
        position: 'absolute',
        left: '8px',
        content: '""',
        height: '18px',
        width: '2px',
        backgroundColor: '#333',
      },
    },
  },
  careerSketchTimelineInCnt: {
    width: '100%',
    display: 'block',
    boxSizing: 'border-box',
  },
  timelineHoverDeatils: {
    background: '#fff',
    borderRadius: '10px',
    border: '1px solid #333333',
    textAlign: 'center',
    width: '250px',
    position: 'absolute',
    bottom: '96%',
    zIndex: '999',
    display: 'inline-block',
    left: '18px',
    padding: '5px',
    color: '#333',
    height: '220px',
    overflow: 'hidden',
    '& h6': {
      fontSize: '16px',
      fontWeight: '300',
      lineHeight: '1.1',
      marginTop: '10px',
      marginBottom: '10px',
    },
  },
  clearChange: {
    position: 'absolute',
    right: '0',
    bottom: '5px',
    '& span': {
      cursor: 'pointer',
      '& img': {
        width: '33px',
        padding: '5px',
        verticalAlign: 'middle',
        boxSizing: 'border-box',
      },
    },
  },
  tooltipFi: {
    top: '-166px',
    left: '-74px',
    maxWidth: '282px',
    borderRadius: '20px',
    zIndex: '9',
    pointerEvents: 'all',
    cursor: 'auto',
    position: 'absolute',
    background: '#fff',
    border: '3px solid #0069aa',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down( 'md' )]: {
      left: '10px',
    },
    '& p': {
      fontSize: '16px',
      padding: '10px 20px',
      textAlign: 'left',
      lineHeight: '24px',
      fontFamily: 'MuseoSans-300',
      color: '#000',
      display: 'inline-block',
      margin: '0',
    },
    '&::before': {
      left: '30px',
      content: '""',
      borderWidth: '30px 29px 0 30px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px',
      right: '0',
      top: 'auto',
      transform: 'none',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
      zIndex: '9999',
    },
    '&::after': {
      left: '30px',
      content: '""',
      right: '0',
      top: 'auto',
      transform: 'none',
      zIndex: '9998',
      borderWidth: '32px 31px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
  },
  timelineInfo: {
    background: 'transparent',
    lineHeight: '20px',
    color: '#333333 !important',
    fontFamily: '"MuseoSans-100"',
    fontWeight: 'normal',
    fontSize: '15px !important',
    maxWidth: '250px',
    padding: '3px 8px !important',
  }, 
  handburgNone:{
    display: 'none'
  }
} );

export default styles;
