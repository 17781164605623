import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruSix = (props) => {
    const {
        classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide,handleWalkThrusBack, walkThruNo

    } = props;

    return (
        <div className={classNames(classes.worksheetWalkthru, classes.worksheetActiveWalkthru, classes.walkthruSix)}>
            <h4>Dashboard and Summary Documents</h4>
            <p>After you build some scenarios, visit the Dashboard - an interactive panel of intelligent charts, graphs and tables that transforms your data unlocking valuable insights and powerful strategies. As you enter your inputs, the Financial Configurator is hard at work building your Summary Documents such as the Income Statement, Tax Statement, Cash Flow Statement and more. Your personal financial statements bring your entire financial picture into one place and are ready for download.</p>
            <div className={classes.walkthruBottom}>
                <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />

                <div className={classes.walkThruBtns}>
                    <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(6) }}>Back</Button>
                    <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(6); }}>Next</Button>
                    <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                </div>
            </div>
        </div>
    );
};

WalkThruSix.propTypes = {
    classes: PropTypes.object.isRequired,
    handleWalkThrus: PropTypes.func.isRequired,
    handleWalkThruClose: PropTypes.func.isRequired,
    completedWalkThruSlides: PropTypes.object.isRequired,
    activeWalkThruSlide: PropTypes.string.isRequired,
    handleWalkThrusBack: PropTypes.func.isRequired,
    walkThruNo: PropTypes.string.isRequired,

};

export default withStyles(styles)(WalkThruSix);