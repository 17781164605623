import { PMT } from '../../utilities/excelLibrary';
// eslint-disable-next-line
let summationData = {};

function fedSubExistingLoanSheet( module1Data, module18Data ) {
  // Student Loan Excel Sheet
  let studentLoanData = {};
  

  // Loan Amount
  let loanAmount = 0;
  if ( typeof module18Data.existingStudentLoansFedSubBeginningBalance !== 'undefined' && module18Data.existingStudentLoansFedSubBeginningBalance !== '' ) {
    loanAmount = module18Data.existingStudentLoansFedSubBeginningBalance;
  }

  // Beginning Balance
  const beginningBalance = loanAmount;

  // Terms in Years
  let termsOfYear = 0;
  if ( beginningBalance > 0 ) {
    termsOfYear = parseFloat( module18Data.fed_sub_term );
  }

  // Annual Interest Rate
  let annualInterestRate = 0;
  if ( typeof module18Data.fed_sub_interest_rate !== 'undefined' && module18Data.fed_sub_interest_rate !== '' ) {
    annualInterestRate = parseFloat( module18Data.fed_sub_interest_rate );
  }

  // Original Payment
  let originalPayment = 0;
  if ( beginningBalance > 0
    && termsOfYear > 0 ) {
    originalPayment = PMT( ( parseFloat( annualInterestRate ) / 100 ) / 12, termsOfYear, -( beginningBalance ), 0, 0 );
  }

  // Year Repayment Begin
  const yearRepaymentBegin = parseInt( module1Data.start_year, 10 ) + parseInt( module18Data.fed_sub_months, 10 ) / 12;

  // Year repayment begin in month
  let yearRepaymentBeginMonth = parseInt( module18Data.fed_sub_months, 10 );

  // Total Interest Paid
  let totalInterestPaid = 0;

  // Total Paid
  let totalPaid = 0;

  // Tabular Data
  let graduationYear = 0;

  graduationYear = parseInt( module1Data.start_year, 10 );
  if ( graduationYear > 0 ) {
    let year = parseInt( graduationYear, 10 );
    const yearLimit = year + 29;

    // Student Loan Data
    studentLoanData = {};

    let Balance = 0;
    let Payment = 0;
    let Interest = 0;
    let Principal = 0;
    let Equity = 0;
    let TotalInterest = 0;
    let TotalPayment = 0;

    const totalInterestPaidArray = [];
    const totalPaidArray = [];
    let period = 1;
    let fedSubMonthValue = 1;
    yearRepaymentBeginMonth = parseInt( module18Data.fed_sub_months, 10 );

    // For Loop from graduation year to +10 years
    for ( year; year <= yearLimit; year += 1 ) {
      studentLoanData[year] = {};

      studentLoanData[year].LaggingInterest = 0;
      studentLoanData[year].LaggingPrincipal = 0;
      studentLoanData[year]['Months Total Balance'] = 0;

      for ( let month = 1; month <= 12; month += 1 ) {
        studentLoanData[year][month] = {};

        if ( year === graduationYear && month === 1 ) {
          studentLoanData[year][month].YearIdentifier = year;

          if ( period === parseInt( module18Data.fed_sub_months, 10 ) + 1 ) {
            Balance = beginningBalance;
          } else {
            Balance = 0;
          }
          studentLoanData[year][month].Balance = Balance;

          studentLoanData[year][month].Month = fedSubMonthValue;
          fedSubMonthValue += 1;

          if ( Balance > 0 ) {
            Payment = originalPayment;
          } else {
            Payment = 0;
          }
          studentLoanData[year][month].Payment = Payment;

          if ( Balance > 0 && annualInterestRate > 0 ) {
            Interest = Balance * ( ( annualInterestRate / 100 ) / 12 );
          } else {
            Interest = 0;
          }
          studentLoanData[year][month].Interest = Interest;

          if ( Balance > 0 ) {
            Principal = Payment - Interest;
          } else {
            Principal = 0;
          }
          studentLoanData[year][month].Principal = Principal;

          if ( Balance > 0 ) {
            Equity = Principal;
          } else {
            Equity = 0;
          }
          studentLoanData[year][month].Equity = Equity;

          if ( Balance > 0 ) {
            TotalInterest = Interest;
          } else {
            TotalInterest = 0;
          }
          studentLoanData[year][month].TotalInterest = TotalInterest;

          if ( Balance > 0 ) {
            TotalPayment = Equity + TotalInterest;
          } else {
            TotalPayment = 0;
          }
          studentLoanData[year][month].TotalPayment = TotalPayment;
        } else {
          // Year Identifier
          studentLoanData[year][month].YearIdentifier = year;

          // Balance
          let tempBalance = 0;
          if ( period === parseInt( module18Data.fed_sub_months, 10 ) + 1 ) {
            tempBalance = loanAmount;
          } else if ( ( year >= parseInt( module18Data.fed_sub_months, 10 ) + 1 ) && ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempBalance = ( Balance - Principal );
          }
          studentLoanData[year][month].Balance = tempBalance;
          studentLoanData[year][month].Month = fedSubMonthValue;
          if ( tempBalance > 0 || yearRepaymentBeginMonth > 0 ) {
            studentLoanData['Final Month'] = fedSubMonthValue;
            studentLoanData['Final Year'] = year;
            yearRepaymentBeginMonth -= 1;
            fedSubMonthValue += 1;
          } else {
            fedSubMonthValue = 0;
          }

          // Payment
          let tempPayment = 0;
          if ( tempBalance > 0 ) {
            tempPayment = originalPayment;
          } else {
            tempPayment = 0;
          }
          studentLoanData[year][month].Payment = tempPayment;

          // Interest
          let tempInterest = 0;
          if ( tempBalance > 0 && annualInterestRate > 0 ) {
            tempInterest = tempBalance * ( ( annualInterestRate / 100 ) / 12 );
          } else {
            tempInterest = 0;
          }
          studentLoanData[year][month].Interest = tempInterest;

          // Principal
          let tempPrincipal = 0;
          if ( tempBalance > 0 ) {
            tempPrincipal = tempPayment - tempInterest;
          } else {
            tempPrincipal = 0;
          }
          studentLoanData[year][month].Principal = tempPrincipal;

          // Equity
          let tempEquity = 0;
          if ( period === parseInt( module18Data.fed_sub_months, 10 ) + 1 ) {
            tempEquity = tempPrincipal;
          } else if ( tempBalance > 0 && ( year >= parseInt( module18Data.fed_sub_months, 10 ) + 1 ) ) {
            tempEquity = Equity + tempPrincipal;
          }
          studentLoanData[year][month].Equity = tempEquity;

          // Total Interest
          let tempTotalInterest = 0;
          if ( period === parseInt( module18Data.fed_sub_months, 10 ) + 1 ) {
            tempTotalInterest = tempInterest;
          } else if ( tempBalance > 0 && ( year >= parseInt( module18Data.fed_sub_months, 10 ) + 1 ) ) {
            tempTotalInterest = TotalInterest + tempInterest;
          }
          studentLoanData[year][month].TotalInterest = tempTotalInterest;

          // Total Payments
          let tempTotalPayment = 0;
          if ( period >= parseInt( module18Data.fed_sub_months, 10 ) + 1 ) {
            tempTotalPayment = tempEquity + tempTotalInterest;
          } else {
            tempTotalPayment = 0;
          }
          studentLoanData[year][month].TotalPayment = tempTotalPayment;

          // Assign Values
          Balance = tempBalance;
          Principal = tempPrincipal;
          Equity = tempEquity;
          TotalInterest = tempTotalInterest;
        }

        // Total Interest Paid Array
        totalInterestPaidArray.push( studentLoanData[year][month].TotalInterest );

        // Total Paid Array
        totalPaidArray.push( studentLoanData[year][month].TotalPayment );

        studentLoanData[year].LaggingInterest = parseFloat( studentLoanData[year].LaggingInterest ) + parseFloat( studentLoanData[year][month].Interest );
        studentLoanData[year].LaggingPrincipal = parseFloat( studentLoanData[year].LaggingPrincipal ) + parseFloat( studentLoanData[year][month].Principal );
        studentLoanData[year]['Months Total Balance'] = parseFloat( studentLoanData[year]['Months Total Balance'] ) + parseFloat( studentLoanData[year][month].Balance );

        period += 1;
      }
    }
  }

  // Summation Data
  if ( typeof module1Data.start_year !== 'undefined'
    && module1Data.start_year !== '' && parseInt( module1Data.start_year, 10 ) > 0 ) {
    let summationYear = parseInt( module1Data.start_year, 10 );
    const summationYearLimit = summationYear + 29;

    // Summation Data
    summationData = {};

    let summationBeginningBalance = 0;
    let summationLaggingInterest = 0;
    let summationLaggingPrincipal = 0;
    let summationCumulativeInterest = 0;
    let summationCumulativePrincipal = 0;
    let summationEndingBalance = 0;
    let s = 0;
    // For Loop from Start year to +29 years
    for ( summationYear; summationYear <= summationYearLimit; summationYear += 1 ) {
      summationData[summationYear] = {};

      // Beginning Balance
      if ( s === 0 ) {
        if ( summationYear <= yearRepaymentBegin ) {
          summationBeginningBalance = module18Data.fed_sub_current_balance;
        } else {
          summationBeginningBalance = 0;
        }
      } else if ( summationYear < yearRepaymentBegin ) {
        summationBeginningBalance = module18Data.fed_sub_current_balance;
      } else if ( typeof studentLoanData !== 'undefined'
            && typeof studentLoanData[summationYear] !== 'undefined'
            && typeof studentLoanData[summationYear][1].Balance !== 'undefined' ) {
        summationBeginningBalance = studentLoanData[summationYear][1].Balance;
      } else {
        summationBeginningBalance = 0;
      }
      s += 1;
      summationData[summationYear].BeginningBalance = summationBeginningBalance;

      // Lagging 12Mo. Interest
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( typeof studentLoanData[summationYear] !== 'undefined'
          && typeof studentLoanData[summationYear].LaggingInterest !== 'undefined' ) {
          summationLaggingInterest = studentLoanData[summationYear].LaggingInterest;
        } else {
          summationLaggingInterest = 0;
        }
      } else {
        summationLaggingInterest = 0;
      }
      summationData[summationYear].LaggingInterest = summationLaggingInterest;
      totalInterestPaid += summationLaggingInterest;

      // Lagging 12Mo. Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( typeof studentLoanData[summationYear] !== 'undefined'
          && typeof studentLoanData[summationYear].LaggingPrincipal !== 'undefined' ) {
          summationLaggingPrincipal = studentLoanData[summationYear].LaggingPrincipal;
        } else {
          summationLaggingPrincipal = 0;
        }
      } else {
        summationLaggingPrincipal = 0;
      }
      summationData[summationYear].LaggingPrincipal = summationLaggingPrincipal;
      totalPaid = totalPaid + summationLaggingInterest + summationLaggingPrincipal;

      // Cumulative Interest
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationCumulativeInterest += summationData[summationYear].LaggingInterest;
      } else {
        summationCumulativeInterest = 0;
      }
      summationData[summationYear].CumulativeInterest = summationCumulativeInterest;

      // Cumulative Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationCumulativePrincipal += summationData[summationYear].LaggingPrincipal;
      } else {
        summationCumulativePrincipal = 0;
      }
      summationData[summationYear].CumulativePrincipal = summationCumulativePrincipal;

      // Ending Balance
      if ( summationData[summationYear].BeginningBalance > summationData[summationYear].LaggingPrincipal ) {
        summationEndingBalance = summationData[summationYear].BeginningBalance - summationData[summationYear].LaggingPrincipal;
      } else {
        summationEndingBalance = 0;
      }
      summationData[summationYear].EndingBalance = summationEndingBalance;
    }
  }

  function studentLoanDataFun() {
    const studentLoanCompleteData = {};
    studentLoanCompleteData['Summation Data'] = summationData;
    studentLoanCompleteData['Total Interest Paid'] = totalInterestPaid;
    studentLoanCompleteData['Total Paid'] = totalPaid;
    studentLoanCompleteData['Annual Interest Rate'] = annualInterestRate;
    studentLoanCompleteData['Year Repayment Begin Month'] = yearRepaymentBeginMonth;
    studentLoanCompleteData['Fed Sub Higher Education'] = studentLoanData;
    studentLoanCompleteData['Beginning Balance'] = beginningBalance;
    studentLoanCompleteData['Terms In Year'] = termsOfYear;
    studentLoanCompleteData['Original Payment'] = originalPayment;
    studentLoanCompleteData['Monthly Payment'] = originalPayment;
    studentLoanCompleteData['Year Repayment Begin'] = yearRepaymentBegin;
	return studentLoanCompleteData;
  }
  return studentLoanDataFun();
}
export default fedSubExistingLoanSheet;
