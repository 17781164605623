import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const AmericanRescuePlan = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>American Rescue Plan</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/am-rescue-plan.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      American Rescue Plan
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/am-rescue-plan-187px.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          <i>A Finance Explained Special Edition</i>
                        </p>
                        <p>
                          The new American Rescue Plan provides a one-time tax
                          credit. Let&apos;s take a look at the amount, who
                          qualifies, and when you might receive the payment.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          The new law provides a $1,400 refundable tax credit -
                          fancy tax language for a cash payment - for
                          individuals and $2,800 for taxpayers filing jointly.
                          Plus, if you have dependent children or adults
                          including disabled adults and college students, they
                          will also qualify you for an additional $1,400 per
                          dependent. The tax credit is paid based on your filing
                          status and income. If you already filed your 2020 tax
                          return, that would determine your eligibility. If you
                          haven&apos;t filed your 2020 return yet, the IRS will
                          determine your eligibility based on your 2019 return.
                        </p>
                        <p>
                          If you file your taxes as single and earned less than
                          $75,000 in{' '}
                          <Link
                            to="/finance-explained/personal-income-taxes"
                            target="_blank">
                            adjusted gross income
                          </Link>{' '}
                          on your latest tax return, you will receive $1,400. If
                          you earned between $75,000 and $80,000 you will
                          receive a portion of the $1,400. See the table below
                          for income ranges.
                        </p>
                        <div className={classes.feTexttableFour}>
                          <table>
                            <thead>
                              <tr>
                                <th>Tax Filing Status</th>
                                <th>Tax Credit Max Amount</th>
                                <th colspan="2">Income Phaseout Range</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Single</td>
                                <td>$1,400</td>
                                <td>$75,000</td>
                                <td>$80,000</td>
                              </tr>
                              <tr>
                                <td>Married, No Children</td>
                                <td>$2,800</td>
                                <td>$150,000</td>
                                <td>$160,000</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <p>
                          It&apos;s important to note that the government will
                          actually look at your 2020 tax return to determine if
                          you should have qualified for a cash payment. I know
                          this is confusing, but it&apos;s easiest to understand
                          in an example.
                        </p>
                        <p>
                          Let&apos;s assume 2019 is your latest tax return and
                          you earned $150,000. You do not qualify for the tax
                          credit based on that year. However, in 2020 you earned
                          $50,000. The IRS would then give you a $1,400 tax
                          credit on your 2020 income tax returns.
                        </p>
                        <p>
                          On the other hand, let&apos;s say you earned $50,000
                          on your latest tax return in 2019. You receive a
                          $1,400 cash payment. In 2020, you earned $150,000.
                          Technically, you would not have qualified for the tax
                          credit, but the government will not take back that
                          payment. You will get to keep your $1,400 in that
                          scenario.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.seperatorDotBlock}>
                    <div className={classes.seperatorDots}>
                      <img src={seperatordots} alt="logo" />
                    </div>
                    <div className={classes.connectFinance}>
                      <p>Connect with Inkwiry</p>
                      <ul>
                        <li>
                          <a
                            href="https://twitter.com/inkwiry"
                            target="_blank"
                            rel="noopener noreferrer">
                            <img src={twitter} alt="logo" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/inkwiryfc/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <img src={instagram} alt="logo" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="mailto:support@inkwiry.com"
                            target="_blank"
                            rel="noopener noreferrer">
                            <img src={mail} alt="logo" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className={classes.seperatorDots}>
                      <img src={seperatordots} alt="logo" />
                    </div>
                    <div className={classes.financialBuild}>
                      <h2>See and build your best tomorrows now.</h2>
                      <Link to="/">www.inkwiry.com</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
AmericanRescuePlan.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AmericanRescuePlan);
