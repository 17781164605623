import React from 'react';
import PropTypes from 'prop-types';
import Component from './UI/component';
import data from './constants/higherEducation';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles';

const CompareHE = ( props ) => {
  const { classes, scenarioOne, scenarioTwo } = props;
  console.log(data);
  return (
    <div>
      {
        data.map(parent => (
          <Component parent={parent} scenarioOne={ scenarioOne } scenarioTwo={ scenarioTwo} classes= {classes }/>
        ))
      }
    </div>
  );
};

CompareHE.propTypes = {
  classes: PropTypes.object.isRequired,
  scenarioOne: PropTypes.object.isRequired,
  scenarioTwo: PropTypes.object.isRequired,
};
export default withStyles( styles )( CompareHE );