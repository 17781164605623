import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const walkThruDotsAll = ( props ) => {
  const {
    classes, completedWalkThruSlides, activeWalkThruSlide, setActiveWalkThru, totalSlides,
  } = props;

  return (
    <ul className={ classes.checkmarksScroll }>
      {
          totalSlides.map( ( data ) => (
            <li className={ classNames( data === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList ) } aria-hidden="true" onClick={ () => { setActiveWalkThru( data ); } } />
          ) )
        }
    </ul>
  );
};

walkThruDotsAll.propTypes = {
  classes: PropTypes.object.isRequired,
  setActiveWalkThru: PropTypes.func.isRequired,
  completedWalkThruSlides: PropTypes.object.isRequired,
  totalSlides: PropTypes.arrayOf( PropTypes.object ).isRequired,
  activeWalkThruSlide: PropTypes.number.isRequired,
};

export default withStyles( styles )( walkThruDotsAll );
