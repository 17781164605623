import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import styles from './styles';
import infoIcon from '../../assets/img/info-icon.svg';

const OtherSetting = ( props ) => {
  const { classes, updateOtherSaveSettingsData, myDetails } = props;

  const [state, setState] = useState( {
    checkedInkwiryNews: ( !!( ( myDetails && myDetails.me.account_setting && myDetails.me.account_setting.inkwiry_news === '1' ) ) ),
    checkedInkwiryPromotions: ( !!( ( myDetails && myDetails.me.account_setting && myDetails.me.account_setting.inkwiry_promotions === '1' ) ) ),
    checkedNewNotification: ( !!( ( myDetails && myDetails.me.account_setting && myDetails.me.account_setting.new_notifications === '1' ) ) ),
    checkedFinanceExplained: ( !!( ( myDetails && myDetails.me.account_setting && myDetails.me.account_setting.finance_explained === '1' ) ) ),
    checkedShowDeleteDirMessage: ( !!( ( myDetails && myDetails.me.fc_setting && myDetails.me.fc_setting.show_delete_dir_message === '1' ) ) ),
    checkedShowDeleteCareerSketch: ( !!( ( myDetails && myDetails.me.fc_setting && myDetails.me.fc_setting.show_delete_career_sketch_message === '1' ) ) ),
    checkedSaveBeforeLeavingModule: ( !!( ( myDetails && myDetails.me.fc_setting && myDetails.me.fc_setting.save_before_leaving_module === '1' ) ) ),
    checkedOpenSimulator: ( !!( ( myDetails && myDetails.me.fc_setting && myDetails.me.fc_setting.open_simulator === '1' ) ) ),
    checkedDragExistingModule: ( !!( ( myDetails && myDetails.me.fc_setting && myDetails.me.fc_setting.drag_existing_module === '1' ) ) ),
  } );

  const handleChecked = ( event ) => {
    setState( { ...state, [event.target.name]: event.target.checked } );
    updateOtherSaveSettingsData( event.target.id, event.target.checked );
  };

  return (
    <div className={ classes.billingInformation }>
      <div className={ classes.billingHistoryNewOne }>
        <div className={ classes.billingHead }>
          <h3>General Settings</h3>
        </div>
        <div className={ classes.checkBoxBlock }>
          <div className={ classes.formGroupCheckBox }>
            <Checkbox checked={ state.checkedInkwiryNews } id="inkwiry_news" onChange={ handleChecked } name="checkedInkwiryNews" />
            <p>Keep me up to date on latest Inkwiry news</p>
          </div>
          <div className={ classes.formGroupCheckBox }>
            <Checkbox checked={ state.checkedInkwiryPromotions } id="inkwiry_promotions" onChange={ handleChecked } name="checkedInkwiryPromotions" />
            <p>Keep me up to date on latest Inkwiry promotions</p>
          </div>
          <div className={ classes.formGroupCheckBox }>
            <Checkbox checked={ state.checkedNewNotification } id="new_notifications" onChange={ handleChecked } name="checkedNewNotification" />
            <p>Email me when I have a new notification</p>
          </div>
          <div className={ classes.formGroupCheckBox }>
            <Checkbox
              checked={ state.checkedFinanceExplained }
              onChange={ handleChecked }
              name="checkedFinanceExplained"
              id="finance_explained"
              inputProps={ { 'aria-label': 'checkbox with default color' } }
            />
            <p>
              Email me Finance Explained by Inkwiry

              <img
                src={ infoIcon }
                alt="infoicon"
                className={ classes.tooltipIcon }
                data-for="generalIcon"
                data-tip="Finance Explained is a weekly series that demystifies the language of finance and makes you smarter about money."
              />
            </p>
          </div>
        </div>
      </div>
      <div className={ classes.deviderNew } />
      <div className={classNames(classes.billingHistoryNew, classes.billingHistoryNew2)}>
        <div className={ classes.billingHead }>
          <h3>Important Tips in the FC</h3>
          <p className={ classes.hidle }>
            In various places throughout Inkwiry, you may be prompted with an &quot;Important Tip&quot;.
            These important tips will guide you as you enjoy all that Inkwiry has to offer.
            You can turn these important tips on and off by checking or not checking the boxes below.
            For example, if you check the first box below, an important tip will pop up when you
            attempt to delete a folder in Inkwiry Sight that may contain scenarios that you do not want
            to delete. If you uncheck the boxes below, the important tips will not appear.
          </p>
        </div>
        <div className={ `${classes.checkBoxBlock} ${classes.collbrationCheckGap}` }>
          <div className={ classes.formGroupCheckBox }>
            <Checkbox id="show_delete_dir_message" checked={ state.checkedShowDeleteDirMessage } onChange={ handleChecked } name="checkedShowDeleteDirMessage" />
            <p>Attempting to delete a folder in Inkwiry Sight that may contain scenarios that you do not want to delete.</p>
          </div>
          <div className={ classes.formGroupCheckBox }>
            <Checkbox id="show_delete_career_sketch_message" checked={ state.checkedShowDeleteCareerSketch } onChange={ handleChecked } name="checkedShowDeleteCareerSketch" />
            <p>Attempting to delete a career sketch in Inkwiry Sight does not delete any scenarios. You are simply deleting the grouping of those scenarios that makes up that sketch.</p>
          </div>
          <div className={ classes.formGroupCheckBox }>
            <Checkbox id="save_before_leaving_module" checked={ state.checkedSaveBeforeLeavingModule } onChange={ handleChecked } name="checkedSaveBeforeLeavingModule" />
            <p>Attempting to leave a module without saving your changes.</p>
          </div>
          <div className={ classes.formGroupCheckBox }>
            <Checkbox id="open_simulator" checked={ state.checkedOpenSimulator } onChange={ handleChecked } name="checkedOpenSimulator" />
            <p>Attempting to access the Simulator Panel without saving your changes.</p>
          </div>
          <div className={ classes.formGroupCheckBox }>
            <Checkbox id="drag_existing_module" checked={ state.checkedDragExistingModule } onChange={ handleChecked } name="checkedDragExistingModule" />
            <p>Dragging and dropping the Existing Assets & Liabilities module to the timeline.</p>
          </div>
        </div>
      </div>
      <ReactTooltip id="generalIcon" place="top" type="light" effect="solid" className={ `${classes.tooltipInfo} ${classes.generalTooltip}` } />
      <ReactTooltip id="infoIcon" place="top" type="light" effect="solid" className={ `${classes.tooltipInfo} ${classes.borderTopColor}` } />
    </div>
  );
};

OtherSetting.propTypes = {
  classes: PropTypes.object.isRequired,
  myDetails: PropTypes.object.isRequired,
  updateOtherSaveSettingsData: PropTypes.func.isRequired,
};

export default withStyles( styles )( OtherSetting );
