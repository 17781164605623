import React from 'react';
import Sight from '../../components/sight';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

const SightPage = ( props ) => {
  const {history} = props
  const sightData = {
    from: 'page',
  };

  const compareCallback=(data)=>{
    const csData = JSON.stringify(data);
    localStorage.setItem('compareScenario_data',csData);
    if(data.selected_module === 'module1'){
      history.push('/financial-independence')
    }
    if(data.selected_module === 'module2'){
      history.push('/higher-education')
    }
    if(data.selected_module === 'module4'){
      history.push('/career-path')
    }
    if(data.selected_module === 'module5'){
      history.push('/career-advancement')
    }
    if(data.selected_module === 'module6'){
      history.push('/more-higher-education')
    }
    if(data.selected_module === 'module8'){
      history.push('/additional-career-advancement')
    }
    if(data.selected_module === 'module9'){
      history.push('/investments')
    }
    if(data.selected_module === 'module10'){
      history.push('/family')
    }
    if(data.selected_module === 'module11'){
      history.push('/vacation')
    }
    if(data.selected_module === 'module12'){
      history.push('/buying-a-car')
    }
    if(data.selected_module === 'module13'){
      history.push('/buying-a-home')
    }
    if(data.selected_module === 'module14'){
      history.push('/rei')
    }
    if(data.selected_module === 'module15'){
      history.push('/further-higher-education')
    }
    if(data.selected_module === 'module17'){
      history.push('/further-career-advancement')
    }
    if(data.selected_module === 'module18'){
      history.push('/existing-assets-liabilities')
    }
  }

  const notesCallBack = (d) =>{
    const nsData = JSON.stringify(d);
    localStorage.setItem('notes_data',nsData);
    if(d.data.selected_module === 'module1'){
      history.push('/financial-independence')
    }
    if(d.data.selected_module === 'module2'){
      history.push('/higher-education')
    }
    if(d.data.selected_module === 'module4'){
      history.push('/career-path')
    }
    if(d.data.selected_module === 'module5'){
      history.push('/career-advancement')
    }
    if(d.data.selected_module === 'module6'){
      history.push('/more-higher-education')
    }
    if(d.data.selected_module === 'module8'){
      history.push('/additional-career-advancement')
    }
    if(d.data.selected_module === 'module9'){
      history.push('/investments')
    }
    if(d.data.selected_module === 'module10'){
      history.push('/family')
    }
    if(d.data.selected_module === 'module11'){
      history.push('/vacation')
    }
    if(d.data.selected_module === 'module12'){
      history.push('/buying-a-car')
    }
    if(d.data.selected_module === 'module13'){
      history.push('/buying-a-home')
    }
    if(d.data.selected_module === 'module14'){
      history.push('/rei')
    }
    if(d.data.selected_module === 'module15'){
      history.push('/further-higher-education')
    }
    if(d.data.selected_module === 'module17'){
      history.push('/further-career-advancement')
    }
    if(d.data.selected_module === 'module18'){
      history.push('/existing-assets-liabilities')
    }   
  }
  
  return (
    <Sight passData={ sightData } compareCallback={compareCallback} notesCallBack={notesCallBack}/>
  );
};

SightPage.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(  
  withRouter,
);

export default enhance(SightPage);
