import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-aca/styles';
import carrerPicture from '../../../assets/img/career-path/picture1_1_careerpath.png';
import carrerPictureNew from '../../../assets/img/career-path/picture2_1_careerpath.png';

const ChoosePath = ( props ) => {
  const {
    classes, handleDoubleClickRole, handleCareerObject,handleUpdatedObject, setRole, role,
  } = props;

  useEffect(()=>{
    const updatedObj = { ...handleCareerObject };
    if(handleCareerObject.start_year !== undefined){
    if(handleCareerObject.start_year !== new Date().getFullYear()){
      setRole('futureMove');
      updatedObj.role = 'futureMove';
      handleUpdatedObject( updatedObj );
    }
    }
    // eslint-disable-next-line
  },[]);

  const handleRole = ( type ) => {
    const updatedObj = { ...handleCareerObject };
    setRole( type );
    updatedObj.role = type;
    handleUpdatedObject( updatedObj );
  };

  return (
    <div className={ classes.contentBlock }>

      <div>
          <p>
            You have your career advancement lined up. Good job. But, what&apos;s your next move? Is there a level of certification that you can achieve that will propel you to a new level in your career? What experience will you need to get to that next level?
          </p>
          <p>
            Get your details into the
            {' '}
            {' '}
            {' '}
            {' '}
            <font>Additional Career Advancement</font>
            {' '}
            {' '}
            module and find answers to these tough questions and many, many more.
          </p>
        </div>
      <div className={ `${`${classes.fourIconsBlock} ${classes.fourIconsBlockNewThree}`}` }>
        <ul>
          <li className={ ( handleCareerObject.role === 'currentRole' || role === 'currentRole' ) ? classes.active : classes.unactiveBox } aria-hidden="true" onClick={ () => handleRole( 'currentRole' ) } onDoubleClick={ () => handleDoubleClickRole( 'currentRole' ) }>
            <figure>
              <img src={ carrerPicture } alt="" />
            </figure>
            <span className={ classes.colorBlue }>Current Role</span>
          </li>
          <li className={ ( handleCareerObject.role === 'futureMove' || role === 'futureMove' ) ? classes.active : classes.unactiveBox } aria-hidden="true" onClick={ () => handleRole( 'futureMove' ) } onDoubleClick={ () => handleDoubleClickRole( 'futureMove' ) }>
            <img src={ carrerPictureNew } alt="" />
            <span>Future Career Move</span>
          </li>

        </ul>
      </div>
    </div>

  );
};

ChoosePath.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleDoubleClickRole: PropTypes.func.isRequired,
  setRole: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,

};

export default withStyles( styles )( ChoosePath );
