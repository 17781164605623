import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const LifeInsurance = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Life Insurance 101</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/life-insurance.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Life Insurance 101
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/life%20insurance-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Life insurance is an uncomfortable financial topic
                          because - who likes talking about their potential
                          death? In reality, life is unpredictable, and things
                          happen that are out of our control. That&apos;s why
                          life insurance was invented - it protects your loved
                          ones and those who depend on you for money in the case
                          of an unexpected death. If anyone relies on you
                          financially, life insurance is a must-have.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          There are a number of differences between your life
                          insurance options, but the concept is the same for all
                          of them. You pay a set premium to an insurance company
                          and if you die while your policy is active, the
                          insurance company will pay the designated funds - a
                          death benefit or lump-sum payment - to your
                          beneficiaries.
                        </p>
                        <p>
                          There are many types of life insurance, but the most
                          common are term life and whole life:
                        </p>
                        <ul>
                          <li>
                            <b>Term life insurance</b> provides financial
                            protection for a specific period of time ranging
                            anywhere from 10 to 40 years, usually in exchange
                            for monthly premiums
                          </li>
                          <li>
                            <b>Whole life insurance</b> provides financial
                            protection for, as it sounds, your entire life in
                            exchange for monthly premiums that are higher than
                            term life.
                          </li>
                        </ul>
                        <p>
                          <b>Term life or whole life insurance?</b>
                          <br />
                          Since term life insurance covers a specific period of
                          time ranging from 10 to 40 years, the monthly premiums
                          are significantly lower than most whole life insurance
                          policies. If you have a term life policy and die
                          during the term, your beneficiaries will receive the
                          death benefit. If you don&apos;t die during the term,
                          the policy has no cash value and expires. Term life
                          insurance is{' '}
                          <a
                            href="https://www.quotacy.com/"
                            target="_blank"
                            rel="noopener noreferrer">
                            more commonly selected
                          </a>{' '}
                          than whole life since it is more affordable and has
                          long-term coverage options.
                        </p>
                        <p>
                          Whole life insurance premiums are much more expensive
                          because:
                        </p>
                        <ul>
                          <li>
                            Whole life provides coverage for your entire life
                          </li>
                          <li>
                            Your monthly premiums have a cash value and earn
                            interest at a fixed rate.
                          </li>
                        </ul>
                        <Table className={classes.insuranceTable}>
                          <thead>
                            <tr className={classes.emptyBlueRow}>
                              <th colSpan="3" />
                            </tr>
                            <tr>
                              <th>Key Features</th>
                              <th>Term Life Insurance</th>
                              <th>Whole Life Insurance</th>
                            </tr>
                            <tr className={classes.emptyBlueRow}>
                              <th colSpan="3" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Coverage Period</td>
                              <td>10-40 years</td>
                              <td>For life</td>
                            </tr>
                            <tr>
                              <td>Monthly Premium Cost*</td>
                              <td>
                                $15 to $55 per month for a
                                <br />
                                30-year term started at age 30
                              </td>
                              <td>
                                $180 to $775 per month
                                <br />
                                started at age 30; <b>12-15x</b>
                                <br />
                                <b>higher than term life</b>
                              </td>
                            </tr>
                            <tr>
                              <td>Death Benefit Value</td>
                              <td>
                                Range from $50,000 to
                                <br />
                                $25,000,000
                              </td>
                              <td>
                                Range from $50,000 to
                                <br />
                                $25,000,000
                              </td>
                            </tr>
                            <tr>
                              <td>Death Benefit Guaranteed</td>
                              <td>Yes</td>
                              <td>Yes</td>
                            </tr>
                            <tr>
                              <td>Cash Value</td>
                              <td>None</td>
                              <td>Yes</td>
                            </tr>
                            <tr>
                              <td>How Cash Value Grows</td>
                              <td>--</td>
                              <td>
                                Usually earns low interest at a
                                <br />
                                fixed rate
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <p className={classes.sourceText}>
                          <i>
                            *Source:{' '}
                            <a
                              href="https://www.quotacy.com/"
                              target="_blank"
                              rel="noopener noreferrer">
                              Quotacy
                            </a>
                            ; lowest three rates for each age and policy type
                            averaged.
                          </i>
                        </p>
                        <p>
                          <b>Key terms</b>
                          <br />
                          Just like health insurance, life insurance has key
                          terms to understand such as death benefit,
                          beneficiary, and monthly premium:
                        </p>
                        <ul>
                          <li>
                            <b>Death benefit</b> is the fixed amount of money
                            that will be paid to your beneficiaries if you die
                            while your life insurance policy is active. A good
                            rule of thumb would be 12-15x your annual income.
                          </li>
                          <li>
                            <b>Beneficiaries</b> are the people you select to
                            receive your death benefit.
                          </li>
                          <li>
                            <b>Monthly premium</b> is the amount you pay to keep
                            your health insurance policy active.
                          </li>
                        </ul>
                        <p>
                          Life insurance is a valuable financial asset that
                          replaces a person’s income and protects loved ones
                          from financial trouble in case of an unexpected death.
                          The plan is to never have to use life insurance, but
                          life insurance provides peace of mind knowing that
                          loved ones are protected in case of tragedy.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
LifeInsurance.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LifeInsurance);
