import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import ReactTooltip from 'react-tooltip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  Dialog, DialogContent,DialogTitle,DialogActions
} from '@material-ui/core';
// import logo1 from '../../assets/img/logo-footer.png';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Slider from 'react-slick';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import styles from './styles';
import Slide1 from '../../assets/img/robert-story/robert_story_img_1.jpg';
import robertStory3 from '../../assets/img/robert-story/robert_story_img_3.jpg';
import SlideRobert from '../../assets/img/robert-story/robert_story_img_2.png';
import RobertSlide5 from '../../assets/img/robert-story/robert_story_img_5.jpg';
import robertStory4 from '../../assets/img/robert-story/robert_story_img_4.jpg';
import RobertSlide6 from '../../assets/img/robert-story/robert_story_img_6.jpg';
import RobertSlide7 from '../../assets/img/robert-story/robert_story_img_7.jpg';
import RobertSlide8 from '../../assets/img/robert-story/robert_story_img_8.jpg';
import RobertSlide9 from '../../assets/img/robert-story/robert_story_img_9.jpg';
import RobertSlide10 from '../../assets/img/robert-story/robert_story_img_10.jpg';

import RobertSlide11 from '../../assets/img/robert-story/robert_story_img_11.jpg';
import forwordIcon from '../../assets/img/mike-story/share_icon.png';
import replayIcon from '../../assets/img/mike-story/dollar-icon.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';

// summary table images

import logoNew from '../../assets/img/mike-story/logo.png';
import graphNew from '../../assets/img/mike-story/graph-new.png';
import TimeLineList from '../../components/time-line/time-line-list';
import NetworthRangeGraph from '../../components/time-line/networth-range-graph';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import getCalculatedValues from '../../calculations/calculated-values';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SummaryDocs from '../../components/documents/documents';
import Summarytable from '../../components/documents/summary-tables';

const MODULE_DATA = loader( '../../graphql/schema/fc/story-module-data.graphql' );

const settings = {
  dots: true,
  infinite: true,
  speed: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Robert = ( props ) => {
  const { classes, history } = props;

  const [forwardPopup, setForwordPopup] = useState( false );
  const [replyPopup, setReplyPopup] = useState( false );
  const [higherEducationPopup, setHigherEducationPopup] = useState( false );
  // const [careerPathPopup, setCareerPathPopup] = useState( false );
  // const [investmentsPopup, setInvestmentsPopup] = useState( false );
  // const [careerAdvancementPopup, setCareerAdvancementPopup] = useState( false );
  // const [additionalCareerAdvancementPopup, setAdditionalCareerAdvancementPopup] = useState( false );
  // const [familyPopup, setFamilyPopup] = useState( false );
  // const [vacationPopup, setVacationPopup] = useState( false );
  // const [buyingCarPopup, setBuyingCarPopup] = useState( false );
  // const [furtherCareerAdvancementPopup, setFurtherCareerAdvancementPopup] = useState( false );
  // const [buyingHomePopup, setBuyingHomePopup] = useState( false );
  const [loading, setLoading] = useState( false );
  const [moduleTimeLine, setModuleTimeLine] = React.useState( [] );
  const [openDocsPopup, setOpenDocsPopup] = React.useState( '' );
  const [rangeSliderValues, setRangeSliderValues] = React.useState( { income: 0, expenses: 0 } );
  const [loadedValues, setLoadedValues] = React.useState( {} );
  const [activeSlide, setActiveSlide] = React.useState( 0 );
  const [SummaryPopup, setSummaryPopup] = useState( false );
  const [linkId, setLinkId] = useState( false );

  const placeTimeLine = ( type, status = '' ) => {
    if ( type === 'default' ) {
      const displayTimeLine = [];
      if ( status === 'not-empty' ) {
        const order = timeLineActions.moduleTimelineData;
        order.forEach( ( value ) => {
          if ( parseInt( value.scenario_id, 10 ) > 0 || value.action === 'CS' ) {
            displayTimeLine.push( value );
          }
        } );
      } else {
        displayTimeLine.push( {
          action: 'CS',
          module_time_line_order: 0,
          scenario_id: 0,
          selected_module: 'module1',
          time_line_order: 0,
        } );
      }
      setModuleTimeLine( displayTimeLine );
    }
  };

  useQuery( MODULE_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      story: 'robert',
    },
    onCompleted( response ) {
      timeLineActions.assignModulesData( response.getStoryModuleData, ( data ) => {
        placeTimeLine( 'default', 'not-empty' );
        setLoading( false );
        setLoadedValues( getCalculatedValues() );
      } );
    },
    onError() {
      timeLineActions.assignModulesData( '', ( data ) => {
        placeTimeLine( 'default', 'empty' );
        setLoading( false );
      } );
    },
  } );

  const handleClose = () => {
    history.push( '/' );
  };

  const handleForwordPopup = () => {
    
    navigator.clipboard.writeText(`${ (window.location.href).split('career-story')[0] }?email=robert`);
    setForwordPopup( !forwardPopup );
  };

  const handleReplyPopup = () => {
    setReplyPopup( !replyPopup );
  };

  const handleSummaryPopup = (id) => {
    setSummaryPopup( !SummaryPopup );
    setLinkId(id);
  };

  const handleHigherEducationPopup = () => {
    setHigherEducationPopup( !higherEducationPopup );
  };

  // const handleCareerPathPopup = () => {
  //   setCareerPathPopup( !careerPathPopup );
  // };

  // const handleInvestmentsPopup = () => {
  //   setInvestmentsPopup( !investmentsPopup );
  // };

  // const handleCareerAdvancementPopup = () => {
  //   setCareerAdvancementPopup( !careerAdvancementPopup );
  // };

  // const handleAdditionalCareerAdvancementPopup = () => {
  //   setAdditionalCareerAdvancementPopup( !additionalCareerAdvancementPopup );
  // };

  // const handleFamilyPopup = () => {
  //   setFamilyPopup( !familyPopup );
  // };

  // const handleVacationPopup = () => {
  //   setVacationPopup( !vacationPopup );
  // };

  // const handleBuyingCarPopup = () => {
  //   setBuyingCarPopup( !buyingCarPopup );
  // };

  // const handleFurtherCareerAdvancementPopup = () => {
  //   setFurtherCareerAdvancementPopup( !furtherCareerAdvancementPopup );
  // };

  // const handleBuyingHomePopup = () => {
  //   setBuyingHomePopup( !buyingHomePopup );
  // };

  useEffect( () => {
    initializeExcelSheetsData();
    workSheetCalc();
    // eslint-disable-next-line
  } );

  const updateRangeValue = ( e, type ) => {
    const rangeSlider = { ...rangeSliderValues };
    rangeSlider[type] = e.target.value;
    setRangeSliderValues( rangeSlider );
  };

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } />}
      <div className={ classes.sliderStory }>
        <div className={ classes.mikeStoryIcons }>
          <div role="button" onClick={ handleForwordPopup } tabIndex={ -1 } aria-hidden="true">
            <img src={ forwordIcon } alt="" data-for="mikeStoryTool" data-tip="Click to copy share link" />
          </div>
          <div role="button" onClick={ handleReplyPopup } tabIndex={ 0 } aria-hidden="true">
            <img src={ replayIcon } alt="" data-for="mikeStoryTool" data-tip="Summary Documents" />
            {replyPopup && (
            <ul className={ classes.newText }>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'summary-output' ); handleReplyPopup(); } }>Summary Output</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'inputsheet-statement' ); handleReplyPopup(); } }>Summary Financials</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'summary-table' ); handleReplyPopup(); } }>Summary Tables</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'income-statement' ); handleReplyPopup(); } }>Income Statement</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'tax-statement' ); handleReplyPopup(); } }>Tax Statement</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'balance-statement' ); handleReplyPopup(); } }>Balance Sheet</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'cashflow-statement' ); handleReplyPopup(); } }>Cash Flow Statement</p></li>
            </ul>
            )}
          </div>
        </div>
        <Slider { ...settings } afterChange={ ( e ) => { setActiveSlide( e ); } }>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ Slide1 } />
                  <div className={ classes.InnerStoryLEft }>
                    <h2>ROBERT</h2>
                    <h2>Car Salesman</h2>
                    <p>15-Year Net Worth*: $635,997</p>
                  </div>
                  <span className={ classes.bottomSpanTextLeft }>
                    *Net worth: What you own (assets) minus what you owe (liabilities).
                  </span>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightBlock }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.imageBlock }>
                    <img src={ SlideRobert } alt="" />
                  </div>
                  <div className={ classes.rightBlockNew }>
                    <h3>Robert&apos;s Life</h3>
                    <p>Following his passion for cars, Robert completes sales training, works long hours, is promoted to Sales Manager, and buys his dream sports car.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.blueBox }>
                  <h3>Robert&apos;s Start Year – 2019</h3>
                  <h4>(18 years old)</h4>
                  <div className={ classes.careerSave }>
                    <p>
                      <span>START:</span>
                      {' '}
                      $1,250 in savings
                    </p>
                    <ul>
                      <li>
                        <p>2019 – 2020: Completes training program and begins selling automobiles.</p>
                      </li>
                      <li>
                        <p>2019 – 2026: Leases car #1 and car #2</p>
                      </li>
                      <li>
                        <p>2020: Begins making investments and retirement contributions</p>
                      </li>
                      <li>
                        <p>2020: Begins taking annual vacations</p>
                      </li>
                      <li>
                        <p>2021 – 2023: Car salesman</p>
                      </li>
                      <li>
                        <p>2024 – 2030: Moves to new dealership and sells higher end vehicles</p>
                      </li>
                      <li>
                        <p>2027: Purchases a luxury sport coupe</p>
                      </li>
                      <li>
                        <p>2031: Promoted to Sales Manager</p>
                      </li>
                      <li>
                        <p>2031: Takes advanced driving course</p>
                      </li>
                      <li>
                        <p>2032: Buys a home</p>
                      </li>
                    </ul>
                    <h3>2033 Net Worth: $635,997</h3>
                  </div>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Introducing Robert</h3>
                    <p>
                      If it has wheels and an engine, Robert is captivated.
                      The youngest of seven kids, he picked up on his older brothers&apos; interests early and could recite the specs of a Porsche boxer engine by the age of nine.
                      At the same time, Robert seemed to hold little interest for higher education. After high school graduation,
                      he was happy to be finished and immediately searched for a way to jump directly into the job market.
                      A conversation with the manager of an auto dealership sparked a new vision for his future, one in which he could spend all day talking about and being around cars,
                      while selling a few to satisfied customers.
                    </p>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Your passion can drive your career choice.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Robert leverages his passion for automobiles and reaches out to experts in his field of interest.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ robertStory3 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Robert hits the sales floor</h3>
                    <p>
                      Robert completes a three-month training program offered by the same local car dealership near Dayton, OH.
                      He decides to continue living at home with his parents, not only to save money but for added support as he tests his career path.
                      Robert hits the sales floor with enthusiasm and the natural persuasiveness he&apos;s developed as the youngest sibling.
                      He grinds through the first year with limited success, honing his craft and establishing a few clients who generate referrals.
                      In his first year, Robert receives salary plus commission during training, and all commission thereafter, for a total of $40k.
                    </p>

                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>An employer-provided vocational training program can provide the knowledge needed for a specific job.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Robert completes short-term training, avoids student loan debt and gets right to work.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ robertStory4 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Robert leases his first car</h3>
                    <p>
                      Robert leases his first car, a compact sedan made even more attractive by the perks he receives from the dealership:
                      discounts on the price, service and parts. He continues to build his client base and rack up sales of medium-brand vehicles,
                      and starts taking an annual vacation – a road trip to beaches in North Carolina.
                    </p>

                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Run the numbers to see if an auto lease is more advantageous than a car purchase. Remember to take time to enjoy life.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Robert leases a compact sedan, taking advantage of the dealership&apos;s offerings. He plans a lengthy road trip to his favorite destination.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ RobertSlide5 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Disciplined and determined</h3>
                    <p>
                      Robert researches every detail of the vehicles in the showroom and truly enjoys connecting his customers with the perfect car for their needs.
                      He still works lots of late nights and, like most sales representatives, copes with many lost leads.
                      Yet he is disciplined and determined to become Sales Manger. After two years living at home, he feels confident about his career,
                      moves out and begins sharing an apartment with a friend.
                    </p>

                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Success comes more easily to those who are focused on a goal and continuously working toward it.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Robert works long hours and never stops researching ways to improve his performance and his relationships with customers. He feels confident enough to move into a shared apartment.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ RobertSlide6 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Robert saves and invests</h3>
                    <p>
                      Beginning in 2020, Robert contributes 5% to a 401(k) retirement plan with a 50% employee match capped at 5%.
                      He sets his sights on a luxury sport coupe, his dream car, and puts aside $100 each month toward its purchase.
                      Meanwhile, he takes advantage of the dealer discounts and upgrades his leased vehicle to drive a middle-tier sedan.
                    </p>

                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Start saving for retirement early to capitalize on compounding interest. Put aside a set amount each month for special purchases.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Robert contributes to a 401(k) retirement plan and sets aside $100 each month for his dream car – a luxury sport coupe.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ RobertSlide7 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Robert makes a move</h3>
                    <p>
                      Robert improves his sales in the third year, earning $60k.
                      In 2024, he accepts a new offer, moves to a dealership near Cincinnati and is excited to start selling higher end cars.
                      Not only does his salary rise, but his fixed-payment-per-sale goes up as well, and his income increases 18% to $85k.
                    </p>

                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Success provides for new opportunities.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Robert leverages his sales success and experience to join a dealership that sells higher end vehicles, boosting his career potential and his income.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ RobertSlide8 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Robert buys his dream car</h3>
                    <p>
                      In 2027, after six years of hard work, frugal living, and smart investing, Robert buys his dream car,
                      a new sport coupe. For this, his first loan, he puts 50% down, takes advantage of attractive lending rates,
                      and receives an extra discount from the dealership, including discounted pricing, service and parts.
                    </p>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Steady progress toward your goals makes it much more likely that you will achieve them.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Robert&apos;s strategies pay off as he buys his dream car.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ RobertSlide9 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Robert achieves his goal</h3>
                    <p>
                      In 2031, Robert achieves his goal as he is promoted to Sales Manager – the dealership recognizes his determination,
                      dependability and ability to work effectively with other salespeople as well as their high-end customers.
                      Robert treats himself with a special $1,000 purchase: an advanced driving course at a speedway in Connecticut.
                    </p>
                    {/* <p>What happens next?</p> */}
                  </div>
                  {/* <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Family" aria-hidden="true" onClick={ handleFamilyPopup }>
                      <img alt="" src={ MikeSlide7Inner } />
                    </figure>
                    <figure data-for="mikeStoryTool" data-tip="Buying a Car" aria-hidden="true" onClick={ handleBuyingCarPopup }>
                      <img alt="" src={ MikeSlide8Inner2 } />
                    </figure>
                  </div> */}
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Robert recognizes that his determination, dependability and teamwork, coupled with vision, pays dividends.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Robert enjoys the rewards of his hard work.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ RobertSlide10 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Robert buys a home</h3>
                    <p className={ classes.paddingBottomPara }>
                      A year after his promotion, Robert notices a house for sale not far from his old neighborhood.
                      He purchases the $400k home, which includes a roomy garage for his sport coupe in Lebanon, Ohio.
                      He now has a 30-minute commute to work, but he enjoys every minute of the drive time!
                    </p>
                    <p>What happens next?</p>
                  </div>
                  {/* <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Buying a Home" aria-hidden="true" onClick={ handleBuyingHomePopup }>
                      <img alt="" src={ MikeSlide9Inner } />
                    </figure>
                  </div> */}
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Saving early can be worth it, and your savings can provide certain extras.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Robert buys a home with a roomy garage.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>

            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 12 }>
                <div className={ classes.blueBoxLeftBorder }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryFullTop }>
                    <div className={ classes.mikestoryFullTopLeft }>
                      <h3>Robert&apos;s Net Worth</h3>
                      <ul>
                        <li>What happens if Robert earns 10% more income in his plan?</li>
                        <li>What happens if he spends 5% less?</li>
                        <li>Drag the income and expenses sliders and see the impact on his net worth.</li>
                      </ul>
                    </div>
                    <div className={ classes.rangeBlock }>
                      <input type="range" min="-20" max="20" value={ rangeSliderValues.income } step="5" onChange={ ( e ) => { updateRangeValue( e, 'income' ); } } />
                      {rangeSliderValues.income}
                      <input type="range" min="-20" max="20" value={ rangeSliderValues.expenses } step="5" onChange={ ( e ) => { updateRangeValue( e, 'expenses' ); } } />
                      {rangeSliderValues.expenses}
                      {/* <img src={ MikeSlideGraph2 } alt="" />
                      <div className={ classes.infoIcon } data-for="mikeStoryTool" data-tip="When you move the slider above, the FC is automatically updating each input in every module related to career income. Then, the FC reruns all calculations, including taxes, and displays the updated figures in the chart below.. All at the speed of thought.">
                        <img src={ infoIcon } alt="infoicon" />
                      </div> */}
                    </div>
                  </div>
                  <div className={ classes.graphBlock }>
                    <NetworthRangeGraph graphSlide={ 10 } activeSlide={ activeSlide } defaultValues={ loadedValues } rangeValues={ rangeSliderValues } />
                    {/* <img src={ MikeSlideGraph } alt="" /> */}
                    {/* <div className={ classes.infoIcon } data-for="mikeStoryTool" data-tip="When you move the slider above, the FC is automatically updating each input in every module related to living expenses. Then, the FC reruns all calculations and displays the updated figures in the chart below. All at the speed of thought.">
                      <img src={ infoIcon } alt="infoicon" />
                    </div> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>

            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 12 }>
                <div className={ classes.blueBoxLeftBorder }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryFullTop }>
                    <div className={ classes.mikestoryFullTopLeft }>
                      <h3>
                        Robert&apos;s Timeline
                        <img className={ classes.infoIcon } data-for="mikeStoryToolBottom" data-tip="The timeline on this page is live! Scroll left and right to view the summary details that make up this sketch. Double click on any module icon to go to that module and view all the inputs for that scenario." src={ infoIcon } alt="infoicon" />
                      </h3>

                      <ul>
                        <li>Scroll over the life events and discover the details in this career sketch.</li>
                        <li>
                          Explore Robert&apos;s financial life plan by module to see how he acheives his goals
                        </li>
                      </ul>
                    </div>
                    <div className={ classes.rangeBlock }>
                      <h4>Robert - Car Salesman</h4>
                      <h4>Net Worth:  $635,997</h4>
                    </div>
                  </div>
                  <div className={ classNames( classes.container, classes.careerSketchTimelineInCnt, classes.relativeBlock ) }>
                    <TimeLineList timeLineData={ moduleTimeLine } page="story" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ RobertSlide11 } />
                  <div className={ classes.ovelayBoxLast }>
                    <h3>
                      Robert&apos;s Life:
                      <br />
                      As of 2033
                      {'   '}
                      <span>(32 years old)</span>
                    </h3>
                    <ul>
                      <li>Cash Savings</li>
                      <li>Investments</li>
                      <li>Sales Training</li>
                      <li>On-the-Job Education</li>
                      <li>Work Experience</li>
                      <li>A Career</li>
                      <li>A Home</li>
                      <li>Multiple Car Leases</li>
                      <li>A Luxury Sport Coupe</li>
                      <li>Annual Vacations</li>
                      <li>A Great Future</li>
                    </ul>
                    <p>2033 Net Worth: $635,997</p>
                  </div>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryLast }>
                    <h3>Robert&apos;s KPIs in 2033</h3>
                    <ul>
                      <li>Debt-to-Income: 15.9%</li>
                      <li>Monthly Liquidity Ratio: 6.6x</li>
                      <li>Current Ratio: 1.9x</li>
                      <li>Savings Rate: 27.3%</li>
                      <li>Investment Assets to Total Assets: 52.6%</li>
                      <li>Total Assets to Total Debt: 3.0x</li>
                      <li>Solvency Ratio: 0.7x</li>
                    </ul>
                    <a href="https://inkwiry.com/kpi-glossary" target="_blank" rel="noopener noreferrer">KPI Reference Guide</a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

        </Slider>
      </div>
      <ReactTooltip id="mikeStoryTool" place="top" effect="solid" className={ classes.tooltipInfoTopNewText2 } />
      <ReactTooltip id="mikeStoryToolBottom" place="bottom" effect="solid" className={ classes.tooltipInfoTopNewTextBottom } />
      <div>
        <Dialog
          open={ forwardPopup }
          onClose={ handleForwordPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={ classes.replayPopup }
        >
          <DialogContent className={ classes.replayPopupInner }>
            <div aria-hidden="true" onClick={ handleForwordPopup } className={ classes.closeIconPop }><i className="la la-close" /></div>
            <div className={ classes.paymentPoupIcon }>
              <span />
              <p>Link successfully copied.</p>
            </div>

          </DialogContent>
        </Dialog>
      </div>
      {/* <div>
        <Dialog
          open={ replyPopup }
          onClose={ handleReplyPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div aria-hidden="true" onClick={ handleReplyPopup }>close</div>
            Reply popup
          </DialogContent>
        </Dialog>
      </div> */}
      <div>
        <Dialog
          open={ higherEducationPopup }
          onClose={ handleHigherEducationPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={ classes.summaryTablePopup }
        >
          <DialogContent>
            <div className={ classes.summaryTableHeader }>
              <img src={ logoNew } alt="" />
              <h3>Summary Tables</h3>
            </div>
            <div className={ classes.summaryTableContent }>
              <div className={ classes.senarioGroup }>
                <h3 className={ classes.mainHead }>Financial Independence (2019-2033)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <p>2019-Registered Architect-01.25.19</p>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Start Year</span>
                        <span>2019</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Income Growth</span>
                        <span>2019</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Inflation</span>
                        <span>3.00%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Current Cash Balance</span>
                        <span>1.80%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Max Cash Balance before Investment</span>
                        <span>$2,000</span>
                      </div>
                    </div>
                  </Grid>
                  <Grid sm={ 8 }>
                    <div className={ classes.graphBox }>
                      <h3>Grow Your Net Worth: What&apos;s Your 5, 10, 15 Year Number?</h3>
                      <img src={ graphNew } alt="" />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.highrEducationHead}` }>Higher Education (2019-2023)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2019-2023-Registered Architect-01.25.19</p>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Type of Higher Education</span>
                        <span>College</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Name of College</span>
                        <span>Virginia Tech</span>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Inflation</span>
                          <span>3.00%</span>
                        </div>
                        <p>Years</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Inflation</span>
                          <span>3.00%</span>
                        </div>
                        <p>Years</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Inflation</span>
                          <span>3.00%</span>
                        </div>
                        <p>Years</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Student Loan Payments during College</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Existing Student Loan Payments</td>
                            <td>0</td>

                          </tr>
                          <tr>

                            <td>College Student Loan Payments</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>College Student Loan Payments</td>
                            <td>0</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Total Cost of Attendance</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Tuition & Fees</td>
                            <td>$167,982</td>
                          </tr>
                          <tr>
                            <td>Room & Board</td>
                            <td>$17,728</td>
                          </tr>
                          <tr>
                            <td>On Campus Living Expenses</td>
                            <td>$17,799</td>
                          </tr>
                          <tr>
                            <td>Off Campus Living Expenses</td>
                            <td>$69,639</td>
                          </tr>
                          <tr>
                            <td>Total Student Loan Payments during College</td>
                            <td>$13,649</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Cost of Attendance</td>
                            <td>$286,795</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Total Expenses</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Tuition & Fees</td>
                            <td>$167,982</td>
                          </tr>
                          <tr>
                            <td>Room & Board</td>
                            <td>$17,728</td>
                          </tr>
                          <tr>
                            <td>On Campus Living Expenses</td>
                            <td>$17,799</td>
                          </tr>
                          <tr>
                            <td>Off Campus Living Expenses</td>
                            <td>$69,639</td>
                          </tr>
                          <tr>
                            <td>Total Student Loan Payments during College</td>
                            <td>$13,649</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>Children and Pet Expenses</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Expenses</td>
                            <td>$286,795</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Income Needed to Support Student Loans Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Student Loan Balance (incl. Existing Loans)</td>
                            <td>$63,000</td>
                          </tr>
                          <tr>
                            <td>Total Monthly Payment (incl. Existing Loans)</td>
                            <td>$719</td>
                          </tr>
                          <tr>
                            <td>Total Annual Paid</td>
                            <td>$8,623</td>
                          </tr>
                          <tr>
                            <td>Divided by 12.5%</td>
                            <td>12.5%</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Income Needed to Support Student Loans</td>
                            <td>$68,983</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Investment Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Scholarships</td>
                            <td>$75,000</td>
                          </tr>
                          <tr>
                            <td>Grants</td>
                            <td>$0</td>
                          </tr>

                          <tr>
                            <td>Other Financial Aid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Income Earned after Taxes</td>
                            <td>$40,361</td>
                          </tr>
                          <tr>
                            <td>Family Contribution</td>
                            <td>$100,000</td>
                          </tr>
                          <tr>
                            <td>Federal Subsidized Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Federal Unsubsidized Student Loan</td>
                            <td>$31,000</td>
                          </tr>
                          <tr>
                            <td>Private Student Loan</td>
                            <td>$32,000</td>
                          </tr>
                          <tr>
                            <td>Origination Fees</td>
                            <td>($501)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Financing Sources</td>
                            <td>277,860</td>
                          </tr>
                          <tr>
                            <td>Total Expenses</td>
                            <td>($286,795)</td>
                          </tr>
                          <tr>
                            <td>Cash Leftover</td>
                            <td><span className={ classes.redBg }>($8,935)</span></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Total Liquid Assets Available</td>
                            <td>$2,000</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.careerPathHead}` }>Career Path (2024-2025)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2024-2025-Registered Architect-01.25.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Career
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Occupation</span>
                        <span>Entry-level Architect</span>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Start Year</span>
                          <span>2024</span>
                        </div>
                        <p>Year</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>End Year</span>
                          <span>2025</span>
                        </div>
                        <p>2</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ `${classes.formGroup} ${classes.noborder}` }>
                          <span>Total Pre-Tax Base Income</span>
                          <span>$50,000</span>
                        </div>
                      </div>
                      <div className={ classes.subHead }>
                        Supplementary Income
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Start Year</span>
                        <span>$0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>End Year</span>
                        <span>$0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Income</span>
                        <span>$0</span>
                      </div>
                      <div className={ classes.subHead }>
                        401(k)
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Contribute to / Already Have a 401(k)?</span>
                        <span>Yes</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Personal Contribution as a % of Base Income</span>
                        <span>8.00%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Employer Matching Rate on Personal Contribution</span>
                        <span>50.00%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Employer Matching Limit as a % of Base Income</span>
                        <span>4.00%</span>
                      </div>

                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Monthly Budget</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Monthly Base Income</td>
                            <td>$4,167</td>
                          </tr>
                          <tr>
                            <td>Other Monthly Income</td>
                            <td>$0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Monthly Income</td>
                            <td>$4,167</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Taxes Paid</td>
                            <td>($825)</td>
                          </tr>

                          <tr>
                            <td>Higher Education Expenses</td>
                            <td>($825)</td>
                          </tr>
                          <tr>
                            <td>Total Taxes Paid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Children Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Pet Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>($532)</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Total Monthly Expenses</td>
                            <td>($3,547)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Monthly Cash Flow before Retirement Contributions</td>
                            <td>$620</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$333</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$287</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Total Cost of Attendance</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Income</td>
                            <td>$50,000</td>
                          </tr>
                          <tr>
                            <td>Total Taxes Paid</td>
                            <td>($9,897)</td>
                          </tr>
                          <tr>
                            <td>Living Expenses</td>
                            <td>($26,280)</td>
                          </tr>
                          <tr>
                            <td>Higher Education Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Children Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Pet Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>$0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Annual Expenses</td>
                            <td>($42,559)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Annual Cash Flow before Retirement Contributions</td>
                            <td>$7,441</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$4,000</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$3,441</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Budget Summary in years 2024 to 2025</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Income</td>
                            <td>$101,500</td>
                          </tr>
                          <tr>
                            <td>Total Taxes</td>
                            <td>($20,123)</td>
                          </tr>
                          <tr>
                            <td>Total Living Expenses</td>
                            <td>($53,033)</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>($15,005)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Expenses</td>
                            <td>($88,161)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Cash Flow before Retirement Contributions</td>
                            <td>$13,339</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$8,120</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$5,219</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Expenses</td>
                            <td>$286,795</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">401(k) Retirement Account Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Beginning 401(k) Balance in Year 2024</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Personal Contributions After 2 Years</td>
                            <td>$8,120</td>
                          </tr>
                          <tr>
                            <td>Earnings on Personal Contributions After 2 Years</td>
                            <td>$502</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Personal Contributions & Earnings</td>
                            <td>$8,622</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Employer Contributions After 2 Years</td>
                            <td>$4,060</td>
                          </tr>
                          <tr>
                            <td>Earnings on Employer Contributions After 2 Years</td>
                            <td>$251</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Total Employer Contributions & Earnings</td>
                            <td>$4,311</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>401(k) Balance before Vesting Reductions</td>
                            <td>$12,933</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>% Vested after 2 years at 25%/yr Schedule</td>
                            <td>50.00%</td>
                          </tr>
                          <tr>
                            <td>% of Employer Contributions & Earnings Not Vested</td>
                            <td>($2,156)</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Ending 401(k) Balance in Year 2025</td>
                            <td>$10,778</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.investHead}` }>Investments (2024)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2024-2033-Registered Architect-01.25.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Short-Term Investments
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Start Year</span>
                          <span>2024</span>
                        </div>

                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Contribution</span>
                        <span>$1,200</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Max Short-Term Investments Balance before Long-Term Investments</span>
                        <span>$30,000</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Expected Pre-Tax Return</span>
                        <span>3.00%</span>
                      </div>

                      <div className={ classes.subHead }>
                        Long-Term Investments
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Start Year</span>
                        <span>2029</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Contribution</span>
                        <span>$2,400</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Expected Pre-Tax Return</span>
                        <span>6.00%</span>
                      </div>

                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Short-Term Investments Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td className={ classes.tableHeadNew }>Beginning Short-Term Investments Balance</td>
                            <td>$4,167</td>
                          </tr>
                          <tr>
                            <td>Total Contributions for 10 Years</td>
                            <td>$15,093</td>
                          </tr>
                          <tr>
                            <td>Contributions from Cash Account for 10 Years</td>
                            <td>$79,259</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td className={ classes.tableHeadNew }>Total Cash Invested for 10 Years</td>
                            <td>($825)</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td className={ classes.tableHeadNew }>Total Earnings for 10 Years</td>
                            <td>$2,284</td>
                          </tr>
                          <tr>
                            <td>Total Short-Term Investments Accumulated</td>
                            <td>$96,636</td>
                          </tr>
                          <tr>
                            <td>Total Withdrawals for Cash Need for 10 Years</td>
                            <td>($8,285)</td>
                          </tr>
                          <tr>
                            <td>Total Transfers into Long-Term Investments for 10 Years</td>
                            <td>($58,352)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td className={ classes.tableHeadNew }>Ending Short-Term Investments Balance</td>
                            <td><span className={ classes.greenBg }>$30,000</span></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>

                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Long-Term Investments Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Beginning Long-Term Investments Balance</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Contributions for 5 Years</td>
                            <td>$13,262</td>
                          </tr>
                          <tr>
                            <td>Contributions from Short-Term Investments for 5 Years</td>
                            <td>12.5%</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Cash Invested for 5 Years</td>
                            <td>$71,613</td>
                          </tr>
                          <tr>
                            <td>Total Long-Term Investments Accumulated</td>
                            <td>$76,349</td>
                          </tr>
                          <tr>
                            <td>Total Withdrawals for Cash Need for 5 Years</td>
                            <td>($2,472)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Ending Long-Term Investments Balance</td>
                            <td><span className={ classes.greenBg }>2022</span></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Charitable Contributions Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Annual Contribution in the Year 2030</td>
                            <td>$5,031</td>
                          </tr>
                          <tr>
                            <td>Total Contributions After 4 Years</td>
                            <td>$23,585</td>
                          </tr>
                          <tr>
                            <td>Estimated Tax Savings at 20% Tax Rate</td>
                            <td>$4,717</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.VacationHead}` }>Vacation (2025)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2024-2033-Registered Architect-01.25.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Vacation
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Start Year</span>
                          <span>2025</span>
                        </div>

                      </div>
                      <div className={ classes.formGroup }>
                        <span>End Year</span>
                        <span>2033</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Cost</span>
                        <span>$2,000</span>
                      </div>

                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.subHead }>
                        Vacation
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Special Purchase</span>
                          <span>Bicycle</span>
                        </div>

                      </div>
                      <div className={ classes.formGroup }>
                        <span>Start Year</span>
                        <span>2024</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>End Year</span>
                        <span>2024</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Cost</span>
                        <span>$800</span>
                      </div>

                    </div>
                  </Grid>

                  <Grid sm={ 4 }>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Vacation + Special Purchase Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Amount Spent  </td>
                            <td>$20,152</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.AddcareerPathHead}` }>Additional Career Advancement (2029-2031)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2024-2025-Registered Architect-01.25.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Career
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Occupation</span>
                        <span>Architect</span>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Start Year</span>
                          <span>2026</span>
                        </div>
                        <p>Year</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>End Year</span>
                          <span>2028</span>
                        </div>
                        <p>2</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ `${classes.formGroup} ${classes.noborder}` }>
                          <span>Total Pre-Tax Base Income</span>
                          <span>$60,000</span>
                        </div>
                      </div>
                      <div className={ classes.subHead }>
                        Supplementary Income
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Start Year</span>
                        <span>$0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>End Year</span>
                        <span>$0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Income</span>
                        <span>$0</span>
                      </div>
                      <div className={ classes.subHead }>
                        401(k)
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Contribute to / Already Have a 401(k)?</span>
                        <span>Yes</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Personal Contribution as a % of Base Income</span>
                        <span>10.00%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Employer Matching Rate on Personal Contribution</span>
                        <span>50.00%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Employer Matching Limit as a % of Base Income</span>
                        <span>5.00%</span>
                      </div>

                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Monthly Budget</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Monthly Base Income</td>
                            <td>$5,000</td>
                          </tr>
                          <tr>
                            <td>Other Monthly Income</td>
                            <td>$0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Monthly Income</td>
                            <td>$5,000</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Taxes Paid</td>
                            <td>($1,003)</td>
                          </tr>

                          <tr>
                            <td>Higher Education Expenses</td>
                            <td>($2,665)</td>
                          </tr>
                          <tr>
                            <td>Children Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Pet Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>($719)</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Total Monthly Expenses</td>
                            <td>($4,387)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Monthly Cash Flow before Retirement Contributions</td>
                            <td>$613</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$500</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$113</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Total Cost of Attendance</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Income</td>
                            <td>$60,000</td>
                          </tr>
                          <tr>
                            <td>Total Taxes Paid</td>
                            <td>($12,040)</td>
                          </tr>
                          <tr>
                            <td>Living Expenses</td>
                            <td>($31,980)</td>
                          </tr>
                          <tr>
                            <td>Higher Education Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Children Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Pet Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>$8,623</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Annual Expenses</td>
                            <td>($52,643)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Annual Cash Flow before Retirement Contributions</td>
                            <td>$7,357</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$6,000</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$1,357</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Budget Summary in years 2024 to 2025</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Income</td>
                            <td>$101,500</td>
                          </tr>
                          <tr>
                            <td>Total Taxes</td>
                            <td>($20,123)</td>
                          </tr>
                          <tr>
                            <td>Total Living Expenses</td>
                            <td>($53,033)</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>($15,005)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Expenses</td>
                            <td>($88,161)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Cash Flow before Retirement Contributions</td>
                            <td>$13,339</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$8,120</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$5,219</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Expenses</td>
                            <td>$286,795</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">401(k) Retirement Account Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Beginning 401(k) Balance in Year 2024</td>
                            <td>$40,691</td>
                          </tr>
                          <tr>
                            <td>Personal Contributions After 2 Years</td>
                            <td>$33,382</td>
                          </tr>
                          <tr>
                            <td>Earnings on Personal Contributions After 2 Years</td>
                            <td>$9,162</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Personal Contributions & Earnings</td>
                            <td>$42,543</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Employer Contributions After 2 Years</td>
                            <td>$16,691</td>
                          </tr>
                          <tr>
                            <td>Earnings on Employer Contributions After 2 Years</td>
                            <td>$3,554</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Total Employer Contributions & Earnings</td>
                            <td>$20,245</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>401(k) Balance before Vesting Reductions</td>
                            <td>$103,479</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>% Vested after 2 years at 25%/yr Schedule</td>
                            <td>75%</td>
                          </tr>
                          <tr>
                            <td>% of Employer Contributions & Earnings Not Vested</td>
                            <td>($5,061)</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Ending 401(k) Balance in Year 2025</td>
                            <td>$98,418</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.VacationHead}` }>Buying a Car (2031)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2031-2031-Registered Architect-12.24.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Buying a Car
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Purchase Year</span>
                          <span>2031</span>
                        </div>

                      </div>
                      <div className={ classes.formGroup }>
                        <span>Total Purchase Price</span>
                        <span>$11,894</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Down Payment</span>
                        <span>40.00%</span>
                      </div>

                    </div>
                  </Grid>

                  <Grid sm={ 4 }>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Buying a Car Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Retail Value </td>
                            <td>$25,000</td>
                          </tr>
                          <tr>
                            <td>My Income Earned</td>
                            <td>$711,153</td>
                          </tr>
                          <tr className={ classes.borderBox }>
                            <td>Destination Charges</td>
                            <td>$650</td>
                          </tr>
                          <tr>
                            <td>Discount</td>
                            <td>($770)</td>
                          </tr>

                          <tr>
                            <td>Rebate</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Trade-In Value</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Sales Tax</td>
                            <td><span className={ classes.greenBg }>$1,742</span></td>
                          </tr>
                          <tr>
                            <td>Registration & Documentation Fees</td>
                            <td><span className={ classes.redBg }>$200</span></td>
                          </tr>
                          <tr>
                            <td>Total Purchase Price</td>
                            <td><span className={ classes.redBg }>$24,881</span></td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Down Payment</td>
                            <td>$9,952</td>
                          </tr>
                          <tr>
                            <td>Origination Fees</td>
                            <td>$119</td>
                          </tr>

                          <tr>
                            <td>Cash Needed at Purchase</td>
                            <td>$12,013</td>
                          </tr>

                          <tr>
                            <td>Total Cash Available</td>
                            <td>$2,507</td>
                          </tr>

                          <tr>
                            <td>Other Liquid Assets Available</td>
                            <td>$13,275</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.VacationHead}` }>Family (2030)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2030-2033-Registered Architect-01.01.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Marriage
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Marriage Year</span>
                          <span>2030</span>
                        </div>

                      </div>
                      <div className={ classes.formGroup }>
                        <span>Spouse&apos;s Career</span>
                        <span>Interior Designer</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Spouse&apos;s Income</span>
                        <span>$70,000</span>
                      </div>

                      <div className={ classes.subHead }>
                        Spouse&apos;s Student Loan Debt
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Student Loan Balance</span>
                          <span>$0</span>
                        </div>

                      </div>
                      <div className={ classes.formGroup }>
                        <span>Interest Rate</span>
                        <span>0.00%</span>
                      </div>
                      <div className={ classes.subHead }>
                        Pet
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Year of Purchase</span>
                          <span>2030</span>
                        </div>
                      </div>

                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.subHead }>
                        Marriage Multiples - Monthly Living Expenses
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Rent</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Utilities</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Food</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Clothing & Personal Care</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Entertainment</span>
                          <span>1.2 x</span>
                        </div>

                      </div>

                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Technology</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Transportation</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Miscellaneous</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Health Insurance Costs outside Premium</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Health Insurance Premium</span>
                          <span>1.2 x</span>
                        </div>

                      </div>

                    </div>
                  </Grid>

                  <Grid sm={ 4 }>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Marital Income Statement Summary 2030 to 2033</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Spouse&apos;s Income Earned </td>
                            <td>$313,772</td>
                          </tr>
                          <tr>
                            <td>My Income Earned</td>
                            <td>$711,153</td>
                          </tr>
                          <tr className={ classes.borderBox }>
                            <td>Taxes Paid</td>
                            <td>($192,104)</td>
                          </tr>
                          <tr>
                            <td>Total Tax Rate</td>
                            <td>18.74%</td>
                          </tr>

                          <tr>
                            <td>Living Expenses Paid</td>
                            <td>($236,132)</td>
                          </tr>
                          <tr>
                            <td>Financing Expenses Paid</td>
                            <td>($60,146)</td>
                          </tr>
                          <tr>
                            <td>Income after Living Expenses, Financing Expenses, & Taxes</td>
                            <td><span className={ classes.greenBg }>$264,584</span></td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.AddcareerPathHead}` }>Further Career Advancement (2032-2033)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2032-2033-Registered Architect-12.24.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Career
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Job Title</span>
                        <span>Project Architect</span>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Start Year</span>
                          <span>2032</span>
                        </div>
                        <p>Year</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>End Year</span>
                          <span>2033</span>
                        </div>
                        <p>2</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ `${classes.formGroup} ${classes.noborder}` }>
                          <span>Total Pre-Tax Base Income</span>
                          <span>$130,000</span>
                        </div>
                      </div>
                      <div className={ classes.subHead }>
                        Supplementary Income
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Start Year</span>
                        <span>2032</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>End Year</span>
                        <span>2033</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Income</span>
                        <span>$10,000</span>
                      </div>
                      <div className={ classes.subHead }>
                        401(k)
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Contribute to / Already Have a 401(k)?</span>
                        <span>Yes</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Personal Contribution as a % of Base Income</span>
                        <span>10.00%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Employer Matching Rate on Personal Contribution</span>
                        <span>100.00%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Employer Matching Limit as a % of Base Income</span>
                        <span>6.00%</span>
                      </div>

                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Monthly Budget</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Monthly Base Income</td>
                            <td>$10,833</td>
                          </tr>
                          <tr>
                            <td>Other Monthly Income</td>
                            <td>$7,464</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Monthly Income</td>
                            <td>$18,297</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Taxes Paid</td>
                            <td>($4,547)</td>
                          </tr>

                          <tr>
                            <td>Living Expenses</td>
                            <td>($4,115)</td>
                          </tr>
                          <tr>
                            <td>Higher Education Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Children Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Pet Expenses</td>
                            <td>($115)</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>($316)</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>($1,049)</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Total Monthly Expenses</td>
                            <td>($10,142)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Monthly Cash Flow before Retirement Contributions</td>
                            <td>$8,155</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$1,083</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$7,072</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Annual Budget</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Income</td>
                            <td>$445,722</td>
                          </tr>
                          <tr>
                            <td>Total Taxes</td>
                            <td>($110,840)</td>
                          </tr>
                          <tr>
                            <td>Total Living Expenses</td>
                            <td>($110,469)</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>($7,642)</td>
                          </tr>

                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>($16,350)</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>($38,935)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Expenses</td>
                            <td>($287,022)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Cash Flow before Retirement Contributions</td>
                            <td>$158,700</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$26,390</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td><span>$132,310</span></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Budget Summary in years 2024 to 2025</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Income</td>
                            <td>$101,500</td>
                          </tr>
                          <tr>
                            <td>Total Taxes</td>
                            <td>($20,123)</td>
                          </tr>
                          <tr>
                            <td>Total Living Expenses</td>
                            <td>($53,033)</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>($15,005)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Expenses</td>
                            <td>($88,161)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Cash Flow before Retirement Contributions</td>
                            <td>$13,339</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$8,120</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$5,219</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Expenses</td>
                            <td>$286,795</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">401(k) Retirement Account Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Beginning 401(k) Balance in Year 2024</td>
                            <td>$40,691</td>
                          </tr>
                          <tr>
                            <td>Personal Contributions After 2 Years</td>
                            <td>$33,382</td>
                          </tr>
                          <tr>
                            <td>Earnings on Personal Contributions After 2 Years</td>
                            <td>$9,162</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Personal Contributions & Earnings</td>
                            <td>$42,543</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Employer Contributions After 2 Years</td>
                            <td>$16,691</td>
                          </tr>
                          <tr>
                            <td>Earnings on Employer Contributions After 2 Years</td>
                            <td>$3,554</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Total Employer Contributions & Earnings</td>
                            <td>$20,245</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>401(k) Balance before Vesting Reductions</td>
                            <td>$103,479</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>% Vested after 2 years at 25%/yr Schedule</td>
                            <td>75%</td>
                          </tr>
                          <tr>
                            <td>% of Employer Contributions & Earnings Not Vested</td>
                            <td>($5,061)</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Ending 401(k) Balance in Year 2025</td>
                            <td>$98,418</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.buyingHomeHead}` }>Buying a Home (2033)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2033-Registered Architect-01.01.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Buying a Home
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Purchase Year</span>
                          <span>2033</span>
                        </div>

                      </div>
                      <div className={ classes.formGroup }>
                        <span>Purchase Price</span>
                        <span>$300,000</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Down Payment</span>
                        <span>15.00%</span>
                      </div>

                    </div>
                  </Grid>

                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>

                      <div className={ classes.subHead }>
                        Buying a Home
                      </div>
                      <div className={ `${classes.formGroup} ${classes.noborder} ${classes.marginZero}` }>
                        <span>Mortgage</span>
                        <span>$255,000</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Term of the Loan</span>
                        <span>30</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Interest Rate</span>
                        <span>4.00%</span>
                      </div>
                      <div className={ `${classes.formGroup} ${classes.noborder} ${classes.marginZero}` }>
                        <span>Monthly Payment (Principal & Interest)</span>
                        <span>$1,217</span>
                      </div>

                      <div className={ `${classes.formGroup} ${classes.noborder} ${classes.marginZero}` }>
                        <span>Total Interest</span>
                        <span>$183,267</span>
                      </div>
                      <div className={ `${classes.formGroup} ${classes.noborder} ${classes.marginZero}` }>
                        <span>Total Principal & Interest</span>
                        <span>$438,267</span>
                      </div>

                    </div>

                  </Grid>

                  <Grid sm={ 4 }>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Buying a Home - Investment Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Purchase Price</td>
                            <td>$300,000</td>
                          </tr>
                          <tr>
                            <td>Down Payment</td>
                            <td>$45,000</td>
                          </tr>
                          <tr className={ classes.borderBox }>
                            <td>Closing Costs</td>
                            <td>$14,889</td>
                          </tr>
                          <tr>
                            <td>Fit Out Costs</td>
                            <td>$10,000</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Cash Needed at Purchase</td>
                            <td>$69,889</td>
                          </tr>
                          <tr>
                            <td>Total Cash Available</td>
                            <td>$20,000</td>
                          </tr>
                          <tr>
                            <td>Other Liquid Assets Available</td>
                            <td>$94,229</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Debt-to-Income Ratio</td>
                            <td>11.65%</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.moreHighrEducationHead}` }>More Higher Education</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>Select Scenario</p>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Type of Higher Education</span>
                        <span>College</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Name of College</span>
                        <span>Virginia Tech</span>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Inflation</span>
                          <span>3.00%</span>
                        </div>
                        <p>Years</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Inflation</span>
                          <span>3.00%</span>
                        </div>
                        <p>Years</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Inflation</span>
                          <span>3.00%</span>
                        </div>
                        <p>Years</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">More Higher Education - Total Cost of Attendance</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Tuition & Fees</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Room & Board</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>On Campus Living Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Off Campus Living Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Student Loan Payments during College</td>
                            <td>$0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Cost of Attendance</td>
                            <td>$0</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Income Needed to Support Student Loans Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Tuition & Fees</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Room & Board</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>On Campus Living Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Off Campus Living Expenses</td>
                            <td>$69,639</td>
                          </tr>
                          <tr>
                            <td>Total Student Loan Payments during College</td>
                            <td>$13,649</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>Children and Pet Expenses</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Expenses</td>
                            <td>$286,795</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Income Needed to Support Student Loans Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Student Loan Balance (incl. Existing Loans)</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Monthly Payment (incl. Existing Loans)</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Annual Paid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Divided by 12.5%</td>
                            <td>12.5%</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Income Needed to Support Student Loans</td>
                            <td>$0</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Investment Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Scholarships</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Grants</td>
                            <td>$0</td>
                          </tr>

                          <tr>
                            <td>Other Financial Aid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Income Earned after Taxes</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Family Contribution</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Federal Subsidized Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Federal Unsubsidized Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Private Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Origination Fees</td>
                            <td>0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Financing Sources</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Cash Leftover</td>
                            <td><span className={ classes.redBg }>$0</span></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Total Liquid Assets Available</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.buyingHead}` }>Buying an Investment Property</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>Select Scenario</p>
                      </div>
                      <div className={ classes.subHead }>
                        Buying a Real Estate Investment Property
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Purchase Year</span>
                        <span>0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Sale Year</span>
                        <span>0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Purchase Price</span>
                        <span>0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Down Payment</span>
                        <span>0</span>
                      </div>

                      <div className={ classes.formGroup }>
                        <span>Annual Rental Income</span>
                        <span>0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Vacancy Rate</span>
                        <span>0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Rental Income Growth</span>
                        <span>0</span>
                      </div>
                      <div className={ classes.subHead }>
                        Real Estate Investment Property Mortgage
                      </div>
                      <div className={ `${classes.formGroup} ${classes.noborder}` }>
                        <span>Mortgage</span>
                        <span>$0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Term of the Loan</span>
                        <span>0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Interest Rate</span>
                        <span>0</span>
                      </div>

                    </div>
                  </Grid>
                  <Grid sm={ 4 }>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Real Estate Investment Property - Purchase Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Purchase Price</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Down Payment</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Closing Costs</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Fit Out Costs</td>
                            <td>$0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Cash Needed at Purchase</td>
                            <td>$0</td>
                          </tr>

                          <tr>
                            <td>Fit Out Costs</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Cash Available  </td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Other Liquid Assets Available</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Debt-to-Income Ratio</td>
                            <td>$0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>$0</span></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Real Estate Investment Property - Investment Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Rental Income</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Operating Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Financing Costs (Principal & Interest)</td>
                            <td>$0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Net Cash Flow</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Cash Invested</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Cash Returned</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Return on Investment (before taxes)</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>Multiple on Money (before taxes)</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Internal Rate of Return (before taxes)</td>
                            <td>0</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Income Needed to Support Student Loans Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Student Loan Balance (incl. Existing Loans)</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Monthly Payment (incl. Existing Loans)</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Annual Paid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Divided by 12.5%</td>
                            <td>12.5%</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Income Needed to Support Student Loans</td>
                            <td>$0</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Investment Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Scholarships</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Grants</td>
                            <td>$0</td>
                          </tr>

                          <tr>
                            <td>Other Financial Aid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Income Earned after Taxes</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Family Contribution</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Federal Subsidized Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Federal Unsubsidized Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Private Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Origination Fees</td>
                            <td>0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Financing Sources</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Cash Leftover</td>
                            <td><span className={ classes.redBg }>$0</span></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Total Liquid Assets Available</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.furtherEducationHead}` }>Further Higher Education</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>Select Scenario</p>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Type of Higher Education</span>
                        <span>&nbsp;</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Name of Further Higher Education</span>
                        <span>&nbsp;</span>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Start Year</span>
                          <span>0</span>
                        </div>
                        <p>Years</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>End year</span>
                          <span>0</span>
                        </div>
                        <p>0</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Room & Board</span>
                          <span>$0</span>
                        </div>
                        <p />
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Tuition & Fees</span>
                          <span>$0</span>
                        </div>
                        <p />
                      </div>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Further Higher Education - Total Cost of Attendance</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Tuition & Fees</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Room & Board</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>On Campus Living Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Off Campus Living Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Student Loan Payments during College</td>
                            <td>$0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Cost of Attendance</td>
                            <td>$0</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Further Higher Education - Total Expenses</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Tuition & Fees</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Room & Board</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>On Campus Living Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Off Campus Living Expenses</td>
                            <td>$69,639</td>
                          </tr>
                          <tr>
                            <td>Total Student Loan Payments during College</td>
                            <td>$13,649</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>Children and Pet Expenses</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Expenses</td>
                            <td>$286,795</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Income Needed to Support Student Loans Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Student Loan Balance (incl. Existing Loans)</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Monthly Payment (incl. Existing Loans)</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Annual Paid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Divided by 12.5%</td>
                            <td>12.5%</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Income Needed to Support Student Loans</td>
                            <td>$0</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Investment Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Scholarships</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Grants</td>
                            <td>$0</td>
                          </tr>

                          <tr>
                            <td>Other Financial Aid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Income Earned after Taxes</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Family Contribution</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Federal Subsidized Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Federal Unsubsidized Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Private Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Origination Fees</td>
                            <td>0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Financing Sources</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Cash Leftover</td>
                            <td><span className={ classes.greenBg }>$0</span></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Total Liquid Assets Available</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.assetsHead}` }>
                  Existing Assets & Liabilities
                </h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>Select Scenario</p>
                      </div>

                    </div>
                  </Grid>

                </Grid>
              </div>

            </div>
            <div className={ classes.summaryTableFooter }>
              <Button className={ classes.closeModalPopup }>Close</Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={ SummaryPopup }
          onClose={ handleSummaryPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.summaryPopup}
        >
                  <DialogTitle className={classes.modalHeader1}>
          <h4>Summary Tables</h4>


         {/* <img src={logo1} alt="logo" />*/}

          <button type="button" onClick={()=>{setSummaryPopup(false)}} className={classes.closePopup}>×</button>
        </DialogTitle>
          <DialogContent className={classes.modalBody1}>
          <Summarytable  linkId= {linkId}/>
          </DialogContent>
          <DialogActions>
          <div className={ classes.modalFooter }>
            <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ ()=>{setSummaryPopup(false)} }>Close</Button>
          </div>
        </DialogActions>
        </Dialog>
      </div>

      {openDocsPopup !== '' && ( <SummaryDocs setOpenDocsPopup={ setOpenDocsPopup } popupStatus={ openDocsPopup } /> )}

    </Typography>
  );
};

Robert.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Robert );
