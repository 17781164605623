import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import styles from '../../../screens/pwi/buying-a-home/styles';
import homeImg from '../../../assets/img/pwi-home/existing_home.png';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import resetIcon from '../../../assets/img/pf101/circle-arrow.png';
import { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { HomeValueLabelComponent, PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as excelLibrary from '../../../utilities/excelLibrary';

const followersMarks = [
  {
    value: 0,
    label: '$0',
  },
  {
    value: 200000,
    label: '$200k',
  },
  {
    value: 400000,
    label: '$400k',
  },
  {
    value: 600000,
    label: '$600k',
  },
  {
    value: 800000,
    label: '$800k',
  },
  {
    value: 1000000,
    label: '$1.0mm',
  },
  {
    value: 1200000,
    label: '$1.2mm',
  },
  {
    value: 1400000,
    label: '$1.4mm',
  },
];

const interestFollowersMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 1.5,
    label: '1.5%',
  },
  {
    value: 3,
    label: '3%',
  },
  {
    value: 4.5,
    label: '4.5%',
  },
  {
    value: 6,
    label: '6%',
  },
  {
    value: 7.5,
    label: '7.5%',
  },
  {
    value: 9,
    label: '9%',
  },
  {
    value: 10.5,
    label: '10.5%',
  },
];

const debtToIncomeFollowersMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 6,
    label: '6%',
  },
  {
    value: 12,
    label: '12%',
  },
  {
    value: 18,
    label: '18%',
  },
  {
    value: 24,
    label: '24%',
  },
  {
    value: 30,
    label: '30%',
  },
  {
    value: 36,
    label: '36%',
  },
  {
    value: 42,
    label: '42%',
  },
];

const AffordabilityCalculator = ( props ) => {
  const {
    classes, inputData, updateData, handleDefinationPopup,
  } = props;
  const [homeValue, setHomeValue] = React.useState( inputData.affordability_home_value === undefined ? 400000 : inputData.affordability_home_value );
  const [interestRate, setInterestRate] = React.useState( inputData.affordability_interest_rate === undefined ? 4.00 : inputData.affordability_interest_rate );
  const [debtToIncome, setDebtToIncome] = React.useState( inputData.affordability_debt_to_income === undefined ? 36 : inputData.affordability_debt_to_income );
  const [otherLoanMonthly, setOtherLoanMonthly] = React.useState( inputData.other_loan_monthly === undefined ? 0 : inputData.other_loan_monthly );
  const [otherLoanyearly, setOtherLoanyearly] = React.useState( inputData.other_loan_monthly === undefined ? 0 : (inputData.other_loan_monthly*12) );
  const [affordValues, setAffordValues] = React.useState( {} );
  const [isFocus, setIsFocus] = React.useState('');

  const getAffordableCalcs = () => {
    const affordableValues = {};

    affordableValues['Purchase Price'] = homeValue;

    // New Calcs
    affordableValues['Down Payment'] = ( homeValue / 100 ) * 20;

    // Closing Costs
    affordableValues['Closing Costs'] = ( homeValue / 100 ) * 3.5;

    // Fit Out Costs
    affordableValues['Fit Out Costs'] = ( homeValue / 100 ) * 3;

    // Cash Needed at Purchase
    affordableValues['Cash Needed at Purchase'] = affordableValues['Down Payment'] + affordableValues['Closing Costs'] + affordableValues['Fit Out Costs'];

    // % of Home Value
    if(homeValue > 0) {
      affordableValues['Of Home Value'] = ( affordableValues['Cash Needed at Purchase'] / homeValue ) * 100;
    } else {
      affordableValues['Of Home Value'] = 0;
    }

    // Mortgage
    affordableValues.Mortgage = homeValue - affordableValues['Down Payment'];

    // Term of the Mortgage
    affordableValues['Term of the Mortgage'] = 30;

    affordableValues['Monthly Mortgage Payment PMT'] = excelLibrary.PMT( ( parseFloat( interestRate ) / 100 ) / 12, affordableValues['Term of the Mortgage'] * 12, -( affordableValues.Mortgage ), 0, 0 );

    // Property Taxes
    affordableValues['Property Taxes'] = ( ( homeValue / 12 ) / 100 ) * 1.5;

    // Yearly Homeowners Insurance
    affordableValues['Monthly Homeowners Insurance'] = ( homeValue / 1000 ) * ( 3.5 / 12 );

    // Total Monthly Home Payment (PITI)
    affordableValues['Total Monthly Home Payment (PITI)'] = affordableValues['Monthly Mortgage Payment PMT']
      + affordableValues['Property Taxes']
      + affordableValues['Monthly Homeowners Insurance'];

    // //Total Loan Payments
    affordableValues['Total Loan Payments Monthly'] = affordableValues['Total Monthly Home Payment (PITI)'] + otherLoanMonthly;

    // //Total Loan Payments Annual
    affordableValues['Total Loan Payments Annual'] = ( affordableValues['Total Monthly Home Payment (PITI)'] * 12 ) + otherLoanyearly;
    if(debtToIncome > 0) {
      // //Monthly
      affordableValues['Monthly Income Before Taxes Monthly'] = ( affordableValues['Total Loan Payments Monthly'] / debtToIncome ) * 100;
  
      // Annual
      affordableValues['Monthly Income Before Taxes Annual'] = ( affordableValues['Total Loan Payments Annual'] / debtToIncome ) * 100;
    } else {
      affordableValues['Monthly Income Before Taxes Monthly'] = 0;
      affordableValues['Monthly Income Before Taxes Annual'] = 0;
    }
    setAffordValues( affordableValues );
  };

  useEffect(()=>{
    getAffordableCalcs();
    // eslint-disable-next-line
  },[homeValue,interestRate,debtToIncome,otherLoanMonthly])

  useEffect( () => {
    const inputDetails = { ...inputData };
    if ( inputData.affordability_home_value === undefined ) {
      setHomeValue( 400000 );
      inputDetails.affordability_home_value = 400000;
    }
    if ( inputData.affordability_interest_rate === undefined ) {
      setInterestRate( 4.00 );
      inputDetails.affordability_interest_rate = 4.00;
    }
    if ( inputData.affordability_debt_to_income === undefined ) {
      setDebtToIncome( 36 );
      inputDetails.affordability_debt_to_income = 36;
    }
    if ( inputData.other_loan_monthly === undefined ) {
      setOtherLoanMonthly( 0 );
      inputDetails.other_loan_monthly = 0;

      setOtherLoanyearly( 0 );
      inputDetails.other_loan_yearly = 0;
    }
    if(inputData.affordability_home_value === undefined) {
      updateData( 'total_obj', inputDetails );
    }
    getAffordableCalcs();
    // eslint-disable-next-line
  }, []);

  const updateValue = ( e, value, field, mainField ) => {
    if ( field === 'home_value_slider' ) {
      setHomeValue( value );
      updateData( mainField, value );
    } else if ( field === 'home_value_input' ) {
      setHomeValue( e.floatValue !== undefined ? e.floatValue : 0 );
      updateData( mainField, e.floatValue !== undefined ? e.floatValue : 0 );
    } else if ( field === 'interest_rate_slider' ) {
      setInterestRate( value );
      updateData( mainField, value );
    } else if ( field === 'interest_rate_input' ) {
      let inputValue = e.floatValue !== undefined ? e.floatValue : 0;
      if ( inputValue > 100 ) {
        inputValue = 100;
      }
      setInterestRate( inputValue );
      updateData( mainField, inputValue );
    } else if ( field === 'debt_to_income_slider' ) {
      setDebtToIncome( value );
      updateData( mainField, value );
    } else if ( field === 'debt_to_income_input' ) {
      let inputValue = e.floatValue !== undefined ? e.floatValue : 0;
      if ( inputValue > 100 ) {
        inputValue = 100;
      }
      setDebtToIncome( inputValue );
      updateData( inputValue );
    } else if ( field === 'other_loan_monthly' ) {
      const inputValue = e.floatValue !== undefined ? e.floatValue : 0;
      setOtherLoanMonthly( inputValue );
      setOtherLoanyearly(inputValue*12)
      updateData( mainField, e.floatValue !== undefined ? e.floatValue : 0 );
    } else if ( field === 'other_loan_yearly' ) {
      const inputValue = e.floatValue !== undefined ? e.floatValue : 0;
      setOtherLoanMonthly( inputValue/12 );
      setOtherLoanyearly( inputValue );
      updateData( 'other_loan_monthly', inputValue/12 );
    }
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p>Knowing how much home you can afford and when is the first step to becoming a homeowner. To buy a home, you&apos;ll need:</p>
      <ul className={ classes.tickList }>
        <li>Cash for the down payment, closing costs and fit out costs</li>
        <li>Income to support the mortgage payment and other monthly expenses</li>
        <li>Six months of expenses left over in cash and investments</li>
      </ul>
      <p>Quickly see what&apos;s needed to buy a home by adjusting the property value below. The tables will estimate how much cash and income are needed to afford the property.</p>
      <div className={ classes.emptyDiv }>
        <p>&nbsp;</p>
      </div>
      <div className={ classes.affordabilityRowScroll }>
        <div className={ classes.affordabilityRow }>
          <div className={ classes.flexDisplayNew }>
            <div className={ classNames( classes.sliderFlex, classes.affordFlex ) }>
              <div>
                <p className={ classes.fontSmall }>
                  <span className={ classes.buyhomeImg }>
                    <img src={ homeImg } alt="" />
                  </span>
                </p>
                <div className={ classes.pwisliderInputs }>
                  <PwiSlider
                    value={ homeValue }
                    ValueLabelComponent={ HomeValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="on"
                    min={ 0 }
                    max={ 1400000 }
                    step={ 100 }
                    marks={ followersMarks }
                    onChange={ ( e, value ) => updateValue( e, value, 'home_value_slider', 'affordability_home_value' ) }
                  />
                </div>
              </div>
            </div>
            <div className={ classNames( classes.sliderValues, classes.affordableValues, classes.affordHome ) }>
              <div>
                <span className={ classes.dollarSymbol }>$</span>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ homeValue } onValueChange={ ( e ) => updateValue( e, '', 'home_value_input', 'affordability_home_value' ) } onFocus={ ( e ) => e.target.select() } />
              </div>
            </div>
          </div>
          <div className={ classes.flexDisplayNew }>
            <div className={ classNames( classes.sliderFlex, classes.affordFlex ) }>
              <div>
                <p className={ classes.fontSmall }>
                  <span>
                    Interest Rate
                  </span>
                </p>
                <div className={ classes.pwisliderInputs }>
                  <PwiSlider
                    value={ interestRate }
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    min={ 0 }
                    max={ 10.50 }
                    step={ 0.05 }
                    marks={ interestFollowersMarks }
                    valueLabelFormat={ percentFormatWithTwoDecimal }
                    onChange={ ( e, value ) => updateValue( e, value, 'interest_rate_slider', 'affordability_interest_rate' ) }
                  />
                </div>
              </div>
            </div>
            <div className={ classNames( classes.sliderValues, classes.affordableValues ) }>
              <div>
                <NumberFormat
                  decimalScale={ isFocus!=='interest_rate_input'?2:5 }
                  fixedDecimalScale={isFocus!=='interest_rate_input'}
                  allowNegative={ false }
                  customInput={ TextField }
                  thousandSeparator
                  value={ interestRate }
                  onValueChange={ ( e ) => updateValue( e, '', 'interest_rate_input', 'affordability_interest_rate' ) }
                  onFocus={ ( e ) => {e.target.select(); setIsFocus('interest_rate_input')} }
                  onBlur={emptyIsFocus} 
                />
                <span className={ classes.percentSymbol }>%</span>
                <span className={ classNames( classes.resetIcon, classes.resetImage ) } role="button" aria-hidden="true" onClick={ ( e ) => updateValue( e, 4, 'interest_rate_slider', 'affordability_interest_rate' ) }>
                  <img src={ resetIcon } alt="" />
                </span>
              </div>
            </div>
          </div>
          <div className={ classes.flexDisplayNew }>
            <div className={ classNames( classes.sliderFlex, classes.affordFlex ) }>
              <div>
                <p className={ classes.fontSmall }>
                  <span>
                    Debt-to-Income
                  </span>
                </p>
                <div className={ classes.pwisliderInputs }>
                  <PwiSlider
                    value={ debtToIncome }
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    min={ 0 }
                    max={ 42 }
                    step={ 1 }
                    valueLabelFormat={ percentFormatWithTwoDecimal }
                    marks={ debtToIncomeFollowersMarks }
                    onChange={ ( e, value ) => updateValue( e, value, 'debt_to_income_slider', 'affordability_debt_to_income' ) }
                  />
                </div>
              </div>
            </div>
            <div className={ classNames( classes.sliderValues, classes.affordableValues ) }>
              <div>
                <NumberFormat
                  decimalScale={ isFocus!=='debt_to_income_input'?2:5 }
                  fixedDecimalScale={isFocus!=='debt_to_income_input'}
                  allowNegative={ false }
                  customInput={ TextField }
                  thousandSeparator
                  value={ debtToIncome }
                  onValueChange={ ( e ) => updateValue( e, '', 'debt_to_income_input', 'affordability_debt_to_income' ) }
                  onFocus={ ( e ) => {e.target.select(); setIsFocus('debt_to_income_input')} }
                  onBlur={emptyIsFocus} 
                />
                <span className={ classes.percentSymbol }>%</span>
                <span className={ classNames( classes.resetIcon, classes.resetImage ) } role="button" aria-hidden="true" onClick={ ( e ) => updateValue( e, 36, 'debt_to_income_slider', 'affordability_debt_to_income' ) }>
                  <img src={ resetIcon } alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={ classes.tableRow }>
        <div className={ classes.leftTable }>
          <table className={ classes.cashTables }>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th colSpan="2">Cash Needed to Purchase</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Down payments on a home are usually 20% of the home price. As you continue in the Buying a Home module, you can change this input." />
                </td>
                <td>
                  <span className={ classes.dashedBorder } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Home Down Payment' ) }>Down Payment</span>
                </td>
                <td>
                  {affordValues['Down Payment'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Down Payment'] } prefix={ affordValues['Down Payment'] >= 0 ? '$' : '($' } suffix={ affordValues['Down Payment'] < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>
                  <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Closing costs can range from 2-5% of the home value and vary depending on location, credit score, and other factors. This calculator assumes 3.5% for closing costs. As you continue in the Buying a Home module, you can change this input." />
                </td>
                <td>
                  <span className={ classes.dashedBorder } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Closing Costs: Real Estate' ) }>Closing Costs</span>
                </td>
                <td>
                  {affordValues['Closing Costs'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Closing Costs'] } prefix={ affordValues['Closing Costs'] >= 0 ? '$' : '($' } suffix={ affordValues['Closing Costs'] < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>
                  <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Furniture, window treatments, landscaping, and home improvements are examples of fit out costs when buying a home. This calculator assumes 3% for fit out costs. As you continue in the Buying a Home module, you can change this input." />
                </td>
                <td>
                  <span className={ classes.dashedBorder } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Fit Out Costs: Real Estate' ) }>Fit Out Costs</span>
                </td>
                <td>
                  {affordValues['Fit Out Costs'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Fit Out Costs'] } prefix={ affordValues['Fit Out Costs'] >= 0 ? '$' : '($' } suffix={ affordValues['Fit Out Costs'] < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>&nbsp;</th>
                <th>Total Cash Needed</th>
                <th>
                  {affordValues['Cash Needed at Purchase'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Cash Needed at Purchase'] } prefix={ affordValues['Cash Needed at Purchase'] >= 0 ? '$' : '($' } suffix={ affordValues['Cash Needed at Purchase'] < 0 && ')' } />
                  ) : ( '$0' )}
                </th>
              </tr>
              <tr className={ classes.blueBottomBorder }>
                <th>&nbsp;</th>
                <th className={ classes.leftData }><i>% of Home Value</i></th>
                <th>
                  <i>
                    {affordValues['Of Home Value'] !== undefined ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ affordValues['Of Home Value'] } prefix={ affordValues['Of Home Value'] >= 0 ? '' : '(' } suffix={ affordValues['Of Home Value'] < 0 ? '%)' : '%' } />
                    ) : ( '0.00%' )}
                  </i>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className={ classes.rightTable }>
          <table className={ classes.cashTables }>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Income Needed</th>
                <th>Monthly</th>
                <th>Annual</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Down payments on a home are usually 20% of the home price. As you continue in the Buying a Home module, you can change this input." />
                </td>
                <td>Mortgage Payment (P&I)</td>
                <td>
                  {affordValues['Monthly Mortgage Payment PMT'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Monthly Mortgage Payment PMT'] } prefix={ affordValues['Monthly Mortgage Payment PMT'] >= 0 ? '$' : '($' } suffix={ affordValues['Monthly Mortgage Payment PMT'] < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {affordValues['Monthly Mortgage Payment PMT'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Monthly Mortgage Payment PMT'] * 12 } prefix={ affordValues['Monthly Mortgage Payment PMT'] >= 0 ? '$' : '($' } suffix={ affordValues['Monthly Mortgage Payment PMT'] < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>
                  <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Closing costs can range from 2-5% of the home value and vary depending on location, credit score, and other factors. This calculator assumes 3.5% for closing costs. As you continue in the Buying a Home module, you can change this input." />
                </td>
                <td>
                  <span className={ classes.dashedBorder } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Property Taxes: Home' ) }>Property Taxes</span>
                </td>
                <td>
                  {affordValues['Property Taxes'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Property Taxes'] } prefix={ affordValues['Property Taxes'] >= 0 ? '$' : '($' } suffix={ affordValues['Property Taxes'] < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {affordValues['Property Taxes'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Property Taxes'] * 12 } prefix={ affordValues['Property Taxes'] >= 0 ? '$' : '($' } suffix={ affordValues['Property Taxes'] < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>
                  <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Furniture, window treatments, landscaping, and home improvements are examples of fit out costs when buying a home. This calculator assumes 3% for fit out costs. As you continue in the Buying a Home module, you can change this input." />
                </td>
                <td>
                  <span className={ classes.dashedBorder } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( "Homeowner's Insurance" ) }>Homeowner&apos;s Insurance</span>
                </td>
                <td>
                  {affordValues['Monthly Homeowners Insurance'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Monthly Homeowners Insurance'] } prefix={ affordValues['Monthly Homeowners Insurance'] >= 0 ? '$' : '($' } suffix={ affordValues['Monthly Homeowners Insurance'] < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {affordValues['Monthly Homeowners Insurance'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Monthly Homeowners Insurance'] * 12 } prefix={ affordValues['Monthly Homeowners Insurance'] >= 0 ? '$' : '($' } suffix={ affordValues['Monthly Homeowners Insurance'] < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr className={ classNames( classes.boldText, classes.blueBottomBorder ) }>
                <td>
                  <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Furniture, window treatments, landscaping, and home improvements are examples of fit out costs when buying a home. This calculator assumes 3% for fit out costs. As you continue in the Buying a Home module, you can change this input." />
                </td>
                <td>Total Payment (PITI)</td>
                <td>
                  {affordValues['Total Monthly Home Payment (PITI)'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Total Monthly Home Payment (PITI)'] } prefix={ affordValues['Total Monthly Home Payment (PITI)'] >= 0 ? '$' : '($' } suffix={ affordValues['Total Monthly Home Payment (PITI)'] < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {affordValues['Total Monthly Home Payment (PITI)'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Total Monthly Home Payment (PITI)'] * 12 } prefix={ affordValues['Total Monthly Home Payment (PITI)'] >= 0 ? '$' : '($' } suffix={ affordValues['Total Monthly Home Payment (PITI)'] < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr className={ classes.whitebgRow }>
                <td>
                  <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Furniture, window treatments, landscaping, and home improvements are examples of fit out costs when buying a home. This calculator assumes 3% for fit out costs. As you continue in the Buying a Home module, you can change this input." />
                </td>
                <td className={ classes.leftData }>Other Loan Payments  </td>
                <td>
                  <div className={ classes.flexInput }>
                    <span className={ classes.dollarSymbol }>$</span>
                    <NumberFormat allowNegative={ false } decimalScale={0} fixedDecimalScale customInput={ TextField } thousandSeparator value={ otherLoanMonthly } onValueChange={ ( e ) => updateValue( e, '', 'other_loan_monthly', 'other_loan_monthly' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
                <td>
                  <div className={ classes.flexInput }>
                    <span className={ classes.dollarSymbol }>$</span>
                    <NumberFormat allowNegative={ false } decimalScale={0} fixedDecimalScale customInput={ TextField } thousandSeparator value={ otherLoanyearly } onValueChange={ ( e ) => updateValue( e, '', 'other_loan_yearly', 'other_loan_yearly' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
              </tr>
              <tr className={ classNames( classes.boldText, classes.blueBottomBorder ) }>
                <td>&nbsp;</td>
                <td>Total Loan Payments</td>
                <td>
                  {affordValues['Total Loan Payments Monthly'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Total Loan Payments Monthly'] } prefix={ affordValues['Total Loan Payments Monthly'] >= 0 ? '$' : '($' } suffix={ affordValues['Total Loan Payments Monthly'] < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {affordValues['Total Loan Payments Annual'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Total Loan Payments Annual'] } prefix={ affordValues['Total Loan Payments Annual'] >= 0 ? '$' : '($' } suffix={ affordValues['Total Loan Payments Annual'] < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>
                  <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Furniture, window treatments, landscaping, and home improvements are examples of fit out costs when buying a home. This calculator assumes 3% for fit out costs. As you continue in the Buying a Home module, you can change this input." />
                </td>
                <td className={ classes.leftData }>
                  <span className={ classes.dashedBorder } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Debt-to-Income Ratio (KPI)' ) }><i>Debt-to-Income-Ratio</i></span>
                </td>
                <td>
                  <i>
                    {debtToIncome !== undefined ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ debtToIncome } prefix={ debtToIncome >= 0 ? '' : '(' } suffix={ debtToIncome < 0 ? '%)' : '%' } />
                    ) : ( '0.00%' )}
                  </i>
                </td>
                <td>
                  <i />
                  {debtToIncome !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ debtToIncome } prefix={ debtToIncome >= 0 ? '' : '(' } suffix={ debtToIncome < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr className={ classes.blueBottomBorder }>
                <th>&nbsp;</th>
                <th>Income Needed</th>
                <th>
                  {affordValues['Monthly Income Before Taxes Monthly'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Monthly Income Before Taxes Monthly'] } prefix={ affordValues['Monthly Income Before Taxes Monthly'] >= 0 ? '$' : '($' } suffix={ affordValues['Monthly Income Before Taxes Monthly'] < 0 && ')' } />
                  ) : ( '$0' )}
                </th>
                <th>
                  {affordValues['Monthly Income Before Taxes Annual'] !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ affordValues['Monthly Income Before Taxes Annual'] } prefix={ affordValues['Monthly Income Before Taxes Annual'] >= 0 ? '$' : '($' } suffix={ affordValues['Monthly Income Before Taxes Annual'] < 0 && ')' } />
                  ) : ( '$0' )}
                </th>
              </tr>
            </tfoot>

          </table>
        </div>
      </div>
      <ReactTooltip id="tooltip" place="top" effect="solid" className={ classNames( classes.tooltipInfo, classes.infoTooltip ) } />
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

AffordabilityCalculator.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,

};

export default withStyles( styles )( AffordabilityCalculator );
