import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/further-higher-education/styles';

const NavigatingFinancialAidContinueSecond = ( props ) => {
  const {
    classes,
  } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>
        Your school&apos;s Net Price Calculator gives you some crucial information for the planning process. Depending on the school, your financial need may or may not be entirely covered by financial aid. Financial need that is not covered by financial aid is additional money that needs to be paid for by you or your family.
      </p>
      <div className={ classes.grayBox }>
        <strong className={ classes.marginBottom }>Cost of Attendance – Expected Family Contribution = Financial Need</strong>
        <p>Financial Need – Financial Aid Awards = Additional Family Contribution</p>
        <p className={ `${classes.paddingLeft} ${classes.italicText}` }>
          *Financial Aid Awards are scholarships, grants, loans, and work-study
        </p>
        <p>
          EFC + Additional Family Contribution = Total Family Contribution
        </p>
      </div>

      <p>
        The Total Family Contribution is the true out-of-pocket cost of attending the school. Now that you have good data to work with, let’s go ahead and build your financing plan.
      </p>

    </div>
  );
};

NavigatingFinancialAidContinueSecond.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( NavigatingFinancialAidContinueSecond );
