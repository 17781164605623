import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import tickMark from '../../../assets/img/finance-explained/email-templates/tick_mark.png';
import styles from './styles';

const InvestingduringHighInflation = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Investing during High Inflation </li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />

                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/inflation-investing.png "
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Investing during High Inflation
                    </h3>
                  </div>

                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/inflation-invest-left.jpg "
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          I promise this will be the last Finance Explained that
                          focuses on inflation in 2021. And let me be the first
                          to wish you a happy new year filled with wealth,
                          health, and happiness.{' '}
                        </p>
                      </div>
                    </div>

                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          I know you're tired of hearing me say it, but
                          inflation is much higher than average right now. For
                          reference, inflation has averaged roughly 3.2% over
                          the last 100 years, but, lately, inflation has been
                          higher than 6%. How do you manage your cash and invest
                          during high inflation?
                        </p>
                        <p>
                          In the chart below, you can see the average annual
                          return since 1985 of different asset classes.
                          Remember, every investment comes with the risk of
                          losing some or of all your investment. The higher the
                          risk usually means the higher the potential return.
                        </p>

                        <p>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                type: 'bar',
                              },
                              credits: {
                                enabled: false,
                              },
                              title: {
                                text: 'Average Annual Return since 1985',
                              },
                              xAxis: {
                                categories: [
                                  'Cash (T-Bill)',
                                  'Gold',
                                  'Int &apos l Bonds',
                                  'All US Bonds',
                                  'Hi-Yield US Bonds',
                                  'Cdn Bonds',
                                  'Int &apos l Dev Stocks',
                                  'Canadian Stocks',
                                  'REIT',
                                  'US Small Cap Stocks  ',
                                  'US Large Cap Stocks',
                                  'Emerging Stocks',
                                ],
                              },
                              legend: false,
                              tooltip: {
                                enabled: false,
                              },
                              yAxis: {
                                tickInterval: 2,
                                min: 0,
                                max: 10,
                                title: {
                                  text: '',
                                },
                                labels: {
                                  formatter: function () {
                                    return (
                                      this.axis.defaultLabelFormatter.call(
                                        this,
                                      ) + ' %'
                                    );
                                  },
                                },
                              },
                              series: [
                                {
                                  name: '',
                                  color: 'rgb(124, 181, 236)',
                                  data: [
                                    0.7, 2.3, 3.6, 4, 5.2, 5.4, 6, 6.1, 7.3,
                                    8.2, 8.7, 8.7,
                                  ],
                                },
                              ],

                              plotOptions: {
                                bar: {
                                  lineWidth: 2,
                                  states: {
                                    hover: {
                                      lineWidth: 2,
                                    },
                                  },
                                  dataLabels: {
                                    enabled: true,
                                    format: '{y} %',
                                  },
                                  marker: {
                                    enabled: false,
                                  },
                                },
                              },

                              exporting: {
                                buttons: {
                                  contextButton: {
                                    menuItems: [
                                      {
                                        textKey: 'downloadPNG',
                                        onclick() {
                                          const chart = this;
                                          chart.exportChart();
                                        },
                                      },
                                      {
                                        textKey: 'downloadJPEG',
                                        onclick() {
                                          const chart = this;
                                          chart.exportChart({
                                            type: 'image/jpeg',
                                          });
                                        },
                                      },
                                      {
                                        textKey: 'downloadPDF',
                                        onclick() {
                                          const chart = this;
                                          chart.exportChart({
                                            type: 'application/pdf',
                                          });
                                        },
                                      },
                                      {
                                        textKey: 'downloadSVG',
                                        onclick() {
                                          const chart = this;
                                          chart.exportChart({
                                            type: 'image/svg+xml',
                                          });
                                        },
                                      },
                                    ],
                                  },
                                },
                              },
                            }}
                          />
                          <p style={{ marginTop: '17px' }}>
                            {' '}
                            Then, there's inflation.{' '}
                          </p>
                        </p>
                        <p>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                type: 'spline',
                              },
                              credits: {
                                enabled: false,
                              },
                              title: {
                                text: 'Trailing 12 Months Inflation',
                              },
                              tooltip: {
                                headerFormat:
                                  '<span style="font-size:14px">{point.key}</span><br/>',
                                crosshairs: true,
                                shared: true,
                                style: {
                                  color: '#000000',
                                  fontSize: '14px',
                                },
                              },
                              xAxis: {
                                tickInterval: 5,
                                title: {
                                  text: '',
                                },
                                categories: [
                                  1914, 1915, 1916, 1917, 1918, 1919, 1920,
                                  1921, 1922, 1923, 1924, 1925, 1926, 1927,
                                  1928, 1929, 1930, 1931, 1932, 1933, 1934,
                                  1935, 1936, 1937, 1938, 1939, 1940, 1941,
                                  1942, 1943, 1944, 1945, 1946, 1947, 1948,
                                  1949, 1950, 1951, 1952, 1953, 1954, 1955,
                                  1956, 1957, 1958, 1959, 1960, 1961, 1962,
                                  1963, 1964, 1965, 1966, 1967, 1968, 1969,
                                  1970, 1971, 1972, 1973, 1974, 1975, 1976,
                                  1977, 1978, 1979, 1980, 1981, 1982, 1983,
                                  1984, 1985, 1986, 1987, 1988, 1989, 1990,
                                  1991, 1992, 1993, 1994, 1995, 1996, 1997,
                                  1998, 1999, 2000, 2001, 2002, 2003, 2004,
                                  2005, 2006, 2007, 2008, 2009, 2010, 2011,
                                  2012, 2013, 2014, 2015, 2016, 2017, 2018,
                                  2019, 2020, 2021,
                                ],
                              },

                              yAxis: {
                                tickInterval: 5,
                                min: -20,
                                // max:75,
                                title: {
                                  text: '',
                                },
                                labels: {
                                  formatter: function () {
                                    return (
                                      this.axis.defaultLabelFormatter.call(
                                        this,
                                      ) + ' %'
                                    );
                                  },
                                },
                              },
                              series: [
                                {
                                  name: 'JAN',
                                  data: [
                                    2.0, 1.0, 3.0, 12.5, 19.7, 17.9, 17.0, -1.6,
                                    -11.1, -0.6, 3.0, 0.0, 3.5, -2.2, -1.1,
                                    -1.2, 0.0, -7.0, -10.1, -9.8, 2.3, 3.0, 1.5,
                                    2.2, 0.7, -1.4, -0.7, 1.4, 11.3, 7.6, 3.0,
                                    2.3, 2.2, 18.1, 10.2, 1.3, -2.1, 8.1, 4.3,
                                    0.4, 1.1, -0.7, 0.4, 3.0, 3.6, 1.4, 1.0,
                                    1.7, 0.7, 1.3, 1.6, 1.0, 1.9, 3.5, 3.6, 4.4,
                                    6.2, 5.3, 3.3, 3.6, 9.4, 11.8, 6.7, 5.2,
                                    6.8, 9.3, 13.9, 11.8, 8.4, 3.7, 4.2, 3.5,
                                    3.9, 1.5, 4.0, 4.7, 5.2, 5.7, 2.6, 3.3, 2.5,
                                    2.8, 2.7, 3.0, 1.6, 1.7, 2.7, 3.7, 1.1, 2.6,
                                    1.9, 3.0, 4.0, 2.1, 4.3, 0.0, 2.6, 1.6, 2.9,
                                    1.6, 1.6, -0.1, 1.4, 2.5, 2.1, 1.6, 2.5,
                                    1.4,
                                  ],
                                  color: '#4472C4',
                                },
                                {
                                  name: 'FEB',
                                  data: [
                                    1.0, 1.0, 4.0, 15.4, 17.5, 14.9, 20.4, -5.6,
                                    -8.2, -0.6, 2.4, 0.0, 4.1, -2.8, -1.7, 0.0,
                                    -0.6, -7.6, -10.2, -9.9, 4.7, 3.0, 0.7, 2.2,
                                    0.0, -1.4, 0.7, 0.7, 12.1, 7.0, 3.0, 2.3,
                                    1.7, 18.8, 9.3, 1.3, -1.3, 9.4, 2.3, 0.8,
                                    1.5, -0.7, 0.4, 3.4, 3.2, 1.0, 1.7, 1.4,
                                    1.0, 1.0, 1.6, 1.0, 2.6, 2.8, 4.0, 4.7, 6.1,
                                    5.0, 3.5, 3.9, 10.0, 11.2, 6.3, 5.9, 6.4,
                                    9.9, 14.2, 11.4, 7.6, 3.5, 4.6, 3.5, 3.1,
                                    2.1, 3.9, 4.8, 5.3, 5.3, 2.8, 3.2, 2.5, 2.9,
                                    2.7, 3.0, 1.4, 1.6, 3.2, 3.5, 1.1, 3.0, 1.7,
                                    3.0, 3.6, 2.4, 4.0, 0.2, 2.1, 2.1, 2.9, 2.0,
                                    1.1, 0.0, 1.0, 2.7, 2.2, 1.5, 2.3, 1.7,
                                  ],
                                  color: '#ED7D31',
                                },
                                {
                                  name: 'MAR',
                                  data: [
                                    1.0, 0.0, 6.1, 14.3, 16.7, 17.1, 20.1, -7.1,
                                    -8.7, 0.6, 1.8, 1.2, 2.9, -2.8, -1.2, -0.6,
                                    -0.6, -7.7, -10.3, -10.0, 5.6, 3.0, 0.0,
                                    3.6, -0.7, -1.4, 0.7, 1.4, 12.7, 7.5, 1.2,
                                    2.3, 2.8, 19.7, 6.8, 1.7, -0.8, 9.3, 1.9,
                                    1.1, 1.1, -0.7, 0.4, 3.7, 3.6, 0.3, 1.7,
                                    1.4, 1.0, 1.3, 1.3, 1.3, 2.6, 2.8, 3.9, 5.2,
                                    5.8, 4.7, 3.5, 4.6, 10.4, 10.3, 6.1, 6.4,
                                    6.6, 10.1, 14.8, 10.5, 6.8, 3.6, 4.8, 3.7,
                                    2.3, 3.0, 3.9, 5.0, 5.2, 4.9, 3.2, 3.1, 2.5,
                                    2.9, 2.8, 2.8, 1.4, 1.7, 3.8, 2.9, 1.5, 3.0,
                                    1.7, 3.1, 3.4, 2.8, 4.0, -0.4, 2.3, 2.7,
                                    2.7, 1.5, 1.5, -0.1, 0.9, 2.4, 2.4, 1.9,
                                    1.5, 2.6,
                                  ],
                                  color: '#A5A5A5',
                                  name: 'APR',
                                  data: [
                                    0.0, 2.0, 6.0, 18.9, 12.7, 17.6, 21.6,
                                    -10.8, -7.7, 1.2, 0.6, 1.2, 4.1, -3.4, -1.2,
                                    -1.2, 0.6, -8.8, -10.3, -9.4, 5.6, 3.8,
                                    -0.7, 4.4, -0.7, -2.8, 1.4, 2.1, 12.6, 8.1,
                                    0.6, 1.7, 3.4, 19.0, 8.7, 0.4, -1.3, 9.3,
                                    2.3, 0.8, 0.8, -0.4, 0.7, 3.7, 3.6, 0.3,
                                    1.7, 1.0, 1.3, 1.0, 1.3, 1.6, 2.9, 2.5, 3.9,
                                    5.5, 6.1, 4.2, 3.5, 5.1, 10.1, 10.2, 6.0,
                                    7.0, 6.5, 10.5, 14.7, 10.0, 6.5, 3.9, 4.6,
                                    3.7, 1.6, 3.8, 3.9, 5.1, 4.7, 4.9, 3.2, 3.2,
                                    2.4, 3.1, 2.9, 2.5, 1.4, 2.3, 3.1, 3.3, 1.6,
                                    2.2, 2.3, 3.5, 3.5, 2.6, 3.9, -0.7, 2.2,
                                    3.2, 2.3, 1.1, 2.0, -0.2, 1.1, 2.2, 2.5,
                                    2.0, 0.3, 4.2,
                                  ],
                                  color: '#FFC000',
                                },
                                {
                                  name: 'MAY',
                                  data: [
                                    2.1, 2.0, 5.9, 19.6, 13.3, 16.6, 21.9,
                                    -14.1, -5.6, 1.2, 0.6, 1.8, 2.9, -2.2, -1.1,
                                    -1.2, -0.6, -9.5, -10.5, -8.0, 5.6, 3.8,
                                    -0.7, 5.1, -2.1, -2.1, 1.4, 2.9, 13.2, 7.4,
                                    0.0, 2.3, 3.4, 18.4, 9.1, -0.4, -0.4, 9.3,
                                    1.9, 1.1, 0.7, -0.7, 1.1, 3.7, 3.2, 0.3,
                                    1.7, 1.0, 1.3, 1.0, 1.3, 1.6, 2.9, 2.8, 3.9,
                                    5.5, 6.0, 4.4, 3.2, 5.5, 10.7, 9.5, 6.2,
                                    6.7, 7.0, 10.9, 14.4, 9.8, 6.7, 3.5, 4.2,
                                    3.8, 1.5, 3.9, 3.9, 5.4, 4.4, 5.0, 3.0, 3.2,
                                    2.3, 3.2, 2.9, 2.2, 1.7, 2.1, 3.2, 3.6, 1.2,
                                    2.1, 3.1, 2.8, 4.2, 2.7, 4.2, -1.3, 2.0,
                                    3.6, 1.7, 1.4, 2.1, 0.0, 1.0, 1.9, 2.8, 1.8,
                                    0.1, 5.0,
                                  ],
                                  color: '#5B9BD5',
                                },
                                {
                                  name: 'JUN',
                                  data: [
                                    1.0, 2.0, 6.9, 20.4, 13.1, 15.0, 23.7,
                                    -15.8, -5.1, 1.8, 0.0, 2.9, 1.1, -0.6, -2.8,
                                    0.0, -1.8, -10.1, -9.9, -6.6, 5.5, 2.2, 0.7,
                                    4.3, -2.1, -2.1, 2.2, 4.3, 10.9, 7.4, 0.6,
                                    2.8, 3.3, 17.6, 9.5, -0.8, -0.4, 8.8, 2.3,
                                    1.1, 0.4, -0.7, 1.9, 3.3, 2.8, 0.7, 1.7,
                                    0.7, 1.3, 1.3, 1.3, 1.9, 2.5, 2.8, 4.2, 5.5,
                                    6.0, 4.6, 2.7, 6.0, 10.9, 9.4, 6.0, 6.9,
                                    7.4, 10.9, 14.4, 9.6, 7.1, 2.6, 4.2, 3.8,
                                    1.8, 3.7, 4.0, 5.2, 4.7, 4.7, 3.1, 3.0, 2.5,
                                    3.0, 2.8, 2.3, 1.7, 2.0, 3.7, 3.2, 1.1, 2.1,
                                    3.3, 2.5, 4.3, 2.7, 5.0, -1.4, 1.1, 3.6,
                                    1.7, 1.8, 2.1, 0.1, 1.0, 1.6, 2.9, 1.6, 0.6,
                                    5.4,
                                  ],
                                  color: '#70AD47',
                                  name: 'JUL',
                                  data: [
                                    1.0, 1.0, 6.9, 18.5, 18.0, 15.2, 19.5,
                                    -14.9, -5.1, 2.4, -0.6, 3.5, -1.1, -1.1,
                                    -1.2, 1.2, -4.0, -9.0, -9.9, -3.7, 2.3, 2.2,
                                    1.5, 4.3, -2.8, -2.1, 1.4, 5.0, 11.6, 6.1,
                                    1.7, 2.3, 9.4, 12.1, 9.9, -2.9, 1.7, 7.5,
                                    3.1, 0.4, 0.4, -0.4, 2.2, 3.3, 2.5, 0.7,
                                    1.4, 1.4, 1.0, 1.3, 1.3, 1.6, 2.8, 2.8, 4.5,
                                    5.4, 6.0, 4.4, 2.9, 5.7, 11.5, 9.7, 5.4,
                                    6.8, 7.7, 11.3, 13.1, 10.8, 6.4, 2.5, 4.2,
                                    3.6, 1.6, 3.9, 4.1, 5.0, 4.8, 4.4, 3.2, 2.8,
                                    2.8, 2.8, 3.0, 2.2, 1.7, 2.1, 3.7, 2.7, 1.5,
                                    2.1, 3.0, 3.2, 4.1, 2.4, 5.6, -2.1, 1.2,
                                    3.6, 1.4, 2.0, 2.0, 0.2, 0.8, 1.7, 2.9, 1.8,
                                    1.0, 5.4,
                                  ],
                                  color: '#264478',
                                },
                                {
                                  name: 'AUG',
                                  data: [
                                    3.0, -1.0, 7.9, 19.3, 18.5, 14.9, 14.7,
                                    -12.8, -6.2, 3.0, -0.6, 4.1, -1.7, -1.1,
                                    -0.6, 1.2, -4.6, -8.5, -10.6, -2.2, 1.5,
                                    2.2, 2.2, 3.6, -2.8, -2.1, 1.4, 6.4, 10.7,
                                    4.8, 2.3, 2.3, 11.6, 11.4, 8.9, -2.9, 2.1,
                                    6.6, 3.1, 0.7, 0.0, -0.4, 1.9, 3.7, 2.1,
                                    1.0, 1.4, 1.0, 1.3, 1.3, 1.0, 1.9, 3.5, 2.4,
                                    4.5, 5.7, 5.4, 4.6, 2.9, 7.4, 10.9, 8.6,
                                    5.7, 6.6, 7.8, 11.8, 12.9, 10.8, 5.9, 2.6,
                                    4.3, 3.3, 1.6, 4.3, 4.0, 4.7, 5.6, 3.8, 3.1,
                                    2.8, 2.9, 2.6, 2.9, 2.2, 1.6, 2.3, 3.4, 2.7,
                                    1.8, 2.2, 2.7, 3.6, 3.8, 2.0, 5.4, -1.5,
                                    1.1, 3.8, 1.7, 1.5, 1.7, 0.2, 1.1, 1.9, 2.7,
                                    1.7, 1.3, 5.3,
                                  ],
                                  color: '#9E480E',
                                },
                                {
                                  name: 'SEP',
                                  data: [
                                    2.0, -1.0, 9.9, 19.8, 18.0, 13.4, 12.4,
                                    -12.5, -5.1, 3.6, -0.6, 3.5, -1.1, -1.1,
                                    0.0, 0.0, -4.0, -9.6, -10.7, -1.5, 3.0, 0.7,
                                    2.2, 4.3, -3.4, 0.0, -0.7, 7.9, 9.3, 5.5,
                                    1.7, 2.3, 12.7, 12.7, 6.5, -2.4, 2.1, 7.0,
                                    2.3, 0.7, -0.4, 0.4, 1.9, 3.3, 2.1, 1.4,
                                    1.0, 1.4, 1.3, 1.0, 1.3, 1.6, 3.5, 2.8, 4.5,
                                    5.7, 5.7, 4.1, 3.2, 7.4, 11.9, 7.9, 5.5,
                                    6.6, 8.3, 12.2, 12.6, 11.0, 5.0, 2.9, 4.3,
                                    3.1, 1.8, 4.4, 4.2, 4.3, 6.2, 3.4, 3.0, 2.7,
                                    3.0, 2.5, 3.0, 2.2, 1.5, 2.6, 3.5, 2.6, 1.5,
                                    2.3, 2.5, 4.7, 2.1, 2.8, 4.9, -1.3, 1.1,
                                    3.9, 2.0, 1.2, 1.7, 0.0, 1.5, 2.2, 2.3, 1.7,
                                    1.4, 5.4,
                                  ],
                                  color: '#636363',
                                  name: 'OCT',
                                  data: [
                                    1.0, 1.0, 10.8, 19.5, 18.5, 13.1, 9.9,
                                    -12.1, -4.6, 3.6, -0.6, 2.9, -0.6, -1.1,
                                    -1.1, 0.6, -4.6, -9.7, -10.7, -0.8, 2.3,
                                    1.5, 2.2, 4.3, -4.1, 0.0, 0.0, 9.3, 9.2,
                                    4.2, 1.7, 2.3, 14.9, 10.6, 6.1, -2.9, 3.8,
                                    6.5, 1.9, 1.1, -0.7, 0.4, 2.2, 2.9, 2.1,
                                    1.7, 1.4, 0.7, 1.3, 1.3, 1.0, 1.9, 3.8, 2.4,
                                    4.7, 5.7, 5.6, 3.8, 3.4, 7.8, 12.1, 7.4,
                                    5.5, 6.4, 8.9, 12.1, 12.8, 10.1, 5.1, 2.9,
                                    4.3, 3.2, 1.5, 4.5, 4.2, 4.5, 6.3, 2.9, 3.2,
                                    2.8, 2.6, 2.8, 3.0, 2.1, 1.5, 2.6, 3.4, 2.1,
                                    2.0, 2.0, 3.2, 4.3, 1.3, 3.5, 3.7, -0.2,
                                    1.2, 3.5, 2.2, 1.0, 1.7, 0.2, 1.6, 2.0, 2.5,
                                    1.8, 1.2, 6.2,
                                  ],
                                  color: '#997300',
                                },
                                {
                                  name: 'NOV',
                                  data: [
                                    1.0, 1.0, 11.7, 17.4, 20.7, 13.5, 7.0,
                                    -12.1, -3.4, 3.0, -0.6, 4.7, -1.7, -2.3,
                                    -0.6, 0.6, -5.2, -10.4, -10.2, 0.0, 2.3,
                                    2.2, 1.4, 3.6, -3.4, 0.0, 0.0, 10.0, 9.1,
                                    3.6, 1.7, 2.3, 17.7, 8.5, 4.8, -1.7, 3.8,
                                    6.9, 1.1, 0.7, -0.4, 0.4, 2.2, 3.3, 2.1,
                                    1.4, 1.4, 0.7, 1.3, 1.3, 1.3, 1.6, 3.8, 2.7,
                                    4.7, 5.9, 5.6, 3.3, 3.7, 8.3, 12.2, 7.4,
                                    4.9, 6.7, 8.9, 12.6, 12.6, 9.6, 4.6, 3.3,
                                    4.1, 3.5, 1.3, 4.5, 4.2, 4.7, 6.3, 3.0, 3.0,
                                    2.7, 2.7, 2.6, 3.3, 1.8, 1.5, 2.6, 3.4, 1.9,
                                    2.2, 1.8, 3.5, 3.5, 2.0, 4.3, 1.1, 1.8, 1.1,
                                    3.4, 1.8, 1.2, 1.3, 0.5, 1.7, 2.2, 2.2, 2.1,
                                    1.2, 6.8,
                                  ],
                                  color: '#255E91',
                                },
                                {
                                  name: 'DEC',
                                  data: [
                                    1.0, 2.0, 12.6, 18.1, 20.4, 14.5, 2.6,
                                    -10.8, -2.3, 2.4, 0.0, 3.5, -1.1, -2.3,
                                    -1.2, 0.6, -6.4, -9.3, -10.3, 0.8, 1.5, 3.0,
                                    1.4, 2.9, -2.8, 0.0, 0.7, 9.9, 9.0, 3.0,
                                    2.3, 2.2, 18.1, 8.8, 3.0, -2.1, 5.9, 6.0,
                                    0.8, 0.7, -0.7, 0.4, 3.0, 2.9, 1.8, 1.7,
                                    1.4, 0.7, 1.3, 1.6, 1.0, 1.9, 3.5, 3.0, 4.7,
                                    6.2, 5.6, 3.3, 3.4, 8.7, 12.3, 6.9, 4.9,
                                    6.7, 9.0, 13.3, 12.5, 8.9, 3.8, 3.8, 3.9,
                                    3.8, 1.1, 4.4, 4.4, 4.6, 6.1, 3.1, 2.9, 2.7,
                                    2.7, 2.5, 3.3, 1.7, 1.6, 2.7, 3.4, 1.6, 2.4,
                                    1.9, 3.3, 3.4, 2.5, 4.1, 0.1, 2.7, 1.5, 3.0,
                                    1.7, 1.5, 0.8, 0.7, 2.1, 2.1, 1.9, 2.3, 1.4,
                                  ],
                                  color: '#43682B',
                                },
                                {
                                  name: 'AVG',
                                  data: [
                                    3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2,
                                    3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2,
                                    3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2,
                                    3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2,
                                    3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2,
                                    3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2,
                                    3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2,
                                    3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2,
                                    3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2,
                                    3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2,
                                    3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2,
                                    3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2,
                                  ],
                                  color: '#698ED0',
                                },
                              ],

                              plotOptions: {
                                series: {
                                  pointStart: 1917,
                                },
                                spline: {
                                  lineWidth: 2,
                                  states: {
                                    hover: {
                                      lineWidth: 2,
                                    },
                                  },
                                  legend: {
                                    width: 500,
                                    itemStyle: {
                                      fontSize: '14px',
                                    },
                                  },
                                  marker: {
                                    enabled: false,
                                  },
                                },
                              },
                              navigation: {
                                buttonOptions: {
                                  verticalAlign: 'bottom',
                                  y: -20,
                                },
                              },

                              exporting: {
                                buttons: {
                                  contextButton: {
                                    menuItems: [
                                      {
                                        textKey: 'downloadPNG',
                                        onclick() {
                                          const chart = this;
                                          chart.exportChart();
                                        },
                                      },
                                      {
                                        textKey: 'downloadJPEG',
                                        onclick() {
                                          const chart = this;
                                          chart.exportChart({
                                            type: 'image/jpeg',
                                          });
                                        },
                                      },
                                      {
                                        textKey: 'downloadPDF',
                                        onclick() {
                                          const chart = this;
                                          chart.exportChart({
                                            type: 'application/pdf',
                                          });
                                        },
                                      },
                                      {
                                        textKey: 'downloadSVG',
                                        onclick() {
                                          const chart = this;
                                          chart.exportChart({
                                            type: 'image/svg+xml',
                                          });
                                        },
                                      },
                                    ],
                                  },
                                },
                              },
                            }}
                          />
                          <p style={{ marginTop: '17px' }}>
                            Lastly, here are the returns by asset class over the
                            last 35 years.{' '}
                          </p>
                        </p>
                        <p>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                type: 'spline',
                              },
                              credits: {
                                enabled: false,
                              },
                              title: {
                                text: 'Historical Performance of Asset Classes',
                              },
                              tooltip: {
                                headerFormat:
                                  '<span style="font-size:14px">{point.key}</span><br/>',
                                crosshairs: true,
                                shared: true,
                                style: {
                                  color: '#000000',
                                  fontSize: '14px',
                                },
                              },
                              xAxis: {
                                tickInterval: 2,
                                title: {
                                  text: '',
                                },
                                categories: [
                                  1985, 1986, 1987, 1988, 1989, 1990, 1991,
                                  1992, 1993, 1994, 1995, 1996, 1997, 1998,
                                  1999, 2000, 2001, 2002, 2003, 2004, 2005,
                                  2006, 2007, 2008, 2009, 2010, 2011, 2012,
                                  2013, 2014, 2015, 2016, 2017, 2018, 2019,
                                  2020,
                                ],
                              },

                              yAxis: {
                                tickInterval: 25,
                                min: -75,
                                max: 75,
                                title: {
                                  text: 'Real Annual Returns (%)',
                                },
                                labels: {
                                  formatter: function () {
                                    return (
                                      this.axis.defaultLabelFormatter.call(
                                        this,
                                      ) + ' %'
                                    );
                                  },
                                },
                              },
                              series: [
                                {
                                  name: 'US Large Cap Stocks',
                                  data: [
                                    26.4, 16.8, 0.3, 11.3, 25.5, -8.9, 26.3,
                                    4.4, 7.0, -1.5, 34.0, 18.9, 31.0, 26.6,
                                    17.9, -12.0, -13.3, -23.9, 26.2, 7.3, 1.4,
                                    12.9, 1.3, -37.0, 23.3, 13.4, -0.9, 14.0,
                                    30.4, 12.8, 0.6, 9.7, 19.3, -6.2, 28.5,
                                    17.0,
                                  ],
                                  color: '#4472C4',
                                },
                                {
                                  name: 'US Small Cap Stocks',
                                  data: [
                                    26.2, 4.5, -12.7, 19.7, 11.0, -22.8, 40.9,
                                    14.9, 15.5, -3.1, 25.6, 14.3, 22.5, -4.2,
                                    19.9, -5.8, 1.6, -21.8, 43.1, 16.2, 3.9,
                                    12.9, -2.7, -36.1, 32.7, 26.0, -5.5, 16.2,
                                    35.8, 6.7, -4.3, 15.9, 13.8, -11.0, 24.5,
                                    17.7,
                                  ],
                                  color: '#ED7D31',
                                },
                                {
                                  name: "Int'l Dev Stocks",
                                  data: [
                                    50.3, 67.5, 19.3, 22.8, 5.6, -27.9, 8.7,
                                    -14.7, 28.9, 4.9, 8.4, 2.6, 0.0, 18.0, 23.6,
                                    -17.1, -23.1, -17.6, 36.1, 16.5, 9.8, 23.1,
                                    6.8, -41.3, 24.9, 6.8, -15.0, 16.5, 20.3,
                                    -6.4, -0.9, 0.4, 23.8, -16.1, 19.3, 8.9,
                                  ],
                                  color: '#A5A5A5',
                                  name: 'Emerging Stocks',
                                  data: [
                                    22.9, 10.4, 9.3, 33.9, 56.9, -16.1, 54.5,
                                    7.8, 69.4, -10.1, -1.9, 12.1, -18.2, -19.4,
                                    57.3, -29.9, -4.4, -9.6, 54.7, 22.1, 27.7,
                                    26.3, 33.6, -52.8, 71.5, 17.2, -21.0, 16.8,
                                    -6.4, -0.2, -16.0, 9.5, 28.7, -16.2, 17.6,
                                    13.9,
                                  ],
                                  color: '#FFC000',
                                },
                                {
                                  name: 'Cdn Stocks',
                                  data: [
                                    19.8, 4.6, 1.6, 6.8, 15.4, -18.9, 7.9, -3.4,
                                    30.3, -0.4, 12.5, 25.5, 14.2, -2.6, 28.4,
                                    4.1, -13.2, -15.7, 24.2, 12.1, 21.4, 15.5,
                                    7.2, -33.8, 33.4, 14.8, -10.8, 6.3, 11.7,
                                    9.0, -9.7, 19.3, 7.1, -10.7, 20.3, 4.3,
                                  ],
                                  color: '#5B9BD5',
                                },
                                {
                                  name: 'All US Bonds',
                                  data: [
                                    17.6, 13.9, -2.8, 2.8, 8.6, 2.4, 11.8, 4.1,
                                    6.7, -5.2, 15.3, 0.3, 7.6, 6.9, -3.4, 7.7,
                                    6.8, 5.8, 2.1, 1.0, -0.9, 1.8, 2.8, 5.1,
                                    3.2, 5.0, 4.6, 2.4, -3.6, 5.1, -0.3, 0.5,
                                    1.4, -1.9, 6.3, 6.3,
                                  ],
                                  color: '#70AD47',
                                  name: 'Hi-Yield US Bonds',
                                  data: [
                                    17.5, 15.6, -1.7, 8.8, -2.6, -11.3, 25.2,
                                    11.0, 15.1, -4.3, 16.2, 6.0, 10.0, 3.9,
                                    -0.2, -4.1, 1.3, -0.6, 15.1, 5.2, -0.5, 5.7,
                                    -1.8, -21.3, 35.6, 10.9, 4.2, 12.5, 3.1,
                                    3.9, -2.0, 9.0, 4.9, -4.7, 13.3, 4.1,
                                  ],
                                  color: '#264478',
                                },
                                {
                                  name: "Int'l Bonds",
                                  data: [
                                    7.0, 10.1, 4.5, 4.4, -0.6, -2.7, 7.5, 3.3,
                                    10.7, -7.3, 14.3, 8.3, 8.9, 10.2, -0.6, 5.4,
                                    4.6, 4.2, 0.4, 1.8, 1.8, 0.5, 0.1, 5.5, 1.6,
                                    1.7, 0.8, 4.5, -0.4, 8.0, 0.3, 2.5, 0.3,
                                    1.0, 5.5, 3.3,
                                  ],
                                  color: '#9E480E',
                                },
                                {
                                  name: 'Cdn Bonds',
                                  data: [
                                    16.1, 10.1, -0.2, 5.6, 7.2, 2.4, 17.6, 7.5,
                                    16.1, -4.5, 18.6, 9.9, 8.8, 8.1, -3.6, 6.8,
                                    7.3, 4.6, 4.6, 4.9, 4.2, 2.5, 1.3, 5.1, 4.0,
                                    4.2, 7.2, 2.8, -2.4, 7.2, 1.9, 0.2, 0.6,
                                    -0.6, 4.6, 7.1,
                                  ],
                                  color: '#636363',
                                  name: 'Cash (T-Bill)',
                                  data: [
                                    3.8, 5.0, 1.3, 2.1, 3.7, 1.6, 2.5, 0.6, 0.2,
                                    1.3, 3.1, 1.9, 3.5, 3.5, 2.0, 2.5, 2.6,
                                    -0.7, -0.9, -2.0, -0.5, 2.1, 0.7, 2.0, -2.4,
                                    -1.5, -2.9, -1.7, -1.5, -0.7, -0.7, -1.8,
                                    -1.3, -0.1, -0.1, -0.7,
                                  ],
                                  color: '#997300',
                                },
                                {
                                  name: 'REIT',
                                  data: [
                                    14.6, 17.7, -7.8, 8.6, 3.9, -20.3, 31.5,
                                    11.2, 16.3, 0.4, 10.0, 31.4, 16.8, -17.7,
                                    -6.5, 22.2, 10.7, 1.3, 33.3, 26.7, 8.3,
                                    31.8, -19.7, -37.0, 26.3, 26.6, 5.5, 15.7,
                                    0.9, 29.3, 1.6, 6.3, 2.8, -7.7, 26.1, -5.8,
                                  ],
                                  color: '#255E91',
                                },
                                {
                                  name: 'Gold',
                                  data: [
                                    1.7, 17.9, 19.0, -19.6, -6.8, -8.3, -12.5,
                                    -8.7, 13.9, -4.9, -1.7, -7.7, -23.2, -2.4,
                                    -1.7, -9.6, -0.4, 20.8, 19.2, 1.4, 13.0,
                                    19.3, 25.8, 5.4, 20.2, 26.0, 5.5, 6.5,
                                    -29.0, -1.2, -12.3, 6.6, 9.3, -3.2, 15.9,
                                    23.6,
                                  ],
                                  color: '#43682B',
                                },
                              ],

                              plotOptions: {
                                series: {
                                  pointStart: 1986,
                                },
                                spline: {
                                  lineWidth: 2,
                                  states: {
                                    hover: {
                                      lineWidth: 2,
                                    },
                                  },
                                  legend: {
                                    width: 500,
                                    itemStyle: {
                                      fontSize: '14px',
                                    },
                                  },
                                  marker: {
                                    enabled: false,
                                  },
                                },
                              },
                              navigation: {
                                buttonOptions: {
                                  verticalAlign: 'bottom',
                                  y: -20,
                                },
                              },

                              exporting: {
                                buttons: {
                                  contextButton: {
                                    menuItems: [
                                      {
                                        textKey: 'downloadPNG',
                                        onclick() {
                                          const chart = this;
                                          chart.exportChart();
                                        },
                                      },
                                      {
                                        textKey: 'downloadJPEG',
                                        onclick() {
                                          const chart = this;
                                          chart.exportChart({
                                            type: 'image/jpeg',
                                          });
                                        },
                                      },
                                      {
                                        textKey: 'downloadPDF',
                                        onclick() {
                                          const chart = this;
                                          chart.exportChart({
                                            type: 'application/pdf',
                                          });
                                        },
                                      },
                                      {
                                        textKey: 'downloadSVG',
                                        onclick() {
                                          const chart = this;
                                          chart.exportChart({
                                            type: 'image/svg+xml',
                                          });
                                        },
                                      },
                                    ],
                                  },
                                },
                              },
                            }}
                          />
                        </p>

                        <p>
                          Although it may not be easy to see in the above graph,
                          in years that inflation was higher than average,
                          stocks, real estate, and commodities usually performed
                          the best. History is not a perfect predictor of the
                          future, but there are reasons why these assets tend to
                          perform better than others in inflationary times:
                        </p>
                        <p>
                          <ul className={classes.feTextList}>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                Stocks
                                <ul>
                                  <li>
                                    The{' '}
                                    <Link
                                      to="/finance-explained/high-yield-savings-account"
                                      target="_blank">
                                      S&P500
                                    </Link>
                                    , which is a basket of 500 large U.S.
                                    stocks, has returned 10% on average over the
                                    last 100 years.
                                  </li>
                                  <li>
                                    When inflation is high, investors look to
                                    stocks to earn a higher return.
                                  </li>
                                </ul>
                              </span>
                            </li>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                Stocks that pay dividends
                                <ul>
                                  <li>
                                    A dividend is finance jargon for a
                                    predetermined cash payment that a company
                                    might pay their stockholders each quarter.
                                    It&apos;s not required and only some
                                    companies pay dividends.
                                  </li>
                                  <li>
                                    The average annual dividend yield for the
                                    S&P500 is currently 1.2%. That means, if you
                                    invested $1,000 in the SPY - an ETF that
                                    tracks the S&P500 - and held that investment
                                    for one year, you would be paid $3 (0.3%,
                                    which is one quarter of 1.2%) in cash each
                                    quarter for a total of $12 that year.
                                  </li>
                                  <li>
                                    That 1.2% return in the form of dividend
                                    cash payments plus the potential price
                                    appreciation of the stock helps fight higher
                                    inflation.
                                  </li>
                                </ul>
                              </span>
                            </li>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                Commodities
                                <ul>
                                  <li>
                                    When inflation is high, the price of goods
                                    is rising. Commodities are goods you can buy
                                    and sell such as lumber, gold, corn, coffee,
                                    oil and more.
                                  </li>
                                  <li>
                                    I know what you're thinking: James, I
                                    can&apos;t buy 30 oil drums, store them out
                                    back and sell them six months later when the
                                    price goes up. There are ETFs that you can
                                    buy just like buying a stock that will give
                                    you exposure to commodities.
                                  </li>
                                </ul>
                              </span>
                            </li>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                Real Estate
                                <ul>
                                  <li>
                                    When inflation is high, the price services
                                    is rising along with goods. Typically, that
                                    means all the inputs to build a home -
                                    commodities and labor - are also rising,
                                    making real estate more expensive. This is
                                    good if you own a home, but can make it more
                                    difficult to buy a home.
                                  </li>
                                  <li>
                                    I know what you're thinking again: James, I
                                    can&apos;t buy 100 multi family townhomes
                                    for $500,000 a piece and rent them over the
                                    next two years to capitalize on inflation.
                                    There is an asset called a REIT (real estate
                                    investment trust) that you can buy and sell
                                    just like a stock to give you exposure to
                                    the real estate market.
                                  </li>
                                </ul>
                              </span>
                            </li>
                          </ul>
                        </p>

                        <p>
                          What about the cash hidden under your bed? How do you
                          manage cash during periods of high inflation? The
                          reason you have cash is because you have a short-term
                          need for that money. Otherwise, you would invest that
                          money for the long term and try to earn a higher
                          return. But what short-term investments can you use to
                          try and soften the blow of high inflation on your
                          greenbacks?
                        </p>

                        <p className={classes.padL40}>
                          <ul className={classes.feTextList}>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                <Link
                                  to="/finance-explained/high-yield-savings-account"
                                  target="_blank">
                                  High-yield savings account
                                </Link>
                                <ul>
                                  <li>
                                    The average traditional savings account
                                    earns roughly 0.06%.
                                  </li>
                                  <li>
                                    A high-yield savings account earns between
                                    0.50% and 0.60%. That&apos;s a meaningful
                                    difference.
                                  </li>
                                  <li>
                                    You can transfer money from your high-yield
                                    savings to your checking usually in 1-2
                                    business days max, making it very
                                    accessible. It&apos;s a better option than
                                    under the mattress.
                                  </li>
                                </ul>
                              </span>
                            </li>
                          </ul>
                        </p>
                        <p>
                          And, most importantly, you need to revisit your budget
                          for the new year. Inkwiry has a{' '}
                          <Link
                            to="/financial-calculators/budget"
                            target="_blank">
                            free budgeting tool
                          </Link>{' '}
                          to make this quick and painless. What happens if your
                          expenses such as food, gas, entertainment, and
                          clothing increase by 5%? How does that impact your
                          monthly and yearly savings and investments goals?
                        </p>
                        <p>
                          In just a few minutes, you'll know the impact of
                          inflation on your 2022 financial life. When you know
                          that, you can quickly build a strategy to reach your
                          monthly savings target. Without a plan, your costs
                          could sneak up on you and you could fall short of your
                          money goals in the new year.
                        </p>
                        <p>
                          With a plan, you make things happen. Here&apos;s to
                          making things happen in the new year.
                        </p>

                        <div className={classes.hitTapRow}>
                          <h3>Just hit the tape</h3>
                          <ul>
                            <li>
                              {' '}
                              <a
                                href="https://www.officialdata.org/us/stocks/s-p-500/1871"
                                target="_blank"
                                rel="noopener noreferrer">
                                Stock market returns since 1871
                              </a>{' '}
                            </li>
                            <li>
                              {' '}
                              <a
                                href="https://www.morningstar.com/articles/1060406/theres-more-to-investing-than-just-risk-and-return"
                                target="_blank"
                                rel="noopener noreferrer">
                                There&apos;s more to investing to risk and
                                reward
                              </a>{' '}
                            </li>
                            <li>
                              {' '}
                              <a
                                href="https://youngmoneyweekly.substack.com/p/so-you-wanna-trade-stocks"
                                target="_blank"
                                rel="noopener noreferrer">
                                So you wanna trade stocks?
                              </a>{' '}
                            </li>
                          </ul>
                          <p>
                            Get smarter about your money. Explore more financial
                            terms on our website in{' '}
                            <Link to="/finance-explained">
                              Finance Explained.
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
InvestingduringHighInflation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InvestingduringHighInflation);
