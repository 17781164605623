import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import styles from '../styles';

const NavbarItemTitle = styled.button`
  background: transparent;
  border: 0;
  font-weight: bold;
  font-family: inherit;
  font-size: 18px;
  padding: 2rem 1.5rem 1.2rem 1.5rem;
  color: white;
  display: flex;
  justify-content: center;
  transition: opacity 250ms;
  cursor: pointer;
  /* position above the dropdown, otherwise the dropdown will cover up the bottom sliver of the buttons */
  position: relative;
  z-index: 2;
  &:hover, &:focus {
    opacity: 0.7;
    outline:none;
  }
`;

const NavbarItemEl = styled.li`
  position: relative;
`;

const DropdownSlot = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  perspective: 1500px;
`;

const NavbarItem = ( props ) => {
  const { classes, title, children} = props;
  const [active, setActive] = useState(false);
  const onMouseEnter = () => {
    props.onMouseEnter( props.index );
  };

  const ref = React.useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if ( document.getElementById( 'menu_overlay' ) ) {
        const menuOverlay = document.getElementById( 'menu_overlay' );
        menuOverlay.remove();
      }
      setActive(false)
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <NavbarItemEl ref = {ref} onMouseEnter={ ()=>{
      setActive(true);
      onMouseEnter();
    } } onFocus={ onMouseEnter } onClick = {()=>{
      setActive(prevState => (!prevState));
    }}>
      <NavbarItemTitle>{title} <span className={(active ? ( classes.arrowIconActive + " " + classes.arrowIcon) : classes.arrowIcon)} ><i className="fa fa-caret-down"></i></span></NavbarItemTitle>
      { active && <DropdownSlot>{children}</DropdownSlot>}
    </NavbarItemEl>
  );
};

NavbarItem.propTypes = {
  onMouseEnter: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

NavbarItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(NavbarItem);
