import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruSavings = (props) => {
    const {
        classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide, handleWalkThrusBack,walkThruNo
    } = props;

    return (
        <div className={classNames(classes.worksheetWalkthru, classes.worksheetActiveWalkthru, classes.walkthruEight)}>
            <h4>What If I Saved More Tool in the Show Me section</h4>
            <p>Scroll to the bottom of the Show Me section and use the more savings tool. This tool allows you to ask, "What if I could save just a little more each month, how much money will that translate into over time?" Once you enter your details above and save that scenario, check out the more savings tool to see how saving a little bit more money each month can grow over time.</p>
            <div className={classes.walkthruBottom}>
                <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                <div className={classes.walkThruBtns}>
                    <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(9) }}>Back</Button>
                    <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(9); }}>Next</Button>
                    <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                </div>
            </div>
        </div>
    );
};

WalkThruSavings.propTypes = {
    classes: PropTypes.object.isRequired,
    handleWalkThrus: PropTypes.func.isRequired,
    handleWalkThruClose: PropTypes.func.isRequired,
    completedWalkThruSlides: PropTypes.object.isRequired,
    activeWalkThruSlide: PropTypes.string.isRequired,
    handleWalkThrusBack: PropTypes.func.isRequired,
    walkThruNo: PropTypes.string.isRequired,
};

export default withStyles(styles)(WalkThruSavings);