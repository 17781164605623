function familySheet( module1Data, module10Data, incomeStatementnetData, assumptionsData, cashFlowStatementData, spouseStudentLoanData ) {
  let year = 0;
  let yearLimit = 0;
  let chartYear = 0;
  let foodVal = 0;
  let clothingVal = 0;
  let entertainmentVal = 0;
  let technologyVal = 0;
  let transportationVal = 0;
  let carMaintenanceVal = 0;
  let carInsuranceVal = 0;
  let carPaymentVal = 0;
  let gasVal = 0;
  let carVal = 0;
  let miscellaneousVal = 0;
  let healthInsuranceDeductibleVal = 0;
  let healthInsurancePremiumVal = 0;
  let healthInsuranceVal = 0;
  let offCampusFoodVal = 0;
  let childrenVal = 0;
  let petVal = 0;
  let homePropertyExpensesVal = 0;
  let propertyTaxesVal = 0;


  let totalTuitionValue = 0;
  let totalRentValue = 0;
  let totalUtilitiesValue = 0;
  let totalFoodValue = 0;
  let totalClothingValue = 0;
  let totalEntertainmentValue = 0;
  let totalTechnologyValue = 0;
  let totalTransportationValue = 0;
  let totalGasValue = 0;
  let totalMiscellaneousValue = 0;
  let totalCarValue = 0;
  let totalHealthValue = 0;
  let totalChildrenValue = 0;
  let totalPetValue = 0;
  let totalHomePropertyExpensesValue = 0;
  let totalPropertyTaxesValue = 0;
  let totalValues = 0;
  let incomeBonusTipsVal = 0;
  let incomeSupplementaryIncomeVal = 0;
  let incomeTotalTaxesVal = 0;
  let retirementContributionsVal = 0;
  let totalLivingExpensesVal = 0;
  let totalDepreciationExpensesVal = 0;
  let totalInterestExpensesVal = 0;
  let netIncomeVal = 0;

  let careerOutputBackend = {};

  year = parseInt( module10Data.start_year, 10 );
  yearLimit = parseInt( module10Data.start_year, 10 ) + 30;

  careerOutputBackend = {};

  totalTuitionValue = 0;
  totalRentValue = 0;
  totalUtilitiesValue = 0;
  totalFoodValue = 0;
  totalClothingValue = 0;
  totalEntertainmentValue = 0;
  totalTechnologyValue = 0;
  totalTransportationValue = 0;
  totalGasValue = 0;
  totalMiscellaneousValue = 0;
  totalCarValue = 0;
  totalHealthValue = 0;
  totalChildrenValue = 0;
  totalPetValue = 0;
  totalHomePropertyExpensesValue = 0;
  totalPropertyTaxesValue = 0;
  totalValues = 0;
  // Spouse Student Loan Array
  const spouseStudentLoanBalance = [];
  const spousePrincipalPayback = [];
  const spouseInterest = [];
  const spousePayment = [];
  const spousesYearsList = [];
  let spouseStudentLoanBalanceVal = 0;
  let spousePrincipalPaybackVal = 0;
  let spouseInterestVal = 0;
  let spousePaymentVal = 0;

  const bar2GraphYearsList = [];
  const netRentalIncomeValArr = [];
  const spouseIncomeValArr = [];
  const spouseBonusTipsCommissionArr = [];
  const baseIncomeValArr = [];
  const incomeBonusTipsArr = [];
  const incomeincomeSupplementaryIncomeArr = [];
  const totalTaxesArr = [];
  const retirementContributionsArr = [];
  const totalLivingExpensesArr = [];
  const totalDepreciationExpensesArr = [];
  const totalInterestExpensesArr = [];
  const netIncomeArr = [];
  //  const k = 0;
  let tuitionVal = 0;
  let rentVal = 0;
  let utilitiesVal = 0;
  let spouseIncomeVal = 0;
  let spouseBonusTipsCommissionVal = 0;

  for ( year; year < yearLimit; year += 1 ) {
    careerOutputBackend[year] = {};

    // Chart Information
    if ( typeof module10Data.start_year !== 'undefined' && module10Data.start_year !== '' ) {
      if ( year === parseInt( module10Data.start_year, 10 ) ) {
        chartYear = module10Data.start_year;
      } else if ( typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== ''
          && chartYear > 0 ) {
        if ( ( parseInt( chartYear, 10 ) + 1 ) <= ( parseInt( module1Data.start_year, 10 ) + 14 ) ) {
          chartYear = parseInt( chartYear, 10 ) + 1;
        } else {
          chartYear = 0;
        }
      } else {
        chartYear = 0;
      }
    } else {
      chartYear = 0;
    }
    careerOutputBackend[year].Year = chartYear;

    if ( chartYear > 0 ) {
      // Tuition Value
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Higher Education Tuition and Fees'] !== undefined && incomeStatementnetData[year].livingExpenses['Higher Education Tuition and Fees'] !== '' ) {
        tuitionVal = -incomeStatementnetData[year].livingExpenses['Higher Education Tuition and Fees'];
      } else {
        tuitionVal = 0;
      }
      careerOutputBackend[year]['Tuition Costs'] = tuitionVal;
      if ( tuitionVal > 0 ) {
        totalTuitionValue += tuitionVal;
      }

      // Rent Value
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Rent !== '' ) {
        rentVal = -incomeStatementnetData[year].livingExpenses.Rent;
      } else {
        rentVal = 0;
      }
      careerOutputBackend[year].Rent = rentVal;
      if ( rentVal > 0 ) {
        totalRentValue += rentVal;
      }

      // Calculation for Utilities
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Utilities !== '' ) {
        utilitiesVal = -incomeStatementnetData[year].livingExpenses.Utilities;
      } else {
        utilitiesVal = 0;
      }
      careerOutputBackend[year].Utilities = utilitiesVal;
      if ( utilitiesVal > 0 ) {
        totalUtilitiesValue += utilitiesVal;
      }

      // Calculation for Off Campus Food
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Off Campus Food'] !== '' ) {
        offCampusFoodVal = -incomeStatementnetData[year].livingExpenses['Off Campus Food'];
      } else {
        offCampusFoodVal = 0;
      }
      careerOutputBackend[year]['Off Campus Food'] = offCampusFoodVal;

      // Calculation for Food
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Food !== '' ) {
        foodVal = -incomeStatementnetData[year].livingExpenses.Food;
      } else {
        foodVal = 0;
      }
      careerOutputBackend[year].Food = foodVal;
      if ( foodVal > 0 ) {
        totalFoodValue += foodVal;
      }

      // Calculation for Clothing
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Clothing !== '' ) {
        clothingVal = -incomeStatementnetData[year].livingExpenses.Clothing;
      } else {
        clothingVal = 0;
      }
      careerOutputBackend[year].Clothing = clothingVal;
      if ( clothingVal > 0 ) {
        totalClothingValue += clothingVal;
      }

      // Calculation for Entertainment
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Entertainment !== '' ) {
        entertainmentVal = -incomeStatementnetData[year].livingExpenses.Entertainment;
      } else {
        entertainmentVal = 0;
      }
      careerOutputBackend[year].Entertainment = entertainmentVal;
      if ( entertainmentVal > 0 ) {
        totalEntertainmentValue += entertainmentVal;
      }

      // Calculation for Technology
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Technology !== '' ) {
        technologyVal = -incomeStatementnetData[year].livingExpenses.Technology;
      } else {
        technologyVal = 0;
      }
      careerOutputBackend[year].Technology = technologyVal;
      if ( technologyVal > 0 ) {
        totalTechnologyValue += technologyVal;
      }

      // Calculation for Transportation
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Transportation !== '' ) {
        transportationVal = -incomeStatementnetData[year].livingExpenses.Transportation;
      } else {
        transportationVal = 0;
      }
      careerOutputBackend[year].Transportation = transportationVal;
      if ( transportationVal > 0 ) {
        totalTransportationValue += transportationVal;
      }

      // Calculation for Car Maintenance
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Car Maintenance'] !== '' ) {
        carMaintenanceVal = -incomeStatementnetData[year].livingExpenses['Car Maintenance'];
      } else {
        carMaintenanceVal = 0;
      }
      careerOutputBackend[year]['Car Maintenance'] = carMaintenanceVal;

      // Calculation for Car Insurance
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Car Insurance'] !== '' ) {
        carInsuranceVal = -incomeStatementnetData[year].livingExpenses['Car Insurance'];
      } else {
        carInsuranceVal = 0;
      }
      careerOutputBackend[year]['Car Insurance'] = carInsuranceVal;

      // Calculation for Car payment
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Car Payment'] !== '' ) {
        carPaymentVal = -incomeStatementnetData[year].livingExpenses['Car Payment'];
      } else {
        carPaymentVal = 0;
      }
      careerOutputBackend[year]['Car Payment'] = carPaymentVal;

      // Calculation for Car
      carVal = careerOutputBackend[year]['Car Maintenance'] + careerOutputBackend[year]['Car Insurance'] + careerOutputBackend[year]['Car Payment'];
      careerOutputBackend[year].Car = carVal;
      if ( carVal > 0 ) {
        totalCarValue += carVal;
      }

      // Calculation for Gas
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Gas !== '' ) {
        gasVal = -incomeStatementnetData[year].livingExpenses.Gas;
      } else {
        gasVal = 0;
      }
      careerOutputBackend[year].Gas = gasVal;
      if ( gasVal > 0 ) {
        totalGasValue += gasVal;
      }

      // Calculation for Miscellaneous
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Miscellaneous !== '' ) {
        miscellaneousVal = -incomeStatementnetData[year].livingExpenses.Miscellaneous;
      } else {
        miscellaneousVal = 0;
      }
      careerOutputBackend[year].Miscellaneous = miscellaneousVal;
      if ( miscellaneousVal > 0 ) {
        totalMiscellaneousValue += miscellaneousVal;
      }

      // Calculation for Health Insurance Deductible
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Healthcare Costs other than Premium'] !== '' ) {
        healthInsuranceDeductibleVal = -incomeStatementnetData[year].livingExpenses['Healthcare Costs other than Premium'];
      } else {
        healthInsuranceDeductibleVal = 0;
      }
      careerOutputBackend[year]['Health Insurance Deductible'] = healthInsuranceDeductibleVal;

      // Calculation for Health Insurance Premium
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Health Insurance Premium'] !== '' ) {
        healthInsurancePremiumVal = -incomeStatementnetData[year].livingExpenses['Health Insurance Premium'];
      } else {
        healthInsurancePremiumVal = 0;
      }
      careerOutputBackend[year]['Health Insurance Premium'] = healthInsurancePremiumVal;

      // Calculation for Health Insurance
      healthInsuranceVal = careerOutputBackend[year]['Health Insurance Deductible'] + careerOutputBackend[year]['Health Insurance Premium'];
      careerOutputBackend[year]['Health Insurance'] = healthInsuranceVal;
      if ( healthInsuranceVal > 0 ) {
        totalHealthValue += healthInsuranceVal;
      }

      // Calculation for Children
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Children !== '' ) {
        childrenVal = -incomeStatementnetData[year].livingExpenses.Children;
      } else {
        childrenVal = 0;
      }
      careerOutputBackend[year].Children = childrenVal;
      if ( childrenVal > 0 ) {
        totalChildrenValue += childrenVal;
      }

      // Calculation for Pet
      if ( typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Pet !== '' ) {
        petVal = -incomeStatementnetData[year].livingExpenses.Pet;
      } else {
        petVal = 0;
      }
      careerOutputBackend[year].Pet = petVal;
      if ( petVal > 0 ) {
        totalPetValue += petVal;
      }

      // Calculation for Home property Expenses
      if ( typeof incomeStatementnetData !== 'undefined' && incomeStatementnetData !== '' && typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Home Property Expenses'] !== undefined ) {
        homePropertyExpensesVal = -( incomeStatementnetData[year].livingExpenses['Home Property Expenses'] + incomeStatementnetData[year].livingExpenses['Home Property Taxes'] );
      } else {
        homePropertyExpensesVal = 0;
      }
      careerOutputBackend[year]['Home Property Expenses'] = homePropertyExpensesVal;
      if ( homePropertyExpensesVal > 0 ) {
        totalHomePropertyExpensesValue += homePropertyExpensesVal;
      }

      // Calculation for Property Taxes
      if ( typeof incomeStatementnetData !== 'undefined' && incomeStatementnetData !== '' && typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Rental Property Taxes'] !== undefined ) {
        propertyTaxesVal = -( incomeStatementnetData[year].livingExpenses['Rental Operating Expenses'] + incomeStatementnetData[year].livingExpenses['Rental Property Taxes'] );
      } else {
        propertyTaxesVal = 0;
      }
      careerOutputBackend[year]['Property Taxes'] = propertyTaxesVal;
      if ( propertyTaxesVal > 0 ) {
        totalPropertyTaxesValue += propertyTaxesVal;
      }

      // Calcualtion Total value
      totalValues = careerOutputBackend[year]['Tuition Costs']
        + careerOutputBackend[year].Rent
        + careerOutputBackend[year].Utilities
        + careerOutputBackend[year].Food
        + careerOutputBackend[year].Clothing
        + careerOutputBackend[year].Entertainment
        + careerOutputBackend[year].Technology
        + careerOutputBackend[year].Transportation
        + careerOutputBackend[year].Car
        + careerOutputBackend[year].Gas
        + careerOutputBackend[year].Miscellaneous
        + careerOutputBackend[year]['Health Insurance']
        + careerOutputBackend[year].Children
        + careerOutputBackend[year].Pet
        + careerOutputBackend[year]['Home Property Expenses']
        + careerOutputBackend[year]['Property Taxes'];
      careerOutputBackend[year].Total = totalValues;

      /** Bar Graph Section * */
      //                    if(bar2GraphYearsList.indexOf(bar2GraphYearsList) === -1){
      //                        bar2GraphYearsList.push(year);
      //                    }

      // Calculate Base Income
      let incomeBasicIncomeVal = 0;

      // this code commenented by mahananda bacause $rootScope is not there
      // if (typeof incomeStatementnetData !== 'undefined' && incomeStatementnetData !== '' &&
      //   typeof incomeStatementnetData[year] !== 'undefined' &&
      //   typeof incomeStatementnetData[year]['Gross Income']['Income Earned during Higher Education'] !== 'undefined' &&
      //   typeof incomeStatementnetData[year]['Gross Income'][$rootScope.occupationTitle] !== 'undefined' &&
      //   typeof incomeStatementnetData[year]['Gross Income'][$rootScope.advanceOccupationTitle + ' - Second'] !== 'undefined' &&
      //   typeof incomeStatementnetData[year]['Gross Income'][$rootScope.jobTitle + ' - Third'] !== 'undefined'
      //   && typeof incomeStatementnetData[year]['Gross Income'][$rootScope.furtherjobTitle + ' - Fourth']) {
      //   incomeBasicIncomeVal =
      //     incomeStatementnetData[year]['Gross Income']['Income Earned during Higher Education'] +
      //     incomeStatementnetData[year]['Gross Income'][$rootScope.occupationTitle] +
      //     incomeStatementnetData[year]['Gross Income'][$rootScope.advanceOccupationTitle + ' - Second'] +
      //     incomeStatementnetData[year]['Gross Income'][$rootScope.jobTitle + ' - Third'] +
      //     incomeStatementnetData[year]['Gross Income'][$rootScope.furtherjobTitle + ' - Fourth'];
      // } else {
      //   incomeBasicIncomeVal = 0;
      // }

      if ( typeof incomeStatementnetData !== 'undefined' && incomeStatementnetData !== ''
      && typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year]['Gross Income'] !== undefined
      && typeof incomeStatementnetData[year]['Gross Income']['Income Earned during Higher Education'] !== 'undefined' ) {
        incomeBasicIncomeVal = incomeStatementnetData[year]['Gross Income']['Income Earned during Higher Education'];
      } else {
        incomeBasicIncomeVal = 0;
      }
      baseIncomeValArr.push( incomeBasicIncomeVal );

      // Calculate Bonus / Tips / Commission
      if ( typeof incomeStatementnetData !== 'undefined' && incomeStatementnetData !== ''
        && typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year]['Gross Income'] !== undefined
        && typeof incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission'] !== 'undefined'
        && typeof incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission - Second'] !== 'undefined'
        && typeof incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission - Third'] !== 'undefined'
        && typeof incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'] !== 'undefined' ) {
        incomeBonusTipsVal = incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission']
          + incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission - Second']
          + incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission - Third']
          + incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'];
      } else {
        incomeBonusTipsVal = 0;
      }
      incomeBonusTipsArr.push( incomeBonusTipsVal );

      // Calculate Supplementary Income Value
      if ( typeof incomeStatementnetData !== 'undefined' && incomeStatementnetData !== ''
        && typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year]['Gross Income'] !== undefined
        && typeof incomeStatementnetData[year]['Gross Income']['Supplementary Income'] !== 'undefined'
        && typeof incomeStatementnetData[year]['Gross Income']['Supplementary Income - Second'] !== 'undefined'
        && typeof incomeStatementnetData[year]['Gross Income']['Supplementary Income - Third'] !== 'undefined'
        && typeof incomeStatementnetData[year]['Gross Income']['Supplementary Income - Fourth'] !== 'undefined' ) {
        incomeSupplementaryIncomeVal = incomeStatementnetData[year]['Gross Income']['Supplementary Income']
          + incomeStatementnetData[year]['Gross Income']['Supplementary Income - Second']
          + incomeStatementnetData[year]['Gross Income']['Supplementary Income - Third']
          + incomeStatementnetData[year]['Gross Income']['Supplementary Income - Fourth'];
      } else {
        incomeSupplementaryIncomeVal = 0;
      }
      incomeincomeSupplementaryIncomeArr.push( incomeSupplementaryIncomeVal );

      // Calculate Spouse Income
      if ( typeof module10Data.start_year !== 'undefined' && module10Data.start_year !== '' && year === module10Data.start_year ) {
        if ( typeof module10Data.spouse_income !== 'undefined' && module10Data.spouse_income !== '' ) {
          spouseIncomeVal = module10Data.spouse_income;
        } else {
          spouseIncomeVal = 0;
        }
      } else if ( typeof module10Data.second_start_year !== 'undefined' && module10Data.second_start_year !== '' && year === module10Data.second_start_year ) {
        if ( typeof module10Data.second_spouse_income !== 'undefined' && module10Data.second_spouse_income !== '' ) {
          spouseIncomeVal = module10Data.second_spouse_income;
        } else {
          spouseIncomeVal = 0;
        }
      } else if ( typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined' ) {
        spouseIncomeVal *= ( ( assumptionsData[year].Income ) / 100 );
      } else {
        spouseIncomeVal = 0;
      }
      spouseIncomeValArr.push( spouseIncomeVal );

      // Calculate Spouse Bonus Tips Commission
      if ( typeof module10Data.start_year !== 'undefined' && module10Data.start_year !== '' && year === module10Data.start_year ) {
        if ( typeof module10Data.spouse_bonus_or_tips_or_commission !== 'undefined' && module10Data.spouse_bonus_or_tips_or_commission !== '' ) {
          spouseBonusTipsCommissionVal = module10Data.spouse_bonus_or_tips_or_commission;
        } else {
          spouseBonusTipsCommissionVal = 0;
        }
      } else if ( typeof module10Data.second_start_year !== 'undefined' && module10Data.second_start_year !== '' && year === module10Data.second_start_year ) {
        if ( typeof module10Data.second_spouse_bonus_or_tips_or_commission !== 'undefined' && module10Data.second_spouse_bonus_or_tips_or_commission !== '' ) {
          spouseBonusTipsCommissionVal = module10Data.second_spouse_bonus_or_tips_or_commission;
        } else {
          spouseBonusTipsCommissionVal = 0;
        }
      } else if ( typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined' ) {
        spouseBonusTipsCommissionVal *= ( ( assumptionsData[year].Income ) / 100 );
      } else {
        spouseBonusTipsCommissionVal = 0;
      }
      spouseBonusTipsCommissionArr.push( spouseBonusTipsCommissionVal );

      // Calculate Net Rental Income
      let netRentalIncomeVal = 0;
      if ( typeof incomeStatementnetData !== 'undefined' && incomeStatementnetData !== ''
        && typeof incomeStatementnetData[year] !== 'undefined' && incomeStatementnetData[year]['Gross Income'] !== undefined
        && typeof incomeStatementnetData[year]['Gross Income']['Rental Income'] !== 'undefined' && incomeStatementnetData[year]['Gross Income']['Rental Income'] !== '' ) {
        netRentalIncomeVal = incomeStatementnetData[year]['Gross Income']['Rental Income'];
      } else {
        netRentalIncomeVal = 0;
      }
      netRentalIncomeValArr.push( netRentalIncomeVal );

      // Calculate Taxes Value
      if ( typeof incomeStatementnetData !== 'undefined' && incomeStatementnetData !== ''
        && typeof incomeStatementnetData[year] !== 'undefined'
        && typeof incomeStatementnetData[year]['Total Taxes'] !== 'undefined' && incomeStatementnetData[year]['Total Taxes'] !== '' ) {
        incomeTotalTaxesVal = -incomeStatementnetData[year]['Total Taxes'];
      } else {
        incomeTotalTaxesVal = 0;
      }
      totalTaxesArr.push( incomeTotalTaxesVal );

      // Calculate Retirement Contributions Value
      if ( typeof cashFlowStatementData !== 'undefined' && cashFlowStatementData !== ''
        && typeof cashFlowStatementData[year] !== 'undefined' && cashFlowStatementData[year]['Cash from Investing Activities'] !== undefined
        && typeof cashFlowStatementData[year]['Cash from Investing Activities']['Retirement Contributions'] !== 'undefined' && cashFlowStatementData[year]['Cash from Investing Activities']['Retirement Contributions'] !== '' ) {
        retirementContributionsVal = -cashFlowStatementData[year]['Cash from Investing Activities']['Retirement Contributions'];
      } else {
        retirementContributionsVal = 0;
      }
      retirementContributionsArr.push( retirementContributionsVal );

      // Calculate Living Expenses Value
      if ( typeof incomeStatementnetData !== 'undefined' && incomeStatementnetData !== ''
        && typeof incomeStatementnetData[year] !== 'undefined'
        && typeof incomeStatementnetData[year]['Total Living Expenses'] !== 'undefined' && incomeStatementnetData[year]['Total Living Expenses'] !== '' ) {
        totalLivingExpensesVal = -( incomeStatementnetData[year]['Total Living Expenses'] + incomeStatementnetData[year]['Total Car Expenses'] + incomeStatementnetData[year]['Total Real Estate Expenses'] );
      } else {
        totalLivingExpensesVal = 0;
      }
      totalLivingExpensesArr.push( totalLivingExpensesVal );

      // Calculate Depreciation Value
      if ( typeof incomeStatementnetData !== 'undefined' && incomeStatementnetData !== ''
        && typeof incomeStatementnetData[year] !== 'undefined'
        && typeof incomeStatementnetData[year]['Total Depreciation Expenses'] !== 'undefined' && incomeStatementnetData[year]['Total Depreciation Expenses'] !== '' ) {
        totalDepreciationExpensesVal = -incomeStatementnetData[year]['Total Depreciation Expenses'];
      } else {
        totalDepreciationExpensesVal = 0;
      }
      totalDepreciationExpensesArr.push( totalDepreciationExpensesVal );

      // Calculate Interest Expenses Value
      if ( typeof incomeStatementnetData !== 'undefined' && incomeStatementnetData !== ''
        && typeof incomeStatementnetData[year] !== 'undefined'
        && typeof incomeStatementnetData[year]['Total Interest Expenses'] !== 'undefined' && incomeStatementnetData[year]['Total Interest Expenses'] !== '' ) {
        totalInterestExpensesVal = -incomeStatementnetData[year]['Total Interest Expenses'];
      } else {
        totalInterestExpensesVal = 0;
      }
      totalInterestExpensesArr.push( totalInterestExpensesVal );

      // Calculate Net Income Value
      if ( typeof incomeStatementnetData !== 'undefined' && incomeStatementnetData !== ''
        && typeof incomeStatementnetData[year] !== 'undefined'
        && typeof incomeStatementnetData[year]['Net Income'] !== 'undefined' && incomeStatementnetData[year]['Net Income'] !== '' ) {
        netIncomeVal = incomeStatementnetData[year]['Net Income'];
      } else {
        netIncomeVal = 0;
      }
      netIncomeArr.push( netIncomeVal );
      if ( incomeBasicIncomeVal > 0 || incomeBonusTipsVal > 0 || incomeSupplementaryIncomeVal > 0 || spouseIncomeVal > 0 || spouseBonusTipsCommissionVal > 0 || netRentalIncomeVal > 0 || incomeTotalTaxesVal > 0 || retirementContributionsVal > 0 || totalLivingExpensesVal > 0 || totalInterestExpensesVal > 0 ) {
        bar2GraphYearsList.push( year );
      }
      // Spouse Student Loan Graph
      if (spouseStudentLoanData && typeof spouseStudentLoanData !== 'undefined' && spouseStudentLoanData !== '' && spouseStudentLoanData['Summation Data'] && spouseStudentLoanData['Summation Data'][year] && typeof spouseStudentLoanData['Summation Data'][year] !== 'undefined' && spouseStudentLoanData['Summation Data'][year] !== '' && typeof spouseStudentLoanData['Summation Data'][year].EndingBalance !== 'undefined' ) {
        spouseStudentLoanBalanceVal = Math.round( spouseStudentLoanData['Summation Data'][year].EndingBalance );
      }
      if (spouseStudentLoanData && typeof spouseStudentLoanData !== 'undefined' && spouseStudentLoanData !== '' && spouseStudentLoanData['Summation Data'] && spouseStudentLoanData['Summation Data'][year] &&typeof spouseStudentLoanData['Summation Data'][year] !== 'undefined' && spouseStudentLoanData['Summation Data'][year] !== '' && typeof spouseStudentLoanData['Summation Data'][year].LaggingPrincipal !== 'undefined' ) {
        spousePrincipalPaybackVal = Math.round( spouseStudentLoanData['Summation Data'][year].LaggingPrincipal );
      }
      if ( spouseStudentLoanData && spouseStudentLoanData['Summation Data'] && spouseStudentLoanData['Summation Data'][year] && typeof spouseStudentLoanData !== 'undefined' && spouseStudentLoanData !== '' && typeof spouseStudentLoanData['Summation Data'][year] !== 'undefined' && spouseStudentLoanData['Summation Data'][year] !== '' && typeof spouseStudentLoanData['Summation Data'][year].LaggingInterest !== 'undefined' ) {
        spouseInterestVal = Math.round( spouseStudentLoanData['Summation Data'][year].LaggingInterest );
      }
      spousePaymentVal = Math.round( spousePrincipalPaybackVal + spouseInterestVal );
      if ( spouseStudentLoanBalanceVal > 0 || spousePrincipalPaybackVal > 0 || spouseInterestVal > 0 || spousePaymentVal > 0 ) {
        spouseStudentLoanBalance.push( spouseStudentLoanBalanceVal );
        spousePrincipalPayback.push( spousePrincipalPaybackVal );
        spouseInterest.push( spouseInterestVal );
        spousePayment.push( spousePrincipalPaybackVal + spouseInterestVal );
        spousesYearsList.push( year );
      }
    }
  }

  function familyOutputBackendData() {
    const graphvaluesData = {};

    graphvaluesData['Pie Chart'] = {};
    graphvaluesData['Pie Chart'].totalTuitionCostsValue = totalTuitionValue;
    graphvaluesData['Pie Chart'].totalRentValue = totalRentValue;
    graphvaluesData['Pie Chart'].totalUtilitiesValue = totalUtilitiesValue;
    graphvaluesData['Pie Chart'].totalFoodValue = totalFoodValue;
    graphvaluesData['Pie Chart'].totalClothingValue = totalClothingValue;
    graphvaluesData['Pie Chart'].totalEntertainmentValue = totalEntertainmentValue;
    graphvaluesData['Pie Chart'].totalTechnologyValue = totalTechnologyValue;
    graphvaluesData['Pie Chart'].totalTransportationValue = totalTransportationValue;
    graphvaluesData['Pie Chart'].totalCarValue = totalCarValue;
    graphvaluesData['Pie Chart'].totalGasValue = totalGasValue;
    graphvaluesData['Pie Chart'].totalMiscellaneousValue = totalMiscellaneousValue;
    graphvaluesData['Pie Chart'].totalHealthInsuranceValue = totalHealthValue;
    graphvaluesData['Pie Chart'].totalChildrenValue = totalChildrenValue;
    graphvaluesData['Pie Chart'].totalPetValue = totalPetValue;
    graphvaluesData['Pie Chart'].totalHomePropertyExpensesValue = totalHomePropertyExpensesValue;
    graphvaluesData['Pie Chart'].totalPropertyTaxesValue = totalPropertyTaxesValue;

    // Total Amount
    graphvaluesData['Pie Chart'].Total = parseFloat( totalTuitionValue )
      + parseFloat( totalRentValue )
      + parseFloat( totalUtilitiesValue )
      + parseFloat( totalFoodValue )
      + parseFloat( totalClothingValue )
      + parseFloat( totalEntertainmentValue )
      + parseFloat( totalTechnologyValue )
      + parseFloat( totalTransportationValue )
      + parseFloat( totalCarValue )
      + parseFloat( totalGasValue )
      + parseFloat( totalMiscellaneousValue )
      + parseFloat( totalHealthValue )
      + parseFloat( totalChildrenValue )
      + parseFloat( totalPetValue )
      + parseFloat( totalHomePropertyExpensesValue )
      + parseFloat( totalPropertyTaxesValue );

    graphvaluesData['Bar Graph'] = {};
    graphvaluesData['Bar Graph'].YearsList = bar2GraphYearsList;
    graphvaluesData['Bar Graph']['Base Income'] = baseIncomeValArr;
    graphvaluesData['Bar Graph']['Bonus / Tips / Commission'] = incomeBonusTipsArr;
    graphvaluesData['Bar Graph']['Supplementary Income'] = incomeincomeSupplementaryIncomeArr;
    graphvaluesData['Bar Graph']['Spouse Income'] = spouseIncomeValArr;
    graphvaluesData['Bar Graph']['Spouse Bonus / Tips / Commission'] = spouseBonusTipsCommissionArr;
    graphvaluesData['Bar Graph']['Net Rental Income'] = netRentalIncomeValArr;
    graphvaluesData['Bar Graph'].Taxes = totalTaxesArr;
    graphvaluesData['Bar Graph']['Retirement Contributions'] = retirementContributionsArr;
    graphvaluesData['Bar Graph']['Living Expenses'] = totalLivingExpensesArr;
    graphvaluesData['Bar Graph'].Depreciation = totalDepreciationExpensesArr;
    graphvaluesData['Bar Graph']['Interest Expenses'] = totalInterestExpensesArr;
    graphvaluesData['Bar Graph']['Net Income'] = netIncomeArr;

    // Spouse Graph
    graphvaluesData['Spouse Bar Graph'] = {};
    graphvaluesData['Spouse Bar Graph'].YearsList = spousesYearsList;
    graphvaluesData['Spouse Bar Graph']['Student Loan Balance'] = spouseStudentLoanBalance;
    graphvaluesData['Spouse Bar Graph']['Principal Payback'] = spousePrincipalPayback;
    graphvaluesData['Spouse Bar Graph'].Interest = spouseInterest;
    graphvaluesData['Spouse Bar Graph'].Payment = spousePayment;
    return graphvaluesData;
  }
  return familyOutputBackendData();
}
export default familySheet;