import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/higher-education/styles';

const NavigatingFinancialAid = ( props ) => {
  const {
    classes, handleDefinationPopup
  } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>
        <span className={ classes.financialAid } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Other Financial Aid' ) }>Financial aid </span>
        {' '}
        is money given to students by a school, federal government, and other organizations to help pay for higher education. There are two types of financial aid: merit-based and need-based.
      </p>
      <ul className={ classes.financialAidGaps }>
        <li>
          Merit-based financial aid consists of scholarships and grants awarded based on academic, athletic, artistic, or other performance measures
        </li>
        <li>
          <div>
            Need-based financial aid consists of scholarships, grants, work-study, and loans, and is awarded based on your
            <strong>&nbsp;financial need.</strong>
          </div>
        </li>
      </ul>
      <p className={ classes.marginBottomZero }>Your financial need is calculated based on the following formula:</p>
      <p><strong className={ classes.marginBottom }>Cost of Attendance – Expected Family Contribution = Financial Need</strong></p>
      <p>
        Expected Family Contribution (EFC) is calculated by the federal government and by your school. The lower your expected family contribution, the higher your financial need. On the next page, you’ll use the federal government’s calculator and your school’s calculator to determine your EFC.
      </p>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />

    </div>
  );
};

NavigatingFinancialAid.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( NavigatingFinancialAid );
