import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';

const ScrollToTop = ( props ) => {
  const { classes } = props;

  const [showScroll, setShowScroll] = useState( false );

  const scrollTop = () => {
    window.scrollTo( { top: 0, behavior: 'smooth' } );
  };

  const checkScrollTop = () => {
    if ( !showScroll && window.pageYOffset > 400 ) {
      setShowScroll( true );
    } else if ( showScroll && window.pageYOffset <= 400 ) {
      setShowScroll( false );
    }
  };

  useEffect( () => {
    window.addEventListener( 'scroll', checkScrollTop );
  } );

  return (
    <div className={ classes.scrollTop }>
      <i aria-hidden="true" className="fa fa-chevron-up" onClick={ scrollTop } style={ { height: 40, display: showScroll ? 'flex' : 'none' } } />
    </div>
  );
};

ScrollToTop.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( ScrollToTop );
