const data = [
  { 
    title: "Buying a Car - Investment Analysis",
    children: [
      {
        label: "Purchase Price",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "retail_value",
      },{
        label: "Discount",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "investment_analysis_discount",
      },{
        label: "Rebate",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "investment_analysis_rebate",
      },{
        label: "Trade-In Value",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "trade_in_value",
      },{
        label: "Sales Tax",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "salestax_calcs",
      },{
        label: "Registration & Documentation Fees",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "registration_and_documentation_fees",
      },{
        label: "Total Purchase Price",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "total_purchase_price",
      },{
        label: "Down Payment",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "car_down_payment",
      },{
        label: "Loan Points",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "car_loan_points",
      },{
        label: "Cash Needed at Purchase",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "cashneeded_at_purchase",
      },{
        label: "Outstanding Car Loan",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "loan",
      },{
        label: "Total Principal & Interest",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "investment_analysis_total_principal",
      },
    ],
  }
]

export default data;