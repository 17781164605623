function assumptionsSheetData( module1Data, module10Data ) {
  const assumptionsData = {};

  let incomeValue = 0;
  let expensesValue = 0;
  let i = 0;
  let year = parseInt( module1Data.start_year, 10 );
  const yearLimit = parseInt( module1Data.start_year, 10 ) + 31;

  for ( year; year <= yearLimit; year += 1 ) {
    i += 1;
    assumptionsData[year] = {};
    assumptionsData[year].Income = 0;
    assumptionsData[year].Expenses = 0;
    assumptionsData.Average = {};

    incomeValue = 100;
    if ( module1Data.long_term_growth_in_percentage !== undefined && module1Data.long_term_growth_in_percentage !== '' ) {
      if ( module1Data.long_term_growth_in_percentage > 0 ) {
        incomeValue = parseInt( incomeValue, 10 ) + parseFloat( module1Data.long_term_growth_in_percentage );
      }
    }
    assumptionsData[year].Income = incomeValue;

    expensesValue = 100;
    if ( module1Data.inflation_in_percentage !== undefined && module1Data.inflation_in_percentage !== '' ) {
      if ( module1Data.inflation_in_percentage > 0 ) {
        expensesValue = parseInt( expensesValue, 10 ) + parseFloat( module1Data.inflation_in_percentage );
      }
    }
    assumptionsData[year].Expenses = expensesValue;
    assumptionsData[year]['Higher Education Growth Rate'] = 104;

    if ( i === 1 || i === 2 || i === 3 ) {
      assumptionsData[year].Housing = 3680;
      assumptionsData[year].Food = 1580;
      assumptionsData[year].Transportation = 1790;
      assumptionsData[year].Clothing = 750;
      assumptionsData[year]['Health Care'] = 1180;
      assumptionsData[year]['Child Care and Education'] = 2870;
      assumptionsData[year].Other = 830;
      assumptionsData[year].Total = 12680;
    }

    if ( i === 4 || i === 5 || i === 6 ) {
      assumptionsData[year].Housing = 3680;
      assumptionsData[year].Food = 1690;
      assumptionsData[year].Transportation = 1840;
      assumptionsData[year].Clothing = 600;
      assumptionsData[year]['Health Care'] = 1110;
      assumptionsData[year]['Child Care and Education'] = 2870;
      assumptionsData[year].Other = 940;
      assumptionsData[year].Total = 12730;
    }

    if ( i === 7 || i === 8 || i === 9 ) {
      assumptionsData[year].Housing = 3680;
      assumptionsData[year].Food = 2280;
      assumptionsData[year].Transportation = 1900;
      assumptionsData[year].Clothing = 600;
      assumptionsData[year]['Health Care'] = 1130;
      assumptionsData[year]['Child Care and Education'] = 1710;
      assumptionsData[year].Other = 1050;
      assumptionsData[year].Total = 12350;
    }

    if ( i === 10 || i === 11 || i === 12 ) {
      assumptionsData[year].Housing = 3680;
      assumptionsData[year].Food = 2680;
      assumptionsData[year].Transportation = 1940;
      assumptionsData[year].Clothing = 780;
      assumptionsData[year]['Health Care'] = 1280;
      assumptionsData[year]['Child Care and Education'] = 1710;
      assumptionsData[year].Other = 1110;
      assumptionsData[year].Total = 13180;
    }

    if ( i === 13 || i === 14 || i === 15 ) {
      assumptionsData[year].Housing = 3680;
      assumptionsData[year].Food = 2780;
      assumptionsData[year].Transportation = 2090;
      assumptionsData[year].Clothing = 860;
      assumptionsData[year]['Health Care'] = 1240;
      assumptionsData[year]['Child Care and Education'] = 1430;
      assumptionsData[year].Other = 950;
      assumptionsData[year].Total = 13030;
    }

    if(typeof module10Data.save_average_cost_raising_child !== undefined && module10Data.save_average_cost_raising_child === 'Yes' 
      && module10Data.housing_cost !== undefined && module10Data.housing_cost !== null) {
        assumptionsData['Average']['Housing'] = module10Data.housing_cost;
    } else {
        assumptionsData['Average']['Housing'] = 3680;
    }

    if(typeof module10Data.save_average_cost_raising_child !== undefined && module10Data.save_average_cost_raising_child === 'Yes' 
      && module10Data.food_cost !== undefined && module10Data.food_cost !== null) {
        assumptionsData['Average']['Food'] = module10Data.food_cost;
    } else {
        assumptionsData['Average']['Food'] = 2202;
    }
    if(typeof module10Data.save_average_cost_raising_child !== undefined && module10Data.save_average_cost_raising_child === 'Yes' 
      && module10Data.transportation_cost !== undefined && module10Data.transportation_cost !== null) {
        assumptionsData['Average']['Transportation'] = module10Data.transportation_cost;
    } else {
        assumptionsData['Average']['Transportation'] = 1912;
    }
    if(typeof module10Data.save_average_cost_raising_child !== undefined && module10Data.save_average_cost_raising_child === 'Yes' 
      && module10Data.clothing_cost !== undefined && module10Data.clothing_cost !== null) {
        assumptionsData['Average']['Clothing'] = module10Data.clothing_cost;
    } else {
        assumptionsData['Average']['Clothing'] = 718;
    }
    if(typeof module10Data.save_average_cost_raising_child !== undefined && module10Data.save_average_cost_raising_child === 'Yes' 
      && module10Data.health_care_cost !== undefined && module10Data.health_care_cost !== null) {
        assumptionsData['Average']['Health Care'] = module10Data.health_care_cost;
    } else {
        assumptionsData['Average']['Health Care'] = 1188;
    }
    if(typeof module10Data.save_average_cost_raising_child !== undefined && module10Data.save_average_cost_raising_child === 'Yes' 
      && module10Data.child_care_and_education !== undefined && module10Data.child_care_and_education !== null) {
        assumptionsData['Average']['Child Care and Education'] = module10Data.child_care_and_education;
    } else {
        assumptionsData['Average']['Child Care and Education'] = 2118;
    }

    if(typeof module10Data.save_average_cost_raising_child !== undefined && module10Data.save_average_cost_raising_child === 'Yes' 
      && module10Data.other_costs !== undefined && module10Data.other_costs !== null) {
        assumptionsData['Average']['Other'] = module10Data.other_costs;
    } else {
        assumptionsData['Average']['Other'] = 976;
    }


    assumptionsData.Total = assumptionsData.Average.Housing
                                            + assumptionsData.Average.Food
                                            + assumptionsData.Average.Transportation
                                            + assumptionsData.Average.Clothing
                                            + assumptionsData.Average['Health Care']
                                            + assumptionsData.Average['Child Care and Education']
                                            + assumptionsData.Average.Other;
    assumptionsData.Average.Total = assumptionsData.Total;
  }
  return assumptionsData;
}
export default assumptionsSheetData;
