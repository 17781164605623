import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const HealthInsurance = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Health Insurance 101</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/health-insurance.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWord}>
                      Finance Explained
                      <span> | </span>
                      Health Insurance 101
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/health-insurance-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Health insurance can be expensive, but you absolutely
                          need it. Health insurance helps you pay for unexpected
                          medical expenses like surgery on a broken bone.
                          Without health insurance, a broken leg surgery could
                          cost you $20,000. No one plans to break a bone but
                          having health insurance will protect you financially
                          from the high costs of health care.
                        </p>
                        <p
                          className={classNames(
                            classes.smallText,
                            classes.noBottomMargin,
                          )}>
                          According to the{' '}
                          <a
                            href="https://www.kff.org/report-section/the-burden-of-medical-debt-section-3-consequences-of-medical-bill-problems/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Kaiser Family Foundation
                          </a>
                          , the #1 cause of bankruptcy for Americans is medical
                          bills. That makes sense considering a normal
                          deductible costs thousands of dollars. But what is a
                          deductible? What are health insurance premiums,
                          coinsurance and copayments? Let&apos;s dive into the
                          language of health insurance.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          <span className={classes.diduKnow}>
                            DID YOU KNOW:
                          </span>{' '}
                          According to{' '}
                          <a
                            href="https://www.ehealthinsurance.com/resources/affordable-care-act/much-obamacare-cost-2018"
                            target="_blank"
                            rel="noopener noreferrer">
                            eHealth
                          </a>
                          , the average monthly premium for an individual was
                          $440 per month or $5,280 per year in 2018. For a
                          family of four, $1,168 per month or $14,016 per year
                          in monthly premiums.
                        </p>
                        <p>
                          <b>Deductible</b>
                          <br />
                          If you have medical bills, the deductible is the
                          amount of money you would pay in a year before your
                          health insurance provider steps in and covers your
                          expenses. This is the most important aspect of health
                          insurance to understand. Your insurance provider does
                          not cover all of your medical expenses. A deductible
                          is easiest to understand in an example.
                        </p>
                        <p>
                          Mike unfortunately breaks his arm playing in an
                          intramural soccer game on his 27th birthday and needs
                          surgery. Mike’s health insurance coverage has a $1,500
                          deductible. The surgery is going to cost $5,000. What
                          is Mike’s responsibility and what is his health
                          insurance provider’s responsibility? Mike will pay
                          $1,500, which satisfies his deductible. His health
                          insurance provider will pay the remaining $3,500 now
                          that Mike paid his deductible for the year (assuming a
                          coinsurance rate of 100%/0%).
                        </p>
                        <p>
                          When shopping for health insurance, pick a deductible
                          that fits your financial picture, so you don&apos;t
                          find yourself in financial ruin. The higher your
                          deductible, the lower your monthly premium.{' '}
                          <a
                            href="https://www.firstquotehealth.com/health-insurance-news/high-low-deductibles"
                            target="_blank"
                            rel="noopener noreferrer">
                            Why might that be?
                          </a>
                        </p>
                        <p>
                          <b>Monthly health insurance premium</b>
                          <br />
                          The premium is the amount of money you pay your health
                          insurance provider each month in exchange for health
                          insurance. Think of health insurance premiums like
                          rent. You pay your rent each month in exchange for a
                          place to live. If you stop paying your rent, your
                          landlord will ask you to leave. If you stop paying
                          your monthly premiums, you will lose your health
                          insurance coverage.
                        </p>
                        <p>
                          <b>Coinsurance</b>
                          <br />
                          After you pay your deductible for the year, the
                          coinsurance percentage determines how much of your
                          additional medical bills you share with your insurance
                          provider. Sounds complex, but again, easiest to
                          understand in our example of Mike.
                        </p>
                        <p>
                          One month after Mike breaks his arm and gets surgery,
                          he cuts his hand preparing dinner and has to go to the
                          hospital for stitches. His hospital bill is $1,000.
                          Mike&apos;s coinsurance is 80%.
                          <b> Since he has already used his deductible,</b> Mike
                          would be responsible for 20% of this hospital bill, or
                          $200, and his insurance provider would pay the
                          remaining $800, or 80%.
                        </p>
                        <p>
                          When selecting your health insurance policy, pay
                          attention to the coinsurance percentage. A common
                          split would be 80/20, just like Mike&apos;s example.
                        </p>
                        <p>
                          <b>Copayments (copay)</b>
                          <br />
                          Copayments, or copay, is a fixed amount that you pay
                          for a regular checkup, other common doctor visits,
                          filling a prescription or seeing a specialist.
                          Copayments are made at the time you receive the
                          service. For example, when you go for a regular
                          checkup with your doctor, your copay could be $15 -
                          $20 that needs to be paid as you check in. Copays do
                          not count towards your deductible!
                        </p>
                        <p>
                          <b>Out-of-Pocket Maximum</b>
                          <br />
                          After you pay your deductible, the coinsurance
                          percentage kicks in on your healthcare costs. But
                          there’s also an out-of-pocket maximum with every
                          health insurance plan required by law. Once you spend
                          up to your out-of-pocket maximum, your insurance
                          provider pays 100% of covered costs for the rest of
                          the year.
                        </p>
                        <p>
                          Health care can be very expensive. Having the right
                          health insurance that fits your medical needs and your
                          budget is crucial to financial independence. When it
                          comes to selecting a health insurance policy, make
                          sure you know your deductible and out-of-pocket
                          maximum, monthly premium, coinsurance and common
                          copays.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
HealthInsurance.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HealthInsurance);
