import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import ReactTooltip from 'react-tooltip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  Dialog, DialogContent,DialogTitle,DialogActions
} from '@material-ui/core';
import logo1 from '../../assets/img/logo-footer.png';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Slider from 'react-slick';
import styles from './styles';
import Slide1 from '../../assets/img/anita-story/anita_story_img_1.jpg';
import mikeStory3 from '../../assets/img/anita-story/anita_story_img_3.jpg';
import mikeStory3Icon from '../../assets/img/mike-story/calvin_m_green_icon.png';
import SlideMike from '../../assets/img/anita-story/anita_story_img_2.png';
import MikeBlue from '../../assets/img/mike-story/calvin_m_blue_icon.png';
import mikeStory4Icon from '../../assets/img/mike-story/calvin_m_gray_icon.png';
import MikeGreen from '../../assets/img/mike-story/calvin_m_yellow_icon.png';
import MikeBlue2 from '../../assets/img/mike-story/calvin_m_light_blue_icon.png';
import MikeSlide5 from '../../assets/img/anita-story/anita_story_img_5.jpg';
import mikeStory4 from '../../assets/img/anita-story/anita_story_img_4.jpg';
import MikeSlide6 from '../../assets/img/anita-story/anita_story_img_6.jpg';
import MikeSlide7 from '../../assets/img/anita-story/anita_story_img_7.jpg';
import MikeSlide6Inner from '../../assets/img/mike-story/calvin_m_light_blue_icon_cloud.png';
import MikeSlide7Inner from '../../assets/img/mike-story/calvin_m_light_green_icon.png';
import MikeSlide7Inner2 from '../../assets/img/mike-story/calvin_m_red_icon.png';
import MikeSlide8 from '../../assets/img/anita-story/anita_story_img_8.jpg';
import MikeSlide9 from '../../assets/img/anita-story/anita_story_img_9.jpg';
import MikeSlide9Inner from '../../assets/img/mike-story/calvin_m_yellow_home_icon.png';
import MikeSlide10 from '../../assets/img/anita-story/anita_story_img_10.jpg';
import forwordIcon from '../../assets/img/mike-story/share_icon.png';
import replayIcon from '../../assets/img/mike-story/dollar-icon.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';

// summary table images

import logoNew from '../../assets/img/mike-story/logo.png';
import graphNew from '../../assets/img/mike-story/graph-new.png';
import TimeLineList from '../../components/time-line/time-line-list';
import NetworthRangeGraph from '../../components/time-line/networth-range-graph';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import getCalculatedValues from '../../calculations/calculated-values';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SummaryDocs from '../../components/documents/documents';
import Summarytable from '../../components/documents/summary-tables';

const MODULE_DATA = loader( '../../graphql/schema/fc/story-module-data.graphql' );

const settings = {
  dots: true,
  infinite: true,
  speed: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Anita = ( props ) => {
  const { classes, history } = props;

  const [forwardPopup, setForwordPopup] = useState( false );
  const [replyPopup, setReplyPopup] = useState( false );
  const [higherEducationPopup, setHigherEducationPopup] = useState( false );
  // const [careerPathPopup, setCareerPathPopup] = useState( false );
  // const [investmentsPopup, setInvestmentsPopup] = useState( false );
  // const [careerAdvancementPopup, setCareerAdvancementPopup] = useState( false );
  // const [additionalCareerAdvancementPopup, setAdditionalCareerAdvancementPopup] = useState( false );
  // const [familyPopup, setFamilyPopup] = useState( false );
  // const [vacationPopup, setVacationPopup] = useState( false );
  // const [buyingCarPopup, setBuyingCarPopup] = useState( false );
  // const [furtherCareerAdvancementPopup, setFurtherCareerAdvancementPopup] = useState( false );
  // const [buyingHomePopup, setBuyingHomePopup] = useState( false );
  const [loading, setLoading] = useState( false );
  const [moduleTimeLine, setModuleTimeLine] = React.useState( [] );
  const [openDocsPopup, setOpenDocsPopup] = React.useState( '' );
  const [rangeSliderValues, setRangeSliderValues] = React.useState( { income: 0, expenses: 0 } );
  const [loadedValues, setLoadedValues] = React.useState( {} );
  const [activeSlide, setActiveSlide] = React.useState( 0 );
  const [SummaryPopup, setSummaryPopup] = useState( false );
  const [linkId, setLinkId] = useState( false );

  const placeTimeLine = ( type, status = '' ) => {
    if ( type === 'default' ) {
      const displayTimeLine = [];
      if ( status === 'not-empty' ) {
        const order = timeLineActions.moduleTimelineData;
        order.forEach( ( value ) => {
          if ( parseInt( value.scenario_id, 10 ) > 0 || value.action === 'CS' ) {
            displayTimeLine.push( value );
          }
        } );
      } else {
        displayTimeLine.push( {
          action: 'CS',
          module_time_line_order: 0,
          scenario_id: 0,
          selected_module: 'module1',
          time_line_order: 0,
        } );
      }
      setModuleTimeLine( displayTimeLine );
    }
  };

  useQuery( MODULE_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      story: 'anita',
    },
    onCompleted( response ) {
      timeLineActions.assignModulesData( response.getStoryModuleData, ( data ) => {
        placeTimeLine( 'default', 'not-empty' );
        setLoading( false );
        setLoadedValues( getCalculatedValues() );
      } );
    },
    onError() {
      timeLineActions.assignModulesData( '', ( data ) => {
        placeTimeLine( 'default', 'empty' );
        setLoading( false );
      } );
    },
  } );

  const handleClose = () => {
    history.push( '/' );
  };

  const handleForwordPopup = () => {
    
    navigator.clipboard.writeText(`${ (window.location.href).split('career-story')[0] }?email=anita`);
    setForwordPopup( !forwardPopup );
  };

  const handleReplyPopup = () => {
    setReplyPopup( !replyPopup );
  };

  const handleSummaryPopup = (id) => {
    setSummaryPopup( !SummaryPopup );
    setLinkId(id);
  };

  const handleHigherEducationPopup = () => {
    setHigherEducationPopup( !higherEducationPopup );
  };

  // const handleCareerPathPopup = () => {
  //   setCareerPathPopup( !careerPathPopup );
  // };

  // const handleInvestmentsPopup = () => {
  //   setInvestmentsPopup( !investmentsPopup );
  // };

  // const handleCareerAdvancementPopup = () => {
  //   setCareerAdvancementPopup( !careerAdvancementPopup );
  // };

  // const handleAdditionalCareerAdvancementPopup = () => {
  //   setAdditionalCareerAdvancementPopup( !additionalCareerAdvancementPopup );
  // };

  // const handleFamilyPopup = () => {
  //   setFamilyPopup( !familyPopup );
  // };

  // const handleVacationPopup = () => {
  //   setVacationPopup( !vacationPopup );
  // };

  // const handleBuyingCarPopup = () => {
  //   setBuyingCarPopup( !buyingCarPopup );
  // };

  // const handleFurtherCareerAdvancementPopup = () => {
  //   setFurtherCareerAdvancementPopup( !furtherCareerAdvancementPopup );
  // };

  // const handleBuyingHomePopup = () => {
  //   setBuyingHomePopup( !buyingHomePopup );
  // };

  useEffect( () => {
    initializeExcelSheetsData();
    workSheetCalc();
    // eslint-disable-next-line
  } );

  const updateRangeValue = ( e, type ) => {
    const rangeSlider = { ...rangeSliderValues };
    rangeSlider[type] = e.target.value;
    setRangeSliderValues( rangeSlider );
  };

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } />}
      <div className={ classes.sliderStory }>
        <div className={ classes.mikeStoryIcons }>
          <div role="button" onClick={ handleForwordPopup } tabIndex={ -1 } aria-hidden="true">
            <img src={ forwordIcon } alt="" data-for="mikeStoryTool" data-tip="Click to copy share link" />
          </div>
          <div role="button" onClick={ handleReplyPopup } tabIndex={ 0 } aria-hidden="true">
            <img src={ replayIcon } alt="" data-for="mikeStoryTool" data-tip="Summary Documents" />
            {replyPopup && (
            <ul className={ classes.newText }>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'summary-output' ); handleReplyPopup(); } }>Summary Output</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'inputsheet-statement' ); handleReplyPopup(); } }>Summary Financials</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'summary-table' ); handleReplyPopup(); } }>Summary Tables</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'income-statement' ); handleReplyPopup(); } }>Income Statement</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'tax-statement' ); handleReplyPopup(); } }>Tax Statement</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'balance-statement' ); handleReplyPopup(); } }>Balance Sheet</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'cashflow-statement' ); handleReplyPopup(); } }>Cash Flow Statement</p></li>
            </ul>
            )}
          </div>
        </div>
        <Slider { ...settings } afterChange={ ( e ) => { setActiveSlide( e ); } }>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ Slide1 } />
                  <div className={ classes.InnerStoryLEft }>
                    <h2>ANITA</h2>
                    <h2>Corporate Attorney</h2>
                    <p>15-Year Net Worth: $1,302,484*</p>
                  </div>
                  <span className={ classes.bottomSpanTextLeft }>
                    *Net worth: What you own (assets) minus what you owe (liabilities). Because Anita gets married, this figure includes her partner&apos;s income/expenses.
                  </span>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightBlock }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.imageBlock }>
                    <img src={ SlideMike } alt="" />
                  </div>
                  <div className={ classes.rightBlockNew }>
                    <h3>Anita&apos;s Life</h3>
                    <p>Anita completes law school at University of Texas, balances high loan payments with frugal spending and advances to Senior Associate, focusing on the energy sector.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.blueBox }>
                  <h3>Anita&apos;s Start Year – 2019</h3>
                  <h4>(36 years old)</h4>
                  <div className={ classes.careerSave }>
                    <p>
                      <span>START:</span>
                      $600 savings, $40k in student loans
                    </p>
                    <ul>
                      <li>
                        <p>2019 – 2021: Attends and graduates from University of Texas, Law School</p>
                      </li>
                      <li>
                        <p>2022 – 2024: Junior Associate focusing on corporate law in Houston; passes Bar Exam</p>
                      </li>
                      <li>
                        <p>2022: Begins making small investments with excess cash; focus on debt paydown</p>
                      </li>
                      <li>
                        <p>2024: Starts taking an annual vacation</p>
                      </li>
                      <li>
                        <p>2025 – 2028: New job as a Mid-Level Associate focusing on the energy industry in Houston, Texas</p>
                      </li>
                      <li>
                        <p>2029: Gets married, gift each other a sports SUV and an electric vehicle</p>
                      </li>
                      <li>
                        <p>2029 – 2033: Promoted to Senior Associate</p>
                      </li>
                      <li>
                        <p>2031: Baby boy is born</p>
                      </li>
                      <li>
                        <p>2032: Buys a home just north of downtown Houston</p>
                      </li>
                    </ul>
                    <h3>2033 Net Worth: $1,302,484</h3>
                  </div>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Introducing Anita</h3>
                    <p>
                      By the time she reached the age of 22, Anita was already well on her way towards meeting her goal of practicing law and eventually becoming a partner at a law firm. Anita funded her undergraduate education through a combination of student loans, scholarships,
                      and was fortunate to have received tuition contributions from family members.
                    </p>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>The earlier you identify your goals, the faster you can achieve them. To minimize borrowing, draw on a mix of resources to pay for your education.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Anita sets her sights on being a partner at a law firm. She utilizes a variety of resources to fund her education.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ mikeStory3 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Anita takes the long view</h3>
                    <p>
                      Anita routinely makes decisions that benefit her long-term outlook, sometimes sacrificing everyday lifestyle luxuries. By the time she received her four-year degree in Legal Studies from Texas A&M, Anita had already been making minimum interest payments on her
                      undergraduate loans with income from on-campus work as a library assistant as well as summer internships with area law firms. Staying on top of her student debt, Anita requests that her undergraduate loans be deferred until after graduate school.
                    </p>

                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Existing Assets & Liabilities" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(18)} }>
                      <img alt="" src={ mikeStory4Icon } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Short-term sacrifices can accelerate your path to long-term goals.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Anita lives frugally and starts paying interest on her loans while still in school, which saves her thousands of dollars by avoiding accrued interest. She requests her undergraduate loans be deferred until after graduate school.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ mikeStory4 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Anita attends law school</h3>
                    <p>
                      Anita attends law school at the University of Texas and completes the program within the standard three-year timeframe. She funds her graduate education with $60k in additional loans,
                      additional family contributions, and a scholarship of $16k per year. By working as a summer associate while in grad school, she earns $15k per year.
                    </p>

                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Higher Education" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(2)} }>
                      <img alt="" src={ mikeStory3Icon } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>When you finish a course of study in the prescribed timeframe, you save on added education costs and start earning earlier.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Anita adheres to her plan, takes on more debt and works during the summers to bring in additional income.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide5 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Anita passes the bar exam</h3>
                    <p>
                      During the second year of her law program, Anita accepts an internship with a firm in Houston, and the same firm offers to hire her upon graduation. During her first year on the job,
                      Anita passes the Texas Bar Exam in 2022, after which she becomes a practicing attorney.
                    </p>
                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Career Path" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(4)} }>
                      <img alt="" src={ MikeBlue } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Understand the proper path to reach your goal and make daily strides toward that goal.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Anita stays on track, accepts an internship and then a position upon graduation. She passes the bar exam and becomes a practicing attorney.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide6 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Anita pays down loans, invests</h3>
                    <p>
                      As a junior associate at the Houston firm, Anita earns $110k per year, but she still keeps her living expenses low as part of her plan to pay off her loans on schedule. In addition, Anita begins making small
                      short-term investments to serve as an emergency fund, as well as a monthly $50 contribution to long-term investments, and regular contributions to a 401(k) retirement account.
                    </p>

                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Investments" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(9)} }>
                      <img alt="" src={ MikeGreen } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>When you pay off your loans on schedule, you avoid any accrued interest. When you save and invest early, you leverage the power of compounding interest.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>To pay off her loans on schedule, Anita keeps her living expenses low. She starts making short and long-term investments and contributes to a 401(k) retirement account.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide7 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Anita receives an offer</h3>
                    <p>
                      Three years after graduation, Anita receives an offer to work in the financing side of the energy sector, as a mid-level associate at a Houston law firm. She receives a pay increase of 24%. Around the same time, Anita and her boyfriend of many years move
                      into a pleasant, new, downtown apartment. Anita uses some of her additional income to invest in tailored suits and wardrobe accessories. The couple begins to take annual vacations, satisfying Anita&apos;s yearn for travel.
                    </p>

                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Career Advancement" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(5)} }>
                      <img alt="" src={ MikeBlue2 } />
                    </figure>
                    <figure data-for="mikeStoryTool" data-tip="Vacation" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(11)} }>
                      <img alt="" src={ MikeSlide7Inner2 } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Always make time for love and relaxation. Make sure your financial life plan budgets for these essential expenditures.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Anita transitions to a new position at another Houston law firm. She remembers to make time for family, special purchases and travel.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide8 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Anita is promoted, gets married</h3>
                    <p>
                      In 2029, Anita receives a promotion to the position of senior associate, with a corresponding pay increase to $225k. In the same year, she and her partner get married. They gift each other new cars: a sports SUV and an electric vehicle.
                      The couple continues to live downtown as they save for an eventual home and potential future family.
                    </p>

                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Additional Career Advancement" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(8)} }>
                      <img alt="" src={ MikeSlide6Inner } />
                    </figure>
                    <figure data-for="mikeStoryTool" data-tip="Family" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(10)} }>
                      <img alt="" src={ MikeSlide7Inner } />
                    </figure>
                    <figure data-for="mikeStoryTool" data-tip="Vacation" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(11)} }>
                      <img alt="" src={ MikeSlide7Inner2 } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Hard work coupled with vision pays dividends. Two incomes can accelerate saving for important goals.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Anita continues toward mastery of her career with a promotion to senior associate. The couple continues to save as they plan for a home and family.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide9 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>A new baby, a new home</h3>
                    <p className={ classes.paddingBottomPara }>
                      Three years later, Anita and her husband welcome a child into the world. Anita continues working full time and her husband chooses to take a year off from his career to help raise the newborn.
                      The same year, the couple purchases a $700k home in the Houston suburbs.
                    </p>
                    <p>What happens next?</p>
                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Family" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(10)} }>
                      <img alt="" src={ MikeSlide7Inner } />
                    </figure>
                    <figure data-for="mikeStoryTool" data-tip="Buying a Home" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(13)} }>
                      <img alt="" src={ MikeSlide9Inner } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Saving early and focusing on your goals gives you financial freedom down the road. Now, enjoy it.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Anita and her husband welcome a child and purchase a $700k home..</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>

            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 12 }>
                <div className={ classes.blueBoxLeftBorder }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryFullTop }>
                    <div className={ classes.mikestoryFullTopLeft }>
                      <h3>Anita&apos;s Net Worth</h3>
                      <ul>
                        <li>What happens if Anita earns 10% more income in her plan?</li>
                        <li>What happens if she spends 5% less?</li>
                        <li>Drag the income and expenses sliders and see the impact on her net worth.</li>
                      </ul>
                    </div>
                    <div className={ classes.rangeBlock }>
                      <input type="range" min="-20" max="20" value={ rangeSliderValues.income } step="5" onChange={ ( e ) => { updateRangeValue( e, 'income' ); } } />
                      {rangeSliderValues.income}
                      <input type="range" min="-20" max="20" value={ rangeSliderValues.expenses } step="5" onChange={ ( e ) => { updateRangeValue( e, 'expenses' ); } } />
                      {rangeSliderValues.expenses}
                      {/* <img src={ MikeSlideGraph2 } alt="" />
                      <div className={ classes.infoIcon } data-for="mikeStoryTool" data-tip="When you move the slider above, the FC is automatically updating each input in every module related to career income. Then, the FC reruns all calculations, including taxes, and displays the updated figures in the chart below.. All at the speed of thought.">
                        <img src={ infoIcon } alt="infoicon" />
                      </div> */}
                    </div>
                  </div>
                  <div className={ classes.graphBlock }>
                    <NetworthRangeGraph graphSlide={ 9 } activeSlide={ activeSlide } defaultValues={ loadedValues } rangeValues={ rangeSliderValues } />
                    {/* <img src={ MikeSlideGraph } alt="" /> */}
                    {/* <div className={ classes.infoIcon } data-for="mikeStoryTool" data-tip="When you move the slider above, the FC is automatically updating each input in every module related to living expenses. Then, the FC reruns all calculations and displays the updated figures in the chart below. All at the speed of thought.">
                      <img src={ infoIcon } alt="infoicon" />
                    </div> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>

            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 12 }>
                <div className={ classes.blueBoxLeftBorder }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryFullTop }>
                    <div className={ classes.mikestoryFullTopLeft }>
                      <h3>
                        Anita&apos;s Timeline
                        <img className={ classes.infoIcon } data-for="mikeStoryToolBottom" data-tip="The timeline on this page is live! Scroll left and right to view the summary details that make up this sketch. Double click on any module icon to go to that module and view all the inputs for that scenario." src={ infoIcon } alt="infoicon" />
                      </h3>

                      <ul>
                        <li>Scroll over the life events and discover the details in this career sketch.</li>
                        <li>
                          Explore Anita&apos;s financial life plan by module to see how she acheives her goals
                        </li>
                      </ul>
                    </div>
                    <div className={ classes.rangeBlock }>
                      <h4>Anita - Corporate Attorney</h4>
                      <h4>Net Worth:  $1,302,484</h4>
                    </div>
                  </div>
                  <div className={ classNames( classes.container, classes.careerSketchTimelineInCnt, classes.relativeBlock ) }>
                    <TimeLineList timeLineData={ moduleTimeLine } page="story" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide10 } />
                  <div className={ classes.ovelayBoxLast }>
                    <h3>
                      Anita&apos;s Life:
                      <br />
                      As of 2033
                      {'   '}
                      <span>(36 years old)</span>
                    </h3>
                    <ul>
                      <li>Cash Savings</li>
                      <li>Investments</li>
                      <li>Education</li>
                      <li>Work Experience</li>
                      <li>A Career</li>
                      <li>A Family</li>
                      <li>A Home</li>
                      <li>A Sports SUV</li>
                      <li>An Electric Car</li>
                      <li>Annual Vacations</li>
                      <li>A Great Future</li>
                    </ul>
                    <p>2033 Net Worth: $1,302,484</p>
                  </div>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryLast }>
                    <h3>Anita&apos;s KPIs in 2033</h3>
                    <ul>
                      <li>Debt-to-Income: 9.0%</li>
                      <li>Monthly Liquidity Ratio: 3.8x</li>
                      <li>Current Ratio: 1.3x</li>
                      <li>Savings Rate: 48.0%</li>
                      <li>Investment Assets to Total Assets: 57.3%</li>
                      <li>Total Assets to Total Debt: 3.3x</li>
                      <li>Solvency Ratio: 0.7x</li>
                    </ul>
                    <a href="https://inkwiry.com/kpi-glossary" target="_blank" rel="noopener noreferrer">KPI Reference Guide</a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

        </Slider>
      </div>
      <ReactTooltip id="mikeStoryTool" place="top" effect="solid" className={ classes.tooltipInfoTopNewText2 } />
      <ReactTooltip id="mikeStoryToolBottom" place="bottom" effect="solid" className={ classes.tooltipInfoTopNewTextBottom } />
      <div>
        <Dialog
          open={ forwardPopup }
          onClose={ handleForwordPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={ classes.replayPopup }
        >
          <DialogContent className={ classes.replayPopupInner }>
            <div aria-hidden="true" onClick={ handleForwordPopup } className={ classes.closeIconPop }><i className="la la-close" /></div>
            <div className={ classes.paymentPoupIcon }>
              <span />
              <p>Link successfully copied.</p>
            </div>

          </DialogContent>
        </Dialog>
      </div>
      {/* <div>
        <Dialog
          open={ replyPopup }
          onClose={ handleReplyPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div aria-hidden="true" onClick={ handleReplyPopup }>close</div>
            Reply popup
          </DialogContent>
        </Dialog>
      </div> */}
      <div>
        <Dialog
          open={ higherEducationPopup }
          onClose={ handleHigherEducationPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={ classes.summaryTablePopup }
        >
          <DialogContent>
            <div className={ classes.summaryTableHeader }>
              <img src={ logoNew } alt="" />
              <h3>Summary Tables</h3>
            </div>
            <div className={ classes.summaryTableContent }>
              <div className={ classes.senarioGroup }>
                <h3 className={ classes.mainHead }>Financial Independence (2019-2033)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <p>2019-Registered Architect-01.25.19</p>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Start Year</span>
                        <span>2019</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Income Growth</span>
                        <span>2019</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Inflation</span>
                        <span>3.00%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Current Cash Balance</span>
                        <span>1.80%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Max Cash Balance before Investment</span>
                        <span>$2,000</span>
                      </div>
                    </div>
                  </Grid>
                  <Grid sm={ 8 }>
                    <div className={ classes.graphBox }>
                      <h3>Grow Your Net Worth: What&apos;s Your 5, 10, 15 Year Number?</h3>
                      <img src={ graphNew } alt="" />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.highrEducationHead}` }>Higher Education (2019-2023)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2019-2023-Registered Architect-01.25.19</p>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Type of Higher Education</span>
                        <span>College</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Name of College</span>
                        <span>Virginia Tech</span>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Inflation</span>
                          <span>3.00%</span>
                        </div>
                        <p>Years</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Inflation</span>
                          <span>3.00%</span>
                        </div>
                        <p>Years</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Inflation</span>
                          <span>3.00%</span>
                        </div>
                        <p>Years</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Student Loan Payments during College</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Existing Student Loan Payments</td>
                            <td>0</td>

                          </tr>
                          <tr>

                            <td>College Student Loan Payments</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>College Student Loan Payments</td>
                            <td>0</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Total Cost of Attendance</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Tuition & Fees</td>
                            <td>$167,982</td>
                          </tr>
                          <tr>
                            <td>Room & Board</td>
                            <td>$17,728</td>
                          </tr>
                          <tr>
                            <td>On Campus Living Expenses</td>
                            <td>$17,799</td>
                          </tr>
                          <tr>
                            <td>Off Campus Living Expenses</td>
                            <td>$69,639</td>
                          </tr>
                          <tr>
                            <td>Total Student Loan Payments during College</td>
                            <td>$13,649</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Cost of Attendance</td>
                            <td>$286,795</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Total Expenses</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Tuition & Fees</td>
                            <td>$167,982</td>
                          </tr>
                          <tr>
                            <td>Room & Board</td>
                            <td>$17,728</td>
                          </tr>
                          <tr>
                            <td>On Campus Living Expenses</td>
                            <td>$17,799</td>
                          </tr>
                          <tr>
                            <td>Off Campus Living Expenses</td>
                            <td>$69,639</td>
                          </tr>
                          <tr>
                            <td>Total Student Loan Payments during College</td>
                            <td>$13,649</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>Children and Pet Expenses</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Expenses</td>
                            <td>$286,795</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Income Needed to Support Student Loans Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Student Loan Balance (incl. Existing Loans)</td>
                            <td>$63,000</td>
                          </tr>
                          <tr>
                            <td>Total Monthly Payment (incl. Existing Loans)</td>
                            <td>$719</td>
                          </tr>
                          <tr>
                            <td>Total Annual Paid</td>
                            <td>$8,623</td>
                          </tr>
                          <tr>
                            <td>Divided by 12.5%</td>
                            <td>12.5%</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Income Needed to Support Student Loans</td>
                            <td>$68,983</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Investment Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Scholarships</td>
                            <td>$75,000</td>
                          </tr>
                          <tr>
                            <td>Grants</td>
                            <td>$0</td>
                          </tr>

                          <tr>
                            <td>Other Financial Aid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Income Earned after Taxes</td>
                            <td>$40,361</td>
                          </tr>
                          <tr>
                            <td>Family Contribution</td>
                            <td>$100,000</td>
                          </tr>
                          <tr>
                            <td>Federal Subsidized Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Federal Unsubsidized Student Loan</td>
                            <td>$31,000</td>
                          </tr>
                          <tr>
                            <td>Private Student Loan</td>
                            <td>$32,000</td>
                          </tr>
                          <tr>
                            <td>Origination Fees</td>
                            <td>($501)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Financing Sources</td>
                            <td>277,860</td>
                          </tr>
                          <tr>
                            <td>Total Expenses</td>
                            <td>($286,795)</td>
                          </tr>
                          <tr>
                            <td>Cash Leftover</td>
                            <td><span className={ classes.redBg }>($8,935)</span></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Total Liquid Assets Available</td>
                            <td>$2,000</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.careerPathHead}` }>Career Path (2024-2025)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2024-2025-Registered Architect-01.25.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Career
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Occupation</span>
                        <span>Entry-level Architect</span>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Start Year</span>
                          <span>2024</span>
                        </div>
                        <p>Year</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>End Year</span>
                          <span>2025</span>
                        </div>
                        <p>2</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ `${classes.formGroup} ${classes.noborder}` }>
                          <span>Total Pre-Tax Base Income</span>
                          <span>$50,000</span>
                        </div>
                      </div>
                      <div className={ classes.subHead }>
                        Supplementary Income
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Start Year</span>
                        <span>$0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>End Year</span>
                        <span>$0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Income</span>
                        <span>$0</span>
                      </div>
                      <div className={ classes.subHead }>
                        401(k)
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Contribute to / Already Have a 401(k)?</span>
                        <span>Yes</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Personal Contribution as a % of Base Income</span>
                        <span>8.00%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Employer Matching Rate on Personal Contribution</span>
                        <span>50.00%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Employer Matching Limit as a % of Base Income</span>
                        <span>4.00%</span>
                      </div>

                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Monthly Budget</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Monthly Base Income</td>
                            <td>$4,167</td>
                          </tr>
                          <tr>
                            <td>Other Monthly Income</td>
                            <td>$0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Monthly Income</td>
                            <td>$4,167</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Taxes Paid</td>
                            <td>($825)</td>
                          </tr>

                          <tr>
                            <td>Higher Education Expenses</td>
                            <td>($825)</td>
                          </tr>
                          <tr>
                            <td>Total Taxes Paid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Children Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Pet Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>($532)</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Total Monthly Expenses</td>
                            <td>($3,547)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Monthly Cash Flow before Retirement Contributions</td>
                            <td>$620</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$333</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$287</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Total Cost of Attendance</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Income</td>
                            <td>$50,000</td>
                          </tr>
                          <tr>
                            <td>Total Taxes Paid</td>
                            <td>($9,897)</td>
                          </tr>
                          <tr>
                            <td>Living Expenses</td>
                            <td>($26,280)</td>
                          </tr>
                          <tr>
                            <td>Higher Education Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Children Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Pet Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>$0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Annual Expenses</td>
                            <td>($42,559)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Annual Cash Flow before Retirement Contributions</td>
                            <td>$7,441</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$4,000</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$3,441</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Budget Summary in years 2024 to 2025</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Income</td>
                            <td>$101,500</td>
                          </tr>
                          <tr>
                            <td>Total Taxes</td>
                            <td>($20,123)</td>
                          </tr>
                          <tr>
                            <td>Total Living Expenses</td>
                            <td>($53,033)</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>($15,005)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Expenses</td>
                            <td>($88,161)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Cash Flow before Retirement Contributions</td>
                            <td>$13,339</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$8,120</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$5,219</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Expenses</td>
                            <td>$286,795</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">401(k) Retirement Account Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Beginning 401(k) Balance in Year 2024</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Personal Contributions After 2 Years</td>
                            <td>$8,120</td>
                          </tr>
                          <tr>
                            <td>Earnings on Personal Contributions After 2 Years</td>
                            <td>$502</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Personal Contributions & Earnings</td>
                            <td>$8,622</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Employer Contributions After 2 Years</td>
                            <td>$4,060</td>
                          </tr>
                          <tr>
                            <td>Earnings on Employer Contributions After 2 Years</td>
                            <td>$251</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Total Employer Contributions & Earnings</td>
                            <td>$4,311</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>401(k) Balance before Vesting Reductions</td>
                            <td>$12,933</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>% Vested after 2 years at 25%/yr Schedule</td>
                            <td>50.00%</td>
                          </tr>
                          <tr>
                            <td>% of Employer Contributions & Earnings Not Vested</td>
                            <td>($2,156)</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Ending 401(k) Balance in Year 2025</td>
                            <td>$10,778</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.investHead}` }>Investments (2024)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2024-2033-Registered Architect-01.25.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Short-Term Investments
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Start Year</span>
                          <span>2024</span>
                        </div>

                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Contribution</span>
                        <span>$1,200</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Max Short-Term Investments Balance before Long-Term Investments</span>
                        <span>$30,000</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Expected Pre-Tax Return</span>
                        <span>3.00%</span>
                      </div>

                      <div className={ classes.subHead }>
                        Long-Term Investments
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Start Year</span>
                        <span>2029</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Contribution</span>
                        <span>$2,400</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Expected Pre-Tax Return</span>
                        <span>6.00%</span>
                      </div>

                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Short-Term Investments Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td className={ classes.tableHeadNew }>Beginning Short-Term Investments Balance</td>
                            <td>$4,167</td>
                          </tr>
                          <tr>
                            <td>Total Contributions for 10 Years</td>
                            <td>$15,093</td>
                          </tr>
                          <tr>
                            <td>Contributions from Cash Account for 10 Years</td>
                            <td>$79,259</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td className={ classes.tableHeadNew }>Total Cash Invested for 10 Years</td>
                            <td>($825)</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td className={ classes.tableHeadNew }>Total Earnings for 10 Years</td>
                            <td>$2,284</td>
                          </tr>
                          <tr>
                            <td>Total Short-Term Investments Accumulated</td>
                            <td>$96,636</td>
                          </tr>
                          <tr>
                            <td>Total Withdrawals for Cash Need for 10 Years</td>
                            <td>($8,285)</td>
                          </tr>
                          <tr>
                            <td>Total Transfers into Long-Term Investments for 10 Years</td>
                            <td>($58,352)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td className={ classes.tableHeadNew }>Ending Short-Term Investments Balance</td>
                            <td><span className={ classes.greenBg }>$30,000</span></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>

                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Long-Term Investments Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Beginning Long-Term Investments Balance</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Contributions for 5 Years</td>
                            <td>$13,262</td>
                          </tr>
                          <tr>
                            <td>Contributions from Short-Term Investments for 5 Years</td>
                            <td>12.5%</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Cash Invested for 5 Years</td>
                            <td>$71,613</td>
                          </tr>
                          <tr>
                            <td>Total Long-Term Investments Accumulated</td>
                            <td>$76,349</td>
                          </tr>
                          <tr>
                            <td>Total Withdrawals for Cash Need for 5 Years</td>
                            <td>($2,472)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Ending Long-Term Investments Balance</td>
                            <td><span className={ classes.greenBg }>2022</span></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Charitable Contributions Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Annual Contribution in the Year 2030</td>
                            <td>$5,031</td>
                          </tr>
                          <tr>
                            <td>Total Contributions After 4 Years</td>
                            <td>$23,585</td>
                          </tr>
                          <tr>
                            <td>Estimated Tax Savings at 20% Tax Rate</td>
                            <td>$4,717</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.VacationHead}` }>Vacation (2025)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2024-2033-Registered Architect-01.25.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Vacation
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Start Year</span>
                          <span>2025</span>
                        </div>

                      </div>
                      <div className={ classes.formGroup }>
                        <span>End Year</span>
                        <span>2033</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Cost</span>
                        <span>$2,000</span>
                      </div>

                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.subHead }>
                        Vacation
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Special Purchase</span>
                          <span>Bicycle</span>
                        </div>

                      </div>
                      <div className={ classes.formGroup }>
                        <span>Start Year</span>
                        <span>2024</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>End Year</span>
                        <span>2024</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Cost</span>
                        <span>$800</span>
                      </div>

                    </div>
                  </Grid>

                  <Grid sm={ 4 }>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Vacation + Special Purchase Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Amount Spent  </td>
                            <td>$20,152</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.AddcareerPathHead}` }>Additional Career Advancement (2029-2031)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2024-2025-Registered Architect-01.25.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Career
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Occupation</span>
                        <span>Architect</span>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Start Year</span>
                          <span>2026</span>
                        </div>
                        <p>Year</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>End Year</span>
                          <span>2028</span>
                        </div>
                        <p>2</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ `${classes.formGroup} ${classes.noborder}` }>
                          <span>Total Pre-Tax Base Income</span>
                          <span>$60,000</span>
                        </div>
                      </div>
                      <div className={ classes.subHead }>
                        Supplementary Income
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Start Year</span>
                        <span>$0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>End Year</span>
                        <span>$0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Income</span>
                        <span>$0</span>
                      </div>
                      <div className={ classes.subHead }>
                        401(k)
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Contribute to / Already Have a 401(k)?</span>
                        <span>Yes</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Personal Contribution as a % of Base Income</span>
                        <span>10.00%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Employer Matching Rate on Personal Contribution</span>
                        <span>50.00%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Employer Matching Limit as a % of Base Income</span>
                        <span>5.00%</span>
                      </div>

                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Monthly Budget</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Monthly Base Income</td>
                            <td>$5,000</td>
                          </tr>
                          <tr>
                            <td>Other Monthly Income</td>
                            <td>$0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Monthly Income</td>
                            <td>$5,000</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Taxes Paid</td>
                            <td>($1,003)</td>
                          </tr>

                          <tr>
                            <td>Higher Education Expenses</td>
                            <td>($2,665)</td>
                          </tr>
                          <tr>
                            <td>Children Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Pet Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>($719)</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Total Monthly Expenses</td>
                            <td>($4,387)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Monthly Cash Flow before Retirement Contributions</td>
                            <td>$613</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$500</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$113</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Total Cost of Attendance</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Income</td>
                            <td>$60,000</td>
                          </tr>
                          <tr>
                            <td>Total Taxes Paid</td>
                            <td>($12,040)</td>
                          </tr>
                          <tr>
                            <td>Living Expenses</td>
                            <td>($31,980)</td>
                          </tr>
                          <tr>
                            <td>Higher Education Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Children Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Pet Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>$8,623</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Annual Expenses</td>
                            <td>($52,643)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Annual Cash Flow before Retirement Contributions</td>
                            <td>$7,357</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$6,000</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$1,357</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Budget Summary in years 2024 to 2025</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Income</td>
                            <td>$101,500</td>
                          </tr>
                          <tr>
                            <td>Total Taxes</td>
                            <td>($20,123)</td>
                          </tr>
                          <tr>
                            <td>Total Living Expenses</td>
                            <td>($53,033)</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>($15,005)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Expenses</td>
                            <td>($88,161)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Cash Flow before Retirement Contributions</td>
                            <td>$13,339</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$8,120</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$5,219</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Expenses</td>
                            <td>$286,795</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">401(k) Retirement Account Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Beginning 401(k) Balance in Year 2024</td>
                            <td>$40,691</td>
                          </tr>
                          <tr>
                            <td>Personal Contributions After 2 Years</td>
                            <td>$33,382</td>
                          </tr>
                          <tr>
                            <td>Earnings on Personal Contributions After 2 Years</td>
                            <td>$9,162</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Personal Contributions & Earnings</td>
                            <td>$42,543</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Employer Contributions After 2 Years</td>
                            <td>$16,691</td>
                          </tr>
                          <tr>
                            <td>Earnings on Employer Contributions After 2 Years</td>
                            <td>$3,554</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Total Employer Contributions & Earnings</td>
                            <td>$20,245</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>401(k) Balance before Vesting Reductions</td>
                            <td>$103,479</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>% Vested after 2 years at 25%/yr Schedule</td>
                            <td>75%</td>
                          </tr>
                          <tr>
                            <td>% of Employer Contributions & Earnings Not Vested</td>
                            <td>($5,061)</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Ending 401(k) Balance in Year 2025</td>
                            <td>$98,418</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.VacationHead}` }>Buying a Car (2031)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2031-2031-Registered Architect-12.24.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Buying a Car
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Purchase Year</span>
                          <span>2031</span>
                        </div>

                      </div>
                      <div className={ classes.formGroup }>
                        <span>Total Purchase Price</span>
                        <span>$11,894</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Down Payment</span>
                        <span>40.00%</span>
                      </div>

                    </div>
                  </Grid>

                  <Grid sm={ 4 }>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Buying a Car Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Retail Value </td>
                            <td>$25,000</td>
                          </tr>
                          <tr>
                            <td>My Income Earned</td>
                            <td>$711,153</td>
                          </tr>
                          <tr className={ classes.borderBox }>
                            <td>Destination Charges</td>
                            <td>$650</td>
                          </tr>
                          <tr>
                            <td>Discount</td>
                            <td>($770)</td>
                          </tr>

                          <tr>
                            <td>Rebate</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Trade-In Value</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Sales Tax</td>
                            <td><span className={ classes.greenBg }>$1,742</span></td>
                          </tr>
                          <tr>
                            <td>Registration & Documentation Fees</td>
                            <td><span className={ classes.redBg }>$200</span></td>
                          </tr>
                          <tr>
                            <td>Total Purchase Price</td>
                            <td><span className={ classes.redBg }>$24,881</span></td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Down Payment</td>
                            <td>$9,952</td>
                          </tr>
                          <tr>
                            <td>Origination Fees</td>
                            <td>$119</td>
                          </tr>

                          <tr>
                            <td>Cash Needed at Purchase</td>
                            <td>$12,013</td>
                          </tr>

                          <tr>
                            <td>Total Cash Available</td>
                            <td>$2,507</td>
                          </tr>

                          <tr>
                            <td>Other Liquid Assets Available</td>
                            <td>$13,275</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.VacationHead}` }>Family (2030)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2030-2033-Registered Architect-01.01.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Marriage
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Marriage Year</span>
                          <span>2030</span>
                        </div>

                      </div>
                      <div className={ classes.formGroup }>
                        <span>Spouse&apos;s Career</span>
                        <span>Interior Designer</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Spouse&apos;s Income</span>
                        <span>$70,000</span>
                      </div>

                      <div className={ classes.subHead }>
                        Spouse&apos;s Student Loan Debt
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Student Loan Balance</span>
                          <span>$0</span>
                        </div>

                      </div>
                      <div className={ classes.formGroup }>
                        <span>Interest Rate</span>
                        <span>0.00%</span>
                      </div>
                      <div className={ classes.subHead }>
                        Pet
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Year of Purchase</span>
                          <span>2030</span>
                        </div>
                      </div>

                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.subHead }>
                        Marriage Multiples - Monthly Living Expenses
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Rent</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Utilities</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Food</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Clothing & Personal Care</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Entertainment</span>
                          <span>1.2 x</span>
                        </div>

                      </div>

                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Technology</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Transportation</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Miscellaneous</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Health Insurance Costs outside Premium</span>
                          <span>1.2 x</span>
                        </div>

                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Health Insurance Premium</span>
                          <span>1.2 x</span>
                        </div>

                      </div>

                    </div>
                  </Grid>

                  <Grid sm={ 4 }>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Marital Income Statement Summary 2030 to 2033</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Spouse&apos;s Income Earned </td>
                            <td>$313,772</td>
                          </tr>
                          <tr>
                            <td>My Income Earned</td>
                            <td>$711,153</td>
                          </tr>
                          <tr className={ classes.borderBox }>
                            <td>Taxes Paid</td>
                            <td>($192,104)</td>
                          </tr>
                          <tr>
                            <td>Total Tax Rate</td>
                            <td>18.74%</td>
                          </tr>

                          <tr>
                            <td>Living Expenses Paid</td>
                            <td>($236,132)</td>
                          </tr>
                          <tr>
                            <td>Financing Expenses Paid</td>
                            <td>($60,146)</td>
                          </tr>
                          <tr>
                            <td>Income after Living Expenses, Financing Expenses, & Taxes</td>
                            <td><span className={ classes.greenBg }>$264,584</span></td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.AddcareerPathHead}` }>Further Career Advancement (2032-2033)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2032-2033-Registered Architect-12.24.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Career
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Job Title</span>
                        <span>Project Architect</span>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Start Year</span>
                          <span>2032</span>
                        </div>
                        <p>Year</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>End Year</span>
                          <span>2033</span>
                        </div>
                        <p>2</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ `${classes.formGroup} ${classes.noborder}` }>
                          <span>Total Pre-Tax Base Income</span>
                          <span>$130,000</span>
                        </div>
                      </div>
                      <div className={ classes.subHead }>
                        Supplementary Income
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Start Year</span>
                        <span>2032</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>End Year</span>
                        <span>2033</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Income</span>
                        <span>$10,000</span>
                      </div>
                      <div className={ classes.subHead }>
                        401(k)
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Contribute to / Already Have a 401(k)?</span>
                        <span>Yes</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Personal Contribution as a % of Base Income</span>
                        <span>10.00%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Employer Matching Rate on Personal Contribution</span>
                        <span>100.00%</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Employer Matching Limit as a % of Base Income</span>
                        <span>6.00%</span>
                      </div>

                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Monthly Budget</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Monthly Base Income</td>
                            <td>$10,833</td>
                          </tr>
                          <tr>
                            <td>Other Monthly Income</td>
                            <td>$7,464</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Monthly Income</td>
                            <td>$18,297</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Taxes Paid</td>
                            <td>($4,547)</td>
                          </tr>

                          <tr>
                            <td>Living Expenses</td>
                            <td>($4,115)</td>
                          </tr>
                          <tr>
                            <td>Higher Education Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Children Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Pet Expenses</td>
                            <td>($115)</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>($316)</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>($1,049)</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Total Monthly Expenses</td>
                            <td>($10,142)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Monthly Cash Flow before Retirement Contributions</td>
                            <td>$8,155</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$1,083</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$7,072</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Annual Budget</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Income</td>
                            <td>$445,722</td>
                          </tr>
                          <tr>
                            <td>Total Taxes</td>
                            <td>($110,840)</td>
                          </tr>
                          <tr>
                            <td>Total Living Expenses</td>
                            <td>($110,469)</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>($7,642)</td>
                          </tr>

                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>($16,350)</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>($38,935)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Expenses</td>
                            <td>($287,022)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Cash Flow before Retirement Contributions</td>
                            <td>$158,700</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$26,390</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td><span>$132,310</span></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Budget Summary in years 2024 to 2025</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Income</td>
                            <td>$101,500</td>
                          </tr>
                          <tr>
                            <td>Total Taxes</td>
                            <td>($20,123)</td>
                          </tr>
                          <tr>
                            <td>Total Living Expenses</td>
                            <td>($53,033)</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>($15,005)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Expenses</td>
                            <td>($88,161)</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Cash Flow before Retirement Contributions</td>
                            <td>$13,339</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Retirement Contributions</td>
                            <td>$8,120</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Net Cash Flow for Savings and Investments</td>
                            <td>$5,219</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Expenses</td>
                            <td>$286,795</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">401(k) Retirement Account Summary</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Beginning 401(k) Balance in Year 2024</td>
                            <td>$40,691</td>
                          </tr>
                          <tr>
                            <td>Personal Contributions After 2 Years</td>
                            <td>$33,382</td>
                          </tr>
                          <tr>
                            <td>Earnings on Personal Contributions After 2 Years</td>
                            <td>$9,162</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Personal Contributions & Earnings</td>
                            <td>$42,543</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Employer Contributions After 2 Years</td>
                            <td>$16,691</td>
                          </tr>
                          <tr>
                            <td>Earnings on Employer Contributions After 2 Years</td>
                            <td>$3,554</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Total Employer Contributions & Earnings</td>
                            <td>$20,245</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>401(k) Balance before Vesting Reductions</td>
                            <td>$103,479</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>% Vested after 2 years at 25%/yr Schedule</td>
                            <td>75%</td>
                          </tr>
                          <tr>
                            <td>% of Employer Contributions & Earnings Not Vested</td>
                            <td>($5,061)</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Ending 401(k) Balance in Year 2025</td>
                            <td>$98,418</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.buyingHomeHead}` }>Buying a Home (2033)</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>2033-Registered Architect-01.01.19</p>
                      </div>
                      <div className={ classes.subHead }>
                        Buying a Home
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Purchase Year</span>
                          <span>2033</span>
                        </div>

                      </div>
                      <div className={ classes.formGroup }>
                        <span>Purchase Price</span>
                        <span>$300,000</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Down Payment</span>
                        <span>15.00%</span>
                      </div>

                    </div>
                  </Grid>

                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>

                      <div className={ classes.subHead }>
                        Buying a Home
                      </div>
                      <div className={ `${classes.formGroup} ${classes.noborder} ${classes.marginZero}` }>
                        <span>Mortgage</span>
                        <span>$255,000</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Term of the Loan</span>
                        <span>30</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Interest Rate</span>
                        <span>4.00%</span>
                      </div>
                      <div className={ `${classes.formGroup} ${classes.noborder} ${classes.marginZero}` }>
                        <span>Monthly Payment (Principal & Interest)</span>
                        <span>$1,217</span>
                      </div>

                      <div className={ `${classes.formGroup} ${classes.noborder} ${classes.marginZero}` }>
                        <span>Total Interest</span>
                        <span>$183,267</span>
                      </div>
                      <div className={ `${classes.formGroup} ${classes.noborder} ${classes.marginZero}` }>
                        <span>Total Principal & Interest</span>
                        <span>$438,267</span>
                      </div>

                    </div>

                  </Grid>

                  <Grid sm={ 4 }>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Buying a Home - Investment Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Purchase Price</td>
                            <td>$300,000</td>
                          </tr>
                          <tr>
                            <td>Down Payment</td>
                            <td>$45,000</td>
                          </tr>
                          <tr className={ classes.borderBox }>
                            <td>Closing Costs</td>
                            <td>$14,889</td>
                          </tr>
                          <tr>
                            <td>Fit Out Costs</td>
                            <td>$10,000</td>
                          </tr>

                          <tr className={ classes.borderBlue }>
                            <td>Cash Needed at Purchase</td>
                            <td>$69,889</td>
                          </tr>
                          <tr>
                            <td>Total Cash Available</td>
                            <td>$20,000</td>
                          </tr>
                          <tr>
                            <td>Other Liquid Assets Available</td>
                            <td>$94,229</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Debt-to-Income Ratio</td>
                            <td>11.65%</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.moreHighrEducationHead}` }>More Higher Education</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>Select Scenario</p>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Type of Higher Education</span>
                        <span>College</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Name of College</span>
                        <span>Virginia Tech</span>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Inflation</span>
                          <span>3.00%</span>
                        </div>
                        <p>Years</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Inflation</span>
                          <span>3.00%</span>
                        </div>
                        <p>Years</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Inflation</span>
                          <span>3.00%</span>
                        </div>
                        <p>Years</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">More Higher Education - Total Cost of Attendance</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Tuition & Fees</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Room & Board</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>On Campus Living Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Off Campus Living Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Student Loan Payments during College</td>
                            <td>$0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Cost of Attendance</td>
                            <td>$0</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Income Needed to Support Student Loans Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Tuition & Fees</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Room & Board</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>On Campus Living Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Off Campus Living Expenses</td>
                            <td>$69,639</td>
                          </tr>
                          <tr>
                            <td>Total Student Loan Payments during College</td>
                            <td>$13,649</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>Children and Pet Expenses</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Expenses</td>
                            <td>$286,795</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Income Needed to Support Student Loans Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Student Loan Balance (incl. Existing Loans)</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Monthly Payment (incl. Existing Loans)</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Annual Paid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Divided by 12.5%</td>
                            <td>12.5%</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Income Needed to Support Student Loans</td>
                            <td>$0</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Investment Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Scholarships</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Grants</td>
                            <td>$0</td>
                          </tr>

                          <tr>
                            <td>Other Financial Aid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Income Earned after Taxes</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Family Contribution</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Federal Subsidized Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Federal Unsubsidized Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Private Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Origination Fees</td>
                            <td>0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Financing Sources</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Cash Leftover</td>
                            <td><span className={ classes.redBg }>$0</span></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Total Liquid Assets Available</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.buyingHead}` }>Buying an Investment Property</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>Select Scenario</p>
                      </div>
                      <div className={ classes.subHead }>
                        Buying a Real Estate Investment Property
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Purchase Year</span>
                        <span>0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Sale Year</span>
                        <span>0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Purchase Price</span>
                        <span>0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Down Payment</span>
                        <span>0</span>
                      </div>

                      <div className={ classes.formGroup }>
                        <span>Annual Rental Income</span>
                        <span>0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Vacancy Rate</span>
                        <span>0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Annual Rental Income Growth</span>
                        <span>0</span>
                      </div>
                      <div className={ classes.subHead }>
                        Real Estate Investment Property Mortgage
                      </div>
                      <div className={ `${classes.formGroup} ${classes.noborder}` }>
                        <span>Mortgage</span>
                        <span>$0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Term of the Loan</span>
                        <span>0</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Interest Rate</span>
                        <span>0</span>
                      </div>

                    </div>
                  </Grid>
                  <Grid sm={ 4 }>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Real Estate Investment Property - Purchase Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Purchase Price</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Down Payment</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Closing Costs</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Fit Out Costs</td>
                            <td>$0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Cash Needed at Purchase</td>
                            <td>$0</td>
                          </tr>

                          <tr>
                            <td>Fit Out Costs</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Cash Available  </td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Other Liquid Assets Available</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Debt-to-Income Ratio</td>
                            <td>$0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>$0</span></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Real Estate Investment Property - Investment Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Rental Income</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Operating Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Financing Costs (Principal & Interest)</td>
                            <td>$0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Net Cash Flow</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Cash Invested</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Cash Returned</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Return on Investment (before taxes)</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>Multiple on Money (before taxes)</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Internal Rate of Return (before taxes)</td>
                            <td>0</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Income Needed to Support Student Loans Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Student Loan Balance (incl. Existing Loans)</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Monthly Payment (incl. Existing Loans)</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Annual Paid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Divided by 12.5%</td>
                            <td>12.5%</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Income Needed to Support Student Loans</td>
                            <td>$0</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Investment Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Scholarships</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Grants</td>
                            <td>$0</td>
                          </tr>

                          <tr>
                            <td>Other Financial Aid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Income Earned after Taxes</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Family Contribution</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Federal Subsidized Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Federal Unsubsidized Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Private Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Origination Fees</td>
                            <td>0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Financing Sources</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Cash Leftover</td>
                            <td><span className={ classes.redBg }>$0</span></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Total Liquid Assets Available</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.furtherEducationHead}` }>Further Higher Education</h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>Select Scenario</p>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Type of Higher Education</span>
                        <span>&nbsp;</span>
                      </div>
                      <div className={ classes.formGroup }>
                        <span>Name of Further Higher Education</span>
                        <span>&nbsp;</span>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Start Year</span>
                          <span>0</span>
                        </div>
                        <p>Years</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>End year</span>
                          <span>0</span>
                        </div>
                        <p>0</p>
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Room & Board</span>
                          <span>$0</span>
                        </div>
                        <p />
                      </div>
                      <div className={ classes.formGroupMain }>
                        <div className={ classes.formGroup }>
                          <span>Annual Tuition & Fees</span>
                          <span>$0</span>
                        </div>
                        <p />
                      </div>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>

                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Further Higher Education - Total Cost of Attendance</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Tuition & Fees</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Room & Board</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>On Campus Living Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Off Campus Living Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Student Loan Payments during College</td>
                            <td>$0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Cost of Attendance</td>
                            <td>$0</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Further Higher Education - Total Expenses</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Tuition & Fees</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Room & Board</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>On Campus Living Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Off Campus Living Expenses</td>
                            <td>$69,639</td>
                          </tr>
                          <tr>
                            <td>Total Student Loan Payments during College</td>
                            <td>$13,649</td>
                          </tr>
                          <tr>
                            <td>Car Expenses</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>Real Estate Expenses</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>Children and Pet Expenses</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>

                            <td>Total Expenses</td>
                            <td>$286,795</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                  <Grid sm={ 4 }>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">Income Needed to Support Student Loans Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Student Loan Balance (incl. Existing Loans)</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Monthly Payment (incl. Existing Loans)</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Annual Paid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Divided by 12.5%</td>
                            <td>12.5%</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Income Needed to Support Student Loans</td>
                            <td>$0</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.tableBox }>
                      <table cellPadding="0" cellSpacing="0">
                        <thead>
                          <th colSpan="2">College - Investment Analysis</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Scholarships</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Grants</td>
                            <td>$0</td>
                          </tr>

                          <tr>
                            <td>Other Financial Aid</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Income Earned after Taxes</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Family Contribution</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Federal Subsidized Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Federal Unsubsidized Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Private Student Loan</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Origination Fees</td>
                            <td>0</td>
                          </tr>
                          <tr className={ classes.borderBlue }>
                            <td>Total Financing Sources</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Total Expenses</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Cash Leftover</td>
                            <td><span className={ classes.greenBg }>$0</span></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Total Liquid Assets Available</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Cash Flow Check</td>
                            <td><span className={ classes.redBg }>2022</span></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={ classes.senarioGroup }>
                <h3 className={ `${classes.mainHead} ${classes.assetsHead}` }>
                  Existing Assets & Liabilities
                </h3>
                <Grid container className={ classes.RowBox }>
                  <Grid sm={ 4 }>
                    <div className={ classes.firstBlockLeft }>
                      <div className={ classes.senarioBox }>
                        <span>Senario</span>
                        <p>Select Scenario</p>
                      </div>

                    </div>
                  </Grid>

                </Grid>
              </div>

            </div>
            <div className={ classes.summaryTableFooter }>
              <Button className={ classes.closeModalPopup }>Close</Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={ SummaryPopup }
          onClose={ handleSummaryPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.summaryPopup}
        >
                  <DialogTitle className={classes.modalHeader1}>
          <h4>Summary Tables</h4>
          <img src={logo1} alt="logo" />
          <button type="button" onClick={()=>{setSummaryPopup(false)}} className={classes.closePopup}>×</button>
        </DialogTitle>
          <DialogContent className={classes.modalBody1}>
          <Summarytable  linkId= {linkId}/>
          </DialogContent>
          <DialogActions>
          <div className={ classes.modalFooter }>
            <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ ()=>{setSummaryPopup(false)} }>Close</Button>
          </div>
        </DialogActions>
        </Dialog>
      </div>

      {openDocsPopup !== '' && ( <SummaryDocs setOpenDocsPopup={ setOpenDocsPopup } popupStatus={ openDocsPopup } /> )}
    </Typography>
  );
};

Anita.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Anita );
