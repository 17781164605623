import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
// import BlueTick from '../../assets/img/pwi-existing/blue_circle_arrow.png';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import * as modulesServices from '../../calculations/modules-services';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import ReactTooltip from 'react-tooltip';

const homeSaleYear = []
const HomeSale1 = ( props ) => {
  const { classes, handleAssestObject, handleUpdatedObject, handleDefinationPopup} = props;
  React.useEffect(() => {
    if(modulesServices.module1Data.start_year !== undefined){
      for(let i = modulesServices.module1Data.start_year; i <= modulesServices.module1Data.start_year + 15; i+=2) {
        const obj = {
          value: i,
          scaledValue: i,
          label: `${i}`,
        }
        homeSaleYear.push(obj);
      }
    }else{
      for(let i = new Date().getFullYear(); i <= (new Date().getFullYear() + 15); i+=2) {
        const obj = {
          value: i,
          scaledValue: i,
          label: `${i}`,
        }
        homeSaleYear.push(obj);
      }
    }
  },[])
  
  const updateValue = ( e, value, field, type ) => {
    const inputValues = {...handleAssestObject};
    let newvalue = 0;
    type === 'slider' || type === 'string' ? newvalue = value : newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    inputValues[field] = newvalue;
    handleUpdatedObject(inputValues);
  };
  return (
    <div className={ classes.contentBlock }>
      <p>Do you plan on selling this home in the next 15 years?</p>
      <div className={classes.schoolOptions}>
        <span className = { handleAssestObject[`current_home_sale_in_15_years_2`] === "Yes" ? classes.active : "" } onClick={ (e)=> updateValue(e, "Yes", `current_home_sale_in_15_years_2`, "string")}>Yes</span>
        <span className = { handleAssestObject[`current_home_sale_in_15_years_2`] === "No" ? classes.active : "" } onClick={ (e)=> updateValue(e, "No", `current_home_sale_in_15_years_2`, "string")}>No</span>
      </div>
        { 
          modulesServices.module18Data.current_home_sale_in_15_years_2 === "Yes" &&
          (<div>
            <div className={classes.salesPara}>
            <p className={ classes.existingSpace }>Has this home been your primary residence for at least two of the last five years? <i>(If yes, you can exclude up to $250k of profits from capital gains tax when selling your home, if single. Up to $500k, if married.)</i></p>
            <div className={classes.schoolOptions}>
              <span className = { handleAssestObject[`primary_residence_2`] === "Yes" ? classes.active : "" } onClick={ (e)=> updateValue(e, "Yes", `primary_residence_2`, "string")}>Yes</span>
              <span className = { handleAssestObject[`primary_residence_2`] === "No" ? classes.active : "" } onClick={ (e)=> updateValue(e, "No", `primary_residence_2`, "string")}>No</span>
            </div>
            <p className={ classes.mt30 }>Are you married or do you plan on being married by the time you sell the home?</p>
            <div className={classes.schoolOptions}>
              <span className = { handleAssestObject[`married_status_2`] === "Yes" ? classes.active : "" } onClick={ (e)=> updateValue(e, "Yes", `married_status_2`, "string")}>Yes</span>
              <span className = { handleAssestObject[`married_status_2`] === "No" ? classes.active : "" } onClick={ (e)=> updateValue(e, "No", `married_status_2`, "string")}>No</span>
            </div> 
          </div>
          <div className={classes.section4TableScroll}>
            <div className={ `${classes.section4Table} ${classes.sliderBottomSpace}` }>
                <div className={ classes.section4TableLeft }>
                  <div className={ classes.section4TableLeftDiv }>
                    <span className={ classes.section4TableLeftTitle }>Sale Year</span>
                    <div className={ classes.section4TableLeftSlide }>
                      <PwiSlider
                        ValueLabelComponent={ ValueLabelComponent }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        marks={ homeSaleYear }
                        min={ modulesServices.module1Data.start_year !== undefined && modulesServices.module1Data.start_year !== null && modulesServices.module1Data.start_year !== "" ? modulesServices.module1Data.start_year : new Date().getFullYear()}
                        max={ modulesServices.module1Data.start_year !== undefined && modulesServices.module1Data.start_year !== null && modulesServices.module1Data.start_year !== "" ? modulesServices.module1Data.start_year + 14 : new Date().getFullYear() + 14}
                        step={ 1 }
                        value={ handleAssestObject.existing_home_sale_year_2 !== undefined ? handleAssestObject.existing_home_sale_year_2 : (new Date().getFullYear() + 14) }
                        valueLabelFormat={ `${commonFunctions.yearFormatter( handleAssestObject.existing_home_sale_year_2 )}` }
                        onChange={ ( e, value ) => updateValue( e, value, 'existing_home_sale_year_2', 'slider' ) }
                      />
                    </div>
                  </div>
                </div>
                <div className={ classes.section4TableRightOne }>
                  <div className={ classes.section4TableRightOneDiv }>
                    <NumberFormat displayType="text" value={ handleAssestObject.existing_home_sale_year_2 }/>
                  </div>
                </div>
                <div className={ `${classes.section4TableRightTwo} ${classes.noAfter}` }>
                  <div className={ classes.section4TableRightOneDiv }>
                    &nbsp;
                  </div>
                </div>
                <div className={ classes.sectionClear }></div>
                <div className={ classes.section4TableLeft }>
                  <div className={ `${classes.newFlexBox} ${classes.vehicleOwnTotalOne}` }>
                    <p>Sale Price in Year {' '}
                      {handleAssestObject[`existing_home_sale_year_2`]}
                      {' '}
                      </p>
                  </div>
                </div>
                <div className={ classes.section4TableRightOne }>
                  <div className={ `${classes.section4TableRightOneDiv} ${classes.totalText}` }>
                  <NumberFormat thousandSeparator decimalScale={ 0 } fixedDecimalScale displayType="text" value={ modulesServices.module18Data[`existingHomeSalesPriceYear2`]} prefix = "$"/> 
                  </div>
                </div>
          
                <div className={ classes.sectionClear }></div>
                  <div className={ classes.section4TableLeft }>
                    <div className={ classes.section4TableLeftDiv }>
                      <span className={ classes.section4TableLeftTitle }><span className={classes.textUnderline} aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Selling Costs' ) }>Selling Cost</span></span>
                      <div className={ classes.section4TableLeftSlide }>
                        <PwiSlider
                          ValueLabelComponent={ ValueLabelComponent }
                          aria-labelledby="discrete-slider-custom"
                          valueLabelDisplay="auto"
                          marks={ sliderValues.businessArray() }
                          min={ 0 }
                          max={ 10 }
                          step={ 1 }
                          value={ handleAssestObject.existing_home_selling_costs_2 }
                          valueLabelFormat={ `${commonFunctions.perFormatter( handleAssestObject.existing_home_selling_costs_2 )}` }
                          onChange={ ( e, value ) => updateValue( e, value, 'existing_home_selling_costs_2', 'slider' ) }
                        />
                      </div>
                    </div>
                  </div>
                  <div className={ classes.section4TableRightOne }>
                    <div className={ classes.section4TableRightOneDiv }>
                      <NumberFormat
                        className={classes.formInput}
                        allowNegative={false}
                        customInput={TextField}
                        value={handleAssestObject.existing_home_selling_costs_2}
                        onValueChange={ ( e, value ) => updateValue( e, value, 'existing_home_selling_costs_2', 'number' ) }
                        onFocus={(e) => e.target.select()}
                        decimalScale={0}
                        fixedDecimalScale
                      />
                    </div>
                  </div>
                  <div className={ `${classes.section4TableRightTwo} ${classes.noAfter}` }>
                    <div className={ classes.section4TableRightOneDiv }>
                      &nbsp;
                    </div>
                  </div>
                  <div className={ classes.sectionClear }></div>
                  <div className={ classes.section4TableLeft }>
                    <div className={ `${classes.newFlexBox} ${classes.vehicleOwnTotalOne}` }>
                      <p>Mortgage Balance Remaining in Year {' '}
                        {handleAssestObject[`existing_home_sale_year`]} 
                        </p>
                    </div>
                    <div className={ `${classes.newFlexBox} ${classes.vehicleOwnTotalOne}` }>
                        <img
                          src={ infoIcon }
                          alt="infoicon"
                          data-for="infoIcon"
                          data-tip="The cash you receive from selling real estate is the sale price minus selling costs. Then, when you sell the property, you also repay the remaining balance on your mortgage. That’s how you calculate the net cash flow from selling real estate."
                        />
                        <ReactTooltip id="infoIcon" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
                      <p>Net Cash Flow from Sale</p>
                    </div>
                  </div>
                  <div className={ classes.section4TableRightOne }>
                    <div className={ `${classes.section4TableRightOneDiv} ${classes.totalText}` }>
                      {modulesServices.module18Data.existingHomeMortgageBalance2 !== undefined ? ( 
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingHomeMortgageBalance2 } prefix={ modulesServices.module18Data.existingHomeMortgageBalance2 >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingHomeMortgageBalance2 < 0 && ')' } />
                      ) : ( '$0' )}
                    </div>
                    <div className={ `${classes.section4TableRightOneDiv} ${classes.totalText}` }>
                        {modulesServices.module18Data.existingHomeNetCashFlowFromSale2 !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingHomeNetCashFlowFromSale2 } prefix={ modulesServices.module18Data.existingHomeNetCashFlowFromSale2 >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingHomeNetCashFlowFromSale2 < 0 && ')' } />
                          ) : ( '$0' )}
                    </div>
                  </div>
                  <div className={ `${classes.section4TableRightTwo} ${classes.noAfter}` }>
                    <div className={ classes.section4TableRightOneDiv }>
                      &nbsp;
                    </div>
                    <div className={ classes.section4TableRightOneDiv }>
                      &nbsp;
                    </div>
                  </div>
              </div>
            </div>
          <div>            
        </div>
      </div>
    )}
  </div>
  );
};

HomeSale1.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( HomeSale1 );
