import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './styles';
import LogoMain from '../../../../assets/img/logo.png';

const DevelopersDropdownEl = styled.div`
  width: 1100px;
  padding: 0;
  
  ul {
    padding: 0 !important;
  }
  li {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    padding: 5px 0;
    margin: 0;
    font-size: 15px;
    margin-bottom: 10px;
  }
  h3 {
    margin:0 0 0;
  }
`;

const DevelopersDropdown = ( props ) => {
  const { classes } = props;
  return (
    <DevelopersDropdownEl className={ classes.marginLeftNewDevoloper }>
      <Grid container>
        <Grid sm={ 6 }>
          <Grid container className={ classes.megamenuContainer }>
            <Grid sm={ 12 }>
              <div className={ classes.LeftMegamenu }>
                <div className={ classes.paddingTopFisty } />
                <div className={ classes.listGroup }>

                  <ul>
                    <li><Link to="/aboutus">About</Link></li>
                    <li><Link to="/team">Leadership Team</Link></li>

                  </ul>
                </div>

              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid sm={ 6 } className={ classes.rightMegamenu }>
          <div className={ classes.aboutMegaMenu }>
            <img src={ LogoMain } alt="" />
            <h4>We have YOUR best interest in mind.</h4>
            <p>
              Inkwiry is independent, so you can be:
              <br />
              free of any conflicts of interest, not affiliated with any educational or financial institution and not sponsored by any third party.
            </p>
            <Button><Link to = "/about" className={ classes.btnstartQuizAbout }>LEARN MORE ABOUT US </Link></Button>
          </div>
        </Grid>

      </Grid>
    </DevelopersDropdownEl>
  );
};
DevelopersDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( DevelopersDropdown );
