import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Typography, Table } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import scrollToComponent from 'react-scroll-to-component';
import _ from 'lodash';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Draggable, Droppable } from 'react-drag-and-drop';
import NumberFormat from 'react-number-format';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { xpChallengePoints, getTotalTime, getUserXPRank } from '../exercises/pf101-service';
import Pf101Popups from '../exercises/popups';
import styles from './styles';
import ScrollToTop from '../../../components/ui/scroll-to-top/scrollToTop';
import poweUp101 from '../../../assets/img/pf101/challenge/powerup.png';
import GraphOne from '../../../assets/img/pf101/challenge/graph_1.png';
import ruleOfInvestments from '../../../assets/img/pf101/challenge/rule_of_investments.png';
import dashboardLogo from '../../../assets/img/pf101/challenge/dashboard-logo.png';
import Pageloader from '../../../components/ui/pageloader';
import { FV } from '../../../utilities/commonFunctions';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

const GET_CHALLENGE = loader( '../../../graphql/schema/pf101/get-exercise-details.graphql' );
const SAVE_CHALLENGE = loader( '../../../graphql/schema/pf101/save-exercise.graphql' );

const startTime = new Date();

const lumpsumArray = [];
const monthlyPaymentsArray = [];
const monthsArray = [];
const lumpsumVal = 75000000;
const lastMonth = 361;
let endingMonthlyPaymentValue = 0;
for ( let m = 0; m < lastMonth; m += 1 ) {
  const endingValue = FV( ( 5 / 12 ) / 100, m, 0, -lumpsumVal, 1 );
  lumpsumArray.push( endingValue );
  if ( m <= 200 ) {
    endingMonthlyPaymentValue = FV( ( 5 / 12 ) / 100, m, -500000, 0, 1 );
  } else {
    endingMonthlyPaymentValue = FV( ( 5 / 12 ) / 100, 1, 0, -endingMonthlyPaymentValue, 1 );
  }
  monthlyPaymentsArray.push( endingMonthlyPaymentValue );
  monthsArray.push( m );
}

const monthsData = monthsArray;
const lumpsumData = lumpsumArray;
const monthlyPaymentsData = monthlyPaymentsArray;

/* eslint react/prop-types: 0 */

const Pf101Challenge3 = ( props ) => {
  const { classes } = props;
  const scrollOptions = {
    offset: -120,
    align: 'top',
    duration: 500,
  };

  const correctAns = ['d', 'd', 'a', 'b', 'b', 'd', 'c', 'd', 'd', 'a', 'b', 'd'];

  const questionMainAns = [];
  const defaultReference = useRef( null );
  for ( let i = 0; i <= 11; i += 1 ) {
    const questionsObject = {
      id: i + 1, answer: '', correctAns: correctAns[i], scroll: defaultReference, question_attempts: 1, xp_earned: 0,
    };
    questionMainAns.push( questionsObject );
  }

  const dropZoneDetails = [
    {
      class: `${classes.riskBox}`, text: 'Less Risk', dropText: '', answer: '4', correct: false,
    },
    {
      class: `${classes.riskBox} ${classes.riskBoxOne}`, text: '', dropText: '', answer: '1', correct: false,
    },
    {
      class: `${classes.riskBox} ${classes.riskBoxTwo}`, text: '', dropText: '', answer: '2', correct: false,
    },
    {
      class: `${classes.riskBox} ${classes.riskBoxThree}`, text: '', dropText: '', answer: '3', correct: false,
    },
    {
      class: `${classes.riskBox} ${classes.riskBoxFour}`, text: 'More Risk', dropText: '', answer: '5', correct: false,
    },
  ];
  const dropTexts = ['US 10-yr Treasury Bond', 'US Equities Mutual Fund', 'Under Armour Stock', 'High-Yield Savings Account', 'Hedge Fund'];

  let quesionSection = useRef( null );

  const [step, setStep] = React.useState( 0 );
  const [questions, setQuestions] = useState( questionMainAns );
  const [progress, setProgress] = React.useState( 0 );
  const [myScorePopup, setMyScorePopup] = React.useState( false );
  const [unselectedQuestions, setUnselectedQuestions] = React.useState( '' );
  const [loading, setLoading] = React.useState( true );
  const [pf101Points, setPf101Points] = React.useState( 0 );
  const [popupDetails, setPopupDetails] = React.useState( {} );
  const [exerciseStatus, setExerciseStatus] = React.useState( 'submit' );
  const [challengePoints, setChallengePoints] = React.useState( 0 );
  const [challengePercentage, setChallengePercentage] = React.useState( 0 );
  const [dropZoneData, setDropZoneData] = React.useState( dropZoneDetails );

  useQuery( GET_CHALLENGE, {
    variables: {
      level: 13,
      exercise: 1,
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      setLoading( false );
      setPf101Points( response.getPf101ExerciseDetails.total_points );
      if ( response.getPf101ExerciseDetails.questions.length > 0 ) {
        const questionData = [...questions];
        response.getPf101ExerciseDetails.questions.forEach( ( data ) => {
          questionData[data.question_id - 1].question_attempts = data.question_attempts > 0 ? data.question_attempts : 1;
          questionData[data.question_id - 1].xp_earned = xpChallengePoints( questions[data.question_id - 1].question_attempts );
          questionData[data.question_id - 1].status = 'C';
          questionData[data.question_id - 1].answer = questions[data.question_id - 1].correctAns;
        } );
        let dropZoneData1 = [...dropZoneData];
        dropZoneData1 = dropZoneData.map( ( value1 ) => {
          const value = value1;
          value.dropText = dropTexts[parseInt( value.answer, 10 ) - 1];
          value.correct = true;
          return value;
        } );
        setDropZoneData( dropZoneData1 );
        setQuestions( questionData );
        setExerciseStatus( 'review' );
        setStep( 2 );
      } else {
        setStep( 1 );
      }
    },
    onError( ) {
      window.location.href = '/';
    },
  } );

  const [saveChallenge] = useMutation( SAVE_CHALLENGE, {
    onCompleted( response ) {
      setLoading( false );
      if ( response.savePf101ExerciseDetails.status === true ) {
        setPopupDetails( ( prevState ) => ( {
          ...prevState,
          popupOpen: true,
        } ) );
      }
    },
    onError( ) {
      return false;
    },
  } );

  useEffect( () => {
    const answerQuestions = _.filter( questions, ['status', 'C'] ).length;
    let progressBar = answerQuestions * ( 100 / 8 );
    progressBar = progressBar.toFixed( 0 );
    if ( progressBar > 99 ) {
      progressBar = 100;
    }
    setProgress( parseFloat( progressBar ) );
    const challengePoint = _.sumBy( questions, 'xp_earned' );
    setChallengePoints( challengePoint );
    setChallengePercentage( ( challengePoint / 1100 ) * 100 );
  }, [questions] );

  const handleAnswer = ( event, index ) => {
    const newQuesions = [...questions];
    newQuesions[index].answer = event.target.value;
    newQuesions[index].status = questions[index].correctAns === event.target.value ? 'C' : 'W';
    if ( newQuesions[index].status === 'W' ) {
      newQuesions[index].question_attempts += 1;
    } else {
      newQuesions[index].xp_earned = xpChallengePoints( newQuesions[index].question_attempts );
    }
    setQuestions( newQuesions );
  };

  const handleMyScore = () => {
    const unAnswerQuestions = _.reject( questions, ['status', 'C'] );
    if ( unAnswerQuestions.length > 0 ) {
      setUnselectedQuestions( _.map( unAnswerQuestions, 'id' ).join( ', ' ) );
      setMyScorePopup( true );
    } else {
      const totalTime = getTotalTime( startTime );
      const totalPoints = _.sumBy( questions, 'xp_earned' );
      const totalAttempts = _.sumBy( questions, 'question_attempts' );
      const questionData = questions.map( ( question ) => _.pick( question, ['id', 'question_attempts', 'xp_earned'] ) );

      const popupValue = {};
      popupValue.exercisePoints = totalPoints;
      popupValue.precision = false;
      popupValue.level = false;

      const presentRank = getUserXPRank( pf101Points );
      const nextRank = getUserXPRank( pf101Points + totalPoints );
      if ( presentRank !== nextRank ) {
        popupValue.rankChangeStatus = true;
        popupValue.rank = nextRank;
      } else {
        popupValue.rankChangeStatus = false;
        popupValue.rank = '';
      }
      popupValue.type = 'challenge';
      setPopupDetails( popupValue );
      saveChallenge( {
        variables: {
          data: {
            level: 13,
            exercise: 1,
            exercise_time: totalTime,
            points: totalPoints,
            total_attempts: totalAttempts,
            questionData,
          },
        },
      } );
    }
    return true;
  };

  const onDrop = ( data, key ) => {
    const dropText = dropTexts[parseInt( data.appropriate, 10 ) - 1];
    const droppableData = [...dropZoneData];
    droppableData[key].dropText = dropText;
    if ( droppableData[key].answer === data.appropriate ) {
      droppableData[key].correct = true;
    } else {
      droppableData[key].correct = false;
    }

    const questionsData = [...questions];

    const correctDrop = _.filter( droppableData, { correct: true } );
    if ( correctDrop.length === 5 ) {
      questionsData[7].answer = 'd';
      questionsData[7].status = 'C';
      questionsData[7].xp_earned = xpChallengePoints( questionsData[7].xp_earned );
      setQuestions( questionsData );
    }

    setDropZoneData( droppableData );
  };

  const handleMyScoreClose = () => {
    setMyScorePopup( false );
  };

  const handleQuestionScroll = ( questionNumber ) => {
    scrollToComponent( questionNumber, scrollOptions );
  };

  const elementInViewport = ( el ) => {
    let element = el;
    if ( typeof $ === 'function' && element instanceof $ ) {
      element = element[0];
    }

    if ( typeof element !== 'undefined' && element !== '' ) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 75
              && rect.left >= 0
              && rect.bottom <= ( window.innerHeight || document.documentElement.clientHeight )
              && rect.right <= ( window.innerWidth || document.documentElement.clientWidth )
      );
    }
    return false;
  };

  useEffect( () => {
    const onScroll = ( ) => {
      if ( step === 2 ) {
        if ( quesionSection !== null && quesionSection.getBoundingClientRect().top <= 65 ) {
          document.getElementById( 'awareness_title' ).style.position = 'fixed';
          document.getElementById( 'awareness_title_sub' ).style.display = 'block';
        } else {
          document.getElementById( 'awareness_title' ).style.position = '';
          document.getElementById( 'awareness_title_sub' ).style.display = 'none';
        }

        if ( quesionSection !== null && window.innerHeight - quesionSection.getBoundingClientRect().top >= 300 ) {
          document.getElementById( 'sticky_numbers' ).style.left = '30px';
        } else {
          document.getElementById( 'sticky_numbers' ).style.left = '-11em';
        }

        for ( let i = 0; i < 12; i += 1 ) {
          const element = $( questionMainAns[i].scroll );
          let subElement = false;
          if ( questions[i].status === 'C' ) {
            subElement = true;
          }

          if ( ( elementInViewport( element ) === true || elementInViewport( element.find( '>div:first-child' ).find( 'select' ) ) )
            || ( subElement === true && ( elementInViewport( element.find( '>div:first-child' ) ) || elementInViewport( element.find( '>div:last-child' ) )
              || elementInViewport( element.find( '>div:last-child' ).find( 'h3' ) ) || elementInViewport( element.find( '>div:last-child' ).find( 'h2' ) )
              || elementInViewport( element.find( '>div:last-child' ).find( 'div' ) ) || elementInViewport( element.find( '>div:last-child' ).find( 'ul' ) ) ) ) ) {
            element.css( 'opacity', '1' );
            element.siblings().not( '#progress-bar, #my-score-button' ).css( 'opacity', '0.3' );
            $( '#sticky_numbers li' ).removeClass( 'stick_number_active_class' );
            if ( i <= 6 ) {
              $( `#sticky_numbers ul:first-child li:nth-child(${i + 1})` ).addClass( 'stick_number_active_class' );
            } else {
              $( `#sticky_numbers ul:nth-child(2) li:nth-child(${i + 1 - 7})` ).addClass( 'stick_number_active_class' );
            }
            break;
          }
        }

        if ( ( quesionSection !== null && quesionSection.getBoundingClientRect() !== null && window.innerHeight - quesionSection.getBoundingClientRect().top < 300 ) ) {
          document.getElementById( 'progress-bar' ).style.display = 'none';
        } else {
          document.getElementById( 'progress-bar' ).style.display = 'block';
        }
      }
    };
    window.addEventListener( 'scroll', onScroll );

    return () => window.removeEventListener( 'scroll', onScroll );
  } );
  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }

      {step === 1 && (
        <div className={ `${classes.textTop} ${classes.textTopMin}` }>
          <div className={ classes.container }>
            <div className={ classes.logoNew }>
              <Link href="/">
                <img src={ dashboardLogo } alt="" />
              </Link>
            </div>
            <div className={ classes.pfModuleImage }>
              <Link href="/pf101/dashboard">
                <img src={ poweUp101 } alt="" />
              </Link>
            </div>
            <div className={ `${classes.tableBox} ${classes.tableWelcomeChallenge}` }>
              <h2>Welcome to Challenge 03!</h2>
              <p>
                How well do you know Levels 07-09?
                <br />
                Put your skills to the test with a challenge.
                <br />
                Let’s get started!
              </p>
              <Button onClick={ () => { setStep( 2 ); } }> Next </Button>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div>
          {exerciseStatus === 'submit' && (
            <div className={ classes.textTop }>
              <div className={ classes.container }>
                <div className={ classes.logoNew }>
                  <img src={ dashboardLogo } alt="" />
                </div>
                <div className={ classes.pfModuleImage }>
                  <img src={ poweUp101 } alt="" />
                </div>
                <div className={ classes.tableBox }>

                  <div className={ classes.textTopContent }>
                    <h4>Each question is worth 100 XP. If you get the question right on the first try, you will earn 100 XP for that question. If you get the question right on the second try, 80 XP. On the third try, 65 XP. Lastly, on the fourth try, 50 XP.</h4>
                    <p>After you get the correct answer, review the solution to that question and gain valuable financial life knowledge. Good luck!</p>
                    <Button onClick={ () => { scrollToComponent( quesionSection, { offset: -56, align: 'top', duration: 500 } ); } }> TAKE THE CHALLENGE </Button>
                  </div>
                  <div className={ classes.imagesBlockNew }>
                    <div className={ classes.imageOne }>
                      <img src={ GraphOne } alt="" />
                    </div>
                    <div className={ classes.imageTwo }>
                      <img src={ GraphOne } alt="" />
                    </div>
                    <div className={ classes.imageThree }>
                      <img src={ GraphOne } alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={ classes.awarenessBody }>
            <div className={ classes.awernessQuestionsSec } ref={ ( section ) => { quesionSection = section; } }>
              <div className={ classes.ChallengesQuizHead } id="awareness_title">
                <div className={ classes.container }>
                  <Typography variant="h3" component="h3">PF101 Challenge 03</Typography>
                </div>
              </div>

              <div className={ classNames( classes.ChallengesQuizHead, classes.ChallengesQuizSub ) } id="awareness_title_sub">
                <div className={ classes.container }>
                  <Typography variant="h3" component="h3">&nbsp;</Typography>
                </div>
              </div>

              <div id="sticky_numbers" className={ classes.ChallengesNumbers }>
                <ul>
                  {
                      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map( ( value ) => (
                        <li key={ value }>
                          <Button onClick={ () => handleQuestionScroll( questionMainAns[value - 1].scroll ) } className={ classNames( classes.listNum, ( questions[value - 1].answer !== '' && questions[value - 1].status === 'C' && classes.listNumRight ) ) }>
                            <span>
                              {value}
                              <i className="las la-check" />
                            </span>
                          </Button>
                        </li>
                      ) )
                    }
                </ul>
                <div className={ classes.stickyBottom }>
                  <Button onClick={ () => handleQuestionScroll( questionMainAns[0].scroll ) } className={ `${classes.listNumArrow} ${classes.arrowUp}` }>
                    <span>
                      <i
                        className="las la-arrow-up"
                        data-for="returnStart"
                        data-tip="Return to start"
                      />
                    </span>
                    <ReactTooltip id="returnStart" place="right" type="info" effect="solid" className={ classes.tooltipInfo } />
                  </Button>
                </div>
              </div>
              <div className={ classes.questionRow }>
                <div className={ classes.container }>
                  <div ref={ ( section ) => { questionMainAns[0].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>1</span>
                      <p className={ classes.questionTitle }>Successful businesses, organizations, entrepreneurs, professional athletes, famous artists and military generals all have one thing in common: a strategy and a plan that works for them. Why do successful people develop strategies and make plans?</p>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[0].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              Developing a strategy and a plan provides a clear roadmap to achieving their goals
                              <input type="radio" disabled={ questions[0].answer === 'd' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 0 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[0].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              Developing a strategy and a plan allows them to ask, &quot;What if I do this or that?&quot; and see what might happen in the future
                              <input type="radio" disabled={ questions[0].answer === 'd' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 0 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[0].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              Having a strategy and a plan better prepares them for the future
                              <input type="radio" disabled={ questions[0].answer === 'd' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 0 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[0].answer === 'd' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              All of the above
                              <input type="radio" disabled={ questions[0].answer === 'd' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 0 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>

                    {questions[0].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classes.ansBox }>
                          <Grid container spacing={ 1 }>
                            <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                              <div className={ classes.boxFifty }>
                                <Typography variant="h4" component="h4">With a strategy and a plan</Typography>
                                <div className={ classes.withStrategyContent }>
                                  <span>42%</span>
                                  <p>more likely to achieve your goals if you write them down on a regular basis</p>
                                </div>
                                <div className={ classes.withStrategyContent }>
                                  <span>44%</span>
                                  <p>of those who have a ﬁnancial plan in place exceed their savings goals each year</p>
                                </div>
                                <div className={ classes.withStrategyContent }>
                                  <span>250%</span>
                                  <p>Over a lifetime, having a plan equates to 250% more retirement savings</p>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                              <div className={ `${classes.boxFifty} ${classes.textBlack}` }>
                                <Typography variant="h4" component="h4">Without a strategy and a plan</Typography>
                                <div className={ `${classes.withStrategyContent} ${classes.textBlackCnt}` }>
                                  <span>78%</span>
                                  <p>of US workers live paycheck-to-paycheck</p>
                                </div>
                                <div className={ `${classes.withStrategyContent} ${classes.textBlackCnt}` }>
                                  <span>60%</span>
                                  <p>of Americans could not handle an unexpected $1,000 expense</p>
                                </div>
                                <div className={ `${classes.withStrategyContent} ${classes.textBlackCnt}` }>
                                  <span>42%</span>
                                  <p>of Americans will retire with less than $10,000 saved</p>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[1].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}

                  </div>

                  <div ref={ ( section ) => { questionMainAns[1].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>2</span>
                      <p className={ classes.questionTitle }>The expression, &quot;don&apos;t put all your eggs in one basket&quot; has great meaning. Which of the following best describes how that expression relates to investing?</p>

                      <ul className={ classes.optionList }>
                        <li className={ ( questions[1].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              If you invest all your money in one company, you are taking a big risk because if that one company goes bankrupt, you will lose all your money.
                              <input type="radio" disabled={ questions[1].answer === 'd' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[1].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              If you put all your eggs in one basket, that basket will overflow and your eggs will fall on the ground and break.
                              <input type="radio" disabled={ questions[1].answer === 'd' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[1].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              If you diversify and invest in 100 companies and one company goes bankrupt, you lose only a little bit of money.
                              <input type="radio" disabled={ questions[1].answer === 'd' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[1].answer === 'd' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              Both A and C
                              <input type="radio" disabled={ questions[1].answer === 'd' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[1].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">&quot;Don&apos;t put all your eggs in one basket&quot;</Typography>
                              <p>
                                <span className={ classes.spanColor }>Making an investment requires taking risk. The risk is that you will lose some or all of your money.</span>
                                {' '}
                                {' '}
                                But, there are ways to lower your risk when making investments. That&apos;s where the expression &quot;don&apos;t put all your eggs in one basket&quot; comes into play. Let&apos;s say you have $100,000 to invest and look at two examples:
                              </p>
                              <div className={ classes.paddingLeft30 }>
                                <p>
                                  Example A: You invest $100,000 in your friend&apos;s new cryptocurrency business
                                  <br />
                                  Example B: You invest $10,000 in your friend&apos;s new cryptocurrency business, $60,000 in 10 different stocks, $20,000 in bonds, and $10,000 in a high-yield savings account
                                </p>
                              </div>
                              <p>
                                What happens in both examples if your friend&apos;s new business goes bankrupt?
                              </p>
                              <div className={ classes.paddingLeft30 }>
                                <p>
                                  Example A: You lose all of your $100,000. Ouch!
                                  <br />
                                  Example B: You only lose $10,000 and still have $90,000 invested elsewhere.
                                </p>
                              </div>
                              <p>
                                &quot;Don&apos;t put all your eggs in one basket&quot; is really good investing advice.
                                {' '}
                                {' '}
                                <span className={ classes.spanColor }>Lower your risk by diversifying your investments.</span>
                              </p>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[2].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[2].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ `${classes.questionNum} ${classes.questionNumThree}` }>3</span>
                      <p className={ classes.questionTitle }>You hit the lottery – congratulations! Now, you have a choice to make.</p>
                      <div className={ classes.optionBlocks }>
                        <p className={ classes.questionTitle }>
                          Option A: Receive $75,000,000 today in a lump sum payment
                          <br />
                          Option B: Receive $500,000 per month for the next 200 months
                        </p>
                      </div>
                      <p className={ classes.questionTitle }>Which option is a better money move? Both options are after taxes. </p>

                      <ul className={ classes.optionList }>
                        <li className={ ( questions[2].answer === 'a' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              Option A
                              <input type="radio" disabled={ questions[2].answer === 'a' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[2].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              Option B
                              <input type="radio" disabled={ questions[2].answer === 'a' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[2].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              I don&apos;t care which option, just give me my money!
                              <input type="radio" disabled={ questions[2].answer === 'a' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>

                    {questions[2].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">Option A: Lump Sum Payment</Typography>
                              <p>
                                Are you surprised? Your logic would tell you to select Option B because the monthly payments equal $100,000,000 in total and $100,000,000 is greater than $75,000,000.
                              </p>
                              <p>
                                Why would Option A be a better money move? Check out the graph below to see how both options grow over time. Assuming both options earn a 5% annual return that is compounded monthly, the answer becomes clear.
                              </p>
                              <p>
                                The lesson here is that
                                {' '}
                                {' '}
                                {' '}
                                <span className={ classes.spanColor }>a dollar today is worth more than a dollar tomorrow.</span>
                                {' '}
                                {' '}
                                {' '}
                                Why? Because I can invest that money today and earn a return that grows over time. This concept is called the
                                {' '}
                                {' '}
                                {' '}
                                <span className={ classes.spanColor }>time value of money.</span>
                              </p>
                              <p className={ classes.graphHeadingChallenge }>
                                <b>Lottery Winnings: Lump Sum vs. Monthly Payments</b>
                              </p>

                              <HighchartsReact
                                highcharts={ Highcharts }
                                options={ {
                                  chart: {
                                    plotBackgroundColor: '#ffffff',
                                    plotBorderColor: '#cccccc',
                                    plotBorderWidth: 1,
                                    plotShadow: false,
                                    type: 'column',
                                  },
                                  credits: {
                                    enabled: false,
                                  },
                                  title: {
                                    text: '',
                                  },
                                  exporting: {
                                    enabled: false,
                                  },
                                  xAxis: {
                                    categories: monthsData,
                                    labels: {
                                      style: {
                                        color: '#000000',
                                        fontSize: '14px',
                                      },
                                    },
                                  },
                                  yAxis: [{ // Primary yAxis
                                    min: 0,
                                    title: {
                                      text: 'Income & Expenses',
                                      enabled: false,
                                      style: {
                                        color: '#000000',
                                        fontSize: '14px',
                                      },
                                    },
                                    labels: {
                                      formatter() {
                                        const chart = this;
                                        if ( chart.value < 0 ) {
                                          return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                                        }
                                        return formatDollar( Math.round( chart.value ) );
                                      },
                                      style: {
                                        color: '#000000',
                                        fontSize: '14px',
                                      },
                                    },
                                  }, { // Tertiary yAxis
                                    /* min: 0,
                                        tickInterval: 10000,
                                        gridLineWidth: 0, */
                                    title: {
                                      text: 'Net Worth',
                                      enabled: false,
                                      style: {
                                        color: '#000000',
                                        fontSize: '14px',
                                      },
                                    },
                                    labels: {
                                      formatter() {
                                        const chart = this;
                                        if ( chart.value < 0 ) {
                                          return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                                        }
                                        return formatDollar( Math.round( chart.value ) );
                                      },
                                      style: {
                                        color: '#000000',
                                        fontSize: '14px',
                                      },
                                    },
                                    opposite: false,
                                  }],
                                  tooltip: {
                                    formatter() {
                                      const chart = this;
                                      if ( chart.y < 0 ) {
                                        return `${chart.series.name}<br/>${chart.x}: <span style="color:red; font-weight:bold;">(${formatDollar( -Math.round( chart.y ) )})</span>`;
                                      }

                                      return `${chart.series.name}<br/>${chart.x}: <b>${formatDollar( Math.round( chart.y ) )}</b>`;
                                    },
                                  },
                                  plotOptions: {
                                    column: {
                                      stacking: 'normal',
                                    },
                                  },
                                  legend: {
                                    itemStyle: {
                                      fontSize: '14px',
                                    },
                                  },
                                  series: [{
                                    name: 'Option A: Lump Sum',
                                    type: 'spline',
                                    yAxis: 1,
                                    data: lumpsumData,
                                    stack: 'female',
                                    color: '#70AD47',
                                  }, {
                                    type: 'spline',
                                    name: 'Option B: Monthly Payments',
                                    yAxis: 1,
                                    data: monthlyPaymentsData,
                                    color: '#B0B0B0',
                                  }],
                                } }
                              />

                            </Grid>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[3].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}

                  </div>

                  <div ref={ ( section ) => { questionMainAns[3].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>4</span>
                      <p className={ classes.questionTitle }>True or False: When you make contributions to your 401(k) retirement account, you are contributing a portion of your paycheck after taxes are taken out.</p>

                      <ul className={ classes.optionList }>
                        <li className={ ( questions[3].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              True
                              <input type="radio" disabled={ questions[3].answer === 'b' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 3 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[3].answer === 'b' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              False
                              <input type="radio" disabled={ questions[3].answer === 'b' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 3 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>

                    {questions[3].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">401(k) retirement account</Typography>
                              <p>
                                <b>False!</b>
                                {' '}
                                {' '}
                                When you make contributions to a 401(k) retirement account, you invest a percentage of your paycheck
                                {' '}
                                <span className={ classes.spanColor }>before taxes are taken out.</span>
                                {' '}
                              </p>
                              <p>401(k) contributions are considered pre-tax contributions and lower your taxable income. Let&apos;s look at a simplified example of how 401(k) contributions lower your taxes.</p>
                              <div className={ `${classes.inflationTable} ${classes.inflationTableNew}` }>
                                <table>
                                  <thead className={ classes.headerRow }>
                                    <tr>
                                      <th />
                                      <th>
                                        Without
                                        <br />
                                        401(k) contributions
                                      </th>
                                      <th>
                                        With
                                        <br />
                                        401(k) contributions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Gross Income</td>
                                      <td>$50,000</td>
                                      <td>$50,000</td>
                                    </tr>
                                    <tr>
                                      <td>Less: 401(k) Contributions</td>
                                      <td>$0</td>
                                      <td className={ classes.textDanger }>($5,000)</td>
                                    </tr>
                                    <tr>
                                      <td>Less: Standard Deduction</td>
                                      <td className={ classes.textDanger }>($12,200)</td>
                                      <td className={ classes.textDanger }>($12,200)</td>
                                    </tr>
                                    <tr>
                                      <td>Federal Taxable Income</td>
                                      <td>$37,800</td>
                                      <td>$32,800</td>
                                    </tr>
                                    <tr>
                                      <td>Tax Rate</td>
                                      <td>20%</td>
                                      <td>20%</td>
                                    </tr>
                                    <tr className={ classes.headerRow }>
                                      <td>Total Taxes</td>
                                      <td>$7,560</td>
                                      <td>$6,560</td>
                                    </tr>
                                  </tbody>

                                </table>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[4].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}

                  </div>

                  <div ref={ ( section ) => { questionMainAns[4].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>5</span>
                      <p className={ classes.questionTitle }>True or False: Since contributions to your 401(k) retirement account are deducted from your salary before taxes, when you go to withdraw that money in retirement, you won&apos;t have to pay taxes.</p>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[4].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              True
                              <input type="radio" disabled={ questions[4].answer === 'b' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[4].answer === 'b' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              False
                              <input type="radio" disabled={ questions[4].answer === 'b' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>

                    {questions[4].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">401(k) Retirement Account</Typography>
                              <p>
                                <b>False!</b>
                                {' '}
                                {' '}
                                When you make contributions to a 401(k) retirement account,
                                you invest a percentage of your paycheck
                                {' '}
                                {' '}
                                <span className={ classes.spanColor }>before taxes are taken out.</span>
                                {' '}
                                But, when you go to withdraw from your 401(k) in retirement, your withdrawals are taxed as ordinary income.
                              </p>
                              <p>The lesson here is that even though you are not paying taxes on the money you contribute to your 401(k) during your working years, you will pay taxes on that money later when you withdraw that money in retirement.</p>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[5].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}

                  </div>

                  <div ref={ ( section ) => { questionMainAns[5].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>6</span>
                      <p className={ classes.questionTitle }>A(n) _______ is a monetary asset that is purchased with the hope that it will generate income or will appreciate in the future.</p>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[5].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              Car
                              <input type="radio" disabled={ questions[5].answer === 'd' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[5].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              Liability
                              <input type="radio" disabled={ questions[5].answer === 'd' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[5].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              TV
                              <input type="radio" disabled={ questions[5].answer === 'd' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[5].answer === 'd' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              Investment
                              <input type="radio" disabled={ questions[5].answer === 'd' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[5].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">What is an investment?</Typography>
                              <p>
                                <span className={ classes.spanColor }>
                                  An investment is a monetary asset that is purchased with the hope that it will generate income or will appreciate in the future;
                                  a tool to create future wealth.
                                </span>
                                {' '}
                                {' '}
                                An investment is a type of asset.
                              </p>
                              <p>There are many types of investments, such as:</p>
                              <Grid container>
                                <Grid sm={ 4 }>
                                  <ul className={ `${classes.arrowList} ${classes.arrowListNew}` }>
                                    <li>Stocks</li>
                                    <li>Bonds</li>
                                    <li>Investment Funds</li>
                                    <li>Bank Products</li>
                                    <li>Options</li>
                                  </ul>
                                </Grid>
                                <Grid sm={ 8 }>
                                  <ul className={ `${classes.arrowList} ${classes.arrowListNew}` }>
                                    <li>Annuities</li>
                                    <li>Retirement Accounts</li>
                                    <li>College Savings Accounts (529, etc.)</li>
                                    <li>Insurance</li>
                                    <li>Alternative and Complex Products</li>
                                  </ul>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[6].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[6].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>7</span>
                      <p className={ classes.questionTitle }>Using the Rule of 72, how long would it take an investment earning 4% annually to double in value? </p>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[6].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              30 years
                              <input type="radio" disabled={ questions[6].answer === 'c' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[6].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              27 years
                              <input type="radio" disabled={ questions[6].answer === 'c' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[6].answer === 'c' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              18 years
                              <input type="radio" disabled={ questions[6].answer === 'c' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[6].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              9 years
                              <input type="radio" disabled={ questions[6].answer === 'c' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>

                    {questions[6].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Grid container>
                            <Grid sm={ 6 }>
                              <Typography variant="h3">Rule of 72 and investments</Typography>
                              <p>
                                <span className={ classes.spanColor }>The Rule of 72 is a quick way to calculate the number of years it will take for an investment to double in value given an annual rate of return or interest rate.</span>
                                {' '}
                                {' '}
                                This formula comes in handy for mental calculations and when thinking about an investment.
                              </p>
                              <br />
                              <p>
                                # of years = 72 / annual rate of return
                                <br />
                                # of years = 72 / 4 = 18 years
                              </p>
                            </Grid>
                            <Grid sm={ 6 } className={ classes.ansBox5Sub }>
                              <div className={ classes.imageRight }>
                                <img src={ ruleOfInvestments } alt="" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[7].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}

                  </div>
                  <div ref={ ( section ) => { questionMainAns[7].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>8</span>
                      <p className={ classes.questionTitle }>
                        Drag and drop the items on the right into the appropriate place on the risk ladder. The top of the ladder being the least risky and the bottom of the ladder being most risky. There is only one right place for each!
                      </p>
                      <div className={ classes.riskSecttion }>
                        <Grid container>
                          <Grid sm={ 6 }>
                            <div className={ classes.riskContainer }>

                              { dropZoneData.map( ( data, index ) => (
                                <Droppable
                                  className={ data.class }
                                  types={ ['appropriate'] }
                                  onDrop={ ( e ) => { onDrop( e, index ); } }
                                >
                                  <span>{data.text}</span>
                                  <p>{data.dropText}</p>
                                  {data.correct === true && data.dropText !== '' && ( <i className={ classNames( classes.textSuccess, 'fa fa-check' ) } /> )}
                                  {data.correct === false && data.dropText !== '' && ( <i className={ classNames( classes.textDanger, 'fa fa-times' ) } /> )}
                                </Droppable>
                              ) )}
                            </div>
                          </Grid>
                          <Grid sm={ 6 }>
                            <div className={ classes.riskContainer }>
                              <ul className={ classes.riskList }>
                                <Draggable type="appropriate" data="1"><li>US 10-yr Treasury Bond</li></Draggable>
                                <Draggable type="appropriate" data="2"><li>US Equities Mutual Fund</li></Draggable>
                                <Draggable type="appropriate" data="3"><li>Under Armour Stock</li></Draggable>
                                <Draggable type="appropriate" data="4"><li>High-Yield Savings Account</li></Draggable>
                                <Draggable type="appropriate" data="5"><li>Hedge Fund</li></Draggable>
                              </ul>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    {questions[7].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>

                          <Grid sm={ 12 }>
                            <Typography variant="h3">Understanding risk when investing</Typography>
                            <Grid container>
                              <Grid sm={ 6 }>
                                <div>
                                  <p>
                                    {' '}
                                    <span className={ classes.spanColor }>A good investing guideline is the higher the risk, the higher the potential reward, and the less likely to achieve that reward.</span>
                                    {' '}
                                  </p>
                                  <p>For example, buying individual stocks is a risky investment. In any given year, stocks could lose 40% of their value or grow by 40%. Although that doesn&apos;t happen often, that is a risk you take by investing in individual stocks.</p>
                                  <p>All investments require taking risk so before making any investment, make sure that you can handle the risk and that the risk-to-reward makes sense.</p>
                                </div>
                              </Grid>
                              <Grid sm={ 6 }>
                                <div className={ classes.riskContainer }>

                                  { dropZoneData.map( ( data, index ) => (
                                    <Droppable
                                      className={ data.class }
                                      types={ ['appropriate'] }
                                      onDrop={ ( e ) => { onDrop( e, index ); } }
                                    >
                                      <span>{data.text}</span>
                                      <p>{data.dropText}</p>
                                    </Droppable>
                                  ) )}
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[8].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[8].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>9</span>
                      <p className={ classes.questionTitle }>Use the Stock Profit Calculator to figure out Mr. B&apos;s profit on buying Under Armour stock.</p>
                      <ul className={ classes.ListStyle }>
                        <li>Mr. B buys 100 shares of Under Armour stock for $13.15 per share with $0 buying fees</li>
                        <li>He then sells his shares two years later for $20.75 per share with $0 selling fees</li>
                        <li>Under Armour paid no dividends during this period</li>
                      </ul>
                      <p className={ classes.questionTitle }>Assuming a capital gains tax rate of 15%, what is Mr. B&apos;s profit on this investment?</p>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[8].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              $760.00
                              <input type="radio" disabled={ questions[8].answer === 'd' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[8].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              $354.00
                              <input type="radio" disabled={ questions[8].answer === 'd' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[8].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              $1,315.00
                              <input type="radio" disabled={ questions[8].answer === 'd' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[8].answer === 'd' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              $646.00
                              <input type="radio" disabled={ questions[8].answer === 'd' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 8 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[8].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">Investing and taxes</Typography>
                              <p>
                                <span className={ classes.spanColor }>Investing in individual stocks is very difficult, takes a lot of time and expertise, and is very risky.</span>
                                {' '}
                                {' '}
                                Having said that, when investing in stocks and thinking about future returns, don&apos;t forget about taxes! When you make a profit on an investment like stocks, you usually will pay 15% capital gains tax on that profit.
                              </p>
                              <p>How does that compare to other long-term investments?</p>
                            </Grid>
                            <div className={ classes.howToInvest }>
                              <Table>
                                <thead>
                                  <tr>
                                    <th />
                                    <th>Company Stock</th>
                                    <th>Interest-Paying Corporate Bond</th>
                                    <th>Mutual Funds</th>
                                    <th>Exchange-Traded Funds (ETFs)</th>
                                    <th>Alternative Investments</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Example</td>
                                    <td>Individual company like Boeing or Apple</td>
                                    <td>Individual company like Boeing or Apple bonds</td>
                                    <td>A mix of all types of investments</td>
                                    <td>A mix of all types of investments</td>
                                    <td>Commodities like gold, hedge funds, real estate</td>
                                  </tr>
                                  <tr>
                                    <td>Level of Risk</td>
                                    <td>High</td>
                                    <td>Low to medium</td>
                                    <td>Medium</td>
                                    <td>Medium</td>
                                    <td>High</td>
                                  </tr>
                                  <tr>
                                    <td>Level of Reward</td>
                                    <td>High</td>
                                    <td>Low to medium</td>
                                    <td>Medium to high</td>
                                    <td>Medium to high</td>
                                    <td>High</td>
                                  </tr>
                                  <tr>
                                    <td>Type of Reward</td>
                                    <td>Price appreciation and dividends</td>
                                    <td>Price appreciation and periodic interest payments</td>
                                    <td>Price appreciation and dividends</td>
                                    <td>Price appreciation and dividends</td>
                                    <td>Price appreciation and dividends</td>
                                  </tr>
                                  <tr>
                                    <td>Estimated Return* (Annual)</td>
                                    <td>5 - 10%</td>
                                    <td>1.5 - 7.5%</td>
                                    <td>5 - 10%</td>
                                    <td>5 - 10%</td>
                                    <td>1.5 - 2.5%</td>
                                  </tr>
                                  <tr>
                                    <td>Fees</td>
                                    <td>Low; trading fees</td>
                                    <td>Low; trading fees</td>
                                    <td>Low to medium; management fees</td>
                                    <td>Low</td>
                                    <td>Medium to high; management fees</td>
                                  </tr>
                                  <tr>
                                    <td>Liquidity</td>
                                    <td>Medium liquid</td>
                                    <td>Medium liquid</td>
                                    <td>Medium liquid</td>
                                    <td>Highly liquid</td>
                                    <td>Low to medium</td>
                                  </tr>
                                  <tr>
                                    <td>Taxes on Earnings</td>
                                    <td>
                                      Price appreciation taxed at capital gains rate (∼10-25%); Dividends are taxed as ordinary income
                                      or at capital gains rate
                                    </td>
                                    <td>
                                      Price appreciation taxed at capital gains rate (∼10-25%); Interest is taxed as ordinary income
                                      income or at capital gains rate
                                    </td>
                                    <td>
                                      Price appreciation taxed at capital gains rate (∼10-25%); Dividends are taxed as ordinary income
                                      or at capital gains rate
                                    </td>
                                    <td>
                                      Price appreciation taxed at capital gains rate (∼10-25%); Dividends are taxed as ordinary income
                                      or at capital gains rate
                                    </td>
                                    <td>
                                      Price appreciation taxed at capital gains rate (∼10-25%); Dividends are taxed as ordinary income
                                      or at capital gains rate
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[9].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>
                  <div ref={ ( section ) => { questionMainAns[9].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>10</span>
                      <p className={ classes.questionTitle }>Use the following details to calculate Mr. B&apos;s medical bills for his shoulder surgery:</p>
                      <ul className={ classes.ListStyle }>
                        <li>Mr. B&apos;s health insurance plan has a $1,000 deductible</li>
                        <li>Mr. B&apos;s health insurance plan also has a 90%/10% coinsurance rate</li>
                        <li>His shoulder surgery total costs are $10,000</li>
                      </ul>
                      <p className={ classes.questionTitle }> How much would Mr. B have to pay for his shoulder surgery? </p>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[9].answer === 'a' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              $1,900
                              <input type="radio" disabled={ questions[9].answer === 'a' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[9].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              $8,100
                              <input type="radio" disabled={ questions[9].answer === 'a' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[9].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              $1,000
                              <input type="radio" disabled={ questions[9].answer === 'a' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[9].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              $9,000
                              <input type="radio" disabled={ questions[9].answer === 'a' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 9 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[9].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">Health insurance explained</Typography>
                              <p>
                                Remember, your deductible and coinsurance are related. If you have medical bills,
                                {' '}
                                {' '}
                                <span className={ classes.spanColor }>the deductible</span>
                                {' '}
                                {' '}
                                {' '}
                                is the amount of money you would pay in a year before your health insurance provider steps in and covers your expenses.
                              </p>
                              <p>
                                After you pay your deductible for the year, the
                                {' '}
                                {' '}
                                <span className={ classes.spanColor }>coinsurance rate</span>
                                {' '}
                                {' '}
                                {' '}
                                determines how much of your additional medical bills you share with your insurance provider.
                              </p>
                              <p>When selecting a health insurance plan, pay attention to these two important terms!</p>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[10].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[10].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>11</span>
                      <p className={ classes.questionTitle }>Which of the following investments would you recommend to Mr. B if he is 25 years old and investing for his retirement, which is 35 years away.</p>
                      <ul className={ classes.ListStyle }>
                        <li>Individual stocks</li>
                        <li>Stock ETFs</li>
                        <li>Money Market account</li>
                        <li>Certificate of Deposit</li>
                      </ul>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[10].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              1 and 4
                              <input type="radio" disabled={ questions[10].answer === 'b' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[10].answer === 'b' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              1 and 2
                              <input type="radio" disabled={ questions[10].answer === 'b' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[10].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              2 and 3
                              <input type="radio" disabled={ questions[10].answer === 'b' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[10].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              2 and 4
                              <input type="radio" disabled={ questions[10].answer === 'b' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 10 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>

                    {questions[10].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">Matching investing strategies with goals</Typography>
                              <p>
                                Remember the general rule of thumb when investing is the higher
                                {' '}
                                <span className={ classes.spanColor }>the risk, the higher the potential reward.</span>
                                {' '}
                                {' '}
                                Since Mr. B is investing for the very long term or 40 years from now, he can take more risk in search of higher reward. Stocks and stock ETFs typically earn a higher rate than a certificate of deposit or money market account. But stocks are riskier.
                              </p>
                              <p>In 2007, the stock market fell by over 50%. If you invested $10,000 in the S&P 500 in the summer of 2007, by the end of February 2019, your account would have dropped to less than $5,000. Fast forward to March 2013 and your account would have grown to $10,000 again. Fast forward to 2019 and your account would have doubled to $20,000.</p>
                              <p>If you are investing for a long period of time, which would be 10 to 15 years or more, you can afford to take more risks in search of a higher reward. Over the longer term, your investments have more time to recover and grow.</p>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[11].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[11].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>12</span>
                      <p className={ classes.questionTitle }>Which of the following investments would you recommend to Mr. A if he is 55 years old and investing for his retirement, which is 5 years away.</p>
                      <ul className={ classes.ListStyle }>
                        <li>Individual stocks</li>
                        <li>Bonds</li>
                        <li>Hedge funds</li>
                        <li>Certificate of Deposit</li>
                      </ul>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[11].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              1 and 4
                              <input type="radio" disabled={ questions[11].answer === 'd' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 11 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[11].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              1 and 2
                              <input type="radio" disabled={ questions[11].answer === 'd' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 11 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[11].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              2 and 3
                              <input type="radio" disabled={ questions[11].answer === 'd' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 11 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[11].answer === 'd' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              2 and 4
                              <input type="radio" disabled={ questions[11].answer === 'd' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 11 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[11].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classNames( classes.ansBox ) }>
                          <Grid container>
                            <Grid sm={ 12 }>
                              <Typography variant="h3">Matching investing strategies with goals</Typography>
                              <p>
                                Remember the general rule of thumb when investing is the higher
                                {' '}
                                <span className={ classes.spanColor }>the risk, the higher the potential reward.</span>
                                {' '}
                                {' '}
                                Since Mr. A needs his money in the short term, his goal is to preserve his money, take less risk, and earn a lower return.
                              </p>
                              <p>Think about it for a second. If Mr. A invests in stocks and his investments drop by 20% or more, he could jeopardize his retirement. When you are investing for the short term and need to preserve your money, high-yield savings accounts, certificate of deposits, and bonds are lower risk investments that still earn a return.</p>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={ classes.progressBarNew }>
                    <div className={ classes.progressBar } id="progress-bar">
                      <p>
                        {progress}
                        % complete
                      </p>
                      <div>
                        <span style={ { width: `${progress}%` } } />
                      </div>
                    </div>
                    <div className={ `${classes.progressBar} ${classes.progressBarRight}` } id="progress-bar">
                      <div>
                        <p>
                          <NumberFormat displayType="text" thousandSeparator value={ challengePoints } />
                          /1,200 XP earned
                        </p>
                        <div>
                          <span style={ { width: `${challengePercentage}%` } } />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={ classes.whatsMyScore } id="my-score-button">
                    {exerciseStatus === 'review' ? (
                      <Link href="/pf101/dashboard">BACK TO PF101 DASHBOARD</Link>
                    ) : (
                      <Button onClick={ handleMyScore }>Finish</Button>
                    )}
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      )}

      {( popupDetails && popupDetails.popupOpen === true ) && (
        <Pf101Popups details={ popupDetails } />
      )}

      <Dialog
        open={ myScorePopup }
        onClose={ handleMyScoreClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ classes.pendinglistPopup }
      >
        <DialogContent>
          <div className={ classes.pendingNumList }>
            <h3>What&apos;s My Score?</h3>
            <p>
              Please answer all the questions and provide an importance rating to access your score. Questions remaining:
              {' '}
              {unselectedQuestions}
              .
            </p>
          </div>
        </DialogContent>
        <DialogActions className={ classes.buttonOk }>
          <Button onClick={ handleMyScoreClose } color="primary" autoFocus>
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>
      <ScrollToTop />
    </Typography>
  );
};

Pf101Challenge3.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Pf101Challenge3 );
