import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import BlueTick from '../../assets/img/pwi-existing/blue_circle_arrow.png';

const Vehicles = ( props ) => {
  const { classes, setVehicles, vehicles, handleAssestObject, handleUpdatedObject} = props;
  const update = (index, type) => {
    const inputValues = {...handleAssestObject}
    // if(vehicles[index].vehicle === true){
    //   let newArr = [...vehicles]
    //   newArr[index] = {
    //     vehicle: false,
    //     type: type,
    //     stepOne: false
    //   }
    //   setVehicles([...newArr])
    //   inputValues[`vehicle_${index+1}_type`] = type;
    // }else{
      let newArr = [...vehicles]
      newArr[index] = {
        vehicle: true,
        type: type,
        stepOne: false
      }
      setVehicles([...newArr])
      inputValues[`vehicle_${index+1}_type`] = type;
    // }
    handleUpdatedObject(inputValues)
  }
  return (
    <div className={ classes.contentBlock }>
      <path className={ classes.existingSpace }>
        Select the number of vehicles and if you own or lease that vehicle below. Include any motorcycles, boats or other vehicles.
      </path>
      <div className={ classes.retirementListBlock}>
        {
          vehicles.map((v, index) => (
            <div className={ classes.retirementListFlex}>
              <span>Vehicle{index + 1}: </span>
              <span className={ vehicles[index].type === "Own"  ? classes.activeVehicle : classes.activeDisable } onClick={ () => update(index, "Own")}>Own <em>{ vehicles[index].type === "Own" && vehicles[index].vehicle && <img src={BlueTick} alt={`v${index+1} own`}  />}</em></span>
              <span className={ vehicles[index].type === "Lease"  ? classes.activeVehicle : classes.activeDisable } onClick={ () => update(index, "Lease")}>Lease <em>{ vehicles[index].type === "Lease" && vehicles[index].vehicle && <img src={BlueTick} alt={`v${index+1} lease`} />}</em></span>
            </div>
          ))
        }
      </div>
    </div>
  );
};

Vehicles.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( Vehicles );
