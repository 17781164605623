import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import existingOne from '../../assets/img/life-event-library/existing_assets_img_1.jpg';
import existingTwo from '../../assets/img/life-event-library/existing_assets_img_2.jpg';
import existingThree from '../../assets/img/life-event-library/existing_assets_img_3.jpg';
import existingFour from '../../assets/img/life-event-library/existing_assets_img_4.jpg';

const LifeEventImageTwo = ( props ) => {
  const { classes } = props;

  const getStartYear = () => {
    props.openNoAccessPopup( true );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={ `${classes.containerNew} ${classes.eventTwoColor}` }>
      <div className={ classes.testimonilas }>
        <div className={ classes.testimonialInner }>
          <Slider { ...settings }>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Existing Assets + Liabilities
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Enter Your Details
                        </Typography>
                        <ul>
                          <li>Develop a plan to effectively manage your existing assets and liabilities</li>
                          <li>Repay federal and private student loans on time or ahead of schedule</li>
                          <li>Analyze how different monthly payments get you out of credit card debt</li>
                          <li>Account for your existing car and auto loan</li>
                          <li>Manage your existing home’s monthly expenses and mortgage repayment</li>
                          <li>See the net cash flow from selling your home and possible tax effects</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.startYear }>GET STARTED</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ existingOne } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Existing Assets + Liabilities
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Credit Cards and Vehicles
                        </Typography>
                        <ul>
                          <li>Carrying a balance on your high interest rate credit card? Build a repayment plan and make sure you maintain a positive cash flow</li>
                          <li>Enter the value of your existing car</li>
                          <li>Account for your monthly auto-related expenses such as gas, insurance and maintenance.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.startYear }>GET STARTED</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ existingTwo } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Existing Assets + Liabilities
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Student Loans
                        </Typography>
                        <ul>
                          <li>See what your monthly payment should be to make sure you repay your loan on time</li>
                          <li>Tweak your student loan repayment schedule and instantly see how much you could save</li>
                          <li>What if you refinanced your student loans and invested the annual savings? Check out the refinancing tool in the Show Me section.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.startYear }>GET STARTED</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ existingThree } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Existing Assets + Liabilities
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Are You a Homeowner?
                        </Typography>
                        <ul>
                          <li>Use Inkwiry to build a plan to manage your home finances</li>
                          <li>View your mortgage repayment schedulee</li>
                          <li>Understand how taxes come into play when you go to sell your home.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.startYear }>GET STARTED</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ existingFour } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

LifeEventImageTwo.propTypes = {
  classes: PropTypes.object.isRequired,
  openNoAccessPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( LifeEventImageTwo );
