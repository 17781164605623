import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});
HCExporting(Highcharts);

const HowBecomeMillionaire = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>How to Become a Millionaire</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div
              className={classNames(classes.pfBudgetPage, classes.blockTable)}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/be-a-millionaire.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      How to Become a Millionaire
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/be-a-millionaire-300px.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Who wants to be a millionaire? I think most people
                          would be happy to have $1 million in the bank. But
                          what does it take to become a millionaire? We&apos;re
                          not talking about winning a game show or hitting the
                          lottery. We&apos;re talking about making smart money
                          moves and saving and investing early.
                        </p>
                      </div>
                    </div>
                    <div
                      className={classNames(classes.pfRow, classes.pfBlockRow)}>
                      <div className={classes.feText}>
                        <p className={classes.noBottomMargin}>
                          We need to make some assumptions before we get
                          started. Let&apos;s assume the following:
                        </p>
                        <div className={classes.leftSpace}>
                          <p>
                            - Investments made in this hypothetical example earn
                            7% annually over time
                          </p>

                          <p>
                            - Investments are made at the end of the month each
                            month
                          </p>

                          <p>
                            - Investments are made by opening a{' '}
                            <Link
                              to="/finance-explained/retirement-accounts"
                              target="_blank">
                              Roth IRA
                            </Link>
                          </p>

                          <p>- Planned retirement date is 67 years old.</p>
                        </div>
                        <p>
                          Remember, a Roth IRA is a retirement account with a
                          great tax benefit. You invest in a Roth IRA with
                          after-tax dollars (you already paid income taxes on
                          this money). When you go to withdraw from your Roth
                          IRA in retirement, you do not pay taxes on that money.
                          Stop and think about that for a second. Your
                          investments grow tax free! That could be 40 years of
                          earnings tax free!
                        </p>
                        <p>
                          If you invest $250 per month into your Roth IRA, how
                          much money will you have at retirement, assuming you
                          keep this up until age 67? Your Roth IRA would grow to
                          $1,019,789 and all of that money would be tax free.
                        </p>
                        <p>
                          This amounts to saving a little more than $8 per day.
                          What happens if you save and invest more each month?
                          What happens if you invest the maximum $500 per month?
                          Can you believe you would have more than $2 million?
                          There&apos;s a{' '}
                          <a
                            href="https://www.irs.gov/retirement-plans/retirement-plans-faqs-regarding-iras-contributions#:~:text=The%20annual%20contribution%20limit%20for,your%20filing%20status%20and%20income"
                            target="_blank"
                            rel="noopener noreferrer">
                            $6,000 limit on Roth IRA contributions each year
                          </a>{' '}
                          if you&apos;re under 50 years old.
                        </p>

                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            chart: {
                              type: 'spline',
                              scrollablePlotArea: {
                                scrollPositionX: 0,
                              },
                              marginTop: 40,
                            },
                            navigation: {
                              menuItemStyle: {
                                fontSize: '14px',
                                textAlign: 'left',
                              },
                              menuItemHoverStyle: {
                                background: '#f5f5f5',
                                color: '#4c4c4c',
                                fontSize: '14px',
                              },
                              buttonOptions: {
                                height: 40,
                                width: 48,
                                symbolSize: 24,
                                symbolX: 24,
                                symbolY: 21,
                                symbolStrokeWidth: 2,
                                verticalAlign: 'bottom',
                                _titleKey: 'y',
                              },
                            },
                            exporting: {
                              buttons: {
                                contextButton: {
                                  menuItems: [
                                    {
                                      textKey: 'downloadPNG',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart();
                                      },
                                    },
                                    {
                                      textKey: 'downloadJPEG',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart({
                                          type: 'image/jpeg',
                                        });
                                      },
                                    },
                                    {
                                      textKey: 'downloadPDF',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart({
                                          type: 'application/pdf',
                                        });
                                      },
                                    },
                                    {
                                      textKey: 'downloadSVG',
                                      onclick() {
                                        const chart = this;
                                        chart.exportChart({
                                          type: 'image/svg+xml',
                                        });
                                      },
                                    },
                                  ],
                                },
                              },
                            },
                            credits: {
                              enabled: false,
                            },
                            title: {
                              text: 'How to Become a Millionaire',
                            },
                            xAxis: [
                              {
                                categories: [
                                  21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
                                  32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
                                  43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
                                  54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64,
                                  65, 66, 67,
                                ],
                                labels: {
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                              },
                            ],
                            yAxis: [
                              {
                                // Primary yAxis
                                labels: {
                                  formatter() {
                                    const chart = this;
                                    if (chart.value < 0) {
                                      return `<span style="color:#bf0000;">(${formatDollar(
                                        -Math.round(chart.value),
                                      )})</span>`;
                                    }
                                    return formatDollar(
                                      Math.round(chart.value),
                                    );
                                  },
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                                title: {
                                  text: '',
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                              },
                            ],
                            tooltip: {
                              headerFormat:
                                '<span style="font-size:14px">{point.key}</span><br/>',
                              crosshairs: true,
                              shared: true,
                              valueDecimals: 0,
                              valuePrefix: '$',
                              style: {
                                color: '#000000',
                                fontSize: '13px',
                              },
                            },
                            plotOptions: {
                              spline: {
                                lineWidth: 2,
                                states: {
                                  hover: {
                                    lineWidth: 2,
                                  },
                                },
                                marker: {
                                  enabled: false,
                                },
                              },
                            },
                            legend: {
                              itemStyle: {
                                fontSize: '14px',
                              },
                            },
                            series: [
                              {
                                name: '$250 Monthly',
                                data: [
                                  0, 3098, 6420, 9983, 13802, 17898, 22290,
                                  27000, 32050, 37465, 43271, 49497, 56174,
                                  63333, 71009, 79241, 88067, 97532, 107680,
                                  118563, 130232, 142744, 156161, 170548,
                                  185976, 202518, 220256, 239277, 259672,
                                  281542, 304993, 330139, 357103, 386016,
                                  417019, 450264, 485911, 524136, 565124,
                                  609075, 656203, 706738, 760927, 819032,
                                  881338, 948149, 1019789,
                                ],
                                color: '#ffc000',
                              },
                              {
                                name: '$375 Monthly',
                                data: [
                                  0, 4647, 9630, 14974, 20703, 26847, 33435,
                                  40500, 48075, 56197, 64907, 74246, 84261,
                                  94999, 106514, 118861, 132101, 146297, 161520,
                                  177844, 195347, 214116, 234242, 255823,
                                  278963, 303777, 330384, 358915, 389508,
                                  422313, 457489, 495208, 535654, 579024,
                                  625529, 675395, 728867, 786204, 847686,
                                  913613, 984305, 1060108, 1141390, 1228549,
                                  1322008, 1422223, 1529683,
                                ],
                                color: '#0070c0',
                              },
                              {
                                name: '$500 Monthly',
                                data: [
                                  0, 6196, 12841, 19965, 27605, 35796, 44580,
                                  53999, 64099, 74929, 86542, 98995, 112347,
                                  126665, 142018, 158481, 176134, 195063,
                                  215361, 237125, 260463, 285489, 312323,
                                  341097, 371951, 405036, 440512, 478553,
                                  519344, 563084, 609985, 660278, 714206,
                                  772032, 834038, 900527, 971823, 1048272,
                                  1130248, 1218150, 1312407, 1413477, 1521854,
                                  1638065, 1762677, 1896297, 2039577,
                                ],
                                color: '#70ad47',
                              },
                            ],
                            responsive: {
                              rules: [
                                {
                                  condition: {
                                    maxWidth: 500,
                                  },
                                  chartOptions: {
                                    legend: {
                                      align: 'center',
                                      verticalAlign: 'bottom',
                                      layout: 'horizontal',
                                    },
                                  },
                                },
                              ],
                            },
                          }}
                        />

                        <p>
                          Now you know how to become a millionaire. The next
                          time you think about buying lottery tickets, invest in
                          your Roth IRA instead and watch your balance grow over
                          time.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
HowBecomeMillionaire.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HowBecomeMillionaire);
