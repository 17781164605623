import TickMark from '../../assets/img/pwi-fi/tick_mark_blue.png';
import oneAfter from '../../assets/img/questions/1-after.png';
import oneBefore from '../../assets/img/questions/1-before.png';
import threeBefore from '../../assets/img/questions/3-before.png';
import threeAfter from '../../assets/img/questions/3-after.png';
import fourBefore from '../../assets/img/questions/4-before.png';
import fourAfter from '../../assets/img/questions/4-after.png';
import fiveBefore from '../../assets/img/questions/5-before.png';
import fiveAfter from '../../assets/img/questions/5-after.png';
import sixBefore from '../../assets/img/questions/6-before.png';
import sixAfter from '../../assets/img/questions/6-after.png';
import sevenBefore from '../../assets/img/questions/7-before.png';
import sevenAfter from '../../assets/img/questions/7-after.png';
import eightBefore from '../../assets/img/questions/8-before.png';
import eightAfter from '../../assets/img/questions/8-after.png';
import nineBefore from '../../assets/img/questions/9-before.png';
import nineAfter from '../../assets/img/questions/9-after.png';
import tenBefore from '../../assets/img/questions/10-before.png';
import tenAfter from '../../assets/img/questions/10-after.png';
import FilterIcon from '../../assets/img/pwi-he/filter-edit.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1140px',
    padding: '0 15px',
    margin: '0px auto',
    '& td i': {
      color: '#000',
    },
  },
  fiHeader: {
    position: 'relative',
  },
  menuIcon: {
    width: '30px',
    lineHeight: 'inherit',
    cursor: 'pointer',
    '& span': {
      height: '4px',
      width: '30px',
      display: 'block',
      background: '#0069aa',
      marginBottom: '4px',
      borderRadius: '5px',
    },
  },
  fiHeaderInner: {
    display: 'flex',
    marginTop: '25px',
    marginBottom: '0',
    padding: '10px 2px',
    alignItems: 'center',
    borderBottom: '4px solid #0069aa',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      margin: '0',
      fontFamily: 'MuseoSans-300',
      fontSize: '27px',
      fontWeight: 'normal',
      marginLeft: '29px',
      lineHeight: '1.1',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      maxWidth: '500px',
      width: '100%',
      [theme.breakpoints.down( 'xs' )]: {
        marginBottom: '10px',
       },
      '& > img': {
        height: '50px',
        cursor: 'pointer',
        [theme.breakpoints.down( 'xs' )]: {
          height: '30px',
        },
      },
    },
    '& > img': {
      margin: '0 0 0 auto',
      maxWidth: '140px',
      height: '38px',
    },
  },

  fiContent: {
    maxWidth: '950px',
    margin: '0 auto',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0 15px',
    },
  },
  fiContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    position: 'relative',
    paddingBottom: '1px',
    '@media (max-width: 767px)': {
      marginTop: '5px',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '25px',
      color: '#0069aa',
    },
    '& i': {
      fontSize: '40px',
    },
    '& h3': {
      margin: '0 0 0 15px',
      fontWeight: 'normal',
      fontSize: '26px',
      lineHeight: '1.1',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
        lineHeight: '24px',   
       },
    },
    '& figure': {
      margin: '0 0 0 auto',
      padding: '7px 10px',
      transiction: 'all ease-in-out 0.3s',
      '&:hover': {
        background: '#333',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      '& img': {
        maxWidth: '30px',
        height: 'auto',
        display: 'flex',
      },
    },

  },
  ProgressBar: {
    width: '100%',
    height: '5px',
    backgroundColor: '#d5d5d5',
    margin: '10px auto 20px',
    position: 'relative',
    '@media (max-width: 767px)': {
      margin: '5px auto 10px',
    },
  },
  ProgressBarInner: {
    width: '25%',
    backgroundColor: '#337ab7',
    height: '5px',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right,#337ab7,#003b69)',
    '&::before': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '1.5px solid transparent',
      borderLeft: '2px solid #fbfdfd',
      content: "''",
      position: 'absolute',
    },
    '&::after': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '2.5px solid transparent',
      borderLeft: '2px solid #003b69',
      content: "''",
      position: 'absolute',
      right: '0',
      marginRight: '-2px',
    },
  },
  contentBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '35px',
    marginTop: '15px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
    },
    '& p': {
      margin: '0 0 20px 0',
      lineHeight: '30px',
      color: '#333',
      '@media (max-width: 767px)': {
        lineHeight: '24px',
        margin: '0 0 10px 0',
      },
    },
    '& a': {
      color: '#337ab7',
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
      },
    },
    '& ul': {
      listStyle: 'none',
      [theme.breakpoints.down( 'xs' )]: {
        paddingLeft: '0px',   
      },
      '& li': {
        backgroundImage: `url(${TickMark})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '11px',
        backgroundPosition: 'left top 8px',
        display: 'flex',
        paddingLeft: '35px',
        alignItems: 'center',
        '@media (max-width: 1024px)': {
          flexWrap: 'wrap',
          display: 'inline-block',
          lineHeight: '22px',
        },
        '@media (max-width: 767px)': {
          flexWrap: 'wrap',
          display: 'inline-block',
          lineHeight: '22px',
        },
      },
      '& select': {
        background: '#fbfdfd',
        border: 'none',
        outline: '0',
        borderBottom: '2px solid #337ab7',
        color: '#337ab7',
        textAlign: 'center',
        padding: '0 20px',
        height: '29px',
        cursor: 'pointer',
        fontWeight: 'normal',
        fontFamily: 'MuseoSans-300',
        fontSize: '21px',
        minWidth: '120px',
        '@media (max-width: 767px)': {
          fontSize: '16px',
          padding: '0 10px',
          minWidth: '90px',
        },
      },
    },

    '& i': {
      color: '#1674b1',
      display: 'block',
    },
    '& font': {
      color: '#1674b1',
    },
  },

  italicNormal:{
    color: '#333 !important',
    display: 'initial !important',
  },
  contentMainBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '35px',
    marginTop: '15px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
    },
    '& p': {
      margin: '0 0 20px 0',
      lineHeight: '30px',
      color: '#333',
      '@media (max-width: 767px)': {
        margin: '0 0 10px 0',
        lineHeight: '24px',
        fontSize: '16px',
      }
    },
    '& a': {
      color: '#337ab7',
      textDecoration: 'underline',
      '@media (max-width: 767px)': {
        margin: '0 0 10px 0',
        lineHeight: '24px',
        fontSize: '16px',
      },
      '&:hover': {
        color: '#f89b23',
      },
    },
    '& i': {
      color: '#1674b1',
      display: 'block',
    },
    '& font': {
      color: '#1674b1',
    },
  },
  btnGroup: {
    display: 'flex',
    marginTop: '40px',
    paddingBottom: '10px',
    position: 'relative',
    '& button': {
      borderRadius: '3px',
      padding: '5px 20px',
      textTransform: 'uppercase',
      fontSize: '18px',
      [theme.breakpoints.down( 'xs' )]: {
        padding: '5px 15px',
        fontSize: '16px',
      },
      '&:last-child': {
        backgroundColor: '#0069aa',
        border: '2px solid #0069aa',
        color: '#fff',
        margin: '0 0 0 auto',
        '&:disabled': {
          color: '#333',
          backgroundColor: '#d5d5d5',
          border: '2px solid #d5d5d5',
          opacity: '0.8',
          cursor: 'no-drop',
          pointerEvents: 'all',
          '&:hover': {
            backgroundColor: '#fff',
          },
        },

      },
    },
  },
  next: {
    backgroundColor: '#0069aa',
    border: '2px solid #0069aa',
    color: '#fff',
    margin: '0 0 0 auto',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#0069aa',
    },
  },
  cancel: {
    border: '2px solid #d5d5d5',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#d5d5d5',
    },
  },
  fixedMenu: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'none',
  },
  fixedMenuDisplay: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    }
  },
  fixedMenuDisplayMain: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    },
  },
  fixedMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '20px',
      display: 'flex',
      marginLeft: '8px',
      cursor: 'pointer',
    },
    '& i': {
      margin: '0 0 0 auto',
      fontSize: '22px',
      color: '#000',
      cursor: 'pointer',
      padding: '10px',
    },
  },
  powerUpLogo: {
    width: '30%',
    margin: '0 auto',
    '& img': {
      width: '100%',
    },
  },
  planWithInkwiry: {
    '& h1': {
      marginTop: '0',
      marginBottom: '10px',
      fontSize: '28px',
      textAlign: 'center',
      fontWeight: '300',
      color: '#0069aa',
    },
  },
  powerUpCnt: {
    padding: '0 .5vh 0 4.9vh',
    '& h4': {
      display: 'flex',
      alignItems: 'center',
      margin: '0',
      color: '#333',
      marginTop: '25px',
      marginBottom: '15px',
      fontWeight: '300',
      fontSize: '18px',
      '& figure': {
        margin: '0 8px 0 0',
        '& img': {
          width: '30px',
          height: '30px',
          display: 'flex',
        },
      },
    },
    '& ul': {
      padding: '0.7vh 0 0vh 35px',
      listStyle: 'none',
      position: 'relative',
      '& li': {
        padding: ' 0 0 .7vh',
        fontSize: '2.08vh',
        lineHeight: '3vh',
        display: 'flex',
        cursor: 'pointer',
        color: 'gray',
        flexWrap: 'wrap',
        '@media (max-width: 3500px)': {
          fontSize: '1.58vh',
          lineHeight: '2.3vh',
        },
        '@media (max-width: 1400px)': {
          fontSize: '2.08vh',
          lineHeight: '3vh',
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
          fontSize: '12px',
          lineHeight: '16px',
        },
        '& ul': {
          width: '100%',
        },
        '&:hover': {
          color: '#eca041',
        },
        '& span': {
          marginRight: '10px',
        },
      },
    },
  },
  numberColor: {
    color: '#0069aa',
  },
  footerMenuFixed: {
    display: 'block',
    background: '#0069aa',
    position: 'absolute',
    width: 'calc(100% - 3vh)',
    bottom: '0',
    left: '0',
    padding: '1vh 1.5vh',
    textAlign: 'right',
    margin: '0',
    '& ul': {
      display: 'flex',
      padding: '0',
      margin: '0',
      justifyContent: 'center',
      '& li': {
        padding: '5px 6px',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        position: 'relative',
        '&:hover': {
          background: '#0d97ec',
        },
        '& img': {
          width: '3.8vh',
          display: 'block',
          verticalAlign: 'middle',
          '@media (max-width: 3500px)': {
            width: '2.6vh',
          },
          '@media (max-width: 1400px)': {
            width: '3.8vh',
          },
        },
      },
    },
  },
  AnnualIncomeGrowth: {
    [theme.breakpoints.down( 'xs' )]: {
      position: 'relative',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '18px',
      marginBottom: '0',
      marginTop: '15px',
      [theme.breakpoints.down( 'xs' )]: {
        display: 'block',
      },
      '& > span': {
        minWidth: '190px',
        maxWidth: '190px',
        fontSize: '20px',
        lineHeight: '25px',
        '@media (max-width: 767px)': {
          fontSize: '16px',
        },
      },
      '& p': {
        margin: '0',
        minWidth: '210px',
        maxWidth: '210px',
        '@media (max-width: 767px)': {
          minWidth: '100%',
          maxWidth: '100%',
        },
        '& span': {
          borderBottom: '1px dashed #0069aa',
          fontSize: '20px',
          display: 'initial',
          '@media (max-width: 767px)': {
            fontSize: '16px',
            maxWidth: '100%',
          },
        },
      },
      '& input': {
        width: '80px',
        border: 'none',
        borderBottom: '1px solid #0069aa',
        textAlign: 'right',
        margin: '0 0 0 auto',
        outline: 'none',
        fontSize: '18px',
      },
    },

  },
  middleAnnual: {
    width: 'calc(100% - 380px)',
    marginTop: '30px',
    '& img': {
      marginLeft: '25px',
      width: 'calc(100% - 25px)',
    },
  },
  grayBox: {
    background: '#f5f5f5',
    padding: '30px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    marginTop: '40px',
    marginBottom: '30px',
    position: 'relative',
    maxWidth: '100%',
    '& p': {
      margin: '0',
    },
  },
  tabButtons: {
    paddingTop: '50px',
    '& button': {
      borderRadius: '15px 15px 0 0',
      border: '1px solid #b7b7b7',
      borderBottom: 'none',
      marginRight: '10px',
      color: '#888',
      textTransform: 'inherit',
      minHeight: 'inherit',
      padding: '8px 25px',
      minWidth: '180px',
      fontSize: '16px',
      [theme.breakpoints.down( 'xs' )]: {
        minWidth: '150px',
      },
    },
    '& > div > div': {
      borderBottom: '1px solid #000',
      display: 'inherit',
      '@media (max-width: 767px)': {
        overflowX: 'auto',
        overflowY: 'hidden',
      }
    },
    '& > div > div + span': {
      display: 'none',
    },

  },
  tabsContent: {
    '& img': {
      maxWidth: '100%',
    },
  },
  graphInflection: {
    '& img': {
      maxWidth: '100%',
    },
  },
  dollarIcon: {
    margin: '0 25px 0 auto',
    color: '#666',
    fontSize: '18px',
    '& input': {
      color: '#666',
    },
  },
  cashFlowChart: {
    marginTop: '35px',
    marginBottom: '15px',
  },
  tooltipInfoTop: {
    maxWidth: '200px',
    width: 'auto',
    background: '#0069aa !important',
    fontSize: '14px !important',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },
    '@media (max-width: 399px)': { 
      maxWidth: '130px',
    }
  },
  activeTab: {
    position: 'relative',
    top: '1px',
    background: '#fff',
    borderColor: '#000 !important',
  },
  activeMenu: {
    color: '#000 !important',
  },
  myPlanYear: {
    marginTop: '45px',
    marginBottom: '40px',
  },
  AnnualIncomeGrowthMain: {
    marginBottom: '50px',
    '& > div': {
      height: '60px',
      '& p': {
        lineHeight: '27px',
      },
    },
  },
  incomeGrowth: {
    marginTop: '10px',
  },
  annuvalIncomeGrowthGraph: {
    width: '50%',
    margin: '10px 25px 0',
    [theme.breakpoints.down( 'xs' )]: {
      width: 'calc(100% - 30px)',
      margin: '10px 15px 0',
    },
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
      // color: '#0069aa',
      minHeight: '4px',
    },
  },
  annuvalIncomeGraph: {
    width: '100%',
    margin: '8px 25px 0 15px',
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
      // color: '#0069aa',
      minHeight: '4px',
    },
  },
  mainBlock: {
    width: '76%',
    position: 'relative',
    '@media (max-width: 767px)': {
      minWidth: '600px'
    },
    '&::before': {
      left: 'auto',
      right: '125px',
      width: '4px',
      height: '100%',
      content: "''",
      position: 'absolute',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
    },
  },
  annuvalIncomeGrowthInput: {
    width: '100px',
    margin: '0 25px 0 15px',
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      margin: '60px 0px 0 0px',
    },
    '& > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },
  MainMenuNew: {
    maxHeight: 'calc(100vh - 210px)',
    overflow: 'auto',
    marginTop: '20px',
    '& ul': {
      padding: '0 26px',
      listStyle: 'none',
      margin: '0',
      '&  div': {
        '& li': {
          padding: '0 10px',
        },
      },
      '& li': {
        marginBottom: '10px',
        '& a': {
          display: 'flex',
          alignItems: 'center',
          color: '#333',
          cursor: 'pointer',
          transiction: 'all 0.3s',
        },
        '& span': {
          paddingLeft: '10px',
          display: 'inline-block',
          width: '100%',
        },
        '& figure': {
          margin: '0',
          '& img': {
            width: '30px',
            height: '30px',
            display: 'flex',
          },
        },
        '&:hover': {
          '& a': {
            color: 'orange',
            textDecoration: 'none',
          },
        },
      },
    },
  },
  menuBoxNew: {
    background: '#f2f6ed',
    padding: '0 0 2px',
    borderRadius: '15px',
    margin: '5px 0',
    '& h5': {
      background: '#84a84d',
      borderRadius: '15px',
      color: '#fff',
      padding: '5px 10px',
      margin: '0 0 5px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontSize: '14px',
    },

  },
  menuBoxNewBg: {
    background: '#e5f0f6',
  },
  fourIconsBlock: {
    '& ul': {
      padding: '0',
      listStyle: 'none',
      width: '50%',
      margin: '40px auto',
      '@media (max-width: 1024px)': {
        width: '100%',
        maxWidth: '500px'
      },
      '@media (max-width: 767px)': {
        width: '100%',
      },
      '& li': {
        width: 'calc(50% - 56px)',
        display: 'inline-block',
        background: 'transparent',
        padding: '12px 10px 10px',
        textAlign: 'center',
        border: '3px solid #d5d5d5',
        borderRadius: '25px',
        margin: '8px 15px',
        fontSize: '18px',
        fontFamily: 'MuseoSans-500',
        cursor: 'pointer',
        '@media (max-width: 767px)': {
          width: '100%',
          boxSizing: 'border-box',
          margin: '8px 0',
        },
        '& h4': {
          fontSize: '30px',
          fontWeight: 'normal',
          color: '#000',
          margin: '0 0 20px',

        },
        '& h2': {
          color: '#333',
          fontWeight: 'normal',
          fontSize: '25px',
          lineHeight: '30px',
        },
        '&:hover': {
          background: '#d5d5d5',
        },
        '&:first-child': {
          color: '#84a84d',
        },
        '&:nth-child(2)': {
          color: '#1f96e0',
        },
        '&:nth-child(3)': {
          color: '#0069aa',
        },
        '&:nth-child(4)': {
          color: '#0069aa',
        },
        '& figure': {
          margin: '0',
        },
      },
    },
  },
  careerSketchTimeline: {
    textAlign: 'center',
    padding: '0 50px',
    '& h3': {
      textAlign: 'left',
      color: '#0069aa',
      fontWeight: 'normal',
      fontSize: '26px',
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginLeft: '5px',
      },
    },
    '& ul': {
      listStyle: 'none',
      padding: '0',
      '& li': {
        background: 'transparent',
        padding: '0',
        display: 'inline-block',
        margin: '5px',
        '& img': {
          maxWidth: '45px',
        },
      },
    },
  },
  clearBtn: {
    margin: '0 15px 0px 20px',
    padding: '1px 6px !important',
    minWidth: 'auto',
    borderRadius: '0px !important',
    '&:hover': {
      background: '#0d97ec',
    },
    '& img': {
      maxWidth: '30px',
    },
  },
  responsiveTable: {
    fontSize: '14px',
    '& table': {
      width: '100%',
      borderCollapse: 'collapse',
      '& tr': {
        borderBottom: '1px solid #ccc',
        '&:last-child': {
          border: 'none',
        },
        '& td': {
          padding: '5px',
        },
        '& th': {
          padding: '5px',
        },
      },
    },
    '& thead': {
      textAlign: 'left',
    },
  },
  pagination: {
    textAlign: 'center',
    '& ul': {
      listStyle: 'none',
      padding: '0',
      fontSize: '14px',
      '& li': {
        display: 'inline-block',
        background: 'none',
        padding: '0',
        cursor: 'pointer',
        '& a': {
          position: 'relative',
          float: 'left',
          padding: '6px 12px',
          marginLeft: '-1px',
          lineHeight: '1.42857143',
          color: '#337ab7',
          textDecoration: 'none',
          backgroundColor: '#fff',
          border: '1px solid #ddd',
          '@media (max-width: 767px)': {
            fontSize: '12px',
            padding: '4px 8px',
          },
          '&:hover': {
            textDecoration: 'underline',
            color: '#337ab7',
          },
        },
      },
    },
  },
  activePagination: {
    '& a': {
      background: '#337ab7 !important',
      color: '#fff !important',
      border: '1px solid #337ab7 !important',
    },

  },
  bottomText: {
    color: '#0069aa',
    fontSize: '16px',
    borderTop: '1px solid #ccc',
    padding: '30px 0',
    '& a': {
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
      },
    },
    '& p': {
      margin: '0px 0px 10px 0px',
    },
  },
  searchBlock: {
    fontSize: '14px',
    '& font': {
      fontFamily: 'MuseoSans-700',
      paddingRight: '15px',
    },
    '& input': {
      outline: '0',
      borderRadius: '25px',
      border: '1px solid #d5d5d5 !important',
      borderRight: 'none',
      padding: '0 15px',
      marginLeft: '10px',
      fontSize: '16px',
      height: '35px',
      width: '50%',
    },
  },
  formGroup: {
    display: 'flex',
    alignItems: 'center',
    '& i': {
      position: 'relative',
      left: '-30px',
      color: '#000',
    },
  },
  responsiveTableNew: {
    fontSize: '16px',
    '& table': {
      width: '100%',
      border: 'none',
      '& td': {
        vertcalAlign: 'middle',
        padding: '5px',
        '& img': {
          display: 'flex',
          margin: ' 0 auto',
        },
        '&:first-child': {
          width: '50px',
          textAlign: 'center',
        },
        '&:nth-child(2)': {
          width: '25%',

        },
      },
    },
    '& tr:nth-child(even)': {
      background: '#f2f2f2',
    },
  },
  amountDollar: {
    display: 'flex',
    alignItems: 'center',
    width: '110px',
    '& div':{
      '&:before':{
        display: 'none',
      },
      '&:after':{
        display: 'none',
      }
    },
    '& input': {
      border: 'none',
      borderBottom: '1px solid #5686da',
      fontSize: '20px',
      display: 'inline-block',
      width: '85px',
      textAlign: 'right',
      background: 'transparent',
      fontFamily: 'MuseoSans-300',
      outline: 'none',
      '&:focus':{
        borderBottom: '2px solid #f89b22',
      }
    },
  },
  higherEducationStepFour: {
    '& table': {
      width: '100%',
      maxWidth: '580px',
      margin: '50px auto 20px',
      fontSize: '20px',
      borderCollapse: 'collapse',
      lineHeight: '1.3',
      '& tr': {
        backgroundColor: '#f2f2f2',
        borderBottom: '1px solid #fff',
      },
      '& td': {
        padding: '8px',
        '&:last-child':{
          minWidth: '120px',
        },
        '& strong':{
          fontFamily: 'MuseoSans-500', 
          fontWeight: 'normal',
        }
      },
    },
  },
  trHead: {
    background: '#ccc',
    backgroundColor: '#dae3f3 !important',
    borderBottom: '3px solid #fff  !important',
    fontFamily: 'MuseoSans-500',
  },
  trFoot: {
    background: '#ccc',
    backgroundColor: '#dae3f3 !important',
    borderTop: '2px solid #5686da',
    fontFamily: 'MuseoSans-500',
    fontWeight: 'normal',
  },
  amountDollarRight: {
    textAlign: 'right',
    '& b':{
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal', 
    }
  },
  amountDollarCenter:{
    textAlign: 'center',
    '& b':{
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal', 
    } 
  },
  grayBoxHigher: {
    maxWidth: '80%',
    '@media (max-width: 767px)': {
      width: '100%',
    },
    '& p': {
      fontSize: '20px',
    },
    marginTop: '25px',
    '& li': {
      marginBottom: '20px',
      '& img': {
        display: 'inline',
        marginLeft: '10px',
      },
    },
  },
  InfoIconNew: {
    display: 'flex',
    marginRight: '0 !important',
    maxWidth: '20px',
    cursor: 'pointer',
  },
  amountDollarNew: {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    margin: '0 0 0 auto',
    '& input': {
      border: 'none',
      borderBottom: '1px solid #5686da',
      fontSize: '16px',
      display: 'inline-block',
      width: '85px',
      textAlign: 'right',
      background: 'transparent',
      fontFamily: 'MuseoSans-300',
      outline: 'none',
    },
  },
  higherEducationStepSix: {
    '& table': {
      maxWidth: '695px',
      color: '#000',
      marginTop: '30px',
      marginBottom: '30px',
      '& thead': {
        background: '#ccc',
        backgroundColor: '#dae3f3 !important',
        borderBottom: '3px solid #fff  !important',
        fontFamily: 'MuseoSans-500',

        '& th': {
          padding: '8px',
          textAlign: 'left',
          fontWeight: 'normal',
          fontSize: '20px',
          '@media (max-width: 767px)': {
            fontSize: '16px',
          },
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
      '& tr': {
        borderBottom: '2px solid #fff',
      },
      '& td': {
        fontSize: '20px',
        '@media (max-width: 767px)': {
          fontSize: '16px',
        },
        '&:last-child': {
          textAlign: 'right',
        },
        '& font': {
          display: 'inherit',
          borderBottom: '1px dashed #0069aa',
          color: '#333',
          lineHeight: '20px',
        },

      },
    },
  },
  trFootNew: {
    background: '#ccc',
    backgroundColor: '#dae3f3 !important',
    borderBottom: '2px solid #5686da !important',
    fontFamily: 'MuseoSans-700',
  },
  trFootNewBorder: {
    background: '#ccc',
    backgroundColor: '#dae3f3 !important',
    borderBottom: '2px solid #fff !important',
    fontFamily: 'MuseoSans-700',
  },
  graphRight: {
    padding: ' 0 50px',
  },
  inputGroup: {
    position: 'relative',
    display: 'table',
    borderCollapse: 'separate',
    '& span': {
      padding: '6px 6px',
      backgroundColor: 'white',
      boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
      fontSize: '20px',
      paddingRight: '0px',
      fontWeight: '400',
      lineHeight: '1',
      color: '#555',
      textAlign: 'center',
      width: '1%',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      display: 'table-cell',
    },
  },
  dollarSymbol: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    borderRight: '0',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  inputGroupMain: {
    marginTop: '25px',
    marginBottom: '25px',
    '& h4': {
      margin: '8px 0',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',

    },
  },
  paddingLeft: {
    paddingLeft: '50px',
  },
  giftSources: {
    margin: '30px 0',
    '& table': {
      width: '100%',
      maxWidth: '720px',
      margin: '0 auto',
      color: '#000',
      borderCollapse: 'collapse',
      '& thead': {
        background: ' #dae3f3',
        textALlign: 'center',
        borderBottom: '3px solid #fff',
        '& th': {
          padding: '8px',
          lineHeight: '26px',
          minWidth: '210px',
        },
      },
      '& tr': {
        background: '#f2f2f2',
        borderBottom: '2px solid #fff',
        '& td': {
          padding: '8px',
          textAlign: 'center',

        },

      },
    },
  },
  newFlex: {
    display: 'flex',
  },
  innnerFlowGroup: {
    display: 'flex',
    position: 'relative',
  },
  garphLeft: {
    width: '60%',
    '& h3': {
      marginBottom: '0',
    },

  },
  annuvalIncomeGrowthGraphNew: {
    marginTop: '20px',
    '& img': {
      width: '100%',
      padding: '0 25px',
    },
  },
  garphRight: {
    display: 'flex',
    margin: '0 0 0 auto',
    width: '40%',
    paddingLeft: '50px',
    '& h3': {
      marginBottom: '0',
      fontSize: '20px',
      textDecoration: 'underline',
      textAlign: 'center',
      color: '#000',
    },
    '& > div': {
      width: '50%',
      margin: '0 25px',
    },
  },
  annuvalGrowthNew: {
    '& > div': {
      margin: '0',
    },
  },
  inputRightBlock: {
    display: 'flex',
    fontSize: '16px',
    '& input': {
      width: '100%',
      border: 'none',
      fontSize: '16px',
      borderBottom: '1px solid #0069aa',
      outline: 'none',
    },
  },
  innnerFlowGroupMain: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '4px',
      height: '100%',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      right: '176px',
    },
  },
  borderDevider: {
    width: '100%',
    height: '3px',
    backgroundImage: 'linear-gradient(to right,#7f7f7f 50%,transparent 50%)',
    backgroundSize: '30px 100%',
    marginTop: '50px',
    marginBottom: '0px',
  },
  totalBoxTable: {
    fontSize: '20px',
    '& table': {
      textAlign: 'left',
      '& tr': {
        '& td': {
          textAlign: 'left',
        },
      },

    },
  },
  gridSpacingImages: {
    '& > div': {
      '& > div': {
        margin: '0 15px',
      },
    },
  },
  orangeBG: {
    background: '#f89b22 !important',
    color: '#fff',
    fontSize: '16px',
    '& td': {
      textAlign: 'center !important',
    },
  },
  fourIconsBlockNew: {
    '& ul': {
      width: '77%',
    },
  },
  careerPathBanner: {
    marginTop: '50px',
    '@media (max-width: 767px)': {
      marginTop: '15px',
    },
    '& img': {
      width: '100%',
    },
  },
  quickstartCircle: {
    padding: '0px',
    position: 'relative',
    transform: 'scale(.74)',
    left: ' -12.5%',
    [theme.breakpoints.down( 'md' )]: {
      textAlign: 'center',
      paddingTop: '140px',
      display: 'inline-block',
      width: '100%',
      transform: 'none',     
      left: '0',
    },
    '@media (max-width: 1024px)': {
      transform: 'none',
      paddingTop: '0',
      left: '0',
    },
    '@media (max-width: 767px)': {
      transform: 'none',
      paddingTop: '0',
      left: '0',
    },
    '& li': {
      display: 'inline-block',
      position: 'absolute',
      width: '232px',
      height: '232px',
      borderRadius: '100%',
      textAlign: 'center',
      border: 'none',
      transition: 'all .5s ease 0s',
      MozTransition: 'all .5s ease 0s',
      WebkitTransition: 'all .5s ease 0s',
      [theme.breakpoints.down( 'md' )]: {
        position: 'initial',
        marginBottom: '20px',
        marginLeft: '10px',
        marginRight: '10px',
        display: 'inline-grid',
      },
      '@media (max-width: 767px)': {
        width: '180px',
        height: '180px',
      },
      '& a': {
        padding: '12px 7px',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'none',
        },
        '& h3': {
          fontFamily: '"MuseoSans-900"',
          fontSize: '70px',
          lineHeight: '70px',
          margin: '0',
          fontWeight: 'normal',
          '@media (max-width: 767px)': {
            fontSize: '50px',
            lineHeight: '60px',
          },
        },
        '& h4': {
          color: theme.palette.common.white,
          fontSize: '22px',
          lineHeight: '27px',
          fontFamily: 'MuseoSans-100',
          fontWeight: 'normal',
          '@media (max-width: 767px)': {
            fontSize: '16px',
            lineHeight: '20px',
          },
        },
      },
    },
  },

  circleOne: {
    top: '20px',
    left: '0',
    zIndex: '1',
    background: '#0069aa',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#83a74c',
    },
  },
  circleOneActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(0deg)',
      zIndex: '-99999',
      left: 'auto',
      right: '90px',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: '132px',
      content: `url(${oneBefore})`,
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
    '&:after': {
      top: '149px',
      transform: 'scale(0.55)',
      width: '0',
      left: '-5px',
      height: '0',
      zIndex: '-2',
      position: 'absolute',
      content: `url(${oneAfter})`,
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
  },

  circleTwo: {
    left: '112px',
    top: '215px',
    background: 'rgba(204, 109, 82, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#cea348',
    },
  },
  circleThree: {
    left: '230px',
    top: '20px',
    zIndex: '1',
    background: 'rgba(143, 176, 94, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#006838',
    },
  },
  circleThreeActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(0deg)',
      zIndex: '-2',
      left: '0px',
      right: '90px',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: 'calc(100% - 15px)',
      content: `url(${threeBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
    '&:after': {
      top: '189px',
      transform: 'scale(0.55)',
      width: '0',
      left: '138px',
      height: '0',
      zIndex: '-2',
      position: 'absolute',
      bottom: '20px',
      content: `url(${threeAfter})`,
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
  },
  circleFour: {
    left: '342px',
    top: '220px',
    background: 'rgba(159, 184, 147, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#006838',
    },
  },
  circleFourActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(0deg)',
      zIndex: '-2',
      left: 'auto',
      height: 'auto',
      position: 'absolute',
      width: '0',
      right: '0',
      bottom: 'calc(100% - 84px)',
      content: `url(${fourBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.55)',
      width: '0',
      left: '-45px',
      height: '0',
      zIndex: '-2',
      position: 'absolute',
      bottom: '18px',
      content: `url(${fourAfter})`,
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
  },
  circleFive: {
    left: '460px',
    top: '22px',
    zIndex: '1',
    background: 'rgba(70, 158, 213, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#83a74c',
    },
  },
  circleFiveActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(0deg)',
      zIndex: '-2',
      left: 'auto',
      height: 'auto',
      position: 'absolute',
      width: '0',
      right: '10px',
      bottom: 'calc(100% - 60px)',
      content: `url(${fiveBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.55)',
      width: '0',
      left: '-175px',
      height: '0',
      zIndex: '-2',
      position: 'absolute',
      bottom: '30px',
      content: `url(${fiveAfter})`,
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
  },
  circleSix: {
    left: '572px',
    top: '222px',
    background: 'rgba(40, 168, 183, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#006aab',
    },
  },
  circleSixActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(0deg)',
      zIndex: '-2',
      left: '-330px',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: 'calc(100% - 60px)',
      content: `url(${sixBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.62)',
      width: '0',
      left: 'auto',
      right: '30px',
      height: '0',
      zIndex: '-2',
      position: 'absolute',
      bottom: '94px',
      content: `url(${sixAfter})`,
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
  },
  circleSeven: {
    left: '690px',
    top: '25px',
    zIndex: '1',
    background: 'rgba(238, 125, 72, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#bf4927',
    },
  },
  circleSevenActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(5deg)',
      zIndex: '-2',
      left: 'auto',
      right: '110px',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: 'calc(100% - -5px)',
      content: `url(${sevenBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.55)',
      zIndex: '-2',
      width: '0',
      left: '70px',
      height: '0',
      position: 'absolute',
      bottom: '40px',
      content: `url(${sevenAfter})`,
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
  },
  circleEight: {
    left: '803px',
    top: '225px',
    background: 'rgba(42, 86, 128, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#83a74c',
    },
  },
  circleEightActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(0deg)',
      zIndex: '-2',
      left: '0',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: '210px',
      content: `url(${eightBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.55)',
      zIndex: '-2',
      width: '0',
      left: '-80px',
      height: '0',
      position: 'absolute',
      bottom: '-10px',
      content: `url(${eightAfter})`,
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
  },
  circleNine: {
    left: '920px',
    top: '28px',
    zIndex: '1',
    background: 'rgba(246, 157, 67, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#f05a28',
    },
  },
  circleNineActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(0deg)',
      zIndex: '-2',
      left: '-140px',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: 'calc(100% - 15px)',
      content: `url(${nineBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.55)',
      zIndex: '-2',
      width: '0',
      left: '-170px',
      height: '0',
      position: 'absolute',
      bottom: '0px',
      content: `url(${nineAfter})`,
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
  },
  circleTen: {
    left: '1033px',
    top: '225px',
    background: 'rgba(211, 172, 90, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#bf4927',
    },
  },
  circleTenActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(0deg)',
      zIndex: '-2',
      left: '-220px',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: 'calc(100% - 15px)',
      content: `url(${tenBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.55)',
      zIndex: '-2',
      width: '0',
      left: '-260px',
      height: '0',
      position: 'absolute',
      bottom: '-7px',
      content: `url(${tenAfter})`,
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      },
    },
  },
  careerPathCircles: {
    minHeight: '400px',
    marginTop: '50px',
    '@media (max-width: 767px)': {
      marginTop: '20px',
    },
  },
  spanBlock: {
    display: 'block',
  },
  spanBold: {
    fontFamily: '"MuseoSans-700"',
    fontSize: '30px',
    lineHeight: '27px',
    '@media (max-width: 767px)': {
      fontSize: '19px',
      lineHeight: '17px',
    }
  },
  paraSlide: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '30px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  fourIconsBlockNewThree: {
    '& img': {
      width: '100%',
      maxWidth: '120px',
      display: 'flex',
      margin: '0 auto',
    },
    '& ul': {
      '& li:first-child': {
        '& span': {
          color: '#84a84d',
        },
      },
      '& li:nth-child(2)': {
        '& span': {
          color: '#f17d23',
        },
      },
    },
  },
  careerFormGroup: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    position: 'relative',
    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
    },
    '& > div': {
      width: '100%',
      '& > div': {
        width: '100%',
        '&::before': {
          display: 'none',
        },
        '&::after': {
          display: 'none',
        },
        '& > div': {
          '&::before': {
            display: 'none',
          },
          '&::after': {
            display: 'none',
          },
        },
      },
    },
    '& span': {
      display: 'inline-block',
      minWidth: '200px',
      maxWidth: '200px',
      marginRight: '5%',
      '@media (max-width: 767px)': {
        minWidth: '100%',
        maxWidth: '100%',
      },
      '& font': {
        borderBottom: '1px dashed #0069aa',
        color: '#000',
        fontSize: '20px',
        cursor: 'pointer',
        '@media (max-width: 767px)': {
          fontSize: '16px',
        }
      },
    },
    '& input': {
      outline: '0!important',
      lineHeight: '1.42857143',
      height: '36px',
      color: '#555',
      backgroundColor: '#fff',
      backgroundImage: 'none',
      border: '1px solid #ccc!important',
      borderRadius: '5px',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      width: '100%',
      padding: '0 5px',
      fontFamily: 'MuseoSans-300',
      fontSize: '20px',
      '@media (max-width: 767px)': {
        fontSize: '16px',
      },
      '&::placeholder': {
        color: '#555',
        opacity: '1',
      },
      '&:focus': {
        border: '1px solid #0069aa!important',
      },
    },
    '& select': {
      outline: '0!important',
      lineHeight: '1.42857143',
      height: '36px',
      color: '#555',
      backgroundColor: '#fff',
      backgroundImage: 'none',
      border: '1px solid #ccc!important',
      borderRadius: '4px',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      width: '100%',
      padding: '0 5px',
      fontFamily: 'MuseoSans-300',
      fontSize: '20px',
    },
    '& ul': {
      padding: '0',
      margin: '0',
      border: '1px solid #ccc',
      display: 'flex',
      borderRadius: '5px',
      overflow: 'hidden',
      '& li': {
        padding: '0px 25px',
        background: 'none',
        display: 'inline-block',
        fontSize: '21px',
        lineHeight: '29px',
        cursor: 'pointer',
        fontFamily: 'MuseoSans-300',
      },
    },
    '& strong': {
      fontWeight: 'normal',
      padding: '0 5px',
    },
    '& img': {
      fontWeight: 'normal',
      maxWidth: '20px',
      padding: '0 8px',
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
  },
  estimatedTaxes: {
    '& > div': {
      width: '18%',
    },
  },
  formCarrerMove: {
    maxWidth: '750px',
    paddingLeft: '15px',
    fontSize: '20px',
    '@media (max-width: 767px)': {
      paddingLeft: '0',
      fontSize: '16px',
    },
  },
  checkBoxGroup: {
    paddingLeft: '20px',
    '& label': {
      marginRight: '35px',
    },
    '& path': {
      color: '#0069aa',
    },
    '& span': {
      width: 'auto',
      minWidth: 'inherit',
      padding: '0',
      margin: '0',
      lineHeight: '0',
      fontSize: '18px',
      '& input': {
        width: 'auto',
        height: 'auto',
      },
    },
  },
  innnerFlowGroupMainRightBoxNone: {
    '@media (max-width: 767px)': {
      display: 'none'
    },
    '&::before': {
      display: 'none',
    },
    '& h3': {
      fontWeight: 'normal',
      marginBottom: '15px',
      [theme.breakpoints.down( 'xs' )]: {
        textAlign: 'left',
        position: 'absolute',
        bottom: '20px',
        left: '0px',
        width: '100% !important',
        marginLeft: '0px',
      }
    },
  },
  garphLeftSeventy: {
    width: '80%',
  },
  garphRightTwenty: {
    width: '20%',
  },
  tabBox: {
    '& img': {
      width: '100%',
    },
  },
  totalCost: {
    backgroundColor: '#dae3f3 !important',
    borderBottom: '2px solid #000 !important',
    '& b': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      color: '#333',
    },
  },
  potentialGraph: {
    border: '1px solid #2f75b5',
    margin: '20px 0',
    padding: '15px 10px',
    '& h3': {
      textAlign: 'center',
      fontWeight: 'normal',
      fontSize: '23px',
      margin: '0',
      borderBottom: '2px solid #2f75b5',
      paddingBottom: '10px',
      lineHeight: '25px',
      fontFamily: 'MuseoSans-500',
    },
  },
  BahanceBtn: {
    position: 'absolute',
    padding: '5px 10px',
    fontSize: '14px',
    lineHeight: '24px',
    background: '#046bab',
    borderColor: '#046bab',
    borderRadius: '3px',
    bottom: '25px',
    right: '30px',
    color: '#fff !important',
    textDecoration: 'none !important',
    '&:hover': {
      background: '#046bab',
      borderColor: '#046bab',
      color: '#fff',
    },
  },
  annuvalIncomeGrowthGraphNewPadding: {
    marginTop: '12px',
    '& p': {
      position: 'absolute',
      marginTop: '-23px !important',
    },
    '& img': {
      padding: '0',
    },
  },
  rentGraphBlock: {
    display: 'flex',
    marginBottom: '15px',
    alignItems: 'center',
    '& h3': {
      fontWeight: 'normal',
      fontSize: '18px',
    },
  },
  annualInput: {
    display: 'flex',
    paddingLeft: '5px',
    '& span': {
      textAlign: 'right',
      width: '100%',
      fontSize: '18px',
    },
    '& i': {
      color: '#333',
    },
    '& input': {
      border: 'none',
      borderBottom: '1px solid #0069aa',
      fontSize: '18px',
      textAlign: 'right',
      width: '180px',
      paddingLeft: '5px',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  livingExpencesRange: {
    width: '60%',
    '& p': {
      margin: '-19px 0 0',
    },
    '& img': {
      width: '100%',
      display: 'inline-block',
      verticalAlign: 'middle',
      marginLeft: '5px',
    },
  },
  opacityZero: {
    opacity: '0',
    '@media (max-width: 767px)': {
      display: 'none',
    }
  },
  livingExpencesMothly: {
    padding: '0 25px',
    width: '100px',
    position: 'relative',
    '&:last-child': {
      '&::before': {
        // content: '""',
        // position: 'absolute',
        // width: '4px',
        // height: 'calc(100% + 80px)',
        // background: '#046bab',
        // left: '16px',
        // top: '0',
      },

    },
    '& h3': {
      textAlign: 'center',
      fontFamily: 'MuseoSans-700',
      textDecoration: 'none',
      marginLeft: '34px',
      marginTop: '0',
      marginBottom: '67px',
      height: '30px',
      lineHeight: '30px',
      '& span': {
        borderBottom: '1px solid',
      },
    },
  },
  livingExpences: {
    display: 'flex',
    // overflow: 'hidden',
    '@media (max-width: 767px)': {
      overflow: 'auto'
    }
  },
  textRight: {
    textAlign: 'right',
    fontFamily: 'MuseoSans-500',
  },
  livingExpencesRangePlain: {
    '& p': {
      marginTop: '0',
    },
  },
  rentGraphBlockNew: {
    marginBottom: '0',
    fontSize: '18px',
  },
  rentGraphBlockItalic: {
    marginBottom: '15px',
    fontSize: '18px',
    fontStyle: 'italic',
    '& p': {
      fontFamily: 'MuseoSans-300',
    },

  },
  retirementPlanning: {
    '@media (max-width: 767px)': {
      overflowX: 'auto',
    },
    '& ul': {
      listStyle: 'inside circle',
      padding: '0',
      '@media (max-width: 767px)': {
        minWidth: '650px',
      },
      '& li': {
        background: 'none',
        padding: '0 0 0 15px',
        fontSize: '18px',
        lineHeight: '28px',
        color: '#167ec0',
        '@media (max-width: 1024px)': {
          display: 'flex',
        },
        '& span': {
          minWidth: '300px',
          color: '#000',

        },
        '&::before': {
          content: '""',
          width: '5px',
          height: '5px',
          background: '#000',
          borderRadius: '50%',
          position: 'relative',
          left: '-15px',
        },
      },
    },
  },
  planningText:{
  color:"#1674b1 !important"
  },
  unactive: {
    background: '#f2f2f2 !important',
  },
  unactiveBox: {
    background: '#fffff',
  },

  fixedMenuDisplayMainNone: {
    display: 'none',
  },
  ChooseSchoolTable: {
    '@media (max-width: 767px)': {
      overflow: 'auto',
    },
    '& table': {
      width: '100%',
      fontSize: '14px',
      marginTop: '15px',
      border: '1px solid #ddd',
      // tableLayout: 'fixed',
      borderCollapse: 'collapse',
      '@media (max-width: 1024px)': {
        minWidth: '950px'
       },
      '@media (max-width: 767px)': {
       minWidth: '780px'
      },
      '& tr': {
        borderBottom: '1px solid #ccc',
        cursor: 'pointer',
        '&:first-child': {
          cursor: 'inherit',
          '&:hover': {
            background: 'none',
          },
        },
        '&:hover': {
          background: '#f2f2f2',
        },
      },
      '& tr:first-child': {
        fontFamily: 'MuseoSans-700',
      },
      '& td': {
        verticalAlign: 'middle',
        color: '#000',
        padding: '5px 8px',
        lineHeight: '1.2',
        height: '35px',
        border: ' 1px solid #ddd',
        textAlign: 'right',
        '&:first-child': {
          width: '20%',
          textAlign: 'left',
        },
        '@media (max-width: 991px)': {
          minWidth: '100px',
        },
        '& i': {
          display: 'inline-block',
          paddingLeft: '8px',
          position: 'relative',
          color: 'transparent',
          backgroundImage: `url(${FilterIcon})`,
          cursor: 'pointer',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right 0px top 0px',
          '& ul': {
            position: 'absolute',
            padding: '0',
            background: '#e9e6e6',
            left: '-46px',
            top: '10px',
            '&::before': {
              content: "''",
              position: 'absolute',
              borderLeft: '9px solid transparent',
              borderRight: '9px solid transparent',
              width: '0',
              background: '0 0',
              borderBottom: '9px solid #e9e6e6',
              borderTop: '10px solid transparent',
              height: 'auto',
              left: '50%',
              top: '-19px',
              transform: 'translateX(-50%)',
            },
            '& li': {
              background: 'none',
              color: '#000',
              width: '80px',
              padding: '4px 20px',
              fontSize: '14px',
              lineHeight: '1.4',
              cursor: 'pointer',
              fontFamily: 'MuseoSans-300',
              margin: '0',
              '&:hover': {
                color: '#fff',
                background: '#0169aa',
              },
            },
          },
        },
      },
    },
  },
  textFieldSearch: {
    fontSize: '14px',
    fontFamily: 'MuseoSans-700',
    padding: '15px 0 0 25px',
    '@media (max-width: 529px)': {
      padding: '15px 0 0 0px',
    }
  },
  searchTextField: {
    width: '70%',
    marginLeft: '15px',
    '@media (max-width: 529px)': {
      marginLeft: '0px',
      display: 'block',
    },
    '&::before': {
      content: '"\\f002"',
      display: 'inline-block',
      font: 'normal normal normal 14px/1 FontAwesome',
      fontSize: 'inherit',
      textRendering: 'auto',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      position: 'absolute',
      right: '10px',
      top: '8px',
      color: '#4a87c0',
    },
    '& > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    '& input': {
      border: '1px solid #ccc',
      borderRadius: '15px',
      padding: '7px 15px',
    },
  },
  dottedText: {
    borderBottom: '1px dashed #0069aa',
    display: 'inline-block',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  moveDropdown: {
    top: '36px',
    zIndex: '99',
    left: '0',
    marginTop: '5px',
    width: '240px',
    maxWidth: '240px',
    lineHeight: '20px',
    position: 'absolute',
    background: '#fff',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    maxHeight: '250px',
    overflow: 'hidden',
    overflowY: 'auto',
    '& p': {
      fontSize: '20px',
      lineHeight: '25px',
      padding: '2px 5px',
      cursor: 'pointer',
      borderBottom: '1px solid #f2f2f2',
      marginBottom: '10px',
      '&:hover': {
        background: '#f5f5f5',
      },
    },
  },
  caretIcon: {
    verticalAlign: 'baseline',
    color: '#0069aa',
    margin: '0 5px 0 0',
    fontFamily: 'MuseoSans-300',
    transition: 'unset',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '2px',
    borderTop: '7px dashed',
    marginTop: '3px',
    borderRight: '7px solid transparent',
    borderLeft: '7px solid transparent',
  },
  searchList: {
    border: '1px solid #ccc',
    display: 'block',
    position: 'absolute',
    fontSize: '14px',
    background: '#fff',
    top: '38px',
    padding: '0',
    maxHeight: '200px',
    overflow: 'auto',
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)',
    zIndex: '9',
    width: '100%',
    '& ul': {
      padding: '0',
      margin: '0',
      display: 'block',
      '& li': {
        padding: '5px 15px',
        background: 'none',
        lineHeight: '21px',
        display: 'block',
        cursor: 'pointer',
        fontSize: '20px',
        fontFamily: 'MuseoSans-300',
        marginBottom: '0',
        '&:hover': {
          background: '#f2f2f2',
        },
      },
    },
  },
  SavePopup: {
    '& > div > div': {
      maxWidth: '100%',
      width: '100%',
      borderRadius: '6px',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      margin: '17px',
      border: '1px solid rgba(0,0,0,.2)',
    },
    '& > div > div > div': {
      padding: '0px !important',
    }
  },
popupClose: {
    maxWidth: 'calc(100% - 30px)',
    width: 'calc(100% - 30px)',
    borderRadius: '0px',
    borderBottom: '1px solid #e5e5e5',
    textAlign: 'right',
    padding: '8px 15px',
    '& span': {
      fontSize: '20px',
      width: '28px',
      display: 'inline-block',
      border: '1px solid',
      paddingBottom: '2px',
      textAlign: 'center',
      textShadow: '0 1px 0 #fff',
      color: '#000',
      opacity: '0.2',
      lineHeight: '22px',
      cursor: 'pointer',
      '&:hover':{
        opacity: '0.5',
      }
    },
  },
  paddingTop5: {
    paddingTop: '5px',
  },
  awarenessPlusText: {
    fontSize: '21px',
    textAlign: 'center',
    width: '63%',
    margin: '0 auto 30px !important',
    '@media (max-width: 767px)': {
      fontSize: '16px',
      textAlign: 'center',
      width: '100%',
      lineHeight: '24px',
    },
  },
  searchListMain: {
    position: 'relative',
  },
  marginBottomZero: {
    marginBottom: '0 !important',
  },
  colorBlack: {
    color: '#333 !important',
  },
  tabContentBaseIncome: {
    '& > div > div > div > div': {
      padding: '0',
    },
  },
  headSection: {
    '& td': {
      paddingRight: '3px !important',
      paddingLeft: '3px !important',
    },
  },
  MaxWidth200: {
    minWidth: '190px',
    maxWidth: '190px',
    lineHeight: '28px',
    fontSize: '20px',
    '@media (max-width: 767px)': {
      maxWidth: '100%',
    }
  },
  totalIncomeBox: {
    display: 'flex',
    fontWeight: 'normal',
    fontFamily: 'MuseoSans-500',
    marginTop: '10px',
    fontSize: '18px',
    '& span': {
      width: '74%',
      textAlign: 'right',
      '@media screen and (max-width: 1023px) and (min-width: 768px)': {
        width: '80%', 
      },
      '@media (max-width: 767px)': {
        fontSize: '14px'
      }
    },
    '& > div': {
      width: '100px',
      margin: '0 25px 0 20px',
      display: 'flex',
      justifyContent: 'flex-end',
      '@media (max-width: 767px)': {
        margin: '0 10px 0 20px',
      },
    },
  },
  borderBg: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '88px',
      height: '3px',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      right: '0',
      margin: 'auto',
      top: '-8px',
    },
  },
  annuvalIncomeTittle: {
    marginLeft: 'calc(50% + 230px)',
    [theme.breakpoints.down( 'xs' )]: {
      marginLeft: '0',
      position: 'relative',
      top: '165px',
    },
    '@media (min-width: 600px) and (max-width: 767px)': {
      marginLeft: 'calc(100% - 125px)',
    },
    '& span': {
      width: '100px',
      textAlign: 'center',
      marginLeft: '18px',
      fontSize: '18px',
      fontFamily: 'MuseoSans-700',
      display: 'inline-block',
      textDecoration: 'underline',
      [theme.breakpoints.down( 'xs' )]: {
        textAlign: 'left',
        marginLeft: '0',
        width: '100%',
      }
    },
  },
  annuvalIncomeTittleFull:{
    [theme.breakpoints.down( 'xs' )]: {
      top: '0',
      position: 'initial',
      marginLeft: 'calc(50% + 280px)',
    }
  },
  graphNewBlock: {
    position: 'relativer',
    height: '100%',
    '& img': {
      display: 'flex',
    },
  },
  garyBoxSeventy: {
    width: '70%',
    '@media (max-width: 767px)': {
      width: '100%',
    }
  },
  careerMoveDropdown: {
    position: 'relative',
  },
  dropdonIcon: {
    '& i': {
      position: 'absolute',
      top: '10px',
      right: '10px',
      color: '#666',
      zIndex: '1',
      fontSize: '16px',
    },

  },
  AnuuvalIncomeRightTiittle: {
    marginLeft: 'calc(50% + 190px)',
    fontSize: '18px',
    marginBottom: '15px',
    textAlign: 'center',
    width: '218px !important',
    fontFamily: 'MuseoSans-700 !important',
    textDecoration: 'underline',
    color: '#000',
  },
  tabButtonsNew: {
    paddingTop: '0 !important',
  },
  grayBoxRent: {
    margin: '20px 0 0 0',
    padding: '30px 30px 80px',
  },
  // pendinglistPopup: {
  //   '& > div > div': {
  //     maxWidth: '830px',
  //     '& h3': {
  //       borderBottom: '1px solid #666',
  //       margin: '0 -24px',
  //       paddingLeft: '25px',
  //       paddingBottom: '8px',
  //     },
  //     '& p': {
  //       fontSize: '18px',
  //       lineHeight: '25px',
  //     },
  //   },
  // },
  pendinglistPopup: {
    '& > div > div': {
      maxWidth: '100%',
      '& > div': {
        padding: '0 !important',
      },
      '& span':{
        top: '0px',
      }
    },
  },
  tableFourHunred: {
    '& table': {
      width: '100%',
      maxWidth: '96%',
      margin: '0 auto 20px',
      fontSize: '20px',
      borderCollapse: 'collapse',
      lineHeight: '1.3',
      '& th': {
        padding: '10px 0',
        fontWeight: 'normal',
      },
      '& tr': {
        backgroundColor: '#f2f2f2',
        borderBottom: '1px solid #fff',

      },
      '& td': {
        padding: '8px',
        paddingLeft: '20px',
        '& span': {
          '& strong': {
            fontWeight: 'normal',
            fontFamily: 'MuseoSans-500',
          },
        },
        '&:last-child': {
          textAlign: 'right',
        },
      },
    },
  },
  totalCostBorder: {
    '& td': {
      borderTop: '2px solid #000 !important',
      background: '#dae3f3',
      paddingLeft: '10px !important',
      fontFamily: 'MuseoSans-500',
    },

  },
  noDottedText: {
    border: 'none !important',
  },
  colorBlue: {
    color: '#0062aa !important',
  },
  salaryHorly: {
    '& span': {
      marginRight: '8px',
    },
  },
  incomeTaxTableNew: {
    '& table': {
      '& th': {
        '&:nth-child(2)': {
          textAlign: 'right',
        },
      },
      '& td': {
        '&:nth-child(2)': {
          textAlign: 'right',
        },
      },
    },
  },
  clearModule: {
    maxWidth: '100%',
    width: '600px',
    boxSizing: 'border-box',
    padding: '0px !important',
    boxShadow: 'none',
  },
  clearHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h2': {
      color: '#0069aa',
      fontSize: '21px',
      margin: '0px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
    },
  },
  clearFooter: {
    padding: '15px',
    borderTop: '1px solid #e5e5e5',
    textAlign: 'right',
  },
  clearContent: {
    padding: '15px',
    '& p': {
      margin: '0px',
      fontSize: '15px',
    },
  },
  buttonOne: {
    backgroundColor: '#caa336',
    color: '#fff',
    padding: '8px 14px',
    lineHeight: '17px',
    fontSize: '17px',
    textTransform: 'capitalize',
    borderRadius: '4px',
    minWidth: '64px',
    height: 'auto',
    borderWidth: '2px',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid transparent',
    borderColor: '#caa336',
    '&:hover': {
      backgroundColor: '#caa336',
      borderColor: '#caa336',
      color: '#fff',
    },
  },
  buttonTwo: {
    backgroundColor: 'transparent',
    color: '#333',
    padding: '8px 14px',
    lineHeight: '17px',
    fontSize: '17px',
    marginLeft: '5px',
    textTransform: 'capitalize',
    borderRadius: '4px',
    minWidth: '64px',
    height: 'auto',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid #ccc',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      border: '2px solid #adadad',
    },
  },
  homeMenuList: {
    position: 'relative',
    paddingLeft: '0px !important',
    maxHeight: 'calc(100vh - 280px)',
    overflow: 'auto',
    '& li': {
      position: 'relative !important',
      paddingLeft: '35px !important',
      '@media (min-width: 768px) and (max-width: 1199px)': { 
        paddingLeft: '50px !important',
      }
    },
  },
  menuCheck: {
    left: '0',
    color: 'green',
    transition: 'all .2s',
    borderRadius: '100%',
    width: '4vh',
    marginRight: '13px',
    marginLeft: '10px',
    visibility: 'visible',
    position: 'absolute',
    top: '0',
    right: 'auto',
    transform: 'rotate(-6deg)',
    '& svg': {
      fontSize: '1rem !important',
      '@media (min-width: 768px) and (max-width: 1199px)': {
        fontSize: '25px !important',
      }
    },
  },
  clickNone: {
    cursor: 'no-drop',
  },

  wagesTable: {
    '& thead': {
      '& tr': {
        '& th': {
          padding: '10px 3px',
          lineHeight: '1.4285714',
        },
      },
    },
  },
  industryTable: {
    '& tbody': {
      '& tr': {
        '& td': {
          '&:nth-child(1)': {
            width: '300px',
          },
        },
      },
    },
  },
  incomeAfterTax: {
    '@media (max-width: 767px)': {
      overflow: 'auto',
    },
    '& table': {
      '@media (max-width: 767px)': {
        fontSize: '16px',
      },
      '& tbody': {
        '& tr': {
          borderBottomWidth: '1px',
        },
      },
    },
  },
  noBorder: {
    borderBottomWidth: '0px !important',
  },
  vacationTable: {
    padding: '20px 33px 26px',
    '@media (max-width: 767px)': {
      padding: '0px',
    },
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      maxWidth: '100%',
      margin: '0 auto',
      fontSize: '20px',
      width: '100%',
      '@media (max-width: 767px)': {
        fontSize: '16px',
        minWidth: '550px'
      },
      '& tr': {
        borderTop: '1px solid #fff',
        // cursor: 'pointer',
        '& th': {
          backgroundColor: '#dae3f3',
          borderBottom: '3px solid #fff',
          fontSize: '20px',
          textAlign: 'center',
          fontWeight: '500',
          fontFamily: 'MuseoSans-500',
          padding: '8px',
          lineHeight: '1.2',
          '@media (max-width: 767px)': {
            fontSize: '16px',
          },
          '& select': {
            border: 'none',
            background: 'transparent',
            fontSize: '18px',
            outline: 'none',
          },
          '&:last-child': {
            textAlign: 'right',
            '@media (max-width: 767px)': {
              minWidth: '110px',
            }
          },
        },
        '& td': {
          padding: '8px',
          lineHeight: '1.2',
          '@media (max-width: 767px)': {
            fontSize: '16px',
          },
          '&:last-child': {
            textAlign: 'right',
          },
          '& b': {
            fontFamily: 'MuseoSans-500',
            fontWeight: 'normal',
          },
          '& i': {
            color: '#000',
          },
        },
      },
    },
  },
  budgetTable: {
    padding: '20px 36px 26px',
    color: '#333',
    '@media (max-width: 767px)': {
      padding: '0px',
      overflowX: 'auto',
    },
    '& table': {
      maxWidth: '100%',
      thead: {
        '& tr': {
          '& th': {
            '&:nth-child(3)': {
              textAlign: 'right',
            },
            '&:nth-child(4)': {
              textAlign: 'right',
            },
            '&:nth-child(5)': {
              textAlign: 'right',
            },
          },
        },
      },
      tbody: {
        '& tr': {
          '& td': {
            '&:nth-child(3)': {
              textAlign: 'right',
            },
            '&:nth-child(4)': {
              textAlign: 'right',
            },
            '&:nth-child(5)': {
              textAlign: 'right',
            },
          },
        },
      },
    },
  },
  grayRow: {
    backgroundColor: '#f2f2f2',
    '&:hover': {
      background: '#eceaea',
    },
  },
  livingRow: {
    backgroundColor: '#fce4d6',
    '&:hover': {
      backgroundColor: '#d5b6a4',
    },
  },
  otherExpenses: {
    backgroundColor: '#fff2cc',
    '&:hover': {
      backgroundColor: '#e3d6af',
    },
  },
  blueRow: {
    backgroundColor: '#dae3f3',
    borderTop: '2px solid #000 !important',
  },
  leftPadding: {
    paddingLeft: '40px !important',
  },
  leftSubPadding: {
    paddingLeft: '60px !important',
  },
  borderDiv: {
    border: '1px solid #2f75b5',
    margin: '20px 0',
    padding: '15px 0',
    '& h3': {
      textAlign: 'center',
      fontSize: '22px',
      marginTop: '0',
      lineHeight: '30px',
      fontWeight: '300',
      marginBottom: '0',
    },
  },
  blueHr: {
    margin: '10px',
    borderTop: '2px solid #2f75b5',
  },
  subListMenu: {
    width: '100%',
    listStyle: 'disc !important',
    listStylePosition: 'inside !important',
    paddingLeft: '28px !important',
    '& li': {
      display: 'list-item !important',
      paddingLeft: '0px !important',
    },
  },
  moduleToolTip: {
    padding: '3px 8px',
    marginTop: '-20px !important',
    width: 'auto !important',
    maxWidth: '200px',
  },
  potentialGrowth: {
    border: '1px solid #2f75b5',
    margin: '20px 0',
    padding: '15px 0',
    '& > div:last-child':{
      '@media (max-width: 991px)': {
        minWidth: '850px',
      }
    },
    '@media (max-width: 991px)': {
      overflow: 'auto',
    },
    '& h3': {
      textAlign: 'center',
      fontSize: '22px',
      marginTop: '0',
      lineHeight: '30px',
      color: theme.palette.common.black,
      marginBottom: '0',
      fontWeight: '300',
      fontFamily: '"MuseoSans-500"',
      '@media (max-width: 991px)': {
        minWidth: '850px',
      }
    },
  },
  blueLine: {
    borderTop: '2px solid #2f75b5',
    margin: '10px',
    '@media (max-width: 991px)': {
      minWidth: '850px',
    }
  },
  potentialInputs: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      transform: 'scale(0.68)',
    },
    '@media (max-width: 991px)': {
      minWidth: '850px',
    }
  },
  careerInput: {
    width: '170px',
    marginRight: '39px',
    marginTop: '30px',
    marginBottom: '30px',
    '& label': {
      fontSize: '21px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
      '@media (max-width: 767px)': {
        lineHeight: '24px',
        fontSize: '18px',
      }
    },
  },
  dolarSymbol: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    borderRight: '0',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  percentSymbol: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    borderLeft: '0',
    paddingRight: '6px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderRightWidth: '1px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  calculateButton: {
    backgroundColor: '#fd9840',
    fontSize: '21px',
    color: '#ffffff',
    lineHeight: '19px',
    borderColor: '#fd9840',
    boxShadow: 'none',
    borderRadius: '3px',
    padding: '10px 20px',
    marginLeft: '0px',
    borderWidth: '2px',
    marginTop: '34px',
    outline: '0',
    '&:hover': {
      backgroundColor: '#f28735',
      borderColor: '#f28735',
      boxShadow: 'none',
    },
  },
  graphImage: {
    '& img': {
      width: '100%',
    },
  },
  netWorth: {
    margin: '37px 0 0 70px',
    '& ul': {
      display: 'inline-block',
      verticalAlign: 'top',
      margin: '0',
      padding: '0',
      width: '100%',
      '& li': {
        display: 'block',
        padding: '0 0 8px',
        backgroundImage: 'none',
        margin: '0',
        '& p': {
          fontFamily: 'MuseoSans-300',
          fontSize: '18px',
          lineHeight: '18px',
          padding: '0',
          margin: '0',
          '& > span': {
            display: 'inline-block',
            verticalAlign: 'middle',
            fontFamily: 'MuseoSans-500',
            fontSize: '12px',
            lineHeight: '12px',
            padding: '4px 13px',
            margin: '0 14px 0 0',
            background: '#ebebeb',
            borderRadius: '12px',
            minWidth: '72px',
            textAlign: 'center',
            boxSizing: 'border-box',
          },
          '& font': {
            '& span ': {
              width: 'auto',
              fontSize: '20px',
              display: 'inline-block',
              verticalAlign: 'middle',
              color: theme.palette.common.black,
            },
          },
        },
      },
    },
  },
  inputGroup1: {
    position: 'relative',
    display: 'table',
    borderCollapse: 'separate',
    '& span': {
      padding: '6px 6px',
      backgroundColor: 'white',
      boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
      fontSize: '20px',
      paddingRight: '0px',
      fontWeight: '400',
      lineHeight: '1',
      color: '#555',
      textAlign: 'center',
      width: '1%',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      display: 'table-cell',
    },
  },
  formInput: {
    fontSize: '20px',
    zIndex: '0',
    outline: '0',
    lineHeight: '1.42857143',
    color: '#555',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
    transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
    width: '100%',
    padding: '4px',
    display: 'table-cell',
    position: 'relative',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderLeftWidth: '0px',
    height: 'auto',
    '& >div': {
      '&:before': {
        content: 'none',
      },
      '&:after': {
        content: 'none',
      },
    },
    '& input': {
      fontSize: '20px',
    },
  },
  annlInput: {
    '& > div': {
      '& input': {
        padding: '0px 0px 0px !important',
      },
    },
  },
  returnInput: {
    borderLeftWidth: '1px',
    borderRightWidth: '0px',
    paddingLeft: '6px',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    paddingRight: '0px',
    '& > div': {
      '& input': {
        padding: '0px 0px 0px !important',
      },
    },
  },
  percentPadding: {
    paddingRight: '6px !important',
  },
  pwiWalkthru: {
    position: 'absolute',
    maxWidth: '640px',
    background: '#fff',
    zIndex: '999',
    border: '3px solid #0069aa',
    borderRadius: '40px',
    top: '135px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    display: 'none',
    '& h4': {
      width: '100%',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      fontSize: '20px',
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '10px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-300',
      color: '#0069aa',
      lineHeight: '1.1',
      boxSizing: 'border-box',
    },
    '& p': {
      color: '#000',
      fontSize: '16px',
      padding: '10px 20px',
      display: 'inline-block',
      lineHeight: '24px',
      margin: '0px 0px 10px',
      boxSizing: 'border-box',
    },
    '&::before': {
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
    '&::after': {
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
  },
  relativeBlock: {
    position: 'relative',
  },
  startWalkThru: {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    padding: '6px 12px',
    borderRadius: '4px',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#337ab7 !important',
      borderColor: '#204d74 !important',
    },
    '& span': {
      color: '#fff !important',
    },
  },
  walkThruBtns: {
    maxWidth: '230px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    textAlign: 'right',
    marginBottom: '10px',
    float: 'right',
    paddingRight: '10px',
    '@media (max-width: 479px)': {
      display: 'block',
      float: 'left',
    },
    '& button': {
      margin: '0px 2px !important',
      display: 'inline-block',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      fontSize: '14px',
      userSelect: 'none',
    },
  },
  checkmarksScroll: {
    listStyle: 'none',
    padding: '0',
    display: 'inline-block',
    marginLeft: '0',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    paddingInlinStart: '35px',
    marginBottom: '10px',
    marginTop: '0px',
    boxSizing: 'border-box',
    '& li': {
      width: '15px',
      height: '15px',
      background: '#0069aa',
      display: 'inline-block',
      margin: '1px 2px',
      borderRadius: '50%',
      position: 'relative',
      lineHeight: '20px',
      float: 'left',
      cursor: 'pointer',
      fontSize: '18px',
      paddingBottom: '8px',
      listStyle: 'none',
      marginLeft: '2px',
      boxSizing: 'border-box',
      '&:last-child': {
        '&::before': {
          background: '0 0!important',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        margin: 'auto',
        left: 'auto',
        marginTop: 'auto',
        width: '7px',
        height: '3px',
        background: '#0069aa',
        display: 'inline-block',
        right: '-5px',
        zIndex: '-1',
        bottom: '0',
        boxSizing: 'border-box',
      },
      '&:hover': {
        opacity: '.8',
      },
    },
  },
  walkthruBottom: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0 25px 0 15px',
    boxSizing: 'border-box',
    '@media (max-width: 479px)': {
      display: 'block',
    }
  },
  closeWalkThru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  skipWalkthru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  pwiWalkthruOne: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'display !important',
    '@media (max-width: 1199px)': {
      right: '0px',
    },
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  nomarginBottom: {
    marginBottom: '0px !important',
  },
  pwiWalkthruTwo: {
    top: 'auto',
    bottom: '90px',
    right: '-30px',
    left: 'auto',
    display: 'none',
    '&::before': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px!important',
    },
    '&::after': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '31px 30px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
    },
  },
  pwiWalkthruThree: {
    top: '80px',
    left: '-56px',
    right: 'auto',
    display: 'none',
    '&::before': {
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
      top: '-29px',
      left: '39px',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  pwiWalkthruFour: {
    top: '77px',
    left: '335px',
    right: 'auto',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
  },
  pwiWalkthruFive: {
    top: 'auto',
    left: '338px',
    right: 'auto',
    bottom: '0',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '52px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '50px',
      top: 'auto',
    },
  },
  pwiWalkthruSix: {
    top: '107px',
    left: '-45px',
    right: 'auto',
    display: 'none',
    '&::before': {
      top: '-29px',
      left: '39px',
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
    },
  },
  pwiWalkthruSeven: {
    top: '25px',
    left: '325px',
    right: 'auto',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '84px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '82px',
      top: 'auto',
    },
  },
  pwiWalkthruEight: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'none !important',
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  activeList: {
    background: '#84aa47 !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#84aa47',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '0',
      margin: 'auto',
      content: '""',
      height: '5px',
      width: '8px',
      borderWidth: '0 0 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      bottom: '2px',
      left: '-1px',
      right: '0',
      transform: 'rotate(-45deg)',
      boxSizing: 'border-box',
    },
  },
  currentTab: {
    background: '#f7941e !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#f7941e',
      },
    },
  },
  rentPlusUntils: {
    marginTop: '55px',
  },
  livingExpencesFlexEnd: {
    alignItems: 'flex-end',
    overflowX: 'auto',
    overflowY: 'hidden',
    '@media (max-width: 1199px)': {
      overflowX: 'auto',
    },
    '@media (max-width: 767px)': {
      marginTop: '10px',
    }
  },
  mainBlockRow:{
    alignItems: 'flex-end',
    display: 'flex',
    width: '100%',
    '@media (max-width: 1199px)': {
      minWidth: '950px',
    }
  },

  rentGraphBlockItalicGap: {
    marginTop: '30px',
  },

  livingExpencesMothlyNoBefore: {
    '&:before': {
      disply: 'none',
      opacity: '0',
    },
  },
  livingExpencesRangeFlex: {
    display: 'flex',
    alignItems: 'center',
    '& > div > div': {
      '& > p': {
        minWidth: '200px',
        maxWidth: '200px',
        lineHeight: '25px',
        fontSize: '18px',
        margin: '0',
      },
    },
  },
  livingExpencesRangeFlexNew: {
    display: 'flex',
    alignItems: 'center',
  },
  annuvalIncomeTittleSpace: {
    marginLeft: 'calc(54% + 230px)',
    position: 'relative',
    // '&::before': {
    //   content: '""',
    //   position: 'absolute',
    //   width: '4px',
    //   height: 'calc(100% + 30px)',
    //   background: '#046bab',
    //   left: '-7px',
    // },
    '@media (max-width: 767px)': { 
      marginLeft: 'calc(54% + 260px)',
    }
  },
  retirementBottomPage: {
    '& > div': {
      marginTop: '5px',
      '& > div': {
        width: 'auto',
        fontFamily: 'MuseoSans-700',
        '&:first-child': {
          width: '78%',
        },
        '&:nth-child(2)': {
          width: '83px',
        },
        '&:last-child': {
          margin: '0 0 0 auto',
          paddingRight: '0',
        },
      },
      '& p': {
        fontFamily: 'MuseoSans-700',
        lineHeight: '25px',
      },
    },
  },
  annuvalIncomeTittleSpaceXtra: {
    marginLeft: 'auto',
    textAlign: 'right',
    '&::before': {
      display: 'none',
    },
    '& span': {
      textAlign: 'right',
    },
  },
  worksheetToolTip: {
    minWidth: '186px',
    maxWidth: '186px',
    boxSizing: 'border-box',
    opacity: '0.9 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    '&::after': {
      borderRightStyle: 'hidden !important',
    },
    '&::before': {
      borderRight: '6px solid #0069aa !important',
      left: '-6px !important',
    },
  },
  definitionTooltips: {
    maxWidth: '200px',
    padding: '3px 8px',
    color: '#fff',
    borderRadius: '4px',
    backgroundColor: '#0069aa !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    fontSize: '14px',
    boxSizing: 'border-box',
    textAlign: 'left',
    width: 'auto',
    '&::after': {
      borderTopColor: '#0069aa !important',
    },
    '@media (max-width: 399px)': { 
      maxWidth: '130px',
    },
  },
  tooltipInfoTopNew: {
    maxWidth: '200px',
    width: 'auto',
    background: '#0069aa !important',
    fontSize: '14px !important',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    marginLeft: '0 !important',
    '&::after': {
      opacity: '1 !important',
      borderLeftColor: '#0069aa',
    },
  },
  grayBoxHigherHalf: {
    width: 'calc(75% - 60px)',
    '@media (max-width: 767px)': {
      width: '100%',
    },
    '& ul': {
      padding: '0',
      margin: '0',
    },
  },
  livingExpencesMothlyXtra: {
    marginTop: '32px',
  },
  grayBoxRentNew: {
    // padding: '10px 20px 20px',
    margin: '20px 0 40px 0px',
  },
  grayBoxRetire: {
    width: '70%',
    boxSizing: 'border-box',
    marginTop: '20px',
  },
  pendingListpopNew: {
    '& > div > div': {
      maxWidth: '60%',
      '& > div': {
        padding: '10px 15px 8px !important',
        overflow: 'hidden',
        '& h3': {
          fontSize: '18px',
          color: '#0069aa',
          fontWeight: 'normal',
          fontFamily: 'MuseoSans-500',
        },
      },
      '& img': {
        width: '100%',
      },
    },
  },
  paddingLeftNew: {
    paddingLeft: '5px',
  },
  annualInputRight: {
    '& span': {
      width: 'auto',
    },
  },
  floatRight:{
    float: 'right',
   },
  potentialGrowthBorderLine: {
    '& h3': {
      borderBottom: '2px solid #2f75b5',
      marginBottom: '20px',
      marginTop: '-15px',
      paddingBottom: '10px',
    },
  },
  livingExpencesRangeFlexNewPensionCont: {
    '& > div > div': {
      '& p': {
        minWidth: '180px',
        maxWidth: '180px',
      },
    },
  },
  
 
 
  // potentialGrowth: {
  //   border: '1px solid #2f75b5',
  //   margin: '20px 0',
  //   padding: '15px 15px',
  //   '& > div:last-child':{
  //     '@media (max-width: 991px)': {
  //       minWidth: '950px',
  //     }
  //   },
  //   '@media (max-width: 991px)': {
  //     overflow: 'auto',
  //   },
  //   '& h3': {
  //     textAlign: 'center',
  //     fontSize: '22px',
  //     marginTop: '0',
  //     lineHeight: '30px',
  //     color: theme.palette.common.black,
  //     marginBottom: '0',
  //     fontWeight: '300',
  //     fontFamily: '"MuseoSans-500"',
  //     '@media (max-width: 991px)': {
  //       minWidth: '950px',
  //     }
  //   },
  // },
  // blueLine: {
  //   borderTop: '2px solid #2f75b5',
  //   margin: '10px',
  //   '@media (max-width: 991px)': {
  //     minWidth: '950px',
  //   }
  // },
  // potentialInputs: {
  //   width: '100%',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   margin: '0 auto',
  //   alignItems: 'center',
  //   '@media (max-width: 991px)': {
  //     minWidth: '950px',
  //   }
  // },
  // careerInput: {
  //   width: '170px',
  //   marginRight: '18px',
  //   marginTop: '30px',
  //   marginBottom: '30px',
  //   '& label': {
  //     fontSize: '18px',
  //     fontWeight: '500',
  //     fontFamily: 'MuseoSans-500',
  //     '@media (max-width: 767px)': {
  //       lineHeight: '24px',
  //       fontSize: '18px',
  //     }
  //   },
  // },
  // dolarSymbol: {
  //   border: '1px solid #ccc',
  //   borderRadius: '4px',
  //   borderRight: '0',
  //   borderTopRightRadius: '0',
  //   borderBottomRightRadius: '0',
  // },
  // percentSymbol: {
  //   border: '1px solid #ccc',
  //   borderRadius: '4px',
  //   borderLeft: '0',
  //   paddingRight: '6px',
  //   borderTopLeftRadius: '0px',
  //   borderBottomLeftRadius: '0px',
  //   borderRightWidth: '1px',
  //   borderTopRightRadius: '4px',
  //   borderBottomRightRadius: '4px',
  // },
  // calculateButton: {
  //   backgroundColor: '#fd9840',
  //   fontSize: '21px',
  //   color: '#ffffff',
  //   lineHeight: '19px',
  //   borderColor: '#fd9840',
  //   boxShadow: 'none',
  //   borderRadius: '3px',
  //   padding: '10px 20px',
  //   marginLeft: '0px',
  //   borderWidth: '2px',
  //   marginTop: '34px',
  //   outline: '0',
  //   '&:hover': {
  //     backgroundColor: '#f28735',
  //     borderColor: '#f28735',
  //     boxShadow: 'none',
  //   },
  // },
  // graphImage: {
  //   '& img': {
  //     width: '100%',
  //   },
  // },
  // netWorth: {
  //   margin: '37px 0 0 18px',
  //   '& ul': {
  //     display: 'inline-block',
  //     verticalAlign: 'top',
  //     margin: '0',
  //     padding: '0',
  //     width: '100%',
  //     '& li': {
  //       display: 'block',
  //       padding: '0 0 8px',
  //       backgroundImage: 'none',
  //       margin: '0',
  //       '& p': {
  //         fontFamily: 'MuseoSans-300',
  //         fontSize: '18px',
  //         lineHeight: '18px',
  //         padding: '0',
  //         margin: '0',
  //         '& > span': {
  //           display: 'inline-block',
  //           verticalAlign: 'middle',
  //           fontFamily: 'MuseoSans-500',
  //           fontSize: '12px',
  //           lineHeight: '12px',
  //           padding: '4px 13px',
  //           margin: '0 14px 0 0',
  //           background: '#ebebeb',
  //           borderRadius: '12px',
  //           minWidth: '72px',
  //           textAlign: 'center',
  //           boxSizing: 'border-box',
  //         },
  //         '& font': {
  //           '& span': {
  //             width: 'auto',
  //             fontSize: '20px',
  //             display: 'inline-block',
  //             verticalAlign: 'middle',
  //             color: theme.palette.common.black,
  //           },
  //         },
  //       },
  //     },
  //   },
  // },
  // inputGroup1: {
  //   position: 'relative',
  //   display: 'table',
  //   borderCollapse: 'separate',
  //   '& span': {
  //     padding: '6px 6px',
  //     backgroundColor: 'white',
  //     boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
  //     fontSize: '20px',
  //     paddingRight: '0px',
  //     fontWeight: '400',
  //     lineHeight: '1',
  //     color: '#555',
  //     textAlign: 'center',
  //     width: '1%',
  //     whiteSpace: 'nowrap',
  //     verticalAlign: 'middle',
  //     display: 'table-cell',
  //   },
  // },
  // formInput: {
  //   fontSize: '20px',
  //   zIndex: '0',
  //   outline: '0',
  //   lineHeight: '1.42857143',
  //   color: '#555',
  //   backgroundColor: '#fff',
  //   backgroundImage: 'none',
  //   border: '1px solid #ccc',
  //   borderRadius: '4px',
  //   boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
  //   transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
  //   width: '100%',
  //   padding: '4px',
  //   display: 'table-cell',
  //   position: 'relative',
  //   borderTopLeftRadius: '0',
  //   borderBottomLeftRadius: '0',
  //   borderLeftWidth: '0px',
  //   height: 'auto',
  //   '& >div': {
  //     '&:before': {
  //       content: 'none',
  //     },
  //     '&:after': {
  //       content: 'none',
  //     },
  //   },
  //   '& input': {
  //     fontSize: '20px',
  //   },
  // },
  // annlInput: {
  //   '& > div': {
  //     '& input': {
  //       padding: '0px 0px 0px !important',
  //     },
  //   },
  // },
  // returnInput: {
  //   borderLeftWidth: '1px',
  //   borderRightWidth: '0px',
  //   paddingLeft: '6px',
  //   borderTopLeftRadius: '4px',
  //   borderBottomLeftRadius: '4px',
  //   borderTopRightRadius: '0px',
  //   borderBottomRightRadius: '0px',
  //   paddingRight: '0px',
  //   '& > div': {
  //     '& input': {
  //       padding: '0px 0px 0px !important',
  //     },
  //   },
  // },
  // percentPadding: {
  //   paddingRight: '6px !important',
  // },
  // livingExpencesFlexEnd: {
  //   alignItems: 'flex-end',
  //   marginTop: '50px',
  //   overflowX: 'auto',
  //   overflowY: 'hidden',
  //   '@media (max-width: 1199px)': {
  //     overflow: 'auto',
  //   },
  //   '@media (max-width: 767px)': {
  //     marginTop: '10px',
  //   }
  // },
  // mainBlockRow:{
  //   alignItems: 'flex-end',
  //   display: 'flex',
  //   width: '100%',
  //   '@media (max-width: 1199px)': {
  //     minWidth: '950px',
  //   }
  // },
  // livingTitle: {
  //   height: '30px',
  //   lineHeight: '30px',
  //   textDecoration: 'none',
  //   marginTop: '0',
  //   textAlign: 'center',
  //   fontFamily: 'MuseoSans-700',
  //   marginLeft: '34px',
  //   marginBottom: '45px',
  //   '& span': {
  //     borderBottom: '1px solid',
  //   },
  // },
  // rentGraphBlockItalicGap: {
  //   marginTop: '30px',
  // },

  // livingExpencesMothlyNoBefore: {
  //   '&:before': {
  //     disply: 'none',
  //     opacity: '0',
  //   },
  // },
  // livingExpencesRangeFlex: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   '& > div > div': {
  //     '& > p': {
  //       minWidth: '200px',
  //       maxWidth: '200px',
  //       lineHeight: '25px',
  //       fontSize: '18px',
  //       margin: '0',
  //     },
  //   },
  // },
  // livingExpencesRangeFlexNew: {
  //   display: 'flex',
  //   alignItems: 'center',
  // },
  // annuvalIncomeTittleSpace: {
  //   marginLeft: 'calc(54% + 230px)',
  //   position: 'relative',
  //   // '&::before': {
  //   //   content: '""',
  //   //   position: 'absolute',
  //   //   width: '4px',
  //   //   height: 'calc(100% + 30px)',
  //   //   background: '#046bab',
  //   //   left: '-7px',
  //   // },
  //   '@media (max-width: 767px)': { 
  //     marginLeft: 'calc(54% + 260px)',
  //   }
  // },
  // retirementBottomPage: {
  //   '& > div': {
  //     marginTop: '5px',
  //     '& > div': {
  //       width: 'auto',
  //       fontFamily: 'MuseoSans-700',
  //       '&:first-child': {
  //         width: '78%',
  //       },
  //       '&:nth-child(2)': {
  //         width: '83px',
  //       },
  //       '&:last-child': {
  //         margin: '0 0 0 auto',
  //         paddingRight: '0',
  //       },
  //     },
  //     '& p': {
  //       minWidth: '180px',
  //       maxWidth: '180px',
  //     },
  //   },
  // },
  pwiAnimation: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'fixed',
      left: '0',
      top: '0',
      width: '100%',
      height: '100vh',
      background: 'rgba(0,0,0,.7)',
      zIndex: '9999',
      display: 'none',
      boxSizing: 'border-box',
    },
  },
  pwiAnimationActive: {
    '&::before': {
      display: 'block',
    },
  },
  pwiWalkThruButton: {
    pointerEvents: 'none',
    position: 'relative',
    zIndex: '9999',
    cursor: 'none',
  },
  pwiActiveWalkthru: {
    display: 'block !important',
    zIndex: '9999',
    pointerEvents: 'all',
    cursor: 'auto',
  },
  pwiActiveBlock: {
    pointerEvents: 'none',
    '& img': {
      position: 'relative',
      zIndex: '9999',
      cursor: 'none',
    },
  },
  pwiWalkTitle: {
    background: '#fff',
    zIndex: '9999',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    top: '0',
    padding: '3px 0px 3px 7px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    width: '98%',
  },
  pwiActiveModule: {
    background: '#fff',
    padding: '5px',
    borderRadius: '4px',
    position: 'relative',
    zIndex: '9999',
    width: '50%',
    '& img': {
      cursor: 'none',
      pointerEvents: 'none',
    },
    '& h3': {
      cursor: 'none',
      pointerEvents: 'none',
    },
  },
  logoLink: {
    margin: '0 0 0 auto',
    '& img': {
      maxWidth: '140px',
      height: '38px',
    },
  },
  squareList: {
    fontSize: '21px',
    position: 'relative',
    textDecoration: 'underline',
    listStyle: 'none',
    marginLeft: '27px',
    marginTop: '0px',
    marginBottom: '0px',
    '& li': {
      position: 'relative',
      '&::before': {
        content: '""',
        color: '#116aa1',
        fontWeight: '700',
        width: '10px',
        height: '10px',
        border: '1px solid',
        background: '#116aa1',
        position: 'absolute',
        left: '-30px',
        bottom: '0',
        top: '0',
        margin: 'auto',
        boxSizing: 'border-box',
      },
      '& a': {
        '&:hover': {
          color: '#f89b23',
        },
      },
    },
  },
  addResource: {
    color: '#333',
    textAlign: 'left',
    lineHeight: '30px',
    fontWeight: '300',
    fontSize: '21px',
  },
  screenHght: {
    height: '110vh',
  },
  sliderChart: {
    width: '24%',
    display: 'inherit',
    padding: '0 15px',
    marginBottom: '30px'
  },
  utilitiesChart: {
    height: '426px !important',
    '@media (max-width: 767px)': {
      height: '204.75px',
    },
    // display: 'table',
  },
  utilitiesPMI: {
    tableLayout: 'fixed',
    width: '100%',
    margin: '54px auto 0',
    verticalAlign: 'bottom',
    boxSizing: 'border-box',
    height: '185.75px',
    '@media (max-width: 767px)': {
      height: '204.75px',
    },
  },
  utilitiesPMITwo: {
    height: '350.75px !important',
    '@media (max-width: 767px)': {
      height: '204.75px',
    },
  },
  chartBar: {
    position: 'relative',
    display: 'table-cell',
    verticalAlign: 'bottom',
    height: 'inherit',
    borderBottom: '1px solid #ccc',
    width: '50%',
  },
  chartText: {
    position: 'relative',
    top: '0',
    textAlign: 'center',
    fontSize: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    lineHeight: '1.4',
  },
  chartSpan: {
    color: '#a6a6a6',
    fontWeight: '500',
    fontSize: '16px',
  },
  flexRow: {
    display: 'flex',
  },
  mortgageFlexRow: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  percentageBar: {
    margin: '0 16px',
    display: 'block',
    background: '#a6a6a6',
    animation: 'draw 1s ease-in-out',
    height: '100%',
    '&:before': {
      position: 'absolute',
      left: '0',
      right: '0',
      top: '0',
      padding: '5px 1em 0',
      display: 'block',
      textAlign: 'center',
      content: 'attr(title)',
    },
  },
  graphPercentage: {
    position: 'absolute',
    width: '100%',
    justifyContent: 'center',
    color: '#a6a6a6',
    paddingTop: '3px',
    fontSize: '14px',
    textAlign: 'center',
    display: 'flex',
  },
  secondGraph: {
    margin: '0 1em',
    display: 'block',
    background: '#0069aa',
    animation: 'draw 1s ease-in-out',
  },
  secondPercentageBarColor: {
    background: '#0069aa !important',
  },
  secondPercentageTextColor: {
    color: '#0069aa !important',
  },
  // livingTitle: {
  //   height: '30px',
  //   lineHeight: '30px',
  //   textDecoration: 'none',
  //   '& span': {
  //     borderbottom: '1px solid',
  //   },
  // },
  mainFullBlock: {
    width: '100%',
    '&::before': {
      right: '205px !important',
      '@media screen and (max-width: 1023px) and (min-width: 768px)': {
        display: 'none'
      },
    },
    
    '@media (max-width: 767px)': {
      minWidth: '950px',
    }
  },
  mainFullBlockFull: {
    display: 'block',
  },
  livingExpencesRow:{
    '@media (max-width: 1199px)': {
      overflowX: 'auto',
    }
  },
  livingExpencesDiv:{
    '@media (max-width: 1199px)': {
      minWidth: '950px',
    }
  },
  quickSave:{
    position: 'absolute',
    right: '110px',
    padding: '1px 6px !important',
    margin: '0px !important',
    minWidth: 'initial',
    borderRadius: '0px !important',
    height: '45px',
    [theme.breakpoints.down( 'xs' )]: {
      right: '85px',
    },
    '&:hover':{
      background: '#0d97ec !important',
    },
    '& img':{
      width:'37px',
      position: 'relative',
      top: '5px',
    }
  },
  quickSavePopupBtn: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        borderColor: '#e6e6e6',
        color: '#333',
        marginRight: '6px',
        '&:hover': {
          backgroundColor: '#e6e6e6',
        },
      },
    },
  },
  scenarioNameBlock: {
    color: '#0069aa',
    marginBottom: '20px',
    marginTop: '20px',
    '& span': {
      display: 'block',
      fontSize: '15px',
      '&:last-child': {
        fontSize: '14px',
        paddingTop:'5px',
        fontStyle:'italic'
      }
    }
  },
  documentsMenu: {
    position:'relative'
  },
  textDanger: {
    color: 'red',
  },
  cursorPointer:{
    cursor: 'pointer',
  },
  csaveScenario: {
    boxSizing: 'border-box',   
    '& > div': {
      alignItems: 'center',
      margin: '0px auto',
      display: 'flex',
      // '@media (max-width: 767px)': {
      //   margin: '30px 15px',
      // },
      // '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      //   margin: '30px 15px',
      // },
      // '@media (max-width: 420px)': {
      //   margin: '30px 15px',
      // },
    },
    '& > div > div ': {
      width: '750px',
      margin: '0 auto',
      maxWidth: '100%',
      borderRadius: '0px',
      background: '#fff',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        width: '85%',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        width: '70%',
      },
      '@media (max-width: 599px)': {
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
      },
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  graphContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    table:{
      width: "250px",
    },
    graph: {
      width: "400px"
    }
  },
  
  retirementList: {
    listStyle: 'none',
    padding: '0px',
    maxWidth: '325px',
    margin: '0px 0px 35px',
    '& li': {
      cursor: 'pointer',
      background: '#f2f2f2',
      position: 'relative',
      display: 'inline-block',
      width: 'calc(100% - 20px)',
      fontSize: '21px',
      padding: '4px 10px',
      border: '1px solid',
      margin: '5px auto',
      borderRadius: '6px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
      },
      '& span':{
        display: 'inline-block',
        width: '38px',
        border: 'none',
        position: 'absolute',
        right: '1px',
        top: '3px',
        background: '0 0',
        '& img':{
          width: '100%',
        }
      }
    }
  },
  blueTick:{
    width: '100px',
    verticalAlign: 'middle',
    paddingLeft: '10px',
  },
  existingstudentloanFlex: {
    marginTop: '20px',
    border: '2px solid #0069aa',
    marginBottom: '20px',
    display: 'flex',
  },
  existingstudentloanGraphLeft: {
    borderRight: '2px solid #0069aa',
    paddingTop: '18px',
    background: '#e7f1f9',
    padding: '0px',
    flexGrow: '0',
    maxWidth: '25%',
    flexBasis: '25%',
    '& table': {
      width: '100%',
      marginBottom: '15px',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '& tr': {
        '& td': {
          paddingLeft: '10px',
          fontSize: '17px',
          paddingRight: '10px',
          color: '#333',
        }
      }
    }
  },
  existingstudentloanGraphRight: {
    flexGrow: '0',
    maxWidth: 'calc(75% - 30px)',
    flexBasis: 'calc(75% - 30px)',
    padding: '0px 15px 15px',
    '& h3': {
      fontWeight: '300',
      margin: '20px 0 10px',
      textAlign: 'center',
      lineHeight: '30px',
      color: '#333',
      fontSize: '22px',
    }
  },
  existingstudentInput: {
    position: 'relative',
    paddingLeft: '2px',
    height: '45px',
    background: '#fff',
    fontSize: '17px',
    display: 'flex',
    color: '#333',
    '& span': {
      position: 'absolute',
      left: '9px',
      top: '6px',
    },
    '& div': {
      width: '100%',
      height: '100%',
      border: '0px',
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
      '& input': {
        padding: '0px 20px',
        border: '0px',
        fontSize: '17px',
      }
    }
  },
  h18: {
    height: '18px',
  },
  tdBorder: {
    height: 'calc(2px - 2px)',
    background: '#2f75b5',
  },
  inputRight: {
    '& span': {
      position: 'absolute',
      right: '15px',
      top: '5px',
      // position: 'absolute',
      left: 'initial',
    },
    '& div': {
      '& input': {
        fontSize: '16px',
        padding: '0px 10px',
        border: '0px',
      }
    }
  },
  fieldText: {
    height: '20px',
    fontFamily: 'MuseoSans-500',
    padding: '0px 15px 10px !important',
    lineHeight: '20px',
  },
  existingstudentloanFlexBig:{
    '@media (min-width: 1200px)': {
      margin: '0px -95px',
    }
  },
  tabSectionScroll:{
    '@media (max-width: 1199px)': {
      overflowX: 'auto',
    },
    '& > div':{
      '@media (max-width: 1199px)': {
        minWidth: '950px',
      }
    }
  },
  blueLineShort:{
    '@media (max-width: 1199px)': {
      minWidth: 'initial',
    }  
  },
  tutionFeesTable70:{
    [theme.breakpoints.down( 'sm' )]: {
      overflowY: 'auto',
    },
    '& table':{
      width:' 70%',
      maxWidth: 'initial',
      margin: '0px',
      [theme.breakpoints.down( 'sm' )]: {
        width:' 100%',
        maxWidth: '700px',
      },
      '& tr':{
        '& td':{
          '&:first-child':{
            [theme.breakpoints.down( 'sm' )]: {
              minWidth: '280px',
            }
          }
        }
      }
    }
  },
  tutionFeesTable70New:{
    [theme.breakpoints.down( 'sm' )]: {
      overflowY: 'auto',
    },
    '& table':{
      width:' 70%',
      maxWidth: 'initial',
      margin: '50px 0 20px',
      [theme.breakpoints.down( 'sm' )]: {
        width:' 100%',
        maxWidth: '700px',
      }
    }
  },
  textBold:{
    fontFamily: 'MuseoSans-500',
    fontWeight: 'normal',
  },
  mt30:{
    marginTop: '30px',
  },
  verticalBorderNone:{
    '&:before':{
      display: 'none',
    }
  },
  mb30:{
    marginBottom: '30px',
  },
  active: {
    background: '#bdd597 !important',
    fontFamily: 'MuseoSans-500',
    fontWeight: 'normal',
  },
  textCenter:{
    textALign: 'center',
  },
  dangerText: {
    color: '#bf0000 !important'
  },
} );

export default styles;
