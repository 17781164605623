import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const RefinanceLoan = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li> Refinance a Loan</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />

                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/refinance-loan.png "
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Refinance a Loan
                    </h3>
                  </div>

                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/refinance-loan-187px.png "
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          {' '}
                          When you buy a home, you most likely will need a
                          mortgage. A typical mortgage is a 30-year loan. A lot
                          can change in 30 years in the financial world as well
                          as with your own personal financial life. What happens
                          when you want to change the terms of the loan?
                        </p>
                      </div>
                    </div>

                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          That&apos;s where refinancing comes into play. When
                          you refinance a loan – a mortgage, car loan, student
                          loan, personal loan or any other loan – you replace
                          the existing loan with a new loan that has better
                          terms. The only reason you refinance a loan is to
                          create a better financial situation. Let&apos;s look
                          at an example.
                        </p>

                        <p>
                          10 years ago, Mike bought a home for $500,000 and used
                          a $400,000 mortgage. His 30-year mortgage has a 5.0%
                          interest rate. Today, the market rate (finance jargon
                          for the current interest rates on similar loans) would
                          be 3% for Mike, based on his credit score and
                          financial situation. If Mike refinances his mortgage,
                          he could save 2% on his loan. Check out the table
                          below to see the savings, assuming Mike refinances for
                          a 2% fee:
                        </p>
                        <div className={classes.feTexttableThree}>
                          <table>
                            <thead>
                              <tr>
                                <th>&nbsp;</th>
                                <th>Existing Loan</th>
                                <th>New Loan</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Loan Amount* </td>
                                <td>$325,368 </td>
                                <td>$328,622</td>
                              </tr>
                              <tr>
                                <td>Interest Rate </td>
                                <td>5.0% </td>
                                <td>3.0% </td>
                              </tr>
                              <tr>
                                <td>Term in Years </td>
                                <td>20.0 </td>
                                <td>20.0 </td>
                              </tr>
                              <tr>
                                <td>Monthly Payment </td>
                                <td>$2,147 </td>
                                <td> $1,823</td>
                              </tr>
                              <tr>
                                <td>
                                  Total Repayment <br />
                                  (Principal & Interest)
                                </td>
                                <td> $515,349 </td>
                                <td>$437,407 </td>
                              </tr>
                              <tr>
                                <td>Total Interest Paid </td>
                                <td>$189,981 </td>
                                <td>$108,785 </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>Savings </td>
                                <td>$81,195</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <p className={classes.noteText}>
                          * New loan amount includes 2% refinancing fee.
                        </p>
                        <p>
                          By refinancing his mortgage, Mike saves over $300 per
                          month or more than $3,600 a year. And, over the life
                          of the loan, Mike will save $80,000. Remember to
                          always do this analysis when considering refinancing a
                          loan.
                        </p>

                        <p>
                          <h3 className={classes.paraTitle}>
                            Reasons to refinance
                          </h3>
                          The two most common reasons to refinance a loan are
                          interest rates and repayment schedule. Over time,
                          interest rates will go up and down. When they go down,
                          refinancing is a great tool to create a new loan with
                          a better interest rate. A lower interest rate means
                          lower monthly payments and lower total cost of the
                          loan.
                        </p>
                        <p>
                          Or sometimes your financial situation changes. Maybe
                          you need a lower monthly payment to get through some
                          tougher cash flow times. You can refinance to extend
                          the term of your loan, which will provide a lower
                          monthly payment but result in a higher cost over the
                          life of the loan.
                        </p>
                        <p>
                          Maybe the opposite is true: Your credit score improves
                          and now you have a better opportunity to refinance to
                          a lower rate. Having the awareness to refinance a loan
                          is the first step. It’s always a good idea to stay on
                          top of your loans and the potential for refinancing.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
RefinanceLoan.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RefinanceLoan);
