import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const CheckingSavingsAccount = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Checking vs Savings Account</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/checking-savings.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWord}>
                      Finance Explained
                      <span> | </span>
                      Checking vs Savings Account
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/checking-v-savings.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Checking accounts and savings accounts are both bank
                          accounts that safely store your money for later use.
                          But, what’s the difference? Explore the table below
                          and find out the major distinctions.
                        </p>
                        <div className={classes.galleryImage}>
                          <img
                            src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/table_savings.png"
                            alt=""
                          />
                        </div>
                        <p
                          className={classNames(
                            classes.smallText,
                            classes.italicStyle,
                          )}>
                          * Up to six transfers or withdrawals a month before
                          fees (varies by bank, but federal law limits the
                          number of monthly transactions to six before fees)
                        </p>
                        <p
                          className={classNames(
                            classes.smallText,
                            classes.italicStyle,
                          )}>
                          ** High-yield savings accounts do earn more interest
                          than regular savings accounts.
                        </p>
                        <p className={classes.smallText}>
                          Since you use money in your checking more frequently
                          than your savings via your debit card, checks, and ATM
                          withdrawals, banks charge more fees and pay less
                          interest on money in your checking account. On the
                          other hand, you make way fewer transactions in your
                          savings account so banks can pay you interest on your
                          money and charge you fewer fees due to lower activity.
                        </p>
                        <p className={classes.smallText}>
                          The number one reason to keep more money in a savings
                          account is to earn interest while you save money for
                          emergencies or longer-term needs.
                        </p>
                      </div>
                    </div>
                    {/* <div className={ classes.pfRow }>
                      <div className={ classes.feText }>
                      </div>
                    </div> */}
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
CheckingSavingsAccount.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckingSavingsAccount);
