import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import compose from 'recompose/compose';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from './styles';
import * as moduleServices from '../../../calculations/modules-services';

const InputStatementPopup = (props) => {
  const {
    classes, detailedInfo, openGrossIncomeModel, assumptionsInfo, openHomePropertyTaxesExpensesModel,
    openDialog, setOpenDialog, openDialogName
  } = props;

  let IncomeGrowth = parseFloat(moduleServices.module1Data.incomeGrowth);
  let Inflation = parseFloat(moduleServices.module1Data.inflation);

  return (
    <div className={classes.modalBodyData}>
      <Dialog
        open={openDialogName === 'homePropertyIncomeEarnedDuringHogher' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.incomeStatementDialog}
      >
        <DialogContent>
          <div>
            <div>
              <div>
                <table className={classes.netIncomeTable}>
                  <tbody>
                    <tr className={classes.popupHeader}>
                      <td colspan="3" className={classes.strongText}>{detailedInfo.headingHigherEducation}</td>
                      <td >Year {detailedInfo['Year']}</td>
                    </tr>
                    <tr>
                      <td >&nbsp;</td>
                    </tr>
                    {detailedInfo.finalYearValueCondition === 1 &&  detailedInfo.firstHigherEducationStartYear > 0 && <tr>
                      <td>&nbsp;</td>
                      <td className={ classes.textCenter }>Start Year</td>
                      <td className={ classes.textCenter }>End Year</td>
                      <td className={ classes.textCenter }>Annual Income</td>
                    </tr>}
                    {detailedInfo.finalYearValueCondition === 1 && detailedInfo.firstHigherEducationStartYear > 0 &&<tr>
                      <td>&nbsp;</td>
                      <td >{detailedInfo.firstHigherEducationStartYear}</td>
                      <td >{detailedInfo.firstHigherEducationEndYear}</td>
                      <td>
                        <span className={classNames(detailedInfo.firstHigherEducationAnnualIncome < 0 && classes.minusValue)}>
                          {detailedInfo.firstHigherEducationAnnualIncome >= 0 ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.firstHigherEducationAnnualIncome} prefix="$" />
                          ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.firstHigherEducationAnnualIncome} prefix="($" suffix=")" />
                          )}
                        </span>
                      </td>
                    </tr>}
                    {detailedInfo.finalYearValueCondition === 1 && <tr>
                      <td >&nbsp;</td>
                    </tr>}
                    {(detailedInfo.previousYearValueCondition === 1 && detailedInfo.finalYearValueCondition === 1) && <tr className={classes.popupHeader}>
                      {/* <td>&nbsp;</td> */}
                      <td colspan="3" className={classes.strongText}>{detailedInfo['Year'] - 1} Income Earned during Higher Education</td>
                      <td >
                        {/* <span class="hidden">{{val84001 = detailedInfo.previousYearValue }}</span> */}
                        {/* <span data-ng-if="val84001 < 0" class="minus-value">({{ -(val84001) | currency:'$':2 }})</span> */}
                        {/* <span data-ng-if="val84001 >= 0">{{ val84001 | currency:'$':2 }}</span> */}
                        <span className={classNames(detailedInfo.previousYearValue < 0 && classes.minusValue)}>

                          {detailedInfo.previousYearValue >= 0 ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.previousYearValue} prefix="$" />
                          ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.previousYearValue} prefix="($" suffix=")" />
                          )}
                        </span>
                      </td>
                    </tr>}
                    {(detailedInfo.previousYearValueCondition === 1 && detailedInfo.finalYearValueCondition === 1) && <tr>
                      <td>x</td>
                      <td>Annual Income Growth2</td>
                      {detailedInfo.incomeGrowthValue && <td ><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.incomeGrowthValue <= 0 && detailedInfo.incomeGrowthValue > 0 ? detailedInfo.incomeGrowthValue : 0} suffix="%" /></td>}
                    </tr>}
                    <tr className={classes.incomeBorder}>
                      <td >=</td>
                      <td colspan="2">{detailedInfo['Year']} Total Income Earned during Higher Education</td>
                      <td >
                        {/* <span class="hidden">{{val84002 = detailedInfo.finalYearValue }}</span> */}
                        {/*// <span data-ng-if="val84002 < 0" class="minus-value">({{ -(val84002) | currency:'$':2 }})</span>*/}
                        {/* // <span data-ng-if="val84002 >= 0">{{ val84002 | currency:'$':2 }}</span> */}
                        <span className={classNames(detailedInfo.finalYearValue < 0 && classes.minusValue)}>

                          {detailedInfo.finalYearValue >= 0 ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.finalYearValue} prefix="$" />
                          ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.finalYearValue} prefix="($" suffix=")" />
                          )}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogName === 'showDetailPopup' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.incomeStatementDialog}
      >
        <DialogContent>
          <div>

            {openGrossIncomeModel === 'income-section' &&
              <table className={classes.netIncomeTable}>
                <tbody>
                  <tr className={classes.popupHeader}>
                    <td>&nbsp;</td>
                    <td colspan="2">
                      {detailedInfo['selectedColumn'] === 'Spouses Income' && <span>Spouse's Income</span>}
                      {detailedInfo['selectedColumn'] !== 'Spouses Income' && <span>{detailedInfo['selectedColumn']}</span>}
                    </td>
                    <td>Year {detailedInfo['Year']}</td>
                  </tr>
                  <tr>
                    <td >&nbsp;</td>
                  </tr>
                  {detailedInfo.StartYear > 0 && detailedInfo['Gross Income'][detailedInfo['selectedColumn']] > 0 && (detailedInfo.condition === 2 || detailedInfo.condition === 1) && <tr>
                    <td>&nbsp;</td>
                    <td className={ classNames( classes.strongText, classes.textCenter ) }>Start Year</td>
                    <td className={ classNames( classes.strongText, classes.textCenter ) }>End Year</td>
                    <td className={ classNames( classes.strongText, classes.textCenter ) }>Annual Income</td>
                  </tr>}
                  {detailedInfo.StartYear > 0 && detailedInfo['Gross Income'][detailedInfo['selectedColumn']] > 0 && (detailedInfo.condition === 2 || detailedInfo.condition === 1) && <tr>
                    <td>&nbsp;</td>
                    <td className={ classNames( classes.tdBorder, classes.bdRightZero, classes.textCenter ) }>{detailedInfo.StartYear}</td>
                    <td className={ classNames( classes.tdBorder, classes.bdRightZero, classes.textCenter  ) }>{detailedInfo.EndYear}</td>
                    <td className={classNames(detailedInfo.YearlyIncome < 0 && classes.minusValue, classes.tdBorder, classes.textCenter )}>

                      {detailedInfo['Gross Income'][detailedInfo['selectedColumn']] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Gross Income'][detailedInfo['selectedColumn']]} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Gross Income'][detailedInfo['selectedColumn']]} prefix="($" suffix=")" />
                      )}
                    </td>
                  </tr>}
                  {detailedInfo.StartYear > 0 && detailedInfo['Gross Income'][detailedInfo['selectedColumn']] > 0 && (detailedInfo.condition === 2 || detailedInfo.condition === 1) && <tr>
                    <td >&nbsp;</td>
                  </tr>}
                  {(detailedInfo.condition === 0 || detailedInfo.condition === 1) && <tr className={classes.incomeBorder}>
                    <td>=</td>
                    <td colspan="2">{detailedInfo['Year']} {detailedInfo['Label']}</td>
                    <td className={classNames(detailedInfo['Gross Income'][detailedInfo['selectedColumn']] < 0 && classes.minusValue)}>

                      {detailedInfo['Gross Income'][detailedInfo['selectedColumn']] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Gross Income'][detailedInfo['selectedColumn']]} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Gross Income'][detailedInfo['selectedColumn']]} prefix="($" suffix=")" />
                      )}
                    </td>
                  </tr>}
                  {detailedInfo.StartYear > 0 && detailedInfo['Gross Income'][detailedInfo['selectedColumn']] > 0 && detailedInfo.condition === 2 && <tr>
                    <td>&nbsp;</td>
                    <td colspan="2">{detailedInfo['Year'] - 1} {detailedInfo['Label']}</td>
                    <td className={classNames(detailedInfo.previousIncomeValue < 0 && classes.minusValue, classes.textRight)}>
                      {detailedInfo.previousIncomeValue >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.previousIncomeValue} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.previousIncomeValue < 0 ? detailedInfo.previousIncomeValue : 0} prefix="($" suffix=")" />
                      )}
                    </td>
                  </tr>}
                  {detailedInfo.StartYear > 0 && detailedInfo['Gross Income'][detailedInfo['selectedColumn']] > 0 && detailedInfo.condition === 2 && <tr>
                    <td>x</td>
                    <td colspan="2">Annual Income Growth3</td>
                    {IncomeGrowth > 0 && IncomeGrowth <= 0 ? <td className={classes.textRight}>{IncomeGrowth}%</td> : <td>0%</td>}
                  </tr>}
                  {detailedInfo.condition === 2 && <tr className={classes.incomeBorder}>
                    <td>=</td>
                    <td colspan="2">{detailedInfo['Year']} {detailedInfo['Label']}</td>
                    <td className={classNames(detailedInfo['Gross Income'][detailedInfo['selectedColumn']] < 0 && classes.minusValue, classes.textRight)}>
                      {(detailedInfo['Gross Income'][detailedInfo['selectedColumn']]) >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={(detailedInfo['Gross Income'][detailedInfo['selectedColumn']])} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(detailedInfo['Gross Income'][detailedInfo['selectedColumn']])} prefix="($" suffix=")" />
                      )}
                    </td>
                  </tr>}
                </tbody>
              </table>
            }
            {openGrossIncomeModel === 'net-rental-income' && <table>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Net Rental Income</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo.finalValueCondition === 1 && <tr>
                  <td></td>
                  <td colspan="2">Rental Income</td>
                  <td>
                    <span className={classNames(detailedInfo['Rental Income'] < 0 && classes.minusValue)}>

                      {detailedInfo['Rental Income'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Rental Income']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Rental Income']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Association Fees</td>
                  <td>
                    <span className={classNames(detailedInfo['Association Fees'] < 0 && classes.minusValue)}>

                      {detailedInfo['Association Fees'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Association Fees']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Association Fees']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Management Fee</td>
                  <td>
                    <span className={classNames(detailedInfo['Management Fees'] < 0 && classes.minusValue)}>

                      {detailedInfo['Management Fees'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Management Fees']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Management Fees']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Homeowner's Insurance</td>

                  <td>
                    <span className={classNames(detailedInfo['Houseowners Insurance'] < 0 && classes.minusValue)}>

                      {detailedInfo['Houseowners Insurance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Houseowners Insurance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Houseowners Insurance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Property Maintenance</td>

                  <td>
                    <span className={classNames(detailedInfo['Property Maintenance'] < 0 && classes.minusValue)}>

                      {detailedInfo['Property Maintenance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Property Maintenance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Property Maintenance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Utilities</td>

                  <td>
                    <span className={classNames(detailedInfo['Utilities'] < 0 && classes.minusValue)}>

                      {detailedInfo['Utilities'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Utilities']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Utilities']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Property Taxes</td>

                  <td>
                    <span className={classNames(detailedInfo['Property Taxes'] < 0 && classes.minusValue)}>

                      {detailedInfo['Property Taxes'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Property Taxes']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Property Taxes']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                <tr>
                  <td>=</td>
                  <td colspan="2">Net Rental Income</td>

                  <td>
                    <span className={classNames(detailedInfo['Net Income'] < 0 && classes.minusValue)}>

                      {detailedInfo['Net Income'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Net Income']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Net Income']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'rental-income' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td colspan="3">Rental Income</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td >&nbsp;</td>
                </tr>
                {detailedInfo.annualRentalIncome > 0 && detailedInfo.annualRentalIncomeFinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Purchase Year / Sale Year</td>
                  <td>Annual Rental Income</td>
                  <td>Vacancy Rate</td>
                </tr>}
                {detailedInfo.annualRentalIncome > 0 && detailedInfo.annualRentalIncomeFinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>{detailedInfo.purchaseYear} / {detailedInfo.sellYear}</td>

                  <td>
                    <span className={classNames(detailedInfo.annualRentalIncome < 0 && classes.minusValue)}>

                      {detailedInfo.annualRentalIncome >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.annualRentalIncome} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.annualRentalIncome} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>

                  <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.vacancyRate} suffix="%" /></td>
                </tr>}
                {detailedInfo.annualRentalIncome > 0 && detailedInfo.annualRentalIncomeFinalCondition === 1 && <tr>
                  <td >&nbsp;</td>
                </tr>}
                {detailedInfo.annualRentalIncome > 0 && (detailedInfo.purchaseYearCondition === 1 && detailedInfo.annualRentalIncomeFinalCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Annual Rental Income</td>
                  <td>
                    <span className={classNames(detailedInfo.annualRentalIncomePrevious < 0 && classes.minusValue)}>

                      {detailedInfo.annualRentalIncomePrevious >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.annualRentalIncomePrevious} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.annualRentalIncomePrevious} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.annualRentalIncome > 0 && (detailedInfo.purchaseYearCondition === 1 && detailedInfo.annualRentalIncomeFinalCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Rental Income Growth</td>
                  <td>{detailedInfo.incomeGrowthStarts}%</td>
                </tr>}
                {/* {detailedInfo.annualRentalIncome > 0 && detailedInfo.annualRentalIncomeFinalCondition === 1 && <tr>
                  <td>=</td>
                  <td>{detailedInfo['Year']} Annual Rental Income</td>
                  <td>
                  
                    <span className={classNames(detailedInfo.annual_income_growth_rate < 0 && classes.minusValue)}>

                      {detailedInfo.annualRentalIncomeCurrent >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.annualRentalIncomeCurrent} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.annualRentalIncomeCurrent} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>} */}
                {detailedInfo.annualRentalIncome > 0 && detailedInfo.annualRentalIncomeFinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Vacancy Rate</td>
                  <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.vacancyRate} suffix="%" /></td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2" className={classes.strongText}>{detailedInfo['Year']} Rental Income</td>
                  <td>
                    <span className={classNames(detailedInfo.annualRentalIncomeCurrent < 0 && classes.minusValue)}>

                      {detailedInfo.annualRentalIncomeCurrent >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.annualRentalIncomeCurrent} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.annualRentalIncomeCurrent} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'rental-operating-expenses' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td colspan="2">Rental Operating Expenses</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td >&nbsp;</td>
                </tr>
                {detailedInfo.realEstateExpensesFinalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Management Fee</td>
                  <td>
                    <span className={classNames(detailedInfo['managementFee'] < 0 && classes.minusValue)}>

                      {detailedInfo['managementFee'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['managementFee']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['managementFee']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.realEstateExpensesFinalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Homeowner's Insurance</td>
                  <td>
                    <span className={classNames(detailedInfo['homeOwnersInsurance'] < 0 && classes.minusValue)}>

                      {detailedInfo['homeOwnersInsurance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['homeOwnersInsurance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['homeOwnersInsurance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.realEstateExpensesFinalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Property Maintenance</td>
                  <td>
                    <span className={classNames(detailedInfo['propertyMaintenance'] < 0 && classes.minusValue)}>


                      {detailedInfo['propertyMaintenance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['propertyMaintenance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['propertyMaintenance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.realEstateExpensesFinalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Association Fees</td>
                  <td>
                    <span className={classNames(detailedInfo['associationFees'] < 0 && classes.minusValue)}>

                      {detailedInfo['associationFees'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['associationFees']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['associationFees']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.realEstateExpensesFinalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Utilities</td>

                  <td>
                    <span className={classNames(detailedInfo['utilities'] < 0 && classes.minusValue)}>

                      {detailedInfo['utilities'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['utilities']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['utilities']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">Total Rental Operating Expenses</td>

                  <td>
                    <span className={classNames(detailedInfo['realEstateExpensesFinalValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['realEstateExpensesFinalValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['realEstateExpensesFinalValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['realEstateExpensesFinalValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'home-property-expenses-new' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td colspan="2">Home Operating Expenses</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td >&nbsp;</td>
                </tr>
                {detailedInfo.totalHomeOperatingExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Private Mortgage Insurance (PMI)</td>
                  <td>
                    <span className={classNames(detailedInfo['privateMortgageInsurance'] < 0 && classes.minusValue)}>

                      {detailedInfo['privateMortgageInsurance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['privateMortgageInsurance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['privateMortgageInsurance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalHomeOperatingExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Homeowner's Insurance</td>
                  <td>
                    <span className={classNames(detailedInfo['homeOwnersInsurance'] < 0 && classes.minusValue)}>

                      {detailedInfo['homeOwnersInsurance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['homeOwnersInsurance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['homeOwnersInsurance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalHomeOperatingExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Property Maintenance</td>
                  <td>
                    <span className={classNames(detailedInfo['propertyMaintenance'] < 0 && classes.minusValue)}>

                      {detailedInfo['propertyMaintenance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['propertyMaintenance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['propertyMaintenance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalHomeOperatingExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Association Fees</td>
                  <td>
                    <span className={classNames(detailedInfo['associationFees'] < 0 && classes.minusValue)}>

                      {detailedInfo['associationFees'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['associationFees']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['associationFees']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalHomeOperatingExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Utilities</td>
                  <td>

                    <span className={classNames(detailedInfo['utilities'] < 0 && classes.minusValue)}>

                      {detailedInfo['utilities'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['utilities']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['utilities']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalHomeOperatingExpensesCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">Total Home Operating Expenses</td>
                  <td>
                    <span className={classNames(detailedInfo['totalHomeOperatingExpenses'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalHomeOperatingExpenses'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalHomeOperatingExpenses']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalHomeOperatingExpenses']} prefix="($" suffix=")" />
                      )}
                    </span>

                  </td>
                </tr>}
                {(detailedInfo.totalHomeOperatingExpensesCondition === 1 && detailedInfo.totalHomeOperatingExpensesCondition2 === 1) && <tr>
                  <td >&nbsp;</td>
                </tr>}
                {detailedInfo.totalHomeOperatingExpensesCondition2 === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Private Mortgage Insurance (PMI)</td>
                  <td>

                    <span className={classNames(detailedInfo['privateMortgageInsurance2'] < 0 && classes.minusValue)}>

                      {detailedInfo['privateMortgageInsurance2'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['privateMortgageInsurance2']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['privateMortgageInsurance2']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalHomeOperatingExpensesCondition2 === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Homeowner's Insurance</td>
                  <td>
                    <span className={classNames(detailedInfo['homeOwnersInsurance2'] < 0 && classes.minusValue)}>

                      {detailedInfo['homeOwnersInsurance2'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['homeOwnersInsurance2']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['homeOwnersInsurance2']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalHomeOperatingExpensesCondition2 === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Property Maintenance</td>
                  <td>
                    <span className={classNames(detailedInfo['propertyMaintenance2'] < 0 && classes.minusValue)}>

                      {detailedInfo['propertyMaintenance2'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['propertyMaintenance2']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['propertyMaintenance2']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalHomeOperatingExpensesCondition2 === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Association Fees</td>
                  <td>
                    <span className={classNames(detailedInfo['associationFees2'] < 0 && classes.minusValue)}>

                      {detailedInfo['associationFees2'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['associationFees2']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['associationFees2']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalHomeOperatingExpensesCondition2 === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Utilities</td>
                  <td>
                    <span className={classNames(detailedInfo['utilities2'] < 0 && classes.minusValue)}>

                      {detailedInfo['utilities2'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['utilities2']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['utilities2']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalHomeOperatingExpensesCondition2 === 1 && <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">Total Existing Home Operating Expenses</td>
                  <td>
                    <span className={classNames(detailedInfo['totalHomeOperatingExpenses2'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalHomeOperatingExpenses2'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalHomeOperatingExpenses2']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalHomeOperatingExpenses2']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {((detailedInfo.totalHomeOperatingExpensesCondition2 === 1 && detailedInfo.totalHomeOperatingExpensesCondition === 1) || (detailedInfo.totalHomeOperatingExpensesCondition2 === 0 && detailedInfo.totalHomeOperatingExpensesCondition === 0)) && <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">Total Home Operating Expenses</td>
                  <td>
                    <span className={classNames(detailedInfo['homepropertyExpensesFinalValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['homepropertyExpensesFinalValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['homepropertyExpensesFinalValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['homepropertyExpensesFinalValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
              </tbody>
            </table>
            }

            {openGrossIncomeModel === 'total-taxes' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td colspan="2">Total Taxes</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Total Taxes before Credits</td>
                  <td>
                    <span className={classNames(detailedInfo['totalTaxesBeforeCredits'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalTaxesBeforeCredits'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalTaxesBeforeCredits']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalTaxesBeforeCredits']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Total Tax Credits</td>
                  <td>

                    <span className={classNames(detailedInfo['totalTaxCredits'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalTaxCredits'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalTaxCredits']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalTaxCredits']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Total Tax on Real Estate Sales</td>
                  <td>
                    <span className={classNames(detailedInfo['totalTaxRealEstateValues'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalTaxRealEstateValues'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalTaxRealEstateValues']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalTaxRealEstateValues']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">Total Taxes</td>
                  <td>
                    <span className={classNames(detailedInfo['totalTaxFinalValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalTaxFinalValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalTaxFinalValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalTaxFinalValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'income-after-taxes' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Income After Taxes</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Total Income</td>
                  <td>
                    <span className={classNames(detailedInfo['Total Income'] < 0 && classes.minusValue)}>

                      {detailedInfo['Total Income'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Total Income']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Total Income']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>-</td>
                  <td>Total Taxes</td>
                  <td>
                    <span className={classNames(detailedInfo['Total Taxes'] < 0 && classes.minusValue)}>

                      {detailedInfo['Total Taxes'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Total Taxes']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Total Taxes']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td>Income After Taxes</td>
                  <td>

                    <span className={classNames(detailedInfo['Income After Taxes'] < 0 && classes.minusValue)}>

                      {detailedInfo['Income After Taxes'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Income After Taxes']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Income After Taxes']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }

            {openGrossIncomeModel === 'off-campus-food' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td colspan="2">Off Campus Food</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td >&nbsp;</td>
                </tr>
                {detailedInfo.StartYear > 0 &&(detailedInfo.condition === 1 || detailedInfo.condition === 2) && <tr>
                  <td>&nbsp;</td>
                  <td className={ classes.textCenter }>Start Year</td>
                  <td className={ classes.textCenter }>End Year</td>
                  <td className={ classes.textCenter }>Annual Off Campus Food</td>
                </tr>}
                {detailedInfo.StartYear > 0 && (detailedInfo.condition === 1 || detailedInfo.condition === 2) && <tr>
                  <td>&nbsp;</td>
                  <td>{detailedInfo.StartYear}</td>
                  <td>{detailedInfo.EndYear}</td>
                  <td>
                    <span className={classNames(detailedInfo.Food < 0 && classes.minusValue)}>
                      {detailedInfo.Food >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.Food} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(detailedInfo.Food < 0 ? detailedInfo.Food : 0)} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.condition === 1 || detailedInfo.condition === 2) && <tr>
                  <td >&nbsp;</td>
                </tr>}
                {detailedInfo.condition === 0 && <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Off Campus Food</td>
                  <td>0</td>
                </tr>}
                {detailedInfo.condition === 1 && <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Off Campus Food</td>
                  <td>
                    <span className={classNames(detailedInfo['livingExpenses']['Off Campus Food'] < 0 && classes.minusValue)}>

                      {detailedInfo['livingExpenses']['Off Campus Food'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['livingExpenses']['Off Campus Food']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['livingExpenses']['Off Campus Food']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.condition === 2 && <tr className={classes.incomeBorder}>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Off Campus Food</td>
                  <td>
                    <span className={classNames(detailedInfo.previousIncomeValue < 0 && classes.minusValue)}>

                      {detailedInfo.previousIncomeValue >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.previousIncomeValue} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.previousIncomeValue} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.condition === 2 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Inflation</td>
                  <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={assumptionsInfo.Expenses - 100} suffix="%" /></td>
                </tr>}
                {detailedInfo.condition === 2 && <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Off Campus Food</td>
                  <td>
                    <span className={classNames(detailedInfo.previousIncomeValue < 0 && classes.minusValue)}>

                      {detailedInfo.previousIncomeValue >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.previousIncomeValue} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.previousIncomeValue} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'children' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td colspan="2">Children Expense</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td >&nbsp;</td>
                </tr>
                {detailedInfo.childrenFinalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Annual Cost of a Child in Year {detailedInfo['Year'] - 1}</td>
                  <td>

                    <span className={classNames(detailedInfo['databaseData']['Children Info'][detailedInfo['Year'] - 1]['value'] < 0 && classes.minusValue)}>

                      {(detailedInfo['databaseData']['Children Info'][detailedInfo['Year'] - 1]['value']) >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={(detailedInfo['databaseData']['Children Info'][detailedInfo['Year'] - 1]['value'])} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(detailedInfo['databaseData']['Children Info'][detailedInfo['Year'] - 1]['value'])} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.childrenFinalValueCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Inflation</td>
                  <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={Inflation > 0 ? Inflation : 0} suffix="%" /></td>
                </tr>}
                {detailedInfo.childrenFinalValueCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">Annual Cost of a Child in Year {detailedInfo['Year']}</td>
                  <td>

                    <span className={classNames(detailedInfo['databaseData']['Children Info'][detailedInfo['Year']]['value'] < 0 && classes.minusValue)}>

                      {(detailedInfo['databaseData']['Children Info'][detailedInfo['Year']]['value']) >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={(detailedInfo['databaseData']['Children Info'][detailedInfo['Year']]['value'])} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(detailedInfo['databaseData']['Children Info'][detailedInfo['Year']]['value'])} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.childrenFinalValueCondition === 1 && <tr>
                  <td >&nbsp;</td>
                </tr>}
                {detailedInfo.childrenFinalValueCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2"># of Children</td>
                  <td>{detailedInfo['totalChild']}x</td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">Total Children Expense</td>
                  <td>
                    <span className={classNames(detailedInfo['livingExpenses']['Children'] < 0 && classes.minusValue)}>

                      {detailedInfo['livingExpenses']['Children'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['livingExpenses']['Children']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['livingExpenses']['Children']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'pet' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td colspan="2">Pet Expense</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td >&nbsp;</td>
                </tr>
                {/* {detailedInfo.condition === 0 && <tr>
                  <td>=</td>
                  <td>Total Pet Expense</td>
                  <td>
                    <span className={classNames(detailedInfo['Pet'] < 0 && classes.minusValue)}>

                      {detailedInfo['livingExpenses']['Total'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['livingExpenses']['Total']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['livingExpenses']['Total']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>} */}
                {detailedInfo.condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2" className={ classNames( classes.strongText, classes.textCenter ) }>Purchase Year</td>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2" className={ classNames( classes.strongText, classes.tdBorder, classes.textCenter ) }>{detailedInfo['purchaseYear']}</td>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.condition === 1 && <tr>
                  <td >&nbsp;</td>
                </tr>}
                {detailedInfo.condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2" >Vet Visits</td>
                  <td>

                    <span className={classNames(detailedInfo['expenseCalculation']['Vet Visits'] < 0 && classes.minusValue)}>

                      {detailedInfo['expenseCalculation']['Vet Visits'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['expenseCalculation']['Vet Visits']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['expenseCalculation']['Vet Visits']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Food</td>
                  <td>

                    <span className={classNames(detailedInfo['expenseCalculation']['Food'] < 0 && classes.minusValue)}>

                      {detailedInfo['expenseCalculation']['Food'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['expenseCalculation']['Food']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['expenseCalculation']['Food']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Toys</td>
                  <td>
                    <span className={classNames(detailedInfo['expenseCalculation']['Toys'] < 0 && classes.minusValue)}>

                      {detailedInfo['expenseCalculation']['Toys'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['expenseCalculation']['Toys']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['expenseCalculation']['Toys']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Emergency Fund</td>
                  <td>
                    <span className={classNames(detailedInfo['expenseCalculation']['Emergency Fund'] < 0 && classes.minusValue)}>

                      {detailedInfo['expenseCalculation']['Emergency Fund'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['expenseCalculation']['Emergency Fund']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['expenseCalculation']['Emergency Fund']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Pet Health Insurance</td>
                  <td>

                    <span className={classNames(detailedInfo['expenseCalculation']['Pet Health Insurance'] < 0 && classes.minusValue)}>

                      {detailedInfo['expenseCalculation']['Pet Health Insurance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['expenseCalculation']['Pet Health Insurance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['expenseCalculation']['Pet Health Insurance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Miscellaneous</td>
                  <td>

                    <span className={classNames(detailedInfo['expenseCalculation']['Miscellaneous'] < 0 && classes.minusValue)}>

                      {detailedInfo['expenseCalculation']['Miscellaneous'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['expenseCalculation']['Miscellaneous']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['expenseCalculation']['Miscellaneous']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Registration</td>
                  <td>

                    <span className={classNames(detailedInfo['expenseCalculation']['Registration'] < 0 && classes.minusValue)}>

                      {detailedInfo['expenseCalculation']['Registration'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['expenseCalculation']['Registration']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['expenseCalculation']['Registration']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">Total Pet Expense</td>
                  <td>
                    <span className={classNames(detailedInfo['expenseCalculation']['Total'] < 0 && classes.minusValue)}>

                      {detailedInfo['expenseCalculation']['Total'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['expenseCalculation']['Total']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(detailedInfo['expenseCalculation']['Total'] < 0 ? detailedInfo['expenseCalculation']['Total'] : 0)} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'home-property-expenses' && <table>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Home Property Expenses</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo.totalNewHomeExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Private Mortgage Insurance (PMI)</td>
                  <td>
                    <span className={classNames(detailedInfo['Private Mortgage Insurance'] < 0 && classes.minusValue)}>

                      {detailedInfo['Private Mortgage Insurance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Private Mortgage Insurance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Private Mortgage Insurance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalNewHomeExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Homeowner's Insurance</td>
                  <td>
                    <span className={classNames(detailedInfo['Houseowners Insurance'] < 0 && classes.minusValue)}>

                      {detailedInfo['Houseowners Insurance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Houseowners Insurance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Houseowners Insurance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalNewHomeExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Property Maintenance</td>
                  <td>
                    <span className={classNames(detailedInfo['Property Maintenance'] < 0 && classes.minusValue)}>

                      {detailedInfo['Property Maintenance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Property Maintenance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Property Maintenance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalNewHomeExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Association Fees</td>
                  <td>
                    <span className={classNames(detailedInfo['Association Fees'] < 0 && classes.minusValue)}>

                      {detailedInfo['Association Fees'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Association Fees']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Association Fees']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalNewHomeExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Utilities</td>
                  <td>

                    <span className={classNames(detailedInfo['Utilities'] < 0 && classes.minusValue)}>

                      {detailedInfo['Utilities'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Utilities']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Utilities']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalNewHomeExpensesCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">Total Home Property Expenses</td>
                  <td>
                    <span className={classNames((detailedInfo['Private Mortgage Insurance'] + detailedInfo['Association Fees'] + detailedInfo['Houseowners Insurance'] + detailedInfo['Property Maintenance'] + detailedInfo['Utilities']) < 0 && classes.minusValue)}>

                      {(detailedInfo['Private Mortgage Insurance'] + detailedInfo['Association Fees'] + detailedInfo['Houseowners Insurance'] + detailedInfo['Property Maintenance'] + detailedInfo['Utilities']) >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={(detailedInfo['Private Mortgage Insurance'] + detailedInfo['Association Fees'] + detailedInfo['Houseowners Insurance'] + detailedInfo['Property Maintenance'] + detailedInfo['Utilities'])} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(detailedInfo['Private Mortgage Insurance'] + detailedInfo['Association Fees'] + detailedInfo['Houseowners Insurance'] + detailedInfo['Property Maintenance'] + detailedInfo['Utilities'])} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.totalNewHomeExpensesCondition === 1 && detailedInfo.totalExistingHomeExpensesCondition === 1) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.totalExistingHomeExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Private Mortgage Insurance (PMI)</td>
                  <td>

                    <span className={classNames(detailedInfo['Private Mortgage Insurance'] < 0 && classes.minusValue)}>

                      {detailedInfo['Private Mortgage Insurance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Private Mortgage Insurance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Private Mortgage Insurance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalExistingHomeExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Homeowner's Insurance</td>
                  <td>
                    <span className={classNames(detailedInfo['Houseowners Insurance'] < 0 && classes.minusValue)}>

                      {detailedInfo['Houseowners Insurance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Houseowners Insurance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Houseowners Insurance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalExistingHomeExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Property Maintenance</td>
                  <td>

                    <span className={classNames(detailedInfo['Maintenance'] < 0 && classes.minusValue)}>

                      {detailedInfo['Maintenance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Maintenance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Maintenance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalExistingHomeExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Association Fees</td>
                  <td>

                    <span className={classNames(detailedInfo['Association Fees'] < 0 && classes.minusValue)}>

                      {detailedInfo['Association Fees'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Association Fees']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Association Fees']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalExistingHomeExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Utilities</td>
                  <td>
                    <span className={classNames(detailedInfo['Utilities'] < 0 && classes.minusValue)}>

                      {detailedInfo['Utilities'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Utilities']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Utilities']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.totalExistingHomeExpensesCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">Total Existing Home Property Expenses</td>
                  <td>
                    <span className={classNames(detailedInfo['Private Mortgage Insurance'] < 0 && classes.minusValue)}>

                      {detailedInfo['Private Mortgage Insurance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Private Mortgage Insurance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Private Mortgage Insurance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {((detailedInfo.totalNewHomeExpensesCondition === 1 && detailedInfo.totalExistingHomeExpensesCondition === 1) || (detailedInfo.totalNewHomeExpensesCondition === 0 && detailedInfo.totalExistingHomeExpensesCondition === 0)) && <tr>
                  <td>=</td>
                  <td colspan="2">Total Home Property Expenses</td>
                  <td>

                    <span className={classNames(detailedInfo['propertyExpenseFinalValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['propertyExpenseFinalValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['propertyExpenseFinalValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['propertyExpenseFinalValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'carloan-interestexpenses' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td className={classNames(classes.strongText, classes.textCenter)} colspan="2">Car Loan Payments</td>
                  <td className={classNames(classes.strongText, classes.textRight)}>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo.existingCarLoanCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Average Existing Car Loan Balance in Year {detailedInfo['Year']}</td>
                  <td>
                    <span className={classNames(detailedInfo['existingCarLoan'] < 0 && classes.minusValue)}>

                      {detailedInfo['existingCarLoan'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['existingCarLoan']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['existingCarLoan']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.existingCarLoanCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Interest Rate</td>
                  <td>{detailedInfo['existingCarLoanInterestRate']}%</td>
                </tr>}
                {detailedInfo.existingCarLoanCondition === 1 && <tr>
                  <td>=/+</td>
                  <td colspan="2">Existing Car Loan Interest Expense</td>
                  <td>
                    <span className={classNames(detailedInfo['existingCarLoanInterest'] < 0 && classes.minusValue)}>

                      {detailedInfo['existingCarLoanInterest'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['existingCarLoanInterest']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['existingCarLoanInterest']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.existingCarLoanCondition === 1 && <tr>
                  <td>+</td>
                  <td colspan="2">Principal Payback</td>
                  <td>
                    <span className={classNames(detailedInfo['existingCarLoanPrincipal'] < 0 && classes.minusValue)}>

                      {detailedInfo['existingCarLoanPrincipal'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['existingCarLoanPrincipal']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['existingCarLoanPrincipal']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.existingCarLoanCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">Existing Car Loan Payment</td>
                  <td>
                    <span className={classNames(detailedInfo['existingCarLoanPayments'] < 0 && classes.minusValue)}>

                      {detailedInfo['existingCarLoanPayments'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['existingCarLoanPayments']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['existingCarLoanPayments']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}

                {detailedInfo.existingCarLoanCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Average Car Loan 1 Balance in Year {detailedInfo['Year']}</td>
                  <td>

                    <span className={classNames(detailedInfo['carLoan1'] < 0 && classes.minusValue)}>

                      {detailedInfo['carLoan1'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carLoan1']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carLoan1']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.existingCarLoanCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Interest Rate</td>
                  <td>{detailedInfo['carLoan1InterestRate'] > 0 ? detailedInfo['carLoan1InterestRate'] : 0}%</td>
                </tr>}
                {detailedInfo.carLoan1Condition === 1 && <tr>
                  <td>=/+</td>
                  <td colspan="2">Car Loan 1 Interest Expense</td>
                  <td>

                    <span className={classNames(detailedInfo['carLoan1Interest'] < 0 && classes.minusValue)}>

                      {detailedInfo['carLoan1Interest'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carLoan1Interest']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carLoan1Interest']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carLoan1Condition === 1 && <tr>
                  <td>+</td>
                  <td colspan="2">Principal Payback</td>
                  <td>
                    <span className={classNames(detailedInfo['carLoan1Principal'] < 0 && classes.minusValue)}>

                      {detailedInfo['carLoan1Principal'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carLoan1Principal']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carLoan1Principal']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carLoan1Condition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">Car Loan 1 Payment</td>
                  <td>
                    <span className={classNames(detailedInfo['carLoan1Payments'] < 0 && classes.minusValue)}>

                      {detailedInfo['carLoan1Payments'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carLoan1Payments']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carLoan1Payments']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}

                {detailedInfo.carLoan2Condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Average Car Loan 2 Balance in Year {detailedInfo['Year']}</td>
                  <td>

                    <span className={classNames(detailedInfo['carLoan2'] < 0 && classes.minusValue)}>

                      {detailedInfo['carLoan2'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carLoan2']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carLoan2']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carLoan2Condition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Interest Rate</td>
                  <td>{detailedInfo['carLoan2InterestRate']}%</td>
                </tr>}
                {detailedInfo.carLoan2Condition === 1 && <tr>
                  <td>=/+</td>
                  <td colspan="2">Car Loan 2 Interest Expense</td>
                  <td>
                    <span className={classNames(detailedInfo['carLoan2Interest'] < 0 && classes.minusValue)}>

                      {detailedInfo['carLoan2Interest'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carLoan2Interest']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carLoan2Interest']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carLoan2Condition === 1 && <tr>
                  <td>+</td>
                  <td colspan="2">Principal Payback</td>
                  <td>

                    <span className={classNames(detailedInfo['carLoan2Principal'] < 0 && classes.minusValue)}>

                      {detailedInfo['carLoan2Principal'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carLoan2Principal']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carLoan2Principal']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carLoan2Condition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">Car Loan 2 Payment</td>
                  <td>

                    <span className={classNames(detailedInfo['carLoan2Payments'] < 0 && classes.minusValue)}>

                      {detailedInfo['carLoan2Payments'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carLoan2Payments']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carLoan2Payments']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">Total Car Loan Payments</td>
                  <td>
                    <span className={classNames(detailedInfo['InterestExpenses']['Car Loans'] < 0 && classes.minusValue)}>

                      {detailedInfo['InterestExpenses']['Car Loans'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['InterestExpenses']['Car Loans']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['InterestExpenses']['Car Loans']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'mortgage-interestexpenses' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td className={classNames(classes.textCenter, classes.strongText)} colspan="2">Mortgage Payments</td>
                  <td className={classNames(classes.textRight, classes.strongText)}>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td >&nbsp;</td>
                </tr>
                {detailedInfo.mortgageLoan1Condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Average Home Mortgage Balance in Year {detailedInfo['Year']}</td>
                  <td>
                    <span className={classNames(detailedInfo['mortgageLoan1Value'] < 0 && classes.minusValue)}>

                      {detailedInfo['mortgageLoan1Value'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['mortgageLoan1Value']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['mortgageLoan1Value']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.mortgageLoan1Condition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Interest Rate</td>
                  <td>{detailedInfo['mortgageLoan1InterestRate'] > 0 ? detailedInfo['mortgageLoan1InterestRate'] : 0}%</td>
                </tr>}

                {(detailedInfo.mortgageLoan1Condition === 1 && detailedInfo.mortgageLoan1YearCondition === 1) && <tr>
                  <td>=/+</td>
                  <td colspan="2">Home Mortgage Interest Expense in Purchase Year</td>
                  <td>
                    <span className={classNames(detailedInfo['mortgageLoan1InterestExpensesInPurchaseYear'] < 0 && classes.minusValue)}>

                      {detailedInfo['mortgageLoan1InterestExpensesInPurchaseYear'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['mortgageLoan1InterestExpensesInPurchaseYear']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['mortgageLoan1InterestExpensesInPurchaseYear']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.mortgageLoan1Condition === 1 && detailedInfo.mortgageLoan1YearCondition === 1) && <tr>
                  <td>-</td>
                  <td colspan="2">Interest Paid Upfront at Close</td>
                  <td>

                    <span className={classNames(detailedInfo['mortgageLoan1InterestPaidUpfrontClose'] < 0 && classes.minusValue)}>

                      {detailedInfo['mortgageLoan1InterestPaidUpfrontClose'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['mortgageLoan1InterestPaidUpfrontClose']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['mortgageLoan1InterestPaidUpfrontClose']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.mortgageLoan1Condition === 1 && <tr>
                  <td>=/+</td>
                  <td colspan="2">Home Mortgage Interest Expense</td>
                  <td>
                    <span className={classNames(detailedInfo['mortgageLoan1Interest'] < 0 && classes.minusValue)}>

                      {detailedInfo['mortgageLoan1Interest'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['mortgageLoan1Interest']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['mortgageLoan1Interest']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.mortgageLoan1Condition === 1 && <tr>
                  <td>+</td>
                  <td colspan="2">Principal Payback</td>
                  <td>

                    <span className={classNames(detailedInfo['mortgageLoan1Principal'] < 0 && classes.minusValue)}>

                      {detailedInfo['mortgageLoan1Principal'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['mortgageLoan1Principal']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['mortgageLoan1Principal']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.mortgageLoan1Condition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">Home Mortgage Payment</td>
                  <td>
                    <span className={classNames(detailedInfo['mortgageLoan1LoanPayments'] < 0 && classes.minusValue)}>

                      {detailedInfo['mortgageLoan1LoanPayments'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['mortgageLoan1LoanPayments']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['mortgageLoan1LoanPayments']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.mortgageLoan2Condition === 1 && detailedInfo.mortgageLoan1Condition === 1 && <tr>
                  <td >&nbsp;</td>
                </tr>}
                {detailedInfo.mortgageLoan2Condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Average REI Property Mortgage Balance in Year {detailedInfo['Year']}</td>
                  <td>
                    <span className={classNames(detailedInfo['mortgageLoan2Value'] < 0 && classes.minusValue)}>

                      {detailedInfo['mortgageLoan2Value'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['mortgageLoan2Value']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['mortgageLoan2Value']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.mortgageLoan2Condition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Interest Rate</td>
                  <td>{detailedInfo['mortgageLoan2InterestRate'] > 0 ? detailedInfo['mortgageLoan2InterestRate'] : 0}%</td>
                </tr>}

                {(detailedInfo.mortgageLoan2Condition === 1 && detailedInfo.mortgageLoan2YearCondition === 1) && <tr>
                  <td>=/+</td>
                  <td colspan="2">REI Mortgage Interest Expense in Purchase Year</td>
                  <td>
                    <span className={classNames(detailedInfo['mortgageLoan2InterestExpensesInPurchaseYear'] < 0 && classes.minusValue)}>

                      {detailedInfo['mortgageLoan2InterestExpensesInPurchaseYear'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['mortgageLoan2InterestExpensesInPurchaseYear']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['mortgageLoan2InterestExpensesInPurchaseYear']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.mortgageLoan2Condition === 1 && detailedInfo.mortgageLoan2YearCondition === 1) && <tr>
                  <td>-</td>
                  <td colspan="2">Interest Paid Upfront at Close</td>
                  <td>

                    <span className={classNames(detailedInfo['mortgageLoan2InterestPaidUpfrontClose'] < 0 && classes.minusValue)}>

                      {detailedInfo['mortgageLoan2InterestPaidUpfrontClose'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['mortgageLoan2InterestPaidUpfrontClose']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['mortgageLoan2InterestPaidUpfrontClose']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}

                {detailedInfo.mortgageLoan2Condition === 1 && <tr>
                  <td>=/+</td>
                  <td colspan="2">REI Property Mortgage Interest Expense</td>
                  <td>

                    <span className={classNames(detailedInfo['mortgageLoan2Interest'] < 0 && classes.minusValue)}>

                      {detailedInfo['mortgageLoan2Interest'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['mortgageLoan2Interest']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['mortgageLoan2Interest']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.mortgageLoan2Condition === 1 && <tr>
                  <td>+</td>
                  <td colspan="2">Principal Payback</td>
                  <td>

                    <span className={classNames(detailedInfo['mortgageLoan2Principal'] < 0 && classes.minusValue)}>

                      {detailedInfo['mortgageLoan2Principal'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['mortgageLoan2Principal']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['mortgageLoan2Principal']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.mortgageLoan2Condition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">REI Property Mortgage Payment</td>
                  <td>

                    <span className={classNames(detailedInfo['mortgageLoan2LoanPayments'] < 0 && classes.minusValue)}>

                      {detailedInfo['mortgageLoan2LoanPayments'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['mortgageLoan2LoanPayments']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['mortgageLoan2LoanPayments']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}

                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">Total Mortgage Payments</td>
                  <td>

                    <span className={classNames(detailedInfo['InterestExpenses']['Mortgages'] < 0 && classes.minusValue)}>

                      {detailedInfo['InterestExpenses']['Mortgages'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['InterestExpenses']['Mortgages']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['InterestExpenses']['Mortgages']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'existingcreditcard-interestexpenses' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td colspan="2">Existing Credit Card Payment</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td >&nbsp;</td>
                </tr>
                {detailedInfo.creditCardInterestExpensesCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Average Existing Credit Card Balance in Year {detailedInfo['Year']}</td>
                  <td>
                    <span className={classNames(detailedInfo['totalInterestExpenses'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalInterestExpenses'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalInterestExpenses']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalInterestExpenses']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.creditCardInterestExpensesCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Interest Rate</td>
                  <td>{detailedInfo['annualInterestRateCcard']}%</td>
                </tr>}
                <tr>
                  <td>=</td>
                  <td className={classes.strongText} colspan="2">Existing Credit Card Interest Expense</td>
                  <td className={classes.strongText}>
                    <span className={classNames(detailedInfo['creditCardInterestExpense'] < 0 && classes.minusValue)}>

                      {detailedInfo['creditCardInterestExpense'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['creditCardInterestExpense']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['creditCardInterestExpense']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr className={classes.incomeBorderNew}>
                  <td>+</td>
                  <td colspan="2">Principal Payback</td>
                  <td>
                    <span className={classNames(detailedInfo['creditCardInterestPrincipal'] < 0 && classes.minusValue)}>

                      {detailedInfo['creditCardInterestPrincipal'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['creditCardInterestPrincipal']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['creditCardInterestPrincipal']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>=</td>
                  <td className={classes.strongText} colspan="2">Existing Credit Card Payment</td>
                  <td className={classes.strongText}>
                    <span className={classNames(detailedInfo['creditCardInterestExpenses'] < 0 && classes.minusValue)}>

                      {detailedInfo['creditCardInterestExpenses'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['creditCardInterestExpenses']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['creditCardInterestExpenses']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'total-expenses' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td className={classNames(classes.textCenter, classes.strongText)} colspan="2">Total Expenses</td>
                  <td className={classNames(classes.textRight, classes.strongText)}>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Total Living Expenses</td>
                  <td>
                    <span className={classNames(detailedInfo['totalLivingExpenses'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalLivingExpenses'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalLivingExpenses']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalLivingExpenses']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Total Car Expenses</td>
                  <td>

                    <span className={classNames(detailedInfo['totalCarExpenses'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalCarExpenses'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalCarExpenses']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalCarExpenses']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Total Real Estate Expenses</td>
                  <td>

                    <span className={classNames(detailedInfo['totalRealEstateExpenses'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalRealEstateExpenses'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalRealEstateExpenses']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalRealEstateExpenses']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Total Interest Expenses</td>
                  <td>

                    <span className={classNames(detailedInfo['totalInterestExpenses'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalInterestExpenses'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalInterestExpenses']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalInterestExpenses']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td className={classes.strongText} colspan="2">Total Expenses</td>
                  <td className={classes.strongText}>

                    <span className={classNames(detailedInfo['TotalExpenses'] < 0 && classes.minusValue)}>

                      {detailedInfo['TotalExpenses'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['TotalExpenses']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['TotalExpenses']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }

            {openGrossIncomeModel === 'net-income' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td className={classNames(classes.textCenter, classes.strongText)} colspan="2">Net Income</td>
                  <td className={classNames(classes.textRight, classes.strongText)}>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Income After Taxes</td>
                  <td>

                    <span className={classNames(detailedInfo['incomeAfterTaxes'] < 0 && classes.minusValue)}>

                      {detailedInfo['incomeAfterTaxes'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeAfterTaxes']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(detailedInfo['incomeAfterTaxes'] < 0 ? detailedInfo['incomeAfterTaxes'] : 0)} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Total Expenses</td>
                  <td>

                    <span className={classNames(detailedInfo['totalExpenses'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalExpenses'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalExpenses']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(detailedInfo['totalExpenses'] < 0 ? detailedInfo['totalExpenses'] : 0)} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>

                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td className={classes.strongText} colspan="2">Net Income</td>
                  <td className={classNames(classes.strongText, classes.textRight)}>

                    <span className={classNames((detailedInfo['incomeAfterTaxes'] + detailedInfo['totalExpenses']) < 0 && classes.minusValue)}>

                      {(detailedInfo['incomeAfterTaxes'] + detailedInfo['totalExpenses']) >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={(detailedInfo['incomeAfterTaxes'] + detailedInfo['totalExpenses'])} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(detailedInfo['incomeAfterTaxes'] + detailedInfo['totalExpenses'] < 0 ? detailedInfo['incomeAfterTaxes'] + detailedInfo['totalExpenses'] : 0)} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'living-expenses-portion-car-payment' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td colspan="2">Car Payment</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {(detailedInfo.finalCarPaymentValueCondition === 1 && detailedInfo.carPaymentLease1Condition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Lease #1</td>
                  <td>
                    <span className={classNames(detailedInfo['carPaymentLease1'] < 0 && classes.minusValue)}>

                      {detailedInfo['carPaymentLease1'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carPaymentLease1']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carPaymentLease1']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.finalCarPaymentValueCondition === 1 && detailedInfo.carPaymentLease2Condition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Lease #2</td>
                  <td>

                    <span className={classNames(detailedInfo['carPaymentLease2'] < 0 && classes.minusValue)}>

                      {detailedInfo['carPaymentLease2'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carPaymentLease2']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carPaymentLease2']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalCarPaymentValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">Total Monthly Car Payment</td>
                  <td>
                    <span className={classNames((detailedInfo['carPaymentLease1'] + detailedInfo['carPaymentLease2']) < 0 && classes.minusValue)}>

                      {detailedInfo['carPaymentLease1'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carPaymentLease1']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carPaymentLease1']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalCarPaymentValueCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Annual Car Payment</td>
                  <td>

                    <span className={classNames(detailedInfo['finalValueCarPayment'] < 0 && classes.minusValue)}>

                      {detailedInfo['finalValueCarPayment'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['finalValueCarPayment']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['finalValueCarPayment']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'living-expenses-portion-car-maintenance' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td colspan="2">Car Maintenance</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo.carMaintenanceLease1FinalCondition === 1 && <tr>
                  <td >Lease #1</td>
                </tr>}
                {detailedInfo.carMaintenanceLease1FinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carMaintenanceLease1FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carMaintenanceLease1PreviousCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Monthly Maintenance</td>
                  <td>

                    <span className={classNames(detailedInfo['carMaintenanceLease1Previous'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceLease1Previous'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceLease1Previous']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceLease1Previous']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carMaintenanceLease1FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carMaintenanceLease1PreviousCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td>
                    <span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceLease1FinalCondition === 1 && <tr>
                  {detailedInfo.carMaintenanceLease1PreviousCondition === 0 && <td>&nbsp;</td>}
                  {detailedInfo.carMaintenanceLease1PreviousCondition === 1 && <td>=</td>}
                  <td>{detailedInfo['Year']} Monthly Maintenance</td>
                  <td >

                    <span className={classNames(detailedInfo['carMaintenanceLease1Current'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceLease1Current'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceLease1Current']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceLease1Current']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceLease1FinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceLease1FinalCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Lease #1 Maintenance</td>
                  <td>

                    <span className={classNames(detailedInfo['carMaintenanceLease1Final'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceLease1Final'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceLease1Final']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceLease1Final']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carMaintenanceLease2FinalCondition === 1 && detailedInfo.carMaintenanceLease1FinalCondition === 1) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.carMaintenanceLease2FinalCondition === 1 && <tr>
                  <td >Lease #2</td>
                </tr>}
                {detailedInfo.carMaintenanceLease2FinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carMaintenanceLease2FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carMaintenanceLease2PreviousCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Monthly Maintenance</td>
                  <td>
                    <span className={classNames(detailedInfo['carMaintenanceLease2Previous'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceLease2Previous'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceLease2Previous']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceLease2Previous']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carMaintenanceLease2FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carMaintenanceLease2PreviousCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td>
                    <span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceLease2FinalCondition === 1 && <tr>
                  {detailedInfo.carMaintenanceLease2PreviousCondition === 0 && <td>&nbsp;</td>}
                  {detailedInfo.carMaintenanceLease2PreviousCondition === 1 && <td>=</td>}
                  <td>{detailedInfo['Year']} Monthly Maintenance</td>
                  <td>

                    <span className={classNames(detailedInfo['carMaintenanceLease2Current'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceLease2Current'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceLease2Current']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceLease2Current']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceLease2FinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceLease2FinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">{detailedInfo['Year']} Lease #2 Maintenance</td>
                  <td>
                    <span className={classNames(detailedInfo['carMaintenanceLease2Final'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceLease2Final'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceLease2Final']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceLease2Final']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carMaintenanceExistingFinalCondition === 1 && (detailedInfo.carMaintenanceLease2FinalCondition === 1 || detailedInfo.carMaintenanceLease1FinalCondition === 1)) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.carMaintenanceExistingFinalCondition === 1 && <tr>
                  <td >Existing Car</td>
                </tr>}
                {detailedInfo.carMaintenanceExistingFinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carMaintenanceExistingFinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Monthly Maintenance</td>
                  <td>

                    <span className={classNames(detailedInfo['carMaintenanceExistingPrevious'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceExistingPrevious'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceExistingPrevious']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceExistingPrevious']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carMaintenanceExistingFinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td><span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceExistingFinalCondition === 1 && <tr>
                  {detailedInfo.previousYearValuesCondition === 0 && <td>=</td>}
                  {detailedInfo.previousYearValuesCondition === 1 && <td>&nbsp;</td>}
                  <td>{detailedInfo['Year']} Monthly Maintenance</td>
                  <td>

                    <span className={classNames(detailedInfo['carMaintenanceExistingCurrent'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceExistingCurrent'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceExistingCurrent']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceExistingCurrent']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceExistingFinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceExistingFinalCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Existing Car Maintenance</td>
                  <td>

                    <span className={classNames(detailedInfo['carMaintenanceExistingFinal'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceExistingFinal'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceExistingFinal']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceExistingFinal']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carMaintenanceNewCar1FinalCondition === 1 && (detailedInfo.carMaintenanceLease2FinalCondition === 1 || detailedInfo.carMaintenanceLease1FinalCondition === 1 || detailedInfo.carMaintenanceExistingFinalCondition === 1)) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.carMaintenanceNewCar1FinalCondition === 1 && <tr>
                  <td >Car #1</td>
                </tr>}
                {detailedInfo.carMaintenanceNewCar1FinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carMaintenanceNewCar1FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carMaintenanceNewCar1PreviousCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Monthly Maintenance</td>
                  <td>

                    <span className={classNames(detailedInfo['carMaintenanceNewCar1Previous'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceNewCar1Previous'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceNewCar1Previous']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceNewCar1Previous']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carMaintenanceNewCar1FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carMaintenanceNewCar1PreviousCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td><span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceNewCar1FinalCondition === 1 && <tr>
                  {detailedInfo.carMaintenanceNewCar1PreviousCondition === 0 && <td>&nbsp;</td>}
                  {detailedInfo.carMaintenanceNewCar1PreviousCondition === 1 && <td>=</td>}
                  <td>{detailedInfo['Year']} Monthly Maintenance</td>
                  <td>

                    <span className={classNames(detailedInfo['carMaintenanceNewCar1Current'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceNewCar1Current'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceNewCar1Current']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceNewCar1Current']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceNewCar1FinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceNewCar1FinalCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Car #1 Maintenance</td>
                  <td>

                    <span className={classNames(detailedInfo['carMaintenanceNewCar1Final'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceNewCar1Final'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceNewCar1Final']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceNewCar1Final']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carMaintenanceNewCar2FinalCondition === 1 && (detailedInfo.carMaintenanceLease2FinalCondition === 1 || detailedInfo.carMaintenanceLease1FinalCondition === 1 || detailedInfo.carMaintenanceExistingFinalCondition === 1 || detailedInfo.carMaintenanceNewCar1FinalCondition === 1)) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.carMaintenanceNewCar2FinalCondition === 1 && <tr>
                  <td >Car #2</td>
                </tr>}
                {detailedInfo.carMaintenanceNewCar2FinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carMaintenanceNewCar2FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carMaintenanceNewCar2PreviousCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Monthly Maintenance</td>
                  <td>

                    <span className={classNames(detailedInfo['carMaintenanceNewCar2Previous'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceNewCar2Previous'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceNewCar2Previous']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceNewCar2Previous']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carMaintenanceNewCar2FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carMaintenanceNewCar2PreviousCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td><span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceNewCar2FinalCondition === 1 && <tr>
                  {detailedInfo.carMaintenanceNewCar2PreviousCondition === 0 && <td>&nbsp;</td>}
                  {detailedInfo.carMaintenanceNewCar2PreviousCondition === 1 && <td>=</td>}
                  <td>{detailedInfo['Year']} Monthly Maintenance</td>
                  <td>

                    <span className={classNames(detailedInfo['carMaintenanceNewCar2Current'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceNewCar2Current'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceNewCar2Current']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceNewCar2Current']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceNewCar2FinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carMaintenanceNewCar2FinalCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Car #2 Maintenance</td>
                  <td>

                    <span className={classNames(detailedInfo['carMaintenanceNewCar2Final'] < 0 && classes.minusValue)}>

                      {detailedInfo['carMaintenanceNewCar2Final'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carMaintenanceNewCar2Final']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carMaintenanceNewCar2Final']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Annual Car Maintenance</td>
                  <td>

                    <span className={classNames(detailedInfo['finalCarMaintenanceValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['finalCarMaintenanceValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['finalCarMaintenanceValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['finalCarMaintenanceValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'living-expenses-portion-car-insurance' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td colspan="2">Car Insurance</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo.carInsuranceLease1FinalCondition === 1 && <tr>
                  <td >Lease #1</td>
                </tr>}
                {detailedInfo.carInsuranceLease1FinalCondition === 1 && <tr >
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carInsuranceLease1FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carInsuranceLease1PreviousCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Monthly Insurance</td>
                  <td>

                    <span className={classNames(detailedInfo['carInsuranceLease1Previous'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceLease1Previous'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceLease1Previous']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceLease1Previous']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carInsuranceLease1FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carInsuranceLease1PreviousCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td>
                    <span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceLease1FinalCondition === 1 && <tr>
                  {detailedInfo.carInsuranceLease1PreviousCondition === 0 && <td>&nbsp;</td>}
                  {detailedInfo.carInsuranceLease1PreviousCondition === 1 && <td>=</td>}
                  <td>{detailedInfo['Year']} Monthly Insurance</td>
                  <td>

                    <span className={classNames(detailedInfo['carInsuranceLease1Current'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceLease1Current'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceLease1Current']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceLease1Current']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceLease1FinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceLease1FinalCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Lease #1 Insurance</td>
                  <td>

                    <span className={classNames(detailedInfo['carInsuranceLease1Final'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceLease1Final'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceLease1Final']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceLease1Final']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carInsuranceLease1FinalCondition === 1 && detailedInfo.carInsuranceLease2FinalCondition === 1) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.carInsuranceLease2FinalCondition === 1 && <tr>
                  <td >Lease #2</td>
                </tr>}
                {detailedInfo.carInsuranceLease2FinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carInsuranceLease2FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carInsuranceLease2PreviousCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Monthly Insurance</td>
                  <td>

                    <span className={classNames(detailedInfo['carInsuranceLease2Previous'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceLease2Previous'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceLease2Previous']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceLease2Previous']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carInsuranceLease2FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carInsuranceLease2PreviousCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td>
                    <span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceLease2FinalCondition === 1 && <tr>
                  {detailedInfo.carInsuranceLease2PreviousCondition === 0 && <td>&nbsp;</td>}
                  {detailedInfo.carInsuranceLease2PreviousCondition === 1 && <td>=</td>}
                  <td>{detailedInfo['Year']} Monthly Insurance</td>
                  <td>
                    <span className={classNames(detailedInfo['carInsuranceLease2Current'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceLease2Current'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceLease2Current']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceLease2Current']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceLease2FinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceLease2FinalCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Lease #2 Insurance</td>
                  <td>

                    <span className={classNames(detailedInfo['carInsuranceLease2Final'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceLease2Final'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceLease2Final']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceLease2Final']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carInsuranceExistingFinalCondition === 1 && (detailedInfo.carInsuranceLease2FinalCondition === 1 || detailedInfo.carInsuranceLease2FinalCondition === 1)) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.carInsuranceExistingFinalCondition === 1 && <tr>
                  <td >Existing Car</td>
                </tr>}
                {detailedInfo.carInsuranceExistingFinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carInsuranceExistingFinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carInsuranceExistingPreviousCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Monthly Insurance</td>
                  <td>

                    <span className={classNames(detailedInfo['carInsuranceExistingPrevious'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceExistingPrevious'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceExistingPrevious']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceExistingPrevious']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carInsuranceExistingFinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carInsuranceExistingPreviousCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td><span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceExistingFinalCondition === 1 && <tr>
                  {detailedInfo.carInsuranceExistingPreviousCondition === 0 && <td>&nbsp;</td>}
                  {detailedInfo.carInsuranceExistingPreviousCondition === 1 && <td>=</td>}
                  <td>{detailedInfo['Year']} Monthly Insurance</td>
                  <td>

                    <span className={classNames(detailedInfo['carInsuranceExistingCurrent'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceExistingCurrent'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceExistingCurrent']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceExistingCurrent']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceExistingFinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceExistingFinalCondition === 1 && <tr>
                  <td>=</td>
                  <td>{detailedInfo['Year']} Existing Car Insurance</td>
                  <td>

                    <span className={classNames(detailedInfo['carInsuranceExistingFinal'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceExistingFinal'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceExistingFinal']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceExistingFinal']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carInsuranceNewCar1FinalCondition === 1 && (detailedInfo.carInsuranceExistingFinalCondition === 1 || detailedInfo.carInsuranceLease2FinalCondition === 1 || detailedInfo.carInsuranceLease2FinalCondition === 1)) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.carInsuranceNewCar1FinalCondition === 1 && <tr>
                  <td >Car #1</td>
                </tr>}
                {detailedInfo.carInsuranceNewCar1FinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carInsuranceNewCar1FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carInsuranceNewCar1PreviousCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td>{detailedInfo['Year'] - 1} Monthly Insurance</td>
                  <td>

                    <span className={classNames(detailedInfo['carInsuranceNewCar1Previous'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceNewCar1Previous'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceNewCar1Previous']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceNewCar1Previous']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carInsuranceNewCar1FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carInsuranceNewCar1PreviousCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td><span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceNewCar1FinalCondition === 1 && <tr>
                  {detailedInfo.carInsuranceNewCar1PreviousCondition === 0 && <td>&nbsp;</td>}
                  {detailedInfo.carInsuranceNewCar1PreviousCondition === 1 && <td>=</td>}
                  <td>{detailedInfo['Year']} Monthly Insurance</td>
                  <td>

                    <span className={classNames(detailedInfo['carInsuranceNewCar1Current'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceNewCar1Current'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceNewCar1Current']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceNewCar1Current']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceNewCar1FinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceNewCar1FinalCondition === 1 && <tr>
                  <td>=</td>
                  <td>{detailedInfo['Year']} Car #1 Insurance</td>
                  <td>

                    <span className={classNames(detailedInfo['carInsuranceNewCar1Final'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceNewCar1Final'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceNewCar1Final']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceNewCar1Final']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carInsuranceNewCar2FinalCondition === 1 && (detailedInfo.carInsuranceNewCar1FinalCondition === 1 || detailedInfo.carInsuranceExistingFinalCondition === 1 || detailedInfo.carInsuranceLease2FinalCondition === 1 || detailedInfo.carInsuranceLease2FinalCondition === 1)) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.carInsuranceNewCar2FinalCondition === 1 && <tr>
                  <td >Car #2</td>
                </tr>}
                {detailedInfo.carInsuranceNewCar2FinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carInsuranceNewCar2FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carInsuranceNewCar2PreviousCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Monthly Insurance</td>
                  <td>

                    <span className={classNames(detailedInfo['carInsuranceNewCar2Previous'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceNewCar2Previous'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceNewCar2Previous']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceNewCar2Previous']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carInsuranceNewCar2FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carInsuranceNewCar2PreviousCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td><span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceNewCar2FinalCondition === 1 && <tr>
                  {detailedInfo.carInsuranceNewCar2PreviousCondition === 0 && <td>&nbsp;</td>}
                  {detailedInfo.carInsuranceNewCar2PreviousCondition === 1 && <td>=</td>}
                  <td>{detailedInfo['Year']} Monthly Insurance</td>
                  <td>
                    <span className={classNames(detailedInfo['carInsuranceNewCar2Current'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceNewCar2Current'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceNewCar2Current']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceNewCar2Current']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceNewCar2FinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carInsuranceNewCar2FinalCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Car #2 Insurance</td>
                  <td>
                    <span className={classNames(detailedInfo['carInsuranceNewCar2Final'] < 0 && classes.minusValue)}>

                      {detailedInfo['carInsuranceNewCar2Final'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carInsuranceNewCar2Final']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carInsuranceNewCar2Final']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Annual Car Insurance</td>
                  <td>
                    <span className={classNames(detailedInfo['finalCarInsuranceValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['finalCarInsuranceValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['finalCarInsuranceValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['finalCarInsuranceValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'living-expenses-portion-car-gas' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td colspan="2">Gas Expense</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo.carGasLease1FinalCondition === 1 && <tr>
                  <td >Lease #1</td>
                </tr>}
                {detailedInfo.carGasLease1FinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carGasLease1FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carGasLease1PreviousCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Monthly Gas Insurance</td>
                  <td>
                    <span className={classNames(detailedInfo['carGasLease1Previous'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasLease1Previous'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasLease1Previous']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasLease1Previous']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carGasLease1FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carGasLease1PreviousCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td>
                    <span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carGasLease1FinalCondition === 1 && <tr>
                  {detailedInfo.carGasLease1PreviousCondition === 0 && <td>&nbsp;</td>}
                  {detailedInfo.carGasLease1PreviousCondition === 1 && <td>=</td>}
                  <td>{detailedInfo['Year']} Monthly Gas Expense</td>
                  <td>

                    <span className={classNames(detailedInfo['carGasLease1Current'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasLease1Current'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasLease1Current']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasLease1Current']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carGasLease1FinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carGasLease1FinalCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Lease #1 Gas Expense</td>
                  <td>

                    <span className={classNames(detailedInfo['carGasLease1Final'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasLease1Final'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasLease1Final']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasLease1Final']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carGasLease1FinalCondition === 1 && detailedInfo.carGasLease2FinalCondition === 1) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.carGasLease2FinalCondition === 1 && <tr>
                  <td >Lease #2</td>
                </tr>}
                {detailedInfo.carGasLease2FinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carGasLease2FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carGasLease2PreviousCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Monthly Gas Expense</td>
                  <td>

                    <span className={classNames(detailedInfo['carGasLease2Previous'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasLease2Previous'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasLease2Previous']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasLease2Previous']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carGasLease2FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carGasLease2PreviousCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td>
                    <span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carGasLease2FinalCondition === 1 && <tr>
                  {detailedInfo.carGasLease2PreviousCondition === 0 && <td>&nbsp;</td>}
                  {detailedInfo.carGasLease2PreviousCondition === 1 && <td>=</td>}
                  <td>{detailedInfo['Year']} Monthly Gas Expense</td>
                  <td>
                    <span className={classNames(detailedInfo['carGasLease2Current'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasLease2Current'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasLease2Current']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasLease2Current']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carGasLease2FinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carGasLease2FinalCondition === 1 && <tr>
                  <td>=</td>
                  <td>{detailedInfo['Year']} Lease #2 Gas Expense</td>
                  <td>
                    <span className={classNames(detailedInfo['carGasLease2Final'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasLease2Final'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasLease2Final']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasLease2Final']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carGasExistingFinalCondition === 1 && (detailedInfo.carGasLease2FinalCondition === 1 || detailedInfo.carGasLease2FinalCondition === 1) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.carGasExistingFinalCondition === 1 && <tr>
                  <td >Existing Car</td>
                </tr>}
                {detailedInfo.carGasExistingFinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carGasExistingFinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carGasExistingPreviousCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Monthly Gas Expense</td>
                  <td>

                    <span className={classNames(detailedInfo['carGasExistingPrevious'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasExistingPrevious'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasExistingPrevious']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasExistingPrevious']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carGasExistingFinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carGasExistingPreviousCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td><span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carGasExistingFinalCondition === 1 && <tr>
                  {detailedInfo.carGasExistingPreviousCondition === 0 && <td>&nbsp;</td>}
                  {detailedInfo.carGasExistingPreviousCondition === 1 && <td>=</td>}
                  <td>{detailedInfo['Year']} Monthly Gas Expense</td>
                  <td>

                    <span className={classNames(detailedInfo['carGasExistingCurrent'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasExistingCurrent'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasExistingCurrent']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasExistingCurrent']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carGasExistingFinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carGasExistingFinalCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Existing Car Gas Expense</td>
                  <td>

                    <span className={classNames(detailedInfo['carGasExistingFinal'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasExistingFinal'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasExistingFinal']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasExistingFinal']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}

                {detailedInfo.carGasNewCar1FinalCondition === 1 && (detailedInfo.carGasLease2FinalCondition === 1 || detailedInfo.carGasLease2FinalCondition === 1 || detailedInfo.carGasExistingFinalCondition === 1) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.carGasNewCar1FinalCondition === 1 && <tr>
                  <td >Car #1</td>
                </tr>}
                {detailedInfo.carGasNewCar1FinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carGasNewCar1FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carGasNewCar1PreviousCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td>{detailedInfo['Year'] - 1} Monthly Gas Expense</td>
                  <td>
                    <span className={classNames(detailedInfo['carGasNewCar1Previous'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasNewCar1Previous'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasNewCar1Previous']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasNewCar1Previous']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carGasNewCar1FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carGasNewCar1PreviousCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td><span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carGasNewCar1FinalCondition === 1 && <tr>
                  {detailedInfo.carGasNewCar1PreviousCondition === 0 && <td>&nbsp;</td>}
                  {detailedInfo.carGasNewCar1PreviousCondition === 1 && <td>=</td>}
                  <td>{detailedInfo['Year']} Monthly Gas Expense</td>
                  <td>
                    <span className={classNames(detailedInfo['carGasNewCar1Current'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasNewCar1Current'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasNewCar1Current']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasNewCar1Current']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carGasNewCar1FinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carGasNewCar1FinalCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Car #1 Gas Expense</td>
                  <td>

                    <span className={classNames(detailedInfo['carGasNewCar1Final'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasNewCar1Final'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasNewCar1Final']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasNewCar1Final']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carGasNewCar2FinalCondition === 1 && (detailedInfo.carGasNewCar1FinalCondition === 1 || detailedInfo.carGasLease2FinalCondition === 1 || detailedInfo.carGasLease2FinalCondition === 1 || detailedInfo.carGasExistingFinalCondition === 1)) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.carGasNewCar2FinalCondition === 1 && <tr>
                  <td >Car #2</td>
                </tr>}
                {detailedInfo.carGasNewCar2FinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {(detailedInfo.carGasNewCar2FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carGasNewCar2PreviousCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td colspan="2">{detailedInfo['Year'] - 1} Monthly Gas Expense</td>
                  <td>

                    <span className={classNames(detailedInfo['carGasNewCar2Previous'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasNewCar2Previous'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasNewCar2Previous']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasNewCar2Previous']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.carGasNewCar2FinalCondition === 1 && detailedInfo.previousYearValuesCondition !== 1 && detailedInfo.carGasNewCar2PreviousCondition === 1) && <tr>
                  <td>x</td>
                  <td colspan="2">Inflation</td>
                  <td><span>{detailedInfo['inflationVal'] > 0 ? detailedInfo['inflationVal'] : 0}%</span>
                  </td>
                </tr>}
                {detailedInfo.carGasNewCar2FinalCondition === 1 && <tr>
                  {detailedInfo.carGasNewCar2PreviousCondition === 0 && <td>&nbsp;</td>}
                  {detailedInfo.carGasNewCar2PreviousCondition === 1 && <td>=</td>}
                  <td>{detailedInfo['Year']} Monthly Gas Expense</td>
                  <td>

                    <span className={classNames(detailedInfo['carGasNewCar2Current'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasNewCar2Current'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasNewCar2Current']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasNewCar2Current']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.carGasNewCar2FinalCondition === 1 && <tr>
                  <td>x</td>
                  <td colspan="2">Annual Multiplier</td>
                  <td>
                    <span>12.0x</span>
                  </td>
                </tr>}
                {detailedInfo.carGasNewCar2FinalCondition === 1 && <tr>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Car #2 Gas Expense</td>
                  <td>

                    <span className={classNames(detailedInfo['carGasNewCar2Final'] < 0 && classes.minusValue)}>

                      {detailedInfo['carGasNewCar2Final'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['carGasNewCar2Final']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['carGasNewCar2Final']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colspan="2">{detailedInfo['Year']} Annual Gas Expense</td>
                  <td>

                    <span className={classNames(detailedInfo['finalCarGasValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['finalCarGasValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['finalCarGasValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['finalCarGasValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
          </div>
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openDialogName === 'homePropertyTaxesExpenses' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.incomeStatementDialogNew}
      >
        <DialogContent>
          {openHomePropertyTaxesExpensesModel === 'home-property-taxes-expenses' && <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td colspan="2">Home Property Taxes</td>
                <td>Year {detailedInfo['Year']}</td>
              </tr>
              <tr>
                <td >&nbsp;</td>
              </tr>
              {detailedInfo.existingHomePropertyTaxesCondition === 1 && <tr>
                <td >Home Property Taxes</td>
              </tr>}
              {detailedInfo.existingHomePropertyTaxesCondition === 1 && <tr>
                <td >&nbsp;</td>
              </tr>}
              {detailedInfo.existingHomePropertyTaxesCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Existing Home Value in {detailedInfo['financialYear']}</td>
                <td>Annual Property Taxes</td>
                <td>Property Tax Rate</td>
              </tr>}
              {detailedInfo.existingHomePropertyTaxesCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>{detailedInfo.existingHomeValueStartYear}</td>
                <td>{detailedInfo.annualPropertyTaxes}</td>
                <td>{detailedInfo.propertyTaxRate}%</td>
              </tr>}
              {detailedInfo.existingHomePropertyTaxesCondition === 1 && <tr>
                <td >&nbsp;</td>
              </tr>}
              {detailedInfo.existingHomePropertyTaxesCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td >{detailedInfo['Year']} Existing Home Value</td>
                <td>

                  <span className={classNames(detailedInfo['existingHomeValueInYear'] < 0 && classes.minusValue)}>

                    {detailedInfo['existingHomeValueInYear'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['existingHomeValueInYear']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['existingHomeValueInYear']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.existingHomePropertyTaxesCondition === 1 && <tr>
                <td>x</td>
                <td colspan="2">Property Tax Rate</td>
                <td>{detailedInfo['propertyTaxRateSecond']}%</td>
              </tr>}
              {detailedInfo.existingHomePropertyTaxesCondition === 1 && <tr>
                <td>=/+</td>
                <td colspan="2">{detailedInfo['Year']} Existing Home Property Taxes</td>
                <td>
                  <span className={classNames(detailedInfo['existingHomePropertyTaxes'] < 0 && classes.minusValue)}>

                    {detailedInfo['existingHomePropertyTaxes'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['existingHomePropertyTaxes']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['existingHomePropertyTaxes']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.existingHomePropertyTaxesCondition === 1 && detailedInfo.buyingaHomePropertyTaxesCondition === 1) && <tr>
                <td >&nbsp;</td>
              </tr>}

              {detailedInfo.buyingaHomePropertyTaxesCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Home Value / Purchase Year</td>
                <td>Property Tax Rate</td>
                <td>Annual Property Taxes</td>
              </tr>}
              {detailedInfo.buyingaHomePropertyTaxesCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>{detailedInfo.BuyingAHomeVal} / {detailedInfo.BuyingAHomePuchaseYear}</td>
                <td>{detailedInfo.propertyTax}%</td>
                <td>
                  <span className={classNames(detailedInfo.propertyTaxesCalcs < 0 && classes.minusValue)}>

                    {detailedInfo.propertyTaxesCalcs >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.propertyTaxesCalcs} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.propertyTaxesCalcs} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.buyingaHomePropertyTaxesCondition === 1 && <tr>
                <td >&nbsp;</td>
              </tr>}
              {(detailedInfo.buyingaHomePropertyTaxesCondition === 1 && detailedInfo.homePropertyTaxesPreviousValueCondition === 1) && <tr>
                <td>&nbsp;</td>
                <td colspan="2">{detailedInfo['Year'] - 1} Home Value</td>
                <td>
                  <span className={classNames(detailedInfo['homePropertyTaxesPreviousValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['homePropertyTaxesPreviousValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['homePropertyTaxesPreviousValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['homePropertyTaxesPreviousValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.buyingaHomePropertyTaxesCondition === 1 && detailedInfo.homePropertyTaxesPreviousValueCondition === 1) && <tr>
                <td>x</td>
                <td colspan="2">Property Tax Rate</td>
                <td>{detailedInfo['propertyTax']}%
                </td>
              </tr>}
              {detailedInfo.buyingaHomePropertyTaxesCondition === 1 && <tr>
                <td>=/+</td>
                <td colspan="2">{detailedInfo['Year']} Home Property Taxes </td>
                <td>
                  <span className={classNames(detailedInfo['buyingaHomePropertyTaxes'] < 0 && classes.minusValue)}>

                    {detailedInfo['buyingaHomePropertyTaxes'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['buyingaHomePropertyTaxes']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['buyingaHomePropertyTaxes']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              <tr className={classes.incomeBorder}>
                <td>=/+</td>
                <td colspan="2">{detailedInfo['Year']} Total Home Property Taxes </td>
                <td>
                  <span className={classNames(detailedInfo['livingExpenses']['Home Property Taxes'] < 0 && classes.minusValue)}>

                    {detailedInfo['livingExpenses']['Home Property Taxes'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['livingExpenses']['Home Property Taxes']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['livingExpenses']['Home Property Taxes']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          }

          {openHomePropertyTaxesExpensesModel === 'rental-property-taxes' && <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td colspan="3" className={classes.strongText}>Rental Property Taxes</td>
                <td>Year {detailedInfo['Year']}</td>
              </tr>
              <tr>
                <td >&nbsp;</td>
              </tr>
              {detailedInfo.rentalPropertyTaxesCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Property Value / Purchase Year</td>
                <td>Property Tax Rate</td>
                <td>Annual Property Taxes</td>
              </tr>}
              {detailedInfo.rentalPropertyTaxesCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>{detailedInfo.propertyValue} / {detailedInfo.purchaseYear}</td>
                <td>{detailedInfo.property_tax_rate}%</td>
                <td>
                  <span className={classNames(detailedInfo.annula_property_tax < 0 && classes.minusValue)}>

                    {detailedInfo.annula_property_tax >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.annula_property_tax} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.annula_property_tax} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.rentalPropertyTaxesCondition === 1 && <tr>
                <td >&nbsp;</td>
              </tr>}
              {detailedInfo.rentalPropertyTaxesCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td colspan="2">{detailedInfo['Year']} Property Value</td>
                <td>
                  <span className={classNames(detailedInfo.propertyTaxes < 0 && classes.minusValue)}>

                    {detailedInfo.propertyTaxes >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.propertyTaxes} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.propertyTaxes} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.rentalPropertyTaxesCondition === 1 && <tr>
                <td>x</td>
                <td colspan="2">Property Tax Rate</td>
                <td>{detailedInfo.property_tax_rate}%</td>
              </tr>}
              <tr className={classes.incomeBorder}>
                <td>=</td>
                <td colspan="2" className={classes.strongText}>{detailedInfo['Year']} Rental Property Taxes</td>
                <td>
                  <span className={classNames(detailedInfo.rentalPropertyTaxes < 0 && classes.minusValue)}>

                    {detailedInfo.rentalPropertyTaxes >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.rentalPropertyTaxes} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.rentalPropertyTaxes} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          }
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openDialogName === 'showDetailPopupWide' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.studentLoanTable}
      >
        <DialogContent>
          {/* <table class="studentloan-interestexpenses"> */}
          <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td className={classNames(classes.textCenter, classes.strongText)}>Student Loan Payments</td>
                <td className={classNames(classes.textRight, classes.strongText)}>Year {detailedInfo['Year']}</td>
              </tr>
              <tr>
                <td colspan="3">&nbsp;</td>
              </tr>
              {detailedInfo.averageExistingFedSubExpenseValCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Average Existing Federal Subsidized Student Loan Balance in Year {detailedInfo['Year']}</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['averageExistingFedSubLoanVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageExistingFedSubLoanVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageExistingFedSubLoanVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageExistingFedSubLoanVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.averageExistingFedSubExpenseValCondition === 1 && <tr>
                <td class="text-center">x</td>
                <td>Interest Rate</td>
                <td class="text-right">{detailedInfo['averageExistingFedSubInterestRate']}%</td>
              </tr>}
              {detailedInfo.averageExistingFedSubExpenseValCondition === 1 && <tr>
                <td class="text-center">=/+</td>
                <td>Existing Federal Subsidized Student Loan Interest Expense</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['averageExistingFedSubExpenseVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageExistingFedSubExpenseVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageExistingFedSubExpenseVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageExistingFedSubExpenseVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.averageExistingFedSubExpenseValCondition === 1 && <tr>
                <td class="text-center">+</td>
                <td>Principal Payback</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['averageExistingFedSubPrincipalVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageExistingFedSubPrincipalVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageExistingFedSubPrincipalVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageExistingFedSubPrincipalVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.averageExistingFedSubExpenseValCondition === 1 && <tr>
                <td class="strong text-center">=</td>
                <td class="strong">Existing Federal Subsidized Student Loan Payment</td>
                <td class="strong text-right">

                  <span className={classNames(detailedInfo['existingFederalSubsidizedStudentLoan'] < 0 && classes.minusValue)}>

                    {detailedInfo['existingFederalSubsidizedStudentLoan'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['existingFederalSubsidizedStudentLoan']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['existingFederalSubsidizedStudentLoan']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              {detailedInfo.averageExistingFedUnsubExpenseValCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Average Existing Federal Unsubsidized Student Loan Balance in Year {detailedInfo['Year']}</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['averageExistingFedUnsubLoanVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageExistingFedUnsubLoanVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageExistingFedUnsubLoanVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageExistingFedUnsubLoanVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.averageExistingFedUnsubExpenseValCondition === 1 && <tr>
                <td class="text-center">x</td>
                <td>Interest Rate</td>
                <td class="text-right">{detailedInfo['averageExistingFedUnsubInterestRate']}%</td>
              </tr>}
              {(detailedInfo.interestExpenseBeforePaymentCondition === 1 && detailedInfo.interestExpenseDuringPaymentCondition === 1) && <tr>
                <td class="text-center">=</td>
                <td>Interest Expense during Repayment</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['interestExpenseDuringPayment'] < 0 && classes.minusValue)}>

                    {detailedInfo['interestExpenseDuringPayment'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['interestExpenseDuringPayment']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['interestExpenseDuringPayment']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.interestExpenseBeforePaymentCondition === 1 && detailedInfo.interestExpenseDuringPaymentCondition === 1) && <tr className={classes.incomeBorder}>
                <td class="text-center">+</td>
                <td >Interest Expense before Repayment begins</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['interestExpenseBeforePayment'] < 0 && classes.minusValue)}>

                    {detailedInfo['interestExpenseBeforePayment'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['interestExpenseBeforePayment']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['interestExpenseBeforePayment']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              {detailedInfo.averageExistingFedUnsubExpenseValCondition === 1 && <tr>
                <td class="text-center">=/+</td>
                <td>Existing Federal Unsubsidized Student Loan Interest Expense</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['averageExistingFedUnsubExpenseVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageExistingFedUnsubExpenseVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageExistingFedUnsubExpenseVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageExistingFedUnsubExpenseVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.averageExistingFedUnsubExpenseValCondition === 1 && <tr>
                <td class="text-center">+</td>
                <td>Principal Payback</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['averageExistingFedUnsubPrincipalVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageExistingFedUnsubPrincipalVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageExistingFedUnsubPrincipalVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageExistingFedUnsubPrincipalVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.averageExistingFedUnsubExpenseValCondition === 1 && <tr>
                <td class="text-center">=</td>
                <td class="strong">Existing Federal Unsubsidized Student Loan Payment</td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['existingFederalUnsubStudentLoan'] < 0 && classes.minusValue)}>

                    {detailedInfo['existingFederalUnsubStudentLoan'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['existingFederalUnsubStudentLoan']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['existingFederalUnsubStudentLoan']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              {detailedInfo.averageExistingPrivateExpenseValCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Average Existing Private Student Loan Balance in Year {detailedInfo['Year']}</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['averageExistingPrivateLoanVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageExistingPrivateLoanVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageExistingPrivateLoanVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageExistingPrivateLoanVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.averageExistingPrivateExpenseValCondition === 1 && <tr>
                <td class="text-center">x</td>
                <td>Interest Rate</td>
                <td class="text-right">{detailedInfo['averageExistingPrivateInterestRate']}%</td>
              </tr>}
              {(detailedInfo.interestExpenseBeforePrivatePaymentCondition === 1 && detailedInfo.interestExpenseDuringPrivatePaymentCondition === 1) && <tr>
                <td class="text-center">=</td>
                <td>Interest Expense during Repayment</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['interestExpenseDuringPrivatePayment'] < 0 && classes.minusValue)}>

                    {detailedInfo['interestExpenseDuringPrivatePayment'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['interestExpenseDuringPrivatePayment']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['interestExpenseDuringPrivatePayment']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.interestExpenseBeforePrivatePaymentCondition === 1 && detailedInfo.interestExpenseDuringPrivatePaymentCondition === 1) && <tr>
                <td class="text-center">+</td>
                <td>Interest Expense before Repayment begins</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['interestExpenseBeforePrivatePayment'] < 0 && classes.minusValue)}>

                    {detailedInfo['interestExpenseBeforePrivatePayment'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['interestExpenseBeforePrivatePayment']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['interestExpenseBeforePrivatePayment']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.averageExistingPrivateExpenseValCondition === 1 && <tr>
                <td class="text-center">=/+</td>
                <td>Existing Private Student Loan Interest Expense</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['averageExistingPrivateExpenseVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageExistingPrivateExpenseVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageExistingPrivateExpenseVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageExistingPrivateExpenseVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.averageExistingPrivateExpenseValCondition === 1 && <tr>
                <td class="text-center">+</td>
                <td>Principal Payback</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['averageExistingPrivatePrincipalVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageExistingPrivatePrincipalVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageExistingPrivatePrincipalVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageExistingPrivatePrincipalVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.averageExistingPrivateExpenseValCondition === 1 && <tr>
                <td class="text-center">=</td>
                <td class="strong">Existing Private Student Loan Payment</td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['existingPrivateStudentsLoansVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['existingPrivateStudentsLoansVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['existingPrivateStudentsLoansVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['existingPrivateStudentsLoansVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              {detailedInfo.fedSubInterestExpenseCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Average Higher Education Federal Subsidized Student Loan Balance in Year {detailedInfo['Year']}</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['averageFederalSubsidizedLoanVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageFederalSubsidizedLoanVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageFederalSubsidizedLoanVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageFederalSubsidizedLoanVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedSubInterestExpenseCondition === 1 && <tr>
                <td class="text-center">x</td>
                <td>Interest Rate</td>
                <td class="text-right">{detailedInfo['fedSubinterestRateVal']}%</td>
              </tr>}
              {detailedInfo.fedSubInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=/+</td>
                <td class="strong">Higher Education Federal Subsidized Student Loan Interest Expense</td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['fedSubInterestExpenseVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedSubInterestExpenseVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedSubInterestExpenseVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedSubInterestExpenseVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedSubInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=/+</td>
                <td>Higher Education Federal Subsidized Student Loan Interest Expense</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['fedSubInterestExpenseVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedSubInterestExpenseVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedSubInterestExpenseVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedSubInterestExpenseVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedSubInterestExpenseCondition === 1 && <tr>
                <td class="text-center">+</td>
                <td>Principal Payback</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['fedSubInterestPrincipalVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedSubInterestPrincipalVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedSubInterestPrincipalVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedSubInterestPrincipalVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedSubInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=</td>
                <td class="strong">Higher Education Federal Subsidized Student Loan Payment</td>
                <td class="strong text-right">

                  <span className={classNames(detailedInfo['fedSubStudentLoansVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedSubStudentLoansVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedSubStudentLoansVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedSubStudentLoansVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              {detailedInfo.fedUnubInterestExpenseCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Average Higher Education Federal Unsubsidized Student Loan Balance in Year {detailedInfo['Year']}</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['averageFederalUnsubsidizedLoanVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageFederalUnsubsidizedLoanVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageFederalUnsubsidizedLoanVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageFederalUnsubsidizedLoanVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedUnubInterestExpenseCondition === 1 && <tr>
                <td class="text-center">x</td>
                <td>Interest Rate</td>
                <td class="text-right">{detailedInfo['fedUnsubinterestRateVal']}%</td>
              </tr>}
              {(detailedInfo.fedUnsubHigherEdMakeInterestpaymentsCondition === 1 && detailedInfo.fedUnubInterestExpenseCondition === 1 && detailedInfo.fedUnsubHigherGracePeriodCondition === 1) && <tr>
                <td class="text-center">=/+</td>
                <td>Higher Education Federal Unsubsidized Student Loan Interest Expense during 6 mo. of repayment</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['fedUnsubHigherRepaymentValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnsubHigherRepaymentValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnsubHigherRepaymentValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnsubHigherRepaymentValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.fedUnsubHigherEdMakeInterestpaymentsCondition === 1 && detailedInfo.fedUnubInterestExpenseCondition === 1 && detailedInfo.fedUnsubHigherGracePeriodCondition === 1) && <tr>
                <td class="text-center">+</td>
                <td>Higher Education Federal Unsubsidized Student Loan Interest during 6 mo. Grace Period</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['fedUnsubHigherGracePeriodValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnsubHigherGracePeriodValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnsubHigherGracePeriodValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnsubHigherGracePeriodValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedUnubInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=/+</td>
                <td>Higher Education Federal Unsubsidized Student Loan Interest Expense</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['fedUnubInterestExpenseVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnubInterestExpenseVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnubInterestExpenseVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnubInterestExpenseVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedUnubInterestExpenseCondition === 1 && <tr>
                <td class="text-center">+</td>
                <td>Principal Payback</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['fedUnubInterestPrincipalVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnubInterestPrincipalVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnubInterestPrincipalVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnubInterestPrincipalVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedUnubInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=</td>
                <td class="strong">Higher Education Federal Unsubsidized Student Loan Payment</td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['fedUnubStudentsLoanVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnubStudentsLoanVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnubStudentsLoanVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnubStudentsLoanVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.privateInterestExpenseCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Average Higher Education Private Student Loan Balance in Year {detailedInfo['Year']}</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['averagePrivateLoanVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averagePrivateLoanVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averagePrivateLoanVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averagePrivateLoanVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.privateInterestExpenseCondition === 1 && <tr>
                <td class="text-center">x</td>
                <td>Interest Rate</td>
                <td class="text-right">{detailedInfo['privateInterestRateVal']}%</td>
              </tr>}
              {(detailedInfo.privateHigherEdMakeInterestpaymentsCondition === 1 && detailedInfo.privateInterestExpenseCondition === 1 && detailedInfo.privateHigherGracePeriodCondition === 1) && <tr>
                <td class="text-center">=/+</td>
                <td>Higher Education Private Student Loan Interest Expense during 6 mo. of repayment</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['privateHigherRepaymentValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['privateHigherRepaymentValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['privateHigherRepaymentValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['privateHigherRepaymentValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.privateHigherEdMakeInterestpaymentsCondition === 1 && detailedInfo.privateInterestExpenseCondition === 1 && detailedInfo.privateHigherGracePeriodCondition === 1) && <tr>
                <td class="text-center">+</td>
                <td>Higher Education Private Student Loan Interest during 6 mo. Grace Period</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['privateHigherGracePeriodValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['privateHigherGracePeriodValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['privateHigherGracePeriodValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['privateHigherGracePeriodValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              <tr>
                <td class="text-center">=/+</td>
                <td class="">Higher Education Private Student Loan Interest Expense</td>
                <td class=" text-right">

                  <span className={classNames(detailedInfo['privateInterestExpenseVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['privateInterestExpenseVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['privateInterestExpenseVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['privateInterestExpenseVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              {detailedInfo.privateInterestExpenseCondition === 1 && <tr>
                <td class="text-center">+</td>
                <td class="">Principal Payback</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['privateInterestPrincipalVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['privateInterestPrincipalVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['privateInterestPrincipalVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['privateInterestPrincipalVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.privateInterestExpenseCondition === 1 && <tr>
                <td class="text-center">+</td>
                <td class="strong">Higher Education Private Student Loan Payment</td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['privateHigherEdStudentLoansVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['privateHigherEdStudentLoansVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['privateHigherEdStudentLoansVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['privateHigherEdStudentLoansVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedSubMoreInterestExpenseCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Average More Higher Education Federal Subsidized Student Loan Balance in Year {detailedInfo['Year']}</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['averageMoreFederalSubsidizedLoanVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageMoreFederalSubsidizedLoanVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageMoreFederalSubsidizedLoanVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageMoreFederalSubsidizedLoanVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedSubMoreInterestExpenseCondition === 1 && <tr>
                <td class="text-center">x</td>
                <td>Interest Rate</td>
                <td class="text-right">{detailedInfo['fedSubMoreinterestRateVal']}%</td>
              </tr>}
              {detailedInfo.fedSubMoreInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=/+</td>
                <td class="">More Higher Education Federal Subsidized Student Loan Interest Expense</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['fedSubMoreInterestExpenseVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedSubMoreInterestExpenseVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedSubMoreInterestExpenseVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedSubMoreInterestExpenseVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedSubMoreInterestExpenseCondition === 1 && <tr>
                <td class="text-center">+</td>
                <td class="">Principal Payback</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['fedSubMoreInterestPrincipalVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedSubMoreInterestPrincipalVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedSubMoreInterestPrincipalVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedSubMoreInterestPrincipalVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedSubMoreInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=</td>
                <td class="">More Higher Education Federal Subsidized Student Loan Payment</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['fedSubMoreLoanPaymentsVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedSubMoreLoanPaymentsVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedSubMoreLoanPaymentsVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedSubMoreLoanPaymentsVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              {detailedInfo.fedUnubMoreInterestExpenseCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Average More Higher Education Federal Unsubsidized Student Loan Balance in Year {detailedInfo['Year']}</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['averageMoreFederalUnsubsidizedLoanVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageMoreFederalUnsubsidizedLoanVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageMoreFederalUnsubsidizedLoanVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageMoreFederalUnsubsidizedLoanVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedUnubMoreInterestExpenseCondition === 1 && <tr>
                <td class="text-center">x</td>
                <td>Interest Rate</td>
                <td class="text-right">{detailedInfo['fedUnsubMoreinterestRateVal']}%</td>
              </tr>}
              {(detailedInfo.fedUnsubMoreHigherEdMakeInterestpaymentsCondition === 1 && detailedInfo.fedUnubMoreInterestExpenseCondition === 1 && detailedInfo.fedUnsubMoreHigherGracePeriodCondition === 1) && <tr>
                <td class="text-center">=/+</td>
                <td>More Higher Education Federal Unsubsidized Student Loan Interest Expense during 6 mo. of repayment</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['fedUnsubMoreHigherRepaymentValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnsubMoreHigherRepaymentValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnsubMoreHigherRepaymentValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnsubMoreHigherRepaymentValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.fedUnsubMoreHigherEdMakeInterestpaymentsCondition === 1 && detailedInfo.fedUnubMoreInterestExpenseCondition === 1 && detailedInfo.fedUnsubMoreHigherGracePeriodCondition === 1) && <tr>
                <td class="text-center">+</td>
                <td>More Higher Education Federal Unsubsidized Student Loan Interest during 6 mo. Grace Period</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['fedUnsubMoreHigherGracePeriodValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnsubMoreHigherGracePeriodValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnsubMoreHigherGracePeriodValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnsubMoreHigherGracePeriodValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedUnubMoreInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=/+</td>
                <td class="">More Higher Education Federal Unsubsidized Student Loan Interest Expense</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['fedUnubMoreInterestExpenseVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnubMoreInterestExpenseVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnubMoreInterestExpenseVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnubMoreInterestExpenseVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedUnubMoreInterestExpenseCondition === 1 && <tr>
                <td class="text-center">+</td>
                <td class="">Principal Payback</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['fedUnubMoreInterestPrincipalVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnubMoreInterestPrincipalVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnubMoreInterestPrincipalVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnubMoreInterestPrincipalVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedUnubMoreInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=</td>
                <td class="strong">More Higher Education Federal Unsubsidized Student Loan Payment</td>
                <td class="strong text-right">

                  <span className={classNames(detailedInfo['fedUnSubMoreLoanPaymentsVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnSubMoreLoanPaymentsVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnSubMoreLoanPaymentsVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnSubMoreLoanPaymentsVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              {detailedInfo.morePrivateInterestExpenseCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Average More Higher Education Private Student Loan Balance in Year {detailedInfo['Year']}</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['averageMorePrivateLoanVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageMorePrivateLoanVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageMorePrivateLoanVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageMorePrivateLoanVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.morePrivateInterestExpenseCondition === 1 && <tr>
                <td class="text-center">x</td>
                <td>Interest Rate</td>
                <td class="text-right">{detailedInfo['morePrivateInterestRateVal']}%</td>
              </tr>}
              {(detailedInfo.morePrivateMakeInterestpaymentsCondition === 1 && detailedInfo.morePrivateInterestExpenseCondition === 1 && detailedInfo.morePrivateGracePeriodCondition === 1) && <tr>
                <td class="text-center">=/+</td>
                <td>More Higher Education Private Student Loan Interest Expense during 6 mo. of repayment</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['morePrivateRepaymentValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['morePrivateRepaymentValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['morePrivateRepaymentValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['morePrivateRepaymentValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.morePrivateMakeInterestpaymentsCondition === 1 && detailedInfo.morePrivateInterestExpenseCondition === 1 && detailedInfo.morePrivateGracePeriodCondition === 1) && <tr>
                <td class="text-center">+</td>
                <td>More Higher Education Private Student Loan Interest during 6 mo. Grace Period</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['morePrivateGracePeriodValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['morePrivateGracePeriodValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['morePrivateGracePeriodValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['morePrivateGracePeriodValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.morePrivateInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=/+</td>
                <td class="">More Higher Education Private Student Loan Interest Expense</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['morePrivateInterestExpenseVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['morePrivateInterestExpenseVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['morePrivateInterestExpenseVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['morePrivateInterestExpenseVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.morePrivateInterestExpenseCondition === 1 && <tr>
                <td class="text-center">+</td>
                <td class="">Principal Payback</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['morePrivateInterestPrincipalVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['morePrivateInterestPrincipalVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['morePrivateInterestPrincipalVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['morePrivateInterestPrincipalVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.morePrivateInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=</td>
                <td class="strong">More Higher Education Private Student Loan Payment</td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['privateMoreStudentsPaymentsVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['privateMoreStudentsPaymentsVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['privateMoreStudentsPaymentsVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['privateMoreStudentsPaymentsVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              {detailedInfo.fedSubNewInterestExpenseCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Average Further Higher Education Federal Subsidized Student Loan Balance in Year {detailedInfo['Year']}</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['averageNewFederalSubsidizedLoanVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageNewFederalSubsidizedLoanVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageNewFederalSubsidizedLoanVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageNewFederalSubsidizedLoanVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedSubNewInterestExpenseCondition === 1 && <tr>
                <td class="text-center">x</td>
                <td>Interest Rate</td>
                <td class="text-right">{detailedInfo['fedSubNewinterestRateVal']}%</td>
              </tr>}
              {detailedInfo.fedSubNewInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=/+</td>
                <td class="">Further Higher Education Federal Subsidized Student Loan Interest Expense</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['fedSubNewInterestExpenseVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedSubNewInterestExpenseVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedSubNewInterestExpenseVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedSubNewInterestExpenseVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedSubNewInterestExpenseCondition === 1 && <tr>
                <td class="text-center">+</td>
                <td class="strong">Principal Payback</td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['fedSubNewInterestPrincipalVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedSubNewInterestPrincipalVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedSubNewInterestPrincipalVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedSubNewInterestPrincipalVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedSubNewInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=</td>
                <td class="strong">Further Higher Education Federal Subsidized Loan Payment</td>
                <td class="strong text-right">

                  <span className={classNames(detailedInfo['fedSubNewHigherPaymentsVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedSubNewHigherPaymentsVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedSubNewHigherPaymentsVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedSubNewHigherPaymentsVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              {detailedInfo.fedUnubNewInterestExpenseCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Average Further Higher Education Federal Unsubsidized Student Loan Balance in Year {detailedInfo['Year']}</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['averageNewFederalUnsubsidizedLoanVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageNewFederalUnsubsidizedLoanVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageNewFederalUnsubsidizedLoanVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageNewFederalUnsubsidizedLoanVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedUnubNewInterestExpenseCondition === 1 && <tr>
                <td class="text-center">x</td>
                <td>Interest Rate</td>
                <td class="text-right">{detailedInfo['fedUnsubNewinterestRateVal']}%</td>
              </tr>}
              {(detailedInfo.furtherFedUnsubMakeInterestpaymentsCondition === 1 && detailedInfo.fedUnubNewInterestExpenseCondition === 1 && detailedInfo.fedUnsubNewGracePeriodCondition === 1) && <tr>
                <td class="text-center">=/+</td>
                <td>Further Higher Education Federal Unsubsidized Student Loan Interest Expense during 6 mo. of repayment</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['fedUnsubNewRepaymentValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnsubNewRepaymentValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnsubNewRepaymentValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnsubNewRepaymentValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.furtherFedUnsubMakeInterestpaymentsCondition === 1 && detailedInfo.fedUnubNewInterestExpenseCondition === 1 && detailedInfo.fedUnsubNewGracePeriodCondition === 1) && <tr>
                <td class="text-center">+</td>
                <td>Further Higher Education Federal Unsubsidized Student Loan Interest during 6 mo. Grace Period</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['fedUnsubNewGracePeriodValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnsubNewGracePeriodValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnsubNewGracePeriodValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnsubNewGracePeriodValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedUnubNewInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=/+</td>
                <td class="">Further Higher Education Federal Unsubsidized Student Loan Interest Expense</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['fedUnubNewInterestExpenseVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnubNewInterestExpenseVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnubNewInterestExpenseVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnubNewInterestExpenseVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedUnubNewInterestExpenseCondition === 1 && <tr>
                <td class="text-center">+</td>
                <td class="">Principal Payback</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['fedUnubNewInterestPrincipalVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnubNewInterestPrincipalVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnubNewInterestPrincipalVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnubNewInterestPrincipalVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.fedUnubNewInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=</td>
                <td class="strong">Further Higher Education Federal Unsubsidized Loan Payment</td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['fedUnsubStudentsLoanPaymentsVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['fedUnsubStudentsLoanPaymentsVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['fedUnsubStudentsLoanPaymentsVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['fedUnsubStudentsLoanPaymentsVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              <tr className={classes.incomeBorder} data-ng-if="detailedInfo.newPrivateInterestExpenseCondition === 1">
                <td>&nbsp;</td>
                <td>Average Further Higher Education Private Student Loan Balance in Year {detailedInfo['Year']}</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['averageNewPrivateLoanVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['averageNewPrivateLoanVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['averageNewPrivateLoanVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['averageNewPrivateLoanVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              {detailedInfo.newPrivateInterestExpenseCondition === 1 && <tr>
                <td class="text-center">x</td>
                <td>Interest Rate</td>
                <td class="text-right">{detailedInfo['newPrivateInterestRateVal']}%</td>
              </tr>}
              {(detailedInfo.furtherPrivateMakeInterestpaymentsCondition === 1 && detailedInfo.newPrivateInterestExpenseCondition === 1 && detailedInfo.furtherPrivateGracePeriodCondition === 1) && <tr>
                <td class="text-center">=/+</td>
                <td>Further Higher Education Private Student Loan Interest Expense during 6 mo. of repayment</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['furtherPrivateRepaymentValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['furtherPrivateRepaymentValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['furtherPrivateRepaymentValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['furtherPrivateRepaymentValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.furtherPrivateMakeInterestpaymentsCondition === 1 && detailedInfo.newPrivateInterestExpenseCondition === 1 && detailedInfo.furtherPrivateGracePeriodCondition === 1) && <tr>
                <td class="text-center">+</td>
                <td>Further Higher Education Private Student Loan Interest during 6 mo. Grace Period</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['furtherPrivateGracePeriodValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['furtherPrivateGracePeriodValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['furtherPrivateGracePeriodValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['furtherPrivateGracePeriodValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.newPrivateInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=/+</td>
                <td class="">Further Higher Education Private Student Loan Interest Expense</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['newPrivateInterestExpenseVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['newPrivateInterestExpenseVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['newPrivateInterestExpenseVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['newPrivateInterestExpenseVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.newPrivateInterestExpenseCondition === 1 && <tr>
                <td class="text-center">+</td>
                <td class="">Principal Payback</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo['newPrivateInterestPrincipalVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['newPrivateInterestPrincipalVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['newPrivateInterestPrincipalVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['newPrivateInterestPrincipalVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.newPrivateInterestExpenseCondition === 1 && <tr>
                <td class="text-center">=</td>
                <td class="strong">Further Higher Education Private Student Loan Payment</td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['privateNewHigherStudentsLoanPaymentsVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['privateNewHigherStudentsLoanPaymentsVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['privateNewHigherStudentsLoanPaymentsVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['privateNewHigherStudentsLoanPaymentsVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              {(detailedInfo.spouseAvarageStudentLoanBalCondition === 1 && detailedInfo.spouseStudentLoanInterestExpenseCondition === 1) && <tr>
                <td>&nbsp;</td>
                <td>Average Spouse Student Loan Balance in Year {detailedInfo['Year']}</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['spouseAvarageStudentLoanBalVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['spouseAvarageStudentLoanBalVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['spouseAvarageStudentLoanBalVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['spouseAvarageStudentLoanBalVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.spouseAnnualInterestRateCondition === 1 && detailedInfo.spouseStudentLoanInterestExpenseCondition === 1) && <tr>
                <td>x</td>
                <td>Interest Rate</td>
                <td class="text-right">
                  {detailedInfo['spouseAnnualInterestRateVal']}%
                </td>
              </tr>}
              {detailedInfo.spouseStudentLoanInterestExpenseCondition === 1 && <tr>
                <td>=/+</td>
                <td class="">Spouse Student Loan Interest Expense</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['spouseStudentLoanInterestExpenseVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['spouseStudentLoanInterestExpenseVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['spouseStudentLoanInterestExpenseVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['spouseStudentLoanInterestExpenseVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.spouseStudentLoanInterestExpenseCondition === 1 && <tr>
                <td>+</td>
                <td class="">Principal Payback</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo['spouseStudentLoanInterestPrincipalVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['spouseStudentLoanInterestPrincipalVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['spouseStudentLoanInterestPrincipalVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['spouseStudentLoanInterestPrincipalVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.spouseStudentLoanInterestExpenseCondition === 1 && <tr>
                <td>=</td>
                <td class="strong">Spouse Student Loan Payment</td>
                <td class="text-right strong">
                  <span className={classNames(detailedInfo['spouseStudentLoanPaymentsVal'] < 0 && classes.minusValue)}>

                    {detailedInfo['spouseStudentLoanPaymentsVal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['spouseStudentLoanPaymentsVal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['spouseStudentLoanPaymentsVal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}


              <tr className={classes.incomeBorder}>
                <td className={classes.textCenter}>=</td>
                <td className={classes.strongText}>Total Student Loan Payments</td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['InterestExpenses']['Student Loans'] < 0 && classes.minusValue)}>
                    {detailedInfo['InterestExpenses'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['InterestExpenses']['Student Loans']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(detailedInfo['InterestExpenses']['Student Loans'] < 0 ? detailedInfo['InterestExpenses']['Student Loans'] : 0)} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openDialogName === 'educationTuitionPopup' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.incomeStatementDialog}
      >
        <DialogContent>
          <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td class="strong text-center" colspan="2">{detailedInfo['CollegeName']} Tuition & Fees</td>
                <td class="strong text-right">Year {detailedInfo['Year']}</td>
              </tr>
              <tr>
                <td colspan="4">&nbsp;</td>
              </tr>
              {detailedInfo.condition === 0 && <tr className={classes.incomeBorder}>
                <td class="text-center">=</td>
                <td class="strong" colspan="2">{detailedInfo['Year']} Total Tuition & Fees</td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['livingExpenses']['Higher Education Tuition and Fees'] < 0 && classes.minusValue)}>

                    {detailedInfo['livingExpenses']['Higher Education Tuition and Fees'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['livingExpenses']['Higher Education Tuition and Fees']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['livingExpenses']['Higher Education Tuition and Fees']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.condition === 1 || detailedInfo.condition === 2) && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textCenter)}>Start Year</td>
                <td className={classNames(classes.strongText, classes.textCenter)}>End Year</td>
                <td className={classNames(classes.strongText, classes.textCenter)}>Annual Tuition & Fees</td>
              </tr>}
              {(detailedInfo.condition === 1 || detailedInfo.condition === 2) && <tr className={classes.rowBorder}>
                <td>&nbsp;</td>
                <td class="text-center value-portion no-border-right">{detailedInfo.StartYear}</td>
                <td class="text-center value-portion no-border-right">{detailedInfo.EndYear}</td>
                <td class="text-center value-portion">

                  <span className={classNames(detailedInfo.Tuition < 0 && classes.minusValue)}>

                    {detailedInfo.Tuition >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.Tuition} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.Tuition} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.condition === 1 || detailedInfo.condition === 2) && <tr>
                <td colspan="4">&nbsp;</td>
              </tr>}
              {detailedInfo.condition === 1 && <tr className={classes.incomeBorder}>
                <td class="text-center">=</td>
                <td class="strong" colspan="2">{detailedInfo['Year']} Total Tuition & Fees</td>
                <td class="strong text-right">

                  <span className={classNames(detailedInfo['livingExpenses']['Higher Education Tuition and Fees'] < 0 && classes.minusValue)}>

                    {detailedInfo['livingExpenses']['Higher Education Tuition and Fees'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['livingExpenses']['Higher Education Tuition and Fees']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['livingExpenses']['Higher Education Tuition and Fees']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.condition === 2 && <tr>
                <td class="text-center">&nbsp;</td>
                <td colspan="2">{detailedInfo['Year'] - 1} Tuition & Fees</td>
                <td class="text-right">
                  <span className={classNames(detailedInfo.previousIncomeValue < 0 && classes.minusValue)}>

                    {detailedInfo.previousIncomeValue >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.previousIncomeValue} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.previousIncomeValue} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.condition === 2 && <tr>
                <td class="text-center">x</td>
                <td colspan="2">Annual Inflation</td>
                <td class="text-right"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={Inflation > 0 ? Inflation : 0} suffix="%" /></td>
              </tr>}
              {detailedInfo.condition === 2 && <tr className={classes.incomeBorder}>
                <td class="text-center">=</td>
                <td class="strong" colspan="2">{detailedInfo['Year']} Total Tuition & Fees</td>
                <td class="strong text-right">

                  <span className={classNames(detailedInfo['livingExpenses']['Higher Education Tuition and Fees'] < 0 && classes.minusValue)}>

                    {detailedInfo['livingExpenses']['Higher Education Tuition and Fees'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['livingExpenses']['Higher Education Tuition and Fees']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['livingExpenses']['Higher Education Tuition and Fees']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogName === 'roomandboardpopup' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.incomeStatementDialog}
      >
        <DialogContent>
          <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td class="strong text-center" colspan="2">{detailedInfo['CollegeName']} Room & Board</td>
                <td class="strong text-right">Year {detailedInfo['Year']}</td>
              </tr>
              <tr>
                <td colspan="4">&nbsp;</td>
              </tr>
              {detailedInfo.condition === 0 && <tr>
                <td class="text-center">=</td>
                <td class="strong" colspan="2">{detailedInfo['Year']} Room & Board</td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['livingExpenses']['Higher Education Room & Board'] < 0 && classes.minusValue)}>

                    {detailedInfo['livingExpenses']['Higher Education Room & Board'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['livingExpenses']['Higher Education Room & Board']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['livingExpenses']['Higher Education Room & Board']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {((detailedInfo.condition === 1 || detailedInfo.condition === 2) && detailedInfo.finalValueCondtion === 1) && detailedInfo.StartYear > 0 && <tr>
                <td>&nbsp;</td>
                <td class="strong text-center">Start Year</td>
                <td class="strong text-center">End Year</td>
                <td class="strong text-center">Annual Room & Board</td>
              </tr>}
              {((detailedInfo.condition === 1 || detailedInfo.condition === 2) && detailedInfo.finalValueCondtion === 1) && detailedInfo.StartYear > 0 && <tr>
                <td>&nbsp;</td>
                <td class="text-center value-portion no-border-right">{detailedInfo.StartYear}</td>
                <td class="text-center value-portion no-border-right">{detailedInfo.EndYear}</td>
                <td class="text-center value-portion">

                  <span className={classNames(detailedInfo.RoomBoard < 0 && classes.minusValue)}>

                    {detailedInfo.RoomBoard >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.RoomBoard} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.RoomBoard} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {((detailedInfo.condition === 1 || detailedInfo.condition === 2) && detailedInfo.finalValueCondtion === 1) && <tr>
                <td colspan="4">&nbsp;</td>
              </tr>}
              <tr>
                <td class="text-center">=</td>
                <td class="strong" colspan="2">{detailedInfo['Year']} Total Room & Board</td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['livingExpenses']['Higher Education Room & Board'] < 0 && classes.minusValue)}>
                    {detailedInfo['livingExpenses']['Higher Education Room & Board'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['livingExpenses']['Higher Education Room & Board']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['livingExpenses']['Higher Education Room & Board']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              {(detailedInfo.condition === 2 && detailedInfo.finalValueCondtion === 1) && <tr>
                <td class="text-center">&nbsp;</td>
                <td colspan="2">{detailedInfo['Year'] - 1} Room & Board</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo.previousValue < 0 && classes.minusValue)}>

                    {detailedInfo.previousValue >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.previousValue} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.previousValue} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.condition === 2 && detailedInfo.finalValueCondtion === 1) && <tr>
                <td class="text-center">x</td>
                <td colspan="2">Annual Inflation</td>
                <td class="text-right"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={Inflation > 0 ? Inflation : 0} suffix="%" /></td>
              </tr>}
              {detailedInfo.condition === 2 && <tr className={classes.incomeBorder}>
                <td class="text-center">=</td>
                <td class="strong" colspan="2">{detailedInfo['Year']} Room & Board</td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['livingExpenses']['Higher Education Room & Board'] < 0 && classes.minusValue)}>

                    {detailedInfo['livingExpenses']['Higher Education Room & Board'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['livingExpenses']['Higher Education Room & Board']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['livingExpenses']['Higher Education Room & Board']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogName === 'livingExpensesPortion' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.incomeStatementDialog}
      >
        <DialogContent>
          {/* <table class="living-expenses-portion"> */}
          <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td class="strong text-center" colspan="2">{detailedInfo['Label']}</td>
                <td class="strong text-right">Year {detailedInfo['Year']}</td>
              </tr>
              {detailedInfo.marriageValueCondition === 0 && <tr>
                <td colspan="4">&nbsp;</td>
              </tr>}
              {detailedInfo.marriageValueCondition === 1 && <tr>
                <td colspan="4">&nbsp;</td>
              </tr>}
              {(detailedInfo.marriageValueCondition === 1 && detailedInfo.finalValueCondition === 1) && <tr>
                <td class="text-center">&nbsp;</td>
                {detailedInfo.condition !== 1 && <td colspan="2">{detailedInfo['Year'] - 1} Monthly {detailedInfo['Label']} </td>}
                {detailedInfo.condition === 1 && <td colspan="2">{detailedInfo['Year']} Monthly {detailedInfo['Label']} </td>}
                <td class="text-right">
                  <span className={classNames(detailedInfo['marriedMonthlyRentExpense'] < 0 && classes.minusValue)}>

                    {detailedInfo['marriedMonthlyRentExpense'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['marriedMonthlyRentExpense']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['marriedMonthlyRentExpense']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.marriageValueCondition === 1 && detailedInfo.finalValueCondition === 1) && <tr>
                <td class="text-center">x</td>
                <td colspan="2">Marriage Multiple</td>
                <td class="text-right">
                  {detailedInfo['marriedMonthlyMultiplier'] < 0 && <span class="minus-value">({-(detailedInfo['marriedMonthlyMultiplier'])})x</span>}
                  {detailedInfo['marriedMonthlyMultiplier'] >= 0 && <span>{detailedInfo['marriedMonthlyMultiplier']}x</span>}
                </td>
              </tr>}
              {((detailedInfo.condition === 0 || detailedInfo.condition === 1) && detailedInfo.finalValueCondition === 1) && <tr>
                {detailedInfo.marriageValueCondition === 1 && <td class="text-center">=</td>}
                {detailedInfo.marriageValueCondition === 0 && <td>&nbsp;</td>}
                <td colspan="2">{detailedInfo['Year']} Monthly {detailedInfo['Label']}</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo.Value < 0 && classes.minusValue)}>

                    {detailedInfo.Value >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.Value} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.Value} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              {(detailedInfo.condition === 2 && detailedInfo.finalValueCondition === 1) && <tr>
                {detailedInfo.marriageValueCondition === 1 && <td class="text-center">=</td>}
                {detailedInfo.marriageValueCondition === 0 && <td class="text-center">&nbsp;</td>}
                <td colspan="2">{detailedInfo['Year'] - 1} Monthly {detailedInfo['Label']} </td>
                <td class="text-right">

                  <span className={classNames(detailedInfo.previousValue < 0 && classes.minusValue)}>

                    {detailedInfo.previousValue >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.previousValue} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.previousValue} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.condition === 2 && detailedInfo.finalValueCondition === 1) && <tr>
                <td class="text-center">x</td>
                <td colspan="2">Inflation</td>
                <td class="text-right">{Inflation > 0 ? Inflation : 0}%</td>
              </tr>}
              {(detailedInfo.condition === 2 && detailedInfo.finalValueCondition === 1) && <tr>
                <td class="text-center">=</td>
                <td class="strong" colspan="2">{detailedInfo['Year']} Monthly {detailedInfo['Label']} </td>
                <td class="strong text-right">

                  <span className={classNames(detailedInfo.Value < 0 && classes.minusValue)}>

                    {detailedInfo.Value >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.Value} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.Value} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.finalValueCondition === 1 && <tr>
                <td class="text-center">x</td>
                <td colspan="2">Annual Multiplier</td>
                <td class="text-right">12.0x</td>
              </tr>}
              <tr className={classes.incomeBorder}>
                <td class="text-center">=</td>
                <td class="strong" colspan="2">{detailedInfo['Year']} Annual {detailedInfo['Label']} </td>
                <td class="strong text-right">

                  <span className={classNames(detailedInfo['livingExpenses'][detailedInfo['selectedColumn']] < 0 && classes.minusValue)}>

                    {detailedInfo['livingExpenses'][detailedInfo['selectedColumn']] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['livingExpenses'][detailedInfo['selectedColumn']]} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['livingExpenses'][detailedInfo['selectedColumn']]} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogName === 'livingExpensesPortion-onyear' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.incomeStatementDialog}
      >
        <DialogContent>
          <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td class="strong text-center" colspan="2">{detailedInfo['Label']}</td>
                <td class="strong text-right">Year {detailedInfo['Year']}</td>
              </tr>
              {detailedInfo.marriageValueCondition === 0 && <tr>
                <td colspan="4">&nbsp;</td>
              </tr>}
              {detailedInfo.marriageValueCondition === 1 && <tr>
                <td colspan="4">&nbsp;</td>
              </tr>}
              {(detailedInfo.marriageValueCondition === 1 && detailedInfo.finalValueCondition === 1) && <tr>
                <td class="text-center">&nbsp;</td>
                {detailedInfo.condition !== 1 && <td colspan="2">{detailedInfo['Year'] - 1} Monthly {detailedInfo['Label']} </td>}
                {detailedInfo.condition === 1 && <td colspan="2">{detailedInfo['Year']} Monthly {detailedInfo['Label']} </td>}
                <td class="text-right">
                  <span className={classNames(detailedInfo['marriedMonthlyRentExpense'] < 0 && classes.minusValue)}>

                    {detailedInfo['marriedMonthlyRentExpense'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['marriedMonthlyRentExpense']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['marriedMonthlyRentExpense']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.condition === 2 && detailedInfo.finalValueCondition === 1) && <tr>
                <td class="text-center">x</td>
                <td colspan="2">Inflation</td>
                <td class="text-right">{Inflation > 0 ? Inflation : 0}%</td>
              </tr>}

              {((detailedInfo.condition === 0 || detailedInfo.condition === 1) && detailedInfo.finalValueCondition === 1) && <tr>
                {detailedInfo.marriageValueCondition === 1 && <td class="text-center">=</td>}
                {detailedInfo.marriageValueCondition === 0 && <td>&nbsp;</td>}
                <td colspan="2">{detailedInfo['Year']} Monthly {detailedInfo['Label']}</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo.Value < 0 && classes.minusValue)}>

                    {detailedInfo.Value >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.Value} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.Value} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              {(detailedInfo.condition === 2 && detailedInfo.finalValueCondition === 1) && <tr>
                {detailedInfo.marriageValueCondition === 1 && <td class="text-center">=</td>}
                {detailedInfo.marriageValueCondition === 0 && <td class="text-center">&nbsp;</td>}
                <td colspan="2">{detailedInfo['Year'] - 1} Monthly {detailedInfo['Label']} </td>
                <td class="text-right">

                  <span className={classNames(detailedInfo.previousValue < 0 && classes.minusValue)}>

                    {detailedInfo.previousValue >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.previousValue} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.previousValue} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              {(detailedInfo.marriageValueCondition === 1 && detailedInfo.finalValueCondition === 1) && <tr>
                <td class="text-center">x</td>
                <td colspan="2">Marriage Multiple</td>
                <td class="text-right">
                  {detailedInfo['marriedMonthlyMultiplier'] < 0 && <span class="minus-value">({-(detailedInfo['marriedMonthlyMultiplier'])})x</span>}
                  {detailedInfo['marriedMonthlyMultiplier'] >= 0 && <span>{detailedInfo['marriedMonthlyMultiplier']}x</span>}
                </td>
              </tr>}

              {(detailedInfo.condition === 2 && detailedInfo.finalValueCondition === 1) && <tr>
                <td class="text-center">=</td>
                <td class="strong" colspan="2">{detailedInfo['Year']} Monthly {detailedInfo['Label']} </td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo.Value < 0 && classes.minusValue)}>

                    {detailedInfo.Value >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.Value} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.Value} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}

              {detailedInfo.finalValueCondition === 1 && <tr>
                <td class="text-center">x</td>
                <td colspan="2">Annual Multiplier</td>
                <td class="text-right">12.0x</td>
              </tr>}
              <tr className={classes.incomeBorder}>
                <td class="text-center">=</td>
                <td class="strong" colspan="2">{detailedInfo['Year']} Annual {detailedInfo['Label']} </td>
                <td class="strong text-right">
                  <span className={classNames(detailedInfo['livingExpenses'][detailedInfo['selectedColumn']] < 0 && classes.minusValue)}>

                    {detailedInfo['livingExpenses'][detailedInfo['selectedColumn']] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['livingExpenses'][detailedInfo['selectedColumn']]} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['livingExpenses'][detailedInfo['selectedColumn']]} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

InputStatementPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  dispalyObj: PropTypes.string.isRequired,
  rangeList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(InputStatementPopup);
