import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Link, withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import styles from './styles';
import mcsTopImage from '../../../assets/img/mcs/level8_bg.png';
import logoImage from '../../../assets/img/mcs/my-career-sketch.png';
import levelIcon from '../../../assets/img/mcs/MCS-level09.png';
import timelineGraphic from '../../../assets/img/mcs/timeline.png';
import progressImage from '../../../assets/img/mcs/100.png';
import questionGraphic from '../../../assets/img/mcs/bigquestion.png';
import tenCircles from '../../../assets/img/mcs/questions-graphic-transp.png';
import logoInkwiry from '../../../assets/img/mcs/logo.png';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Pageloader from '../../../components/ui/pageloader';

const MCS_LEVEL_ACTIVITY_DATA = loader('../../../graphql/schema/career-sketch/mcs-level-activity.graphql');
const SAVEMYCAREERSKETCH = loader('../../../graphql/schema/career-sketch/save-careersketch.graphql');

const staticLevelData = [
  {
    "id": "7",
    "user_id": 214,
    "level": 2,
    "option_id": 1,
    "status": 0,
    "completed_date": "09/03/2020 at 6:59pm EST",
    "created_date": "2020-09-03 13:29:33"
  },
  {
    "id": "8",
    "user_id": 214,
    "level": 2,
    "option_id": 2,
    "status": 0,
    "completed_date": "09/03/2020 at 6:59pm EST",
    "created_date": "2020-09-03 13:30:01"
  },
  {
    "id": "8",
    "user_id": 214,
    "level": 2,
    "option_id": 3,
    "status": 0,
    "completed_date": "09/03/2020 at 6:59pm EST",
    "created_date": "2020-09-03 13:30:01"
  }
]

const McsLevelSeven = (props) => {
  const { classes, history } = props;
  const [firstActivityData, setFirstActivityData] = React.useState("");
  const [secondActivityData, setSecondActivityData] = React.useState("");
  const [thirdActivityData, setThirdActivityData] = React.useState("");
  const [startActivity, setStartActivity] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [levelPercentage, setLevelPercentage] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const [submitFirstActivity] = useMutation(SAVEMYCAREERSKETCH, {
    onCompleted({
      saveMyCareerSketch: {
        status,
      },
    }) {
      if (status) {
        setLoading(false);
        history.push('./dashboard');
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        message
      ));
      setErrorMessage(formatedErrors);
      setLoading(false);
    },
  });

  const { data: mcsLevelWiseData } = useQuery(MCS_LEVEL_ACTIVITY_DATA, {
    variables: {
      level_id: "7",
    },
    fetchPolicy: 'network-only',
    onCompleted() {
      setLevelPercentage(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_percent);
      setFirstActivityData(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_activity.length > 0 ? mcsLevelWiseData.getMCSLevelActivityData.level_activity.filter(obj => obj.option_id === 1)[0] : staticLevelData.filter(obj => obj.option_id === 1)[0]);
      setSecondActivityData(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_activity.length > 0 ? mcsLevelWiseData.getMCSLevelActivityData.level_activity.filter(obj => obj.option_id === 2)[0] : staticLevelData.filter(obj => obj.option_id === 2)[0]);
      setThirdActivityData(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_activity.length > 0 ? mcsLevelWiseData.getMCSLevelActivityData.level_activity.filter(obj => obj.option_id === 3)[0] : staticLevelData.filter(obj => obj.option_id === 3)[0]);
    },
  });
  React.useEffect(() => {
    if (mcsLevelWiseData) {
      setLoading(false);
    }
  }, [mcsLevelWiseData]);
  const handleStartActivity = () => {
    setStartActivity(true);
  }
  const handleCompletedFirstActivity = (activity_id) => {
    setLoading(true);
    submitFirstActivity({
      variables: {
        data: {
          level: "7",
          option_id: activity_id,
        },
      },
    });
  }
  const handleGotoNextLevel = () => {
  }
  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={loading} />}
      <div className={classes.mcsLevelOne}>
        <div className={classes.topImage}>
          <img src={mcsTopImage} alt="mcs" />
        </div>
        <div className={classes.container}>
          <div className={classes.mcsRow}>
            <div className={classes.mcsLogoImage}>
              <Link to="/my-career-sketch/dashboard">
                <img src={logoImage} alt="logo" />
              </Link>
            </div>
            <div className={classes.mcsLevelNum}>
              <h3>LEVEL 07</h3>
              <img src={levelIcon} alt="info" className={classes.iconImage} />
            </div>
          </div>
          <div className={classes.mcsBodyContent}>
            <Grid container>
              <Grid item md={6} sm={12} xs={12}>
                <div className={classes.leftBlock}>
                  <h1 className={classes.mcsLevelTitle}>Checklist + Next Steps</h1>
                  <div className={classes.qPosition}>
                    <div className={classes.questionBlock}>
                      <p>What defines a great career sketch? </p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>
                        The best sketch uses fewer resources and poses less risk as you reach targets and achieve goals. In financial life planning, the two most important resources are time and money. When you find ways to achieve your goals in a shorter amount of time while using less money, your sketch is probably less risky.
                      </p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>First, run your career sketch past the seven-point checklist to make sure you hit all the important steps.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>Congratuations: Next, you’ll want to download your Certificate of Completion.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>You&apos;re now ready to build a complete career sketch by adding life events such as investments, buying a car and home, vacation and more. Download the Career Sketching Toolkit to use as your guide. </p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>As you begin to complete your career sketch, take advantage of these two Inkwiry challenges:</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <ul>
                        <li>
                          <span className={classes.blueColor}>Awareness Challenge:</span>
                          {' '}
                          Before you make a single move, discover how investing 15 minutes in the Awareness Challenge can greatly improve your chances for success.
                        </li>
                        <li>
                          <span className={classes.blueColor}>Discovery Challenge:</span>
                          {' '}
                          Think you know money? Put your financial skills and knowledge to the test in the Discovery Challenge.
                          {' '}
                        </li>
                      </ul>
                    </div>
                    {startActivity ? secondActivityData && secondActivityData.status === 1 ?
                      <div className={classes.startButton}>
                        <button type="button" onClick={() => handleCompletedFirstActivity("3")}>complete third Activity</button>
                      </div> : firstActivityData && firstActivityData.status === 1 ?
                        <div className={classes.startButton}>
                          <button type="button" onClick={() => handleCompletedFirstActivity("2")}>complete second Activity</button>
                        </div> : <div className={classes.startButton}>
                          <button type="button" onClick={() => handleCompletedFirstActivity("1")}>complete first Activity</button>
                        </div>
                      : thirdActivityData && thirdActivityData.status === 1 ? <div className={classes.startButton}>
                        <button type="button" onClick={handleGotoNextLevel}>DOWNLOAD CERTIFICATE</button>
                      </div> : firstActivityData && firstActivityData.status === 1 ? <div className={classes.startButton}>
                        <button type="button" onClick={handleStartActivity}>continue</button>
                      </div> : <div className={classes.startButton}>
                        <button type="button" onClick={handleStartActivity}>start</button>
                      </div>}
                    {errorMessage && <span>{errorMessage}</span>}
                  </div>
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className={classes.rightBlock}>
                  <div className={classes.checklistImage}>
                    <img src={timelineGraphic} alt="timeline" />
                  </div>
                  <div className={classes.questionImage}>
                    <img src={questionGraphic} alt="question" />
                  </div>
                  <div className={classes.tenCircles}>
                    <img src={tenCircles} alt="circles" />
                  </div>
                  <div className={classes.activeRow}>
                    <div className={classes.activities}>
                      <h4>Level 07 Activities</h4>
                      <div className={classes.checkboxRadio}>
                        <label htmlFor="2_1" className={firstActivityData && firstActivityData.status === 1 ? classes.checkedEmployment : ""}>
                          <input type="checkbox" name="equation" id="2_1" />
                          Complete the checklist
                        </label>
                        {firstActivityData && firstActivityData.status === 1 ? <button type="button" className={classes.goButton}> Review</button> : ""}
                      </div>
                      <div className={classes.checkboxRadio}>
                        <label htmlFor="2_2" className={secondActivityData && secondActivityData.status === 1 ? classes.checkedEmployment : ""}>
                          <input type="checkbox" name="equation" id="2_2" />
                          Download your Certificate of Completion
                        </label>
                        {secondActivityData && secondActivityData.status === 1 ? <button type="button" className={classes.goButton}>Review</button> : ""}
                      </div>
                      <div className={classes.checkboxRadio}>
                        <label htmlFor="2_3" className={thirdActivityData && thirdActivityData.status === 1 ? classes.checkedEmployment : ""}>
                          <input type="checkbox" name="equation" id="2_3" />
                          Download the Career Sketching Toolkit
                        </label>
                        {thirdActivityData && thirdActivityData.status === 1 ? <button type="button" className={classes.goButton}>Review</button> : ""}
                      </div>
                    </div>
                    <div className={classes.myLevelProgress}>
                      <h4>My Level 07 Progress</h4>
                    </div>
                    <div className={classes.progressPuzzle}>
                      <span className={classes.progressPercent}>{levelPercentage}%</span>
                      <p className={classes.puzzleBar}>
                        <img src={progressImage} alt="info" />
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.mcsFooter}>
          <div className={classes.container}>
            <div className={classes.footerRow}>
              <div className={classes.copyRight}>
                <p>Copyright © 2022 // All rights reserved</p>
              </div>
              <div className={classNames(classes.copyRight, classes.informedText)}>
                <p>
                  Informed and Powered by
                  <a href="/"><img src={logoInkwiry} alt="inkwiry" /></a>
                </p>
              </div>
            </div>
          </div>
          <div className={classes.mcsFluid}>
            <p className={classes.termLinks}>
              <a href="/">Terms of Service</a>
&nbsp;//&nbsp;
              <a href="/">Privacy Policy</a>
&nbsp;//&nbsp;
              <a href="/">Security</a>
            </p>
          </div>
        </div>
      </div>
    </Typography>
  );
};

McsLevelSeven.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(McsLevelSeven);