import * as moduleServices from '../calculations/modules-services';


function balanceSheetGraph() {

  var balanceSheetCompleteData = moduleServices.balanceSheetCompleteData();
  let graphsData = {};
      //Total Tangible Assets
      graphsData[1] = {
        chart: {
          renderTo: "container",
          defaultSeriesType: 'bar',
          type: 'column',
          width: 100,
          height: 30,
          spacingBottom: -8,
          spacingTop: -10,
          spacingLeft: 0,
          spacingRight: 0,
          verticalAlign: 'top',
          top: 0,
          plotBackgroundColor: '#DDDAD6'
        },
        exporting: {
          enabled: false
        },
        title: {
          text: null
        },
        xAxis: {
          labels:
          {
            enabled: false
          }
        },
        yAxis: {
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels:
          {
            enabled: false
          }
        },
        
        legend: {
          enabled: false
        },
        credits: {
          verticalAlign: 'top',
          enabled: false
        },
        plotOptions: {
          allowPointSelect: false,
          series: {
            negativeColor: '#BF0000'
          }
        },
        
        tooltip: {
          enabled: false
        },
        series: [{
          data: balanceSheetCompleteData['Total Tangible Assets Graph'],
          color: '#2F75B5',
          pointWidth: 5
        }]
      }

      //Total Investments  
      graphsData[2] = {
        chart: {
          renderTo: "container",
          defaultSeriesType: 'bar',
          type: 'column',
          width: 100,
          height: 30,
          spacingBottom: -8,
          spacingTop: -10,
          spacingLeft: 0,
          spacingRight: 0,
          verticalAlign: 'top',
          top: 0,
          plotBackgroundColor: '#DDDAD6'
        },
        exporting: {
          enabled: false
        },
        title: {
          text: null
        }, 
        xAxis: {
          labels:
          {
            enabled: false
          }
        },
        yAxis: {
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels:
          {
            enabled: false
          }
        },
        
        legend: {
          enabled: false
        },
        credits: {
          verticalAlign: 'top',
          enabled: false
        },
        plotOptions: {
          allowPointSelect: false,
          series: {
            negativeColor: '#BF0000'
          }
        },
        
        tooltip: {
          enabled: false
        },
        series: [{
          data: balanceSheetCompleteData['Total Investments Graph'],
          color: '#2F75B5',
          pointWidth: 5
        }]
      }

      //Total Assets  
      graphsData[3] = {
        chart: {
          renderTo: "container",
          defaultSeriesType: 'bar',
          type: 'column',
          width: 100,
          height: 30,
          spacingBottom: -8,
          spacingTop: -10,
          spacingLeft: 0,
          spacingRight: 0,
          verticalAlign: 'top',
          top: 0,
          plotBackgroundColor: '#DDDAD6'
        },
        exporting: {
          enabled: false
        },
        title: {
          text: null
        },  
        xAxis: {
          labels:
          {
            enabled: false
          }
        },
        yAxis: {
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels:
          {
            enabled: false
          }
        },
        
        legend: {
          enabled: false
        },
        credits: {
          verticalAlign: 'top',
          enabled: false
        },
        plotOptions: {
          allowPointSelect: false,
          series: {
            negativeColor: '#BF0000'
          }
        },
        
        tooltip: {
          enabled: false
        },
        series: [{
          data: balanceSheetCompleteData['Total Assets Graph'],
          color: '#2F75B5',
          pointWidth: 5

        }]
      }

      //Total Liabilities  
      graphsData[4] = {
        chart: {
          renderTo: "container",
          defaultSeriesType: 'bar',
          type: 'column',
          width: 100,
          height: 30,
          spacingBottom: -8,
          spacingTop: -10,
          spacingLeft: 0,
          spacingRight: 0,
          verticalAlign: 'top',
          top: 0,
          plotBackgroundColor: '#DDDAD6'
        },
        exporting: {
          enabled: false
        },
        title: {
          text: null
        },
        xAxis: {
          labels:
          {
            enabled: false
          }
        },
        yAxis: {
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels:
          {
            enabled: false
          }
        },
        
        legend: {
          enabled: false
        },
        credits: {
          verticalAlign: 'top',
          enabled: false
        },
        plotOptions: {
          allowPointSelect: false,
          series: {
            negativeColor: '#BF0000'
          }
        },
        
        tooltip: {
          enabled: false
        },
        series: [{
          data: balanceSheetCompleteData['Total Liabilities Graph'],
          color: '#2F75B5',
          pointWidth: 5
        }]
      }

      //Net Worth
      graphsData[5] = {
        chart: {
          renderTo: "container",
          defaultSeriesType: 'bar',
          type: 'column',
          width: 100,
          height: 30,
          spacingBottom: -8,
          spacingTop: -10,
          spacingLeft: 0,
          spacingRight: 0,
          verticalAlign: 'top',
          top: 0,
          plotBackgroundColor: '#DDDAD6',
          border: 0
        },
        exporting: {
          enabled: false
        },
        title: {
          text: null
        },
        xAxis: {
          labels:
          {
            enabled: false
          }
        },
        yAxis: {
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels:
          {
            enabled: false
          }
        },                    
        legend: {
          enabled: false
        },
        credits: {
          verticalAlign: 'top',
          enabled: false
        },
        plotOptions: {
          allowPointSelect: false,
          series: {
            negativeColor: '#BF0000'
          }
        },                  
        tooltip: {
          enabled: false
        },
        series: [{
          data: balanceSheetCompleteData['Net Worth Graph'],
          color: '#2F75B5',
          pointWidth: 5
        }]
      }


      return graphsData; 
    }
    export default balanceSheetGraph;
