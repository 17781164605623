import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Link,
} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import scrollToComponent from 'react-scroll-to-component';
import Sidebar from './sidebar';
import DefinationPopup from '../../components/ui/pwi-sidebar/defination-popup';
import Introduction from '../../components/pwi/family/introduction';
import MarriageYear from '../../components/pwi/family/marrige-year';
import FederalIncomeTax from '../../components/pwi/family/federal-income-tax';
import SpouseCareerFirst from '../../components/pwi/family/spouse-career-first';
import SpouseCareerSecond from '../../components/pwi/family/spouse-career-second';
import IncomeAfterTaxesFirst from '../../components/pwi/family/income-after-tax-first';
import SpouseCareerAdvancementFirst from '../../components/pwi/family/spouse-career-advancement-first';
import SpouseCareerAdvancementSecond from '../../components/pwi/family/spouse-career-advancement-second';
import IncomeAfterTaxesSecond from '../../components/pwi/family/income-after-tax-second';
import UpdatingCombinedBudgetFirst from '../../components/pwi/family/updating-combined-budget-first';
import LivingExpensesAdjustment from '../../components/pwi/family/living-expenses-adjustment';
import UpdatingCombinedBudgetSecond from '../../components/pwi/family/updating-combined-budget-second';
import UpdatingCombinedBudgetThird from '../../components/pwi/family/updating-combined-budget-third';
import CombinedBudgetSummary from '../../components/pwi/family/conbined-budget-summery';
import SpouseStudentLoanDebt from '../../components/pwi/family/spouse-student-loan-debt';
import StartingFamily from '../../components/pwi/family/starting-family';
import AverageCostRaisingChild from '../../components/pwi/family/average-cost-raising-child';
import AddPetFamily from '../../components/pwi/family/add-pet-family';
import FamilySummery from '../../components/pwi/family/family-summery';
import MyCombinedBudget from '../../components/pwi/family/my-combined-budget';
import headerFI from '../../assets/img/pwi-investments/family.png';
import LogoImage from '../../assets/img/logo.png';
import styles from './styles';
import PwiLeftMenu from '../../components/pwi/pwi-left-menu';
import save from '../../assets/img/build-career-sketch/icon-save-blue.png';
import PwiSave from '../../components/pwi-save/pwi-save';
import SavePopupSucess from '../../components/common/save-popup-success';
import Pageloader from '../../components/ui/pageloader';
import Walk from '../../assets/img/pwi-investments/walk.png';
import ClearTime from '../../assets/img/pwi-investments/clear-timeline-blue.png';
import WalkThruOne from '../pwi/wallk-thru/walkThruOne';
import WalkThruTwo from '../pwi/wallk-thru/walkThruTwo';
import WalkThruThree from '../pwi/wallk-thru/walkThruThree';
import WalkThruFour from '../pwi/wallk-thru/walkThruFour';
import WalkThruFive from '../pwi/wallk-thru/walkThruFive';
import WalkThruSix from '../pwi/wallk-thru/walkThruSix';
import WalkThruSeven from '../pwi/wallk-thru/walkThruSeven';
import WalkThruEight from '../pwi/wallk-thru/walkThruEight';
import * as modulesServices from '../../calculations/modules-services';
import * as timeLineActions from '../../calculations/time-line';
import messages from '../../utilities/pwi-popup-messages';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import * as moduleServices from '../../calculations/modules-services';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';
import SimulatorPanel from '../../components/documents/simulator-panel';
import SummaryDocs from '../../components/documents/documents';
const PWI_DEFINATION = loader('../../graphql/schema/financial-independence/getPWiDefination.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');
const CHECK_MODULE_EXISTS = loader('../../graphql/schema/fc/check-module-exists.graphql');
const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');

const titleText = [
  'Introduction',
  'Marriage Year',
  'Federal Income Tax Changes',
  "Spouse's Career",
  "Spouse's Career",
  'Income After Taxes',
  "Spouse's Career Advancement",
  "Spouse's Career Advancement",
  'Income After Taxes',
  'Updating Your Combined Budget',
  'Living Expenses Adjustment',
  'Updating Your Combined Budget',
  'Updating Your Combined Budget (Continued)',
  'Combined Budget Summary',
  'Spouse Student Loan Debt',
  'Starting a Family',
  'Average Cost of Raising a Child',
  'Adding a Pet to the Family',
  'Family Summary',
];

const Family = (props) => {
  const { classes, history } = props;
  let bodySection = useRef(null);

  const [menu, setMenu] = useState(false);
  const [mainMenu, setMainMenu] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [titleDefination, setTitleDefination] = useState('');
  const [definationPopup, setDefinationPopup] = useState(false);
  const [progress, setProgress] = useState(0);
  const [completedSlides, setCompletedSlides] = useState({ 0: true });
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = useState({ 0: false });
  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [inputData, setInputData] = useState({});
  const [saveKey, setSaveKey] = useState('');
  const [popupClose, setPopupClose] = useState(false);
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [clearModule, setClearModule] = React.useState(false);
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [openPopup, setOpenPopup] = React.useState('');
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [loadValue, setLoadValue] = React.useState(false);

  const { data: definationResult } = useQuery(PWI_DEFINATION, {
    variables: {
      title: titleDefination,
    },
    fetchPolicy: 'network-only',
    skip: titleDefination === '',
  });


  // added new below function now
  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module10' : ''
    },
    onCompleted(response) {
      setClearModule(false);
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          let completedSlide = {};
          for (let c = 0; c <= 18; c += 1) {
            completedSlide[c] = true;
          } 
          setCompletedSlides(completedSlide);
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          setInputData(data);
        } else if(moduleServices.module1Data.start_year <= 0){
          const popupdetails = messages('No Fi Module');
          let saveContinueBtn = true;
          setBeforeLeavePopup({...{popupStatus: true,type:'confirm',no_pwi_fi: '/plan-with-inkwiry/fi',saveContinueBtn,'classVal':'clearConfirmDialog'},...popupdetails})
        } else {
          let completedSlide = {};
          for (let c = 0; c <= 18; c += 1) {
            completedSlide[c] = false;
          } 
          setCompletedSlides(completedSlide);
          setInputData({});
          setActiveScenarioData({});
        }
        setActiveStep(0);
        setLoading(false);
      });
    },
    onError() {
      let completedSlide = {};
      for (let c = 0; c <= 18; c += 1) {
        completedSlide[c] = false;
      }
      setCompletedSlides(completedSlide);
      setClearModule(false);
      timeLineActions.assignModulesData('', (data) => {
        if(moduleServices.module1Data.start_year <= 0) {
          const popupdetails = messages('No Fi Module','Family');
          let saveContinueBtn = true;
          setBeforeLeavePopup({...{popupStatus: true,type:'no_pwi_fi',url: '/plan-with-inkwiry/fi',saveContinueBtn,'classVal':'clearConfirmDialog'},...popupdetails})
        }
        if (data !== '') {
          setInputData({});
        }
        setActiveStep(0);
        setActiveScenarioData({});
        setLoading(false);
      });
    },
  });

  // added new below function now
  useQuery(CHECK_MODULE_EXISTS, {
    variables: {
      module: 'module10',
      page: 'pwi'
    },
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (response.checkModuleExists.status === true) {
        if (response.checkModuleExists.message === 'success') {
          setLoading(false);
          const popupdetails = messages('clearData');
          setBeforeLeavePopup({
            ...{
              popupStatus: true,
              type: 'default_clear',
            }, ...popupdetails
          })
        } else {
          getModuleData()
        }
      } else {
        if (response.checkModuleExists.message === 'unauthenticated') {
          history.push('/login')
        } else {
          getModuleData()
        }
      }
    }
  });

  // added new below function now
  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }
  const openBeforeLeavePopup = (url, moduleName, type = 'page') => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages(moduleName);
    let saveContinueBtn = true;
    if (window.location.pathname === url) {
      saveContinueBtn = false;
    }
    setBeforeLeavePopup({ ...{ popupStatus: true, type: type === 'page' ? 'confirm' : 'load', url: moduleName === 'Worksheet' ? '/family' : url, saveContinueBtn }, ...popupdetails })
  }

  // added new below function upto recallDataApi
  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages('clearScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear',
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  }

  useEffect(() => {
    let progressBar = (activeStep + 1) * (100 / 21);
    progressBar = progressBar.toFixed(0);
    if (progressBar > 77) {
      progressBar = 100;
    }
    setProgress(progressBar);
  }, [activeStep]);

  const updateInputValue = (field, value) => {
    let inputDetails = { ...inputData };
    if (field === 'total_obj') {
      inputDetails = value;
    } else {
      inputDetails[field] = value;
    }
    setInputData(inputDetails);
    modulesServices.setModule10Data(inputDetails);
  };

  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleMainMenu = () => {
    setMainMenu(!mainMenu);
  };

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    if (slide === 1) {
      window.scrollTo(0, bodySection.scrollHeight);
      scrollToComponent(bodySection, {
        offset: 20,
        align: 'bottom',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 3 || slide === 4) {
      setMenu(true);
    } else if (slide === 5) {
      setMenu(false);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 6) {
      setMenu(false);
      setMainMenu(true);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else {
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
      setMenu(false);
      setMainMenu(false);
    }
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setBackgroundBlur(true);
  };

  // added new belo function
  const saveScenarioCallBack = () => {
    setMenu(false);
    setMainMenu(false);
    handleHomeSave('save');
  }

  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setBackgroundBlur(false);
    setMenu(false);
    setMainMenu(false);
  };
  useEffect(() => {
    if (backgroundBlur) {
      document.body.classList.add('pwiAnimation');
    } else {
      document.body.classList.remove('pwiAnimation');
    }
  }, [backgroundBlur]);



  const handleNextSlide = (viewNumber, goType) => {

    switch(viewNumber) {
      case 4:
        if(goType === 'skip') {
          handleNextSlide(9,'next');
          return false;
        }
        setActiveStep(viewNumber);
        break;  
      case 5: 
        if(goType === 'back') {
          handleNextSlide(3,'back');
          return false;
        }
        setActiveStep(viewNumber);
        break;
      case 7: 
        if(goType === 'skip') {
          handleNextSlide(9,'next');
          return false;
        }
        setActiveStep(viewNumber);
        break;
      case 8:
        if(goType === 'back') {
          if(inputData.start_year === undefined || inputData.start_year === 0 || inputData.start_year === null  || inputData.end_year === undefined || inputData.end_year === 0 || inputData.end_year === null ) {
            handleNextSlide(3,'back');
            return false;
          }
          if(inputData.second_start_year === undefined || inputData.second_start_year === 0 || inputData.second_start_year === null  || inputData.second_end_year === undefined || inputData.second_end_year === 0 || inputData.second_end_year === null ) {
            handleNextSlide(6,'back');
            return false;
          }
        } 
        setActiveStep(viewNumber);
        break;
      case 9:
        setActiveStep(viewNumber);
        break;
      default:
        setActiveStep(viewNumber);
          break;
    }
    const completedSlide = { ...completedSlides };
    completedSlide[viewNumber] = true;
    setCompletedSlides(completedSlide);
    setMenu(false);
    setMainMenu(false);
    window.scrollTo(0, 0);
  };

  const handleHomeSave = (data) => {
    const objectParams = inputData;
    if (data === 'save') {
      objectParams.start_year = (inputData.start_year !== undefined) ? inputData.start_year : '';
      objectParams.type = 'save';
      objectParams.sketchName = 'Folder';
      objectParams.module = 'module10';
      objectParams.moduleNumber = 'module10Data';
      setmoduleObjectParams(objectParams);
    }
    setInputData(inputData);
    setSaveKey(data);
    setPopupClose(true);
    setMenu(false);
    setMainMenu(false);
  };

  const handlePopUpClose = () => {
    setPopupClose(false);
  };

  const handleDefinationPopup = (def) => {
    setTitleDefination(def);
    setDefinationPopup(true);
  };

  const handleDefinationPopupClose = () => {
    setTitleDefination('');
    setDefinationPopup(false);
  };

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);       
        setTimeout(() => {
          setLoadValue(false);
          setLoading(false);
        }, 1000);
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module10";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module10', 'quick-save', activeScenarioData);
      finalObject['module10Data'] = pwiObject.getfilteredPWIFamily(moduleServices.module10Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }


  let disabledNextBtn = false;
  if(activeStep === 3) {
    if(!(inputData.start_year !== undefined && inputData.start_year > 0 && inputData.end_year !== undefined && inputData.end_year > 0 && inputData.occupation !== undefined && inputData.occupation !== '')) {
      disabledNextBtn = true;
    }
  } else if(activeStep === 6) {
    if(!(inputData.second_start_year !== undefined && inputData.second_start_year > 0 && inputData.second_end_year !== undefined && inputData.second_end_year > 0 && inputData.second_occupation !== undefined && inputData.second_occupation !== '')) {
      disabledNextBtn = true;
    }
  }

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} ref={(body) => { bodySection = body; }}>
      {loading && <Pageloader loading={loading} />}
      <div className={document.body.scrollHeight < 700 ? classes.screenHght : ''}>
        <Sidebar activeScenario={activeScenarioData} sketchName={timeLineActions.careerSketchName !== undefined ? timeLineActions.careerSketchName : ''} saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} menu={menu} handleSideMenu={handleNextSlide} openBeforeLeavePopup={openBeforeLeavePopup} handleMenu={setMenu} completedSlides={completedSlides} />
        {activeWalkThruSlide === 4 && (<WalkThruFour handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 5 && (<WalkThruFive handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={mainMenu ? classes.fixedMenuDisplayMain : classes.fixedMenuDisplayMainNone}>
          <PwiLeftMenu saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} openBeforeLeavePopup={openBeforeLeavePopup} setMainMenu={setMainMenu} />
        </div>
        {activeWalkThruSlide === 7 && (<WalkThruSeven handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.fiHeader}>
          <div className={classes.container}>
            <div className={classes.fiHeaderInner}>
              <span className={activeWalkThruSlide === 6 ? classes.pwiActiveModule : ''}>
                <img src={headerFI} alt="" aria-hidden="true" onClick={handleMainMenu} />
                <h3>Family</h3>
              </span>
              <Link href="/" onClick={(event) => { event.preventDefault(); openBeforeLeavePopup('/', 'Home'); }} className={classes.logoLink}>
                <img src={LogoImage} alt="" />
              </Link>
              {activeWalkThruSlide === 6 && (<WalkThruSix handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
          </div>
        </div>
        <div className={classes.fiContent}>
          <div className={classes.stepOne}>
            <div className={classes.fiContentHeader}>
              <div className={activeWalkThruSlide === 3 ? classes.pwiWalkTitle : ''}>
                <div aria-hidden="true" className={classes.menuIcon} onClick={handleMenu}>
                  <span />
                  <span />
                  <span />
                </div>
                {activeWalkThruSlide === 3 && (<WalkThruThree handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                <h3>{titleText[activeStep]}</h3>
              </div>
              <figure className={backgroundBlur && (activeWalkThruSlide === 1 || activeWalkThruSlide === 8) ? classes.pwiActiveBlock : ''}>
                <img src={Walk} alt="" data-for="walk" data-tip="Click here for a Walk-Thru of the Plan With Inkwiry interface." aria-hidden="true" onClick={() => { handleWalkThrus(0); }} />
              </figure>
              {activeWalkThruSlide === 1 && (<WalkThruOne handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              {activeWalkThruSlide === 8 && (<WalkThruEight handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
            <div className={classes.ProgressBar}>
              <div className={classes.ProgressBarInner} style={{ width: `${progress}%` }} />
            </div>
            {(activeStep === 0) && (
              <Introduction />
            )}

            {(activeStep === 1) && (
              <MarriageYear
                inputData={inputData}
                updateData={updateInputValue}
              />
            )}
            
            {(activeStep === 2) && (
              <FederalIncomeTax handleDefinationPopup={handleDefinationPopup} />
            )}
            
            {(activeStep === 3) && (
              <SpouseCareerSecond
                handleDefinationPopup={handleDefinationPopup}
                inputData={inputData}
                updateData={updateInputValue}
              />
            )}
            {(activeStep === 4) && (
              <SpouseCareerFirst
                inputData={inputData}
                updateData={updateInputValue}
                handleDefinationPopup={handleDefinationPopup}
              />

            )}
            {(activeStep === 5) && (
              <IncomeAfterTaxesFirst inputData={inputData} />
            )}

            {(activeStep === 6) && (
              <SpouseCareerAdvancementFirst
                inputData={inputData}
                updateData={updateInputValue}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 7) && (
              <SpouseCareerAdvancementSecond
                inputData={inputData}
                updateData={updateInputValue}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 8) && (
              <IncomeAfterTaxesSecond inputData={inputData} />
            )}
            
            {(activeStep === 9) && (
              <UpdatingCombinedBudgetFirst
                inputData={inputData}
                updateData={updateInputValue}
              />
            )}
            {(activeStep === 10) && (
              <LivingExpensesAdjustment />
            )}
            {(activeStep === 11) && (
              <UpdatingCombinedBudgetSecond
                inputData={inputData}
                updateData={updateInputValue}
              />
            )} 
            {(activeStep === 12) && (
              <UpdatingCombinedBudgetThird
                inputData={inputData}
                updateData={updateInputValue}
              />
            )}
            {(activeStep === 13) && (
              <CombinedBudgetSummary />
            )}
            {(activeStep === 14) && (
              <SpouseStudentLoanDebt
                inputData={inputData}
                updateData={updateInputValue}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 15) && (
              <StartingFamily
                inputData={inputData}
                updateData={updateInputValue}
              />
            )}
            {(activeStep === 16) && (
              <AverageCostRaisingChild
                inputData={inputData}
                updateData={updateInputValue}
              />
            )}
            {(activeStep === 17) && (
              <AddPetFamily
                inputData={inputData}
                updateData={updateInputValue}
              />
            )}
            {(activeStep === 18) && (
              <FamilySummery />
            )}

          </div>
          {((activeStep >= 11 && activeStep <= 13) || activeStep >= 16) && (
            <React.Fragment>
              {activeStep !== 13 && activeStep !== 18 && (
                <div className={ classes.borderDivider } />
              )}
              <MyCombinedBudget activeStep={activeStep} />
            </React.Fragment>
          )}
          

          <div className={classes.btnGroup}>
            {activeWalkThruSlide === 2 && (<WalkThruTwo handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} moduleName="family" />)}
            {activeStep === 0 ? '' : <Button className={classes.cancel} onClick={() => handleNextSlide(activeStep-1, 'back')}>Back</Button>}
            <Button className={classes.clearBtn} onClick={clearEntireScenario} aria-hidden="true" data-for="walk" data-tip="Clear your scenario and start fresh."><img src={ClearTime} alt="" /></Button>
            <div>
              {activeScenarioData.id !== undefined && activeScenarioData.id !== '' && (
                <Button onClick={handleQuickSave} className={classes.quickSave}><span><img src={save} alt="" data-for="walk" data-tip="Quick save your changes. To save a new scenario, use the menu in the top left of the page." /></span></Button>
              )}
              {(activeStep === 3 || activeStep===6) && <Button className={classes.skipBtn} onClick={() => handleNextSlide(activeStep+1, 'skip')}>Skip</Button>}

              {activeStep === 18 ? (
                <Button
                  className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton)
                    : classes.next}
                  onClick={() => handleHomeSave('save')}
                >
                  Save
                </Button>
              )
                : <Button className={classNames(classes.next, activeWalkThruSlide === 2 && classes.pwiWalkThruButton, disabledNextBtn && classes.nextDisable )} disabled={disabledNextBtn} onClick={() => handleNextSlide(activeStep+1, 'next')}>Next</Button>}
            </div>
          </div>
          {(activeStep === 4 || activeStep === 7) && (
            <div className={classes.bottomText}>
              <p>
                <i>
                  Use  &nbsp;
                <a className={classes.navigatorHover} target="_blank" rel="college_navigator noopener noreferrer" href="https://www.payscale.com/research/US/Country=United_States/Salary">PayScale</a>
                  &nbsp; to search careers and discover what bonus, tips and commission you might expect
              </i>
              </p>
              <p>Source: U.S. Bureau of Labor Statistics (BLS)</p>
              <p>Notes: Latest data published by BLS is as of May 2018</p>
            </div>
          )}
          {(activeStep === 16 || activeStep === 17) && (
            <div className={classes.bottomText}>
              <p>
                Sources:
              <a className={classes.navigatorHover} target="_blank" rel="college_navigator noopener noreferrer" href="https://fns-prod.azureedge.net/sites/default/files/CRC_Infographic-2015.2_0.pdf">USDA</a>
              </p>
            </div>
          )}
        </div>
        {definationResult && definationResult.getPWiDefination
          && (
            <DefinationPopup
              definationPopup={definationPopup}
              handleDefinationPopupClose={handleDefinationPopupClose}
              getPWiDefination={definationResult.getPWiDefination}
            />
          )}
        <ReactTooltip id="walk" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
        <div className={classes.videoModalPopup}>
          <Dialog
            open={popupClose}
            onClose={handlePopUpClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={saveKey === 'save' ? classes.SavePopup : classes.pendinglistPopup}
          >
            <DialogContent className={saveKey === 'clear' ? classes.clearModule : ''}>
              {saveKey === 'save' && (
                <div>
                  <div className={classes.popupClose}>
                    <span aria-hidden="true" onClick={handlePopUpClose}>
                      ×
                  </span>
                  </div>
                  <PwiSave setPopupOpen={setPopupClose} moduleParams={moduleObjectParams} getSaveData={inputData} setLoadValue={setLoadValue}/>
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} saveScenarioCallBack={saveScenarioCallBack} setLoading={setLoading} history={history} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel otherData={{ 'module': 'module10' }} setOpenPopup={setOpenPopup} popupStatus={openPopup} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
   
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
          <SavePopupSucess setLoadValue={setLoadValue}/>
           </DialogContent>
        </Dialog>
      </div>
   </Typography>
  );
};

Family.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(Family);
