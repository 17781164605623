import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import * as moduleServices from '../../calculations/modules-services';
import styles from '../../screens/pwi-existing/styles';

const ExistingStudLoansRepayment = ( props ) => {
  const { classes } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>As a benchmark, your total student loan payments in one full year should not be more than 12.5% of your total gross income.</p>
      <p>See below for how much income this rule would suggest you need to make in your career to support your existing student loan payments.</p>
      <div className={ classes.studentAnalytics }>
        <table>
          <thead>
            <tr>
              <th colSpan="2">Income Needed to Support Existing Student Loans Analysis</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total Existing Student Loan Balance</td>
              <td>
                {moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance } prefix={ moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance < 0 && ')' } /> ) : ( '$0' )}
              </td>
            </tr>
            <tr>
              <td>Total Monthly Payment</td>
              <td>
                {moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment } prefix={ moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment < 0 && ')' } /> ) : ( '$0' )}
              </td>
            </tr>
            <tr>
              <td>Total Annual Paid</td>
              <td>
                {moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment * 12 } prefix={ moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment < 0 && ')' } /> ) : ( '$0' )}
              </td>
            </tr>
            <tr className={ classes.leftPadding }>
              <td><i>Divided by 12.50%</i></td>
              <td><i>12.50%</i></td>
            </tr>
          </tbody>
          <tfoot>
            <tr className={ classes.blueTopBorder }>
              <th>Income Needed to Support Existing Student Loans</th>
              <th>
                {moduleServices.module18Data.incomeNeededExistingStudentLoans !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.incomeNeededExistingStudentLoans } prefix={ moduleServices.module18Data.incomeNeededExistingStudentLoans >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.incomeNeededExistingStudentLoans < 0 && ')' } /> ) : ( '$0' )}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

ExistingStudLoansRepayment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( ExistingStudLoansRepayment );
