function simPanelCalcs(module1Data, module2Data, module4Data, module5Data, module6Data, module8Data, module10Data, module18Data, module12Data, module17Data, assumptionsData, fedSubHigherEdLoanData, fedUnSubHigherEdLoanData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData, module15Data, fedSubNewHigherEdLoanData, fedUnSubNewHigherEdLoanData, incomeStatementData, existingHomeData, existingStudentLoanBackendData, depreciationSheduleData, higherEdOutputData, moreHigherEdOutputData, newHigherEdOutputData, careerOutputBackendData, careerAdvOutputBackendData, addnCareerAdvOutputBackendData, furtherCareerAdvOutputBackendData, cashFlowData, familyOutputBackendData, carLoan1Data, carLoan2Data, buyingAHomeData, invPropertyRoiData) {
	let simData = {};
	//Financial Independence Cash flow
	if (typeof module1Data != 'undefined' && typeof module1Data.current_cash_balance != 'undefined') {
		simData['Financial_Independence'] = module1Data.current_cash_balance;
	} else {
		simData['Financial_Independence'] = 0;
	}

	//Existing Assets & Liabilities Cash flow
	let startYear = parseInt(module1Data.start_year);
	let endYear = parseInt(module1Data.start_year) + 14;
	let existingHomeFinal = existingHomeData['Data1'];
	let mortgagePaymentValue = 0;
	let propertyTaxesValue = 0;
	let homeOwnersInsuranceValue = 0;
	let privateMortgageInsuranceValue = 0;
	let maintenanceValue = 0;
	let assiciationFeesValue = 0;
	let utilitiesValue = 0;
	let saleValue = 0;
	let existingHomeTotal = 0;
	let existingStudentLoanBackendData1Total = 0;
	let existingStudentLoanBackendData2Total = 0;
	let existingStudentLoanBackendData3Total = 0;
	let existingStudentLoanBackendData4Total = 0;
	let existingStudentLoanBackendData5Total = 0;
    
	if (Object.keys(existingHomeFinal).length !== 0) {
      Object.keys(existingHomeFinal).forEach((year) => {
          	mortgagePaymentValue += existingHomeFinal[year]['Mortgage Payment'];
			propertyTaxesValue += existingHomeFinal[year]['Property Taxes'];
			homeOwnersInsuranceValue += existingHomeFinal[year]['Houseowners Insurance'];
			privateMortgageInsuranceValue += existingHomeFinal[year]['Private Mortgage Insurance'];
			maintenanceValue += existingHomeFinal[year]['Maintenance'];
			assiciationFeesValue += existingHomeFinal[year]['Association Fees'];
			utilitiesValue += existingHomeFinal[year]['Utilities'];
			saleValue += existingHomeFinal[year]['Sale Value'];
      });
    }
	existingHomeTotal = mortgagePaymentValue + propertyTaxesValue + homeOwnersInsuranceValue + privateMortgageInsuranceValue + maintenanceValue + assiciationFeesValue + utilitiesValue + saleValue;

	if (typeof existingStudentLoanBackendData != 'undefined' && existingStudentLoanBackendData != '' && typeof existingStudentLoanBackendData['Federal Subsidized Graph'] != 'undefined' && existingStudentLoanBackendData['Federal Subsidized Graph'] != '' && typeof existingStudentLoanBackendData['Federal Subsidized Graph']['Payment'] != 'undefined') {
		let existingStudentLoanBackendData1 = existingStudentLoanBackendData['Federal Subsidized Graph']['Payment'];
		for (let i in existingStudentLoanBackendData1) {
			existingStudentLoanBackendData1Total += existingStudentLoanBackendData1[i];
		}
	}


	if (typeof existingStudentLoanBackendData != 'undefined' && existingStudentLoanBackendData != ''
		&& typeof existingStudentLoanBackendData['Federal Unsubsidized Graph'] != 'undefined' && existingStudentLoanBackendData['Federal Unsubsidized Graph'] != ''
		&& typeof existingStudentLoanBackendData['Federal Unsubsidized Graph']['Payment'] != 'undefined') {
		let existingStudentLoanBackendData2 = existingStudentLoanBackendData['Federal Unsubsidized Graph']['Payment'];
		for (let i in existingStudentLoanBackendData2) {
			existingStudentLoanBackendData2Total += existingStudentLoanBackendData2[i];
		}
	}

	if (typeof existingStudentLoanBackendData != 'undefined' && existingStudentLoanBackendData != ''
		&& typeof existingStudentLoanBackendData['Private Graph'] != 'undefined' && existingStudentLoanBackendData['Private Graph'] != ''
		&& typeof existingStudentLoanBackendData['Private Graph']['Payment'] != 'undefined') {
		let existingStudentLoanBackendData3 = existingStudentLoanBackendData['Private Graph']['Payment'];
		for (let i in existingStudentLoanBackendData3) {
			existingStudentLoanBackendData3Total += existingStudentLoanBackendData3[i];
		}
	}

	if (typeof existingStudentLoanBackendData != 'undefined' && existingStudentLoanBackendData != ''
		&& typeof existingStudentLoanBackendData['Existing Car'] != 'undefined' && existingStudentLoanBackendData['Existing Car'] != ''
		&& typeof existingStudentLoanBackendData['Existing Car']['Payment'] != 'undefined') {
		let existingStudentLoanBackendData4 = existingStudentLoanBackendData['Existing Car']['Payment'];
		for (let i in existingStudentLoanBackendData4) {
			existingStudentLoanBackendData4Total += existingStudentLoanBackendData4[i];
		}
	}

	if (typeof existingStudentLoanBackendData != 'undefined' && existingStudentLoanBackendData != ''
		&& typeof existingStudentLoanBackendData['Credit Card Debt'] != 'undefined' && existingStudentLoanBackendData['Credit Card Debt'] != ''
		&& typeof existingStudentLoanBackendData['Credit Card Debt']['Payment'] != 'undefined') {
		let existingStudentLoanBackendData5 = existingStudentLoanBackendData['Credit Card Debt']['Payment'];
		for (let i in existingStudentLoanBackendData5) {
			existingStudentLoanBackendData5Total += existingStudentLoanBackendData5[i];
		}
	}

	//Cars Data
	let insuranceValue = 0;
	let gasValue = 0;
	let carTotalValue = 0;
	
	if (Object.keys(depreciationSheduleData).length !== 0) {
      Object.keys(depreciationSheduleData).forEach((year) => {
        if (year >= startYear && year <= endYear) {
          if (typeof depreciationSheduleData != 'undefined' && depreciationSheduleData != '' && typeof depreciationSheduleData[year] != 'undefined' && depreciationSheduleData[year] != '') {
				maintenanceValue += depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second']['Maintenance'];
				insuranceValue += depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second']['Insurance'];
				gasValue += depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second']['Gas'];
			}
        }
      });
    }
	
	/*depreciationSheduleData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof depreciationSheduleData != 'undefined' && depreciationSheduleData != '' && typeof depreciationSheduleData[year] != 'undefined' && depreciationSheduleData[year] != '') {
				maintenanceValue += depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second']['Maintenance'];
				insuranceValue += depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second']['Insurance'];
				gasValue += depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second']['Gas'];
			}
		}
	  } );*/
	carTotalValue = maintenanceValue + insuranceValue + gasValue;

	simData['existing_assets_liabilities'] = -(existingHomeTotal + existingStudentLoanBackendData1Total + existingStudentLoanBackendData2Total + existingStudentLoanBackendData3Total + existingStudentLoanBackendData4Total + existingStudentLoanBackendData5Total + carTotalValue);

	//Higher Education 
	let higherEdOutputBackendData1Total = 0;
	let higherEdOutputBackendData2Total = 0;
	let higherEdOutputBackendData3Total = 0;
	let higherEdOutputBackendData4Total = 0;
	let higherEdOutputBackendData5Total = 0;
	let firstHalfValue = 0;


	if (typeof higherEdOutputData != 'undefined' && higherEdOutputData != '' &&
		typeof higherEdOutputData['Federal Subsidized Graph'] != 'undefined' && higherEdOutputData['Federal Subsidized Graph'] != '' &&
		typeof higherEdOutputData['Federal Subsidized Graph']['Payment'] != 'undefined' && higherEdOutputData['Federal Subsidized Graph']['Payment'] != '') {
		let higherEdOutputBackendData1 = higherEdOutputData['Federal Subsidized Graph']['Payment'];
		for (let i in higherEdOutputBackendData1) {
			higherEdOutputBackendData1Total += higherEdOutputBackendData1[i];
		}
	}

	if (typeof higherEdOutputData != 'undefined' && higherEdOutputData != '' &&
		typeof higherEdOutputData['Federal Unsubsidized Graph'] != 'undefined' && higherEdOutputData['Federal Unsubsidized Graph'] != '' &&
		typeof higherEdOutputData['Federal Unsubsidized Graph']['Payment'] != 'undefined' && higherEdOutputData['Federal Unsubsidized Graph']['Payment'] != '') {
		let higherEdOutputBackendData2 = higherEdOutputData['Federal Unsubsidized Graph']['Payment'];
		for (let i in higherEdOutputBackendData2) {
			higherEdOutputBackendData2Total += higherEdOutputBackendData2[i];
		}
	}

	if (typeof higherEdOutputData != 'undefined' && higherEdOutputData != '' &&
		typeof higherEdOutputData['Private Graph'] != 'undefined' && higherEdOutputData['Private Graph'] != '' &&
		typeof higherEdOutputData['Private Graph']['Payment'] != 'undefined' && higherEdOutputData['Private Graph']['Payment'] != '') {
		let higherEdOutputBackendData3 = higherEdOutputData['Private Graph']['Payment'];
		for (let i in higherEdOutputBackendData3) {
			higherEdOutputBackendData3Total += higherEdOutputBackendData3[i];
		}
	}


	if (typeof higherEdOutputData != 'undefined' && higherEdOutputData != '' && typeof higherEdOutputData['Bar Graph2'] != 'undefined' && higherEdOutputData['Bar Graph2'] != '' &&
		typeof higherEdOutputData['Bar Graph2']['Tuition Cost'] != 'undefined' && higherEdOutputData['Bar Graph2']['Tuition Cost'] != '') {
		let higherEdOutputBackendData5 = higherEdOutputData['Bar Graph2']['Tuition Cost'];
		for (let i in higherEdOutputBackendData5) {
			higherEdOutputBackendData5Total += higherEdOutputBackendData5[i];
		}
	}



	if (typeof higherEdOutputData['Pie Chart'] != 'undefined' && higherEdOutputData['Pie Chart'] != '') {
		higherEdOutputBackendData4Total = higherEdOutputData['Pie Chart']['totalCollegeValue'] +
			higherEdOutputData['Pie Chart']['totalRentValue'] +
			higherEdOutputData['Pie Chart']['totalUtilitiesValue'] +
			higherEdOutputData['Pie Chart']['totalFoodValue'] +
			higherEdOutputData['Pie Chart']['totalClothingValue'] +
			higherEdOutputData['Pie Chart']['totalEntertainmentValue'] +
			higherEdOutputData['Pie Chart']['totalTechnologyValue'] +
			higherEdOutputData['Pie Chart']['totalTransportationValue'] +
			higherEdOutputData['Pie Chart']['totalMiscellaneousValue'] +
			higherEdOutputData['Pie Chart']['totalHealthInsuranceValue'];
	}






	firstHalfValue = higherEdOutputBackendData1Total + higherEdOutputBackendData2Total + higherEdOutputBackendData3Total + higherEdOutputBackendData4Total + higherEdOutputBackendData5Total;

	//Second Half
	let higherEdOutputBackendData6Total = 0;
	let higherEdOutputBackendData7Total = 0;
	let higherEdOutputBackendData8Total = 0;
	let higherEdOutputBackendData9Total = 0;
	let higherEdOutputBackendData10Total = 0;
	let higherEdOutputBackendData11Total = 0;
	let higherEdOutputBackendData12Total = 0;
	let higherEdOutputBackendData13Total = 0;
	let secondHalfValue = 0;

	if (typeof higherEdOutputData != 'undefined' && higherEdOutputData != '' && typeof higherEdOutputData['Bar Graph2'] != 'undefined' && higherEdOutputData['Bar Graph2'] != '') {
		let higherEdOutputBackendData6 = higherEdOutputData['Bar Graph2']['Scholarships'];
		let higherEdOutputBackendData7 = higherEdOutputData['Bar Graph2']['Grants'];
		let higherEdOutputBackendData8 = higherEdOutputData['Bar Graph2']['Financial Aid'];
		let higherEdOutputBackendData9 = higherEdOutputData['Bar Graph2']['Income Earned During'];
		let higherEdOutputBackendData10 = higherEdOutputData['Bar Graph2']['Family Contribution'];
		let higherEdOutputBackendData11 = higherEdOutputData['Bar Graph2']['Federal Subsidized Student Loan'];
		let higherEdOutputBackendData12 = higherEdOutputData['Bar Graph2']['Federal Unsubsidized Student Loan'];
		let higherEdOutputBackendData13 = higherEdOutputData['Bar Graph2']['Private Student Loan'];

		for (let i in higherEdOutputBackendData6) {
			higherEdOutputBackendData6Total += higherEdOutputBackendData6[i];
		}
		for (let i in higherEdOutputBackendData7) {
			higherEdOutputBackendData7Total += higherEdOutputBackendData7[i];
		}
		for (let i in higherEdOutputBackendData8) {
			higherEdOutputBackendData8Total += higherEdOutputBackendData8[i];
		}
		for (let i in higherEdOutputBackendData9) {
			higherEdOutputBackendData9Total += higherEdOutputBackendData9[i];
		}
		for (let i in higherEdOutputBackendData10) {
			higherEdOutputBackendData10Total += higherEdOutputBackendData10[i];
		}
		for (let i in higherEdOutputBackendData11) {
			higherEdOutputBackendData11Total += higherEdOutputBackendData11[i];
		}
		for (let i in higherEdOutputBackendData12) {
			higherEdOutputBackendData12Total += higherEdOutputBackendData12[i];
		}
		for (let i in higherEdOutputBackendData13) {
			higherEdOutputBackendData13Total += higherEdOutputBackendData13[i];
		}
	}

	secondHalfValue = higherEdOutputBackendData6Total + higherEdOutputBackendData7Total + higherEdOutputBackendData8Total + higherEdOutputBackendData9Total + higherEdOutputBackendData10Total + higherEdOutputBackendData11Total + higherEdOutputBackendData12Total + higherEdOutputBackendData13Total;

	simData['higher_education'] = -firstHalfValue + secondHalfValue;

	//More Higher Education moreHigherEdOutputData
	let morehigherEdOutputBackendData1Total = 0;
	let morehigherEdOutputBackendData2Total = 0;
	let morehigherEdOutputBackendData3Total = 0;
	let morehigherEdOutputBackendData4Total = 0;
	let morehigherEdOutputBackendData5Total = 0;
	let morefirstHalfValue = 0;

	if (typeof moreHigherEdOutputData != 'undefined' && moreHigherEdOutputData != '' && typeof moreHigherEdOutputData['Federal Subsidized Graph'] != 'undefined' &&
		moreHigherEdOutputData['Federal Subsidized Graph'] != '' && typeof moreHigherEdOutputData['Federal Subsidized Graph']['Payment'] != 'undefined' && moreHigherEdOutputData['Federal Subsidized Graph']['Payment'] != '') {
		let morehigherEdOutputBackendData1 = moreHigherEdOutputData['Federal Subsidized Graph']['Payment'];
		for (let i in morehigherEdOutputBackendData1) {
			morehigherEdOutputBackendData1Total += morehigherEdOutputBackendData1[i];
		}
	}

	if (typeof moreHigherEdOutputData != 'undefined' && moreHigherEdOutputData != '' && typeof moreHigherEdOutputData['Federal Unsubsidized Graph'] != 'undefined' &&
		moreHigherEdOutputData['Federal Unsubsidized Graph'] != '' && typeof moreHigherEdOutputData['Federal Unsubsidized Graph']['Payment'] != 'undefined' && moreHigherEdOutputData['Federal Subsidized Graph']['Payment'] != '') {
		let morehigherEdOutputBackendData2 = moreHigherEdOutputData['Federal Unsubsidized Graph']['Payment'];
		for (let i in morehigherEdOutputBackendData2) {
			morehigherEdOutputBackendData2Total += morehigherEdOutputBackendData2[i];
		}
	}

	if (typeof moreHigherEdOutputData != 'undefined' && moreHigherEdOutputData != '' && typeof moreHigherEdOutputData['Private Graph'] != 'undefined' &&
		moreHigherEdOutputData['Private Graph'] != '' && typeof moreHigherEdOutputData['Private Graph']['Payment'] != 'undefined' && moreHigherEdOutputData['Federal Subsidized Graph']['Payment'] != '') {
		let morehigherEdOutputBackendData3 = moreHigherEdOutputData['Private Graph']['Payment'];
		for (let i in morehigherEdOutputBackendData3) {
			morehigherEdOutputBackendData3Total += morehigherEdOutputBackendData3[i];
		}
	}

	if (typeof moreHigherEdOutputData != 'undefined' && moreHigherEdOutputData != '' &&
		typeof moreHigherEdOutputData['Bar Graph2'] != 'undefined' && moreHigherEdOutputData['Bar Graph2'] != '' &&
		typeof moreHigherEdOutputData['Bar Graph2']['Tuition Cost'] != 'undefined' && moreHigherEdOutputData['Bar Graph2']['Tuition Cost'] != '') {
		let morehigherEdOutputBackendData5 = moreHigherEdOutputData['Bar Graph2']['Tuition Cost'];
		for (let i in morehigherEdOutputBackendData5) {
			morehigherEdOutputBackendData5Total += morehigherEdOutputBackendData5[i];
		}
	}

	if (typeof moreHigherEdOutputData != 'undefined' && typeof moreHigherEdOutputData['Pie Chart'] != 'undefined' && moreHigherEdOutputData['Pie Chart'] != '') {
		morehigherEdOutputBackendData4Total = moreHigherEdOutputData['Pie Chart']['totalCollegeValue'] +
			moreHigherEdOutputData['Pie Chart']['totalRentValue'] +
			moreHigherEdOutputData['Pie Chart']['totalUtilitiesValue'] +
			moreHigherEdOutputData['Pie Chart']['totalFoodValue'] +
			moreHigherEdOutputData['Pie Chart']['totalClothingValue'] +
			moreHigherEdOutputData['Pie Chart']['totalEntertainmentValue'] +
			moreHigherEdOutputData['Pie Chart']['totalTechnologyValue'] +
			moreHigherEdOutputData['Pie Chart']['totalTransportationValue'] +
			moreHigherEdOutputData['Pie Chart']['totalMiscellaneousValue'] +
			moreHigherEdOutputData['Pie Chart']['totalHealthInsuranceValue'];
	}


	morefirstHalfValue = morehigherEdOutputBackendData1Total + morehigherEdOutputBackendData2Total + morehigherEdOutputBackendData3Total + morehigherEdOutputBackendData4Total + morehigherEdOutputBackendData5Total;

	//Second Half values
	let morehigherEdOutputBackendData6Total = 0;
	let morehigherEdOutputBackendData7Total = 0;
	let morehigherEdOutputBackendData8Total = 0;
	let morehigherEdOutputBackendData9Total = 0;
	let morehigherEdOutputBackendData10Total = 0;
	let morehigherEdOutputBackendData11Total = 0;
	let morehigherEdOutputBackendData12Total = 0;
	let morehigherEdOutputBackendData13Total = 0;
	let moresecondHalfValue = 0;

	if (typeof moreHigherEdOutputData != 'undefined' && moreHigherEdOutputData != '' && typeof moreHigherEdOutputData['Bar Graph2'] != 'undefined' && moreHigherEdOutputData['Bar Graph2'] != '') {
		let morehigherEdOutputBackendData6 = moreHigherEdOutputData['Bar Graph2']['Scholarships'];
		let morehigherEdOutputBackendData7 = moreHigherEdOutputData['Bar Graph2']['Grants'];
		let morehigherEdOutputBackendData8 = moreHigherEdOutputData['Bar Graph2']['Financial Aid'];
		let morehigherEdOutputBackendData9 = moreHigherEdOutputData['Bar Graph2']['Income Earned During'];
		let morehigherEdOutputBackendData10 = moreHigherEdOutputData['Bar Graph2']['Family Contribution'];
		let morehigherEdOutputBackendData11 = moreHigherEdOutputData['Bar Graph2']['Federal Subsidized Student Loan'];
		let morehigherEdOutputBackendData12 = moreHigherEdOutputData['Bar Graph2']['Federal Unsubsidized Student Loan'];
		let morehigherEdOutputBackendData13 = moreHigherEdOutputData['Bar Graph2']['Private Student Loan'];

		for (let i in morehigherEdOutputBackendData6) {
			morehigherEdOutputBackendData6Total += morehigherEdOutputBackendData6[i];
		}
		for (let i in morehigherEdOutputBackendData7) {
			morehigherEdOutputBackendData7Total += morehigherEdOutputBackendData7[i];
		}
		for (let i in morehigherEdOutputBackendData8) {
			morehigherEdOutputBackendData8Total += morehigherEdOutputBackendData8[i];
		}
		for (let i in morehigherEdOutputBackendData9) {
			morehigherEdOutputBackendData9Total += morehigherEdOutputBackendData9[i];
		}
		for (let i in morehigherEdOutputBackendData10) {
			morehigherEdOutputBackendData10Total += morehigherEdOutputBackendData10[i];
		}
		for (let i in morehigherEdOutputBackendData11) {
			morehigherEdOutputBackendData11Total += morehigherEdOutputBackendData11[i];
		}
		for (let i in morehigherEdOutputBackendData12) {
			morehigherEdOutputBackendData12Total += morehigherEdOutputBackendData12[i];
		}
		for (let i in morehigherEdOutputBackendData13) {
			morehigherEdOutputBackendData13Total += morehigherEdOutputBackendData13[i];
		}
	}

	moresecondHalfValue = morehigherEdOutputBackendData6Total + morehigherEdOutputBackendData7Total + morehigherEdOutputBackendData8Total + morehigherEdOutputBackendData9Total + morehigherEdOutputBackendData10Total + morehigherEdOutputBackendData11Total + morehigherEdOutputBackendData12Total + morehigherEdOutputBackendData13Total;
	simData['more_higher_education'] = -morefirstHalfValue + moresecondHalfValue;

	//Further Higher Education Values Cash Flow
	let furhigherEdOutputBackendData1Total = 0;
	let furhigherEdOutputBackendData2Total = 0;
	let furhigherEdOutputBackendData3Total = 0;
	let furhigherEdOutputBackendData4Total = 0;
	let furhigherEdOutputBackendData5Total = 0;
	let furfirstHalfValue = 0;


	if (typeof newHigherEdOutputData != 'undefined' && newHigherEdOutputData != '' &&
		typeof newHigherEdOutputData['Federal Subsidized Graph'] != 'undefined' && newHigherEdOutputData['Federal Subsidized Graph'] != '' &&
		typeof newHigherEdOutputData['Federal Subsidized Graph']['Payment'] != 'undefined' && newHigherEdOutputData['Federal Subsidized Graph']['Payment'] != '') {
		let furhigherEdOutputBackendData1 = newHigherEdOutputData['Federal Subsidized Graph']['Payment'];
		for (let i in furhigherEdOutputBackendData1) {
			furhigherEdOutputBackendData1Total += furhigherEdOutputBackendData1[i];
		}
	}

	if (typeof newHigherEdOutputData != 'undefined' && newHigherEdOutputData != '' &&
		typeof newHigherEdOutputData['Federal Unsubsidized Graph'] != 'undefined' && newHigherEdOutputData['Federal Unsubsidized Graph'] != '' &&
		typeof newHigherEdOutputData['Federal Unsubsidized Graph']['Payment'] != 'undefined' && newHigherEdOutputData['Federal Unsubsidized Graph']['Payment'] != '') {
		let furhigherEdOutputBackendData2 = newHigherEdOutputData['Federal Unsubsidized Graph']['Payment'];
		for (let i in furhigherEdOutputBackendData2) {
			furhigherEdOutputBackendData2Total += furhigherEdOutputBackendData2[i];
		}
	}

	if (typeof newHigherEdOutputData != 'undefined' && newHigherEdOutputData != '' &&
		typeof newHigherEdOutputData['Private Graph'] != 'undefined' && newHigherEdOutputData['Private Graph'] != '' &&
		typeof newHigherEdOutputData['Private Graph']['Payment'] != 'undefined' && newHigherEdOutputData['Private Graph']['Payment'] != '') {
		let furhigherEdOutputBackendData3 = newHigherEdOutputData['Private Graph']['Payment'];
		for (let i in furhigherEdOutputBackendData3) {
			furhigherEdOutputBackendData3Total += furhigherEdOutputBackendData3[i];
		}
	}

	if (typeof newHigherEdOutputData != 'undefined' && newHigherEdOutputData != '' &&
		typeof newHigherEdOutputData['Bar Graph2'] != 'undefined' && newHigherEdOutputData['Bar Graph2'] != '' &&
		typeof newHigherEdOutputData['Bar Graph2']['Tuition Cost'] != 'undefined' && newHigherEdOutputData['Bar Graph2']['Tuition Cost'] != '') {
		let furhigherEdOutputBackendData5 = newHigherEdOutputData['Bar Graph2']['Tuition Cost'];
		for (let i in furhigherEdOutputBackendData5) {
			furhigherEdOutputBackendData5Total += furhigherEdOutputBackendData5[i];
		}
	}


	if (typeof newHigherEdOutputData != 'undefined' && newHigherEdOutputData != '' && typeof newHigherEdOutputData['Pie Chart'] != 'undefined' && newHigherEdOutputData['Pie Chart'] != '') {
		furhigherEdOutputBackendData4Total = newHigherEdOutputData['Pie Chart']['totalCollegeValue'] +
			newHigherEdOutputData['Pie Chart']['totalRentValue'] +
			newHigherEdOutputData['Pie Chart']['totalUtilitiesValue'] +
			newHigherEdOutputData['Pie Chart']['totalFoodValue'] +
			newHigherEdOutputData['Pie Chart']['totalClothingValue'] +
			newHigherEdOutputData['Pie Chart']['totalEntertainmentValue'] +
			newHigherEdOutputData['Pie Chart']['totalTechnologyValue'] +
			newHigherEdOutputData['Pie Chart']['totalTransportationValue'] +
			newHigherEdOutputData['Pie Chart']['totalMiscellaneousValue'] +
			newHigherEdOutputData['Pie Chart']['totalHealthInsuranceValue'];
	}



	furfirstHalfValue = furhigherEdOutputBackendData1Total + furhigherEdOutputBackendData2Total + furhigherEdOutputBackendData3Total + furhigherEdOutputBackendData4Total + furhigherEdOutputBackendData5Total;

	//Second Half values
	let furhigherEdOutputBackendData6Total = 0;
	let furhigherEdOutputBackendData7Total = 0;
	let furhigherEdOutputBackendData8Total = 0;
	let furhigherEdOutputBackendData9Total = 0;
	let furhigherEdOutputBackendData10Total = 0;
	let furhigherEdOutputBackendData11Total = 0;
	let furhigherEdOutputBackendData12Total = 0;
	let furhigherEdOutputBackendData13Total = 0;
	let fursecondHalfValue = 0;
	
	
	if (typeof newHigherEdOutputData != 'undefined' && newHigherEdOutputData != '' && typeof newHigherEdOutputData['Bar Graph2'] != 'undefined' && newHigherEdOutputData['Bar Graph2'] != '') {
		let furhigherEdOutputBackendData6 = newHigherEdOutputData['Bar Graph2']['Scholarships'];
		let furhigherEdOutputBackendData7 = newHigherEdOutputData['Bar Graph2']['Grants'];
		let furhigherEdOutputBackendData8 = newHigherEdOutputData['Bar Graph2']['Financial Aid'];
		let furhigherEdOutputBackendData9 = newHigherEdOutputData['Bar Graph2']['Income Earned During'];
		let furhigherEdOutputBackendData10 = newHigherEdOutputData['Bar Graph2']['Family Contribution'];
		let furhigherEdOutputBackendData11 = newHigherEdOutputData['Bar Graph2']['Federal Subsidized Student Loan'];
		let furhigherEdOutputBackendData12 = newHigherEdOutputData['Bar Graph2']['Federal Unsubsidized Student Loan'];
		let furhigherEdOutputBackendData13 = newHigherEdOutputData['Bar Graph2']['Private Student Loan'];
		
		

		for (let i in furhigherEdOutputBackendData6) {
			furhigherEdOutputBackendData6Total += furhigherEdOutputBackendData6[i];
		}
		for (let i in furhigherEdOutputBackendData7) {
			furhigherEdOutputBackendData7Total += furhigherEdOutputBackendData7[i];
		}
		for (let i in furhigherEdOutputBackendData8) {
			furhigherEdOutputBackendData8Total += furhigherEdOutputBackendData8[i];
		}
		for (let i in furhigherEdOutputBackendData9) {
			furhigherEdOutputBackendData9Total += furhigherEdOutputBackendData9[i];
		}
		for (let i in furhigherEdOutputBackendData10) {
			furhigherEdOutputBackendData10Total += furhigherEdOutputBackendData10[i];
		}
		for (let i in furhigherEdOutputBackendData11) {
			furhigherEdOutputBackendData11Total += furhigherEdOutputBackendData11[i];
		}
		for (let i in furhigherEdOutputBackendData12) {
			furhigherEdOutputBackendData12Total += furhigherEdOutputBackendData12[i];
		}
		for (let i in furhigherEdOutputBackendData13) {
			furhigherEdOutputBackendData13Total += furhigherEdOutputBackendData13[i];
		}
	}

			
			
	fursecondHalfValue = furhigherEdOutputBackendData6Total + furhigherEdOutputBackendData7Total + furhigherEdOutputBackendData8Total + furhigherEdOutputBackendData9Total + furhigherEdOutputBackendData10Total + furhigherEdOutputBackendData11Total + furhigherEdOutputBackendData12Total + furhigherEdOutputBackendData13Total;
	simData['further_higher_education'] = -furfirstHalfValue + fursecondHalfValue;


	//Career Path Values
	let dbAdminValue = 0;
	let bonusTipsValue = 0;
	let supIncomeValue = 0;
	let firstCareerPathValue = 0;
	
	if (Object.keys(incomeStatementData).length !== 0) {
      Object.keys(incomeStatementData).forEach((year) => {
        if (year >= startYear && year <= endYear) {
          if (typeof incomeStatementData != 'undefined' && incomeStatementData != '' && typeof incomeStatementData[year] != 'undefined' && incomeStatementData[year] != '') {
				dbAdminValue += incomeStatementData[year]['Gross Income'][module4Data.occupation_title];
				bonusTipsValue += incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission'];
				supIncomeValue += incomeStatementData[year]['Gross Income']['Supplementary Income'];
			}
        }
      });
    }
	
	/*incomeStatementData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof incomeStatementData != 'undefined' && incomeStatementData != '' && typeof incomeStatementData[year] != 'undefined' && incomeStatementData[year] != '') {
				dbAdminValue += incomeStatementData[year]['Gross Income']['occupationTitle'];
				bonusTipsValue += incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission'];
				supIncomeValue += incomeStatementData[year]['Gross Income']['Supplementary Income'];
			}
		}
	  } );*/
	firstCareerPathValue = dbAdminValue + bonusTipsValue + supIncomeValue;

	let secondCareerPathValue = 0;

	if (typeof careerOutputBackendData != 'undefined' && careerOutputBackendData != '' && typeof careerOutputBackendData['Pie Chart'] != 'undefined' && careerOutputBackendData['Pie Chart'] != '') {
		secondCareerPathValue = careerOutputBackendData['Pie Chart']['totalRentValue'] +
			careerOutputBackendData['Pie Chart']['totalUtilitiesValue'] +
			careerOutputBackendData['Pie Chart']['totalFoodValue'] +
			careerOutputBackendData['Pie Chart']['totalClothingValue'] +
			careerOutputBackendData['Pie Chart']['totalEntertainmentValue'] +
			careerOutputBackendData['Pie Chart']['totalTechnologyValue'] +
			careerOutputBackendData['Pie Chart']['totalTransportationValue'] +
			careerOutputBackendData['Pie Chart']['totalMiscellaneousValue'] +
			careerOutputBackendData['Pie Chart']['totalHealthInsuranceValue'];

	}

	let thirdCareerPathValue = 0;

	if (typeof careerOutputBackendData != 'undefined' && careerOutputBackendData != '' &&
		typeof careerOutputBackendData['Bar Graph'] != 'undefined' && careerOutputBackendData['Bar Graph'] != '' &&
		typeof careerOutputBackendData['Bar Graph']['Retirement Contributions'] != 'undefined' && careerOutputBackendData['Bar Graph']['Retirement Contributions'] != '') {
		let careerOutputBackendData1 = careerOutputBackendData['Bar Graph']['Retirement Contributions'];

		for (let i in careerOutputBackendData1) {
			thirdCareerPathValue += careerOutputBackendData1[i];
		}
	}


	simData['career_path'] = firstCareerPathValue - secondCareerPathValue - thirdCareerPathValue;

	//Career Advancement Cash Flow value
	let dbAdminValueCareerAdv = 0;
	let bonusTipsValueCareerAdv = 0;
	let supIncomeValueCareerAdv = 0;
	let firstCareerPathValueCareerAdv = 0;
	
	if (Object.keys(incomeStatementData).length !== 0) {
      Object.keys(incomeStatementData).forEach((year) => {
        if (year >= startYear && year <= endYear) {
         if (typeof incomeStatementData != 'undefined' && incomeStatementData != '' && typeof incomeStatementData[year] != 'undefined' && incomeStatementData[year] != '') {
				dbAdminValueCareerAdv += incomeStatementData[year]['Gross Income'][`${module5Data.occupation_title} - Second`];
				bonusTipsValueCareerAdv += incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Second'];
				supIncomeValueCareerAdv += incomeStatementData[year]['Gross Income']['Supplementary Income - Second'];
			}
        }
      });
    }
	
	
	/*incomeStatementData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof incomeStatementData != 'undefined' && incomeStatementData != '' && typeof incomeStatementData[year] != 'undefined' && incomeStatementData[year] != '') {
				dbAdminValueCareerAdv += incomeStatementData[year]['Gross Income']['advanceOccupationTitle' + ' - Second'];
				bonusTipsValueCareerAdv += incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Second'];
				supIncomeValueCareerAdv += incomeStatementData[year]['Gross Income']['Supplementary Income - Second'];
			}
		}
	  } );*/
	firstCareerPathValueCareerAdv = dbAdminValueCareerAdv + bonusTipsValueCareerAdv + supIncomeValueCareerAdv;

	let secondCareerAdvValue = 0;

	if (typeof careerAdvOutputBackendData != 'undefined' && careerAdvOutputBackendData != '' &&
		typeof careerAdvOutputBackendData['Pie Chart'] != 'undefined' && careerAdvOutputBackendData['Pie Chart'] != '') {
		secondCareerAdvValue = careerAdvOutputBackendData['Pie Chart']['totalRentValue'] +
			careerAdvOutputBackendData['Pie Chart']['totalUtilitiesValue'] +
			careerAdvOutputBackendData['Pie Chart']['totalFoodValue'] +
			careerAdvOutputBackendData['Pie Chart']['totalClothingValue'] +
			careerAdvOutputBackendData['Pie Chart']['totalEntertainmentValue'] +
			careerAdvOutputBackendData['Pie Chart']['totalTechnologyValue'] +
			careerAdvOutputBackendData['Pie Chart']['totalTransportationValue'] +
			careerAdvOutputBackendData['Pie Chart']['totalMiscellaneousValue'] +
			careerAdvOutputBackendData['Pie Chart']['totalHealthInsuranceValue'];
	}

	let thirdCareerAdvValue = 0;

	if (typeof careerAdvOutputBackendData != 'undefined' && careerAdvOutputBackendData != '' && typeof careerAdvOutputBackendData['Bar Graph'] != 'undefined' &&
		careerAdvOutputBackendData['Bar Graph'] != '' && typeof careerAdvOutputBackendData['Bar Graph']['Retirement Contributions'] != 'undefined' && careerAdvOutputBackendData['Bar Graph']['Retirement Contributions'] != '') {

		let careerAdvOutputBackendData1 = careerAdvOutputBackendData['Bar Graph']['Retirement Contributions'];

		for (let i in careerAdvOutputBackendData1) {
			thirdCareerAdvValue += careerAdvOutputBackendData1[i];
		}
	}
	simData['career_advancement'] = firstCareerPathValueCareerAdv - secondCareerAdvValue - thirdCareerAdvValue;

	//Additional Career Advancement Casf Flow value
	let dbAdminValueCareerAddn = 0;
	let bonusTipsValueCareerAddn = 0;
	let supIncomeValueCareerAddn = 0;
	let firstCareerPathValueCareerAddn = 0;
	
	
	if (Object.keys(incomeStatementData).length !== 0) {
      Object.keys(incomeStatementData).forEach((year) => {
        if (year >= startYear && year <= endYear) {
         if (typeof incomeStatementData != 'undefined' && incomeStatementData != '' && typeof incomeStatementData[year] != 'undefined' && incomeStatementData[year] != '') {
				dbAdminValueCareerAddn += incomeStatementData[year]['Gross Income'][`${module8Data.occupation_title} - Third`];
				bonusTipsValueCareerAddn += incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Third'];
				supIncomeValueCareerAddn += incomeStatementData[year]['Gross Income']['Supplementary Income - Third'];
			}
        }
      });
    }
	
	
	/*incomeStatementData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof incomeStatementData != 'undefined' && incomeStatementData != '' && typeof incomeStatementData[year] != 'undefined' && incomeStatementData[year] != '') {
				dbAdminValueCareerAddn += incomeStatementData[year]['Gross Income']['jobTitle' + ' - Third'];
				bonusTipsValueCareerAddn += incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Third'];
				supIncomeValueCareerAddn += incomeStatementData[year]['Gross Income']['Supplementary Income - Third'];
			}
		}
	  } );*/
	firstCareerPathValueCareerAddn = dbAdminValueCareerAddn + bonusTipsValueCareerAddn + supIncomeValueCareerAddn;

	let secondCareerAddnValue = 0;

	if (typeof addnCareerAdvOutputBackendData != 'undefined' && addnCareerAdvOutputBackendData != '' && typeof addnCareerAdvOutputBackendData['Pie Chart'] != 'undefined' && addnCareerAdvOutputBackendData['Pie Chart'] != '') {
		secondCareerAddnValue = addnCareerAdvOutputBackendData['Pie Chart']['totalRentValue'] +
			addnCareerAdvOutputBackendData['Pie Chart']['totalUtilitiesValue'] +
			addnCareerAdvOutputBackendData['Pie Chart']['totalFoodValue'] +
			addnCareerAdvOutputBackendData['Pie Chart']['totalClothingValue'] +
			addnCareerAdvOutputBackendData['Pie Chart']['totalEntertainmentValue'] +
			addnCareerAdvOutputBackendData['Pie Chart']['totalTechnologyValue'] +
			addnCareerAdvOutputBackendData['Pie Chart']['totalTransportationValue'] +
			addnCareerAdvOutputBackendData['Pie Chart']['totalMiscellaneousValue'] +
			addnCareerAdvOutputBackendData['Pie Chart']['totalHealthInsuranceValue'];
	}

	let thirdCareerAddnValue = 0;
	if (typeof addnCareerAdvOutputBackendData != 'undefined' && addnCareerAdvOutputBackendData != '' &&
		typeof addnCareerAdvOutputBackendData['Bar Graph'] != 'undefined' && addnCareerAdvOutputBackendData['Bar Graph'] != '' &&
		typeof addnCareerAdvOutputBackendData['Bar Graph']['Retirement Contributions'] != 'undefined' && addnCareerAdvOutputBackendData['Bar Graph']['Retirement Contributions'] != '') {

		let careerAddnOutputBackendData1 = addnCareerAdvOutputBackendData['Bar Graph']['Retirement Contributions'];
		for (let i in careerAddnOutputBackendData1) {
			thirdCareerAddnValue += careerAddnOutputBackendData1[i];
		}
	}

	simData['additional_career_advancement'] = firstCareerPathValueCareerAddn - secondCareerAddnValue - thirdCareerAddnValue;

	//Further Career Advancement Casf Flow value
	let dbAdminValueCareerFur = 0;
	let bonusTipsValueCareerFur = 0;
	let supIncomeValueCareerFur = 0;
	let firstCareerPathValueCareerFur = 0;
	
	if (Object.keys(incomeStatementData).length !== 0) {
      Object.keys(incomeStatementData).forEach((year) => {
        if (year >= startYear && year <= endYear) {
         if (typeof incomeStatementData != 'undefined' && incomeStatementData != '' && typeof incomeStatementData[year] != 'undefined' && incomeStatementData[year] != '') {
				dbAdminValueCareerFur += incomeStatementData[year]['Gross Income'][`${module17Data.occupation_title} - Fourth`];
				bonusTipsValueCareerFur += incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'];
				supIncomeValueCareerFur += incomeStatementData[year]['Gross Income']['Supplementary Income - Fourth'];
			}
        }
      });
    }
	
	/*incomeStatementData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof incomeStatementData != 'undefined' && incomeStatementData != '' && typeof incomeStatementData[year] != 'undefined' && incomeStatementData[year] != '') {
				dbAdminValueCareerFur += incomeStatementData[year]['Gross Income']['furtherjobTitle' + ' - Fourth'];
				bonusTipsValueCareerFur += incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'];
				supIncomeValueCareerFur += incomeStatementData[year]['Gross Income']['Supplementary Income - Fourth'];
			}
		}
	  } );*/

	firstCareerPathValueCareerFur = dbAdminValueCareerFur + bonusTipsValueCareerFur + supIncomeValueCareerFur;

	let secondCareerFurValue = 0;

	if (typeof furtherCareerAdvOutputBackendData != 'undefined' && furtherCareerAdvOutputBackendData != '' && typeof furtherCareerAdvOutputBackendData['Pie Chart'] != 'undefined' && furtherCareerAdvOutputBackendData['Pie Chart'] != '') {
		secondCareerFurValue = furtherCareerAdvOutputBackendData['Pie Chart']['totalRentValue'] +
			furtherCareerAdvOutputBackendData['Pie Chart']['totalUtilitiesValue'] +
			furtherCareerAdvOutputBackendData['Pie Chart']['totalFoodValue'] +
			furtherCareerAdvOutputBackendData['Pie Chart']['totalClothingValue'] +
			furtherCareerAdvOutputBackendData['Pie Chart']['totalEntertainmentValue'] +
			furtherCareerAdvOutputBackendData['Pie Chart']['totalTechnologyValue'] +
			furtherCareerAdvOutputBackendData['Pie Chart']['totalTransportationValue'] +
			furtherCareerAdvOutputBackendData['Pie Chart']['totalMiscellaneousValue'] +
			furtherCareerAdvOutputBackendData['Pie Chart']['totalHealthInsuranceValue'];
	}
	let thirdCareerFurValue = 0;

	if (typeof furtherCareerAdvOutputBackendData != 'undefined' && furtherCareerAdvOutputBackendData != '' && typeof furtherCareerAdvOutputBackendData['Bar Graph'] != 'undefined' &&
		furtherCareerAdvOutputBackendData['Bar Graph'] != '' && typeof furtherCareerAdvOutputBackendData['Bar Graph']['Retirement Contributions'] != 'undefined' && furtherCareerAdvOutputBackendData['Bar Graph']['Retirement Contributions'] != '') {

		let careerFurOutputBackendData1 = furtherCareerAdvOutputBackendData['Bar Graph']['Retirement Contributions'];
		for (let i in careerFurOutputBackendData1) {
			thirdCareerFurValue += careerFurOutputBackendData1[i];
		}
	}

	simData['further_career_Advancement'] = firstCareerPathValueCareerFur - secondCareerFurValue - thirdCareerFurValue;

	//Investments CahFlow value
	let investmentsFirstValue = 0;
	let investmentsSecondValue = 0;
	let investmentsThirdValue = 0;
	
	if (Object.keys(cashFlowData).length !== 0) {
      Object.keys(cashFlowData).forEach((year) => {
        if (year >= startYear && year <= endYear) {
			if (typeof cashFlowData != 'undefined' && cashFlowData != '' && typeof cashFlowData[year] != 'undefined' && cashFlowData[year] != '') {
				if (typeof cashFlowData[year]['Cash from Investing Activities'] != 'undefined' && cashFlowData[year]['Cash from Investing Activities'] != ''
					&& typeof cashFlowData[year]['Cash from Investing Activities']['Contribution to Investment Accounts'] != 'undefined'
					&& cashFlowData[year]['Cash from Investing Activities']['Contribution to Investment Accounts'] != '') {
					investmentsFirstValue += cashFlowData[year]['Cash from Investing Activities']['Contribution to Investment Accounts'];
				}

				if (typeof cashFlowData[year]['Cash from Investing Activities'] != 'undefined' && cashFlowData[year]['Cash from Investing Activities'] != ''
					&& typeof cashFlowData[year]['Cash from Investing Activities']['Charitable Contributions'] != 'undefined'
					&& cashFlowData[year]['Cash from Investing Activities']['Charitable Contributions'] != '') {
					investmentsSecondValue += cashFlowData[year]['Cash from Investing Activities']['Charitable Contributions'];
				}

				if (typeof cashFlowData[year]['Cash from Investing Activities'] != 'undefined' && cashFlowData[year]['Cash from Investing Activities'] != ''
					&& typeof cashFlowData[year]['Cash from Investing Activities']['One-Time Financial Gift'] != 'undefined'
					&& cashFlowData[year]['Cash from Investing Activities']['One-Time Financial Gift'] != '') {
					investmentsThirdValue += cashFlowData[year]['Cash from Financing Activities']['One-Time Financial Gift'];
				}
			}
		}
      });
    }
	
	/*cashFlowData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof cashFlowData != 'undefined' && cashFlowData != '' && typeof cashFlowData[year] != 'undefined' && cashFlowData[year] != '') {
				if (typeof cashFlowData[year]['Cash from Investing Activities'] != 'undefined' && cashFlowData[year]['Cash from Investing Activities'] != ''
					&& typeof cashFlowData[year]['Cash from Investing Activities']['Contribution to Investment Accounts'] != 'undefined'
					&& cashFlowData[year]['Cash from Investing Activities']['Contribution to Investment Accounts'] != '') {
					investmentsFirstValue += cashFlowData[year]['Cash from Investing Activities']['Contribution to Investment Accounts'];
				}

				if (typeof cashFlowData[year]['Cash from Investing Activities'] != 'undefined' && cashFlowData[year]['Cash from Investing Activities'] != ''
					&& typeof cashFlowData[year]['Cash from Investing Activities']['Charitable Contributions'] != 'undefined'
					&& cashFlowData[year]['Cash from Investing Activities']['Charitable Contributions'] != '') {
					investmentsSecondValue += cashFlowData[year]['Cash from Investing Activities']['Charitable Contributions'];
				}

				if (typeof cashFlowData[year]['Cash from Investing Activities'] != 'undefined' && cashFlowData[year]['Cash from Investing Activities'] != ''
					&& typeof cashFlowData[year]['Cash from Investing Activities']['One-Time Financial Gift'] != 'undefined'
					&& cashFlowData[year]['Cash from Investing Activities']['One-Time Financial Gift'] != '') {
					investmentsThirdValue += cashFlowData[year]['Cash from Financing Activities']['One-Time Financial Gift'];
				}
			}
		}
	  } );*/

	simData['investments'] = investmentsFirstValue + investmentsSecondValue + investmentsThirdValue;

	//Family Cashflow Value
	let familyFirstValue = 0;
	if (typeof module10Data != 'undefined' && typeof module10Data.marritalTotalIncome != 'undefined') {
		familyFirstValue = module10Data.marritalTotalIncome;
	}

	let familySecondValue = 0;

	if (typeof familyOutputBackendData != 'undefined' && familyOutputBackendData != '' && typeof familyOutputBackendData['Pie Chart'] != 'undefined' && familyOutputBackendData['Pie Chart'] != '') {

		familySecondValue = familyOutputBackendData['Pie Chart']['totalRentValue'] +
			familyOutputBackendData['Pie Chart']['totalUtilitiesValue'] +
			familyOutputBackendData['Pie Chart']['totalFoodValue'] +
			familyOutputBackendData['Pie Chart']['totalClothingValue'] +
			familyOutputBackendData['Pie Chart']['totalEntertainmentValue'] +
			familyOutputBackendData['Pie Chart']['totalTechnologyValue'] +
			familyOutputBackendData['Pie Chart']['totalTransportationValue'] +
			familyOutputBackendData['Pie Chart']['totalMiscellaneousValue'] +
			familyOutputBackendData['Pie Chart']['totalHealthInsuranceValue'] +
			familyOutputBackendData['Pie Chart']['totalChildrenValue'] +
			familyOutputBackendData['Pie Chart']['totalPetValue'];
	}

	let familyThirdValue = 0;
	if (typeof incomeStatementData != 'undefined' && incomeStatementData != '') {
		startYear = parseInt(module10Data.marriege_year);
		let endYear1 = endYear;		
		
		if (Object.keys(incomeStatementData).length !== 0) {
		  Object.keys(incomeStatementData).forEach((year) => {
			if (year >= startYear && year <= endYear1) {
			 if (typeof incomeStatementData[year] != 'undefined' && incomeStatementData[year] != ''
						&& typeof incomeStatementData[year]['Gross Income'] != 'undefined' && incomeStatementData[year]['Gross Income'] != ''
						&& typeof incomeStatementData[year]['Gross Income']['Rental Income'] != 'undefined' && incomeStatementData[year]['Gross Income']['Rental Income'] != '') {
						familyThirdValue += incomeStatementData[year]['Gross Income']['Rental Income'];
					}
			}
		  });
		}
	
		/*incomeStatementData.forEach( ( value, year ) => {
			if (year >= startYear && year <= endYear1) {
				if (typeof incomeStatementData[year] != 'undefined' && incomeStatementData[year] != ''
					&& typeof incomeStatementData[year]['Gross Income'] != 'undefined' && incomeStatementData[year]['Gross Income'] != ''
					&& typeof incomeStatementData[year]['Gross Income']['Rental Income'] != 'undefined' && incomeStatementData[year]['Gross Income']['Rental Income'] != '') {
					familyThirdValue += incomeStatementData[year]['Gross Income']['Rental Income'];
				}
			}
		  } );*/
	}
	simData['family'] = familyFirstValue - familySecondValue - familyThirdValue;

	//Vacation + Special Purchases
	let vacationFirstValue = 0;
	let vacationSecondValue = 0;
	let vacationThirdValue = 0;
	
	if (Object.keys(cashFlowData).length !== 0) {
		  Object.keys(cashFlowData).forEach((year) => {
			if (year >= startYear && year <= endYear) {
			if (typeof cashFlowData != 'undefined' && cashFlowData != '' && typeof cashFlowData[year] != 'undefined' && cashFlowData[year] != '') {

				if (typeof cashFlowData[year]['Cash from Investing Activities'] != 'undefined' && cashFlowData[year]['Cash from Investing Activities'] != '') {
					if (typeof cashFlowData[year]['Cash from Investing Activities']['Vacation'] != 'undefined' && cashFlowData[year]['Cash from Investing Activities']['Vacation'] != '') {
						vacationFirstValue += cashFlowData[year]['Cash from Investing Activities']['Vacation'];
					}
					if (typeof cashFlowData[year]['Cash from Investing Activities']['otherAcquisition1'] != 'undefined' && cashFlowData[year]['Cash from Investing Activities']['otherAcquisition1'] != '') {
						vacationSecondValue += cashFlowData[year]['Cash from Investing Activities']['otherAcquisition1'];
					}
					if (typeof cashFlowData[year]['Cash from Investing Activities']['otherAcquisition2' + ' - Second'] != 'undefined' && cashFlowData[year]['Cash from Investing Activities']['otherAcquisition2' + ' - Second'] != '') {
						vacationThirdValue += cashFlowData[year]['Cash from Investing Activities']['otherAcquisition2' + ' - Second'];
					}
				}
			}
		}
		  });
		}
		
		
	/*cashFlowData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof cashFlowData != 'undefined' && cashFlowData != '' && typeof cashFlowData[year] != 'undefined' && cashFlowData[year] != '') {

				if (typeof cashFlowData[year]['Cash from Investing Activities'] != 'undefined' && cashFlowData[year]['Cash from Investing Activities'] != '') {
					if (typeof cashFlowData[year]['Cash from Investing Activities']['Vacation'] != 'undefined' && cashFlowData[year]['Cash from Investing Activities']['Vacation'] != '') {
						vacationFirstValue += cashFlowData[year]['Cash from Investing Activities']['Vacation'];
					}
					if (typeof cashFlowData[year]['Cash from Investing Activities']['otherAcquisition1'] != 'undefined' && cashFlowData[year]['Cash from Investing Activities']['otherAcquisition1'] != '') {
						vacationSecondValue += cashFlowData[year]['Cash from Investing Activities']['otherAcquisition1'];
					}
					if (typeof cashFlowData[year]['Cash from Investing Activities']['otherAcquisition2' + ' - Second'] != 'undefined' && cashFlowData[year]['Cash from Investing Activities']['otherAcquisition2' + ' - Second'] != '') {
						vacationThirdValue += cashFlowData[year]['Cash from Investing Activities']['otherAcquisition2' + ' - Second'];
					}
				}
			}
		}
	  } );*/

	simData['vacation'] = vacationFirstValue + vacationSecondValue + vacationThirdValue;

	//Buying a Car cashflow value
	let carLoan1Value1 = 0;
	let carLoan1Value2 = 0;
	let carLoan1Value = 0;
	let carLoan2Value1 = 0;
	let carLoan2Value2 = 0;
	let carLoan2Value = 0;
	let carLoan1NeedData = carLoan1Data['Summation Data'];
	
	if (Object.keys(carLoan1NeedData).length !== 0) {
		  Object.keys(carLoan1NeedData).forEach((year) => {
			if (year >= startYear && year <= endYear) {
				if (typeof carLoan1NeedData != 'undefined' && carLoan1NeedData != '') {
					if (typeof carLoan1Data['Summation Data'][year] != 'undefined' && carLoan1Data['Summation Data'][year] != '' && typeof carLoan1Data['Summation Data'][year]['LaggingInterest'] != 'undefined' && carLoan1Data['Summation Data'][year]['LaggingInterest'] != '') {
						carLoan1Value1 += carLoan1Data['Summation Data'][year]['LaggingInterest'];
					}
					if (typeof carLoan1Data['Summation Data'][year] != 'undefined' && carLoan1Data['Summation Data'][year] != ''
						&& typeof carLoan1Data['Summation Data'][year]['LaggingPrincipal'] != 'undefined'
						&& carLoan1Data['Summation Data'][year]['LaggingPrincipal'] != '') {
						carLoan1Value2 += carLoan1Data['Summation Data'][year]['LaggingPrincipal'];
					}
				}
			}
		  });
		}
		
	/*carLoan1NeedData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof carLoan1NeedData != 'undefined' && carLoan1NeedData != '') {
				if (typeof carLoan1Data['Summation Data'][year] != 'undefined' && carLoan1Data['Summation Data'][year] != '' && typeof carLoan1Data['Summation Data'][year]['LaggingInterest'] != 'undefined' && carLoan1Data['Summation Data'][year]['LaggingInterest'] != '') {
					carLoan1Value1 += carLoan1Data['Summation Data'][year]['LaggingInterest'];
				}
				if (typeof carLoan1Data['Summation Data'][year] != 'undefined' && carLoan1Data['Summation Data'][year] != ''
					&& typeof carLoan1Data['Summation Data'][year]['LaggingPrincipal'] != 'undefined'
					&& carLoan1Data['Summation Data'][year]['LaggingPrincipal'] != '') {
					carLoan1Value2 += carLoan1Data['Summation Data'][year]['LaggingPrincipal'];
				}
			}
		}
	  } );*/
	carLoan1Value = carLoan1Value1 + carLoan1Value2;

	let carLoan2NeedData = carLoan2Data['Summation Data'];
	
	if (Object.keys(carLoan2NeedData).length !== 0) {
		  Object.keys(carLoan2NeedData).forEach((year) => {
			if (year >= startYear && year <= endYear) {
				if (typeof carLoan2NeedData != 'undefined' && carLoan2NeedData != '') {
					if (typeof carLoan2Data['Summation Data'][year] != 'undefined' && carLoan2Data['Summation Data'][year] != '' &&
						typeof carLoan2Data['Summation Data'][year]['LaggingInterest'] != 'undefined' && carLoan2Data['Summation Data'][year]['LaggingInterest'] != '') {
						carLoan2Value1 += carLoan2Data['Summation Data'][year]['LaggingInterest'];
					}
					if (typeof carLoan2Data['Summation Data'][year] != 'undefined' && carLoan2Data['Summation Data'][year] != '' &&
						typeof carLoan2Data['Summation Data'][year]['LaggingPrincipal'] != 'undefined' && carLoan2Data['Summation Data'][year]['LaggingPrincipal'] != '') {
						carLoan2Value2 += carLoan2Data['Summation Data'][year]['LaggingPrincipal'];
					}
				}
			}
		  });
		}
		
	/*carLoan2NeedData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof carLoan2NeedData != 'undefined' && carLoan2NeedData != '') {
				if (typeof carLoan2Data['Summation Data'][year] != 'undefined' && carLoan2Data['Summation Data'][year] != '' &&
					typeof carLoan2Data['Summation Data'][year]['LaggingInterest'] != 'undefined' && carLoan2Data['Summation Data'][year]['LaggingInterest'] != '') {
					carLoan2Value1 += carLoan2Data['Summation Data'][year]['LaggingInterest'];
				}
				if (typeof carLoan2Data['Summation Data'][year] != 'undefined' && carLoan2Data['Summation Data'][year] != '' &&
					typeof carLoan2Data['Summation Data'][year]['LaggingPrincipal'] != 'undefined' && carLoan2Data['Summation Data'][year]['LaggingPrincipal'] != '') {
					carLoan2Value2 += carLoan2Data['Summation Data'][year]['LaggingPrincipal'];
				}
			}
		}
	  } );*/

	carLoan2Value = carLoan2Value1 + carLoan2Value2;

	let car1Value = 0;
	let Car1maintenanceValue = 0;
	let Car1insuranceValue = 0;
	let Car1gasValue = 0;
	
	if (Object.keys(depreciationSheduleData).length !== 0) {
		  Object.keys(depreciationSheduleData).forEach((year) => {
			if (year >= startYear && year <= endYear) {
				if (typeof depreciationSheduleData != 'undefined' && depreciationSheduleData != ''
					&& typeof depreciationSheduleData[year] != 'undefined' && depreciationSheduleData[year] != '') {
	
					if (typeof depreciationSheduleData[year]['Understanding Depreciation'] != 'undefined' && depreciationSheduleData[year]['Understanding Depreciation'] != '') {
						if (typeof depreciationSheduleData[year]['Understanding Depreciation']['Maintenance'] != 'undefined' && depreciationSheduleData[year]['Understanding Depreciation']['Maintenance'] != '') {
							Car1maintenanceValue += depreciationSheduleData[year]['Understanding Depreciation']['Maintenance'];
						}
						if (typeof depreciationSheduleData[year]['Understanding Depreciation']['Insurance'] != 'undefined' && depreciationSheduleData[year]['Understanding Depreciation']['Insurance'] != '') {
							Car1insuranceValue += depreciationSheduleData[year]['Understanding Depreciation']['Insurance'];
						}
						if (typeof depreciationSheduleData[year]['Understanding Depreciation']['Gas'] != 'undefined' && depreciationSheduleData[year]['Understanding Depreciation']['Gas'] != '') {
							Car1gasValue += depreciationSheduleData[year]['Understanding Depreciation']['Gas'];
						}
					}
	
				}
			}
		  });
		}

	/*depreciationSheduleData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof depreciationSheduleData != 'undefined' && depreciationSheduleData != ''
				&& typeof depreciationSheduleData[year] != 'undefined' && depreciationSheduleData[year] != '') {

				if (typeof depreciationSheduleData[year]['Understanding Depreciation'] != 'undefined' && depreciationSheduleData[year]['Understanding Depreciation'] != '') {
					if (typeof depreciationSheduleData[year]['Understanding Depreciation']['Maintenance'] != 'undefined' && depreciationSheduleData[year]['Understanding Depreciation']['Maintenance'] != '') {
						Car1maintenanceValue += depreciationSheduleData[year]['Understanding Depreciation']['Maintenance'];
					}
					if (typeof depreciationSheduleData[year]['Understanding Depreciation']['Insurance'] != 'undefined' && depreciationSheduleData[year]['Understanding Depreciation']['Insurance'] != '') {
						Car1insuranceValue += depreciationSheduleData[year]['Understanding Depreciation']['Insurance'];
					}
					if (typeof depreciationSheduleData[year]['Understanding Depreciation']['Gas'] != 'undefined' && depreciationSheduleData[year]['Understanding Depreciation']['Gas'] != '') {
						Car1gasValue += depreciationSheduleData[year]['Understanding Depreciation']['Gas'];
					}
				}

			}
		}
	  } );*/

	car1Value = Car1maintenanceValue + Car1insuranceValue + Car1gasValue;

	let car2Value = 0;
	let Car2maintenanceValue = 0;
	let Car2insuranceValue = 0;
	let Car2gasValue = 0;
	if (Object.keys(depreciationSheduleData).length !== 0) {
		  Object.keys(depreciationSheduleData).forEach((year) => {
			if (year >= startYear && year <= endYear) {
				if (typeof depreciationSheduleData != 'undefined' && depreciationSheduleData != '' && typeof depreciationSheduleData[year] != 'undefined' && depreciationSheduleData[year] != '') {
	
					if (typeof depreciationSheduleData[year]['Automobile Straight-Line Depreciation'] != 'undefined' && depreciationSheduleData[year]['Automobile Straight-Line Depreciation'] != '') {
						if (typeof depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Maintenance'] != 'undefined') {
							Car2maintenanceValue += depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Maintenance'];
						}
						if (typeof depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Insurance'] != 'undefined') {
							Car2insuranceValue += depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Insurance'];
						}
						if (typeof depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Gas'] != 'undefined') {
							Car2gasValue += depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Gas'];
						}
					}
	
				}
			}
		  });
		}

	/*depreciationSheduleData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof depreciationSheduleData != 'undefined' && depreciationSheduleData != '' && typeof depreciationSheduleData[year] != 'undefined' && depreciationSheduleData[year] != '') {

				if (typeof depreciationSheduleData[year]['Automobile Straight-Line Depreciation'] != 'undefined' && depreciationSheduleData[year]['Automobile Straight-Line Depreciation'] != '') {
					if (typeof depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Maintenance'] != 'undefined') {
						Car2maintenanceValue += depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Maintenance'];
					}
					if (typeof depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Insurance'] != 'undefined') {
						Car2insuranceValue += depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Insurance'];
					}
					if (typeof depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Gas'] != 'undefined') {
						Car2gasValue += depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Gas'];
					}
				}

			}
		}
	  } );*/

	car2Value = Car2maintenanceValue + Car2insuranceValue + Car2gasValue;

	let car3Value = 0;
	let Car3DownPaymentValue = 0;
	let Car3CarPaymentValue = 0;
	let Car3maintenanceValue = 0;
	let Car3insuranceValue = 0;
	let Car3gasValue = 0;
	
	if (Object.keys(depreciationSheduleData).length !== 0) {
		  Object.keys(depreciationSheduleData).forEach((year) => {
			if (year >= startYear && year <= endYear) {
				if (typeof depreciationSheduleData != 'undefined' && depreciationSheduleData != ''
					&& typeof depreciationSheduleData[year] != 'undefined' && depreciationSheduleData[year] != '') {
	
					if (typeof depreciationSheduleData[year]['Lease1 Expenses'] != 'undefined' && depreciationSheduleData[year]['Lease1 Expenses'] != '') {
						if (typeof depreciationSheduleData[year]['Lease1 Expenses']['Down Payment'] != 'undefined'
							&& depreciationSheduleData[year]['Lease1 Expenses']['Down Payment'] != '') {
							Car3DownPaymentValue += depreciationSheduleData[year]['Lease1 Expenses']['Down Payment'];
						}
						if (typeof depreciationSheduleData[year]['Lease1 Expenses']['Car Payment'] != 'undefined'
							&& depreciationSheduleData[year]['Lease1 Expenses']['Car Payment'] != '') {
							Car3CarPaymentValue += depreciationSheduleData[year]['Lease1 Expenses']['Car Payment'];
						}
						if (typeof depreciationSheduleData[year]['Lease1 Expenses']['Maintenance'] != 'undefined'
							&& depreciationSheduleData[year]['Lease1 Expenses']['Maintenance'] != '') {
							Car3maintenanceValue += depreciationSheduleData[year]['Lease1 Expenses']['Maintenance'];
						}
						if (typeof depreciationSheduleData[year]['Lease1 Expenses']['Insurance'] != 'undefined'
							&& depreciationSheduleData[year]['Lease1 Expenses']['Insurance'] != '') {
							Car3insuranceValue += depreciationSheduleData[year]['Lease1 Expenses']['Insurance'];
						}
						if (typeof depreciationSheduleData[year]['Lease1 Expenses']['Gas'] != 'undefined'
							&& depreciationSheduleData[year]['Lease1 Expenses']['Gas'] != '') {
							Car3gasValue += depreciationSheduleData[year]['Lease1 Expenses']['Gas'];
						}
					}
				}
			}
		  });
		}

	/*depreciationSheduleData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof depreciationSheduleData != 'undefined' && depreciationSheduleData != ''
				&& typeof depreciationSheduleData[year] != 'undefined' && depreciationSheduleData[year] != '') {

				if (typeof depreciationSheduleData[year]['Lease1 Expenses'] != 'undefined' && depreciationSheduleData[year]['Lease1 Expenses'] != '') {
					if (typeof depreciationSheduleData[year]['Lease1 Expenses']['Down Payment'] != 'undefined'
						&& depreciationSheduleData[year]['Lease1 Expenses']['Down Payment'] != '') {
						Car3DownPaymentValue += depreciationSheduleData[year]['Lease1 Expenses']['Down Payment'];
					}
					if (typeof depreciationSheduleData[year]['Lease1 Expenses']['Car Payment'] != 'undefined'
						&& depreciationSheduleData[year]['Lease1 Expenses']['Car Payment'] != '') {
						Car3CarPaymentValue += depreciationSheduleData[year]['Lease1 Expenses']['Car Payment'];
					}
					if (typeof depreciationSheduleData[year]['Lease1 Expenses']['Maintenance'] != 'undefined'
						&& depreciationSheduleData[year]['Lease1 Expenses']['Maintenance'] != '') {
						Car3maintenanceValue += depreciationSheduleData[year]['Lease1 Expenses']['Maintenance'];
					}
					if (typeof depreciationSheduleData[year]['Lease1 Expenses']['Insurance'] != 'undefined'
						&& depreciationSheduleData[year]['Lease1 Expenses']['Insurance'] != '') {
						Car3insuranceValue += depreciationSheduleData[year]['Lease1 Expenses']['Insurance'];
					}
					if (typeof depreciationSheduleData[year]['Lease1 Expenses']['Gas'] != 'undefined'
						&& depreciationSheduleData[year]['Lease1 Expenses']['Gas'] != '') {
						Car3gasValue += depreciationSheduleData[year]['Lease1 Expenses']['Gas'];
					}
				}
			}
		}
	  } );*/

	car3Value = Car3DownPaymentValue + Car3CarPaymentValue + Car3maintenanceValue + Car3insuranceValue + Car3gasValue;

	let car4Value = 0;
	let Car4DownPaymentValue = 0;
	let Car4CarPaymentValue = 0;
	let Car4maintenanceValue = 0;
	let Car4insuranceValue = 0;
	let Car4gasValue = 0;
	
	if (Object.keys(depreciationSheduleData).length !== 0) {
		  Object.keys(depreciationSheduleData).forEach((year) => {
			if (year >= startYear && year <= endYear) {
				if (typeof depreciationSheduleData != 'undefined' && depreciationSheduleData != ''
					&& typeof depreciationSheduleData[year] != 'undefined' && depreciationSheduleData[year] != '') {
	
					if (typeof depreciationSheduleData[year]['Lease2 Expenses'] != 'undefined' && depreciationSheduleData[year]['Lease2 Expenses'] != '') {
						if (typeof depreciationSheduleData[year]['Lease2 Expenses']['Down Payment'] != 'undefined' && depreciationSheduleData[year]['Lease2 Expenses']['Down Payment'] != '') {
							Car4DownPaymentValue += depreciationSheduleData[year]['Lease2 Expenses']['Down Payment'];
						}
						if (typeof depreciationSheduleData[year]['Lease2 Expenses']['Car Payment'] != 'undefined' && depreciationSheduleData[year]['Lease2 Expenses']['Car Payment'] != '') {
							Car4CarPaymentValue += depreciationSheduleData[year]['Lease2 Expenses']['Car Payment'];
						}
						if (typeof depreciationSheduleData[year]['Lease2 Expenses']['Maintenance'] != 'undefined' && depreciationSheduleData[year]['Lease2 Expenses']['Maintenance'] != '') {
							Car4maintenanceValue += depreciationSheduleData[year]['Lease2 Expenses']['Maintenance'];
						}
						if (typeof depreciationSheduleData[year]['Lease2 Expenses']['Insurance'] != 'undefined' && depreciationSheduleData[year]['Lease2 Expenses']['Insurance'] != '') {
							Car4insuranceValue += depreciationSheduleData[year]['Lease2 Expenses']['Insurance'];
						}
						if (typeof depreciationSheduleData[year]['Lease2 Expenses']['Gas'] != 'undefined' && depreciationSheduleData[year]['Lease2 Expenses']['Gas'] != '') {
							Car4gasValue += depreciationSheduleData[year]['Lease2 Expenses']['Gas'];
						}
					}
				}
			}
		  });
		}
		
	/*depreciationSheduleData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof depreciationSheduleData != 'undefined' && depreciationSheduleData != ''
				&& typeof depreciationSheduleData[year] != 'undefined' && depreciationSheduleData[year] != '') {

				if (typeof depreciationSheduleData[year]['Lease2 Expenses'] != 'undefined' && depreciationSheduleData[year]['Lease2 Expenses'] != '') {
					if (typeof depreciationSheduleData[year]['Lease2 Expenses']['Down Payment'] != 'undefined' && depreciationSheduleData[year]['Lease2 Expenses']['Down Payment'] != '') {
						Car4DownPaymentValue += depreciationSheduleData[year]['Lease2 Expenses']['Down Payment'];
					}
					if (typeof depreciationSheduleData[year]['Lease2 Expenses']['Car Payment'] != 'undefined' && depreciationSheduleData[year]['Lease2 Expenses']['Car Payment'] != '') {
						Car4CarPaymentValue += depreciationSheduleData[year]['Lease2 Expenses']['Car Payment'];
					}
					if (typeof depreciationSheduleData[year]['Lease2 Expenses']['Maintenance'] != 'undefined' && depreciationSheduleData[year]['Lease2 Expenses']['Maintenance'] != '') {
						Car4maintenanceValue += depreciationSheduleData[year]['Lease2 Expenses']['Maintenance'];
					}
					if (typeof depreciationSheduleData[year]['Lease2 Expenses']['Insurance'] != 'undefined' && depreciationSheduleData[year]['Lease2 Expenses']['Insurance'] != '') {
						Car4insuranceValue += depreciationSheduleData[year]['Lease2 Expenses']['Insurance'];
					}
					if (typeof depreciationSheduleData[year]['Lease2 Expenses']['Gas'] != 'undefined' && depreciationSheduleData[year]['Lease2 Expenses']['Gas'] != '') {
						Car4gasValue += depreciationSheduleData[year]['Lease2 Expenses']['Gas'];
					}
				}
			}
		}
	  } );*/

	car4Value = Car4DownPaymentValue + Car4CarPaymentValue + Car4maintenanceValue + Car4insuranceValue + Car4gasValue;

	let car5Value = 0;
	if (typeof module12Data != 'undefined' && typeof module12Data.downPaymentCar != 'undefined') {
		car5Value = module12Data.downPaymentCar;
	}

	let car6Value = 0;
	if (typeof module12Data != 'undefined' && typeof module12Data.moreDownPaymentCar != 'undefined') {
		car6Value = module12Data.moreDownPaymentCar;
	}

	simData['buying_car'] = -(carLoan1Value + carLoan2Value + car1Value + car2Value + car3Value + car4Value + car5Value + car6Value);

	//Buying a Home Cash Flow value buyingAHomeData
	let buyingAHomeNeededData = buyingAHomeData['Data1'];
	let buyingaHomeValue = 0;
	
	if (Object.keys(buyingAHomeNeededData).length !== 0) {
		  Object.keys(buyingAHomeNeededData).forEach((year) => {
			if (year >= startYear && year <= endYear) {
				if (typeof buyingAHomeNeededData != 'undefined' && buyingAHomeNeededData != ''
					&& typeof buyingAHomeNeededData[year] != 'undefined' && buyingAHomeNeededData[year] != '') {
					buyingaHomeValue += buyingAHomeNeededData[year]['Net Cash Flow'];
				}
			}
		  });
		}
		

	/*buyingAHomeNeededData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof buyingAHomeNeededData != 'undefined' && buyingAHomeNeededData != ''
				&& typeof buyingAHomeNeededData[year] != 'undefined' && buyingAHomeNeededData[year] != '') {
				buyingaHomeValue += buyingAHomeNeededData[year]['Net Cash Flow'];
			}
		}
	  } );*/

	simData['buying_home'] = buyingaHomeValue;

	//Buying a Real Estate Property Cash Flow value
	//Buying a Real Estate Property Cash Flow value
	let invPropertyRoiNeededData = invPropertyRoiData['Data1'];
	let reiValue = 0;
	
	if (Object.keys(buyingAHomeNeededData).length !== 0) {
		  Object.keys(buyingAHomeNeededData).forEach((year) => {
			if (year >= startYear && year <= endYear) {
				if (typeof invPropertyRoiNeededData != 'undefined' && invPropertyRoiNeededData != '' && typeof invPropertyRoiNeededData[year] != 'undefined' && invPropertyRoiNeededData[year] != '') {
					reiValue += invPropertyRoiNeededData[year]['Net Cash Flow'];
				}
			}
		  });
		}
		
	/*buyingAHomeNeededData.forEach( ( value, year ) => {
		if (year >= startYear && year <= endYear) {
			if (typeof invPropertyRoiNeededData != 'undefined' && invPropertyRoiNeededData != '' && typeof invPropertyRoiNeededData[year] != 'undefined' && invPropertyRoiNeededData[year] != '') {
				reiValue += invPropertyRoiNeededData[year]['Net Cash Flow'];
			}
		}
	  } );*/

	simData['Buying_a_Real_Estate_Property'] = reiValue;


	function simPanelCalcsCompleteData() {
		let simPanelCalcsData = {};
		simPanelCalcsData['Data'] = simData;
		console.log('phrudhvi',simData);
		return simPanelCalcsData;
	}
	return simPanelCalcsCompleteData();
}


export default simPanelCalcs;
