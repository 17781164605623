import gradientBlue from '../../assets/img/desktop/gradient_bg.png';
import graphBg from '../../assets/img/dashboard/dashboard_graph_bg.png';

const styles = (theme) => ({
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  welcomeContent: {
    border: '2px solid #d7d7d7',
    padding: '0',
    margin: '0px 0 35px',
    // paddingTop: '20px',
    borderTopWidth: '15px',
    borderRadius: '12px',
    display: 'flex',
    marginLeft: '-15px',
    width: 'calc(100% + 30px)',
    position: 'relative',
    top: '20px',
    boxSizing: 'border-box',
    background: '#fff',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      paddingBottom: '20px',
    },
  },
  dashboardImage: {
    display: 'flex',
    width: '34%',
    margin: '10px 10px 10px 23px',
    borderRight: '3px solid #d7d7d7',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      width: '100%',
      borderRight: 'none',
    },
    '& h2': {
      display: 'table-cell',
      verticalAlign: 'top',
      color: '#0069aa',
      fontSize: '24px',
      padding: '17px 0 0 10px',
      textTransform: 'capitalize',
      lineHeight: '24px',
      fontFamily: '"MuseoSans-500"',
      fontWeight: '400',
      '@media (max-width: 320px)': {
        fontSize: '16px !important',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '22px',
      },
      '@media screen and (min-width: 600px) and (max-width: 700px)': {
        fontSize: '13px',
      },
      '@media screen and (min-width: 701px) and (max-width: 767px)': {
        fontSize: '15px',
      },
      '@media screen and (min-width: 768px) and (max-width: 992px)': {
        fontSize: '16px',
      },
      '@media screen and (min-width: 993px) and (max-width: 1024px)': {
        fontSize: '20px',
      },
    },
  },
  acSketch: {
    '@media screen and (min-width: 600px) and (max-width: 992px)': {
      maxWidth: '40%',
      flexBasis: '40%',
    },
  },
  userProfile: {
    width: '115px',
    verticalAign: 'top',
    '@media screen and (min-width: 600px) and (max-width: 768px)': {
      width: '50px',
    },
    '@media screen and (min-width: 769px) and (max-width: 992px)': {
      width: '80px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80px',
    },
    '@media (max-width: 320px)': {
      width: '60px',
    },
    '& img': {
      display: 'block',
      width: '108px',
      height: '108px',
      borderRadius: '100%',
      border: '1px solid #ccc',
      cursor: 'pointer',
      objectFit: 'cover',
      '@media screen and (min-width: 600px) and (max-width: 768px)': {
        width: '50px',
        height: '50px',
      },
      '@media screen and (min-width: 769px) and (max-width: 992px)': {
        width: '80px',
        height: '80px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '80px',
        height: '80px',
      },
      '@media (max-width: 320px)': {
        width: '60px',
        height: '60px',
      },
    },
  },
  dashboardContent: {
    width: '66%',
    display: 'inline-block',
    padding: '29px 0 0 8px',
    '@media (max-width: 768px)': {
      fontSize: '13px',
    },
    '@media screen and (min-width: 600px) and (max-width: 768px)': {
      width: '70%',
    },
  },
  activeSketch: {
    '& h2': {
      margin: '0',
      padding: '0 0 6px',
      fontFamily: '"MuseoSans-300"',
      fontSize: '18px',
      lineHeight: '18px',
      color: '#717171',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        fontSize: '14px',
      },
      '@media screen and (min-width: 768px) and (max-width: 992px)': {
        fontSize: '17px',
      },
      '@media (max-width: 320px)': {
        fontSize: '16px',
      },
    },
    '& p': {
      margin: '0',
      padding: '0 0 15px',
      fontFamily: '"MuseoSans-100"',
      fontSize: '14px',
      lineHeight: '14px',
      color: theme.palette.common.black,
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '0px',
      },
    },
  },
  dashboardNetWorth: {
    display: 'flex',
    flexWrap: 'wrap',
    '& h2': {
      padding: '0 12px 0 0',
      fontFamily: '"MuseoSans-300"',
      fontSize: '18px',
      lineHeight: '18px',
      color: '#717171',
      '@media (max-width: 768px)': {
        fontSize: '16px',
      },
    },
    '& ul': {
      padding: '0px',
      margin: '0px',
      listStyle: 'none',
      '& li': {
        display: 'flex',
        padding: '0 0 8px',
        color: '#000',
        margin: '0',
        '& font': {
          display: 'inline-block',
          verticalAlign: 'middle',
          width: '45%',
          fontFamily: '"MuseoSans-300"',
          fontSize: '18px',
          lineHeight: '18px',
          boxSizing: 'border-box',
        },
      },
    },
  },
  yearCount:{
    display: 'inline-block',
    verticalAlign: 'middle',
    fontFamily: '"MuseoSans-500"',
    fontSize: '12px',
    lineHeight: '12px',
    padding: '4px 13px',
    margin: '0 14px 0 0',
    background: '#ebebeb',
    borderRadius: '12px',
    minWidth: '72px',
    textAlign: 'center',
    boxSizing: 'border-box',
    '@media screen and (min-width: 600px) and (max-width: 767px)': {
      fontSize: '10px',
    },
  },
  formInput:{
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '45%',
    fontFamily: '"MuseoSans-300"',
    fontSize: '18px',
    lineHeight: '18px',
    boxSizing: 'border-box',
  },
  formInputFull:{
    width: '100%',
  },
  desktopArrow: {
    maxWidth: '82%',
    position: 'relative',
    margin: '0 auto',
    '& img': {
      position: 'absolute',
      maxWidth: '100%',
      top: '130px',
      bottom: '0',
      display: 'block',
      height: 'auto',
      paddingTop: '0px',
    },
  },
  uncoverPath: {
    backgroundImage: `url(${gradientBlue})`,
    backgroundSize: 'cover',
    display: 'block',
    padding: '45px 0px',
  },
  arrowContent: {
    position: 'relative',
    '& h4': {
      color: '#fff',
      fontSize: '45px',
      lineHeight: '45px',
      textAlign: 'center',
      padding: '30px 0px 70px',
      fontFamily: 'MuseoSans-100',
    },
  },
  desktopLogos: {
    margin: '8px 0',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'relative',
    padding: '50px 0 100px',
    boxSizing: 'border-box',
  },
  powerUp: {
    padding: '10px 20px',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    borderRadius: '10px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      background: '#789',
    },
    '& img': {
      maxWidth: '280px',
      float: 'right',
    },
  },
  careerSketch: {
    padding: '10px 20px',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    borderRadius: '10px',
    margin: '110px 10px 0',
    cursor: 'pointer',
    boxSizing: 'border-box',
    '&:hover': {
      background: '#789',
    },
    '& img': {
      maxWidth: '280px',
      float: 'right',
    },
  },
  planInkwiry: {
    marginLeft: '0',
    padding: '24px 40px',
    marginTop: '-25px',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    borderRadius: '10px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      background: '#789',
    },
    '& img': {
      maxWidth: '240px',
      display: 'flex',
      margin: '0 auto',
    },
  },
  bodyBgNew: {
    background: '#f3f3f3',
  },
  currentSenarioBlock: {
    padding: '20px',
    paddingBottom: '2px',
    '& header': {
      border: 'none',
      background: 'transparent',
      boxShadow: 'none',
      '& div': {
        minHeight: '20px',
        '& button': {
          minHeight: '20px',
          background: '#dcdcdc',
          marginRight: '2px',
          textTransform: 'inherit',
          padding: '5px 15px',
          boxSizing: 'border-box',
          lineHeight: '1.42857143',
          minWidth: 'auto',
          '&:hover': {
            background: '#f89b22',
            color: '#fff',
          },
          '&:active': {
            background: '#f89b22',
            color: '#fff',
          },
        },
      },
      '& span': {
        backgroundColor: 'transparent !important',
      },
    },
  },
  careerSketchTimeline: {
    textAlign: 'center',
    padding: '0 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0px',
    },
    '& h3': {
      textAlign: 'left',
      color: '#0069aa',
      fontWeight: 'normal',
      fontSize: '26px',
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginLeft: '5px',
      },
    },
    '& ul': {
      listStyle: 'none',
      padding: '0',
      '& li': {
        background: 'transparent',
        padding: '0',
        display: 'inline-block',
        margin: '2px',
        cursor: 'pointer',
        verticalAlign: 'top',
        paddingLeft: '5px',
        paddingRight: '5px',
        '& ul li': {
          display: 'block',
          lineHeight: '15px',
          fontSize: '13px',
        },
        '& img': {
          maxWidth: '45px',
          [theme.breakpoints.down('sm')]: {
            maxWidth: '24px',
          },
        },
      },
    },
  },
  tabContentBox: {
    background: '#fff',
    border: '1px solid #ccc',
    '& > div': {
      padding: '0',
    },
  },
  profileBox: {
    padding: '15px',
    listStyle: 'none',
    margin: '0',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
    '& li': {
      width: '14.28%',
      margin: '0 7px',
      display: 'inline-block',
      position: 'relative',
      background: '#ccc',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: '5px 7px',
      },
      '& i': {

      },
      '& figure': {
        margin: '0',
        '& img': {
          width: '100%',
          display: 'flex',
        },
      },
    },
  },
  textCaption: {
    top: '0',
    color: '#fff',
    bottom: '0',
    height: 'inherit',
    margin: 'auto',
    display: 'flex',
    position: 'absolute',
    background: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    alignItems: 'center',
    width: '100%',
    left: '0',
    justifyContent: 'center',
    '& h3': {
      fontWeight: 'normal',
      [theme.breakpoints.down('xs')]: {
        fontSize: '25px',
      },
      '& span': {
        display: 'block',
        '@media screen and (min-width: 600px) and (max-width: 992px)': {
          fontSize: '9px',
        },
      },
    },
  },
  closeIconNew: {
    position: 'absolute',
    background: '#fff',
    padding: '0',
    zIndex: '1',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #ccc',
    right: '5px',
    top: '5px',
    '@media screen and (min-width: 600px) and (max-width: 992px)': {
      height: '10px',
      width: '10px',
    },
    '& i': {
      '@media screen and (min-width: 600px) and (max-width: 992px)': {
        fontSize: '10px',
      },
    },
  },
  plusIcon: {
    display: 'flex',
    fontSize: '35px',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%',
      filter: 'grayscale(100%) opacity(50%)',
      WebkitFilter: 'grayscale(100%) opacity(50%)',
      MozFilter: 'grayscale(100%) opacity(50%)',
      MsFilter: 'grayscale(100%) opacity(50%)',
    },
  },
  bluePlus: {
    '& img': {
      filter: 'none',
      WebkitFilter: 'none',
    },
  },
  graphSection: {
    padding: '15px',
  },
  myGoals: {
    padding: '0',
    border: 'none',
    background: '0 0',
    marginTop: '0px',
    marginBottom: '7px',
    '& h3': {
      padding: '8px 0px',
      background: '#0069aa',
      color: '#fff',
      margin: '0',
      fontWeight: '500',
      textAlign: 'center',
      fontFamily: 'MuseoSans-500',
      fontSize: '18px',
      lineHeight: '18px',
      borderRadius: '10px 10px 0px 0px',
      position: 'relative',
      '& span': {
        position: 'absolute',
        right: '18px',
        cursor: 'pointer',
      },
    },
  },
  graphTabSection: {
    paddingLeft: '15px',
    paddingRight: '6px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    '& header': {
      border: 'none',
      background: 'transparent',
      boxShadow: 'none',
      '& div': {
        minHeight: '20px',
        // '@media (max-width: 420px)': {
        //   flexWrap: 'wrap',
        // },
        '& button': {
          minHeight: '20px',
          background: '#7e7e7e',
          marginRight: '2px',
          textTransform: 'inherit',
          borderRadius: '10px 10px 0 0',
          color: '#fff',
          minWidth: 'auto',
          padding: '6px 16px',
          lineHeight: '1.42857143',
          '@media (max-width: 768px)': {
            minWidth: '140px',
          },
          '@media screen and (min-width: 769px) and (max-width: 992px)': {
            minWidth: '150px',
          },
          '@media screen and (min-width: 321px) and (max-width: 420px)': {
            fontSize: '15px',
          },
          // '@media (max-width: 420px)': {
          //   borderRadius: '0px',
          //   width: '100%',
          //   marginBottom: '10px',
          // },
          '&:hover': {
            background: '#0170c1',
            color: '#fff',
          },
        },
      },
    },
  },
  activeClass: {
    background: '#0170c1 !important',
  },
  tabContentBoxGraph: {
    background: '#fff',
    border: '1px solid #ccc',
    color: '#000',
  },
  tabContentBoxGraphBox: {
    background: '#fff',
    border: '2px solid #bfbfbf',
    color: '#000',
    padding: '20px',
    marginTop: '20px',
    position:'relative',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 20px)',
      boxSizing: 'border-box',
    },    
  },
  linesBackground:{
    position:'relative',
    boxSizing: 'border-box',    
  },
  graphLines:{
    boxSizing: 'border-box',     
    '&::before':{
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '50%',
      width: '90%',
      height: '80%',
      background: `url(${graphBg})`,
      transform: 'translateX(-50%)',
      WebkitTransform: 'translateX(-50%)',
      MozTransform: 'translateX(-50%)',
      MsTransform: 'translateX(-50%)',
      oTransform: 'translateX(-50%)',
      backgroundPosition:'center top',
      backgroundRepeat:'repeat-y',
      boxSizing:'border-box',
    },
  },
  graphSectionTop: {
    display: 'flex',
    color: '#333',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
    '& h4': {
      margin: '0',
      color: '#333',
      fontSize: '16px',
      display: 'block',
      textDecoration: 'underline',
      fontWeight: '700',
      fontFamily: 'MuseoSans-500',
      '& img': {
        marginLeft: '10px',
        verticalAlign: 'middle',
        maxWidth: '20px',
        cursor: 'pointer',
      },
    },
    '& h3': {
      margin: '0',
      fontSize: '28px',
      fontFamily: 'MuseoSans-500',
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
      },
      '@media screen and (min-width: 600px) and (max-width: 768px)': {
        fontSize: '20px',
      },
      '& img': {
        marginLeft: '10px',
        verticalAlign: 'middle',
      },
    },
  },
  graphRight: {
    margin: '0 0 0 auto',
    textAlign: 'right',
    fontWeight: '600',
    '& p':{
      margin: '5px 0px',
    }
  },
  greenText: {
    color: '#00b050',
  },
  redText: {
    color: 'red',
  },
  graphBlock: {
    '& img': {
      width: '100%',
    },
  },
  yearText: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0',
    '& > img': {
      maxWidth: '32px',
    },
    '& ul': {
      paddingLeft: '30px',
      margin: '0',
      '& li': {
        fontSize: '12px',
        fontFamily: 'MuseoSans-300',
        fontWeight: '400',
        color: '#000',
        wordBreak: 'break-word',
      },
    },
  },
  buttonSection: {
    position: 'relative',
    '& button': {
      fontSize: '16px',
      marginBottom: '8px',
      width: '100%',
      color: '#fff',
      border: '2px solid transparent',
      borderRadius: '6px',
      padding: '2px 0',
      textTransform: 'uppercase',
      fontFamily: 'MuseoSans-500',
      '@media screen and (min-width: 600px) and (max-width: 768px)': {
        fontSize: '14px',
      },
    },
  },
  buttonGreen: {
    background: '#84a84d',
    '&:hover': {
      background: '#5e881e',
    },
  },
  buttonBlue: {
    background: '#7b9fbe',
    '&:hover': {
      background: '#698aa9',
    },
  },
  buttonDarkBlue: {
    background: '#4e7da7',
    '&:hover': {
      background: '#3b5d7b',
    },
  },

  gridContainer: {
    padding: '0',
    margin: '0 -1%',
    width: 'calc(100% + 2%)',
    '& > div': {
      flexGrow: '0',
      maxWidth: '48%',
      flexBasis: '48%',
      margin: '0 1%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        flexBasis: '100%',
        margin: '0',
      },
    },
  },
  gridContainerNew: {
    padding: '0',
    margin: '0 -1%',
    width: 'calc(100% + 2%)',
    '& > div': {
      flexGrow: '0',
      maxWidth: '23%',
      flexBasis: '23%',
      margin: '0 1%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '48%',
        flexBasis: '48%',
        margin: '0 1%',
        flexGrow: '0',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        flexBasis: '100%',
        margin: '0',
      },
    },
  },
  graphFull: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
    '& div': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    '& h3': {
      fontSize: '23px',
      marginBottom: '10px',
    },
  },
  goalsSection: {
    width: '22%',
    maxWidth: '22%',
    flexBasis: '22%',
    padding: '0 6px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      width: '40%',
      maxWidth: '40%',
      flexBasis: '40%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%',
      padding: '0px',
    },
  },
  rightSection: {
    maxWidth: '78%',
    flexBasis: '78%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      width: '60%',
      maxWidth: '60%',
      flexBasis: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  tooltipInfoTop: {
    maxWidth: '200px',
    width: 'auto',
    background: '#0069aa !important',
    fontSize: '12px !important',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    borderColor: '#0069aa !important',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa',
    },
  },
  fiLeftArrow: {
    '& a': {
      display: 'block',
      position: 'relative',
      width: '35px',
      height: '35px',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        width: '100%',
        height: '2px',
        left: '2px',
        background: '#bcbec0',
        marginTop: '-2px',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '8px',
        width: '18px',
        height: '18px',
        borderTop: '2px solid #bcbec0',
        borderLeft: '2px solid #bcbec0',
        transform: 'rotate(-42deg) skew(10deg)',
        left: '5px',
      },
    },
  },
  classOrange: {
    color: '#fff',
    background: '#f89b22 !important',
  },
  dashboardGoals: {
    padding: '20px 15px',
    border: '2px solid #bfbfbf',
    borderTop: '0',
    background: '#fff',
    '@media screen and (min-width: 600px) and (max-width: 700px)': {
      padding: '10px',
    },
    '& p': {
      display: 'flex',
      marginBottom: '9px',
      fontSize: '18px',
      lineHeight: '18px',
      color: '#333',
      fontFamily: 'MuseoSans-500',
      padding: '0',
      margin: '0',
      '& span': {
        display: 'inline-block',
        fontSize: '12px',
        fontFamily: 'MuseoSans-300',
        fontWeight: '400',
        '&:first-child': {
          width: '48%',
        },
        '&:last-child': {
          width: '52%',
        },
      },
    },
  },
  grapTitle: {
    width: '27%',
    '&:last-child': {
      width: '18%',
    },
  },
  graphTooltip: {
    fontSize: '14px !important',
    textAlign: 'left',
    textTransform: 'none',
  },
  modalHeader: {
    background: '#0069aa',
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    position: 'relative',
    '& h4': {
      color: '#fff',
      textAlign: 'center',
      margin: '0',
      lineHeight: '1.42857143',
      fontWeight: '500',
      fontSize: '18px',
      fontFamily: '"MuseoSans-300"',
    },
  },
  closeButton: {
    color: '#fff',
    opacity: '1',
    marginTop: '-2px',
    webkitAppearance: 'none',
    padding: '0',
    cursor: 'pointer',
    background: '0 0',
    border: '0',
    float: 'right',
    fontSize: '21px',
    lineHeight: '1',
    textShadow: '0 1px 0 #fff',
    fontFamily: 'inherit',
    fontWeight: '700',
  },
  modalBody: {
    position: 'relative',
    padding: '15px',
    boxSizing: 'border-box',
    fontSize: '14px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
    },
  },
  formRow: {
    padding: '0 0 10px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    margin: '0 -15px',
    fontFamily: '"MuseoSans-300"',
    color: '#000',
    fontSize: '14px',
    lineHeight: '1.42857143',
    [theme.breakpoints.down('xs')]: {
      margin: '0px',
    },
    '& p': {
      margin: '0px',
    },
    '& textarea': {
      width: '100%',
      borderRadius: '4px',
      border: '1px solid #ccc',
      height: '34px',
      padding: '6px 12px',
      backgroundImage: 'none',
      fontFamily: 'inherit',
      boxSizing: 'border-box',
      lineHeight: '1.42857143',
      color: '#555 !important',
      resize: 'auto',
      '&:focus': {
        borderColor: '#66afe9 !important',
        outline: '0',
        WebkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
        boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
      },
    },
    '& > div': {
      alignItems: 'center',
    },
  },
  formControl: {
    '& input': {
      borderRadius: '4px',
      width: '100%',
      height: '34px',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      padding: '6px 12px',
      backgroundImage: 'none',
      boxSizing: 'border-box',
      fontFamily: 'inherit',
      lineHeight: '1.42857143',
      '&:focus': {
        borderColor: '#66afe9 !important',
        outline: '0',
        WebkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
        boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
      },
    },
    '& > div': {
      '&::before': {
        border: 'none !important',
      },
      '&::after': {
        border: 'none !important',
      },
    },
    '&:hover': {
      '&:before': {
        border: 'none !important',
      },
    },
  },
  editGoalsPopup: {
    '& > div > div > div': {
      padding: '0px !important',
      width: '600px',
      boxSizing: 'border-box',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    '& > div > div': {
      borderTopLeftRadius: '14px',
      borderTopRightRadius: '14px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: '100%',
        margin: '15px',
      },
    },
  },
  subRow: {
    display: 'flex',
    alignItems: 'center',
  },
  gridSpacing: {
    paddingLeft: '15px',
    paddingRight: '15px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5px',
      paddingRight: '5px',
    },
  },
  baseDisplay: {
    display: 'flex',
    alignItems: 'baseline',
    '& > div': {
      display: 'flex',
      alignItems: 'baseline',
    },
  },
  dashboardWidth: {
    paddingLeft: '25px',
    paddingTop: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
    },
    '& div': {
      height: '44px',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },
  },
  bottomSpace: {
    marginBottom: '20px',
  },
  radioSpan: {
    top: '4px',
    position: 'relative',
    width: '23px',
    marginBottom: '0',
    cursor: 'pointer',
    display: 'inline-block',
    maxWidth: '100%',
    fontWeight: '700',
    '& input': {
      opacity: '0',
      margin: '4px 0 0',
      lineHeight: 'normal',
      boxSizing: 'border-box',
    },
    '& input + span': {
      cursor: 'pointer',
      width: '16px',
      height: '16px',
      display: 'inline-block',
      background: '#fff',
      border: '1px solid #0069aa',
      borderRadius: '50%',
      position: 'absolute',
      left: '0',
      top: '0',
      paddingTop: '2px',
      boxSizing: 'border-box',
    },
    '& input:checked + span': {
      background: '#0069aa',
      border: '1px solid #0069aa',
    },
  },
  gridRightspacing: {
    paddingRight: '15px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  footerRow: {
    padding: '0 0 10px',
    display: 'flex',
    alignItems: 'center',
  },
  footerModal: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginBottom: '0px',
      marginTop: '0px',
      fontFamily: 'inherit',
      color: '#000',
      '& a': {
        color: '#f0ad4e',
        textDecoration: 'underline',
      },
    },
  },
  textRight: {
    textAlign: 'right',
  },
  saveBtn: {
    color: '#fff',
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '6px 12px',
    backgroundImage: 'none',
    textTransform: 'uppercase',
    fontFamily: 'inherit',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ec971f',
      borderColor: '#d58512',
      boxShadow: 'none',
    },
  },
  gridMobile: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  graphTooltipNew: {
    fontSize: '14px !important',
    textAlign: 'left',
    textTransform: 'none',
    maxWidth: '340px',
    '@media (max-width: 320px)': {
      maxWidth: '280px',
    },
  },
  formtextAreaIn: {
    outline: '0',
    width: '100%',
    '& > div': {
      padding: '0px',
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    '& textarea': {
      backgroundColor: theme.palette.common.white,
      width: '100%',
      border: '1px solid #b2b2b1',
      transition: 'box-shadow .3s ease-in-out',
      WebkitTransition: 'box-shadow .3s ease-in-out',
      MozTransition: 'box-shadow .3s ease-in-out',
      color: '#898a8a',
      position: 'relative',
      boxSizing: 'border-box',
      fontFamily: 'MuseoSans-300',
    },
  },
  cotrolLabel: {
    marginLeft: '-6px',
    '& span:last-child': {
      color: '#000',
      fontSize: '14px',
    },
    '& > span': {
      padding: '0px',
      marginLeft: '5px',
      marginBottom: '2px',
    },
    '& span:first-child': {
      color: '#0069aa',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  outlookTitle: {
    textTransform: 'capitalize',
  },
  linesTop:{   
     boxSizing: 'border-box',     
  },
  summeryDropdownDiv:{
    '& ul':{
      left: '105%',
      position: 'absolute',
      right: 'auto',
      top: '-20px',
      zIndex: '999',
      width: '200px',
      padding: '5px 0px',
      margin: '2px 0 0',
      transform: 'scale(1)',
      MozTransform: 'scale(1)',
      WebkitTransform: 'scale(1)',
      fontSize: '14px',
      textAlign: 'left',
      listStyle: 'none',
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      border: '1px solid rgba(0,0,0,.15)',
      borderRadius: '4px',
      WebkitBoxShadow: '0 6px 12px rgb(0 0 0 / 18%)',
      boxShadow: '0 6px 12px rgb(0 0 0 / 18%)',
      [theme.breakpoints.down( 'xs' )]: {
        left: '20%',
        top: 'auto',
      },
      '&:after':{
        content: '""',
        borderLeft: '9px solid transparent',
        borderRight: '11px solid #fff',
        position: 'absolute',
        width: '0',
        background: '0 0',
        borderBottom: '9px solid transparent',
        borderTop: '9px solid transparent',
        height: 'auto',
        left: '-19px',
        top: '45%',
        [theme.breakpoints.down( 'xs' )]: {
          top: '-16px',
          left: '20%',
          borderRight: '11px solid transparent',
          borderBottom: '9px solid #fff',
        }
      },
      '& li':{
        '& p':{
          margin: '0px',
          padding: '5px 15px',
          cursor: 'pointer',
          '&:hover':{
            backgroundColor: '#f5f5f5',
          }
        }
      }
    }
  },
  mt0: {
    marginTop: '0px',
  },
  sightHeader: {
    textAlign: 'right',
    padding: '8px 15px',
    borderBottom: '1px solid #e5e5e5',
    display: 'inline-block',
    width: 'calc(100% - 30px)',
    '& button': {
      color: '#000',
      width: '28px',
      border: '1px solid',
      filter: 'alpha(opacity=20)',
      margin: '0',
      display: 'block',
      opacity: '.2',
      fontSize: '20px',
      textAlign: 'center',
      fontFamily: 'MuseoSans-100',
      fontWeight: '700',
      lineHeight: '22px',
      textShadow: '0 1px 0 #fff',
      borderRadius: '2px',
      paddingBottom: '2px',
      float: 'right',
      cursor: 'pointer',
    },
  },
});

export default styles;