const styles = ( ) => ( {
    pwiWalkthru: {
      position: 'absolute',
      maxWidth: '640px',
      boxSizing: 'border-box',
      background: '#fff',
      zIndex: '999',
      border: '3px solid #0069aa',
      borderRadius: '40px',
      top: '135px',
      right: '60px',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      display: 'none',
      '& h4': {
        width: '100%',
        padding: '10px 20px',
        borderBottom: '1px solid #ccc',
        fontSize: '20px',
        textAlign: 'left',
        marginTop: '10px',
        marginBottom: '10px',
        fontWeight: '500',
        fontFamily: 'MuseoSans-300',
        color: '#0069aa',
        lineHeight: '1.1',
        boxSizing: 'border-box',
        '@media (max-width: 991px)': {
          padding: '0px 20px 5px',
          marginTop: '8px',
          marginBottom: '8px',
          fontSize: '16px',
        }
      },
      '& p': {
        color: '#000',
        fontSize: '16px',
        padding: '10px 20px',
        display: 'inline-block',
        lineHeight: '24px',
        margin: '0px 0px 10px',
        boxSizing: 'border-box',
        '@media (max-width: 991px)': {
          margin: '-3px 0px 5px',
          fontSize: '12px',
          lineHeight: '16px',
          padding: '0px 10px',
        }
      },
      '&::before': {
        borderWidth: '0 30px 30px',
        borderColor: 'transparent transparent #fff',
        zIndex: '9999',
        content: '""',
        width: '0',
        height: '0',
        margin: 'auto',
        position: 'absolute',
        borderStyle: 'solid',
      },
      '&::after': {
        borderWidth: '0 34px 34px',
        borderColor: 'transparent transparent #0069aa',
        zIndex: '9998',
        content: '""',
        width: '0',
        height: '0',
        margin: 'auto',
        position: 'absolute',
        borderStyle: 'solid',
      },
    },
    pwiWalkthruOne: {
      top: '73px',
      left: 'auto',
      right: '-72px',
      display: 'block !important',
      '@media (max-width: 1199px)': {
        right: '0px',
      },
      '@media (max-width: 991px)': {
        top: '50px',
      },
      '&::before': {
        top: '-30px',
        right: '64px',
        bottom: 'auto',
        transform: 'none',
        left: 'auto',
        '@media (max-width: 1199px)': {
          top: '-19px',
          right: '26px',
          borderWidth: '0 20px 20px',
        },
        '@media (max-width: 991px)': {
          top: '-14px',
          right: '28px',
          borderWidth: '0 15px 15px',
        }
      },
      '&::after': {
        top: '-34px',
        bottom: 'auto',
        transform: 'none',
        left: 'auto',
        right: '60px',
        '@media (max-width: 1199px)': {
          top: '-22px',
          right: '26px',
          borderWidth: '0 20px 20px',
        },
        '@media (max-width: 991px)': {
          top: '-17px',
          borderWidth: '0 17px 17px',
        }
      },
    },
    walkthruBottom: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      padding: '0 25px 0 15px',
      boxSizing: 'border-box',
      '@media (max-width:479px)': {
        display: 'block',
      }
    },
    checkmarksScroll: {
      listStyle: 'none',
      padding: '0',
      display: 'inline-block',
      marginLeft: '0',
      width: '100%',
      textAlign: 'left',
      position: 'relative',
      paddingInlinStart: '35px',
      marginBottom: '10px',
      marginTop: '0px',
      boxSizing: 'border-box',
      '& li': {
        width: '15px',
        height: '15px',
        background: '#0069aa',
        display: 'inline-block',
        margin: '1px 2px',
        borderRadius: '50%',
        position: 'relative',
        lineHeight: '20px',
        float: 'left',
        cursor: 'pointer',
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        marginLeft: '2px',
        boxSizing: 'border-box',
        '&:last-child': {
          '&::before': {
            background: '0 0!important',
          },
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '0',
          margin: 'auto',
          left: 'auto',
          marginTop: 'auto',
          width: '7px',
          height: '3px',
          background: '#0069aa',
          display: 'inline-block',
          right: '-5px',
          zIndex: '-1',
          bottom: '0',
          boxSizing: 'border-box',
        },
        '&:hover': {
          opacity: '.8',
        },
      },
    },
    activeList: {
      background: '#84aa47 !important',
      '&:not(:last-child)': {
        '&::before': {
          background: '#84aa47',
        },
      },
      '&::after': {
        position: 'absolute',
        top: '0',
        margin: 'auto',
        content: '""',
        height: '5px',
        width: '8px',
        borderWidth: '0 0 2px 2px',
        borderStyle: 'solid',
        borderColor: '#fff',
        bottom: '2px',
        left: '-1px',
        right: '0',
        transform: 'rotate(-45deg)',
        boxSizing: 'border-box',
      },
    },
    currentTab: {
      background: '#f7941e !important',
      '&:not(:last-child)': {
        '&::before': {
          background: '#f7941e',
        },
      },
      '&::after': {
        position: 'absolute',
        top: '0',
        margin: 'auto',
        content: '""',
        height: '5px',
        width: '8px',
        borderWidth: '0 0 2px 2px',
        borderStyle: 'solid',
        borderColor: '#fff',
        bottom: '2px',
        left: '-1px',
        right: '0',
        transform: 'rotate(-45deg)',
        boxSizing: 'border-box',
      },
    },
    walkThruBtns: {
      maxWidth: '230px',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      textAlign: 'right',
      marginBottom: '5px',
      float: 'right',
      paddingRight: '10px',
      '@media (max-width:479px)': {
        display: 'block',
        float: 'left',
        textAlign: 'left',
        justifyContent: 'initial',
      },
      '& button': {
        margin: '0px 2px !important',
        display: 'inline-block',
        lineHeight: '1.42857143',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        touchAction: 'manipulation',
        cursor: 'pointer',
        fontSize: '14px !important',
        userSelect: 'none',
        '@media (max-width: 991px)': {
          fontSize: '12px !important',
          lineHeight: '10px !important',
        },
        '& span':{
          marginBottom: '0',
        }
      },
    },
    startWalkThru: {
      backgroundColor: '#337ab7',
      borderColor: '#2e6da4',
      padding: '7px 12px 5px !important',
      borderRadius: '4px',
      color: '#fff !important',
      fontSize: '14px !important',
      fontWeight: '400',
      textTransform: 'uppercase',
      border: '2px solid transparent',
      '&:hover': {
        backgroundColor: '#337ab7 !important',
        borderColor: '#204d74 !important',
      },
      '& span': {
        color: '#fff !important',
      },
    },
    skipWalkthru: {
      backgroundColor: '#fff',
      borderColor: '#ccc',
      color: '#333',
      padding: '6px 12px !important',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      textTransform: 'uppercase',
      border: '2px solid transparent',
      '&:hover': {
        backgroundColor: '#e6e6e6 !important',
        borderColor: '#adadad !important',
      },
    },
    pwiWalkthruTwo: {
      top: 'auto',
      bottom: '100px',
      right: '-30px',
      left: 'auto',
      display: 'none',
      '@media (max-width: 1199px)': {
        right: '0px',
      },
      '@media (max-width: 991px)': {
        bottom: '95px',
      },
      '&::before': {
        left: 'auto',
        right: '40px',
        top: 'auto',
        transform: 'none',
        borderWidth: '30px 30px 0 32px',
        borderColor: '#fff transparent transparent',
        bottom: '-27px!important',
        '@media (max-width: 991px)': {
          right: '42px',
          bottom: '-15px!important',
          borderWidth: '15px 15px 0 15px',
        }
      },
      '&::after': {
        left: 'auto',
        right: '40px',
        top: 'auto',
        transform: 'none',
        borderWidth: '31px 30px 0 32px',
        borderColor: '#0069aa transparent transparent',
        bottom: '-32px!important',
        '@media (max-width: 991px)': {
          bottom: '-20px!important',
          borderWidth: '17px 17px 0 17px',
        }
      },
    },
    pwiNextWalkthru:{
     top: 'auto',
      bottom: '100px',
      right: '-30px',
      left: 'auto',
      display: 'none',
      '@media (max-width: 1199px)': {
        right: '0px',
      },
      '@media (max-width: 991px)': {
        bottom: '95px',
      },
      '&::before': {
        left: 'auto',
        right: '40px',
        top: 'auto',
        transform: 'none',
        borderWidth: '30px 30px 0 32px',
        borderColor: '#fff transparent transparent',
        bottom: '-27px!important',
        '@media (max-width: 991px)': {
          right: '42px',
          bottom: '-15px!important',
          borderWidth: '15px 15px 0 15px',
        }
      },
      '&::after': {
        left: 'auto',
        right: '40px',
        top: 'auto',
        transform: 'none',
        borderWidth: '31px 30px 0 32px',
        borderColor: '#0069aa transparent transparent',
        bottom: '-32px!important',
        '@media (max-width: 991px)': {
          bottom: '-20px!important',
          borderWidth: '17px 17px 0 17px',
        }
      },
    },
    pwiWalkthruThree: {
      top: '80px',
      left: '-56px',
      right: 'auto',
      //display: 'none',
      '@media (max-width: 1199px)': {
        left: '0px',
      },
      '@media (max-width: 991px)': {
        top: '65px',
      },
      '&::before': {
        bottom: 'auto',
        transform: 'none',
        right: 'auto',
        top: '-29px',
        left: '39px',
        borderWidth: '0 30px 30px',
        borderColor: 'transparent transparent #fff',
        zIndex: '9999',
        '@media (max-width: 991px)': {
          top: '-15px',
          left: '37px',
          borderWidth: '0 15px 15px',
        },
      },
      '&::after': {
        top: '-34px',
        bottom: 'auto',
        transform: 'none',
        left: '35px',
        right: 'auto',
        borderWidth: '0 34px 34px',
        borderColor: 'transparent transparent #0069aa',
        zIndex: '9998',
        '@media (max-width: 991px)': {
          top: '-19px',
          borderWidth: '0 17px 17px',
        }
      },
    },
    pwiWalkthruFour: {
      top: '74px',
      left: '335px',
      right: 'auto',
      display: 'none',
      zIndex: '9999',
      '@media (max-width: 991px)': {
        top: '208px',
        left: '0px',
        margin: '0px 20px',
      },
      '&::before': {
        left: '-44px',
        right: 'auto',
        transform: 'rotate(-90deg)',
        top: '0',
        bottom: '0',
        '@media (max-width: 991px)': {
          top: '-15px',
          left: '70px',
          transform: 'rotate(0deg)',
          height: 'auto',
          bottom: 'auto',
          borderWidth: '0 15px 15px',
        },
      },
      '&::after': {
        left: '-51px',
        right: 'auto',
        transform: 'rotate(-90deg)',
        top: '0',
        bottom: '0',
        '@media (max-width: 991px)': {
          top: '-19px',
          left: '68px',
          transform: 'rotate(0deg)',
          height: 'auto',
          bottom: 'auto',
          borderWidth: '0 17px 17px',
        },
      },
    },
    pwiWalkthruFive: {
      top: 'auto',
      left: '338px',
      right: 'auto',
      bottom: '0',
      position: 'fixed',
      display: 'none',
      zIndex: '9999',
      '@media (max-width: 991px)': {
        left: '0',
        bottom: '65px',
        margin: '0px 20px',
      },
      '&::before': {
        left: '-44px',
        right: 'auto',
        transform: 'rotate(-90deg)',
        bottom: '52px',
        top: 'auto',
        '@media (max-width: 991px)': {
          left: '62px',
          bottom: '-14px',
          transform: 'rotate(-180deg)',
          borderWidth: '0 15px 15px',
        }
      },
      '&::after': {
        left: '-51px',
        right: 'auto',
        transform: 'rotate(-90deg)',
        bottom: '50px',
        top: 'auto',
        '@media (max-width: 991px)': {
          left: '60px',
          bottom: '-18px',
          transform: 'rotate(-180deg)',
          borderWidth: '0 17px 17px',
        }
      },
    },
    pwiWalkthruSix: {
      top: '128px',
      left: '-45px',
      right: 'auto',
      display: 'none',
      '@media (max-width: 991px)': {
        left: '0px',
        margin: '0px 20px',
        top: '100px',
      },
      '@media (min-width: 992px) and (max-width: 1299px)': {
        left: '0',
      },
      '&::before': {
        top: '-29px',
        left: '39px',
        bottom: 'auto',
        transform: 'none',
        right: 'auto',
        '@media (max-width: 991px)': {
          top: '-15px',
          left: '37px',
          borderWidth: '0 15px 15px',
        }
      },
      '&::after': {
        top: '-34px',
        bottom: 'auto',
        transform: 'none',
        left: '35px',
        right: 'auto',
        '@media (max-width: 991px)': {
          top: '-19px',
          borderWidth: '0 17px 17px',
        }
      },
    },
    pwiWalkthruSeven: {
      top: '25px',
      left: '325px',
      right: 'auto',
      position: 'fixed',
      display: 'none',
      zIndex: '9999',
      '@media (max-width: 991px)': {
        left: '0px',
        margin: '0px 20px',
        top: '45px',
      },
      '&::before': {
        left: '-44px',
        right: 'auto',
        transform: 'rotate(-90deg)',
        bottom: '84px',
        top: 'auto',
        '@media (max-width: 991px)': {
          left: '80px',
          bottom: '-15px',
          transform: 'rotate(-180deg)',
          borderWidth: '0 15px 15px',
        }
      },
      '&::after': {
        left: '-51px',
        right: 'auto',
        transform: 'rotate(-90deg)',
        bottom: '82px',
        top: 'auto',
        '@media (max-width: 991px)': {
          left: '78px',
          bottom: '-19px',
          transform: 'rotate(-180deg)',
          borderWidth: '0 17px 17px',
        }
      },
    },
    pwiWalkthruEight: {
      top: '73px',
      left: 'auto',
      right: '-72px',
      display: 'none !important',
      '@media (max-width: 1199px)': {
        right: '0px',
      },
      '@media (max-width: 991px)': {
        top: '55px',
      },
      '&::before': {
        top: '-30px',
        right: '64px',
        bottom: 'auto',
        transform: 'none',
        left: 'auto',
        '@media (max-width: 1199px)': {
          top: '-19px',
          right: '26px',
          borderWidth: '0 20px 20px',
        },
        '@media (max-width: 991px)': {
          top:' -14px',
          right: '28px',
          borderWidth: '0 15px 15px',
        }
      },
      '&::after': {
        top: '-34px',
        bottom: 'auto',
        transform: 'none',
        left: 'auto',
        right: '60px',
        '@media (max-width: 1199px)': {
          top: '-22px',
          right: '26px',
          borderWidth: '0 20px 20px',
        },
        '@media (max-width: 991px)': {
          top: '-18px',
          borderWidth: '0px 17px 17px',
        }
      },
    },
    pwiActiveWalkthru: {
      display: 'block !important',
      zIndex: '9999',
      pointerEvents: 'all',
      cursor: 'auto',
    },
    pwiActiveBlock: {
      pointerEvents: 'none',
      '& img': {
        position: 'relative',
        zIndex: '9999',
        cursor: 'none',
      },
    },
    closeWalkThru: {
      backgroundColor: '#fff !important',
      borderColor: '#ccc !important',
      color: '#333 !important',
      padding: '6px 12px !important',
      borderRadius: '4px',
      fontSize: '14px !important',
      fontWeight: '400',
      textTransform: 'uppercase',
      border: '2px solid transparent',
      '&:hover': {
        backgroundColor: '#e6e6e6 !important',
        borderColor: '#adadad !important',
      },
    },
    // pwiNextWalkthru: {
    //   top: 'auto',
    //   left: 'auto',
    //   right: '125px',
    //   bottom: '65px',
    //   display: 'none',
    //   '&::before': {
    //     right: '-44px',
    //     bottom: '0',
    //     borderWidth: '0 30px 30px',
    //     borderColor: 'transparent transparent #fff',
    //     zIndex: '9999',
    //     top: '71px',
    //     transform: 'rotate(90deg)',
    //   },
    //   '&::after': {
    //     top: '71px',
    //     bottom: '0',
    //     right: '-51px',
    //     borderWidth: '0 34px 34px',
    //     borderColor: 'transparent transparent #0069aa',
    //     zIndex: '9998',
    //     transform: 'rotate(90deg)',
    //   },
    // },
    pwiCpWalkThru: {
      top: 'auto',
      left: 'auto',
      right: '125px',
       bottom: '100px',
      // bottom: '-35px',
      display: 'none',
      '@media (max-width: 991px)': {
        bottom: '95px',
        right: '0px',
      },
      '&::before': {
        right: '-44px',
        borderWidth: '0 30px 30px',
        borderColor: 'transparent transparent #fff',
        zIndex: '9999',
        top: '71px',
        bottom: '0',
        transform: 'rotate(90deg)',
        '@media (max-width: 991px)': {
          right: '42px',
          bottom: '-15px!important',
          borderWidth: '15px 15px 0 15px',
          top: 'auto',
          left: 'auto',
          transform: 'none',
          borderColor: '#fff transparent transparent',
        }
      },
      '&::after': {
        top: '71px',
        right: '-51px',
        borderWidth: '0 34px 34px',
        borderColor: 'transparent transparent #0069aa',
        zIndex: '9998',
        bottom: '0',
        transform: 'rotate(90deg)',
        '@media (max-width: 991px)': {
          bottom: '-20px!important',
          borderWidth: '17px 17px 0 17px',
          top: 'auto',
          left: 'auto',
          right: '40px',
          borderColor: '#0069aa transparent transparent',
          transform: 'none',
        }
      },
    },
    worksheetWalkthru: {
      position: 'absolute',
      maxWidth: '640px',
      background: '#fff',
      zIndex: '999',
      border: '3px solid #0069aa',
      borderRadius: '40px',
      top: '135px',
      right: '60px',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      boxSizing: 'border-box',
      '& h4': {
        width: '100%',
        padding: '10px 20px',
        borderBottom: '1px solid #ccc',
        fontSize: '20px',
        textAlign: 'left',
        marginTop: '10px',
        marginBottom: '10px',
        color: '#0069aa',
        fontWeight: 'normal',
        boxSizing: 'border-box',
      },
      '& p': {
        color: '#000!important',
        fontSize: '16px!important',
        padding: '10px 20px',
        display: 'inline-block',
        lineHeight: '24px',
        margin: '0 0 10px',
        boxSizing: 'border-box',
      },
      '&::before, &::after':{
        content: '""',
        top: '0',
        width: '0',
        height: '0',
        transform: 'rotate(90deg)',
        bottom: '0',
        margin: 'auto',
        position: 'absolute',
        borderStyle: 'solid',
      },
    },
    // walkthruBottom: {
    //   display: 'flex',
    //   width: '100%',
    //   alignItems: 'center',
    //   padding: '0 25px 0 15px',
    //   boxSizing: 'border-box',
    //   justifyContent: 'flex-end',
    //   '@media (max-width: 767px)': {
    //     flexWrap: 'wrap',
    //   },
    // },
    // walkThruBtns: {
    //   maxWidth: '230px',
    //   display: 'flex',
    //   justifyContent: 'flex-end',
    //   width: '100%',
    //   textAlign: 'right',
    //   marginBottom: '10px',
    //   float: 'right',
    //   paddingRight: '10px',
    //   '@media (max-width: 767px)': {
    //     maxWidth: '200px',
    //   },
    //   '@media (max-width: 479px)': {
    //     display: 'block',
    //     float: 'left',
    //     textAlign: 'left',
    //   },
    //   '& button': {
    //     margin: '0px 2px',
    //     display: 'inline-block',
    //     lineHeight: '1.42857143',
    //     textAlign: 'center',
    //     whiteSpace: 'nowrap',
    //     verticalAlign: 'middle',
    //     touchAction: 'manipulation',
    //     cursor: 'pointer',
    //     fontSize: '14px',
    //     userSelect: 'none',
    //     borderWidth: '1px',
    //     '@media (max-width: 767px)': {
    //       fontSize: '11px',
    //       padding: '6px',
    //     },
    //   },
    // },
    // startWalkThru: {
    //   backgroundColor: '#337ab7',
    //   borderColor: '#2e6da4',
    //   padding: '6px 12px',
    //   borderRadius: '4px',
    //   color: '#fff !important',
    //   fontSize: '14px',
    //   fontWeight: '400',
    //   textTransform: 'uppercase',
    //   border: '2px solid transparent',
    //   '&:hover': {
    //     backgroundColor: '#337ab7 !important',
    //     borderColor: '#204d74 !important',
    //   },
    //   '& span': {
    //     color: '#fff !important',
    //   },
    // }, 
    // closeWalkThru: {
    //   backgroundColor: '#fff !important',
    //   borderColor: '#ccc !important',
    //   color: '#333 !important',
    //   padding: '6px 12px',
    //   borderRadius: '4px',
    //   fontSize: '14px',
    //   fontWeight: '400',
    //   textTransform: 'uppercase',
    //   border: '2px solid transparent',
    //   '&:hover': {
    //     backgroundColor: '#e6e6e6 !important',
    //     borderColor: '#adadad !important',
    //   },
    // },
    // skipWalkthru: {
    //   backgroundColor: '#fff',
    //   borderColor: '#ccc',
    //   color: '#333',
    //   padding: '6px 12px',
    //   borderRadius: '4px',
    //   fontSize: '14px',
    //   fontWeight: '400',
    //   textTransform: 'uppercase',
    //   border: '2px solid transparent',
    //   '&:hover': {
    //     backgroundColor: '#e6e6e6 !important',
    //     borderColor: '#adadad !important',
    //   },
    // },
    worksheetActiveWalkthru: {
     // display: 'block',
      zIndex: '999999',
      pointerEvents: 'all',
      cursor: 'auto',
    },
    backWalkThru: {
      backgroundColor: '#f0ad4e',
      borderColor: '#eea236',
      padding: '6px 12px',
      borderRadius: '4px',
      color: '#fff !important',
      fontSize: '14px',
      fontWeight: '400',
      textTransform: 'uppercase',
      border: '2px solid transparent',
      '&:hover': {
        backgroundColor: '#ec971f !important',
        borderColor: '#d58512 !important',
      },
      '& span': {
        color: '#fff !important',
      },
    },
    walkthruOne: {
      // left: 'auto',
      maxWidth: '770px',
      // top:'80px',
      top: '50px',
      left: '497px',
     // display: 'none !important',
      '&::before': {
        display:'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    walkthruTwo: {
      // top: '101px',
      // right: '-261px',
      top: '108px',
      right: '-265px',
      maxWidth: '600px',
      left: 'auto',
      transform: 'translateY(-50%)',
      //display: 'none !important',
      '&::before': {
        top: '71px',
        right: '-44px',
        bottom: 'auto',
        transform: 'rotate(90deg)',
        left:'auto',
        borderWidth: '0 31px 32px 30px',
        borderColor: 'transparent transparent #fff',
        zIndex: '9999',
      },
      '&::after': {
        top: '69px',
        bottom: 'auto',
        transform: 'rotate(90deg)',
        right: '-51px',
        left: 'auto',
        borderWidth: '0 34px 36px',
        borderColor: 'transparent transparent #0069aa',
        zIndex: '9998',
      },
    },
    relativeBlock: {
      position: 'relative',
    },
    walkthruThree: {
      left: '30px',
      top: '190px',
      //display: 'none !important',
      '&::before': {
        top: '-29px',
        left: '39px',
        bottom: 'auto',
        transform: 'none',
        right: 'auto',
        borderWidth: '0 30px 30px',
        borderColor: 'transparent transparent #fff',
        zIndex: '9999',
      },
      '&::after': {
        top: '-34px',
        bottom: 'auto',
        transform: 'none',
        left: '35px',
        right: 'auto',
        borderWidth: '0 34px 34px',
        borderColor: 'transparent transparent #0069aa',
        zIndex: '9998',
      },
    },
    detailsHeader: {
      position: 'relative',
    },
    walkthruFour: {
      top: '-65px',
      left: '85px',
      right: 'auto',
      maxWidth: '570px',
      //display:'none !important',
      '&::before': {
        top: '71px',
        right: '-44px',
        bottom: 'auto',
        transform: 'rotate(90deg)',
        left: 'auto',
        borderWidth: '0 30px 30px',
        borderColor: 'transparent transparent #fff',
        zIndex: '9999',
      },
      '&::after': {
        top: '69px',
        bottom: 'auto',
        transform: 'rotate(90deg)',
        right: '-51px',
        left: 'auto',
        borderWidth: '0 34px 34px',
        borderColor: 'transparent transparent #0069aa',
        zIndex: '9998',
      },
    },
    walkthruFive: {
      left: 'auto',
      top: '-65px',
      right: '225px',
      maxWidth: '540px',
      //display:'none !important',
      '&::before': {
        top: '71px',
        right: '-44px',
        bottom: 'auto',
        transform: 'rotate(90deg)',
        left: 'auto',
        borderWidth: '0 30px 30px',
        borderColor: 'transparent transparent #fff',
        zIndex: '9999',
      },
      '&::after': {
        top: '69px',
        bottom: 'auto',
        transform: 'rotate(90deg)',
        right: '-51px',
        left: 'auto',
        borderWidth: '0 34px 34px',
        borderColor: 'transparent transparent #0069aa',
        zIndex: '9998',
      },
    },
    walkthruSix:{
      maxWidth: '640px',
      right: '325px',
      left: 'auto',
      top: '-65px',
     // display:'none !important',
      '&::before':{
        top: '71px',
        right: '-44px',
        bottom: 'auto',
        transform: 'rotate(90deg)',
        left: 'auto',
        borderWidth: '0 30px 30px',
        borderColor: 'transparent transparent #fff',
        zIndex: '9999',
      },
      '&::after':{
        top: '69px',
        bottom: 'auto',
        transform: 'rotate(90deg)',
        right: '-51px',
        left: 'auto',
        borderWidth: '0 34px 34px',
        borderColor: 'transparent transparent #0069aa',
        zIndex: '9998',
      },
    },
    walkthruSeven: {
      // right: '100px',
      // maxWidth: '660px',
      right: '5px',
      maxWidth: '770px',
      top: '-245px',
      left: 'auto',
      //display: 'none!important',
      '&::before': {
        left: '44px',
        bottom: '-30px',
        transform: 'rotate(180deg)',
        top: 'auto',
        borderWidth: '0 30px 30px',
        borderColor: 'transparent transparent #fff',
        zIndex: '9999',
      },
      '&::after':{
        transform: 'rotate(180deg)',
        left: '40px',
        bottom: '-34px',
        top: 'auto',
        borderWidth: '0 34px 34px',
        borderColor: 'transparent transparent #0069aa',
        zIndex: '9998',
      },
    },
    walkthruEight:{
      maxWidth: '680px',
    top: 'auto',
    // bottom: '-32px',
    bottom: '-8px',
    // left: '240px',
    left: '260px',
    right: '-630px',
      //display:'none !important',
      '&::before':{
        left: '-44px',
        bottom: '57px',
        top: 'auto',
        right: 'auto',
        transform: 'rotate(-90deg)',
        borderWidth: '0 30px 30px',
        borderColor: 'transparent transparent #fff',
        zIndex: '9999',
      },
      '&::after':{
        top: 'auto',
        left: '-51px',
        bottom: '55px',
        right: 'auto',
        transform: 'rotate(-90deg)',
        borderWidth: '0 34px 34px',
        borderColor: 'transparent transparent #0069aa',
        zIndex: '9998',
      },
    },
    walkthruNine:{
      maxWidth: '550px',
      left: 'auto',
      // top: '-100px',
      // right: '120px',
      top: '-80px',
      right: '135px',
     // display:'none !important',
      '&::before':{
        top: '70px',
        left: '-44px',
        right: 'auto',
        transform: 'rotate(-90deg)',
        borderWidth: '0 30px 30px',
        borderColor: 'transparent transparent #fff',
        zIndex: '9999',
      },
      '&::after':{
        left: '-51px',
        top: '70px',
        right: 'auto',
        transform: 'rotate(-90deg)',
        borderWidth: '0 34px 34px',
        borderColor: 'transparent transparent #0069aa',
        zIndex: '9998',
      },
    },
    walkthruTen:{
      left: 'auto',
      maxWidth: '580px',
      // top: '-130px',
      // right: '0',
      top: '-115px',
      right: '15px',
      //display:'none !important',
      '&::before':{
        left: '-44px',
        bottom: '47px',
        top: 'auto',
        right: 'auto',
        transform: 'rotate(-90deg)',
        borderWidth: '0 30px 30px',
        borderColor: 'transparent transparent #fff',
        zIndex: '9999',
      },
      '&::after':{
        top: 'auto',
        bottom: '45px',
        right: 'auto',
        transform: 'rotate(-90deg)',
        left: '-51px',
        borderWidth: '0 34px 34px',
        borderColor: 'transparent transparent #0069aa',
        zIndex: '9998',
      },
    },
    walkthruEleven:{
      left: 'auto',
      maxWidth: '550px',
      top: 'auto',
      // bottom: '-32px',
      // right: '200px',
      bottom: '-5px',
      right: '215px',
     // display:'none !important',
      '&::before':{
        left: '-44px',
        bottom: '57px',
        top: 'auto',
        right: 'auto',
        transform: 'rotate(-90deg)',
        borderWidth: '0 30px 30px',
        borderColor: 'transparent transparent #fff',
        zIndex: '9999',
      },
      '&::after':{
        top: 'auto',
        left: '-51px',
        bottom: '55px',
        right: 'auto',
        transform: 'rotate(-90deg)',     
        borderWidth: '0 34px 34px',
        borderColor: 'transparent transparent #0069aa',
        zIndex: '9998',
      },
    },
    walkthruTwelve:{
      left: '380px',
      maxWidth: '750px',
      right: '0',
      margin: 'auto',
      boxSizing:'border-box',
     // display:'none !important',
      top:'30px',
      '&::before':{
        display:'none',
      },
      '&::after':{
        display:'none',
      },
    },
    pwiAnimation: {
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'fixed',
        left: '0',
        top: '0',
        width: '100%',
        height: '100vh',
        background: 'rgba(0,0,0,.7)',
        zIndex: '9999',
        display: 'none',
        boxSizing: 'border-box',
      },
    },
    pwiAnimationActive: {
      '&::before': {
        display: 'block',
      },
    },
    detailsPopupScroll:{
      marginTop: '-40px', 
      backgroundSize: 'cover',
      backgroundPosition:'top center',
      backgroundRepeat:'no-repeat',
      borderRadius: '15px',
      maxHeight: '800px',
      position: 'relative',
      zIndex: '999999',
      cursor: 'none',
      pointerEvents: 'none',
      display:'none !important',
    },
    scaleOpacity:{
      // opacity:'0',
    },
    detailsMainBlock:{
      position:'relative',
    },
    showmeWalkthru:{
      maxHeight: '1210px', 
      backgroundSize: 'cover',
      backgroundPosition:'top center',
      backgroundRepeat:'no-repeat',
      backgroundColor:'#fff',
      borderRadius: '15px',
      position: 'relative',
      zIndex: '999999',
      cursor: 'none',
      pointerEvents: 'none',
    },
    bootWalkthru:{
      position: 'relative',
      zIndex: '999999',
      cursor: 'none',
      pointerEvents: 'none',
      borderRadius: '15px',
    },
    compareWalkthru:{
      position: 'relative',
      zIndex: '999999',
      cursor: 'none',
      pointerEvents: 'none',   
      backgroundRepeat:'no-repeat',
      backgroundColor:'#fff',
      maxHeight: '670px',
    },
    notesWalkthru:{
      position: 'relative',
      zIndex: '999999',
      cursor: 'none',
      pointerEvents: 'none',    
    },
    intrestModal: {
      '& > div > div': {
        width: '100%',
        maxWidth: '600px',
      },
      '& > div > div > div': {
        padding: '0px',
      },
    },
    intrestModalHeader: {
      padding: '15px !important',
      borderBottom: '1px solid #e5e5e5',
      '& h2': {
        textAlign: 'left',
        lineHeight: '30px',
        fontWeight: '300',
        fontSize: '20px',
        margin: '0',
        color: '#0069aa',
      },
    },
    intrestModalFooter: {
      padding: '15px !important',
      '& > div':{
        borderTop: '0px',
      }
    },
    intrestModalBody: {
      overflow: 'auto',
      boxSizing: 'border-box',
      '& p':{
        padding: '0px 20px',
      }
    },
    okBtn: {
      backgroundColor: '#84a84d',
      borderColor: '#84a84d',
      color: '#fff',
      margin: '0px 5px',
      '&:hover': {
        backgroundColor: '#799e41',
        borderColor: '#799e41',
      },
    },
  } );
  
  export default styles;
  