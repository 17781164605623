import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { useLazyQuery } from '@apollo/react-hooks';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { loader } from 'graphql.macro';
import classNames from 'classnames';
import {
    Dialog, DialogContent
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import styles from './styles';
import * as commonFunctions from '../../utilities/commonFunctions';
import Fail from '../../assets/img/login/fail.png';
import Pass from '../../assets/img/login/pass.png';
import InkLogo from '../../assets/img/logo.png';


const CHECK_USER_NAME = loader('../../graphql/schema/register/check-username.graphql');

const FinishRegistration = (props) => {

    const { classes } = props;

    const [passCheck, setPassCheck] = useState(false);
    const [passwordLength, setPasswordLength] = useState(false);
    const [alphabetsExist, setAlphabetsExist] = useState(false);
    const [numericExist, setNumericExist] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [AccountType, setAccountType] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [userName, setUserName] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [checked, setChecked] = useState(false);
    const [open, setOpen] = useState(false)

    const [checkUserName] = useLazyQuery(CHECK_USER_NAME, {
        variables: {
            user_name: userName,
        },
        fetchPolicy: 'network-only',
        onCompleted({
            CheckUserName: {
                status,
            },
        }) {
            if (status) {
                setUsernameError('');
            }
        },
        onError(errors) {
            const formatedErrors = errors.graphQLErrors.map(({ message }) => (
                <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
            ));
            if (userName !== '') {
                setUsernameError(formatedErrors);
            }
        }

    });

    const handleUserName = (e) => {
        checkUserName(e);
    }

    const handleSubmitClick = () => {
        setSubmitClicked(true);
    };

    const validate = (values) => {
        const errors = {};

        if (!values.userName) {
            errors.userName = 'user name is required.';
        } else {
            setUserName(values.userName);
        }

        if (values.userName) {
            if (values.userName.length <= 3) {
                setUsernameError("User must be more that 4 charecters");
            } else {
                setUsernameError('');
            }
        }
        if (!values.password) {
            errors.password = 'password is required.';
        }
        if (values.password) {
            if (values.password.length > 8) {
                setPasswordLength(true);
            } else {
                setPasswordLength(false);
            }
            if (!commonFunctions.validateAlpha(values.password)) {
                setAlphabetsExist(true);
            } else {
                setAlphabetsExist(false);
            }
            if (!commonFunctions.validateNumeric(values.password)) {
                setNumericExist(true);
            } else {
                setNumericExist(false);
            }
        }
        if (!values.password_confirmation) {
            errors.password_confirmation = 'confirm password is required.';
        }
        if (values.password_confirmation) {
            if (values.password === values.password_confirmation) {
                setPasswordMatch(true);
            } else {
                setPasswordMatch(false);
            }
        }
        if (!values.type) {
            if (submitClicked) {
                setAccountType(true);
            }
            errors.type = "please select Account type"
        } else {
            setAccountType(false)
        }
        return errors;
    };

    const hanndleAgreeTerms = (event) => {
        setChecked(event.target.checked);
    };

    const onSubmit = (values) => {
        console.log(values);
        setOpen(true)
    }

    return (
        <div>
            <div className={classes.container}>
                <div className={classes.finishRegistrationRow}>
                    <div className={classes.logoRow}>
                        <Link>
                            <img src={InkLogo} alt="" />
                        </Link>
                        <Link>
                            <img src={InkLogo} alt="" />
                        </Link>
                    </div>
                    <div className={classes.finishRegistratioForm}>
                        <Typography variant="h3" component="h3">Account Registration</Typography>
                        <Form
                            validate={validate}
                            onSubmit={onSubmit}
                            render={({ handleSubmit, values }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    noValidate
                                >
                                    <div className={classes.accountInfoRow}>
                                        <Typography variant="h4" component="h4">Account Information</Typography>
                                        <div className={classes.formFlex}>
                                            <div className={classes.formInHalf}>
                                                <Field
                                                    placeholder="ex. Mike"
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    type="text"
                                                    autoComplete="current-password"
                                                    name="first_name"
                                                    fullWidth
                                                    required
                                                    component={TextField}
                                                />
                                            </div>
                                            <div className={classes.formInHalf}>
                                                <Field
                                                    placeholder="ex. Inkwiry"
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    type="text"
                                                    autoComplete="current-password"
                                                    name="last_name"
                                                    fullWidth
                                                    required
                                                    component={TextField}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.formInFull}>
                                            <Field
                                                placeholder="ex. mike@inkwiry.com"
                                                id="outlined-basic"
                                                variant="outlined"
                                                type="text"
                                                autoComplete="current-password"
                                                name="email"
                                                fullWidth
                                                required
                                                component={TextField}
                                            />
                                        </div>
                                        <div className={classes.formInFull}>
                                            <Field
                                                id="outlined-basic"
                                                variant="outlined"
                                                type="text"
                                                autoComplete="current-password"
                                                name="groupName"
                                                placeholder="ex. Sophomore"
                                                fullWidth
                                                required
                                                component={TextField}
                                            />
                                        </div>
                                        <div className={classes.formInFull}>
                                            <Field
                                                name="type"
                                                component="select"
                                                variant="outlined"
                                            >
                                                <option value="">Select Type </option>
                                                <option value="Student">Student</option>
                                                <option value="Parent">Parent</option>
                                                <option value="Professional">Professional</option>
                                                <option value="Other">Other</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className={classes.accountInfoRow}>
                                        <Typography variant="h4" component="h4">Username and Password</Typography>
                                        <div className={classes.formInFull}>
                                            <Field
                                                id="outlined-basic"
                                                variant="outlined"
                                                type="text"
                                                autoComplete="current-password"
                                                name="userName"
                                                placeholder="Username"
                                                fullWidth
                                                required
                                                onBlur={(e) => { handleUserName(e); }}
                                                component={TextField}
                                            />
                                            {usernameError ?
                                                <div className={classes.nameError}>{usernameError}</div>
                                                :
                                                ''
                                            }
                                        </div>
                                        <div className={classes.formInFull}>
                                            <Field
                                                id="outlined-basic"
                                                variant="outlined"
                                                type="password"
                                                autoComplete="current-password"
                                                placeholder="Password"
                                                name="password"
                                                fullWidth
                                                required
                                                component={TextField}
                                                onFocus={() => { setPassCheck(true); }}
                                                onBlur={() => { setPassCheck(false); }}
                                            />
                                            <div className={classes.passwordList}>
                                                <div className={classes.condition}>
                                                    <p>
                                                        <span>{passwordLength ? <img src={Pass} alt="" /> : <img src={Fail} alt="" />}</span>
                                                        <small>Include at least 8 characters</small></p>
                                                    <p><span>{alphabetsExist ? <img src={Pass} alt="" /> : <img src={Fail} alt="" />}</span>
                                                        <small>Include upper and lower case characters</small></p>
                                                    <p><span>{numericExist ? <img src={Pass} alt="" /> : <img src={Fail} alt="" />}</span>
                                                        <small>Include at least 1 number</small> </p>
                                                    <p><span>{passwordMatch ? <img src={Pass} alt="" /> : <img src={Fail} alt="" />}</span>
                                                        <small>Passwords match</small></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes.formInFull}>
                                            <Field
                                                id="outlined-basic"
                                                variant="outlined"
                                                type="password"
                                                autoComplete="current-password"
                                                name="password_confirmation"
                                                placeholder="Confirm New Password"
                                                fullWidth
                                                required
                                                component={TextField}
                                                onFocus={() => { setPassCheck(true); }}
                                                onBlur={() => { setPassCheck(false); }}
                                            />
                                        </div>
                                        <div className={classNames(classes.formCheckBox, "formCheckBoxNormal")}>
                                            <div>
                                                <Checkbox
                                                    checked={checked}
                                                    name="agreeTerms"
                                                    onChange={hanndleAgreeTerms}
                                                />
                                                <label>
                                                    {' '}
                                                    I agree to the
                                                    {' '}
                                                    <Link to="/termsofservice">Terms of Service</Link>
                                                    {' '}
                                                    and
                                                    {' '}
                                                    <Link to="/privacy">Privacy Policy</Link>
                                                </label>
                                            </div>
                                        </div>
                                        <div className={classes.formBtn}>
                                            <button
                                                onClick={handleSubmitClick}
                                                type="submit"
                                            >
                                                SIGN UP
                                            </button>
                                        </div>
                                    </div>



                                </form>
                            )}
                        />
                    </div>
                    <div>

                    </div>

                    <Dialog
                        open={open}
                        onClose={() => { setOpen(false) }}
                    >
                        <DialogContent >
                            <div >
                                <Link aria-hidden="true" to="/" onClick={() => { setOpen(false) }}>X</Link>
                            </div>
                            <div>
                                <span>
                                    <i className="las la-check" />
                                </span>
                                <h2>Registration Successfull!</h2>
                                <p>
                                    Please check your email for next steps to activate your account.
                                </p>
                            </div>
                            <div />
                        </DialogContent>
                    </Dialog>
                </div>
                <div className={classes.villanovaFooter}>
                    <div className={classes.footerIn}>
                        <ul>
                            <li><Link to="/" >Inkwiry</Link></li>
                            <li><Link to="/termsofservice" >Terms of Service</Link></li>
                            <li><Link to="/privacy" >Privacy Policy</Link></li>
                            <li><Link to="/security" >Security</Link></li>
                            <li>Copyright © 2022 Inkwiry. All rights reserved.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default withStyles(styles)(FinishRegistration)