import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/core/styles';
import {
  TextField, Typography, Tabs, Tab, Box,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import ReactPaginate from 'react-paginate';
import SwipeableViews from 'react-swipeable-views';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styles from '../../../screens/pwi-aca/styles';
import * as commonFunctions from '../../../utilities/commonFunctions';
import GreyBox from './grey-common-box';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import Pageloader from '../../ui/pageloader';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';

const OCCUPATION_DATA = loader('../../../graphql/schema/pwi-career-path/occupation-data.graphql');

function TabPanel( props ) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={ value !== index }
      id={ `vertical-tabpanel-${index}` }
      aria-labelledby={ `vertical-tab-${index}` }
      { ...other }
    >
      {value === index && (
        <Box p={ 3 }>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps( index ) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
const hoursWages = [
  {
    value: 0,
    scaledValue: 0,
    label: '$0',
  },
  {
    value: 25,
    scaledValue: 25,
    label: '$25',
  },
  {
    value: 50,
    scaledValue: 50,
    label: '$50',
  },
  {
    value: 75,
    scaledValue: 75,
    label: '$75',
  },
  {
    value: 100,
    scaledValue: 100,
    label: '$100',
  },
  {
    value: 125,
    scaledValue: 125,
    label: '$125',
  },
];
const marks = [
  {
    value: 0,
    scaledValue: 0,
    label: '$0',
  },
  {
    value: 50000,
    scaledValue: 50000,
    label: '$50k',
  },
  {
    value: 100000,
    scaledValue: 100000,
    label: '$100k',
  },
  {
    value: 150000,
    scaledValue: 150000,
    label: '$150k',
  },
  {
    value: 200000,
    scaledValue: 200000,
    label: '$200k',
  },
  {
    value: 250000,
    scaledValue: 250000,
    label: '$250k',
  },
];
const firstYearWokingHours = [
  {
    value: 0,
    scaledValue: 0,
    label: '0',
  },
  {
    value: 500,
    scaledValue: 500,
    label: '500',
  },
  {
    value: 1000,
    scaledValue: 100000,
    label: '1,000',
  },
  {
    value: 1500,
    scaledValue: 1500,
    label: '1,500',
  },
  {
    value: 2000,
    scaledValue: 2000,
    label: '2,000',
  },
  {
    value: 2500,
    scaledValue: 2500,
    label: '2,500',
  },
  {
    value: 3000,
    scaledValue: 3000,
    label: '3,000',
  },
];
const BaseIncome = ( props ) => {
  const {
    classes, handleCareerObject, handleUpdatedObject,
  } = props;

  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState( 0 );
  const [offset, setOffset] = useState( 0 );
  const [occupationType, setOccupationType] = useState( false );
  const [totalEmployed, setTotalEmployed] = useState( false );
  const [annualWageMean, setAnnualWageMean] = useState( false );
  const [AnnualWageMedian, setAnnualWageMedian] = useState( false );
  const [AnnualWageLowend, setAnnualWageLowend] = useState( false );
  const [AnnualWageHighend, setAnnualWageHighend] = useState( false );
  const [hourlyMean, setHourlyMean] = useState( false );
  const [hourlyMedian, setHourlyMedian] = useState( false );
  const [hourlyLowend, setHourlyLowend] = useState( false );
  const [hourlyHighend, setHourlyHighend] = useState( false );
  const [search, setSearch] = useState( '' );
  const [collegeSearchList, setCollegeSearchList] = useState( [] );
  const [sliderValue, setSliderValue] = React.useState( 50000 );
  const [hourlyWage, setHourlyWage] = React.useState( 18.00 );
  const [hourlyFirstYear, setHourlyFirstYear] = React.useState( 2000 );
  const [checked, setChecked] = React.useState( true );
  const [hourly, setHourly] = React.useState( false );
  const [isFocus, setIsFocus] = React.useState('');
  const [selectType, setSelectType] = useState('all');
  const [educationOrder, setEducationOrder] = useState({ name: 'occupation', order: 'ASC' });
  const [occupationData, setOccupationData] = useState([]);
  const [pageCount, setPageCount] = useState('');
  const [slice, setSlice] = useState([]);

  useQuery(OCCUPATION_DATA, {
    variables: {
      occupation_type: selectType,
      order_column: educationOrder.name,
      order_type: educationOrder.order,
    },
    fetchPolicy: 'network-only',
    // skip: educationType === '',
    onCompleted(res) {
      setOccupationData(res && res.getOccupationsData);
      setLoading(false)
    },
    onError() {
      setLoading(false)
    }
  });

  React.useEffect( () => {
    setCollegeSearchList( occupationData );
  }, [occupationData] );

  React.useEffect(() => {
    setPageCount(collegeSearchList && Math.ceil(collegeSearchList.length / 10));
    setSlice(collegeSearchList && collegeSearchList.slice(offset, offset + 10));
  }, [collegeSearchList]); 

  const handleChange = ( event, newValue ) => {
    setLoading(true);
    setSearch('');
    setValue( newValue );
    if ( newValue === 0 ) {
      setSelectType( 'all' );
      setEducationOrder( { name: 'occupation', order: 'ASC' } );
    } else if ( newValue === 1 ) {
      setSelectType( 'industry' );
      setEducationOrder( { name: 'industry', order: 'ASC' } );
    }
  };

  const handleChangeIndex = ( index ) => {
    setValue( index );
  };

  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.yearly_income !== undefined ) {
      setSliderValue( handleCareerObject.yearly_income );
    } else {
      updatedValues['yearly_income'] = 50000;
    }
    if ( handleCareerObject.hourly_wage !== undefined ) {
      setHourlyWage( handleCareerObject.hourly_wage);
    } else {
      updatedValues['hourly_wage'] = 18.00;
    }
    if ( handleCareerObject.first_hour_year !== undefined ) {
      setHourlyFirstYear( handleCareerObject.first_hour_year);
    } else {
      updatedValues['first_hour_year'] = 2000;
    }
    updatedValues['base_income_type'] = 'salary' ;
    handleUpdatedObject(updatedValues);
    // eslint-disable-next-line
    }, [] );

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    switch ( field ) {
      case 'yearly_income': {
        setSliderValue( newvalue );
        updatedValues[field] = newvalue;
        break;
      }
      case 'hourly_wage': {
        setHourlyWage( newvalue );
        updatedValues[field] = newvalue;
        break;
      }
      case 'first_hour_year': {
        setHourlyFirstYear( newvalue );
        updatedValues[field] = newvalue;
        break;
      }
      default:
        break;
    }
    handleUpdatedObject(updatedValues);
  };

  const handleCheckbox = ( event, data ) => {
    const updatedValues = {...handleCareerObject};
    if ( event.target.checked && data === 'hourly' ) {
      setChecked( false );
      setHourly( true );
    } else if ( event.target.checked && data === 'salary' ) {
      setHourly( false );
      setChecked( true );
    }
    updatedValues['base_income_type'] =data;
    handleUpdatedObject(updatedValues);
  };

  const handlePageClick = ( data ) => {
    const { selected } = data;
    const off = Math.ceil( selected * 10 );
    setOffset( off );
    setPageCount(collegeSearchList && Math.ceil(collegeSearchList.length / 10));
    setSlice(collegeSearchList && collegeSearchList.slice(off, off + 10));
  };

  const handleSearch = ( e ) => {
    setSearch( e.target.value );   
    if (value === 0) {
      setCollegeSearchList(occupationData.filter((user) => user.occupation.toLowerCase().search(e.target.value.toLowerCase()) !== -1));
    }
    if (value === 1) {
      setCollegeSearchList(occupationData.filter((user) => user.industry.toLowerCase().search(e.target.value.toLowerCase()) !== -1));
    }
  };

  const handleSort = ( type ) => {
    if ( type === 'occupation' ) {
      setOccupationType( !occupationType );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'total_employed' ) {
      setOccupationType( false );
      setTotalEmployed( !totalEmployed );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'annual_wage_mean' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( !annualWageMean );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'annual_wage_median' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( !AnnualWageMedian );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'annual_wage_lowend' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( !AnnualWageLowend );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'annual_wage_highend' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( !AnnualWageHighend );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'hourly_wage_mean' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( !hourlyMean );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'hourly_wage_median' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( !hourlyMedian );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'hourly_wage_lowend' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( !hourlyLowend );
      setHourlyHighend( false );
    } else if ( type === 'hourly_wage_highend' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( !hourlyHighend );
    }
  };

  const handleSorting = ( name, order ) => {
    if(educationOrder.name !== name){
      setLoading(true)
    }
    setEducationOrder( { name, order } );
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
       {loading && <Pageloader loading={loading} />}
      {handleCareerObject.role === 'futureMove' && (
        <div>
          <p>
            There are many different ways to earn income - hourly wage or
            salary, bonus, tips, commission, and a second or even third job.
            Discover what your career move and others might pay by using the
            data in the table below. The table shows the average earnings by
            career in both annual salary and hourly wage.
          </p>
          <p>
            For this career move, how much do you plan to make in the first year
            in total hourly wages or salary? Do not include bonus, tips,
            commission, or any other income from other jobs.
          </p>
          <p>
            Knowing your potential earnings is crucial to developing a solid
            plan for your next career move. Use the data below to find your
            career and discover what you might earn.
          </p>
        </div>
      )}
      {handleCareerObject.role === 'currentRole' && (
        <div>
          <p>
            There are many different ways to earn income - hourly wage or
            salary, bonus, tips, commission, and a second or even third job. Use
            the table below to see how your current wage stacks up to the
            averages for your career. The table shows the average earnings by
            career in both annual salary and hourly wage.
          </p>

          <p>
            For this career move, how much do you currently earn in total hourly
            wages or salary? Do not include bonus, tips, commission, or any
            other income from other jobs.
          </p>
        </div>
      )}
      <div className={ classes.garyBoxSeventy }>
        <GreyBox handleCareerObject={ handleCareerObject } />
      </div>

      <div className={ `${classes.careerFormGroup} ${classes.salaryHorly}` }>
        <span>Salary / Hourly</span>
        <div className={ classes.checkBoxGroup }>
          <FormControlLabel
            control={ (
              <Checkbox
                name="checkedA"
                checked={ checked }
                onClick={ ( e ) => {
                  handleCheckbox( e, 'salary' );
                } }
              />
            ) }
            label="Salary"
          />
          <FormControlLabel
            control={ (
              <Checkbox
                name="checkedA"
                checked={ hourly }
                onClick={ ( e ) => {
                  handleCheckbox( e, 'hourly' );
                } }
              />
            ) }
            label="Hourly"
          />
        </div>
      </div>

      <div
        className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMainRightBoxNone}` }
      >
        {checked && (
          <div className={ classes.AnnualIncomeGrowth }>
            <h3 className={ classes.AnuuvalIncomeRightTiittle }>Annual</h3>
            <div>
              <span>Annual Income</span>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ marks }
                  min={ 0 }
                  max={ 250000 }
                  step={ 500 }
                  value={ sliderValue }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter(
                    sliderValue,
                  )}` }
                  onChange={ ( e, value ) => updateValue( e, value, 'yearly_income', 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat
                  customInput={ TextField }
                  thousandSeparator
                  decimalScale={0}
                  value={ sliderValue }
                  onValueChange={ ( e ) => updateValue( e, '', 'yearly_income', 'number' ) }
                  onFocus={ ( e ) => {
                    e.target.select();
                  } }
                />
              </div>
            </div>
          </div>
        )}
        {hourly && (
          <div>

            <div className={ classes.AnnualIncomeGrowth }>
              <div>
                <span>Hourly Wage</span>
                <div className={ classes.annuvalIncomeGrowthGraph }>
                  <PwiSlider
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={ hoursWages }
                    min={ 0 }
                    max={ 125 }
                    step={ 0.5 }
                    value={ hourlyWage }
                    valueLabelFormat={ `${commonFunctions.numFormatter(
                      hourlyWage,
                    )}` }
                    onChange={ ( e, value ) => updateValue( e, value, 'hourly_wage', 'slider' ) }
                  />
                </div>
                <div className={ classes.annuvalIncomeGrowthInput }>
                  $
                  <NumberFormat
                    customInput={ TextField }
                    thousandSeparator
                    value={ hourlyWage }
                    decimalScale={isFocus!=='hourly_wage'?2:5}
                    fixedDecimalScale={isFocus!=='hourly_wage'}
                    onValueChange={ ( e ) => updateValue( e, '', 'hourly_wage', 'number' ) }
                    onFocus={ ( e ) => {
                      e.target.select();
                      setIsFocus('hourly_wage')
                    } }
                    onBlur={emptyIsFocus}
                  />
                </div>
              </div>
            </div>
            <div className={ classes.AnnualIncomeGrowth }>
              <div>
                <span>Hours Worked in first year</span>
                <div className={ classes.annuvalIncomeGrowthGraph }>
                  <PwiSlider
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={ firstYearWokingHours }
                    min={ 0 }
                    max={ 3000 }
                    step={ 10 }
                    value={ hourlyFirstYear }
                    valueLabelFormat={ <NumberFormat value={ hourlyFirstYear } displayType="text" thousandSeparator /> }
                    onChange={ ( e, value ) => updateValue( e, value, 'first_hour_year', 'slider' ) }
                  />
                </div>
                <div className={ classes.annuvalIncomeGrowthInput }>
                  <span className={ classes.opacityZero }>$</span>
                  <NumberFormat
                    customInput={ TextField }
                    thousandSeparator
                    decimalScale={0}
                    value={ hourlyFirstYear }
                    onValueChange={ ( e ) => updateValue( e, '', 'first_hour_year', 'number' ) }
                    onFocus={ ( e ) => {
                      e.target.select();
                    } }
                  />
                </div>
              </div>
            </div>
            <div className={ classes.totalIncomeBox }>
              <span>Annual Income</span>
              <div className={ classes.annuvalIncomeGrowthInput }>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ hourlyWage * hourlyFirstYear } prefix="$" />
              </div>
            </div>
          </div>
        )}
        <div className={ classes.tabSection }>
          <Tabs
            value={ value }
            onChange={ handleChange }
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className={ classes.tabButtons }
            aria-label="full width tabs example"
          >
            <Tab
              className={ value === 0 ? classes.activeTab : '' }
              label="All Occupations"
              { ...a11yProps( 0 ) }
            />
            <Tab
              className={ value === 1 ? classes.activeTab : '' }
              label="By Industry"
              { ...a11yProps( 1 ) }
            />
          </Tabs>
          <SwipeableViews
            axis={ theme.direction === 'rtl' ? 'x-reverse' : 'x' }
            index={ value }
            className={ `${classes.tabsContent} ${classes.tabContentBaseIncome}` }
            onChangeIndex={ handleChangeIndex }
          >
            <TabPanel value={ value } index={ 0 } dir={ theme.direction }>
              <div className={ classes.textFieldSearch }>
                {collegeSearchList && (
                  <NumberFormat
                    value={ collegeSearchList.length }
                    displayType="text"
                    thousandSeparator
                  />
                )}
                {' '}
                occupations
                <TextField
                  className={ classes.searchTextField }
                  id="standard-basic"
                  placeholder="Search"
                  value={ search }
                  onChange={ handleSearch }
                />
              </div>

              <div className={ classes.heigherEducationSearch }>
                <div className={ classNames( classes.ChooseSchoolTable, classes.wagesTable ) }>
                  <table cellPadding="0" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th colSpan="4">Annual Wages</th>
                        <th colSpan="4">Hourly Wages</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={ classes.headSection }>
                        <td>
                          Occupation
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'occupation' ) }
                          >
                            {occupationType && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'occupation', 'ASC' ) }
                                >
                                  Sort A to Z
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'occupation', 'DESC' ) }
                                >
                                  Sort Z to A
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                        <td>
                          # Employed
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'total_employed' ) }
                          >
                            {totalEmployed && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'total_employed', 'ASC' ) }
                                >
                                  Sort A to Z
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'total_employed', 'DESC' ) }
                                >
                                  Sort Z to A
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                        <td>
                          Mean
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'annual_wage_mean' ) }
                          >
                            {annualWageMean && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'annual_wage_mean', 'ASC' ) }
                                >
                                  Highest to Lowest
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'annual_wage_mean', 'DESC' ) }
                                >
                                  Lowest to Highest
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                        <td>
                          Median
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'annual_wage_median' ) }
                          >
                            {AnnualWageMedian && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'annual_wage_median', 'ASC' ) }
                                >
                                  Highest to Lowest
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'annual_wage_median', 'DESC' ) }
                                >
                                  Lowest to Highest
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                        <td>
                          Low End
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'annual_wage_lowend' ) }
                          >
                            {AnnualWageLowend && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'annual_wage_lowend', 'ASC' ) }
                                >
                                  Highest to Lowest
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'annual_wage_lowend', 'DESC' ) }
                                >
                                  Lowest to Highest
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                        <td>
                          High End
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'annual_wage_highend' ) }
                          >
                            {AnnualWageHighend && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'annual_wage_highend', 'ASC' ) }
                                >
                                  Highest to Lowest
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'annual_wage_highend', 'DESC' ) }
                                >
                                  Lowest to Highest
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                        <td>
                          Mean
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'hourly_wage_mean' ) }
                          >
                            {hourlyMean && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'hourly_wage_mean', 'ASC' ) }
                                >
                                  Highest to Lowest
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'hourly_wage_mean', 'DESC' ) }
                                >
                                  Lowest to Highest
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                        <td>
                          Median
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'hourly_wage_median' ) }
                          >
                            {hourlyMedian && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'hourly_wage_median', 'ASC' ) }
                                >
                                  Highest to Lowest
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'hourly_wage_median', 'DESC' ) }
                                >
                                  Lowest to Highest
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                        <td>
                          Low End
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'hourly_wage_lowend' ) }
                          >
                            {hourlyLowend && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'hourly_wage_lowend', 'ASC' ) }
                                >
                                  Highest to Lowest
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'hourly_wage_lowend', 'DESC' ) }
                                >
                                  Lowest to Highest
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                        <td>
                          High End
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'hourly_wage_highend' ) }
                          >
                            {hourlyHighend && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'hourly_wage_highend', 'ASC' ) }
                                >
                                  Highest to Lowest
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'hourly_wage_highend', 'DESC' ) }
                                >
                                  Lowest to Highest
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                      </tr>
                      {slice && slice.length > 0 ? (
                        slice.map( ( data ) => (
                          <tr key = {data.total_employed}>
                            <td>{data.occupation}</td>
                            <td>
                              <NumberFormat
                                value={ data.total_employed }
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={ data.annual_wage_mean }
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={ data.annual_wage_median }
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={ data.annual_wage_lowend }
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={ data.annual_wage_highend }
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={ data.hourly_wage_mean }
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={ data.hourly_wage_median }
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={ data.hourly_wage_lowend }
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </td>

                            <td>
                              <NumberFormat
                                value={ data.hourly_wage_highend }
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </td>
                          </tr>
                        ) )
                      ) : (
                        <tr className={ classes.noData }>
                          <td colSpan="5">No data found...</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {slice && slice.length > 0 && (
                    <div className={ classes.pagination }>
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        breakClassName="break-me"
                        pageCount={ pageCount }
                        marginPagesDisplayed={ 1 }
                        pageRangeDisplayed={ 7 }
                        onPageChange={ handlePageClick }
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                      />
                    </div>
                  )}
                </div>
              </div>
            </TabPanel>
            <TabPanel value={ value } index={ 1 } dir={ theme.direction }>
              <div className={ classes.textFieldSearch }>
                {collegeSearchList && (
                <NumberFormat
                  value={ collegeSearchList.length }
                  displayType="text"
                  thousandSeparator
                />
                )}
                {' '}
                industries
                <TextField
                  className={ classes.searchTextField }
                  id="standard-basic"
                  placeholder="Search"
                  value={ search }
                  onChange={ handleSearch }
                />
              </div>

              <div className={ classes.heigherEducationSearch }>
                <div className={ classNames( classes.ChooseSchoolTable, classes.wagesTable, classes.industryTable ) }>
                  <table cellPadding="0" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th colSpan="2">Annual Wages</th>
                        <th colSpan="2">Hourly Wages</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Industry
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'occupation' ) }
                          >
                            {occupationType && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'occupation', 'ASC' ) }
                                >
                                  Sort A to Z
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'occupation', 'DESC' ) }
                                >
                                  Sort Z to A
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                        <td>
                          # Employed
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'total_employed' ) }
                          >
                            {totalEmployed && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'total_employed', 'ASC' ) }
                                >
                                  Sort A to Z
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'total_employed', 'DESC' ) }
                                >
                                  Sort Z to A
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                        <td>
                          Mean
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'annual_wage_mean' ) }
                          >
                            {annualWageMean && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'annual_wage_mean', 'ASC' ) }
                                >
                                  Highest to Lowest
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'annual_wage_mean', 'DESC' ) }
                                >
                                  Lowest to Highest
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                        <td>
                          Median
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'annual_wage_median' ) }
                          >
                            {AnnualWageMedian && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'annual_wage_median', 'ASC' ) }
                                >
                                  Highest to Lowest
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'annual_wage_median', 'DESC' ) }
                                >
                                  Lowest to Highest
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                        <td>
                          Mean
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'hourly_wage_mean' ) }
                          >
                            {hourlyMean && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'hourly_wage_mean', 'ASC' ) }
                                >
                                  Highest to Lowest
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'hourly_wage_mean', 'DESC' ) }
                                >
                                  Lowest to Highest
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                        <td>
                          Median
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={ () => handleSort( 'hourly_wage_median' ) }
                          >
                            {hourlyMedian && (
                              <ul>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'hourly_wage_median', 'ASC' ) }
                                >
                                  Highest to Lowest
                                </li>
                                <li
                                  aria-hidden="true"
                                  onClick={ () => handleSorting( 'hourly_wage_median', 'DESC' ) }
                                >
                                  Lowest to Highest
                                </li>
                              </ul>
                            )}
                          </i>
                        </td>
                      </tr>
                      {slice && slice.length > 0 ? (
                        slice.map( ( data ) => (
                          <tr key = {data.total_employed}>
                            <td>{data.industry}</td>
                            <td>
                              <NumberFormat
                                value={ data.total_employed }
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={ data.annual_wage_mean }
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={ data.annual_wage_median }
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={ data.hourly_wage_mean }
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={ data.hourly_wage_median }
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </td>
                          </tr>
                        ) )
                      ) : (
                        <tr className={ classes.noData }>
                          <td colSpan="5">No data found...</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {slice && slice.length > 0 && (
                    <div className={ classes.pagination }>
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        breakClassName="break-me"
                        pageCount={ pageCount }
                        marginPagesDisplayed={ 2 }
                        pageRangeDisplayed={ 5 }
                        onPageChange={ handlePageClick }
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                      />
                    </div>
                  )}
                </div>
              </div>
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    </div>
  );
};

BaseIncome.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  // occupationData: PropTypes.arrayOf( PropTypes.object ).isRequired,
  // setEducationOrder: PropTypes.func.isRequired,
  // setSelectType: PropTypes.func.isRequired,

};

export default withStyles( styles )( BaseIncome );
