import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import compose from 'recompose/compose';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from './styles';
import * as moduleServices from '../../../calculations/modules-services';
import infoIcon from '../../../assets/img/info-icon.svg';

const InputStatementPopup = (props) => {
  const {
    classes, detailedInfo, openGrossIncomeModel, openTaxCreditsDetailModel, openDialog, setOpenDialog, openDialogName
  } = props;

  let IncomeGrowth = parseFloat(moduleServices.module1Data.incomeGrowth);


  return (
    <div className={classes.modalBodyData}>
      <Dialog
        open={openDialogName === 'homePropertyIncomeEarnedDuringHogher' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div>
            <div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td >{detailedInfo.headingHigherEducation}</td>
                      <td >Year {detailedInfo['Year']}</td>
                    </tr>
                    <tr>
                      <td >&nbsp;</td>
                    </tr>
                    {detailedInfo.finalYearValueCondition === 1 && detailedInfo.firstHigherEducationStartYear > 0 && <tr>
                      <td>&nbsp;</td>
                      <td >Start Year</td>
                      <td >End Year</td>
                      <td >Annual Income</td>
                    </tr>}
                    {detailedInfo.finalYearValueCondition === 1 && detailedInfo.firstHigherEducationStartYear > 0 && <tr>
                      <td>&nbsp;</td>
                      <td >{detailedInfo.firstHigherEducationStartYear}</td>
                      <td >{detailedInfo.firstHigherEducationEndYear}</td>
                      <td>
                        <span className={classNames(detailedInfo.firstHigherEducationAnnualIncome < 0 && classes.minusValue)}>
                          {detailedInfo.firstHigherEducationAnnualIncome >= 0 ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.firstHigherEducationAnnualIncome} prefix="$" />
                          ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.firstHigherEducationAnnualIncome} prefix="($" suffix=")" />
                          )}
                        </span>
                      </td>
                    </tr>}
                    {detailedInfo.finalYearValueCondition === 1 && <tr>
                      <td >&nbsp;</td>
                    </tr>}
                    {(detailedInfo.previousYearValueCondition === 1 && detailedInfo.finalYearValueCondition === 1) && <tr>
                      <td>&nbsp;</td>
                      <td>{detailedInfo['Year'] - 1} Income Earned during Higher Education</td>
                      <td >
                        {/* <span class="hidden">{{val84001 = detailedInfo.previousYearValue }}</span> */}
                        {/* <span data-ng-if="val84001 < 0" class="minus-value">({{ -(val84001) | currency:'$':2 }})</span> */}
                        {/* <span data-ng-if="val84001 >= 0">{{ val84001 | currency:'$':2 }}</span> */}
                        <span className={classNames(detailedInfo.previousYearValue < 0 && classes.minusValue)}>

                          {detailedInfo.previousYearValue >= 0 ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.previousYearValue} prefix="$" />
                          ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.previousYearValue} prefix="($" suffix=")" />
                          )}
                        </span>
                      </td>
                    </tr>}
                    {(detailedInfo.previousYearValueCondition === 1 && detailedInfo.finalYearValueCondition === 1) && <tr>
                      <td>x</td>
                      <td>Annual Income Growth</td>
                      {detailedInfo.incomeGrowthValue && <td >{detailedInfo.incomeGrowthValue}%</td>}
                    </tr>}
                    <tr >
                      <td >=</td>
                      <td>{detailedInfo['Year']} Total Income Earned during Higher Education</td>
                      <td >
                        {/* <span class="hidden">{{val84002 = detailedInfo.finalYearValue }}</span> */}
                        {/*// <span data-ng-if="val84002 < 0" class="minus-value">({{ -(val84002) | currency:'$':2 }})</span>*/}
                        {/* // <span data-ng-if="val84002 >= 0">{{ val84002 | currency:'$':2 }}</span> */}
                        <span className={classNames(detailedInfo.finalYearValue < 0 && classes.minusValue)}>

                          {detailedInfo.finalYearValue >= 0 ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.finalYearValue} prefix="$" />
                          ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.finalYearValue} prefix="($" suffix=")" />
                          )}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div >
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>

        </DialogContent>
      </Dialog>

      <Dialog
        open={openDialogName === 'showDetailPopup' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.incomeStatementDialog}
      >
        <DialogContent>
          <div>

            {openGrossIncomeModel === 'income-section' &&
              <table>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      {detailedInfo['selectedColumn'] === 'Spouses Income' && <span>Spouse's Income</span>}
                      {detailedInfo['selectedColumn'] !== 'Spouses Income' && <span>{detailedInfo['selectedColumn']}</span>}
                    </td>
                    <td>Year {detailedInfo['Year']}</td>
                  </tr>
                  <tr>
                    <td >&nbsp;</td>
                  </tr>
                  {(detailedInfo.condition === 2 || detailedInfo.condition === 1) && detailedInfo.StartYear > 0 && <tr>
                    <td>&nbsp;</td>
                    <td>Start Year</td>
                    <td>End Year</td>
                    <td>Annual Income</td>
                  </tr>}
                  {(detailedInfo.condition === 2 || detailedInfo.condition === 1) && detailedInfo.StartYear > 0 && <tr>
                    <td>&nbsp;</td>
                    <td>{detailedInfo.StartYear}</td>
                    <td>{detailedInfo.EndYear}</td>
                    <td className={classNames(detailedInfo.YearlyIncome < 0 && classes.minusValue)}>

                      {detailedInfo.YearlyIncome >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.YearlyIncome} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.YearlyIncome} prefix="($" suffix=")" />
                      )}
                    </td>
                  </tr>}
                  {(detailedInfo.condition === 2 || detailedInfo.condition === 1) && <tr>
                    <td >&nbsp;</td>
                  </tr>}
                  {(detailedInfo.condition === 0 || detailedInfo.condition === 1) && <tr>
                    <td>=</td>
                    <td>{detailedInfo['Year']} {detailedInfo['Label']}</td>
                    <td className={classNames(detailedInfo['Gross Income'][detailedInfo['selectedColumn']] < 0 && classes.minusValue)}>

                      {detailedInfo['Gross Income'][detailedInfo['selectedColumn']] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Gross Income'][detailedInfo['selectedColumn']]} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Gross Income'][detailedInfo['selectedColumn']]} prefix="($" suffix=")" />
                      )}
                    </td>
                  </tr>}
                  {detailedInfo.condition === 2 && <tr>
                    <td>&nbsp;</td>
                    <td>{detailedInfo['Year'] - 1} {detailedInfo['Label']}</td>
                    <td className={classNames(detailedInfo.previousIncomeValue < 0 && classes.minusValue)}>

                      {detailedInfo.previousIncomeValue >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.previousIncomeValue} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.previousIncomeValue} prefix="($" suffix=")" />
                      )}
                    </td>
                  </tr>}
                  {detailedInfo.condition === 2 && <tr>
                    <td>x</td>
                    <td>Annual Income Growth</td>
                    {IncomeGrowth && <td>{IncomeGrowth}%</td>}
                  </tr>}
                  {detailedInfo.condition === 2 && <tr>
                    <td>=</td>
                    <td>{detailedInfo['Year']} {detailedInfo['Label']}</td>
                    <td className={classNames(detailedInfo['Gross Income'][detailedInfo['selectedColumn']] < 0 && classes.minusValue)}>

                      {(detailedInfo['Gross Income'][detailedInfo['selectedColumn']]) >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={(detailedInfo['Gross Income'][detailedInfo['selectedColumn']])} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(detailedInfo['Gross Income'][detailedInfo['selectedColumn']])} prefix="($" suffix=")" />
                      )}
                    </td>
                  </tr>}
                </tbody>
              </table>
            }
            {openGrossIncomeModel === 'net-rental-income' && <table>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>Net Rental Income</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo.finalValueCondition === 1 && <tr>
                  <td></td>
                  <td>Rental Income</td>
                  <td>
                    <span className={classNames(detailedInfo['Rental Income'] < 0 && classes.minusValue)}>

                      {detailedInfo['Rental Income'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Rental Income']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Rental Income']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Association Fees</td>
                  <td>
                    <span className={classNames(detailedInfo['Association Fees'] < 0 && classes.minusValue)}>

                      {detailedInfo['Association Fees'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Association Fees']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Association Fees']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Management Fee</td>
                  <td>
                    <span className={classNames(detailedInfo['Management Fees'] < 0 && classes.minusValue)}>

                      {detailedInfo['Management Fees'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Management Fees']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Management Fees']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Homeowner's Insurance</td>

                  <td>
                    <span className={classNames(detailedInfo['Houseowners Insurance'] < 0 && classes.minusValue)}>

                      {detailedInfo['Houseowners Insurance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Houseowners Insurance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Houseowners Insurance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Property Maintenance</td>

                  <td>
                    <span className={classNames(detailedInfo['Property Maintenance'] < 0 && classes.minusValue)}>

                      {detailedInfo['Property Maintenance'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Property Maintenance']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Property Maintenance']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Utilities</td>

                  <td>
                    <span className={classNames(detailedInfo['Utilities'] < 0 && classes.minusValue)}>

                      {detailedInfo['Utilities'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Utilities']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Utilities']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Property Taxes</td>

                  <td>
                    <span className={classNames(detailedInfo['Property Taxes'] < 0 && classes.minusValue)}>

                      {detailedInfo['Property Taxes'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Property Taxes']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Property Taxes']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                <tr>
                  <td>=</td>
                  <td>Net Rental Income</td>

                  <td>
                    <span className={classNames(detailedInfo['Net Income'] < 0 && classes.minusValue)}>

                      {detailedInfo['Net Income'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Net Income']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Net Income']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'rental-income' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td colSpan="3" className={classNames(classes.textCenter, classes.strongText)}>Rental Income</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td >&nbsp;</td>
                </tr>
                {detailedInfo.annualRentalIncomeFinalCondition === 1 && detailedInfo.purchaseYear > 0 && <tr>
                  <td>&nbsp;</td>
                  <td>Purchase Year / Sale Year</td>
                  <td>Annual Rental Income</td>
                  <td>Vacancy Rate</td>
                </tr>}
                {detailedInfo.annualRentalIncomeFinalCondition === 1 && detailedInfo.purchaseYear > 0 && <tr>
                  <td>&nbsp;</td>
                  <td>{detailedInfo.purchaseYear} / {detailedInfo.sellYear}</td>

                  <td>
                    <span className={classNames(detailedInfo.annualRentalIncome < 0 && classes.minusValue)}>

                      {detailedInfo.annualRentalIncome >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.annualRentalIncome} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.annualRentalIncome} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>

                  <td>{detailedInfo.vacancyRate}%</td>
                </tr>}
                {detailedInfo.annualRentalIncomeFinalCondition === 1 && <tr>
                  <td >&nbsp;</td>
                </tr>}
                {(detailedInfo.purchaseYearCondition === 1 && detailedInfo.annualRentalIncomeFinalCondition === 1) && <tr>
                  <td>&nbsp;</td>
                  <td>{detailedInfo['Year'] - 1} Annual Rental Income</td>
                  <td>
                    <span className={classNames(detailedInfo.annualRentalIncomePrevious < 0 && classes.minusValue)}>

                      {detailedInfo.annualRentalIncomePrevious >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.annualRentalIncomePrevious} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.annualRentalIncomePrevious} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.purchaseYearCondition === 1 && detailedInfo.annualRentalIncomeFinalCondition === 1) && <tr>
                  <td>x</td>
                  <td>Annual Rental Income Growth</td>
                  <td>{detailedInfo.incomeGrowthStarts}%</td>
                </tr>}
                {detailedInfo.annualRentalIncomeFinalCondition === 1 && <tr>
                  <td>=</td>
                  <td>{detailedInfo['Year']} Annual Rental Income</td>
                  <td>

                    <span className={classNames(detailedInfo.annualRentalIncomeCurrent < 0 && classes.minusValue)}>

                      {detailedInfo.annualRentalIncomeCurrent >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.annualRentalIncomeCurrent} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.annualRentalIncomeCurrent} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.annualRentalIncomeFinalCondition === 1 && <tr>
                  <td>x</td>
                  <td>Vacancy Rate</td>
                  <td>{detailedInfo.vacancyRate}%</td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td colSpan="2">{detailedInfo['Year']} Rental Income</td>
                  <td>
                    <span className={classNames(detailedInfo.annualRentalIncomeFinal < 0 && classes.minusValue)}>

                      {detailedInfo.annualRentalIncomeFinal >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.annualRentalIncomeFinal} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.annualRentalIncomeFinal} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }


            {openGrossIncomeModel === 'adjusted-gross-income' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Adjusted Gross Income</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo.condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Total Gross Income (excluding Rental Income)</td>
                  <td>
                    <span className={classNames(detailedInfo['totalGrossIncomePop'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalGrossIncomePop'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalGrossIncomePop']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalGrossIncomePop']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}

                {detailedInfo.condition === 1 && <tr>
                  <td>+</td>
                  <td>REI Property Taxable Income (Loss)</td>
                  <td>
                    <span className={classNames(detailedInfo['reiTaxableIncomePop'] < 0 && classes.minusValue)}>

                      {detailedInfo['reiTaxableIncomePop'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['reiTaxableIncomePop']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['reiTaxableIncomePop']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.condition === 1 && <tr>
                  <td>-</td>
                  <td>Total Adjustments</td>
                  <td>
                    <span className={classNames(detailedInfo['totalAdjustmentsPop'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalAdjustmentsPop'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalAdjustmentsPop']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalAdjustmentsPop']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td>Total Adjusted Gross Income</td>
                  <td>
                    <span className={classNames(detailedInfo['adjustmentsGrossIncomePop'] < 0 && classes.minusValue)}>

                      {detailedInfo['adjustmentsGrossIncomePop'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['adjustmentsGrossIncomePop']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['adjustmentsGrossIncomePop']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'self-employement-tax' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Self-Employment Tax Adjustment</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td className={classes.strongText}>Self employed?</td>
                  <td className={classes.strongText}>{detailedInfo['selfEmployement']}</td>
                </tr>
                {detailedInfo.selfEmployementFinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Self-Employment Taxes (Medicare + Social Security Taxes)</td>
                  <td>
                    <span className={classNames(detailedInfo['selfEmployementTaxesValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['selfEmployementTaxesValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['selfEmployementTaxesValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['selfEmployementTaxesValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.selfEmployementFinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Multiplier</td>
                  <td>{detailedInfo['multiplier']}%</td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td>Self-Employment Tax Adjustment</td>
                  <td>
                    <span className={classNames(detailedInfo['selfEmployementFinalValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['selfEmployementFinalValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['selfEmployementFinalValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['selfEmployementFinalValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'passive-losses' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Passive Losses</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo.detailsPopupCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Net Operating Income</td>
                  <td>
                    <span className={classNames(detailedInfo.netRentalIncomeValue < 0 && classes.minusValue)}>

                      {detailedInfo.netRentalIncomeValue >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.netRentalIncomeValue} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.netRentalIncomeValue} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.detailsPopupCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Mortgage Interest, Loan Points, & Origination Fees</td>
                  <td>

                    <span className={classNames(detailedInfo.mortgageInterestLoanPointsValue < 0 && classes.minusValue)}>

                      {detailedInfo.mortgageInterestLoanPointsValue >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.mortgageInterestLoanPointsValue} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.mortgageInterestLoanPointsValue} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.detailsPopupCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td className={classes.strongText}>Depreciation</td>
                  <td>
                    <span className={classNames(detailedInfo.depreciationValue < 0 && classes.minusValue)}>

                      {detailedInfo.depreciationValue >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.depreciationValue} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.depreciationValue} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.detailsPopupCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Passive Loss Created</td>
                  <td>
                    <span className={classNames(detailedInfo.passiveLossesCreated < 0 && classes.minusValue)}>

                      {detailedInfo.passiveLossesCreated >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.passiveLossesCreated} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.passiveLossesCreated} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.detailsPopupCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.detailsPopupCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Beginning Passive Losses Balance in Year {detailedInfo['Year']}</td>
                  <td>
                    <span className={classNames(detailedInfo.beginningPassiveValue < 0 && classes.minusValue)}>

                      {detailedInfo.beginningPassiveValue >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.beginningPassiveValue} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.beginningPassiveValue} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.detailsPopupCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Passive Loss Created</td>
                  <td>
                    <span className={classNames(detailedInfo.passiveLossesCreated1 < 0 && classes.minusValue)}>

                      {detailedInfo.passiveLossesCreated1 >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.passiveLossesCreated1} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.passiveLossesCreated1} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.detailsPopupCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Passive Loss Taken</td>
                  <td>
                    <span className={classNames(detailedInfo.passiveLossesTaken1 < 0 && classes.minusValue)}>

                      {detailedInfo.passiveLossesTaken1 >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.passiveLossesTaken1} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.passiveLossesTaken1} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.detailsPopupCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Remaining Passive Losses Balance in Year {detailedInfo['Year']}</td>
                  <td>
                    <span className={classNames(detailedInfo.remainingPassiveLossesBalance < 0 && classes.minusValue)}>

                      {detailedInfo.remainingPassiveLossesBalance >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.remainingPassiveLossesBalance} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.remainingPassiveLossesBalance} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.detailsPopupCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td>Passive Losses Deduction</td>
                  <td>

                    <span className={classNames(detailedInfo['passiveLossesFinalValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['passiveLossesFinalValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['passiveLossesFinalValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['passiveLossesFinalValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'total-taxes-before-credits' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Total Taxes before Credits</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                {detailedInfo.totalTaxesBeforeCreditsFinal !== 0 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.federalIncomeTaxFinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Federal Income Tax Rate</td>
                  <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.federalIncomeTaxRate} suffix='%' /></td>
                </tr>}
                {detailedInfo.federalIncomeTaxFinalCondition === 1 && <tr>
                  <td>x</td>
                  <td>Federal Taxable Income</td>
                  <td>
                    <span className={classNames(detailedInfo.federalTaxbleIncome < 0 && classes.minusValue)}>

                      {detailedInfo.federalTaxbleIncome >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.federalTaxbleIncome} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.federalTaxbleIncome} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.federalIncomeTaxFinalCondition === 1 && <tr className={classes.incomeBorder}>
                  <td>=/+</td>
                  <td>Federal Income Tax</td>
                  <td>

                    <span className={classNames(detailedInfo.federalIncomeTaxFinal < 0 && classes.minusValue)}>

                      {detailedInfo.federalIncomeTaxFinal >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.federalIncomeTaxFinal} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.federalIncomeTaxFinal} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.federalIncomeTaxFinalCondition === 1 && detailedInfo.socialSecurityFinalCondition === 1) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.socialSecurityFinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Social Security Tax Rate</td>
                  <td>{detailedInfo.socialSecurityTaxRate}%</td>
                </tr>}
                {detailedInfo.socialSecurityFinalCondition === 1 && <tr>
                  <td>x</td>
                  <td>Total Gross Income (excluding Rental Income)</td>
                  <td>

                    <span className={classNames(detailedInfo.socialSecurityIncome < 0 && classes.minusValue)}>

                      {detailedInfo.socialSecurityIncome >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.socialSecurityIncome} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.socialSecurityIncome} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.socialSecurityFinalCondition === 1 && <tr className={classes.topSingleBorder}>
                  <td>&nbsp;</td>
                  <td><i>Maximum Income Limit (adjusted for inflation annually)</i></td>
                  <td>
                    <span className={classNames(detailedInfo.socialSecurityMaximumIncomeLimit < 0 && classes.minusValue)}>

                      {detailedInfo.socialSecurityMaximumIncomeLimit >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.socialSecurityMaximumIncomeLimit} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.socialSecurityMaximumIncomeLimit} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.socialSecurityFinalCondition === 1 && <tr className={classes.incomeBorder}>
                  <td>=/+</td>
                  <td>Social Security Tax</td>
                  <td>

                    <span className={classNames(detailedInfo.socialSecurityFinal < 0 && classes.minusValue)}>

                      {detailedInfo.socialSecurityFinal >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.socialSecurityFinal} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.socialSecurityFinal} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {(detailedInfo.socialSecurityFinalCondition === 1 && detailedInfo.medicareTaxFinalCondition === 1) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.medicareTaxFinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Medicare Tax Rate</td>
                  <td>{detailedInfo.medicareTaxRate}%</td>
                </tr>}
                {detailedInfo.medicareTaxFinalCondition === 1 && <tr>
                  <td>x</td>
                  <td>Total Gross Income</td>
                  <td>

                    <span className={classNames(detailedInfo.medicareIncome < 0 && classes.minusValue)}>

                      {detailedInfo.medicareIncome >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.medicareIncome} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.medicareIncome} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.medicareTaxFinalCondition === 1 && <tr className={classes.topSingleBorder}>
                  <td>&nbsp;</td>
                  <td><i>Maximum Income Limit ({detailedInfo.Status})</i></td>
                  <td>

                    <span className={classNames(detailedInfo.medicareMaximumIncomeLimit < 0 && classes.minusValue)}>

                      {detailedInfo.medicareMaximumIncomeLimit >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.medicareMaximumIncomeLimit} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.medicareMaximumIncomeLimit} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.medicareTaxFinalCondition === 1 && <tr className={classes.incomeBorder}>
                  <td>=/+</td>
                  <td>Medicare Tax</td>
                  <td>

                    <span className={classNames(detailedInfo.medicareTaxFinal < 0 && classes.minusValue)}>

                      {detailedInfo.medicareTaxFinal >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.medicareTaxFinal} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.medicareTaxFinal} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {((detailedInfo.finalMedicareAdditionalTaxRateCondition === 1 && detailedInfo.medicareTaxFinalCondition === 1) || detailedInfo.stateAndLocalTaxFinalCondition === 1) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.finalMedicareAdditionalTaxRateCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Additional Medicare Tax Rate</td>
                  <td>{detailedInfo.additionalMedicareTaxRate}%</td>
                </tr>}
                {detailedInfo.finalMedicareAdditionalTaxRateCondition === 1 && <tr>
                  <td>x</td>
                  <td>Total Gross Income in excess of Medicare limit</td>
                  <td>
                    <span className={classNames(detailedInfo.totalGrossIncomeInExcess < 0 && classes.minusValue)}>

                      {detailedInfo.totalGrossIncomeInExcess >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.totalGrossIncomeInExcess} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.totalGrossIncomeInExcess} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalMedicareAdditionalTaxRateCondition === 1 && <tr className={classes.incomeBorder}>
                  <td>=/+</td>
                  <td>Additional Medicare Tax</td>
                  <td>
                    <span className={classNames(detailedInfo.finalMedicareAdditionalTaxRate < 0 && classes.minusValue)}>

                      {detailedInfo.finalMedicareAdditionalTaxRate >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.finalMedicareAdditionalTaxRate} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.finalMedicareAdditionalTaxRate} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}

                {(detailedInfo.finalMedicareAdditionalTaxRateCondition === 1 && detailedInfo.stateAndLocalTaxFinalCondition === 1) && <tr>
                  <td>&nbsp;</td>
                </tr>}
                {detailedInfo.stateAndLocalTaxFinalCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>State & Local Tax Rate</td>
                  <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.stateAndLocalTaxRate} suffix="%" /></td>
                </tr>}
                {detailedInfo.stateAndLocalTaxFinalCondition === 1 && <tr>
                  <td>x</td>
                  <td>Adjusted Gross Income</td>
                  <td>
                    <span className={classNames(detailedInfo.stateAndLocalIncome < 0 && classes.minusValue)}>

                      {detailedInfo.stateAndLocalIncome >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.stateAndLocalIncome} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.stateAndLocalIncome} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.stateAndLocalTaxFinalCondition === 1 && <tr className={classes.incomeBorder}>
                  <td>=/+</td>
                  <td>State & Local Taxes</td>
                  <td>
                    <span className={classNames(detailedInfo.stateAndLocalTaxFinal < 0 && classes.minusValue)}>

                      {detailedInfo.stateAndLocalTaxFinal >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.stateAndLocalTaxFinal} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.stateAndLocalTaxFinal} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td>Total Taxes before Credits</td>
                  <td>
                    <span className={classNames(detailedInfo.totalTaxesBeforeCreditsFinal < 0 && classes.minusValue)}>

                      {detailedInfo.totalTaxesBeforeCreditsFinal >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.totalTaxesBeforeCreditsFinal} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.totalTaxesBeforeCreditsFinal} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'health-insurance' &&  <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Health Insurance Deduction</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo.stateAndLocalTaxFinalCondition === 1 && 
                  <tr>
                    <td>&nbsp;</td>
                    <td>Health Insurance Expenses</td>
                    <td>
                      <span className={classNames(detailedInfo['healthInsuranceExpenses'] < 0 && classes.minusValue)}>

                        {detailedInfo['healthInsuranceExpenses'] >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['healthInsuranceExpenses']} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['healthInsuranceExpenses']} prefix="($" suffix=")" />
                        )}
                      </span>
                    </td>
                  </tr>
                }
                {detailedInfo.stateAndLocalTaxFinalCondition === 1 &&
                  <tr>
                    <td></td>
                    <td>10% of Adjusted Gross Income</td>
                    <td>
                      {detailedInfo['adjustedGrossIncome'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['adjustedGrossIncome']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['adjustedGrossIncome']} prefix="($" suffix=")" />
                      )}
                    </td>
                  </tr>
                }
                <tr>
                  <td>=</td>
                  <td>Health Insurance Expenses</td>
                  <td>
                    <span className={classNames(detailedInfo['healthInsuranceFinal'] < 0 && classes.minusValue)}>

                      {detailedInfo['healthInsuranceFinal'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['healthInsuranceFinal']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['healthInsuranceFinal']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>}
            {openGrossIncomeModel === 'charitable-contributions' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Charitable Contributions Deduction</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td >&nbsp;</td>
                </tr>
                {detailedInfo.charitableContributionCondition === 1 && detailedInfo['charitableStartYear'] > 0 && <tr>
                  <td>&nbsp;</td>
                  <td>Start Year</td>
                  <td>End Year</td>
                  <td>% of Income</td>
                </tr>}
                {detailedInfo.charitableContributionCondition === 1 && detailedInfo['charitableStartYear'] > 0 && <tr>
                  <td>&nbsp;</td>
                  <td>{detailedInfo['charitableStartYear']}</td>
                  <td>{detailedInfo['charitableEndYear']}</td>
                  <td>{detailedInfo['grossIncomePercentageValue']}%</td>
                </tr>}
                {detailedInfo.charitableContributionCondition === 1 && <tr>
                  <td >&nbsp;</td>
                </tr>}
                {detailedInfo.charitableContributionCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>{detailedInfo['Year']} Gross Income</td>
                  <td>
                    <span className={classNames(detailedInfo['grossIncomeValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['grossIncomeValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['grossIncomeValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['grossIncomeValue']} prefix="($" suffix=")" />
                      )}
                    </span>

                  </td>
                </tr>}
                {detailedInfo.charitableContributionCondition === 1 && <tr>
                  <td>x</td>
                  <td>Contribution %</td>
                  <td>{detailedInfo['grossIncomePercentageValue']}%</td>
                </tr>}
                {detailedInfo.charitableContributionCondition === 1 && <tr>
                  <td>=</td>
                  <td>{detailedInfo['Year']} Charitable Contributions</td>
                  <td>
                    <span className={classNames(detailedInfo['charitableContributionValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['charitableContributionValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['charitableContributionValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['charitableContributionValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.charitableContributionCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td><i>Max Deduction (60% of Total Gross Income)</i></td>
                  <td>
                    <span className={classNames(detailedInfo['grossIncomeValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['grossIncomeValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['grossIncomeValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['grossIncomeValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td>{detailedInfo['Year']} Charitable Contributions Deduction</td>
                  <td>

                    <span className={classNames(detailedInfo['charitableContributionValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['charitableContributionValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['charitableContributionValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['charitableContributionValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'state-local-taxes' && <table>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>State & Local Tax Rate</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Adjusted Gross Income</td>
                  <td>

                    <span className={classNames(detailedInfo['Adjusted Gross Income'] < 0 && classes.minusValue)}>

                      {detailedInfo['Adjusted Gross Income'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Adjusted Gross Income']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Adjusted Gross Income']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>x</td>
                  <td>State & Local Tax Rate</td>
                  <td>
                    {detailedInfo['taxes']['State & Local Taxes (x Adjusted Gross Income)']}%
                  </td>
                </tr>
                <tr>
                  <td>=</td>
                  <td>State & Local Taxes</td>
                  <td>
                    <span className={classNames(detailedInfo['Itemized Deductions']['State and Local Taxes'] < 0 && classes.minusValue)}>

                      {detailedInfo['Itemized Deductions']['State and Local Taxes'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Itemized Deductions']['State and Local Taxes']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Itemized Deductions']['State and Local Taxes']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>}

            {openGrossIncomeModel === 'itemized-deductions' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Itemized Deductions</td>
                  <td>Year {detailedInfo['Year']}</td>
                  { }
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo['HomeMortgageInterestPopup'] !== 0 && <tr>
                  <td>&nbsp;</td>
                  <td>Home Mortgage Interest, Loan Points & Origination Fees, & PMI</td>
                  <td>

                    <span className={classNames(detailedInfo['HomeMortgageInterestPopup'] < 0 && classes.minusValue)}>

                      {detailedInfo['HomeMortgageInterestPopup'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['HomeMortgageInterestPopup']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['HomeMortgageInterestPopup']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo['HomePropertyTaxesPopup'] !== 0 && <tr>
                  <td>&nbsp;</td>
                  <td>Home Property Taxes, State & Local Income Taxes (SALT)</td>
                  <td>

                    <span className={classNames(detailedInfo['HomePropertyTaxesPopup'] < 0 && classes.minusValue)}>

                      {detailedInfo['HomePropertyTaxesPopup'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['HomePropertyTaxesPopup']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['HomePropertyTaxesPopup']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo['HealthInsurancePopup'] !== 0 && <tr>
                  <td>&nbsp;</td>
                  <td>Health Insurance</td>
                  <td>
                    <span className={classNames(detailedInfo['HealthInsurancePopup'] < 0 && classes.minusValue)}>

                      {detailedInfo['HealthInsurancePopup'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['HealthInsurancePopup']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['HealthInsurancePopup']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo['CharitableContributionsPopup'] !== 0 && <tr>
                  <td>&nbsp;</td>
                  <td>Charitable Contributions</td>
                  <td>
                    <span className={classNames(detailedInfo['CharitableContributionsPopup'] < 0 && classes.minusValue)}>

                      {detailedInfo['CharitableContributionsPopup'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['CharitableContributionsPopup']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['CharitableContributionsPopup']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td>Itemized Deductions</td>
                  <td>
                    <span className={classNames(detailedInfo['ItemizedDeductionsPopup'] < 0 && classes.minusValue)}>

                      {detailedInfo['ItemizedDeductionsPopup'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['ItemizedDeductionsPopup']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['ItemizedDeductionsPopup']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'standard-deduction' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Standard Deduction</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td className={classes.textRight}>Marriage Year</td>
                  <td>
                    <span>{detailedInfo.marrideStatus}</span>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td className={classes.textRight}>Single Deduction</td>
                  <td>
                    <span>
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.singleDeductionValue} prefix="$" />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td className={classes.textRight}>Married Deduction</td>
                  <td>
                    <span>
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.marriedDeductionValue} prefix="$" />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td className={classNames(classes.textRight, classes.strongText)}>Standard Deduction</td>
                  <td>
                    <span className={classNames(detailedInfo.finalStandardValue < 0 && classes.minusValue)}>

                      {detailedInfo.finalStandardValue >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.finalStandardValue} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.finalStandardValue} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'total-deductions-exemptions' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Total Deductions</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo['itemizedDeductionsPopup'] && <tr>
                  <td>&nbsp;</td>
                  <td>Itemized Deductions</td>
                  {/* {console.log('phrudhvi', detailedInfo)} */}
                  <td>
                    <span className={classNames(detailedInfo['itemizedDeductionsPopup'] < 0 && classes.minusValue)}>

                      {detailedInfo['itemizedDeductionsPopup'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['itemizedDeductionsPopup']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['itemizedDeductionsPopup']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo['itemizedDeductionsPopup'] <= detailedInfo['standardDeductionVal'] && <tr>
                  <td>&nbsp;</td>
                  <td>Standard Deduction</td>
                  <td>
                    <span className={classNames(detailedInfo['standardDeductionVal'] < 0 && classes.minusValue)}>

                      {detailedInfo['standardDeductionVal'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['standardDeductionVal']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['standardDeductionVal']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td>Total Deductions</td>
                  <td>
                    <span className={classNames(detailedInfo['ItemizedDeductionsPopup'] < 0 && classes.minusValue)}>
                      {detailedInfo['ItemizedDeductionsPopup'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['ItemizedDeductionsPopup']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['ItemizedDeductionsPopup']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'federal-taxable-income' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Federal Taxable Income</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo.federalTaxableIncomeCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Adjusted Gross Income</td>
                  <td>
                    <span className={classNames(detailedInfo['adjustedGrossIncomePop'] < 0 && classes.minusValue)}>

                      {detailedInfo['adjustedGrossIncomePop'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['adjustedGrossIncomePop']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['adjustedGrossIncomePop']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.federalTaxableIncomeCondition === 1 && <tr>
                  <td>-</td>
                  <td>Total Deductions</td>
                  <td>
                    <span className={classNames(detailedInfo['totalDeductionsPop'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalDeductionsPop'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalDeductionsPop']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalDeductionsPop']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td>Federal Taxable Income</td>
                  <td>
                    <span className={classNames(detailedInfo['federalTaxableIncomePop'] < 0 && classes.minusValue)}>

                      {detailedInfo['federalTaxableIncomePop'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['federalTaxableIncomePop']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['federalTaxableIncomePop']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'federal' && <table className={classes.netIncomeTable}>
              <thead>
                <tr className={classNames(classes.popupHeader, classes.tHeader)}>
                  <td>&nbsp;</td>
                  <td colSpan="4">Federal Income Tax Rate</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td colSpan="6">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td colSpan="4" className={classNames(classes.strongText, classes.textRight)}>Federal Taxable Income</td>
                  {detailedInfo['Income'] !== undefined &&
                    <td className={classNames(classes.strongText, classes.textRight)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['Income']} prefix='$' /></td>
                  }
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr className={classes.feHeader}>
                  <td>&nbsp;</td>
                  <td>Rate</td>
                  <td colSpan="2">Income Ranges ({detailedInfo['Type']})</td>
                  <td>Income</td>
                  <td>Taxes</td>
                </tr>
              </thead>
              <tbody className={classes.federalBody}>

                {
                  detailedInfo['Data'].map((key, value) =>
                    <tr>
                      <td>&nbsp;</td>
                      <td>{value.Percentage}%</td>
                      {key !== 6 && <td>
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={value.Range} prefix="$" />
                      </td>}
                      {key === 6 && <td>
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={value.Range} prefix="$" />
                      </td>}
                      {key !== 6 && <td>
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['Iteration Data'] && detailedInfo['Iteration Data'][key] && detailedInfo['Iteration Data'][key]['Difference']} prefix="$" />
                      </td>}
                      {key === 6 && <td>
                        &nbsp;
                      </td>}
                      <td>
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={value.Range2} prefix="$" />
                      </td>
                      <td>
                        <span className={classNames(detailedInfo['Iteration Data'] < 0 && classes.minusValue)}>

                          {detailedInfo['Iteration Data'] >= 0 ? (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Iteration Data']} prefix="$" />
                          ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Iteration Data']} prefix="($" suffix=")" />
                          )}
                        </span>
                      </td>
                    </tr>
                  )
                }
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="4">&nbsp;</td>
                  <td className={classNames(classes.strongText, classes.textRight)}>{detailedInfo.typeCondition === 0 &&
                    <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['Total Difference']} prefix="$" />
                  }
                    {detailedInfo.typeCondition === 1 &&
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo['Total Difference']} prefix="$" />
                    }</td>
                  <td className={classNames(classes.strongText, classes.textRight)}>
                    <span className={classNames(detailedInfo['Total Taxes'] < 0 && classes.minusValue)}>

                      {detailedInfo['Total Taxes'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Total Taxes']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Total Taxes']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>

                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td colSpan="4" className={classNames(classes.strongText, classes.textRight)}>Total Effective Federal Income Tax Rate (Taxes / Income)</td>
                  <td className={classNames(classes.strongText, classes.textRight)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={detailedInfo.TaxData['taxes']['Federal Income Tax (x Federal Taxable Income)']} suffix='%' /></td>
                </tr>
              </tfoot>
            </table>
            }
            {openGrossIncomeModel === 'social-security' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Social Security Tax Rate</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td><i>Maximum Income Limit (adjusted for inflation annually)</i></td>
                  <td>
                    <span className={classNames(detailedInfo['socialSecurityincomeLimit'] < 0 && classes.minusValue)}>

                      {detailedInfo['socialSecurityincomeLimit'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['socialSecurityincomeLimit']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['socialSecurityincomeLimit']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr className={classes.topSingleBorder}>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Social Security Tax Rate</td>
                  <td>{detailedInfo['socialSecurityTaxRate']}%</td>
                </tr>
                <tr>
                  <td>x</td>
                  <td>Self-Employment Multiplier</td>
                  <td>{detailedInfo['selfEmploymentMultiplier']}x</td>
                </tr>
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td>Total Social Security Tax Rate</td>
                  <td>
                    {detailedInfo.condition === 0 && <span>
                      {detailedInfo['socialSecurityTaxRate']}%
                    </span>}
                    {detailedInfo.condition === 1 && <span >
                      {detailedInfo['socialSecurityTaxRate'] * 2}%
                    </span>}
                  </td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'medicare' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Medicare Tax Rate</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo.finalMedicareTaxRateValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td className={classes.textRight}><i>Self-Employed</i> (Yes/No)</td>
                  <td>{detailedInfo['selfEmploymentStatus']}</td>
                </tr>}
                {detailedInfo.finalMedicareTaxRateValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td className={classes.textRight}><i>Maximum Income Limit ({detailedInfo['Status']})</i></td>
                  <td>
                    <span className={classNames(detailedInfo['maximumIncomelimit'] < 0 && classes.minusValue)}>

                      {detailedInfo['maximumIncomelimit'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['maximumIncomelimit']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['maximumIncomelimit']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalMedicareTaxRateValueCondition === 1 && <tr className={classes.topSingleBorder}>
                  <td>&nbsp;</td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td className={classes.textRight}>Medicare Tax Rate</td>
                  <td>{detailedInfo['finalMedicareTaxRateValue']}%</td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'medicare-additional' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Additional Medicare Tax Rate</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo.finalMedicareTaxRateValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Self-Employed (Yes/No)</td>
                  <td>{detailedInfo['selfEmploymentStatus']}</td>
                </tr>}
                {detailedInfo.finalMedicareTaxRateValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td><i>Maximum Income Limit ({detailedInfo['Status']})</i></td>
                  <td>
                    <span className={classNames(detailedInfo['maximumIncomelimit'] < 0 && classes.minusValue)}>

                      {detailedInfo['maximumIncomelimit'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['maximumIncomelimit']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['maximumIncomelimit']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.finalMedicareTaxRateValueCondition === 1 && <tr>
                  <td>&nbsp;</td>
                </tr>}
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td className={classes.textRight}>Additional Medicare Tax Rate</td>
                  <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['finalMedicareTaxRateValue']} suffix="%" /></td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'state-local' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>State & Local Tax Rate</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>State Tax Rate ({detailedInfo['State']} Average)</td>
                  <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['State tax'] * 100} suffix="%" /></td>
                </tr>
                <tr>
                  <td>+</td>
                  <td>Local Tax Rate (Average)</td>
                  <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['City tax'] * 100} suffix="%" /></td>
                </tr>
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td>Total State & Local Tax Rate</td>
                  <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['State tax'] * 100 + detailedInfo['City tax'] * 100} suffix="%" /></td>
                </tr>
              </tbody>
            </table>
            }
            {openGrossIncomeModel === 'total-tax-rate' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Total Tax Rate</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr >
                  <td>&nbsp;</td>
                  <td>Total Taxes before Credits</td>
                  <td>
                    <span className={classNames(detailedInfo['totalTaxRatebeforeCredits'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalTaxRatebeforeCredits'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalTaxRatebeforeCredits']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalTaxRatebeforeCredits']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>/</td>
                  <td>Total Gross Income</td>
                  <td>
                    <span className={classNames(detailedInfo['totalTaxRateGrossIncome'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalTaxRateGrossIncome'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalTaxRateGrossIncome']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalTaxRateGrossIncome']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td>Total Tax Rate</td>
                  {(detailedInfo['totalTaxRatebeforeCredits'] !== 0 && detailedInfo['totalTaxRateGrossIncome'] !== 0) && <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={((detailedInfo['totalTaxRatebeforeCredits'] / detailedInfo['totalTaxRateGrossIncome']) * 100) > 0 || ((detailedInfo['totalTaxRatebeforeCredits'] / detailedInfo['totalTaxRateGrossIncome']) * 100) <= 0 ? -((detailedInfo['totalTaxRatebeforeCredits'] / detailedInfo['totalTaxRateGrossIncome']) * 100) : 0} suffix="%" /></td>}
                  {(detailedInfo['totalTaxRatebeforeCredits'] === 0 || detailedInfo['totalTaxRateGrossIncome'] === 0) && <td>0.00%</td>}
                </tr>
              </tbody>
            </table>
            }



            {/* {openGrossIncomeModel === 'state-local' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>State & Local Tax Rate</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>State Tax Rate ({detailedInfo['State']} Average)</td>
                  <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['State tax'] * 100} suffix="%" /></td>
                </tr>
                <tr>
                  <td>+</td>
                  <td>Local Tax Rate (Average)</td>
                  <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['City tax'] * 100} suffix="%" /></td>
                </tr>
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td>Total State & Local Tax Rate</td>
                  <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['State tax'] * 100 + detailedInfo['City tax'] * 100} suffix="%" /></td>
                </tr>
              </tbody>
            </table>
            } */}

            {openGrossIncomeModel === 'total-taxes' && <table className={classes.netIncomeTable}>
              <tbody>
                <tr className={classes.popupHeader}>
                  <td>&nbsp;</td>
                  <td>Total Taxes</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Total Taxes before Credits</td>
                  <td>
                    <span className={classNames(detailedInfo['totalTaxesBeforeCredits'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalTaxesBeforeCredits'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalTaxesBeforeCredits']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalTaxesBeforeCredits']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Total Tax Credits</td>
                  <td>

                    <span className={classNames(detailedInfo['totalTaxCredits'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalTaxCredits'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalTaxCredits']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalTaxCredits']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Total Tax on Real Estate Sales</td>
                  <td>
                    <span className={classNames(detailedInfo['totalTaxRealEstateValues'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalTaxRealEstateValues'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalTaxRealEstateValues']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalTaxRealEstateValues']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
                <tr className={classes.incomeBorder}>
                  <td>=</td>
                  <td>Total Taxes</td>
                  <td>
                    <span className={classNames(detailedInfo['totalTaxFinalValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalTaxFinalValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalTaxFinalValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalTaxFinalValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            }




            {openGrossIncomeModel === 'investment-propertysale' && <table>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>Investment Property Sale</td>
                  <td>Year {detailedInfo['Year']}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {detailedInfo.condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Net Selling Price</td>
                  <td>

                    <span className={classNames(detailedInfo['netSellingPrice'] < 0 && classes.minusValue)}>

                      {detailedInfo['netSellingPrice'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['netSellingPrice']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['netSellingPrice']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Purchase Price</td>
                  <td>
                    <span className={classNames(detailedInfo['purchasePrice'] < 0 && classes.minusValue)}>

                      {detailedInfo['purchasePrice'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['purchasePrice']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['purchasePrice']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.condition === 1 && <tr>
                  <td>&nbsp;</td>
                  <td>Closing Costs, Fit Out Costs</td>
                  <td>
                    <span className={classNames(detailedInfo['closingCosts'] < 0 && classes.minusValue)}>

                      {detailedInfo['closingCosts'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['closingCosts']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['closingCosts']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.condition === 1 && <tr>
                  <td>=</td>
                  <td>Gain (loss) on Sale</td>
                  <td>

                    <span className={classNames(detailedInfo['gainOnSale'] < 0 && classes.minusValue)}>

                      {detailedInfo['gainOnSale'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['gainOnSale']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['gainOnSale']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
                {detailedInfo.condition === 0 && <tr>
                  <td>=</td>
                  <td>Investment Property Sale</td>
                  <td>

                    <span className={classNames(detailedInfo['Investment Property Sale']['Gain'] < 0 && classes.minusValue)}>

                      {detailedInfo['Investment Property Sale']['Gain'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Investment Property Sale']['Gain']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Investment Property Sale']['Gain']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>}
              </tbody>
            </table>
            }

          </div>

        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogName === 'DetailPopup401k' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.sepDialog}
      >
        <DialogContent>
          <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td>401(k), SEP IRA, & Pension Contributions Adjustment</td>
                <td>Year {detailedInfo['Year']}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              {detailedInfo.final401kValueCondition === 1 && <tr>
                <td>401(k)</td>
              </tr>}
              {detailedInfo.final401kValueCondition === 1 && <tr>
                <td>&nbsp;</td>
              </tr>}
              {detailedInfo.final401kValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>{detailedInfo['Year']} Base Income</td>
                <td>
                  <span className={classNames(detailedInfo['baseIncome401k'] < 0 && classes.minusValue)}>

                    {detailedInfo['baseIncome401k'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['baseIncome401k']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['baseIncome401k']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.final401kValueCondition === 1 && <tr>
                <td>x</td>
                <td>Personal Contribution %</td>
                <td>
                  <span>{detailedInfo['personalContribution401k']}%</span>
                </td>
              </tr>}
              {detailedInfo.final401kValueCondition === 1 && <tr>
                <td>=</td>
                <td>{detailedInfo['Year']} Personal Contribution</td>
                <td>
                  <span className={classNames(detailedInfo['personalContributionValue401k'] < 0 && classes.minusValue)}>

                    {detailedInfo['personalContributionValue401k'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['personalContributionValue401k']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['personalContributionValue401k']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.final401kValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td><i>Max Personal Contribution by Law</i></td>
                <td>
                  <i>
                    <span className={classNames(detailedInfo['database401k'] < 0 && classes.minusValue)}>

                      {detailedInfo['database401k'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['database401k']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['database401k']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </i>
                </td>
              </tr>}
              {detailedInfo.final401kValueCondition === 1 && <tr>
                <td>=/+</td>
                <td>{detailedInfo['Year']} Total 401(k) Personal Contribution</td>
                <td>
                  <span className={classNames(detailedInfo['final401kValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['final401kValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['final401kValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['final401kValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.finalSephIraValueCondition === 1 && detailedInfo.final401kValueCondition === 1) && <tr>
                <td>&nbsp;</td>
              </tr>}
              {detailedInfo.finalSephIraValueCondition === 1 && <tr>
                <td>SEP IRA</td>
              </tr>}
              {detailedInfo.finalSephIraValueCondition === 1 && <tr>
                <td>&nbsp;</td>
              </tr>}
              {detailedInfo.finalSephIraValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>{detailedInfo['Year']} Base Income</td>
                <td>
                  <span className={classNames(detailedInfo['baseIncomeSephIra'] < 0 && classes.minusValue)}>

                    {detailedInfo['baseIncomeSephIra'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['baseIncomeSephIra']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['baseIncomeSephIra']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.finalSephIraValueCondition === 1 && <tr>
                <td>x</td>
                <td>Personal Contribution %</td>
                <td>
                  <span>{detailedInfo['personalContributionSephIra']}%</span>
                </td>
              </tr>}
              {detailedInfo.finalSephIraValueCondition === 1 && <tr>
                <td>=</td>
                <td>{detailedInfo['Year']} Personal Contribution</td>
                <td>

                  <span className={classNames(detailedInfo['personalContributionValueSephIra'] < 0 && classes.minusValue)}>

                    {detailedInfo['personalContributionValueSephIra'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['personalContributionValueSephIra']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['personalContributionValueSephIra']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.finalSephIraValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td><i>Max Personal Contribution by Law (lesser of 1 &amp; 2)</i></td>
                <td><i>

                  <span className={classNames(detailedInfo['maxContributionByLawSephIra'] < 0 && classes.minusValue)}>

                    {detailedInfo['maxContributionByLawSephIra'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['maxContributionByLawSephIra']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['maxContributionByLawSephIra']} prefix="($" suffix=")" />
                    )}
                  </span></i>
                </td>
              </tr>}
              {detailedInfo.finalSephIraValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td><i>(1) 25% of Income, limited to $275,000 of Income</i></td>
                <td>
                  <i>
                    <span className={classNames(detailedInfo['firstSephIraValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['firstSephIraValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['firstSephIraValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['firstSephIraValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </i>
                </td>
              </tr>}
              {detailedInfo.finalSephIraValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td><i>(2) $55,000</i></td>
                <td>
                  <i>
                    <span className={classNames(detailedInfo['secondSephIraValue'] < 0 && classes.minusValue)}>

                      {detailedInfo['secondSephIraValue'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['secondSephIraValue']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['secondSephIraValue']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </i>
                </td>
              </tr>}
              {detailedInfo.finalSephIraValueCondition === 1 && <tr>
                <td>=/+</td>
                <td>{detailedInfo['Year']} Total SEP IRA Personal Contribution</td>
                <td>
                  <span className={classNames(detailedInfo['finalSephIraValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['finalSephIraValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['finalSephIraValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['finalSephIraValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.finalPensionValueCondition === 1 && (detailedInfo.finalSephIraValueCondition === 1 || detailedInfo.final401kValueCondition === 1)) && <tr>
                <td>&nbsp;</td>
              </tr>}
              {detailedInfo.finalPensionValueCondition === 1 && <tr>
                <td>Pension</td>
              </tr>}
              {detailedInfo.finalPensionValueCondition === 1 && <tr>
                <td>&nbsp;</td>
              </tr>}
              {detailedInfo.finalPensionValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>{detailedInfo['Year']} Base Income</td>
                <td>
                  <span className={classNames(detailedInfo['baseIncomePension'] < 0 && classes.minusValue)}>

                    {detailedInfo['baseIncomePension'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['baseIncomePension']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['baseIncomePension']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.finalPensionValueCondition === 1 && <tr>
                <td>x</td>
                <td>Personal Contribution %</td>
                <td>
                  <span>{detailedInfo['personalContributionPension']}%</span>
                </td>
              </tr>}
              {detailedInfo.finalPensionValueCondition === 1 && <tr>
                <td>=</td>
                <td>{detailedInfo['Year']} Personal Contribution</td>
                <td>
                  <span className={classNames(detailedInfo['personalContributionValuePension'] < 0 && classes.minusValue)}>

                    {detailedInfo['personalContributionValuePension'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['personalContributionValuePension']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['personalContributionValuePension']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.finalPensionValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td><i>Max Contribution by Law (including Employer Contribution)</i></td>
                <td>
                  <i>
                    <span className={classNames(detailedInfo['databasePension'] < 0 && classes.minusValue)}>

                      {detailedInfo['databasePension'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['databasePension']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['databasePension']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </i>
                </td>
              </tr>}
              {detailedInfo.finalPensionValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td><i>Employer Contribution</i></td>
                <td>
                  <i>
                    <span className={classNames(detailedInfo['employerContributionPension'] < 0 && classes.minusValue)}>

                      {detailedInfo['employerContributionPension'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['employerContributionPension']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['employerContributionPension']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </i>
                </td>
              </tr>}
              {detailedInfo.finalPensionValueCondition === 1 && <tr>
                <td>=/+</td>
                <td>{detailedInfo['Year']} Total Pension Personal Contribution</td>
                <td>
                  <span className={classNames(detailedInfo['finalPensionValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['finalPensionValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['finalPensionValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['finalPensionValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              <tr className={classes.incomeBorder}>
                <td>=</td>
                <td>{detailedInfo['Year']} 401(k), SEP IRA, & Pension Contributions Adjustment</td>
                <td>
                  <span className={classNames(detailedInfo['totalAdjustmentsValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['totalAdjustmentsValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalAdjustmentsValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalAdjustmentsValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogName === 'showDetailPopupFull' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.taxStatementDialog}
      >
        <DialogContent>
          <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td colSpan="6">Student Loan Interest Adjustment</td>
                <td>Year {detailedInfo['Year']}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              {detailedInfo.condition === 0 && <tr>
                <td>&nbsp;</td>
                <td colSpan="6" className={classNames(classes.strongText, classes.textRight)}>Total Gross Income (excluding Rental Income)</td>
                <td className={classNames(classes.strongText, classes.textRight)}>
                  {
                    detailedInfo['Gross Income']['Total Gross Income (excluding Rental Income)'] !== undefined ?
                      (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Gross Income']['Total Gross Income (excluding Rental Income)']} prefix={detailedInfo['Gross Income']['Total Gross Income (excluding Rental Income)'] >= 0 ? "$" : "($"} suffix={detailedInfo['Gross Income']['Total Gross Income (excluding Rental Income)'] < 0 && ")"} />
                      ) : ("$0")
                  }
                </td>
              </tr>}
              {detailedInfo.condition === 0 && <tr>
                <td>&nbsp;</td>
              </tr>}
              {detailedInfo.condition === 0 && <tr>
                <td>&nbsp;</td>
                <td colSpan="6" className={classes.textRight}>Interest Expense</td>
                <td className={classes.textRight}>
                  <span className={classNames(detailedInfo['studentInterestExpenseValue'] < 0 && classes.minusValue)}>
                    {
                      detailedInfo['studentInterestExpenseValue'] !== undefined ?
                        (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['studentInterestExpenseValue']} prefix={detailedInfo['studentInterestExpenseValue'] >= 0 ? "$" : "($"} suffix={detailedInfo['studentInterestExpenseValue'] < 0 && ")"} />
                        ) : ("$0")
                    }
                  </span>
                </td>
              </tr>}
              {detailedInfo.condition === 0 && <tr>
                <td>&nbsp;</td>
                <td colSpan="6" className={classes.textRight}>Capitalized Interest Payback</td>
                <td className={classes.textRight}>
                  <span className={classNames(detailedInfo['totalCapitilizedInterestPayback'] < 0 && classes.minusValue)}>
                    {
                      detailedInfo['totalCapitilizedInterestPayback'] !== undefined ?
                        (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalCapitilizedInterestPayback']} prefix={detailedInfo['totalCapitilizedInterestPayback'] >= 0 ? "$" : "($"} suffix={detailedInfo['totalCapitilizedInterestPayback'] < 0 && ")"} />
                        ) : ("$0")
                    }
                  </span>
                </td>
              </tr>}
              {detailedInfo.condition === 0 && <tr className={classes.incomeBorderNew}>
                <td>&nbsp;</td>
                <td colSpan="6" className={classNames(classes.strongText, classes.textRight)}>Total Deductible Student Loan Interest</td>
                <td className={classNames(classes.strongText, classes.textRight)}>
                  {
                    detailedInfo['InterestExpenses']['Student Loans'] !== undefined ?
                      (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['InterestExpenses']['Student Loans']} prefix={detailedInfo['InterestExpenses']['Student Loans'] >= 0 ? "$" : "($"} suffix={detailedInfo['InterestExpenses']['Student Loans'] < 0 && ")"} />
                      ) : ("$0")
                  }
                </td>
              </tr>}
              {detailedInfo.condition === 0 && <tr>
                <td>&nbsp;</td>
              </tr>}
              {detailedInfo.condition === 0 && <tr>
                <td>&nbsp;</td>
                <td colspan="2" className={classNames(classes.strongText, classes.textCenter, classes.widthThreeZero)}>Income Ranges ({detailedInfo.Status})</td>
                <td className={classNames(classes.strongText, classes.textCenter, classes.widthTwoZero)}>Difference</td>
                <td className={classNames(classes.strongText, classes.textCenter, classes.widthTwoZero)}>Divisor</td>
                <td className={classNames(classes.strongText, classes.textCenter, classes.widthOneZero)}>Reduction&nbsp;Factor</td>
                <td className={classNames(classes.strongText, classes.textCenter, classes.widthOneZero)}>Less</td>
                <td className={classNames(classes.strongText, classes.textCenter)}>Deduction</td>
              </tr>}
              {detailedInfo.condition === 0 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.textRight, classes.tdBorder, classes.bdRightZero)}>
                  {
                    detailedInfo['DefaultData1'] && detailedInfo['DefaultData1'][0] !== undefined ?
                      (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['DefaultData1'] && detailedInfo['DefaultData1'][0]} prefix={detailedInfo['DefaultData1'] && detailedInfo['DefaultData1'][0] >= 0 ? "$" : "($"} suffix={detailedInfo['DefaultData1'] && detailedInfo['DefaultData1'][0] < 0 && ")"} />
                      ) : ("$0")
                  }
                </td>
                <td className={classNames(classes.tdBlue, classes.textRight, classes.tdBorder)}>
                  {
                    detailedInfo['DefaultData2'] && detailedInfo['DefaultData2'][0] !== undefined ?
                      (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['DefaultData2'] && detailedInfo['DefaultData2'][0]} prefix={detailedInfo['DefaultData2'] && detailedInfo['DefaultData2'][0] >= 0 ? "$" : "($"} suffix={detailedInfo['DefaultData2'] && detailedInfo['DefaultData2'][0] < 0 && ")"} />
                      ) : ("$0")
                  }
                </td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.textRight, classes.tdBorder)}>
                  {detailedInfo.row1 === 1 &&
                    <span>
                      {
                        detailedInfo['CompleteData']['Iteration Data'][0]['Deduction'] !== undefined ?
                          (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['CompleteData']['Iteration Data'][0]['Deduction']} prefix={detailedInfo['CompleteData']['Iteration Data'][0]['Deduction'] >= 0 ? "$" : "($"} suffix={detailedInfo['CompleteData']['Iteration Data'][0]['Deduction'] < 0 && ")"} />
                          ) : ("$0")
                      }
                    </span>}
                  {detailedInfo.row1 === 0 && <span>&nbsp;</span>}
                </td>
              </tr>}
              {detailedInfo.condition === 0 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.textRight, classes.tdBorder, classes.bdRightZero)}>
                  {
                    detailedInfo['DefaultData1'] && detailedInfo['DefaultData1'][1] !== undefined ?
                      (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['DefaultData1'] && detailedInfo['DefaultData1'][1]} prefix={detailedInfo['DefaultData1'] && detailedInfo['DefaultData1'][1] >= 0 ? "$" : "($"} suffix={detailedInfo['DefaultData1'] && detailedInfo['DefaultData1'][1] < 0 && ")"} />
                      ) : ("$0")
                  }
                </td>
                <td className={classNames(classes.textRight, classes.tdBorder)}>
                  {
                    detailedInfo['DefaultData2'] && detailedInfo['DefaultData2'][1] !== undefined ?
                      (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['DefaultData2'] && detailedInfo['DefaultData2'][1]} prefix={detailedInfo['DefaultData2'] && detailedInfo['DefaultData2'][1] >= 0 ? "$" : "($"} suffix={detailedInfo['DefaultData2'] && detailedInfo['DefaultData2'][1] < 0 && ")"} />
                      ) : ("$0")
                  }
                </td>

                {detailedInfo.row2 === 1 &&
                  <td className={classNames(classes.textRight, classes.tdBorder)}>
                    {
                      detailedInfo['CompleteData']['Iteration Data'][1]['Difference'] !== undefined ?
                        (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['CompleteData']['Iteration Data'][1]['Difference']} prefix={detailedInfo['CompleteData']['Iteration Data'][1]['Difference'] >= 0 ? "$" : "($"} suffix={detailedInfo['CompleteData']['Iteration Data'][1]['Difference'] < 0 && ")"} />
                        ) : ("$0")
                    }
                  </td>}
                {detailedInfo.row2 === 0 && <td className={classes.tdBorder}>&nbsp;</td>}

                {detailedInfo.row2 === 1 && <td className={classNames(classes.textRight, classes.tdBorder)}>
                  {
                    detailedInfo['CompleteData']['Iteration Data'][1]['Divisor'] !== undefined ?
                      (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['CompleteData']['Iteration Data'][1]['Divisor']} prefix={detailedInfo['CompleteData']['Iteration Data'][1]['Divisor'] >= 0 ? "$" : "($"} suffix={detailedInfo['CompleteData']['Iteration Data'][1]['Divisor'] < 0 && ")"} />
                      ) : ("$0")
                  }
                </td>}
                {detailedInfo.row2 === 0 && <td className={classes.tdBorder}>&nbsp;</td>}

                {detailedInfo.row2 === 1 && <td className={classNames(classes.textRight, classes.tdBorder)}>
                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={(detailedInfo['CompleteData']['Iteration Data'][1]['Difference'] / detailedInfo['CompleteData']['Iteration Data'][1]['Divisor']) * 100} suffix='%' />

                </td>}
                {detailedInfo.row2 === 0 && <td className={classes.tdBorder}>&nbsp;</td>}

                {detailedInfo.row2 === 1 && <td className={classNames(classes.textRight, classes.tdBorder)}>
                  <span className={classNames(-((detailedInfo['CompleteData']['Iteration Data'][1]['Difference'] / detailedInfo['CompleteData']['Iteration Data'][1]['Divisor']) * detailedInfo['CompleteData']['Iteration Data'][0]['Interest']) < 0 && classes.minusValue)}>
                    {-((detailedInfo['CompleteData']['Iteration Data'][1]['Difference'] / detailedInfo['CompleteData']['Iteration Data'][1]['Divisor']) * detailedInfo['CompleteData']['Iteration Data'][0]['Interest']) >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-((detailedInfo['CompleteData']['Iteration Data'][1]['Difference'] / detailedInfo['CompleteData']['Iteration Data'][1]['Divisor']) * detailedInfo['CompleteData']['Iteration Data'][0]['Interest'])} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(-((detailedInfo['CompleteData']['Iteration Data'][1]['Difference'] / detailedInfo['CompleteData']['Iteration Data'][1]['Divisor']) * detailedInfo['CompleteData']['Iteration Data'][0]['Interest']))} prefix="($" suffix=")" />
                    )}
                  </span>

                </td>}
                {detailedInfo.row2 === 0 && <td className={classes.tdBorder}>&nbsp;</td>}

                {detailedInfo.row2 === 1 && <td className={classes.tdBorder}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['CompleteData']['Iteration Data'][1]['Deduction']} prefix='$' /></td>}
                {detailedInfo.row2 === 0 && <td className={classes.tdBorder}>&nbsp;</td>}
              </tr>}
              {detailedInfo.condition === 0 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.textRight, classes.tdBorder, classes.bdRightZero)}>
                  {
                    detailedInfo['DefaultData1'] && detailedInfo['DefaultData1'][2] !== undefined ?
                      (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['DefaultData1'] && detailedInfo['DefaultData1'][2]} prefix={detailedInfo['DefaultData1'] && detailedInfo['DefaultData1'][2] >= 0 ? "$" : "($"} suffix={detailedInfo['DefaultData1'] && detailedInfo['DefaultData1'][2] < 0 && ")"} />
                      ) : ("$0")
                  }
                  +</td>
                <td className={classes.tdBorder}>&nbsp;</td>
                <td className={classes.tdBorder}>&nbsp;</td>
                <td className={classes.tdBorder}>&nbsp;</td>
                <td className={classes.tdBorder}>&nbsp;</td>
                <td className={classes.tdBorder}>&nbsp;</td>
                {detailedInfo.row3 === 1 && <td className={classes.tdBorder}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['CompleteData']['Iteration Data'][2]['Deduction']} prefix='$' /></td>}
                {detailedInfo.row3 === 0 && <td className={classes.tdBorder}>&nbsp;</td>}
              </tr>}
              {detailedInfo.condition === 0 && <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td className={classNames(classes.textRight, classes.strongText)}>
                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={(detailedInfo['CompleteData'] && detailedInfo['CompleteData']['Iteration Data']) && detailedInfo['CompleteData']['Iteration Data'][0]['Deduction'] +
                    (detailedInfo['CompleteData'] && detailedInfo['CompleteData']['Iteration Data']) && detailedInfo['CompleteData']['Iteration Data'][1]['Deduction'] +
                    (detailedInfo['CompleteData'] && detailedInfo['CompleteData']['Iteration Data']) && detailedInfo['CompleteData']['Iteration Data'][2]['Deduction']} prefix='$' />

                </td>
              </tr>}
              {detailedInfo.condition === 0 && <tr>
                <td>&nbsp;</td>
              </tr>}
              {detailedInfo.condition === 0 && <tr>
                <td>&nbsp;</td>
                <td colSpan="6" className={classes.textRight}>Student Loan Interest Adjustment</td>
                <td className={classes.textRight}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={(detailedInfo['CompleteData'] && detailedInfo['CompleteData']['Iteration Data']) && detailedInfo['CompleteData']['Iteration Data'][0]['Deduction'] +
                  (detailedInfo['CompleteData'] && detailedInfo['CompleteData']['Iteration Data']) && detailedInfo['CompleteData']['Iteration Data'][1]['Deduction'] +
                  (detailedInfo['CompleteData'] && detailedInfo['CompleteData']['Iteration Data']) && detailedInfo['CompleteData']['Iteration Data'][2]['Deduction']} prefix='$' />
                </td>
              </tr>}
              {detailedInfo.condition === 0 && <tr>
                <td>&nbsp;</td>
                <td colSpan="6" className={classes.textRight}><i>Max Student Loan Interest Adjustment</i></td>
                <td className={classes.textRight}><i>
                  {
                    detailedInfo['CompleteData'] && detailedInfo['CompleteData']['Max Deduction'] !== undefined ?
                      (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['CompleteData'] && detailedInfo['CompleteData']['Max Deduction']} prefix={detailedInfo['CompleteData'] && detailedInfo['CompleteData']['Max Deduction'] >= 0 ? "$" : "($"} suffix={detailedInfo['CompleteData'] && detailedInfo['CompleteData']['Max Deduction'] < 0 && ")"} />
                      ) : ("$0")
                  }
                </i></td>
              </tr>}
              <tr className={classes.incomeBorder}>
                <td>&nbsp;</td>
                <td colSpan="6" className={classes.textRight}>Total Student Loan Interest Adjustment</td>
                <td className={classes.textRight}>
                  {
                    detailedInfo['Adjustments'] && detailedInfo['Adjustments']['Student Loan Interest'] !== undefined ?
                      (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Adjustments'] && detailedInfo['Adjustments']['Student Loan Interest']} prefix={detailedInfo['Adjustments'] && detailedInfo['Adjustments']['Student Loan Interest'] >= 0 ? "$" : "($"} suffix={detailedInfo['Adjustments'] && detailedInfo['Adjustments']['Student Loan Interest'] < 0 && ")"} />
                      ) : ("$0")
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogName === 'showDetailPopupMortgageDeductions' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.mortgageDialogNew}
      >
        <DialogContent>

          <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td colSpan="6">Home Mortgage Interest, Loan Points & Origination Fees, & Private Mortgage Insurance (PMI)</td>
                <td>Year {detailedInfo['Year']}</td>
              </tr>
              {((detailedInfo.condition1 !== 1 && detailedInfo.condition2 !== 1) || detailedInfo.condition === 1) && <tr>
                <td>&nbsp;</td>
              </tr>}
              <tr>
                <td colSpan="8" className={classNames(classes.strongText, classes.textCenter)}>Mortgage Interest</td>
              </tr>
              {detailedInfo.existingHomeInterestExpenseCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Average Existing Home Mortgage Balance in Year  {detailedInfo['Year']}</td>
                <td>
                  <span className={classNames(detailedInfo['existingHomeMortgageBalanceAvg'] < 0 && classes.minusValue)}>

                    {detailedInfo['existingHomeMortgageBalanceAvg'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['existingHomeMortgageBalanceAvg']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['existingHomeMortgageBalanceAvg']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.existingHomeInterestExpenseCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Interest Rate</td>
                <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['existingHomeMortgageBalanceInterestRate']} suffix='%' /></td>
              </tr>}
              {detailedInfo.existingHomeInterestExpenseCondition === 1 && <tr >
                <td>=/+</td>
                <td>Existing Home Mortgage Interest Expense</td>
                <td>
                  <span className={classNames(detailedInfo['existingHomeInterestExpense'] < 0 && classes.minusValue)}>

                    {detailedInfo['existingHomeInterestExpense'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['existingHomeInterestExpense']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['existingHomeInterestExpense']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.condition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Average Home Mortgage Balance in Year {detailedInfo['Year']}</td>
                <td>
                  <span className={classNames(detailedInfo['homeMortgage'] < 0 && classes.minusValue)}>

                    {detailedInfo['homeMortgage'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['homeMortgage']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['homeMortgage']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.condition === 1 && <tr>
                <td>&nbsp;</td>
                <td>Interest Rate</td>
                <td><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['homeMortgageInterestRate']} suffix='%' /></td>
              </tr>}
              {detailedInfo.condition === 1 && <tr>
                <td>=/+</td>
                <td>Home Mortgage Interest Expense</td>
                <td>

                  <span className={classNames(detailedInfo['mortgage1Interest'] < 0 && classes.minusValue)}>

                    {detailedInfo['mortgage1Interest'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['mortgage1Interest']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['mortgage1Interest']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              <tr className={classes.borderTopBottom}>
                <td className={classNames(classes.strongText, classes.textCenter)}>=/+</td>
                <td colspan="6" className={classes.strongText}>Total Home Mortgage Interest Expense</td>
                <td className={classNames(classes.strongText, classes.textRight)}>
                  <span className={classNames((detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense']) < 0 && classes.minusValue)}>

                    {(detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense']) >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={(detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense'])} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense'])} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              {detailedInfo.condition === 1 && <tr>
                <td>&nbsp;</td>
                <td><i>Total Home Mortgage Value Limit</i></td>
                <td>
                  <i>
                    <span className={classNames(detailedInfo['totalHomeMortgageValueLimit'] < 0 && classes.minusValue)}>

                      {detailedInfo['totalHomeMortgageValueLimit'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalHomeMortgageValueLimit']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalHomeMortgageValueLimit']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </i>
                </td>
              </tr>}
              {detailedInfo.condition === 1 && <tr>
                <td>&nbsp;</td>
                <td><i>Reduction % (Mortgage Limit / Average Mortgage Balance)</i></td>
                <td>{detailedInfo['reductionPercentage']}%</td>
              </tr>}
              {detailedInfo.condition === 1 && <tr >
                <td>&nbsp;</td>
                <td><i>Reduction to Home Mortgage Interest</i></td>
                <td>
                  <i>
                    <span className={classNames((-((detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense']) / 100) * detailedInfo['reductionPercentage']) < 0 && classes.minusValue)}>

                      {(-((detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense']) / 100) * detailedInfo['reductionPercentage']) >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={(-((detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense']) / 100) * detailedInfo['reductionPercentage'])} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(-((detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense']) / 100) * detailedInfo['reductionPercentage'])} prefix="($" suffix=")" />
                      )}
                    </span>
                  </i>
                </td>
              </tr>}
              {detailedInfo.condition === 1 && <tr >
                <td>=/+</td>
                <td clas>Total Home Mortgage Interest Expense</td>
                <td>

                  <i>
                    <span className={classNames((-((detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense']) / 100) * detailedInfo['reductionPercentage']) + (detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense']) < 0 && classes.minusValue)}>

                      {(-((detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense']) / 100) * detailedInfo['reductionPercentage']) + (detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense']) >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={(-((detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense']) / 100) * detailedInfo['reductionPercentage']) + (detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense'])} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(-((detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense']) / 100) * detailedInfo['reductionPercentage']) + (detailedInfo['mortgage1Interest'] + detailedInfo['existingHomeInterestExpense'])} prefix="($" suffix=")" />
                      )}
                    </span>
                  </i>
                </td>
              </tr>}


              <tr>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <td className={classNames(classes.strongText, classes.textCenter)} colspan="8">Loan Points & Origination Fees</td>
              </tr>
              <tr data-ng-if="detailedInfo.pointsPaidForHomeCondition === 1" className={classes.incomeBorderNew}>
                <td>&nbsp;</td>
                <td colspan="6" className={classes.strongText}>Home Mortgage</td>
                <td className={classNames(classes.strongText, classes.textRight)}>
                  <span className={classNames(detailedInfo.homePropertyMortgage < 0 && classes.minusValue)}>

                    {detailedInfo.homePropertyMortgage >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.homePropertyMortgage} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.homePropertyMortgage} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              {detailedInfo.pointsPaidForHomeCondition === 1 && <tr>
                <td>x</td>
                <td>Loan Points</td>
                <td>
                  <span>{detailedInfo.homePropertyPoints}%</span>
                </td>
              </tr>}
              {detailedInfo.pointsPaidForHomeCondition === 1 && <tr>
                <td>x</td>
                <td>Origination Fees</td>
                <td>
                  <span>{detailedInfo.originationsPoints}%</span>
                </td>
              </tr>}
              <tr className={classes.borderTopBottom}>
                <td className={classes.strongText}>=/+</td>
                <td colSpan="6" className={classes.strongText}>Loan Points & Origination Fees for Home Mortgage</td>
                <td className={classes.strongText}  >

                  <span className={classNames(detailedInfo.pointsPaidForHome < 0 && classes.minusValue)}>

                    {detailedInfo.pointsPaidForHome >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.pointsPaidForHome} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.pointsPaidForHome} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>

              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td className={classNames(classes.strongText, classes.textCenter)} colspan="8">Private Mortgage Insurance (PMI)</td>
              </tr>
              <tr className={classes.incomeBorder}>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="6">Adjusted Gross Income</td>
                <td className={classNames(classes.strongText, classes.textRight)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeAdjustedGrossIncome']} prefix="$" /></td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="6">Private Mortgage Insurance (PMI)</td>
                <td className={classNames(classes.strongText, classes.textRight)}>${(detailedInfo['privateMortgageInsuranceValue'] + detailedInfo['existingHomeInsuranceValue'])}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textCenter, classes.widthThreeZero)} colSpan="2">Income Ranges <i>({detailedInfo.Status})</i></td>
                <td className={classNames(classes.strongText, classes.textCenter, classes.widthTwoZero)}>Difference</td>
                <td className={classNames(classes.strongText, classes.textCenter, classes.widthTwoZero)}>Divisor</td>
                <td className={classNames(classes.strongText, classes.textCenter, classes.widthOneZero)}>Reduction&nbsp;Factor</td>
                <td className={classNames(classes.strongText, classes.textCenter, classes.widthOneZero)}>Less</td>
                <td className={classNames(classes.strongText, classes.textCenter)}>Deduction</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.tdBorder, classes.textRight)}>${detailedInfo['incomeRanges1']}</td>
                <td className={classNames(classes.tdBorder, classes.textRight)}>${detailedInfo['incomeRanges2']}</td>
                <td className={classNames(classes.tdBorder, classes.textRight)}>&nbsp;</td>
                <td className={classNames(classes.tdBorder, classes.textRight)}>&nbsp;</td>
                <td className={classNames(classes.tdBorder, classes.textRight)}>&nbsp;</td>
                <td className={classNames(classes.tdBorder, classes.textRight)}>&nbsp;</td>
                <td className={classNames(classes.tdBorder, classes.textRight)}>
                  <span className={classNames(detailedInfo['deduction1'] < 0 && classes.minusValue)}>

                    {detailedInfo['deduction1'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['deduction1']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['deduction1']} prefix="($" suffix=")" />
                    )}
                  </span>

                  {detailedInfo['deduction1'] === 0 && <span>&nbsp;</span>}
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.tdBorder, classes.textRight)}>${detailedInfo['incomeRanges3']}</td>
                <td className={classNames(classes.tdBorder, classes.textRight)}>${detailedInfo['incomeRanges4']}</td>
                <td className={classNames(classes.tdBorder, classes.textRight)}>

                  <span className={classNames(detailedInfo['difference1'] < 0 && classes.minusValue)}>

                    {detailedInfo['difference1'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['difference1']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['difference1']} prefix="($" suffix=")" />
                    )}
                  </span>

                  {detailedInfo['difference1'] === 0 && <span>&nbsp;</span>}
                </td>
                <td className={classNames(classes.tdBorder, classes.textRight)}>
                  <span className={classNames(detailedInfo['divisor1'] < 0 && classes.minusValue)}>

                    {detailedInfo['divisor1'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['divisor1']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['divisor1']} prefix="($" suffix=")" />
                    )}
                  </span>

                  {detailedInfo['divisor1'] === 0 && <span>&nbsp;</span>}
                </td>
                <td className={classNames(classes.tdBorder, classes.textRight)}>
                  <span className={classNames(detailedInfo['reductionfactor1'] < 0 && classes.minusValue)}>

                    {detailedInfo['reductionfactor1'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['reductionfactor1']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['reductionfactor1']} prefix="($" suffix=")" />
                    )}
                  </span>

                  {detailedInfo['reductionfactor1'] === 0 && <span>&nbsp;</span>}
                </td>
                <td className={classNames(classes.tdBorder, classes.textRight)}>
                  <span className={classNames(detailedInfo['less1'] < 0 && classes.minusValue)}>

                    {detailedInfo['less1'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['less1']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['less1']} prefix="($" suffix=")" />
                    )}
                  </span>

                  {detailedInfo['less1'] === 0 && <span>&nbsp;</span>}
                </td>
                <td className={classNames(classes.tdBorder, classes.textRight)}>

                  <span className={classNames(detailedInfo['deduction2'] < 0 && classes.minusValue)}>

                    {detailedInfo['deduction2'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['deduction2']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['deduction2']} prefix="($" suffix=")" />
                    )}
                  </span>

                  {detailedInfo['deduction2'] === 0 && <span>&nbsp;</span>}
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder, classes.textRight)}>${detailedInfo['incomeRanges4']}+</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder, classes.textRight)}>
                  <span className={classNames(detailedInfo['deduction3'] < 0 && classes.minusValue)}>

                    {detailedInfo['deduction3'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['deduction3']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['deduction3']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td colSpan="6" className={classNames(classes.strongText, classes.textRight)}>Private Mortgage Insurance (PMI) Deduction</td>
                <td className={classes.strongText}>${detailedInfo['totalDeductions']}</td>
              </tr>
              <tr className={classes.incomeBorder}>
                <td>&nbsp;</td>
              </tr>
              {detailedInfo.selectedColumn === 'Home Mortgage Interest, Loan Points & Origination Fees, & PMI' &&
                <tr>
                  <td className={classes.strongText}>=</td>
                  <td colSpan="6" className={classes.strongText}>Total Home Mortgage Interest, Loan Points & Origination Fees, & PMI Deduction</td>
                  <td className={classes.strongText}>
                    {/* {console.log('phrudhvi', detailedInfo)} */}
                    <span className={classNames(detailedInfo['Itemized Deductions']['Home Mortgage Interest, Loan Points & Origination Fees, & PMI'] < 0 && classes.minusValue)}>

                      {detailedInfo['Itemized Deductions']['Home Mortgage Interest, Loan Points & Origination Fees, & PMI'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Itemized Deductions']['Home Mortgage Interest, Loan Points & Origination Fees, & PMI']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Itemized Deductions']['Home Mortgage Interest, Loan Points & Origination Fees, & PMI']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </td>
                </tr>
              }
            </tbody>
          </table>

        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogName === 'homePropertyTaxesDeductions' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.saltDeductionDialog}
      >
        <DialogContent>
          <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textCenter)} colSpan="2">Home Property Taxes, State & Local Income Taxes (SALT) Deduction</td>
                <td className={classNames(classes.strongText, classes.textRight)}>Year {detailedInfo['Year']}</td>
              </tr>
              <tr>
                <td colspan="4">&nbsp;</td>
              </tr>
              {detailedInfo.existingHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td>Home Property Taxes</td>
              </tr>}
              {detailedInfo.existingHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td colspan="4">&nbsp;</td>
              </tr>}
              {detailedInfo.existingHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classes.textCenter}>Existing Home Value in {detailedInfo['startyearFirst']}</td>
                <td className={classes.textCenter}>Annual Property Taxes</td>
                <td className={classes.textCenter}>Property Tax Rate</td>
              </tr>}
              {detailedInfo.existingHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td class="text-center value-portion no-border-right">{detailedInfo.existingHomeValueFirstYear}</td>
                <td class="text-center value-portion no-border-right">
                  <span className={classNames(detailedInfo.annualPropertyTaxes < 0 && classes.minusValue)}>

                    {detailedInfo.annualPropertyTaxes >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.annualPropertyTaxes} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.annualPropertyTaxes} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
                <td class="text-center value-portion">
                  <span>{detailedInfo.propertyTaxRate}%</span>
                </td>
              </tr>}
              {detailedInfo.existingHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td colSpan="4">&nbsp;</td>
              </tr>}
              {detailedInfo.existingHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td className={classes.textCenter}>&nbsp;</td>
                <td colSpan="2">{detailedInfo['Year']} Existing Home Value </td>
                <td className={classes.textRightr}>

                  <span className={classNames(detailedInfo['existingHomeValueCurrentYear'] < 0 && classes.minusValue)}>

                    {detailedInfo['existingHomeValueCurrentYear'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['existingHomeValueCurrentYear']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['existingHomeValueCurrentYear']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.existingHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td>x</td>
                <td colSpan="2">Property Tax Rate</td>
                <td className={classes.textRight}>{detailedInfo['propertyTaxRateCurrentYear']}%
                </td>
              </tr>}
              {detailedInfo.existingHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td>=/+</td>
                <td className={classes.strongText} colSpan="2">{detailedInfo['Year']} Existing Home Property Taxes</td>
                <td className={classNames(classes.strongText, classes.textCenter)}>
                  <span className={classNames(detailedInfo['existingHomePropertyTaxCurrentYear'] < 0 && classes.minusValue)}>

                    {detailedInfo['existingHomePropertyTaxCurrentYear'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['existingHomePropertyTaxCurrentYear']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['existingHomePropertyTaxCurrentYear']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.existingHomePropertyTaxCurrentYearCondition === 1 && detailedInfo.newHomePropertyTaxCurrentYearCondition === 1) && <tr>
                <td colspan="4">&nbsp;</td>
              </tr>}
              {detailedInfo.newHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classes.textCenter}>Home Value / Purchase Year</td>
                <td className={classes.textCenter}>Property Tax Rate</td>
                <td className={classes.textCenter}>Annual Property Taxes</td>
              </tr>}
              {detailedInfo.newHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td class="text-center value-portion no-border-right">{detailedInfo.BuyingAHomeVal} / {detailedInfo.BuyingAHomePuchaseYear}</td>
                <td class="text-center value-portion no-border-right">{detailedInfo.propertyTax}%</td>
                <td class="text-center value-portion">
                  <span className={classNames(detailedInfo.propertyTaxesCalcs < 0 && classes.minusValue)}>

                    {detailedInfo.propertyTaxesCalcs >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.propertyTaxesCalcs} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.propertyTaxesCalcs} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.newHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td colspan="4">&nbsp;</td>
              </tr>}
              {detailedInfo.newHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td className={classes.textCenter}>&nbsp;</td>
                <td colSpan="2">{detailedInfo['Year'] - 1} Home Value</td>
                <td className={classes.textRight}>
                  <span className={classNames(detailedInfo['newHomeAssetValueCurrentYear'] < 0 && classes.minusValue)}>

                    {detailedInfo['newHomeAssetValueCurrentYear'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['newHomeAssetValueCurrentYear']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['newHomeAssetValueCurrentYear']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.newHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td>x</td>
                <td colspan="2">Property Tax Rate</td>
                <td className={classes.textRight}>{detailedInfo['homePropertyTaxes']}%
                </td>
              </tr>}
              {detailedInfo.newHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td>=/+</td>
                <td className={classes.strongText} colSpan="2">{detailedInfo['Year']} Home Property Taxes</td>
                <td className={classNames(classes.strongText, classes.textRight)}>
                  <span className={classNames(detailedInfo['newHomePropertyTaxCurrentYear'] < 0 && classes.minusValue)}>

                    {detailedInfo['newHomePropertyTaxCurrentYear'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['newHomePropertyTaxCurrentYear']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['newHomePropertyTaxCurrentYear']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.newHomePropertyTaxCurrentYearCondition === 1 && <tr>
                <td>=</td>
                <td className={classes.strongText} colspan="2">{detailedInfo['Year']} Total Home Property Taxes Deduction</td>
                <td className={classNames(classes.strongText, classes.textRight)}>

                  <span className={classNames(detailedInfo['homePropertyTaxesCurrentValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['homePropertyTaxesCurrentValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['homePropertyTaxesCurrentValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['homePropertyTaxesCurrentValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {(detailedInfo.stateAndLocalIncomeTaxesCondition === 1 && detailedInfo.newHomePropertyTaxCurrentYearCondition === 1) && <tr>
                <td colSpan="4">&nbsp;</td>
              </tr>}
              {detailedInfo.stateAndLocalIncomeTaxesCondition === 1 && <tr>
                <td colSpan="4" className={classes.strongText}>State & Local Income Taxes</td>
              </tr>}
              {detailedInfo.stateAndLocalIncomeTaxesCondition === 1 && <tr>
                <td colSpan="4">&nbsp;</td>
              </tr>}
              {detailedInfo.stateAndLocalIncomeTaxesCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classes.strongText} colSpan="2">Adjusted Gross Income</td>
                <td className={classNames(classes.strongText, classes.textRight)}>

                  <span className={classNames(detailedInfo['stateAndLocalAdjustedGrossIncome'] < 0 && classes.minusValue)}>

                    {detailedInfo['stateAndLocalAdjustedGrossIncome'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['stateAndLocalAdjustedGrossIncome']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['stateAndLocalAdjustedGrossIncome']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.stateAndLocalIncomeTaxesCondition === 1 && <tr>
                <td colSpan="4">&nbsp;</td>
              </tr>}
              {detailedInfo.stateAndLocalIncomeTaxesCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td colSpan="2">State Tax Rate ({detailedInfo['stateNameLocalTaxes']} Average)</td>
                <td className={classes.textRight}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['stateNameLocalTaxesValue']} suffix="%" /></td>
              </tr>}
              {detailedInfo.stateAndLocalIncomeTaxesCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td colSpan="2">Local Tax Rate (Average)</td>
                <td className={classes.textRight}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['cityNameLocalTaxesValue']} suffix="%" /></td>
              </tr>}
              {detailedInfo.stateAndLocalIncomeTaxesCondition === 1 && <tr>
                <td>x</td>
                <td className={classes.strongText} colSpan="2">Total State & Local Tax Rate</td>
                <td className={classNames(classes.strongText, classes.textRight)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={(detailedInfo['totalTaxesValue'])} suffix="%" /></td>
              </tr>}
              {detailedInfo.stateAndLocalIncomeTaxesCondition === 1 && <tr>
                <td>=/+</td>
                <td className={classes.strongText} colSpan="2">Total State & Local Taxes</td>
                <td className={classNames(classes.strongText, classes.textRight)}>
                  <span className={classNames(detailedInfo['totalTaxesValueFinal'] < 0 && classes.minusValue)}>

                    {detailedInfo['totalTaxesValueFinal'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalTaxesValueFinal']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalTaxesValueFinal']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              <tr className={classes.topSingleBorder}>
                <td colsSan="4">&nbsp;</td>
              </tr>
              <tr className={classes.topSingleBorder}>
                <td>=</td>
                <td className={classes.strongText} colspan="2">Total SALT</td>
                <td className={classNames(classes.strongText, classes.textRight)}>
                  <span className={classNames(detailedInfo['totalSaltValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['totalSaltValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalSaltValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalSaltValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              <tr className={classes.topSingleBorder}>
                <td>&nbsp;</td>
                <td colSpan="2" className={classes.padLeft10}><em>Max SALT Deduction</em></td>
                <td className={classes.textRight}>

                  <em>
                    <span className={classNames(detailedInfo['maxSaltDeduction'] < 0 && classes.minusValue)}>

                      {detailedInfo['maxSaltDeduction'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['maxSaltDeduction']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['maxSaltDeduction']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </em>
                </td>
              </tr>
              <tr className={classes.incomeBorder}>
                <td>=</td>
                <td className={classes.strongText} colSpan="2">SALT Deduction</td>
                <td className={classNames(classes.strongText, classes.textRight)}>

                  <span className={classNames(detailedInfo['finalSaltDeductionValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['finalSaltDeductionValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['finalSaltDeductionValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['finalSaltDeductionValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>



      <Dialog
        open={openDialogName === 'taxCreditsDetailPopup' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.americanOppurtunity}
      >
        <DialogContent>
          {openTaxCreditsDetailModel === 'american-opportunity' && <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td className={classNames(classes.textCenter, classes.strongText)} colSpan="5">American Opportunity or Lifetime Learning Tax Credit</td>
                <td className={classNames(classes.textRight, classes.strongText)} colSpan="2">Year {detailedInfo['Year']}</td>
              </tr>
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td colSpan="8" className={classNames(classes.textCenter, classes.strongText)}>American Opportunity</td>
              </tr>}
              <tr className={classes.topSingleBorder}>
                <td colSpan="8">&nbsp;</td>
              </tr>
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.textRight, classes.strongText)} colSpan="5">Total Gross Income (excluding Rental Income)	</td>
                <td className={classNames(classes.textRight, classes.strongText)} colSpan="2"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalGrossIncomeValue']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.textRight, classes.strongText)} colSpan="5">Tuition & Fees</td>
                <td className={classNames(classes.textRight, classes.strongText)} colSpan="2"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['tuitionAndFeesValue']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr className={classes.incomeBorderNew}>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="5">100% of First $2,000 of Expenses</td>
                <td className={classes.textRight} colSpan="2"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['firstExpenses']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="5">25% of Next $2,000 of Expenses</td>
                <td className={classes.textRight} colSpan="2"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['nextExpenses']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr className={classes.incomeBorderNew}>
                <td>&nbsp;</td>
                <td className={classNames(classes.textRight, classes.strongText)} colSpan="5">Available Tax Credit</td>
                <td className={classNames(classes.textRight, classes.strongText)} colSpan="2"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['firstExpenses'] + detailedInfo['nextExpenses']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="5">Previous Credits Taken (Max of 4)</td>
                <td className={classes.textRight} colSpan="2">${detailedInfo['previousCreditsTakenValue'] > 0 || detailedInfo['previousCreditsTakenValue'] < 0 ? detailedInfo['previousCreditsTakenValue'] : 0}</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="5">College / Other (Qualify / Do Not Qualify)</td>
                <td className={classes.textRight} colSpan="2">{detailedInfo['collegeQualify']}</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="5">Independent / Dependent (Qualify / Do Not Qualify)</td>
                <td className={classes.textRight} colSpan="2">{detailedInfo['independentQualify']}</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr className={classes.incomeBorderNew}>
                <td>&nbsp;</td>
                <td className={classNames(classes.textRight, classes.strongText)} colSpan="5">Must satisfy all of the above to Qualify</td>
                <td className={classNames(classes.textRight, classes.strongText)} colSpan="2">{detailedInfo['mustSatisfyValue']}</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td colspan="8">&nbsp;</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.textCenter, classes.strongText)} colSpan="2" width="30%">Income Ranges <i>({detailedInfo.Status})</i></td>
                <td className={classNames(classes.textCenter, classes.strongText)} width="20%">Difference</td>
                <td className={classNames(classes.textCenter, classes.strongText)} width="20%">Divisor</td>
                <td className={classNames(classes.textCenter, classes.strongText)} width="10%">Reduction Factor</td>
                <td className={classNames(classes.textCenter, classes.strongText)} width="10%">Less</td>
                <td className={classNames(classes.textCenter, classes.strongText)}>Credit</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr className={classes.valuesRow}>
                <td>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.textRight, classes.tdBorder, classes.bdRightZero)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeRange1']} prefix="$" /></td>
                <td className={classNames(classes.tdBlue, classes.textRight, classes.tdBorder)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeRange2']} prefix="$" /></td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder, classes.textRight)}>
                  {detailedInfo.creditValueRange1 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['creditValue1']} prefix="$" /></span>}
                  {detailedInfo.creditValueRange1 === 0 && <span>&nbsp;</span>}
                </td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr className={classes.valuesRow}>
                <td>&nbsp;</td>
                <td class="text-right value-portion no-border-right"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeRange3']} prefix="$" /></td>
                <td class="text-right value-portion"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeRange4']} prefix="$" /></td>
                <td class="text-right value-portion">
                  {detailedInfo.differenceValueRange1 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['differenceValue1']} prefix="$" /></span>}
                  {detailedInfo.differenceValueRange1 === 0 && <span>&nbsp;</span>}
                </td>
                <td class="text-right value-portion">
                  {detailedInfo.divisorValueRange1 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['divisorValue1']} prefix="$" /></span>}
                  {detailedInfo.divisorValueRange1 === 0 && <span>&nbsp;</span>}
                </td>
                <td class="text-right value-portion">
                  {detailedInfo.reducationFactorRange1 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['reducationFactorValue1']} suffix="%" /></span>}
                  {detailedInfo.reducationFactorRange1 === 0 && <span>&nbsp;</span>}
                </td>
                <td class="text-right value-portion">
                  {detailedInfo.lessRange1 === 1 && <span>
                    <span className={classNames(detailedInfo['lessValue1'] < 0 && classes.minusValue)}>

                      {detailedInfo['lessValue1'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['lessValue1']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['lessValue1']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </span>}
                  {detailedInfo.lessRange1 === 0 && <span>&nbsp;</span>}
                </td>
                <td class="text-right value-portion">
                  {detailedInfo.creditValueRange2 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['creditValue2']} prefix="$" /></span>}
                  {detailedInfo.creditValueRange2 === 0 && <span>&nbsp;</span>}
                </td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr className={classes.valuesRow}>
                <td>&nbsp;</td>
                <td class="text-right value-portion no-border-right"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeRange4']} prefix="$" />+</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">
                  {detailedInfo.creditValueRange3 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['creditValue3']} prefix="$" /></span>}
                  {detailedInfo.creditValueRange3 === 0 && <span>&nbsp;</span>}
                </td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="6">Available Tax Credit after Phaseouts</td>
                <td className={classNames(classes.strongText, classes.textRight)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['availableTaxCreditAfterPhaseout']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td colspan="8">&nbsp;</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="6">Federal Income Taxes</td>
                <td className={classNames(classes.strongText, classes.textRight)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['availableTaxCreditAfterPhaseout1']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td colSpan="8" className={classes.textRight}><em>Need federal taxes to offset a tax credit; 40% of Available Credit can be refunded as cash (max refund of $1,000)</em></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>}
              <tr className={classes.borderTopBottom}>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="6">AOTC</td>
                <td className={classNames(classes.strongText, classes.textRight)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['finalAOTCValue']} prefix="$" /></td>
              </tr>
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr className={classes.bottomBorder}>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textCenter)} colSpan="7">Lifetime Learning</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="5">Total Gross Income (excluding Rental Income)</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="2"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalGrossIncomeValue1']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="5">Tuition & Fees</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="2"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['tuitionAndFeesValue1']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr className={classes.incomeBorderNew}>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="5">20% of Up To $10,000 of Expenses</td>
                <td className={classes.textRight} colSpan="2"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['firstExpenses1']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr className={classes.incomeBorderNew}>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="5">Available Tax Credit</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="2"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['firstExpenses1']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="5">If AOTC taken this year, Do Not Qualify</td>
                <td className={classes.textRight} colSpan="2">{detailedInfo['collegeQualify1']}</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="5">Independent / Dependent (Qualify / Do Not Qualify)</td>
                <td className={classes.textRight} colSpan="2">{detailedInfo['independentQualify1']}</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr className={classes.incomeBorderNew}>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="5">Must satisfy all of the above to Qualify</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="2">{detailedInfo['mustSatisfyValue1']}</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textCenter)} colSpan="2" width="30%">Income Ranges <i>({detailedInfo.Status1})</i></td>
                <td className={classNames(classes.strongText, classes.textCenter)} width="20%">Difference</td>
                <td className={classNames(classes.strongText, classes.textCenter)} width="20%">Divisor</td>
                <td className={classNames(classes.strongText, classes.textCenter)} width="10%">Reduction Factor</td>
                <td className={classNames(classes.strongText, classes.textCenter)} width="10%">Less</td>
                <td className={classNames(classes.strongText, classes.textCenter)}>Credit</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr className={classes.valuesRow}>
                <td>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.textRight, classes.tdBorder, classes.bdRightZero)} ><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeRange11']} prefix="$" /></td>
                <td className={classNames(classes.tdBlue, classes.textRight, classes.tdBorder)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeRange21']} prefix="$" /></td>
                <td className={classNames(classes.tdBlue, classes.textRight, classes.tdBorder, classes.bdRightZero)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder)}  >
                  {detailedInfo.creditValueRange11 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['creditValue11']} prefix="$" /></span>}
                  {detailedInfo.creditValueRange11 === 0 && <span>&nbsp;</span>}
                </td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr className={classes.valuesRow}>
                <td>&nbsp;</td>
                <td class="text-right value-portion no-border-right"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeRange31']} prefix="$" /></td>
                <td class="text-right value-portion"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeRange41']} prefix="$" /></td>
                <td class="text-right value-portion">
                  {detailedInfo.differenceValueRange11 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['differenceValue11']} prefix="$" /></span>}
                  {detailedInfo.differenceValueRange11 === 0 && <span>&nbsp;</span>}
                </td>
                <td class="text-right value-portion">
                  {detailedInfo.divisorValueRange11 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['divisorValue11']} prefix="$" /></span>}
                  {detailedInfo.divisorValueRange11 === 0 && <span>&nbsp;</span>}
                </td>
                <td class="text-right value-portion">
                  {detailedInfo.reducationFactorRange11 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['reducationFactorValue11']} suffix="%" /></span>}
                  {detailedInfo.reducationFactorRange11 === 0 && <span>&nbsp;</span>}
                </td>
                <td class="text-right value-portion">
                  {detailedInfo.lessRange11 === 1 && <span>

                    <span className={classNames(detailedInfo['lessValue11'] < 0 && classes.minusValue)}>

                      {detailedInfo['lessValue11'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['lessValue11']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['lessValue11']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </span>}
                  {detailedInfo.lessRange11 === 0 && <span>&nbsp;</span>}
                </td>
                <td class="text-right value-portion">
                  {detailedInfo.creditValueRange21 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['creditValue21']} prefix="$" /></span>}
                  {detailedInfo.creditValueRange21 === 0 && <span>&nbsp;</span>}
                </td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr className={classes.valuesRow}>
                <td>&nbsp;</td>
                <td class="text-right value-portion no-border-right"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeRange41']} prefix="$" />+</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion no-border-right">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">
                  {detailedInfo.creditValueRange31 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['creditValue31']} prefix="$" /></span>}
                  {detailedInfo.creditValueRange31 === 0 && <span>&nbsp;</span>}
                </td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="6">Federal Income Taxes</td>
                <td className={classNames(classes.strongText, classes.textRight)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['availableTaxCreditAfterPhaseout1']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr>
                <td colSpan="8" className={classes.textRight}><em>Need federal taxes to offset a tax credit; none of this tax credit is refundable.</em></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition1 === 1 && <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>}
              <tr className={classes.topSingleBorder}>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="6">Lifetime Learning Tax Credit</td>
                <td className={classNames(classes.strongText, classes.textRight)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['finalAOTCValue'] < 0 ? -(detailedInfo['finalAOTCValue']) : detailedInfo['finalAOTCValue']} prefix="$" /></td>
              </tr>
            </tbody>
          </table>}
          {openTaxCreditsDetailModel === 'lifetime-learning' && <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td class="strong text-center" colSpan="5">Lifetime Learning Tax Credit</td>
                <td class="strong text-right" colSpan="2">Year {detailedInfo['Year']}</td>
              </tr>
              <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="5">Total Gross Income</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="2"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalGrossIncomeValue']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="5">Tuition & Fees</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="2"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['tuitionAndFeesValue']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="5">20% of Up To $10,000 of Expenses</td>
                <td className={classes.textRight} colSpan="2"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['firstExpenses']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr className={classes.incomeBorderNew}>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="5">Available Tax Credit</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="2"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['firstExpenses']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="5">If AOTC taken this year, Do Not Qualify</td>
                <td className={classes.textRight} colSpan="2">{detailedInfo['collegeQualify']}</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="5">Independent / Dependent (Qualify / Do Not Qualify)</td>
                <td className={classes.textRight} colSpan="2">{detailedInfo['independentQualify']}</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr className={classes.incomeBorderNew}>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="5">Must satisfy all of the above to Qualify</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="2">{detailedInfo['mustSatisfyValue']}</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textCenter)} colSpan="2" width="30%">Income Ranges <i>({detailedInfo.Status})</i></td>
                <td className={classNames(classes.strongText, classes.textCenter)} width="20%">Difference</td>
                <td className={classNames(classes.strongText, classes.textCenter)} width="20%">Divisor</td>
                <td className={classNames(classes.strongText, classes.textCenter)} width="10%">Reduction Factor</td>
                <td className={classNames(classes.strongText, classes.textCenter)} width="10%">Less</td>
                <td className={classNames(classes.strongText, classes.textCenter)}>Credit</td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr className={classes.valuesRow}>
                <td>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.textRight, classes.tdBorder, classes.bdRightZero)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeRange1']} prefix="$" /></td>
                <td className={classNames(classes.tdBlue, classes.textRight, classes.tdBorder)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeRange2']} prefix="$" />{ }</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder, classes.bdRightZero)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder, classes.bdRightZero)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder, classes.bdRightZero)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder, classes.bdRightZero)}>&nbsp;</td>
                <td className={classNames(classes.tdBlue, classes.tdBorder, classes.bdRightZero)}>
                  {detailedInfo.creditValueRange1 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['creditValue1']} prefix="$" /></span>}
                  {detailedInfo.creditValueRange1 === 0 && <span>&nbsp;</span>}
                </td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr className={classes.valuesRow}>
                <td>&nbsp;</td>
                <td class="text-right value-portion no-border-right"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeRange3']} prefix="$" /></td>
                <td class="text-right value-portion">${detailedInfo['incomeRange4']}</td>
                <td class="text-right value-portion">
                  {detailedInfo.differenceValueRange1 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['differenceValue1']} prefix="$" /></span>}
                  {detailedInfo.differenceValueRange1 === 0 && <span>&nbsp;</span>}
                </td>
                <td class="text-right value-portion">
                  {detailedInfo.divisorValueRange1 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['divisorValue1']} prefix="$" /></span>}
                  {detailedInfo.divisorValueRange1 === 0 && <span>&nbsp;</span>}
                </td>
                <td class="text-right value-portion">
                  {detailedInfo.reducationFactorRange1 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['reducationFactorValue1']} suffix="%" /></span>}
                  {detailedInfo.reducationFactorRange1 === 0 && <span>&nbsp;</span>}
                </td>
                <td class="text-right value-portion">
                  {detailedInfo.lessRange1 === 1 && <span>
                    <span className={classNames(detailedInfo['lessValue1'] < 0 && classes.minusValue)}>

                      {detailedInfo['lessValue1'] >= 0 ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['lessValue1']} prefix="$" />
                      ) : (
                        <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['lessValue1']} prefix="($" suffix=")" />
                      )}
                    </span>
                  </span>}
                  {detailedInfo.lessRange1 === 0 && <span>&nbsp;</span>}
                </td>
                <td class="text-right value-portion">
                  {detailedInfo.creditValueRange2 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['creditValue2']} prefix="$" /></span>}
                  {detailedInfo.creditValueRange2 === 0 && <span>&nbsp;</span>}
                </td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr className={classes.valuesRow}>
                <td>&nbsp;</td>
                <td class="text-right value-portion no-border-right"><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['incomeRange4']} prefix="$" />+</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion no-border-right">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">
                  {detailedInfo.creditValueRange3 === 1 && <span><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['creditValue3']} prefix="$" /></span>}
                  {detailedInfo.creditValueRange3 === 0 && <span>&nbsp;</span>}
                </td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="6">Available Tax Credit after Phaseouts</td>
                <td className={classNames(classes.strongText, classes.textRight)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['availableTaxCreditAfterPhaseout']} prefix="$" /></td>
              </tr>}
              {detailedInfo.tuitionAndFeesValueCondition === 1 && <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>}
              <tr className={classes.incomeBorderNew}>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textRight)} colSpan="6">Lifetime Learning Tax Credit</td>
                <td className={classNames(classes.strongText, classes.textRight)}><NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(detailedInfo['finalAOTCValue'])} prefix="$" /></td>
              </tr>
            </tbody>
          </table>}
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogName === 'childTaxCreditPopup' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.childDialog}
      >
        <DialogContent>
          <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td class="strong text-center" colspan="6">Child Tax Credit</td>
                <td class="strong text-right">Year {detailedInfo['Year']}</td>
              </tr>
              <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="6"># of Children</td>
                <td className={classes.textRight}>${detailedInfo['numberOfChildren']}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="6">Credit per Child</td>
                <td className={classes.textRight}>${detailedInfo['creditPerChild']}</td>
              </tr>
              <tr className={classes.topSingleBorder}>
                <td>&nbsp;</td>
                <td className={classNames(classes.textRight, classes.strongText)} colSpan="6">Available Tax Credit</td>
                <td className={classNames(classes.textRight, classes.strongText)}>

                  <span className={classNames(detailedInfo['availableTaxCredit'] < 0 && classes.minusValue)}>

                    {detailedInfo['availableTaxCredit'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['availableTaxCredit']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['availableTaxCredit']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className={classNames(classes.textRight, classes.strongText)} colSpan="6">Total Gross Income (excluding Rental Income)</td>
                <td className={classNames(classes.textRight, classes.strongText)}>

                  <span className={classNames(detailedInfo['childTotalGrossIncome'] < 0 && classes.minusValue)}>

                    {detailedInfo['childTotalGrossIncome'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['childTotalGrossIncome']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['childTotalGrossIncome']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>
              <tr className={classes.incomeRangesData}>
                <td>&nbsp;</td>
                <td colSpan="2" >Income Ranges <i>({detailedInfo.Status})</i></td>
                <td>Difference (1)</td>
                <td>Divisor (2)</td>
                <td>Factor (1/2=3)</td>
                <td>Multiple (4)</td>
                <td>Phaseout (3*4)</td>
              </tr>
              <tr className={classNames(classes.valuesRow, classes.blueRowBackgroundNew)}>
                <td>&nbsp;</td>
                <td class="text-right value-portion no-border-right">${detailedInfo['incomeRange1']}</td>
                <td class="text-right value-portion">${detailedInfo['incomeRange2']}</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">
                  {detailedInfo.crow1 === 1 && <span>${detailedInfo['firstPhaseout1']}</span>}
                  {detailedInfo.crow1 === 0 && <span>&nbsp;</span>}
                </td>
              </tr>
              <tr className={classes.valuesRow}>
                <td>&nbsp;</td>
                <td class="text-right value-portion no-border-right">${detailedInfo['incomeRange3']}</td>
                <td class="text-right value-portion">${detailedInfo['incomeRange4']}</td>

                {detailedInfo.crow2 === 1 && <td class="text-right value-portion">${detailedInfo['firstDifference1']}</td>}
                {detailedInfo.crow2 === 0 && <td class="text-right value-portion">&nbsp;</td>}

                {detailedInfo.crow2 === 1 && <td class="text-right value-portion">${detailedInfo['firstDivisor1']}</td>}
                {detailedInfo.crow2 === 0 && <td class="text-right value-portion">&nbsp;</td>}

                {detailedInfo.crow2 === 1 && <td class="text-center value-portion">${detailedInfo['firstFactor1']}%</td>}
                {detailedInfo.crow2 === 0 && <td class="text-right value-portion">&nbsp;</td>}

                {detailedInfo.crow2 === 1 && <td class="text-right value-portion">

                  <span className={classNames(detailedInfo['firstMultiple1'] < 0 && classes.minusValue)}>

                    {detailedInfo['firstMultiple1'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['firstMultiple1']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['firstMultiple1']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>}
                {detailedInfo.crow2 === 0 && <td class="text-right value-portion">&nbsp;</td>}

                {detailedInfo.crow2 === 1 && <td class="text-right value-portion" >${detailedInfo['firstPhaseout2']}</td>}
                {detailedInfo.crow2 === 0 && <td class="text-right value-portion">&nbsp;</td>}
              </tr>
              <tr className={classes.valuesRow}>
                <td>&nbsp;</td>
                <td class="text-right value-portion no-border-right">${detailedInfo['incomeRange4']}+</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                <td class="text-right value-portion">&nbsp;</td>
                {detailedInfo.crow3 === 1 && <td class="text-right value-portion">

                  <span className={classNames(detailedInfo['firstPhaseout3'] < 0 && classes.minusValue)}>

                    {detailedInfo['firstPhaseout3'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['firstPhaseout3']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['firstPhaseout3']} prefix="($" suffix=")" />
                    )}
                  </span>

                </td>}
                {detailedInfo.crow3 === 0 && <td class="text-right value-portion">&nbsp;</td>}
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="6"># of Children</td>
                <td className={classes.textRight}>${detailedInfo['numberOfChildren']}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="6">Total Phaseout Reduction</td>
                <td className={classes.textRight}>

                  <span className={classNames(detailedInfo['totalPhaseOutReduction'] < 0 && classes.minusValue)}>

                    {detailedInfo['totalPhaseOutReduction'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalPhaseOutReduction']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalPhaseOutReduction']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              <tr className={classes.topSingleBorder}>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="6">Tax Credit after Phaseouts</td>
                <td className={classes.textRight}>

                  <span className={detailedInfo['totalPhaseOutReduction'] + detailedInfo['availableTaxCredit'] < 0 && classes.minusValue}>

                    {(detailedInfo['totalPhaseOutReduction'] + detailedInfo['availableTaxCredit']) >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={(detailedInfo['totalPhaseOutReduction'] + detailedInfo['availableTaxCredit'])} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(detailedInfo['totalPhaseOutReduction'] + detailedInfo['availableTaxCredit'])} prefix="($" suffix=")" />
                    )}
                  </span>

                </td>
              </tr>
              <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>
              <tr className={classes.topSingleBorder}>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="6">
                  <div className={classes.infoImage} uib-tooltip="Child tax credit can be applied in two forms: as a credit to your federal income taxes and as a refund. If your federal income taxes are less than the tax credit after phaseouts, you qualify for a refund of up to $1,400 per child. If your federal income taxes are greater than the tax credit after phaseouts, the child tax credit will lower your federal income taxes. For more information, check out the 'FC + Taxes' document, which is available on the Tax Statement at the top of the page. Also, review the definitions in the Boost My Awareness section of the family module." tooltip-placement="left">
                    {/* <img src="external/img/info-icon.svg" alt="" /> */}
                    <img src={infoIcon} alt="infoicon" />
                  </div>&nbsp;&nbsp;
                  Federal Income Taxes

                </td>
                <td className={classes.textRight}>

                  <span className={classNames(detailedInfo['childTotalTaxes'] < 0 && classes.minusValue)}>

                    {detailedInfo['childTotalTaxes'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['childTotalTaxes']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['childTotalTaxes']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              <tr className={classes.topSingleBorder}>
                <td>&nbsp;</td>
                <td className={classNames(classes.textRight, classes.strongText)} colSpan="6">Tax Credit Applied before Refund</td>
                <td className={classNames(classes.textRight, classes.strongText)}>

                  <span className={classNames(detailedInfo['taxCreditAppliedBeforeRefund'] < 0 && classes.minusValue)}>

                    {detailedInfo['taxCreditAppliedBeforeRefund'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['taxCreditAppliedBeforeRefund']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['taxCreditAppliedBeforeRefund']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className={classes.textRight} colSpan="6">Tax Credit Remaining</td>
                <td>

                  <span className={classNames(((detailedInfo['totalPhaseOutReduction'] + detailedInfo['availableTaxCredit']) - detailedInfo['taxCreditAppliedBeforeRefund']) < 0 && classes.minusValue)}>

                    {((detailedInfo['totalPhaseOutReduction'] + detailedInfo['availableTaxCredit']) - detailedInfo['taxCreditAppliedBeforeRefund']) >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={((detailedInfo['totalPhaseOutReduction'] + detailedInfo['availableTaxCredit']) - detailedInfo['taxCreditAppliedBeforeRefund'])} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-((detailedInfo['totalPhaseOutReduction'] + detailedInfo['availableTaxCredit']) - detailedInfo['taxCreditAppliedBeforeRefund'])} prefix="($" suffix=")" />
                    )}
                  </span>

                </td>
              </tr>
              <tr className={classes.topSingleBorder}>
                <td>&nbsp;</td>
                <td className={classNames(classes.textRight, classes.strongText)} colSpan="6">
                  <div className={classes.infoImage} uib-tooltip="The max refund for the child tax credit as of 2018 is $1,400. Each year, the max refund is usually increased by inflation. So, the FC increases the max refund limit each year by your annual inflation input. For more about the child tax credit, check out the Boost My Awareness section in the family module." tooltip-placement="left">
                    <img src={infoIcon} alt="infoicon" />
                  </div>&nbsp;&nbsp;
                  Tax Credit Applied as a Refund (max of {detailedInfo.taxCreditAppliedAsRefundLabel} per child)</td>
                <td className={classNames(classes.textRight, classes.strongText)}>

                  <span className={classNames(detailedInfo['taxCreditAppliedAsRefund'] < 0 && classes.minusValue)}>

                    {detailedInfo['taxCreditAppliedAsRefund'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['taxCreditAppliedAsRefund']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['taxCreditAppliedAsRefund']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan="8">&nbsp;</td>
              </tr>
              <tr className={classes.topSingleBorder}>
                <td>&nbsp;</td>
                <td className={classNames(classes.textRight, classes.strongText)} colSpan="6">Total Child Tax Credit</td>
                <td className={classNames(classes.textRight, classes.strongText)}>

                  <span className={classNames(detailedInfo['finalTaxCreditValue'] < 0 && classes.minusValue)}>

                    {detailedInfo['finalTaxCreditValue'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['finalTaxCreditValue']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['finalTaxCreditValue']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              <tr className={classes.topSingleBorder}>
                <td colSpan="8">&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td colSpan="7" className={classes.textLeft}>1. Difference rounded down to nearest thousand.</td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openDialogName === 'taxOnSaleDetailPopup' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.taxDialogSixZero}
      >
        <DialogContent>
          <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td className={classNames(classes.strongText, classes.textCenter)}>Tax on Real Estate Sale</td>
                <td className={classNames(classes.strongText, classes.textRight)}>Year {detailedInfo['Year']}</td>
              </tr>
              {(detailedInfo.condition === 0 && detailedInfo.conditionGain === 0) && <tr>
                <td colSpan="3">&nbsp;</td>
              </tr>}
              {detailedInfo.condition === 1 && <tr>
                <td colSpan="3">&nbsp;</td>
              </tr>}
              {detailedInfo.condition === 1 && <tr>
                <td colSpan="3" class="strong">Real Estate Investment Property Sale</td>
              </tr>}
              {detailedInfo.condition === 1 && <tr>
                <td colSpan="3">&nbsp;</td>
              </tr>}
              {detailedInfo.condition === 1 && <tr>
                <td className={classes.textCenter}>&nbsp;</td>
                <td>Gain (Loss) on Sale</td>
                <td className={classes.textRight}>
                  <span className={classNames(detailedInfo['gainOnSale'] < 0 && classes.minusValue)}>

                    {detailedInfo['gainOnSale'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['gainOnSale']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['gainOnSale']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.condition === 1 && <tr>
                <td className={classes.textCenter}>x</td>
                <td>Tax Rate on Gain</td>
                <td className={classes.textRight}>{15}%</td>
              </tr>}
              {detailedInfo.condition === 1 && <tr>
                <td className={classes.textCenter}>=/+</td>
                <td className={classes.strongText}>Tax on Gain on Sale</td>
                <td className={classNames(classes.strongText, classes.textRight)}>
                  <span className={classNames(detailedInfo['taxOnGainOnSale'] < 0 && classes.minusValue)}>

                    {detailedInfo['taxOnGainOnSale'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['taxOnGainOnSale']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['taxOnGainOnSale']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.condition === 1 && <tr>
                <td className={classes.textCenter}>&nbsp;</td>
                <td>Accumulated Depreciation</td>
                <td className={classes.textRight}>

                  <span className={classNames(detailedInfo['accumulatedDepreciation'] < 0 && classes.minusValue)}>

                    {detailedInfo['accumulatedDepreciation'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['accumulatedDepreciation']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['accumulatedDepreciation']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.condition === 1 && <tr>
                <td className={classes.textCenter}>x</td>
                <td>Tax Rate on Accumulated Depreciation</td>
                <td className={classes.textRight}>{25}%</td>
              </tr>}
              {detailedInfo.condition === 1 && <tr>
                <td className={classes.textCenter}>=/+</td>
                <td className={classes.strongText}>Tax on Accumulated Depreciation</td>
                <td className={classNames(classes.strongText, classes.textRight)}>
                  <span className={classNames(detailedInfo['higherEduStudentLoan'] < 0 && classes.minusValue)}>

                    {detailedInfo['higherEduStudentLoan'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['higherEduStudentLoan']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['higherEduStudentLoan']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.condition === 1 && <tr>
                <td className={classes.textCenter}>=</td>
                <td className={classes.strongText}>Total Tax on REI Property Sale</td>
                <td className={classNames(classes.strongText, classes.textRight)}>
                  <span className={classNames(detailedInfo['Investment Property Sale']['Tax on Sale'] < 0 && classes.minusValue)}>

                    {detailedInfo['Investment Property Sale']['Tax on Sale'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['Investment Property Sale']['Tax on Sale']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['Investment Property Sale']['Tax on Sale']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.conditionGain === 1 && <tr>
                <td colSpan="3">&nbsp;</td>
              </tr>}
              {detailedInfo.conditionGain === 1 && <tr>
                <td colSpan="3" className={classes.strongText}>Existing Home Sale</td>
              </tr>}
              {detailedInfo.conditionGain === 1 && <tr>
                <td colSpan="3">&nbsp;</td>
              </tr>}
              {detailedInfo.conditionGain === 1 && <tr>
                <td className={classes.textCenter}>&nbsp;</td>
                <td>Gain (Loss) on Sale</td>
                <td className={classes.textRight}>

                  <span className={classNames(detailedInfo['gainOnSaleExistingHome'] < 0 && classes.minusValue)}>

                    {detailedInfo['gainOnSaleExistingHome'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['gainOnSaleExistingHome']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['gainOnSaleExistingHome']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.conditionGain === 1 && <tr>
                <td className={classes.textCenter}>&nbsp;</td>
                <td>Taxable Gain (Up to $250,000 is Tax-Free if Single)</td>
                <td className={classes.textRight}>
                  <span className={classNames(detailedInfo['taxOnTaxbleGainUp'] < 0 && classes.minusValue)}>

                    {detailedInfo['taxOnTaxbleGainUp'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['taxOnTaxbleGainUp']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['taxOnTaxbleGainUp']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.conditionGain === 1 && <tr>
                <td className={classes.textCenter}>x</td>
                <td>Tax on Taxable Gain (15%)</td>
                <td className={classes.textRight}>
                  <span className={classNames(detailedInfo['taxOnTaxbleGain'] < 0 && classes.minusValue)}>

                    {detailedInfo['taxOnTaxbleGain'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['taxOnTaxbleGain']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['taxOnTaxbleGain']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.conditionGain === 1 && <tr>
                <td className={classes.textCenter}>=/+</td>
                <td className={classes.strongText}>Total Tax on Existing Home Sale</td>
                <td className={classNames(classes.strongText, classes.textRight)}>
                  <span className={classNames(detailedInfo['totalTaxExistingHomeSale'] < 0 && classes.minusValue)}>

                    {detailedInfo['totalTaxExistingHomeSale'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['totalTaxExistingHomeSale']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['totalTaxExistingHomeSale']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              <tr className={classes.incomeBorder}>
                <td class="text-center">=</td>
                <td class="strong">Total Tax on Real Estate Sale</td>
                <td class="strong text-right">

                  <span className={classNames(detailedInfo['finalTotalTaxOnHomeSale'] < 0 && classes.minusValue)}>

                    {detailedInfo['finalTotalTaxOnHomeSale'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['finalTotalTaxOnHomeSale']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['finalTotalTaxOnHomeSale']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogName === 'mortgageDeductionsDetailPopup' ? openDialog : ''}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.mortgageDialogNew}
      >
        <DialogContent>
          <table className={classes.netIncomeTable}>
            <tbody>
              <tr className={classes.popupHeader}>
                <td>&nbsp;</td>
                <td>REI Property Mortgage Interest, Loan Points & Origination Fees, & Depreciation Deduction</td>
                <td class="strong text-right">Year {detailedInfo['Year']}</td>
              </tr>
              <tr>
                <td colspan="3">&nbsp;</td>
              </tr>
              <tr>
                <td className={classNames(classes.strongText, classes.textCenter)} colSpan="3">Mortgage Interest</td>
              </tr>
              {detailedInfo.mortgageInterestExpenseCondtion === 1 && <tr className={classes.topSingleBorder}>
                <td>&nbsp;</td>
                <td>Average REI Property Mortgage Balance in Year {detailedInfo['Year']}</td>
                <td className={classes.textRight}>

                  <span className={classNames(detailedInfo.mortgageBalanceInYearValue < 0 && classes.minusValue)}>

                    {detailedInfo.mortgageBalanceInYearValue >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.mortgageBalanceInYearValue} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.mortgageBalanceInYearValue} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.mortgageInterestExpenseCondtion === 1 && <tr>
                <td>x</td>
                <td>Interest Rate</td>
                <td className={classes.textRight}>
                  <span className={classNames(detailedInfo.mortgageInterestRate < 0 && classes.minusValue)}>

                    {detailedInfo.mortgageInterestRate >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.mortgageInterestRate} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.mortgageInterestRate} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              <tr className={classes.incomeBorder}>
                {detailedInfo.mortgageInterestExpenseCondtion === 1 && <td>=/x</td>}
                {detailedInfo.mortgageInterestExpenseCondtion === 0 && <td>&nbsp;</td>}
                <td class="strong">REI Property Interest Expense</td>
                <td class="strong text-right">

                  <span className={classNames(detailedInfo.mortgageInterestExpenseValue < 0 && classes.minusValue)}>

                    {detailedInfo.mortgageInterestExpenseValue >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.mortgageInterestExpenseValue} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.mortgageInterestExpenseValue} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="8">&nbsp;</td>
              </tr>
              <tr>
                <td className={classNames(classes.strongText, classes.textCenter)} colspan="8">Loan Points & Origination Fees</td>
              </tr>
              {detailedInfo.reiPropertyMortgagePopCondition === 1 && <tr class="tr-border-top">
                <td>&nbsp;</td>
                <td>REI Property Mortgage</td>
                <td class="text-right">

                  <span className={classNames(detailedInfo.reiPropertyMortgagePop < 0 && classes.minusValue)}>

                    {detailedInfo.reiPropertyMortgagePop >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.reiPropertyMortgagePop} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.reiPropertyMortgagePop} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.reiPropertyMortgagePopCondition === 1 && <tr>
                <td>x</td>
                <td>Loan Points</td>
                <td class="text-right">
                  <span>{detailedInfo.reiLoanPointsPop}%</span>
                </td>
              </tr>}
              {detailedInfo.reiPropertyMortgagePopCondition === 1 && <tr>
                <td>x</td>
                <td>Origination Fees</td>
                <td class="text-right">
                  <span>{detailedInfo.reiOriginationFeesPop}%</span>
                </td>
              </tr>}
              <tr className={classes.incomeBorder}>
                {detailedInfo.reiPropertyMortgagePopCondition === 1 && <td>=/+</td>}
                {detailedInfo.reiPropertyMortgagePopCondition === 0 && <td>&nbsp;</td>}
                <td class="strong">Loan Points & Origination Fees for REI Property Mortgage</td>
                <td class="strong text-right">

                  <span className={classNames(detailedInfo.loanPointsAndOrgFeePop < 0 && classes.minusValue)}>

                    {detailedInfo.loanPointsAndOrgFeePop >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.loanPointsAndOrgFeePop} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.loanPointsAndOrgFeePop} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              <tr className={classes.incomeBorder}>
                <td colSpan="3">&nbsp;</td>
              </tr>
              <tr >
                <td className={classNames(classes.strongText, classes.texttCenter)} colSpan="3">Depreciation</td>
              </tr>
              {detailedInfo.annualDepreciationDeductionCondition === 1 && <tr className={classes.topSingleBorder}>
                <td>&nbsp;</td>
                <td>REI Property Value</td>
                <td className={classes.textRight}>

                  <span className={classNames(detailedInfo.reiPropertyValue < 0 && classes.minusValue)}>

                    {detailedInfo.reiPropertyValue >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.reiPropertyValue} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.reiPropertyValue} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>}
              {detailedInfo.annualDepreciationDeductionCondition === 1 && <tr>
                <td>/</td>
                <td>Useful Life</td>
                <td className={classes.textRight}>{detailedInfo.usefulValue} Years</td>
              </tr>}
              <tr className={classes.incomeBorder} >
                {detailedInfo.annualDepreciationDeductionCondition === 1 && <td>=</td>}
                {detailedInfo.annualDepreciationDeductionCondition === 0 && <td>&nbsp;</td>}
                <td class="strong">Annual Depreciation Deduction</td>
                <td class="strong text-right">

                  <span className={classNames(detailedInfo.annualDepreciationDeductionValue < 0 && classes.minusValue)}>

                    {detailedInfo.annualDepreciationDeductionValue >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo.annualDepreciationDeductionValue} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo.annualDepreciationDeductionValue} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>
              <tr className={classes.incomeBorder}>
                <td colspan="3">&nbsp;</td>
              </tr>
              <tr className={classes.incomeBorder}>
                <td class="text-center">=</td>
                <td class="strong">Total REI Property Mortgage Interest, Loan Points & Origination Fees, & Depreciation Deduction</td>
                <td class="strong text-right">

                  <span className={classNames(detailedInfo['MortgageInterestLoanPointsPop'] < 0 && classes.minusValue)}>

                    {detailedInfo['MortgageInterestLoanPointsPop'] >= 0 ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={detailedInfo['MortgageInterestLoanPointsPop']} prefix="$" />
                    ) : (
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-detailedInfo['MortgageInterestLoanPointsPop']} prefix="($" suffix=")" />
                    )}
                  </span>
                </td>
              </tr>

            </tbody>
          </table>
        </DialogContent>
        <DialogActions className={classes.modalBottomFooter}>
          <div className={classes.incomeFooter}>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

InputStatementPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  dispalyObj: PropTypes.string.isRequired,
  rangeList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(InputStatementPopup);
