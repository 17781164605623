function financeExplainedValues() {
  return [
    {
      img: 'investing101-fe-cream.jpg',
      link: 'getting-started-with-investing',
      title: 'Getting Started with Investing',
      posted_date: 'March 2022',
    },
    {
      img: 'fed-reserve-horiz.jpg',
      link: 'will-higher-interest-rates-cool-the-stock-market',
      title: 'Will Higher Interest Rates Cool the Stock Market?',
      posted_date: 'Feb 2022',
    },
    {
      img: 'interest-home-buying-horiz.jpg',
      link: 'interest-rates-and-home-buying',
      title: 'Interest Rates and Home Buying',
      posted_date: 'Feb 2022',
    },
    {
      img: 'market-turbulence-horiz.jpg',
      link: 'stock-market-turbulence',
      title: 'Stock Market Turbulence',
      posted_date: 'Jan 2022',
    },
    {
      img: 'best-worst-2021-horiz.jpg',
      link: 'best-and-worst-2021-investments',
      title: 'Best and Worst 2021 Investments',
      posted_date: 'Jan 2022',
    },
    {
      img: 'inflation-invest-horiz.jpg',
      link: 'inflation-invest',
      title: 'Investing during High Inflation',
      posted_date: 'Dec 2021',
    },
    {
      img: 'keep-up-with-inflation-horiz.jpg',
      link: 'keeping-up-with-inflation',
      title: 'Keeping Up With Inflation',
      posted_date: 'Dec 2021',
    },
    {
      img: 'inflation-horiz.jpg',
      link: 'inflation-and-your-money',
      title: 'Inflation and Your Money',
      posted_date: 'Dec 2021',
    },
    {
      img: 'extra-loan-payment-horiz.jpg',
      link: 'extra-loan-payment',
      title: 'Extra Loan Payment',
      posted_date: 'June 2021',
    },
    {
      img: 'refinance-loan-horiz.jpg',
      link: 'refinance-a-loan',
      title: 'Refinance a Loan',
      posted_date: 'June 2021',
    },
    {
      img: 'emergency-fund-horiz.jpg',
      link: 'emergency-fund',
      title: 'Emergency Fund',
      posted_date: 'April 2021',
    },
    {
      img: 'am-rescue-plan-horiz.jpg',
      link: 'american-rescue-plan',
      title: 'American Rescue Plan',
      posted_date: 'March 2021 | Special Edition',
    },
    {
      img: 'going-long-horiz.jpg',
      link: 'going-long-stocks',
      title: 'Going Long Stocks',
      posted_date: 'Feb 2021',
    },
    {
      img: 'short-selling-horiz.jpg',
      link: 'short-selling-stocks',
      title: 'Short Selling Stocks',
      posted_date: 'Feb 2021',
    },
    {
      img: 'mutual-fund-horiz.jpg',
      link: 'mutual-fund',
      title: 'Mutual Fund',
      posted_date: 'Feb 2021',
    },
    {
      img: 'etf-horiz.jpg',
      link: 'etf',
      title: 'Exchange-Traded Fund (ETF)',
      posted_date: 'Jan 2021',
    },
    {
      img: 'turbulent-markets-horiz.jpg',
      link: 'turbulent-markets',
      title: 'Investing in Turbulent Markets',
      posted_date: 'Jan 2021',
    },
    {
      img: 'ipo-horiz.jpg',
      link: 'ipo',
      title: 'Initial Public Offering (IPO)',
      posted_date: 'Dec 2020',
    },
    {
      img: '401k-roth401k-horiz.jpg',
      link: '401k-vs-roth401k',
      title: '401(k) vs Roth 401(k)',
      posted_date: 'Nov 2020',
    },
    {
      img: '2021-fed-inc-tax-horiz.jpg',
      link: 'federal-income-taxes',
      title: '2021 Federal Income Taxes',
      posted_date: 'Nov 2020',
    },
    {
      img: 'be-a-millionaire-horiz.jpg',
      link: 'how-to-become-a-millionaire',
      title: 'How to Become a Millionaire',
      posted_date: 'Oct 2020',
    },
    {
      img: 'spending-smart-horiz.jpg',
      link: 'spending-smart',
      title: 'Spending Smart',
      posted_date: 'Oct 2020',
    },
    {
      img: 'monetary-policy-part2-horiz.jpg',
      link: 'monetary-policy-part2',
      title: 'Monetary Policy: Part 2',
      posted_date: 'Oct 2020',
    },
    {
      img: 'monetary-policy-horiz.jpg',
      link: 'monetary-policy',
      title: 'Monetary Policy',
      posted_date: 'Oct 2020',
    },
    {
      img: 'federal-reserve-horiz.jpg',
      link: 'federal-reserve',
      title: 'Federal Reserve',
      posted_date: 'Sep 2020',
    },
    {
      img: 'correction-horiz.jpg',
      link: 'correction',
      title: 'Correction',
      posted_date: 'Sep 2020',
    },
    {
      img: 'dividend-horiz.jpg',
      link: 'dividend',
      title: 'Dividend',
      posted_date: 'Sep 2020',
    },
    {
      img: 'stock-split-horiz.jpg',
      link: 'stock-split',
      title: 'Stock Split',
      posted_date: 'Aug 2020',
    },
    {
      img: 'tax-deductions-horiz-new.jpg',
      link: 'standard-or-itemized-deductions',
      title: 'Standard or Itemized Deductions',
      posted_date: 'Aug 2020',
    },

    {
      img: 'tax-brackets-horiz.jpg',
      link: 'federal-income-tax-brackets',
      title: 'Federal Income Tax Brackets',
      posted_date: 'Aug 2020',
    },
    {
      img: 'heals-act-horiz.jpg',
      link: 'heals-act-highlights',
      title: 'HEALS Act Highlights',
      posted_date: 'July 2020 | Special Edition',
    },

    {
      img: 'opportunity-cost-horiz.jpg',
      link: 'opportunity-cost',
      title: 'Opportunity Cost',
      posted_date: 'July 2020',
    },

    {
      img: 'gap-year-analysis-horiz.jpg',
      link: 'gap-year-analysis',
      title: 'Gap Year Analysis',
      posted_date: 'July 2020',
    },
    {
      img: 'gap-year-horiz.jpg',
      link: 'gap-year',
      title: 'Gap Year',
      posted_date: 'June 2020',
    },
    {
      img: 'bull-vs-bear-horiz.jpg',
      link: 'bull-vs-bear-market',
      title: 'Bull vs Bear Market',
      posted_date: 'June 2020',
    },
    {
      img: 'volatility-horiz.jpg',
      link: 'volatility',
      title: 'Volatility',
      posted_date: 'June 2020',
    },
    {
      img: 's&p-horiz.jpg',
      link: 'sandp-500',
      title: 'S&P 500',
      posted_date: 'June 2020',
    },
    {
      img: 'market-cap.jpg',
      link: 'market-capitalization',
      title: 'Market Capitalization',
      posted_date: 'May 2020',
    },
    {
      img: 'dow-jones-horiz.jpg',
      link: 'dow-jones-industrial-average',
      title: 'Dow Jones Industrial Average',
      posted_date: 'May 2020',
    },
    {
      img: 'unemployment-rate.jpg',
      link: 'unemployment-rate',
      title: 'Unemployment Rate',
      posted_date: 'May 2020',
    },
    {
      img: 'dollar-cost-averaging.jpg',
      link: 'dollar-cost-averaging',
      title: 'Dollar-Cost Averaging (DCA)',
      posted_date: 'May 2020',
    },
    {
      img: 'risk-reward-horiz.jpg',
      link: 'risk-v-reward',
      title: 'Risk v Reward',
      posted_date: 'April 2020',
    },

    {
      img: 'cares-unemployment-horiz.jpg',
      link: 'cares-act-unemployment-benefits',
      title: 'CARES Act Unemployment Benefits',
      posted_date: 'April 2020 | Special Edition',
    },
    {
      img: 'CARES-tax-credit-horiz.jpg',
      link: 'cares-act-tax-credit',
      title: 'CARES Act Tax Credit',
      posted_date: 'April 2020 | Special Edition',
    },
    {
      img: 'student-loan-relief-horiz.jpg',
      link: 'federal-student-loan-relief',
      title: 'Federal Student Loan Relief',
      posted_date: 'March 2020 | Special Edition',
    },
    {
      img: 'covid-19-horiz.jpg',
      link: 'covid-19-and-your-money',
      title: 'COVID-19 and Your Money',
      posted_date: 'March 2020 | Special Edition',
    },
    {
      img: 'student-loan-repayment2-horiz.jpg',
      link: 'income-based-repayment',
      title: 'Income-Based Repayment',
      posted_date: 'March 2020',
    },
    {
      img: 'student-loan-repay1-horiz.jpg',
      link: 'student-loan-repayment-plans',
      title: 'Student Loan Repayment Plans',
      posted_date: 'March 2020',
    },
    {
      img: 'grace-period-horiz.jpg',
      link: 'grace-period',
      title: 'Grace Period (Student Loans)',
      posted_date: 'March 2020',
    },
    {
      img: 'subsidized-vs-unsubsidized-horiz.jpg',
      link: 'subsidized-vs-unsubsidized-loans',
      title: 'Subsidized vs Unsubsidized Loans',
      posted_date: 'Feb 2020',
    },
    {
      img: 'student-loans-horiz.jpg',
      link: 'student-loan',
      title: 'Student Loan',
      posted_date: 'Feb 2020',
    },
    {
      img: 'car-insurance-horiz.jpg',
      link: 'car_insurance_requirements',
      title: 'Car Insurance Requirements',
      posted_date: 'Feb 2020',
    },
    {
      img: 'lease-payment-horiz.jpg',
      link: 'whats_in_a_car_lease_payment',
      title: "What's in a Car Lease Payment?",
      posted_date: 'Feb 2020',
    },
    {
      img: 'buy-lease-cost-analysis-horiz.jpg',
      link: 'buy-or-lease-a-cost-analysis',
      title: 'Buy or Lease? A Cost Analysis',
      posted_date: 'Jan 2020',
    },
    {
      img: 'leasing_vs_buying_a_car.jpg',
      link: 'leasing-vs-buying-a-car',
      title: 'Leasing vs Buying a Car',
      posted_date: 'Jan 2020',
    },
    {
      img: 'car-lease-horiz.jpg',
      link: 'car-lease',
      title: 'Car Lease',
      posted_date: 'Jan 2020',
    },
    {
      img: 'save-now-save-later-horiz-v2.jpg',
      link: 'save-now-save-later',
      title: 'What If I Save Now vs Save Later',
      posted_date: 'Jan 2020',
    },
    {
      img: 'new-year-plan-horiz.jpg',
      link: 'money-resolutions',
      title: 'Money Resolutions',
      posted_date: 'Dec 2019',
    },
    {
      img: 'power-financial-literacy-horiz.jpg',
      link: 'power-of-financial-literacy',
      title: 'Power of Financial Literacy',
      posted_date: 'Dec 2019',
    },
    {
      img: 'risk-tolerance-horiz.jpg',
      link: 'risk-tolerance',
      title: 'Risk Tolerance',
      posted_date: 'Dec 2019',
    },
    {
      img: 'diversification-horiz.jpg',
      link: 'diversification',
      title: 'Diversification',
      posted_date: 'Dec 2019',
    },
    {
      img: 'investment-portfolio-horiz.jpg',
      link: 'investment-portfolio',
      title: 'Investment Portfolio',
      posted_date: 'Nov 2019',
    },
    {
      img: 'stock-exchange-horiz.jpg',
      link: 'stock-exchange',
      title: 'Stock Exchange',
      posted_date: 'Nov 2019',
    },
    {
      img: 'stock-market-horiz.jpg',
      link: 'stock_market',
      title: 'Stock Market',
      posted_date: 'Nov 2019',
    },
    {
      img: 'smart-goals-horiz.png',
      link: 'smart_goals',
      title: 'SMART Goals',
      posted_date: 'Nov 2019',
    },
    {
      img: 'auto-home-renters-horiz.jpg',
      link: 'asset_insurance',
      title: 'Insuring Your Car and Home',
      posted_date: 'Oct 2019',
    },
    {
      img: 'life-insurance-horiz.jpg',
      link: 'life_insurance_101',
      title: 'Life Insurance 101',
      posted_date: 'Oct 2019',
    },
    {
      img: 'health-insurance-horiz.jpg',
      link: 'health_insurance',
      title: 'Health Insurance 101',
      posted_date: 'Oct 2019',
    },
    {
      img: 'retirement-horiz.jpg',
      link: 'retirement-accounts',
      title: 'Retirement Accounts',
      posted_date: 'Oct 2019',
    },
    {
      img: 'funds-alternative-inv-horiz.jpg',
      link: 'funds-alternative-investments',
      title: 'Funds and Alternative Investments',
      posted_date: 'Oct 2019',
    },
    {
      img: 'stocks-bonds-horiz.jpg',
      link: 'stocks-bonds',
      title: 'Stocks and Bonds',
      posted_date: 'Sep 2019',
    },
    {
      img: 'investing-101-horiz.jpg',
      link: 'investing-101',
      title: 'Investing 101',
      posted_date: 'Sep 2019',
    },
    {
      img: 'tax-filing-horiz.jpg',
      link: 'filing-your-taxes',
      title: 'Filing Your Taxes',
      posted_date: 'Sep 2019',
    },
    {
      img: 'federal-inc-taxes-horiz.jpg',
      link: 'federal_income_taxes',
      title: 'Federal Income Taxes',
      posted_date: 'Sep 2019',
    },
    {
      img: 'personal-income-taxes-horiz.jpg',
      link: 'personal-income-taxes',
      title: 'Personal Income Taxes 101',
      posted_date: 'Aug 2019',
    },
    {
      img: 'typeof_income.jpg',
      link: 'three-types-of-income',
      title: '3 Types of Income',
      posted_date: 'Aug 2019',
    },
    {
      img: 'credit-score-myths-horiz.jpg',
      title: '5 Credit Score Myths',
      link: 'credit-score-myths',
      posted_date: 'Aug 2019',
    },
    {
      img: 'calculate-credit-score-horiz.jpg',
      title: 'How Your Credit Score Is Calculated',
      link: 'credit-score-calculated',
      posted_date: 'Aug 2019',
    },
    {
      img: 'credit-score-horiz.jpg',
      title: "What's a Credit Score?",
      link: 'credit-score',
      posted_date: 'July 2019',
    },
    {
      img: 'interest-rate-horiz.jpg',
      title: "What's an Interest Rate?",
      link: 'interest-rate',
      posted_date: 'July 2019',
    },
    {
      img: 'lower-loan-costs-horiz.jpg',
      title: 'How to Lower the Cost of a Loan',
      link: 'lower-cost-of-a-loan',
      posted_date: 'July 2019',
    },
    {
      img: 'interest-horiz.jpg',
      title: "What's Interest?",
      link: 'interest',
      posted_date: 'July 2019',
    },
    {
      img: 'loan-payment-horiz.jpg',
      title: "What's in a Loan Payment?",
      link: 'loan-payment',
      posted_date: 'July 2019',
    },
    {
      img: 'loan-principal-horiz.jpg',
      title: "What's Loan Principal?",
      link: 'loan-principal',
      posted_date: 'June 2019',
    },
    {
      img: 'loan-horiz.jpg',
      title: "What's a Loan?",
      link: 'loan',
      posted_date: 'June 2019',
    },
    {
      img: 'budget-horiz.png',
      title: "What's a Budget?",
      link: 'budget',
      posted_date: 'June 2019',
    },
    {
      img: 'check.png',
      title: 'How to Write a Check',
      link: 'write-a-check',
      posted_date: 'June 2019',
    },
    {
      img: 'high-yield.jpg',
      link: 'high-yield-savings-account',
      title: "What's a High-Yield Savings Account",
      posted_date: 'May 2019',
    },
    {
      img: 'checking_savings.jpg',
      title: 'Checking vs Savings Account',
      link: 'checking-account-vs-savings-account',
      posted_date: 'May 2019',
    },
    {
      img: 'savingaccount.jpg',
      link: 'savings-account',
      title: 'Savings Accounts',
      posted_date: 'May 2019',
    },
    {
      img: 'checking.jpg',
      title: 'Checking Accounts',
      link: 'checking-account',
      posted_date: 'May 2019',
    },
    {
      img: 'debit.jpg',
      title: "What's a Debit Card?",
      link: 'debit-card',
      posted_date: 'May 2019',
    },
    {
      img: 'creditcard.jpg',
      title: "What's a Credit Card?",
      link: 'credit-card',
      posted_date: 'April 2019',
    },
    {
      img: 'DvsC.jpg',
      title: 'Debit Card vs Credit Card',
      link: 'debit-card-vs-credit-card',
      posted_date: 'April 2019',
    },
    {
      img: 'sheet.jpg',
      title: "What's a Balance Sheet?",
      link: 'balance-sheet',
      posted_date: 'April 2019',
    },
    {
      img: 'networth.jpg',
      link: 'net-worth',
      title: 'Net Worth',
      posted_date: 'April 2019',
    },
    {
      img: 'liability.jpg',
      link: 'liability',
      title: "What's a Liability?",
      posted_date: 'March 2019',
    },
    {
      img: 'asset.jpg',
      title: "What's an Asset?",
      link: 'asset',
      posted_date: 'March 2019',
    },
    {
      img: 'finance.jpg',
      link: 'personal-finance',
      title: 'Personal Finance 101',
      posted_date: 'March 2019',
    },
  ];
}
export default financeExplainedValues;
