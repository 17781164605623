import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const DowJonesIndustrialAverage = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Dow Jones Industrial Average</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/dow-jones.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Dow Jones Industrial Average
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/dow-jones-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Have you ever heard someone say, &quot;The market is
                          up big today.&quot;? That person is most likely
                          talking about the Dow Jones Industrial Average (DJIA),
                          more commonly referred to as &quot;the Dow.&quot; What
                          is the Dow and what does it mean when someone says the
                          Dow is up or down?
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          The Dow is an{' '}
                          <a
                            href="https://www.youtube.com/watch?v=WA3Jhvm4W9k"
                            target="_blank"
                            rel="noopener noreferrer">
                            index
                          </a>{' '}
                          and was first created in 1896 by Charles Dow and his
                          partner, Edward Jones. The Dow tracks the price
                          movement of 30 large publicly traded companies that
                          are listed on the{' '}
                          <Link
                            href="/finance-explained/stock-exchange"
                            rel="noopener noreferrer">
                            New York Stock Exchange and the NASDAQ
                          </Link>
                          . These 30 companies represent a significant portion
                          of the U.S. economy. Because of this, investors
                          typically view the Dow as a representation of the
                          broader U.S. economy.
                        </p>
                        <p>
                          As of this newsletter (
                          <a
                            href="https://www.spglobal.com/spdji/en/images/indexology-microsite/spdji-the-changing-djia.jpg"
                            target="_blank"
                            rel="noopener noreferrer">
                            5/18/20
                          </a>
                          ), the 30 Dow companies are:
                          <ul className={classes.dowCompanies}>
                            <li>1) 3M</li>
                            <li>2) American Express</li>
                            <li>3) Apple</li>
                            <li>4) Boeing</li>
                            <li>5) Caterpillar</li>
                            <li>6) Chevron</li>
                            <li>7) Cisco Systems</li>
                            <li> 8) Coca-Cola</li>
                            <li>9) Dow</li>
                            <li>10) Exxon Mobil</li>
                            <li>11) Walgreens Boots Alliance</li>
                            <li>12) Goldman Sachs Group</li>
                            <li>13) Home Depot</li>
                            <li>14) IBM</li>
                            <li>15) Intel</li>
                            <li>16) Johnson & Johnson</li>
                            <li>17) JPMorgan Chase</li>
                            <li>18) McDonald&apos;s</li>
                            <li>19) Merck</li>
                            <li>20) Microsoft</li>
                            <li>21) Nike</li>
                            <li>22) Pfizer</li>
                            <li>23) Procter & Gamble</li>
                            <li>24) The Travelers Companies</li>
                            <li>25) UnitedHealth Group Inc.</li>
                            <li>26) United Technologies</li>
                            <li>27) Verizon</li>
                            <li>28) Visa</li>
                            <li>29) Walmart</li>
                            <li>30) Walt Disney</li>
                          </ul>
                        </p>
                        <p>
                          When you hear the market is up and you check to see
                          the price of the Dow that day, remember what that
                          price represents. The Dow Jones Industrial Average
                          reflects the price movement of 30 stocks that make up
                          a large portion of the U.S. economy. If the Dow is
                          moving up, investors must think positively of those 30
                          companies and the U.S. economy that day. And if the
                          Dow is down, vice versa.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
DowJonesIndustrialAverage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DowJonesIndustrialAverage);
