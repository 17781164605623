import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import $ from 'jquery';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styles from '../../screens/pwi-car/styles';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';

const SecondCarLeasePaymentMode = ( props ) => {
  const {
    classes, handleDefinationPopup, handleBuyingCarObject, handleUpdatedObject, percentageVal, percentageVal2, finalMnthlyAfterIncome, finalMnthlyExpenses,
  } = props;

  const [basePrice, setBasePrice] = React.useState( handleBuyingCarObject.leasing_second_car_lease_payment === undefined ? 0 : handleBuyingCarObject.leasing_second_car_lease_payment );

  useEffect( () => {
    const leasePayment = { ...handleBuyingCarObject };
    if ( handleBuyingCarObject.leasing_second_car_lease_payment === undefined ) {
      leasePayment.leasing_second_car_lease_payment = 0;
      setBasePrice( 0 );
    }
    // eslint-disable-next-line
  }, []);

  useEffect( () => {
    $( '#percentage1' ).css( 'height', `${percentageVal}%` );
    $( '#percentage2' ).css( 'height', `${percentageVal2}%` );
  }, [percentageVal,percentageVal2] );

  const handleBasePriceSlider = ( event, newValue ) => {
    const leasePayment = { ...handleBuyingCarObject };
    setBasePrice( newValue );
    leasePayment.leasing_second_car_lease_payment = newValue;
    handleUpdatedObject( leasePayment );
  };

  const handleBasePriceInput = ( event ) => {
    const leasePayment = { ...handleBuyingCarObject };
    setBasePrice( event.floatValue === undefined ? 0 : event.floatValue );
    leasePayment.leasing_second_car_lease_payment = event.floatValue === undefined ? 0 : event.floatValue;
    handleUpdatedObject( leasePayment );
  };

  return (
    <div className={ classes.contentBlock }>
      <p>A lease is a contract that allows you to rent a vehicle for a fixed period of time. In exchange for the vehicle, you pay a monthly lease payment. The lease payment</p>
      <div className={ classes.grayBox }>
        <p>Leases typically have the following terms:</p>
        <ul className={ classes.downPaymentNew }>
          <li>Lease term ranging from 24 to 48 months.</li>
          <li>Annual mileage limits 10,000, 12,000, or 15,000 annual mileage limits with a penalty fee for every mile you drive over that limit.</li>
          <li>Money factor or lease rate, which is the interest rate stated differently. The money factor is the interest rate divided by 2,400.</li>
          <li>Residual value is the leasing company&apos;s prediction of what the car will be worth at the end of the leasing term. The higher the residual value, the lower your monthly payment.</li>
          <li>Sales tax is added to each of your monthly lease payments.</li>
        </ul>
      </div>
      <p>
        Use
        {' '}
        {' '}
        <a href="https://www.edmunds.com/calculators/car-lease.html" target="_blank" rel="noopener noreferrer">Edmunds.com&apos;s free lease calculator</a>
        {' '}
        that allows you to select a make and model and adjust all the lease terms to get to your monthly payment. Then, come back and enter that value below.
      </p>
      <div className={ classes.priceSlidersScroll }>
        <div className={ classes.priceSlidersScrollDiv }>
          <div className={ classes.leasePay }>
            <div className={ classes.leftBlock }>
              <div className={ classes.textRow }>
                <div className={ classes.slideTitle }>
                  <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Lease Payment' ) }>Lease Payment</span>
                </div>
                <div className={ classes.slideRow }>
                  <div className={ classes.slideText }>
                    <span>
                      <PwiSlider
                        ValueLabelComponent={ ValueLabelComponent }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        marks={ sliderValues.LeasePayment() }
                        min={ 0 }
                        max={ 600 }
                        step={ 5 }
                        value={ basePrice }
                        valueLabelFormat={ `${commonFunctions.numFormatter( basePrice )}` }
                        onChange={ handleBasePriceSlider }
                      />
                    </span>
                  </div>
                  <div className={ classes.slideInput }>
                    <span className={ classes.dollarSymbol }>$</span>
                    <span>
                      {' '}
                      <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ basePrice } onValueChange={ handleBasePriceInput } onFocus={ ( e ) => { e.target.select(); } } />
                    </span>
                  </div>
                </div>
              </div>
              <div className={ classes.textRow }>
                <div className={ classes.slideTitle }>
                  <span>&nbsp;</span>
                </div>
                <div className={ classes.flexDisplay }>
                  <div className={ classes.slideText }>
                    <p><b>Total Lease Payments</b></p>
                  </div>
                  <div className={ classes.slideAmount }>
                    <span><b><NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ handleBuyingCarObject.leasing_second_car_number_of_years * basePrice} prefix={ '$'} /></b></span>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.rightBlock }>
              <ul className={ classNames( classes.utilitiesChart, classes.leaseChart ) }>
                <li className={ classes.chartBar }>
                  <div className={ classes.chartText }>
                    Monthly Car Expenses
                    <span  className={ classes.chartSpan }>
                      {finalMnthlyExpenses !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ finalMnthlyExpenses } prefix={ finalMnthlyExpenses >= 0 ? '$' : '($' } suffix={ finalMnthlyExpenses < 0 && ')' } />
                      ) : ( '$0' )}
                    </span>
                  </div>
                  <span className={ classes.percentageBar } id="percentage1" />
                  <div className={ classes.graphPercentage }>
                    {percentageVal !== undefined ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageVal } prefix={ percentageVal >= 0 ? '' : '(' } suffix={ percentageVal < 0 ? '%)' : '%' } />
                    ) : ( '0.00%' )}
                  </div>
                </li>
                <li className={ classes.chartBar }>
                  <div className={ classes.chartText }>
                    Monthly Income
                    <span className={ classNames( classes.chartSpan, classes.secondPercentageTextColor ) }>
                      {finalMnthlyAfterIncome !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ finalMnthlyAfterIncome } prefix={ finalMnthlyAfterIncome >= 0 ? '$' : '($' } suffix={ finalMnthlyAfterIncome < 0 && ')' } />
                      ) : ( '$0' )}
                    </span>
                  </div>
                  <span className={ classNames( classes.percentageBar, classes.secondPercentageBarColor ) } id="percentage2" />
                  <div  className={ classNames( classes.graphPercentage, classes.secondPercentageTextColor ) }>
                    {percentageVal2 !== undefined ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageVal2 } prefix={ percentageVal2 >= 0 ? '' : '(' } suffix={ percentageVal2 < 0 ? '%)' : '%' } />
                    ) : ( '0.00%' )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};
SecondCarLeasePaymentMode.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
  percentageVal: PropTypes.number.isRequired,
  percentageVal2: PropTypes.number.isRequired,
  finalMnthlyAfterIncome: PropTypes.number.isRequired,
  finalMnthlyExpenses: PropTypes.number.isRequired,
};

export default withStyles( styles )( SecondCarLeasePaymentMode );
