import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import scrollToComponent from 'react-scroll-to-component';
import _ from 'lodash';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import NumberFormat from 'react-number-format';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { xpChallengePoints, getTotalTime, getUserXPRank } from '../exercises/pf101-service';
import Pf101Popups from '../exercises/popups';
import styles from './styles';
import ScrollToTop from '../../../components/ui/scroll-to-top/scrollToTop';
import poweUp101 from '../../../assets/img/pf101/challenge/powerup.png';
import GraphOne from '../../../assets/img/pf101/challenge/graph_1.png';
import dashboardLogo from '../../../assets/img/pf101/challenge/dashboard-logo.png';
import tableFi from '../../../assets/img/pf101/challenge/table_fi.png';
import netWorthNew from '../../../assets/img/pf101/challenge/net_worth.png';
import IncomeArrow from '../../../assets/img/pf101/challenge/income_arrow.png';
import FedaralIncome from '../../../assets/img/pf101/challenge/fedaral_tax.png';
import piTaxBreakdown from '../../../assets/img/pf101/challenge/pi_tax_breakdown.png';
import budgeting101 from '../../../assets/img/pf101/challenge/budgeting_101.png';
import Pageloader from '../../../components/ui/pageloader';

import { FV } from '../../../utilities/commonFunctions';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

const GET_CHALLENGE = loader( '../../../graphql/schema/pf101/get-exercise-details.graphql' );
const SAVE_CHALLENGE = loader( '../../../graphql/schema/pf101/save-exercise.graphql' );

const startTime = new Date();

/* eslint react/prop-types: 0 */

const Pf101Challenge1 = ( props ) => {
  const { classes } = props;
  const scrollOptions = {
    offset: -120,
    align: 'top',
    duration: 500,
  };

  const correctAns = ['b', 'a', 'd', 'c', 'a', 'c', 'b', 'd'];

  const questionMainAns = [];
  const defaultReference = useRef( null );
  for ( let i = 0; i <= 7; i += 1 ) {
    const questionsObject = {
      id: i + 1, answer: '', correctAns: correctAns[i], scroll: defaultReference, question_attempts: 1, xp_earned: 0,
    };
    console.log(questionsObject);
    questionMainAns.push( questionsObject );
  }

  let quesionSection = useRef( null );

  const [monthlySavings, setMonthlySavings] = React.useState( 1210 );
  const [annualReturn, setAnnualReturn] = React.useState( 7 );
  const [annualReturnGraphData, setAnnualReturnGraphData] = React.useState( {} );

  const [step, setStep] = React.useState( 0 );
  const [questions, setQuestions] = useState( questionMainAns );
  const [progress, setProgress] = React.useState( 0 );
  const [myScorePopup, setMyScorePopup] = React.useState( false );
  const [unselectedQuestions, setUnselectedQuestions] = React.useState( '' );
  const [loading, setLoading] = React.useState( true );
  const [pf101Points, setPf101Points] = React.useState( 0 );
  const [challengePoints, setChallengePoints] = React.useState( 0 );
  const [challengePercentage, setChallengePercentage] = React.useState( 0 );
  const [popupDetails, setPopupDetails] = React.useState( {} );
  const [exerciseStatus, setExerciseStatus] = React.useState( 'submit' );

  const updateGraph = () => {
    const graphArray = [];
    const graphArray1 = [];
    const graphArray2 = [];
    const graphArrayYears = [];
    const secondReturn = annualReturn / 2;
    const thirdReturn = annualReturn + secondReturn;
    for ( let y = 1; y <= 30; y += 1 ) {
      const endingValue = FV( ( annualReturn / 12 ) / 100, y * 12, -monthlySavings, 0, 0 );
      graphArray.push( endingValue );
      const endingValue1 = FV( ( secondReturn / 12 ) / 100, y * 12, -monthlySavings, 0, 0 );
      graphArray1.push( endingValue1 );
      const endingValue2 = FV( ( thirdReturn / 12 ) / 100, y * 12, -monthlySavings, 0, 0 );
      graphArray2.push( endingValue2 );

      graphArrayYears.push( y );
    }
    setAnnualReturnGraphData(
      {
        list: graphArrayYears,
        data: [{
          name: `${parseFloat( thirdReturn ).toFixed( 2 )}% return`,
          data: graphArray2,
          color: '#4472C4',
        }, {
          name: `${parseFloat( annualReturn ).toFixed( 2 )}% return`,
          data: graphArray,
          color: '#Ed7D31',
        }, {
          name: `${parseFloat( secondReturn ).toFixed( 2 )}% return`,
          data: graphArray1,
          color: '#A5A5A5',
        }],
      },
    );
  };

  useEffect( () => {
    updateGraph();
    // eslint-disable-next-line
  },[])

  const updateInputValue = ( e, inputType ) => {
    let value = e.floatValue !== undefined ? e.floatValue : 0;
    if ( inputType === 'savings' ) {
      setMonthlySavings( value );
    } else {
      if ( value > 100 ) {
        value = 100;
      }
      setAnnualReturn( value );
    }
  };

  useQuery( GET_CHALLENGE, {
    variables: {
      level: 11,
      exercise: 1,
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      setLoading( false );
      setPf101Points( response.getPf101ExerciseDetails.total_points );
      if ( response.getPf101ExerciseDetails.questions.length > 0 ) {
        const questionData = [...questions];
        response.getPf101ExerciseDetails.questions.forEach( ( data ) => {
          questionData[data.question_id - 1].question_attempts = data.question_attempts > 0 ? data.question_attempts : 1;
          questionData[data.question_id - 1].xp_earned = xpChallengePoints( questions[data.question_id - 1].question_attempts );
          questionData[data.question_id - 1].status = 'C';
          questionData[data.question_id - 1].answer = questions[data.question_id - 1].correctAns;
        } );
        setQuestions( questionData );
        setExerciseStatus( 'review' );
        setStep( 2 );
      } else {
        setStep( 1 );
      }
    },
    onError( ) {
      window.location.href = '/';
    },
  } );

  const [saveChallenge] = useMutation( SAVE_CHALLENGE, {
    onCompleted( response ) {
      setLoading( false );
      if ( response.savePf101ExerciseDetails.status === true ) {
        setPopupDetails( ( prevState ) => ( {
          ...prevState,
          popupOpen: true,
        } ) );
      }
    },
    onError( ) {
      return false;
    },
  } );

  useEffect( () => {
    const answerQuestions = _.filter( questions, ['status', 'C'] ).length;
    let progressBar = answerQuestions * ( 100 / 8 );
    progressBar = progressBar.toFixed( 0 );
    if ( progressBar > 99 ) {
      progressBar = 100;
    }
    setProgress( parseFloat( progressBar ) );

    const challengePoint = _.sumBy( questions, 'xp_earned' );
    setChallengePoints( challengePoint );
    setChallengePercentage( ( challengePoint / 800 ) * 100 );
  }, [questions] );

  const handleAnswer = ( event, index ) => {
    const newQuesions = [...questions];
    newQuesions[index].answer = event.target.value;
    newQuesions[index].status = questions[index].correctAns === event.target.value ? 'C' : 'W';
    if ( newQuesions[index].status === 'W' ) {
      newQuesions[index].question_attempts += 1;
    } else {
      newQuesions[index].xp_earned = xpChallengePoints( newQuesions[index].question_attempts );
    }
    setQuestions( newQuesions );
  };

  const handleMyScore = () => {
    const unAnswerQuestions = _.reject( questions, ['status', 'C'] );
    if ( unAnswerQuestions.length > 0 ) {
      setUnselectedQuestions( _.map( unAnswerQuestions, 'id' ).join( ', ' ) );
      setMyScorePopup( true );
    } else {
      const totalTime = getTotalTime( startTime );
      const totalPoints = _.sumBy( questions, 'xp_earned' );
      const totalAttempts = _.sumBy( questions, 'question_attempts' );
      const questionData = questions.map( ( question ) => _.pick( question, ['id', 'question_attempts', 'xp_earned'] ) );

      const popupValue = {};
      popupValue.exercisePoints = totalPoints;
      popupValue.precision = false;
      popupValue.level = false;

      const presentRank = getUserXPRank( pf101Points );
      const nextRank = getUserXPRank( pf101Points + totalPoints );
      if ( presentRank !== nextRank ) {
        popupValue.rankChangeStatus = true;
        popupValue.rank = nextRank;
      } else {
        popupValue.rankChangeStatus = false;
        popupValue.rank = '';
      }
      popupValue.type = 'challenge';
      setPopupDetails( popupValue );
      saveChallenge( {
        variables: {
          data: {
            level: 11,
            exercise: 1,
            exercise_time: totalTime,
            points: totalPoints,
            total_attempts: totalAttempts,
            questionData,
          },
        },
      } );
    }
    return true;
  };

  const handleMyScoreClose = () => {
    setMyScorePopup( false );
  };

  const handleQuestionScroll = ( questionNumber ) => {
    scrollToComponent( questionNumber, scrollOptions );
  };

  const elementInViewport = ( el ) => {
    let element = el;
    if ( typeof $ === 'function' && element instanceof $ ) {
      element = element[0];
    }

    if ( typeof element !== 'undefined' && element !== '' ) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 75
              && rect.left >= 0
              && rect.bottom <= ( window.innerHeight || document.documentElement.clientHeight )
              && rect.right <= ( window.innerWidth || document.documentElement.clientWidth )
      );
    }
    return false;
  };

  useEffect( () => {
    const onScroll = ( ) => {
      if ( step === 2 ) {
        if ( quesionSection !== null && quesionSection.getBoundingClientRect().top <= 65 ) {
          document.getElementById( 'awareness_title' ).style.position = 'fixed';
          document.getElementById( 'awareness_title_sub' ).style.display = 'block';
        } else {
          document.getElementById( 'awareness_title' ).style.position = '';
          document.getElementById( 'awareness_title_sub' ).style.display = 'none';
        }

        if ( quesionSection !== null && window.innerHeight - quesionSection.getBoundingClientRect().top >= 300 ) {
          document.getElementById( 'sticky_numbers' ).style.left = '30px';
        } else {
          document.getElementById( 'sticky_numbers' ).style.left = '-11em';
        }

        for ( let i = 0; i < 8; i += 1 ) {
          const element = $( questionMainAns[i].scroll );
          let subElement = false;
          if ( questions[i].status === 'C' ) {
            subElement = true;
          }

          if ( ( elementInViewport( element ) === true || elementInViewport( element.find( '>div:first-child' ).find( 'select' ) ) )
            || ( subElement === true && ( elementInViewport( element.find( '>div:first-child' ) ) || elementInViewport( element.find( '>div:last-child' ) )
              || elementInViewport( element.find( '>div:last-child' ).find( 'h3' ) ) || elementInViewport( element.find( '>div:last-child' ).find( 'h2' ) )
              || elementInViewport( element.find( '>div:last-child' ).find( 'div' ) ) || elementInViewport( element.find( '>div:last-child' ).find( 'ul' ) ) ) ) ) {
            element.css( 'opacity', '1' );
            element.siblings().not( '#progress-bar, #my-score-button' ).css( 'opacity', '0.3' );
            $( '#sticky_numbers li' ).removeClass( 'stick_number_active_class' );
            if ( i <= 6 ) {
              $( `#sticky_numbers ul:first-child li:nth-child(${i + 1})` ).addClass( 'stick_number_active_class' );
            } else {
              $( `#sticky_numbers ul:nth-child(2) li:nth-child(${i + 1 - 7})` ).addClass( 'stick_number_active_class' );
            }
            break;
          }
        }

        if ( ( quesionSection !== null && quesionSection.getBoundingClientRect() !== null && window.innerHeight - quesionSection.getBoundingClientRect().top < 300 ) ) {
          document.getElementById( 'progress-bar' ).style.display = 'none';
        } else {
          document.getElementById( 'progress-bar' ).style.display = 'block';
        }
      }
    };
    window.addEventListener( 'scroll', onScroll );

    return () => window.removeEventListener( 'scroll', onScroll );
  } );
  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }

      {step === 1 && (
        <div className={ `${classes.textTop} ${classes.textTopMin}` }>
          <div className={ classes.container }>
            <div className={ classes.logoNew }>
              <Link href="/">
                <img src={ dashboardLogo } alt="" />
              </Link>
            </div>
            <div className={ classes.pfModuleImage }>
              <Link href="/pf101/dashboard">
                <img src={ poweUp101 } alt="" />
              </Link>
            </div>
            <div className={ `${classes.tableBox} ${classes.tableWelcomeChallenge}` }>
              <h2>Welcome to Challenge 01!</h2>
              <p>
                How well do you know Levels 01-03?
                <br />
                Put your skills to the test with a challenge.
                <br />
                Let’s get started!
              </p>
              <Button onClick={ () => { setStep( 2 ); } }> Next </Button>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div>
          {exerciseStatus === 'submit' && (
            <div className={ classes.textTop }>
              <div className={ classes.container }>
                <div className={ classes.logoNew }>
                  <Link href="/">
                    <img src={ dashboardLogo } alt="" />
                  </Link>
                </div>
                <div className={ classes.pfModuleImage }>
                  <Link href="/pf101/dashboard">
                    <img src={ poweUp101 } alt="" />
                  </Link>
                </div>
                <div className={ classes.tableBox }>

                  <div className={ classes.textTopContent }>
                    <h4>Each question is worth 100 XP. If you get the question right on the first try, you will earn 100 XP for that question. If you get the question right on the second try, 80 XP. On the third try, 65 XP. Lastly, on the fourth try, 50 XP.</h4>
                    <p>After you get the correct answer, review the solution to that question and gain valuable financial life knowledge. Good luck!</p>
                    <Button onClick={ () => { scrollToComponent( quesionSection, { offset: -56, align: 'top', duration: 500 } ); } }> TAKE THE CHALLENGE </Button>
                  </div>
                  <div className={ classes.imagesBlockNew }>
                    <div className={ classes.imageOne }>
                      <img src={ GraphOne } alt="" />
                    </div>
                    <div className={ classes.imageTwo }>
                      <img src={ GraphOne } alt="" />
                    </div>
                    <div className={ classes.imageThree }>
                      <img src={ GraphOne } alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) }
          <div className={ classes.awarenessBody }>
            <div className={ classes.awernessQuestionsSec } ref={ ( section ) => { quesionSection = section; } }>
              <div className={ classes.ChallengesQuizHead } id="awareness_title">
                <div className={ classes.container }>
                  <Typography variant="h3" component="h3">PF101 Challenge 01</Typography>
                </div>
              </div>

              <div className={ classNames( classes.ChallengesQuizHead, classes.ChallengesQuizSub ) } id="awareness_title_sub">
                <div className={ classes.container }>
                  <Typography variant="h3" component="h3">&nbsp;</Typography>
                </div>
              </div>

              <div id="sticky_numbers" className={ classes.ChallengesNumbers }>
                <ul>
                  {
                      [1, 2, 3, 4, 5, 6, 7, 8].map( ( value ) => (
                        <li key={ value }>
                          <Button onClick={ () => handleQuestionScroll( questionMainAns[value - 1].scroll ) } className={ classNames( classes.listNum, ( questions[value - 1].answer !== '' && questions[value - 1].status === 'C' && classes.listNumRight ) ) }>
                            <span>
                              {value}
                              <i className="las la-check" />
                            </span>
                          </Button>
                        </li>
                      ) )
                    }
                </ul>
                <div className={ classes.stickyBottom }>
                  <Button onClick={ () => handleQuestionScroll( questionMainAns[0].scroll ) } className={ `${classes.listNumArrow} ${classes.arrowUp}` }>
                    <span>
                      <i
                        className="las la-arrow-up"
                        data-for="returnStart"
                        data-tip="Return to start"
                      />
                    </span>
                    <ReactTooltip id="returnStart" place="right" type="info" effect="solid" className={ classes.tooltipInfo } />
                  </Button>
                </div>
              </div>
              <div className={ classes.questionRow }>
                <div className={ classes.container }>

                  <div ref={ ( section ) => { questionMainAns[0].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>1</span>
                      <p className={ classes.questionTitle }>What day is &quot;Tax Day&quot; in the United States? This is the day that personal tax returns are due.</p>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[0].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              April 10
                              <sub>th</sub>
                              <input type="radio" disabled={ questions[0].answer === 'b' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 0 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[0].answer === 'b' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              April 15
                              <sub>th</sub>
                              <input type="radio" disabled={ questions[0].answer === 'b' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 0 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[0].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              April 20
                              <sub>th</sub>
                              <input type="radio" disabled={ questions[0].answer === 'b' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 0 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[0].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              April Fools
                              <input type="radio" disabled={ questions[0].answer === 'b' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 0 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[0].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2"> Correct !</Typography>
                        <div className={ classes.ansBox }>

                          <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
                            <h3>
                              April 15
                              <sub>th</sub>
                              {' '}
                              – tax day in the USA
                            </h3>
                            <p>
                              The deadline to filing personal taxes is
                              {' '}
                              <font className={ classes.yellowText }>
                                April 15
                                <sub>th</sub>
                                .
                              </font>
                              {' '}
                              If April 15
                              <sub>th</sub>
                              {' '}
                              falls on a holiday or weekend, then personal taxes are due the next business day. If you don’t file your taxes by that deadline, you could pay penalties and fees of up to 25% of the taxes that you owe!
                            </p>
                            <p>
                              There’s no penalty for filing your taxes earlier in the year and the IRS allows returns to be filed starting in February. Don’t waste money on IRS fees. Get your taxes out of the way earlier than April.
                            </p>
                          </Grid>

                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[1].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    ) }
                  </div>

                  <div ref={ ( section ) => { questionMainAns[1].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>2</span>
                      <p className={ classes.questionTitle }>
                        Complete the following equation:
                        <br />
                        <br />
                        Assets – Liabilities = ?
                      </p>

                      <ul className={ classes.optionList }>
                        <li className={ ( questions[1].answer === 'a' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              Net Worth
                              <input type="radio" disabled={ questions[1].answer === 'a' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                            </label>
                          </p>
                        </li>

                        <li className={ ( questions[1].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              Net Income
                              <input type="radio" disabled={ questions[1].answer === 'a' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[1].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              Adjusted Gross Income
                              <input type="radio" disabled={ questions[1].answer === 'a' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[1].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              Net Change in Cash
                              <input type="radio" disabled={ questions[1].answer === 'a' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 1 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[1].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2"> Correct !</Typography>
                        <div className={ `${classes.ansBox} ${classes.ansBoxPadding}` }>
                          <Grid container>
                            <Grid sm={ 6 }>
                              <Typography variant="h3">Net Worth = assets – liabilities.</Typography>
                              <p>Net worth is the value of what you own (assets) minus what you owe (liabilities).</p>
                              <p>The value of an asset is the price that you can sell it for today. The value of a liability is the outstanding amount on that loan that still needs to be repaid.</p>
                              <p>Knowing your net worth is important for two main reasons:</p>
                              <ol>
                                <li>Net worth shows your current financial situation in one, clean number, and</li>
                                <li>Net worth provides a reference point for measuring your future financial progress.</li>
                              </ol>
                              <p>Net worth allows you to track your financial progress over time. Are you making smart money moves that grow your net worth?</p>
                            </Grid>
                            <Grid sm={ 6 }>
                              <div className={ classes.imageRight }>
                                <img src={ tableFi } alt="" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[2].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[2].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>3</span>
                      <p className={ classes.questionTitle }>
                        Click to open the
                        {' '}
                        {' '}
                        {' '}
                        <Link target="_blank" href="/financial-calculators/net-worth">Net Worth Calculator</Link>
                        {' '}
                        {' '}
                        {' '}
                        and use the following details to calculate Mr. B&apos;s net worth.
                      </p>
                      <ul className={ classes.ListStyle }>
                        <li>Mr. B has $1,000 in cash, $3,000 in his checking account, and $5,000 in his savings account</li>
                        <li>He just financed the purchase of a used car with 50% in cash and 50% from a loan that is worth $20,000</li>
                        <li>He has $50,000 in his 401(k) retirement account and $20,000 in his investment account</li>
                        <li>Mr. B is almost done repaying his student loans and has $5,000 outstanding</li>
                      </ul>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[2].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              $99,000
                              <input type="radio" disabled={ questions[2].answer === 'd' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                            </label>
                          </p>
                        </li>

                        <li className={ ( questions[2].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              $94,000
                              <input type="radio" disabled={ questions[2].answer === 'd' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[2].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              $84,000
                              <input type="radio" disabled={ questions[2].answer === 'd' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[2].answer === 'd' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              $74,000
                              <input type="radio" disabled={ questions[2].answer === 'd' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 2 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[2].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2"> Correct !</Typography>
                        <div className={ classes.ansBox }>
                          <Grid container>
                            <Grid sm={ 6 }>
                              <Typography variant="h3">Net worth</Typography>
                              <Typography variant="h4"><b>Net Worth = assets – liabilities.</b></Typography>
                              <p>Mr. B’s car purchase was the trick here. He financed his car with 50% in cash and 50% from a loan. What does that mean exactly?</p>
                              <p>The car price was $20,000. Mr. B would have paid 50% or $10,000 in cash. That means 50% or $10,000 would be an auto loan.</p>
                            </Grid>
                            <Grid sm={ 6 }>
                              <div className={ `${classes.imageRight} ${classes.imageRightEighty}` }>
                                <img src={ netWorthNew } alt="" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[3].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}

                  </div>

                  <div ref={ ( section ) => { questionMainAns[3].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>4</span>
                      <p className={ classes.questionTitle }>
                        Put the 3 types of income in order from highest taxes to lowest taxes.
                      </p>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[3].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              Passive
                              {' '}
                              {'>'}
                              {' '}
                              Earned
                              {' '}
                              {'>'}
                              {' '}
                              Portfolio
                              <input type="radio" disabled={ questions[3].answer === 'c' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 3 ) } />
                            </label>
                          </p>
                        </li>

                        <li className={ ( questions[3].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              Portfolio
                              {' '}
                              {'>'}
                              {' '}
                              Earned
                              {' '}
                              {'>'}
                              {' '}
                              Passive
                              <input type="radio" disabled={ questions[3].answer === 'c' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 3 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[3].answer === 'c' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              Earned
                              {' '}
                              {'>'}
                              {' '}
                              Portfolio
                              {' '}
                              {'>'}
                              {' '}
                              Passive
                              <input type="radio" disabled={ questions[3].answer === 'c' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 3 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[3].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              Earned
                              {' '}
                              {'>'}
                              {' '}
                              Passive
                              {' '}
                              {'>'}
                              {' '}
                              Portfolio
                              <input type="radio" disabled={ questions[3].answer === 'c' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 3 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[3].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classes.ansBox }>
                          <h3>3 types of income</h3>
                          <Grid container>
                            <Grid sm={ 4 }>

                              <div className={ classes.blueBox }>
                                <h5>Highest Taxes</h5>
                                <h3>Highest Taxes</h3>
                                <p>
                                  Money derived from spending your time working. Examples are jobs that pay salary and wages, bonuses, tips and commission. Your time is what produces earned income. Earned income is taxed at the highest rate (~40%).
                                </p>
                                <img src={ IncomeArrow } alt="" className={ classes.incomeArrow } />
                              </div>
                            </Grid>
                            <Grid sm={ 4 }>
                              <div className={ classes.blueBox }>
                                <h5>Medium Taxes</h5>
                                <h3>Portfolio Income</h3>
                                <p>
                                  Money you receive from selling an investment such as a stock, bond, or other fund for more than you bought it for. For example, if you bought Apple stock at $100 and sold for $150, the $50 gain would be portfolio income. Your initial investment of money is what produces portfolio income. Portfolio income is usually taxed at a lower rate than earned income.
                                </p>
                                <img src={ IncomeArrow } alt="" className={ classes.incomeArrow } />
                              </div>
                            </Grid>
                            <Grid sm={ 4 }>
                              <div className={ classes.blueBox }>
                                <h5>Lowest Taxes</h5>
                                <h3>Passive Income</h3>
                                <p>
                                  Money generated from assets you own that you don&apos;t actively work on. For example, if you own real estate and receive rent payments each month, the rent would be passive income. The asset that you own (not your time or selling the asset). is what produces passive income. Passive income is taxed at the lowest rate of the three.
                                </p>

                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[4].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}

                  </div>

                  <div ref={ ( section ) => { questionMainAns[4].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>5</span>
                      <p className={ classes.questionTitle }>
                        Social Security and Medicare taxes for self-employed individuals are ____% and for not self-employed individuals are ____%.
                      </p>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[4].answer === 'a' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              15.30% / 7.65%
                              <input type="radio" disabled={ questions[4].answer === 'a' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                            </label>
                          </p>
                        </li>

                        <li className={ ( questions[4].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              12.40% / 6.20%
                              <input type="radio" disabled={ questions[4].answer === 'a' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[4].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              6.20% / 12.40%
                              <input type="radio" disabled={ questions[4].answer === 'a' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[4].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              7.65% / 15.30%
                              <input type="radio" disabled={ questions[4].answer === 'a' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 4 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[4].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classes.ansBox }>
                          <Typography variant="h3">Personal income tax rate breakdown</Typography>
                          <Grid container>
                            <Grid sm={ 6 }>
                              <p>When you are not self-employed, you pay 7.65% for Social Security and Medicare taxes and your employer pays 7.65% as well.</p>
                              <p>When you are self-employed, since you don’t have an employer, you pay all of the Social Security and Medicare taxes at 15.30%.</p>
                              <p>Social Security and Medicare taxes are called FICA taxes. FICA stands for the law requiring these taxes, which is the Federal Insurance Contributions Act (FICA).</p>
                            </Grid>
                            <Grid sm={ 6 }>
                              <div className={ `${classes.imageRight} ${classes.imageRightNoGap}` }>
                                <img src={ piTaxBreakdown } alt="" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[5].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[5].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>6</span>
                      <p className={ classes.questionTitle }>
                        Click to open the
                        {' '}
                        {' '}
                        <Link target="_blank" href="/financial-calculators/budget">Budgeting 101 Calculator</Link>
                        {' '}
                        and use the following details to calculate the amount Mr. B is saving each month.
                      </p>
                      <ul className={ classes.ListStyle }>
                        <li>Mr. B earns $72,000 per year in salary and pays $14,400 a year in taxes</li>
                        <li>His student loan monthly payment is $300, and his car loan payment is $190</li>
                        <li>He buys $300 of clothing and personal care items, spends $300 on entertainment and tech, and $300 on gas</li>
                        <li>He spends $800 on rent and utilities, plus another $800 on food and drink per month</li>
                        <li>Mr. B has a car insurance policy with a $250 monthly premium and budgets another $100 in car maintenance per month</li>
                      </ul>
                      <p className={ `${classes.questionTitle} ${classes.pgaSecondTittle}` }> What&apos;s Mr. B&apos;s Monthly Cash Left Over for Savings / Invest? </p>
                      <ul className={ classes.optionList }>
                        <li className={ ( questions[5].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              $2,090
                              <input type="radio" disabled={ questions[5].answer === 'c' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                            </label>
                          </p>
                        </li>

                        <li className={ ( questions[5].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              $1,510
                              <input type="radio" disabled={ questions[5].answer === 'c' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[5].answer === 'c' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              $1,210
                              <input type="radio" disabled={ questions[5].answer === 'c' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[5].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              $950
                              <input type="radio" disabled={ questions[5].answer === 'c' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 5 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[5].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <Typography className={ classNames( classes.ansTitle, classes.ansCurrect ) } variant="h2" component="h2">Correct !</Typography>
                        <div className={ classes.ansBox }>
                          <Typography variant="h3">Budgeting 101</Typography>
                          <Grid container>
                            <Grid sm={ 7 }>

                              <div className={ classes.imageLeft }>
                                <img src={ budgeting101 } alt="" />
                              </div>
                            </Grid>
                            <Grid sm={ 5 }>
                              <div className={ classes.gridSpacesPadding }>
                                <p>How does Mr. B’s compare to the budgeting guidelines?</p>
                                <ul className={ classes.rightNewBlockList }>
                                  <li>
                                    <span>
                                      Rent & Utilities / Home Expenses
                                      <br />
                                      <span className={ classes.spanBold }><i>20% of After-Tax Income</i></span>
                                    </span>
                                    <span />
                                  </li>
                                  <li>
                                    <span>Food & Drink </span>
                                    <span>15%</span>
                                  </li>
                                  <li>
                                    <span>Transportation & Car </span>
                                    <span>12%</span>
                                  </li>
                                  <li>
                                    <span>Clothing & Personal Care </span>
                                    <span>7%</span>
                                  </li>
                                  <li>
                                    <span>Entertainment & Technology</span>
                                    <span>7%</span>
                                  </li>
                                  <li>
                                    <span>Health Insurance & Healthcare </span>
                                    <span>5%</span>
                                  </li>
                                  <li>
                                    <span>Pet</span>
                                    <span>2%</span>
                                  </li>
                                  <li>
                                    <span>Miscellaneous</span>
                                    <span>2%</span>
                                  </li>
                                  <li>
                                    <span>Loan Payments</span>
                                    <span>15-20%</span>
                                  </li>
                                  <li>
                                    <span>Cash Left Over for Savings / Invest</span>
                                    <span>10-20%</span>
                                  </li>
                                </ul>
                              </div>
                            </Grid>

                          </Grid>
                        </div>

                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[6].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[6].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>7</span>
                      <p className={ classes.questionTitle }>
                        Continuing with the Mr. B example, if Mr. B saves and invests $1,210 per month and earns a 7% return on his investment,
                        how much will he have in 15 years? Use the
                        {' '}
                        {' '}
                        {' '}
                        <Link href="/financial-calculators/savings-growth" target="_blank">Savings Growth calculator</Link>
                        {' '}
                        {' '}
                        {' '}
                        to discover how much Mr. B’s savings can grow to.
                      </p>

                      <ul className={ classes.optionList }>
                        <li className={ ( questions[6].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              $209,433
                              <input type="radio" disabled={ questions[6].answer === 'b' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                            </label>
                          </p>
                        </li>

                        <li className={ ( questions[6].answer === 'b' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              $383,524
                              <input type="radio" disabled={ questions[6].answer === 'b' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[6].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              $285,906
                              <input type="radio" disabled={ questions[6].answer === 'b' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[6].answer === 'd' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              $173,553
                              <input type="radio" disabled={ questions[6].answer === 'b' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 6 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[6].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <div className={ classes.ansBox }>
                          <h3>Budgeting and saving</h3>
                          <Grid container className={ classes.gridSpaces }>

                            <Grid sm={ 8 }>
                              <div className={ classes.imageLeft }>
                                <h5 className={ classes.textSaving }>Savings Growth</h5>
                                <div className={ classes.monthlySavings }>
                                  <div className={ classes.formGroup }>
                                    <font>Monthly Savings:</font>
                                    <div>
                                      {/* <span className={ classes.DollorLeft }>$</span>
                                      <input type="text" /> */}
                                      <span className={ classes.dollarSymbol }>$</span>
                                      <NumberFormat id="annual" decimalScale={ 0 } fixedDecimalScale className={ classNames( classes.formInput ) } customInput={ TextField } thousandSeparator value={ monthlySavings } onValueChange={ ( e ) => updateInputValue( e, 'savings' ) } onFocus={ ( e ) => e.target.select() } />
                                    </div>
                                  </div>
                                  <div className={ `${classes.formGroup} ${classes.formGroupRight}` }>
                                    <font>Monthly Savings:</font>
                                    <div>
                                      {/* <input type="text" />
                                      <span className={ classes.DollorLeft }>%</span> */}
                                      <NumberFormat id="annual" decimalScale={ 2 } className={ classNames( classes.formInput, classes.returnInput ) } customInput={ TextField } thousandSeparator value={ annualReturn } onValueChange={ ( e ) => updateInputValue( e, 'return' ) } onFocus={ ( e ) => e.target.select() } />
                                      <span className={ classNames( classes.percentSymbol, classes.percentPadding ) }>%</span>
                                    </div>
                                  </div>
                                  <div className={ classes.formGroup }>
                                    <Button onClick={ updateGraph }>Calcucate</Button>
                                  </div>
                                  <div className={ classes.listIrems }>
                                    <ul>
                                      <li>
                                        <span>5 Years</span>
                                        {' '}
                                        $86,627
                                      </li>
                                      <li>
                                        <span>10 Years</span>
                                        {' '}
                                        $86,627
                                      </li>
                                      <li>
                                        <span>15 Years</span>
                                        {' '}
                                        $86,627
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div>
                                  <HighchartsReact
                                    highcharts={ Highcharts }
                                    options={ {
                                      chart: {
                                        type: 'spline',
                                        scrollablePlotArea: {
                                          width: 600,
                                          scrollPositionX: 0,
                                        },
                                      },
                                      credits: {
                                        enabled: false,
                                      },
                                      title: {
                                        text: '',
                                        style: {
                                          color: '#000000',
                                          fontSize: '14px',
                                        },
                                      },
                                      exporting: {
                                        enabled: false,
                                      },
                                      xAxis: [{
                                        tickInterval: 1,
                                        categories: annualReturnGraphData.list,
                                        labels: {
                                          style: {
                                            color: '#000000',
                                            fontSize: '14px',
                                          },
                                        },
                                        title: {
                                          text: 'Years',
                                          style: {
                                            color: '#000000',
                                            fontSize: '14px',
                                          },
                                        },
                                      }],
                                      yAxis: [{
                                        labels: {
                                          formatter() {
                                            const chart = this;
                                            if ( chart.value < 0 ) {
                                              return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                                            }
                                            return formatDollar( Math.round( chart.value ) );
                                          },
                                          style: {
                                            color: '#000000',
                                            fontSize: '14px',
                                          },
                                        },
                                        title: {
                                          text: '',
                                          style: {
                                            color: '#000000',
                                            fontSize: '14px',
                                          },
                                        },
                                      }],
                                      tooltip: {
                                        headerFormat: '<span style="font-size:14px">Year {point.key}</span><br/>',
                                        crosshairs: true,
                                        shared: true,
                                        valueDecimals: 0,
                                        valuePrefix: '$',
                                        style: {
                                          color: '#000000',
                                          fontSize: '13px',
                                        },
                                      },
                                      plotOptions: {
                                        spline: {
                                          lineWidth: 2,
                                          states: {
                                            hover: {
                                              lineWidth: 2,
                                            },
                                          },
                                          marker: {
                                            enabled: false,
                                          },

                                        },
                                      },
                                      legend: {
                                        itemStyle: {
                                          fontSize: '14px',
                                        },
                                      },
                                      series: annualReturnGraphData.data,
                                    } }
                                  />
                                </div>

                              </div>
                            </Grid>
                            <Grid sm={ 4 }>

                              <p>Saving and investing consistently over time allows Mr. B to capitalize on the power of compounding interest. Don’t worry – in Level 07, you’ll learn more about that.</p>

                            </Grid>

                          </Grid>
                        </div>

                        <div className={ classes.nextQuestion }>
                          <Link to="/" onClick={ () => handleQuestionScroll( questionMainAns[7].scroll ) }>next question v</Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div ref={ ( section ) => { questionMainAns[7].scroll = section; } } className={ classNames( classes.questionBlock ) }>
                    <div className={ classes.question }>
                      <span className={ classes.questionNum }>8</span>
                      <p className={ classes.questionTitle }>
                        Click to open the
                        {' '}
                        {' '}
                        {' '}
                        <Link href="/financial-calculators/federal-income-tax" target="_blank">Federal Income Taxes Calculator</Link>
                        . If Mr. B is single and earns $72,00 per year, how much federal income taxes can he expect to pay?
                      </p>

                      <ul className={ classes.optionList }>
                        <li className={ ( questions[7].answer === 'a' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_1">
                              $5,269
                              <input type="radio" disabled={ questions[7].answer === 'd' } name="equation" value="a" id="1_1" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                            </label>
                          </p>
                        </li>

                        <li className={ ( questions[7].answer === 'b' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_2">
                              $6,700
                              <input type="radio" disabled={ questions[7].answer === 'd' } name="equation" value="b" id="1_2" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[7].answer === 'c' && classes.WrongAnswer ) }>
                          <p>
                            <label htmlFor="1_3">
                              $7,542
                              <input type="radio" disabled={ questions[7].answer === 'd' } name="equation" value="c" id="1_3" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                            </label>
                          </p>
                        </li>
                        <li className={ ( questions[7].answer === 'd' && classes.correctAnswer ) }>
                          <p>
                            <label htmlFor="1_4">
                              $8,902
                              <input type="radio" disabled={ questions[7].answer === 'd' } name="equation" value="d" id="1_4" onChange={ ( e ) => handleAnswer( e, 7 ) } />
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                    {questions[7].status === 'C' && (
                      <div className={ classes.ansRow }>
                        <div className={ classes.ansBox }>
                          <Grid container>
                            <h3>Understanding federal income taxes</h3>
                            <Grid sm={ 12 }>

                              <p>Federal income taxes are calculated by using progressive tax rates, which means that as your income increases, you are taxed at higher rates. There are seven tax brackets ranging from 10% to 37%.</p>
                              <p>It&apos;s important to note that portions of your income are taxed at different rates - your entire income is not taxed at one rate!</p>

                            </Grid>
                            <Grid sm={ 12 }>
                              <div className={ classes.imageFull }>
                                <img src={ FedaralIncome } alt="" />
                              </div>
                            </Grid>

                          </Grid>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={ classes.progressBarNew }>
                    <div className={ classes.progressBar } id="progress-bar">
                      <p>
                        {progress}
                        % complete
                      </p>
                      <div>
                        <span style={ { width: `${progress}%` } } />
                      </div>
                    </div>
                    <div className={ `${classes.progressBar} ${classes.progressBarRight}` } id="progress-bar">
                      <div>
                        <p>
                          {challengePoints}
                          /800 XP earned
                        </p>
                        <div>
                          <span style={ { width: `${challengePercentage}%` } } />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={ classes.whatsMyScore } id="my-score-button">

                    {exerciseStatus === 'review' ? (
                      <Link href="/pf101/dashboard">BACK TO PF101 DASHBOARD</Link>
                    ) : (
                      <Button onClick={ handleMyScore }>Finish</Button>
                    )}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      )}

      {( popupDetails && popupDetails.popupOpen === true ) && (
        <Pf101Popups details={ popupDetails } />
      )}

      <Dialog
        open={ myScorePopup }
        onClose={ handleMyScoreClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ classes.pendinglistPopup }
      >
        <DialogContent>
          <div className={ classes.pendingNumList }>
            <h3>What&apos;s My Score?</h3>
            <p>
              Please answer all the questions and provide an importance rating to access your score. Questions remaining:
              {' '}
              {unselectedQuestions}
              .
            </p>
          </div>
        </DialogContent>
        <DialogActions className={ classes.buttonOk }>
          <Button onClick={ handleMyScoreClose } color="primary" autoFocus>
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>
      <ScrollToTop />
    </Typography>
  );
};

Pf101Challenge1.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Pf101Challenge1 );
