import React, { useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import {
  Button,
  Typography,
} from '@material-ui/core';
import Pageloader from '../../../ui/pageloader';
import styles from '../styles';
import globalStyles from '../../../layout/globalStyles';
import * as commonFunctions from '../../../../utilities/commonFunctions';
import combineStyles from '../../../../utilities/combineStyles';
import Logo from '../../../../assets/img/login/logo.png';

const FORGOT_PASSWORD = loader('../../../../graphql/schema/auth/forgot-password.graphql');

const ForgotPasswordForm = (props) => {
  const {
    classes, history,
  } = props;

  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState('');
  const [forgotPasswordError, setForgotPasswordError] = useState('');
  const [emailAddress, setEmail] = useState('');
  

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Email is required.';
    }

    if (values.email) {
      if (!commonFunctions.validateEmail(values.email)) {
        errors.email = 'Enter valid email.';
      }
    }

    return errors;
  };

  const [submitForgotPassword, { error, loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted({
      forgotPassword: {
        status,
        message,
      },
    }) {
      if (status === 'EMAIL_NOT_SENT') {
        setForgotPasswordError(message);
        setForgotPasswordSuccess('');
      } else if (status === 'EMAIL_SENT') {
        setForgotPasswordError('');
        setForgotPasswordSuccess(message);
      } else {
        setForgotPasswordError('');
        setForgotPasswordSuccess('');
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));

      setForgotPasswordError(formatedErrors);
      setForgotPasswordSuccess('');
    },
  });

  const onSubmit = (values) => {
    if (values.email) {
      setEmail(values.email);
    }
    submitForgotPassword({
      variables: {
        data: {
          email: values.email || emailAddress,
        },
      },
    });
  };

  const redirectToLogin = () => {
    history.push('login');
  };

  return (
    <Typography variant="body1" component="div" className={classes.loginBg}>
      {loading && <Pageloader loading={loading} />}
      <div>

        <div className={classes.loginForm}>
          <div className={classes.loginFormHeader}>
            <img src={Logo} alt="" />
          </div>
          <Form
            validate={validate}
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting }) => (
              <form
                className={classes.formContact}
                onSubmit={handleSubmit}
                noValidate
              >
                <div className={classes.loginFields}>
                  <Typography variant="h3">Forgot Password</Typography>
                  <div className={classes.formGroup}>
                    <Field
                      id="email"
                      placeholder="Email"
                      type="text"
                      name="email"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      component={TextField}
                    />
                  </div>
                  {
                    (forgotPasswordError || error) && (
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        className={classes.textDanger}
                      >
                        {forgotPasswordError}
                      </Typography>
                    )
                  }
                  {
                    (forgotPasswordSuccess !== '') && (
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        className={classes.textSuccess}
                      >
                        <span>{forgotPasswordSuccess}</span>
                      </Typography>
                    )
                  }
                  <div className={classes.formGroup}>
                    <Button variant="contained" disabled={submitting} type="submit">Reset password</Button>
                  </div>
                  <p className={classes.already}>
                    Already have a username?
                    {' '}
                    <span aria-hidden="true" className={classes.forgotPass} onClick={redirectToLogin}>Login here</span>
                  </p>
                </div>
              </form>
            )}
          />
        </div>

      </div>
    </Typography>
  );
};

ForgotPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(globalStyles, styles);

const enhance = compose(
  withStyles(combinedStyles),
  withRouter,
);

export default enhance(ForgotPasswordForm);
