import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const StockSplit = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Stock Split</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/stock-split.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Stock Split
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/stock-split-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          The main reason for a company to exercise a stock
                          split is to lower the share price, making the stock
                          look more affordable to regular investors. Think about
                          it for a second. If Company A is $1,500 per share of
                          stock, Company B is $40 per share of stock, and you
                          like both companies equally, which one would you
                          choose?
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Probably Company B. I only need $40 to invest in
                          Company B. For the average investor, that&apos;s much
                          easier to handle than the $1,500 price tag on Company
                          A.
                        </p>
                        <p>
                          When a publicly traded company&apos;s stock price gets
                          too high, they can use a tactic called a stock split
                          to lower the share price. Stock splits divide existing
                          shares into multiple shares to lower the stock price.
                          Easiest to understand in an example about a $100 pie.
                          Try not to get too hungry.
                        </p>
                        <p>
                          The pie in this example is the value of all the
                          company&apos;s stock. When the stock first became
                          available, the pie was cut into four slices and you
                          bought a slice for $25. You own one quarter of the
                          $100 pie. If the company decides to do a
                          &quot;5-for-1&quot; stock split, that means each share
                          becomes five shares. Now we have 20 slices of pie
                          worth $5 each. The $100 pie hasn&apos;t changed;
                          there&apos;s just more slices of pie.
                        </p>
                        <p>
                          Not only has the price of a slice of pie lowered, with
                          20 shares versus four, there&apos;s probably going to
                          be more trading now, too. Companies know this. They
                          use stock splits to lower the price per share of stock
                          to attract all types of investors big and small.
                        </p>
                        <p>
                          Why do you think it&apos;s advantageous to have more
                          people trading your stock? Feeling brave? Reply to
                          this email and let us know.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
StockSplit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StockSplit);
