import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Pageloader from '../../components/ui/pageloader';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import classNames from 'classnames';

import popupSurvey from '../../assets/img/popup_survey_img.png';

import * as commonFunctions from '../../utilities/commonFunctions';
import { useMutation } from '@apollo/react-hooks';

const TWO_QUICK_QUESTIONS = loader('../../graphql/schema/contact/twoquickQuestions.graphql');

const MainDropDown = (props) => {

    const { classes, open, setOpen } = props;

    const [twoquestions] = useMutation(TWO_QUICK_QUESTIONS, {
        onCompleted() {
            setSuccessPopup(true);
            setLoading(false);
            setOpen(false);
        }
    });

    const [valueOne, setvalueOne] = useState('');
    const [valueTwo, setvalueTwo] = useState('');
    const [textvalueOne, setTextvalueOne] = useState('');
    const [textvalueTwo, setTextvalueTwo] = useState('');
    const [error, setError] = useState('');
    const [content, setContent] = useState('1');
    const [Email, setEmail] = useState('');
    const [SuccessPopup, setSuccessPopup] = useState(false);
    const [loading, setLoading] = useState(false);

    const validate = (values) => {
        const errors = {};
        if (!values.first_name) {
            errors.first_name = 'Firstname is required.';
        }
        if (values.email) {
            if (!commonFunctions.validateEmail(values.email)) {
                errors.email = 'Enter valid email.';
            }
            setEmail(values.email);
        }
        if (!values.email) {
            errors.email = 'email is required.';
            setEmail('');
        }
        // if (!values.last_name) {
        //     errors.last_name = 'lastname is required.';
        // }
        return errors;
    };

    const handleAnswerOne = (e) => {
        setvalueOne(e.target.value);
    }

    const handleTextone = (e) => {
        setTextvalueOne(e.target.value);
    }

    const handleBackone = () => {
         setOpen(false);
    }

    const handleNextone = () => {
        if (valueOne === '') {
            setError('Please choose one option form the above list.');
        }

        if (valueOne) {
            if (valueOne === 'Other (please explain in as much detail as you wish)') {
                setError('Please type your reason in the box provided.');
                if (textvalueOne) {
                    setError('')

                    setContent('2');
                }
            } else {
                setContent('2');
                setError('')
            }

        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleAnswerTwo = (e) => {
        setvalueTwo(e.target.value);
    }


    const handleTexttwo = (e) => {
        setTextvalueTwo(e.target.value);
    }

    const handleNexttwo = () => {
        if (valueTwo === '') {
            setError('Please choose one option form the above list.');
        } else {
            setError('');
        }
        if (valueTwo) {
            if (valueTwo === 'Other (please explain in as much detail as you wish)') {
                setError('Please type your reason in the box provided.');
                if (textvalueTwo) {
                    setError('');
                    setContent('3');
                }
            } else {
                setError('')
                setContent('3');
            }
        }
    }

    const handleBacktwo = () => {
                setContent('1');
    }

    const handleBackthree = () => {
                setContent('2');
    }
    
    const onSubmit = (values) => {
        setLoading(true);
        twoquestions({
            variables: {
                data: {
                    question_1: valueOne,
                    question_2: valueTwo,
                    first_name: values.first_name,
                    last_name: "_blank",
                    email: Email,
                    question_1_reason: textvalueOne,
                    question_2_reason: textvalueTwo
                }
            }
        })
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => { setOpen(false) }}
                className={classNames(classes.popupAnswerQuestionsBox, "popupAnswerQuestionsRow")}
            >
                <DialogContent className={classes.popupAnswerQuestions}>
                    {loading && <Pageloader loading={loading} />}
                    <div className={classes.popupHearfromyouHead}>
                        <IconButton onClick={handleClose} className={classes.btnClose}>
                            <CloseIcon />
                        </IconButton>
                        <img src={popupSurvey} alt="" />
                        <Typography variant="h2" component="h2">We want to hear from you.</Typography>
                    </div>
                    {content === '1' ?
                        <div className={classes.popupHearfromyouBody}>
                            <Typography variant="h3" component="h3">First, two quick questions:</Typography>

                            <p>
                                When it comes to your personal finances, which best describes the area where you would like some help? The reason for asking is so we can direct you to the solutions that best suit specific needs.
                            </p>
                            <div>
                                <RadioGroup name="Qone" onChange={handleAnswerOne}>
                                    <ul>
                                        <li>
                                            <FormControlLabel sx={{width:25}} value="Developing a complete financial life plan" name="upgrade" control={<Radio 
                                             size ="large"/>} 
                                              label = { (<h5> Developing a complete financial life plan</h5>)}
                                            />
                                        </li>
                                        <li>
                                            <FormControlLabel value="Managing debt, including student loan debt" name="upgrade" control={
                                                <Radio 
                                                />} 
                                              label = {(<h5> Managing debt, including student loan debt </h5>)}
                                            />
                                        </li>
                                        <li>
                                            <FormControlLabel value="Acquiring higher education without going deeply in debt" name="upgrade" control={<Radio />} 
                                              label = {(<h5>Acquiring higher education without going deeply in debt</h5>)}
                                            />
                                        </li>
                                        <li>
                                            <FormControlLabel value=" Building an emergency fund; saving for the future" name="upgrade" control={<Radio />} 
                                              label = {(<h5>  Building an emergency fund; saving for the future </h5>)}
                                            />
                                        </li>
                                        <li>
                                            <FormControlLabel value="Other (please explain in as much detail as you wish)" name="upgrade" control={<Radio />} 
                                              label = { (<h5> Other (please explain in as much detail as you wish) </h5>)}
                                            />
                                        </li>
                                    </ul>
                                </RadioGroup>
                            </div>
                            <TextareaAutosize aria-label="minimum height" minRows={3} onChange={handleTextone} />

                            <p className={ classes.errorMsg }>{ error }</p>
                            <div className={ classes.btnGroup }>
                                <div className={classes.btnBack}>
                                    <button type="button" onClick={handleBackone} >Back</button>
                                </div>
                                <div className={classes.btnNext}>
                                    <button type="button" onClick={handleNextone} >Next</button>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                    }
                    {content === '2' ?
                        <div className={classes.popupHearfromyouBody}>
                            <div>

                              {/*</div></div>  <p className={ classes.paraSecond }>How did you hear about Inkwiry?</p>*/}

                                <p className={classes.paraSecond}>How did you hear about Inkwiry?</p>
                                <div >
                                    <RadioGroup name="Qtwo" onChange={handleAnswerTwo}>
                                        <ul>
                                            <li>
                                                <FormControlLabel value="Online search" name="upgrade" control={<Radio />} 
                                                  label = { (<h5>  Online search </h5>)}
                                                 />
                                            </li>
                                            <li>
                                                <FormControlLabel value="Family member, friend, or colleague" name="upgrade" control={<Radio />} 
                                                  label = {(<h5> Family member, friend, or colleague </h5>)}
                                                 />
                                            </li>
                                            <li>
                                                <FormControlLabel  value="Facebook" name="upgrade" control={<Radio />} 
                                                  label = {(<h5> Facebook </h5>)}
                                                />
                                            </li>
                                            <li>
                                                <FormControlLabel value="Instagram" name="upgrade" control={<Radio />} 
                                                  label = {(<h5>Instagram</h5>)}
                                                />
                                            </li>
                                            <li>
                                                <FormControlLabel value="Twitter" name="upgrade" control={<Radio />} 
                                                  label = {(<h5>Twitter </h5>)}
                                                />
                                            </li>
                                            <li>
                                                <FormControlLabel value="High school, college or other educational institution" name="upgrade" control={<Radio />} 
                                                  label = {(<h5> High school, college or other educational institution </h5>)}
                                                />
                                            </li>
                                            <li>
                                                <FormControlLabel value="Counselor or career center" name="upgrade" control={<Radio />} 
                                                  label = {(<h5>Counselor or career center </h5>)}
                                                />
                                            </li>
                                            <li>
                                                <FormControlLabel value="Certified planner or wealth manager" name="upgrade" control={<Radio />} 
                                                  label = {(<h5>Certified planner or wealth manager</h5>)}
                                                />
                                            </li>
                                            <li>
                                                <FormControlLabel value="Benefits package at your place of work" name="upgrade" control={<Radio />} 
                                                  label = {(<h5>Benefits package at your place of work </h5>)}
                                                />
                                            </li>
                                            <li>
                                                <FormControlLabel value="Other (please explain in as much detail as you wish)" name="upgrade" control={<Radio />} 
                                                  label = {(<h5>Other (please explain in as much detail as you wish </h5>)}
                                                />
                                            </li>
                                        </ul>
                                    </RadioGroup>
                                </div>

                               {/* <TextareaAutosize className={ classes.textareaTwo } aria-label="minimum height" minRows={3} onChange={handleTexttwo} />*/}

                                <TextareaAutosize className={classes.textareaTwo} aria-label="minimum height" minRows={3} onChange={handleTexttwo} />

                            </div>
                            <p className={ classes.errorMsg }>{ error }</p>
                             <div className={ classes.btnGroup }>
                                <div className={classes.btnBack}>
                                    <button type="button" onClick={handleBacktwo} >Back</button>
                                </div>
                                <div className={classes.btnNext}>
                                    <button type="button" onClick={handleNexttwo} >Next</button>
                                </div>
                            </div>
                            
                        </div>
                        :
                        ''
                    }
                    {content === '3' ?
                        <div className={classes.popupHearfromyouBody}>
                            <div>
                                <Typography variant="h3" component="h3">You're almost done.</Typography>
                                <p className={classes.paraThird}>
                                    Thank you. What’s your best contact information so we can direct you to the Inkwiry application or tool that best suits your needs? Our mission is to provide the skills, knowledge, and tools you need to achieve financial freedom faster. We won’t send you spam. And you can unsubscribe at any time.
                                </p>
                            </div>
                            <Form
                                validate={validate}
                                onSubmit={onSubmit}
                                render={({ handleSubmit }) => (
                                    <form
                                        onSubmit={handleSubmit}
                                        noValidate
                                    >
                                        <div className={classes.halfOne}>
                                            <Field
                                                id="filled-password-input"
                                                label="First Name"
                                                type="text"
                                                autoComplete="current-password"
                                                variant="filled"
                                                name="first_name"
                                                fullWidth
                                                required
                                                component={TextField}
                                            />
                                        </div>
                                      
                                        <div className={classes.halfTwo}>
                                            <Field
                                                id="filled-password-input"
                                                label="Email"
                                                type="text"
                                                autoComplete="current-password"
                                                variant="filled"
                                                name="email"
                                                fullWidth
                                                required
                                                component={TextField}
                                            />
                                        </div>
                                       {/* <div className={classes.btnNext}>*/}

                                        {/* <div className={ classes.btnSignup }>
                                            <Button
                                            type ="submit"
                                            >
                                            Sign Up
                                            </Button>
                                        </div> */}

                                          <div className={`${ classes.btnGroup} ${ classes.forSignup}` }>
                                            <div className={classes.btnBack}>
                                                <button type="button" onClick={handleBackthree} className={classes.halfOne} >Back</button>
                                            </div>
                                            <div className={classes.btnNext}>
                                                {/* <button type="button" onClick={handleNexttwo} >Next</button> */}
                                                 {/* <Button type ="submit">Sign Up</Button> */}
                                                 <button type="submit" className={classes.halfTwo}  >Sign Up</button>
                                            </div> 
                                         </div>
                                    </form>
                                )}
                            />
                        </div>
                        :
                        ''
                    }
                </DialogContent>
            </Dialog>
            <Dialog
                open={SuccessPopup}
                onClose={() => { setSuccessPopup(false) }}
                className={classes.addGroup}
            >
                <DialogContent>
                    <div className={classes.popupHeader}>
                        <div onClick={() => { setSuccessPopup(false) }} className={classes.closeBtn}>
                            <i className="la la-times" />
                        </div>
                    </div>
                    <div className={classes.popupContent}>
                        <span className={classes.successicon}></span>
                        <p>Thanks for sharing your feedback. We will get back to you shortly! In the meantime, check out Finance Explained in the Apps + Tools to get smarter about money.</p>
                    </div>
                </DialogContent>
            </Dialog>
        </div>

    )
}

MainDropDown.propTypes = {
    open: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default withStyles(styles)(MainDropDown);
