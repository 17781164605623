import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Container, Grid,
} from '@material-ui/core';
import styles from './styles';
import { Link } from 'react-router-dom';
import WhiteLogo from '../../../assets/img/login/logo.png';

const Footer = ( props ) => {
  const { classes } = props;

  return (
    <div className={ classes.footer } id="footer">
      {/* <Container className={ classes.container }>
        <Grid container spacing={ 3 }>
          <Grid item sm={ 4 } className={ classes.footerLeft }>
            <p>
              <img src={ Tomorrow } alt="" />
              {' '}
              See Your Tomorrows Now
            </p>
          </Grid>
          <Grid item sm={ 4 } className={ classes.footerMiddle }>
            <p>Copyright © 2020 Inkwiry. All rights reserved.</p>
          </Grid>
          <Grid item sm={ 4 } className={ classes.footerRight }>
            <p>
              Informed and Powered by
              <img src={ WhiteLogo } alt="" />
            </p>
          </Grid>
        </Grid>
      </Container> */}
      <Container className={ classes.container }>
        <Grid container>
          <Grid item sm={ 6 } className={ classes.copyRight }>
            <p>Copyright © 2022 Inkwiry. All rights reserved.</p>
          </Grid>
          <Grid item sm={ 6 } className={ classes.copyrightText }>
            <p>
              Informed and Powered by
              <img src={ WhiteLogo } alt="" />
            </p>
          </Grid>
        </Grid>
      </Container>
      <Grid container className={ classes.grayFooter }>
        <p className={ classes.termLinks }>
          <Link to="/termsofservice">Terms of Service</Link>
&nbsp;//&nbsp;
          <Link to="/privacy">Privacy Policy</Link>
&nbsp;//&nbsp;
          <Link to="/security">Security</Link>
        </p>
      </Grid>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Footer );
