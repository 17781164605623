import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-car/styles';
import BuyingCarTableBottom from './buying-cat-table-bottom';

const FirstbuyingCarSummary = ( props ) => {
  const { classes, handleBuyingCarObject, activeStep } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>Review your details and check the 20-4-10 rule. Remember, the 20-4-10 rule of car buying suggests that you can afford a car if:</p>
      <ul className={ classes.downPayment }>
        <li>20% down payment minimum</li>
        <li>4-year term of the car loan maximum</li>
        <li>10% of your total income for monthly car expenses maximum</li>
      </ul>
      <p>With a solid plan in hand, you&apos;ll have confidence in buying your next car!</p>
      <BuyingCarTableBottom handleBuyingCarObject={ handleBuyingCarObject } activeStep={ activeStep } />
    </div>
  );
};

FirstbuyingCarSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
  activeStep: PropTypes.string.isRequired,

};

export default withStyles( styles )( FirstbuyingCarSummary );
