import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import * as commonFunctions from '../../../utilities/commonFunctions';
import styles from '../../../screens/pwi-aca/styles';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../../calculations/modules-services';
import CareerBottomTabs from './career-bottom-tab-graphs';

const arrayValuesMatching = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 4,
    scaledValue: 4,
    label: '4%',
  },
  {
    value: 8,
    scaledValue: 8,
    label: '8%',
  },
  {
    value: 12,
    scaledValue: 12,
    label: '12%',
  },
  {
    value: 16,
    scaledValue: 16,
    label: '16%',
  },
  {
    value: 20,
    scaledValue: 20,
    label: '20%',
  },
];

const PersonalArray = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },{
    value: 10,
    scaledValue: 10,
    label: '10%',
  },
  {
    value: 20,
    scaledValue: 20,
    label: '20%',
  },{
    value: 30,
    scaledValue: 30,
    label: '30%',
  },
  {
    value: 40,
    scaledValue: 40,
    label: '40%',
  },{
    value: 50,
    scaledValue: 50,
    label: '50%',
  },
];
const Pension = ( props ) => {
  const {
    classes, handleCareerObject, handleDefinationPopup, handleUpdatedObject,
  } = props;
  const [beginingBalannce, setBeginingBal] = React.useState( 0 );
  const [annualExpected, setAnnualExpected] = React.useState( 0 );
  // const [iconStatus, setIconStatus] = React.useState( false );
  const [isFocus, setIsFocus] = React.useState('');

  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.pension_employer_contribution !== undefined ) {
      setAnnualExpected( handleCareerObject.pension_personal_contribution );
    } else {
      updatedValues['pension_employer_contribution'] = 0;
    }

    if ( handleCareerObject.pension_personal_contribution !== undefined ) {
      setBeginingBal( handleCareerObject.pension_employer_contribution );
    } else {
      updatedValues['pension_personal_contribution'] = 0;
    }
    handleUpdatedObject(updatedValues);
    // eslint-disable-next-line
    },[]);

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'number' ) {
      newvalue = e.floatValue;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    switch ( field ) {
      case 'pension_employer_contribution': {
        newvalue = newvalue > 100 ? 100 : newvalue;
        setAnnualExpected( newvalue );
        break;
      }
      case 'pension_personal_contribution': {
        newvalue = newvalue > 100 ? 100 : newvalue;
        setBeginingBal( newvalue );
        break;
      }
      default:
        break;
    }
    updatedValues[field] =newvalue;
    handleUpdatedObject(updatedValues);
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />

      <p>
        There are two parts to pension contributions: personal contributions and employer contributions. And, just like a 401(k), pension contributions are made before taxes.
      </p>
      <p>How much do you plan to contribute to your pension? What will your employer contribute?</p>
      <div className={ `${classes.grayBox} ${classes.grayBoxRent} ${classes.grayBoxRentNew}` }>
        <p>
          Pension Balance in Year {moduleServices.module8Data.start_year}: {moduleServices.module8Data.addCareerPensionBeginningBalance !== undefined ? (
            <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerPensionBeginningBalance} prefix={moduleServices.module8Data.addCareerPensionBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerPensionBeginningBalance < 0 && ')'} />
          ) : ('$0')}
        </p>
      </div>
      <div>
        <div className={ classes.livingExpencesRow }>
          <div className={ classes.livingExpencesDiv }>
            <div className={ classes.livingExpences }>
              <div className={ `${classes.mainBlock} ${classes.livingExpencesRangeFlex} ${classes.mainFullBlock} ${classes.mainFullBlockFull}` }>
                <div className={ `${classes.annuvalIncomeTittle} ${classes.annuvalIncomeTittleSpace} ${classes.annuvalIncomeTittleFull}` }>
                  {' '}
                  <span>Monthly</span>
                  {' '}
                </div>
                <div className={ classes.rentGraphBlock }>
                  <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangeFlexNew}` }>
                    <p>
                      <span className={ classes.noDottedText }>Personal Contribution as a % of Base Income</span>
                    </p>
                    <div className={ classes.annuvalIncomeGraph }>
                      <PwiSlider
                        ValueLabelComponent={ ValueLabelComponent }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        marks={ arrayValuesMatching }
                        min={ 0 }
                        max={ 20 }
                        step={ 0.2 }
                        value={ beginingBalannce }
                        valueLabelFormat={ `${commonFunctions.perFormatter(beginingBalannce)}` }
                        onChange={ ( e, value ) => updateValue( e, value, 'pension_personal_contribution', 'slider' ) }
                      />
                    </div>
                  </div>
                  <div className={ classes.livingExpencesMothly }>
                    <div className={ `${classes.annualInput} ${classes.annualInputRight}` }>
                      <NumberFormat
                        customInput={ TextField }
                        thousandSeparator
                        decimalScale = { isFocus!=='pension_personal_contribution'?2:5 }
                        fixedDecimalScale={isFocus!=='pension_personal_contribution'}
                        value={ beginingBalannce }
                        onValueChange={ ( e ) => updateValue( e, '', 'pension_personal_contribution', 'number' ) }
                        onFocus={ ( e ) => {e.target.select(); setIsFocus('pension_personal_contribution') } }
                        onBlur={emptyIsFocus}
                      />
                      <span>%</span>
                    </div>
                  </div>
                  <div className={ classes.livingExpencesMothly }>
                    <div className={ classes.annualInput }>
                      <span>
                        {moduleServices.module8Data.addCareerPensionPersonalContributionCalc !== undefined ? (
                          <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module8Data.addCareerPensionPersonalContributionCalc / 12 } prefix={ moduleServices.module8Data.addCareerPensionPersonalContributionCalc >= 0 ? '$' : '($' } suffix={ moduleServices.module8Data.addCareerPensionPersonalContributionCalc < 0 && ')' } />
                        ) : ( '$0' )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={ classes.rentGraphBlock }>
                  <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangeFlexNew}` }>
                    <p>
                      <span className={ classes.noDottedText }>Employer Contribution as a % of Base Income</span>
                    </p>
                    <div className={ classes.annuvalIncomeGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ PersonalArray }
                      min={ 0 }
                      max={ 50 }
                      step={ 0.2 }
                      value={ annualExpected }
                      valueLabelFormat={ `${commonFunctions.perFormatter( parseFloat( annualExpected ).toFixed( 2 ) )}` }
                      onChange={ ( e, value ) => updateValue( e, value, 'pension_employer_contribution', 'slider' ) }
                    />
                    </div>
                  </div>
                  <div className={ classes.livingExpencesMothly }>
                    <div className={ `${classes.annualInput} ${classes.annualInputRight}` }>
                      <NumberFormat
                        customInput={ TextField }
                        value={ annualExpected }
                        decimalScale={isFocus!=='pension_employer_contribution'?2:5}
                        fixedDecimalScale={isFocus!=='pension_employer_contribution'}
                        onValueChange={ ( e ) => updateValue( e, '', 'pension_employer_contribution', 'number' ) }
                        onFocus={ ( e ) => {e.target.select(); setIsFocus('pension_employer_contribution') } }
                        onBlur={emptyIsFocus}
                      />
                      <span>%</span>
                    </div>
                  </div>
                  <div className={ classes.livingExpencesMothly }>
                    <div className={ classes.annualInput }>
                      <span>
                        {moduleServices.module8Data.addCareerPensionEmployerContributionCalc !== undefined ? (
                          <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module8Data.addCareerPensionEmployerContributionCalc / 12 } prefix={ moduleServices.module8Data.addCareerPensionEmployerContributionCalc >= 0 ? '$' : '($' } suffix={ moduleServices.module8Data.addCareerPensionEmployerContributionCalc < 0 && ')' } />
                        ) : ( '$0' )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={ classes.retirementBottomPage }>
      <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockNew}` }>
        <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
          &nbsp;
        </div>
          <div className={ `${classes.annuvalIncomeTittle} ${classes.annuvalIncomeTittleSpace} ${classes.annuvalIncomeTittleSpaceXtra} ${classes.annuvalIncomeTittleFull}` }>
            {' '}
            <span>Monthly</span>
            {' '}
          </div><div className={ `${classes.annuvalIncomeTittle} ${classes.annuvalIncomeTittleSpace} ${classes.annuvalIncomeTittleSpaceXtra} ${classes.annuvalIncomeTittleFull}` }>
            {' '}
            <span>Annual</span>
            {' '}
          </div>
        </div>
        <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockNew}` }>
          <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
            <p className={ classes.textRight }>
              <span
                className={ classes.dottedText }
                aria-hidden="true"
                data-for="definationTitle"
                data-tip="Click for a definition."
                onClick={ () => handleDefinationPopup(
                  'Pension',
                ) }
              >
                Total Pension Contribution
                <br />
                (
                {moduleServices.module8Data.addCareerPensionTotalContribution_pension !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module8Data.addCareerPensionTotalContribution_pension } prefix={ moduleServices.module8Data.addCareerPensionTotalContribution_pension >= 0 ? '$' : '($' } suffix={ moduleServices.module8Data.addCareerPensionTotalContribution_pension < 0 && ')' } />
                ) : ( '$0' )}
                {' '}
                max contribution per year by law)
              </span>
            </p>
          </div>
          <div className={ classes.livingExpencesMothly }>
            <div className={ classes.annualInput }>
              <span>
                {moduleServices.module8Data.addCareerPensionTotalContribution /12 !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module8Data.addCareerPensionTotalContribution / 12 } prefix={ moduleServices.module8Data.addCareerPensionTotalContribution >= 0 ? '$' : '($' } suffix={ moduleServices.module8Data.addCareerPensionTotalContribution < 0 && ')' } />
                ) : ( '$0' )}
              </span>
            </div>
          </div>
          <div className={ `${classes.livingExpencesMothly} ${classes.livingExpencesMothlyNoBefore}` }>
            <div className={ classes.annualInput }>

              <span>
                {moduleServices.module8Data.addCareerPensionTotalContribution !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module8Data.addCareerPensionTotalContribution } prefix={ moduleServices.module8Data.addCareerPensionTotalContribution >= 0 ? '$' : '($' } suffix={ moduleServices.module8Data.addCareerPensionTotalContribution < 0 && ')' } />
                ) : ( '$0' )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <CareerBottomTabs handleCareerObject={ handleCareerObject } />
    </div>

  );
};

Pension.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  // setPensionConfirmation: PropTypes.func.isRequired,
  pensionConfirmation: PropTypes.bool.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( Pension );
