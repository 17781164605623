import React from "react";
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const StateEarning = (props) => {
    const { resultData } = props;
    const series = [];
    const data1 = [];
    const data2 = [];

        if (resultData.data1.state !== null ) {
            if(resultData['data1'] !== undefined && resultData['data1']['state'] !== undefined ) {
                data1.push(parseInt(resultData['data1']['state']['a_pct10']));
                data1.push(parseInt(resultData['data1']['state']['a_pct25']));
                data1.push(parseInt(resultData['data1']['state']['a_median']));
                data1.push(parseInt(resultData['data1']['state']['a_pct75']));
                data1.push(parseInt(resultData['data1']['state']['a_pct90']));

                series.push({
                    type: 'spline',
                    name:resultData['data1']['state']['title'],
                    data: data1,
                    color: '#0070c0'
                })
            }            
        }

        if (resultData.data2.state !== null ) {
            if(resultData['data2'] !== undefined && resultData['data2']['state'] !== undefined ) {
                data2.push(parseInt(resultData['data2']['state']['a_pct10']));
                data2.push(parseInt(resultData['data2']['state']['a_pct25']));
                data2.push(parseInt(resultData['data2']['state']['a_median']));
                data2.push(parseInt(resultData['data2']['state']['a_pct75']));
                data2.push(parseInt(resultData['data2']['state']['a_pct90']));
                series.push({
                    type: 'spline',
                    name:resultData['data2']['state']['title'],
                    data: data2,
                    color: '#ed7d31'
                })
            }
        }
    

        const formatDollar = (num) => {
            var p = num.toFixed(2).split(".");       
           return "$" + p[0].split("").reverse().reduce(function (acc, num, i, orig) {           
               return num === "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
           }, "");
       }

    // console.log(formatDollar);

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            plotBackgroundColor: '#ffffff',
                            plotBorderColor: '#cccccc',
                            plotBorderWidth: 1,
                            plotShadow: false,
                            type: 'column',
                        },
                        title: {
                            text: ''
                        },
                        credits: {
                            enabled: false
                        },
                        exporting: {
                             enabled: false
                        },
                        xAxis: {
                            categories: ['Low 10% Earnings','Low 25% Earnings','Median Earnings','Top 25% Earnings','Top 10% Earnings'],
                            labels: {
                                style: {
                                    color: '#000000',
                                    fontSize: '14px'
                                }
                            }
                        },                 
                        yAxis: [{ // Primary yAxis
                            min: 0,
                            labels: {
                                formatter() {
                                    if( this.value < 0 ) {
                                        return '<span style="color:#bf0000;">('+formatDollar(-Math.round(this.value))+')</span>';
                                    } else { 
                                        return formatDollar(Math.round(this.value));
                                    }
                                },
                                style: {
                                    color: '#000000',
                                    fontSize: '14px'
                                }
                            },
                            title: {
                                text: '',
                                style: {
                                    color: '#000000',
                                    fontSize: '14px'
                                }
                            }
                        }],
                        // tooltip: {
                        //     formatter: function() {
                        //         if( this.y < 0 ){
                        //             return this.series.name +'<br/>'+this.x +': <span style="color:red; font-weight:bold;">('+formatDollar(-Math.round(this.y))+')</span>';
                        //         }
                        //         else{ 
                        //             return this.series.name +'<br/>'+this.x +': <b>'+formatDollar(Math.round(this.y))+'</b>';
                        //         }
                        //     }
                        // },
                        tooltip: {
                            headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                            crosshairs: true,
                            shared: true,
                            valueDecimals: 0,
                            valuePrefix: '$',
                            style: {
                                    color: '#000000',
                                    fontSize: '13px'
                                }
                        },
                        plotOptions: {
                            column: {
                                stacking: 'normal'
                            }
                        },
                        legend: {
                            itemStyle: {
                                fontSize: '14px'
                            }
                        },
                        series: series
                    }
                }
            />
        </div>
    )
}

StateEarning.propTypes = {
    resultData: PropTypes.func.isRequired,
};

export default StateEarning