import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import * as commonFunctions from '../../utilities/commonFunctions';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './styles';
import classNames from 'classnames';
import puzzleIcon from '../../assets/img/parents/puzzle-icon.png';
import chooseArrow from '../../assets/img/parents/choose_arrow.png';
import chooseArrowOne from '../../assets/img/parents/choose_arrow1.png';
import choosePath from '../../assets/img/parents/choose-path.png';
import toolkitSalespg from '../../assets/img/parents/toolkit-salespg.png';
import seeTheOutcome from '../../assets/img/parents/see-the-outcome.png';
import compareSchools from '../../assets/img/parents/compare-schools.png';
import notWhiz from '../../assets/img/parents/not-a-whiz.png';
import roiGraphic from '../../assets/img/parents/roi-graphic.png';
import quoteMark from '../../assets/img/parents/block_quote.png';
import tickMark from '../../assets/img/parents/tick_mark.png';
import planningVideo from '../../assets/img/parents/Planning-With-Inkwiry-is-Fast-and-Easy.mp4';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import Pageloader from '../../components/ui/pageloader';

const SUMBIT_TOOLKIT_FORM = loader('../../graphql/schema/why-inkwiry/submit-toolkit.graphql');

let GOOGLE_CAPTCHA = process.env.REACT_APP_GOOGLE_CAPTCHA;
const DELAY = 1500;

const Parents = (props) => {
  const { classes } = props;
  const recaptchaRef = React.createRef();
  const [loading, setLoading] = React.useState(false);
  const [addGroup, setAddGroup] = useState(false); 
  const [isverified, setIsVerified] = useState(false);
  const [captchamsg, setCaptchaMsg] = useState('');
  const [load, setLoad] = useState(false);
  const [formValid, setFormValid] = useState(null);
  const [toolkit, setToolkitPopUp] = useState(false);

  const [submitToolkitForm] = useMutation(SUMBIT_TOOLKIT_FORM, {
    onCompleted() {
      setToolkitPopUp(true);
      formValid.resetFieldState('first_name');
      formValid.resetFieldState('email');
      setAddGroup(false);
      setLoading(false);
    }
  })

  React.useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, DELAY);
  }, [load]);

  const handleAddGroupPopupClose = () => {
    setAddGroup(false);
  };

  const addTeamGroup = () => {
    setAddGroup(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = 'First Name is required';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!commonFunctions.validateEmail(values.email)) {
      errors.email = 'Please enter valid email';
    }
    return errors;
  };

  const onSubmit = (values, form) => {
    if (isverified) {
      submitToolkitForm({
        variables: {
          data: {
            name: values.first_name,
            email: values.email
          },
        },
      });
      setLoading(true);
      recaptchaRef.current.reset();
    }
    setFormValid(form);
  };

  const handleSubmitClick = () => {
    if (isverified === false) {
      setCaptchaMsg('Please resolve the captcha and submit');
    }
    if (isverified === true) {
      setCaptchaMsg('');
    }
  };
  const handleChange = (value) => {
    setIsVerified(true)
  };


  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}
      <div className={classes.parentsPage}>
        <div className={classes.bannerBlock}>
          <div className={classes.bannerCaptionNew}>
            <div className={classes.container}>
              <div className={classes.bannerIntro}>
                <Typography variant="h3" component="h3">For the first time ever, easily plan <span className={classes.textBlock}> for your student's higher education</span></Typography>
                <p>Achieve peace of mind in 15 minutes or less.</p>
              </div>
              <div className={classes.bannerBtn}>
                <Link to="/plan-with-inkwiry/he">TRY PLAN WITH INKWIRY <i className="las la-greater-than"></i></Link>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.choosePathRow}>
          <div className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3} className={classes.mobileGridLeft}>
                <div className={classes.choosePathLeft}>
                  <div className={classes.puzzleIcon}>
                    <img src={puzzleIcon} alt="" />
                    <p>Plan With Inkwiry</p>
                  </div>
                  <div className={classes.chooseArrow}>
                    <img src={chooseArrow} alt="" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={9} className={classes.mobileGridRight}>
                <div className={classes.choosePathRight}>
                  <p><span className={classes.textBlue}>You're a parent who really wants to get this right.</span>  You envision your student traveling the best possible higher education path to a career that affords financial independence. But how? You're tired of worrying about how to pay for higher education and navigate the process – you want peace of mind.</p>
                  <p className={classes.paraSpace}><span className={classes.textBlue}>Plan With Inkwiry helps you get it right.</span>  We've thought everything through for you, step by step, so you don't leave your future to chance. Try it today and launch your student – and yourself – into a financially sound future.</p>
                  <div className={classes.choosePathImg}>
                    <img src={choosePath} alt="" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.instantAccessRow}>
          <div className={classes.container}>
            <div className={classes.instantContent}>
              <Typography variant="h3" component="h3">Know the outcome of decisions <span className={classes.textItalic}>before</span>  you make them.</Typography>
              <p>In 15 minutes or less, make a financial life plan for your student's <span className={classes.textBlock}>higher education that doesn't break the bank.</span></p>
            </div>
          </div>
        </div>
        <div className={classes.parentToolkitRow}>
          <div className={classes.container}>
            <Grid container className={classes.videoImageRowOne}>
              <Grid item xs={12} sm={12} md={6} className={classes.gridHalf}>
                <div className={classes.parentToolkitRowLeft}>
                  <div className={classes.parentToolkitRowOne}>
                    <Typography variant="h3" component="h3">Start with the toolkit</Typography>
                    <p>You'll get Plan With Inkwiry's helpful Higher Education Toolkit for parents, which details the 5-step higher education planning process. From discovering the true cost of attendance to navigating funding sources, Inkwiry helps you and your student discover the best education at the most reasonable cost.</p>
                  </div>
                  <div className={classes.downloadToolkit}>
                    <Typography variant="h2" component="h2">Eager to begin a plan for your student right now?</Typography>
                    <p>Get your free Higher Education Toolkit.</p>
                    <div className={classes.toolkitBtn}>
                      <span onClick={addTeamGroup}> Download My Toolkit <i className="las la-greater-than"></i></span>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className={classes.gridHalf}>
                <div className={classes.parentToolkitRowRightNew}>
                  <img src={toolkitSalespg} alt="" />
                </div>
              </Grid>
            </Grid>
            <Grid container className={classes.videoImageRowTwo}>
              <Grid item xs={12} sm={12} md={7} className={classes.videoImageLeft}>
                <video width="100%" preload="auto" muted autoPlay="true" loop playsinline>
                  <source src={planningVideo} type="video/mp4" />
                </video>
              </Grid>
              <Grid item xs={12} sm={12} md={5} className={classes.videoImageRight}>
                <Typography variant="h3" component="h3">So fast and easy</Typography>
                <p>Using Plan With Inkwiry's intuitive interface, you can plan at the speed of thought, comparing options to find the best scenario for you and your student. We want you to use your time efficiently, so there's no busywork – it's all about what's important to you.</p>
              </Grid>
            </Grid>
            <div className={classes.videoImageRowThree}>
              <Typography variant="h3" component="h3" className={classes.titleFull}>Bring clarity to the risks <span className={classes.textBlock}>and rewards</span></Typography>
              <Grid container>
                <Grid item xs={12} sm={12} md={5} className={classes.videoImageLeftTwo}>
                  <p className={classNames(classes.paraText, classes.textWidth80)}>Gain confidence and peace of mind around the decisions you're making about higher education. Factor in scholarships and grants, know all your funding sources and how they'll be used. Strategize to protect your own assets, like the money you've saved for retirement, while making sure your student will have the ability to repay any education loan debt.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={7} className={classes.videoImageRightTwo}>
                  <img src={seeTheOutcome} alt="" />
                </Grid>
              </Grid>
            </div>
            <div className={classes.videoImageRowFour}>
              <Grid container>
                <Grid item xs={12} sm={12} md={5} className={classes.videoImageLeftFour}>
                  <img src={compareSchools} alt="" />
                  <p>IPEDS, NCES, U.S. Department of Education, College Scorecard Data</p>
                </Grid>
                <Grid item xs={12} sm={12} md={7} className={classes.videoImageRightFour}>
                  <Typography variant="h3" component="h3">Compare schools <span className={classes.textBlock}>side by side </span></Typography>
                  <p>Explore and select the schools that best prepare your student for his or her intended career path, and then compare them side by side. Choose wisely as 50% of college students pursue careers that are unrelated to their major. Inkwiry provides the relevant details that help you quickly make informed decisions.</p>
                </Grid>
              </Grid>
            </div>
            <div className={classes.videoImageRowFive}>
              <Grid container>
                <Grid item xs={12} sm={12} md={5} className={classes.videoImageLeftFive}>
                  <Typography variant="h3" component="h3">You don't need to be a financial whiz</Typography>
                  <p>No prior financial knowledge is needed to Plan With Inkwiry. Inkwiry delivers choice bits of info right when you need them during the planning process. Just enter your details and Inkwiry's Financial Configurator runs the data. Detailed walk-throughs guide you through the interface. Plan at your own pace from the comfort of your home – your data is accessible anytime, anywhere.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={7} className={classes.videoImageRightFive}>
                  <img src={notWhiz} alt="" />
                </Grid>
              </Grid>
            </div>
            <div className={classes.videoImageRowSix}>
              <Typography variant="h3" component="h3" className={classes.titleFull}>Get the tool that pays for itself 1,000+ times over</Typography>
              <Grid container>
                <Grid item xs={12} sm={12} md={7} className={classes.videoImageLeftSix}>
                  <img src={roiGraphic} alt="" />
                </Grid>
                <Grid item xs={12} sm={12} md={5} className={classes.videoImageRightSix}>
                  <p>At $9, Plan With Inkwiry is ultra affordable and easily can save you $150,000 or more as you plan for higher education. In addition, as you build awareness and understanding of the decisions you need to make, Plan With Inkwiry equips you to be your own advisor and your student's advisor. The skills and knowledge you gain could change your life and the way you think about planning.</p>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className={classes.parentTestimonial}>
          <div className={classes.container}>
            <div className={classNames(classes.testimonialBlock, classes.mb50)}>
              <div className={classes.blockQuote}>
                <img src={quoteMark} alt="" />
              </div>
              <div className={classes.blockQuoteText}>
                <p>We love Plan With Inkwiry. It's really easy to use and brings all of the important higher education information together in one place and in a logical sequence. With Inkwiry, we can plan on our own from the comfort of our home and don't need to be on someone else's schedule.”</p>
                <h5>– Jeff and Veronica, Langhorne, PA</h5>
              </div>
            </div>
            <div className={classes.testimonialBlock}>
              <div className={classes.blockQuote}>
                <img src={quoteMark} alt="" />
              </div>
              <div className={classes.blockQuoteText}>
                <p>If I had this tool, I would've done things differently, like look for more scholarships and grants and encourage my daughter to pick up a part-time job in her easier semesters to lessen the need for student loans. Even with my fat fingers I could use this thing.”</p>
                <h5>– Frank, Hamilton, NJ</h5>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.parentPlanHe}>
          <div className={classes.container}>
            <Typography variant="h2" component="h2">Start planning for your student's higher <span className={classes.textBlock}>education today using Plan With Inkwiry</span></Typography>
            <Grid container className={classes.planList}>
              <Grid item xs={12} sm={12} md={3} className={classes.planListLeft}>
                <div className={classes.parentPlanHeBox}>
                  <p>WITHOUT A PLAN, THINGS HAPPEN TO YOU.</p>
                  <p className={classes.mt30}>WITH A PLAN, YOU MAKE <span className={classes.textBlock}>THINGS HAPPEN.</span></p>
                </div>
                <div className={classes.puzzleIconSpace}>
                  <img src={puzzleIcon} alt="" />
                  <p>Plan With Inkwiry</p>
                </div>
                <div className={classes.chooseIconSpace}>
                  <img src={chooseArrowOne} alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={9} className={classes.planHeDesc}>
                <p>Make a solid plan for your student's higher education with Plan With Inkwiry, the easy-to-use tool that delivers powerful insights and allows you to efficiently navigate the process on a tablet, laptop or desktop (thanks for your patience, we're working to bring Plan With Inkwiry to your phone, too!).</p>
                <p className={classes.justSpace}>With a plan in hand, you'll have confidence and peace of mind. For $9:</p>
                <ul className={classes.hePlansList}>
                  <li>
                    <span className={classes.listLeft}><img src={tickMark} alt="" /></span>
                    <span className={classes.listRight}>Obtain instant access to Plan With Inkwiry for 30 days, which gives you plenty of time to design your higher education plan. (Although your membership does not renew automatically, you can renew manually as many times as you wish or join at a higher level.)</span>
                  </li>
                  <li>
                    <span className={classes.listLeft}><img src={tickMark} alt="" /></span>
                    <span className={classes.listRight}> Run and save an unlimited number of higher education and career scenarios, and determine exactly which plan best suits you, your student and your futures.</span>
                  </li>
                  <li>
                    <span className={classes.listLeft}><img src={tickMark} alt="" /></span>
                    <span className={classes.listRight}>Enjoy full access to all of Inkwiry's career sketching resources and career sketch stories to grow your student's awareness of potential career paths and the education needed to make it happen.</span>
                  </li>
                  <li>
                    <span className={classes.listLeft}><img src={tickMark} alt="" /></span>
                    <span className={classes.listRight}>Explore Inkwiry's online course, Personal Finance 101, which has earned rave reviews from high school and college students and their teachers/professors.</span>
                  </li>
                  <li>
                    <span className={classes.listLeft}><img src={tickMark} alt="" /></span>
                    <span className={classes.listRight}>Appreciate the advanced analytics, visualizations and reporting that allow you to see your data in a new light, perform “what-if” scenarios and compare outcomes.</span>
                  </li>
                  <li>
                    <span className={classes.listLeft}><img src={tickMark} alt="" /></span>
                    <span className={classes.listRight}>Take a breath. You've got this! </span>
                  </li>
                </ul>
              </Grid>
            </Grid>
            <div className={classes.parentTryInkwiryPlan}>
              <div>
                <Typography variant="h2" component="h2">Try Plan With Inkwiry</Typography>
                <p>Want to capture that feeling of confidence and peace of mind around your higher education decisions? And develop a plan without breaking the bank? With our 7-day money-back guarantee, you have nothing to lose and everything to gain. Get Plan With Inkwiry today and build your plan for higher education today.</p>
                <div className={classNames(classes.toolkitBtn, classes.textCenter)}>
                  <span> GET PLAN WITH INKWIRY <i className="las la-greater-than"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Dialog
        onClose={handleAddGroupPopupClose}
        aria-labelledby="simple-dialog-title"
        open={addGroup}
        className={classes.toolKitPopup}
      >
        <div className={classes.toolKitPopupHeader}>
          <div aria-hidden="true" onClick={handleAddGroupPopupClose}>
            <i className="la la-times" />
          </div>
        </div>
        <div className={classes.popupBodyNew}>
          <div className={classes.popupBluebox}>
            <Typography variant="h2" component="h2">Parents:</Typography>
            <Typography variant="h3" component="h3"> Get Our Free Higher Education Toolkit</Typography>
          </div>
          <div className={classes.popupCnt}>
            <div className={classes.parentsToolkitBlock}>
              <div className={classes.parentsToolImg}>
                <img src={toolkitSalespg} alt="" />
              </div>
              <div className={classes.parentsToolCnt}>
                <p><span className={classes.textBlueNew}>Demystify the higher education planning process in 5 clear steps.</span>  Make a complete plan for your student’s higher education that doesn’t break the bank and can save $150,000 or more.</p>
                <p>To download our free Higher Education Toolkit, all you need to do is enter your first name and email address.</p>
              </div>
            </div>
            <p className={classes.popupPara}>If you don't see an email from Inkwiry Support, please check your spam folder.</p>

            <form
              className={classes.formContact}
            >
              <Form
                validate={validate}
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                  <form
                    className={classes.formContact}
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className={classes.formIn}>
                      <Field
                        id="first_name"
                        placeholder="First Name *"
                        type="text"
                        name="first_name"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div className={classes.formIn}>
                      <Field
                        id="email"
                        placeholder="Email Address *"
                        type="email"
                        name="email"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div className={classes.captcha}>
                      {load && (
                        <ReCAPTCHA
                          style={{ display: 'inline-block' }}
                          ref={recaptchaRef}
                          sitekey={GOOGLE_CAPTCHA}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                    {isverified === false ?
                      <div className={classes.captchaError}>
                        <span>{captchamsg}</span>
                      </div>
                      :
                      ''
                    }
                    <div className={classNames(classes.toolkitBtn, classes.toolkitBtnSend, "toolkitBtnSendBtn")}>
                      <Button
                        variant="contained"
                        onClick={handleSubmitClick}
                        type="submit"
                      >
                        SEND MY TOOLKIT <i className="las la-greater-than"></i>
                      </Button>
                    </div>
                  </form>
                )}
              />
              {/* <div className={classes.formIn}>
                <input type="text" placeholder="First Name*" />
              </div>
              <div className={classes.formIn}>
                <input type="text" placeholder="Email*" />
              </div>
              <div className={classNames(classes.toolkitBtn, classes.toolkitBtnSend)}>
                <span>SEND MY TOOLKIT <i className="las la-greater-than"></i></span>
              </div> */}

            </form>
          </div>
        </div>

      </Dialog>
      <Dialog
        open={toolkit}
        onClose={() => { setToolkitPopUp(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.pendinglistPopup}
      >
        <DialogContent className={classes.messagePopup}>
          <div className={classes.popupClose}>
            <span aria-hidden="true" onClick={() => { setToolkitPopUp(false) }}>X</span>
          </div>
          <div className={classes.popupBody}>
            <span className={classes.popupCheck}>
              <i className="las la-check" />
            </span>
            <p>Success! Please check your email for a link to download the Higher Education Toolkit and start your planning today</p>
          </div>
          <div />
        </DialogContent>
      </Dialog>
    </Typography>
  );
};

Parents.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(Parents);
