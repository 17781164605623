import React from 'react'
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styles from './styles';

const Privacy = (props) => {
	const { classes } = props; 
    return (
        <div className={ classes.containerRow }>
          <div className={ classes.container }>
						<Grid container spacing={ 2 }>
							<Grid item xs={ 12 } sm={ 3 }>
								<div className={ classes.readmoreLftCnt }>
								<ul>
										<li><Link to="/termsofservice">Terms Of Services</Link></li>
										<li><Link to="/privacy" className={ classes.active }>Privacy Policy</Link></li>
										<li><Link to="/security">Security</Link></li>
									</ul>
								</div>
            	</Grid>
							<Grid item xs={ 12 } sm={ 9 }>
								<div className={ classes.securityRgt }>
									<Typography variant="h2" component="h2">Inkwiry's Privacy Policy</Typography>
									<p>
											<u><b>Overview</b></u>
									</p>
									<div>
											<p>Protecting your privacy is a core goal of Inkwiry. We understand how important privacy is to you, and we are committed to creating a safe and secure environment for our users. This Privacy Policy is intended to provide information about the privacy policy of <Link to="/" className={ classes.textBlue }><u>Inkwiry.com</u></Link>. This Privacy Policy applies exclusively to personally identifiable information collected by this website. Please read it carefully and <Link to="/contact" className={ classes.textBlue }><u>contact us</u></Link> with any questions.</p>
									</div>
									<div>
											<p>This Privacy Policy is intended to inform you regarding:</p>
											<ul type="1" className={ classes.listMain }>
													<li>What information we collect;</li>
													<li>How your information is used and retained;</li>
													<li>What might be excluded from this Privacy Policy;</li>
													<li>How you can access and control your information;</li>
													<li>The strict security in place to protect your information; and</li>
													<li>Other items related to your information and this Privacy Policy.</li>
											</ul>
									</div><hr className={ classes.hrBorder } />
								<div>
									<ul type="1" className={ classes.listMain }>
											<li><b className={ classes.mb20 }>Some Examples of Information We Collect</b>
													<p>We strive to collect only the information necessary to accomplish our mission of bringing business intelligence to financial life planning so young people can make smart moves in life and reach their individual goals. To that end, we may collect information you provide such as your name, email address, phone number, date of birth, address, and some general financial data (such as how much money you make at your current job). We may also collect information you provide about your future financial projections.</p>
													<p>We also use “cookies” on our website, which can track the use of our website, and collect information based on the use of our website. You can read more about website cookies <a target="blank" href="https://www.ftc.gov/site-information/privacy-policy/internet-cookies" className={ classes.textBlue }><u>here</u></a>. We may collect information about your use of our website and services through the use of cookies or other means.</p>
											</li>
											<li>
													<b className={ classes.mb20 }>How We Might Use and Retain Your Information</b>
													<p>We consider the responsible use and retention of your information to be of the highest priority. We only collect information that you voluntarily give us via forms on this website, interaction with this website, email or other direct contact from you.</p>
													<p>We may use your information in order to deliver a high quality, effective, and rewarding user experience. For example, we may use this information to provide our products and services to you, for our marketing purposes, to respond to your inquiries, and to improve our products and services. We only share personally identifiable information with third party partners where appropriate, such as with our credit card merchant provider for processing credit card payments. When information is shared with such third-party partners, the information is subject to the respective privacy policy(ies) of the partner(s) (for example, the privacy policy of our credit card merchant provider, Stripe, can be found <a target="blank" href="https://stripe.com/us/privacy/" className={ classes.textBlue }><u>here</u></a>). We may also be compelled to disclose information by law or by court order, in which case we must comply.</p>
													<p>We will not sell your information to third parties, for advertising purposes or otherwise.</p>
													<p>You may choose to receive communications from us (such as email), when giving us your contact information or at other times. For example, if you opt-in to receive communications from us, we may tell you about new products or services, or changes to this Privacy Policy. You can opt out of this contact at any time as explained further below.</p>
													<p>Unless we receive a deletion request, we will retain your information for as long as your account is active or as is reasonably useful for operational purposes. For example, we may retain certain data as necessary to prevent fraud or future abuse, for recordkeeping or other legitimate business purposes, or if required by law. We may also retain information which has been de-identified or aggregated such that it can no longer reasonably identify a particular individual. All retained personal information will remain subject to the terms of this Privacy Policy.</p>
													<p>We do not knowingly solicit personal information from individuals under the age of 13, or where otherwise prohibited by law.</p>
											</li>
											<li>
													<b className={ classes.mb20 }>What this Privacy Policy Does Not Cover</b>
													<p>Inkwiry.com may link to third party websites or services. This Privacy Policy does not apply to any other websites, including websites that we link to, other than Inkwiry.com. Any other website is subject to its respective privacy policy.</p>
													<p>This Privacy Policy does not apply to information collected about our employees, about customers' information not collected or otherwise utilized through this website, or information which is collected in aggregate and not associated with any personally identifiable information.</p>
											</li>
											<li>
													<b className={ classes.mb20 }>Your Access to, and Control Over, Your Information</b>
													<p>At any time, you can access your Inkwiry.com account by going to the “My Account” section of the website, where you can change or correct information such as your name, email address and billing information at any time.</p>
													<p>You may opt out of any future contacts from us at any time. We will always include the means to unsubscribe or to stop receiving communications from us, usually by including an “unsubscribe” button in the communication. You may also opt out of the use of your information for marketing purposes by contacting us <Link to="/contact" className={ classes.textBlue }><u>here</u></Link>.</p>
											</li>
											<li>
													<b className={ classes.mb20 }>How We Keep Your Information Secure</b>
													<p>We strive to provide you with access to and control over the information you give us, and we take the protection of your information very seriously. To that end, we take numerous precautions and safeguards to protect your information. For more detail regarding the strict security measures we implement to protect your information, please see our dedicated Security page <Link to="/security" className={ classes.textBlue }><u>here</u></Link>.</p>
													<p>Inkwiry uses Amazon Web Services' (AWS), virtual servers in the cloud to run our application and provide our services to you. The AWS infrastructure's safeguards help to protect your privacy. All data is stored in AWS' data centers. To read more about AWS' security measures, click <a target="blank" href="https://aws.amazon.com/security/?nc1=f_cc" className={ classes.textBlue }><u>here</u></a>. Certain information may be hosted by other secure third-party providers. Information hosted by a third-party is subject to that third party's policies, standards and practices.</p>
													<p>While we take every reasonable measure to secure and protect your information, no method is completely foolproof. In the event the security of any of your information is compromised, we will take every reasonable measure to address such unintentional disclosure, notify you of the unintentional disclosure, mitigate any loss or consequence of the unintentional disclosure and work the with relevant authorities to address the unintentional disclosure.</p>
											</li>
											<li>
													<b className={ classes.mb20 }>Miscellaneous Items About Your Information and This Privacy Policy</b>
													<p>This Privacy Policy is not intended to impose any obligations upon the website, its parent company or affiliates, but is instead intended to provide information to those using this website regarding how certain user information is handled and protected.</p>
													<p>This Privacy Policy provides information in addition to any terms, conditions or other provisions of any other agreement you might have with Inkwiry. To the extent any term of this Privacy Policy might be deemed to conflict with any terms, conditions or other provisions of any other agreement you have with Inkwiry, the terms of the other document, and not this Privacy Policy, will control. We can change this Privacy Policy at any time. When we change the Privacy Policy, the updated version will be published to this website, and it will supersede any prior version of the Privacy Policy. If significant changes are made, we will attempt to notify, via email, those users of the website that have provided us with their email address. It is your responsibility to ensure your email address is up-to-date and accessible, to ensure you can receive any communications from us.</p>
													<p>We comply with the laws of every U.S. state, and every country, in which we do business. To the extent the laws of any U.S. state or country conflict with the statement(s) in this Privacy Policy, or extend protections deemed greater than those set forth in this Privacy Policy, the laws of that U.S. state or country shall supersede and apply.</p>
													<p>Your use of the website constitutes your acceptance to the practices and information described in this Privacy Policy. If you have any questions about this Privacy Policy, please <Link to="/contact" className={ classes.textBlue }><u>contact us</u></Link>. If for any reason you do not accept, agree, or otherwise are dissatisfied with the contents of this Privacy Policy, you may stop using this website as your exclusive remedy. You are not entitled to any damages or injunctive relief under this Privacy Policy, including for the unintended disclosure of information, as a result of our negligence, or otherwise. We make no other assurances, and no other remedies are provided under this Privacy Policy or otherwise.</p>
											</li>
									</ul>
							</div>
							</div>
						</Grid>
					</Grid>
			</div>
		</div>
    )
}

export default withStyles(styles)(Privacy)


