import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, Button,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import styles from '../../screens/pwi-career-path/styles';
import * as commonFunctions from '../../utilities/commonFunctions';
import * as moduleServices from '../../calculations/modules-services';
import CareerBottomTabs from './career-bottom-tab-graphs';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import SideGraphDetails from './career-path-side-graph';

const RentArray = [
  {
    value: 0,
    scaledValue: 0,
    label: '$0',
  },
  {
    value: 1500,
    scaledValue: 1500,
    label: '$1.5k',
  },
  {
    value: 3000,
    scaledValue: 3000,
    label: '$3k',
  },
  {
    value: 4500,
    scaledValue: 4500,
    label: '$4.5k',
  },
  {
    value: 6000,
    scaledValue: 6000,
    label: '$6k',
  },
  {
    value: 7500,
    scaledValue: 7500,
    label: '$7.5k',
  },
];

const utilitiesArray = [
  {
    value: 0,
    scaledValue: 0,
    label: '$0',
  },
  {
    value: 500,
    scaledValue: 500,
    label: '$500',
  },
  {
    value: 1000,
    scaledValue: 1000,
    label: '$1k',
  },
  {
    value: 1500,
    scaledValue: 1500,
    label: '$1.5k',
  },
  {
    value: 2000,
    scaledValue: 2000,
    label: '$2k',
  },
  {
    value: 2500,
    scaledValue: 2500,
    label: '$2.5k',
  },
];

const RentandUtilities = ( props ) => {
  const {
    classes, handleCareerObject, handleDefinationPopup, handleUpdatedObject, sideGraphData,
  } = props;
  const [rentValue, setRentValue] = React.useState( 0 );
  const [utilitiesValue, setUtilitiesValue] = React.useState( 0 );
  const [rentInsurance, setRentInsurance] = React.useState( 0 );

  useEffect( () => {
    $( '#percentage1' ).css( 'height', `${sideGraphData.percentageValue}%` );
    $( '#percentage2' ).css( 'height', `${sideGraphData.percentageValue1}%` );
  }, [sideGraphData] );

  
  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.rent !== undefined ) {
      setRentValue( handleCareerObject.rent );
    } else {
      updatedValues['rent'] =  0;
    }

    if ( handleCareerObject.rent_insurance !== undefined ) {
      setRentInsurance( handleCareerObject.rent_insurance );
    } else {
      updatedValues['rent_insurance'] =  0;
    }

    if ( handleCareerObject.utilities !== undefined ) {
      setUtilitiesValue( handleCareerObject.utilities );
    } else {
      updatedValues['utilities'] = 0;
    }
    handleUpdatedObject( updatedValues );
    // eslint-disable-next-line
  },[]);

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    switch ( field ) {
      case 'rent': {
        setRentValue( newvalue );
        let l = newvalue + handleCareerObject.rent_insurance;
        updatedValues['rent_total'] = l;
        updatedValues['rent'] = newvalue;
        break;
      }
      case 'utilities': {
        setUtilitiesValue( newvalue );
        updatedValues['utilities'] = newvalue;
        break;
      }
      case 'rent_insurance': {
        setRentInsurance( newvalue );
        let l = newvalue + handleCareerObject.rent;
        updatedValues['rent_total'] = l;
        updatedValues['rent_insurance'] = newvalue;
        break;
      }
      default:
        break;
    }
    handleUpdatedObject( updatedValues );
    // percentageOfIncomeAfterTax();
  };

  const handleBenchMarks = () => {
    const updatedValues = {...handleCareerObject};
    const incomeSheetData = moduleServices.incomeStatementCompleteData();
    let incomeAfterTaxesValue = 0;
    if (incomeSheetData !== undefined && incomeSheetData !== '' && incomeSheetData[handleCareerObject.start_year] !== undefined && incomeSheetData[handleCareerObject.start_year] !== '' && incomeSheetData[handleCareerObject.start_year]['Income After Taxes'] !== undefined) {
      incomeAfterTaxesValue = incomeSheetData[handleCareerObject.start_year]['Income After Taxes'];
    } 
    setRentValue( ((incomeAfterTaxesValue/12)/100)*17 );
    setUtilitiesValue( (incomeAfterTaxesValue/12/100)*1.5 );
    setRentInsurance( (incomeAfterTaxesValue/12/100)*1.5 );
    updatedValues.rent = ((incomeAfterTaxesValue/12)/100)*17;
    updatedValues.utilities = (incomeAfterTaxesValue/12/100)*1.5;
    updatedValues.rent_insurance = (incomeAfterTaxesValue/12/100)*1.5;
    handleUpdatedObject( updatedValues );
  };

  return (
    <div className={ classes.contentMainBlock }>
      <p>Building a solid budget starts with where you're going to live. How much will you spend on rent and utilities each month in this career move?</p>
      <p>If aren't renting or do not plan on renting, leave these inputs at $0.</p>
      <div className={ `${classes.grayBox} ${classes.grayBoxRent} ${classes.grayBoxRentNew}` }>
        <p>
          <font>FINANCE EXPLAINED TIP:</font>
          {' '}
          {' '}
          As a benchmark, rent, insurance and utilities should be 20.0% or less of your income after taxes. If you're not sure what your expenses might be for this career move, click on 'Use Benchmark Value &gt;' to automate your budget.
        </p>
        <Button onClick={ handleBenchMarks } className={ classes.BahanceBtn }>USE BENCHMARK VALUE</Button>
      </div>
      <div className={ `${classes.livingExpences} ${classes.livingExpencesFlexEnd}` }>
        <div className={ classes.mainBlockRow }>
          <div className={ classes.mainBlock }>
            <div className={ classes.rentGraphBlock }>
              <div className={ classes.livingExpencesRange }>
                <h3 className={ classes.opacityZero }>Monthly</h3>
                <span>Rent</span>
                <div className={ classes.annuvalIncomeGraph }>
                  <PwiSlider
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={ RentArray }
                    min={ 0 }
                    max={ 7500 }
                    step={ 50 }
                    value={ rentValue.toFixed(0) }
                    valueLabelFormat={ `${commonFunctions.numFormatter( rentValue )}` }
                    onChange={ ( e, value ) => updateValue( e, value, 'rent', 'slider' ) }
                  />
                </div>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <h3 className={ classes.livingTitle }><span>Monthly</span></h3>
                <div className={ classes.annualInput }>
                  <span>$</span>
                  <NumberFormat
                    customInput={ TextField }
                    thousandSeparator
                    value={ rentValue }
                    decimalScale={0}
                    onValueChange={ ( e ) => updateValue( e, '', 'rent', 'number' ) }
                    onFocus={ ( e ) => {
                      e.target.select();
                    } }
                  />
                </div>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <h3 className={ classes.livingTitle }><span>Annual</span></h3>
                <div className={ classes.annualInput }>
                  <span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ rentValue * 12 } prefix="$" />
                  </span>
                </div>
              </div>
            </div>
            <div className={ classes.rentGraphBlock }>
              <div className={ classes.livingExpencesRange }>
                  Renter's Insurance
                <div className={ classes.annuvalIncomeGraph }>
                  <PwiSlider
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={ utilitiesArray }
                    min={ 0 }
                    max={ 2500 }
                    step={ 10 }
                    value={ rentInsurance.toFixed(0) }
                    valueLabelFormat={ `${commonFunctions.numFormatter( rentInsurance )}` }
                    onChange={ ( e, value ) => updateValue( e, value, 'rent_insurance', 'slider' ) }
                  />
                </div>
              </div>
              <div className={ `${classes.livingExpencesMothly} ${classes.livingExpencesMothlyXtra}` }>
                <div className={ classes.annualInput }>
                  <span>$</span>
                  <NumberFormat
                    customInput={ TextField }
                    thousandSeparator
                    decimalScale={0}
                    value={ rentInsurance }
                    onValueChange={ ( e ) => updateValue( e, '', 'rent_insurance', 'number' ) }
                    onFocus={ ( e ) => {
                      e.target.select();
                    } }
                  />
                </div>
              </div>
              <div className={ `${classes.livingExpencesMothly} ${classes.livingExpencesMothlyXtra}` }>
                <div className={ classes.annualInput }>
                  <span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ rentInsurance * 12 } prefix="$" />
                  </span>
                </div>
              </div>
            </div>
            <div className={ classes.rentGraphBlock }>
              <div className={ classes.livingExpencesRange }>
                <span
                  className={ classes.dottedText }
                  aria-hidden="true"
                  data-for="definationTitle"
                  data-tip="Click for a definition."
                  onClick={ () => handleDefinationPopup(
                    'Utilities',
                  ) }
                >
                  Utilities
                </span>
                <div className={ classes.annuvalIncomeGraph }>
                  <PwiSlider
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={ utilitiesArray }
                    min={ 0 }
                    max={ 2500 }
                    step={ 10 }
                    value={ utilitiesValue }
                    valueLabelFormat={ `${commonFunctions.numFormatter( utilitiesValue )}` }
                    onChange={ ( e, value ) => updateValue( e, value, 'utilities', 'slider' ) }
                  />
                </div>
              </div>
              <div className={ `${classes.livingExpencesMothly} ${classes.livingExpencesMothlyXtra}` }>
                <div className={ classes.annualInput }>
                  <span>$</span>
                  <NumberFormat
                    customInput={ TextField }
                    thousandSeparator
                    value={ utilitiesValue }
                    decimalScale={0}
                    onValueChange={ ( e ) => updateValue( e, '', 'utilities', 'number' ) }
                    onFocus={ ( e ) => {
                      e.target.select();
                    } }
                  />
                </div>
              </div>
              <div className={ `${classes.livingExpencesMothly} ${classes.livingExpencesMothlyXtra}` }>
                <div className={ classes.annualInput }>
                  <span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ utilitiesValue * 12 } prefix="$" />
                  </span>
                </div>
              </div>
            </div>
            <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockNew} ${classes.rentPlusUntils}` }>
              <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
                <p className={ classes.textRight }>Rent + Insurance + Utilities</p>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <div className={ classes.annualInput }>
                  <span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ rentValue + rentInsurance + utilitiesValue } prefix="$" />
                  </span>
                </div>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <div className={ classes.annualInput }>

                  <span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ ( rentValue + rentInsurance + utilitiesValue ) * 12 } prefix="$" />
                  </span>
                </div>
              </div>
            </div>
            <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockItalic}` }>
              <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
                <p className={ classes.textRight }>% of Income after Taxes</p>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <div className={ classes.annualInput }>
                  <span>
                    <i>
                      {(sideGraphData.incomeAfterTaxesValue !== undefined && !isNaN(sideGraphData.incomeAfterTaxesValue) && sideGraphData.incomeAfterTaxesValue !== 0) ? (
                        <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ ( ( utilitiesValue + rentInsurance + rentValue ) * 12 ) / sideGraphData.incomeAfterTaxesValue } prefix={ sideGraphData.incomeAfterTaxesValue >= 0 ? '' : '(' } suffix={ sideGraphData.incomeAfterTaxesValue < 0 ? '%)' : '%' } />
                      ) : ( '0.00%' )}
                    </i>
                  </span>
                </div>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <div className={ classes.annualInput }>
                  <span>
                    <i>
                      {(sideGraphData.incomeAfterTaxesValue !== undefined && !isNaN(sideGraphData.incomeAfterTaxesValue) && sideGraphData.incomeAfterTaxesValue !== 0 && sideGraphData.incomeAfterTaxesValue !== undefined) ? (
                        <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ ( utilitiesValue + rentInsurance + rentValue ) / sideGraphData.incomeAfterTaxesValue } prefix={ sideGraphData.incomeAfterTaxesValue >= 0 ? '' : '(' } suffix={ sideGraphData.incomeAfterTaxesValue < 0 ? '%)' : '%' } />
                      ) : ( '0.00%' )}
                    </i>
                  </span>
                </div>
              </div>
            </div>
            <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockNew}` }>
              <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
                <p className={ classes.textRight }>Total Living Expenses</p>
              </div>
              <div className={ classes.livingExpencesMothly }>

                <div className={ classes.annualInput }>
                  <span>
                    <b>
                    {(-moduleServices.module4Data.livingExpensesCareerPath) !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" className={ moduleServices.module4Data.livingExpensesCareerPath < 0 ? classes.textDanger : '' } allowNegative={ false } thousandSeparator value={ moduleServices.module4Data.livingExpensesCareerPath } prefix={ (-moduleServices.module4Data.livingExpensesCareerPath) >= 0 ? '$' : '($' } suffix={ (-moduleServices.module4Data.livingExpensesCareerPath) < 0 && ')' } />
                    ) : ( '$0' )}
                  
                  </b>
                  </span>
                </div>
              </div>
              <div className={ classes.livingExpencesMothly }>
                <div className={ classes.annualInput }>
                  <span>
                    <b>
                    {moduleServices.module4Data.careerPathLivingExpenses !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" className={ moduleServices.module4Data.careerPathLivingExpenses < 0 ? classes.textDanger : '' }  allowNegative={ false } thousandSeparator value={ moduleServices.module4Data.careerPathLivingExpenses * 12 } prefix={ moduleServices.module4Data.careerPathLivingExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module4Data.careerPathLivingExpenses < 0 && ')' } />
                    ) : ( '$0' )}
                    </b>
                  </span>
                </div>
              </div>
            </div>

          </div>

          <SideGraphDetails sideGraphData={ sideGraphData } />
        </div>
      </div>
      <div className={ classes.borderDevider } />
      <CareerBottomTabs handleCareerObject={ handleCareerObject }/>

      <ReactTooltip
        id="definationTitle"
        place="top"
        type="info"
        effect="solid"
        className={ classes.tooltipInfoTop }
      />
    </div>

  );
};

RentandUtilities.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  sideGraphData: PropTypes.object.isRequired,

};

export default withStyles( styles )( RentandUtilities );
