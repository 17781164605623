import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/further-higher-education/styles';
import * as moduleServices from '../../../calculations/modules-services';

const UnsubsidizedStudentLoan = ( props ) => {
  const {
    classes, handleDefinationPopup, updateData, inputData
  } = props;

  const [checked, setChecked] = useState( (inputData.fed_unsub_paymentplan !== undefined && inputData.fed_unsub_paymentplan !== '' && inputData.fed_unsub_paymentplan > 0) ? inputData.fed_unsub_paymentplan : 2 );

  useEffect(()=>{
    if(!(inputData.fed_unsub_paymentplan !== undefined && inputData.fed_unsub_paymentplan !== '' && inputData.fed_unsub_paymentplan > 0)) {
      updateData('fed_unsub_paymentplan',2);
    }
  },[]);

  const handleToggle = ( check ) => {
    setChecked( check );
    updateData('fed_unsub_paymentplan',check);
  };

  const tableRows = ['Interest Rate','Payment Period','Term of the Loan (years)','Loan Amount','Interest Paid before Repayment','Accrued Interest','Beginning Balance after Grace Period','Monthly Payment','Total Interest Paid','Total Repayment Amount (Principal & Interest)'];


  const displayValue = (type, paymentType) => {
    const fedUnsubHigherEdLoanData = moduleServices.fedUnSubNewHigherEdLoanCompleteData();
    let value = 0;
    if(checked === paymentType) {
      if(typeof fedUnsubHigherEdLoanData !== 'undefined' && fedUnsubHigherEdLoanData !== '' 
          && typeof fedUnsubHigherEdLoanData['Repayment Data'] !== 'undefined' && fedUnsubHigherEdLoanData['Repayment Data'] !== '') {
          if(typeof fedUnsubHigherEdLoanData['Repayment Data'][type] !== 'undefined') {
              value = fedUnsubHigherEdLoanData['Repayment Data'][type];
          }
      }
    } else {
      if(typeof fedUnsubHigherEdLoanData !== 'undefined' && fedUnsubHigherEdLoanData !== '' 
          && typeof fedUnsubHigherEdLoanData['Repayment Data New'] !== 'undefined' && fedUnsubHigherEdLoanData['Repayment Data New'] !== '') {
          if(typeof fedUnsubHigherEdLoanData['Repayment Data New'][type] !== 'undefined') {
              value =  fedUnsubHigherEdLoanData['Repayment Data New'][type];
          } 
      }
    }

    if(type === 'Interest Rate') {
      return (
        <NumberFormat decimalScale={ 2 } fixedDecimalScale value={ value } displayType="text" thousandSeparator suffix="%" />
      );
    } else if(type === 'Term of the Loan (years)') {
      return (
        <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ value } displayType="text" thousandSeparator suffix=" yrs" />
      )
    } else if(type === 'Payment Period') {
      return (
        <span>{value}</span>
      )
    } else {
      if(value >= 0 ) {        
        return (
          <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ value } displayType="text" thousandSeparator prefix="$" />
        );
      } else {
        return (
          <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ -value } displayType="text" thousandSeparator prefix="($" suffix=")"/>
        );
      }
    }

  }

  return (
    <div className={ `${classes.contentBlock} ${classes.forStufentLonas}` }>
      <p>
        Interest on federal unsubsidized student loans
        <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Accrued Interest' ) }>accrue</span>
        starting from the day you receive any money. What does that mean exactly? Every month during school that you don’t make a payment, your student loan balance is growing.
      </p>
      <p>During school, you have the option to make interest payments. Will paying interest during school save you money on your loan? This strategy has the potential to save you thousands. Toggle between make or defer below to see how this option can affect your student loan repayment.</p>
      <p>Make sure you have enough cash to support this move.</p>
      <div className={ `${classes.flexContainer} ${classes.flexConatineNew}` }>
        
        <div className={ classes.deferText }>
          <p className={ classes.deferDesc } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Defer Interest Payments: Student Loan' ) }><font className={ classes.dottedText }>Make / Defer Interest Payments during School:</font></p>
          <ul className={ classes.radioDefer }>
            <li aria-hidden="true" className={ checked === 1 ? classes.activeList : '' } onClick={ () => handleToggle( 1 ) }>
              Make
            </li>
            <li aria-hidden="true" className={ checked === 2 ? classes.activeList : '' } onClick={ () => handleToggle( 2 ) }>
              Defer
            </li>
          </ul>
        </div>
      </div>
      <div className={ `${classes.giftSources} ${classes.giftSourcesNewHalf} ${classes.duringScholl} ${classes.deferTable}` }>
        <table cellPadding="0" cellSpacing="0" className={ checked === 1 ? classes.makeBg : classes.deferBg }>
          <thead>
            <tr>
              <th>Federal Unsubsidized Student Loan Repayment</th>
              <th>Make Interest Payments</th>
              <th>Defer Interest Payments</th>
            </tr>
          </thead>
          <tbody className={ classes.studentLoansAnalysisTable }>
            {tableRows.map( ( value ) => {
              return (
                <tr>
                  <td className={(value === 'Accrued Interest' || value === 'Total Repayment Amount (Principal & Interest)') && (classes.blueBottomBorder)}>
                    {value !== 'Beginning Balance after Grace Period' && value !== 'Interest Paid before Repayment' && value}
                    {value === 'Beginning Balance after Grace Period' && 'Beginning Balance when Repayment Begins'}
                    {value === 'Interest Paid before Repayment' && 'Interest Paid before Repayment Begins'}
                  </td>
                  <td className={(value === 'Accrued Interest' || value === 'Total Repayment Amount (Principal & Interest)') && (classes.blueBottomBorder)}>
                    {displayValue(value,1)}
                  </td>
                  <td className={(value === 'Accrued Interest' || value === 'Total Repayment Amount (Principal & Interest)') && (classes.blueBottomBorder)}>
                    {displayValue(value,2)}
                  </td>
                </tr>
              )
            } )}
          </tbody>
        </table>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};

UnsubsidizedStudentLoan.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  roomBoard: PropTypes.string.isRequired,
  tuitionFees: PropTypes.string.isRequired,
  totalOffCampusWithYear: PropTypes.string.isRequired,
  totalOnCampusWithYear: PropTypes.string.isRequired,
  totalCostOfAttendance: PropTypes.string.isRequired,
  totalFunding: PropTypes.string.isRequired,
  totalScholarships: PropTypes.string.isRequired,
  totalGrand: PropTypes.string.isRequired,
  remainingAmount: PropTypes.string.isRequired,
  totalFamilyContribution: PropTypes.string.isRequired,
  totalCashInvestment: PropTypes.string.isRequired,
  totalEstimatedAfterTaxRate: PropTypes.string.isRequired,
};

export default withStyles( styles )( UnsubsidizedStudentLoan );
