import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import styles from '../../screens/pwi-investments/styles';
import CareerSketchTimeline from '../ui/career-sketch-timeline/career-sketch-timeline';
import * as modulesServices from '../../calculations/modules-services';
import InvestmentBottomTwo from './investmment-bottomTwo';
// import _ from 'lodash';
import { Dialog, DialogContent } from '@material-ui/core';
import MaxBalance from '../../assets/img/pwi-investments/max-balance.png';

const SettingTimelines = (props) => {
  const {
    classes, getInvestmentObject, handleInvestmentData
  } = props;

  const [startYear, setStartYear] = React.useState();
  const [open, setOpen] = React.useState(false);

  const d = new Date();
  const currentYear = d.getFullYear();
  const startYearOptions = [];
  const loopYear = modulesServices.module1Data.start_year > 0 ? modulesServices.module1Data.start_year : currentYear;
  for (let year = 0; year <= 14; year += 1) {
    startYearOptions.push(loopYear + year);
  }

  const handleStartYear = (event) => {
    let InvObj = {...getInvestmentObject};
    let start_year = 0;
    start_year = parseInt(event.target.value);
    setStartYear(start_year);
    InvObj['short_term_start_year']=parseInt(event.target.value, 10);
    handleInvestmentData(InvObj);    
  };

  useEffect(() => {
    if(getInvestmentObject.short_term_start_year !== undefined && getInvestmentObject.short_term_start_year !== '' && getInvestmentObject.short_term_start_year !== null){
    setStartYear(getInvestmentObject.short_term_start_year);
    }else{
      setStartYear(currentYear);
    }
  }, [getInvestmentObject]);

  return (
    <div className={classes.contentBlock}>
      <p>Only 39% of Americans could pay for a $1,000 emergency expense as of <a href="https://www.cnbc.com/2021/01/11/just-39percent-of-americans-could-pay-for-a-1000-emergency-expense.html" target="_blank" rel="noreferrer" >January 2021</a>. Peace of mind starts with having enough short-term investments to cover at least six months of expenses. That way, when the unexpected strikes, you have room to adapt and confidence in your finances.</p>
      <p>When do you plan on contributing to short-term investments? If you already are contributing, enter today's year. If not, enter the year you plan to start.</p>
      <div className={classNames(classes.grayBox, classes.investGrayDesign)}>
        <p>
          Make sure your net cash flow is positive in the year you plan to start making short-term investments.
        </p>
        <ul className={classes.yearsList}>
          <li>
            <span>I plan to start making short-term investments in year&nbsp;</span>
            <select value={startYear} onChange={handleStartYear} >
              {startYearOptions.map((year) => <option value={year} key={year}>{year}</option>)}
            </select>
            .
          </li>
          <li>
            <span>Beginning short-term investments balance in year&nbsp;</span>
            <span>{startYear ? startYear : ''} : </span>
            {modulesServices.module9Data.shortTermBeginningBalance ?
              <NumberFormat id="beginning" type="text" displayType={'text'} decimalScale={0} fixedDecimalScale thousandSeparator value={modulesServices.module9Data.shortTermBeginningBalance} prefix={'$'} />
              :
              '$0'
            }.
          </li>
          <li>
            <span className={classes.anchorFont}><span className={classes.clickHere} onClick={() => { setOpen(true) }}>Max short-term investments balance</span>&nbsp;:</span>
            {modulesServices.module9Data.shortTermBeginningBalance ?
              <NumberFormat id="beginning" type="text" decimalScale={0} displayType={'text'} fixedDecimalScale thousandSeparator value={modulesServices.module9Data.shortTermBeginningBalance} prefix={'$'} />
              :
              '$0'
            }.
          </li>
        </ul>
      </div>
      <CareerSketchTimeline moduleName="module9" />
      <div className={ `${classes.borderDivider} ${classes.borderDividerMT30}` } />
      {startYear !== '' && startYear !== undefined &&
        <InvestmentBottomTwo activeSlide={3} getInvestmentObject={getInvestmentObject} startYear={startYear} />
      }
      <Dialog
        open={open}
        onClose={() => { setOpen(false); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.maxModule}
      >
        <DialogContent className={classes.maxModal}>
          <div className={classes.maxHeader}>
            <h3>MaxBalance</h3>
            <div className={classes.popupClose}>
              <span className={classes.maxClose} aria-hidden="true" onClick={() => { setOpen(false); }}>
                ×
              </span>
            </div>
          </div>
          <div className={classes.maxModalBody}>
            <div>
              <img src={MaxBalance} alt="" />
            </div>
          </div>

        </DialogContent>
      </Dialog>
    </div>

  );
};

SettingTimelines.propTypes = {
  classes: PropTypes.object.isRequired,
  getInvestmentObject: PropTypes.object.isRequired,
  handleInvestmentData: PropTypes.func.isRequired,

};

export default withStyles(styles)(SettingTimelines);
