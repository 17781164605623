// removed these from below function  i.e. module1Data, module2Data, studentLoan2Data, incomeTaxData ---- mahananda

function moreHigherEducationSheetPlayback( module6Data, inputData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData, privateMoreHigherEdLoanData, databaseData ) {
  // console.log( 'module1Data', module1Data, module10Data );

  let year = 0;
  let yearLimit = 0;
  let higherEdPaybackData = {};
  const higherEducationPaybackData = {};
  const higherEducationTaxesData = {};
  const higherSchoolTaxesData = {};
  // let educationDataArray = {};
  // let schoolDataArray = {};
  const GraphsData = {};
  let tutionValue = 0;
  let scholarshipsValue = 0;
  let grantsValue = 0;
  let financialAidValue = 0;
  let capitalInvestedLostReturnVal = 0;
  let totalInvestmentValue = 0;
  let higherEducationGrossIncomeVal = 0;
  let higherEducationTaxesVal = 0;
  let higherEducationIncome = 0;
  let higherSchoolIncome = 0;
  let higherEducationTaxRates = 0;
  let highSchoolTaxRates = 0;
  let higherEducationNetIncomeVal = 0;
  let higherSchoolGrossIncomeVal = 0;
  let higherSchoolTaxesVal = 0;
  let higherSchoolNetIncomeVal = 0;
  let totalGainedIncomeVal = 0;
  let paybackVal = 0;
  let paybackPeriodVal = 0;
  let capitalInvestmentTotal = 0;
  let scholarshipsTotal = 0;
  let grantsTotal = 0;
  let financialAidTotal = 0;
  let excessFamilyContributionTotal = 0;

  year = parseInt( module6Data.start_year, 10 );
  yearLimit = parseInt( module6Data.start_year, 10 ) + 30;

  higherEdPaybackData = {};

  higherEducationPaybackData['Higher Education Salary'] = inputData.higherEducationSalary;
  higherEducationPaybackData['High School Salary'] = inputData.highSchoolSalary;
  higherEducationPaybackData['Higher Education Growth Rate on Salary'] = inputData.highEducationGrowthRateOnSalary;
  higherEducationPaybackData['Higher School Growth Rate on Salary'] = inputData.highSchoolGrowthRateOnSalary;
  higherEducationPaybackData['Capital Investment Payback'] = inputData.capitalInvestment;

  // below code commeneted because it never used anywhere ----- mahananda
  // let percentageArray = [];
  // if ( typeof incomeTaxData !== 'undefined' && incomeTaxData !== '' && typeof incomeTaxData.percentageArray !== 'undefined' && incomeTaxData.percentageArray !== '' ) {
  //   percentageArray = incomeTaxData[percentageArray];
  // }

  // Single Range Arrays
  // let educationRangeArray = [];
  // let educationRange2Array = [];
  // let schoolRangeArray = [];
  // let schoolRange2Array = [];
  // if ( typeof incomeTaxData !== 'undefined' && incomeTaxData !== '' && typeof incomeTaxData.singleRangeArray !== 'undefined' && incomeTaxData.singleRangeArray !== '' ) {
  //   educationRangeArray = incomeTaxData.singleRangeArray;
  // schoolRangeArray = incomeTaxData.singleRangeArray;
  // }
  // if ( typeof incomeTaxData !== 'undefined' && incomeTaxData !== '' && typeof incomeTaxData.singleRange2Array !== 'undefined' && incomeTaxData.singleRange2Array !== '' ) {
  //   educationRange2Array = incomeTaxData.singleRange2Array;
  // schoolRange2Array = incomeTaxData.singleRange2Array;
  // }
  // below code commeneted because it never used anywhere ----- mahananda

  // educationDataArray = {};

  // educationDataArray = percentageArray.map( ( percentage, index ) => {
  //   const returnData = {};
  //   returnData[index] = {};
  //   returnData[index].Percentage = percentage;
  //   returnData[index].Range = educationRangeArray[index];
  //   returnData[index].Range2 = educationRange2Array[index];
  //   if ( index === 6 ) {
  //     returnData[index].Difference = 0;
  //     returnData[index].Tax = 0;
  //   } else {
  //     returnData[index].Difference = returnData[index].Range2 - returnData[index].Range;
  //     returnData[index].Tax = returnData[index].Difference * ( percentage / 100 );
  //   }
  //   return returnData;
  // } );
  // below code commeneted because it never used anywhere ----- mahananda
  // schoolDataArray = {};

  // schoolDataArray = percentageArray.map( ( percentage, index ) => {
  //   const returnData = {};
  //   returnData[index] = {};
  //   returnData[index].Percentage = percentage;
  //   returnData[index].Range = schoolRangeArray[index];
  //   returnData[index].Range2 = schoolRange2Array[index];
  //   if ( index === 6 ) {
  //     returnData[index].Difference = 0;
  //     returnData[index].Tax = 0;
  //   } else {
  //     returnData[index].Difference = returnData[index].Range2 - returnData[index].Range;
  //     returnData[index].Tax = returnData[index].Difference * ( percentage / 100 );
  //   }
  //   return returnData;
  // } );

  function educationCalculation( income ) {
    // Total Taxes
    const totalTaxes = 0;

    // below code commeneted because it never used anywhere ----- mahananda

    // let educationIteration1Array = {};
    // educationIteration1Array = percentageArray.map( ( percentage, index ) => {
    //   const returnData = {};
    //   returnData[index] = {};
    //   returnData[index].Percentage = percentage;
    //   let range = 0;
    //   if ( income >= educationDataArray[index].Range ) {
    //     range = educationDataArray[index].Range;
    //   } else {
    //     range = 0;
    //   }
    //   returnData[index].Range = range;
    //   let range2 = 0;
    //   if ( index === 6 ) {
    //     if ( income >= educationDataArray[index].Range ) {
    //       range2 = income;
    //     } else {
    //       range2 = 0;
    //     }
    //   } else if ( income >= educationDataArray[index].Range2 ) {
    //     range2 = educationDataArray[index].Range2;
    //   } else if ( income <= educationDataArray[index].Range2
    //         && income >= educationDataArray[index].Range ) {
    //     range2 = income;
    //   } else {
    //     range2 = 0;
    //   }
    //   returnData[index].Range2 = range2;

    //   returnData[index].Difference = range2 - range;

    //   returnData[index].Tax = returnData[index].Difference * ( percentage / 100 );

    //   totalTaxes += returnData[index].Tax;
    //   return returnData;
    // } );
    // Federal Income Tax
    let federalIncomeTax = 0;
    if ( totalTaxes > 0 ) {
      federalIncomeTax = ( totalTaxes / income ) * 100;
    }
    // Social Security
    const socialSecurity = 7.65;
    // State & local
    const stateAndLocal = 5;
    // Total Tax
    const totalTax = federalIncomeTax + socialSecurity + stateAndLocal;

    const taxInfo = {};
    taxInfo['Total Taxes'] = totalTaxes;
    taxInfo['Effective Tax Rate'] = federalIncomeTax;
    taxInfo['State & Local'] = stateAndLocal;
    taxInfo['Federal Income Tax'] = federalIncomeTax;
    taxInfo['Social Security'] = socialSecurity;
    taxInfo['Total Tax'] = totalTax;

    return taxInfo;
  }

  function schoolCalculation( income ) {
    // Total Taxes
    const totalTaxes = 0;

    // below code commeneted because it never used anywhere ----- mahananda

    // let schoolIteration1Array = {};
    // schoolIteration1Array = percentageArray.map( ( percentage, index ) => {
    //   const returnData = {};
    //   returnData[index] = {};
    //   returnData[index].Percentage = percentage;

    //   let range = 0;
    //   if ( income >= educationDataArray[index].Range ) {
    //     range = educationDataArray[index].Range;
    //   } else {
    //     range = 0;
    //   }
    //   returnData[index].Range = range;
    //   let range2 = 0;
    //   if ( index === 6 ) {
    //     if ( income >= educationDataArray[index].Range ) {
    //       range2 = income;
    //     } else {
    //       range2 = 0;
    //     }
    //   } else if ( income >= educationDataArray[index].Range2 ) {
    //     range2 = educationDataArray[index].Range2;
    //   } else if ( income <= educationDataArray[index].Range2
    //         && income >= educationDataArray[index].Range ) {
    //     range2 = income;
    //   } else {
    //     range2 = 0;
    //   }
    //   returnData[index].Range2 = range2;

    //   returnData[index].Difference = range2 - range;

    //   returnData[index].Tax = returnData[index].Difference * ( percentage / 100 );

    //   totalTaxes += returnData[index].Tax;
    //   return returnData;
    // } );
    // Federal Income Tax
    let federalIncomeTax = 0;
    if ( totalTaxes > 0 ) {
      federalIncomeTax = ( totalTaxes / income ) * 100;
    }
    // Social Security
    const socialSecurity = 7.65;

    // State & local
    const stateAndLocal = 5;
    // Total Tax
    const totalTax = federalIncomeTax + socialSecurity + stateAndLocal;

    const taxInfo = {};
    taxInfo['Total Taxes'] = totalTaxes;
    taxInfo['Effective Tax Rate'] = federalIncomeTax;
    taxInfo['State & Local'] = stateAndLocal;
    taxInfo['Federal Income Tax'] = federalIncomeTax;
    taxInfo['Social Security'] = socialSecurity;
    taxInfo['Total Tax'] = totalTax;

    return taxInfo;
  }

  const higherEducationIncomeData = [];
  const highSchoolNetIncomeData = [];
  const debtData = [];
  const capitalData = [];
  const yearsList = [];
  const paybackYearValue = [];
  let paybackYear = 0;
  let k = 0;
  let j = 0;
  let m = 0;
  // let capitalInvestmentTotal = 0;
  // let excessFamilyContributionTotal = 0;
  // let scholarshipsTotal = 0;
  // let grantsTotal = 0;
  // let financialAidTotal = 0;
  let fedSubLaggingInterest = 0;
  let fedSubPointsPaid = 0;
  let fedUnSubLaggingInterest = 0;
  let fedUnSubPointsPaid = 0;
  let fedPrivateLaggingInterest = 0;
  let fedPrivatePointsPaid = 0;
  let capitalInvestedValue = 0;
  let cumulativeInvestment = 0;
  let cumulativeGained = 0;
  let positivePaybackYear = 0;
  for ( year; year < yearLimit; year += 1 ) {
    higherEdPaybackData[year] = {};
    higherEdPaybackData[year]['Investment Summary'] = {};
    higherEdPaybackData[year]['Total Investment'] = {};
    higherEdPaybackData[year]['Gained Income'] = {};
    higherEdPaybackData[year]['Tax Rates'] = {};
    higherEdPaybackData[year]['Higher Education'] = {};
    higherEdPaybackData[year]['Total Gained Income'] = {};
    higherEdPaybackData[year].Payback = {};
    higherEdPaybackData[year]['Payback Period'] = {};
    GraphsData[year] = {};

    higherEducationTaxesData[year] = {};
    higherSchoolTaxesData[year] = {};
    yearsList.push( year );

    /** Investment Summary ** */
    // Tuition
    if ( typeof module6Data.graduation_year !== 'undefined' && module6Data.graduation_year !== '' && typeof module6Data.moreHigherinvestmentAnalysisTuitionCosts !== 'undefined' && module6Data.moreHigherinvestmentAnalysisTuitionCosts !== '' && typeof module6Data.start_year !== 'undefined' ) {
      if ( year <= module6Data.graduation_year && typeof module6Data.years_diff !== 'undefined' && module6Data.years_diff !== '' ) {
        tutionValue = -module6Data.moreHigherinvestmentAnalysisTuitionCosts / module6Data.years_diff;
      } else {
        tutionValue = 0;
      }
    } else {
      tutionValue = 0;
    }
    higherEdPaybackData[year]['Investment Summary'].Tuition = tutionValue;

    // Scholarships
    if ( typeof module6Data.graduation_year !== 'undefined' && module6Data.graduation_year !== ''
      && typeof module6Data.moreHigherinvestmentAnalysisScholships !== 'undefined'
      && module6Data.moreHigherinvestmentAnalysisScholships !== '' && typeof module6Data.years_diff !== 'undefined' && module6Data.years_diff !== '' ) {
      if ( year <= module6Data.graduation_year ) {
        scholarshipsValue = -module6Data.moreHigherinvestmentAnalysisScholships / parseInt( module6Data.years_diff, 10 );
      } else {
        scholarshipsValue = 0;
      }
    }
    higherEdPaybackData[year]['Investment Summary'].Scholarships = scholarshipsValue;
    // Scholarships Total
    scholarshipsTotal += higherEdPaybackData[year]['Investment Summary'].Scholarships;

    // Grants
    if ( typeof module6Data.graduation_year !== 'undefined' && module6Data.graduation_year !== '' && module6Data.moreHigherinvestmentAnalysisGrants !== '' && module6Data.years_diff !== '' ) {
      if ( year <= module6Data.graduation_year ) {
        grantsValue = -module6Data.moreHigherinvestmentAnalysisGrants / module6Data.years_diff;
      } else {
        grantsValue = 0;
      }
    }
    higherEdPaybackData[year]['Investment Summary'].Grants = grantsValue;
    // Grants Total
    grantsTotal += higherEdPaybackData[year]['Investment Summary'].Grants;

    // Other Financial Aid
    if ( typeof module6Data.graduation_year !== 'undefined' && module6Data.graduation_year !== ''
      && module6Data.moreHigherinvestmentAnalysisFinancialAid !== '' && module6Data.years_diff !== '' ) {
      if ( year <= module6Data.graduation_year ) {
        financialAidValue = -module6Data.moreHigherinvestmentAnalysisFinancialAid / module6Data.years_diff;
      } else {
        financialAidValue = 0;
      }
    }
    higherEdPaybackData[year]['Investment Summary']['Other Financial Aid'] = financialAidValue;
    // Financial Total
    financialAidTotal += higherEdPaybackData[year]['Investment Summary']['Other Financial Aid'];

    // Capital Invested
    const capitalInvestedValue1 = parseFloat( tutionValue )
      + parseFloat( scholarshipsValue )
      + parseFloat( grantsValue )
      + parseFloat( financialAidValue );
    if ( capitalInvestedValue1 > 0 ) {
      capitalInvestedValue = capitalInvestedValue1;
    } else {
      capitalInvestedValue = 0;
    }
    higherEdPaybackData[year]['Investment Summary']['Capital Invested'] = capitalInvestedValue;
    // Capital Investment Total
    capitalInvestmentTotal += higherEdPaybackData[year]['Investment Summary']['Capital Invested'];

    // Excess Family Contribution
    if ( typeof module6Data.graduation_year !== 'undefined'
      && module6Data.graduation_year !== ''
      && typeof module6Data.yearly_contribution !== 'undefined' && module6Data.yearly_contribution !== '' ) {
      if ( year <= module6Data.graduation_year
        && capitalInvestedValue < module6Data.yearly_contribution ) {
        capitalInvestedLostReturnVal = module6Data.yearly_contribution
          - capitalInvestedValue;
      } else {
        capitalInvestedLostReturnVal = 0;
      }
    }
    higherEdPaybackData[year]['Investment Summary']['Excess Family Contribution'] = capitalInvestedLostReturnVal;
    excessFamilyContributionTotal += capitalInvestedLostReturnVal;

    // Interest Expense
    if ( typeof fedSubMoreHigherEdLoanData !== 'undefined'
      && typeof fedSubMoreHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof fedSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined'
      && typeof fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' ) {
      fedSubLaggingInterest = fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest;
    } else {
      fedSubLaggingInterest = 0;
    }
    if ( typeof fedSubMoreHigherEdLoanData !== 'undefined'
      && typeof fedSubMoreHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof fedSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined'
      && typeof fedSubMoreHigherEdLoanData['Summation Data'][year]['Points Paid'] !== 'undefined' ) {
      fedSubPointsPaid = fedSubMoreHigherEdLoanData['Summation Data'][year]['Points Paid'];
    } else {
      fedSubPointsPaid = 0;
    }
    if ( typeof fedUnSubMoreHigherEdLoanData !== 'undefined'
      && typeof fedUnSubMoreHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined'
      && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' ) {
      fedUnSubLaggingInterest = fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest;
    } else {
      fedUnSubLaggingInterest = 0;
    }
    if ( typeof fedUnSubMoreHigherEdLoanData !== 'undefined'
      && typeof fedUnSubMoreHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined'
      && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year]['Points Paid'] !== 'undefined' ) {
      fedUnSubPointsPaid = fedUnSubMoreHigherEdLoanData['Summation Data'][year]['Points Paid'];
    } else {
      fedUnSubPointsPaid = 0;
    }
    if ( typeof privateMoreHigherEdLoanData !== 'undefined'
      && typeof privateMoreHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof privateMoreHigherEdLoanData['Summation Data'][year] !== 'undefined'
      && typeof privateMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' ) {
      fedPrivateLaggingInterest = privateMoreHigherEdLoanData['Summation Data'][year].LaggingInterest;
    } else {
      fedPrivateLaggingInterest = 0;
    }
    if ( typeof privateMoreHigherEdLoanData !== 'undefined'
      && typeof privateMoreHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof privateMoreHigherEdLoanData['Summation Data'][year] !== 'undefined'
      && typeof privateMoreHigherEdLoanData['Summation Data'][year]['Points Paid'] !== 'undefined' ) {
      fedPrivatePointsPaid = privateMoreHigherEdLoanData['Summation Data'][year]['Points Paid'];
    } else {
      fedPrivatePointsPaid = 0;
    }
    higherEdPaybackData[year]['Investment Summary']['Interest Expense'] = fedSubLaggingInterest + fedSubPointsPaid + fedUnSubLaggingInterest + fedUnSubPointsPaid + fedPrivateLaggingInterest + fedPrivatePointsPaid;

    // Annual Investment
    totalInvestmentValue = higherEdPaybackData[year]['Investment Summary']['Capital Invested']
      + higherEdPaybackData[year]['Investment Summary']['Interest Expense']
      + higherEdPaybackData[year]['Investment Summary']['Excess Family Contribution'];
    higherEdPaybackData[year]['Annual Investment'] = totalInvestmentValue;

    // Higher Education Gross Income
    if ( typeof module6Data.graduation_year !== 'undefined' && module6Data.graduation_year !== '' ) {
      if ( year > module6Data.graduation_year ) {
        if ( year === ( parseFloat( module6Data.graduation_year ) + 1 ) ) {
          higherEducationGrossIncomeVal = higherEducationPaybackData['Higher Education Salary'];
        } else if ( m === 0 ) {
          higherEducationGrossIncomeVal = 0;
        } else {
          higherEducationGrossIncomeVal *= ( 1 + ( higherEducationPaybackData['Higher Education Growth Rate on Salary'] / 100 ) );
        }
      } else {
        higherEducationGrossIncomeVal = 0;
      }
    } else {
      higherEducationGrossIncomeVal = 0;
    }
    higherEdPaybackData[year]['Gained Income']['Higher Education Gross Income'] = higherEducationGrossIncomeVal;

    // Higher Education Income
    higherEducationIncome = 0;

    if ( typeof databaseData !== 'undefined' && typeof databaseData.Data !== 'undefined' && databaseData.Data !== '' && typeof databaseData.Data['Standard Deductions'] !== 'undefined' && typeof databaseData.Data['Standard Deductions'].Single !== 'undefined' && higherEdPaybackData[year]['Gained Income']['Higher Education Gross Income'] - databaseData.Data['Standard Deductions'].Single > 0 ) {
      higherEducationIncome = higherEdPaybackData[year]['Gained Income']['Higher Education Gross Income'] - databaseData.Data['Standard Deductions'].Single;
    }
    higherEducationTaxesData[year]['Higher Education Income'] = higherEducationIncome;

    higherEducationTaxesData[year].Education = {};
    higherEducationTaxesData[year].Education = educationCalculation( Math.round( higherEducationIncome ), year );
    // Higher School Gross Income
    if ( typeof module6Data.start_year !== 'undefined' && module6Data.start_year !== ''
      && year === module6Data.start_year ) {
      higherSchoolGrossIncomeVal = higherEducationPaybackData['High School Salary'];
    } else if ( m === 0 ) {
      higherSchoolGrossIncomeVal = 0;
    } else {
      higherSchoolGrossIncomeVal *= ( 1 + higherEducationPaybackData['Higher School Growth Rate on Salary'] / 100 );
    }
    m += 1;
    higherEdPaybackData[year]['Gained Income']['Higher School Gross Income'] = higherSchoolGrossIncomeVal;

    // Higher School Income
    higherSchoolIncome = 0;
    if ( typeof databaseData !== 'undefined' && typeof databaseData.Data !== 'undefined' && databaseData.Data !== '' && typeof databaseData.Data['Standard Deductions'] !== 'undefined' && typeof databaseData.Data['Standard Deductions'].Single !== 'undefined' && typeof databaseData !== 'undefined'
      && typeof databaseData.Data['Standard Deductions'].Single !== 'undefined'
      && higherEdPaybackData[year]['Gained Income']['Higher School Gross Income'] - databaseData.Data['Standard Deductions'].Married > 0 ) {
      higherSchoolIncome = higherEdPaybackData[year]['Gained Income']['Higher School Gross Income'] - databaseData.Data['Standard Deductions'].Married;
    }
    higherSchoolTaxesData[year]['Higher School Income'] = higherSchoolIncome;

    higherSchoolTaxesData[year].School = {};
    higherSchoolTaxesData[year].School = schoolCalculation( Math.round( higherSchoolIncome ) );

    // Tax Rates - Higher Education
    higherEducationTaxRates = higherEducationTaxesData[year].Education['Total Tax'];
    higherEdPaybackData[year]['Tax Rates']['Higher Education'] = higherEducationTaxRates;
    // Tax Rates - High School
    highSchoolTaxRates = higherSchoolTaxesData[year].School['Total Tax'];
    higherEdPaybackData[year]['Tax Rates']['High School'] = highSchoolTaxRates;

    // Higher Education Taxes
    higherEducationTaxesVal = -higherEdPaybackData[year]['Gained Income']['Higher Education Gross Income']
      * ( higherEdPaybackData[year]['Tax Rates']['Higher Education'] / 100 );

    higherEdPaybackData[year]['Gained Income']['Higher Education Taxes'] = higherEducationTaxesVal;

    // Higher Education Net Income
    higherEducationNetIncomeVal = higherEdPaybackData[year]['Gained Income']['Higher Education Gross Income']
      + higherEdPaybackData[year]['Gained Income']['Higher Education Taxes'];

    higherEdPaybackData[year]['Gained Income']['Higher Education Net Income'] = higherEducationNetIncomeVal;

    // Higher School Taxes
    higherSchoolTaxesVal = -higherEdPaybackData[year]['Gained Income']['Higher School Gross Income']
      * ( higherEdPaybackData[year]['Tax Rates']['High School'] / 100 );

    higherEdPaybackData[year]['Gained Income']['Higher School Taxes'] = higherSchoolTaxesVal;

    // Higher School Net Income
    higherSchoolNetIncomeVal = higherEdPaybackData[year]['Gained Income']['Higher School Gross Income']
      + higherEdPaybackData[year]['Gained Income']['Higher School Taxes'];

    higherEdPaybackData[year]['Gained Income']['Higher School Net Income'] = higherSchoolNetIncomeVal;

    // Annual Gained (Lost) Income
    totalGainedIncomeVal = higherEdPaybackData[year]['Gained Income']['Higher Education Net Income']
      - higherEdPaybackData[year]['Gained Income']['Higher School Net Income'];

    higherEdPaybackData[year]['Annual Gained (Lost) Income'] = totalGainedIncomeVal;

    // Cumulative Investment
    higherEdPaybackData[year].Payback['Cumulative Investment'] = cumulativeInvestment + higherEdPaybackData[year]['Annual Investment'];
    cumulativeInvestment = higherEdPaybackData[year].Payback['Cumulative Investment'];

    // Cumulative Gained (Lost) Income
    higherEdPaybackData[year].Payback['Cumulative Gained (Lost) Income'] = cumulativeGained + higherEdPaybackData[year]['Annual Gained (Lost) Income'];
    cumulativeGained = higherEdPaybackData[year].Payback['Cumulative Gained (Lost) Income'];

    // Calculate Payback value
    paybackVal = parseFloat( higherEdPaybackData[year].Payback['Cumulative Gained (Lost) Income'] )
      - parseFloat( higherEdPaybackData[year].Payback['Cumulative Investment'] );
    higherEdPaybackData[year].Payback['Payback Value'] = paybackVal;

    // Calculate Payback value
    if ( higherEdPaybackData[year].Payback['Payback Value'] > 0 && k === 0 ) {
      paybackYear = year;
      paybackPeriodVal = year;
      paybackYearValue.push( 500000 );
      positivePaybackYear = j + 1;
      k += 1;
    } else {
      paybackPeriodVal = -year;
      paybackYearValue.push( 0 );
    }
    higherEdPaybackData[year]['Payback Period'] = paybackPeriodVal;
    j += 1;

    // Graphs Data Calculation
    // Years Value
    GraphsData[year].Years = year;
    // Higher Education Net Income Value
    GraphsData[year]['Higher Education Net Income'] = higherEdPaybackData[year]['Gained Income']['Higher Education Net Income'];
    higherEducationIncomeData.push( Math.round( GraphsData[year]['Higher Education Net Income'] ) );

    // High School Net Income Value
    // highSchoolNetIncomeVal = ( higherEdPaybackData[year]['Investment Summary']['Wages Lost'] * ( 1 - ( higherEdPaybackData[year]['Tax Rates']['High School'] / 100 ) ) ) + higherEdPaybackData[year]['Gained Income']['Higher School Net Income'];
    GraphsData[year]['High School Net Income'] = higherEdPaybackData[year]['Gained Income']['Higher School Net Income'];
    // GraphsData[year]['High School Net Income'] = Math.round(highSchoolNetIncomeVal);
    highSchoolNetIncomeData.push( Math.round( GraphsData[year]['High School Net Income'] ) );

    // Debt Value
    if ( typeof module6Data.studentLoanPrincipal !== 'undefined' && module6Data.studentLoanPrincipal !== '' ) {
      GraphsData[year].Debt = module6Data.studentLoanPrincipal;
    } else {
      GraphsData[year].Debt = 0;
    }
    debtData.push( GraphsData[year].Debt );

    // Capital value
    if ( typeof module6Data.studentLoanPrincipal !== 'undefined' && module6Data.studentLoanPrincipal !== '' && typeof module6Data.totalTuition !== 'undefined' && module6Data.totalTuition !== '' ) {
      GraphsData[year].Capital = module6Data.totalTuition - module6Data.studentLoanPrincipal;
    } else {
      GraphsData[year].Capital = 0;
    }
    capitalData.push( GraphsData[year].Capital );
  }

  let breakYear = 0;
  if ( paybackYear > 0 ) {
    breakYear = ( paybackYear - parseInt( module6Data.years_diff, 10 ) - parseInt( module6Data.start_year, 10 ) ) + 1;
  }

  const i = paybackYearValue.indexOf( 500000 );
  paybackYearValue[i] = Math.max.apply( null, higherEducationIncomeData ) / 2;

  let includesYear = 0;
  if ( typeof module6Data !== 'undefined' && typeof module6Data.graduation_year !== 'undefined' ) {
    includesYear = module6Data.graduation_year + 1;
  }

  function higherEdPaybackBackendData() {
    const graphvaluesData = {};
    graphvaluesData['Dynamic Bar Graph'] = {};
    graphvaluesData['Dynamic Bar Graph']['Higher Education Net Income'] = higherEducationIncomeData;
    graphvaluesData['Dynamic Bar Graph']['High School Net Income'] = highSchoolNetIncomeData;
    graphvaluesData['Dynamic Bar Graph'].Debt = debtData;
    graphvaluesData['Dynamic Bar Graph'].Capital = capitalData;
    graphvaluesData['Dynamic Bar Graph']['Years List'] = yearsList;
    graphvaluesData['Dynamic Bar Graph']['Payback Year'] = paybackYearValue;
    graphvaluesData['Dynamic Bar Graph']['Includes Year'] = includesYear;
    graphvaluesData['Dynamic Bar Graph']['Payback Break Year'] = breakYear;
    graphvaluesData['Dynamic Bar Graph']['Sheet Data'] = higherEdPaybackData;
    graphvaluesData['Dynamic Bar Graph']['Positive Payback Year'] = positivePaybackYear;

    graphvaluesData['Scholarships Total'] = scholarshipsTotal;
    graphvaluesData['Grants Total'] = grantsTotal;
    graphvaluesData['Financial Aid Total'] = financialAidTotal;
    graphvaluesData['Capital Investment Total'] = capitalInvestmentTotal;
    graphvaluesData['Excess Family Contribution Total'] = excessFamilyContributionTotal;
    return graphvaluesData;
  }
  return higherEdPaybackBackendData();
}
export default moreHigherEducationSheetPlayback;
