import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import styles from './styles';

const BitCoinPopups = (props) => {
  const { classes, challengeIndex, level,setChallengeAnswer, challengeAnswer,btnDisabled, setSelectedAnswer,selectedAnswer } = props;
  

  useEffect(()=>{
    if(level ===4){
      if(challengeIndex === 1){
        setSelectedAnswer('1');
      }else if(challengeIndex === 2){
        setSelectedAnswer('1');
      }else if(challengeIndex === 3){
        setSelectedAnswer('2');
      }
    }
  },[challengeIndex]);

  const answerQuestion = (val) => {
    setChallengeAnswer(val.target.value);
  }
  
  return (
    <Typography variant="body1" component="div">
      { /* bitcoin XP questions  Popup */}
      {challengeIndex === 1 && (
      <div> 
        <div className={classes.completePopupChallenge}>
          <h1>POWERUP CHALLENGE EXPLANATION</h1>
            <div className={classes.completePopupPowerup}>
              <p className={classes.popupPowerupPara}>Use the following details and the <a href="/financial-calculators/budget" target="_blank">Build My Budget calculator</a> to calculate Mary's cash left over for savings and investment.</p>
              <ul className={classNames(classes.bitUiList, classes.bitUiListPowerup)}>
              <li>Mary earns $27 an hour and worked 2,000 hours in the year</li>
              <li>She pays $420 in taxes each month</li>
              <li>Her total living expenses are 78% of her after-tax income</li>
              <li>She has a $300 student loan payment</li>
              </ul>
              <div className={classNames(classes.assetsOptions, "assets-row")}>
                <ul className={classNames(classes.exerciseOptions, classes.exerciseOptionsPowerup)}>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="1" id="firstOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '1' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '1' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))}   htmlFor="firstOption">
                      $580
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="2" id="secondOption"  disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, (challengeAnswer === '2' && challengeAnswer === '' && classes.ageChecked), (challengeAnswer === '2' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))}   htmlFor="secondOption">
                      $1,160
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="3"  id="thirdOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '3' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '3' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))}    htmlFor="thirdOption">
                      $1,380
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="4"  id="fourthOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '4' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '4' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))}    htmlFor="fourthOption">
                      $1,508
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}


      {challengeIndex === 2 && (
        <div>
            <div className={classes.completePopupChallenge}>
              <h1>POWERUP CHALLENGE EXPLANATION</h1>
              <div className={classes.completePopupPowerup}>
                <p className={classes.popupPowerupPara}>Use the following details and the <a href="/"  target="_blank">Build My Budget calculator</a> to calculate Mary's cash left over for savings and investment.</p>
                <ul className={classNames(classes.bitUiList, classes.bitUiListPowerup)}>
                  <li>Mary earns $30 an hour and worked 2,000 hours in the year</li>
                  <li>She pays $520 in taxes each month</li>
                  <li>Her total living expenses are 70% of her after-tax income</li>
                  <li>She has a $250 student loan payment</li>
                </ul>      
              
              <div className={classNames(classes.assetsOptions, "assets-row")}>
              <ul className={classNames(classes.exerciseOptions, classes.exerciseOptionsPowerup)}>
              <li>
                <p>
                  <input type="radio" name="equation" value="1" id="firstOption"  disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                  <label className={classNames(classes.spanRadio, challengeAnswer === '1' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '1' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))}   htmlFor="firstOption">
                  $1,094
                  </label>
                </p>
              </li>
              <li>
                <p>
                  <input type="radio" name="equation" value="2" id="secondOption"  disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                  <label className={classNames(classes.spanRadio, (challengeAnswer === '2' && challengeAnswer === '' && classes.ageChecked), (challengeAnswer === '2' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))}    htmlFor="secondOption">
                  $1,160
                  </label>
                </p>
              </li>
              <li>
                <p>
                  <input type="radio" name="equation" value="3"  id="thirdOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                  <label className={classNames(classes.spanRadio, challengeAnswer === '3' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '3' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))}   htmlFor="thirdOption">
                  $1,380
                  </label>
                </p>
              </li>
              <li>
                <p>
                  <input type="radio" name="equation" value="4"  id="fourthOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                  <label className={classNames(classes.spanRadio, challengeAnswer === '4' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '4' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))}   htmlFor="fourthOption">
                  $1,508
                  </label>
                </p>
              </li>
            </ul>
              </div>
            </div>

            </div>
        </div>
      )}
      {challengeIndex === 3 && (
        <div>
            <div className={classes.completePopupChallenge}>
              <h1>POWERUP CHALLENGE EXPLANATION</h1>
                <div className={classes.completePopupPowerup}>
                  <p className={classes.popupPowerupPara}>Use the following details and the Build <a href="/" target="_blank">My Budget calculator</a> to calculate Mary's cash left over for savings and investment. </p>
                  <ul className={classNames(classes.bitUiList, classes.bitUiListPowerup)}>
                    <li>Mary earns $36 an hour and worked 2,000 hours in the year</li>
                    <li>She pays $750 in taxes each month</li>
                    <li>Her total living expenses are 74% of her after-tax income</li>
                    <li>She has a $200 student loan payment</li>
                  </ul>
             
              <div className={classNames(classes.assetsOptions, "assets-row")}>
                <ul className={classNames(classes.exerciseOptions, classes.exerciseOptionsPowerup)}>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="1" id="firstOption"  disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '1' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '1' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))}   htmlFor="firstOption">
                      $580
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="2" id="secondOption"  disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, (challengeAnswer === '2' && challengeAnswer === '' && classes.ageChecked), (challengeAnswer === '2' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))}   htmlFor="secondOption">
                      $1,165
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="3"  id="thirdOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '3' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '3' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))}   htmlFor="thirdOption">
                      $1,380
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="4"  id="fourthOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '4' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '4' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))}   htmlFor="fourthOption">
                      $1,508
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}



    </Typography>
  );
};

BitCoinPopups.propTypes = {
  classes: PropTypes.object.isRequired,
  level:PropTypes.number.isRequired,
  setChallengeAnswer:PropTypes.func.isRequired,
  challengeAnswer:PropTypes.string.isRequired,
  btnDisabled:PropTypes.bool.isRequired,
  setSelectedAnswer:PropTypes.func.isRequired,
  selectedAnswer:PropTypes.string.isRequired
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(BitCoinPopups);
