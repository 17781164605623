function investmentsSheetData(module1Data, module4Data, module5Data, module8Data, module9Data, module10Data, module17Data, module18Data, cashFlowData, incomeStatementData, databaseData, assumptionsData) {
  let year = parseInt(module1Data.start_year, 10);
  let yearLimit = parseInt(module1Data.start_year, 10) + 15;
  const investmentsData = {};

  investmentsData['Short-Term Investments'] = {};
  let begBalance = 0;
  let cashContibution = 0;
  let balance1 = 0;
  let earnings = 0;
  let balance2 = 0;
  let cashNeed = 0;
  let cashTransfer = 0;
  let balance3 = 0;
  let contribution = 0;
  let balance4 = 0;
  let excess = 0;
  let endingBalance = 0;

  let shortTermEarningsTotal = 0;
  let shortTermCashContributionsTotal = 0;
  let shortTermCashTransferTotal = 0;
  let shortTermContributionsTotal = 0;
  let shortTermExcessTotal = 0;
  let shortTermEndingBalanceLastVal = 0;

  // Short Term Investments
  investmentsData['Long-Term Investments'] = {};
  let begBalanceLong = 0;
  let cashContibutionLong = 0;
  let balance1Long = 0;
  let earningsLong = 0;
  let balance2Long = 0;
  let cashNeedLong = 0;
  let cashTransferLong = 0;
  let balance3Long = 0;
  let contributionLong = 0;
  let endingBalanceLong = 0;

  //529 Plan
  investmentsData['529 Plan'] = {};
  let begBalanceLong529 = 0;
  let cashContibutionLong529 = 0;
  let balance1Long529 = 0;
  let earningsLong529 = 0;
  let balance2Long529 = 0;
  // let cashNeedLong529 = 0;
  // let cashTransferLong529 = 0;
  // let balance3Long529 = 0;
  // let contributionLong529 = 0;
  // let balance4Long529 = 0;
  // let excessLong529 = 0;
  // let endingBalanceLong529 = 0;
  let longTermEarningsTotal529 = 0;
  let longTermCashContributionsTotal529 = 0;
  // let longTermCashTransferTotal529 = 0;
  // let longTermContributionsTotal529 = 0;
  // let longTermEndingBalanceLastVal529 = 0;
  let earningAvg529 = 0;

  investmentsData['Life Insurance'] = {};
  let begBalanceLongLife = 0;
  let cashContibutionLongLife = 0;
  let balance1LongLife = 0;
  let earningsLongLife = 0;
  let balance2LongLife = 0;
  // let cashNeedLongLife = 0;
  // let cashTransferLongLife = 0;
  // let balance3LongLife = 0;
  // let contributionLongLife = 0;
  // let balance4LongLife = 0;
  // let excessLongLife = 0;
  // let endingBalanceLongLife = 0;
  let longTermEarningsTotalLife = 0;
  let longTermCashContributionsTotalLife = 0;
  // let longTermCashTransferTotalLife = 0;
  // let longTermContributionsTotalLife = 0;
  // let longTermEndingBalanceLastValLife = 0;
  let earningAvgLife = 0;

  investmentsData['Retirement Accounts 401k'] = {};
  investmentsData['Retirement Accounts 401k Second'] = {};

  //Rothe 401k Data
  investmentsData['Roth 401k'] = {};
  investmentsData['Roth 401k Second'] = {};

  // Roth IRA
  investmentsData['Retirement Accounts Roth IRA'] = {};
  investmentsData['Retirement Accounts Roth IRA Second'] = {};

  //Traditional IRA
  investmentsData['Retirement Accounts Traditional IRA'] = {};
  investmentsData['Retirement Accounts Traditional IRA Second'] = {};
  //HSA
  investmentsData['Retirement Accounts HSA'] = {};
  investmentsData['HSA Second'] = {};

  // SEP IRA
  investmentsData['Retirement Accounts Seph IRA'] = {};
  investmentsData['Retirement Accounts Seph IRA Second'] = {};
  // Pension
  investmentsData['Retirement Accounts Pension'] = {};
  investmentsData['Retirement Accounts Pension Second'] = {};

  let longTermEarningsTotal = 0;
  let longTermCashContributionsTotal = 0;
  let longTermCashTransferTotal = 0;
  let longTermContributionsTotal = 0;
  let longTermEndingBalanceLastVal = 0;

  let earningAvg;
  let totalAnnualExpectedReturn = 0;
  let totalAnnualExpectedReturnRothIra = 0;
  let totalAnnualExpectedReturnSephIra = 0;
  let totalAnnualExpectedReturnPension = 0;
  let totalAnnualExpectedReturnRoth = 0;
  let totalAnnualExpectedReturnRothIraTraditional = 0;

  for (year; year < yearLimit; year += 1) {
    /** *
       ** Short Term Investments
       ** */
    investmentsData['Short-Term Investments'][year] = {};

    if (year === module1Data.start_year) {
      begBalance = module18Data.existing_total_short_term_investments;
    } else {
      begBalance = endingBalance;
    }
    investmentsData['Short-Term Investments'][year]['Beg Balance'] = begBalance;

    // Cash Contribution
    if (year >= module9Data.short_term_start_year && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[year] !== undefined && incomeStatementData[year]['Income After Taxes'] !== undefined && incomeStatementData[year]['Income After Taxes'] > 0 && module9Data.short_term_annual_contribution !== undefined) {
      cashContibution = incomeStatementData[year]['Income After Taxes'] * (module9Data.short_term_annual_contribution / 100);
    } else {
      cashContibution = 0;
    }
    investmentsData['Short-Term Investments'][year]['Cash Contibution'] = cashContibution;
    shortTermCashContributionsTotal += investmentsData['Short-Term Investments'][year]['Cash Contibution'];

    // Balance 1
    balance1 = begBalance + cashContibution;
    investmentsData['Short-Term Investments'][year].Balance1 = balance1;

    // Earnings
    earningAvg = 0;
    if ((balance1 + begBalance) > 0) {
      earningAvg = (balance1 + begBalance) / 2;
    }
    earnings = earningAvg * (parseFloat(module9Data.short_term_annual_expected_return_in_percentage) / 100);
    investmentsData['Short-Term Investments'][year].Earnings = earnings;
    shortTermEarningsTotal += investmentsData['Short-Term Investments'][year].Earnings;

    // Balance 2
    balance2 = balance1 + parseFloat(earnings);
    investmentsData['Short-Term Investments'][year].Balance2 = balance2;

    // Cash Need
    let module1MaxCashBalance = 0;
    // if ( module1Data.max_cash_balance !== undefined && module1Data.max_cash_balance !== '' ) {
    //     module1MaxCashBalance = module1Data.max_cash_balance;
    //   } else {
    //     module1MaxCashBalance = 0;
    //   }

    if (year >= module9Data.short_term_start_year) {
      if (cashFlowData !== undefined
        && cashFlowData[year] !== undefined
        && cashFlowData[year]['Ending Cash Surplus'] !== undefined
        && cashFlowData[year]['Ending Cash Surplus'] > 0) {
        cashNeed = 0;
      } else {
        if (cashFlowData !== undefined && cashFlowData[year] !== undefined) {
          cashNeed = -cashFlowData[year]['Ending Cash Surplus'] + module1MaxCashBalance;
        } else {
          cashNeed = 0;
        }
      }
    } else {
      cashNeed = 0;
    }
    investmentsData['Short-Term Investments'][year]['Cash Need'] = cashNeed;

    // Cash Transfer
    if (balance2 >= cashNeed) {
      cashTransfer = cashNeed;
    } else if (balance2 > 0) {
      cashTransfer = balance2;
    } else {
      cashTransfer = 0;
    }
    investmentsData['Short-Term Investments'][year]['Cash Transfer'] = cashTransfer;
    shortTermCashTransferTotal += investmentsData['Short-Term Investments'][year]['Cash Transfer'];

    // Balance 3
    balance3 = parseFloat(balance2) - parseFloat(cashTransfer);
    investmentsData['Short-Term Investments'][year].Balance3 = balance3;

    // Contribution from Excess Cash
    // if ( module9Data.short_term_start_year !== undefined && module9Data.short_term_start_year > 0 && year >= module9Data.short_term_start_year ) {
    //   if ( cashFlowData !== undefined && cashFlowData[year] !== undefined && cashFlowData[year]['Excess Cash Put into STI'] !== undefined
    //             && cashFlowData[year]['Excess Cash Put into STI'][module1Data.max_cash_balance] !== undefined ) {
    //     if ( cashFlowData[year]['Excess Cash Put into STI'][module1Data.max_cash_balance] === 0 ) {
    //       contribution = 0;
    //     } else {
    //       contribution = -( cashFlowData[year]['Excess Cash Put into STI'][module1Data.max_cash_balance] );
    //     }
    //   } else {
    //     contribution = 0;
    //   }
    // } else {
    // }
    contribution = 0;
    investmentsData['Short-Term Investments'][year].Contribution = contribution;
    shortTermContributionsTotal += investmentsData['Short-Term Investments'][year].Contribution;

    // Balance 4
    balance4 = parseFloat(balance3) + parseFloat(contribution);
    investmentsData['Short-Term Investments'][year].Balance4 = balance4;

    // Excess
    if (module9Data.short_term_max_sti_balance_before_lt_investment !== undefined && module9Data.short_term_max_sti_balance_before_lt_investment !== ''
      && balance4 > module9Data.short_term_max_sti_balance_before_lt_investment) {
      if (module9Data.long_term_start_year !== undefined && module9Data.long_term_start_year !== '' && year >= module9Data.long_term_start_year) {
        excess = balance4 - module9Data.short_term_max_sti_balance_before_lt_investment;
      } else {
        excess = 0;
      }
    } else {
      excess = 0;
    }
    investmentsData['Short-Term Investments'][year].Excess = excess;
    shortTermExcessTotal += investmentsData['Short-Term Investments'][year].Excess;

    // Ending Balance
    endingBalance = parseFloat(balance4) - parseFloat(excess);
    investmentsData['Short-Term Investments'][year]['Ending Balance'] = endingBalance;
    shortTermEndingBalanceLastVal = endingBalance;

    /** *
                 ** Retirement Accounts
                 ** */
    investmentsData['Retirement Accounts 401k'][year] = {};
    // Base Income
    if (incomeStatementData !== undefined
      && incomeStatementData[year] !== undefined && incomeStatementData[year] !== ''
      && incomeStatementData[year]['Gross Income'] !== undefined
      && incomeStatementData[year]['Gross Income'][module4Data.occupation_title] !== undefined
      && incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission'] !== undefined
      && incomeStatementData[year]['Gross Income'][`${module5Data.occupation_title} - Second`] !== undefined
      && incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Second'] !== undefined
      && incomeStatementData[year]['Gross Income'][`${module8Data.occupation_title} - Third`] !== undefined
      && incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Third'] !== undefined
      && incomeStatementData[year]['Gross Income'][`${module17Data.occupation_title} - Fourth`] !== undefined
      && incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'] !== undefined
    ) {
      investmentsData['Retirement Accounts 401k'][year]['Base Income'] = incomeStatementData[year]['Gross Income'][module4Data.occupation_title]
        + incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission']
        + incomeStatementData[year]['Gross Income'][`${module5Data.occupation_title} - Second`]
        + incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Second']
        + incomeStatementData[year]['Gross Income'][`${module8Data.occupation_title} - Third`]
        + incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Third']
        + incomeStatementData[year]['Gross Income'][`${module17Data.occupation_title} - Fourth`]
        + incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'];
    }
    
    let personalPercentagContributionRoth = 0;
    let personalPercentagContribution = 0;
    //Personal Contribution Percentage Percentage
    if (typeof module4Data.contribute_401k !== 'undefined' && module4Data.contribute_401k === 'Yes' && typeof module4Data.start_year !== 'undefined' && module4Data.start_year !== '' && year >= module4Data.start_year && typeof module4Data.end_year !== 'undefined' && module4Data.end_year !== '' && year <= module4Data.end_year && typeof module4Data.base_income !== 'undefined' && module4Data.base_income !== '') {
      personalPercentagContribution = (module4Data.base_income / (parseFloat(module4Data.base_income) + parseFloat(module4Data.base_roth_income))) * 100;
    } else {
      if (typeof module5Data.contribute_401k !== 'undefined' && module5Data.contribute_401k === 'Yes' && typeof module5Data.start_year !== 'undefined' && module5Data.start_year !== '' && year >= module5Data.start_year && typeof module5Data.end_year !== 'undefined' && module5Data.end_year !== '' && year <= module5Data.end_year && typeof module5Data.base_income !== 'undefined' && module5Data.base_income !== '') {
        personalPercentagContribution = (module5Data.base_income / (parseFloat(module5Data.base_income) + parseFloat(module5Data.base_roth_income))) * 100;
      } else {
        if (typeof module8Data.contribute_401k !== 'undefined' && module8Data.contribute_401k === 'Yes' && typeof module8Data.start_year !== 'undefined' && module8Data.start_year !== '' && year >= module8Data.start_year && typeof module8Data.end_year !== 'undefined' && module8Data.end_year !== '' && year <= module8Data.end_year && typeof module8Data.base_income !== 'undefined' && module8Data.base_income !== '') {
          personalPercentagContribution = (module8Data.base_income / (parseFloat(module8Data.base_income) + parseFloat(module8Data.base_roth_income))) * 100;
        } else {
          if (typeof module17Data.contribute_401k !== 'undefined' && module17Data.contribute_401k === 'Yes' && typeof module17Data.start_year !== 'undefined' && module17Data.start_year !== '' && year >= module17Data.start_year && typeof module17Data.end_year !== 'undefined' && module17Data.end_year !== '' && year <= module17Data.end_year && typeof module17Data.base_income !== 'undefined' && module17Data.base_income !== '') {
            personalPercentagContribution = (module17Data.base_income / (parseFloat(module17Data.base_income) + parseFloat(module17Data.base_roth_income))) * 100;
          } else {
            personalPercentagContribution = 0;
          }
        }
      }
    }
    if (isNaN(personalPercentagContribution)) {
      personalPercentagContribution = 0;
    }
    investmentsData['Retirement Accounts 401k'][year]['Personal Contribution Percentage'] = personalPercentagContribution;

    // Personal Contrubution
    let personalContributionVal = 0;
    // let personalContributionVal1 = 0;
    if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes'
      && module4Data.start_year !== undefined && module4Data.start_year !== ''
      && module4Data.end_year !== undefined && module4Data.end_year !== ''
      && year >= module4Data.start_year && year <= module4Data.end_year
      && module4Data.base_income !== undefined && module4Data.base_income !== '') {
      personalContributionVal = (investmentsData['Retirement Accounts 401k'][year]['Base Income'] / 100) * module4Data.base_income;
    } else {
      if (module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes'
        && module5Data.start_year !== undefined && module5Data.start_year !== '' && module5Data.end_year !== undefined
        && module5Data.end_year !== '' && year >= module5Data.start_year && year <= module5Data.end_year
        && module5Data.base_income !== undefined && module5Data.base_income !== '') {
        personalContributionVal = (investmentsData['Retirement Accounts 401k'][year]['Base Income'] / 100) * module5Data.base_income;
      } else {
        if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes'
          && module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined
          && module8Data.end_year !== '' && year >= module8Data.start_year && year <= module8Data.end_year
          && module8Data.base_income !== undefined && module8Data.base_income !== '') {
          personalContributionVal = (investmentsData['Retirement Accounts 401k'][year]['Base Income'] / 100) * module8Data.base_income;
        } else {
          if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes'
            && module17Data.start_year !== undefined && module17Data.start_year !== ''
            && module17Data.end_year !== undefined && module17Data.end_year !== ''
            && year >= module17Data.start_year && year <= module17Data.end_year && module17Data.base_income !== undefined
            && module17Data.base_income !== '') {
            personalContributionVal = (investmentsData['Retirement Accounts 401k'][year]['Base Income'] / 100) * module17Data.base_income;
          } else {
            personalContributionVal = 0;
          }
        }
      }
    }
    investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'] = personalContributionVal;

    // Employer Matching
    let employerMatchingVal = 0;
    if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.start_year !== undefined
      && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year
      && year <= module4Data.end_year && module4Data.employer_matching_rate !== undefined && module4Data.employer_matching_rate !== '') {
      employerMatchingVal = (investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'] / 100) * module4Data.employer_matching_rate;
    } else if (module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.start_year !== undefined
      && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== ''
      && year >= module5Data.start_year && year <= module5Data.end_year && module5Data.employer_matching_rate !== undefined
      && module5Data.employer_matching_rate !== '') {
      employerMatchingVal = (investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'] / 100) * module5Data.employer_matching_rate;
    } else if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.start_year !== undefined
      && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year
      && year <= module8Data.end_year && module8Data.employer_matching_rate !== undefined && module8Data.employer_matching_rate !== '') {
      employerMatchingVal = (investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'] / 100) * module8Data.employer_matching_rate;
    } else if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.start_year !== undefined
      && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== ''
      && year >= module17Data.start_year && year <= module17Data.end_year && module17Data.employer_matching_rate !== undefined && module17Data.employer_matching_rate !== '') {
      employerMatchingVal = (investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'] / 100) * module17Data.employer_matching_rate;
    } else {
      employerMatchingVal = 0;
    }
    investmentsData['Retirement Accounts 401k'][year]['Employer Matching'] = employerMatchingVal;

    // Employer Limit
    let employerLimitVal = 0;
    if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.start_year !== undefined
      && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year
      && year <= module4Data.end_year && module4Data.employer_matching_limit !== undefined && module4Data.employer_matching_limit !== '') {
      employerLimitVal = (investmentsData['Retirement Accounts 401k'][year]['Base Income'] / 100) * module4Data.employer_matching_limit;
    } else if (module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.start_year !== undefined
      && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year
      && year <= module5Data.end_year && module5Data.employer_matching_limit !== undefined && module5Data.employer_matching_limit !== '') {
      employerLimitVal = (investmentsData['Retirement Accounts 401k'][year]['Base Income'] / 100) * module5Data.employer_matching_limit;
    } else if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.start_year !== undefined
      && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year
      && year <= module8Data.end_year && module8Data.employer_matching_limit !== undefined && module8Data.employer_matching_limit !== '') {
      employerLimitVal = (investmentsData['Retirement Accounts 401k'][year]['Base Income'] / 100) * module8Data.employer_matching_limit;
    } else if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.start_year !== undefined
      && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year
      && year <= module17Data.end_year && module17Data.employer_matching_limit !== undefined && module17Data.employer_matching_limit !== '') {
      employerLimitVal = (investmentsData['Retirement Accounts 401k'][year]['Base Income'] / 100) * module17Data.employer_matching_limit;
    } else {
      employerLimitVal = 0;
    }
    investmentsData['Retirement Accounts 401k'][year]['Employer Limit'] = employerLimitVal;

    // Employer Contribution Value
    let employerContributionVal = 0;
    let employerContributionVal1 = 0;
    let employerContributionVal2 = 0;

    if (investmentsData['Retirement Accounts 401k'][year]['Employer Matching'] < investmentsData['Retirement Accounts 401k'][year]['Employer Limit']) {
      employerContributionVal = investmentsData['Retirement Accounts 401k'][year]['Employer Matching'];
    } else {
      employerContributionVal = investmentsData['Retirement Accounts 401k'][year]['Employer Limit'];
    }
    employerContributionVal1 = employerContributionVal + investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'];


    if (typeof databaseData !== 'undefined' && databaseData !== '' && typeof databaseData['401(k) 2018 Contribution Limits'][year] !== 'undefined' && databaseData['401(k) 2018 Contribution Limits'][year] !== '' && employerContributionVal1 > databaseData['401(k) 2018 Contribution Limits'][year]['Total Limit']) {
      employerContributionVal2 = ((databaseData['401(k) 2018 Contribution Limits'][year]['Total Limit'] - investmentsData['Retirement Accounts 401k'][year]['Personal Contribution']) / 100) * investmentsData['Retirement Accounts 401k'][year]['Personal Contribution Percentage'];

    }
    else {
      if (investmentsData['Retirement Accounts 401k'][year]['Employer Matching'] < investmentsData['Retirement Accounts 401k'][year]['Employer Limit']) {
        employerContributionVal2 = (investmentsData['Retirement Accounts 401k'][year]['Employer Matching'] / 100) * investmentsData['Retirement Accounts 401k'][year]['Personal Contribution Percentage'];
      }
      else {
        employerContributionVal2 = (investmentsData['Retirement Accounts 401k'][year]['Employer Limit'] / 100) * investmentsData['Retirement Accounts 401k'][year]['Personal Contribution Percentage'];
      }
    }
    investmentsData['Retirement Accounts 401k'][year]['Employer Contribution'] = employerContributionVal2;


    // Total Contribution
    investmentsData['Retirement Accounts 401k'][year]['Total Contribution'] = investmentsData['Retirement Accounts 401k'][year]['Employer Contribution'] + investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'];

    // Annual Expected Return
    let annualExpectedReturn = 0;
    if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.start_year !== undefined
      && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year
      && year <= module4Data.end_year && module4Data.Annual_expected401k !== undefined && module4Data.Annual_expected401k !== ''
      && module4Data.Annual_expected401k >= 0) {
      annualExpectedReturn = module4Data.Annual_expected401k !== null ? module4Data.Annual_expected401k : 0;
    } else {
      if (module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.start_year !== undefined
        && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year
        && year <= module5Data.end_year && module5Data.Annual_expected401k !== undefined && module5Data.Annual_expected401k !== ''
        && module5Data.Annual_expected401k >= 0) {
        annualExpectedReturn = module5Data.Annual_expected401k !== null ? module5Data.Annual_expected401k : 0;
      } else {
        if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.start_year !== undefined
          && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year
          && year <= module8Data.end_year && module8Data.Annual_expected401k !== undefined && module8Data.Annual_expected401k !== ''
          && module8Data.Annual_expected401k >= 0) {
          annualExpectedReturn = module8Data.Annual_expected401k !== null ? module8Data.Annual_expected401k : 0;
        } else {
          if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.start_year !== undefined
            && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year
            && year <= module17Data.end_year && module17Data.Annual_expected401k !== undefined && module17Data.Annual_expected401k !== ''
            && module17Data.Annual_expected401k >= 0) {
            annualExpectedReturn = module17Data.Annual_expected401k !== null ? module17Data.Annual_expected401k : 0;
          } else {
            if (databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && databaseData.Data['401k Limit Rate'] !== undefined) {
              annualExpectedReturn = databaseData.Data['401k Limit Rate'];
            } else {
              annualExpectedReturn = 0;
            }
          }
        }
      }
    }
    investmentsData['Retirement Accounts 401k'][year]['Annual Expected Return'] = annualExpectedReturn;
    totalAnnualExpectedReturn += annualExpectedReturn;

    //Roth 401k
    investmentsData['Roth 401k'][year] = {};
    //Base Income
    if (incomeStatementData !== undefined
      && incomeStatementData[year] !== undefined && incomeStatementData[year] !== ''
      && incomeStatementData[year]['Gross Income'] !== undefined
      && incomeStatementData[year]['Gross Income'][module4Data.occupation_title] !== undefined
      && incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission'] !== undefined
      && incomeStatementData[year]['Gross Income'][`${module5Data.occupation_title} - Second`] !== undefined
      && incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Second'] !== undefined
      && incomeStatementData[year]['Gross Income'][`${module8Data.occupation_title} - Third`] !== undefined
      && incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Third'] !== undefined
      && incomeStatementData[year]['Gross Income'][`${module17Data.occupation_title} - Fourth`] !== undefined
      && incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'] !== undefined
    )

      if (incomeStatementData !== undefined &&
        incomeStatementData[year] !== undefined && incomeStatementData[year] !== '' &&
        incomeStatementData[year]['Gross Income'][module4Data.occupation_title] !== undefined &&
        incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission'] !== undefined &&
        incomeStatementData[year]['Gross Income'][`${module5Data.occupation_title} - Second`] !== undefined &&
        incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Second'] !== undefined &&
        incomeStatementData[year]['Gross Income'][`${module8Data.occupation_title} - Third`] !== undefined &&
        incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Third'] !== undefined &&
        incomeStatementData[year]['Gross Income'][`${module17Data.occupation_title} - Fourth`] !== undefined &&
        incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'] !== undefined
      ) {
        investmentsData['Roth 401k'][year]['Base Income'] = incomeStatementData[year]['Gross Income'][module4Data.occupation_title] +
          incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission'] +
          incomeStatementData[year]['Gross Income'][`${module5Data.occupation_title} - Second`] +
          incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Second'] +
          incomeStatementData[year]['Gross Income'][`${module8Data.occupation_title} - Third`] +
          incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Third'] +
          incomeStatementData[year]['Gross Income'][`${module17Data.occupation_title} - Fourth`] +
          incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'];
      }
    //Personal Contribution Percentage
    if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.start_year !== undefined && module4Data.start_year !== '' && year >= module4Data.start_year && module4Data.end_year !== undefined && module4Data.end_year !== '' && year <= module4Data.end_year && module4Data.base_income !== undefined && module4Data.base_income !== '') {
      personalPercentagContributionRoth = (module4Data.base_roth_income / (parseFloat(module4Data.base_income) + parseFloat(module4Data.base_roth_income))) * 100;

    } else {
      if (module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.start_year !== undefined && module5Data.start_year !== '' && year >= module5Data.start_year && module5Data.end_year !== undefined && module5Data.end_year !== '' && year <= module5Data.end_year && module5Data.base_income !== undefined && module5Data.base_income !== '') {
        personalPercentagContributionRoth = (module5Data.base_roth_income / (parseFloat(module5Data.base_income) + parseFloat(module5Data.base_roth_income))) * 100;
      } else {
        if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.start_year !== undefined && module8Data.start_year !== '' && year >= module8Data.start_year && module8Data.end_year !== undefined && module8Data.end_year !== '' && year <= module8Data.end_year && module8Data.base_income !== undefined && module8Data.base_income !== '') {
          personalPercentagContributionRoth = (module8Data.base_roth_income / (parseFloat(module8Data.base_income) + parseFloat(module8Data.base_roth_income))) * 100;
        } else {
          if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.start_year !== undefined && module17Data.start_year !== '' && year >= module17Data.start_year && module17Data.end_year !== undefined && module17Data.end_year !== '' && year <= module17Data.end_year && module17Data.base_income !== undefined && module17Data.base_income !== '') {
            personalPercentagContributionRoth = (module17Data.base_roth_income / (parseFloat(module17Data.base_income) + parseFloat(module17Data.base_roth_income))) * 100;
          } else {
            personalPercentagContributionRoth = 0;
          }
        }
      }
    }
    if (isNaN(personalPercentagContributionRoth)) {
      personalPercentagContributionRoth = 0;
    }
    investmentsData['Roth 401k'][year]['Personal Contribution Percentage'] = personalPercentagContributionRoth;

    //Personal Contrubution
    let personalContributionVal2 = 0;
    if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.start_year !== undefined && module4Data.start_year !== '' && year >= module4Data.start_year && module4Data.end_year !== undefined && module4Data.end_year !== '' && year <= module4Data.end_year && module4Data.base_income !== undefined && module4Data.base_income !== '') {
      personalContributionVal2 = (investmentsData['Retirement Accounts 401k'][year]['Base Income'] / 100) * module4Data.base_roth_income;
    } else {
      if (module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.start_year !== undefined && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year && year <= module5Data.end_year && module5Data.base_income !== undefined && module5Data.base_income !== '') {
        personalContributionVal2 = (investmentsData['Retirement Accounts 401k'][year]['Base Income'] / 100) * module5Data.base_roth_income;
      }
      else {
        if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year && year <= module8Data.end_year && module8Data.base_income !== undefined && module8Data.base_income !== '') {
          personalContributionVal2 = (investmentsData['Retirement Accounts 401k'][year]['Base Income'] / 100) * module8Data.base_roth_income;
        }
        else {
          if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year && year <= module17Data.end_year && module17Data.base_income !== undefined && module17Data.base_income !== '') {
            personalContributionVal2 = (investmentsData['Retirement Accounts 401k'][year]['Base Income'] / 100) * module17Data.base_roth_income;
          }
          else {
            personalContributionVal2 = 0;
          }
        }
      }
    }
    if (isNaN(personalContributionVal2)) {
      personalContributionVal2 = 0;
    }
    investmentsData['Roth 401k'][year]['Personal Contribution'] = personalContributionVal2;

    //Employer Matching
    let employerMatchingVal1 = 0;
    if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year && year <= module4Data.end_year && module4Data.employer_matching_rate !== undefined && module4Data.employer_matching_rate !== '') {
      employerMatchingVal1 = (investmentsData['Roth 401k'][year]['Personal Contribution'] / 100) * module4Data.employer_matching_rate;
    }
    else {
      if (module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.start_year !== undefined && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year && year <= module5Data.end_year && module5Data.employer_matching_rate !== undefined && module5Data.employer_matching_rate !== '') {
        employerMatchingVal1 = (investmentsData['Roth 401k'][year]['Personal Contribution'] / 100) * module5Data.employer_matching_rate;
      }
      else {
        if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year && year <= module8Data.end_year && module8Data.employer_matching_rate !== undefined && module8Data.employer_matching_rate !== '') {
          employerMatchingVal1 = (investmentsData['Roth 401k'][year]['Personal Contribution'] / 100) * module8Data.employer_matching_rate;
        }
        else {
          if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year && year <= module17Data.end_year && module17Data.employer_matching_rate !== undefined && module17Data.employer_matching_rate !== '') {
            employerMatchingVal1 = (investmentsData['Roth 401k'][year]['Personal Contribution'] / 100) * module17Data.employer_matching_rate;
          }
          else {
            employerMatchingVal1 = 0;
          }
        }
      }
    }
    if (isNaN(employerMatchingVal1)) {
      employerMatchingVal1 = 0;
    }
    investmentsData['Roth 401k'][year]['Employer Matching'] = employerMatchingVal1;

    //Employer Limit
    let employerLimitVal1 = 0;
    if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year && year <= module4Data.end_year && module4Data.employer_matching_limit !== undefined && module4Data.employer_matching_limit !== '') {
      employerLimitVal1 = (investmentsData['Roth 401k'][year]['Base Income'] / 100) * module4Data.employer_matching_limit;
    }
    else {
      if (module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.start_year !== undefined && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year && year <= module5Data.end_year && module5Data.employer_matching_limit !== undefined && module5Data.employer_matching_limit !== '') {
        employerLimitVal1 = (investmentsData['Roth 401k'][year]['Base Income'] / 100) * module5Data.employer_matching_limit;
      }
      else {
        if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year && year <= module8Data.end_year && module8Data.employer_matching_limit !== undefined && module8Data.employer_matching_limit !== '') {
          employerLimitVal1 = (investmentsData['Roth 401k'][year]['Base Income'] / 100) * module8Data.employer_matching_limit;
        }
        else {
          if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year && year <= module17Data.end_year && module17Data.employer_matching_limit !== undefined && module17Data.employer_matching_limit !== '') {
            employerLimitVal1 = (investmentsData['Roth 401k'][year]['Base Income'] / 100) * module17Data.employer_matching_limit;
          }
          else {
            employerLimitVal1 = 0;
          }
        }
      }
    }
    if (isNaN(employerLimitVal1)) {
      employerLimitVal1 = 0;
    }
    investmentsData['Roth 401k'][year]['Employer Limit'] = employerLimitVal1;

    //Employer Contribution Value
    let employerContributionValRoth = 0;
    let employerContributionVal1Roth = 0;
    let employerContributionVal2Roth = 0;

    if (investmentsData['Roth 401k'][year]['Employer Matching'] < investmentsData['Roth 401k'][year]['Employer Limit']) {
      employerContributionValRoth = investmentsData['Roth 401k'][year]['Employer Matching'];
    }
    else {
      employerContributionValRoth = investmentsData['Roth 401k'][year]['Employer Limit'];
    }
    employerContributionVal1Roth = employerContributionValRoth + investmentsData['Roth 401k'][year]['Personal Contribution'];

    if (databaseData !== undefined && databaseData !== '' && databaseData['401(k) 2018 Contribution Limits'][year] !== undefined && databaseData['401(k) 2018 Contribution Limits'][year] !== '' && employerContributionVal1Roth > databaseData['401(k) 2018 Contribution Limits'][year]['Total Limit']) {
      employerContributionVal2Roth = ((databaseData['401(k) 2018 Contribution Limits'][year]['Total Limit'] - investmentsData['Roth 401k'][year]['Personal Contribution']) / 100) * investmentsData['Roth 401k'][year]['Personal Contribution Percentage'];

    }
    else {
      if (investmentsData['Roth 401k'][year]['Employer Matching'] < investmentsData['Roth 401k'][year]['Employer Limit']) {
        employerContributionVal2Roth = (investmentsData['Roth 401k'][year]['Employer Matching'] / 100) * investmentsData['Roth 401k'][year]['Personal Contribution Percentage'];
      }
      else {
        employerContributionVal2Roth = (investmentsData['Roth 401k'][year]['Employer Limit'] / 100) * investmentsData['Roth 401k'][year]['Personal Contribution Percentage'];
      }
    }
    investmentsData['Roth 401k'][year]['Employer Contribution'] = employerContributionVal2Roth;

    investmentsData['Roth 401k'][year]['Total Contribution'] = investmentsData['Roth 401k'][year]['Personal Contribution'] + investmentsData['Roth 401k'][year]['Employer Contribution'];

    //Annual Expected Return
    let annualExpectedReturnRoth = 0;
    if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year && year <= module4Data.end_year && module4Data.Annual_expected401k !== undefined && module4Data.Annual_expected401k !== '' && module4Data.Annual_expected401k >= 0) {
      annualExpectedReturnRoth = module4Data.Annual_expected401k !== null ? module4Data.Annual_expected401k : 0;
    }
    else {
      if (module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.start_year !== undefined && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year && year <= module5Data.end_year && module5Data.Annual_expected401k !== undefined && module5Data.Annual_expected401k !== '' && module5Data.Annual_expected401k >= 0) {
        annualExpectedReturnRoth = module5Data.Annual_expected401k !== null ? module5Data.Annual_expected401k : 0;
      }
      else {
        if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year && year <= module8Data.end_year && module8Data.Annual_expected401k !== undefined && module8Data.Annual_expected401k !== '' && module8Data.Annual_expected401k >= 0) {
          annualExpectedReturnRoth = module8Data.Annual_expected401k !== null ? module8Data.Annual_expected401k : 0;
        }
        else {
          if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year && year <= module17Data.end_year && module17Data.Annual_expected401k !== undefined && module17Data.Annual_expected401k !== '' && module17Data.Annual_expected401k >= 0) {
            annualExpectedReturnRoth = module17Data.Annual_expected401k !== null ? module17Data.Annual_expected401k : 0;
          }
          else {
            if (databaseData !== undefined && databaseData !== '' && databaseData['Data']['401k Limit Rate'] !== undefined) {
              annualExpectedReturnRoth = databaseData['Data']['401k Limit Rate'];
            }
          }
        }
      }
    }
    investmentsData['Roth 401k'][year]['Annual Expected Return'] = annualExpectedReturnRoth;
    totalAnnualExpectedReturnRoth = totalAnnualExpectedReturnRoth + annualExpectedReturnRoth;

    // HSA Calculations
    investmentsData['Retirement Accounts HSA'][year] = {};
    //Base Income
    if (incomeStatementData !== undefined &&
      incomeStatementData[year] !== undefined && incomeStatementData[year] !== '' &&
      incomeStatementData[year]['Gross Income'][module4Data.occupation_title] !== undefined &&
      incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission'] !== undefined &&
      incomeStatementData[year]['Gross Income'][`${module5Data.occupation_title} - Second`] !== undefined &&
      incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Second'] !== undefined &&
      incomeStatementData[year]['Gross Income'][`${module8Data.occupation_title} - Third`] !== undefined &&
      incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Third'] !== undefined &&
      incomeStatementData[year]['Gross Income'][`${module17Data.occupation_title} - Fourth`] !== undefined &&
      incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'] !== undefined
    ) {
      investmentsData['Retirement Accounts HSA'][year]['Base Income'] = incomeStatementData[year]['Gross Income'][module4Data.occupation_title] +
        incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission'] +
        incomeStatementData[year]['Gross Income'][`${module5Data.occupation_title} - Second`] +
        incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Second'] +
        incomeStatementData[year]['Gross Income'][`${module8Data.occupation_title} - Third`] +
        incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Third'] +
        incomeStatementData[year]['Gross Income'][`${module17Data.occupation_title} - Fourth`] +
        incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'];
    }
    let contributionLimitHsa = 0;
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year >= module10Data.marriege_year && module10Data.marriege_year > 0 && databaseData !== undefined && databaseData !== '' && databaseData['HSA Limits'][year] !== undefined && databaseData['HSA Limits'][year] !== '') {
      contributionLimitHsa = databaseData['HSA Limits'][year]['Married'];
    } else {
      if (databaseData !== undefined && databaseData !== '' && databaseData['HSA Limits'][year] !== undefined && databaseData['HSA Limits'][year] !== '') {
        contributionLimitHsa = databaseData['HSA Limits'][year]['Single'];
      } else {
        contributionLimitHsa = 0;
      }
    }
    investmentsData['Retirement Accounts HSA'][year]['Contribution Limit'] = contributionLimitHsa;

    let rothIRAPersonalContributionHsa = 0;
    if (module4Data.contribute_hsa_savings_account !== undefined && module4Data.contribute_hsa_savings_account === 'Yes' && module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year && year <= module4Data.end_year && module4Data.hsa_personal_contribution !== undefined && module4Data.hsa_personal_contribution !== '') {
      rothIRAPersonalContributionHsa = (investmentsData['Retirement Accounts HSA'][year]['Base Income'] / 100) * module4Data.hsa_personal_contribution;
    }
    else {
      if (module5Data.contribute_hsa_savings_account !== undefined && module5Data.contribute_hsa_savings_account === 'Yes' && module5Data.start_year !== undefined && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year && year <= module5Data.end_year && module5Data.hsa_personal_contribution !== undefined && module5Data.hsa_personal_contribution !== '') {
        rothIRAPersonalContributionHsa = (investmentsData['Retirement Accounts HSA'][year]['Base Income'] / 100) * module5Data.hsa_personal_contribution;
      }
      else {
        if (module8Data.contribute_hsa_savings_account !== undefined && module8Data.contribute_hsa_savings_account === 'Yes' && module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year && year <= module8Data.end_year && module8Data.hsa_personal_contribution !== undefined && module8Data.hsa_personal_contribution !== '') {
          rothIRAPersonalContributionHsa = (investmentsData['Retirement Accounts HSA'][year]['Base Income'] / 100) * module8Data.hsa_personal_contribution;
        }
        else {
          if (module17Data.contribute_hsa_savings_account !== undefined && module17Data.contribute_hsa_savings_account === 'Yes' && module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year && year <= module17Data.end_year && module17Data.hsa_personal_contribution !== undefined && module17Data.hsa_personal_contribution !== '') {
            rothIRAPersonalContributionHsa = (investmentsData['Retirement Accounts HSA'][year]['Base Income'] / 100) * module17Data.hsa_personal_contribution;
          }
          else {
            rothIRAPersonalContributionHsa = 0;
          }
        }
      }
    }
    if (isNaN(rothIRAPersonalContributionHsa)) {
      rothIRAPersonalContributionHsa = 0;
    }
    investmentsData['Retirement Accounts HSA'][year]['Personal Contribution'] = rothIRAPersonalContributionHsa;

    //Annual Expected Return
    let annualPreTaxExpectedReturnHsa = 0;
    if (module4Data.contribute_hsa_savings_account !== undefined && module4Data.contribute_hsa_savings_account === 'Yes' && module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year && year <= module4Data.end_year && module4Data.hsa_annual_expected !== undefined && module4Data.hsa_annual_expected !== '' && parseFloat(module4Data.hsa_annual_expected) > 0) {
      annualPreTaxExpectedReturnHsa = parseFloat(module4Data.hsa_annual_expected);
    }
    else {
      if (module5Data.contribute_hsa_savings_account !== undefined && module5Data.contribute_hsa_savings_account === 'Yes' && module5Data.start_year !== undefined && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year && year <= module5Data.end_year && module5Data.hsa_annual_expected !== undefined && module5Data.hsa_annual_expected !== '' && parseFloat(module5Data.hsa_annual_expected) > 0) {
        annualPreTaxExpectedReturnHsa = parseFloat(module5Data.hsa_annual_expected);
      }
      else {
        if (module8Data.contribute_hsa_savings_account !== undefined && module8Data.contribute_hsa_savings_account === 'Yes' && module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year && year <= module8Data.end_year && module8Data.hsa_annual_expected !== undefined && module8Data.hsa_annual_expected !== '' && parseFloat(module8Data.hsa_annual_expected) > 0) {
          annualPreTaxExpectedReturnHsa = parseFloat(module8Data.hsa_annual_expected);
        }
        else {
          if (module17Data.contribute_hsa_savings_account !== undefined && module17Data.contribute_hsa_savings_account === 'Yes' && module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year && year <= module17Data.end_year && module17Data.hsa_annual_expected !== undefined && module17Data.hsa_annual_expected !== '' && parseFloat(module17Data.hsa_annual_expected) > 0) {
            annualPreTaxExpectedReturnHsa = parseFloat(module17Data.hsa_annual_expected);
          }
          else {
            if (typeof databaseData !== undefined && databaseData !== '' && typeof databaseData['Data']['HSA Account Return'] !== undefined) {
              annualPreTaxExpectedReturnHsa = databaseData['Data']['HSA Account Return'];
            }
          }
        }
      }
    }
    investmentsData['Retirement Accounts HSA'][year]['Annual Pre Tax Expected Return'] = annualPreTaxExpectedReturnHsa;


    // Traditional IRA Calculations
    investmentsData['Retirement Accounts Traditional IRA'][year] = {};
    //Base Income
    if (incomeStatementData !== undefined &&
      incomeStatementData[year] !== undefined && incomeStatementData[year] !== '' &&
      incomeStatementData[year]['Gross Income'][module4Data.occupation_title] !== undefined &&
      incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission'] !== undefined &&
      incomeStatementData[year]['Gross Income'][`${module5Data.occupation_title} - Second`] !== undefined &&
      incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Second'] !== undefined &&
      incomeStatementData[year]['Gross Income'][`${module8Data.occupation_title} - Third`] !== undefined &&
      incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Third'] !== undefined &&
      incomeStatementData[year]['Gross Income'][`${module17Data.occupation_title} - Fourth`] !== undefined &&
      incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'] !== undefined
    ) {
      investmentsData['Retirement Accounts Traditional IRA'][year]['Base Income'] = incomeStatementData[year]['Gross Income'][module4Data.occupation_title] +
        incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission'] +
        incomeStatementData[year]['Gross Income'][`${module5Data.occupation_title} - Second`] +
        incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Second'] +
        incomeStatementData[year]['Gross Income'][`${module8Data.occupation_title} - Third`] +
        incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Third'] +
        incomeStatementData[year]['Gross Income'][`${module17Data.occupation_title} - Fourth`] +
        incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'];
    }

    var rothIRAPersonalContributionTraditional = 0;
    if (module4Data.roth_contribute !== undefined && module4Data.roth_contribute === 'Yes' && module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year && year <= module4Data.end_year && module4Data.roth_personal_contribution !== undefined && module4Data.roth_personal_contribution !== '') {
      rothIRAPersonalContributionTraditional = (investmentsData['Retirement Accounts Traditional IRA'][year]['Base Income'] / 100) * module4Data.roth_personal_contribution;
    }
    else {
      if (module5Data.roth_contribute !== undefined && module5Data.roth_contribute === 'Yes' && module5Data.start_year !== undefined && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year && year <= module5Data.end_year && module5Data.roth_personal_contribution !== undefined && module5Data.roth_personal_contribution !== '') {
        rothIRAPersonalContributionTraditional = (investmentsData['Retirement Accounts Traditional IRA'][year]['Base Income'] / 100) * module5Data.roth_personal_contribution;
      }
      else {
        if (module8Data.roth_contribute !== undefined && module8Data.roth_contribute === 'Yes' && module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year && year <= module8Data.end_year && module8Data.roth_personal_contribution !== undefined && module8Data.roth_personal_contribution !== '') {
          rothIRAPersonalContributionTraditional = (investmentsData['Retirement Accounts Traditional IRA'][year]['Base Income'] / 100) * module8Data.roth_personal_contribution;
        }
        else {
          if (module17Data.roth_contribute !== undefined && module17Data.roth_contribute === 'Yes' && module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year && year <= module17Data.end_year && module17Data.roth_personal_contribution !== undefined && module17Data.roth_personal_contribution !== '') {
            rothIRAPersonalContributionTraditional = (investmentsData['Retirement Accounts Traditional IRA'][year]['Base Income'] / 100) * module17Data.roth_personal_contribution;
          }
          else {
            rothIRAPersonalContributionTraditional = 0;
          }
        }
      }
    }
    if (isNaN(rothIRAPersonalContributionTraditional)) {
      rothIRAPersonalContributionTraditional = 0;
    }
    investmentsData['Retirement Accounts Traditional IRA'][year]['Personal Contribution'] = rothIRAPersonalContributionTraditional;

    //Annual Expected Return
    let annualPreTaxExpectedReturnTraditional = 0;
    if (module4Data.ira_roth_contribute !== undefined && module4Data.ira_roth_contribute === 'Yes' && module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year && year <= module4Data.end_year && module4Data.roth_annual_expected_pre_tax !== undefined && module4Data.roth_annual_expected_pre_tax !== '' && module4Data.rothAnnualExpectedPreTax > 0) {
      annualPreTaxExpectedReturnTraditional = module4Data.roth_annual_expected_pre_tax;
    }
    else {
      if (module5Data.ira_roth_contribute !== undefined && module5Data.ira_roth_contribute === 'Yes' && module5Data.start_year !== undefined && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year && year <= module5Data.end_year && module5Data.roth_annual_expected_pre_tax !== undefined && module5Data.roth_annual_expected_pre_tax !== '') {
        annualPreTaxExpectedReturnTraditional = module5Data.roth_annual_expected_pre_tax;
      }
      else {
        if (module8Data.ira_roth_contribute !== undefined && module8Data.ira_roth_contribute === 'Yes' && module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year && year <= module8Data.end_year && module8Data.roth_annual_expected_pre_tax !== undefined && module8Data.roth_annual_expected_pre_tax !== '') {
          annualPreTaxExpectedReturnTraditional = module8Data.roth_annual_expected_pre_tax;
        }
        else {
          if (module17Data.ira_roth_contribute !== undefined && module17Data.ira_roth_contribute === 'Yes' && module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year && year <= module17Data.end_year && module17Data.roth_annual_expected_pre_tax !== undefined && module17Data.roth_annual_expected_pre_tax !== '') {
            annualPreTaxExpectedReturnTraditional = module17Data.roth_annual_expected_pre_tax;
          }
          else {
            if (databaseData !== undefined && databaseData !== '' && databaseData['Data']['Roth IRA Limit Rate'] !== undefined) {
              annualPreTaxExpectedReturnTraditional = databaseData['Data']['Roth IRA Limit Rate'];
            }
          }
        }
      }
    }
    if (isNaN(annualPreTaxExpectedReturnTraditional)) {
      annualPreTaxExpectedReturnTraditional = 0;
    }
    investmentsData['Retirement Accounts Traditional IRA'][year]['Annual Pre Tax Expected Return'] = annualPreTaxExpectedReturnTraditional;
    totalAnnualExpectedReturnRothIraTraditional = totalAnnualExpectedReturnRothIraTraditional + annualPreTaxExpectedReturnTraditional;



    // Roth IRA Calculations
    investmentsData['Retirement Accounts Roth IRA'][year] = {};

    // Total Income for Phaseout
    let totalIncomeForPhaseOut = 0;
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[year] !== undefined
      && incomeStatementData[year]['Total Income'] !== undefined && incomeStatementData[year]['Total Income'] !== ''
      && incomeStatementData[year]['Gross Income']['Rental Income'] !== undefined) {
      totalIncomeForPhaseOut = incomeStatementData[year]['Total Income'] - incomeStatementData[year]['Gross Income']['Rental Income'];
    } else {
      totalIncomeForPhaseOut = 0;
    }
    investmentsData['Retirement Accounts Roth IRA'][year]['Total Income for Phaseout'] = totalIncomeForPhaseOut;

    // Base Income
    investmentsData['Retirement Accounts Roth IRA'][year]['Base Income'] = investmentsData['Retirement Accounts 401k'][year]['Base Income'];

    // Personal Contrubution
    let rothIRAPersonalContribution = 0;
    let rothIRAPersonalContribution1 = 0;
    let rothIRAPersonalContribution2 = 0;

    if (module4Data.roth_contribute !== undefined && module4Data.roth_contribute === 'Yes' && module4Data.start_year !== undefined && module4Data.start_year !== ''
      && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year && year <= module4Data.end_year
      && module4Data.roth_personal_contribution !== undefined && module4Data.roth_personal_contribution !== '') {
      rothIRAPersonalContribution = (investmentsData['Retirement Accounts Roth IRA'][year]['Base Income'] / 100) * module4Data.roth_personal_contribution;
    } else {
      if (module5Data.roth_contribute !== undefined && module5Data.roth_contribute === 'Yes' && module5Data.start_year !== undefined
        && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== ''
        && year >= module5Data.start_year && year <= module5Data.end_year && module5Data.roth_personal_contribution !== undefined
        && module5Data.roth_personal_contribution !== '') {
        rothIRAPersonalContribution = (investmentsData['Retirement Accounts Roth IRA'][year]['Base Income'] / 100) * module5Data.roth_personal_contribution;
      } else {
        if (module8Data.roth_contribute !== undefined && module8Data.roth_contribute === 'Yes' && module8Data.start_year !== undefined
          && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year
          && year <= module8Data.end_year && module8Data.roth_personal_contribution !== undefined && module8Data.roth_personal_contribution !== '') {
          rothIRAPersonalContribution = (investmentsData['Retirement Accounts Roth IRA'][year]['Base Income'] / 100) * module8Data.roth_personal_contribution;
        } else {
          if (module17Data.roth_contribute !== undefined && module17Data.roth_contribute === 'Yes' && module17Data.start_year !== undefined
            && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year
            && year <= module17Data.end_year && module17Data.roth_personal_contribution !== undefined && module17Data.roth_personal_contribution !== '') {
            rothIRAPersonalContribution = (investmentsData['Retirement Accounts Roth IRA'][year]['Base Income'] / 100) * module17Data.roth_personal_contribution;
          } else {
            rothIRAPersonalContribution = 0;
          }
        }
      }
    }

    if (databaseData !== undefined && databaseData !== '' && databaseData['Roth IRA 2018 Contribution Limit'] !== undefined && databaseData['Roth IRA 2018 Contribution Limit'][year] !== undefined
      && databaseData['Roth IRA 2018 Contribution Limit'][year] !== '' && databaseData['Roth IRA 2018 Contribution Limit'][year]['Max Personal Limit']
      && rothIRAPersonalContribution > databaseData['Roth IRA 2018 Contribution Limit'][year]['Max Personal Limit']) {
      rothIRAPersonalContribution1 = databaseData['Roth IRA 2018 Contribution Limit'][year]['Max Personal Limit'];
    } else {
      if (module4Data.roth_contribute !== undefined && module4Data.roth_contribute === 'Yes' && module4Data.start_year !== undefined
        && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year
        && year <= module4Data.end_year && module4Data.roth_personal_contribution !== undefined && module4Data.roth_personal_contribution !== '') {
        rothIRAPersonalContribution1 = (investmentsData['Retirement Accounts Roth IRA'][year]['Base Income'] / 100) * module4Data.roth_personal_contribution;
      } else {
        if (module5Data.roth_contribute !== undefined && module5Data.roth_contribute === 'Yes' && module5Data.start_year !== undefined
          && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year
          && year <= module5Data.end_year && module5Data.roth_personal_contribution !== undefined
          && module5Data.roth_personal_contribution !== '') {
          rothIRAPersonalContribution1 = (investmentsData['Retirement Accounts Roth IRA'][year]['Base Income'] / 100) * module5Data.roth_personal_contribution;
        } else {
          if (module8Data.roth_contribute !== undefined && module8Data.roth_contribute === 'Yes' && module8Data.start_year !== undefined
            && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year
            && year <= module8Data.end_year && module8Data.roth_personal_contribution !== undefined && module8Data.roth_personal_contribution !== '') {
            rothIRAPersonalContribution1 = (investmentsData['Retirement Accounts Roth IRA'][year]['Base Income'] / 100) * module8Data.roth_personal_contribution;
          } else {
            if (module17Data.roth_contribute !== undefined && module17Data.roth_contribute === 'Yes' && module17Data.start_year !== undefined
              && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year
              && year <= module17Data.end_year && module17Data.roth_personal_contribution !== undefined && module17Data.roth_personal_contribution !== '') {
              rothIRAPersonalContribution1 = (investmentsData['Retirement Accounts Roth IRA'][year]['Base Income'] / 100) * module17Data.roth_personal_contribution;
            } else {
              rothIRAPersonalContribution1 = 0;
            }
          }
        }
      }
    }


    // Updated formula
    if (module10Data.marriege_year !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0) {
      if (databaseData !== undefined && databaseData !== '' && databaseData['Roth IRA Income Limits'] !== undefined && databaseData['Roth IRA Income Limits'][year] !== undefined
        && databaseData['Roth IRA Income Limits'][year] !== '' && databaseData['Roth IRA Income Limits'][year].Married1 !== undefined
        && totalIncomeForPhaseOut >= databaseData['Roth IRA Income Limits'][year].Married1) {
        rothIRAPersonalContribution2 = 0;
      } else {
        if (databaseData !== undefined && databaseData !== '' && databaseData['Roth IRA Income Limits'] !== undefined && databaseData['Roth IRA Income Limits'][year] !== undefined
          && databaseData['Roth IRA Income Limits'][year] !== '' && databaseData['Roth IRA Income Limits'][year].Married1 !== undefined
          && totalIncomeForPhaseOut > databaseData['Roth IRA Income Limits'][year].Married && totalIncomeForPhaseOut < databaseData['Roth IRA Income Limits'][year].Married1) {
          rothIRAPersonalContribution2 = (databaseData['Roth IRA Income Limits'][year].Married1 - totalIncomeForPhaseOut) / (databaseData['Roth IRA Income Limits'][year].Married1 - databaseData['Roth IRA Income Limits'][year].Married);
        } else {
          rothIRAPersonalContribution2 = 1;
        }
      }
    } else {
      if (databaseData !== undefined && databaseData !== '' && databaseData['Roth IRA Income Limits'] !== undefined
        && databaseData['Roth IRA Income Limits'][year] !== undefined
        && databaseData['Roth IRA Income Limits'][year] !== '' && databaseData['Roth IRA Income Limits'][year].Single1 !== undefined
        && totalIncomeForPhaseOut >= databaseData['Roth IRA Income Limits'][year].Single1) {
        rothIRAPersonalContribution2 = 0;
      } else {
        if (databaseData !== undefined && databaseData !== '' && databaseData['Roth IRA Income Limits'] !== undefined
          && databaseData['Roth IRA Income Limits'][year] !== undefined
          && databaseData['Roth IRA Income Limits'][year] !== '' && databaseData['Roth IRA Income Limits'][year].Single !== undefined
          && totalIncomeForPhaseOut > databaseData['Roth IRA Income Limits'][year].Single && totalIncomeForPhaseOut < databaseData['Roth IRA Income Limits'][year].Single1) {
          rothIRAPersonalContribution2 = (databaseData['Roth IRA Income Limits'][year].Single1 - totalIncomeForPhaseOut) / (databaseData['Roth IRA Income Limits'][year].Single1 - databaseData['Roth IRA Income Limits'][year].Single);
        } else {
          rothIRAPersonalContribution2 = 1;
        }
      }
    }


    investmentsData['Retirement Accounts Roth IRA'][year]['Personal Contribution'] = rothIRAPersonalContribution1 * rothIRAPersonalContribution2;

    // Annual Expected Return
    let annualPreTaxExpectedReturn = 0;
    if (module4Data.roth_contribute !== undefined && module4Data.roth_contribute === 'Yes' && module4Data.start_year !== undefined
      && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year
      && year <= module4Data.end_year && module4Data.roth_annual_expected_pre_tax !== undefined && module4Data.roth_annual_expected_pre_tax !== '' && module4Data.roth_annual_expected_pre_tax > 0) {
      annualPreTaxExpectedReturn = module4Data.roth_annual_expected_pre_tax;
    } else {
      if (module5Data.roth_contribute !== undefined && module5Data.roth_contribute === 'Yes' && module5Data.start_year !== undefined
        && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year
        && year <= module5Data.end_year && module5Data.roth_annual_expected_pre_tax !== undefined && module5Data.roth_annual_expected_pre_tax !== '') {
        annualPreTaxExpectedReturn = module5Data.roth_annual_expected_pre_tax;
      } else {
        if (module8Data.roth_contribute !== undefined && module8Data.roth_contribute === 'Yes' && module8Data.start_year !== undefined
          && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year
          && year <= module8Data.end_year && module8Data.roth_annual_expected_pre_tax !== undefined && module8Data.roth_annual_expected_pre_tax !== '') {
          annualPreTaxExpectedReturn = module8Data.roth_annual_expected_pre_tax;
        } else {
          if (module17Data.roth_contribute !== undefined && module17Data.roth_contribute === 'Yes' && module17Data.start_year !== undefined
            && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== ''
            && year >= module17Data.start_year && year <= module17Data.end_year && module17Data.roth_annual_expected_pre_tax !== undefined
            && module17Data.roth_annual_expected_pre_tax !== '') {
            annualPreTaxExpectedReturn = module17Data.roth_annual_expected_pre_tax;
          } else {
            if (databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && databaseData.Data['Roth IRA Limit Rate'] !== undefined) {
              annualPreTaxExpectedReturn = databaseData.Data['Roth IRA Limit Rate'];
            }
          }
        }
      }
    }
    investmentsData['Retirement Accounts Roth IRA'][year]['Annual Pre Tax Expected Return'] = annualPreTaxExpectedReturn;
    totalAnnualExpectedReturnRothIra += annualPreTaxExpectedReturn;

    // SEP IRA
    investmentsData['Retirement Accounts Seph IRA'][year] = {};

    // Base Income
    investmentsData['Retirement Accounts Seph IRA'][year]['Base Income'] = investmentsData['Retirement Accounts 401k'][year]['Base Income'];

    // Personal Contrubution
    let sephIraPersonalContribution = 0;
    if (module4Data.ira_contribute !== undefined && module4Data.ira_contribute === 'Yes' && module4Data.start_year !== undefined
      && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== ''
      && year >= module4Data.start_year && year <= module4Data.end_year && module4Data.ira_personal_contribution !== undefined
      && module4Data.ira_personal_contribution !== '') {
      sephIraPersonalContribution = (investmentsData['Retirement Accounts Seph IRA'][year]['Base Income'] / 100) * module4Data.ira_personal_contribution;
    } else {
      if (module5Data.ira_contribute !== undefined && module5Data.ira_contribute === 'Yes' && module5Data.start_year !== undefined
        && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year
        && year <= module5Data.end_year && module5Data.ira_personal_contribution !== undefined && module5Data.ira_personal_contribution !== '') {
        sephIraPersonalContribution = (investmentsData['Retirement Accounts Seph IRA'][year]['Base Income'] / 100) * module5Data.ira_personal_contribution;
      } else {
        if (module8Data.ira_contribute !== undefined && module8Data.ira_contribute === 'Yes' && module8Data.start_year !== undefined
          && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year
          && year <= module8Data.end_year && module8Data.ira_personal_contribution !== undefined && module8Data.ira_personal_contribution !== '') {
          sephIraPersonalContribution = (investmentsData['Retirement Accounts Seph IRA'][year]['Base Income'] / 100) * module8Data.ira_personal_contribution;
        } else {
          if (module17Data.ira_contribute !== undefined && module17Data.ira_contribute === 'Yes' && module17Data.start_year !== undefined
            && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year
            && year <= module17Data.end_year && module17Data.ira_personal_contribution !== undefined && module17Data.ira_personal_contribution !== '') {
            sephIraPersonalContribution = (investmentsData['Retirement Accounts Seph IRA'][year]['Base Income'] / 100) * module17Data.ira_personal_contribution;
          } else {
            sephIraPersonalContribution = 0;
          }
        }
      }
    }
    investmentsData['Retirement Accounts Seph IRA'][year]['Personal Contribution'] = sephIraPersonalContribution;

    // Max Income Based Limit (25% of up to 275k Income)
    let maxIncomeBasedLimit = 0;
    if (databaseData !== undefined && databaseData !== '' && databaseData['SEP IRA Contribution Limit'] !== undefined
      && databaseData['SEP IRA Contribution Limit'][year] !== undefined && databaseData['SEP IRA Contribution Limit'][year]['Total Limit'] !== undefined
      && investmentsData['Retirement Accounts Seph IRA'][year]['Base Income'] > databaseData['SEP IRA Contribution Limit'][year]['Total Limit']) {
      maxIncomeBasedLimit = (databaseData['SEP IRA Contribution Limit'][year]['Total Limit'] / 100) * databaseData['SEP IRA Contribution Limit'][year].Return;
    } else if (databaseData !== undefined && databaseData !== '' && databaseData['SEP IRA Contribution Limit'] !== undefined
      && databaseData['SEP IRA Contribution Limit'][year] !== undefined && databaseData['SEP IRA Contribution Limit'][year].Return !== undefined) {
      maxIncomeBasedLimit = (investmentsData['Retirement Accounts Seph IRA'][year]['Base Income'] / 100) * databaseData['SEP IRA Contribution Limit'][year].Return;
    } else {
      maxIncomeBasedLimit = 0;
    }
    investmentsData['Retirement Accounts Seph IRA'][year]['Max Income Based Limit'] = maxIncomeBasedLimit;

    // Max Dollar Based Limit ($55k)
    let maxDollarBasedLimit = 0;
    if (databaseData !== undefined && databaseData !== '' && databaseData['SEP IRA Contribution Limit'] !== undefined
      && databaseData['SEP IRA Contribution Limit'][year] !== undefined && databaseData['SEP IRA Contribution Limit'][year]['Max Personal Limit'] !== undefined) {
      maxDollarBasedLimit = databaseData['SEP IRA Contribution Limit'][year]['Max Personal Limit'];
    }
    investmentsData['Retirement Accounts Seph IRA'][year]['Max Dollar Based Limit'] = maxDollarBasedLimit;

    // Personal Contribution
    let sephIraFinalPersonalContribution = 0;
    if (maxIncomeBasedLimit < maxDollarBasedLimit) {
      if (sephIraPersonalContribution > maxIncomeBasedLimit) {
        sephIraFinalPersonalContribution = maxIncomeBasedLimit;
      } else {
        sephIraFinalPersonalContribution = sephIraPersonalContribution;
      }
    } else if (sephIraPersonalContribution > maxDollarBasedLimit) {
      sephIraFinalPersonalContribution = maxDollarBasedLimit;
    } else {
      sephIraFinalPersonalContribution = sephIraPersonalContribution;
    }
    investmentsData['Retirement Accounts Seph IRA'][year]['Personal Contribution Final'] = sephIraFinalPersonalContribution;

    // Annual Expected Return
    let annualPreTaxExpectedReturn1 = 0;
    if (module4Data.ira_contribute !== undefined && module4Data.ira_contribute === 'Yes' && module4Data.start_year !== undefined
      && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year
      && year <= module4Data.end_year && module4Data.ira_annual_expected_pre_tax !== undefined && module4Data.ira_annual_expected_pre_tax !== ''
      && module4Data.ira_annual_expected_pre_tax > 0) {
      annualPreTaxExpectedReturn1 = module4Data.ira_annual_expected_pre_tax;
    } else if (module5Data.ira_contribute !== undefined && module5Data.ira_contribute === 'Yes' && module5Data.start_year !== undefined
      && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year
      && year <= module5Data.end_year && module5Data.ira_annual_expected_pre_tax !== undefined && module5Data.ira_annual_expected_pre_tax !== '') {
      annualPreTaxExpectedReturn1 = module5Data.ira_annual_expected_pre_tax;
    } else if (module8Data.ira_contribute !== undefined && module8Data.ira_contribute === 'Yes' && module8Data.start_year !== undefined
      && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year
      && year <= module8Data.end_year && module8Data.ira_annual_expected_pre_tax !== undefined && module8Data.ira_annual_expected_pre_tax !== '') {
      annualPreTaxExpectedReturn1 = module8Data.ira_annual_expected_pre_tax;
    } else if (module17Data.ira_contribute !== undefined && module17Data.ira_contribute === 'Yes' && module17Data.start_year !== undefined
      && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year
      && year <= module17Data.end_year && module17Data.ira_annual_expected_pre_tax !== undefined && module17Data.ira_annual_expected_pre_tax !== '') {
      annualPreTaxExpectedReturn1 = module17Data.ira_annual_expected_pre_tax;
    } else if (databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && databaseData.Data['SEP IRA Limits Rate Second'] !== undefined) {
      annualPreTaxExpectedReturn1 = databaseData.Data['SEP IRA Limits Rate Second'];
    }
    investmentsData['Retirement Accounts Seph IRA'][year]['Annual Pre Tax Expected Return'] = annualPreTaxExpectedReturn1;
    totalAnnualExpectedReturnSephIra += annualPreTaxExpectedReturn1;

    // Pre Tax Pension
    investmentsData['Retirement Accounts Pension'][year] = {};
    investmentsData['Retirement Accounts Pension'][year]['Base Income'] = investmentsData['Retirement Accounts 401k'][year]['Base Income'];

    // Employer Contribution
    let pensionIraEmployerContribution = 0;
    if (module4Data.pension_contribute !== undefined && module4Data.pension_contribute === 'Yes' && module4Data.start_year !== undefined
      && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year
      && year <= module4Data.end_year && module4Data.pension_employer_contribution !== undefined && module4Data.pension_employer_contribution !== '') {
      pensionIraEmployerContribution = (investmentsData['Retirement Accounts Pension'][year]['Base Income'] / 100) * module4Data.pension_employer_contribution;
    } else {
      if (module5Data.pension_contribute !== undefined && module5Data.pension_contribute === 'Yes' && module5Data.start_year !== undefined
        && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year
        && year <= module5Data.end_year && module5Data.pension_employer_contribution !== undefined && module5Data.pension_employer_contribution !== '') {
        pensionIraEmployerContribution = (investmentsData['Retirement Accounts Pension'][year]['Base Income'] / 100) * module5Data.pension_employer_contribution;
      } else {
        if (module8Data.pension_contribute !== undefined && module8Data.pension_contribute === 'Yes' && module8Data.start_year !== undefined
          && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year
          && year <= module8Data.end_year && module8Data.pension_employer_contribution !== undefined && module8Data.pension_employer_contribution !== '') {
          pensionIraEmployerContribution = (investmentsData['Retirement Accounts Pension'][year]['Base Income'] / 100) * module8Data.pension_employer_contribution;
        } else {
          if (module17Data.pension_contribute !== undefined && module17Data.pension_contribute === 'Yes' && module17Data.start_year !== undefined
            && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year
            && year <= module17Data.end_year && module17Data.pension_employer_contribution !== undefined && module17Data.pension_employer_contribution !== '') {
            pensionIraEmployerContribution = (investmentsData['Retirement Accounts Pension'][year]['Base Income'] / 100) * module17Data.pension_employer_contribution;
          } else {
            pensionIraEmployerContribution = 0;
          }
        }
      }
    }
    investmentsData['Retirement Accounts Pension'][year]['Employer Contribution'] = pensionIraEmployerContribution;

    // Personal Contrubution
    let pensionPersonalContribution = 0;
    let pensionPersonalContribution1 = 0;
    if (module4Data.pension_contribute !== undefined && module4Data.pension_contribute === 'Yes' && module4Data.start_year !== undefined
      && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== ''
      && year >= module4Data.start_year && year <= module4Data.end_year && module4Data.pension_personal_contribution !== undefined
      && module4Data.pension_personal_contribution !== '') {
      pensionPersonalContribution = (investmentsData['Retirement Accounts Pension'][year]['Base Income'] / 100) * module4Data.pension_personal_contribution;
    } else {
      if (module5Data.pension_contribute !== undefined && module5Data.pension_contribute === 'Yes' && module5Data.start_year !== undefined
        && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year

        && year <= module5Data.end_year && module5Data.pension_personal_contribution !== undefined && module5Data.pension_personal_contribution !== '') {
        pensionPersonalContribution = (investmentsData['Retirement Accounts Pension'][year]['Base Income'] / 100) * module5Data.pension_personal_contribution;
      } else {
        if (module8Data.pension_contribute !== undefined && module8Data.pension_contribute === 'Yes' && module8Data.start_year !== undefined
          && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== ''
          && year >= module8Data.start_year && year <= module8Data.end_year && module8Data.pension_personal_contribution !== undefined
          && module8Data.pension_personal_contribution !== '') {
          pensionPersonalContribution = (investmentsData['Retirement Accounts Pension'][year]['Base Income'] / 100) * module8Data.pension_personal_contribution;
        } else {
          if (module17Data.pension_contribute !== undefined && module17Data.pension_contribute === 'Yes' && module17Data.start_year !== undefined
            && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year
            && year <= module17Data.end_year && module17Data.pension_personal_contribution !== undefined
            && module17Data.pension_personal_contribution !== '') {
            pensionPersonalContribution = (investmentsData['Retirement Accounts Pension'][year]['Base Income'] / 100) * module17Data.pension_personal_contribution;
          } else {
            pensionPersonalContribution = 0;
          }
        }
      }
    }

    if (databaseData !== undefined
      && databaseData !== ''
      && databaseData['Pension 2018 Contribution Limit'] !== undefined
      && databaseData['Pension 2018 Contribution Limit'][year] !== undefined
      && databaseData['Pension 2018 Contribution Limit'][year] !== ''
      && databaseData['Pension 2018 Contribution Limit'][year]['Total Limit'] !== undefined
      && (pensionPersonalContribution) > databaseData['Pension 2018 Contribution Limit'][year]['Total Limit']) {
      pensionPersonalContribution1 = databaseData['Pension 2018 Contribution Limit'][year]['Total Limit'];
    } else {
      if (module4Data.pension_contribute !== undefined && module4Data.pension_contribute === 'Yes' && module4Data.start_year !== undefined
        && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year
        && year <= module4Data.end_year && module4Data.pension_personal_contribution !== undefined && module4Data.pension_personal_contribution !== '') {
        pensionPersonalContribution1 = (investmentsData['Retirement Accounts Pension'][year]['Base Income'] / 100) * module4Data.pension_personal_contribution;
      } else {
        if (module5Data.pension_contribute !== undefined && module5Data.pension_contribute === 'Yes' && module5Data.start_year !== undefined
          && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year
          && year <= module5Data.end_year && module5Data.pension_personal_contribution !== undefined && module5Data.pension_personal_contribution !== '') {
          pensionPersonalContribution1 = (investmentsData['Retirement Accounts Pension'][year]['Base Income'] / 100) * module5Data.pension_personal_contribution;
        } else {
          if (module8Data.pension_contribute !== undefined && module8Data.pension_contribute === 'Yes' && module8Data.start_year !== undefined
            && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year
            && year <= module8Data.end_year && module8Data.pension_personal_contribution !== undefined && module8Data.pension_personal_contribution !== '') {
            pensionPersonalContribution1 = (investmentsData['Retirement Accounts Pension'][year]['Base Income'] / 100) * module8Data.pension_personal_contribution;
          } else {
            if (module17Data.pension_contribute !== undefined && module17Data.pension_contribute === 'Yes' && module17Data.start_year !== undefined
              && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year
              && year <= module17Data.end_year && module17Data.pension_personal_contribution !== undefined && module17Data.pension_personal_contribution !== '') {
              pensionPersonalContribution1 = (investmentsData['Retirement Accounts Pension'][year]['Base Income'] / 100) * module17Data.pension_personal_contribution;
            } else {
              pensionPersonalContribution1 = 0;
            }
          }
        }
      }
    }
    investmentsData['Retirement Accounts Pension'][year]['Personal Contribution'] = pensionPersonalContribution1;

    // Total Contribution
    investmentsData['Retirement Accounts Pension'][year]['Total Contribution'] = pensionPersonalContribution1 + pensionIraEmployerContribution;

    // Annual Pre-Tax Expected Return
    let pensionAnnualPreTaxExpectedReturn = 0;
    if (module4Data.pension_contribute !== undefined && module4Data.pension_contribute === 'Yes' && module4Data.start_year !== undefined
      && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year >= module4Data.start_year
      && year <= module4Data.end_year && module4Data.pension_annual_expected_pre_tax !== undefined && module4Data.pension_annual_expected_pre_tax !== ''
      && module4Data.pension_annual_expected_pre_tax > 0) {
      pensionAnnualPreTaxExpectedReturn = module4Data.pension_annual_expected_pre_tax;
    } else if (module5Data.pension_contribute !== undefined && module5Data.pension_contribute === 'Yes' && module5Data.start_year !== undefined
      && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year >= module5Data.start_year
      && year <= module5Data.end_year && module5Data.pension_annual_expected_pre_tax !== undefined && module5Data.pension_annual_expected_pre_tax !== '') {
      pensionAnnualPreTaxExpectedReturn = module5Data.pension_annual_expected_pre_tax;
    } else if (module8Data.pension_contribute !== undefined && module8Data.pension_contribute === 'Yes' && module8Data.start_year !== undefined
      && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year >= module8Data.start_year
      && year <= module8Data.end_year && module8Data.pension_annual_expected_pre_tax !== undefined
      && module8Data.pension_annual_expected_pre_tax !== '') {
      pensionAnnualPreTaxExpectedReturn = module8Data.pension_annual_expected_pre_tax;
    } else if (module17Data.pension_contribute !== undefined && module17Data.pension_contribute === 'Yes' && module17Data.start_year !== undefined
      && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year >= module17Data.start_year
      && year <= module17Data.end_year && module17Data.pension_annual_expected_pre_tax !== undefined
      && module17Data.pension_annual_expected_pre_tax !== '') {
      pensionAnnualPreTaxExpectedReturn = module17Data.pension_annual_expected_pre_tax;
    } else if (databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined
      && databaseData.Data['Pension 2018 Limits Rate'] !== undefined) {
      pensionAnnualPreTaxExpectedReturn = databaseData.Data['Pension 2018 Limits Rate'];
    }
    investmentsData['Retirement Accounts Pension'][year]['Annual Pre Tax Expected Return'] = pensionAnnualPreTaxExpectedReturn;
    totalAnnualExpectedReturnPension += pensionAnnualPreTaxExpectedReturn;

    // Total Beginning Balance
    /** *
         ** Long Term Investments
         ** */
    investmentsData['Long-Term Investments'][year] = {};

    // Beg Balance
    if (year === module1Data.start_year) {
      begBalanceLong = module18Data.existing_total_long_term_investments;
    } else {
      begBalanceLong = endingBalanceLong;
    }
    investmentsData['Long-Term Investments'][year]['Beg Balance'] = begBalanceLong;

    // Cash Contribution
    if (module9Data.long_term_start_year !== undefined && year >= module9Data.long_term_start_year && module9Data.longtermAnnualContributionPercentage !== undefined && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[year] !== undefined && incomeStatementData[year]['Income After Taxes'] !== undefined && incomeStatementData[year]['Income After Taxes'] > 0) {
      cashContibution = incomeStatementData[year]['Income After Taxes'] * (module9Data.longtermAnnualContributionPercentage / 100);
    } else {
      cashContibution = 0;
    }
    investmentsData['Long-Term Investments'][year]['Cash Contibution'] = cashContibutionLong;
    longTermCashContributionsTotal += investmentsData['Long-Term Investments'][year]['Cash Contibution'];

    // Balance 1
    balance1Long = parseFloat(begBalanceLong) + parseFloat(cashContibutionLong);
    investmentsData['Long-Term Investments'][year].Balance1 = balance1Long;

    // Earnings
    earningAvg = 0;
    if ((balance1Long + begBalanceLong) > 0) {
      earningAvg = (balance1Long + begBalanceLong) / 2;
    }
    earningsLong = earningAvg * (parseFloat(module9Data.long_term_annual_expected_return_in_percentage) / 100);
    investmentsData['Long-Term Investments'][year].Earnings = earningsLong;
    longTermEarningsTotal += investmentsData['Long-Term Investments'][year].Earnings;

    // Balance 2
    balance2Long = balance1Long + earningsLong;
    investmentsData['Long-Term Investments'][year].Balance2 = balance2Long;

    // Cash Need
    if (investmentsData['Short-Term Investments'][year]['Cash Transfer'] < investmentsData['Short-Term Investments'][year]['Cash Need']) {
      cashNeedLong = investmentsData['Short-Term Investments'][year]['Cash Need'] - investmentsData['Short-Term Investments'][year]['Cash Transfer'];
    } else {
      cashNeedLong = 0;
    }
    investmentsData['Long-Term Investments'][year]['Cash Need'] = cashNeedLong;

    // Cash Transfer
    if (balance2Long >= cashNeedLong) {
      cashTransferLong = cashNeedLong;
    } else if (balance2Long > 0) {
      cashTransferLong = balance2Long;
    } else {
      cashTransferLong = 0;
    }
    investmentsData['Long-Term Investments'][year]['Cash Transfer'] = cashTransferLong;
    longTermCashTransferTotal += investmentsData['Long-Term Investments'][year]['Cash Transfer'];

    // Balance 3
    balance3Long = balance2Long - cashTransferLong;
    investmentsData['Long-Term Investments'][year].Balance3 = balance3Long;

    // Contribution from Excess Cash
    if (module9Data.long_term_start_year !== undefined && module9Data.long_term_start_year > 0 && year >= module9Data.long_term_start_year) {
      contributionLong = investmentsData['Short-Term Investments'][year].Excess;
    } else {
      contributionLong = 0;
    }
    investmentsData['Long-Term Investments'][year].Contribution = contributionLong;
    longTermContributionsTotal += investmentsData['Long-Term Investments'][year].Contribution;

    // Ending Balance
    endingBalanceLong = balance3Long + contributionLong;
    investmentsData['Long-Term Investments'][year]['Ending Balance'] = endingBalanceLong;
    longTermEndingBalanceLastVal = endingBalanceLong;

    //529 Analysis data
    investmentsData['529 Plan'][year] = {};

    // Beg Balance
    if (year === module1Data.start_year && module18Data.existing_529_plan !== undefined && module18Data.existing_529_plan !== '') {
      begBalanceLong529 = module18Data.existing_529_plan;
    } else {
      begBalanceLong529 = balance2Long529;
    }
    investmentsData['529 Plan'][year]['Beg Balance'] = begBalanceLong529;

    // Cash Contribution
    if (module9Data.plan_529_start_year !== undefined && module9Data.plan_529_start_year !== '' && year >= module9Data.plan_529_start_year && module9Data.plan_529_annual_contribution_percentage !== undefined && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[year] !== undefined && incomeStatementData[year] !== '' && incomeStatementData[year]['Income After Taxes'] !== undefined && incomeStatementData[year]['Income After Taxes'] > 0) {
      cashContibutionLong529 = incomeStatementData[year]['Income After Taxes'] * (module9Data.plan_529_annual_contribution_percentage / 100);
    } else {
      cashContibutionLong529 = 0;
    }

    investmentsData['529 Plan'][year]['Cash Contibution'] = cashContibutionLong529;
    longTermCashContributionsTotal529 = longTermCashContributionsTotal529 + investmentsData['529 Plan'][year]['Cash Contibution'];

    // Balance 1
    balance1Long529 = parseFloat(begBalanceLong529) + parseFloat(cashContibutionLong529);
    investmentsData['529 Plan'][year]['Balance1'] = balance1Long529;

    // Earnings
    earningAvg529 = 0;
    if ((balance1Long529 + begBalanceLong529) > 0) {
      earningAvg529 = (balance1Long529 + begBalanceLong529) / 2;
    }
    earningsLong529 = earningAvg529 * (parseFloat(module9Data.plan_529_annual_expected_pre_tax_return) / 100);
    investmentsData['529 Plan'][year]['Earnings'] = earningsLong529;
    longTermEarningsTotal529 = longTermEarningsTotal529 + investmentsData['529 Plan'][year]['Earnings'];

    // Balance 2
    balance2Long529 = balance1Long529 + earningsLong529;
    investmentsData['529 Plan'][year]['Balance2'] = balance2Long529;

    //Life Isurance section
    investmentsData['Life Insurance'][year] = {};
    // Beg Balance
    if (year === module1Data.start_year && module18Data.existing_cash_value_life_insurance_policies !== undefined && module18Data.existing_cash_value_life_insurance_policies !== '') {
      begBalanceLongLife = module18Data.existing_cash_value_life_insurance_policies;
    } else {
      begBalanceLongLife = balance2LongLife;
    }
    investmentsData['Life Insurance'][year]['Beg Balance'] = begBalanceLongLife;

    // Cash Contribution
    if (module9Data.life_insurance_start_year !== undefined && module9Data.life_insurance_start_year !== '' && year === module9Data.life_insurance_start_year && module9Data.monthlyContributionCashBuilderAnnualVal !== undefined && module9Data.monthlyContributionCashBuilderAnnualVal !== '') {
      cashContibutionLongLife = module9Data.monthlyContributionCashBuilderAnnualVal;
    } else {
      if (module9Data.life_insurance_start_year !== undefined && module9Data.life_insurance_start_year !== '' && year >= module9Data.life_insurance_start_year && assumptionsData !== undefined && assumptionsData[year] !== undefined) {
        cashContibutionLongLife = cashContibutionLongLife * ((assumptionsData[year].Expenses) / 100);
      } else {
        cashContibutionLongLife = 0;
      }
    }
    investmentsData['Life Insurance'][year]['Cash Contibution'] = cashContibutionLongLife;
    longTermCashContributionsTotalLife = longTermCashContributionsTotalLife + investmentsData['Life Insurance'][year]['Cash Contibution'];

    // Balance 1
    balance1LongLife = parseFloat(begBalanceLongLife) + parseFloat(cashContibutionLongLife);
    investmentsData['Life Insurance'][year]['Balance1'] = balance1LongLife;

    // Earnings
    earningAvgLife = 0;
    if ((balance1LongLife + begBalanceLongLife) > 0) {
      earningAvgLife = (balance1LongLife + begBalanceLongLife) / 2;
    }
    earningsLongLife = earningAvgLife * (parseFloat(module9Data.annual_expected_pre_tax_return_life_ins) / 100);
    investmentsData['Life Insurance'][year]['Earnings'] = earningsLongLife;
    longTermEarningsTotalLife = longTermEarningsTotalLife + investmentsData['Life Insurance'][year]['Earnings'];

    // Balance 2
    balance2LongLife = balance1LongLife + earningsLongLife;
    investmentsData['Life Insurance'][year]['Balance2'] = balance2LongLife;
  }

  /** *
           ** 401k & Roth IRA
           ** */
  year = parseInt(module1Data.start_year, 10);
  yearLimit = parseInt(module1Data.start_year, 10) + 30;

  const investmentsData1 = {};
  // 401K Personal Contributions
  investmentsData1['401k'] = {};
  investmentsData1['Roth'] = {};
  investmentsData1['HSA'] = {};

  // 401K Employer Contributions
  investmentsData1['401k Employer Comtributions'] = {};
  investmentsData1['Roth Employer Comtributions'] = {};
  // let earningsEmployerTotal = 0;
  // Roth IRA
  investmentsData1['Roth IRA'] = {};
  //Traditional IRA
  investmentsData1['Traditional IRA'] = {};
  // SEPH IRA
  investmentsData1['Seph IRA'] = {};
  // Pre-Tax Pension
  investmentsData1.Pension = {};

  let earnings401KTotal = 0;
  let contributions401KTotal = 0;
  let endingBalance401kLastVal = 0;

  let rothContributionsTotal = 0;
  let TraditionalContributionsTotal = 0;
  let HsaContributionsTotal = 0;
  let earningsRothTotal = 0;
  let earningsTraditionalTotal = 0;
  let earningsHsaTotal = 0;
  let rothEndingBalanceLastVal = 0;
  let devideRothVal = 0;
  let devideRothValTraditional = 0;
  let devideRothValHsa = 0;

  // Seph IRA
  // let sephContributionsTotal = 0;
  // let earningsSephTotal = 0;
  let devideSephVal = 0;

  // Pension
  // let pensionContributionsTotal = 0;
  // let earningsPensionTotal = 0;
  let devidePensionVal = 0;
  let begBalance401kRoth = 0;
  if (module18Data.existing_roth_401k_403b !== undefined && module18Data.existing_roth_401k_403b !== '') {
    begBalance401kRoth = module18Data.existing_roth_401k_403b;
  }

  let k = 0;

  let begBalance401k = 0;
  if (module18Data.existing_401k_403b !== undefined && module18Data.existing_401k_403b !== '') {
    begBalance401k = module18Data.existing_401k_403b;
  }

  let begBalanceTraditional = 0;
  if (module18Data.existing_traditional_ra !== undefined && module18Data.existing_traditional_ra !== '') {
    begBalanceTraditional = module18Data.existing_traditional_ra;
  }

  let begBalanceHsa = 0;
  if (module18Data.existing_total_value_of_hsa !== undefined && module18Data.existing_total_value_of_hsa !== '') {
    begBalanceHsa = module18Data.existing_total_value_of_hsa;
  }

  let contribution401k = 0;
  let earnings401k = 0;
  let endBalance401k = 0;
  let totalBegBalanceRoth = 0;
  let contribution401kRoth = 0;
  let contributionTotal401kRoth = 0;

  let begBalanceRoth = 0;

  let contributionRoth = 0;
  let earningsRoth = 0;
  let endBalanceRoth = 0;
  let avarage401k;
  let avarageRothIRA;
  let firstVar = 0;
  let firstRothVar = 0;

  let contributionTraditional = 0;
  let earningsTraditional = 0;
  let endBalanceTraditional = 0;
  let avarageRothIRATraditional;
  let firstRothVarTraditional = 0;

  // Seph IRA
  let begBalanceSeph = 0;

  let contributionSeph = 0;
  let earningsSeph = 0;
  let endBalanceSeph = 0;
  let avarageSephIRA;
  let firstSephVar = 0;

  // Pension
  let begBalancePension = 0;

  let contributionPension = 0;
  let earningsPension = 0;
  let endBalancePension = 0;
  let avaragePensionIRA;
  let firstPensionVar = 0;

  let begBalanceEmployer = 0;
  let employerContribution = 0;
  let employerVar = 0;
  let avarageEmployer;
  let employerEarnings = 0;
  let balanceBeforeVesting = 0;
  let vestingReductions = 0;
  let totalEndingBalance = 0;

  let begBalanceEmployerRoth = 0; 
  let employerContributionRoth = 0;
  let contributionTotalEmployerRoth = 0;
  let employerVarRoth = 0;
  let avarageEmployerRoth;
  let employerEarningsRoth = 0;
  let balanceBeforeVestingRoth = 0;
  let vestingReductionsRoth = 0;
  let totalEndingBalanceRoth = 0;

  let earnings401kRoth = 0;
  let avarage401kRoth = 0;
  let earningsTotal401kRoth = 0;
  let endBalance401kRoth = 0;

  let firstVarRoth = 0;
  let earningsTotalEmployerRoth = 0;
  let contributions401KTotalRoth = 0;
  let earnings401KTotalRoth = 0;
  let earningsEmployerTotalRoth = 0;
 

  let contributionHsa = 0;
  let earningsHsa = 0;
  let endBalanceHsa = 0;
  let avarageRothIRAHsa = 0;
  let firstRothVarHsa = 0;



  for (year; year < yearLimit; year += 1) {
    /** 401K Personal Comtributions* */
    investmentsData1['401k'][year] = {};
    investmentsData1['Roth'][year] = {};
    investmentsData1['HSA'][year] = {};

    // 401k Employer Comtributions
    investmentsData1['401k Employer Comtributions'][year] = {};
    investmentsData1['Roth Employer Comtributions'][year] = {};
    // let contributionTotalEmployer = 0;
    let earningsTotalEmployer = 0;

    /** Roth IRA * */
    investmentsData1['Roth IRA'][year] = {};

    //Traditional IRA
    investmentsData1['Traditional IRA'][year] = {};

    /** Seph IRA * */
    investmentsData1['Seph IRA'][year] = {};

    /** Pension * */
    investmentsData1.Pension[year] = {};

    let begBalanceInitial401k = 0;
    let begBalanceInitial401kRoth = 0;
    let contributionTotal401k = 0;
    let earningsTotal401k = 0;

    let begBalanceInitialRoth = 0;
    let contributionTotalRoth = 0;
    let earningsTotalRoth = 0;
    let totalBegBalance = 0;

    let begBalanceInitialTraditional = 0;
    let contributionTotalTraditional = 0;
    let earningsTotalTraditional = 0;
    let begBalanceInitialHsa = 0;
    let contributionTotalHsa = 0;
    let earningsTotalHsa = 0;
    earningsTotal401kRoth = 0;


    // Seph IRA
    let begBalanceInitialSeph = 0;
    let contributionTotalSeph = 0;
    let earningsTotalSeph = 0;

    // Pension
    let begBalanceInitialPension = 0;
    let contributionTotalPension = 0;
    let earningsTotalPension = 0;


    for (let month = 1; month <= 12; month += 1) {
      /* 401k */
      investmentsData1['401k'][year][month] = {};
      investmentsData1['Roth'][year][month] = {};
      investmentsData1['HSA'][year][month] = {};

      // Total Beg Balance
      if (module1Data.start_year !== undefined && year === module1Data.start_year && month === 1) {
        totalBegBalance = begBalance401k;
      } else {
        totalBegBalance = totalEndingBalance;
      }
	  investmentsData1['401k'][year][month]['Total Beg Balance'] = totalBegBalance;

      //Roth Total Beg Balance
      if (module1Data.start_year !== undefined && year === module1Data.start_year && month === 1) {
        totalBegBalanceRoth = begBalance401kRoth;
      }
      else {
        totalBegBalanceRoth = totalEndingBalanceRoth;
      }
      investmentsData1['Roth'][year][month]['Total Beg Balance'] = totalBegBalanceRoth;
      // Roth Beg Balance
      investmentsData1['Roth'][year][month]['Beg Balance'] = begBalance401kRoth;
      if (month === 1) {
        begBalanceInitial401kRoth = begBalance401kRoth;
      }
      // Roth Contribution
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Roth 401k'][year] !== undefined && investmentsData['Roth 401k'][year]['Personal Contribution'] !== '') {
        contribution401kRoth = investmentsData['Roth 401k'][year]['Personal Contribution'] / 12;
      }
      else {
        contribution401kRoth = 0;
      }
      investmentsData1['Roth'][year][month]['Contribution'] = contribution401kRoth;
      contributionTotal401kRoth = contributionTotal401kRoth + contribution401kRoth;

      // Roth Earnings
      firstVarRoth = begBalance401kRoth + contribution401kRoth;
      avarage401kRoth = (firstVarRoth + begBalance401kRoth) / 2;
      if (avarage401kRoth < begBalance401kRoth) {
        avarage401kRoth = begBalance401kRoth;
      }

      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Roth 401k'][year] !== undefined && investmentsData['Roth 401k'][year]['Annual Expected Return'] !== '') {
        earnings401kRoth = avarage401kRoth * ((parseFloat(investmentsData['Roth 401k'][year]['Annual Expected Return']) / 100) / 12);
      }
      else {
        earnings401kRoth = 0;
      }

      investmentsData1['Roth'][year][month]['Earnings'] = earnings401kRoth;
      earningsTotal401kRoth = earningsTotal401kRoth + earnings401kRoth;


      // Roth End Balance
      endBalance401kRoth = begBalance401kRoth + contribution401kRoth + earnings401kRoth;
      investmentsData1['Roth'][year][month]['End Balance'] = endBalance401kRoth;
      begBalance401kRoth = endBalance401kRoth;

      ////Roth 401k Employer Contributions
      investmentsData1['Roth Employer Comtributions'][year][month] = {};
      // Roth Beg Balance
      // let begBalanceInitialEmployerRoth = 0;
      investmentsData1['Roth Employer Comtributions'][year][month]['Beg Balance'] = begBalanceEmployerRoth;
      // 
      if (month === 1) {
        let begBalanceInitialEmployerRoth = begBalanceEmployerRoth;
      }
      if (month === 1) {
        investmentsData1['Roth Employer Comtributions'][year][month]['Year'] = year;
      }
      else {
        investmentsData1['Roth Employer Comtributions'][year][month]['Year'] = '';
      }

      //Roth Contribution
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Roth 401k'][year] !== undefined && investmentsData['Roth 401k'][year]['Employer Contribution'] !== '') {
        employerContributionRoth = investmentsData['Roth 401k'][year]['Employer Contribution'] / 12;
      }
      else {
        employerContributionRoth = 0;
      }
      investmentsData1['Roth Employer Comtributions'][year][month]['Contribution'] = employerContributionRoth;
      contributionTotalEmployerRoth = contributionTotalEmployerRoth + employerContributionRoth;

      //Earnings
      employerVarRoth = begBalanceEmployerRoth + employerContributionRoth;
      avarageEmployerRoth = (employerVarRoth + begBalanceEmployerRoth) / 2;
      if (avarageEmployerRoth < begBalanceEmployerRoth) {
        avarageEmployerRoth = begBalanceEmployerRoth;
      }
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Roth 401k'][year] !== undefined && investmentsData['Roth 401k'][year]['Annual Expected Return'] !== '') {
        employerEarningsRoth = avarageEmployerRoth * ((parseFloat(investmentsData['Roth 401k'][year]['Annual Expected Return']) / 100) / 12);
      }
      else {
        employerEarningsRoth = 0;
      }
      investmentsData1['Roth Employer Comtributions'][year][month]['Earnings'] = employerEarningsRoth;
      earningsTotalEmployerRoth = earningsTotalEmployerRoth + employerEarningsRoth;

      //Roth Balance before Vesting
      balanceBeforeVestingRoth = begBalanceEmployerRoth + employerContributionRoth + employerEarningsRoth;
      investmentsData1['Roth Employer Comtributions'][year][month]['Balance before Vesting'] = balanceBeforeVestingRoth;

      //Vesting Reductions
      if (module4Data.end_year !== undefined && module4Data.end_year !== '' && year === module4Data.end_year && month === 12) {
        if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year === module4Data.end_year && module4Data.employerContributionsEarningsNotVested !== undefined && module4Data.employerContributionsEarningsNotVested !== '') {
          vestingReductionsRoth = module4Data.employerContributionsEarningsNotVested;
        }
        else {
          vestingReductionsRoth = 0;
        }
      }
      else {
        if (module5Data.end_year !== undefined && module5Data.end_year !== '' && year === module5Data.end_year && month === 12) {
          if (module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year === module5Data.end_year && module5Data.employerContributionsAndEarningsNotVested !== undefined && module5Data.employerContributionsAndEarningsNotVested !== '') {
            vestingReductionsRoth = module5Data.employerContributionsAndEarningsNotVested;
          }
          else {
            vestingReductionsRoth = 0;
          }
        }
        else {
          if (module8Data.end_year !== undefined && module8Data.end_year !== '' && year === module8Data.end_year && month === 12) {
            if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year === module8Data.end_year && module8Data.employerContributionsAndEarningsNotVestedAdd !== undefined && module8Data.employerContributionsAndEarningsNotVestedAdd !== '') {
              vestingReductionsRoth = module8Data.employerContributionsAndEarningsNotVestedAdd;
            }
            else {
              vestingReductionsRoth = 0;
            }
          }
          else {
            if (module17Data.end_year !== undefined && module17Data.end_year !== '' && year === module17Data.end_year && month === 12) {
              if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year === module17Data.end_year && module17Data.employerContributionsAndEarningsNotVestedFurAdd !== undefined && module17Data.employerContributionsAndEarningsNotVestedFurAdd !== '') {
                vestingReductionsRoth = module17Data.employerContributionsAndEarningsNotVestedFurAdd;
              }
              else {
                vestingReductionsRoth = 0;
              }
            }
            else {
              vestingReductionsRoth = 0;
            }
          }
        }
      }
      investmentsData1['Roth Employer Comtributions'][year][month]['Vesting Reductions'] = vestingReductionsRoth;

      //End Balance
      investmentsData1['Roth Employer Comtributions'][year][month]['End Balance'] = investmentsData1['Roth Employer Comtributions'][year][month]['Balance before Vesting'] + investmentsData1['Roth Employer Comtributions'][year][month]['Vesting Reductions'];
      begBalanceEmployerRoth = investmentsData1['Roth Employer Comtributions'][year][month]['End Balance'];

      //Total Ending Balance
      totalEndingBalanceRoth = investmentsData1['Roth'][year][month]['End Balance'] + investmentsData1['Roth Employer Comtributions'][year][month]['End Balance'];
      investmentsData1['Roth Employer Comtributions'][year][month]['Total Ending Balance'] = totalEndingBalanceRoth;



      // Beg Balance
      investmentsData1['401k'][year][month]['Beg Balance'] = begBalance401k;
      if (month === 1) {
        begBalanceInitial401k = begBalance401k;
      }

      // Contribution
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts 401k'][year] !== undefined
        && investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'] !== '') {
        contribution401k = investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'] / 12;
      } else {
        contribution401k = 0;
      }
      investmentsData1['401k'][year][month].Contribution = contribution401k;
      contributionTotal401k += contribution401k;

      // Earnings
      firstVar = begBalance401k + contribution401k;
      avarage401k = (firstVar + begBalance401k) / 2;
      if (avarage401k < begBalance401k) {
        avarage401k = begBalance401k;
      }
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts 401k'][year] !== undefined
        && investmentsData['Retirement Accounts 401k'][year]['Annual Expected Return'] !== '') {
        earnings401k = avarage401k * ((parseFloat(investmentsData['Retirement Accounts 401k'][year]['Annual Expected Return']) / 100) / 12);
      } else {
        earnings401k = 0;
      }

      investmentsData1['401k'][year][month].Earnings = earnings401k;
      earningsTotal401k += earnings401k;

      // End Balance
      endBalance401k = begBalance401k + contribution401k + earnings401k;
      investmentsData1['401k'][year][month]['End Balance'] = endBalance401k;
      begBalance401k = endBalance401k;

      // Employer Contributions
      investmentsData1['401k Employer Comtributions'][year][month] = {};

      // Beg Balance
      investmentsData1['401k Employer Comtributions'][year][month]['Beg Balance'] = begBalanceEmployer;

      if (month === 1) {
        investmentsData1['401k Employer Comtributions'][year][month].Year = year;
      } else {
        investmentsData1['401k Employer Comtributions'][year][month].Year = '';
      }

      // Contribution
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts 401k'][year] !== undefined
        && investmentsData['Retirement Accounts 401k'][year]['Employer Contribution'] !== '') {
        employerContribution = investmentsData['Retirement Accounts 401k'][year]['Employer Contribution'] / 12;
      } else {
        employerContribution = 0;
      }
      investmentsData1['401k Employer Comtributions'][year][month].Contribution = employerContribution;
      // contributionTotalEmployer += employerContribution;

      // Earnings
      employerVar = begBalanceEmployer + employerContribution;
      avarageEmployer = (employerVar + begBalanceEmployer) / 2;
      if (avarageEmployer < begBalanceEmployer) {
        avarageEmployer = begBalanceEmployer;
      }
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts 401k'][year] !== undefined
        && investmentsData['Retirement Accounts 401k'][year]['Annual Expected Return'] !== '') {
        employerEarnings = avarageEmployer * ((parseFloat(investmentsData['Retirement Accounts 401k'][year]['Annual Expected Return']) / 100) / 12);
      } else {
        employerEarnings = 0;
      }
      investmentsData1['401k Employer Comtributions'][year][month].Earnings = employerEarnings;
      earningsTotalEmployer += employerEarnings;

      // Balance before Vesting
      balanceBeforeVesting = begBalanceEmployer + employerContribution + employerEarnings;
      investmentsData1['401k Employer Comtributions'][year][month]['Balance before Vesting'] = balanceBeforeVesting;

      // Vesting Reductions
      if (module4Data.end_year !== undefined && module4Data.end_year !== '' && year === module4Data.end_year && month === 12) {
        if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.end_year !== undefined
          && module4Data.end_year !== '' && year === module4Data.end_year && module4Data.employerContributionsEarningsNotVested !== undefined && module4Data.employerContributionsEarningsNotVested !== '') {
          vestingReductions = module4Data.employerContributionsEarningsNotVested;
        } else {
          vestingReductions = 0;
        }
      } else {
        if (module5Data.end_year !== undefined && module5Data.end_year !== '' && year === module5Data.end_year && month === 12) {
          if (module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.end_year !== undefined
            && module5Data.end_year !== '' && year === module5Data.end_year && module5Data.employerContributionsAndEarningsNotVested !== undefined
            && module5Data.employerContributionsAndEarningsNotVested !== '') {
            vestingReductions = module5Data.employerContributionsAndEarningsNotVested;
          } else {
            vestingReductions = 0;
          }
        } else {
          if (module8Data.end_year !== undefined && module8Data.end_year !== '' && year === module8Data.end_year && month === 12) {
            if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.end_year !== undefined
              && module8Data.end_year !== '' && year === module8Data.end_year && module8Data.employerContributionsAndEarningsNotVestedAdd !== undefined
              && module8Data.employerContributionsAndEarningsNotVestedAdd !== '') {
              vestingReductions = module8Data.employerContributionsAndEarningsNotVestedAdd;
            } else {
              vestingReductions = 0;
            }
          } else {
            if (module17Data.end_year !== undefined && module17Data.end_year !== '' && year === module17Data.end_year && month === 12) {
              if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.end_year !== undefined
                && module17Data.end_year !== '' && year === module17Data.end_year && module17Data.employerContributionsAndEarningsNotVestedFur !== undefined && module17Data.employerContributionsAndEarningsNotVestedFur !== '') {
                vestingReductions = module17Data.employerContributionsAndEarningsNotVestedFur;
              } else {
                vestingReductions = 0;
              }
            } else {
              vestingReductions = 0;
            }
          }
        }
      }
      investmentsData1['401k Employer Comtributions'][year][month]['Vesting Reductions'] = vestingReductions;

      // End Balance
      investmentsData1['401k Employer Comtributions'][year][month]['End Balance'] = investmentsData1['401k Employer Comtributions'][year][month]['Balance before Vesting'] + investmentsData1['401k Employer Comtributions'][year][month]['Vesting Reductions'];
	  
      begBalanceEmployer = investmentsData1['401k Employer Comtributions'][year][month]['End Balance'];

      // Total Ending Balance
	  totalEndingBalance = investmentsData1['401k'][year][month]['End Balance'] + investmentsData1['401k Employer Comtributions'][year][month]['End Balance'];
      investmentsData1['401k Employer Comtributions'][year][month]['Total Ending Balance'] = totalEndingBalance;
      // Completed

      /* Roth IRA */
      investmentsData1['Roth IRA'][year][month] = {};

      // Beg Balance
      if (module4Data.start_year !== undefined && module4Data.start_year !== '' && year === module4Data.start_year
        && module4Data.roth_beginning_balance !== undefined && module4Data.roth_beginning_balance !== '' && month === 1 && module1Data.start_year === year) {
        begBalanceRoth = module4Data.roth_beginning_balance;
      }

      investmentsData1['Roth IRA'][year][month]['Beg Balance'] = begBalanceRoth;
      if (month === 1) {
        begBalanceInitialRoth = begBalanceRoth;
      }
      // Contribution
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts Roth IRA'] !== undefined
        && investmentsData['Retirement Accounts Roth IRA'][year] !== undefined && investmentsData['Retirement Accounts Roth IRA'][year]['Personal Contribution'] !== '') {
        contributionRoth = investmentsData['Retirement Accounts Roth IRA'][year]['Personal Contribution'] / 12;
      } else {
        contributionRoth = 0;
      }
      investmentsData1['Roth IRA'][year][month].Contribution = contributionRoth;
      contributionTotalRoth += contributionRoth;

      // Earnings
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts Roth IRA'] !== undefined
        && investmentsData['Retirement Accounts Roth IRA'][year] !== undefined && investmentsData['Retirement Accounts Roth IRA'][year]['Annual Pre Tax Expected Return'] !== '') {
        devideRothVal = investmentsData['Retirement Accounts Roth IRA'][year]['Annual Pre Tax Expected Return'];
      }
      if (devideRothVal > 0) {
        firstRothVar = begBalanceRoth + contributionRoth;
        avarageRothIRA = (firstRothVar + begBalanceRoth) / 2;
        earningsRoth = avarageRothIRA * ((parseFloat(devideRothVal) / 100) / 12);
      } else {
        earningsRoth = 0;
      }
      investmentsData1['Roth IRA'][year][month].Earnings = earningsRoth;
      earningsTotalRoth += earningsRoth;

      // End Balance
      endBalanceRoth = begBalanceRoth + contributionRoth + earningsRoth;
      begBalanceRoth = endBalanceRoth;
      investmentsData1['Roth IRA'][year][month]['End Balance'] = endBalanceRoth;

      /* HSA */
      //Beg Balance
      investmentsData1['HSA'][year][month]['Beg Balance'] = begBalanceHsa;

      //Contribution
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts HSA'] !== undefined && investmentsData['Retirement Accounts HSA'][year] !== undefined && investmentsData['Retirement Accounts HSA'][year]['Personal Contribution'] !== '') {
        contributionHsa = investmentsData['Retirement Accounts HSA'][year]['Personal Contribution'] / 12;
      }
      else {
        contributionHsa = 0;
      }
      investmentsData1['HSA'][year][month]['Contribution'] = contributionHsa;
      contributionTotalHsa = contributionTotalHsa + contributionHsa;

      // Earnings
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts HSA'] !== undefined && investmentsData['Retirement Accounts HSA'][year] !== undefined && investmentsData['Retirement Accounts HSA'][year]['Annual Pre Tax Expected Return'] !== '') {
        devideRothValHsa = investmentsData['Retirement Accounts HSA'][year]['Annual Pre Tax Expected Return'];
      }
      if (devideRothValHsa > 0) {
        firstRothVarHsa = begBalanceHsa + contributionHsa;
        avarageRothIRAHsa = (firstRothVarHsa + begBalanceHsa) / 2;
        earningsHsa = avarageRothIRAHsa * ((parseFloat(devideRothValHsa) / 100) / 12);
      } else {
        earningsHsa = 0;
      }
      investmentsData1['HSA'][year][month]['Earnings'] = earningsHsa;
      earningsTotalHsa = earningsTotalHsa + earningsHsa;

      // End Balance
      endBalanceHsa = begBalanceHsa + contributionHsa + earningsHsa;
      begBalanceHsa = endBalanceHsa;
      investmentsData1['HSA'][year][month]['End Balance'] = endBalanceHsa;


      /* Traditional IRA */
      investmentsData1['Traditional IRA'][year][month] = {};

      // Beg Balance
      investmentsData1['Traditional IRA'][year][month]['Beg Balance'] = begBalanceTraditional;
      if (month === 1) {
        begBalanceInitialTraditional = begBalanceTraditional;
      } else {
        begBalanceTraditional = endBalanceTraditional;
      }

      //Contribution
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts Traditional IRA'] !== undefined && investmentsData['Retirement Accounts Traditional IRA'][year] !== undefined && investmentsData['Retirement Accounts Traditional IRA'][year]['Personal Contribution'] !== '') {
        contributionTraditional = investmentsData['Retirement Accounts Traditional IRA'][year]['Personal Contribution'] / 12;
      }
      else {
        contributionTraditional = 0;
      }
      investmentsData1['Traditional IRA'][year][month]['Contribution'] = contributionTraditional;
      contributionTotalTraditional = contributionTotalTraditional + contributionTraditional;

      // Earnings
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts Traditional IRA'] !== undefined && investmentsData['Retirement Accounts Traditional IRA'][year] !== undefined && investmentsData['Retirement Accounts Traditional IRA'][year]['Annual Pre Tax Expected Return'] !== '') {
        devideRothValTraditional = investmentsData['Retirement Accounts Traditional IRA'][year]['Annual Pre Tax Expected Return'];
      }
      if (devideRothValTraditional > 0) {
        firstRothVarTraditional = begBalanceTraditional + contributionTraditional;
        avarageRothIRATraditional = (firstRothVarTraditional + begBalanceTraditional) / 2;
        earningsTraditional = avarageRothIRATraditional * ((parseFloat(devideRothValTraditional) / 100) / 12);
      } else {
        earningsTraditional = 0;
      }
      investmentsData1['Traditional IRA'][year][month]['Earnings'] = earningsTraditional;
      earningsTotalTraditional = earningsTotalTraditional + earningsTraditional;

      // End Balance
      endBalanceTraditional = begBalanceTraditional + contributionTraditional + earningsTraditional;
      begBalanceTraditional = endBalanceTraditional;
      investmentsData1['Traditional IRA'][year][month]['End Balance'] = endBalanceTraditional;




      /* Seph IRA */
      investmentsData1['Seph IRA'][year][month] = {};
      // Beg Balance
      if (typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year === module1Data.start_year && typeof module18Data.existing_sep_ira !== 'undefined' && module18Data.existing_sep_ira !== '' && month === 1 && module1Data.start_year === year) {
        begBalanceSeph = module18Data.existing_sep_ira;
      }


      investmentsData1['Seph IRA'][year][month]['Beg Balance'] = begBalanceSeph;
      if (month === 1) {
        begBalanceInitialSeph = begBalanceSeph;
      }

      // Contribution
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts Seph IRA'] !== undefined
        && investmentsData['Retirement Accounts Seph IRA'][year] !== undefined && investmentsData['Retirement Accounts Seph IRA'][year]['Personal Contribution Final'] !== '') {
        contributionSeph = investmentsData['Retirement Accounts Seph IRA'][year]['Personal Contribution Final'] / 12;
      } else {
        contributionSeph = 0;
      }
      investmentsData1['Seph IRA'][year][month].Contribution = contributionSeph;
      contributionTotalSeph += contributionSeph;

      // Earnings
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts Seph IRA'] !== undefined
        && investmentsData['Retirement Accounts Seph IRA'][year] !== undefined && investmentsData['Retirement Accounts Seph IRA'][year]['Annual Pre Tax Expected Return'] !== '') {
        devideSephVal = investmentsData['Retirement Accounts Seph IRA'][year]['Annual Pre Tax Expected Return'];
      }
      if (devideSephVal > 0) {
        firstSephVar = begBalanceSeph + contributionSeph;
        avarageSephIRA = (firstSephVar + begBalanceSeph) / 2;
        earningsSeph = avarageSephIRA * ((parseFloat(devideSephVal) / 100) / 12);
      } else {
        earningsSeph = 0;
      }
      investmentsData1['Seph IRA'][year][month].Earnings = earningsSeph;
      earningsTotalSeph += earningsSeph;

      // End Balance
      endBalanceSeph = begBalanceSeph + contributionSeph + earningsSeph;
      begBalanceSeph = endBalanceSeph;
      investmentsData1['Seph IRA'][year][month]['End Balance'] = endBalanceSeph;

      /* Pension Arrau */
      investmentsData1.Pension[year][month] = {};

      // Beg Balance
      if (module18Data.existing_pension !== undefined && module18Data.existing_pension !== '' && month === 1 && module1Data.start_year === year) {
        begBalancePension = module18Data.existing_pension;
      }

      investmentsData1.Pension[year][month]['Beg Balance'] = begBalancePension;
      if (month === 1) {
        begBalanceInitialPension = begBalancePension;
      }

      // Contribution
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts Pension'] !== undefined
        && investmentsData['Retirement Accounts Pension'][year] !== undefined && investmentsData['Retirement Accounts Pension'][year]['Total Contribution'] !== '') {
        contributionPension = investmentsData['Retirement Accounts Pension'][year]['Total Contribution'] / 12;
      } else {
        contributionPension = 0;
      }
      investmentsData1.Pension[year][month].Contribution = contributionPension;
      contributionTotalPension += contributionPension;

      // Earnings
      if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts Pension'] !== undefined
        && investmentsData['Retirement Accounts Pension'][year] !== undefined && investmentsData['Retirement Accounts Pension'][year]['Annual Pre Tax Expected Return'] !== '') {
        devidePensionVal = investmentsData['Retirement Accounts Pension'][year]['Annual Pre Tax Expected Return'];
      }
      if (devidePensionVal > 0) {
        firstPensionVar = begBalancePension + contributionPension;
        avaragePensionIRA = (firstPensionVar + begBalancePension) / 2;
        earningsPension = avaragePensionIRA * ((parseFloat(devidePensionVal) / 100) / 12);
      } else {
        earningsPension = 0;
      }
      investmentsData1.Pension[year][month].Earnings = earningsPension;
      earningsTotalPension += earningsPension;

      // End Balance
      endBalancePension = begBalancePension + contributionPension + earningsPension;
      begBalancePension = endBalancePension;
      investmentsData1.Pension[year][month]['End Balance'] = endBalancePension;
    }


    // Beg Balance HSA
    investmentsData1['HSA'][year]['Beg Balance'] = begBalanceInitialHsa;

    // Contribution Value Roth IRA
    investmentsData1['HSA'][year]['Contribution'] = contributionTotalHsa;
    if (k <= 14) {
      HsaContributionsTotal = HsaContributionsTotal + investmentsData1['HSA'][year]['Contribution'];
    }

    // Earnings Value  Roth IRA
    investmentsData1['HSA'][year]['Earnings'] = earningsTotalHsa;
    if (k <= 14) {
      earningsHsaTotal = earningsHsaTotal + investmentsData1['HSA'][year]['Earnings'];
    }

    // Beg Balance Traditional IRA
    investmentsData1['Traditional IRA'][year]['Beg Balance'] = begBalanceInitialTraditional;

    // Contribution Value Roth IRA
    investmentsData1['Traditional IRA'][year]['Contribution'] = contributionTotalTraditional;
    if (k <= 14) {
      TraditionalContributionsTotal = TraditionalContributionsTotal + investmentsData1['Traditional IRA'][year]['Contribution'];
    }

    // Earnings Value  Roth IRA
    investmentsData1['Traditional IRA'][year]['Earnings'] = earningsTotalTraditional;
    if (k <= 14) {
      earningsTraditionalTotal = earningsTraditionalTotal + investmentsData1['Traditional IRA'][year]['Earnings'];
    }



    //Roth Beg Balance
    investmentsData1['Roth'][year]['Beg Balance'] = begBalanceInitial401kRoth;

    // Contribution Value 401k
    investmentsData1['Roth'][year]['Contribution'] = contributionTotal401kRoth;
    if (k <= 14) {
      contributions401KTotalRoth = contributions401KTotalRoth + investmentsData1['Roth'][year]['Contribution'];
    }

    // Earnings Value  401k
    investmentsData1['Roth'][year]['Earnings'] = earningsTotal401kRoth;
    if (k <= 14) {
      earnings401KTotalRoth = earnings401KTotalRoth + investmentsData1['Roth'][year]['Earnings'];
    }

    // Earnings Value  401k Employer Comtributions
    investmentsData1['Roth Employer Comtributions'][year]['Earnings'] = earningsTotalEmployerRoth;
    if (k <= 14) {
      earningsEmployerTotalRoth = earningsEmployerTotalRoth + investmentsData1['Roth Employer Comtributions'][year]['Earnings'];
    }

    // End balance 401k
    
    
    investmentsData1['Roth'][year]['End Balance'] = parseFloat(investmentsData1['Roth'][year]['Beg Balance']) +
      parseFloat(investmentsData1['Roth'][year]['Contribution']) +
      parseFloat(investmentsData1['Roth'][year]['Earnings']);
     
    if (k === 14) {
      let endingBalance401kLastValRoth = investmentsData1['Roth'][year]['End Balance'];
    }



    /** 401k * */
    // Beg balance  401k
    investmentsData1['401k'][year]['Beg Balance'] = begBalanceInitial401k;

    // Contribution Value 401k
    investmentsData1['401k'][year].Contribution = contributionTotal401k;
    if (k <= 14) {
      contributions401KTotal += investmentsData1['401k'][year].Contribution;
    }

    // Earnings Value  401k
    investmentsData1['401k'][year].Earnings = earningsTotal401k;
    if (k <= 14) {
      earnings401KTotal += investmentsData1['401k'][year].Earnings;
    }

    // Earnings Value  401k Employer Comtributions
    investmentsData1['401k Employer Comtributions'][year].Earnings = earningsTotalEmployer;
    // if ( k <= 14 ) {
    //   earningsEmployerTotal += investmentsData1['401k Employer Comtributions'][year].Earnings;
    // }

    // End balance 401k
    investmentsData1['401k'][year]['End Balance'] = parseFloat(investmentsData1['401k'][year]['Beg Balance'])
      + parseFloat(investmentsData1['401k'][year].Contribution)
      + parseFloat(investmentsData1['401k'][year].Earnings);
    if (k === 14) {
      endingBalance401kLastVal = investmentsData1['401k'][year]['End Balance'];
    }

    /** Roth IRA * */
    // Beg Balance Roth IRA
    investmentsData1['Roth IRA'][year]['Beg Balance'] = begBalanceInitialRoth;

    // Contribution Value Roth IRA
    investmentsData1['Roth IRA'][year].Contribution = contributionTotalRoth;
    if (k <= 14) {
      rothContributionsTotal += investmentsData1['Roth IRA'][year].Contribution;
    }

    // Earnings Value  Roth IRA
    investmentsData1['Roth IRA'][year].Earnings = earningsTotalRoth;
    if (k <= 14) {
      earningsRothTotal += investmentsData1['Roth IRA'][year].Earnings;
    }

    /** Seph IRA * */
    // Beg Balance Roth IRA
    investmentsData1['Seph IRA'][year]['Beg Balance'] = begBalanceInitialSeph;

    // Contribution Value Seph IRA
    investmentsData1['Seph IRA'][year].Contribution = contributionTotalSeph;
    // if ( k <= 14 ) {
    //   sephContributionsTotal += investmentsData1['Seph IRA'][year].Contribution;
    // }

    // Earnings Value  Seph IRA
    investmentsData1['Seph IRA'][year].Earnings = earningsTotalSeph;
    // if ( k <= 14 ) {
    //   earningsSephTotal += investmentsData1['Seph IRA'][year].Earnings;
    // }

    /** Pension * */
    // Beg Balance Pension
    investmentsData1.Pension[year]['Beg Balance'] = begBalanceInitialPension;

    // Contribution Value Pension
    investmentsData1.Pension[year].Contribution = contributionTotalPension;
    // if ( k <= 14 ) {
    //   pensionContributionsTotal += investmentsData1.Pension[year].Contribution;
    // }

    // Earnings Value  Pension
    investmentsData1.Pension[year].Earnings = earningsTotalPension;
    // if ( k <= 14 ) {
    //   earningsPensionTotal += investmentsData1.Pension[year].Earnings;
    // }

    // End balance 401k
    investmentsData1['Roth IRA'][year]['End Balance'] = parseFloat(investmentsData1['Roth IRA'][year]['Beg Balance'])
      + parseFloat(investmentsData1['Roth IRA'][year].Contribution)
      + parseFloat(investmentsData1['Roth IRA'][year].Earnings);
    if (k === 14) {
      rothEndingBalanceLastVal = investmentsData1['Roth IRA'][year]['End Balance'];
    }

    k += 1;
  }

  // 401k Add to show me Graphs Values
  year = parseInt(module1Data.start_year, 10);
  yearLimit = parseInt(module1Data.start_year, 10) + 30;
  let totalBeginningBalance = 0;
  let beginningPersonalBalance = 0;
  let personalContribution = 0;
  let personalEarnings = 0;
  
  // let personalPercentagContribution = 0;
  // for employer
  let beginningEmployerBalance = 0;
  let employerContributionValue = 0;
  let employerEarningsVal = 0;
  let employeBalBeforeVestingReductions = 0;
  let vestingReductionsValue = 0;
  let endingEmployerBalance = 0;
  let endingTotalBalance = 0;

  // Roth IRA
  let rothIRABeginningBalance = 0;
  let rothIRAAnnualContribution = 0;
  let rothIRAAnnualEarnings = 0;

  let rothIRABeginningBalanceTraditional = 0;
  let rothIRAAnnualContributionTraditional = 0;
  let rothIRAAnnualEarningsTraditional = 0;

  // Seph IRA
  let sephIRABeginningBalance = 0;
  let sephIRAAnnualContribution = 0;
  let sephIRAAnnualEarnings = 0;

  // Pension
  let pensionIRABeginningBalance = 0;
  let pensionIRAAnnualEarnings = 0;
  let pensionEmployerContributionVal = 0;
  let pensionPersonalContributionVal = 0;
  let pensionEndingBalanceVal = 0;

  let totalBeginningBalanceRoth = 0;
  let beginningPersonalBalanceRoth = 0;
  let personalContributionRoth = 0;
  let personalEarningsRoth = 0;
  let beginningEmployerBalanceRoth = 0;
  let employerContributionValueRoth = 0;
  let employerEarningsValRoth = 0;
  let employeBalBeforeVestingReductionsRoth = 0;
  let vestingReductionsValueRoth = 0;
  let endingEmployerBalanceRoth = 0;
  let endingTotalBalanceRoth = 0;

  let rothIRABeginningBalanceHsa = 0;
  let rothIRAAnnualContributionHsa = 0;
  let rothIRAAnnualEarningsHsa = 0;


  for (year; year < yearLimit; year += 1) {
    /** 401K Personal Comtributions* */
    investmentsData['Retirement Accounts 401k Second'][year] = {};
    investmentsData['Roth 401k Second'][year] = {};

    //Total Beginning Balance
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Roth'] !== undefined && investmentsData1['Roth'][year] !== undefined && investmentsData1['Roth'][year][1]['Total Beg Balance'] !== undefined && investmentsData1['Roth'][year][1]['Total Beg Balance'] !== '') {
      totalBeginningBalanceRoth = investmentsData1['Roth'][year][1]['Total Beg Balance'];
    } else {
      totalBeginningBalanceRoth = 0;
    }
    investmentsData['Roth 401k Second'][year]['Total Beginning Balance'] = totalBeginningBalanceRoth;

    //Beginning Personal Balance
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Roth'] !== undefined && investmentsData1['Roth'][year] !== undefined && investmentsData1['Roth'][year][1]['Beg Balance'] !== undefined && investmentsData1['Roth'][year][1]['Beg Balance'] !== '') {
      beginningPersonalBalanceRoth = investmentsData1['Roth'][year][1]['Beg Balance'];
    } else {
      beginningPersonalBalanceRoth = 0;
    }
    investmentsData['Roth 401k Second'][year]['Beginning Personal Balance'] = beginningPersonalBalanceRoth;

    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Roth'] !== undefined && investmentsData1['Roth'][year] !== undefined && investmentsData1['Roth'][year] !== '' && investmentsData1['Roth'][year][1]['Contribution'] !== '') {
      personalContributionRoth = investmentsData1['Roth'][year][1]['Contribution'] * 12;
    } else {
      personalContributionRoth = 0;
    }
    investmentsData['Roth 401k Second'][year]['Personal Contribution'] = personalContributionRoth;

    //Personal Earnings
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Roth'] !== undefined && investmentsData1['Roth'][year] !== undefined && investmentsData1['Roth'][year] !== '') {
      personalEarningsRoth = investmentsData1['Roth'][year]['Earnings'];
    } else {
      personalEarningsRoth = 0;
    }
    investmentsData['Roth 401k Second'][year]['Personal Earnings'] = personalEarningsRoth;

    //Ending Personal balance
    investmentsData['Roth 401k Second'][year]['Ending Personal Earnings'] = investmentsData['Roth 401k Second'][year]['Beginning Personal Balance'] + investmentsData['Roth 401k Second'][year]['Personal Contribution'] + investmentsData['Roth 401k Second'][year]['Personal Earnings'];

    //Beginning Employer Balance
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Roth Employer Comtributions'] !== undefined && investmentsData1['Roth Employer Comtributions'][year] !== undefined && investmentsData1['Roth Employer Comtributions'][year][1]['Beg Balance'] !== '' && investmentsData1['Roth Employer Comtributions'][year][1]['Beg Balance'] !== '') {
      beginningEmployerBalanceRoth = investmentsData1['Roth Employer Comtributions'][year][1]['Beg Balance'];
    } else {
      beginningEmployerBalanceRoth = 0;
    }
    investmentsData['Roth 401k Second'][year]['Beginning Employer Balance'] = beginningEmployerBalanceRoth;

    //Employer Contribution
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Roth Employer Comtributions'] !== undefined && investmentsData1['Roth Employer Comtributions'][year] !== undefined && investmentsData1['Roth Employer Comtributions'][year] !== '' && investmentsData1['Roth Employer Comtributions'][year][1]['Contribution'] !== '') {
      employerContributionValueRoth = parseFloat(investmentsData1['Roth Employer Comtributions'][year][1]['Contribution']) * 12;
    } else {
      employerContributionValueRoth = 0;
    }
    investmentsData['Roth 401k Second'][year]['Employer Contribution'] = employerContributionValueRoth;
    //Employer Earnings
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Roth Employer Comtributions'] !== undefined && investmentsData1['Roth Employer Comtributions'][year] !== undefined && investmentsData1['Roth Employer Comtributions'][year] !== '' && investmentsData1['Roth Employer Comtributions'][year]['Earnings'] !== '') {
      employerEarningsValRoth = investmentsData1['Roth Employer Comtributions'][year]['Earnings'];
    }
    investmentsData['Roth 401k Second'][year]['Employer Earnings'] = employerEarningsValRoth;

    //Employer Balance before Vesting Reductions
    employeBalBeforeVestingReductionsRoth = beginningEmployerBalanceRoth + employerContributionValueRoth + employerEarningsValRoth;
    investmentsData['Roth 401k Second'][year]['Employer Balance before Vesting Reductions'] = employeBalBeforeVestingReductionsRoth;

    //Vesting Reductions
    if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year === module4Data.end_year && module4Data.employerContributionsEarningsNotVested !== undefined && module4Data.employerContributionsEarningsNotVested !== '') {
      vestingReductionsValueRoth = module4Data.employerContributionsEarningsNotVested;
    }
    else {
      if (module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year === module5Data.end_year && module5Data.employerContributionsAndEarningsNotVested !== undefined && module5Data.employerContributionsAndEarningsNotVested !== '') {
        vestingReductionsValueRoth = module5Data.employerContributionsAndEarningsNotVested;
      }
      else {
        if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year === module8Data.end_year && module8Data.employerContributionsAndEarningsNotVestedAdd !== undefined && module8Data.employerContributionsAndEarningsNotVestedAdd !== '') {
          vestingReductionsValueRoth = module8Data.employerContributionsAndEarningsNotVestedAdd;
        }
        else {
          if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year === module17Data.end_year && module17Data.employerContributionsAndEarningsNotVestedFur !== undefined && module17Data.employerContributionsAndEarningsNotVestedFur !== '') {
            vestingReductionsValueRoth = module17Data.employerContributionsAndEarningsNotVestedFur;
          }
          else {
            vestingReductionsValueRoth = 0;
          }
        }
      }
    }
    investmentsData['Roth 401k Second'][year]['Vesting Reductions'] = vestingReductionsValueRoth;

    //Ending Employer Balance
    endingEmployerBalanceRoth = investmentsData['Roth 401k Second'][year]['Employer Balance before Vesting Reductions'] +
      investmentsData['Roth 401k Second'][year]['Vesting Reductions'];
    investmentsData['Roth 401k Second'][year]['Ending Employer Balance'] = endingEmployerBalanceRoth;

    //Ending Total Balance
    endingTotalBalanceRoth = investmentsData['Roth 401k Second'][year]['Ending Personal Earnings'] + investmentsData['Roth 401k Second'][year]['Ending Employer Balance'];
    investmentsData['Roth 401k Second'][year]['Ending Total Balance'] = endingTotalBalanceRoth;


    // Total Beginning Balance
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['401k'] !== undefined
      && investmentsData1['401k'][year] !== undefined && investmentsData1['401k'][year][1]['Total Beg Balance'] !== undefined
      && investmentsData1['401k'][year][1]['Total Beg Balance'] !== '') {
      totalBeginningBalance = investmentsData1['401k'][year][1]['Total Beg Balance'];
    } else {
      totalBeginningBalance = 0;
    }
    investmentsData['Retirement Accounts 401k Second'][year]['Total Beginning Balance'] = totalBeginningBalance;

    // Beginning Personal Balance
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['401k'] !== undefined
      && investmentsData1['401k'][year] !== undefined && investmentsData1['401k'][year][1]['Beg Balance'] !== undefined
      && investmentsData1['401k'][year][1]['Beg Balance'] !== '') {
      beginningPersonalBalance = investmentsData1['401k'][year][1]['Beg Balance'];
    } else {
      beginningPersonalBalance = 0;
    }
    investmentsData['Retirement Accounts 401k Second'][year]['Beginning Personal Balance'] = beginningPersonalBalance;

    // Personal Contribution
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['401k'] !== undefined
      && investmentsData1['401k'][year] !== undefined && investmentsData1['401k'][year] !== ''
      && investmentsData1['401k'][year][1].Contribution !== '') {
      personalContribution = investmentsData1['401k'][year][1].Contribution * 12;
    } else {
      personalContribution = 0;
    }
    investmentsData['Retirement Accounts 401k Second'][year]['Personal Contribution'] = personalContribution;

    // Personal Earnings
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['401k'] !== undefined
      && investmentsData1['401k'][year] !== undefined && investmentsData1['401k'][year] !== '') {
      personalEarnings = investmentsData1['401k'][year].Earnings;
    } else {
      personalEarnings = 0;
    }
    investmentsData['Retirement Accounts 401k Second'][year]['Personal Earnings'] = personalEarnings;

    // Ending Personal balance
    investmentsData['Retirement Accounts 401k Second'][year]['Ending Personal Earnings'] = investmentsData['Retirement Accounts 401k Second'][year]['Beginning Personal Balance'] + investmentsData['Retirement Accounts 401k Second'][year]['Personal Contribution'] + investmentsData['Retirement Accounts 401k Second'][year]['Personal Earnings'];

    // Beginning Employer Balance
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['401k Employer Comtributions'] !== undefined
      && investmentsData1['401k Employer Comtributions'][year] !== undefined
      && investmentsData1['401k Employer Comtributions'][year][1]['Beg Balance'] !== ''
      && investmentsData1['401k Employer Comtributions'][year][1]['Beg Balance'] !== '') {
      beginningEmployerBalance = investmentsData1['401k Employer Comtributions'][year][1]['Beg Balance'];
    } else {
      beginningEmployerBalance = 0;
    }
    investmentsData['Retirement Accounts 401k Second'][year]['Beginning Employer Balance'] = beginningEmployerBalance;

    // Employer Contribution
    if (investmentsData1 !== undefined && investmentsData1 !== ''
      && investmentsData1['401k Employer Comtributions'] !== undefined && investmentsData1['401k Employer Comtributions'][year] !== undefined
      && investmentsData1['401k Employer Comtributions'][year] !== '' && investmentsData1['401k Employer Comtributions'][year][1].Contribution !== '') {
      employerContributionValue = parseFloat(investmentsData1['401k Employer Comtributions'][year][1].Contribution) * 12;
    } else {
      employerContributionValue = 0;
    }
    investmentsData['Retirement Accounts 401k Second'][year]['Employer Contribution'] = employerContributionValue;

    // Employer Earnings
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['401k Employer Comtributions'] !== undefined
      && investmentsData1['401k Employer Comtributions'][year] !== undefined && investmentsData1['401k Employer Comtributions'][year] !== ''
      && investmentsData1['401k Employer Comtributions'][year].Earnings !== '') {
      employerEarningsVal = investmentsData1['401k Employer Comtributions'][year].Earnings;
    }
    investmentsData['Retirement Accounts 401k Second'][year]['Employer Earnings'] = employerEarningsVal;

    // Employer Balance before Vesting Reductions
    employeBalBeforeVestingReductions = beginningEmployerBalance + employerContributionValue + employerEarningsVal;
    investmentsData['Retirement Accounts 401k Second'][year]['Employer Balance before Vesting Reductions'] = employeBalBeforeVestingReductions;

    // Vesting Reductions
    if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes'
      && module4Data.end_year !== undefined && module4Data.end_year !== '' && year === module4Data.end_year
      && module4Data.employerContributionsEarningsNotVested !== undefined && module4Data.employerContributionsEarningsNotVested !== '') {
      vestingReductionsValue = module4Data.employerContributionsEarningsNotVested;
    } else {
		if (module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year === module5Data.end_year
      && module5Data.employerContributionsAndEarningsNotVested !== undefined && module5Data.employerContributionsAndEarningsNotVested !== '') {
		  vestingReductionsValue = module5Data.employerContributionsAndEarningsNotVested;
		} else {
			if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.end_year !== undefined
		  && module8Data.end_year !== '' && year === module8Data.end_year && module8Data.employerContributionsAndEarningsNotVestedAdd !== undefined
		  && module8Data.employerContributionsAndEarningsNotVestedAdd !== '') {
		  vestingReductionsValue = module8Data.employerContributionsAndEarningsNotVestedAdd;
			} else {
				if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.end_year !== undefined
			  && module17Data.end_year !== '' && year === module17Data.end_year
			  && module17Data.employerContributionsAndEarningsNotVestedFur !== undefined
			  && module17Data.employerContributionsAndEarningsNotVestedFur !== '') {
			  vestingReductionsValue = module17Data.employerContributionsAndEarningsNotVestedFur;
				} else {
				  vestingReductionsValue = 0;
				}
			}
		}
	}
    investmentsData['Retirement Accounts 401k Second'][year]['Vesting Reductions'] = vestingReductionsValue;

    // Ending Employer Balance
    endingEmployerBalance = investmentsData['Retirement Accounts 401k Second'][year]['Employer Balance before Vesting Reductions']
      + investmentsData['Retirement Accounts 401k Second'][year]['Vesting Reductions'];
    investmentsData['Retirement Accounts 401k Second'][year]['Ending Employer Balance'] = endingEmployerBalance;

    // Ending Total Balance
    endingTotalBalance = investmentsData['Retirement Accounts 401k Second'][year]['Ending Personal Earnings'] + investmentsData['Retirement Accounts 401k Second'][year]['Ending Employer Balance'];
    investmentsData['Retirement Accounts 401k Second'][year]['Ending Total Balance'] = endingTotalBalance;

    /** ******
        Retirement Account Roth IRA
        ******** */
    investmentsData['Retirement Accounts Roth IRA Second'][year] = {};

    // Beginning Balance
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Roth IRA'] !== undefined
      && investmentsData1['Roth IRA'][year] !== undefined && investmentsData1['Roth IRA'][year][1]['Beg Balance'] !== undefined
      && investmentsData1['Roth IRA'][year][1]['Beg Balance'] !== '') {
      rothIRABeginningBalance = investmentsData1['Roth IRA'][year][1]['Beg Balance'];
    } else {
      rothIRABeginningBalance = 0;
    }
    investmentsData['Retirement Accounts Roth IRA Second'][year]['Beginning Balance'] = rothIRABeginningBalance;

    // Annual Contribution
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Roth IRA'] !== undefined
      && investmentsData1['Roth IRA'][year] !== undefined && investmentsData1['Roth IRA'][year][1].Contribution !== undefined
      && investmentsData1['Roth IRA'][year][1].Contribution !== '') {
      rothIRAAnnualContribution = investmentsData1['Roth IRA'][year][1].Contribution * 12;
    } else {
      rothIRAAnnualContribution = 0;
    }
    investmentsData['Retirement Accounts Roth IRA Second'][year]['Annual Contribution'] = rothIRAAnnualContribution;

    // Earnings Value
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Roth IRA'] !== undefined
      && investmentsData1['Roth IRA'][year] !== undefined && investmentsData1['Roth IRA'][year].Earnings !== undefined
      && investmentsData1['Roth IRA'][year].Earnings !== '') {
      rothIRAAnnualEarnings = investmentsData1['Roth IRA'][year].Earnings;
    } else {
      rothIRAAnnualEarnings = 0;
    }
    investmentsData['Retirement Accounts Roth IRA Second'][year].Earnings = rothIRAAnnualEarnings;

    // Ending Balance
    investmentsData['Retirement Accounts Roth IRA Second'][year]['Ending Balance'] = rothIRABeginningBalance + rothIRAAnnualContribution + rothIRAAnnualEarnings;

    // HSA Second
    investmentsData['HSA Second'][year] = {};
    //Beginning Balance
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['HSA'] !== undefined && investmentsData1['HSA'][year] !== undefined && investmentsData1['HSA'][year][1]['Beg Balance'] !== undefined && investmentsData1['HSA'][year][1]['Beg Balance'] !== '') {
      rothIRABeginningBalanceHsa = investmentsData1['HSA'][year][1]['Beg Balance'];
    } else {
      rothIRABeginningBalanceHsa = 0;
    }
    investmentsData['HSA Second'][year]['Beginning Balance'] = rothIRABeginningBalanceHsa;

    //Annual Contribution
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['HSA'] !== undefined && investmentsData1['HSA'][year] !== undefined && investmentsData1['HSA'][year][1]['Contribution'] !== undefined && investmentsData1['HSA'][year][1]['Contribution'] !== '') {
      rothIRAAnnualContributionHsa = investmentsData1['HSA'][year][1]['Contribution'] * 12;
    }
    else {
      rothIRAAnnualContributionHsa = 0;
    }
    investmentsData['HSA Second'][year]['Annual Contribution'] = rothIRAAnnualContributionHsa;

    //Earnings Value
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['HSA'] !== undefined && investmentsData1['HSA'][year] !== undefined && investmentsData1['HSA'][year]['Earnings'] !== undefined && investmentsData1['HSA'][year]['Earnings'] !== '') {
      rothIRAAnnualEarningsHsa = investmentsData1['HSA'][year]['Earnings'];
    }
    else {
      rothIRAAnnualEarningsHsa = 0;
    }
    investmentsData['HSA Second'][year]['Earnings'] = rothIRAAnnualEarningsHsa;

    //Ending Balance
    investmentsData['HSA Second'][year]['Ending Balance'] = rothIRABeginningBalanceHsa + rothIRAAnnualContributionHsa + rothIRAAnnualEarningsHsa;

    //Traditional IRA 
    investmentsData['Retirement Accounts Traditional IRA Second'][year] = {};
    //Beginning Balance
    if (investmentsData1 !== 'undefined' && investmentsData1 !== '' && investmentsData1['Traditional IRA'] !== 'undefined' && investmentsData1['Traditional IRA'][year] !== 'undefined' && investmentsData1['Traditional IRA'][year][1]['Beg Balance'] !== 'undefined' && investmentsData1['Traditional IRA'][year][1]['Beg Balance'] !== '') {
      rothIRABeginningBalanceTraditional = investmentsData1['Traditional IRA'][year][1]['Beg Balance'];
    } else {
      rothIRABeginningBalanceTraditional = 0;
    }
    investmentsData['Retirement Accounts Traditional IRA Second'][year]['Beginning Balance'] = rothIRABeginningBalanceTraditional;

    //Annual Contribution
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Traditional IRA'] !== undefined && investmentsData1['Traditional IRA'][year] !== undefined && investmentsData1['Traditional IRA'][year][1]['Contribution'] !== undefined && investmentsData1['Traditional IRA'][year][1]['Contribution'] !== '') {
      rothIRAAnnualContributionTraditional = investmentsData1['Traditional IRA'][year][1]['Contribution'] * 12;
    }
    else {
      rothIRAAnnualContributionTraditional = 0;
    }
    investmentsData['Retirement Accounts Traditional IRA Second'][year]['Annual Contribution'] = rothIRAAnnualContributionTraditional;

    //Earnings Value
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Traditional IRA'] !== undefined && investmentsData1['Traditional IRA'][year] !== undefined && investmentsData1['Traditional IRA'][year]['Earnings'] !== undefined && investmentsData1['Traditional IRA'][year]['Earnings'] !== '') {
      rothIRAAnnualEarningsTraditional = investmentsData1['Traditional IRA'][year]['Earnings'];
    }
    else {
      rothIRAAnnualEarningsTraditional = 0;
    }
    investmentsData['Retirement Accounts Traditional IRA Second'][year]['Earnings'] = rothIRAAnnualEarningsTraditional;


    investmentsData['Retirement Accounts Traditional IRA Second'][year]['Ending Balance'] = rothIRABeginningBalanceTraditional + rothIRAAnnualContributionTraditional + rothIRAAnnualEarningsTraditional;



    /** ******
        Retirement Account Sep IRA
        ******** */
    investmentsData['Retirement Accounts Seph IRA Second'][year] = {};

    // Beginning Balance
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Seph IRA'] !== undefined
      && investmentsData1['Seph IRA'][year] !== undefined && investmentsData1['Seph IRA'][year][1]['Beg Balance'] !== undefined
      && investmentsData1['Seph IRA'][year][1]['Beg Balance'] !== '') {
      sephIRABeginningBalance = investmentsData1['Seph IRA'][year][1]['Beg Balance'];
    }
    investmentsData['Retirement Accounts Seph IRA Second'][year]['Beginning Balance'] = sephIRABeginningBalance;

    // Annual Contribution
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Seph IRA'] !== undefined
      && investmentsData1['Seph IRA'][year] !== undefined && investmentsData1['Seph IRA'][year][1].Contribution !== undefined
      && investmentsData1['Seph IRA'][year][1].Contribution !== '') {
      sephIRAAnnualContribution = investmentsData1['Seph IRA'][year][1].Contribution * 12;
    } else {
      sephIRAAnnualContribution = 0;
    }
    investmentsData['Retirement Accounts Seph IRA Second'][year]['Annual Contribution'] = sephIRAAnnualContribution;

    // Earnings Value
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1['Seph IRA'] !== undefined
      && investmentsData1['Seph IRA'][year] !== undefined && investmentsData1['Seph IRA'][year].Earnings !== undefined
      && investmentsData1['Seph IRA'][year].Earnings !== '') {
      sephIRAAnnualEarnings = investmentsData1['Seph IRA'][year].Earnings;
    } else {
      sephIRAAnnualEarnings = 0;
    }
    investmentsData['Retirement Accounts Seph IRA Second'][year].Earnings = sephIRAAnnualEarnings;

    // Ending Balance
    investmentsData['Retirement Accounts Seph IRA Second'][year]['Ending Balance'] = sephIRABeginningBalance + sephIRAAnnualContribution + sephIRAAnnualEarnings;

    /** ******
        Retirement Account Pension
        ******** */
    investmentsData['Retirement Accounts Pension Second'][year] = {};
    // Beginning Balance
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1.Pension !== undefined
      && investmentsData1.Pension[year] !== undefined && investmentsData1.Pension[year][1]['Beg Balance'] !== undefined
      && investmentsData1.Pension[year][1]['Beg Balance'] !== '') {
      pensionIRABeginningBalance = investmentsData1.Pension[year][1]['Beg Balance'];
    }
    investmentsData['Retirement Accounts Pension Second'][year]['Beginning Balance'] = pensionIRABeginningBalance;

    // Employer Contribution
    if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts Pension'] !== undefined
      && investmentsData['Retirement Accounts Pension'][year] !== undefined && investmentsData['Retirement Accounts Pension'][year]['Employer Contribution'] !== undefined
      && investmentsData['Retirement Accounts Pension'][year]['Employer Contribution'] !== '') {
      pensionEmployerContributionVal = investmentsData['Retirement Accounts Pension'][year]['Employer Contribution'];
    } else {
      pensionEmployerContributionVal = 0;
    }
    investmentsData['Retirement Accounts Pension Second'][year]['Employer Contribution'] = pensionEmployerContributionVal;

    // Personal Contribution
    if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts Pension'] !== undefined
      && investmentsData['Retirement Accounts Pension'][year] !== undefined && investmentsData['Retirement Accounts Pension'][year]['Personal Contribution'] !== undefined
      && investmentsData['Retirement Accounts Pension'][year]['Personal Contribution'] !== '') {
      pensionPersonalContributionVal = investmentsData['Retirement Accounts Pension'][year]['Personal Contribution'];
    } else {
      pensionPersonalContributionVal = 0;
    }
    investmentsData['Retirement Accounts Pension Second'][year]['Personal Contribution'] = pensionPersonalContributionVal;

    // Earnings
    if (investmentsData1 !== undefined && investmentsData1 !== '' && investmentsData1.Pension !== undefined
      && investmentsData1.Pension[year] !== undefined && investmentsData1.Pension[year].Earnings !== undefined
      && investmentsData1.Pension[year].Earnings !== '') {
      pensionIRAAnnualEarnings = investmentsData1.Pension[year].Earnings;
    } else {
      pensionIRAAnnualEarnings = 0;
    }
    investmentsData['Retirement Accounts Pension Second'][year].Earnings = pensionIRAAnnualEarnings;

    // Ending Balance
    pensionEndingBalanceVal = pensionIRABeginningBalance + pensionEmployerContributionVal + pensionPersonalContributionVal + pensionIRAAnnualEarnings;
    investmentsData['Retirement Accounts Pension Second'][year]['Ending Balance'] = pensionEndingBalanceVal;
  }

  // To return Student Loan Complete Data
  function investmentsCompleteData() {
    const investmentsInfo = {};
    investmentsInfo['Short-Term Investments'] = investmentsData['Short-Term Investments'];
    investmentsInfo['Short-Term Earnings Total'] = shortTermEarningsTotal;
    investmentsInfo['Short-Term Cash Contributions Total'] = shortTermCashContributionsTotal;
    investmentsInfo['Short-Term Cash Transfer Total'] = shortTermCashTransferTotal;
    investmentsInfo['Short-Term Contributions Total'] = shortTermContributionsTotal;
    investmentsInfo['Short-Term Excess Total'] = shortTermExcessTotal;
    investmentsInfo['Short-Term Ending Balance Last Val'] = shortTermEndingBalanceLastVal;

    investmentsInfo['Long-Term Investments'] = investmentsData['Long-Term Investments'];
    investmentsInfo['Long-Term Earnings Total'] = longTermEarningsTotal;
    investmentsInfo['Long-Term Cash Contributions Total'] = longTermCashContributionsTotal;
    investmentsInfo['Long-Term Cash Transfer Total'] = longTermCashTransferTotal;
    investmentsInfo['Long-Term Contributions Total'] = longTermContributionsTotal;
    investmentsInfo['Long-Term Ending Balance Last Val'] = longTermEndingBalanceLastVal;

    investmentsInfo['401k'] = investmentsData1['401k'];
    investmentsInfo['401k Contributions Total'] = contributions401KTotal;
    investmentsInfo['401k Earnings Total'] = earnings401KTotal;
    investmentsInfo['401k Ending Balance Last Val'] = endingBalance401kLastVal;
    investmentsInfo['401k Employer Comtributions'] = investmentsData1['401k Employer Comtributions'];

    // 401k Retirements Accounts
    investmentsInfo['Retirement Accounts 401k'] = investmentsData['Retirement Accounts 401k'];
    investmentsInfo['401k Second'] = investmentsData['Retirement Accounts 401k Second'];
    investmentsInfo['Total Annual PreTax Expected Return'] = totalAnnualExpectedReturn;

    investmentsInfo['Roth IRA'] = investmentsData1['Roth IRA'];
    investmentsInfo['Roth Contributions Total'] = rothContributionsTotal;
    investmentsInfo['Roth Earnings Total'] = earningsRothTotal;
    investmentsInfo['Roth Ending Balance Last Val'] = rothEndingBalanceLastVal;
    investmentsInfo['Roth IRA Second'] = investmentsData['Retirement Accounts Roth IRA Second'];
    investmentsInfo['Retirement Accounts Roth IRA'] = investmentsData['Retirement Accounts Roth IRA'];
    investmentsInfo['Total Annual PreTax Expected Return Roth IRA'] = totalAnnualExpectedReturnRothIra;

    // Seph IRA
    investmentsInfo['Seph IRA'] = investmentsData1['Seph IRA'];
    investmentsInfo['Seph IRA Second'] = investmentsData['Retirement Accounts Seph IRA Second'];
    investmentsInfo['Retirement Accounts Seph IRA'] = investmentsData['Retirement Accounts Seph IRA'];
    investmentsInfo['Total Annual PreTax Expected Return Seph IRA'] = totalAnnualExpectedReturnSephIra;

    // Pension
    investmentsInfo.Pension = investmentsData1.Pension;
    investmentsInfo['Pension Second'] = investmentsData['Retirement Accounts Pension Second'];
    investmentsInfo['Retirement Accounts Pension'] = investmentsData['Retirement Accounts Pension'];
    investmentsInfo['Total Annual PreTax Expected Return Pension'] = totalAnnualExpectedReturnPension;

    //Roth 401k
    investmentsInfo['Roth'] = investmentsData1['Roth'];
    investmentsInfo['Roth 401k'] = investmentsData['Roth 401k'];
    investmentsInfo['Roth 401k Second'] = investmentsData['Roth 401k Second'];
    investmentsInfo['Roth Employer Comtributions'] = investmentsData1['Roth Employer Comtributions'];

    //529 Plan
    investmentsInfo['529 Plan'] = investmentsData['529 Plan'];
    investmentsInfo['Life Insurance'] = investmentsData['Life Insurance'];

    //Traditional IRA
    investmentsInfo['Traditional IRA'] = investmentsData1['Traditional IRA'];
    investmentsInfo['Retirement Accounts Traditional IRA'] = investmentsData['Retirement Accounts Traditional IRA'];
    investmentsInfo['Retirement Accounts Traditional IRA Second'] = investmentsData['Retirement Accounts Traditional IRA Second'];

    //HSA 
    investmentsInfo['HSA'] = investmentsData1['HSA'];
    investmentsInfo['HSA Second'] = investmentsData['HSA Second'];
    investmentsInfo['Retirement Accounts HSA'] = investmentsData['Retirement Accounts HSA'];
    return investmentsInfo;
  }
  return investmentsCompleteData();
}
export default investmentsSheetData;