import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, TextField,
} from '@material-ui/core';
import styles from './styles';

const FederalIncomeTax = ( props ) => {
  const { classes } = props;

  const federalIncomeTaxDefaultValues = {
    singleValue: 12400,
    marriedValue: 24800,
    status: 'Yes',
    year: '2021',
    totalIncome: 72000,
    standardDeduction: 0,
    federalTaxbleIncome: 0,
    finalData: {},
  };

  const [federalIncomeTaxValues, setFederalIncomeTaxValues] = useState( federalIncomeTaxDefaultValues );
  const [calculatedValues, setCalculatedValues] = useState( {} );

  useEffect( () => {
    const interestRates = [10, 12, 22, 24, 32, 35, 37];
    if ( federalIncomeTaxValues.year === '2021' ) {
      federalIncomeTaxValues.singleValue = 12550;
      federalIncomeTaxValues.marriedValue = 25100;
    } else if ( federalIncomeTaxValues.year === '2020' ) {
      federalIncomeTaxValues.singleValue = 12400;
      federalIncomeTaxValues.marriedValue = 24800;
    } else {
      federalIncomeTaxValues.singleValue = 12200;
      federalIncomeTaxValues.marriedValue = 24400;
    }
    let singleRangeArray = [];

    if ( federalIncomeTaxValues.status === 'Yes' ) {
      if ( federalIncomeTaxValues.year === '2021' ) {
        singleRangeArray = [9950, 40525, 86375, 164925, 209425, 523600];
      } else if ( federalIncomeTaxValues.year === '2020' ) {
        singleRangeArray = [9875, 40125, 85525, 163300, 207350, 518400];
      } else {
        singleRangeArray = [9700, 39475, 84200, 160725, 204100, 510300];
      }
    } else if ( federalIncomeTaxValues.year === '2021' ) {
      singleRangeArray = [19900, 81050, 172750, 329850, 418850, 628300, 0];
    } else if ( federalIncomeTaxValues.year === '2020' ) {
      singleRangeArray = [19750, 80250, 171050, 326600, 414700, 622050, 0];
    } else {
      singleRangeArray = [19400, 78950, 168400, 321450, 408200, 612350, 0];
    }

    if ( typeof federalIncomeTaxValues.status !== 'undefined' && federalIncomeTaxValues.status === 'Yes' ) {
      federalIncomeTaxValues.standardDeduction = parseInt( federalIncomeTaxValues.singleValue, 10 );
      federalIncomeTaxValues.marriedStatus = 'Single';
    } else {
      federalIncomeTaxValues.standardDeduction = parseInt( federalIncomeTaxValues.marriedValue, 10 );
      federalIncomeTaxValues.marriedStatus = 'Married';
    }

    federalIncomeTaxValues.federalTaxbleIncome = parseInt( federalIncomeTaxValues.totalIncome, 10 ) - federalIncomeTaxValues.standardDeduction;

    if ( federalIncomeTaxValues.federalTaxbleIncome < 0 ) {
      federalIncomeTaxValues.federalTaxbleIncome = 0;
    }

    federalIncomeTaxValues.finalData.Rate = [];
    federalIncomeTaxValues.finalData.Second_Range = [];
    federalIncomeTaxValues.finalData.First_Range = [];
    federalIncomeTaxValues.finalData.Taxable_Income = [];
    federalIncomeTaxValues.finalData.Taxes = [];
    federalIncomeTaxValues.finalData.Total_Taxable_Income = 0;
    federalIncomeTaxValues.finalData.Total_Taxes = 0;
    federalIncomeTaxValues.finalData.Federal_Income_Tax_Rate = 0;
    let totalTaxableIncome = 0;
    let totalTaxes = 0;
    for ( let i = 0; i < 7; i += 1 ) {
      federalIncomeTaxValues.finalData.Rate[i] = interestRates[i];
      federalIncomeTaxValues.finalData.Second_Range[i] = singleRangeArray[i];
      if ( i === 0 ) {
        federalIncomeTaxValues.finalData.First_Range[0] = 0;
      } else {
        federalIncomeTaxValues.finalData.First_Range[i] = singleRangeArray[i - 1];
      }
      if ( i === 6 ) {
        if ( federalIncomeTaxValues.federalTaxbleIncome >= federalIncomeTaxValues.finalData.First_Range[i] ) {
          federalIncomeTaxValues.finalData.Taxable_Income[i] = federalIncomeTaxValues.federalTaxbleIncome - federalIncomeTaxValues.finalData.First_Range[i];
        } else {
          federalIncomeTaxValues.finalData.Taxable_Income[i] = 0;
        }
      } else if ( federalIncomeTaxValues.federalTaxbleIncome > federalIncomeTaxValues.finalData.Second_Range[i] ) {
        federalIncomeTaxValues.finalData.Taxable_Income[i] = federalIncomeTaxValues.finalData.Second_Range[i] - federalIncomeTaxValues.finalData.First_Range[i];
      } else if ( federalIncomeTaxValues.federalTaxbleIncome >= federalIncomeTaxValues.finalData.First_Range[i] ) {
        federalIncomeTaxValues.finalData.Taxable_Income[i] = federalIncomeTaxValues.federalTaxbleIncome - federalIncomeTaxValues.finalData.First_Range[i];
      } else {
        federalIncomeTaxValues.finalData.Taxable_Income[i] = 0;
      }
      totalTaxableIncome += federalIncomeTaxValues.finalData.Taxable_Income[i];
      federalIncomeTaxValues.finalData.Taxes[i] = ( federalIncomeTaxValues.finalData.Taxable_Income[i] / 100 ) * federalIncomeTaxValues.finalData.Rate[i];
      totalTaxes += federalIncomeTaxValues.finalData.Taxes[i];
      federalIncomeTaxValues.finalData.Total_Taxable_Income = totalTaxableIncome;
      federalIncomeTaxValues.finalData.Total_Taxes = totalTaxes;

      federalIncomeTaxValues.finalData.Federal_Income_Tax_Rate = ( totalTaxes / parseInt( federalIncomeTaxValues.totalIncome, 10 ) ) * 100;
      if ( Number.isNaN( federalIncomeTaxValues.finalData.Federal_Income_Tax_Rate ) ) {
        federalIncomeTaxValues.finalData.Federal_Income_Tax_Rate = 0;
      }
    }
    setCalculatedValues( federalIncomeTaxValues );
    // eslint-disable-next-line
  },[federalIncomeTaxValues] );

  const updateValue = ( e, field ) => {
    let newValue = 0;
    const valuesUpdated = { ...federalIncomeTaxValues };
    if ( field === 'year' || field === 'status' ) {
      newValue = e.target.value;
    } else if ( field === 'totalIncome' ) {
      newValue = e.floatValue !== undefined ? e.floatValue : 0;
    } else {
      newValue = e.value ? e.value : 0;
    }
    valuesUpdated[field] = newValue;
    setFederalIncomeTaxValues( valuesUpdated );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.bondProfitPage }>
        <div className={ classes.formHorizontal }>
          <div className={ classes.container }>
            <ul className={ classes.breadCrumbs }>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>Federal Income Tax</li>
            </ul>
            <div className={ classes.csBox }>
              <div className={ classes.blueBorder }>
                <div className={ classes.leftPanel }>
                  <h3>Inputs</h3>
                  <table>
                    <tbody>
                      <tr>
                        <td> Year </td>
                      </tr>
                      <tr>
                        <td>
                          <div className={ classes.radioFederal }>
                            <div className={ classes.radioGroup }>
                              <label className={ federalIncomeTaxValues.year === '2021' && classes.checkedLabel } htmlFor="2021">
                                2021
                                <input type="radio" id="2021" name="2021" value="2021" checked={ federalIncomeTaxValues.year === '2021' } onChange={ ( e ) => updateValue( e, 'year' ) } />
                              </label>
                            </div>
                            <div className={ classes.radioGroup }>
                              <label className={ federalIncomeTaxValues.year === '2020' && classes.checkedLabel } htmlFor="2020">
                                2020
                                <input type="radio" id="2020" name="2020" value="2020" checked={ federalIncomeTaxValues.year === '2020' } onChange={ ( e ) => updateValue( e, 'year' ) } />
                              </label>
                            </div>
                            <div className={ classes.radioGroup }>
                              <label className={ federalIncomeTaxValues.year === '2019' && classes.checkedLabel } htmlFor="2019">
                                2019
                                <input type="radio" id="2019" name="2019" value="2019" checked={ federalIncomeTaxValues.year === '2019' } onChange={ ( e ) => updateValue( e, 'year' ) } />
                              </label>
                            </div>
                          </div>
                          <div className={ classes.clearFix } />
                        </td>
                      </tr>
                      <tr>
                        <td> Total Income </td>
                      </tr>
                      <tr>
                        <td className={ classes.dollarField }>
                          <span className={ classes.dollarSymbol }>$</span>
                          <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ federalIncomeTaxValues.totalIncome } onFocus={ ( e ) => e.target.select() } onValueChange={ ( e ) => updateValue( e, 'totalIncome' ) } />
                        </td>
                      </tr>
                      <tr>
                        <td className={ classes.heightEight } />
                      </tr>
                      <tr>
                        <td> Single / Married </td>
                      </tr>
                      <tr>
                        <td>
                          <div className={ classes.radioFederal }>
                            <div className={ classes.radioGroup }>
                              <label className={ federalIncomeTaxValues.status === 'Yes' && classes.checkedLabel } htmlFor="single">
                                Single
                                <input type="radio" id="single" name="single" value="Yes" checked={ federalIncomeTaxValues.year === 'Yes' } onChange={ ( e ) => updateValue( e, 'status' ) } />
                              </label>
                            </div>
                            <div className={ classes.radioGroup }>
                              <label className={ federalIncomeTaxValues.status === 'No' && classes.checkedLabel } htmlFor="married">
                                Married
                                <input type="radio" id="married" name="married" value="No" checked={ federalIncomeTaxValues.year === 'No' } onChange={ ( e ) => updateValue( e, 'status' ) } />
                              </label>
                            </div>
                          </div>
                          <div className={ classes.clearFix } />
                        </td>
                      </tr>
                      <tr>
                        <td> Standard Deduction </td>
                      </tr>
                      <tr>
                        <td className={ classNames( classes.dollarField, classes.noColor ) }>
                          <b><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues.standardDeduction !== undefined ? calculatedValues.standardDeduction : 0 } displayType="text" prefix="$" /></b>
                        </td>
                      </tr>
                      <tr>
                        <td className={ classes.heightEight } />
                      </tr>
                      <tr>
                        <td> Federal Taxable Income </td>
                      </tr>
                      <tr>
                        <td className={ classNames( classes.dollarField, classes.noColor ) }>
                          <b><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ federalIncomeTaxValues.federalTaxbleIncome !== undefined ? federalIncomeTaxValues.federalTaxbleIncome : 0 } displayType="text" prefix="$" /></b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={ classes.rightPanel }>
                  <h3>
                    Federal Income Tax Calculator (
                    {federalIncomeTaxValues.year}
                    )
                  </h3>
                  <hr className={ classes.blueLine } />
                  <div className={ classes.federalFlow }>
                    <table className={ classes.federalTable }>
                      <thead>
                        <tr className={ classes.tHeader }>
                          <th>&nbsp;</th>
                          <th colSpan="4">Federal Income Taxes</th>
                          <th>
                            Year
                            {' '}
                            {federalIncomeTaxValues.year}
                          </th>
                        </tr>
                        <tr>
                          <td colSpan="6">&nbsp;</td>
                        </tr>
                        <tr className={ classes.federalTaxIncome }>
                          <th colSpan="5">Federal Taxable Income</th>
                          <th><span><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ federalIncomeTaxValues.federalTaxbleIncome !== undefined ? federalIncomeTaxValues.federalTaxbleIncome : 0 } displayType="text" prefix="$" /></span></th>
                        </tr>
                        <tr>
                          {' '}
                          <td colSpan="6">&nbsp;</td>
                          {' '}
                        </tr>
                        <tr className={ classes.incomeRanges }>
                          <th>&nbsp;</th>
                          <th>Rate</th>
                          <th colSpan="2">
                            Income Ranges (
                            {federalIncomeTaxValues.status === 'Yes' ? 'Single' : 'Married'}
                            )
                          </th>
                          <th>Taxable Income</th>
                          <th>Taxes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr> */}

                        {
                              [0, 1, 2, 3, 4, 5, 6].map( ( n ) => (
                                <tr>
                                  <td>&nbsp;</td>
                                  <td>
                                    {federalIncomeTaxValues.finalData.Rate && federalIncomeTaxValues.finalData.Rate[n].toFixed( 2 )}
                                    %
                                  </td>
                                  <td><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ federalIncomeTaxValues.finalData.First_Range && federalIncomeTaxValues.finalData.First_Range[n] } displayType="text" prefix="$" /></td>
                                  <td><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ federalIncomeTaxValues.finalData.Second_Range && federalIncomeTaxValues.finalData.Second_Range[n] } displayType="text" prefix="$" /></td>
                                  <td><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ federalIncomeTaxValues.finalData.Taxable_Income && federalIncomeTaxValues.finalData.Taxable_Income[n] } displayType="text" prefix="$" /></td>
                                  <td><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ federalIncomeTaxValues.finalData.Taxes && federalIncomeTaxValues.finalData.Taxes[n] } displayType="text" prefix="$" /></td>
                                </tr>
                              ) )
                            }

                      </tbody>
                      <tfoot>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ federalIncomeTaxValues.finalData.Total_Taxable_Income && federalIncomeTaxValues.finalData.Total_Taxable_Income } displayType="text" prefix="$" /></td>
                          <td><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ federalIncomeTaxValues.finalData.Total_Taxes && federalIncomeTaxValues.finalData.Total_Taxes } displayType="text" prefix="$" /></td>
                        </tr>
                        <tr>
                          {' '}
                          <td colSpan="6">&nbsp;</td>
                          {' '}
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td colSpan="4">Effective Federal Income Tax Rate (Taxes / Total Income)</td>
                          <td>
                            {federalIncomeTaxValues.finalData.Federal_Income_Tax_Rate && federalIncomeTaxValues.finalData.Federal_Income_Tax_Rate.toFixed( 2 )}
                            {' '}
                            %
                          </td>

                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

FederalIncomeTax.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( FederalIncomeTax );
