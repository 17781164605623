import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { loader } from 'graphql.macro';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';
import compose from 'recompose/compose';
import * as timeLineActions from '../../calculations/time-line';
import styles from './styles';
import infoIcon from '../../assets/img/sight/info-icon-contrast.png';
import folderIcon from '../../assets/img/sight/explorer_folder_icon.png';
import shareIcon from '../../assets/img/sight/explorer_share_icon.png';
import inkwiryIcon from '../../assets/img/sight/explorer_inkwiry_icon.png';
import userIcon from '../../assets/img/sight/users.svg';
import csIcon from '../../assets/img/sight/career-sketch-networth-bg.jpg';
import fileIcon from '../../assets/img/sight/explorer_file_icon.png';
import Pageloader from '../ui/pageloader';
import dummyProfile from '../../assets/img/addressbook/user.png';
import alertIcon from '../../assets/img/alert_icon.png';
import { Scrollbars } from 'react-custom-scrollbars';
import * as moduleServices from '../../calculations/modules-services';


const moduleNames = [];
moduleNames.module1 = { name: 'Financial Independence', position: 1, url: '/plan-with-inkwiry/fi' };
moduleNames.module2 = { name: 'Higher Education', position: 2, url: '/plan-with-inkwiry/he' };
moduleNames.module4 = { name: 'Career Path', position: 4, url: '/plan-with-inkwiry/cp' };
moduleNames.module5 = { name: 'Career Advancement', position: 5, url: '/plan-with-inkwiry/ca' };
moduleNames.module6 = { name: 'More Higher Education', position: 6, url: '/plan-with-inkwiry/mhe' };
moduleNames.module8 = { name: 'Additional Career Advancement', position: 8, url: '/plan-with-inkwiry/aca' };
moduleNames.module9 = { name: 'Investments', position: 9, url: '/plan-with-inkwiry/investments' };
moduleNames.module10 = { name: 'Family', position: 10, url: '/plan-with-inkwiry/family' };
moduleNames.module11 = { name: 'Vacation', position: 11, url: '/plan-with-inkwiry/vacation' };
moduleNames.module12 = { name: 'Buying a Car', position: 12, url: '/plan-with-inkwiry/car' };
moduleNames.module13 = { name: 'Buying a Home', position: 13, url: '/plan-with-inkwiry/home' };
moduleNames.module14 = { name: 'Real Estate Investment', position: 14, url: '/plan-with-inkwiry/rei' };
moduleNames.module15 = { name: 'Further Higher Education', position: 15, url: '/plan-with-inkwiry/fhe' };
moduleNames.module17 = { name: 'Further Career Advancement', position: 17, url: '/plan-with-inkwiry/fca' };
moduleNames.module18 = { name: 'Existing Assets & Liabilities', position: 18, url: '/plan-with-inkwiry/existing' };

const IMAGE_PATH = process.env.REACT_APP_IMAGE_URL;

const MY_DETAIL = loader('../../graphql/schema/auth/sight-me.graphql');
const SIGHTDETAILSDATA = loader('../../graphql/schema/pwi/sight-page-data.graphql');
const SAVEFOLDER = loader('../../graphql/schema/pwi/save-folder.graphql');
const DELETEFOLDER = loader('../../graphql/schema/pwi/delete-folder.graphql');
const DELTESHAREDUSERDATA = loader('../../graphql/schema/pwi/delete-shared-user.graphql');
const LOADCAREERSKETCHAPI = loader('../../graphql/schema/pwi/load-career-sketch.graphql');
const DELETECAREERSKETCHSAPI = loader('../../graphql/schema/pwi/delete-career-sketch.graphql');
const LOADSCENARIOAPI = loader('../../graphql/schema/pwi/load-scenario-sketch.graphql');
const DELETESCENARIOAPI = loader('../../graphql/schema/pwi/delete-scenario.graphql');
const UPDATESKETCHNAMEAPI = loader('../../graphql/schema/pwi/update-sketch-name.graphql');
const UPDATESCENARIONAMEAPI = loader('../../graphql/schema/pwi/update-scenario.graphql');
const SAVECAREERSKETCHAPI = loader('../../graphql/schema/pwi/save-career-sketch.graphql');
const ADD_SKETCHS = loader('../../graphql/schema/dashboard/addSketch.graphql');
const LOAD_CAREER_SKETCHS = loader('../../graphql/schema/dashboard/loadCareerSketch.graphql');



function renderThumb({ style, ...props }) {
  const thumbStyle = {
    backgroundColor: '#eca041',
    borderRadius: '3px',
    width: '3px',
  };
  return (
    <div
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
}
renderThumb.propTypes = {
  style: PropTypes.object.isRequired,
};


const Sight = (props) => {
  const {
    classes, history, passData, sightPathCallback, compareCallback, notesCallBack, getLoadSketches, setOpenSightPopup, loadSketchList, moduleName
  } = props;
  const [loading, setLoading] = React.useState(true);
  const [sightData, setSightData] = React.useState({});
  const [stepDetails, setStepDetails] = React.useState({});
  const [deleteSharedUserStatus, setDeleteSharedUserStatus] = React.useState(false);
  const [deleteFolderStatus, setDeleteFolderStatus] = React.useState(false);
  const [deleteFolderCheckbox, setDeleteFolderCheckbox] = React.useState(false);
  const [deleteSketchCheckbox, setDeleteSketchCheckbox] = React.useState(false);
  const [deleteScenarioStatus, setDeleteScenarioStatus] = React.useState(false);

  const [createNewFolder, setCreateNewFolder] = React.useState(false);
  const [newFolderError, setNewFolderError] = React.useState('');
  const [newFolderName, setNewFolderName] = React.useState('');
  const [folderNameType, setFolderNameType] = React.useState('');

  const [editScenarioStaus, setEditScenarioStaus] = React.useState(false);
  const [editScenarioError, setEditScenarioError] = React.useState('');
  const [editScenarioName, setEditScenarioName] = React.useState('');
  const [scenarioNameType, setScenarioNameType] = React.useState('');
  const [editScenarioData, setEditScenarioData] = React.useState({});
  const [searchText, setSearchText] = React.useState('');
  const [apiSearchText, setApiSearchText] = React.useState('');
  const [showSearchData, setShowSearchData] = React.useState(false);
  const [resultSearchData, setResultSearchData] = React.useState({});
  const [showData, setShowData] = React.useState({});
  const [hight, setHight] = React.useState(0);
  const [activeStep5, setActiveStep5] = React.useState(false);
  // const [element, setElement] = React.useState('');
  // const sightImages = require.context( '../../assets/img/sight/', true );
  const [mouseLeftClick, setMouseLeftClick] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const ref = useRef()


  useEffect(() => {
    if (ref.current) {
      ref.current.style.transform = `translate(${position.x}px, ${position.y}px)`
    }
  }, [position]);



  const _onMouseMove = (event) => {
    const x = position.x + event.movementX;
    const y = position.y + event.movementY;

    if (mouseLeftClick) {
      setPosition({
        x: x,
        y: y
      })
    }
  }

  useEffect(() => {


  }, [])



  const [saveCareerSketch] = useMutation(SAVECAREERSKETCHAPI, {
    onCompleted({
      saveCareerSketch: {
        status,
      },
    }) {
      if (status === true) {
        setLoading(false);
        sightPathCallback('sketch-save');
      }
    },
    onError(errors) {
      setLoading(false);
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));
      setNewFolderError(formatedErrors);
    },
  });
  const [updateSketchName] = useMutation(UPDATESKETCHNAMEAPI, {
    onCompleted({
      updateCareerSkecth: {
        id,
        name,
      },
    }) {
      const dataKey = 'sketches';
      const duplicateSightData = { ...sightData };
      const folderIndex = _.findIndex(duplicateSightData[dataKey], { id });
      if (folderIndex >= 0) {
        duplicateSightData[dataKey][folderIndex].name = name;
      }
      setFolderNameType('');
      setSightData(duplicateSightData);
      setCreateNewFolder(false);
      setLoading(false);
    },
    onError(errors) {
      setLoading(false);
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));
      setNewFolderError(formatedErrors);
    },
  });

  const [submitFolderName] = useMutation(SAVEFOLDER, {
    onCompleted({
      saveFolder: {
        id,
        name,
      },
    }) {
      let dataKey = '';
      if (stepDetails.step3.slug === 'my_saved_notes') {
        dataKey = 'notesFolder';
      } else if (stepDetails.step3.slug === 'my_comparisons') {
        dataKey = 'compare_scenario';
      } else if (stepDetails.step3.slug === 'my_scenarios') {
        dataKey = 'folders';
      }
      const duplicateSightData = { ...sightData };
      const folderIndex = _.findIndex(duplicateSightData[dataKey], { id });
      if (folderIndex >= 0) {
        duplicateSightData[dataKey][folderIndex].name = name;
      } else {
        duplicateSightData[dataKey].push({
          id,
          name,
          dir_type: 'O',
          inkwiry_directory: 0,
        });
      }
      setFolderNameType('');
      setSightData(duplicateSightData);     
      setCreateNewFolder(false);
      setLoading(false);
    },
    onError(errors) {
      setLoading(false);
      // const formatedErrors = errors.graphQLErrors.map(({ message }) => (
      //   <span key={`login-error-${Math.random()}`}>{message}</span>
      // ));
      // setNewFolderError(formatedErrors);
    },
  });

  const saveFolderName = () => {
    if (newFolderName !== '') {
      let dataKey = '';
      if (stepDetails.step3.slug === 'my_saved_notes') {
        dataKey = 'notes';
      } else if (stepDetails.step3.slug === 'my_comparisons') {
        dataKey = 'comparisons';
      } else if (stepDetails.step3.slug === 'my_scenarios') {
        dataKey = 'scenarios';
      } else if (stepDetails.step3.slug === 'my_career_sketches') {
        dataKey = 'all';
      }
      if (dataKey === 'all') {
        setLoading(true);
        updateSketchName({
          variables: {
            data: {
              name: newFolderName,
              id: folderNameType,
            },
          },
        });
      } else {
        const data = {
          type: dataKey,
          name: newFolderName,
        };
        if (folderNameType !== 'new') {
          data.id = folderNameType;
        }
        setLoading(true);
        submitFolderName({
          variables: {
            data,
          },
        });
      }
      setFolderNameType('');
    } else {
      setNewFolderError('Please provide a folder name');
    }
  };

  const [loadCarrerSketchData] = useMutation(LOADCAREERSKETCHAPI, {
    onCompleted({
      loadCareerSketch: {
        status,
      },
    }) {
      if (status === true) {
        if (passData.from === 'page') {
          history.push('/timeline-sketcher');
        } else {
          setLoading(false);
          sightPathCallback('sketch-load');
        }
      } else {
        setLoading(false);
      }
    },
    onError() {
      setLoading(false);
    },
  });

  const saveCareerSketchData = () => {
    let sketchId = '';
    if (stepDetails.step4 !== undefined && stepDetails.step4 !== '' && stepDetails.step4.slug !== undefined && stepDetails.step4.slug !== '') {
      sketchId = stepDetails.step4.slug;
    }
    if (newFolderName !== '' || sketchId !== '') {
      let name = '';
      if (newFolderName !== '') {
        name = newFolderName;
      } else {
        name = stepDetails.step4.title;
      }

      const moduleTimeLine = timeLineActions.moduleTimelineData;
      const timeLineVal = moduleTimeLine.map((data) => _.pick(data, ['action', 'module_time_line_order', 'selected_module', 'time_line_order', 'scenario_id']));

      setLoading(true);
      saveCareerSketch({
        variables: {
          data: {
            id: sketchId,
            name,
            order: timeLineVal,
          },
        },
      });
    } else {
      setNewFolderError('Please provide career sketch name');
    }
  };

  const { data: myDetails } = useQuery(MY_DETAIL, { fetchPolicy: 'no-cache' });



  const [sightApiData] = useLazyQuery(SIGHTDETAILSDATA, {
    variables: {
      operation_type: 'save',
      type: 'all',
    },
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      if (response.SightData) {
        setLoading(false);
        setSightData(response.SightData);
        setHight(window.innerHeight - 325);
        if (passData.from === 'page') {
          setShowData({
            step5: true,
            step6: true,
            show_shared: true,
            show_inkwiry: true,
            show_my_career_sketches: true,
            show_my_scenarios: true,
            show_my_saved_notes: true,
            show_my_comparisions: true,
            show_default_scenario: true,
            show_shared_folder: true,
            show_career_sketche_options: true,
          });
          setStepDetails({
            step1: { slug: 'fc', title: 'Financial Configurator' },
            step2: { slug: 'my', title: 'My' }
          });
        } else if (passData.from === 'popup') {
          if (passData.forType !== undefined && passData.forType === 'sketch') {
            setShowData({
              step5: false,
              step6: false,
              show_shared: passData.type === 'load',
              show_inkwiry: passData.type === 'load',
              show_my_career_sketches: true,
              show_my_scenarios: false,
              show_my_saved_notes: false,
              show_my_comparisions: false,
              show_default_scenario: false,
              show_shared_folder: false,
              show_career_sketche_options: false,
            });
            setStepDetails({
              step1: { slug: 'fc', title: 'Financial Configurator' },
              step2: { slug: 'my', title: 'My' }
            });
          } else if (passData.forType !== undefined && passData.forType === 'scenario') {
            setShowData({
              step5: true,
              step6: true,
              show_shared: true,
              show_inkwiry: true,
              show_my_career_sketches: true,
              show_my_scenarios: true,
              show_my_saved_notes: false,
              show_my_comparisions: false,
              show_default_scenario: true,
              show_shared_folder: true,
              show_career_sketche_options: false,
            });
            setStepDetails({
              step1: { slug: 'fc', title: 'Financial Configurator' },
              step2: { slug: 'my', title: 'My' }
            });
          } else if (passData.forType !== undefined && passData.forType === 'compareScenario') {
            setShowData({
              step5: true,
              step6: true,
              show_shared: false,
              show_inkwiry: false,
              show_my_career_sketches: false,
              show_my_scenarios: false,
              show_my_saved_notes: false,
              show_my_comparisions: true,
              show_default_scenario: true,
              show_shared_folder: true,
              show_career_sketche_options: false,
            });
            setStepDetails({ step1: { slug: "fc", title: "Financial Configurator" }, step2: { slug: "my", title: "My" }, step3: { slug: 'my_comparisons', title: 'My Comparisions', data: response.SightData.compare_scenario } });
          }
        }
      }
    },
  });

  useEffect(() => {
    sightApiData();
  }, []);

  const [searchSightData] = useLazyQuery(SIGHTDETAILSDATA, {
    variables: {
      operation_type: 'save',
      type: 'all',
      search_text: apiSearchText,
    },
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      setResultSearchData(response.SightData);
      setShowSearchData(true);
      setLoading(false);
    },
  });

  const searchData = (e) => {
    if (searchText !== '') {
      if (e.nativeEvent.keyCode === 13 || e.nativeEvent.keyCode === undefined) {
        setLoading(true);
        setApiSearchText(searchText);
        searchSightData();
      }
    }
  };
  const manageNavigation = (step, type, title, folderType, prevData = '') => {
    setCreateNewFolder(false);
    setNewFolderError('');
    setNewFolderName('');
    setEditScenarioStaus(false);
    setScenarioNameType('');
    setFolderNameType('');

    if (step === 'step1') {
      setStepDetails({ step1: { slug: type, title } });
    } else if (step === 'step2') {
      let sharedUsers = [];
      if (type === 'shared') {
        // eslint-disable-next-line
        sharedUsers = sightData.sharedUsers;
      }
      // console.log('phrudhvi',sightData.sharedUsers,{ step1: stepDetails.step1, step2: { slug: type, title, shared_users: sharedUsers } })
      setStepDetails({ step1: stepDetails.step1, step2: { slug: type, title, shared_users: sharedUsers } });
    } else if (step === 'step3') {
      let step4Data = [];
      switch (type) {
        case 'my_career_sketches': {
          let sketchs = sightData.sketches;
          sketchs = _.filter(sketchs, { inkwiry_career_sketch: '0' });
          sketchs = _.filter(sketchs, { shared_user_id: null });
          step4Data = sketchs;
          break;
        }
        case 'my_scenarios': {
          let { folders } = sightData;
          folders = _.reject(folders, { inkwiry_directory: 2 });
          folders = _.reject(folders, { dir_type: 'S' });
          step4Data = folders;
          break;
        }
        case 'my_saved_notes': {
          step4Data = sightData.notesFolder;
          break;
        }
        case 'my_comparisons': {
          step4Data = sightData.compare_scenario;
          break;
        }
        case 'all_sketches': {
          let sketchs = sightData.sketches;
          sketchs = _.filter(sketchs, { inkwiry_career_sketch: '1' });
          sketchs = _.filter(sketchs, { sketch_type: 'I' });
          step4Data = sketchs;
          break;
        }
        case 'powerup_sketches': {
          let sketchs = sightData.sketches;
          sketchs = _.filter(sketchs, { inkwiry_career_sketch: '1' });
          sketchs = _.filter(sketchs, { sketch_type: 'P' });
          step4Data = sketchs;
          break;
        }
        case 'default_scenarios': {
          let { folders } = sightData;
          folders = _.filter(folders, { inkwiry_directory: 2 });
          step4Data = folders;
          break;
        }
        default: {
          let sketchs = sightData.sketches;
          sketchs = _.filter(sketchs, { shared_user_id: parseInt(type, 10) });
          if (showData.show_shared_folder === true) {
            let { folders } = sightData;
            folders = _.filter(folders, { dir_type: 'S' });
            step4Data = [...sketchs, ...folders];
          } else {
            step4Data = sketchs;
          }
          break;
        }
      }

      // console.log('phrudhvi',{ step1: stepDetails.step1 !== 'undefined' ?  stepDetails.step1 :{ slug: "fc", title: "Financial Configurator" }, step2: stepDetails.step2 !== 'undefined' ? stepDetails.step2 : { slug: "my", title: "My" }  , step3: { slug: type, title, data: step4Data } },typeof(stepDetails.step1))
      setStepDetails({ step1: stepDetails.step1, step2: stepDetails.step2, step3: { slug: type, title, data: step4Data } });
    } else if (step === 'step4') {
      setActiveStep5(false);
      let step5Data = [];
      let scenarios = [];
      if (folderType === 'folder') {
        switch (stepDetails.step3.slug) {
          case 'my_saved_notes': {
            scenarios = _.filter(sightData.notes, { directory_id: type });
            break;
          }
          case 'my_comparisons': {
            scenarios = _.filter(sightData.compare_scenario_notes, { directory_id: type });
            break;
          }
          default: {
            scenarios = _.filter(sightData.scenarios, { directory_id: type });
            break;
          }
        }
      } else {
        const sketchIndex = _.findIndex(sightData.sketches, { id: type });
        const scenarioOrder = sightData.sketches[sketchIndex].order;
        const scenarioIds = _.map(scenarioOrder, 'scenario_id');

        if (scenarioIds.length > 0) {
          scenarios = sightData.scenarios.filter((e) => scenarioIds.includes(e.id));

        }
      }
      if (scenarios.length > 0) {
        let selectedModules = _.map(scenarios, 'selected_module');

        selectedModules = _.uniq(selectedModules);
        selectedModules = selectedModules.filter((val) => { return Boolean(val) });
        step5Data = selectedModules.map((value) => moduleNames[value]);
        step5Data = _.orderBy(step5Data, ['position'], ['asc']);
      }

      setStepDetails({
        step1: stepDetails.step1,
        step2: stepDetails.step2,
        step3: stepDetails.step3,
        step4: {
          slug: type, title, folder_type: folderType, data: step5Data, scenarios,
        },
      });
    } else if (step === 'step5') {
      let scenarios = [];
      // console.log('step5');
      if (prevData !== '') {
        scenarios = _.filter(prevData.step4.scenarios, { selected_module: (`module${type}`) });
        // console.log("scenarios"+ scenarios);
      } else {
        scenarios = _.filter(stepDetails.step4.scenarios, { selected_module: (`module${type}`) });
      }
      if (stepDetails.step3.slug !== 'my_saved_notes') {
        const relationYear = sightData.relation_year;
        const relationEndYear = relationYear + 14;

        scenarios = scenarios.map((scenario) => {
          const item = scenario;
          let relationYearStatus = false;
          if (item.start_year_relation !== undefined) {
            if (parseInt(item.start_year_relation, 10) > 0
              && parseInt(item.end_year_relation, 10) > 0) {
              if (parseInt(item.start_year_relation, 10) >= relationYear
                && parseInt(item.end_year_relation, 10) <= relationEndYear) {
                relationYearStatus = true;
              } else {
                relationYearStatus = false;
              }
            } else {
              relationYearStatus = true;
            }
          } else if (item.relation_year === relationYear) {
            relationYearStatus = true;
          }
          if (passData.from === 'popup' && item.selected_module !== passData.module) {
            relationYearStatus = false;
          }
          item.relation_year_status = relationYearStatus;
          return item;
        });
      }
      setStepDetails({
        step1: stepDetails.step1, step2: stepDetails.step2, step3: stepDetails.step3, step4: (prevData !== '') ? prevData.step4 : stepDetails.step4, step5: { slug: type, title, data: scenarios },
      });
    } else if (step === 'step6') {
      // console.log("passData.scenarioType", passData.scenarioType);
      if (prevData.relation_year_status === true || passData.from === 'page' || passData.scenarioType === 'compareScenario') {
        setStepDetails({
          step1: stepDetails.step1, step2: stepDetails.step2, step3: stepDetails.step3, step4: stepDetails.step4, step5: stepDetails.step5, step6: { slug: type, title },
        });
      }
    }
    // if( step === "step1"){
    //   manageNavigation('step2', 'my', 'My');
    //   // manageNavigation('step3', 'my_scenarios', 'My Scenarios');
    // }
  };

  const handleNewFolder = () => {
    setCreateNewFolder(true);
    setFolderNameType('new');
  }

  /*
  *** code started for deleted shared user
  */

  const [deleteSharedUserApi] = useMutation(DELTESHAREDUSERDATA, {
    onCompleted(response) {
      setLoading(false);
      if (response.deleteSharedUser.status === true) {
        const sharedUserId = response.deleteSharedUser.ids;
        const duplicateSightData = { ...sightData };
        let { sharedUsers } = duplicateSightData;
        sharedUsers = _.reject(sharedUsers, { id: sharedUserId });
        duplicateSightData.sharedUsers = sharedUsers;
        setSightData(duplicateSightData);
        setDeleteSharedUserStatus(true);
      }
    },
    onError() {
      return false;
    },
  });

  useEffect(() => {
    if (deleteSharedUserStatus === true) {
      manageNavigation('step2', 'shared', 'Shared');
      setDeleteSharedUserStatus(false);
    }
    // eslint-disable-next-line
  }, [deleteSharedUserStatus])

  const deleteSharedUser = () => {
    if (stepDetails.step3 !== undefined && stepDetails.step3.slug !== undefined && stepDetails.step3.slug !== '' && stepDetails.step2.slug === 'shared') {
      const sharedUserId = stepDetails.step3.slug;

      confirmAlert({
        // eslint-disable-next-line
        customUI: ({ onClose }) => (
          <div className={classes.impPopup}>
            <div>
              <h3>Important Tip</h3>
              <div className={classes.impDiv}>
                <span><img src={alertIcon} alt="" /></span>
                <p>When you delete a shared user, you are removing the sketches and scenarios that the user has shared with you. If you want to access those sketches and scenarios again, you will have to ask the user to reshare the information.</p>
              </div>
            </div>
            <div className={classes.impbuttonOkRedo}>
              <button
                type="button"
                onClick={() => { onClose(); setLoading(true); deleteSharedUserApi({ variables: { user_ids: sharedUserId } }); }}
              >
                OK
              </button>
              <button type="button" onClick={onClose}>CANCEL</button>
            </div>
          </div>
        ),
      });
    }
  };

  /*
  *** code ended for deleted shared user
  */

  /*
  *** code Started for deleted Sketch or folder
  */

  const [deleteFolderApi] = useMutation(DELETEFOLDER, {
    onCompleted(response) {
      setLoading(false);
      if (response.deleteFolder.status === true) {
        const folderId = response.deleteFolder.ids;
        const duplicateSightData = { ...sightData };
        let folders = [];
        let folderKey = '';
        let scenarioKey = '';
        if (stepDetails.step3.slug === 'my_scenarios') {
          folderKey = 'folders';
          scenarioKey = 'scenarios';
        } else if (stepDetails.step3.slug === 'my_saved_notes') {
          folderKey = 'notesFolder';
          scenarioKey = 'notes';
        }
        folders = sightData[folderKey];
        let scenarios = sightData[scenarioKey];
        for (let f = 0; f < folderId.length; f += 1) {
          folders = _.reject(folders, { id: folderId[f] });
          scenarios = _.reject(scenarios, { directory_id: folderId[f] });
        }
        duplicateSightData[folderKey] = folders;
        duplicateSightData[scenarioKey] = scenarios;
        setSightData(duplicateSightData);
        setDeleteFolderStatus(true);
      }
    },
    onError() {
      return false;
    },
  });

  useEffect(() => {
    if (deleteFolderStatus === true) {
      manageNavigation('step3', stepDetails.step3.slug, stepDetails.step3.title);
      setDeleteFolderStatus(false);
    }
    // eslint-disable-next-line
  }, [deleteFolderStatus])

  const [deleteCareerSketch] = useMutation(DELETECAREERSKETCHSAPI, {
    onCompleted(response) {
      setLoading(false);

      if (passData.from === 'page') {
        if (response.deleteCareerSkecth.status === true) {
          const sketchId = response.deleteCareerSkecth.ids;
          const duplicateSightData = { ...sightData };
          let sketchs = sightData.sketches;
          let { scenarios } = sightData;
          for (let s = 0; s < sketchId.length; s += 1) {
            const singleSketch = _.find(sketchs, { id: sketchId[s] });
            if (singleSketch.shared_user_id !== null && singleSketch.shared_user_id !== '' && singleSketch.shared_user_id !== 0) {
              const scenarioIds = _.map(singleSketch.order, 'scenario_id');
              scenarios = _.reject(scenarios, (k) => scenarioIds.includes(k.id));
            }
            sketchs = _.reject(sketchs, { id: sketchId[s] });
          }
          duplicateSightData.sketches = sketchs;
          duplicateSightData.scenarios = scenarios;
          setSightData(duplicateSightData);
          setDeleteFolderStatus(true);
        }
      } else {
        setLoading(false);
        sightPathCallback('sketch-delete');
      }
    },
    onError() {
      return false;
    },
  });

  const updateCheckboxvalue = (e, type) => {
    if (type === 'sketch') {
      setDeleteSketchCheckbox(e.target.checked);
    } else {
      setDeleteFolderCheckbox(e.target.checked);
    }
  };

  const deleteSketchOrFolder = () => {
    if (stepDetails.step3.slug === 'my_scenarios' || stepDetails.step3.slug === 'my_saved_notes' || stepDetails.step3.slug === 'my_comparisons') {
      const folderIds = [stepDetails.step4.slug];
      let type = '';
      if (stepDetails.step3.slug === 'my_scenarios') {
        type = 'scenarios';
      } else if (stepDetails.step3.slug === 'my_saved_notes') {
        type = 'notes';
      } else {
        type = 'comparisons';
      }

      if (((myDetails.me.fc_setting === null || myDetails.me.fc_setting === undefined) || (myDetails.me.fc_setting.show_delete_dir_message !== undefined && myDetails.me.fc_setting.show_delete_dir_message === '1')) && deleteFolderCheckbox === false) {
        confirmAlert({
          // eslint-disable-next-line
          customUI: ({ onClose }) => (
            <div className={classes.impPopup}>
              <div>
                <h3>Important Tip</h3>
                <div className={classes.impDiv}>
                  <span><img src={alertIcon} alt="" /></span>
                  <div>
                    {stepDetails.step3.slug === 'my_scenarios' && (<p>If you delete this folder, you will delete every scenario contained in this folder. Make sure to check all modules to see what scenario might be saved to this folder.</p>)}
                    {stepDetails.step3.slug === 'my_saved_notes' && (<p>If you delete this folder, you will delete every note contained in this folder. Make sure to check all modules to see what note might be saved to this folder.</p>)}
                    {stepDetails.step3.slug === 'my_comparisons' && (<p>If you delete this folder, you will delete every comparison contained in this folder. Make sure to check all modules to see what comparison might be saved to this folder.</p>)}
                    <p>
                      <FormControlLabel
                        control={<Checkbox onChange={(e) => updateCheckboxvalue(e, 'folder')} color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />}
                        label="I understand and don't need to see this message again."
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className={classes.impbuttonOkRedo}>
                <button
                  type="button"
                  onClick={() => { onClose(); setLoading(true); deleteFolderApi({ variables: { id: folderIds, type } }); }}
                >
                  OK
                </button>
                <button type="button" onClick={onClose}>CANCEL</button>
              </div>
            </div>
          ),
        });
      } else {
        setLoading(true);
        deleteFolderApi({ variables: { user_ids: folderIds, type } });
      }
    } else if (stepDetails.step3.slug === 'my_career_sketches') {
      const sketchId = [stepDetails.step4.slug];
      if (((myDetails.me.fc_setting === null || myDetails.me.fc_setting === undefined) || (myDetails.me.fc_setting.show_delete_career_sketch_message !== undefined && myDetails.me.fc_setting.show_delete_career_sketch_message === '1')) && deleteSketchCheckbox === false) {
        confirmAlert({
          // eslint-disable-next-line
          customUI: ({ onClose }) => (
            <div className={classes.impPopup}>
              <div>
                <h3>Important Tip</h3>
                <div className={classes.impDiv}>
                  <span><img src={alertIcon} alt="" /></span>
                  <div>
                    <p>When you delete a career sketch, you are not deleting the actual scenarios in that sketch. They will still be available as individual scenarios. You are simply deleting the grouping of those scenarios that makes up that sketch.</p>
                    <p>
                      <FormControlLabel
                        control={<Checkbox onChange={(e) => updateCheckboxvalue(e, 'sketch')} color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />}
                        label="I understand and don't need to see this message again."
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className={classes.impbuttonOkRedo}>
                <button
                  type="button"
                  onClick={() => { onClose(); setLoading(true); deleteCareerSketch({ variables: { id: sketchId } }); }}
                >
                  OK
                </button>
                <button type="button" onClick={onClose}>CANCEL</button>
              </div>
            </div>
          ),
        });
      } else {
        deleteCareerSketch({ variables: { id: sketchId } });
      }
    } else if (stepDetails.step2.slug === 'shared' && stepDetails.step4 !== undefined && stepDetails.step4.folder_type === 'sketch') {
      const sketchId = [stepDetails.step4.slug];
      confirmAlert({
        // eslint-disable-next-line
        customUI: ({ onClose }) => (
          <div className={classes.impPopup}>
            <div>
              <h3>Important Tip</h3>
              <div className={classes.impDiv}>
                <span><img src={alertIcon} alt="" /></span>
                <div>
                  <p>When you delete a sketch or the shared folder, you are removing that sketch or the scenarios in that folder that the user has shared with you. If you want to access those sketches and scenarios again, you will have to ask the user to reshare the information.</p>
                </div>
              </div>
            </div>
            <div className={classes.impbuttonOkRedo}>
              <button
                type="button"
                onClick={() => { onClose(); setLoading(true); deleteCareerSketch({ variables: { id: sketchId } }); }}
              >
                OK
              </button>
              <button type="button" onClick={onClose}>CANCEL</button>
            </div>
          </div>
        ),
      });
    }
  };

  /*
  *** code Ended for deleted Sketch or folder
  */


  const [AddSketchData] = useMutation(ADD_SKETCHS, {
    onCompleted({
      addSketch: {
        status,
        message,
      },
    }) {
      if (status === true) {
        getLoadSketches();
        setOpenSightPopup(false);
      } else {
        setLoading(false);
      }
    },
    onError() {
      setLoading(false);
    },
  });



  const [loadCarrerSketchCal] = useLazyQuery(LOAD_CAREER_SKETCHS, {
    onCompleted(response) {
      setLoading(true);
      timeLineActions.assignModulesData(response.loadCompairCareerSketch, (data) => {
        let dashboardNetWorthData = moduleServices.dashBoardCalcsCompleteData();
        if (typeof dashboardNetWorthData !== 'undefined' && dashboardNetWorthData !== ''
          && typeof dashboardNetWorthData['Data'] !== 'undefined' && dashboardNetWorthData['Data'] !== ''
          && typeof dashboardNetWorthData['Data']['Net Worth Graph'] !== 'undefined' && dashboardNetWorthData['Data']['Net Worth Graph'] !== '') {
          var dashBoardOutputSheetCompleteData = dashboardNetWorthData['Data']['Net Worth Graph'];
          var newWorthArray = dashBoardOutputSheetCompleteData['Net Worth'];
          var grossArray = dashBoardOutputSheetCompleteData['Gross Income'];
          var expensesArray = dashBoardOutputSheetCompleteData['Total Expenses'];
          AddSketchData({
            variables: {
              info: {
                "position": passData.index,
                "sketch_id": Number(stepDetails.step4.slug),
                "networth": {
                  "color": passData.code,
                  "name": response.loadCompairCareerSketch.name,
                  "type": "spline",
                  "data": newWorthArray
                },
                "gross": {
                  "color": passData.code,
                  "name": response.loadCompairCareerSketch.name,
                  "type": "spline",
                  "data": grossArray
                },
                "expenses": {
                  "color": passData.code,
                  "name": response.loadCompairCareerSketch.name,
                  "type": "spline",
                  "data": expensesArray
                }
              }
            },
          });

        };
      });
    },
    onError() {
      setLoading(false);

    }
  });


  /*
  *** code Started for load career Sketch
  */

  const loadCareerSketch = () => {
    if (passData.fromType && passData.fromType === "dashboard") {
      let checkRecordExist = _.findIndex(loadSketchList, (sketch) => { return sketch.sketch_id === Number(stepDetails.step4.slug); });
      if (checkRecordExist === -1) {
        setLoading(true);
        loadCarrerSketchCal({ variables: { sketch_id: Number(stepDetails.step4.slug) } });
      } else {
        confirmAlert({
          // eslint-disable-next-line
          customUI: ({ onClose }) => (
            <div className={classes.impPopup}>
              <div>
                <h3><b>Important Tip</b></h3>
                <div className={classNames(classes.impDiv, classes.noPadding)}>
                  <div>
                    <p>You have already added this career sketch.</p>
                  </div>
                </div>
              </div>
              <div className={classNames(classes.impbuttonOkRedo, classes.impbuttonOkDefault)}>
                <button type="button" onClick={onClose}>Close</button>
              </div>
            </div>
          ),
        });

      }
    } else {
      confirmAlert({
        // eslint-disable-next-line
        customUI: ({ onClose }) => (
          <div className={classes.impPopup}>
            <div>
              <h3><b>Load Career Sketch</b></h3>
              <div className={classNames(classes.impDiv, classes.noPadding)}>
                <div>
                  <p>Would you like to clear your Simulator Panel and load the Career Sketch?</p>
                </div>
              </div>
            </div>
            <div className={classNames(classes.impbuttonOkRedo, classes.impbuttonOkDefault)}>
              <button
                type="button"
                onClick={() => { onClose(); setLoading(true); loadCarrerSketchData({ variables: { id: stepDetails.step4.slug } }); }}
              >
                OK
              </button>
              <button type="button" onClick={onClose}>CANCEL</button>
            </div>
          </div>
        ),
      });
    }
  };

  /*
  *** code Ended for load career Sketch
  */

  const [loadScenarioData] = useMutation(LOADSCENARIOAPI, {
    onCompleted({
      loadScenario: {
        status,
        module,
      },
    }) {
      if (status === true) {
        if (passData.from === 'page') {
          history.push(moduleNames[module].url);
        } else {
          if (compareCallback !== null) {
            compareCallback({ slug: stepDetails.step6.slug, title: stepDetails.step6.title, relation_year: sightData.relation_year })
          };
          setLoading(false);
          sightPathCallback('scenario-load');
        }
      } else {
        setLoading(false);
      }
    },
    onError() {
      setLoading(false);
    },
  });

  const loadScenario = async () => {
    if (stepDetails.step3.slug !== 'my_saved_notes' && stepDetails.step3.slug !== 'my_comparisons') {
      const scenario = _.find(stepDetails.step5.data, { id: stepDetails.step6.slug });

      if (scenario.selected_module === 'module1') {
        if (sightData.relation_year !== scenario.relation_year) {
          confirmAlert({
            // eslint-disable-next-line
            customUI: ({ onClose }) => (
              <div className={classes.impPopup}>
                <div>
                  <h3><b>Important Tip</b></h3>
                  <div className={classNames(classes.impDiv, classes.noPadding)}>
                    <div>
                      <p>By loading this scenario, you are changing the start year of your 5, 10, 15 year timeline. Only scenarios that fall within that timeline can be loaded. Are you sure you want to load this scenario and change your current timeline?</p>
                    </div>
                  </div>
                </div>
                <div className={classNames(classes.impbuttonOkRedo, classes.impbuttonOkDefault)}>
                  <button
                    type="button"
                    onClick={() => { onClose(); setLoading(true); loadScenarioData({ variables: { id: scenario.id } }); }}
                  >
                    OK
                  </button>
                  <button type="button" onClick={onClose}>CANCEL</button>
                </div>
              </div>
            ),
          });
        } else {
          setLoading(true);
          await loadScenarioData({ variables: { id: scenario.id } });
          await compareCallback({ slug: stepDetails.step6.slug, title: stepDetails.step6.title, relation_year: sightData.relation_year });

        }
      } else if (scenario.relation_year_status === false) {
        confirmAlert({
          // eslint-disable-next-line
          customUI: ({ onClose }) => (
            <div className={classes.impPopup}>
              <div>
                <h3>Important Tip</h3>
                <div className={classes.impDiv}>
                  <span><img src={alertIcon} alt="" /></span>
                  <div>
                    <p>To load a scenario, the start year (and end year) of that scenario has to fall within the Current Timeline. You set your timeline in the Financial Independence module. To change your timeline, change your start year in the Financial Independence module.</p>
                  </div>
                </div>
              </div>
              <div className={classes.impbuttonOkRedo}>
                <button
                  type="button"
                  onClick={onClose}
                >
                  OK
                </button>
                <button type="button" onClick={onClose} className={classes.hideButton}>CANCEL</button>
              </div>
            </div>
          ),
        });
      } else {
        setLoading(true);
        await loadScenarioData({ variables: { id: scenario.id } });
      }

    } else {
      const scenario = _.find(stepDetails.step5.data, { id: stepDetails.step6.slug });
      const notes = _.find(stepDetails.step5.data, { id: stepDetails.step6.slug });
      setLoading(true);
      if (scenario.relation_year !== undefined) {
        await compareCallback({ relation_year: scenario.relation_year, slug: [scenario.scenario_1, scenario.scenario_2], selected_module: scenario.selected_module })
      } else {
        notesCallBack({ data: notes });
      }
    }
  };

  useEffect(() => {
    if (deleteScenarioStatus === true) {
      const duplicateStepDetails = { ...stepDetails };
      let { scenarios } = duplicateStepDetails.step4;
      scenarios = _.reject(scenarios, { id: stepDetails.step6.slug });
      duplicateStepDetails.step4.scenarios = scenarios;

      manageNavigation('step5', stepDetails.step5.slug, stepDetails.step5.title, duplicateStepDetails);

      setDeleteScenarioStatus(false);
    }
    // eslint-disable-next-line
  }, [deleteScenarioStatus])

  const [deleteScenarioData] = useMutation(DELETESCENARIOAPI, {
    onCompleted(response) {
      setLoading(false);
      if (response.deleteScenario.status === true) {
        const scenarioIds = response.deleteScenario.ids;
        const duplicateSightData = { ...sightData };
        let scenarios = [];
        let dataKey = '';
        if (stepDetails.step3.slug === 'my_saved_notes') {
          scenarios = sightData.notes;
          dataKey = 'notes';
        } else if (stepDetails.step3.slug === 'my_comparisons') {
          scenarios = sightData.compare_scenario_notes;
        } else if (stepDetails.step3.slug === 'my_scenarios' || stepDetails.step2.slug === 'shared') {
          // eslint-disable-next-line
          scenarios = sightData.scenarios;
          dataKey = 'scenarios';
        }

        for (let s = 0; s < scenarioIds.length; s += 1) {
          scenarios = _.reject(scenarios, { id: scenarioIds[s] });
        }
        duplicateSightData[dataKey] = scenarios;
        setSightData(duplicateSightData);
        setDeleteScenarioStatus(true);
      }
    },
    onError() {
      return false;
    },
  });

  const deleteScenario = () => {
    let type = '';
    let title = '';
    const text = 'Are you sure you want to delete?';
    if (stepDetails.step3.slug === 'my_saved_notes') {
      type = 'note';
      title = 'Delete Note';
    } else if (stepDetails.step3.slug === 'my_comparisons') {
      type = 'comparisons';
      title = 'Delete Comparison';
    } else if (stepDetails.step3.slug === 'my_scenarios' || stepDetails.step2.slug === 'shared') {
      type = 'scenarios';
      title = 'Delete Scenario';
    }
    if (type !== '') {
      const id = [stepDetails.step6.slug];
      confirmAlert({
        // eslint-disable-next-line
        customUI: ({ onClose }) => (
          <div className={classes.impPopup}>
            <div>
              <h3><b>{title}</b></h3>
              <div className={classNames(classes.impDiv, classes.noPadding)}>
                <div>
                  <p>{text}</p>
                </div>
              </div>
            </div>
            <div className={classNames(classes.impbuttonOkRedo, classes.impbuttonOkDefault)}>
              <button
                type="button"
                onClick={() => { onClose(); setLoading(true); deleteScenarioData({ variables: { id, type } }); }}
              >
                OK
              </button>
              <button type="button" onClick={onClose}>CANCEL</button>
            </div>
          </div>
        ),
      });
    }
  };

  const editScenario = (scenario) => {
    setScenarioNameType(scenario.id);
    let name = '';
    if (stepDetails.step3.slug === 'my_comparisons' || stepDetails.step3.slug === 'my_scenarios') {
      // eslint-disable-next-line
      name = scenario.name;
    } else {
      name = scenario.note_title;
    }
    let lastVal = '';
    let firstVal = '';
    name = name.split('-');
    lastVal = name[name.length - 1];
    name.pop();
    if (stepDetails.step3.slug !== 'my_saved_notes') {
      if (scenario.selected_module !== 'module18') {
        if (scenario.selected_module === 'module1') {
          firstVal = name[0];
          name.shift();
        } else if (!Number.isNaN(parseInt(name[0], 10))) {
          if (scenario.selected_module === 'module13' || scenario.selected_module === 'module14') {
            if (name[0] === scenario.start_year_relation) {
              firstVal = firstVal[0];
              name.shift();
            }
          } else {
            if (name[0] === scenario.start_year_relation) {
              firstVal = name[0];
              name.shift();
            }
            if (name[0] === scenario.end_year_relation) {
              firstVal = `${firstVal}-${name[0]}`;
              name.shift();
            }
          }
        }
      }
    }
    setEditScenarioData({ first: firstVal, last: lastVal });
    setEditScenarioName(name.join('-'));
  };

  const [updateScenarioNameData] = useMutation(UPDATESCENARIONAMEAPI, {
    onCompleted({
      updateScenario: {
        id,
        name,
      },
    }) {
      const duplicateSightData = { ...sightData };
      let dateKey = '';
      if (stepDetails.step3.slug === 'my_comparisons') {
        dateKey = 'scenarios';
      } else if (stepDetails.step3.slug === 'my_saved_notes') {
        dateKey = 'notes';
      }
      const index = _.findIndex(duplicateSightData[dateKey], { id });
      if (index >= 0) {
        if (dateKey === 'scenarios') {
          duplicateSightData[dateKey][index].name = name;
        } else {
          duplicateSightData[dateKey][index].note_title = name;
        }
      }

      setScenarioNameType('');
      setSightData(duplicateSightData);
      setEditScenarioStaus(false);
      setLoading(false);
    },
    onError(errors) {
      setLoading(false);
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));
      setEditScenarioError(formatedErrors);
    },
  });

  const updateScenarioName = () => {
    if (editScenarioData !== '') {
      let dateKey = '';
      let type = '';
      if (stepDetails.step3.slug === 'my_comparisons') {
        dateKey = 'comparisons';
      } else if (stepDetails.step3.slug === 'my_saved_notes') {
        dateKey = 'notesFolder';
        type = 'notes';
      } else if (stepDetails.step3.slug === 'my_scenarios') {
        dateKey = 'scenarios';
        type = 'scenarios';
      }
      if (dateKey !== '') {
        let name = '';
        if (editScenarioData.first !== undefined && editScenarioData.first !== '') {
          name = `${editScenarioData.first}-`;
        }
        name += editScenarioName;
        if (editScenarioData.last !== undefined && editScenarioData.last !== '') {
          name = `${name}-${editScenarioData.last}`;
        }
        setLoading(true);
        updateScenarioNameData({
          variables: {
            data: {
              name,
              id: scenarioNameType,
              type,
            },
          },
        });
      }
    } else {
      setEditScenarioError('Please enter scenario name');
    }
  };

  useEffect(() => {
    if (editScenarioStaus === false) {
      if (stepDetails.step6 !== undefined && stepDetails.step6 === undefined) {
        manageNavigation('step5', stepDetails.step5.slug, stepDetails.step5.title);
      }
    } else {
      setStepDetails({
        step1: stepDetails.step1, step2: stepDetails.step2, step3: stepDetails.step3, step4: stepDetails.step4, step5: stepDetails.step5,
      });
    }
    setEditScenarioError('');
    setEditScenarioName('');
    // eslint-disable-next-line
  }, [editScenarioStaus])

  useEffect(() => {
    if (createNewFolder === false) {
      if (stepDetails.step3 !== undefined && stepDetails.step4 === undefined) {
        manageNavigation('step3', stepDetails.step3.slug, stepDetails.step3.title);
      }
    } else {
      setStepDetails({ step1: stepDetails.step1, step2: stepDetails.step2, step3: stepDetails.step3 });
    }
    setNewFolderError('');
    setNewFolderName('');
    // eslint-disable-next-line
  }, [createNewFolder])

  const submitScenarioName = (e) => {
    if (e.keyCode === 13) {
      updateScenarioName();
    }
  };

  const submitFolderNameData = (e) => {
    if (e.keyCode === 13) {
      if (passData.from !== undefined && passData.from === 'popup' && passData.type !== undefined && passData.type === 'save' && passData.forType === 'sketch') {
        saveCareerSketchData();
      } else {
        saveFolderName();
      }
    }
  };

  // const [searchDiv, setSearchDiv] = useState(false)

  // useEffect(() => {
  //   if (showSearchData === true) {
  //     setSearchDiv(true);
  //   } else {
  //     setSearchDiv(false);
  //   }
  // }, [showSearchData])

  const shareCareerSketch = () => {
    if (passData.from === 'popup') {
      const sketchId = stepDetails.step4.slug;
      sightPathCallback('sketch-share', { type: 'sketch', id: [sketchId] });
    }
  };
  const autoSelectSearchResults = (type, data) => {
    // console.log("sightData",sightData);

    switch (type) {
      case "folder":
        let folders = sightData.folders;
        folders = _.filter(folders, { inkwiry_directory: data.inkwiry_directory });
        folders = _.filter(folders, { dir_type: data.dir_type });

        switch (data.dir_type) {
          case 'O':
            setStepDetails({
              step1: stepDetails.step1, step2: { slug: 'my', title: 'My' },
              step3: { slug: "my_scenarios", title: "My Scenarios", data: folders },
              step4: { slug: data.id, title: data.name, folder_type: "sketch", scenario: [], data: [] }
            });
            break;
          default:
            break;
        }
        break;
      case "user":
        break;
      case "career_sketch":
        const sketch_type = data.sketch_type;
        let sketchs = sightData.sketches;
        sketchs = _.filter(sketchs, { inkwiry_career_sketch: data.inkwiry_career_sketch });
        sketchs = _.filter(sketchs, { sketch_type });
        switch (sketch_type) {
          case 'U':
            setStepDetails({
              step1: stepDetails.step1, step2: { slug: "my", title: "My" }, step3: { slug: "my_career_sketches", title: "My Career Sketches", data: sketchs },
              step4: { slug: data.id, title: data.name, folder_type: "sketch", scenario: [], data: [] }
            });
            break;
          case 'I':
            setStepDetails({
              step1: stepDetails.step1, step2: { slug: "inkwiry", title: "Inkwiry" }, step3: { slug: "all_sketches", title: "All Sketches", data: sketchs },
              step4: { slug: data.id, title: data.name, folder_type: "sketch", scenario: [], data: [] }
            });
            break;
          case 'P':
            setStepDetails({
              step1: stepDetails.step1, step2: { slug: "inkwiry", title: "Inkwiry" }, step3: { slug: "powerup_sketches", title: "PowerUp Sketches", data: sketchs },
              step4: { slug: data.id, title: data.name, folder_type: "sketch", scenario: [], data: [] }
            });
            break;
          default:
            break;
        }
        break;
      case "scenario":
        let scenarios_s = _.filter(sightData.scenarios, { directory_id: data.directory_id });
        let selectedModules = _.map(scenarios_s, 'selected_module');
        selectedModules = _.uniq(selectedModules);
        let step5Data = selectedModules.map((value) => moduleNames[value]);
        step5Data = _.orderBy(step5Data, ['position'], ['asc']);
        let scenarios_ss = _.filter(scenarios_s, { selected_module: data.selected_module });
        if (data.inkwiry_scenario === 0) {
          let folders_s = sightData.folders;
          folders_s = _.filter(folders_s, { inkwiry_directory: 0 });
          setStepDetails({
            step1: { slug: "fc", title: "Financial Configurator" }, step2: { slug: "my", title: "My" },
            step3: { slug: "my_scenarios", title: "My Scenarios", data: folders_s },
            step4: { slug: data.directory_id, title: data.name, folder_type: "folder", scenario: scenarios_s, data: step5Data },
            step5: { slug: 1, title: "", data: scenarios_ss }
          });
        } else if (data.inkwiry_scenario === 1) {
          let sketch_s = _.filter(sightData.sketches, { sketch_type: "I" })

          setStepDetails({
            step1: { slug: "fc", title: "Financial Configurator" }, step2: { slug: "inkwiry", title: "Inkwiry" },
            step3: { slug: "all_sketches", title: "All Sketches", data: sketch_s },
            step4: { slug: data.directory_id, title: data.name, folder_type: "folder", scenario: scenarios_s, data: step5Data },
            step5: { slug: 1, title: "", data: scenarios_ss }
          });
        }
        break;
      case "notefolder":
        setStepDetails({
          step1: stepDetails.step1, step2: stepDetails.step2,
          step3: { slug: "my_saved_notes", title: "My Saved Notes", data: sightData.notesFolder },
          step4: { slug: data.id, title: data.name, folder_type: "folder", scenarios: [], data: [] }
        });
        break;
      case "note":
        let scenarios_n = _.filter(sightData.notes, { directory_id: data.directory_id });
        let selectedModules_n = _.map(scenarios_n, 'selected_module');
        selectedModules_n = _.uniq(selectedModules_n);
        let step5Data_n = selectedModules_n.map((value) => moduleNames[value]);
        step5Data_n = _.orderBy(step5Data_n, ['position'], ['asc']);
        let notes = _.filter(sightData.notes, { id: data.id })
        let scenarios_nn = _.filter(scenarios_n, { selected_module: data.selected_module });
        setStepDetails({
          step1: { slug: "fc", title: "Financial Configurator" }, step2: { slug: "my", title: "My" },
          step3: { slug: "my_saved_notes", title: "My Saved Notes", data: sightData.notesFolder },
          step4: { slug: data.directory_id, title: "", folder_type: "folder", scenarios: scenarios_n, data: step5Data_n },
          step5: { slug: 1, title: "", data: scenarios_nn },
          step6: { slug: data.id, title: data.note_title, data: notes }
        });
        break;
      default:
        break;
    }
  }
  return (
    <Typography variant="body1" component="div" className={classes.sightPage}>
      {loading && <Pageloader loading={loading} />}
      <div className={classNames(classes.senseBody, classes.sightPopupBlock)} onMouseUp={() => setMouseLeftClick(false)}>
        <div className={classes.explorerHead}>
          <div className={classes.containerFluild}>
            <div className={classes.explorerTitle}>
              <Typography variant="h2" component="h2" id="type">
                Inkwiry Sight
              </Typography>
              <div className={classes.relationYear}>
                <span
                  className={classes.infoIcon}
                  data-for="infoIcon4"
                  data-tip="To load a scenario, the start year (and end year) of that scenario has to fall within the Current Timeline. You set your timeline in the Financial Independence module. To change your timeline, change your start year in the Financial Independence module."
                >
                  <img src={infoIcon} alt="info" />
                </span>
                <p>
                  Current Timeline:{' '}
                  {sightData.relation_year > 0 && sightData.relation_year}
                  {' '}
                  -
                  {' '}
                  {sightData.relation_year > 0 && (sightData.relation_year + 14)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.breadCrumbs}>
          <div className={classNames(classes.breadCrumbsLeft, passData.from === 'popup' && classes.fullBreadcrumbsList)}>
            <div className={classes.folderIcon}>
              <ul>
                <li>
                  <span><img src={folderIcon} alt="folder" /></span>
                </li>
              </ul>
            </div>
            <div className={classNames(classes.breadcrumbsList)}>
              <ul>
                {stepDetails.step1 !== undefined && stepDetails.step1.title !== undefined && (
                  <li>
                    <span aria-hidden="true" onClick={() => { manageNavigation('step1', 'fc', 'Financial Configurator'); }}>{stepDetails.step1.title}</span>
                  </li>
                )}
                {stepDetails.step2 !== undefined && stepDetails.step2.title !== undefined && (
                  <li>
                    <span aria-hidden="true" onClick={() => { manageNavigation('step2', stepDetails.step2.slug, stepDetails.step2.title); }}>{stepDetails.step2.title}</span>
                  </li>
                )}
                {stepDetails.step3 !== undefined && stepDetails.step3.title !== undefined && (
                  <li>
                    <span aria-hidden="true" onClick={() => { manageNavigation('step3', stepDetails.step3.slug, stepDetails.step3.title); }}>{stepDetails.step3.title}</span>
                  </li>
                )}
                {stepDetails.step4 !== undefined && stepDetails.step4.title !== undefined && (
                  <li>
                    <span aria-hidden="true" onClick={() => { manageNavigation('step4', stepDetails.step4.slug, stepDetails.step4.title, stepDetails.step4.folder_type); }}>{stepDetails.step4.title}</span>
                  </li>
                )}
                {stepDetails.step5 !== undefined && stepDetails.step5.title !== undefined && (
                  <li>
                    <span aria-hidden="true" onClick={() => { manageNavigation('step5', stepDetails.step5.slug, stepDetails.step5.title); }}>{stepDetails.step5.title}</span>
                  </li>
                )}
                {stepDetails.step6 !== undefined && stepDetails.step6.title !== undefined && (
                  <li>
                    <span aria-hidden="true" onClick={() => { manageNavigation('step6', stepDetails.step6.slug, stepDetails.step6.title); }}>{stepDetails.step6.title}</span>
                  </li>
                )}
              </ul>
            </div>
          </div>
          {passData.from !== undefined && passData.from === 'page' && (
            <div className={classes.breadCrumbsRight}>
              <input className={classes.searchInput} value={searchText} onChange={(e) => { setSearchText(e.target.value); }} type="text" onKeyUp={searchData} placeholder="Search here" />
              <Button className={classes.searchButton} onClick={searchData} />
            </div>
          )}
        </div>
        <div className={classes.explorerMainContent}>
          <div className={classes.explorerContentIn}>
            <div className={classNames(classes.explorerContent, classes.exploreOne)}>
              <Typography variant="h2" component="h2">Application</Typography>
              <Scrollbars renderThumbVertical={renderThumb} className={classNames(classes.MainMenuNew, classes.manageScrollbar)} style={{ height: hight }}>
                <ul>
                  <li><span aria-hidden="true" onClick={() => { manageNavigation('step1', 'fc', 'Financial Configurator'); }} className={stepDetails.step1 !== undefined && stepDetails.step1.slug === 'fc' && classes.activeTab}>Financial Configurator</span></li>
                </ul>
              </Scrollbars>
            </div>
            {stepDetails.step1 !== undefined && stepDetails.step1.slug === 'fc' && (
              <div className={classNames(classes.explorerContent, classes.exploreTwo)}>
                <Typography variant="h2" component="h2">
                  Path
                  <span
                    className={classes.infoImage}
                    data-for="infoIcon"
                    data-tip="There are three paths in Inkwiry: My, Shared, and Inkwiry. In the 'My' path, you will find all scenarios and career sketches that you have created. In the 'Shared' path, you will find all scenarios and career sketches that were shared with you. Lastly, in the 'Inkwiry' path, you will find all scenarios and career sketches that Inkwiry has created for you."
                  >
                    <img src={infoIcon} alt="info" />
                  </span>
                  <ReactTooltip id="infoIcon" place="right" type="info" effect="solid" className={classes.tooltipInfo} />
                </Typography>
                <Scrollbars renderThumbVertical={renderThumb} className={classNames(classes.MainMenuNew, classes.manageScrollbar)} style={{ height: hight }}>
                  <ul>
                    <li>
                      <span aria-hidden="true" onClick={() => { manageNavigation('step2', 'my', 'My'); }} className={stepDetails.step2 !== undefined && stepDetails.step2.slug === 'my' && classes.activeTab}>
                        <img src={myDetails && myDetails.me.profile_image ? IMAGE_PATH + myDetails.me.profile_image : dummyProfile} alt="profile" />
                        {myDetails && myDetails.me.user_fname && myDetails.me.user_fname}
                      </span>
                    </li>
                    {showData.show_shared === true && (
                      <li>
                        <span aria-hidden="true" onClick={() => { manageNavigation('step2', 'shared', 'Shared'); }} className={stepDetails.step2 !== undefined && stepDetails.step2.slug === 'shared' && classes.activeTab}>
                          <img src={shareIcon} alt="info" />
                          Shared
                        </span>
                      </li>
                    )}
                    {showData.show_inkwiry === true && (
                      <li>
                        <span aria-hidden="true" onClick={() => { manageNavigation('step2', 'inkwiry', 'Inkwiry'); }} className={stepDetails.step2 !== undefined && stepDetails.step2.slug === 'inkwiry' && classes.activeTab}>
                          <img src={inkwiryIcon} alt="info" />
                          Inkwiry
                        </span>
                      </li>
                    )}
                  </ul>
                </Scrollbars>
              </div>
            )}
            {stepDetails.step2 !== undefined && stepDetails.step2.slug !== '' && (
              <div className={classNames(classes.explorerContent, classes.exploreThird)}>
                {stepDetails.step2.slug === 'shared' ? (
                  <Typography variant="h2" component="h2">User</Typography>
                ) : (
                  <Typography variant="h2" component="h2">Category</Typography>
                )}
                {stepDetails.step2.slug === 'my' && (
                  <Scrollbars renderThumbVertical={renderThumb} className={classNames(classes.MainMenuNew, classes.manageScrollbar)} style={{ height: hight }}>
                    <ul>
                      {showData.show_my_career_sketches === true && (
                        <li>

                          <span aria-hidden="true" onClick={() => { manageNavigation('step3', 'my_career_sketches', 'My Career Sketches'); }} className={stepDetails.step3 !== undefined && stepDetails.step3.slug === 'my_career_sketches' && classes.activeTab}>
                            <img src={userIcon} alt="info" />
                            My Career Sketches
                          </span>
                        </li>
                      )}
                      {showData.show_my_scenarios === true && (
                        <li>
                          <span aria-hidden="true" onClick={() => { manageNavigation('step3', 'my_scenarios', 'My Scenarios'); }} className={stepDetails.step3 !== undefined && stepDetails.step3.slug === 'my_scenarios' && classes.activeTab}>
                            <img src={folderIcon} alt="info" />
                            My Scenarios
                          </span>
                        </li>
                      )}
                      {showData.show_my_saved_notes === true && (
                        <li>
                          <span aria-hidden="true" onClick={() => { manageNavigation('step3', 'my_saved_notes', 'My Saved Notes'); }} className={stepDetails.step3 !== undefined && stepDetails.step3.slug === 'my_saved_notes' && classes.activeTab}>
                            <img src={folderIcon} alt="info" />
                            My Saved Notes
                          </span>
                        </li>
                      )}
                      {showData.show_my_comparisions === true && (
                        <li>
                          <span aria-hidden="true" onClick={() => { manageNavigation('step3', 'my_comparisons', 'My Comparisions'); }} className={stepDetails.step3 !== undefined && stepDetails.step3.slug === 'my_comparisons' && classes.activeTab}>
                            <img src={folderIcon} alt="info" />
                            My Comparisions
                          </span>
                        </li>
                      )}
                    </ul>
                  </Scrollbars>
                )}
                {stepDetails.step2.slug === 'inkwiry' && (
                  <Scrollbars renderThumbVertical={renderThumb} className={classNames(classes.MainMenuNew, classes.manageScrollbar)} style={{ height: hight }}>
                    <ul>
                      <li>
                        <span aria-hidden="true" onClick={() => { manageNavigation('step3', 'all_sketches', 'All Sketches'); }} className={stepDetails.step3 !== undefined && stepDetails.step3.slug === 'all_sketches' && classes.activeTab}>
                          <img src={userIcon} alt="info" />
                          All Sketches
                        </span>
                      </li>
                      <li>
                        <span aria-hidden="true" onClick={() => { manageNavigation('step3', 'powerup_sketches', 'PowerUp Sketches'); }} className={stepDetails.step3 !== undefined && stepDetails.step3.slug === 'powerup_sketches' && classes.activeTab}>
                          <img src={userIcon} alt="info" />
                          PowerUp Sketches
                        </span>
                      </li>
                      {showData.show_default_scenario === true && (
                        <li>
                          <span aria-hidden="true" onClick={() => { manageNavigation('step3', 'default_scenarios', 'Default Scenarios'); }} className={stepDetails.step3 !== undefined && stepDetails.step3.slug === 'default_scenarios' && classes.activeTab}>
                            <img src={folderIcon} alt="info" />
                            Default Scenarios
                          </span>
                        </li>
                      )}
                    </ul>
                  </Scrollbars>
                )}
                {
                  stepDetails.step2.slug === 'shared' && (
                    <Scrollbars renderThumbVertical={renderThumb} className={classNames(classes.MainMenuNew, classes.manageScrollbar)} style={{ height: hight }}>
                      <ul>
                        {
                          stepDetails.step2.shared_users.map((user) => (
                            <li>
                              <span aria-hidden="true" onClick={() => { manageNavigation('step3', user.id, user.user_fname); }} className={stepDetails.step3 !== undefined && stepDetails.step3.slug === user.id && classes.activeTab}>
                                <img src={(user.profile_image !== null && user.profile_image !== '') ? IMAGE_PATH + user.profile_image : dummyProfile} alt="profile" />
                                {user.user_fname}
                              </span>
                            </li>
                          ))
                        }
                      </ul>
                    </Scrollbars>
                  )
                }
                {passData.from === 'page' && stepDetails.step2.slug === 'shared' && stepDetails.step3 !== undefined && stepDetails.step3.slug !== '' && (
                  <div className={classes.addNewBtn}>
                    <ul>
                      <li>
                        <Button
                          className={classNames(classes.minusBtn, classes.circlrBtn)}
                          data-for="infoLeftIcon"
                          data-tip="Delete"
                          onClick={deleteSharedUser}
                        >
                          -
                        </Button>
                      </li>
                      <li />
                    </ul>
                    <ReactTooltip id="infoLeftIcon" place="top" type="info" effect="solid" className={classes.tooltipInfo} />
                  </div>
                )}

              </div>
            )}

            {stepDetails.step3 !== undefined && stepDetails.step3.slug !== '' && (

              <div className={classNames(classes.explorerContent, classes.scrollMenu)}>

                {(stepDetails.step3.slug === 'my_career_sketches' || stepDetails.step3.slug === 'all_sketches' || stepDetails.step3.slug === 'powerup_sketches') ? (
                  <Typography variant="h2" component="h2">
                    Sketch Name
                    <span
                      className={classes.infoImage}
                      data-for="infoIcon2"
                      data-position="left"
                      data-tip="Career sketches can be created and saved on the Simulator Panel. A career sketch is a combination of scenarios – only 1 scenario per module – that makes up a complete 5, 10, 15 year plan. When saved, a career sketch can be loaded, which will load all the scenarios that make up that sketch."
                    >
                      <img src={infoIcon} alt="info" />
                    </span>
                    <ReactTooltip id="infoIcon2" place="left" type="info" effect="solid" className={classes.tooltipInfo} />
                  </Typography>
                ) : (
                  <Typography variant="h2" component="h2">
                    {stepDetails.step2.slug === 'shared' ? 'Folder / Sketch' : 'Folder'}
                    {(stepDetails.step3.slug === 'my_scenarios' || stepDetails.step3.slug === 'default_scenarios') && (
                      <span
                        className={classes.infoImage}
                        data-for="infoIcon2"
                        data-position="left"
                        data-tip="Folders are created to store the scenarios that you build. Just like saving any file on your computer, you save a scenario to a folder. You can save an unlimited number of scenarios from any module to a folder."
                      >
                        <img src={infoIcon} alt="info" />
                        <ReactTooltip id="infoIcon2" place="left" type="info" effect="solid" className={classes.tooltipInfo} />
                      </span>
                    )}
                    {stepDetails.step3.slug === 'my_saved_notes' && (
                      <span
                        className={classes.infoImage}
                        data-for="infoIcon2"
                        data-position="left"
                        data-tip="Folders are created to store the note that you create. Just like saving any file on your computer, you can save a note to a folder. You can save an unlimited number of notes from any module to a folder."
                      >
                        <img src={infoIcon} alt="info" />
                        <ReactTooltip id="infoIcon2" place="left" type="info" effect="solid" className={classes.tooltipInfo} />
                      </span>
                    )}
                    {stepDetails.step3.slug === 'my_comparisons' && (
                      <span
                        className={classes.infoImage}
                        data-for="infoIcon2"
                        data-position="left"
                        data-tip="Folders are created to store the comparison that you build. Just like saving any file on your computer, you can save a comparison to a folder. You can save an unlimited number of comparisons from any module to a folder."
                      >
                        <img src={infoIcon} alt="info" />
                        <ReactTooltip id="infoIcon2" place="left" type="info" effect="solid" className={classes.tooltipInfo} />
                      </span>
                    )}
                  </Typography>
                )}
                <Scrollbars renderThumbVertical={renderThumb} className={classNames(classes.MainMenuNew, classes.manageScrollbar)} style={{ height: hight }}>
                  <ul>
                    {(createNewFolder === true || (passData.from !== undefined && passData.from === 'popup' && passData.type !== undefined && passData.type === 'save' && passData.forType === 'sketch')) && (
                      <li className={classes.createNewFolder} id="section1">
                        <p >
                          {createNewFolder === true ? (
                            <input type="text" autoFocus placeholder="New Folder Name" onKeyUp={submitFolderNameData} value={newFolderName} onChange={(e) => { setNewFolderName(e.target.value); }} />
                          ) : (
                            <input type="text" autoFocus placeholder="Create New" onKeyUp={submitFolderNameData} value={newFolderName} onChange={(e) => { setNewFolderName(e.target.value); }} onFocus={() => { manageNavigation('step3', 'my_career_sketches', 'My Career Sketches'); }} />
                          )}
                          {newFolderError !== '' && (
                            <p className={classes.dangerText}>{newFolderError}</p>
                          )}
                        </p>
                      </li>
                    )}
                    {
                      stepDetails.step3.data.map((sketch) => (
                        (folderNameType === sketch.id ? (
                          <li>
                            <span className={classes.activeTab} aria-hidden="true">
                              {sketch.inkwiry_career_sketch !== undefined ? (
                                <img src={csIcon} alt="info" />
                              ) : (
                                <img src={folderIcon} alt="info" />
                              )}

                              <input type="text" onKeyUp={submitFolderNameData} value={newFolderName} onChange={(e) => { setNewFolderName(e.target.value); }} />
                              <small className={classes.saveFolder} aria-hidden="true" onClick={saveFolderName}>
                                <i />
                              </small>
                              {newFolderError !== '' && (
                                <p className={classes.dangerText}>{newFolderError}</p>
                              )}
                            </span>
                          </li>
                        ) : (
                          <li>
                            {sketch.inkwiry_career_sketch !== undefined ? (
                              <span className={stepDetails.step4 !== undefined && stepDetails.step4.slug === sketch.id && classes.activeTab} aria-hidden="true" onClick={() => { manageNavigation('step4', sketch.id, sketch.name, 'sketch'); }}>
                                {/* <img src={ sightImages( `./${sketch.sketch_image}` ) } alt="info" /> */}
                                {sketch.name}
                              </span>
                            ) : (
                              <span className={stepDetails.step4 !== undefined && stepDetails.step4.slug === sketch.id && classes.activeTab} aria-hidden="true" onClick={() => { manageNavigation('step4', sketch.id, sketch.name, 'folder'); }}>
                                <img src={folderIcon} alt="info" />
                                {sketch.name}
                              </span>
                            )}

                            {passData.from !== undefined && passData.from === 'page' && stepDetails.step2.slug === 'my' && stepDetails.step4 !== undefined && stepDetails.step4.slug === sketch.id && (
                              <small aria-hidden="true" onClick={() => { setFolderNameType(sketch.id); setNewFolderName(sketch.name); }} className={classes.smallText}><i className="fa fa-edit" /></small>
                            )}
                          </li>
                        ))
                      ))
                    }

                  </ul>
                </Scrollbars>

                {((stepDetails.step2.slug !== 'inkwiry' || (stepDetails.step4 !== undefined && stepDetails.step4.slug !== '' && stepDetails.step4.folder_type === 'sketch')) && showData.show_career_sketche_options === true) ? (
                  <div className={classes.addNewBtn}>
                    {(createNewFolder === true && folderNameType === 'new') ? (
                      <ul>
                        <li>
                          <Button
                            className={classNames(classes.cancelBtn, classes.manageBtn)}
                          >
                            CANCEL
                          </Button>
                        </li>
                        <li>
                          <Button
                            className={classNames(classes.manageBtn)}
                            onClick={saveFolderName}
                          >
                            SAVE
                          </Button>
                        </li>
                      </ul>
                    ) : (
                      <ul>
                        <li>
                          {((stepDetails.step4 !== undefined && stepDetails.step4.slug !== '' && stepDetails.step2.slug !== 'shared') || (stepDetails.step2.slug === 'shared' && stepDetails.step4 !== undefined && stepDetails.step4.folder_type === 'sketch')) && stepDetails.step2.slug !== 'inkwiry' && (
                            <Button
                              className={classNames(classes.minusBtn, classes.circlrBtn)}
                              onClick={deleteSketchOrFolder}
                              data-for="infoLeftIcon"
                              data-tip="Delete"
                            >
                              -
                              <ReactTooltip id="infoLeftIcon" place="top" type="info" effect="solid" className={classes.tooltipInfo} />
                            </Button>
                          )}
                        </li>
                        <li>
                          {(stepDetails.step4 !== undefined && stepDetails.step4.slug !== '' && stepDetails.step4.folder_type === 'sketch') && (
                            <button className={classes.loadBtn} type="button" onClick={loadCareerSketch}>
                              Load
                            </button>
                          )}
                          {(stepDetails.step3.slug !== 'my_career_sketches' && stepDetails.step2.slug === 'my') && (
                            <Button
                              className={classNames(classes.plusBtn, classes.circlrBtn)}
                              data-for="infoLeftIconplus"
                              data-tip="Create New"
                              onClick={handleNewFolder}
                            >
                              +
                              <ReactTooltip id="infoLeftIconplus" place="top" type="info" effect="solid" className={classes.tooltipInfo} />
                            </Button>
                          )}
                        </li>
                      </ul>
                    )}
                  </div>
                ) : (
                  (passData.forType !== undefined && passData.forType === 'sketch' && (
                    <div className={classes.addNewBtn}>
                      <ul>
                        <li>
                          &nbsp;
                        </li>
                        <li>
                          {passData.forType !== undefined && passData.type === 'load' && (
                            <Button
                              className={classNames(classes.manageBtn)}
                              onClick={loadCareerSketch}
                            >
                              LOAD
                            </Button>
                          )}
                          {passData.forType !== undefined && passData.type === 'share' && (
                            <Button
                              className={classNames(classes.manageBtn)}
                              onClick={shareCareerSketch}
                            >
                              SHARE
                            </Button>
                          )}
                          {passData.forType !== undefined && passData.type === 'save' && (
                            <Button
                              className={classNames(classes.manageBtn)}
                              onClick={saveCareerSketchData}
                            >
                              SAVE
                            </Button>
                          )}
                          {passData.forType !== undefined && passData.type === 'delete' && (
                            <Button
                              className={classNames(classes.cancelBtn, classes.manageBtn)}
                              onClick={deleteSketchOrFolder}
                            >
                              DELETE
                            </Button>
                          )}
                        </li>
                      </ul>
                    </div>
                  ))
                )}

              </div>
            )}
            {showData.step5 === true && stepDetails.step4 !== undefined && stepDetails.step4.data !== undefined && stepDetails.step4.slug !== '' && (
              <div className={classNames(classes.explorerContent)}>
                {/* {console.log("phrudhvi", stepDetails)} */}
                <Typography variant="h2" component="h2">
                  Modules
                </Typography>
                <Scrollbars renderThumbVertical={renderThumb} className={classNames(classes.MainMenuNew, classes.manageScrollbar)} style={{ height: hight }}>
                  <ul>
                    {stepDetails.step4.data.map((module) => (
                      // , passData.module !== undefined && passData.module !== '' && `module${module.position}` !== passData.module && classes.inActiveModule
                      <li className={(passData.module !== undefined && passData.module !== '' && `module${module.position}` !== passData.module && classes.inActiveModule)}>
                        {/* {console.log("chandrakala", stepDetails)} */}
                        {
                          module.name === moduleName && !activeStep5 ?
                            manageNavigation('step5', module.position, module.name) : ""
                        }
                        {(module.name === moduleName && !activeStep5) ?
                          setActiveStep5(true)
                          : ""
                        }
                        <span className={module.name === moduleName ? classes.activeTab : ""} aria-hidden="true" onClick={() => { manageNavigation('step5', module.position, module.name); }}>
                          <img src={fileIcon} alt="info" />
                          {module.name}
                        </span>

                      </li>
                    ))}
                  </ul>
                </Scrollbars>
              </div>
            )}
            {showSearchData === true && (
              <div id="myDiv" className="dragBox" ref={ref}>
                <header onMouseMove={_onMouseMove} onMouseDown={() => setMouseLeftClick(true)} onMouseUp={() => setMouseLeftClick(false)}>
                  <h2 id="myDivheader">
                    Search Results
                    <span aria-hidden="true" onClick={() => { setShowSearchData(false); }}>X</span>
                    <small className={classes.dragTag}></small>
                  </h2>
                </header>
                {/* {console.log("resultSearchData",resultSearchData)} */}
                <div id="mDivfooter">
                  {resultSearchData.sketches !== undefined && resultSearchData.sketches.length > 0 && (
                    <ul>
                      <li>Career Sketches</li>
                      {resultSearchData.sketches.map((sketch) => (
                        <li onClick={() => autoSelectSearchResults("career_sketch", sketch)}>
                          <span>{sketch.name}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                  {resultSearchData.folders !== undefined && resultSearchData.folders.length > 0 && (
                    <ul>
                      <li>Folders</li>
                      {resultSearchData.folders.map((folder) => (
                        <li onClick={() => autoSelectSearchResults("folder", folder)}>
                          <span>{folder.name}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                  {resultSearchData.sharedUsers !== undefined && resultSearchData.sharedUsers.length > 0 && (
                    <ul>
                      <li>Shared Users</li>
                      {resultSearchData.sharedUsers.map((user) => (
                        <li onClick={() => autoSelectSearchResults("user", user)}>
                          <span>{user.user_fname}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                  {resultSearchData.scenarios !== undefined && resultSearchData.scenarios.length > 0 && (
                    <ul>
                      <li>Scenarios</li>
                      {resultSearchData.scenarios.map((scenario) => (
                        <li onClick={() => autoSelectSearchResults("scenario", scenario)}>
                          <span>{scenario.name}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                  {resultSearchData.notesFolder !== undefined && resultSearchData.notesFolder.length > 0 && (
                    <ul>
                      <li>Note Floders</li>
                      {resultSearchData.notesFolder.map((notefolder) => (
                        <li onClick={() => autoSelectSearchResults("notefolder", notefolder)}>
                          <span>{notefolder.name}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                  {resultSearchData.notes !== undefined && resultSearchData.notes.length > 0 && (
                    <ul>
                      <li>Notes</li>
                      {resultSearchData.notes.map((note) => (
                        <li onClick={() => autoSelectSearchResults("note", note)}>
                          <span>{note.note_title}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                  {resultSearchData.notes !== undefined && resultSearchData.notes.length === 0 && resultSearchData.notesFolder.length === 0 && resultSearchData.scenarios.length === 0 && resultSearchData.sharedUsers.length === 0 && resultSearchData.folders.length === 0 && resultSearchData.sketches.length === 0 && (
                    <ul>
                      <li>No Results</li>
                    </ul>
                  )}
                </div>
                <button type="button" className="dragButton" onClick={() => { setShowSearchData(false); }}>Close</button>
              </div>
            )}
            {showData.step6 === true && stepDetails.step5 !== undefined && stepDetails.step5.slug !== '' && (
              <div className={classNames(classes.explorerContent, classes.scenariosContent)}>
                <Typography variant="h2" component="h2">
                  Scenarios
                  <span
                    className={classes.infoImage}
                    data-for="infoIcon3"
                    data-tip=" Click this icon to see how a scenario name is built and what that scenario name means in the FC. "
                  >
                    <img src={infoIcon} alt="info" />
                  </span>
                  <ReactTooltip id="infoIcon3" place="bottom" type="info" effect="solid" className={classes.tooltipInfo} />
                </Typography>
                <Scrollbars renderThumbVertical={renderThumb} className={classNames(classes.MainMenuNew, classes.manageScrollbar)} style={{ height: hight }}>
                  <ul>
                    {stepDetails.step5.data.map((scenario) => (
                      (scenarioNameType === scenario.id) ? (
                        <li>
                          <span className={classes.activeTab} aria-hidden="true">
                            <img src={fileIcon} alt="info" />
                            <input type="text" onKeyUp={submitScenarioName} value={editScenarioName} onChange={(e) => { setEditScenarioName(e.target.value); }} />
                            <small className={classes.saveFolder} aria-hidden="true" onClick={updateScenarioName}>
                              <i />
                            </small>
                            {editScenarioError !== '' && (
                              <p className={classes.dangerText}>{editScenarioError}</p>
                            )}
                          </span>
                        </li>
                      ) : (
                        <li className={scenario.selected_module !== 'module1' && scenario.relation_year_status !== undefined && scenario.relation_year_status === false && classes.inActiveModule}>
                          <span className={stepDetails.step6 !== undefined && stepDetails.step6.slug === scenario.id && classes.activeTab} aria-hidden="true" onClick={() => { manageNavigation('step6', scenario.id, scenario.name, '', scenario); }}>
                            <img src={fileIcon} alt="info" />
                            {stepDetails.step3.slug === 'my_saved_notes' ? scenario.note_title : scenario.name}
                          </span>
                          {passData.from === 'page' && stepDetails.step2.slug === 'my' && stepDetails.step3.slug !== 'my_career_sketches' && stepDetails.step6 !== undefined && stepDetails.step6.slug === scenario.id && (
                            <small aria-hidden="true" onClick={() => { editScenario(scenario); }} className={classes.smallText}><i className="fa fa-edit" /></small>
                          )}
                        </li>
                      )
                    ))}
                  </ul>
                </Scrollbars>
                {stepDetails.step6 !== undefined && stepDetails.step6.slug !== '' && (
                  <div className={classes.addNewBtn}>
                    <ul>
                      <li>
                        {((stepDetails.step2.slug === 'my' && stepDetails.step3.slug !== 'my_career_sketches') || (stepDetails.step2.slug === 'shared' && stepDetails.step4 !== undefined && stepDetails.step4.folder_type === 'folder')) && (
                          <Button
                            className={classNames(classes.minusBtn, classes.circlrBtn)}
                            data-for="infoLeftIcon"
                            data-tip="Delete"
                            onClick={deleteScenario}
                          >
                            -
                            <ReactTooltip id="infoLeftIcon" place="top" type="info" effect="solid" className={classes.tooltipInfo} />
                          </Button>
                        )}
                      </li>
                      <li>
                        <button className={classes.loadBtn} type="button" onClick={loadScenario}>
                          Load
                        </button>
                      </li>
                    </ul>
                  </div>
                )}

              </div>
            )}

          </div>
        </div>
      </div>
      <ReactTooltip id="infoIcon2" place="left" type="info" effect="solid" className={classes.tooltipInfo} />
      <ReactTooltip id="infoIcon3" place="bottom" type="info" effect="solid" className={classes.tooltipInfo} />
      <ReactTooltip id="infoIcon4" place="left" type="info" effect="solid" className={classes.tooltipInfo} />

    </Typography>
  );
};

Sight.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  passData: PropTypes.object.isRequired,
  sightPathCallback: PropTypes.func,
  compareCallback: PropTypes.func.isRequired,
  notesCallBack: PropTypes.func.isRequired,
  getLoadSketches: PropTypes.func,
  setOpenSightPopup: PropTypes.func,
  loadSketchList: PropTypes.array
};

Sight.defaultProps = {
  sightPathCallback: null,
  compareCallback: null
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(Sight);
