const styles = ( theme ) => ( {
  containerFluid: {
    maxWidth: '100%',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  img: {
    verticalAlign: 'middle',
  },
  div: {
    boxSizing: 'border-box',
  },
  pfBudgetPage: {
    textAlign: 'center',
    position: 'relative',
    maxWidth: '850px',
    margin: '0 auto',
    paddingLeft: '100px',
    paddingRight: '100px',
    paddingTop: '10px',
    display: 'table',
    fontFamily: '"MuseoSans-100"',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      paddingLeft: '0px',
      paddingRight: '0px',
      maxWidth: '650px',
    },
    '@media (max-width: 700px)': {
      paddingLeft: '0px',
      paddingRight: '0px',
      maxWidth: '90%',
      display: 'block',
    },
  },
  breadCrumbs: {
    position: 'relative',
    zIndex: '999',
    listStyle: 'none',
    display: 'flex',
    marginBottom: '0',
    padding: '0 15px 15px 0',
    marginTop: '0px',
    '& li': {
      listStyle: 'none',
      fontSize: '14px',
      [theme.breakpoints.down( 'sm' )]: {
        paddingLeft: '10px',       
      },
      '& a': {
        color: '#333',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '& span': {
        '&:first-child': {
          // padding: '0 5px',
          fontSize: '18px',
          lineHeight: '18px',
          color: '#000',
        },
      },
    },
  },
  blueRow: {
    background: '#0069aa',
    paddingTop: '15px',
  },
  logoBlock: {
    background: '#fff',
    padding: '20px 0',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '20px 0 0px 0px',
    },
    '& img': {
      maxWidth: '300px',
      cursor: 'pointer',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '260px',
      },
    },
  },
  pfHeading: {
    '& h3': {
      margin: '0',
      padding: '30px 0',
      fontSize: '29px',
      color: '#0069aa',
      fontWeight: '500',
      fontFamily: '"MuseoSans-100"',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '24px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        padding: '0px 0px 30px 0px',
      },
    },
  },
  pfVideo: {
    '& iframe': {
      border: '0',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
  },
  kickStart: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '640px',
    justifyContent: 'center',
    '& p': {
      color: '#000',
      fontSize: '22px',
      textAlign: 'left',
      display: 'block',
      justifyContent: 'center',
      paddingTop: '20px',
      fontFamily: '"MuseoSans-300"',
      margin: '0 auto',
      '& span': {
        display: 'block',
        [theme.breakpoints.down( 'xs' )]: {
          display: 'inline',
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '14px',
      },
    },
  },
  continueButton: {
    background: '#0069aa',
    borderColor: '#0069aa',
    marginTop: '10px',
    marginLeft: '0px',
    padding: '12px 22px',
    marginBottom: '15px',
    fontFamily: '"MuseoSans-100"',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '6px',
    border: '2px solid #f89b22',
    lineHeight: 'inherit',
    color: '#fff',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    display: 'inline-block',
    '&:hover': {
      border: '2px solid #d5d5d5',
      color: '#fff',
      background: '#0069aa',
    },
    '&:disabled': {
      cursor: 'no-drop',
      pointerEvents: 'all',
      background: '#d5d5d5',
      borderColor: '#d5d5d5',
      color: '#333333',
      opacity: 0.65,
      '&:hover': {
        background: '#d5d5d5',
        borderColor: '#d5d5d5',
      },
    },
  },
  accessLink: {
    fontSize: '14px',
    marginBottom: '35px',
    fontFamily: '"MuseoSans-100"',
    marginTop: '0px',
    color: '#000',
    '& a': {
      textDecoration: 'underline',
    },
  },
  tomorrowsIcon: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down( 'xs' )]: {
      justifyContent: 'center',
    },
    '& p': {
      fontSize: '20px',
      fontWeight: '100',
      color: '#fff',
      margin: '0',
      lineHeight: '35px',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginRight: '10px',
        maxWidth: '62px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '12px',
      },
    },
  },
  footerBlock: {
    background: '#000',
    bottom: '0',
    color: '#fff',
    float: 'left',
    fontSize: '14px',
    height: '45px',
    left: '0',
    padding: '0px 0',
    position: 'static',
    width: '100%',
    [theme.breakpoints.down( 'sm' )]: {
      height: 'auto',
    },
    '& container': {
      height: '100%',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      [theme.breakpoints.down( 'xs' )]: {
        justifyContent: 'center',
      },
    },
  },
  copyRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down( 'xs' )]: {
      justifyContent: 'center',
    },
    '& p': {
      fontSize: '16px',
      margin: '0',
      padding: '0px 0 0',
      textAlign: 'right',
      lineHeight: '35px',
      color: '#fff',
      fontWeight: '100',
      display: 'flex',
      alignItems: 'center',
      '& a': {
        display: 'flex',
        '& img': {
          marginLeft: '8px',
          maxWidth: '100px',
        },
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '11px !important',
      },
    },
  },
  copyRightText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      fontSize: '13px',
      margin: '0',
      padding: '0px 0 0',
      textAlign: 'center',
      lineHeight: '35px',
      color: '#fff',
      fontWeight: '100',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '11px !important',
      },
    },
  },
  gridRow: {
    height: '100%',
  },
  pfRow: {
    display: 'flex',
    textAlign: 'left',
    color: '#000',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',      
    },
  },
  fiRow: {
    display: 'flex',
    textAlign: 'left',
    color: '#000',
    flexWrap: 'wrap',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
    '& ul': {
      paddingLeft: '25px',
      margin: '0px',
      '& li': {
        fontSize: '18px',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '14px',
        },
      },
    },
  },
  pfImage: {
    paddingTop: '0px',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0 auto 10px auto',
    },
  },
  pfImageBorder: {
    border: '1px solid #337ab7',
  },
  maImage: {
    paddingTop: '20px',
    border: 'none',
    '& img': {
      width: '100%',
      border: 'none',
    },
  },
  pfSlideImage: {
    paddingTop: '8px',
    '& img': {
      width: 'auto',
    },
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0 auto 10px auto',
    },
  },
  pfText: {
    paddingLeft: '20px',
    fontSize: '18px',
    lineHeight: '1.5',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '0px',
      fontSize: '14px',
    },
  },
  coverAnything: {
    fontSize: '20px',
    marginBottom: '18px',
    margin: '0 0 10px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
    },
    '& b': {
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
  },
  pfList: {
    marginBottom: '25px',
    marginTop: '0px',
    '& li': {
      fontSize: '18px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '14px',
      },
    },
  },
  pfContent: {
    boxSizing: 'border-box',
    '@media screen and (min-width: 600px) and (max-width: 767px)': {
      padding: '0 15px',
    },  
    '& > div': {
      boxSizing: 'border-box',
    },
  },
  creditText: {
    fontSize: '18px',
    paddingLeft: '0', 
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '14px',
    },   
    '& p': {
      marginBottom: '18px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '14px',
      },
    },
    '& b': {
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
    '& noBottomMargin': {
      marginBottom: '0px',
    },
    '& ul': {
      [theme.breakpoints.down( 'xs' )]: {
        paddingLeft: '30px',
        '& li': {
          marginBottom: '5px',
          '&:last-child': {
            marginBottom: '0px',
          },
        },
      },
    },
    '& ol': {
      [theme.breakpoints.down( 'xs' )]: {
        paddingLeft: '30px',
        '& li': {
          marginBottom: '5px',
          '&:last-child': {
            marginBottom: '0px',
          },
        },
      },
    },
  },
  b: {
    fontWeight: 'normal',
    fontFamily: '"MuseoSans-500"',
  },
  noBottomMargin: {
    marginBottom: '0px !important',
  },
  grayBox: {
    background: '#f5f5f5',
    padding: '30px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px',
    },
    '& p': {
      margin: '0 0 10px',
      '& b': {
        fontFamily: '"MuseoSans-500"',
        fontWeight: 'normal',
      },
    },
    '& b': {
      fontFamily: '"MuseoSans-500"',
      fontWeight: 'normal',
    },
  },
  lightGallery: {
    margin: '30px 0',
    cursor: 'pointer',
    [theme.breakpoints.down( 'xs' )]: {
      margin: '10px 0',
    },
    '& img': {
      width: '100%',
      objectFit: 'cover',
      verticalAlign: 'middle',
      [theme.breakpoints.down( 'xs' )]: {
        width: '100% !important',
      },
    },
  },
  lightGalleryImage: {
    margin: '20px 0',
    cursor: 'pointer',
    '& img': {
      width: '100%',
      objectFit: 'cover',
      verticalAlign: 'middle',
      [theme.breakpoints.down( 'xs' )]: {
        width: '100%',
      },
    },
  },
  nextStep: {
    fontSize: '24px',
    margin: '30px 0px',
    color: '#0069aa',
    fontWeight: 'normal',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '20px',
    },
  },
  applyLearned: {
    fontSize: '20px',
    color: '#000',
    margin: '0px 0px 10px 0px',
  },
  graphicImage: {
    '& img': {
      width: '100px',
      margin: '30px 0px',
    },
  },
  reviewButton: {
    marginBottom: '50px',
  },
  continueLink: {
    color: '#337ab7',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: '#f59a34',
    },
  },
  breadCrumbVideoLink: {
    color: '#333',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  anchorStyle: {
    textDecoration: 'underline',
    color: '#337ab7',
    '&:hover': {
      color: '#f59a34',
    },
  },
  p: {
    '& b': {
      fontFamily: '"MuseoSans-500"',
      fontWeight: 'normal',
    },
  },
  imageFi: {
    paddingTop: '20px',
    width: '450px !important',
    objectFit: 'cover',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
  },
  widthAuto: {
    paddingTop: '20px',
    width: '450px !important',
    objectFit: 'cover',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
  },
  incomeExamples: {
    fontSize: '16px',
    color: '#000',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '14px',
     },
    '& thead': {
      '& tr': {
        backgroundColor: '#dae3f3',
        '& th': {
          borderBottom: '3px solid #fff',
          fontWeight: '700',
          fontSize: '16px',
          textAlign: 'left',
          padding: '8px',
          lineHeight: '1.42857143',
          fontFamily: '"MuseoSans-300"',
          [theme.breakpoints.down( 'xs' )]: {
           fontSize: '14px',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        backgroundColor: '#f2f2f2',
        '& td': {
          borderTop: '1px solid #fff',
          verticalAlign: 'middle',
          textAlign: 'left',
          padding: '8px',
          lineHeight: '1.42857143',
          '&:nth-child(2)': {
            textAlign: 'right',
          },
          '&:nth-child(3)': {
            textAlign: 'right',
          },
        },
      },
    },
  },
  redText: {
    color: '#f00',
  },
  breakdownTax: {
    fontSize: '18px',
    color: '#000',
    '& thead': {
      '& tr': {
        backgroundColor: '#dae3f3',
        '& th': {
          borderBottom: '3px solid #fff',
          textAlign: 'center',
          fontWeight: '700',
          fontSize: '18px',
          padding: '8px',
          lineHeight: '1.42857143',
          fontFamily: '"MuseoSans-300"',
        },
      },
    },
    '& tbody': {
      '& tr': {
        backgroundColor: '#f2f2f2',
        '& td': {
          borderTop: '1px solid #fff',
          verticalAlign: 'middle',
          textAlign: 'left',
          padding: '8px',
          lineHeight: '1.42857143',
          '&:nth-child(2)': {
            textAlign: 'right',
          },
          '&:nth-child(3)': {
            textAlign: 'right',
          },
          '& span': {
            display: 'inline-block',
            width: '100%',
          },
        },
      },
    },
  },
  blackBorder: {
    borderTop: '2px solid #000',
  },
  bottomblackBorder: {
    borderBottom: '2px solid #000',
  },
  incomeTable: {
    paddingTop: '0px !important',
  },
  lotofWork: {
    paddingTop: '15px',
    marginBottom: '5px',
  },
  budgetingTable: {
    paddingBottom: '20px',
    '& table': {
      fontSize: '18px',
      color: '#000',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '14px',
       },
      '& thead': {
        '& tr': {
          backgroundColor: '#dae3f3',
          '& th': {
            borderBottom: '3px solid #fff',
            textAlign: 'center',
            fontWeight: '700',
            fontSize: '16px',
            padding: '8px',
            lineHeight: '1.42857143',
            fontFamily: '"MuseoSans-300"',
          },
        },
      },
      '& tbody': {
        '& tr': {
          backgroundColor: '#f2f2f2',
          '& td': {
            borderTop: '1px solid #fff',
            verticalAlign: 'middle',
            textAlign: 'left',
            padding: '8px',
            lineHeight: '1.42857143',
            '&:nth-child(2)': {
              textAlign: 'right',
            },
            '&:nth-child(3)': {
              textAlign: 'right',
            },
            '& span': {
              display: 'inline-block',
              width: '100%',
            },
          },
        },
      },
    },
  },
  savingsTable: {
    marginTop: '20px',
    marginBottom: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      overflow: 'auto',
    },
    '& table': {
      borderSpacing: '0px',
      borderCollapse: 'collapse',
      '& thead': {
        '& tr': {
          '& th': {
            background: '#4472c4',
            color: '#fff',
            textAlign: 'center',
            padding: '8px',
            lineHeight: '1.42857143',
            fontSize: '16px',
          },
        },
      },
      '& tbody': {
        '& tr': {
          '&:nth-child(odd)': {
            background: '#cfd5ea',
          },
          '&:nth-child(even)': {
            background: '#e9ebf5',
          },
          '& td': {
            border: '1px solid #fff',
            padding: '8px',
            lineHeight: '1.42857143',
            color: '#000',
            fontSize: '16px',
            '&:first-child': {
              textAlign: 'left',
            },
          },
        },
      },
    },
  },
  seperatorDots: {
    margin: '25px auto 20px auto',
  },
  prosCons: {
    marginTop: '20px',
    marginBottom: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      marginTop: '10px',
    marginBottom: '10px',
    },
    '& table': {
      borderCollapse: 'collapse',
      borderSpacing: '0',
      '& thead': {
        '& tr': {
          background: '#4472c4',
          color: '#fff',
          '& th': {
            padding: '8px',
            lineHeight: '1.42857143',
            fontSize: '18px',
            [theme.breakpoints.down( 'xs' )]: {
              fontSize: '16px',
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          '&:nth-child(odd)': {
            background: '#d9e2f3',
          },
          '&:nth-child(even)': {
            background: '#fff',
          },
          '& td': {
            border: '1px solid #8eaadb',
            textAlign: 'left',
            padding: '8px',
            lineHeight: '1.42857143',
            fontSize: '18px',
            color: '#000',
            [theme.breakpoints.down( 'xs' )]: {
              fontSize: '14px',
            },
          },
        },
      },
    },
  },
  proTable: {
    '& thead': {
      '& tr': {
        '& th': {
          width: '50%',
        },
      },
    },
  },
  balanceTable: {
    [theme.breakpoints.down( 'xs' )]: {
      overflow: 'auto',
    },
    '& table': {
      borderSpacing: '0px',
      borderCollapse: 'collapse',
      '& thead': {
        '& tr': {
          '& th': {
            background: '#4472c4',
            color: '#fff',
            textAlign: 'center',
            padding: '8px',
            lineHeight: '1.42857143',
            fontSize: '18px',
            [theme.breakpoints.down( 'xs' )]: {
              fontSize: '16px',
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          '&:nth-child(odd)': {
            background: '#cfd5ea',
          },
          '&:nth-child(even)': {
            background: '#e9ebf5',
          },
          '& td': {
            border: '1px solid #fff',
            padding: '8px',
            lineHeight: '1.42857143',
            color: '#000',
            fontSize: '18px',
            [theme.breakpoints.down( 'xs' )]: {
              fontSize: '14px',
            },
            '&:first-child': {
              textAlign: 'left',
            },
          },
        },
      },
    },
  },
  loanTable: {
    border: '2px solid #000',
    width: '75%',
    marginTop: '8px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
    '& table': {
      '& tr': {
        '& td': {
          padding: '8px',
          lineHeight: '1.42857143',
          fontSize: '18px',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '14px',
          },
          '&:nth-child(1)': {
            textAlign: 'left',
          },
          '&:nth-child(2)': {
            textAlign: 'right',
          },
        },
      },
    },
  },
  dashedBorder: {
    '& td': {
      background: '#d9e1f2',
      borderTop: '1px dashed #000',
    },
  },
  typesofLoans: {
    [theme.breakpoints.down( 'xs' )]: {
      overflow: 'auto',
    },
    '& thead': {
      '& tr': {
        '& th': {
          background: '#4472c4',
          color: '#fff',
          textAlign: 'center',
          fontSize: '15px',
          verticalAlign: 'middle',
          padding: '8px',
          lineHeight: '1.42857143',
          '&:first-child': {
            background: 'transparent',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          background: '#f2f2f2',
          fontSize: '15px',
          verticalAlign: 'middle',
          border: '1px solid #fff',
          width: '16.6%',
          padding: '8px',
          lineHeight: '1.42857143',
          color: '#000',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '14px',
          },
          '&:nth-child(1)': {
            background: '#cfd5ea',
          },
        },
      },
    },
  },
  repayTable: {
    [theme.breakpoints.down( 'xs' )]: {
      overflow: 'auto',
    },
    '& table': {
      '& thead': {
        '& tr': {
          '& th': {
            textAlign: 'center',
            fontSize: '16px',
            background: '#dae3f3',
            padding: '8px',
            lineHeight: '1.42857143',
            color: '#000',
            [theme.breakpoints.down( 'xs' )]: {
              fontSize: '15px',
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            border: '1px solid #fff',
            fontSize: '16px',
            padding: '8px',
            lineHeight: '1.42857143',
            color: '#000',
            [theme.breakpoints.down( 'xs' )]: {
              fontSize: '14px',
            },
          },
        },
      },
    },
  },
  bluebgRow: {
    '& td': {
      background: '#f2f2f2',
    },
  },
  pinkbgRow: {
    '& td': {
      background: '#fbe5d6',
    },
    borderBottom: '2px solid #408fc0',
  },
  debtImage: {
    '& img': {
      margin: '20px 0px',
      [theme.breakpoints.down( 'xs' )]: {
        margin: '10px 0px',
      },
    },
  },
  federalPic: {
    margin: '20px 0px 15px 0px',
    '& img': {
      paddingLeft: '40px',
      [theme.breakpoints.down( 'xs' )]: {
        paddingLeft: '0px',
      },
    },
  },
  orderList: {
    marginTop: '0px',
    marginBotttom: '10px',
  },
  repaymentTable: {
    marginTop: '18px',
    [theme.breakpoints.down( 'xs' )]: {
      overflowX: 'auto',
    },
    '& table': {
      [theme.breakpoints.down( 'xs' )]: {
        minWidth: '650px',
      },
      '& tr': {
        '& th': {
          background: '#dae3f3',
          fontSize: '15px',
          color: '#000',
          padding: '8px',
          lineHeight: '1.42857143',
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            padding: '8px',
            lineHeight: '1.42857143',
            verticalAlign: 'top',
            borderTop: '1px solid #ddd',
            fontSize: '15px',
            color: '#000',
            '&:nth-child(1)': {
              textAlign: 'left',
            },
            '&:nth-child(2)': {
              textAlign: 'right',
            },
            '&:nth-child(3)': {
              textAlign: 'right',
            },
          },
        },
      },
    },
  },
  emptyData: {
    background: '#408fc0',
    padding: '0px !important',
    height: '2px !important',
    border: '0',
    lineHeight: '2px !important',
  },
  orangeRow: {
    '& td': {
      background: '#fbe5d6',
      fontStyle: 'italic',
      '&:first-child': {
        paddingLeft: '55px',
      },
    },
  },
  investBalance: {
    margin: '20px 0px 20px 0px',
    [theme.breakpoints.down( 'xs' )]: {
      overflowX: 'auto',
    },
    '& table': {
      borderBottom: '2px solid #ddd',
      borderLeft: '2px solid #ddd',
      borderRight: '2px solid #ddd',
      color: '#000',
      '& thead': {
        '& tr': {
          '& th': {
            borderBottom: '2px solid #ddd',
            verticalAlign: 'middle',
            background: '#f2f2f2',
            textAlign: 'center',
            fontSize: '15px',
            padding: '8px',
            lineHeight: '1.42857143',
            borderTop: '2px solid #ddd',
            [theme.breakpoints.down( 'xs' )]: {
              fontSize: '10px',
              padding: '3px',
            },
            '&:nth-child(1)': {
              background: 'transparent',
              borderTop: '0px solid #fff',
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            border: '0 solid',
            verticalAlign: 'middle',
            textAlign: 'right',
            fontSize: '15px',
            borderRight: '2px solid #ddd',
            padding: '8px',
            lineHeight: '1.42857143',
            [theme.breakpoints.down( 'xs' )]: {
              fontSize: '10px',
              padding: '3px',
            },
            '&:nth-child(1)': {
              textAlign: 'left',
            },
          },
        },
      },
    },
  },
  beginingBalance: {
    margin: '20px 0px 0px 0px',
    '& thead': {
      '& tr': {
        '& th': {
          '&:nth-child(even)': {
            background: '#70ad47',
          },
          '&:nth-child(odd)': {
            background: '#ffc000',
          },
        },
      },
    },
  },
  compoundSec: {
    textAlign: 'left',
  },
  powerImage: {
    cursor: 'pointer',
    border: '1px solid #000',
    padding: '20px 0px',
    '& img': {
      width: '100%',
    },
  },
  graphText: {
    width: 'auto',
    margin: '0 30px',
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0 10px',
    },
  },
  blueBox: {
    border: '1px solid #0069aa',
    padding: '5px 10px',
    color: '#0069aa',
    textAlign: 'left',
    '& p': {
      margin: '0px',
      fontSize: '16px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '14px',
      },
    },
    '& b': {
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
  },
  greenBox: {
    border: '1px solid #39b54a',
    padding: '5px 10px',
    margin: '20px 0',
    color: '#39b54a',
    textAlign: 'left',
    '& p': {
      margin: '0px',
      fontSize: '16px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '14px',
      },
    },
    '& b': {
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
  },
  redBox: {
    border: '1px solid red',
    padding: '5px 10px',
    color: 'red',
    textAlign: 'left',
    '& p': {
      margin: '0px',
      fontSize: '16px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '14px',
      },
    },
    '& b': {
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
  },
  riskUl: {
    width: '60%',
    paddingLeft: '0',
    marginBottom: '0px',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
    '& li': {
      listStyleType: 'none',
      fontSize: '18px',
      flexWrap: 'wrap',
      width: '100%',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '14px',
      },
    },
  },
  noTopMargin: {
    marginTop: '0px !important',
  },
  riskList: {
    border: '2px solid #4472c4',
    borderRadius: '0 6px 6px 0',
    borderLeft: '72px solid #4472c4',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '5px',
    padding: '10px 0 10px 30px',
    textAlign: 'left',
    boxSizing: 'border-box',
    '& span': {
      position: 'absolute',
      left: '0',
      flexWrap: 'wrap',
      width: '72px',
      padding: '5px',
      color: '#fff',
      textTransform: 'uppercase',
      fontSize: '18px',
      textAlign: 'center',
      boxSizing: 'border-box',
    },
  },
  listBonds: {
    borderColor: '#006547',
  },
  fundsList: {
    borderColor: '#fff200',
  },
  stockList: {
    borderColor: '#f15a29',
  },
  investList: {
    borderColor: '#be1e2d',
  },
  dividendTable: {
    '& table': {
      fontSize: '18px',
      marginBottom: '0',
      borderTop: '1px solid #fff',
      width: '80%',
      color: '#000',
      [theme.breakpoints.down( 'xs' )]: {
        width: '100%',
        fontSize: '14px',
      },
      '& tr': {
        '& th': {
          background: '#4472c4',
          color: '#fff',
          textAlign: 'center',
          verticalAlign: 'middle',
          padding: '8px',
          lineHeight: '1.42857143',
          '&:nth-child(1)': {
            background: 'transparent',
          },
        },
        '& td': {
          background: '#f2f2f2',
          padding: '8px',
          lineHeight: '1.42857143',
          border: '1px solid #fff',
          '&:nth-child(1)': {
            background: '#cfd5ea',
          },
        },
      },
    },
  },
  noMargin: {
    marginTop: '0px',
  },
  noMarginBottom: {
    marginBottom: '0px !important',
  },
  tableFourOne: {
    [theme.breakpoints.down( 'xs' )]: {
      overflow: 'auto',
    },
    '& table': {
      [theme.breakpoints.down( 'xs' )]: {
        minWidth: '650px',
      },
      color: '#000',
      boxSizing: 'border-box',
      '& thead': {
        '& tr': {
          '& th': {
            fontSize: '15px',
            textAlign: 'left',
            backgroundColor: '#dae3f3',
            borderBottom: '3px solid #fff',
            padding: '8px',
            lineHeight: '1.5',
            fontWeight: 'normal',
            fontFamily: '"MuseoSans-500"',
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            fontSize: '15px',
            textAlign: 'left',
            backgroundColor: '#f2f2f2',
            padding: '8px',
            lineHeight: '1.42857143',
            borderTop: '1px solid #fff',
            '&:nth-child(2)': {
              textAlign: 'right',
            },
            '&:nth-child(3)': {
              textAlign: 'right',
            },
            '& b': {
              fontWeight: 'normal',
              fontFamily: '"MuseoSans-500"',
            },
          },
        },
      },
    },
  },
  contributionLaw: {
    '& td': {
      fontStyle: 'italic',
      '&:nth-child(1)': {
        paddingLeft: '30px',
      },
    },
  },
  boldText: {
    '& td': {
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
  },
  noTopBorder: {
    '& td': {
      borderTop: 'none !important',
    },
  },
  howToInvest: {
    [theme.breakpoints.down( 'xs' )]: {
      overflow: 'auto',
      marginTop: '10px',
    },
    '& table': {
      '& thead': {
        '& tr': {
          '& th': {
            width: '16.6%',
            fontSize: '13px',
            background: '#4472c4',
            color: '#fff',
            textAlign: 'center',
            verticalAlign: 'middle',
            padding: '8px',
            lineHeight: '1.42857143',
            '&:first-child': {
              background: 'transparent',
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            background: '#f2f2f2',
            fontSize: '13px',
            verticalAlign: 'middle',
            padding: '8px',
            lineHeight: '1.42857143',
            border: '1px solid #fff',
            color: '#000',
            '&:first-child': {
              background: '#cfd5ea',
            },
          },
        },
      },
    },
  },
  noteText: {
    display: 'block',
    height: '38px',
    verticalAlign: 'middle',
    fontSize: '14px',
    color: '#000',
    paddingLeft: '0px',
    marginBottom: '20px',
    marginTop: '2px',
  },
  ruleImage: {
    margin: '30px 0px',
    '& img': {
      width: '100%',
    },
  },
  didYouKnow: {
    color: '#337ab7',
  },
  keyTable: {
    borderTop: '2px solid #408fc0',
    borderBottom: '2px solid #408fc0',
    color: '#000',
    '& thead': {
      '& tr': {
        '& th': {
          backgroundColor: 'inherit',
          borderBottom: '0',
          paddingTop: '15px',
          paddingBottom: '15px',
          fontSize: '15px',
          fontWeight: 'normal',
          fontFamily: '"MuseoSans-500"',
          textAlign: 'left',
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          padding: '8px',
          lineHeight: '1.5',
          fontSize: '15px',
          textAlign: 'left',
          '& b': {
            fontWeight: 'normal',
            fontFamily: '"MuseoSans-500"',
          },
        },
        '&:nth-child(odd)': {
          background: '#dae3f3',
        },
        '&:nth-child(even)': {
          background: '#f2f2f2',
        },
        '&:first-child': {
          borderTop: '2px solid #408fc0',
        },
      },
    },
  },
  quickCirclesSection: {
    width: 'inherit',
    height: '350px',
    marginTop: '-80px',
    marginBottom: '50px',
    boxSizing: 'border-box',
  },
  quickCircles: {
    height: '480px',
    margin: '0 auto',
    position: 'relative',
    transform: 'scale(.5)',
    left: '-24.5%',
    width: '100%',
  },
  quickCircle: {
    display: 'block',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '232px',
    height: '232px',
    borderRadius: '100%',
    background: '#0069aa',
    textAlign: 'center',
    padding: '12px 7px',
    border: '7px solid transparent',
    boxSizing: 'border-box',
    transition: 'all 0.5s ease 0s',
    MozTransition: 'all 0.5s ease 0s',
    WebkitTransition: 'all 0.5s ease 0s',
    '& h2': {
      color: '#83a74c',
      fontSize: '70px',
      lineHeight: '70px',
      margin: '0',
      fontFamily: '"MuseoSans-700"',
    },
    '& h3': {
      margin: '0',
      fontFamily: '"MuseoSans-100"',
      color: '#fff',
      lineHeight: '27px',
      textAlign: 'center',
      fontSize: '21px',
      '& b': {
        display: 'inline-block',
        color: '#fff',
        fontSize: '31px',
        lineHeight: '31px',
        margin: '0',
        fontFamily: '"MuseoSans-700"',
      },
    },
    '& span': {
      display: 'block',
      fontWeight: 'normal',
    },
    '&:hover': {
      transform: 'scale(1.5)',
      zIndex: '9',
      cursor: 'default',
    },
  },
  circleOne: {
    top: '20px',
    left: '0',
    zIndex: '1',
  },
  circleTwo: {
    left: '229px',
    top: '20px',
    zIndex: '1',
    background: 'rgba(143, 176, 94, 0.9)',
    '& h2': {
      color: '#006838',
    },
  },
  circleThree: {
    background: 'rgba(70, 158, 213, 0.9)',
    left: '456px',
    top: '20px',
    zIndex: '1',
    '& h2': {
      color: '#83a74c',
    },
  },
  circleFour: {
    background: 'rgba(238, 125, 72, 0.9)',
    left: '685px',
    top: '20px',
    zIndex: '1',
    '& h2': {
      color: '#bf4927',
    },
  },
  circleFive: {
    left: '916px',
    top: '20px',
    background: 'rgba(246, 157, 67, 0.9)',
    '& h2': {
      color: '#f05a28',
    },
    '& h3': {
      '& b': {
        fontSize: '28px',
        lineHeight: '31px',
      },
    },
  },
  circleSix: {
    left: '117px',
    top: '218px',
    zIndex: '1',
    background: 'rgba(204, 109, 82, 0.9)',
    '& h2': {
      color: '#cea348',
    },
  },
  circleSeven: {
    left: '347px',
    top: '218px',
    background: 'rgba(159, 184, 147, 0.9)',
    '& h2': {
      color: '#006838',
    },
  },
  circleEight: {
    left: '578px',
    top: '218px',
    background: 'rgba(40, 168, 183, 0.9)',
    '& h2': {
      color: '#006aab',
    },
  },
  circleNine: {
    background: 'rgba(42, 86, 128, 0.9)',
    left: '805px',
    top: '218px',
    '& h2': {
      color: '#83a74c',
    },
  },
  circleTen: {
    background: 'rgba(211, 172, 90, 0.9)',
    left: '1035px',
    top: '218px',
    '& h2': {
      color: '#bf4927',
    },
  },
  stockBondsGraph: {
    border: '1px solid #333333',
    paddingTop: '20px',
    marginTop: '20px',
  },
  fitaxVideo: {
    '& iframe': {
      height: '390px',
    },
  },
  purchaseText: {
    fontSize: '20px',
    lineHeight: '1.5',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
    },
  },
  iframeHeight: {
    '& iframe': {
      height: '390px',
    },
  },
  noTopSpace: {
    marginBottom: '0px',
  },
  noBottomSpace: {
    marginTop: '0px',
  },
  noteTextNew: {
    height: 'auto',
    marginBottom: '0',
  },
  tableFourOneNew: {
    width: '100%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    '& tbody': {
      '& tr': {
        '& td': {
          fontSize: '16px !important',
        },
      },
    },
  },
} );

export default styles;
