import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import * as moduleServices from '../../../calculations/modules-services';
import NumberFormat from 'react-number-format';
import { convertCurrencyToInteger } from  '../../../utilities/FcCommonFunctions';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

Highcharts.setOptions( {
    lang: {
      thousandsSep: ',',
    },
    colors: ['#389BD2', '#FF7322', '#A7A39E', '#FFBE00', '#2A70BF', '#58AD4B', '#12668D', '#B04400', '#63625D'],
  } );
  
HCExporting( Highcharts );

const WhatIfTool = ( props ) => {
    const { classes, count, setData, data } = props;

    const yearsDifference = [0,4,9,14,19,24,29];
    
    const [updateCount, setupdateCount] = React.useState();
    const [inputValues, setInputValues] = React.useState({});
    const [otherDetails, setOtherDetails] = React.useState({})

    const generateCalc = (inputData) => {
        let otherValues  = {};
        otherValues.currentAnnualIncome = moduleServices.module4Data.yearly_income+moduleServices.module4Data.bonus_or_tips_or_commission+moduleServices.module4Data.second_yearly_income;
                               
        const incomeStatementData = moduleServices.incomeStatementCompleteData();
        if( typeof incomeStatementData !== 'undefined' && typeof incomeStatementData[moduleServices.module4Data.start_year] !== 'undefined' && incomeStatementData[moduleServices.module4Data.start_year]['Total Living Expenses'] !== ''){
            otherValues.currentAnnualExpenses = incomeStatementData[moduleServices.module4Data.start_year]['Total Living Expenses'];
        } else {
            otherValues.currentAnnualExpenses = 0;	
        }
        const carreOutputBackendData = moduleServices.careerWhatIfOutputInitialize({...inputData, ...otherValues});
        
        otherValues.currentAnnualIncomeValue = otherValues.currentAnnualIncome;
        otherValues.currentAnnualExpensesValue = otherValues.currentAnnualExpenses;
        otherValues.currentAnnualTaxesValue              = -carreOutputBackendData['Career What If Output'][moduleServices.module4Data.start_year]['Current Scenario']['Taxes'];
        otherValues.currentAnnualNetIncomeValue          = otherValues.currentAnnualIncomeValue + otherValues.currentAnnualExpensesValue + otherValues.currentAnnualTaxesValue;
        otherValues.currentMonthlyNetIncomeValue         = otherValues.currentAnnualNetIncomeValue / 12;
        otherValues.newMonthlyNetIncome                  = carreOutputBackendData['Career What If Output'][moduleServices.module4Data.start_year]['New Scenario']['Net Income'] / 12;
        otherValues.newYearlyNetIncome                   = carreOutputBackendData['Career What If Output'][moduleServices.module4Data.start_year]['New Scenario']['Net Income'];
        otherValues.currentMonthlyInvestment             = carreOutputBackendData['Career What If Output'][moduleServices.module4Data.start_year]['Current Scenario']['Contribution to Investments']/12;
        otherValues.currentYearlyInvestment              = carreOutputBackendData['Career What If Output'][moduleServices.module4Data.start_year]['Current Scenario']['Contribution to Investments'];
        otherValues.newMonthlyInvestment                 = carreOutputBackendData['Career What If Output'][moduleServices.module4Data.start_year]['New Scenario']['Contribution to Investments']/12;
        otherValues.newYearlyInvestment                  = carreOutputBackendData['Career What If Output'][moduleServices.module4Data.start_year]['New Scenario']['Contribution to Investments'];
        otherValues.addMoreIncomeYearly = inputValues.addMoreIncome*12;
        otherValues.taxes = carreOutputBackendData['Career What If Output'][moduleServices.module4Data.start_year]['New Scenario']['Taxes'] - 
        carreOutputBackendData['Career What If Output'][moduleServices.module4Data.start_year]['Current Scenario']['Taxes'];
        otherValues.addSavedExpensesYearly = inputValues.addSavedExpenses;
        otherValues.careerOutputBarGraphDataTable = carreOutputBackendData['Career What If Output'];
		
        otherValues.startYear = moduleServices.module4Data.start_year;
        const careerWhatIfData		    	= carreOutputBackendData['Bar Graph'];
        const  currentNetIncomeData      		= careerWhatIfData['Current Net Income'];
        const  newNetIncomeData                = careerWhatIfData['New Net Income'];
        const  currentCumulativeInvestmentsData= careerWhatIfData['Current Cumulative Investments'];
        const  newCumulativeInvestmentsData    = careerWhatIfData['New Cumulative Investments'];
        const  yearsList                   	= careerWhatIfData['yearsList'];	

        const graphData = {
            'series': [{
                name: 'Current Net Income',
                type: 'column',
                yAxis: 1,
                data: currentNetIncomeData,
                tooltip: {
                    valuePrefix: '$'
                },
                color: '#70AD47'
            },{
                name: 'New Net Income',
                type: 'column',
                yAxis: 1,
                data: newNetIncomeData,
                tooltip: {
                    valuePrefix: '$'
                },
                color: '#0070C0'
            }, {
                name: 'Current Cumulative Investments',
                type: 'spline',
                yAxis: 0,
                data: currentCumulativeInvestmentsData,
                tooltip: {
                    valuePrefix: '$'
                },
                color: '#ED7D31'
            }, {
                name: 'New Cumulative Investments',
                type: 'spline',
                yAxis: 0,
                data: newCumulativeInvestmentsData,
                marker: {
                    enabled: false
                },
                dashStyle: 'shortdot',
                tooltip: {
                    valuePrefix: '$'
                },
                color: '#FF0000'
            }] ,
            years:  yearsList
        }
        otherValues.graphData = graphData;
        setOtherDetails(otherValues);
    }

    useEffect(()=>{
        if(count !== updateCount) {
            setupdateCount(count);  
            setInputValues(data);
            generateCalc(data);
        }
    })

    const updateValue = ( e, field, type ) => {
        let inputData  = {...inputValues};
        let newVal = e.target.value;
        if(newVal === '') {
            newVal = 0;
        }
        newVal = convertCurrencyToInteger(newVal);
        inputData[field] = newVal;
        setInputValues(inputData);
        generateCalc(inputData);
        setData(inputData);
    }

    return (
        <React.Fragment>
            {
                otherDetails.careerOutputBarGraphDataTable !== undefined && (
                    <React.Fragment>
                        <div className={ classes.cpGraphBlock }>
                            <div className={ classes.graphHead }>
                                <h3>More Savings?</h3>
                            </div>
                            <hr className={ classes.blueDivider } />
                            <div className={ classes.moreSavings }>
                                <h3>
                                What if I could save just a little more each month,
                                <span>how much money will that translate into over time?</span>
                                </h3>
                                <div className={ classes.moreSavingsScenario }>
                                <div className={ classes.flexDisplay }>
                                    <ul className={ classes.translateList }>
                                    <li>
                                        <span>
                                        Current Annual Income
                                        <i>(estimated)</i>
                                        </span>
                                        <NumberFormat fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.currentAnnualIncomeValue !== undefined ? otherDetails.currentAnnualIncomeValue : 0} displayType="text" prefix={otherDetails.currentAnnualIncomeValue < 0 ? '($': '$'} suffix={otherDetails.currentAnnualIncomeValue < 0 && ')'}/>
                                    </li>
                                    <li>
                                        <span>
                                        Current Annual Expenses
                                        <i>(estimated)</i>
                                        </span>
                                        <NumberFormat fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.currentAnnualExpensesValue !== undefined ? otherDetails.currentAnnualExpensesValue : 0} displayType="text" prefix={otherDetails.currentAnnualExpensesValue < 0 ? '($': '$'} suffix={otherDetails.currentAnnualExpensesValue < 0 && ')'}/>
                                    </li>
                                    <li>
                                        <span>
                                        Current Annual Taxes
                                        <i>(estimated)</i>
                                        </span>
                                        <NumberFormat fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.currentAnnualTaxesValue !== undefined ? otherDetails.currentAnnualTaxesValue : 0} displayType="text" prefix={otherDetails.currentAnnualTaxesValue < 0 ? '($': '$'} suffix={otherDetails.currentAnnualTaxesValue < 0 && ')'}/>
                                    </li>
                                    <li>
                                        <span>
                                        Current Annual Net Income
                                        <i>(estimated)</i>
                                        </span>
                                        <NumberFormat fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.currentAnnualNetIncomeValue !== undefined ? otherDetails.currentAnnualNetIncomeValue : 0} displayType="text" prefix={otherDetails.currentAnnualNetIncomeValue < 0 ? '($': '$'} suffix={otherDetails.currentAnnualNetIncomeValue < 0 && ')'}/>
                                    </li>
                                    <li>
                                        <span>
                                        Current Monthly Net Income
                                        <i>(estimated)</i>
                                        </span>
                                        <NumberFormat fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.currentMonthlyNetIncomeValue !== undefined ? otherDetails.currentMonthlyNetIncomeValue : 0} displayType="text" prefix={otherDetails.currentMonthlyNetIncomeValue < 0 ? '($': '$'} suffix={otherDetails.currentMonthlyNetIncomeValue < 0 && ')'}/>
                                    </li>
                                    </ul>
                                </div>
                                <h2 className={ classes.whatIfScenario }>What If Scenario</h2>
                                <div className={ classes.blockListRow }>
                                    <ul>
                                    <li>
                                        <span className={ classes.titleSpan }>Percent Invested of Monthly Net Income</span>
                                        <span className={ classes.greyColorSpan }>
                                            <b>
                                                <NumberFormat fixedDecimalScale decimalScale={0} value={inputValues.percentInvestedMonthly} displayType="text" suffix="%"/>
                                            </b>
                                        </span>
                                        <input type="range" min="0" max="100" value={inputValues.percentInvestedMonthly} onChange={ ( e ) => updateValue( e, 'percentInvestedMonthly', 'input' ) }/>
                                    </li>
                                    <li>
                                        <span className={ classes.titleSpan }>Return on Saved Capital</span>
                                        <span className={ classes.greyColorSpan }>
                                            <b>
                                                <NumberFormat fixedDecimalScale decimalScale={0} value={inputValues.returnSavedCapital} displayType="text" suffix="%"/>
                                            </b>
                                        </span>
                                        <input type="range" min="0" max="100" value={inputValues.returnSavedCapital} onChange={ ( e ) => updateValue( e, 'returnSavedCapital', 'input' ) }/>
                                    </li>
                                    </ul>
                                </div>
                                <div className={ classes.blockListRowFour }>
                                    <ul>
                                    <li>
                                        <span>
                                        Current Monthly Net Income
                                        <i>(Calculated from Income and Expenses above)</i>
                                        </span>
                                        <span className={ classes.moneySpan }>
                                        <b>
                                        <NumberFormat fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.currentMonthlyNetIncomeValue !== undefined ? otherDetails.currentMonthlyNetIncomeValue : 0} displayType="text" prefix={otherDetails.currentMonthlyNetIncomeValue < 0 ? '($': '$'} suffix={otherDetails.currentMonthlyNetIncomeValue < 0 && ')'}/>
                                        </b>
                                        <br />
                                        <i>(
                                            <NumberFormat fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.currentAnnualNetIncomeValue !== undefined ? otherDetails.currentAnnualNetIncomeValue : 0} displayType="text" prefix={otherDetails.currentAnnualNetIncomeValue < 0 ? '($': '$'} suffix={otherDetails.currentAnnualNetIncomeValue < 0 && ')'}/> annually)</i>
                                        </span>
                                    </li>
                                    <li>
                                        <span className={ classes.titleSpan }>
                                        + Add More Income
                                        <i>(Addt&apos;I Income from More Salary, Bonuses, Tips, etc.)</i>
                                        </span>
                                        <span>
                                        <b className={ classes.borderInput }>
                                            $
                                            <NumberFormat  allowNegative={ false } decimalScale={ 0 } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputValues.addMoreIncome } onChange={ ( e ) => updateValue( e, 'addMoreIncome', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                                            {/* <input type="text" /> */}
                                        </b>
                                        <br />
                                        <i>(<NumberFormat fixedDecimalScale decimalScale={0} value={otherDetails.addMoreIncomeYearly !== undefined ? otherDetails.addMoreIncomeYearly : 0} displayType="text" prefix={otherDetails.addMoreIncomeYearly < 0 ? '($': '$'} suffix={otherDetails.addMoreIncomeYearly < 0 && ')'}/> Income - <NumberFormat fixedDecimalScale decimalScale={0} value={otherDetails.taxes !== undefined ? otherDetails.taxes : 0} displayType="text" prefix={otherDetails.taxes < 0 ? '($': '$'} suffix={otherDetails.taxes < 0 && ')'}/> Taxes)</i>
                                        </span>
                                    </li>
                                    <li>
                                        <span className={ classes.titleSpan }>
                                        + Add Saved Expenses
                                        <i>(Save on Expenses such as Rent, Food, Entertainment, etc.)</i>
                                        </span>
                                        <span>
                                        <b className={ classes.borderInput }>
                                            $
                                            <NumberFormat  allowNegative={ false } decimalScale={ 0 } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputValues.addSavedExpenses } onChange={ ( e ) => updateValue( e, 'addSavedExpenses', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                                            {/* <input type="text" /> */}
                                        </b>
                                        <br />
                                        <i>(<NumberFormat fixedDecimalScale decimalScale={0} value={otherDetails.addSavedExpensesYearly !== undefined ? otherDetails.addSavedExpensesYearly : 0} displayType="text" prefix={otherDetails.addSavedExpensesYearly < 0 ? '($': '$'} suffix={otherDetails.addSavedExpensesYearly < 0 && ')'}/> annually)</i>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                        New Monthly Net Income
                                        <i>(More Income - Additional Taxes + Saved Expenses)</i>
                                        </span>
                                        <span className={ classes.moneySpan }>
                                        <b>
                                        <NumberFormat fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.newMonthlyNetIncome !== undefined ? otherDetails.newMonthlyNetIncome : 0} displayType="text" prefix={otherDetails.newMonthlyNetIncome < 0 ? '($': '$'} suffix={otherDetails.newMonthlyNetIncome < 0 && ')'}/>
                                        </b>
                                        <br />
                                        <i>(<NumberFormat fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.newYearlyNetIncome !== undefined ? otherDetails.newYearlyNetIncome : 0} displayType="text" prefix={otherDetails.newYearlyNetIncome < 0 ? '($': '$'} suffix={otherDetails.newYearlyNetIncome < 0 && ')'}/> annually)</i>
                                        </span>
                                    </li>
                                    </ul>
                                </div>
                                <div className={ classes.versusRow }>
                                    <ul>
                                    <li>
                                        <span>
                                        Current Monthly Investment
                                        <i>(Percent Invested * Currently Monthly Net Income )</i>
                                        </span>
                                        <span>
                                        <b>
                                        <NumberFormat fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.currentMonthlyInvestment !== undefined ? otherDetails.currentMonthlyInvestment : 0} displayType="text" prefix={otherDetails.currentMonthlyInvestment < 0 ? '($': '$'} suffix={otherDetails.currentMonthlyInvestment < 0 && ')'}/>
                                        </b>
                                        <br />
                                        <i>(
                                            <NumberFormat fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.currentYearlyInvestment !== undefined ? otherDetails.currentYearlyInvestment : 0} displayType="text" prefix={otherDetails.currentYearlyInvestment < 0 ? '($': '$'} suffix={otherDetails.currentYearlyInvestment < 0 && ')'}/>
                                            annually)</i>
                                        </span>
                                    </li>
                                    <li>vs</li>
                                    <li>
                                        <span>
                                        New Monthly Investment
                                        <i>(Percent Invested * New Monthly Net Income )</i>
                                        </span>
                                        <span>
                                        <b>
                                        <NumberFormat fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.newMonthlyInvestment !== undefined ? otherDetails.newMonthlyInvestment : 0} displayType="text" prefix={otherDetails.newMonthlyInvestment < 0 ? '($': '$'} suffix={otherDetails.newMonthlyInvestment < 0 && ')'}/>
                                        </b>
                                        <br />
                                        <i>(
                                            <NumberFormat fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.newYearlyInvestment !== undefined ? otherDetails.newYearlyInvestment : 0} displayType="text" prefix={otherDetails.newYearlyInvestment < 0 ? '($': '$'} suffix={otherDetails.newYearlyInvestment < 0 && ')'}/>
                                            annually)</i>
                                        </span>
                                    </li>
                                    </ul>
                                </div>
                                </div>
                                <div className={ classes.clearFix } />
                                <h2 className={ classes.savingsTitle }>Savings and Investment Tables Over Time</h2>
                                <hr className={ classes.blueDivider } />
                                <div className={ classes.currentBlock }>
                                <table className={ classes.currentTable }>
                                    <tbody>
                                    <tr colSpan="8" />
                                    <tr className={ classes.blackBorder }>
                                        <th>Current Scenario</th>
                                        {
                                            yearsDifference.map((year)=>(
                                                <th>{otherDetails.startYear+year}</th>
                                            ))
                                        }
                                    </tr>
                                    <tr>
                                        <td>Income</td>
                                        {
                                            yearsDifference.map((year)=>(
                                                <td>
                                                    <NumberFormat allowNegative={false} fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Gross Income'] !== undefined ? otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Gross Income'] : 0} displayType="text" prefix={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Gross Income'] < 0 ? '($': '$'} suffix={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Gross Income'] < 0 && ')'}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr>
                                        <td>Estimated Taxes</td>
                                        {
                                            yearsDifference.map((year)=>(
                                                <td>
                                                    <NumberFormat allowNegative={false} fixedDecimalScale decimalScale={0} thousandSeparator value={-otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Taxes'] !== undefined ? -otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Taxes'] : 0} displayType="text" prefix={-otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Taxes'] < 0 ? '($': '$'} suffix={-otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Taxes'] < 0 && ')'}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr className={ classes.blackBorder }>
                                        <td>Expenses</td>
                                        {
                                            yearsDifference.map((year)=>(
                                                <td>
                                                    <NumberFormat allowNegative={false} fixedDecimalScale decimalScale={0} thousandSeparator value={-otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Expenses'] !== undefined ? -otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Expenses'] : 0} displayType="text" prefix={-otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Expenses'] < 0 ? '($': '$'} suffix={-otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Expenses'] < 0 && ')'}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr>
                                        <td>Net Income</td>
                                        {
                                            yearsDifference.map((year)=>(
                                                <td>
                                                    <NumberFormat allowNegative={false} fixedDecimalScale decimalScale={0}  thousandSeparator value={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Net Income'] !== undefined ? otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Net Income'] : 0} displayType="text" prefix={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Net Income'] < 0 ? '($': '$'} suffix={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Net Income'] < 0 && ')'}/>
                                                </td>
                                            ))
                                        }
                                    </tr>


                                    <tr className={ classes.boldText }>
                                        <td>Cumulative Investments</td>
                                        {
                                            yearsDifference.map((year)=>(
                                                <td>
                                                    <NumberFormat allowNegative={false} fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Cumulative Investments'] !== undefined ? otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Cumulative Investments'] : 0} displayType="text" prefix={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Cumulative Investments'] < 0 ? '($': '$'} suffix={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['Current Scenario']['Cumulative Investments'] < 0 && ')'}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr colSpan="8" className={ classes.heightThirty } />
                                    <tr className={ classes.blackBorder }>
                                        <th>New Scenario</th>
                                        <th colSpan="7"> </th>
                                    </tr>
                                    <tr>
                                        <td>Income</td>
                                        {
                                            yearsDifference.map((year)=>(
                                                <td>
                                                    <NumberFormat allowNegative={false} fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Gross Income'] !== undefined ? otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Gross Income'] : 0} displayType="text" prefix={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Gross Income'] < 0 ? '($': '$'} suffix={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Gross Income'] < 0 && ')'}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr>
                                        <td>Estimated Taxes</td>
                                        {
                                            yearsDifference.map((year)=>(
                                                <td>
                                                    <NumberFormat allowNegative={false} fixedDecimalScale decimalScale={0} thousandSeparator value={-otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Taxes'] !== undefined ? -otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Taxes'] : 0} displayType="text" prefix={-otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Taxes'] < 0 ? '($': '$'} suffix={-otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Taxes'] < 0 && ')'}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr className={ classes.blackBorder }>
                                        <td>Expenses</td>
                                        {
                                            yearsDifference.map((year)=>(
                                                <td>
                                                    <NumberFormat allowNegative={false} fixedDecimalScale decimalScale={0} thousandSeparator value={-otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Expenses'] !== undefined ? -otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Expenses'] : 0} displayType="text" prefix={-otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Expenses'] < 0 ? '($': '$'} suffix={-otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Expenses'] < 0 && ')'}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr>
                                        <td>Net Income</td>
                                        {
                                            yearsDifference.map((year)=>(
                                                <td>
                                                    <NumberFormat allowNegative={false} fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Net Income'] !== undefined ? otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Net Income'] : 0} displayType="text" prefix={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Net Income'] < 0 ? '($': '$'} suffix={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Net Income'] < 0 && ')'}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr>
                                        <td>Cumulative Investments</td>
                                        {
                                            yearsDifference.map((year)=>(
                                                <td>
                                                    <NumberFormat allowNegative={false} fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Cumulative Investments'] !== undefined ? otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Cumulative Investments'] : 0} displayType="text" prefix={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Cumulative Investments'] < 0 ? '($': '$'} suffix={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Cumulative Investments'] < 0 && ')'}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr colSpan="8" className={ classes.heightThirty } />
                                    <tr className={ classes.borderBlueDash }>
                                        <th>Additional Earnings</th>
                                        {
                                            yearsDifference.map((year)=>(
                                                <td>
                                                    <NumberFormat allowNegative={false} fixedDecimalScale decimalScale={0} thousandSeparator value={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Additional Earnings'] !== undefined ? otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Additional Earnings'] : 0} displayType="text" prefix={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Additional Earnings'] < 0 ? '($': '$'} suffix={otherDetails.careerOutputBarGraphDataTable[otherDetails.startYear + year]['New Scenario']['Additional Earnings'] < 0 && ')'}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    </tbody>
                                </table>
                                </div>

                            </div>
                            </div>

                            <div className={ classes.descriptionRow }>
                            <div className={ classes.graphBlock }>
                                <h3>Savings and Investment Curves Over Time</h3>
                                <hr className={ classes.blueRow } />
                                <HighchartsReact
                                    highcharts={ Highcharts }
                                    options={ {
                                        chart: {
                                            plotBackgroundColor: '#ffffff',
                                            plotBorderColor: '#cccccc',
                                            plotBorderWidth: 1,
                                            plotShadow: false,
                                            zoomType: false,
                                        },
                                        navigation: {
                                                menuItemStyle: {
                                                    fontSize: '14px',
                                                    textAlign: 'left'
                                                },
                                                menuItemHoverStyle: {
                                                    background: '#f5f5f5',
                                                    color: '#4c4c4c',
                                                    fontSize: '14px'
                                                  },
                                                buttonOptions: {
                                                    height: 40,
                                                    width: 48,
                                                    symbolSize: 24,
                                                    symbolX: 24,
                                                    symbolY: 21,
                                                    symbolStrokeWidth: 2,
                                                    verticalAlign: 'bottom',
                                                    _titleKey: 'y'
                                                }
                                            },
                                            exporting: {
                                                buttons: {
                                                    contextButton: {
                                                        menuItems: [{
                                                            textKey: 'downloadPNG',
                                                            onclick() {
                                                              const chart = this;
                                                              chart.exportChart();
                                                            },
                                                          }, {
                                                            textKey: 'downloadJPEG',
                                                            onclick() {
                                                              const chart = this;
                                                            chart.exportChart({
                                                                type: 'image/jpeg'
                                                              });
                                                            }
                                                          }, {
                                                            textKey: 'downloadPDF',
                                                            onclick() {
                                                              const chart = this;
                                                            chart.exportChart({
                                                                type: 'application/pdf'
                                                              });
                                                            }
                                                          }, {
                                                            textKey: 'downloadSVG',
                                                            onclick() {
                                                              const chart = this;
                                                            chart.exportChart({
                                                                type: 'image/svg+xml'
                                                              });
                                                            }
                                                          }]
                                                    }
                                                }
                                              },
                                        title: {
                                            text: ''
                                        },
                                        xAxis: [{
                                            categories: otherDetails.graphData.years,
                                            crosshair: true,
                                            labels: {
                                                style: {
                                                    color: '#000000',
                                                    fontSize: '14px'
                                                }
                                            }
                                        }],
                                        yAxis: [{ // Primary yAxis                    
                                            title: {
                                                text: 'Investments',
                                                style: {
                                                    color: '#000000',
                                                    fontSize: '16px'
                                                }
                                            },
                                            labels: {
                                                formatter: function() {
                                                    const chart = this;
                                                    if( chart.value < 0 ) {
                                                        return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                                                    } else { 
                                                        return formatDollar(Math.round(chart.value));
                                                    }
                                                },
                                                style: {
                                                    color: '#000000',
                                                    fontSize: '14px'
                                                }
                                            },
                                            opposite: true
                                        }, { // Secondary yAxis
                                            gridLineWidth: 0,
                                            title: {
                                                text: 'Net Income',
                                                style: {
                                                    color: '#000000',
                                                    fontSize: '16px'
                                                }
                                            },
                                            labels: {
                                                formatter: function() {
                                                    const chart = this;
                                                    if( chart.value < 0 ) {
                                                        return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                                                    } else { 
                                                        return formatDollar(Math.round(chart.value));
                                                    }
                                                },
                                                style: {
                                                    color: '#000000',
                                                    fontSize: '14px'
                                                }
                                            }
                                        }],
                                        tooltip: {},
                                        legend: {
                                            itemStyle: {
                                                fontSize: '14px'
                                            }
                                        },
                                        series: otherDetails.graphData.series
                                    } }
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        </React.Fragment>
    )
}



WhatIfTool.prototype = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    setData: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
}
export default WhatIfTool;

