import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import GreyBox from './grey-common-box';
import * as commonFunctions from '../../../utilities/commonFunctions';
import styles from '../../../screens/pwi-career-advancement/styles';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';

const marks = [
  {
    value: 0,
    scaledValue: 0,
    label: '$0',
  },
  {
    value: 30000,
    scaledValue: 30000,
    label: '$30k',
  },
  {
    value: 60000,
    scaledValue: 60000,
    label: '$60k',
  },
  {
    value: 90000,
    scaledValue: 90000,
    label: '$90k',
  },
  {
    value: 120000,
    scaledValue: 120000,
    label: '$120k',
  },
  {
    value: 150000,
    scaledValue: 150000,
    label: '$150k',
  },
];

const BonusTips = ( props ) => {
  const {
    classes, handleCareerObject, handleDefinationPopup, handleUpdatedObject,
  } = props;
  const [sliderValue, setSliderValue] = React.useState( 0 );
  const [annualIncome, setAnnualIncome] = React.useState( 0 );

  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.bonus_or_tips_or_commission !== undefined ) {
      setSliderValue( handleCareerObject.bonus_or_tips_or_commission );
    } else {
      updatedValues['bonus_or_tips_or_commission'] = 0;
    }
    if ( handleCareerObject.base_income_type === 'salary' ) {
      let updateAnnualIncome = 0;
      if ( handleCareerObject.yearly_income !== undefined ) {
        updateAnnualIncome = handleCareerObject.yearly_income;
      updatedValues['totalPreTaxExpected'] =  updateAnnualIncome + sliderValue;
      } else {
        updateAnnualIncome = 50000;
      updatedValues['totalPreTaxExpected'] =  updateAnnualIncome;
      }
      setAnnualIncome( updateAnnualIncome );
    } else {
      let hourWage = 0;
      let firstHourWage = 0;
      if ( handleCareerObject.hourly_wage !== undefined ) {
        hourWage = handleCareerObject.hourly_wage;
      } else {
        hourWage = 18.00;
      }
      if ( handleCareerObject.first_hour_year !== undefined ) {
        firstHourWage = handleCareerObject.first_hour_year;
      } else {
        firstHourWage = 2000;
      }
      updatedValues['totalPreTaxExpected'] = hourWage * firstHourWage;
      setAnnualIncome( hourWage * firstHourWage );
    }
   
    handleUpdatedObject(updatedValues)
    // eslint-disable-next-line
    }, [] );

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    setSliderValue( newvalue );
    updatedValues[field] = newvalue;
    handleUpdatedObject( updatedValues );
  };

  return (
    <div className={ classes.contentBlock }>
      {handleCareerObject.role === 'currentRole' && (
        <div>
          <p>
            Does this career move pay an annual, quarterly, or monthly bonus?
            Maybe you&apos;ll be earning tips as you work? What about commissions on
            sales?
          </p>
          <p>
            For this position, how much do you currently make in bonus, tips,
            and commission? Do not include any other income from other jobs.
          </p>
        </div>
      )}
      {handleCareerObject.role === 'futureMove' && (
        <div>
          <p>
            Will this career move pay an annual, quarterly, or monthly bonus?
            Maybe you&apos;ll be earning tips as you work? What about commissions on
            sales?
          </p>
          <p>
            For this career move, how much do you plan to make in the first year
            in bonus, tips, and commission? Do not include any other income from
            other jobs.
          </p>
        </div>
      )}

      <div className={ classes.garyBoxSeventy }><GreyBox handleCareerObject={ handleCareerObject } /></div>

      <div className={ classes.annuvalIncomeTittle }>
        {' '}
        <span>Annual</span>
        {' '}
      </div>

      <div className={ classes.AnnualIncomeGrowth }>
        <div>
          <div className={ classes.MaxWidth200 }>
            <span
              className={ classes.dottedText }
              aria-hidden="true"
              data-for="definationTitle"
              data-tip="Click for a definition."
              onClick={ () => handleDefinationPopup(
                'Bonus / Tips / Commission',
              ) }
            >
              Bonus / Tips / Commission
            </span>
          </div>
          <div className={ classes.annuvalIncomeGrowthGraph }>
            <PwiSlider
              ValueLabelComponent={ ValueLabelComponent }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              marks={ marks }
              min={ 0 }
              max={ 150000 }
              step={ 500 }
              value={ sliderValue }
              valueLabelFormat={ `${commonFunctions.numFormatter( sliderValue )}` }
              onChange={ ( e, value ) => updateValue( e, value, 'bonus_or_tips_or_commission', 'slider' ) }
            />
          </div>
          <div className={ classes.annuvalIncomeGrowthInput }>
            $
            <NumberFormat
              customInput={ TextField }
              decimalScale={0}
              thousandSeparator
              value={ sliderValue }
              onValueChange={ ( e ) => updateValue( e, '', 'bonus_or_tips_or_commission', 'number' ) }
              onFocus={ ( e ) => {
                e.target.select();
              } }
            />
          </div>
        </div>
      </div>

      <div className={ classes.totalIncomeBox }>
        <span>Annual Income</span>
        <div className={ classes.annuvalIncomeGrowthInput }>
          {( annualIncome !== undefined ) ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ annualIncome } prefix="$" />
          ) : ( '$0' )}
        </div>
      </div>
      <div className={ classes.totalIncomeBox }>
        <span>Total Pre-Tax Base Income</span>
        <div className={ `${classes.annuvalIncomeGrowthInput} ${classes.borderBg}` }>
          {( annualIncome !== undefined && sliderValue !== undefined ) ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ ( annualIncome ) + sliderValue } prefix="$" />
          ) : ( '$0' )}
        </div>
      </div>
      <ReactTooltip
        id="definationTitle"
        place="top"
        type="info"
        effect="solid"
        className={ classes.tooltipInfoTop }
      />
    </div>
  );
};

BonusTips.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( BonusTips );
