import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles';
import Component from './UI/component';
import data from './constants/additional-career-advancement.js'

const CompareAdditionalCareerAdvancementPath = ( props ) => {
  const { classes, scenarioOne, scenarioTwo } = props;

  return (
    <div>
      { 
        data.map(parent => (
          <Component parent={parent} scenarioTwo={ scenarioTwo}  scenarioOne={ scenarioOne } classes= {classes }/>
        ))
      }
    </div>
  );
};

CompareAdditionalCareerAdvancementPath.propTypes = {
  classes: PropTypes.object.isRequired,
  scenarioOne: PropTypes.object.isRequired,
  scenarioTwo: PropTypes.object.isRequired,
};

export default withStyles( styles )( CompareAdditionalCareerAdvancementPath );
