import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import classNames from 'classnames';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import IncomeTaxes from '../../../assets/img/finance-explained/income_taxes.png';

import styles from './styles';

const PersonalIncomeTaxes = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Personal Income Taxes 101</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/personal-income-tax.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWord}>
                      Finance Explained
                      <span> | </span>
                      Personal Income Taxes 101
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/personal-income-taxes-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Common types of taxes in the US are personal income
                          tax, corporate tax, sales tax, property tax, and
                          estate tax. Personal income taxes are the largest
                          source of revenue for the federal government. In 2020,
                          the U.S. government&apos;s total revenue is estimated
                          to be $3,643,000,000,000 or $3.643 trillion. Personal
                          income taxes are expected to total $3.117 trillion or{' '}
                          <a
                            href="https://www.whitehouse.gov/wp-content/uploads/2019/03/budget-fy2020.pdf"
                            target="_blank"
                            rel="noopener noreferrer">
                            86% of the government&apos;s total revenue
                          </a>
                          . Review the graphic below to see how the federal
                          government spends our tax dollars.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <div
                          className={classNames(
                            classes.galleryImage,
                            classes.loanImage,
                            classes.autoImageWidth,
                          )}>
                          <img src={IncomeTaxes} alt="IncomeTaxes" />
                        </div>
                        <p>
                          Personal income taxes are a percentage of your annual
                          earnings paid to the federal, state and local
                          governments. Each state and city have a different tax
                          system. Check out a simplified example below of how
                          taxes work for a single person earning $100,000 this
                          year.
                        </p>
                        <Table className={classes.taxExample}>
                          <thead>
                            <tr>
                              <th>Personal Income Taxes Example</th>
                              <th />
                              <th>Year 2019</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Total Income</td>
                              <td />
                              <td>$100,000</td>
                            </tr>
                            <tr>
                              <td>(-) Adjustments</td>
                              <td />
                              <td className={classes.textRed}>($5,000)</td>
                            </tr>
                            <tr>
                              <td>(=) Adjusted Gross Income (AGI)</td>
                              <td />
                              <td>$95,000</td>
                            </tr>
                            <tr>
                              <td>(-) Deductions (Standard vs Itemized)</td>
                              <td />
                              <td className={classes.textRed}>($12,000)</td>
                            </tr>
                            <tr className={classes.bottomBorder}>
                              <td>(=) Federal Taxable Income</td>
                              <td />
                              <td>$82,800</td>
                            </tr>
                            <tr>
                              <td>Social Security Taxes</td>
                              <td>6.20% x Total Income</td>
                              <td className={classes.textRed}>($6,200)</td>
                            </tr>
                            <tr>
                              <td>Medicare Taxes</td>
                              <td>1.45% x Total Income</td>
                              <td className={classes.textRed}>($1,450)</td>
                            </tr>
                            <tr>
                              <td>Federal Income Taxes</td>
                              <td>17.00% x Federal Taxable Income</td>
                              <td className={classes.textRed}>($14,075)</td>
                            </tr>
                            <tr>
                              <td>State Income Taxes</td>
                              <td>3.00% of Adjusted Gross Income</td>
                              <td className={classes.textRed}>($2,850)</td>
                            </tr>
                            <tr>
                              <td>Local Income Taxes</td>
                              <td>1.00% of Adjusted Gross Income</td>
                              <td className={classes.textRed}>($950)</td>
                            </tr>
                            <tr className={classes.bottomBorder}>
                              <td>
                                <b>Total Income Taxes</b>
                              </td>
                              <td>
                                <b>25.53% of Total Income</b>
                              </td>
                              <td className={classes.textRed}>
                                <b>($25,525)</b>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <p>
                          That&apos;s how personal income taxes work without
                          getting into the smaller details. Two key terms in the
                          table above that need defining: adjustments and
                          deductions.
                        </p>
                        <ul>
                          <li>
                            <b>Adjustments:</b> Specific expenses that reduce
                            your taxable income to arrive at Adjusted Gross
                            Income (AGI). Some common adjustments are qualified
                            student loan interest or pre-tax retirement
                            contributions.
                          </li>
                          <li>
                            <b>Deductions:</b> a portion of your income that is
                            not subject to federal income taxes. There are two
                            types of deductions: standard deduction or itemized
                            deduction. You choose each year whether to take the
                            standard or itemized deduction based on which one is
                            greater.
                          </li>
                          <ul>
                            <li>
                              <b>Standard deduction:</b> given to all taxpayers
                              and is $12,200 for single filers and $24,400 for
                              married couples.{' '}
                              <b>Most taxpayers take the standard deduction.</b>
                            </li>
                            <li>
                              <b>Itemized deductions:</b> specific expenses that
                              reduce your adjusted gross income to arrive at
                              federal taxable income. Itemized deductions can
                              get complex. Click{' '}
                              <a
                                href="https://www.thebalance.com/itemized-deductions-3192880"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.linkText}>
                                here
                              </a>{' '}
                              if you&apos;re interested in learning more.
                            </li>
                          </ul>
                        </ul>
                        <p>
                          You might be wondering how taxes can vary based on
                          income, employment status, or even state and city. The
                          table below shows you a breakdown of personal income
                          tax ranges.
                        </p>
                        <Table className={classes.breakdownTable}>
                          <thead>
                            <tr>
                              <th colSpan="2">
                                Personal Income Tax Rate Breakdown
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Federal Income Tax Rate</td>
                              <td>10.00% - 37.00%</td>
                            </tr>
                            <tr>
                              <td>Social Security Tax Rate</td>
                              <td>
                                6.20%
                                <br />
                                (12.40% if self-employed)
                              </td>
                            </tr>
                            <tr>
                              <td>Medicare Tax Rate</td>
                              <td>
                                1.45%
                                <br />
                                (2.90% if self-employed)
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Additional Medicare Tax Rate
                                <br />
                                (Single Income &gt; $200k)
                                <br />
                                (Married Income &gt; $250k)
                              </td>
                              <td>0.90%</td>
                            </tr>
                            <tr>
                              <td>State Income Tax Rate</td>
                              <td>0.00% - 13.30%</td>
                            </tr>
                            <tr>
                              <td>Local (City) Income Tax Rate</td>
                              <td>0.00% - 3.88%</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
PersonalIncomeTaxes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PersonalIncomeTaxes);
