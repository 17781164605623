import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import scrollToComponent from 'react-scroll-to-component';
import { loader } from 'graphql.macro';
import { withStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import compose from 'recompose/compose';
import { confirmAlert } from 'react-confirm-alert';
import Pageloader from '../../../../../components/ui/pageloader';
import styles from './styles';
import eachQuestionXp, { xpPoints, getTotalTime, getUserXPRank } from '../../pf101-service';
import Pf101Popups from '../../popups';
import headerLogo from '../../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import book from '../../../../../assets/img/pf101/icon-book-blue.png';
import downloadSmartGoals from '../../../../../assets/img/pf101/Inkwiry_PF101_My_SMART_Goals.pdf';

const SAVE_EXERCISE = loader( '../../../../../graphql/schema/pf101/save-exercise.graphql' );
const GET_EXERCISE = loader( '../../../../../graphql/schema/pf101/get-exercise-details.graphql' );

/* eslint react/prop-types: 0 */
const startTime = new Date();
const SmartGoals = ( props ) => {
  const { classes, history } = props;
  const defaultCorrectAnswer = ['4', '2', '2', '2', '1', '1', '1', '1'];
  const defaultQuestionList = [];

  for ( let i = 0; i <= 7; i += 1 ) {
    defaultQuestionList.push( {
      id: i + 1,
      question_attempts: 1,
      xp_earned: eachQuestionXp,
      correct_answer: defaultCorrectAnswer[i],
      status: '',
      answer: '',
    } );
  }

  let bodySection = useRef( null );

  const [loading, setLoading] = React.useState( true );
  const [activeSlide, setActiveSlide] = React.useState( 1 );
  const [questionList, setQuestionList] = React.useState( defaultQuestionList );
  const [progress, setProgress] = React.useState( 1 );
  const [questionProgressData, setQuestionProgressData] = React.useState( {
    questions_progress_val: eachQuestionXp, questions_progress: 100, total_progress: 0, total_progress_val: 0,
  } );
  const [popupDetails, setPopupDetails] = React.useState( {} );
  const [pf101Points, setPf101Points] = React.useState( 0 );
  const [exerciseStatus, setExerciseStatus] = React.useState( 'submit' );

  useQuery( GET_EXERCISE, {
    variables: {
      level: 9,
      exercise: 1,
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      setLoading( false );
      setPf101Points( response.getPf101ExerciseDetails.total_points );
      if ( response.getPf101ExerciseDetails.questions.length > 0 ) {
        const questions = [...questionList];
        response.getPf101ExerciseDetails.questions.forEach( ( data ) => {
          questions[data.question_id - 1].question_attempts = data.question_attempts > 0 ? data.question_attempts : 1;
          questions[data.question_id - 1].xp_earned = xpPoints( questions[data.question_id - 1].question_attempts );
          questions[data.question_id - 1].status = 'C';
          questions[data.question_id - 1].answer = questions[data.question_id - 1].correct_answer;
        } );
        setQuestionList( questions );
        setExerciseStatus( 'review' );
      }
    },
    onError( ) {
      window.location.href = '/';
    },
  } );

  const [saveExercise] = useMutation( SAVE_EXERCISE, {
    onCompleted( response ) {
      setLoading( false );
      if ( response.savePf101ExerciseDetails.status === true ) {
        setPopupDetails( ( prevState ) => ( {
          ...prevState,
          popupOpen: true,
        } ) );
      }
    },
    onError( ) {
      return false;
    },
  } );

  const backToDashBoard = () => {
    confirmAlert( {
      customUI: ( { onClose } ) => (
        <div className={ classes.redoPopup }>
          <div className={ classes.redoPopupNew }>
            <h3>Back to Dashboard</h3>
            <p>If you go back to the Dashboard, your progress will be lost. Are you sure you want to exit the activity and go back to the Dashboard?</p>
          </div>
          <div className={ classes.buttonOkRedo }>
            <button
              type="button"
              onClick={ () => { onClose(); history.push( '/pf101/dashboard' ); } }
            >
              CONTINUE
            </button>
            <button type="button" onClick={ onClose }>CANCEL</button>
          </div>
        </div>
      ),
    } );
  };

  useEffect( () => {
    const questionsData = [...questionList];

    if ( activeSlide > 5 ) {
      if ( questionsData[activeSlide - 1].status !== 'C' ) {
        questionsData[activeSlide - 1].status = 'C';
        setQuestionList( questionsData );
      }
    }

    scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } );

    setProgress( parseFloat( ( 100 / questionsData.length ) * activeSlide ) );

    // Updating bottom progress
    const progressData = {};
    progressData.questions_progress_val = questionsData[activeSlide - 1].xp_earned;
    progressData.questions_progress = ( questionsData[activeSlide - 1].xp_earned / eachQuestionXp ) * 100;
    const correctAnswer = _.filter( questionsData, { status: 'C' } );
    if ( correctAnswer.length > 0 ) {
      progressData.total_progress_val = _.sumBy( correctAnswer, 'xp_earned' );
      progressData.total_progress = ( progressData.total_progress_val / ( questionsData.length * eachQuestionXp ) ) * 100;
    } else {
      progressData.total_progress = 0;
      progressData.total_progress_val = 0;
    }
    setQuestionProgressData( progressData );
    // eslint-disable-next-line
  }, [activeSlide, exerciseStatus] );

  const answerQuestion = ( e ) => {
    const questions = [...questionList];
    questions[activeSlide - 1].answer = e.target.value;
    questions[activeSlide - 1].status = '';
    setQuestionList( questions );
  };

  const checkAnswer = ( question, slide = '' ) => {
    const questions = [...questionList];

    if ( slide === 5 ) {
      questions[question - 1].answer = '1';
    }

    if ( questions[question - 1].answer === questions[question - 1].correct_answer ) {
      questions[question - 1].status = 'C';
    } else {
      questions[question - 1].status = 'W';
      questions[question - 1].question_attempts = questions[question - 1].question_attempts + 1;
    }
    questions[question - 1].xp_earned = xpPoints( questions[question - 1].question_attempts );
    setQuestionList( questions );

    // Updating bottom progress
    const progressData = {};
    progressData.questions_progress_val = questions[question - 1].xp_earned;
    progressData.questions_progress = ( questions[question - 1].xp_earned / eachQuestionXp ) * 100;
    const correctAnswer = _.filter( questions, { status: 'C' } );
    if ( correctAnswer.length > 0 ) {
      progressData.total_progress_val = _.sumBy( correctAnswer, 'xp_earned' );
      progressData.total_progress = ( progressData.total_progress_val / ( defaultQuestionList.length * eachQuestionXp ) ) * 100;
    } else {
      progressData.total_progress = 0;
      progressData.total_progress_val = 0;
    }
    setQuestionProgressData( progressData );
  };

  const finishActivity = () => {
    if ( exerciseStatus === 'review' ) {
      history.push( '/pf101/dashboard' );
      return false;
    }
    setLoading( true );
    const popupValue = {};

    const totalTime = getTotalTime( startTime );
    let totalPoints = _.sumBy( questionList, 'xp_earned' );
    const totalAttempts = _.sumBy( questionList, 'question_attempts' );
    const questionData = questionList.map( ( questions ) => _.pick( questions, ['id', 'question_attempts', 'xp_earned'] ) );

    popupValue.exercisePoints = totalPoints;

    // Adding 50 points for all questions answered single attempt
    if ( totalAttempts === defaultQuestionList.length ) {
      totalPoints += 50;
      popupValue.precision = true;
      popupValue.precisionPoints = 50;
    } else {
      popupValue.precision = false;
      popupValue.precisionPoints = 0;
    }

    popupValue.level = false;
    popupValue.levelPoints = 0;
    popupValue.levelNumber = 9;

    const presentRank = getUserXPRank( pf101Points );
    const nextRank = getUserXPRank( pf101Points + totalPoints );
    if ( presentRank !== nextRank ) {
      popupValue.rankChangeStatus = true;
      popupValue.rank = nextRank;
    } else {
      popupValue.rankChangeStatus = false;
      popupValue.rank = '';
    }

    setPopupDetails( popupValue );

    saveExercise( {
      variables: {
        data: {
          level: 9,
          exercise: 1,
          exercise_time: totalTime,
          points: totalPoints,
          total_attempts: totalAttempts,
          questionData,
        },
      },
    } );
    return true;
  };

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.pfHeader }>
        <div className={ classes.container }>
          <div className={ classes.pfHeaderInner }>
            <h3>
              <span className={ classes.breadcrumbTitle }>Level 9:</span>
              {' '}
              Goals + Planning
            </h3>
            <Button onClick={ () => { backToDashBoard(); } }>
              <img src={ headerLogo } alt="" />
            </Button>
          </div>
        </div>
      </div>
      <div className={ classes.pfContent }>
        <div className={ classes.stepOne }>
          <div className={ classes.pfContentHeader }>
            <div>
              <h3>
                <span>Activity:</span>
                {' '}
                SMART Goals (
                {activeSlide}
                {' '}
                of
                {' '}
                {defaultQuestionList.length}
                )
              </h3>
            </div>
            <Link to="/pf101/level9/smart-goals" target="_blank">
              <figure>
                <img
                  src={ book }
                  alt="infoicon"
                  data-for="infoLeftIcon"
                  data-tip="Click here to open the activity's reading in a new tab."
                  data-offset="{'left':10}"
                />
                <ReactTooltip id="infoLeftIcon" place="left" type="info" effect="solid" className={ classes.tooltipInfo } />
              </figure>
            </Link>

          </div>
          <div className={ classes.ProgressBar }>
            <div className={ classes.ProgressBarInner } style={ { width: `${progress}%` } } />
          </div>
          { activeSlide === 1 && (
            <div className={ classes.contentBlock }>
              <div className={ classes.grayBox }>
                <h3>
                  To complete this activity, answer the following questions. Each question is worth
                  {' '}
                  <span className={ classes.bonusXp }>+20 XP.</span>
                  {' '}
                  If you get the question right on the first try, you will earn
                  {' '}
                  <span className={ classes.bonusXp }>+20 XP</span>
                  . On the second try,
                  {' '}
                  <span className={ classes.bonusXp }>+15 XP</span>
                  . Third try,
                  {' '}
                  <span className={ classes.bonusXp }>+12 XP</span>
                  . And fourth try,
                  {' '}
                  <span className={ classes.bonusXp }>+10 XP</span>
                  .
                </h3>
                <h3>
                  <span className={ classes.bonusXp }>BONUS XP:</span>
                  {' '}
                  If you answer all questions correctly on the first try, you will earn
                  {' '}
                  <span className={ classes.bonusXp }>+50 XP</span>
                  {' '}
                  at the end of the activity. Good luck!
                </h3>
                <h3>A green check mark will appear once you answer correctly and the Next button will turn blue once you&apos;ve answered all correctly.</h3>
              </div>
              <h3>The power of planning is real. Just think about it. How do you expect to achieve a goal if you don&apos;t have a plan to get there?</h3>
              <h3>You are ____% more likely to achieve your goals if you write them down on a regular basis.</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classes.exerciseOptions }>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        20%
                        <input type="radio" name="equation" value="1" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        22%
                        <input type="radio" name="equation" value="2" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        35%
                        <input type="radio" name="equation" value="3" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        42%
                        <input type="radio" name="equation" value="4" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 2 && (
            <div className={ classes.contentBlock }>
              <h3>The &apos;S&apos; in a smart goal stands for _________. When creating a goal, you want to write down exactly what you are trying to achieve including as much detail as possible.</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classes.exerciseOptions }>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        Smart
                        <input type="radio" name="equation" value="1" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        Specific
                        <input type="radio" name="equation" value="2" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        Strategic
                        <input type="radio" name="equation" value="3" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        Special
                        <input type="radio" name="equation" value="4" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 3 && (
            <div className={ classes.contentBlock }>
              <h3>Which one of the below goals would be an example of a SMART goal?</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classNames( classes.exerciseOptions, classes.optionsFullWidth ) }>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        Save money for the future
                        <input type="radio" name="equation" value="1" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        Build an emergency fund of $2,500 over the next 3 months
                        <input type="radio" name="equation" value="2" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        Invest money in stocks
                        <input type="radio" name="equation" value="3" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        Don&apos;t spend too much money
                        <input type="radio" name="equation" value="4" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 4 && (
            <div className={ classes.contentBlock }>
              <h3>Why isn&apos;t the goal below a SMART goal:</h3>
              <h3>I&apos;m going to learn how to code Javascript and Python so I can earn a promotion to senior software engineer.</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classNames( classes.exerciseOptions, classes.optionsFullWidth ) }>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        The goal isn&apos;t specific
                        <input type="radio" name="equation" value="1" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        The goal isn&apos;t timely
                        <input type="radio" name="equation" value="2" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        The goal isn&apos;t measurable
                        <input type="radio" name="equation" value="3" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        None of the above because this is a great example of a SMART goal
                        <input type="radio" name="equation" value="4" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 5 && (
            <div className={ classNames( classes.contentBlock, classes.contentBlockNew ) }>
              <h3 className={ classes.blueText }>Visualize Your Future</h3>
              <h3>Take a moment and pause. In all the craziness of our day-to-day lives, sometimes we forget to think long-term. What are you trying to achieve over the next 5, 10, 15 years? Tough question, I know. But let&apos;s use a proven strategy to help. Goal setting and visualization.</h3>
              <h3>To visualize and set goals for your future, use the link below to download the My SMART Goals worksheet. Paint a picture of your answers in your mind. You might surprise yourself!</h3>
              <h3>Don&apos;t worry - we&apos;ll help you think of some goals. Click through the next couple of screens for inspiration after downloading your worksheet.</h3>

              <div className={ classes.pdfButton }><a href={ downloadSmartGoals } download="download" onClick={ () => checkAnswer( activeSlide, 5 ) } target="_blank" rel="noopener noreferrer">DOWNLOAD PDF</a></div>
            </div>
          )}

          { activeSlide === 6 && (
            <div className={ classes.contentBlock }>
              <h3 className={ classes.blueText }>PRO</h3>
              <h3 className={ classes.blueText }>Visualize your professional life...</h3>
              <ul className={ classes.tickList }>
                <li>Where will you be working?</li>
                <li>What career will you be waking up for?</li>
                <li>Are you in business casual or business formal heading to work?</li>
                <li>Will you be taking public transportation or driving your own car?</li>
                <li>Will you look to start your own business?</li>
                <li>Will you be working on a team? Or, out on the road making sales?</li>
                <li>Will you travel often? Or, hunker down at headquarters?</li>
              </ul>
              <div>
                <div className={ classes.proText }>
                  <h3>Next 5 years</h3>
                  <p>ex. Start my marketing career in New York City</p>
                  <p>ex. Get my master’s degree in engineering at Penn State University</p>
                </div>
                <div className={ classes.proText }>
                  <h3>Next 10 years</h3>
                  <p>ex. Lead a marketing team for a technology company</p>
                  <p>ex. Earn my professional engineering license for civil engineering</p>
                </div>
                <div className={ classes.proText }>
                  <h3>Next 15 years</h3>
                  <p>ex. Start my own marketing agency that helps technology companies grow</p>
                  <p>ex. Lead a team of engineers working on suburban neighborhoods</p>
                </div>
              </div>
            </div>
          )}
          { activeSlide === 7 && (
            <div className={ classes.contentBlock }>
              <h3 className={ classes.blueText }>MONEY</h3>
              <h3 className={ classes.blueText }>Visualize your financial life...</h3>
              <ul className={ classes.tickList }>
                <li>How much money will you be making per month? Per year?</li>
                <li>How much money will you be saving per month? Per year?</li>
                <li>Will you be earning a salary and getting a consistent monthly paycheck? Or, making sales and earning commission?</li>
                <li>Will you be renting an apartment with friends?</li>
                <li>Will you be saving to buy a home? What type of home will you buy?</li>
                <li>Will you lease or own a car? What kind of car?</li>
              </ul>
              <div>
                <div className={ classes.proText }>
                  <h3>Next 5 years</h3>
                  <p>ex. Make over $50,000 per year after I graduate from college</p>
                  <p>ex. Max out my employer matching program for retirement contributions</p>
                </div>
                <div className={ classes.proText }>
                  <h3>Next 10 years</h3>
                  <p>ex. Make over $100,000 per year and save over $20,000 in that year</p>
                  <p>ex. Repay my student loans ahead of schedule</p>
                </div>
                <div className={ classes.proText }>
                  <h3>Next 15 years</h3>
                  <p>ex. Buy a $500,000 home in the suburbs near my family</p>
                  <p>ex. Start a separate investment account for my future children</p>
                </div>
              </div>
            </div>
          )}
          { activeSlide === 8 && (
            <div className={ classes.contentBlock }>
              <h3 className={ classes.blueText }>FUN</h3>
              <h3 className={ classes.blueText }>Visualize the fun stuff in your life...</h3>
              <ul className={ classes.tickList }>
                <li>What will your hobbies be?</li>
                <li>Will you be exercising in the morning? After work? Late evening?</li>
                <li>Where will you vacation to?</li>
                <li>How will you spend time with friends?</li>
                <li>How will you spend time with family?</li>
                <li>Will you have a pet?</li>
                <li>Will you be in a relationship? Maybe even married?</li>
              </ul>
              <div>
                <div className={ classes.proText }>
                  <h3>Next 5 years</h3>
                  <p>ex. Complete a half and full marathon for charity</p>
                  <p>ex. Take one vacation a year with friends</p>
                </div>
                <div className={ classes.proText }>
                  <h3>Next 10 years</h3>
                  <p>ex. Visit every state that has a beach in the U.S.</p>
                  <p>ex. Break 90 on the golf course on a consistent basis</p>
                </div>
                <div className={ classes.proText }>
                  <h3>Next 15 years</h3>
                  <p>ex. Take my family on a beach vacation every year</p>
                  <p>ex. Build an art studio in my house for my painting hobby</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={ classes.answerStatus }>
          {questionList[activeSlide - 1].status === 'C' && (
          <h2 className={ classes.correctAnswer }>
            Correct! You earned +
            {questionList[activeSlide - 1].xp_earned}
            {' '}
            XP.
          </h2>
          )}
          {questionList[activeSlide - 1].status === 'W' && <h2 className={ classes.wrongAnswer }>Incorrect. Try again!</h2>}
        </div>
        <div className={ classes.btnGroup }>
          {activeSlide !== 1 && <Button className={ classNames( classes.manageButton, classes.backButton ) } onClick={ () => { setActiveSlide( activeSlide - 1 ); } }>Back</Button>}
          <div className={ classes.progressXp }>
            <div className={ classes.questionXp }>
              <p>
                <span>Question XP:</span>
                {' '}
                +
                {questionList[activeSlide - 1].xp_earned}
                {' '}
                XP
              </p>
              <div className={ classes.progressBlock }>
                <div className={ classes.progressBar } style={ { width: `${questionProgressData.questions_progress}%` } } role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
              </div>
              <span>
                {eachQuestionXp}
                {' '}
                XP
              </span>
            </div>
            <div className={ classNames( classes.questionXp, classes.totalQuestion ) }>
              <p>
                <span>Total XP:</span>
                {' '}
                +
                {questionProgressData.total_progress_val}
                {' '}
                XP
              </p>
              <div className={ classes.progressBlock }>
                <div className={ classes.progressBar } style={ { width: `${questionProgressData.total_progress}%` } } role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
              </div>
              <span>
                {eachQuestionXp * defaultQuestionList.length}
                {' '}
                XP
              </span>
            </div>
          </div>
          {activeSlide < 5 && (
            <Button className={ `${classes.manageButton} ${classes.checkAnswer}` } disabled={ questionList[activeSlide - 1].answer === '' } onClick={ () => checkAnswer( activeSlide ) }>Check Answer</Button>
          )}
          {defaultQuestionList.length === activeSlide ? (
            <Button className={ classNames( classes.manageButton, classes.nextButton ) } disabled={ questionList[activeSlide - 1].status !== 'C' } onClick={ () => finishActivity() }>FINISH</Button>
          ) : (
            <Button className={ classNames( classes.manageButton, classes.nextButton ) } disabled={ questionList[activeSlide - 1].status !== 'C' } onClick={ () => { setActiveSlide( activeSlide + 1 ); } }>Next</Button>
          )}

        </div>
      </div>
      {( popupDetails && popupDetails.popupOpen === true ) && (
        <Pf101Popups details={ popupDetails } />
      )}
    </Typography>
  );
};

SmartGoals.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( SmartGoals );
