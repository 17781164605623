const styles = ( theme ) => ( {
  fiModuleIcons: {
    '& > ul': {
      paddingLeft: '0px',
      textAlign: 'center',
     // marginTop: '0px',
      // marginBottom: '25px',
      margin: '15px',
      '& > li': {
        display: 'inline-block',
        paddingRight: '5px',
        paddingLeft: '5px',
        margin: '2px',
        verticalAlign: 'top',
        borderTop: '2px solid #1c6645',
        paddingTop: '2px',
        borderTopColor: 'transparent',
        '@media (max-width: 768px)': {
          paddingLeft: '2px',
          paddingRight: '1px',
        },
        '& a': {
          display: 'block',
          position: 'relative',
          '& img': {
            maxWidth: '45px',
            width: '100%',
            verticalAlign: 'middle',
            '@media (max-width: 1024px)': {
              maxWidth: '40px',            
            },
            '@media (max-width: 992px)': {
              maxWidth: '30px',            
            },
            '@media (max-width: 768px)': {
              maxWidth: '25px',            
            },
          },
        },
      },
    },
  },
  fiLeftArrow: {
    '& a': {
      display: 'block',
      position: 'relative',
      width: '35px',
      height: '35px',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        width: '100%',
        height: '2px',
        left: '2px',
        background: '#bcbec0',
        marginTop: '-2px',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '8px',
        width: '18px',
        height: '18px',
        borderTop: '2px solid #bcbec0',
        borderLeft: '2px solid #bcbec0',
        transform: 'rotate(-42deg) skew(10deg)',
        left: '5px',
      },
    },
  },
  timelineBar: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
    color: '#898d8f',
    fontSize: '12px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '10px',
    },
    '& li': {
      margin: '2px',
      verticalAlign: 'top',
      textAlign: 'center',
    },
  },
  tooltipInfoTop: {
    maxWidth: '200px',
    background: '#0069aa !important',
    fontSize: '14px !important',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '0.9 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.42857143',
    borderRadius: '4px',
    boxSizing: 'border-box',
    '&::after': {
      opacity: '0.9 !important',
      borderTopColor: '#0069aa !important',
      borderLeft: '6px solid transparent !important',
      borderRight: '6px solid transparent !important',
    },
  },
  fiModuleIconsVisible:{
    borderRadius: '15px',
    background: '#fff',
    position: 'relative',
    zIndex: '999999',
    cursor: 'none',
    padding: '8px',
  }
} );

export default styles;
