import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import NumberFormat from 'react-number-format';
import {Tabs, Tab} from '@material-ui/core';
import * as modulesServices from '../../calculations/modules-services';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import classNames from 'classnames';

const HomeSummary = ( props ) => {
  const { classes, home, handleAssestObject} = props;
  const [value, setValue] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  var existingStudentLoanOutputData = {};
  existingStudentLoanOutputData = modulesServices.existingStudentLoanOutputGraphData();
  var existingHomeLoanOutputData  = existingStudentLoanOutputData['Existing Home' + home];

  var existingHomeLoanBalanceData = existingHomeLoanOutputData['Mortgage Balance'];
  var existingHomeLoanPaymentData = existingHomeLoanOutputData['Home Value'];
  var existingHomeLoanPrincipalPaybackData = existingHomeLoanOutputData['Principal Payback'];
  var existingHomeLoanInterestData = existingHomeLoanOutputData['Interest'];
  var existingHomeLoanYearsList = existingHomeLoanOutputData['yearsList'];
  React.useEffect(() => {
    setTotal( home === 1 ? 
      parseFloat(modulesServices.module18Data.existig_home_current_mortgage_balance) - parseFloat(modulesServices.module18Data.existig_home_current_value) : 
      parseFloat(modulesServices.module18Data.existig_home_current_mortgage_balance_2) - parseFloat(modulesServices.module18Data.existig_home_current_value_2) )
  }, [])
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={ classes.contentBlock }>
      <div className={ classes.tabSection }>
        <Tabs
          value={ value }
          onChange={ handleChange }
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className={ classes.tabButtons }
          aria-label="full width tabs example"
        >
          <Tab className={ value === 0 ? classes.activeTab : '' } label="Table" />
          <Tab className={ value === 1 ? classes.activeTab : '' } label="Graph" />

      </Tabs>
        <div className={ `${classes.tabsContent} ${classes.graphBoxNew} ${classes.graphBoxNewGraph}` }>
          {value === 0 && (
            <div className={ classes.contentBlock }>
              <div className={classNames(classes.tableBox, classes.tableBoxPWINewHomeExpensive, classes.mbot0)}>
                <table cellPadding="0" cellSpacing="0">
                  <thead>
                    <th colSpan={ 2 }>Home Equity</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Current Home Value</td>
                      <td>
                        <div className={ classes.amountDollar }>
                          {
                            modulesServices.module18Data.existig_home_current_value !== undefined || modulesServices.module18Data.existig_home_current_value_2 !== undefined ?
                              <NumberFormat decimalScale={0} fixedDecimalScale thousandSeparator displayType="text" value={ home === 1 ? modulesServices.module18Data.existig_home_current_value : modulesServices.module18Data.existig_home_current_value_2 }  prefix="$"/>
                            : "$0"
                          }
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td >Mortgage Balance</td>
                      <td>
                        <div className={ classes.amountDollar }>
                          {modulesServices.module18Data.existig_home_current_mortgage_balance !== undefined || modulesServices.module18Data.existig_home_current_mortgage_balance_2 !== undefined ? (
                            <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1? modulesServices.module18Data.existig_home_current_mortgage_balance : modulesServices.module18Data.existig_home_current_mortgage_balance_2 } prefix="$"/> ) : ( '$0' )}
                        </div>
                      </td>
                    </tr>
                    <tr className={ classes.trFoot }>
                      <td className={ classes.firstYearTutuin }>Home Equity</td>
                      <td>
                        <div className={ classes.amountDollarRight }>
                          <span>
                            {
                              (modulesServices.exisitngHomeSummary(home) !== undefined ? (
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.exisitngHomeSummary(home) } prefix={ modulesServices.exisitngHomeSummary(home) >= 0 ? '$' : '($' } suffix={ modulesServices.exisitngHomeSummary(home) < 0 && ')' } />
                              ) : ( '$0' ))
                            }
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={ `${classes.tableBox} ${classes.tableBoxPWINewHomeExpensive} ${classes.mbot0}` }>
               
                <table cellPadding="0" cellSpacing="0">
                  <thead>
                    <tr className={ classes.trHead }>
                      <th colSpan={ 2 }>Home Expenses</th>
                      <th colSpan={ 2 }>Monthly</th>
                      <th colSpan={ 2 }>Annual</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={ 2 }>Property Taxes</td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollar }>
                          {handleAssestObject.existing_home_monthly_property_taxes !== undefined ? (
                          <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_property_taxes :  handleAssestObject[`existing_home_monthly_property_taxes_${home}`]} prefix="$"/> ) : ( '$0' )}
                        </div>
                      </td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollar }>
                          {handleAssestObject.existing_home_monthly_property_taxes !== undefined ? (
                            <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_property_taxes * 12 :  handleAssestObject[`existing_home_monthly_property_taxes_${home}`] * 12 } prefix="$"/> ) : ( '$0' )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={ 2 }>Homeowner&apos;s Insurance</td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollar }>
                          {handleAssestObject.existing_home_monthly_homeowner_insurance !== undefined ? (
                            <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_homeowner_insurance :  handleAssestObject[`existing_home_monthly_homeowner_insurance_${home}`] } prefix="$"/> ) : ( '$0' )}
                        </div>
                      </td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollar }>
                          {handleAssestObject.existing_home_monthly_homeowner_insurance !== undefined ? (
                            <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_homeowner_insurance * 12 :  handleAssestObject[`existing_home_monthly_homeowner_insurance_${home}`] * 12 } prefix="$"/> ) : ( '$0' )}
                          </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={ 2 }>Private Mortgage Insurance (PMI)</td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollar }>
                          ${handleAssestObject.existing_home_monthly_private_mortgage_insurance !== undefined ? (
                          <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_private_mortgage_insurance :  handleAssestObject[`existing_home_monthly_private_mortgage_insurance_${home}`] } /> ) : ( '0' )}
                        </div>
                      </td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollar }>
                          ${handleAssestObject.existing_home_monthly_private_mortgage_insurance !== undefined ? (
                            <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_private_mortgage_insurance * 12 :  handleAssestObject[`existing_home_monthly_private_mortgage_insurance_${home}`] * 12 } /> ) : ( '0' )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={ 2 }>Maintenance</td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollar }>
                          ${handleAssestObject.existing_home_monthly_maintenance !== undefined ? (
                          <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_maintenance :  handleAssestObject[`existing_home_monthly_maintenance_${home}`] } /> ) : ( '0' )}
                        </div>
                      </td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollar }>
                          ${handleAssestObject.existing_home_monthly_maintenance !== undefined ? (
                            <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_maintenance * 12 :  handleAssestObject[`existing_home_monthly_maintenance_${home}`] * 12 } /> ) : ( '0' )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={ 2 }>Association Fees</td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollar }>
                          ${handleAssestObject.existing_home_monthly_association_fees !== undefined ? (
                          <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_association_fees :  handleAssestObject[`existing_home_monthly_association_fees_${home}`] } /> ) : ( '0' )}
                        </div>
                      </td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollar }>
                          ${handleAssestObject.existing_home_monthly_association_fees !== undefined ? (
                            <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_association_fees * 12 :  handleAssestObject[`existing_home_monthly_association_fees_${home}`] * 12 } /> ) : ( '0' )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={ 2 }>Utilities</td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollar }>
                          ${handleAssestObject.existing_home_monthly_utilities !== undefined ? (
                          <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_utilities :  handleAssestObject[`existing_home_monthly_utilities_${home}`] } /> ) : ( '0' )}
                        </div>
                      </td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollar }>
                          ${handleAssestObject.existing_home_monthly_utilities !== undefined ? (
                            <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_monthly_utilities * 12 :  handleAssestObject[`existing_home_monthly_utilities_${home}`] * 12 } /> ) : ( '0' )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={ 2 }>Mortgage Monthly Payment (Principal & Interest)</td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollarRight }>
                          <span>
                            ${modulesServices.module18Data.existingHomeMortgageMonthlyPayment !== undefined ? (
                              <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? modulesServices.module18Data.existingHomeMortgageMonthlyPayment : modulesServices.module18Data.existingHomeMortgageMonthlyPayment2} /> ) : ( '0' )}
                          </span>
                        </div>
                      </td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollarRight }>
                          <span>
                            ${modulesServices.module18Data.existingHomeMortgageMonthlyPayment !== undefined ? (
                              <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ home === 1 ? modulesServices.module18Data.existingHomeMortgageMonthlyPayment * 12 : modulesServices.module18Data.existingHomeMortgageMonthlyPayment2 * 12 } /> ) : ( '0' )}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                      <td colSpan={ 2 }>Total Monthly Home Expenses</td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollarRight }>
                          <span>
                            ${modulesServices.totalMonthlyHomeExpenses() !== undefined ? (
                              <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" thousandSeparator value={ home === 1 ? modulesServices.totalMonthlyHomeExpenses() : modulesServices.totalMonthlyHomeExpenses2()}/> ) : ( '0' )}
                          </span>
                        </div>
                      </td>
                      <td colSpan={ 2 }>
                        <div className={ classes.amountDollarRight }>
                          <span>
                            ${modulesServices.totalMonthlyHomeExpenses2() !== undefined ? (
                              <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" thousandSeparator value={ home === 1 ? modulesServices.totalMonthlyHomeExpenses() * 12 : modulesServices.totalMonthlyHomeExpenses2()  * 12 }/> ) : ( '0' )}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              
              </div>
            </div>
          )}
          {
            value === 1 && (
              <div>
                <HighchartsReact
                    highcharts={ Highcharts }
                    options={{
                    chart: {
                      plotBackgroundColor: '#ffffff',
                      plotBorderColor: '#cccccc',
                      plotBorderWidth: 1,
                      plotShadow: false,
                      zoomType: false,
                    },
                    navigation: {
                      menuItemStyle: {
                        fontSize: '14px',
                        textAlign: 'left'
                      },
                      menuItemHoverStyle: {
                        background: '#f5f5f5',
                        color: '#4c4c4c',
                        fontSize: '14px'
                      },
                      buttonOptions: {
                        height: 40,
                        width: 48,
                        symbolSize: 24,
                        symbolX: 24,
                        symbolY: 21,
                        symbolStrokeWidth: 2,
                        verticalAlign: 'bottom',
                        _titleKey: 'y'
                      }
                    },
                        exporting: {
                            buttons: {
                                contextButton: {
                                  menuItems: [{
                                        textKey: 'downloadPNG',
                                        onclick: function () {
                                            this.exportChart();
                                        },
                                    }, {
                                        textKey: 'downloadJPEG',
                                        onclick: function () {
                                            this.exportChart({
                                                type: 'image/jpeg'
                                            });
                                        }
                                    },{
                                        textKey: 'downloadPDF',
                                        onclick: function () {
                                            this.exportChart({
                                                type: 'application/pdf'
                                            });
                                        }
                                    }, {
                                        textKey: 'downloadSVG',
                                        onclick: function () {
                                            this.exportChart({
                                                type: 'image/svg+xml'
                                            });
                                        }
                                    }]
                                }
                            }
                          },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: ''
                    },
                    xAxis: [{
                        categories: existingHomeLoanYearsList,
                        crosshair: true,
                        labels: {
                            style: {
                                color: '#000000',
                                fontSize: '14px'
                            }
                        }
                    }],
                    yAxis: [{ // Primary yAxis
                        labels: {
                            formatter: function() {
                                if( this.value < 0 ) {
                                    return '<span style="color:#bf0000;">('+(-Math.round(this.value))+')</span>';
                                } else { 
                                    return (Math.round(this.value));
                                }
                            },
                            style: {
                                color: '#000000',
                                fontSize: '14px'
                            }
                        },
                        title: {
                            text: 'Principal and Interest',
                            style: {
                                color: '#000000',
                                fontSize: '14px'
                            }
                        },
                        opposite: true
                    }, { // Secondary yAxis
                        gridLineWidth: 0,
                        title: {
                            text: 'Existing Home Mortgage Balance',
                            style: {
                                color: '#000000',
                                fontSize: '14px'
                            }
                        },
                        labels: {
                            formatter: function() {
                                if( this.value < 0 ) {
                                    return '<span style="color:#bf0000;">('+(-Math.round(this.value))+')</span>';
                                } else { 
                                    return (Math.round(this.value));
                                }
                            },
                            style: {
                                color: '#000000',
                                fontSize: '14px'
                            }
                        }
                    }],
                    tooltip: {},
                    legend: {
                        itemStyle: {
                            fontSize: '14px'
                        }
                    },
                    series: [{
                        name: 'Mortgage Balance',
                        type: 'column',
                        yAxis: 1,
                        data: existingHomeLoanBalanceData,
                        tooltip: {
                            valuePrefix: '$'
                        },
                        color: '#CC0000'
                    },{
                        name: 'Home Value',
                        type: 'column',
                        yAxis: 1,
                        data: existingHomeLoanPaymentData,
                        tooltip: {
                            valuePrefix: '$'
                        },
                        color: '#0070C0'
                    }, {
                        name: 'Principal Payback',
                        type: 'spline',
                        data: existingHomeLoanPrincipalPaybackData,
                        yAxis: 0,
                        tooltip: {
                            valuePrefix: '$'
                        },
                        color: '#548235'
                    }, {
                        name: 'Interest',
                        type: 'spline',
                        yAxis: 0,
                        data: existingHomeLoanInterestData,
                        marker: {
                            enabled: true
                        },
                        tooltip: {
                            valuePrefix: '$'
                        },
                        color: '#C5E0B4'
                    }]
                }}
              />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

HomeSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
};

export default withStyles( styles )( HomeSummary );
