import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from './styles';
import * as moduleServices from '../../../calculations/modules-services';
import TaxStatementPopup from './tax-statement-popup';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const TaxStatement = (props) => {
  const {
    classes, dispalyObj, rangeList,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogName, setOpenDialogName] = useState(false);
  const [openGrossIncomeModel, setOpenGrossIncomeModel] = useState('');
  const [openTaxCreditsDetailModel, setOpenTaxCreditsDetailModel] = useState('');
  let [detailedInfo, setDetailedInfo] = useState({});
  let [assumptionsInfo, setAssumptionsInfo] = useState({});

  const showDetailInfo = (selectedYear, selectedSection, selectedColumn) => {
    let detailedInfo = {};
    detailedInfo.condition = 0;
    let incomeTaxesData = moduleServices.inputTaxesCompleteData();
    let incomeStatementData = moduleServices.incomeStatementCompleteData();
    if (typeof selectedYear !== 'undefined' && selectedYear !== '' && selectedYear > 0 &&
      typeof selectedSection !== 'undefined' && selectedSection !== '' &&
      typeof selectedColumn !== 'undefined' && selectedColumn !== '') {


      let assumptionsData = moduleServices.assumptionsCompleteData();

      setAssumptionsInfo(assumptionsData[selectedYear]);

      // let IncomeGrowth = parseFloat(moduleServices.module1Data.incomeGrowth);
      // let Inflation = parseFloat(moduleServices.module1Data.inflation);

      detailedInfo = incomeStatementData[selectedYear];
      detailedInfo.selectedColumn = selectedColumn;
      detailedInfo.Year = selectedYear;

      switch (selectedSection) {
        /********************
     * Income Statement *
     ********************/
        /* Gross Income */

        case 'Gross Income':
          switch (selectedColumn) {
            case 'Income Earned during Higher Education':
              if (typeof moduleServices.module2Data.incomeStartYear !== 'undefined' && typeof moduleServices.module2Data.incomeEndYear !== 'undefined' && selectedYear >= moduleServices.module2Data.incomeStartYear && selectedYear <= moduleServices.module2Data.incomeEndYear && typeof moduleServices.module2Data.educationName !== 'undefined' && typeof moduleServices.module2Data.yearlyIncome !== 'undefined' && parseInt(moduleServices.module2Data.yearlyIncome, 10) > 0) {
                if (moduleServices.module2Data.educationName.name === 'Other' && typeof moduleServices.module2Data.CustomName !== 'undefined') {
                  detailedInfo.headingHigherEducation = moduleServices.module2Data.CustomName + ' - Income Earned during Higher Education';
                } else {
                  detailedInfo.headingHigherEducation = moduleServices.module2Data.educationName.name + ' - Income Earned during Higher Education';
                }
                detailedInfo.firstHigherEducationStartYear = moduleServices.module2Data.incomeStartYear;
                detailedInfo.firstHigherEducationEndYear = moduleServices.module2Data.incomeEndYear;
                detailedInfo.firstHigherEducationAnnualIncome = parseInt(moduleServices.module2Data.yearlyIncome, 10);
              } else {
                if (typeof moduleServices.module6Data.incomeEarnedstartYear !== 'undefined' && typeof moduleServices.module6Data.incomeEarnedendYear !== 'undefined' && selectedYear >= moduleServices.module6Data.incomeEarnedstartYear && selectedYear <= moduleServices.module6Data.incomeEarnedendYear && typeof moduleServices.module6Data.educationName !== 'undefined' && typeof moduleServices.module6Data.incomeEarnedYearlyIncome !== 'undefined' && parseInt(moduleServices.module6Data.incomeEarnedYearlyIncome, 10) > 0) {
                  if (moduleServices.module6Data.educationName.name === 'Other' && typeof moduleServices.module6Data.CustomName !== 'undefined') {
                    detailedInfo.headingHigherEducation = moduleServices.module6Data.CustomName + ' - Income Earned during Higher Education';
                  } else {
                    detailedInfo.headingHigherEducation = moduleServices.module6Data.educationName.name + ' - Income Earned during Higher Education';
                  }
                  detailedInfo.firstHigherEducationStartYear = moduleServices.module6Data.incomeEarnedstartYear;
                  detailedInfo.firstHigherEducationEndYear = moduleServices.module6Data.incomeEarnedendYear;
                  detailedInfo.firstHigherEducationAnnualIncome = parseInt(moduleServices.module6Data.incomeEarnedYearlyIncome, 10);
                } else {
                  if (typeof moduleServices.module15Data.incomeEarnedstartYear !== 'undefined' && typeof moduleServices.module15Data.incomeEarnedendYear !== 'undefined' && selectedYear >= moduleServices.module15Data.incomeEarnedstartYear && selectedYear <= moduleServices.module15Data.incomeEarnedendYear && typeof moduleServices.module15Data.educationName !== 'undefined' && typeof moduleServices.module15Data.incomeEarnedYearlyIncome !== 'undefined' && parseInt(moduleServices.module15Data.incomeEarnedYearlyIncome, 10) > 0) {
                    if (moduleServices.module15Data.educationName.name === 'Other' && typeof moduleServices.module15Data.CustomName !== 'undefined') {
                      detailedInfo.headingHigherEducation = moduleServices.module15Data.CustomName + ' - Income Earned during Higher Education';
                    } else {
                      detailedInfo.headingHigherEducation = moduleServices.module15Data.educationName.name + ' - Income Earned during Higher Education';
                    }

                    detailedInfo.firstHigherEducationStartYear = moduleServices.module15Data.incomeEarnedstartYear;
                    detailedInfo.firstHigherEducationEndYear = moduleServices.module15Data.incomeEarnedendYear;
                    detailedInfo.firstHigherEducationAnnualIncome = parseInt(moduleServices.module15Data.incomeEarnedYearlyIncome, 10);
                  } else {
                    detailedInfo.headingHigherEducation = 'Income Earned during Higher Education';
                  }
                }
              }
              detailedInfo.previousYearValueCondition = 0;
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear - 1] !== 'undefined' && typeof incomeStatementData[selectedYear - 1]['Gross Income']['Income Earned during Higher Education'] !== 'undefined' && incomeStatementData[selectedYear - 1]['Gross Income']['Income Earned during Higher Education'] > 0) {
                detailedInfo.previousYearValue = incomeStatementData[selectedYear - 1]['Gross Income']['Income Earned during Higher Education'];
                detailedInfo.previousYearValueCondition = 1;
              }
              detailedInfo.finalYearValueCondition = 0;
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined' && typeof incomeStatementData[selectedYear]['Gross Income']['Income Earned during Higher Education'] !== 'undefined' && incomeStatementData[selectedYear]['Gross Income']['Income Earned during Higher Education'] > 0) {
                detailedInfo.finalYearValue = incomeStatementData[selectedYear]['Gross Income']['Income Earned during Higher Education'];
                detailedInfo.finalYearValueCondition = 1;
              } else {
                detailedInfo.finalYearValue = 0;
              }
              if (typeof moduleServices.module1Data.incomeGrowth !== 'undefined') {
                detailedInfo.incomeGrowthValue = moduleServices.module1Data.incomeGrowth;
              }
              // setOpenIncomeEarnedDuringHigher( true );
              setOpenDialog(true);
              setOpenDialogName('homePropertyIncomeEarnedDuringHogher');
              break;
            // case 'Income Earned during ' + $rootScope.educationTitle:
            //   if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
            //     typeof incomeStatementData[selectedYear] !== 'undefined') {

            //     detailedInfo.StartYear = moduleServices.module2Data.incomeStartYear;
            //     detailedInfo.EndYear = moduleServices.module2Data.incomeEndYear;
            //     detailedInfo.YearlyIncome = parseInt(moduleServices.module2Data.yearlyIncome, 10);

            //     detailedInfo.condition = incomeStatementData[selectedYear]['Income Earned Condition'];
            //     if (detailedInfo.condition === 2) {
            //       detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
            //     }

            //     detailedInfo.Label = 'Income';

            //     // Show Income Earned Portion
            //     // let nb = $('div.modal-backdrop').length;
            //     // if (nb <= 1) {
            //     //   $('.income-sections').show().siblings().hide();
            //     //   $('#showDetailPopup').modal('show');
            //     // }
            //   }
            //   break;
            // 
            case moduleServices.module4Data.occupation_title:
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {

                detailedInfo.StartYear = moduleServices.module4Data.startYear;
                detailedInfo.EndYear = moduleServices.module4Data.incomeEndYear;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module4Data.yearlyIncome, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Income Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Bonus / Tips / Commission':
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {

                detailedInfo.StartYear = moduleServices.module4Data.startYear;
                detailedInfo.EndYear = moduleServices.module4Data.incomeEndYear;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module4Data.bonusTips, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Bonus Tips Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Bonus / Tips / Commission';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Supplementary Income':
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {

                detailedInfo.StartYear = moduleServices.module4Data.supplementaryStartYear;
                detailedInfo.EndYear = moduleServices.module4Data.supplementaryEndYear;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module4Data.supplementaryYearlyIncome, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Supplementary Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Supplementary Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case moduleServices.module5Data.occupation_title + ' - Second':
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {

                detailedInfo.StartYear = moduleServices.module5Data.startYear;
                detailedInfo.EndYear = moduleServices.module5Data.incomeEndYear;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module5Data.yearlyIncome, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Income - Second Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Bonus / Tips / Commission - Second':
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {

                detailedInfo.StartYear = moduleServices.module5Data.startYear;
                detailedInfo.EndYear = moduleServices.module5Data.incomeEndYear;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module5Data.bonusTips, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Bonus / Tips / Commission - Second Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Bonus / Tips / Commission';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Supplementary Income - Second':
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {

                detailedInfo.StartYear = moduleServices.module5Data.startYear;
                detailedInfo.EndYear = moduleServices.module5Data.incomeEndYear;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module5Data.supplementaryYearlyIncome, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Supplementary Income - Second Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Supplementary Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            // case 'Income Earned during ' + $rootScope.higherEducationTitle:
            //   if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
            //     typeof incomeStatementData[selectedYear] !== 'undefined') {

            //     detailedInfo.StartYear = moduleServices.module6Data.incomeEarnedstartYear;
            //     detailedInfo.EndYear = moduleServices.module6Data.incomeEarnedendYear;
            //     detailedInfo.YearlyIncome = parseInt(moduleServices.module6Data.incomeEarnedYearlyIncome, 10);

            //     detailedInfo.condition = incomeStatementData[selectedYear]['Income Earned Second Condition'];
            //     if (detailedInfo.condition === 2) {
            //       detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
            //     }

            //     detailedInfo.Label = 'Income';

            //     // Show Income Earned Portion
            //     // let nb = $('div.modal-backdrop').length;
            //     // if (nb <= 1) {
            //     //   $('.income-section').show().siblings().hide();
            //     //   $('#showDetailPopup').modal('show');
            //     // }
            //   }
            //   break;

            case moduleServices.module8Data.occupation_title + ' - Third':
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {

                detailedInfo.StartYear = moduleServices.module8Data.startYear;
                detailedInfo.EndYear = moduleServices.module8Data.endYear;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module8Data.jobyearlyIncome, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Income Second Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Bonus / Tips / Commission - Third':
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {

                detailedInfo.StartYear = moduleServices.module8Data.startYear;
                detailedInfo.EndYear = moduleServices.module8Data.endYear;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module8Data.jobbonusTips, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Bonus / Tips / Commission - Third Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Bonus / Tips / Commission';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case moduleServices.module17Data.occupation_title + ' - Fourth':
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {

                detailedInfo.StartYear = moduleServices.module17Data.startYear;
                detailedInfo.EndYear = moduleServices.module17Data.endYear;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module17Data.jobyearlyIncome, 10);

                detailedInfo.condition = incomeStatementData[selectedYear][moduleServices.module17Data.occupation_title + ' - Fourth Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Bonus / Tips / Commission - Fourth':
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {

                detailedInfo.StartYear = moduleServices.module8Data.startYear;
                detailedInfo.EndYear = moduleServices.module8Data.endYear;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module17Data.jobbonusTips, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Bonus / Tips / Commission - Fourth Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Bonus / Tips / Commission';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Supplementary Income - Third':
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {

                detailedInfo.StartYear = moduleServices.module8Data.supplementaryIncomeStartYear;
                detailedInfo.EndYear = moduleServices.module8Data.supplementaryIncomeEndYear;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module8Data.supplementaryYearlyIncome, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Supplementary Income - Third Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Supplementary Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Supplementary Income - Fourth':

              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {

                detailedInfo.StartYear = moduleServices.module17Data.supplementaryIncomeStartYear;
                detailedInfo.EndYear = moduleServices.module17Data.supplementaryIncomeEndYear;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module17Data.supplementaryYearlyIncome, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Supplementary Income - Fourth Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Supplementary Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Spouses Income':
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {

                detailedInfo.condition = 0;
                let condition = incomeStatementData[selectedYear]['Spouses Income Condition'];
                let condition2 = incomeStatementData[selectedYear]['Spouses Income Second Condition'];
                if (condition === 1 || condition === 2) {
                  detailedInfo.condition = 1;
                  detailedInfo.StartYear = moduleServices.module10Data.startyear;
                  detailedInfo.EndYear = moduleServices.module10Data.endYear;
                  detailedInfo.YearlyIncome = moduleServices.module10Data.preTaxExpectedIncome;

                  if (condition === 2) {
                    detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                    detailedInfo.condition = 2;
                  }
                } else {
                  if (condition2 === 1 || condition2 === 2) {
                    detailedInfo.condition = 1;
                    detailedInfo.StartYear = moduleServices.module10Data.moreStartyear;
                    detailedInfo.EndYear = moduleServices.module10Data.moreEndYear;
                    detailedInfo.YearlyIncome = moduleServices.module10Data.morePreTaxExpectedIncome;

                    if (condition2 === 2) {
                      detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                      detailedInfo.condition = 2;
                    }
                  } else {
                    detailedInfo.StartYear = moduleServices.module10Data.startyear;
                    detailedInfo.EndYear = moduleServices.module10Data.endYear;
                    detailedInfo.YearlyIncome = moduleServices.module10Data.preTaxExpectedIncome;
                  }
                }

                detailedInfo.Label = 'Spouse\'s Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Net Rental Income':
              let invPropertyRoiCompleteData = moduleServices.invPropertyRoiCompleteData();
              if (typeof invPropertyRoiCompleteData !== 'undefined' && invPropertyRoiCompleteData !== '' &&
                typeof invPropertyRoiCompleteData['Data1'][selectedYear] !== 'undefined') {

                detailedInfo = invPropertyRoiCompleteData['Data1'][selectedYear];
                detailedInfo.Year = selectedYear;
                // let finalValueCondition = 0;
                if (invPropertyRoiCompleteData['Data1'][selectedYear]['Net Income'] !== 0) {
                  detailedInfo.finalValueCondition = 1;
                }
                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('net-rental-income');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Rental Income':

              let incomeStatementCompleteData = moduleServices.incomeStatementCompleteData();
              //let invPropertyRoiCompleteData = moduleServices.invPropertyRoiCompleteData();

              if (typeof invPropertyRoiCompleteData !== 'undefined' && invPropertyRoiCompleteData !== '' && typeof invPropertyRoiCompleteData['Buying Expenses'] !== 'undefined') {
                detailedInfo.purchaseYear = invPropertyRoiCompleteData['Buying Expenses']['Purchase Year'];
                detailedInfo.sellYear = invPropertyRoiCompleteData['Buying Expenses']['Sell Year'];
              }
              if (typeof moduleServices.module14Data.yearlyRentalIncome !== 'undefined' && moduleServices.module14Data.yearlyRentalIncome !== '') {
                detailedInfo.annualRentalIncome = parseInt(moduleServices.module14Data.yearlyRentalIncome, 10);
              } else {
                detailedInfo.annualRentalIncome = 0;
              }
              if (typeof moduleServices.module14Data.vacancyRate !== 'undefined' && moduleServices.module14Data.vacancyRate !== '') {
                detailedInfo.vacancyRate = moduleServices.module14Data.vacancyRate;
              } else {
                detailedInfo.vacancyRate = 0;
              }

              //Current year Annual Rental income values
              if (typeof incomeStatementCompleteData !== 'undefined' && incomeStatementCompleteData !== '' && typeof incomeStatementCompleteData[selectedYear] !== 'undefined' && incomeStatementCompleteData[selectedYear] !== '') {
                detailedInfo.annualRentalIncomeCurrent = (100 / ((1 - (detailedInfo.vacancyRate / 100)) * 100)) * incomeStatementCompleteData[selectedYear]['Gross Income']['Rental Income'];
              } else {
                detailedInfo.annualRentalIncomeCurrent = 0;
              }
              detailedInfo.purchaseYearCondition = 0;
              if (selectedYear <= detailedInfo.purchaseYear) {
                detailedInfo.annualRentalIncomePrevious = 0;
                detailedInfo.incomeGrowthStarts = 0;
              } else {
                detailedInfo.purchaseYearCondition = 1;
                //Previous year Annual Rental income values     
                if (typeof incomeStatementCompleteData !== 'undefined' && incomeStatementCompleteData !== '' && typeof incomeStatementCompleteData[selectedYear - 1] !== 'undefined' && incomeStatementCompleteData[selectedYear - 1] !== '') {
                  detailedInfo.annualRentalIncomePrevious = (100 / ((1 - (detailedInfo.vacancyRate / 100)) * 100)) * incomeStatementCompleteData[selectedYear - 1]['Gross Income']['Rental Income'];
                } else {
                  detailedInfo.annualRentalIncomePrevious = 0;
                }
                //Annual Income Growth Value
                if (typeof moduleServices.module14Data.annualRentalIncomeGrowth !== 'undefined' && moduleServices.module14Data.annualRentalIncomeGrowth !== '') {
                  detailedInfo.incomeGrowthStarts = moduleServices.module14Data.annualRentalIncomeGrowth;
                } else {
                  detailedInfo.incomeGrowthStarts = 0;
                }
              }
              //Final value
              detailedInfo.annualRentalIncomeFinalCondition = 0;
              if (typeof incomeStatementCompleteData !== 'undefined' && incomeStatementCompleteData !== '' && typeof incomeStatementCompleteData[selectedYear] !== 'undefined' && incomeStatementCompleteData[selectedYear] !== '' && incomeStatementCompleteData[selectedYear]['Gross Income']['Rental Income'] !== 0) {
                detailedInfo.annualRentalIncomeFinal = incomeStatementCompleteData[selectedYear]['Gross Income']['Rental Income'];
                detailedInfo.annualRentalIncomeFinalCondition = 1;
              } else {
                detailedInfo.annualRentalIncomeFinal = 0;
              }
              // Show Net Rental Income Portion

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('rental-income');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Mortgage Interest, Loan Points & Origination Fees, & Depreciation':
              // let mortgage1Data = moduleServices.mortgage1CompleteData();
              let mortgage2Data = moduleServices.mortgage2CompleteData();
              let REIData = moduleServices.invPropertyRoiCompleteData();
              // let incomeStatementData = moduleServices.incomeStatementCompleteData();
              let taxStatementData = moduleServices.taxStatementCompleteData();
              let mortgageInterestValue = 0;
              let REIInterestValue = 0;
              //let reiPropertyMortgageInterestValueCondition = 0;

              if (typeof mortgage2Data !== 'undefined' && mortgage2Data !== '' && typeof mortgage2Data['Mortage2'][selectedYear] !== 'undefined' && typeof mortgage2Data['Mortage2'][selectedYear]['Months Total Balance'] !== 'undefined' && mortgage2Data['Mortage2'][selectedYear]['Months Total Balance'] !== '') {
                detailedInfo.mortgageBalanceInYearValue = mortgage2Data['Mortage2'][selectedYear]['Months Total Balance'] / 12;
              }
              else {
                detailedInfo.mortgageBalanceInYearValue = 0;
              }

              if (typeof mortgage2Data !== 'undefined' && mortgage2Data !== '' && typeof mortgage2Data['Annual Interest Rate'] !== 'undefined' && mortgage2Data['Annual Interest Rate'] !== '') {
                detailedInfo.mortgageInterestRate = mortgage2Data['Annual Interest Rate'];
              }

              if (typeof mortgage2Data !== 'undefined' && mortgage2Data !== '' && typeof mortgage2Data['Summation Data'][selectedYear] !== 'undefined' && typeof mortgage2Data['Summation Data'][selectedYear]['LaggingInterest'] !== 'undefined' && mortgage2Data['Summation Data'][selectedYear]['LaggingInterest'] !== '') {
                mortgageInterestValue = mortgage2Data['Summation Data'][selectedYear]['LaggingInterest'];
              }
              if (typeof moduleServices.module14Data.purchasingYear !== 'undefined' && selectedYear === moduleServices.module14Data.purchasingYear && typeof REIData !== 'undefined' && REIData !== '' && typeof REIData['Closing Cost'] !== 'undefined' && REIData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] !== '') {
                REIInterestValue = REIData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'];
              } else {
                REIInterestValue = 0;
              }

              detailedInfo.mortgageInterestExpenseValue = mortgageInterestValue + REIInterestValue;
              detailedInfo.mortgageInterestExpenseCondtion = 0;
              if (detailedInfo.mortgageInterestExpenseValue !== 0) {
                detailedInfo.mortgageInterestExpenseCondtion = 1;
              }

              //Loan Points Values
              // if( typeof moduleServices.module14Data !== 'undefined' && moduleServices.module14Data.purchasingYear !== '' && selectedYear === parseFloat(moduleServices.module14Data.purchasingYear-1) && moduleServices.module14Data.mortage !== '' ){
              //     detailedInfo.reiPropertyMortgage = moduleServices.module14Data.mortage;
              // }
              // else{
              //     detailedInfo.reiPropertyMortgage = 0;
              // }
              // if( typeof moduleServices.module14Data !== 'undefined' && moduleServices.module14Data.purchasingYear !== '' && selectedYear === parseFloat(moduleServices.module14Data.purchasingYear-1) && moduleServices.module14Data.pointsForTheMortgage !== '' ){
              //     detailedInfo.reiPropertyPoints = moduleServices.module14Data.pointsForTheMortgage;
              // }
              // else{
              //     detailedInfo.reiPropertyPoints = 0;
              // }
              // if( typeof moduleServices.module14Data !== 'undefined' && moduleServices.module14Data.purchasingYear !== '' && selectedYear === parseFloat(moduleServices.module14Data.purchasingYear-1) && typeof REIData !== 'undefined' && REIData !== '' && typeof REIData['Closing Cost'] !== 'undefined' && typeof REIData['Closing Cost']['Loan Acquisition Points'] !== 'undefined' && typeof REIData['Closing Cost']['Origination Points'] !== 'undefined' ){
              //     detailedInfo.pointsPaidForrei = REIData['Closing Cost']['Loan Acquisition Points'] + REIData['Closing Cost']['Origination Points'];
              // }
              // else{
              //     detailedInfo.pointsPaidForrei = 0;
              // }
              // //Origination Fees
              // if( typeof moduleServices.module14Data !== 'undefined' && moduleServices.module14Data.purchasingYear !== '' && selectedYear === parseFloat(moduleServices.module14Data.purchasingYear-1) && typeof REIData !== 'undefined' && REIData !== '' && typeof REIData['Closing Cost'] !== 'undefined' && REIData['Closing Cost']['Origination Points'] !== '' ){
              //     detailedInfo.originationPoints = REIData['Closing Cost']['Origination Points'];
              // }
              // else{
              //     detailedInfo.originationPoints = 0;
              // }

              // detailedInfo.pointsPaidForreiCondition = 0;
              // if( detailedInfo.pointsPaidForrei !== 0 ){
              //     detailedInfo.pointsPaidForreiCondition = 1;
              // }
              detailedInfo.reiPropertyMortgagePopCondition = 0;
              let totalMortageInterestLoanPointsVal = 0;
              if (typeof moduleServices.module14Data !== 'undefined'
                && moduleServices.module14Data.purchasingYear !== ''
                && selectedYear === moduleServices.module14Data.purchasingYear) {
                detailedInfo.reiPropertyMortgagePopCondition = 1;
                detailedInfo.reiPropertyMortgagePop = moduleServices.module14Data.mortage;
                detailedInfo.reiLoanPointsPop = moduleServices.module14Data.pointsForTheMortgage;
                detailedInfo.reiOriginationFeesPop = moduleServices.module14Data.originationFees;
                if (typeof REIData !== 'undefined' && REIData !== '' && typeof REIData['Closing Cost'] !== 'undefined') {
                  if (typeof REIData['Closing Cost']['Loan Acquisition Points'] !== 'undefined') {
                    totalMortageInterestLoanPointsVal += REIData['Closing Cost']['Loan Acquisition Points'];
                  }
                  if (typeof REIData['Closing Cost']['Origination Points'] !== 'undefined') {
                    totalMortageInterestLoanPointsVal += REIData['Closing Cost']['Origination Points'];
                  }
                }
              }
              detailedInfo.loanPointsAndOrgFeePop = totalMortageInterestLoanPointsVal;


              //Depreciation Values
              if (typeof moduleServices.module14Data !== 'undefined'
                && moduleServices.module14Data.purchasingYear !== ''
                && selectedYear >= moduleServices.module14Data.purchasingYear
                && typeof REIData !== 'undefined' && REIData !== ''
                && typeof REIData['Real Estate Asset Value'] !== 'undefined'
                && REIData['Real Estate Asset Value'] !== '') {
                detailedInfo.reiPropertyValue = REIData['Real Estate Asset Value'];
              }
              else {
                detailedInfo.reiPropertyValue = 0;
              }
              if (typeof moduleServices.module14Data !== 'undefined'
                && moduleServices.module14Data.purchasingYear !== ''
                && selectedYear >= moduleServices.module14Data.purchasingYear) {
                detailedInfo.usefulValue = 27.5;
              }
              else {
                detailedInfo.usefulValue = 0;
              }
              detailedInfo.annualDepreciationDeductionCondition = 0;
              if (detailedInfo.usefulValue > 0) {
                detailedInfo.annualDepreciationDeductionValue = detailedInfo.reiPropertyValue / detailedInfo.usefulValue;
              }
              else {
                detailedInfo.annualDepreciationDeductionValue = 0;
              }

              if (detailedInfo.usefulValue > 0) {
                detailedInfo.annualDepreciationDeductionCondition = 1;
              }
              //Deduction calculation values
              if (typeof incomeStatementData !== 'undefined' && typeof incomeStatementData[selectedYear] !== 'undefined'
                && typeof incomeStatementData[selectedYear]['Gross Income']['Rental Income'] !== 'undefined'
                && incomeStatementData[selectedYear]['Gross Income']['Rental Income'] !== '') {
                detailedInfo.maximumDeductionValue = incomeStatementData[selectedYear]['Gross Income']['Rental Income'];
              }
              else {
                detailedInfo.maximumDeductionValue = 0;
              }
              detailedInfo.annualDepreciationDeductionTotalValue = detailedInfo.annualDepreciationDeductionValue + detailedInfo.pointsPaidForrei + detailedInfo.mortgageInterestExpenseValue;

              detailedInfo.passiveLossCarriedValue = detailedInfo.annualDepreciationDeductionTotalValue - detailedInfo.maximumDeductionValue;
              detailedInfo.passiveLossCarriedCondition = 0;
              if (detailedInfo.passiveLossCarriedValue !== 0) {
                detailedInfo.passiveLossCarriedCondition = 1;
              }

              if (typeof taxStatementData !== 'undefined' && typeof taxStatementData[selectedYear] !== 'undefined'
                && typeof taxStatementData[selectedYear]['REI Property'] !== 'undefined'
                && typeof taxStatementData[selectedYear]['REI Property']['Mortgage Interest, Loan Points & Origination Fees, & Depreciation'] !== 'undefined') {
                detailedInfo.MortgageInterestLoanPointsPop = taxStatementData[selectedYear]['REI Property']['Mortgage Interest, Loan Points & Origination Fees, & Depreciation'];
              } else {
                detailedInfo.MortgageInterestLoanPointsPop = 0;
              }

              // setOpenGrossIncomePopup( true );

              setOpenDialog(true);
              setOpenDialogName('mortgageDeductionsDetailPopup');
              break;
            default:
              break;
          }
          break;

        /* Adjustments */

        case 'Adjustments':
          switch (selectedColumn) {
            case '401k Contributions':
              detailedInfo.databaseData = moduleServices.databaseCompleteData();
              let investmentsData = moduleServices.investmentsCompleteData();
              let databaseData = moduleServices.databaseCompleteData();
              let personalContribution401k = 0;
              let personalContributionSephIra = 0;
              let personalContributionPension = 0;
              let invData = moduleServices.taxStatementCompleteData();

              //For 401k Base Income
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts 401k'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts 401k'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts 401k'][selectedYear]['Base Income'] !== 'undefined') {
                detailedInfo.baseIncome401k = investmentsData['Retirement Accounts 401k'][selectedYear]['Base Income'];
              } else {
                detailedInfo.baseIncome401k = 0;
              }
              //base Income Percentage
              if (typeof moduleServices.module4Data.contribute_401k !== 'undefined' && moduleServices.module4Data.contribute_401k === 'Yes'
                && typeof moduleServices.module4Data.start_year !== 'undefined' && selectedYear >= moduleServices.module4Data.start_year
                && typeof moduleServices.module4Data.end_year !== 'undefined' && selectedYear <= moduleServices.module4Data.end_year
                && typeof moduleServices.module4Data.base_income !== 'undefined' && moduleServices.module4Data.base_income !== '') {
                personalContribution401k = moduleServices.module4Data.base_income;
              } else {
                if (typeof moduleServices.module5Data.contribute_401k !== 'undefined' && moduleServices.module5Data.contribute_401k === 'Yes'
                  && typeof moduleServices.module5Data.start_year !== 'undefined' && selectedYear >= moduleServices.module5Data.start_year
                  && typeof moduleServices.module5Data.end_year !== 'undefined' && selectedYear <= moduleServices.module5Data.end_year
                  && typeof moduleServices.module5Data.base_income !== 'undefined' && moduleServices.module5Data.base_income !== '') {
                  personalContribution401k = moduleServices.module5Data.base_income;
                } else {
                  if (typeof moduleServices.module8Data.contribute_401k !== 'undefined' && moduleServices.module8Data.contribute_401k === 'Yes'
                    && typeof moduleServices.module8Data.start_year !== 'undefined' && selectedYear >= moduleServices.module8Data.start_year
                    && typeof moduleServices.module8Data.endYear !== 'undefined' && selectedYear <= moduleServices.module8Data.endYear
                    && typeof moduleServices.module8Data.base_income !== 'undefined' && moduleServices.module8Data.base_income !== '') {
                    personalContribution401k = moduleServices.module8Data.base_income;
                  } else {
                    if (typeof moduleServices.module17Data.contribute_401k !== 'undefined' && moduleServices.module17Data.contribute_401k === 'Yes'
                      && typeof moduleServices.module17Data.start_year !== 'undefined' && selectedYear >= moduleServices.module17Data.start_year
                      && typeof moduleServices.module17Data.endYear !== 'undefined' && selectedYear <= moduleServices.module17Data.endYear
                      && typeof moduleServices.module17Data.base_income !== 'undefined' && moduleServices.module17Data.base_income !== '') {
                      personalContribution401k = moduleServices.module17Data.base_income;
                    } else {
                      personalContribution401k = 0;
                    }
                  }
                }
              }
              detailedInfo.personalContribution401k = personalContribution401k;
              //Personal Contribution value
              detailedInfo.personalContributionValue401k = (detailedInfo.baseIncome401k / 100) * personalContribution401k;
              //Database Value
              if (typeof databaseData !== 'undefined' && databaseData !== '' && typeof databaseData['Data']['401k Limit'] !== 'undefined') {
                detailedInfo.database401k = databaseData['Data']['401k Limit'];
              } else {
                detailedInfo.database401k = 0;
              }
              //Final 401k Value
              detailedInfo.final401kValueCondition = 0;
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts 401k'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts 401k'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts 401k'][selectedYear]['Personal Contribution'] !== 'undefined' && investmentsData['Retirement Accounts 401k'][selectedYear]['Personal Contribution'] !== 0) {
                detailedInfo.final401kValue = investmentsData['Retirement Accounts 401k'][selectedYear]['Personal Contribution'];
                detailedInfo.final401kValueCondition = 1;
              } else {
                detailedInfo.final401kValue = 0;
              }

              //Seph IRA values
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Base Income'] !== 'undefined') {
                detailedInfo.baseIncomeSephIra = investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Base Income'];
              } else {
                detailedInfo.baseIncomeSephIra = 0;
              }
              //base Income Percentage
              if (typeof moduleServices.module4Data.ira_contribute !== 'undefined' && moduleServices.module4Data.ira_contribute === 'Yes'
                && typeof moduleServices.module4Data.start_year !== 'undefined' && selectedYear >= moduleServices.module4Data.start_year
                && typeof moduleServices.module4Data.end_year !== 'undefined' && selectedYear <= moduleServices.module4Data.end_year
                && typeof moduleServices.module4Data.iraPersonalContribution !== 'undefined' && moduleServices.module4Data.iraPersonalContribution !== '') {
                personalContributionSephIra = moduleServices.module4Data.iraPersonalContribution;
              } else {
                if (typeof moduleServices.module5Data.ira_contribute !== 'undefined' && moduleServices.module5Data.ira_contribute === 'Yes'
                  && typeof moduleServices.module5Data.start_year !== 'undefined' && selectedYear >= moduleServices.module5Data.start_year
                  && typeof moduleServices.module5Data.end_year !== 'undefined' && selectedYear <= moduleServices.module5Data.end_year
                  && typeof moduleServices.module5Data.iraPersonalContribution !== 'undefined' && moduleServices.module5Data.iraPersonalContribution !== '') {
                  personalContributionSephIra = moduleServices.module5Data.iraPersonalContribution;
                } else {
                  if (typeof moduleServices.module8Data.ira_contribute !== 'undefined' && moduleServices.module8Data.ira_contribute === 'Yes'
                    && typeof moduleServices.module8Data.start_year !== 'undefined' && selectedYear >= moduleServices.module8Data.start_year
                    && typeof moduleServices.module8Data.endYear !== 'undefined' && selectedYear <= moduleServices.module8Data.endYear
                    && typeof moduleServices.module8Data.iraPersonalContribution !== 'undefined' && moduleServices.module8Data.iraPersonalContribution !== '') {
                    personalContributionSephIra = moduleServices.module8Data.iraPersonalContribution;
                  } else {
                    if (typeof moduleServices.module17Data.ira_contribute !== 'undefined' && moduleServices.module17Data.ira_contribute === 'Yes'
                      && typeof moduleServices.module17Data.start_year !== 'undefined' && selectedYear >= moduleServices.module17Data.start_year
                      && typeof moduleServices.module17Data.endYear !== 'undefined' && selectedYear <= moduleServices.module17Data.endYear
                      && typeof moduleServices.module17Data.iraPersonalContribution !== 'undefined' && moduleServices.module17Data.iraPersonalContribution !== '') {
                      personalContributionSephIra = moduleServices.module17Data.iraPersonalContribution;
                    } else {
                      personalContributionSephIra = 0;
                    }
                  }
                }
              }
              detailedInfo.personalContributionSephIra = personalContributionSephIra;
              //Personal Contribution value
              detailedInfo.personalContributionValueSephIra = (detailedInfo.baseIncomeSephIra / 100) * personalContributionSephIra;
              //Seph IRA Compared Values
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Max Income Based Limit'] !== 'undefined') {
                detailedInfo.firstSephIraValue = investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Max Income Based Limit'];
              } else {
                detailedInfo.firstSephIraValue = 0;
              }
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Max Dollar Based Limit'] !== 'undefined') {
                detailedInfo.secondSephIraValue = investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Max Dollar Based Limit'];
              } else {
                detailedInfo.secondSephIraValue = 0;
              }
              //Max Contribution by Law
              if (detailedInfo.firstSephIraValue < detailedInfo.secondSephIraValue) {
                detailedInfo.maxContributionByLawSephIra = detailedInfo.firstSephIraValue;
              } else {
                detailedInfo.maxContributionByLawSephIra = detailedInfo.secondSephIraValue;
              }
              //Final 401k Value
              detailedInfo.finalSephIraValueCondition = 0;
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Personal Contribution Final'] !== 'undefined' && investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Personal Contribution Final'] !== 0) {
                detailedInfo.finalSephIraValue = investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Personal Contribution Final'];
                detailedInfo.finalSephIraValueCondition = 1;
              } else {
                detailedInfo.finalSephIraValue = 0;
              }

              //Pension Values
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Pension'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Pension'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Pension'][selectedYear]['Base Income'] !== 'undefined') {
                detailedInfo.baseIncomePension = investmentsData['Retirement Accounts 401k'][selectedYear]['Base Income'];
              } else {
                detailedInfo.baseIncomePension = 0;
              }
              //base Income Percentage
              if (typeof moduleServices.module4Data.pension_contribute !== 'undefined' && moduleServices.module4Data.pension_contribute === 'Yes'
                && typeof moduleServices.module4Data.start_year !== 'undefined' && selectedYear >= moduleServices.module4Data.start_year
                && typeof moduleServices.module4Data.end_year !== 'undefined' && selectedYear <= moduleServices.module4Data.end_year
                && typeof moduleServices.module4Data.pensionPersonalContribution !== 'undefined' && moduleServices.module4Data.pensionPersonalContribution !== '') {
                personalContributionPension = moduleServices.module4Data.pensionPersonalContribution;
              } else {
                if (typeof moduleServices.module5Data.pension_contribute !== 'undefined' && moduleServices.module5Data.pension_contribute === 'Yes'
                  && typeof moduleServices.module5Data.start_year !== 'undefined' && selectedYear >= moduleServices.module5Data.start_year
                  && typeof moduleServices.module5Data.end_year !== 'undefined' && selectedYear <= moduleServices.module5Data.end_year
                  && typeof moduleServices.module5Data.pensionPersonalContribution !== 'undefined' && moduleServices.module5Data.pensionPersonalContribution !== '') {
                  personalContributionPension = moduleServices.module5Data.pensionPersonalContribution;
                } else {
                  if (typeof moduleServices.module8Data.pension_contribute !== 'undefined' && moduleServices.module8Data.pension_contribute === 'Yes'
                    && typeof moduleServices.module8Data.start_year !== 'undefined' && selectedYear >= moduleServices.module8Data.start_year
                    && typeof moduleServices.module8Data.endYear !== 'undefined' && selectedYear <= moduleServices.module8Data.endYear
                    && typeof moduleServices.module8Data.pensionPersonalContribution !== 'undefined' && moduleServices.module8Data.pensionPersonalContribution !== '') {
                    personalContributionPension = moduleServices.module8Data.pensionPersonalContribution;
                  } else {
                    if (typeof moduleServices.module17Data.pension_contribute !== 'undefined' && moduleServices.module17Data.pension_contribute === 'Yes'
                      && typeof moduleServices.module17Data.start_year !== 'undefined' && selectedYear >= moduleServices.module17Data.start_year
                      && typeof moduleServices.module17Data.endYear !== 'undefined' && selectedYear <= moduleServices.module17Data.endYear
                      && typeof moduleServices.module17Data.pensionPersonalContribution !== 'undefined' && moduleServices.module17Data.pensionPersonalContribution !== '') {
                      personalContributionPension = moduleServices.module17Data.pensionPersonalContribution;
                    } else {
                      personalContributionPension = 0;
                    }
                  }
                }
              }
              detailedInfo.personalContributionPension = personalContributionPension;
              //Personal Contribution value
              detailedInfo.personalContributionValuePension = (detailedInfo.baseIncomePension / 100) * personalContributionPension;
              //Database Value
              if (typeof databaseData !== 'undefined' && databaseData !== '' && typeof databaseData['Data']['Pension 2018 Limits'] !== 'undefined') {
                detailedInfo.databasePension = databaseData['Data']['Pension 2018 Limits'];
              } else {
                detailedInfo.databasePension = 0;
              }
              //Employer Contribution both values
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Pension'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Pension'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Pension'][selectedYear]['Employer Contribution'] !== 'undefined') {
                detailedInfo.employerContributionPension = investmentsData['Retirement Accounts Pension'][selectedYear]['Employer Contribution'];
              } else {
                detailedInfo.employerContributionPension = 0;
              }
              //Final Pension Value
              detailedInfo.finalPensionValueCondition = 0;
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Pension'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Pension'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Pension'][selectedYear]['Personal Contribution'] !== 'undefined' && investmentsData['Retirement Accounts Pension'][selectedYear]['Personal Contribution'] !== 0) {
                detailedInfo.finalPensionValue = investmentsData['Retirement Accounts Pension'][selectedYear]['Personal Contribution'];
                detailedInfo.finalPensionValueCondition = 1;
              } else {
                detailedInfo.finalPensionValue = 0;
              }

              //Total 401k Adjustments value
              detailedInfo.totalAdjustmentsValue = invData[selectedYear]['Adjustments']['401k Contributions'];

              if (typeof invData !== 'undefined' && invData !== '' && typeof invData[selectedYear] !== 'undefined' && invData[selectedYear]['Adjustments']['401k Contributions'] !== '') {
                detailedInfo.totalAdjustmentsValue = invData[selectedYear]['Adjustments']['401k Contributions'];
              } else {
                detailedInfo.totalAdjustmentsValue = 0;
              }


              // setOpenDetailPopup401kPopup(true);
              setOpenDialog(true);
              setOpenDialogName('DetailPopup401k');
              break;
            case 'Student Loan Interest':
              let fedUnSubHigherEdLoanData = moduleServices.fedUnSubHigherEdLoanCompleteData();
              let privateHigherEdLoanData = moduleServices.privateHigherEdLoanCompleteData();

              let fedUnSubMoreHigherEdLoanData = moduleServices.fedUnSubMoreHigherEdLoanCompleteData();
              let privateMoreHigherEdLoanData = moduleServices.privateMoreHigherEdLoanCompleteData();

              let fedUnSubNewHigherEdLoanData = moduleServices.fedUnSubNewHigherEdLoanCompleteData();
              let privateNewHigherEdLoanData = moduleServices.privateNewHigherEdLoanCompleteData();

              //let existingFedSubStudentLoanData = moduleServices.fedSubExistingLoanCompleteData();
              let existingFedUnsubStudentLoanData = moduleServices.fedUnSubExistingLoanCompleteData();
              let existingPrivateStudentLoanData = moduleServices.privateExistingLoanCompleteData();


              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '') {

                let studentLoanTaxData = moduleServices.studentLoanTaxCompleteData();
                let studentLoanTaxCompleteData = studentLoanTaxData['Complete Data'][selectedYear];

                detailedInfo.Status = '';
                if (typeof moduleServices.module10Data !== 'undefined' && moduleServices.module10Data.marriageYear !== '' && selectedYear >= moduleServices.module10Data.marriageYear) {
                  detailedInfo.Status = 'Married';
                  detailedInfo.CompleteData = studentLoanTaxCompleteData['Married'];
                  detailedInfo.DefaultData1 = studentLoanTaxData['Default Data']['Married1'];
                  detailedInfo.DefaultData2 = studentLoanTaxData['Default Data']['Married2'];

                } else {
                  detailedInfo.Status = 'Single';
                  detailedInfo.CompleteData = studentLoanTaxCompleteData['Single'];
                  detailedInfo.DefaultData1 = studentLoanTaxData['Default Data']['Single1'];
                  detailedInfo.DefaultData2 = studentLoanTaxData['Default Data']['Single2'];
                }

                detailedInfo.condition = 0;
                if (detailedInfo.CompleteData['Iteration Data'][0]['Interest'] <= 0) {
                  detailedInfo.condition = 1;
                }

                detailedInfo.row1 = 0;
                detailedInfo.row2 = 0;
                detailedInfo.row3 = 0;

                if (detailedInfo['CompleteData']['Income'] >= detailedInfo['CompleteData']['Iteration Data'][0]['Range'] &&
                  detailedInfo['CompleteData']['Income'] <= detailedInfo['CompleteData']['Iteration Data'][0]['Range2']) {
                  detailedInfo.row1 = 1;
                } else if (detailedInfo['CompleteData']['Income'] > detailedInfo['CompleteData']['Iteration Data'][1]['Range'] &&
                  detailedInfo['CompleteData']['Income'] <= detailedInfo['CompleteData']['Iteration Data'][1]['Range2']) {
                  detailedInfo.row2 = 1;
                } else if (detailedInfo['CompleteData']['Income'] > detailedInfo['CompleteData']['Iteration Data'][1]['Range2']) {
                  detailedInfo.row3 = 1;
                }

                if (typeof incomeStatementData[selectedYear] !== 'undefined' && incomeStatementData[selectedYear]['InterestExpenses']['Student Loans'] !== '') {
                  detailedInfo.studentInterestExpenseValue = -incomeStatementData[selectedYear]['InterestExpenses']['Student Loans'];
                } else {
                  detailedInfo.studentInterestExpenseValue = 0;
                }

                if (typeof fedUnSubHigherEdLoanData !== 'undefined' && fedUnSubHigherEdLoanData !== '' && typeof fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'] !== 'undefined') {
                  detailedInfo.fedUnubTaxDeductionVal = fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'];
                }
                if (typeof privateHigherEdLoanData !== 'undefined' && privateHigherEdLoanData !== '' && typeof privateHigherEdLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'] !== 'undefined') {
                  detailedInfo.privateTaxDeductionVal = privateHigherEdLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'];
                }
                if (typeof fedUnSubMoreHigherEdLoanData !== 'undefined' && fedUnSubMoreHigherEdLoanData !== '' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'] !== 'undefined') {
                  detailedInfo.fedUnsubMoreTaxDeductionVal = fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'];
                }
                if (typeof privateMoreHigherEdLoanData !== 'undefined' && privateMoreHigherEdLoanData !== '' && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'] !== 'undefined') {
                  detailedInfo.privateMoreTaxDeductionVal = privateMoreHigherEdLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'];
                }
                if (typeof fedUnSubNewHigherEdLoanData !== 'undefined' && fedUnSubNewHigherEdLoanData !== '' && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'] !== 'undefined') {
                  detailedInfo.fedUnsubNewTaxDeductionVal = fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'];
                }
                if (typeof privateNewHigherEdLoanData !== 'undefined' && privateNewHigherEdLoanData !== '' && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'] !== 'undefined') {
                  detailedInfo.privateNewTaxDeductionVal = privateNewHigherEdLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'];
                }
                if (typeof existingFedUnsubStudentLoanData !== 'undefined' && existingFedUnsubStudentLoanData !== '' && typeof existingFedUnsubStudentLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'] !== 'undefined') {
                  detailedInfo.existingFedUnsubNewTaxDeductionVal = existingFedUnsubStudentLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'];
                }
                if (typeof existingPrivateStudentLoanData !== 'undefined' && existingPrivateStudentLoanData !== '' && typeof existingPrivateStudentLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'] !== 'undefined') {
                  detailedInfo.existingPrivateNewTaxDeductionVal = existingPrivateStudentLoanData['Summation Data'][selectedYear]['Capitalized Interest Tax Deduction'];
                }

                detailedInfo.totalCapitilizedInterestPayback =
                  detailedInfo.fedUnubTaxDeductionVal +
                  detailedInfo.privateTaxDeductionVal +
                  detailedInfo.fedUnsubMoreTaxDeductionVal +
                  detailedInfo.privateMoreTaxDeductionVal +
                  detailedInfo.fedUnsubNewTaxDeductionVal +
                  detailedInfo.privateNewTaxDeductionVal +
                  detailedInfo.existingFedUnsubNewTaxDeductionVal +
                  detailedInfo.existingPrivateNewTaxDeductionVal;


                // setOpeShowDetailsPopupFullPopup( true );
                setOpenDialog(true);
                setOpenDialogName('showDetailPopupFull');

              }
              break;
            case 'Self-Employment Tax':
              // let incomeStatementCompleteData = moduleServices.incomeStatementCompleteData();
              let taxStatementData = moduleServices.taxStatementCompleteData();
              // let databaseData = moduleServices.databaseCompleteData();
              let selfEmployementTaxes = 0;
              let selfEmployementTaxes1 = 0;
              let selfEmployementTaxes2 = 0;

              if (typeof moduleServices.module4Data.selfEmployment !== 'undefined' && typeof moduleServices.module4Data.startYear !== 'undefined' && typeof moduleServices.module4Data.incomeEndYear !== 'undefined' && selectedYear >= moduleServices.module4Data.startYear && selectedYear <= moduleServices.module4Data.incomeEndYear && moduleServices.module4Data.selfEmployment === 'Yes') {
                detailedInfo.selfEmployement = 'Yes';
              }
              else {
                if (typeof moduleServices.module5Data.selfEmployment !== 'undefined' && typeof moduleServices.module5Data.startYear !== 'undefined' && typeof moduleServices.module5Data.incomeEndYear !== 'undefined' && selectedYear >= moduleServices.module5Data.startYear && selectedYear <= moduleServices.module5Data.incomeEndYear && moduleServices.module5Data.selfEmployment === 'Yes') {
                  detailedInfo.selfEmployement = 'Yes';
                }
                else {
                  if (typeof moduleServices.module8Data.selfEmployment !== 'undefined' && typeof moduleServices.module8Data.startYear !== 'undefined' && typeof moduleServices.module8Data.endYear !== 'undefined' && selectedYear >= moduleServices.module8Data.startYear && selectedYear <= moduleServices.module8Data.endYear && moduleServices.module8Data.selfEmployment === 'Yes') {
                    detailedInfo.selfEmployement = 'Yes';
                  }
                  else {
                    if (typeof moduleServices.module17Data.selfEmployment !== 'undefined' && typeof moduleServices.module17Data.startYear !== 'undefined' && typeof moduleServices.module17Data.endYear !== 'undefined' && selectedYear >= moduleServices.module17Data.startYear && selectedYear <= moduleServices.module17Data.endYear && moduleServices.module17Data.selfEmployment === 'Yes') {
                      detailedInfo.selfEmployement = 'Yes';
                    }
                    else {
                      detailedInfo.selfEmployement = 'No';
                    }
                  }
                }
              }
              if (detailedInfo.selfEmployement === 'Yes') {
                if (typeof taxStatementData !== 'undefined' && typeof taxStatementData[selectedYear] !== 'undefined' && typeof taxStatementData[selectedYear]['taxes']['Medicare Tax (x Total Gross Income)'] !== 'undefined' && typeof taxStatementData[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== 'undefined') {
                  selfEmployementTaxes1 = (taxStatementData[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'] / 100) * taxStatementData[selectedYear]['taxes']['Medicare Tax (x Total Gross Income)'];
                }
                if (typeof databaseData !== 'undefined' && typeof databaseData['Social Security Income Tax Limit'][selectedYear]['Limit'] !== 'undefined' && typeof taxStatementData !== 'undefined' && typeof taxStatementData[selectedYear] !== 'undefined' && typeof taxStatementData[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== 'undefined' && taxStatementData[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'] > databaseData['Social Security Income Tax Limit'][selectedYear]['Limit'] && typeof taxStatementData[selectedYear]['taxes']['Social Security Tax (x Total Gross Income with limits)'] !== 'undefined') {
                  selfEmployementTaxes2 = (databaseData['Social Security Income Tax Limit'][selectedYear]['Limit'] / 100) * taxStatementData[selectedYear]['taxes']['Social Security Tax (x Total Gross Income with limits)'];
                }
                else {
                  selfEmployementTaxes2 = (taxStatementData[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'] / 100) * taxStatementData[selectedYear]['taxes']['Social Security Tax (x Total Gross Income with limits)'];
                }
                selfEmployementTaxes = selfEmployementTaxes1 + selfEmployementTaxes2;
              }
              detailedInfo.selfEmployementTaxesValue = selfEmployementTaxes;

              if (detailedInfo.selfEmployement === 'Yes') {
                detailedInfo.multiplier = 50;
              }
              else {
                detailedInfo.multiplier = 0;
              }
              detailedInfo.selfEmployementFinalCondition = 0;

              if (typeof taxStatementData !== 'undefined' && typeof taxStatementData[selectedYear] !== 'undefined' && typeof taxStatementData[selectedYear]['Adjustments']['Self-Employment Tax'] !== 'undefined' && taxStatementData[selectedYear]['Adjustments']['Self-Employment Tax'] !== 0) {
                detailedInfo.selfEmployementFinalValue = taxStatementData[selectedYear]['Adjustments']['Self-Employment Tax'];
                detailedInfo.selfEmployementFinalCondition = 1;
              }
              else {
                detailedInfo.selfEmployementFinalValue = 0;
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('self-employement-tax');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            default:
              break;
          }
          break;
        /* End of Adjustments */
        /* Adjusted Gross Income */

        case 'Adjusted Gross Income':
          let taxStatementData = moduleServices.taxStatementCompleteData();
          detailedInfo.condition = 0;
          if (taxStatementData[selectedYear]['Adjusted Gross Income'] !== 0) {
            detailedInfo.adjustmentsGrossIncomePop = taxStatementData[selectedYear]['Adjusted Gross Income'];
            detailedInfo.condition = 1;
          } else {
            detailedInfo.adjustmentsGrossIncomePop = 0;
          }
          if (typeof taxStatementData !== 'undefined' && typeof taxStatementData[selectedYear] !== 'undefined' &&
            typeof taxStatementData[selectedYear]['Gross Income'] !== 'undefined'
            && typeof taxStatementData[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== 'undefined') {
            detailedInfo.totalGrossIncomePop = taxStatementData[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'];
          } else {
            detailedInfo.totalGrossIncomePop = 0;
          }

          if (typeof taxStatementData !== 'undefined' && typeof taxStatementData[selectedYear] !== 'undefined' && typeof taxStatementData[selectedYear]['REI Property Taxable Income (Passive Losses Taken)'] !== 'undefined') {
            detailedInfo.reiTaxableIncomePop = taxStatementData[selectedYear]['REI Property Taxable Income (Passive Losses Taken)'];
          } else {
            detailedInfo.reiTaxableIncomePop = 0;
          }

          if (typeof taxStatementData !== 'undefined' && typeof taxStatementData[selectedYear] !== 'undefined' && typeof taxStatementData[selectedYear]['Total Adjustments'] !== 'undefined') {
            detailedInfo.totalAdjustmentsPop = taxStatementData[selectedYear]['Total Adjustments'];
          } else {
            detailedInfo.totalAdjustmentsPop = 0;
          }

          // setOpenGrossIncomePopup( true );
          setOpenGrossIncomeModel('adjusted-gross-income');
          setOpenDialog(true);
          setOpenDialogName('showDetailPopup');
          break;
        /* End of Adjusted Gross Income */
        /* Deductions & Exemptions */

        case 'Deductions':
          switch (selectedColumn) {
            case 'Home Mortgage Interest, Loan Points & Origination Fees, & PMI':
              detailedInfo.condition = 0;
              detailedInfo.condition2 = 0;
              //if(incomeStatementData[selectedYear]['Itemized Deductions']['Mortgages Interest & Private Mortgage Insurance (PMI)'] > 0) {
              let mortgage1Data = moduleServices.mortgage1CompleteData();
              let mortgage2Data = moduleServices.mortgage2CompleteData();
              let BuyingAHomeData = moduleServices.buyingAHomeCompleteData();
              let REIData = moduleServices.invPropertyRoiCompleteData();
              let existingHomeData = moduleServices.existingHomeCompleteData();
              let existingHomeSaleYearValue = 0;
              let existingHomeSaleInterestRate = 0;
              // let existingHomeMortgageData = moduleServices.existingHomeMortgageCompleteData();
              let databaseCompleteData = moduleServices.databaseCompleteData();
              //Existing Home Mortgage Balance Section              
              if (typeof moduleServices.module18Data.existingHomeSaleYear !== 'undefined' &&
                moduleServices.module18Data.existingHomeSaleYear !== '' &&
                selectedYear >= moduleServices.module18Data.existingHomeSaleYear) {
                existingHomeSaleYearValue = 0;
                existingHomeSaleInterestRate = 0;
              }
              // else {
              //   if (typeof existingHomeSaleYearValue !== 'undefined' && existingHomeSaleYearValue !== '' && typeof existingHomeSaleYearValue['Existing Home Data'][selectedYear] !== 'undefined' && existingHomeSaleYearValue['Existing Home Data'][selectedYear] !== '' && typeof existingHomeSaleYearValue['Existing Home Data'][selectedYear]['Months Total Balance'] !== 'undefined' && existingHomeSaleYearValue['Existing Home Data'][selectedYear]['Months Total Balance'] !== '') {
              //     existingHomeSaleYearValue = existingHomeSaleYearValue['Existing Home Data'][selectedYear]['Months Total Balance'] / 12;
              //   } else {
              //     existingHomeSaleYearValue = 0;
              //   }
              //   if (typeof existingHomeSaleYearValue !== 'undefined' && existingHomeSaleYearValue !== '' && typeof existingHomeSaleYearValue['Annual Interest Rate'] !== 'undefined' && existingHomeSaleYearValue['Annual Interest Rate'] !== '') {
              //     existingHomeSaleInterestRate = existingHomeSaleYearValue['Annual Interest Rate'];
              //   } else {
              //     existingHomeSaleInterestRate = 0;
              //   }
              // }
              detailedInfo.existingHomeInterestExpenseCondition = 0;
              // if (typeof existingHomeSaleYearValue !== 'undefined' && existingHomeSaleYearValue !== '' && typeof existingHomeSaleYearValue['Summation Data'][selectedYear] !== 'undefined' && existingHomeSaleYearValue['Summation Data'][selectedYear] !== '' && typeof existingHomeSaleYearValue['Summation Data'][selectedYear]['LaggingInterest'] !== 'undefined' && existingHomeSaleYearValue['Summation Data'][selectedYear]['LaggingInterest'] !== '') {
              //   detailedInfo.existingHomeInterestExpense = existingHomeSaleYearValue['Summation Data'][selectedYear]['LaggingInterest'];
              //   detailedInfo.existingHomeInterestExpenseCondition = 1;
              // } else {
              //   detailedInfo.existingHomeInterestExpense = 0;
              // }
              detailedInfo.existingHomeMortgageBalanceAvg = existingHomeSaleYearValue;
              detailedInfo.existingHomeMortgageBalanceInterestRate = existingHomeSaleInterestRate;

              //Interest rate

              //Existing Home Mortgage Balance Section End

              detailedInfo.mortgage1Interest = 0;
              let value1 = 0;
              if (typeof mortgage1Data !== 'undefined' && mortgage1Data !== '' &&
                typeof mortgage1Data['Summation Data'][selectedYear] !== 'undefined') {
                value1 = mortgage1Data['Summation Data'][selectedYear]['LaggingInterest'];
              }
              let value2 = 0
              if (selectedYear === moduleServices.module13Data.yearOfPurchase) {
                value2 = BuyingAHomeData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'];
              }
              detailedInfo.mortgage1Interest = parseFloat(value1) + parseFloat(value2);

              if (detailedInfo.mortgage1Interest !== 0) {
                detailedInfo.condition = 1;
                detailedInfo.homeMortgage = mortgage1Data['Mortage1'][selectedYear]['Months Total Balance'] / 12;
                detailedInfo.homeMortgageInterestRate = moduleServices.module13Data.rateOfTheMortage;
              }

              detailedInfo.mortgage2Interest = 0;
              let value3 = 0;
              if (typeof mortgage2Data !== 'undefined' && mortgage1Data !== '' &&
                typeof mortgage2Data['Summation Data'][selectedYear] !== 'undefined') {
                value3 = mortgage2Data['Summation Data'][selectedYear]['LaggingInterest'];
              }
              let value4 = 0
              if (selectedYear === moduleServices.module14Data.purchasingYear) {
                value4 = REIData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'];
              }
              detailedInfo.mortgage2Interest = value3 + value4;
              if (detailedInfo.mortgage2Interest !== 0) {
                detailedInfo.condition2 = 1;
                detailedInfo.REIMortgage = mortgage2Data['Mortage2'][selectedYear]['Months Total Balance'] / 12;
                detailedInfo.REIMortgageInterestRate = moduleServices.module14Data.rateOfTheMortgage;
              }

              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' && typeof incomeStatementData[selectedYear] !== 'undefined' && typeof incomeStatementData[selectedYear]['Adjusted Gross Income'] !== 'undefined') {
                detailedInfo.incomeAdjustedGrossIncome = incomeStatementData[selectedYear]['Adjusted Gross Income'];
              }

              else {
                detailedInfo.incomeAdjustedGrossIncome = 0;
              }
              if (typeof existingHomeData !== 'undefined' && existingHomeData !== '' && typeof existingHomeData['Data1'][selectedYear] !== 'undefined' && existingHomeData['Data1'][selectedYear] !== '' && typeof existingHomeData['Data1'][selectedYear]['Private Mortgage Insurance'] !== 'undefined') {
                detailedInfo.existingHomeInsuranceValue = existingHomeData['Data1'][selectedYear]['Private Mortgage Insurance'];
              } else {
                detailedInfo.existingHomeInsuranceValue = 0;
              }

              if (typeof BuyingAHomeData !== 'undefined' && BuyingAHomeData !== '' && typeof BuyingAHomeData['Data1'] !== 'undefined' && typeof BuyingAHomeData['Data1'][selectedYear] !== 'undefined' && typeof BuyingAHomeData['Data1'][selectedYear]['Private Mortgage Insurance'] !== 'undefined') {
                detailedInfo.privateMortgageInsuranceValue = BuyingAHomeData['Data1'][selectedYear]['Private Mortgage Insurance'];
              }
              else {
                detailedInfo.privateMortgageInsuranceValue = 0;
              }
              //Loan Points Values
              if (typeof moduleServices.module13Data !== 'undefined' && moduleServices.module13Data.yearOfPurchase !== '' && selectedYear === moduleServices.module13Data.yearOfPurchase && moduleServices.module13Data.Mortage !== '') {
                detailedInfo.homePropertyMortgage = moduleServices.module13Data.Mortage;
              }
              else {
                detailedInfo.homePropertyMortgage = 0;
              }
              if (typeof moduleServices.module13Data !== 'undefined' && moduleServices.module13Data.yearOfPurchase !== '' && selectedYear === moduleServices.module13Data.yearOfPurchase && moduleServices.module13Data.pointsForMortage !== '') {
                detailedInfo.homePropertyPoints = moduleServices.module13Data.pointsForMortage;
              }
              else {
                detailedInfo.homePropertyPoints = 0;
              }
              //Origination Fees
              if (typeof moduleServices.module13Data !== 'undefined' && moduleServices.module13Data.yearOfPurchase !== '' && selectedYear === moduleServices.module13Data.yearOfPurchase && moduleServices.module13Data.originationFees !== '') {
                detailedInfo.originationsPoints = moduleServices.module13Data.originationFees;
              }
              else {
                detailedInfo.originationsPoints = 0;
              }

              if (typeof moduleServices.module13Data !== 'undefined' && moduleServices.module13Data.yearOfPurchase !== '' && selectedYear === moduleServices.module13Data.yearOfPurchase && typeof BuyingAHomeData !== 'undefined' && BuyingAHomeData !== '' && typeof BuyingAHomeData['Closing Cost'] !== 'undefined' && typeof BuyingAHomeData['Closing Cost']['Loan Acquisition Points'] !== 'undefined' && typeof BuyingAHomeData['Closing Cost']['Origination Points'] !== 'undefined') {
                detailedInfo.pointsPaidForHome = BuyingAHomeData['Closing Cost']['Loan Acquisition Points'] + BuyingAHomeData['Closing Cost']['Origination Points'];
              }
              else {
                detailedInfo.pointsPaidForHome = 0;
              }
              detailedInfo.pointsPaidForHomeCondition = 0;
              if (detailedInfo.pointsPaidForHome !== 0) {
                detailedInfo.pointsPaidForHomeCondition = 1;
              }
              //Completed
              //User Status Married or Single
              if (typeof moduleServices.module10Data !== 'undefined' && moduleServices.module10Data.marriageYear !== '' && selectedYear >= moduleServices.module10Data.marriageYear) {
                detailedInfo.Status = 'Married';
                detailedInfo.incomeRanges1 = 0;
                if (typeof databaseCompleteData !== 'undefined' && typeof databaseCompleteData['Data'] !== 'undefined' && typeof databaseCompleteData['Data']['PMI Deduction Third'] !== 'undefined') {
                  detailedInfo.incomeRanges2 = databaseCompleteData['Data']['PMI Deduction Third'];
                } else {
                  detailedInfo.incomeRanges2 = 0;
                }
                detailedInfo.incomeRanges3 = detailedInfo.incomeRanges2 + 1;
                detailedInfo.incomeRanges1 = 0;
                if (typeof databaseCompleteData !== 'undefined' && typeof databaseCompleteData['Data'] !== 'undefined' && typeof databaseCompleteData['Data']['PMI Deduction Fourth'] !== 'undefined') {
                  detailedInfo.incomeRanges4 = databaseCompleteData['Data']['PMI Deduction Fourth'];
                } else {
                  detailedInfo.incomeRanges4 = 0;
                }
              } else {
                detailedInfo.Status = 'Single';
                detailedInfo.incomeRanges1 = 0;
                if (typeof databaseCompleteData !== 'undefined' && typeof databaseCompleteData['Data'] !== 'undefined' && typeof databaseCompleteData['Data']['PMI Deduction'] !== 'undefined') {
                  detailedInfo.incomeRanges2 = databaseCompleteData['Data']['PMI Deduction'];
                } else {
                  detailedInfo.incomeRanges2 = 0;
                }
                detailedInfo.incomeRanges3 = detailedInfo.incomeRanges2 + 1;
                if (typeof databaseCompleteData !== 'undefined' && typeof databaseCompleteData['Data'] !== 'undefined' && typeof databaseCompleteData['Data']['PMI Deduction Second'] !== 'undefined') {
                  detailedInfo.incomeRanges4 = databaseCompleteData['Data']['PMI Deduction Second'];
                } else {
                  detailedInfo.incomeRanges4 = 0;
                }
              }

              if (detailedInfo.incomeAdjustedGrossIncome <= detailedInfo.incomeRanges2) {
                detailedInfo.deduction1 = detailedInfo.privateMortgageInsuranceValue;
              }
              else {
                detailedInfo.deduction1 = 'Empty';
              }
              //Claculate Difference Row
              if (detailedInfo.incomeAdjustedGrossIncome >= detailedInfo.incomeRanges3 && detailedInfo.incomeAdjustedGrossIncome <= detailedInfo.incomeRanges4) {
                if (typeof moduleServices.module10Data.marriageYear !== 'undefined' && moduleServices.module10Data.marriageYear !== '' && selectedYear < moduleServices.module10Data.marriageYear) {
                  detailedInfo.difference1 = Math.ceil(detailedInfo.incomeAdjustedGrossIncome - detailedInfo.incomeRanges3, 500);
                }
                else {
                  detailedInfo.difference1 = Math.ceil(detailedInfo.incomeAdjustedGrossIncome - detailedInfo.incomeRanges3, 1000);
                }
              }
              else {
                detailedInfo.difference1 = 'Empty';
              }
              //Divisor Value
              if (detailedInfo.difference1 !== 'Empty') {
                if (selectedYear < moduleServices.module10Data.marriageYear) {
                  detailedInfo.divisor1 = 5000;
                }
                else {
                  detailedInfo.divisor1 = 10000;
                }
              }
              else {
                detailedInfo.divisor1 = 'Empty';
              }
              //Reduction factor Value
              if (detailedInfo.divisor1 !== 'Empty') {
                detailedInfo.reductionfactor1 = detailedInfo.difference1 / detailedInfo.divisor1;
              }
              else {
                detailedInfo.reductionfactor1 = 'Empty';
              }
              //Less Value
              if (detailedInfo.reductionfactor1 !== 'Empty') {
                detailedInfo.less1 = -detailedInfo.reductionfactor1 * detailedInfo.privateMortgageInsuranceValue;
              }
              else {
                detailedInfo.less1 = 'Empty';
              }
              //Deduction value
              detailedInfo.deduction2 = 0;
              if (detailedInfo.less1 !== 'Empty') {
                detailedInfo.deduction2 = parseFloat(detailedInfo.privateMortgageInsuranceValue) + parseFloat(detailedInfo.less1);
              }
              else {
                detailedInfo.deduction2 = 'Empty';
              }
              //Deduction3 value
              detailedInfo.deduction3 = 0;
              if (detailedInfo.incomeAdjustedGrossIncome > detailedInfo.incomeRanges4) {
                detailedInfo.deduction3 = 0;
              }
              else {
                detailedInfo.deduction3 = 0;
              }
              //Total PMI Deductions
              detailedInfo.firstDeduction = detailedInfo.deduction1;
              detailedInfo.secondDeduction = detailedInfo.deduction2;
              detailedInfo.thirdDeduction = detailedInfo.deduction3;

              if (detailedInfo.deduction1 === 'Empty') {
                detailedInfo.firstDeduction = 0;
              }
              if (detailedInfo.deduction2 === 'Empty') {
                detailedInfo.secondDeduction = 0;
              }
              if (detailedInfo.deduction3 === 'Empty') {
                detailedInfo.thirdDeduction = 0;
              }

              detailedInfo.totalDeductions = parseFloat(detailedInfo.firstDeduction) + parseFloat(detailedInfo.secondDeduction) + parseFloat(detailedInfo.thirdDeduction);
              detailedInfo.totalDeductionsCondition = 0;
              if (detailedInfo.totalDeductions > 0) {
                detailedInfo.totalDeductionsCondition = 1;
              }
              //Total Home Mortgage Value Limit
              if (typeof databaseCompleteData !== 'undefined' && databaseCompleteData !== '' && typeof databaseCompleteData['Data'] !== 'undefined' && typeof databaseCompleteData['Data']['Mortgage Interest Cap'] !== 'undefined') {
                detailedInfo.totalHomeMortgageValueLimit = databaseCompleteData['Data']['Mortgage Interest Cap'];
              }
              //Reduction % (Mortgage Limit / Average Mortgage Balance)
              if ((detailedInfo.existingHomeMortgageBalanceAvg + detailedInfo.homeMortgage) > detailedInfo.totalHomeMortgageValueLimit) {
                detailedInfo.reductionPercentage = 1 - (detailedInfo.totalHomeMortgageValueLimit / (detailedInfo.existingHomeMortgageBalanceAvg + detailedInfo.homeMortgage));
              } else {
                detailedInfo.reductionPercentage = 0;
              }
              /*}
              else{
                  detailedInfo.mortgage1Interest = 0;
                  detailedInfo.mortgage2Interest = 0;
              }*/


              // setOpenMortgageDeductionsPopup( true );

              setOpenDialog(true);
              setOpenDialogName('showDetailPopupMortgageDeductions');
              break;
            case 'Home Property Taxes, State & Local Income Taxes (SALT)':
              let taxStatementData = moduleServices.taxStatementCompleteData();
              //let BuyingAHomeData = moduleServices.buyingAHomeCompleteData();
              if (typeof BuyingAHomeData !== 'undefined' && BuyingAHomeData !== '' && typeof BuyingAHomeData['Real Estate Asset Value'] !== 'undefined') {
                detailedInfo.BuyingAHomeVal = BuyingAHomeData['Real Estate Asset Value'];
              }
              detailedInfo.BuyingAHomePuchaseYear = moduleServices.module13Data.yearOfPurchase;
              detailedInfo.propertyTax = parseFloat(moduleServices.module13Data.propertyTaxes);
              detailedInfo.propertyTaxesCalcs = moduleServices.module13Data.propertyTaxesCalcs;
              detailedInfo.inflationFirst = moduleServices.module1Data.inflation;
              detailedInfo.startyearFirst = moduleServices.module1Data.startYear;
              let databaseData = moduleServices.databaseCompleteData();
              detailedInfo.homePropertyTaxesCondition = 0;
              //for Home Property Taxes Deductions
              //let existingHomeData = moduleServices.existingHomeCompleteData();
              if (typeof existingHomeData !== 'undefined' && existingHomeData !== '' && typeof existingHomeData['Real Estate Asset Value'] !== 'undefined') {
                detailedInfo.existingHomeValueFirstYear = existingHomeData['Real Estate Asset Value'];
              } else {
                detailedInfo.existingHomeValueFirstYear = 0;
              }
              if (typeof moduleServices.module18Data.existingHomeMonthlyPropertyTaxesCalcs !== 'undefined' && moduleServices.module18Data.existingHomeMonthlyPropertyTaxesCalcs !== '') {
                detailedInfo.annualPropertyTaxes = moduleServices.module18Data.existingHomeMonthlyPropertyTaxesCalcs;
              } else {
                detailedInfo.annualPropertyTaxes = 0;
              }
              detailedInfo.propertyTaxRate = (detailedInfo.annualPropertyTaxes / detailedInfo.existingHomeValueFirstYear) * 100;
              if (typeof existingHomeData !== 'undefined' && existingHomeData !== '' && typeof existingHomeData['Real Estate'] !== 'undefined' && typeof existingHomeData['Real Estate'][selectedYear] !== 'undefined' && typeof existingHomeData['Real Estate'][selectedYear]['Asset Value'] !== 'undefined') {
                detailedInfo.existingHomeValueCurrentYear = existingHomeData['Real Estate'][selectedYear]['Asset Value'];
              } else {
                detailedInfo.existingHomeValueCurrentYear = 0;
              }
              if (detailedInfo.existingHomeValueCurrentYear > 0) {
                detailedInfo.propertyTaxRateCurrentYear = detailedInfo.propertyTaxRate;
              } else {
                detailedInfo.propertyTaxRateCurrentYear = 0;
              }
              //Existing home final value
              detailedInfo.existingHomePropertyTaxCurrentYearCondition = 0;
              if (typeof existingHomeData !== 'undefined' && existingHomeData !== '' && typeof existingHomeData['Real Estate'] !== 'undefined' && typeof existingHomeData['Real Estate'][selectedYear] !== 'undefined' && typeof existingHomeData['Real Estate'][selectedYear]['Property Tax'] !== 'undefined' && existingHomeData['Real Estate'][selectedYear]['Property Tax'] !== 0) {
                detailedInfo.existingHomePropertyTaxCurrentYear = existingHomeData['Real Estate'][selectedYear]['Property Tax'];
                detailedInfo.existingHomePropertyTaxCurrentYearCondition = 1;
              } else {
                detailedInfo.existingHomePropertyTaxCurrentYear = 0;
              }

              //Home Value Second Section
              if (typeof BuyingAHomeData !== 'undefined' && BuyingAHomeData !== '' && typeof BuyingAHomeData['Real Estate'] !== 'undefined' && typeof BuyingAHomeData['Real Estate'][selectedYear] !== 'undefined' && typeof BuyingAHomeData['Real Estate'][selectedYear]['Asset Value'] !== 'undefined') {
                detailedInfo.newHomeAssetValueCurrentYear = BuyingAHomeData['Real Estate'][selectedYear]['Asset Value'];
              } else {
                detailedInfo.newHomeAssetValueCurrentYear = 0;
              }
              if (typeof moduleServices.module13Data.propertyTaxes !== 'undefined' && moduleServices.module13Data.propertyTaxes !== '') {
                detailedInfo.homePropertyTaxes = moduleServices.module13Data.propertyTaxes;
              } else {
                detailedInfo.homePropertyTaxes = 0;
              }
              //Total Home Property Taxes
              detailedInfo.newHomePropertyTaxCurrentYearCondition = 0;
              if (typeof BuyingAHomeData !== 'undefined' && BuyingAHomeData !== '' && typeof BuyingAHomeData['Real Estate'] !== 'undefined' && typeof BuyingAHomeData['Real Estate'][selectedYear] !== 'undefined' && typeof BuyingAHomeData['Real Estate'][selectedYear]['Property Tax'] !== 'undefined' && BuyingAHomeData['Real Estate'][selectedYear]['Property Tax'] !== 0) {
                detailedInfo.newHomePropertyTaxCurrentYear = BuyingAHomeData['Real Estate'][selectedYear]['Property Tax'];
                detailedInfo.newHomePropertyTaxCurrentYearCondition = 1;
              } else {
                detailedInfo.newHomePropertyTaxCurrentYear = 0;
              }

              //Total Final value
              if (typeof incomeStatementData !== 'undefined' && typeof incomeStatementData[selectedYear] !== 'undefined' && incomeStatementData[selectedYear]['Itemized Deductions']['Home Property Taxes, State & Local Income Taxes (SALT)'] !== 0) {
                detailedInfo.homePropertyTaxesCurrentValue = detailedInfo.newHomePropertyTaxCurrentYear + detailedInfo.existingHomePropertyTaxCurrentYear;
              }
              else {
                detailedInfo.homePropertyTaxesCurrentValue = 0;
              }

              //For the section State and Local Taxes
              detailedInfo.stateAndLocalIncomeTaxesCondition = 0;
              if (typeof taxStatementData !== 'undefined' && typeof taxStatementData[selectedYear] !== 'undefined' && taxStatementData[selectedYear]['Adjusted Gross Income'] !== '') {
                detailedInfo.stateAndLocalAdjustedGrossIncome = taxStatementData[selectedYear]['Adjusted Gross Income'];
              }
              else {
                detailedInfo.stateAndLocalAdjustedGrossIncome = 0;
              }

              if (typeof incomeTaxesData !== 'undefined' && incomeTaxesData !== '' &&
                typeof incomeTaxesData['State Local Taxes'][selectedYear] !== 'undefined' &&
                typeof incomeTaxesData['State Local Taxes'][selectedYear]['State'] !== 'undefined') {
                detailedInfo.stateNameLocalTaxes = incomeTaxesData["State Local Taxes"][selectedYear]["State"];
              } else {
                detailedInfo.stateNameLocalTaxes = '';
              }
              if (typeof incomeTaxesData !== 'undefined' && incomeTaxesData !== '' &&
                typeof incomeTaxesData['State Local Taxes'][selectedYear] !== 'undefined' &&
                typeof incomeTaxesData['State Local Taxes'][selectedYear]['State tax'] !== 'undefined') {
                detailedInfo.stateNameLocalTaxesValue = incomeTaxesData["State Local Taxes"][selectedYear]["State tax"] * 100;
              } else {
                detailedInfo.stateNameLocalTaxesValue = 0;
              }
              if (typeof incomeTaxesData !== 'undefined' && incomeTaxesData !== '' &&
                typeof incomeTaxesData['State Local Taxes'][selectedYear] !== 'undefined' &&
                typeof incomeTaxesData['State Local Taxes'][selectedYear]['City tax'] !== 'undefined') {
                detailedInfo.cityNameLocalTaxesValue = incomeTaxesData["State Local Taxes"][selectedYear]["City tax"] * 100;
              } else {
                detailedInfo.cityNameLocalTaxesValue = 0;
              }
              detailedInfo.totalTaxesValue = detailedInfo.stateNameLocalTaxesValue + detailedInfo.cityNameLocalTaxesValue;
              //Cobnvert rate into value
              detailedInfo.totalTaxesValueFinal = (detailedInfo.stateAndLocalAdjustedGrossIncome / 100) * detailedInfo.totalTaxesValue;
              if (detailedInfo.totalTaxesValueFinal !== 0) {
                detailedInfo.stateAndLocalIncomeTaxesCondition = 1;
              }
              //Total SALT value
              detailedInfo.totalSaltValue = detailedInfo.totalTaxesValueFinal + detailedInfo.newHomePropertyTaxCurrentYear;
              //Max Salt Deduction
              if (typeof databaseData !== 'undefined' && databaseData !== '' && typeof databaseData['Data']['SALT Limit'] !== 'undefined') {
                detailedInfo.maxSaltDeduction = databaseData['Data']['SALT Limit'];
              } else {
                detailedInfo.maxSaltDeduction = 0;
              }
              if (typeof taxStatementData !== 'undefined' && typeof taxStatementData[selectedYear] !== 'undefined' && taxStatementData[selectedYear]['Itemized Deductions']['Home Property Taxes, State & Local Income Taxes (SALT)'] !== 0) {
                detailedInfo.finalSaltDeductionValue = taxStatementData[selectedYear]['Itemized Deductions']['Home Property Taxes, State & Local Income Taxes (SALT)'];
              }
              else {
                detailedInfo.finalSaltDeductionValue = 0;
              }

              // setOpenHomePropertyTaxesDeductionsPopup( true ); 
              setOpenDialog(true);
              setOpenDialogName('homePropertyTaxesDeductions');
              break;
            case 'Health Insurance':
              let taxstatementData = moduleServices.taxStatementCompleteData();
              let incomestatementCompleteData = moduleServices.databaseCompleteData();
              //let databaseData = moduleServices.databaseCompleteData();
              detailedInfo.healthInsuranceExpenses = 0;
              detailedInfo.adjustedGrossIncome = 0;
              if (typeof incomestatementCompleteData !== 'undefined' && incomestatementCompleteData !== '' && typeof incomestatementCompleteData[selectedYear] !== 'undefined') {
                detailedInfo.healthInsuranceExpenses = -(incomestatementCompleteData[selectedYear]['livingExpenses']['Healthcare Costs other than Premium'] + incomestatementCompleteData[selectedYear]['livingExpenses']['Health Insurance Premium']);
                //detailedInfo.adjustedGrossIncome     = taxStatementData[selectedYear]['Adjusted Gross Income']*10/100;
              }

              if (typeof incomestatementCompleteData !== 'undefined' && incomestatementCompleteData !== '' && selectedYear === incomestatementCompleteData['Data']['Health Insurance Itemized Deduct Year']) {
                detailedInfo.adjustedGrossIncomePercentage = incomestatementCompleteData['Data']['Health Insurance Itemized Deduct Rate'];
              } else {
                if (typeof incomestatementCompleteData !== 'undefined' && incomestatementCompleteData !== '' && typeof incomestatementCompleteData['Data'] !== 'undefined' && incomestatementCompleteData['Data'] !== '' && typeof incomestatementCompleteData['Data']['Health Insurance Itemized Deduct Rate Second'] !== 'undefined' && incomestatementCompleteData['Data']['Health Insurance Itemized Deduct Rate Second'] !== '') {
                  detailedInfo.adjustedGrossIncomePercentage = incomestatementCompleteData['Data'] && incomestatementCompleteData['Data']['Health Insurance Itemized Deduct Rate Second'];
                }
              }
              if (typeof taxstatementData !== 'undefined' && taxstatementData !== '' && typeof taxstatementData[selectedYear] !== 'undefined' && taxstatementData[selectedYear] !== '' && typeof taxstatementData[selectedYear]['Adjusted Gross Income'] !== 'undefined' && taxstatementData[selectedYear]['Adjusted Gross Income'] !== '') {
                detailedInfo.adjustedGrossIncome = (taxstatementData[selectedYear]['Adjusted Gross Income'] / 100) * detailedInfo.adjustedGrossIncomePercentage;
              }
              detailedInfo.condition = 0;
              if (typeof taxstatementData !== 'undefined' && taxstatementData !== '' && typeof taxstatementData[selectedYear] !== 'undefined' && taxstatementData[selectedYear]['Itemized Deductions']['Health Insurance'] !== 0) {
                detailedInfo.healthInsuranceFinal = taxstatementData[selectedYear]['Itemized Deductions']['Health Insurance'];
                detailedInfo.condition = 1;
              } else {
                detailedInfo.healthInsuranceFinal = 0;
              }

              // setOpenGrossIncomePopup(true);
              setOpenGrossIncomeModel('health-insurance');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            /*case 'Charitable Contributions':
                detailedInfo.charitableCondition    = 0;
                detailedInfo.startYear   = moduleServices.module9Data.charitableStartYear;
                detailedInfo.endYear     = moduleServices.module9Data.charitableEndYear;
                if(selectedYear >= detailedInfo.startYear &&
                    selectedYear <= detailedInfo.endYear ) {
                    detailedInfo.charitableCondition= 1;
                }
            
                // Show Portion
                let nb = $('div.modal-backdrop').length;
                if(nb <= 1){
                    $('.charitable-contribution').show().siblings().hide();
                $('#    showDetailPopup').modal('show');
                    }
                break;*/
            case 'Charitable Contributions':
              let incomeStatementCompleteData = moduleServices.incomeStatementCompleteData();
              let cashFlowData = moduleServices.cashFlowCompleteData();
              detailedInfo.charitableContributionCondition = 0;
              if (typeof moduleServices.module9Data !== 'undefined' &&
                typeof moduleServices.module9Data.charitableStartYear !== 'undefined') {
                detailedInfo.charitableStartYear = moduleServices.module9Data.charitableStartYear;
              }
              else {
                detailedInfo.charitableStartYear = 0;
              }
              if (typeof moduleServices.module9Data !== 'undefined' &&
                typeof moduleServices.module9Data.charitableEndYear !== 'undefined') {
                detailedInfo.charitableEndYear = moduleServices.module9Data.charitableEndYear;
              }
              else {
                detailedInfo.charitableEndYear = 0;
              }
              if (typeof moduleServices.module9Data !== 'undefined' &&
                typeof moduleServices.module9Data.charitableYearlyContribution !== 'undefined') {
                detailedInfo.grossIncomePercentageValue = moduleServices.module9Data.charitableYearlyContribution;
              }
              else {
                detailedInfo.grossIncomePercentageValue = 0;
              }
              if (typeof incomeStatementCompleteData !== 'undefined' &&
                incomeStatementCompleteData !== '' &&
                typeof incomeStatementCompleteData[selectedYear]['Total Income'] !== 'undefined') {
                detailedInfo.grossIncomeValue = incomeStatementCompleteData[selectedYear]['Total Income'];
              }
              else {
                detailedInfo.grossIncomeValue = 0;
              }

              if (typeof cashFlowData !== 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear]['Cash from Investing Activities'] !== 'undefined' &&
                typeof cashFlowData[selectedYear]['Cash from Investing Activities']['Charitable Contributions'] !== 'undefined' && cashFlowData[selectedYear]['Cash from Investing Activities']['Charitable Contributions'] !== 0) {
                detailedInfo.charitableContributionValue = cashFlowData[selectedYear]['Cash from Investing Activities']['Charitable Contributions'];
                detailedInfo.charitableContributionCondition = 1;
              }
              else {
                detailedInfo.charitableContributionValue = 0;
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('charitable-contributions');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'State and Local Taxes':

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('state-local-taxes');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;

            default:
              break;
          }
          break;
        /* End of Deductions & Exemptions */
        /* REI Property Deductions */

        case 'REI Deductions':
          switch (selectedColumn) {
            case 'Passive Losses Taken':
              let REIData = moduleServices.invPropertyRoiCompleteData();
              // let incomeStatementData = moduleServices.incomeStatementCompleteData();
              let taxStatementData = moduleServices.taxStatementCompleteData();
              // let passiveLossCreated1 = 0;
              // let passiveLossCreated2 = 0;

              if (typeof REIData !== 'undefined' && typeof REIData['Data3'][selectedYear] !== 'undefined' && typeof REIData['Data3'][selectedYear]['Net Rental Income'] !== 'undefined') {
                detailedInfo.netRentalIncomeValue = REIData['Data3'][selectedYear]['Net Rental Income'];
              }
              if (typeof REIData !== 'undefined' && typeof REIData['Data1'][selectedYear] !== 'undefined' && typeof REIData['Data1'][selectedYear]['Mortgage Interest, Loan Points, Origination Fees'] !== 'undefined') {
                detailedInfo.mortgageInterestLoanPointsValue = REIData['Data1'][selectedYear]['Mortgage Interest, Loan Points, Origination Fees'];
              }
              if (typeof REIData !== 'undefined' && typeof REIData['Real Estate'][selectedYear] !== 'undefined' && typeof REIData['Real Estate'][selectedYear]['Home REI Table'] !== 'undefined') {
                detailedInfo.depreciationValue = -REIData['Real Estate'][selectedYear]['Home REI Table'];
              }
              //Passive Losses Creared
              if (typeof REIData !== 'undefined' && typeof REIData['Data3'][selectedYear] !== 'undefined' && typeof REIData['Data3'][selectedYear]['Passive Loss Created'] !== 'undefined') {
                detailedInfo.passiveLossesCreated = REIData['Data3'][selectedYear]['Passive Loss Created'];
              }
              if (typeof taxStatementData !== 'undefined' && taxStatementData !== '' && typeof taxStatementData[selectedYear] !== 'undefined' && taxStatementData[selectedYear] !== '') {
                detailedInfo.beginningPassiveValue = taxStatementData[selectedYear]['REI Property1']['Passive Losses Beginning Balance'];
              }
              if (typeof taxStatementData !== 'undefined' && taxStatementData !== '' && typeof taxStatementData[selectedYear] !== 'undefined' && taxStatementData[selectedYear] !== '') {
                detailedInfo.passiveLossesCreated1 = taxStatementData[selectedYear]['REI Property1']['Passive Loss Created'];
              }
              if (typeof taxStatementData !== 'undefined' && taxStatementData !== '' && typeof taxStatementData[selectedYear] !== 'undefined' && taxStatementData[selectedYear] !== '') {
                detailedInfo.passiveLossesTaken1 = taxStatementData[selectedYear]['REI Property1']['Passive Losses Taken'];
              }
              if (typeof taxStatementData !== 'undefined' && taxStatementData !== '' && typeof taxStatementData[selectedYear] !== 'undefined' && taxStatementData[selectedYear] !== '') {
                detailedInfo.remainingPassiveLossesBalance = taxStatementData[selectedYear]['REI Property1']['Remaining Passive Losses'];
              }

              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' && typeof incomeStatementData[selectedYear] !== 'undefined' && typeof incomeStatementData[selectedYear]['REI Property Deductions']['Passive Losses'] !== 'undefined') {
                detailedInfo.passiveLossesFinalValue = -incomeStatementData[selectedYear]['REI Property Deductions']['Passive Losses']
              }
              detailedInfo.detailsPopupCondition = 0;
              if (typeof moduleServices.module14Data !== 'undefined' && typeof moduleServices.module14Data.purchasingYear !== 'undefined' && typeof moduleServices.module14Data.yearOfSale !== 'undefined' && selectedYear >= (moduleServices.module14Data.purchasingYear - 1) && selectedYear <= moduleServices.module14Data.yearOfSale) {
                detailedInfo.detailsPopupCondition = 1;
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('passive-losses');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            default:
              break;
          }
          break;
        /* End of REI Property Deductions */
        /* Itemized Deductions */

        case 'Itemized Deductions':
          // Show Portion
          let taxstatementData = moduleServices.taxStatementCompleteData();
          if (typeof taxstatementData !== 'undefined' && typeof taxstatementData[selectedYear] !== 'undefined' && typeof taxstatementData[selectedYear]['Itemized Deductions'] !== 'undefined') {
            detailedInfo.HomeMortgageInterestPopup = taxstatementData[selectedYear]['Itemized Deductions']['Home Mortgage Interest, Loan Points & Origination Fees, & PMI'];
            detailedInfo.HomePropertyTaxesPopup = taxstatementData[selectedYear]['Itemized Deductions']['Home Property Taxes, State & Local Income Taxes (SALT)'];
            detailedInfo.HealthInsurancePopup = taxstatementData[selectedYear]['Itemized Deductions']['Health Insurance'];
            detailedInfo.CharitableContributionsPopup = taxstatementData[selectedYear]['Itemized Deductions']['Charitable Contributions'];

          } else {
            detailedInfo.HomeMortgageInterestPopup = 0;
            detailedInfo.HomePropertyTaxesPopup = 0;
            detailedInfo.HealthInsurancePopup = 0;
            detailedInfo.CharitableContributionsPopup = 0;
          }
          if (typeof taxstatementData !== 'undefined' && typeof taxstatementData[selectedYear] !== 'undefined' && typeof taxstatementData[selectedYear]['Total Itemized Deductions'] !== 'undefined') {
            detailedInfo.ItemizedDeductionsPopup = taxstatementData[selectedYear]['Total Itemized Deductions'];
          }

          // setOpenGrossIncomePopup( true );
          setOpenGrossIncomeModel('itemized-deductions');
          setOpenDialog(true);
          setOpenDialogName('showDetailPopup');
          break;
        /* End of Itemized Deductions */
        /* Standard Deduction */

        case 'Standard Deductions':
          detailedInfo.standardCondition = 0;
          detailedInfo.startYear = moduleServices.module10Data.marriageYear;
          let databaseCompleteData = moduleServices.databaseCompleteData();
          //let taxStatementData = moduleServices.taxStatementCompleteData();

          if (typeof moduleServices.module10Data.marriageYear !== 'undefined' && moduleServices.module10Data.marriageYear > 0 && moduleServices.module10Data.marriageYear <= selectedYear) {
            detailedInfo.marrideStatus = moduleServices.module10Data.marriageYear;
          } else {
            detailedInfo.marrideStatus = 'Not Married'
          }

          if (typeof databaseCompleteData !== 'undefined' && databaseCompleteData !== '' && typeof databaseCompleteData['Standard Deductions'] !== 'undefined' && databaseCompleteData['Standard Deductions'] !== '' && typeof databaseCompleteData['Standard Deductions'][selectedYear] !== 'undefined' && databaseCompleteData['Standard Deductions'][selectedYear] !== '' && typeof databaseCompleteData['Standard Deductions'][selectedYear]['Single'] !== 'undefined' && databaseCompleteData['Standard Deductions'][selectedYear]['Single'] !== '') {
            detailedInfo.singleDeductionValue = databaseCompleteData['Standard Deductions'][selectedYear]['Single'];
          }
          if (typeof databaseCompleteData !== 'undefined' && databaseCompleteData !== '' && typeof databaseCompleteData['Standard Deductions'] !== 'undefined' && databaseCompleteData['Standard Deductions'] !== '' && typeof databaseCompleteData['Standard Deductions'][selectedYear] !== 'undefined' && databaseCompleteData['Standard Deductions'][selectedYear] !== '' && typeof databaseCompleteData['Standard Deductions'][selectedYear]['Married'] !== 'undefined' && databaseCompleteData['Standard Deductions'][selectedYear]['Married'] !== '') {
            detailedInfo.marriedDeductionValue = databaseCompleteData['Standard Deductions'][selectedYear]['Married'];
          }

          if (typeof taxStatementData !== 'undefined' && taxStatementData !== '' && typeof taxStatementData[selectedYear] !== 'undefined' && taxStatementData[selectedYear] !== '' && typeof taxStatementData[selectedYear]['Standard Deductions'] !== 'undefined' && taxStatementData[selectedYear]['Standard Deductions'] !== '') {
            detailedInfo.finalStandardValue = taxStatementData[selectedYear]['Standard Deductions'];
          } else {
            detailedInfo.finalStandardValue = 0;
          }

          // setOpenGrossIncomePopup( true );
          setOpenGrossIncomeModel('standard-deduction');
          setOpenDialog(true);
          setOpenDialogName('showDetailPopup');
          break;
        /* End of Standard Deduction*/

        /* Total Deductions & Exemptions */

        case 'Total Deductions Exemptions':
           let tStatementData = moduleServices.taxStatementCompleteData();
          if (typeof tStatementData !== 'undefined' && tStatementData[selectedYear] !== 'undefined') {           
            detailedInfo.standardDeductionVal = tStatementData[selectedYear]['Standard Deduction'];
            detailedInfo.totalDeductionsVal = tStatementData[selectedYear]['Total Deductions'];
            detailedInfo.ItemizedDeductionsPopup = tStatementData[selectedYear]['Total Itemized Deductions'];
          }

          // setOpenGrossIncomePopup( true );
          setOpenGrossIncomeModel('total-deductions-exemptions');
          setOpenDialog(true);
          setOpenDialogName('showDetailPopup');
          break;
        /* End of Total Deductions & Exemptions */
        /* Federal Taxable Income */

        case 'Federal Taxable Income':

          let txStatementData = moduleServices.taxStatementCompleteData();
          detailedInfo.federalTaxableIncomeCondition = 0;
          if (typeof txStatementData !== 'undefined' && txStatementData !== ''
            && typeof txStatementData[selectedYear] !== 'undefined' && typeof txStatementData[selectedYear]['Adjusted Gross Income'] !== 'undefined'
            && typeof txStatementData[selectedYear]['Total Deductions'] !== 'undefined' && (txStatementData[selectedYear]['Adjusted Gross Income'] - txStatementData[selectedYear]['Total Deductions']) > 0) {
            detailedInfo.federalTaxableIncomeCondition = 1;
            detailedInfo.adjustedGrossIncomePop = txStatementData[selectedYear]['Adjusted Gross Income'];
            detailedInfo.totalDeductionsPop = txStatementData[selectedYear]['Total Deductions'];
            detailedInfo.federalTaxableIncomePop = txStatementData[selectedYear]['Adjusted Gross Income'] - txStatementData[selectedYear]['Total Deductions'];;
          
          } else {
            detailedInfo.federalTaxableIncomePop = 0;
          }

          // setOpenGrossIncomePopup( true );
          setOpenGrossIncomeModel('federal-taxable-income');
          setOpenDialog(true);
          setOpenDialogName('showDetailPopup');
          break;
        /* End of Federal Taxable Income*/
        /* Taxes */

        case 'Taxes':
          switch (selectedColumn) {
            case 'Federal Income Tax (x Federal Taxable Income)':
              let taxStatementDatatwo = moduleServices.taxStatementCompleteData();
              let incometaxesData = moduleServices.inputTaxesCompleteData();
              if (typeof incometaxesData !== 'undefined' && incometaxesData !== '' &&
                typeof incometaxesData['Federal Income'][selectedYear] !== 'undefined') {
                if (typeof moduleServices.module10Data.marriageYear !== 'undefined' &&
                  selectedYear >= moduleServices.module10Data.marriageYear && moduleServices.module10Data.marriageYear !== 0) {
                  detailedInfo = incometaxesData['Federal Income'][selectedYear]['Married'];
                  detailedInfo.Type = 'Married';
                  detailedInfo.Year = selectedYear;
                  detailedInfo.Data = incometaxesData['Federal Income']['Married Data'];
                  detailedInfo.typeCondition = 1;

                } else {

                  detailedInfo = incometaxesData['Federal Income'][selectedYear]['Single'];
                  detailedInfo.Type = 'Single';
                  detailedInfo.Year = selectedYear;
                  detailedInfo.Data = incometaxesData['Federal Income']['Single Data'];
                  detailedInfo.typeCondition = 0;
                }
                if (taxStatementDatatwo !== undefined && taxStatementDatatwo !== '') {
                  detailedInfo.TaxData = taxStatementDatatwo[selectedYear];
                }
                //Single and Married values
                if (typeof moduleServices.module10Data.marriageYear !== 'undefined' && moduleServices.module10Data.marriageYear !== 0 &&
                  selectedYear >= moduleServices.module10Data.marriageYear) {
                  if (typeof incometaxesData !== 'undefined' && incometaxesData !== '' && typeof incometaxesData['Inflation Adjusted Tax Brackets'][selectedYear] !== 'undefined' && typeof incometaxesData['Inflation Adjusted Tax Brackets'][selectedYear]['Married']['Iteration Data'] !== 'undefined') {
                    detailedInfo.Data = incometaxesData['Inflation Adjusted Tax Brackets'][selectedYear]['Married']['Iteration Data'];
                  } else {
                    detailedInfo.Data = {};
                  }
                } else {
                  if (typeof incometaxesData !== 'undefined' && incometaxesData !== '' && typeof incometaxesData['Inflation Adjusted Tax Brackets'][selectedYear] !== 'undefined' && typeof incometaxesData['Inflation Adjusted Tax Brackets'][selectedYear]['Single']['Iteration Data'] !== 'undefined') {
                    detailedInfo.Data = incometaxesData['Inflation Adjusted Tax Brackets'][selectedYear]['Single']['Iteration Data'];
                  } else {
                    detailedInfo.Data = {};
                  }
                }

                // setOpenGrossIncomePopup( true );
                setOpenGrossIncomeModel('federal');
                setOpenDialog(true);
                setOpenDialogName('showDetailPopup');
              }
              break;
            case 'Social Security Tax (x Total Gross Income with limits)':
              let databaseData = moduleServices.databaseCompleteData();
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {

                detailedInfo.socialSecurityTaxRate = 6.20;

                if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                  typeof incomeStatementData[selectedYear] !== 'undefined') {
                  detailedInfo.condition = incomeStatementData[selectedYear]['Social Security Tax Condition'];
                }
                //Maximum Income Limit Value
                if (typeof databaseData !== 'undefined' && typeof databaseData['Social Security Income Tax Limit'] !== 'undefined' && typeof databaseData['Social Security Income Tax Limit'] !== 'undefined' && typeof databaseData['Social Security Income Tax Limit'][selectedYear]['Limit'] !== 'undefined') {
                  detailedInfo.socialSecurityincomeLimit = databaseData['Social Security Income Tax Limit'][selectedYear]['Limit'];
                } else {
                  detailedInfo.socialSecurityincomeLimit = 0;
                }
                //Self Employment Multiplier
                if (typeof moduleServices.module4Data.startYear !== 'undefined' &&
                  typeof moduleServices.module4Data.incomeEndYear !== 'undefined' &&
                  typeof moduleServices.module4Data.selfEmployment !== 'undefined' &&
                  selectedYear >= moduleServices.module4Data.startYear &&
                  selectedYear <= moduleServices.module4Data.incomeEndYear &&
                  moduleServices.module4Data.selfEmployment === 'Yes') {
                  detailedInfo.selfEmploymentMultiplier = 2;
                } else {
                  if (typeof moduleServices.module5Data.startYear !== 'undefined' &&
                    typeof moduleServices.module5Data.incomeEndYear !== 'undefined' &&
                    typeof moduleServices.module5Data.selfEmployment !== 'undefined' &&
                    selectedYear >= moduleServices.module5Data.startYear &&
                    selectedYear <= moduleServices.module5Data.incomeEndYear &&
                    moduleServices.module5Data.selfEmployment === 'Yes') {
                    detailedInfo.selfEmploymentMultiplier = 2;
                  } else {
                    if (typeof moduleServices.module8Data.startYear !== 'undefined' &&
                      typeof moduleServices.module8Data.endYear !== 'undefined' &&
                      typeof moduleServices.module8Data.selfEmployment !== 'undefined' &&
                      selectedYear >= moduleServices.module8Data.startYear &&
                      selectedYear <= moduleServices.module8Data.endYear &&
                      moduleServices.module8Data.selfEmployment === 'Yes') {
                      detailedInfo.selfEmploymentMultiplier = 2;
                    } else {
                      if (typeof moduleServices.module17Data.startYear !== 'undefined' &&
                        typeof moduleServices.module17Data.endYear !== 'undefined' &&
                        typeof moduleServices.module17Data.selfEmployment !== 'undefined' &&
                        selectedYear >= moduleServices.module17Data.startYear &&
                        selectedYear <= moduleServices.module17Data.endYear &&
                        moduleServices.module17Data.selfEmployment === 'Yes') {
                        detailedInfo.selfEmploymentMultiplier = 2;
                      } else {
                        detailedInfo.selfEmploymentMultiplier = 1;
                      }
                    }
                  }
                }

                // setOpenGrossIncomePopup( true );
                setOpenGrossIncomeModel('social-security');
                setOpenDialog(true);
                setOpenDialogName('showDetailPopup');
              }
              break;
            case 'Medicare Tax (x Total Gross Income with limits)':
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {
                detailedInfo = incomeTaxesData['Federal Income'][selectedYear]['Medicare'];

                detailedInfo.Year = selectedYear;
                //Self Employment Multiplier
                if (typeof moduleServices.module4Data.startYear !== 'undefined' &&
                  typeof moduleServices.module4Data.incomeEndYear !== 'undefined' &&
                  typeof moduleServices.module4Data.selfEmployment !== 'undefined' &&
                  selectedYear >= moduleServices.module4Data.startYear &&
                  selectedYear <= moduleServices.module4Data.incomeEndYear &&
                  moduleServices.module4Data.selfEmployment === 'Yes') {
                  detailedInfo.selfEmploymentStatus = 'Yes';
                } else {
                  if (typeof moduleServices.module5Data.startYear !== 'undefined' &&
                    typeof moduleServices.module5Data.incomeEndYear !== 'undefined' &&
                    typeof moduleServices.module5Data.selfEmployment !== 'undefined' &&
                    selectedYear >= moduleServices.module5Data.startYear &&
                    selectedYear <= moduleServices.module5Data.incomeEndYear &&
                    moduleServices.module5Data.selfEmployment === 'Yes') {
                    detailedInfo.selfEmploymentStatus = 'Yes';
                  } else {
                    if (typeof moduleServices.module8Data.startYear !== 'undefined' &&
                      typeof moduleServices.module8Data.endYear !== 'undefined' &&
                      typeof moduleServices.module8Data.selfEmployment !== 'undefined' &&
                      selectedYear >= moduleServices.module8Data.startYear &&
                      selectedYear <= moduleServices.module8Data.endYear &&
                      moduleServices.module8Data.selfEmployment === 'Yes') {
                      detailedInfo.selfEmploymentStatus = 'Yes';
                    } else {
                      if (typeof moduleServices.module17Data.startYear !== 'undefined' &&
                        typeof moduleServices.module17Data.endYear !== 'undefined' &&
                        typeof moduleServices.module17Data.selfEmployment !== 'undefined' &&
                        selectedYear >= moduleServices.module17Data.startYear &&
                        selectedYear <= moduleServices.module17Data.endYear &&
                        moduleServices.module17Data.selfEmployment === 'Yes') {
                        detailedInfo.selfEmploymentStatus = 'Yes';
                      } else {
                        detailedInfo.selfEmploymentStatus = 'No';
                      }
                    }
                  }
                }
                //Maximum Income Limit
                if (typeof incomeTaxesData !== 'undefined' && incomeTaxesData !== '' &&
                  typeof incomeTaxesData['Medicare Taxes'][selectedYear] !== 'undefined' &&
                  incomeTaxesData['Medicare Taxes'][selectedYear] !== ''
                  && typeof incomeTaxesData['Medicare Taxes'][selectedYear] !== 'undefined' &&
                  typeof incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Threshold'] !== 'undefined') {
                  detailedInfo.maximumIncomelimit = incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Threshold'];
                } else {
                  detailedInfo.maximumIncomelimit = 0;
                }
                //Marriage Status
                if (typeof moduleServices.module10Data !== 'undefined' && moduleServices.module10Data.marriageYear !== '' && selectedYear >= moduleServices.module10Data.marriageYear) {
                  detailedInfo.Status = 'Married';
                } else {
                  detailedInfo.Status = 'Single';
                }
                //Medicare Tax final value
                detailedInfo.finalMedicareTaxRateValueCondition = 0;
                if (typeof incomeTaxesData !== 'undefined' && incomeTaxesData !== '' &&
                  typeof incomeTaxesData['Medicare Taxes'][selectedYear] !== 'undefined' &&
                  incomeTaxesData['Medicare Taxes'][selectedYear] !== ''
                  && typeof incomeTaxesData['Medicare Taxes'][selectedYear] !== 'undefined' &&
                  typeof incomeTaxesData['Medicare Taxes'][selectedYear]['Medicare Tax Rate'] !== 'undefined' &&
                  incomeTaxesData['Medicare Taxes'][selectedYear]['Medicare Tax Rate'] > 0) {
                  detailedInfo.finalMedicareTaxRateValue = incomeTaxesData['Medicare Taxes'][selectedYear]['Medicare Tax Rate'];
                  detailedInfo.finalMedicareTaxRateValueCondition = 1;
                } else {
                  detailedInfo.finalMedicareTaxRateValue = 0;
                }

                // setOpenGrossIncomePopup(true);
               
                setOpenGrossIncomeModel('medicare');
                setOpenDialog(true);
                setOpenDialogName('showDetailPopup');
              }
              break;
            case 'Additional Medicare Tax (x Total Gross Income in excess of limits)':
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {
                detailedInfo = incomeTaxesData['Federal Income'][selectedYear]['Medicare'];

                detailedInfo.Year = selectedYear;
                //Self Employment Multiplier
                if (typeof moduleServices.module4Data.startYear !== 'undefined' &&
                  typeof moduleServices.module4Data.incomeEndYear !== 'undefined' &&
                  typeof moduleServices.module4Data.selfEmployment !== 'undefined' &&
                  selectedYear >= moduleServices.module4Data.startYear &&
                  selectedYear <= moduleServices.module4Data.incomeEndYear &&
                  moduleServices.module4Data.selfEmployment === 'Yes') {
                  detailedInfo.selfEmploymentStatus = 'Yes';
                } else {
                  if (typeof moduleServices.module5Data.startYear !== 'undefined' &&
                    typeof moduleServices.module5Data.incomeEndYear !== 'undefined' &&
                    typeof moduleServices.module5Data.selfEmployment !== 'undefined' &&
                    selectedYear >= moduleServices.module5Data.startYear &&
                    selectedYear <= moduleServices.module5Data.incomeEndYear &&
                    moduleServices.module5Data.selfEmployment === 'Yes') {
                    detailedInfo.selfEmploymentStatus = 'Yes';
                  } else {
                    if (typeof moduleServices.module8Data.startYear !== 'undefined' &&
                      typeof moduleServices.module8Data.endYear !== 'undefined' &&
                      typeof moduleServices.module8Data.selfEmployment !== 'undefined' &&
                      selectedYear >= moduleServices.module8Data.startYear &&
                      selectedYear <= moduleServices.module8Data.endYear &&
                      moduleServices.module8Data.selfEmployment === 'Yes') {
                      detailedInfo.selfEmploymentStatus = 'Yes';
                    } else {
                      if (typeof moduleServices.module17Data.startYear !== 'undefined' &&
                        typeof moduleServices.module17Data.endYear !== 'undefined' &&
                        typeof moduleServices.module17Data.selfEmployment !== 'undefined' &&
                        selectedYear >= moduleServices.module17Data.startYear &&
                        selectedYear <= moduleServices.module17Data.endYear &&
                        moduleServices.module17Data.selfEmployment === 'Yes') {
                        detailedInfo.selfEmploymentStatus = 'Yes';
                      } else {
                        detailedInfo.selfEmploymentStatus = 'No';
                      }
                    }
                  }
                }
                //Maximum Income Limit
                if (typeof incomeTaxesData !== 'undefined' && incomeTaxesData !== '' &&
                  typeof incomeTaxesData['Medicare Taxes'][selectedYear] !== 'undefined' &&
                  incomeTaxesData['Medicare Taxes'][selectedYear] !== ''
                  && typeof incomeTaxesData['Medicare Taxes'][selectedYear] !== 'undefined' &&
                  typeof incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Threshold'] !== 'undefined') {
                  detailedInfo.maximumIncomelimit = incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Threshold'];
                } else {
                  detailedInfo.maximumIncomelimit = 0;
                }
                //Marriage Status
                if (typeof moduleServices.module10Data !== 'undefined' && moduleServices.module10Data.marriageYear !== '' && selectedYear >= moduleServices.module10Data.marriageYear) {
                  detailedInfo.Status = 'Married';
                } else {
                  detailedInfo.Status = 'Single';
                }
                //Medicare Tax final value
                detailedInfo.finalMedicareTaxRateValueCondition = 0;
                if (typeof incomeTaxesData !== 'undefined' && incomeTaxesData !== '' &&
                  typeof incomeTaxesData['Medicare Taxes'][selectedYear] !== 'undefined' &&
                  incomeTaxesData['Medicare Taxes'][selectedYear] !== ''
                  && typeof incomeTaxesData['Medicare Taxes'][selectedYear] !== 'undefined' &&
                  typeof incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Tax Rate'] !== 'undefined' &&
                  incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Tax Rate'] > 0) {
                  detailedInfo.finalMedicareTaxRateValue = incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Tax Rate'];
                  detailedInfo.finalMedicareTaxRateValueCondition = 1;
                } else {
                  detailedInfo.finalMedicareTaxRateValue = 0;
                }

                // setOpenGrossIncomePopup( true );
                setOpenGrossIncomeModel('medicare-additional');
                setOpenDialog(true);
                setOpenDialogName('showDetailPopup');
              }
              break;
            case 'State & Local Taxes (x Adjusted Gross Income)':
              if (typeof incomeTaxesData !== 'undefined' && incomeTaxesData !== '' &&
                typeof incomeTaxesData['Federal Income'][selectedYear] !== 'undefined') {

                detailedInfo = incomeTaxesData['State Local Taxes'][selectedYear];
                detailedInfo.Year = selectedYear;

                // setOpenGrossIncomePopup( true );
                setOpenGrossIncomeModel('state-local');
                setOpenDialog(true);
                setOpenDialogName('showDetailPopup');
              }
              break;
            case 'Total Tax Rate':
              let taxStatementDataThree = moduleServices.taxStatementCompleteData();
              // if(typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
              //     typeof incomeStatementData[selectedYear] !== 'undefined') {

              if (typeof taxStatementDataThree !== 'undefined' && taxStatementDataThree !== '' &&
                typeof taxStatementDataThree[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== 'undefined') {
                detailedInfo.totalTaxRateGrossIncome = taxStatementDataThree[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'];
              }
              if (typeof taxStatementDataThree !== 'undefined' && taxStatementDataThree !== '' &&
                typeof taxStatementDataThree[selectedYear]['Total Taxes'] !== 'undefined') {
                detailedInfo.totalTaxRatebeforeCredits = taxStatementDataThree[selectedYear]['Total Taxes'];
              }
              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('total-tax-rate');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Total Taxes':
              let TaxstatementData = moduleServices.taxStatementCompleteData();
              //let incomeStatementData = moduleServices.incomeStatementCompleteData();
              if (typeof TaxstatementData !== 'undefined' && TaxstatementData !== '' &&
                typeof TaxstatementData[selectedYear] !== 'undefined') {
                detailedInfo.totalTaxesBeforeCredits = TaxstatementData[selectedYear]['Total Taxes'];
                detailedInfo.totalTaxCredits = TaxstatementData[selectedYear]['Total Tax Credits'];
                detailedInfo.totalTaxRealEstateValues = TaxstatementData[selectedYear]['Total Investment Property Sale'];
              } else {
                detailedInfo.totalTaxesBeforeCredits = 0;
                detailedInfo.totalTaxCredits = 0;
                detailedInfo.totalTaxRealEstateValues = 0;
              }
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' &&
                typeof incomeStatementData[selectedYear] !== 'undefined') {
                detailedInfo.totalTaxFinalValue = incomeStatementData[selectedYear]['Total Taxes'];
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('total-taxes');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;

            case 'Total Taxes before Credits':
              let taxStatementCompleteData = moduleServices.taxStatementCompleteData();
              // let databaseData = moduleServices.databaseCompleteData();
              //let incomeTaxesData = moduleServices.inputTaxesCompleteData();
              detailedInfo.federalIncomeTaxFinalCondition = 1;
              detailedInfo.socialSecurityFinalCondition = 1;
              detailedInfo.medicareTaxFinalCondition = 1;
              detailedInfo.stateAndLocalTaxFinalCondition = 1;
              if (typeof taxStatementCompleteData !== 'undefined' && taxStatementCompleteData !== '' && typeof taxStatementCompleteData[selectedYear] !== 'undefined' && taxStatementCompleteData[selectedYear] !== '') {
                detailedInfo.federalIncomeTaxRate = taxStatementCompleteData[selectedYear]['taxes']['Federal Income Tax (x Federal Taxable Income)'];
                detailedInfo.federalTaxbleIncome = taxStatementCompleteData[selectedYear]['Federal Taxable Income'];
                detailedInfo.federalIncomeTaxFinal = taxStatementCompleteData[selectedYear]['taxes']['Federal Income Tax (x Federal Taxable Income)'] * (taxStatementCompleteData[selectedYear]['Federal Taxable Income'] / 100);
                if (detailedInfo.federalIncomeTaxFinal !== 0) {
                  detailedInfo.federalIncomeTaxFinalCondition = 1;
                }
              }
              //Social Security Tax Rate
              if (typeof taxStatementCompleteData !== 'undefined' && taxStatementCompleteData !== '' && typeof taxStatementCompleteData[selectedYear] !== 'undefined' && taxStatementCompleteData[selectedYear] !== '') {
                detailedInfo.socialSecurityTaxRate = taxStatementCompleteData[selectedYear]['taxes']['Social Security Tax (x Total Gross Income with limits)'];
                detailedInfo.socialSecurityIncome = taxStatementCompleteData[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'];
              } else {
                detailedInfo.socialSecurityTaxRate = 0;
                detailedInfo.socialSecurityIncome = 0;
              }
              if (typeof databaseData !== 'undefined' && databaseData !== '' && typeof databaseData['Social Security Income Tax Limit'][selectedYear] !== 'undefined' && databaseData['Social Security Income Tax Limit'][selectedYear]['Limit'] !== 'undefined') {
                detailedInfo.socialSecurityMaximumIncomeLimit = databaseData['Social Security Income Tax Limit'][selectedYear]['Limit'];
              }
              if (detailedInfo.socialSecurityIncome > detailedInfo.socialSecurityMaximumIncomeLimit) {
                detailedInfo.socialSecurityFinal = detailedInfo.socialSecurityTaxRate * (detailedInfo.socialSecurityMaximumIncomeLimit / 100);
              } else {
                detailedInfo.socialSecurityFinal = detailedInfo.socialSecurityTaxRate * (detailedInfo.socialSecurityIncome / 100);
              }
              if (detailedInfo.socialSecurityFinal !== 0) {
                detailedInfo.socialSecurityFinalCondition = 1;
              }
              //Social Security Tax Rate is completed
              //Medicae Tax rate
              if (typeof taxStatementCompleteData !== 'undefined' && taxStatementCompleteData !== '' && typeof taxStatementCompleteData[selectedYear] !== 'undefined' && taxStatementCompleteData[selectedYear] !== '') {
                detailedInfo.medicareTaxRate = taxStatementCompleteData[selectedYear]['taxes']['Medicare Tax (x Total Gross Income)'];
                detailedInfo.medicareIncome = taxStatementCompleteData[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'];
              }
              if (typeof incomeTaxesData !== 'undefined' && incomeTaxesData !== '' && typeof incomeTaxesData['Medicare Taxes'][selectedYear] !== 'undefined' && incomeTaxesData['Medicare Taxes'][selectedYear] !== '' && typeof incomeTaxesData['Medicare Taxes'][selectedYear]['Medicare Taxes'] !== 'undefined' && incomeTaxesData['Medicare Taxes'][selectedYear]['Medicare Taxes'] > 0) {
                detailedInfo.medicareTaxFinal = incomeTaxesData['Medicare Taxes'][selectedYear]['Medicare Taxes'];
                detailedInfo.medicareTaxFinalCondition = 1;
              }

              //Maximum Income limit
              if (typeof incomeTaxesData !== 'undefined' && incomeTaxesData !== '' && typeof incomeTaxesData['Medicare Taxes'][selectedYear] !== 'undefined' && incomeTaxesData['Medicare Taxes'][selectedYear] !== '' && typeof incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Threshold'] !== 'undefined' && incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Threshold'] !== '') {
                detailedInfo.medicareMaximumIncomeLimit = incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Threshold'];
              } else {
                detailedInfo.medicareMaximumIncomeLimit = 0;
              }
              if (typeof moduleServices.module10Data !== 'undefined' && moduleServices.module10Data.marriageYear !== '' && selectedYear >= moduleServices.module10Data.marriageYear) {
                detailedInfo.Status = 'Married';
              } else {
                detailedInfo.Status = 'Single';
              }

              //Additional Medicare Data
              if (typeof taxStatementCompleteData !== 'undefined' && taxStatementCompleteData !== '' && typeof taxStatementCompleteData[selectedYear] !== 'undefined' && taxStatementCompleteData[selectedYear] !== '' && typeof taxStatementCompleteData[selectedYear]['taxes']['Additional Medicare Tax (x Total Gross Income in excess of limits)'] !== 'undefined') {
                detailedInfo.additionalMedicareTaxRate = taxStatementCompleteData[selectedYear]['taxes']['Additional Medicare Tax (x Total Gross Income in excess of limits)'];
              } else {
                detailedInfo.additionalMedicareTaxRate = 0;
              }
              //Total Gross Income in excess of Medicare Limit
              if (typeof incomeTaxesData !== 'undefined' && incomeTaxesData !== '' &&
                typeof incomeTaxesData['Medicare Taxes'][selectedYear] !== 'undefined' &&
                incomeTaxesData['Medicare Taxes'][selectedYear] !== '' &&
                typeof incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Threshold'] !== 'undefined' &&
                incomeTaxesData['Medicare Taxes'][selectedYear]['Total Gross Income (excl Rental Income)'] > incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Threshold']) {
                detailedInfo.totalGrossIncomeInExcess = incomeTaxesData['Medicare Taxes'][selectedYear]['Total Gross Income (excl Rental Income)'] - incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Threshold'];
              } else {
                detailedInfo.totalGrossIncomeInExcess = 0;
              }
              //Final Medicare additional tax rate
              detailedInfo.finalMedicareAdditionalTaxRateCondition = 1;
              if (typeof incomeTaxesData !== 'undefined' && incomeTaxesData !== '' &&
                typeof incomeTaxesData['Medicare Taxes'][selectedYear] !== 'undefined' &&
                incomeTaxesData['Medicare Taxes'][selectedYear] !== '' &&
                typeof incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Taxes'] !== 'undefined' &&
                incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Taxes'] > 0) {
                detailedInfo.finalMedicareAdditionalTaxRate = incomeTaxesData['Medicare Taxes'][selectedYear]['Addn Medicare Taxes'];
                detailedInfo.finalMedicareAdditionalTaxRateCondition = 1;
              } else {
                detailedInfo.finalMedicareAdditionalTaxRate = 0;
              }


              //State and local tax rates
              if (typeof taxStatementCompleteData !== 'undefined' && taxStatementCompleteData !== '' && typeof taxStatementCompleteData[selectedYear] !== 'undefined' && taxStatementCompleteData[selectedYear] !== '') {
                detailedInfo.stateAndLocalTaxRate = taxStatementCompleteData[selectedYear]['taxes']['State & Local Taxes (x Adjusted Gross Income)'];
                detailedInfo.stateAndLocalIncome = taxStatementCompleteData[selectedYear]['Adjusted Gross Income'];
                detailedInfo.stateAndLocalTaxFinal = taxStatementCompleteData[selectedYear]['taxes']['State & Local Taxes (x Adjusted Gross Income)'] * (taxStatementCompleteData[selectedYear]['Adjusted Gross Income'] / 100);
                if (detailedInfo.stateAndLocalTaxFinal !== 0) {
                  detailedInfo.stateAndLocalTaxFinalCondition = 1;
                }
              }
              //Final Value
              if (typeof taxStatementCompleteData !== 'undefined' && taxStatementCompleteData !== '' && typeof taxStatementCompleteData[selectedYear] !== 'undefined' && taxStatementCompleteData[selectedYear] !== '') {
                detailedInfo.totalTaxesBeforeCreditsFinal = taxStatementCompleteData[selectedYear]['Total Taxes'];
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('total-taxes-before-credits');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;

            default:
              break;
          }
          break;
        /* End of Taxes */

        case 'Tax Credits':
          switch (selectedColumn) {
            case 'American Opportunity or Lifetime Learning':
              // let incomeStatementData = moduleServices.incomeStatementCompleteData();
              let taxStatementCompleteData = moduleServices.taxStatementCompleteData();
              let databaseData = moduleServices.databaseCompleteData();
              let creditValueTotal = 0;
              if (typeof taxStatementCompleteData !== 'undefined' && taxStatementCompleteData !== '' && typeof taxStatementCompleteData[selectedYear] !== 'undefined' && typeof taxStatementCompleteData[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== 'undefined') {
                detailedInfo.totalGrossIncomeValue = taxStatementCompleteData[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'];
              }
              else {
                detailedInfo.totalGrossIncomeValue = 0;
              }
              detailedInfo.tuitionAndFeesValueCondition = 0;
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' && typeof incomeStatementData[selectedYear] !== 'undefined' && typeof incomeStatementData[selectedYear]['livingExpenses']['Higher Education Tuition and Fees'] !== 'undefined' && incomeStatementData[selectedYear]['livingExpenses']['Higher Education Tuition and Fees'] !== 0) {
                detailedInfo.tuitionAndFeesValue = -incomeStatementData[selectedYear]['livingExpenses']['Higher Education Tuition and Fees'];
                detailedInfo.tuitionAndFeesValueCondition = 1;
              }
              else {
                detailedInfo.tuitionAndFeesValue = 0;
              }
              //First Expense Value
              if (detailedInfo.tuitionAndFeesValue > 2000) {
                detailedInfo.firstExpenses = 2000;
              }
              else {
                if (detailedInfo.tuitionAndFeesValue > 0) {
                  detailedInfo.firstExpenses = detailedInfo.tuitionAndFeesValue;
                }
                else {
                  detailedInfo.firstExpenses = 0;
                }
              }

              //Next Expenses Value
              if (detailedInfo.tuitionAndFeesValue > 4000) {
                detailedInfo.nextExpenses = (2000 / 100) * 25;
              }
              else {
                if (detailedInfo.tuitionAndFeesValue > 2000 && detailedInfo.tuitionAndFeesValue < 4000) {
                  detailedInfo.nextExpenses = ((detailedInfo.tuitionAndFeesValue - 2000) / 100) * 25;
                }
                else {
                  detailedInfo.nextExpenses = 0;
                }
              }
              //Previous Credits Taken Value
              if (typeof databaseData !== 'undefined' && typeof databaseData['American Opportunity'] !== 'undefined' && typeof databaseData['American Opportunity'] !== 'undefined' && typeof databaseData['American Opportunity'][selectedYear]['Count'] !== 'undefined') {
                detailedInfo.previousCreditsTakenValue = databaseData['American Opportunity'][selectedYear]['Count'];
              }
              //College Qualification
              if (typeof databaseData !== 'undefined' && typeof databaseData['American Opportunity'] !== 'undefined' && typeof databaseData['American Opportunity'] !== 'undefined' && typeof databaseData['American Opportunity'][selectedYear]['College'] !== 'undefined' && databaseData['American Opportunity'][selectedYear]['College'] === 0) {
                detailedInfo.collegeQualify = 'Do Not Qualify';
              }
              else {
                detailedInfo.collegeQualify = 'Qualify';
              }
              //Independent / Dependent Qualification Value
              if (typeof databaseData !== 'undefined' && typeof databaseData['American Opportunity'] !== 'undefined' && typeof databaseData['American Opportunity'] !== 'undefined' && typeof databaseData['American Opportunity'][selectedYear]['Independent'] !== 'undefined' && databaseData['American Opportunity'][selectedYear]['Independent'] === 0) {
                detailedInfo.independentQualify = 'Do Not Qualify';
              }
              else {
                detailedInfo.independentQualify = 'Qualify';
              }
              //Must satisfy all of the value
              if (detailedInfo.independentQualify === 'Do Not Qualify' || detailedInfo.collegeQualify === 'Do Not Qualify' || detailedInfo.previousCreditsTakenValue === 4) {
                detailedInfo.mustSatisfyValue = 'Do Not Qualify';
              }
              else {
                detailedInfo.mustSatisfyValue = 'Qualify';
              }
              //Marriage Status
              /*if(typeof moduleServices.module10Data !== 'undefined' && selectedYear < moduleServices.module10Data.marriageYear) {
                  detailedInfo.Status          = 'Single';
                  detailedInfo.incomeRange1 = 0;
                  detailedInfo.incomeRange2 = 80000;
                  detailedInfo.incomeRange3 = 80001;
                  detailedInfo.incomeRange4 = 90000;
              } else {
                  detailedInfo.Status          = 'Married';
                  detailedInfo.incomeRange1 = 0;
                  detailedInfo.incomeRange2 = 160000;
                  detailedInfo.incomeRange3 = 160001;
                  detailedInfo.incomeRange4 = 180000;
              }*/
              if (typeof moduleServices.module10Data !== 'undefined' && moduleServices.module10Data.marriageYear !== '' && selectedYear >= moduleServices.module10Data.marriageYear) {
                detailedInfo.Status = 'Married';
                detailedInfo.incomeRange1 = 0;
                if (typeof databaseData !== 'undefined' && typeof databaseData['AOTC Tax Credit Phaseout'][selectedYear] !== 'undefined' && typeof databaseData['AOTC Tax Credit Phaseout'][selectedYear] !== 'undefined') {
                  detailedInfo.incomeRange2 = databaseData['AOTC Tax Credit Phaseout'][selectedYear]['Married'];
                } else {
                  detailedInfo.incomeRange2 = 0;
                }
                detailedInfo.incomeRange3 = detailedInfo.incomeRange2;
                detailedInfo.incomeRange1 = 0;
                if (typeof databaseData !== 'undefined' && typeof databaseData['AOTC Tax Credit Phaseout'][selectedYear] !== 'undefined' && typeof databaseData['AOTC Tax Credit Phaseout'][selectedYear]['Married2'] !== 'undefined') {
                  detailedInfo.incomeRange4 = databaseData['AOTC Tax Credit Phaseout'][selectedYear]['Married2'];
                } else {
                  detailedInfo.incomeRange4 = 0;
                }
              } else {
                detailedInfo.Status = 'Single';
                detailedInfo.incomeRange1 = 0;
                if (typeof databaseData !== 'undefined' && typeof databaseData['AOTC Tax Credit Phaseout'][selectedYear] !== 'undefined' && typeof databaseData['AOTC Tax Credit Phaseout'][selectedYear]['Single'] !== 'undefined') {
                  detailedInfo.incomeRange2 = databaseData['AOTC Tax Credit Phaseout'][selectedYear]['Single'];
                } else {
                  detailedInfo.incomeRange2 = 0;
                }
                detailedInfo.incomeRange3 = detailedInfo.incomeRange2;
                if (typeof databaseData !== 'undefined' && typeof databaseData['AOTC Tax Credit Phaseout'][selectedYear] !== 'undefined' && typeof databaseData['AOTC Tax Credit Phaseout'][selectedYear]['Single2'] !== 'undefined') {
                  detailedInfo.incomeRange4 = databaseData['AOTC Tax Credit Phaseout'][selectedYear]['Single2'];
                } else {
                  detailedInfo.incomeRange4 = 0;
                }
              }
              //Credit1 Value
              if (detailedInfo.totalGrossIncomeValue <= detailedInfo.incomeRange2) {
                detailedInfo.creditValue1 = detailedInfo.firstExpenses + detailedInfo.nextExpenses;
                detailedInfo.creditValueRange1 = 1;
                creditValueTotal = detailedInfo.creditValue1;
              }
              else {
                detailedInfo.creditValue1 = '';
                detailedInfo.creditValueRange1 = 0;
              }
              //Difference Values
              if (detailedInfo.totalGrossIncomeValue >= detailedInfo.incomeRange3 && detailedInfo.totalGrossIncomeValue <= detailedInfo.incomeRange4) {
                detailedInfo.differenceValue1 = detailedInfo.totalGrossIncomeValue - detailedInfo.incomeRange2;
                detailedInfo.differenceValueRange1 = 1;
              }
              else {
                detailedInfo.differenceValue1 = '';
                detailedInfo.differenceValueRange1 = 0;
              }
              //Divisor Values
              if (detailedInfo.totalGrossIncomeValue >= detailedInfo.incomeRange3 && detailedInfo.totalGrossIncomeValue <= detailedInfo.incomeRange4) {
                detailedInfo.divisorValue1 = detailedInfo.incomeRange4 - detailedInfo.incomeRange2;
                detailedInfo.divisorValueRange1 = 1;
              }
              else {
                detailedInfo.divisorValue1 = '';
                detailedInfo.divisorValueRange1 = 0;
              }
              //Reducation Factor Value
              if (detailedInfo.totalGrossIncomeValue >= detailedInfo.incomeRange3 && detailedInfo.totalGrossIncomeValue <= detailedInfo.incomeRange4) {
                detailedInfo.reducationFactorValue1 = (detailedInfo.differenceValue1 / detailedInfo.divisorValue1) * 100;
                detailedInfo.reducationFactorRange1 = 1;
              }
              else {
                detailedInfo.reducationFactorValue1 = '';
                detailedInfo.reducationFactorRange1 = 0;
              }
              //Less Value
              if (detailedInfo.totalGrossIncomeValue >= detailedInfo.incomeRange3 && detailedInfo.totalGrossIncomeValue <= detailedInfo.incomeRange4) {
                detailedInfo.lessValue1 = -(detailedInfo.reducationFactorValue1 / 100) * (detailedInfo.firstExpenses + detailedInfo.nextExpenses);
                detailedInfo.lessRange1 = 1;
              }
              else {
                detailedInfo.lessValue1 = '';
                detailedInfo.lessRange1 = 0;
              }
              //Second Row Credit Value
              if (detailedInfo.totalGrossIncomeValue >= detailedInfo.incomeRange3 && detailedInfo.totalGrossIncomeValue <= detailedInfo.incomeRange4) {
                detailedInfo.creditValue2 = (detailedInfo.firstExpenses + detailedInfo.nextExpenses) + detailedInfo.lessValue1;
                detailedInfo.creditValueRange2 = 1;
                creditValueTotal = creditValueTotal + detailedInfo.creditValue2;
              }
              else {
                detailedInfo.creditValue2 = '';
                detailedInfo.creditValueRange2 = 0;
              }
              if (detailedInfo.totalGrossIncomeValue > detailedInfo.incomeRange4) {
                detailedInfo.creditValue3 = 0;
                detailedInfo.creditValueRange3 = 1;
                creditValueTotal = creditValueTotal + detailedInfo.creditValue3;
              }
              else {
                detailedInfo.creditValue3 = '';
                detailedInfo.creditValueRange3 = 0;
              }
              //Available Tax Credit after phaseouts
              detailedInfo.availableTaxCreditAfterPhaseout = creditValueTotal;

              //Get federal Taxble Income Value
              if (typeof taxStatementCompleteData !== 'undefined' && taxStatementCompleteData !== '' && typeof taxStatementCompleteData[selectedYear] !== 'undefined' && typeof taxStatementCompleteData[selectedYear]['Total Taxes'] !== 'undefined') {
                detailedInfo.federalTaxableIncomeValue = -taxStatementCompleteData[selectedYear]['Total Taxes'];
              }
              else {
                detailedInfo.federalTaxableIncomeValue = 0;
              }
              //Final Value
              if (typeof databaseData !== 'undefined' && databaseData !== '' && typeof databaseData['American Opportunity'][selectedYear] !== 'undefined' && databaseData['American Opportunity'][selectedYear] !== '' && typeof databaseData['American Opportunity'][selectedYear]['Tax Credit Applied'] !== 'undefined') {
                detailedInfo.finalAOTCValue = databaseData['American Opportunity'][selectedYear]['Tax Credit Applied'];
              }
              else {
                detailedInfo.finalAOTCValue = 0;
              }
              // For Lifetime learning
              // let creditValueTotal1 = 0;
              if (typeof taxStatementCompleteData !== 'undefined' && taxStatementCompleteData !== '' && typeof taxStatementCompleteData[selectedYear] !== 'undefined' && typeof taxStatementCompleteData[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== 'undefined') {
                detailedInfo.totalGrossIncomeValue1 = taxStatementCompleteData[selectedYear]['Gross Income']['Total Gross Income (excluding Rental Income)'];
              }
              else {
                detailedInfo.totalGrossIncomeValue1 = 0;
              }
              detailedInfo.tuitionAndFeesValueCondition1 = 0;
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' && typeof incomeStatementData[selectedYear] !== 'undefined' && typeof incomeStatementData[selectedYear]['livingExpenses']['Higher Education Tuition and Fees'] !== 'undefined' && incomeStatementData[selectedYear]['livingExpenses']['Higher Education Tuition and Fees'] !== 0) {
                detailedInfo.tuitionAndFeesValue1 = -incomeStatementData[selectedYear]['livingExpenses']['Higher Education Tuition and Fees'];
                detailedInfo.tuitionAndFeesValueCondition1 = 1;
              }
              else {
                detailedInfo.tuitionAndFeesValue1 = 0;
              }
              //First Expense Value
              if (detailedInfo.tuitionAndFeesValue1 > 10000) {
                detailedInfo.firstExpenses1 = 2000;
              }
              else {
                if (detailedInfo.tuitionAndFeesValue1 > 0) {
                  detailedInfo.firstExpenses1 = (detailedInfo.tuitionAndFeesValue / 100) * 20;
                }
                else {
                  detailedInfo.firstExpenses1 = 0;
                }
              }

              //College Qualification
              if (typeof databaseData !== 'undefined' && typeof databaseData['Lifetime Learning'] !== 'undefined' && typeof databaseData['Lifetime Learning'][selectedYear]['American Opportunity'] !== 'undefined' && databaseData['American Opportunity'][selectedYear]['American Opportunity'] === 1) {
                detailedInfo.collegeQualify1 = 'Qualify';
              }
              else {
                detailedInfo.collegeQualify1 = 'Do Not Qualify';
              }
              //Independent / Dependent Qualification Value
              if (typeof databaseData !== 'undefined' && typeof databaseData['Lifetime Learning'] !== 'undefined' && typeof databaseData['Lifetime Learning'][selectedYear]['Independent'] !== 'undefined' && databaseData['Lifetime Learning'][selectedYear]['Independent'] === 0) {
                detailedInfo.independentQualify1 = 'Qualify';
              }
              else {
                detailedInfo.independentQualify1 = 'Do Not Qualify';
              }
              //Must satisfy all of the value
              if (detailedInfo.independentQualify1 === 'Do Not Qualify' || detailedInfo.collegeQualify1 === 'Do Not Qualify') {
                detailedInfo.mustSatisfyValue1 = 'Do Not Qualify';
              }
              else {
                detailedInfo.mustSatisfyValue1 = 'Qualify';
              }
              //Marriage Status
              /*if(typeof moduleServices.module10Data !== 'undefined' && selectedYear < moduleServices.module10Data.marriageYear) {
                  detailedInfo.Status1          = 'Single';
                  detailedInfo.incomeRange11 = 0;
                  detailedInfo.incomeRange21 = 55000;
                  detailedInfo.incomeRange31 = 55001;
                  detailedInfo.incomeRange41 = 65000;
              } else {
                  detailedInfo.Status1          = 'Married';
                  detailedInfo.incomeRange11 = 0;
                  detailedInfo.incomeRange21 = 111000;
                  detailedInfo.incomeRange31 = 111001;
                  detailedInfo.incomeRange41 = 131000;
              }*/
              if (typeof moduleServices.module10Data !== 'undefined' && moduleServices.module10Data.marriageYear !== '' && selectedYear >= moduleServices.module10Data.marriageYear) {
                detailedInfo.Status1 = 'Married';
                detailedInfo.incomeRange11 = 0;
                if (typeof databaseData !== 'undefined' && typeof databaseData['Lifetime Learning Credit'] !== 'undefined' && typeof databaseData['Lifetime Learning Credit'][selectedYear]['Married'] !== 'undefined') {
                  detailedInfo.incomeRange21 = databaseData['Lifetime Learning Credit'][selectedYear]['Married'];
                } else {
                  detailedInfo.incomeRange21 = 0;
                }
                detailedInfo.incomeRange31 = detailedInfo.incomeRange21;
                detailedInfo.incomeRange11 = 0;
                if (typeof databaseData !== 'undefined' && typeof databaseData['Lifetime Learning Credit'] !== 'undefined' && typeof databaseData['Lifetime Learning Credit'][selectedYear]['Married2'] !== 'undefined') {
                  detailedInfo.incomeRange41 = databaseData['Lifetime Learning Credit'][selectedYear]['Married2'];
                } else {
                  detailedInfo.incomeRange41 = 0;
                }
              } else {
                detailedInfo.Status1 = 'Single';
                detailedInfo.incomeRange11 = 0;
                if (typeof databaseData !== 'undefined' && typeof databaseData['Lifetime Learning Credit'] !== 'undefined' && typeof databaseData['Lifetime Learning Credit'][selectedYear]['Single'] !== 'undefined') {
                  detailedInfo.incomeRange21 = databaseData['Lifetime Learning Credit'][selectedYear]['Single'];
                } else {
                  detailedInfo.incomeRange21 = 0;
                }
                detailedInfo.incomeRange31 = detailedInfo.incomeRange21;
                if (typeof databaseData !== 'undefined' && typeof databaseData['Lifetime Learning Credit'] !== 'undefined' && typeof databaseData['Lifetime Learning Credit'][selectedYear]['Single2'] !== 'undefined') {
                  detailedInfo.incomeRange41 = databaseData['Lifetime Learning Credit'][selectedYear]['Single2'];
                } else {
                  detailedInfo.incomeRange41 = 0;
                }
              }

              //Credit1 Value
              if (detailedInfo.totalGrossIncomeValue1 <= detailedInfo.incomeRange21) {
                detailedInfo.creditValue11 = detailedInfo.firstExpenses1;
                detailedInfo.creditValueRange11 = 1;
                // creditValueTotal1 = detailedInfo.creditValue11;
              }
              else {
                detailedInfo.creditValue11 = '';
                detailedInfo.creditValueRange11 = 0;
              }
              //Difference Values
              if (detailedInfo.totalGrossIncomeValue1 >= detailedInfo.incomeRange31 && detailedInfo.totalGrossIncomeValue1 <= detailedInfo.incomeRange41) {
                detailedInfo.differenceValue11 = detailedInfo.totalGrossIncomeValue1 - detailedInfo.incomeRange21;
                detailedInfo.differenceValueRange11 = 1;
              }
              else {
                detailedInfo.differenceValue11 = '';
                detailedInfo.differenceValueRange11 = 0;
              }
              //Divisor Values
              if (detailedInfo.totalGrossIncomeValue1 >= detailedInfo.incomeRange31 && detailedInfo.totalGrossIncomeValue1 <= detailedInfo.incomeRange41) {
                detailedInfo.divisorValue11 = detailedInfo.incomeRange41 - detailedInfo.incomeRange21;
                detailedInfo.divisorValueRange11 = 1;
              }
              else {
                detailedInfo.divisorValue11 = '';
                detailedInfo.divisorValueRange11 = 0;
              }
              //Reducation Factor Value
              if (detailedInfo.totalGrossIncomeValue1 >= detailedInfo.incomeRange31 && detailedInfo.totalGrossIncomeValue1 <= detailedInfo.incomeRange41) {
                detailedInfo.reducationFactorValue11 = (detailedInfo.differenceValue11 / detailedInfo.divisorValue11) * 100;
                detailedInfo.reducationFactorRange11 = 1;
              }
              else {
                detailedInfo.reducationFactorValue11 = '';
                detailedInfo.reducationFactorRange11 = 0;
              }
              //Less Value
              if (detailedInfo.totalGrossIncomeValue1 >= detailedInfo.incomeRange31 && detailedInfo.totalGrossIncomeValue1 <= detailedInfo.incomeRange41) {
                detailedInfo.lessValue11 = -(detailedInfo.reducationFactorValue11 / 100) * (detailedInfo.firstExpenses1 + detailedInfo.nextExpenses1);
                detailedInfo.lessRange11 = 1;
              }
              else {
                detailedInfo.lessValue11 = '';
                detailedInfo.lessRange11 = 0;
              }
              //Second Row Credit Value
              if (detailedInfo.totalGrossIncomeValue1 >= detailedInfo.incomeRange31 && detailedInfo.totalGrossIncomeValue1 <= detailedInfo.incomeRange41) {
                detailedInfo.creditValue21 = (detailedInfo.firstExpenses1 + detailedInfo.nextExpenses1) + detailedInfo.lessValue11;
                detailedInfo.creditValueRange21 = 1;
                // creditValueTotal1 = creditValueTotal1 + detailedInfo.creditValue21;
              }
              else {
                detailedInfo.creditValue21 = '';
                detailedInfo.creditValueRange21 = 0;
              }
              if (detailedInfo.totalGrossIncomeValue1 > detailedInfo.incomeRange41) {
                detailedInfo.creditValue31 = 0;
                detailedInfo.creditValueRange31 = 1;
                // creditValueTotal1 = creditValueTotal1 + detailedInfo.creditValue31;
              }
              else {
                detailedInfo.creditValue31 = '';
                detailedInfo.creditValueRange31 = 0;
              }
              //Available Tax Credit after phaseouts
              if (typeof databaseData !== 'undefined' && typeof databaseData['American Opportunity'] !== 'undefined' && typeof databaseData['American Opportunity'][selectedYear]['Federal Taxable Income'] !== 'undefined') {
                detailedInfo.availableTaxCreditAfterPhaseout1 = databaseData['American Opportunity'][selectedYear]['Federal Taxable Income'];
              }
              else {
                detailedInfo.availableTaxCreditAfterPhaseout1 = 0;
              }

              //detailedInfo.availableTaxCreditAfterPhaseout1 = creditValueTotal1;
              //Final Value
              if (typeof databaseData !== 'undefined' && typeof databaseData['Lifetime Learning'] !== 'undefined' && typeof databaseData['Lifetime Learning'][selectedYear]['Tax Credit Applied'] !== 'undefined') {
                detailedInfo.finalAOTCValue1 = databaseData['Lifetime Learning'][selectedYear]['Tax Credit Applied'];
              }
              else {
                detailedInfo.finalAOTCValue1 = 0;
              }

              // setOpenTaxCreditsDetailPopup( true );
              setOpenTaxCreditsDetailModel('american-opportunity');
              setOpenDialog(true);
              setOpenDialogName('taxCreditsDetailPopup');
              break;
            case 'Child Tax Credit':
              // Child Tax Credit Portion
              //Number of Children
              //let taxStatementCompleteData = moduleServices.taxStatementCompleteData();
              //let databaseData = moduleServices.databaseCompleteData();
              if (typeof databaseData !== 'undefined' && typeof databaseData['Child Tax Credit'] !== 'undefined' && typeof databaseData['Child Tax Credit'][selectedYear]['Child Tax Credit']['Children'] !== 'undefined') {
                detailedInfo.numberOfChildren = databaseData['Child Tax Credit'][selectedYear]['Child Tax Credit']['Children'];
              }
              else {
                detailedInfo.numberOfChildren = 0;
              }
              //Credit Per Child
              if (typeof databaseData !== 'undefined' && typeof databaseData['Child Tax Credit'] !== 'undefined' && typeof databaseData['Child Tax Credit']['Credit per Child'] !== 'undefined') {
                detailedInfo.creditPerChild = databaseData['Child Tax Credit']['Credit per Child'];
              }
              else {
                detailedInfo.creditPerChild = 0;
              }
              if (typeof databaseData !== 'undefined' && typeof databaseData['Child Tax Credit'] !== 'undefined' && typeof databaseData['Child Tax Credit'][selectedYear]['Child Tax Credit']['Credit before Phaseouts'] !== 'undefined') {
                detailedInfo.availableTaxCredit = databaseData['Child Tax Credit'][selectedYear]['Child Tax Credit']['Credit before Phaseouts'];
              }
              else {
                detailedInfo.availableTaxCredit = 0;
              }
              //Total Gross Income (excluding Net Rental Income)
              if (typeof databaseData !== 'undefined' && typeof databaseData['Child Tax Credit'] !== 'undefined' && typeof databaseData['Child Tax Credit'][selectedYear]['Child Tax Credit']['Total Gross Income'] !== 'undefined') {
                detailedInfo.childTotalGrossIncome = databaseData['Child Tax Credit'][selectedYear]['Child Tax Credit']['Total Gross Income'];
              }
              else {
                detailedInfo.childTotalGrossIncome = 0;
              }
              //Marriage Status
              detailedInfo.crow1 = 0;
              detailedInfo.crow2 = 0;
              detailedInfo.crow3 = 0;
              if (typeof moduleServices.module10Data !== 'undefined' && moduleServices.module10Data.marriageYear !== '' && selectedYear >= moduleServices.module10Data.marriageYear) {
                detailedInfo.Status = 'Married';
                detailedInfo.incomeRange1 = 0;
                if (typeof databaseData !== 'undefined' && typeof databaseData['Child Tax Credit'] !== 'undefined' && typeof databaseData['Child Tax Credit']['Married Phaseout Ranges1'] !== 'undefined') {
                  detailedInfo.incomeRange2 = databaseData['Child Tax Credit']['Married Phaseout Ranges1'];
                } else {
                  detailedInfo.incomeRange2 = 0;
                }
                detailedInfo.incomeRange3 = detailedInfo.incomeRange2;
                detailedInfo.incomeRange1 = 0;
                if (typeof databaseData !== 'undefined' && typeof databaseData['Child Tax Credit'] !== 'undefined' && typeof databaseData['Child Tax Credit']['Married Phaseout Ranges2'] !== 'undefined') {
                  detailedInfo.incomeRange4 = databaseData['Child Tax Credit']['Married Phaseout Ranges2'];
                } else {
                  detailedInfo.incomeRange4 = 0;
                }
              } else {
                detailedInfo.Status = 'Single';
                detailedInfo.incomeRange1 = 0;
                if (typeof databaseData !== 'undefined' && typeof databaseData['Child Tax Credit'] !== 'undefined' && typeof databaseData['Child Tax Credit']['Single Phaseout Ranges1'] !== 'undefined') {
                  detailedInfo.incomeRange2 = databaseData['Child Tax Credit']['Single Phaseout Ranges1'];
                } else {
                  detailedInfo.incomeRange2 = 0;
                }
                detailedInfo.incomeRange3 = detailedInfo.incomeRange2;
                if (typeof databaseData !== 'undefined' && typeof databaseData['Child Tax Credit'] !== 'undefined' && typeof databaseData['Child Tax Credit']['Single Phaseout Ranges2'] !== 'undefined') {
                  detailedInfo.incomeRange4 = databaseData['Child Tax Credit']['Single Phaseout Ranges2'];
                } else {
                  detailedInfo.incomeRange4 = 0;
                }
              }
              //First Phaseout Value
              if (detailedInfo.childTotalGrossIncome <= detailedInfo.incomeRange2) {
                detailedInfo.firstPhaseout1 = detailedInfo.availableTaxCredit;
                detailedInfo.crow1 = 1;
              } else {
                detailedInfo.firstPhaseout1 = '';
                detailedInfo.crow1 = 0;
              }
              //First Difference Value
              if (detailedInfo.childTotalGrossIncome >= detailedInfo.incomeRange3 && detailedInfo.childTotalGrossIncome <= detailedInfo.incomeRange4) {
                let coefd1 = Math.pow(10, -3);
                let childPhaseoutReduction2 = -(Math.floor((detailedInfo.childTotalGrossIncome - detailedInfo.incomeRange3) * coefd1)) / coefd1;
                detailedInfo.firstDifference1 = childPhaseoutReduction2;
                detailedInfo.firstDivisor1 = 1000;
                detailedInfo.firstFactor1 = childPhaseoutReduction2 / detailedInfo.firstDivisor1;
                detailedInfo.firstMultiple1 = 50;
                detailedInfo.firstPhaseout2 = detailedInfo.firstFactor1 * detailedInfo.firstMultiple1;
                detailedInfo.crow2 = 1;
              } else {
                detailedInfo.crow2 = 0;
                detailedInfo.firstDifference1 = '';
                detailedInfo.firstDivisor1 = '';
                detailedInfo.firstFactor1 = '';
                detailedInfo.firstMultiple1 = '';
                detailedInfo.firstPhaseout2 = '';
              }
              //Last PhaseOut Value
              if (detailedInfo.childTotalGrossIncome > detailedInfo.incomeRange4) {
                if (detailedInfo.availableTaxCredit > 0) {
                  detailedInfo.firstPhaseout3 = -detailedInfo.creditPerChild;
                  detailedInfo.crow3 = 1;
                } else {
                  detailedInfo.firstPhaseout3 = '';
                  detailedInfo.crow3 = 0;
                }
              }
              //Total Phaseout Reduction Value
              if (detailedInfo.childTotalGrossIncome <= detailedInfo.incomeRange4) {
                detailedInfo.totalPhaseOutReduction = 0;
              } else {
                if (detailedInfo.childTotalGrossIncome >= detailedInfo.incomeRange3 && detailedInfo.childTotalGrossIncome <= detailedInfo.incomeRange4) {
                  if (detailedInfo.availableTaxCredit > 0) {
                    detailedInfo.totalPhaseOutReduction = detailedInfo.firstPhaseout2 * detailedInfo.numberOfChildren;
                  } else {
                    detailedInfo.totalPhaseOutReduction = 0;
                  }
                } else {
                  if (detailedInfo.availableTaxCredit > 0) {
                    detailedInfo.totalPhaseOutReduction = detailedInfo.firstPhaseout3 * detailedInfo.numberOfChildren;
                  } else {
                    detailedInfo.totalPhaseOutReduction = 0;
                  }
                }
              }
              if (typeof taxStatementCompleteData !== 'undefined' && taxStatementCompleteData !== '' && typeof taxStatementCompleteData[selectedYear] !== 'undefined' && typeof taxStatementCompleteData[selectedYear]['Total Taxes'] !== 'undefined') {
                detailedInfo.childTotalTaxes = taxStatementCompleteData[selectedYear]['Total Taxes'];
              }
              else {
                detailedInfo.childTotalTaxes = 0;
              }

              if (typeof databaseData !== 'undefined' && typeof databaseData['Child Tax Credit'] !== 'undefined' && typeof databaseData['Child Tax Credit'][selectedYear]['Child Tax Credit']['Tax Credit Applied before Refund'] !== 'undefined') {
                detailedInfo.taxCreditAppliedBeforeRefund = databaseData['Child Tax Credit'][selectedYear]['Child Tax Credit']['Tax Credit Applied before Refund'];
              }
              else {
                detailedInfo.taxCreditAppliedBeforeRefund = 0;
              }

              if (typeof databaseData !== 'undefined' && typeof databaseData['Child Tax Credit'] !== 'undefined' && typeof databaseData['Child Tax Credit'][selectedYear]['Child Tax Credit']['Tax Credit Applied as Refund'] !== 'undefined') {
                detailedInfo.taxCreditAppliedAsRefund = databaseData['Child Tax Credit'][selectedYear]['Child Tax Credit']['Tax Credit Applied as Refund'];
              }
              else {
                detailedInfo.taxCreditAppliedAsRefund = 0;
              }
              if (typeof databaseData !== 'undefined' && typeof databaseData['Child Tax Credit'] !== 'undefined' && typeof databaseData['Child Tax Credit'][selectedYear]['Child Tax Credit']['Maximum Refund Values'] !== 'undefined') {
                detailedInfo.taxCreditAppliedAsRefundLabel = databaseData['Child Tax Credit'][selectedYear]['Child Tax Credit']['Maximum Refund Values'];
              }
              else {
                detailedInfo.taxCreditAppliedAsRefundLabel = 0;
              }
              //To get the Label value

              //Final Value
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' && typeof incomeStatementData[selectedYear] !== 'undefined' && typeof incomeStatementData[selectedYear]['Tax Credits']['Child Tax Credit'] !== 'undefined') {
                detailedInfo.finalTaxCreditValue = incomeStatementData[selectedYear]['Tax Credits']['Child Tax Credit'];
              }
              else {
                detailedInfo.finalTaxCreditValue = 0;
              }

              // setOpenChildTaxCreditPopup( true );
              setOpenDialog(true);
              setOpenDialogName('childTaxCreditPopup');
              break;

            case 'Lifetime Learning':
              //let incomeStatementData = moduleServices.incomeStatementCompleteData();
              //let databaseData = moduleServices.databaseCompleteData();
              //let creditValueTotal = 0;
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' && typeof incomeStatementData[selectedYear] !== 'undefined' && typeof incomeStatementData[selectedYear]['Total Income'] !== 'undefined') {
                detailedInfo.totalGrossIncomeValue = incomeStatementData[selectedYear]['Total Income'];
              }
              else {
                detailedInfo.totalGrossIncomeValue = 0;
              }
              detailedInfo.tuitionAndFeesValueCondition = 0;
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' && typeof incomeStatementData[selectedYear] !== 'undefined' && typeof incomeStatementData[selectedYear]['livingExpenses']['Higher Education Tuition and Fees'] !== 'undefined' && incomeStatementData[selectedYear]['livingExpenses']['Higher Education Tuition and Fees'] !== 0) {
                detailedInfo.tuitionAndFeesValue = -incomeStatementData[selectedYear]['livingExpenses']['Higher Education Tuition and Fees'];
                detailedInfo.tuitionAndFeesValueCondition = 1;
              }
              else {
                detailedInfo.tuitionAndFeesValue = 0;
              }
              //First Expense Value
              if (detailedInfo.tuitionAndFeesValue > 10000) {
                detailedInfo.firstExpenses = 2000;
              }
              else {
                if (detailedInfo.tuitionAndFeesValue > 0) {
                  detailedInfo.firstExpenses = (detailedInfo.tuitionAndFeesValue / 100) * 20;
                }
                else {
                  detailedInfo.firstExpenses = 0;
                }
              }

              //College Qualification
              if (typeof databaseData !== 'undefined' && typeof databaseData['Lifetime Learning'] !== 'undefined' && typeof databaseData['Lifetime Learning'][selectedYear]['American Opportunity'] !== 'undefined' && databaseData['American Opportunity'][selectedYear]['American Opportunity'] === 0) {
                detailedInfo.collegeQualify = 'Do Not Qualify';
              }
              else {
                detailedInfo.collegeQualify = 'Qualify';
              }
              //Independent / Dependent Qualification Value
              if (typeof databaseData !== 'undefined' && typeof databaseData['Lifetime Learning'] !== 'undefined' && typeof databaseData['Lifetime Learning'][selectedYear]['Independent'] !== 'undefined' && databaseData['Lifetime Learning'][selectedYear]['Independent'] === 0) {
                detailedInfo.independentQualify = 'Do Not Qualify';
              }
              else {
                detailedInfo.independentQualify = 'Qualify';
              }
              //Must satisfy all of the value
              if (detailedInfo.independentQualify === 'Do Not Qualify' || detailedInfo.collegeQualify === 'Do Not Qualify') {
                detailedInfo.mustSatisfyValue = 'Do Not Qualify';
              }
              else {
                detailedInfo.mustSatisfyValue = 'Qualify';
              }
              //Marriage Status
              if (typeof moduleServices.module10Data !== 'undefined' && selectedYear < moduleServices.module10Data.marriageYear) {
                detailedInfo.Status = 'Single';
                detailedInfo.incomeRange1 = 0;
                detailedInfo.incomeRange2 = 55000;
                detailedInfo.incomeRange3 = 55001;
                detailedInfo.incomeRange4 = 65000;
              } else {
                detailedInfo.Status = 'Married';
                detailedInfo.incomeRange1 = 0;
                detailedInfo.incomeRange2 = 111000;
                detailedInfo.incomeRange3 = 111001;
                detailedInfo.incomeRange4 = 131000;
              }
              //Credit1 Value
              if (detailedInfo.totalGrossIncomeValue <= detailedInfo.incomeRange2) {
                detailedInfo.creditValue1 = detailedInfo.firstExpenses;
                detailedInfo.creditValueRange1 = 1;
                creditValueTotal = detailedInfo.creditValue1;
              }
              else {
                detailedInfo.creditValue1 = '';
                detailedInfo.creditValueRange1 = 0;
              }
              //Difference Values
              if (detailedInfo.totalGrossIncomeValue >= detailedInfo.incomeRange3 && detailedInfo.totalGrossIncomeValue <= detailedInfo.incomeRange4) {
                detailedInfo.differenceValue1 = detailedInfo.totalGrossIncomeValue - detailedInfo.incomeRange2;
                detailedInfo.differenceValueRange1 = 1;
              }
              else {
                detailedInfo.differenceValue1 = '';
                detailedInfo.differenceValueRange1 = 0;
              }
              //Divisor Values
              if (detailedInfo.totalGrossIncomeValue >= detailedInfo.incomeRange3 && detailedInfo.totalGrossIncomeValue <= detailedInfo.incomeRange4) {
                detailedInfo.divisorValue1 = detailedInfo.incomeRange4 - detailedInfo.incomeRange2;
                detailedInfo.divisorValueRange1 = 1;
              }
              else {
                detailedInfo.divisorValue1 = '';
                detailedInfo.divisorValueRange1 = 0;
              }
              //Reducation Factor Value
              if (detailedInfo.totalGrossIncomeValue >= detailedInfo.incomeRange3 && detailedInfo.totalGrossIncomeValue <= detailedInfo.incomeRange4) {
                detailedInfo.reducationFactorValue1 = (detailedInfo.differenceValue1 / detailedInfo.divisorValue1) * 100;
                detailedInfo.reducationFactorRange1 = 1;
              }
              else {
                detailedInfo.reducationFactorValue1 = '';
                detailedInfo.reducationFactorRange1 = 0;
              }
              //Less Value
              if (detailedInfo.totalGrossIncomeValue >= detailedInfo.incomeRange3 && detailedInfo.totalGrossIncomeValue <= detailedInfo.incomeRange4) {
                detailedInfo.lessValue1 = -(detailedInfo.reducationFactorValue1 / 100) * (detailedInfo.firstExpenses + detailedInfo.nextExpenses);
                detailedInfo.lessRange1 = 1;
              }
              else {
                detailedInfo.lessValue1 = '';
                detailedInfo.lessRange1 = 0;
              }
              //Second Row Credit Value
              if (detailedInfo.totalGrossIncomeValue >= detailedInfo.incomeRange3 && detailedInfo.totalGrossIncomeValue <= detailedInfo.incomeRange4) {
                detailedInfo.creditValue2 = (detailedInfo.firstExpenses + detailedInfo.nextExpenses) + detailedInfo.lessValue1;
                detailedInfo.creditValueRange2 = 1;
                creditValueTotal = creditValueTotal + detailedInfo.creditValue2;
              }
              else {
                detailedInfo.creditValue2 = '';
                detailedInfo.creditValueRange2 = 0;
              }
              if (detailedInfo.totalGrossIncomeValue > detailedInfo.incomeRange4) {
                detailedInfo.creditValue3 = 0;
                detailedInfo.creditValueRange3 = 1;
                creditValueTotal = creditValueTotal + detailedInfo.creditValue3;
              }
              else {
                detailedInfo.creditValue3 = '';
                detailedInfo.creditValueRange3 = 0;
              }
              //Available Tax Credit after phaseouts
              detailedInfo.availableTaxCreditAfterPhaseout = creditValueTotal;
              //Final Value
              if (typeof incomeStatementData !== 'undefined' && incomeStatementData !== '' && typeof incomeStatementData[selectedYear] !== 'undefined' && typeof incomeStatementData[selectedYear]['Tax Credits']['Lifetime Learning'] !== 'undefined') {
                detailedInfo.finalAOTCValue = incomeStatementData[selectedYear]['Tax Credits']['Lifetime Learning'];
              }
              else {
                detailedInfo.finalAOTCValue = 0;
              }

              // setOpenTaxCreditsDetailPopup( true );
              setOpenTaxCreditsDetailModel('lifetime-learning');
              setOpenDialog(true);
              setOpenDialogName('taxCreditsDetailPopup');

              break;
            default:
          }

          break;
        default:
          break;

        /* Investment Property Sale */

        case 'InvestmentPropertySale':
          switch (selectedColumn) {
            case 'Investment Property Sale':
              detailedInfo.condition = 0;
              if (incomeStatementData[selectedYear]['Investment Property Sale']['Gain'] !== 0) {
                detailedInfo.condition = 1;
                detailedInfo.sellingPrice = moduleServices.moreREISellingPrice();
                detailedInfo.sellingCosts = moduleServices.moreREISellingCost();
                detailedInfo.netSellingPrice = moduleServices.moreREINetSellingPrice();
                detailedInfo.purchasePrice = moduleServices.moreREIPurchasePrice();
                moduleServices.realEstateClosingCosts();
                detailedInfo.closingCosts = moduleServices.moreREIClosingCost();
                detailedInfo.gainOnSale = moduleServices.moreREIGain();
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('investment-propertysale');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Total Tax on  Real Estate Sales':
              let taxStatementCompleteData = moduleServices.taxStatementCompleteData();
              detailedInfo.condition = 0;
              detailedInfo.conditionGain = 0;

              if (incomeStatementData[selectedYear]['Investment Property Sale']['Tax on Sale'] !== 0) {
                detailedInfo.condition = 1;
                detailedInfo.gainOnSale = moduleServices.moreREIGain();
                detailedInfo.taxOnGainOnSale = moduleServices.moreREITaxCapital();
                detailedInfo.accumulatedDepreciation = moduleServices.moreREITaxAccumulatedValue();
                detailedInfo.higherEduStudentLoan = moduleServices.moreREITaxAccumulated();
              }
              if (typeof moduleServices.module18Data.existingHomeSaleYear !== 'undefined' && moduleServices.module18Data.existingHomeSaleYear !== '' && selectedYear === moduleServices.module18Data.existingHomeSaleYear && typeof moduleServices.module18Data.netGainOnSale !== 'undefined') {
                detailedInfo.conditionGain = 1;
                detailedInfo.totalTaxExistingHomeSale = -moduleServices.module18Data.netGainOnSale;
              } else {
                detailedInfo.totalTaxExistingHomeSale = 0;
              }
              if (typeof moduleServices.module18Data.gainOnSale !== 'undefined') {
                detailedInfo.gainOnSaleExistingHome = -moduleServices.module18Data.gainOnSale;
              }
              if (typeof moduleServices.module18Data.taxableGainUp !== 'undefined') {
                detailedInfo.taxOnTaxbleGainUp = -moduleServices.module18Data.taxableGainUp;
              } else {
                detailedInfo.taxOnTaxbleGainUp = 0;
              }
              if (typeof moduleServices.module18Data.taxOnGain !== 'undefined') {
                detailedInfo.taxOnTaxbleGain = -moduleServices.module18Data.taxOnGain;
              } else {
                detailedInfo.taxOnTaxbleGain = 0;
              }
              if (typeof taxStatementCompleteData !== 'undefined' && taxStatementCompleteData !== '' && typeof taxStatementCompleteData[selectedYear]['Investment Property Sale'] !== 'undefined' && typeof taxStatementCompleteData[selectedYear]['Total Investment Property Sale'] !== 'undefined') {
                detailedInfo.finalTotalTaxOnHomeSale = taxStatementCompleteData[selectedYear]['Total Investment Property Sale'];
              } else {
                detailedInfo.finalTotalTaxOnHomeSale = 0;
              }

              // setTaxOnSaleDetailPopup( true );
              setOpenDialog(true);
              setOpenDialogName('taxOnSaleDetailPopup');
              break;
            case 'Gain':
              if (typeof moduleServices.module14Data.yearOfSale !== 'undefined' && moduleServices.module14Data.yearOfSale !== 'undefined') {
                detailedInfo.propertySaleYear = moduleServices.module14Data.yearOfSale;
              } else {
                detailedInfo.propertySaleYear = 0;
              }

              if (typeof moduleServices.module14Data.yearOfSale !== 'undefined' && moduleServices.module14Data.yearOfSale === selectedYear && typeof moduleServices.module14Data.moreREISellingPrice !== 'undefined' && moduleServices.module14Data.moreREISellingPrice !== '') {
                detailedInfo.gainSelleingPrice = moduleServices.module14Data.moreREISellingPrice;
              } else {
                detailedInfo.gainSelleingPrice = 0;
              }

              if (typeof moduleServices.module14Data.yearOfSale !== 'undefined' && moduleServices.module14Data.yearOfSale === selectedYear && typeof moduleServices.module14Data.moreREISellingCost !== 'undefined' && moduleServices.module14Data.moreREISellingCost !== '') {
                detailedInfo.gainSelleingCost = moduleServices.module14Data.moreREISellingCost;
              } else {
                detailedInfo.gainSelleingCost = 0;
              }

              if (typeof moduleServices.module14Data.yearOfSale !== 'undefined' && moduleServices.module14Data.yearOfSale === selectedYear && typeof moduleServices.module14Data.moreREINetSellingPrice !== 'undefined' && moduleServices.module14Data.moreREINetSellingPrice !== '') {
                detailedInfo.gainNetSelleingPrice = moduleServices.module14Data.moreREINetSellingPrice;
              } else {
                detailedInfo.gainNetSelleingPrice = 0;
              }

              if (typeof moduleServices.module14Data.yearOfSale !== 'undefined' && moduleServices.module14Data.yearOfSale === selectedYear && typeof moduleServices.module14Data.moreREIPurchasePrice !== 'undefined' && moduleServices.module14Data.moreREIPurchasePrice !== '') {
                detailedInfo.gainPurchasePrice = moduleServices.module14Data.moreREIPurchasePrice;
              } else {
                detailedInfo.gainPurchasePrice = 0;
              }

              if (typeof moduleServices.module14Data.yearOfSale !== 'undefined' && moduleServices.module14Data.yearOfSale === selectedYear && typeof moduleServices.module14Data.moreREIClosingCost !== 'undefined' && moduleServices.module14Data.moreREIClosingCost !== '') {
                detailedInfo.gainClosingFitOutCosts = moduleServices.module14Data.moreREIClosingCost;
              } else {
                detailedInfo.gainClosingFitOutCosts = 0;
              }

              if (typeof moduleServices.module14Data.yearOfSale !== 'undefined' && moduleServices.module14Data.yearOfSale === selectedYear && typeof moduleServices.module14Data.moreREIGain !== 'undefined' && moduleServices.module14Data.moreREIGain !== '') {
                detailedInfo.gainLossOnSale = moduleServices.module14Data.moreREIGain;
              } else {
                detailedInfo.gainLossOnSale = 0;
              }

              //Existing Home Sale Values
              if (typeof moduleServices.module18Data.existingHomeSaleYear !== 'undefined' && moduleServices.module18Data.existingHomeSaleYear !== 'undefined') {
                detailedInfo.existingHomeYear = moduleServices.module18Data.existingHomeSaleYear;
              } else {
                detailedInfo.existingHomeYear = 0;
              }
              if (typeof moduleServices.module18Data.existingHomeSaleYear !== 'undefined' && moduleServices.module18Data.existingHomeSaleYear === selectedYear && typeof moduleServices.module18Data.existingHomeSaleSellingPrice !== 'undefined' && moduleServices.module18Data.existingHomeSaleSellingPrice !== '') {
                detailedInfo.existingHomeSelleingPrice = moduleServices.module18Data.existingHomeSaleSellingPrice;
              } else {
                detailedInfo.existingHomeSelleingPrice = 0;
              }
              if (typeof moduleServices.module18Data.existingHomeSaleYear !== 'undefined' && moduleServices.module18Data.existingHomeSaleYear === selectedYear && typeof moduleServices.module18Data.sellingCosts !== 'undefined' && moduleServices.module18Data.sellingCosts !== '') {
                detailedInfo.existingHomeSelleingCost = moduleServices.module18Data.sellingCosts;
              } else {
                detailedInfo.existingHomeSelleingCost = 0;
              }
              if (typeof moduleServices.module18Data.existingHomeSaleYear !== 'undefined' && moduleServices.module18Data.existingHomeSaleYear === selectedYear && typeof moduleServices.module18Data.netSellingPrice !== 'undefined' && moduleServices.module18Data.netSellingPrice !== '') {
                detailedInfo.existingHomeNetSelleingPrice = moduleServices.module18Data.netSellingPrice;
              } else {
                detailedInfo.existingHomeNetSelleingPrice = 0;
              }
              if (typeof moduleServices.module18Data.existingHomeSaleYear !== 'undefined' && moduleServices.module18Data.existingHomeSaleYear === selectedYear && typeof moduleServices.module18Data.existingLoansBasis !== 'undefined' && moduleServices.module18Data.existingLoansBasis !== '') {
                detailedInfo.existingHomePurchaseBasis = moduleServices.module18Data.existingLoansBasis;
              } else {
                detailedInfo.existingHomePurchaseBasis = 0;
              }
              if (typeof moduleServices.module18Data.existingHomeSaleYear !== 'undefined' && moduleServices.module18Data.existingHomeSaleYear === selectedYear && typeof moduleServices.module18Data.gainOnSale !== 'undefined' && moduleServices.module18Data.gainOnSale !== '') {
                detailedInfo.existingHomeLossOnSale = moduleServices.module18Data.gainOnSale;
              } else {
                detailedInfo.existingHomeLossOnSale = 0;
              }

              // setGainDetailsPopup( true );
              setOpenDialog(true);
              setOpenDialogName('gainDetailPopup');
              break;
            default:
              break;
          }
          break;
        /* End of Investment Property Sale */

      }
      setDetailedInfo(detailedInfo);
    }
  }

  return (
    <div className={classes.modalBodyData}>
      <div className={classes.incomeSheetModal}>
        <div className={classes.popupTable}>
          <table className={classNames(classes.incomeSheetTable, classes.taxSheetTable)}>
            <thead>
              <tr className={classes.topIncomeBg}>
                <th>Annual Tax Statement</th>
                <th>&nbsp;</th>
                {
                  rangeList.map((range) => (
                    <th>{range + (dispalyObj.year)}</th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              <tr className={classNames(classes.blankSpace, classes.emptyRowdata)}>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>Gross Income</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classNames(classes.blueBorderLine, classes.noCursorData)}>
                <td className={classes.subHeadingData}>Total Gross Income (excluding Rental Income)</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[1]}
                  />
                </td>
                {
                  dispalyObj.taxData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.taxData[range + (dispalyObj.year)]['Gross Income']['Total Gross Income (excluding Rental Income)'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Gross Income', 'Total Gross Income (excluding Rental Income)') }}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.emptyRowdata}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>Adjustments</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              {
                dispalyObj.taxStatementAdjustmentsHeadings && Object.keys(dispalyObj.taxStatementAdjustmentsHeadings).map((livingExpensesTitle) => (
                  <tr>
                    <td className={classes.subHeadingData}>
                      {livingExpensesTitle === '401k Contributions' ? 'Traditional IRA, 401(k), SEP IRA, & Pension Contributions' : livingExpensesTitle}
                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      rangeList.map((range) => {

                        const value = dispalyObj.taxData[range + (dispalyObj.year)].Adjustments[livingExpensesTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Adjustments', livingExpensesTitle) }}>
                            {value >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                            ) : (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }
              <tr className={classNames(classes.blueBorderLine, classes.noCursorData)}>
                <td className={classes.headingData}>Total Adjustments</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[2]}
                  />
                </td>
                {
                  dispalyObj.taxData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.taxData[range + (dispalyObj.year)]['Total Adjustments'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.emptyRowdata}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Adjusted Gross Income</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[3]}
                  />
                </td>
                {
                  dispalyObj.taxData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.taxData[range + (dispalyObj.year)]['Adjusted Gross Income'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Adjusted Gross Income', 'Adjusted Gross Income') }}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.emptyRowdata}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>REI Property</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              {
                dispalyObj.taxStatementREIPropertyHeadings && Object.keys(dispalyObj.taxStatementREIPropertyHeadings).map((livingExpensesTitle) => (
                  <tr className={ classes.trFirstRow }>
                    <td className={classes.subHeadingData}>
                      {livingExpensesTitle === '401k Contributions' ? '401(k), SEP IRA, & Pension Contributions' : livingExpensesTitle}
                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      rangeList.map((range) => {
                        const value = dispalyObj.taxData[range + (dispalyObj.year)]['REI Property'][livingExpensesTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Gross Income', livingExpensesTitle) }}>
                            {value >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                            ) : (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }
              <tr className={classNames(classes.blueBorderLine, classes.noCursorData)}>
                <td className={classes.headingData}>REI Property Taxable Income (Passive Loss Created)</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[4]}
                  />
                </td>
                {
                  dispalyObj.taxData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.taxData[range + (dispalyObj.year)]['REI Property Taxable Income (Passive Loss Created)'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.emptyRowdata}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              {
                dispalyObj.taxStatementREIPropertyHeadings1 && Object.keys(dispalyObj.taxStatementREIPropertyHeadings1).map((livingExpensesTitle) => (
                  <tr>
                    <td className={classes.subHeadingData}>
                      {livingExpensesTitle}
                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      rangeList.map((range) => {
                        const value = dispalyObj.taxData[range + (dispalyObj.year)]['REI Property1'][livingExpensesTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Gross Income', livingExpensesTitle) }}>
                            {value >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                            ) : (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Passive Losses Taken</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[5]}
                  />
                </td>
                {
                  dispalyObj.taxData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.taxData[range + (dispalyObj.year)]['REI Property1']['Passive Losses Taken'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'REI Deductions', 'Passive Losses Taken') }}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.emptyRowdata}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classNames(classes.blueBorderLine, classes.noCursorData)}>
                <td className={classes.headingData}>REI Property Taxable Income (Loss)</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[6]}
                  />
                </td>
                {
                  dispalyObj.taxData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.taxData[range + (dispalyObj.year)]['REI Property Taxable Income (Passive Losses Taken)'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.emptyRowdata}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              <tr className={classes.blueRowData}>
                <td>Itemized Deductions or Standard Deduction</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              {
                dispalyObj.taxStatementItemizedDeductionsHeadings && Object.keys(dispalyObj.taxStatementItemizedDeductionsHeadings).map((livingExpensesTitle) => (
                  <tr>
                    <td className={classes.subHeadingData}>
                      {livingExpensesTitle}
                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      rangeList.map((range) => {
                        const value = dispalyObj.taxData[range + (dispalyObj.year)]['Itemized Deductions'][livingExpensesTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Deductions', livingExpensesTitle) }}>
                            {value >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                            ) : (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }

              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Itemized Deductions (1)  </td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[7]}
                  />
                </td>
                {
                  dispalyObj.taxData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.taxData[range + (dispalyObj.year)]['Total Itemized Deductions'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Itemized Deductions', 'Itemized Deductions') }}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Itemized Deductions (2)</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[8]}
                  />
                </td>
                {
                  dispalyObj.taxData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.taxData[range + (dispalyObj.year)]['Standard Deduction'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Standard Deductions', 'Standard Deductions') }}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Total Deductions = Greater of (1) or (2)</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[9]}
                  />
                </td>

                {
                  dispalyObj.taxData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.taxData[range + (dispalyObj.year)]['Total Deductions'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Total Deductions Exemptions', 'Total Deductions Exemptions') }}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.emptyRowdata}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Federal Taxable Income</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[10]}
                  />
                </td>
                {
                  dispalyObj.taxData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.taxData[range + (dispalyObj.year)]['Federal Taxable Income'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Federal Taxable Income', 'Federal Taxable Income') }}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>

              <tr className={classes.emptyRowdata}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>Taxes</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              {
                dispalyObj.taxStatementTaxesHeadings && Object.keys(dispalyObj.taxStatementTaxesHeadings).map((livingExpensesTitle) => (
                  <tr>
                    <td className={classes.subHeadingData}>
                      {livingExpensesTitle}
                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      rangeList.map((range) => {
                        const value = dispalyObj.taxData[range + (dispalyObj.year)].taxes[livingExpensesTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Taxes', livingExpensesTitle) }}>
                            {value >= 0 ? (
                              <NumberFormat decimalScale={2} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="" suffix="%" />
                            ) : (
                              <NumberFormat decimalScale={2} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="(" suffix="%)" />
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Total Taxes before Credits</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[11]}
                  />
                </td>
                {
                  dispalyObj.taxData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.taxData[range + (dispalyObj.year)]['Total Taxes'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Taxes', 'Total Taxes before Credits') }}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.emptyRowdata}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              <tr className={classes.blueRowData}>
                <td>Tax Credits </td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              {
                dispalyObj.taxStatementTaxCreditsHeadings && Object.keys(dispalyObj.taxStatementTaxCreditsHeadings).map((livingExpensesTitle) => (
                  <tr>
                    <td className={classes.subHeadingData}>
                      {livingExpensesTitle}
                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      rangeList.map((range) => {
                        const value = dispalyObj.taxData[range + (dispalyObj.year)]['Tax Credits'][livingExpensesTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Tax Credits', livingExpensesTitle) }}>
                            {value >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                            ) : (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Total Tax Credits</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[12]}
                  />
                </td>
                {
                  dispalyObj.taxData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.taxData[range + (dispalyObj.year)]['Total Tax Credits'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.emptyRowdata}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              <tr className={classes.blueRowData}>
                <td>Real Estate Sales </td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              {
                dispalyObj.taxStatementTaxCreditsHeadings && Object.keys(dispalyObj.taxStatementInvestmentPropertySaleHeadings).map((livingExpensesTitle) => (
                  <tr>
                    <td className={classes.subHeadingData}>{livingExpensesTitle}</td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      dispalyObj.taxData !== undefined && rangeList.map((range) => {
                        const value = dispalyObj.taxData[range + (dispalyObj.year)]['Investment Property Sale'];
                        return (
                          <td className={value['Tax on Sale'] < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'InvestmentPropertySale', livingExpensesTitle) }}>
                            {value['Tax on Sale'] >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value['Tax on Sale'] !== null ? value['Tax on Sale'] : 0} prefix="$" />
                            ) : (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value['Tax on Sale'] < 0 ? value['Tax on Sale'] : 0)} prefix="($" suffix=")" />
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Total Tax on Real Estate Sales  </td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                {
                  dispalyObj.taxData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.taxData[range + (dispalyObj.year)]['Total Investment Property Sale'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'InvestmentPropertySale', 'Total Tax on  Real Estate Sales') }}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.emptyRowdata}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Total Taxes</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[13]}
                  />
                </td>
                {
                  dispalyObj.taxData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.taxData[range + (dispalyObj.year)]['Net Income'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Taxes', 'Total Taxes') }}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-value} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>

            </tbody>
          </table>
        </div>
      </div>
      {(openDialog
      ) &&
        <TaxStatementPopup
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          openDialogName={openDialogName}
          detailedInfo={detailedInfo}
          openGrossIncomeModel={openGrossIncomeModel}
          assumptionsInfo={assumptionsInfo}
          openTaxCreditsDetailModel={openTaxCreditsDetailModel}

        />}
    </div>
  );
};

TaxStatement.propTypes = {
  classes: PropTypes.object.isRequired,
  dispalyObj: PropTypes.string.isRequired,
  rangeList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(TaxStatement);
