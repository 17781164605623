import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/rei/styles';
import * as modulesServices from '../../../calculations/modules-services';
import converyIntoThousands, { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';

const maintenanceMarks = [];
for ( let i = 0; i <= 5; i += 1 ) {
  maintenanceMarks.push( {
    value: i,
    label: i === 0 ? '0% of Purchase Price' : `${i}%`,
  } );
}

const managementMarks = [];
for ( let i = 0; i <= 10; i += 2 ) {
  managementMarks.push( {
    value: i,
    label: i === 0 ? '0% of Annual Rental Income' : `${i}%`,
  } );
}

const associationFeesMarks = [
  {
    value: 0,
    label: '$0',
  },
  {
    value: 100,
    label: '$100',
  },
  {
    value: 200,
    label: '$200',
  },
  {
    value: 300,
    label: '$300',
  },
  {
    value: 400,
    label: '$400',
  },
  {
    value: 500,
    label: '$500',
  },
];
const utilitiesFeesMarks = [
  {
    value: 0,
    label: '$0',
  },
  {
    value: 200,
    label: '$200',
  },
  {
    value: 400,
    label: '$400',
  },
  {
    value: 600,
    label: '$600',
  },
  {
    value: 800,
    label: '$800',
  },
  {
    value: 1000,
    label: '$1k',
  },
];

const ManagementFeeMaintenance = ( props ) => {
  const {
    classes, inputData, updateData, handleDefinationPopup, percentageFirstVal, percentageSecondVal
  } = props;
  const purchasePrice = inputData.purchase_price !== undefined ? inputData.purchase_price : 0;
  const rentalIncome = inputData.yearly_rental_income !== undefined ? ( inputData.yearly_rental_income * 12 ) : 0;

  const [managementFee, setManagementFee] = React.useState( 0 );
  const [managementFeeValue, setManagementFeeValue] = React.useState( 0 );
  const [maintenance, setMaintenance] = React.useState( 0 );
  const [maintenanceValue, setMaintenanceValue] = React.useState( 0 );
  const [associationFees, setAssociationFees] = React.useState( 0 );
  const [utilities, setUtilities] = React.useState( 0 );

  useEffect( () => {
    let inputDetails = {...inputData}
    let managementValue = 0.0;
    if ( inputData.managemtnt_fee_in_percentage !== undefined ) {
      managementValue = inputData.managemtnt_fee_in_percentage;
    } else {
      managementValue = 1;
      inputDetails['managemtnt_fee_in_percentage'] = managementValue;
    }
    setManagementFee( managementValue );
    setManagementFeeValue( (( rentalIncome / 100 ) * managementValue)/12 );

    let maintenanceVal = 0;
    if ( inputData.yearly_maintanance_percantage !== undefined ) {
      maintenanceVal = inputData.yearly_maintanance_percantage;
    } else {
      maintenanceVal = 1;
      inputDetails['yearly_maintanance_percantage'] = maintenanceVal;
    }
    setMaintenance( maintenanceVal );
    setMaintenanceValue( (( purchasePrice / 100 ) * maintenanceVal)/12 );

    if ( inputData.yearly_association_fees !== undefined ) {
      setAssociationFees( inputData.yearly_association_fees / 12 );
    } else {
      inputDetails['yearly_association_fees'] = 0;
    }

    if ( inputData.monthly_utilities !== undefined ) {
      setUtilities( inputData.monthly_utilities );
    } else {
      inputDetails['monthly_utilities'] = 0;
    }
    updateData( 'total_obj', inputDetails );

    // eslint-disable-next-line
  }, [] );

  const updateValue = ( e, value, field, mainField, type ) => {
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    let inputDetails = {...inputData}
    switch ( field ) {
      case 'management': {
        setManagementFee( newvalue );
        setManagementFeeValue( (( rentalIncome / 100 ) * newvalue)/12 );
        inputDetails[mainField] = newvalue;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'management_value': {
        if ( newvalue > rentalIncome ) {
          newvalue = rentalIncome;
        }
        setManagementFeeValue( newvalue );
        let managementValue = 0;
        if ( rentalIncome > 0 ) {
          managementValue = ( newvalue*12 / rentalIncome ) * 100;
        }
        setManagementFee( managementValue );
        inputDetails[mainField] = managementValue;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'maintenance': {
        setMaintenance( newvalue );
        setMaintenanceValue( (( purchasePrice / 100 ) * newvalue)/12 );
        inputDetails[mainField] = newvalue;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'maintenance_input': {
        setMaintenanceValue( newvalue );
        let maintenanceVal = 0.0;
        if ( newvalue >= 0 && purchasePrice >= 0 ) {
          maintenanceVal = ( (newvalue*12) / purchasePrice ) * 100;
        }
        setMaintenance( maintenanceVal );
        inputDetails[mainField] = maintenanceVal;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'association': {
        setAssociationFees( newvalue );
        inputDetails[mainField] = newvalue * 12;
        updateData( 'total_obj', inputDetails );

        break;
      }
      case 'utilities': {
        setUtilities( newvalue );
        inputDetails[mainField] = newvalue;
        updateData( 'total_obj', inputDetails );
        break;
      }
      default:
        break;
    }
  };

  const totalValue = managementFeeValue+maintenanceValue+associationFees+utilities; 

  return (
    <div className={ classes.contentBlock }>
      <p>
        Owning an investment property requires constant attention. From tracking rent payments, collecting any late fees, coordinating maintenance and repairs, and dealing with bad tenants,
        a property manager can save you time and money and give you peace of mind. If you hire a property manager,
        you can expect to pay anywhere from 4-10% of your annual rental income in a
        {' '}
        <span aria-hidden="true" className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Management Fee: Real Estate Investment Property' ) }>management fee.</span>
      </p>
      <p>
        <span aria-hidden="true" className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Real Estate Maintenance' ) }>Maintenance</span>
        {' '}
        {' '}
        varies depending on the property. If your property is newer, then you might expect less maintenance and vice versa. A good budgeting benchmark for maintenance is $1 per square foot per year.
      </p>
      <p>
        <span aria-hidden="true" className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Association Fees' ) }>Homeowners association fees</span>
        , or HOA fees, are monthly fees sometimes paid by a property owner for the general upkeep of a neighborhood such as snow removal, trash and landscape.
      </p>
      <p>Utilities are monthly expenses paid for electricity, gas, water, sewer, internet, phone, cable and security systems.</p>
      <div className={ classes.homeMainRowScroll }>
        <div className={ classes.homeMainRow }>
          <div className={ classes.pmileftSection }>
            <div className={ classNames( classes.sliderGraph, classes.pmiGraph ) }>
              <div className={ classes.graphLeft }>
                <div className={ classes.sliderBar }>
                  <span>&nbsp;</span>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.titleNew }><span>Monthly</span></div>
                <div className={ classes.titleNew }><span>Annual</span></div>
              </div>
            </div>
            <div className={ classNames( classes.flexDisplayNew, classes.transferSlider ) }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider, classes.pmiRange ) }>
                <div>
                  <p>
                    <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Management Fee: Real Estate Investment Property' ) } className={ classes.dottedText }>Management Fee</span>
                  </p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      value={ managementFee }
                      ValueLabelComponent={ ValueLabelComponent }
                      valueLabelFormat={ percentFormatWithTwoDecimal }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      min={ 0 }
                      max={ 10 }
                      step={ 0.05 }
                      onChange={ ( e, value ) => updateValue( e, value, 'management', 'managemtnt_fee_in_percentage', 'slider' ) }
                      marks={ managementMarks }
                    />
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.firstDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ managementFeeValue } onValueChange={ ( e ) => updateValue( e, '', 'management_value', 'managemtnt_fee_in_percentage', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.lastDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ managementFeeValue * 12 } displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classNames( classes.flexDisplayNew, classes.transferSlider ) }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider, classes.pmiRange ) }>
                <div>
                  <p>
                    <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Real Estate Maintenance' ) } className={ classes.dottedText }>Maintenance</span>
                  </p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      value={ maintenance }
                      ValueLabelComponent={ ValueLabelComponent }
                      valueLabelFormat={ percentFormatWithTwoDecimal }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      min={ 0 }
                      max={ 5 }
                      step={ 0.05 }
                      onChange={ ( e, value ) => updateValue( e, value, 'maintenance', 'yearly_maintanance_percantage', 'slider' ) }
                      marks={ maintenanceMarks }
                    />
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.firstDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ maintenanceValue } onValueChange={ ( e ) => updateValue( e, '', 'maintenance_input', 'yearly_maintanance_percantage', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.lastDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ maintenanceValue * 12 } displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.flexDisplayNew }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider, classes.pmiRange ) }>
                <div>
                  <p>
                    <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Association Fees' ) } className={ classes.dottedText }>Association Fees (HOA)</span>
                  </p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      value={ associationFees }
                      ValueLabelComponent={ ValueLabelComponent }
                      valueLabelFormat={ converyIntoThousands }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      min={ 0 }
                      max={ 500 }
                      step={ 5 }
                      onChange={ ( e, value ) => updateValue( e, value, 'association', 'yearly_association_fees', 'slider' ) }
                      marks={ associationFeesMarks }
                    />
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.firstDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ associationFees } onValueChange={ ( e ) => updateValue( e, '', 'association', 'yearly_association_fees', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.lastDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ associationFees * 12 } displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.flexDisplayNew }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider, classes.pmiRange ) }>
                <div>
                  <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Utilities' ) } className={ classes.dottedText }>Utilities</span></p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      value={ utilities }
                      ValueLabelComponent={ ValueLabelComponent }
                      valueLabelFormat={ converyIntoThousands }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      min={ 0 }
                      max={ 1000 }
                      step={ 5 }
                      onChange={ ( e, value ) => updateValue( e, value, 'utilities', 'monthly_utilities', 'slider' ) }
                      marks={ utilitiesFeesMarks }
                    />
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.firstDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ utilities } onValueChange={ ( e ) => updateValue( e, '', 'utilities', 'monthly_utilities', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.lastDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ utilities * 12 } displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin, classes.sliderHeight ) }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider ) }>
                <div>
                  <div className={ classes.pwisliderInputs }>
                    <p><b>Other Property Expenses</b></p>
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classNames( classes.firstDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -modulesServices.module14Data.reiTotalMonthlyExpenses - totalValue } prefix="$" /> ) : ( <span>$0</span> )}
                  </div>
                </div>
                <div className={ classNames( classes.lastDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ (-modulesServices.module14Data.reiTotalMonthlyExpenses - totalValue)*12 } prefix="$" /> ) : ( <span>$0</span> )}
                  </div>
                </div>
              </div>
            </div>
            <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin, classes.sliderHeight ) }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider ) }>
                <div>
                  <div className={ classes.pwisliderInputs }>
                    <p><b>Total Property Expenses</b></p>
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classNames( classes.firstDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -modulesServices.module14Data.reiTotalMonthlyExpenses } prefix="$" /> ) : ( <span>$0</span> )}
                  </div>
                </div>
                <div className={ classNames( classes.lastDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -modulesServices.module14Data.reiTotalMonthlyExpenses*12 } prefix="$" /> ) : ( <span>$0</span> )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.sliderChart }>
            <ul className={ classes.utilitiesPMI }>
              <li className={ classes.chartBar }>
                <div className={ classes.chartText }>
                    Monthly Property Expenses
                    <span className={ classes.chartSpan }>
                      {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -modulesServices.module14Data.reiTotalMonthlyExpenses } prefix="$" /> ) : ( <span>$0</span> )}
                    </span>
                  </div>
                <span className={ classes.percentageBar } style={{height: `calc(${percentageFirstVal}% - 72px)`}} />
                <div className={ classes.graphPercentage }>
                  {percentageFirstVal !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageFirstVal } prefix={ percentageFirstVal >= 0 ? '' : '(' } suffix={ percentageFirstVal < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </div>
              </li>
              <li className={ classes.chartBar }>
                <div className={ classes.chartText }>
                  Monthly Adjusted Rental Income
                  <span className={ classNames( classes.chartSpan, classes.secondPercentageTextColor ) }>
                    {modulesServices.module14Data.adjustedRentalIncomeForGraph !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module14Data.adjustedRentalIncomeForGraph/12 } prefix="$" /> ) : ( <span>$0</span> )}
                  </span>
                </div>
                <span className={ classNames( classes.percentageBar, classes.secondPercentageBarColor ) } style={{height: `calc(${percentageSecondVal}% - 72px)`}} />
                <div className={ classNames( classes.graphPercentage, classes.secondPercentageTextColor ) }>
                  {percentageSecondVal !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageSecondVal } prefix={ percentageSecondVal >= 0 ? '' : '(' } suffix={ percentageSecondVal < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>  
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

ManagementFeeMaintenance.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  percentageFirstVal: PropTypes.string.isRequired,
  percentageSecondVal: PropTypes.string.isRequired,
};

export default withStyles( styles )( ManagementFeeMaintenance );
