import checkImg from '../../../../../assets/img/pf101/arrow-check.png';

const styles = ( theme ) => ( {
  container: {
    margin: ' 0px auto',
    padding: '0 15px',
    maxWidth: '1140px',
  },
  pfHeader: {
    position: 'relative',
  },
  pfHeaderInner: {
    display: 'flex',
    marginTop: '25px',
    marginBottom: '0',
    padding: '10px 2px',
    alignItems: 'center',
    borderBottom: '4px solid #cfa448',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      margin: '0',
      fontFamily: 'MuseoSans-300',
      fontSize: '27px',
      fontWeight: '300',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
      },
    },
    '& button': {
      margin: '0 0 0 auto',
      outline: 'none',
      [theme.breakpoints.down( 'xs' )]: {
        margin: '0 auto',
      },
      '& span': {
        '& > img': {
          maxWidth: '400px',
          height: '50px',
          cursor: 'pointer',
        },
      },
      '&:hover': {
        background: 'transparent',
      },
    },
  },
  pfContent: {
    maxWidth: '950px',
    margin: '0 auto',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0 20px',
    },
  },
  pfContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '25px',
    },
    '& i': {
      fontSize: '40px',
    },
    '& h3': {
      margin: '0 0 0 0px',
      fontWeight: 'normal',
      fontSize: '26px',
      color: '#000',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
      },
      '& span': {
        color: '#0069aa',
      },
    },
    '& a': {
      margin: '0 0 0 auto',
      '& figure': {
        margin: '0 0 0 auto',
        padding: '7px 15px',
        transition: 'all ease-in-out 0.3s',
        '&:hover': {
          background: '#333',
          borderRadius: '5px',
          cursor: 'pointer',
        },
        '& img': {
          maxWidth: '30px',
          height: 'auto',
          display: 'flex',
        },
      },
    },
  },
  ProgressBar: {
    width: '100%',
    height: '5px',
    backgroundColor: '#d5d5d5',
    margin: '10px auto 0',
    position: 'relative',
  },
  ProgressBarInner: {
    width: '25%',
    backgroundColor: '#337ab7',
    height: '5px',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right,#337ab7,#003b69)',
    '&::before': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '1.5px solid transparent',
      borderLeft: '2px solid #fbfdfd',
      content: "''",
      position: 'absolute',
    },
    '&::after': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '2.5px solid transparent',
      borderLeft: '2px solid #003b69',
      content: "''",
      position: 'absolute',
      right: '0',
      marginRight: '-2px',
    },
  },
  contentBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '35px',
    marginTop: '15px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '18px',
    },
    '& p': {
      margin: '0 0 10px 0',
    },
    '& a': {
      color: '#337ab7',
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
      },
    },
    '& font': {
      color: '#1674b1',
    },
    '& h3': {
      color: '#333',
      textAlign: 'left',
      lineHeight: '30px',
      fontWeight: '300',
      fontSize: '21px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
      },
    },
  },
  breadcrumbTitle: {
    color: '#cfa448',
  },
  grayBox: {
    background: '#f5f5f5',
    padding: '30px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px',
    },
    '& h3': {
      color: '#333',
      textAlign: 'left',
      lineHeight: '30px',
      fontWeight: '300',
      fontSize: '21px',
      marginTop: '0',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '25px',
      },
    },
  },
  bonusXp: {
    color: '#0069aa',
    fontWeight: '700',
  },

  ansWrong: {
    '& span': {
      '& svg': {
        color: theme.palette.common.red,
      },
    },
  },
  ansRight: {
    '& span': {
      '& svg': {
        color: '#39b54a',
      },
    },
  },
  answerStatus: {
    position: 'relative',
    minHeight: '100px',
    textAlign: 'right',
    fontWeight: '300',
    fontFamily: '"MuseoSans-500"',
    fontSize: '21px',
    lineHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& h2': {
      textAlign: 'right',
      fontSize: '21px',
      fontWeight: 300,
      lineHeight: '30px',
    },
  },
  correctAnswer: {
    color: '#008000',
  },
  wrongAnswer: {
    color: '#ff0000',
  },
  btnGroup: {
    display: 'flex',
    marginTop: '20px',
    marginBottom: '20px',
    lineHeight: '1.4',
    [theme.breakpoints.down( 'sm' )]: {
      alignItems: 'center',
    },
    '@media (max-width: 550px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    '& button': {
      borderRadius: '3px',
      padding: '5px 19px',
      textTransform: 'uppercase',
      fontSize: '17px',
      lineHeight: '1.4',
      boxSizing: 'border-box',
      fontFamily: '"MuseoSans-300"',
      backgroundColor: '#0069aa',
      border: '2px solid #0069aa',
      color: '#fff',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '13px',
      },
      '@media screen and (min-width: 550px) and (max-width: 767px)': {
        fontSize: '11px',
      }, 
      '&:hover': {
        border: '2px solid #d5d5d5',
        backgroundColor: '#0069aa',
      },
      '&:disabled': {
        backgroundColor: '#d5d5d5',
        borderColor: '#d5d5d5',
        cursor: 'not-allowed',
        filter: 'alpha(opacity=65)',
        webkitBoxShadow: 'none',
        boxShadow: 'none',
        opacity: '.65',
        color: theme.palette.common.black,
        pointerEvents: 'all',
        '&:hover': {
          backgroundColor: '#d5d5d5',
          border: '2px solid #d5d5d5',
        },
        '& span': {
          pointerEvents: 'none',
        },
      },
    },
  },
  checkAnswer: {
    marginRight: '20px',
    marginLeft: 'auto',
    [theme.breakpoints.down( 'sm' )]: {      
      whiteSpace: 'nowrap',     
    },
    '@media screen and (min-width: 550px) and (max-width: 700px)': {
      width: '130px',
      marginRight: '5px',
    },
    '@media screen and (min-width: 701px) and (max-width: 992px)': {
      width: '200px',
      marginRight: '10px',
    },
    [theme.breakpoints.down( 'xs' )]: {      
      marginLeft: '0px',   
    },
  },
  progressXp: {
    display: 'flex',
    position: 'relative',
    marginLeft: '17px',
    width: '58%',
    float: 'left',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',      
    },
    '@media (max-width: 550px)': {
      marginLeft: '0px',
      width: '100%',
      flexWrap: 'wrap',
      marginTop: '20px',
    }, 
    '@media screen and (min-width: 600px) and (max-width: 700px)': {      
      marginLeft: '10px',
    },  
  },
  questionXp: {
    color: '#84a84d',
    fontSize: '15px',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      marginRight: '8px',
    },
    '@media screen and (min-width: 600px) and (max-width: 700px)': {
      marginRight: '5px',
    },
    '@media (max-width: 550px)': {
      justifyContent: 'center',
      display: 'block',
      margin: '0 auto',
      marginBottom: '20px',
    },
    '& p': {
      marginBottom: '2px',
      fontSize: '20px',
      marginTop: '0px',
      '@media screen and (min-width: 600px) and (max-width: 992px)': {
        fontSize: '14px',
      },
      '@media screen and (min-width: 500px) and (max-width: 599px)': {
        fontSize: '12px',
      },
    },
    '& span': {
      fontSize: '16px',
      paddingLeft: '5px',
      marginTop: '-5px',
      color: '#333',
      [theme.breakpoints.down( 'sm' )]: {
        marginTop: '0px',
      },
      '@media screen and (min-width: 600px) and (max-width: 992px)': {
        fontSize: '14px',
      },
      '@media screen and (min-width: 500px) and (max-width: 599px)': {
        fontSize: '12px',
      },
    },
  },
  progressBlock: {
    background: '#fff',
    position: 'relative',
    width: '190px',
    height: '11px',
    borderRadius: '4px',
    border: '1px solid #0069aa',
    margin: '0',
    boxShadow: 'inset 0 1px 2px rgba(0,0,0,.1)',
    float: 'left',
    [theme.breakpoints.down( 'sm' )]: {
      width: '130px',
      marginRight: '5px',
    },
    '@media (max-width: 550px)': {
      width: '160px',
      marginRight: '5px',
    },
    '@media screen and (min-width: 551px) and (max-width: 700px)': {
      width: '115px',
    },
  },
  progressBar: {
    position: 'relative',
    backgroundColor: '#84a84d',
    borderRadius: '0',
    marginTop: '0',
    marginBottom: '0',
    left: 'auto',
    boxShadow: 'inset 0 -1px 0 rgba(0,0,0,.15)',
    webkitTransition: 'width .6s ease',
    oTransition: 'width .6s ease',
    transition: 'width .6s ease',
    float: 'left',
    width: '100%',
    height: '100%',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#fff',
    textAlign: 'center',
    bottom: '0',
    marginLeft: '0px',
  },
  totalQuestion: {
    progressBlock: {
      width: '210px',
    },
  },
  activeBtn: {
    backgroundColor: '#0069aa',
    border: '2px solid #0069aa',
    color: '#fff',
    '&:hover': {
      border: '2px solid #0069aa',
      backgroundColor: 'transparent',
      color: '#fff',
    },
  },
  cancel: {
    border: '2px solid #d5d5d5',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#d5d5d5',
    },
  },
  assetsOptions: {
    padding: '30px 0 0px 0',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
  },
  exerciseOptions: {
    width: '100%',
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '0',
    padding: '0px 0 0 0',
    textAlign: 'center',
    fontFamily: '"MuseoSans-100"',
    '& li': {
      display: 'inline-block',
      verticalAlign: 'top',
      padding: '0',
      textAlign: 'center',
      width: '24%',
      position: 'relative',
      [theme.breakpoints.down( 'sm' )]: {
        minHeight: '100px',
        width: '50%',
      },
      '@media (max-width: 500px)': {
        width: '100%',
      },
      '& input': {
        position: 'absolute',
        left: '0',
        opacity: '0',
        width: '170px',
        height: '100px',
        top: '-52px',
        zIndex: '9',
        cursor: 'pointer',
      },
      '& [type="radio"]:not(:checked)+label:after': {
        opacity: '0',
        transform: 'scale(0)',
      },
      '& input[type="radio"]:checked+label:after': {
        opacity: '1',
        transform: 'scale(1)',
      },
    },
  },
  spanRadio: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '55px',
    fontSize: '24px',
    lineHeight: '31px',
    position: 'relative',
    cursor: 'pointer',
    color: theme.palette.common.blue,
    fontFamily: '"museo-sans", sans-serif',
    display: 'inline-block',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '20px',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '0',
      width: '40px',
      height: '40px',
      border: '2px solid #7cb1d3',
      background: theme.palette.common.white,
      borderRadius: '100%',
      marginLeft: '-20px',
      boxSizing: 'border-box',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '0',
      transition: 'all .2s',
      textTransform: 'none',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      textShadow: '0px 0px 2px #0069aa',
      backgroundSize: '24px',
      width: '30px',
      height: '30px',
      marginLeft: '-12px',
      marginTop: '0px',
      backgroundRepeat: 'no-repeat',
      opacity: '1',
      transform: 'scale(1)',
    },
  },
  ageChecked: {
    '&:after': {
      backgroundImage: `url(${checkImg})`,
      marginTop: '11px',
    },
  },
  radioGreen: {
    '&:before': {
      border: '2px solid #39b54a',
    },
    '&:after': {
      background: '#39b54a',
      color: '#39b54a',
      fontSize: '0px',
      position: 'absolute',
      left: '50%',
      top: '0px',
      transition: 'all .2s',
      marginLeft: '-20px',
      borderRadius: '100%',
      width: '40px',
      lineHeight: '40px',
      height: '40px',
      fontFamily: 'themify',
      speak: 'none',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontVariant: 'normal',
      textTransform: 'none',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      textShadow: '0px 0px 2px #0069aa',
      boxSizing: 'border-box',
    },
  },
  radioRed: {
    '&:before': {
      border: '2px solid #ff0000',
    },
    '&:after': {
      background: theme.palette.common.red,
      textShadow: '0px 0px 2px #ff0000',
      position: 'absolute',
      left: '50%',
      top: '0px',
      fontSize: '23px',
      color: '#0069aa',
      transition: 'all .2s',
      marginLeft: '-20px',
      borderRadius: '100%',
      width: '40px',
      lineHeight: '40px',
      height: '40px',
      fontFamily: 'themify',
      speak: 'none',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontVariant: 'normal',
      textTransform: 'none',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      boxSizing: 'border-box',
    },
  },
  mikeNetTable: {
    width: '50%',
    maxWidth: '100%',
    marginBottom: '20px',
    fontSize: '18px',
    fontFamily: 'museo-sans,sans-serif',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    '& thead': {
      '& tr': {
        '& th': {
          backgroundColor: '#dae3f3',
          borderBottom: '3px solid #fff',
          fontWeight: '700',
          padding: '8px',
          lineHeight: '1.42857143',
          '&:first-child': {
            textAlign: 'left',
          },
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          padding: '8px',
          lineHeight: '1.42857143',
          borderBottom: '1px solid #fff',
          verticalAlign: 'middle',
          backgroundColor: '#f5f5f5',
          '&:first-child': {
            textAlign: 'left',
          },
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
  },
  tooltipInfo: {
    width: '200px',
    background: '#0069aa !important',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '7px',
    textAlign: 'initial',
    lineHeight: '20px',
    fontFamily: 'MuseoSans-300',
  },
  redoPopup: {
    padding: '0',
    boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    background: theme.palette.common.white,
    maxWidth: '600px',
    borderRadius: '6px',
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '90%',
      margin: '0 auto',
    },
    '& > div': {
      padding: '15px',
    },
    '& h3': {
      fontSize: '24px',
      color: '#0069aa',
      fontFamily: '"MuseoSans-500"',
      borderBottom: '1px solid #e2e2e2',
      paddingBottom: '10px',
      margin: ' 0 -15px',
      paddingLeft: '15px',
      fontWeight: 'normal',
    },
    '& p': {
      marginBottom: '0',
      color: '#333',
      lineHeight: '24px',
    },
  },
  buttonOkRedo: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        background: '#0069aa',
        borderColor: '#0069aa',
        color: '#fff',
        marginRight: '6px',
      },
      '&:last-child': {
        color: '#333',
        '&:hover': {
          backgroundColor: '#e6e6e6',
        },
      },

    },
  },
  intrestBox: {
    background: '#f5f5f5',
    padding: '30px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    width: '65%',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      padding: '10px',
    },
    '& h3': {
      color: '#333',
      textAlign: 'left',
      lineHeight: '30px',
      fontWeight: '300',
      fontSize: '21px',
      marginTop: '0',
      marginBottom: '0px',
      fontFamily: 'MuseoSans-100',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '25px',
      },
      '& span': {
        display: 'block',
        width: '100%',
      },
    },
  },
  debitTable: {
    [theme.breakpoints.down( 'xs' )]: {
      overflow: 'auto',
    },
    '& table': {
      width: '87%',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      margin: '20px 0px',
      '& thead': {
        '& tr': {
          '& th': {
            background: '#4472c4',
            color: '#fff',
            textAlign: 'center',
            padding: '8px',
            lineHeight: '1.42857143',
            fontSize: '18px',
            [theme.breakpoints.down( 'xs' )]: {
              fontSize: '14px',
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            textAlign: 'center',
            padding: '8px',
            lineHeight: '1.42857143',
            fontSize: '18px',
            verticalAlign: 'middle',
            background: '#cfd5ea',
            border: '1px solid #fff',
            [theme.breakpoints.down( 'xs' )]: {
              fontSize: '14px',
            },
          },
          '&:nth-child(even)': {
            background: '#e9ebf5',
          },
        },
      },
    },
  },
  optionsFullWidth: {
    '& li': {
      width: '100%',
      textAlign: 'left',
      display: 'flex',
      marginBottom: '30px',
      marginLeft: '20px',
    },
  },
  fullRadio: {
    paddingTop: '9px',
    textAlign: 'left',
    paddingLeft: '60px',
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      left: '0',
      top: 'inherit !important',
    },
    '&:after': {
      left: '0px',
      top: 'inherit !important',
    },
  },
  backButton: {
    backgroundColor: '#fff !important',
    border: '2px solid #d5d5d5 !important',
    color: '#333 !important',
    '&:hover': {
      backgroundColor: ' #d5d5d5 !important',
    },
  },
} );

export default styles;
