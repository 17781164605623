import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import submenuRightRewsImg from '../../../../assets/img/menu/submenu_right_pricing_img.png';

const ContactDropdownEl = styled.div`
  width: 50rem;
  padding: 0;
  
  ul {
    padding: 0 !important;
  }
  li {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    padding: 5px 0;
    margin: 0;
    font-size: 15px;
    margin-bottom: 10px;
  }
  h3 {
    margin:0 0 0;
  }
`;

const ContactDropdown = (props) => {
  const { classes, setOpen } = props;

  return (
    <div>
      <ContactDropdownEl className={classes.marginLeftNewCompany}>
        <Grid container>
          <Grid sm={6}>
            <Grid container className={classes.megamenuContainer}>
              <Grid sm={12}>
                <div className={classes.LeftMegamenu}>
                  <div className={classes.paddingTopFisty} />
                  <div className={classes.listGroup}>
                    <ul>
                      <li><Link to="/contact">We want to hear from you</Link></li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid sm={6} className={classes.rightMegamenu}>
            <div className={`${classes.aboutMegaMenu} ${classes.contactDropDown}`}>
              <img src={submenuRightRewsImg} alt="" />
              <h4>What do you need?</h4>
              <p>Let us know your thoughts and suggestions for Inkwiry. </p>
              <Button className={classes.btnstartQuizNews} onClick={() => { setOpen(true); }}>ANSWER 2 QUESTIONS</Button>
            </div>
          </Grid>
        </Grid>
      </ContactDropdownEl>
    </div>
  );
};

ContactDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default withStyles(styles)(ContactDropdown);
