import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import styles from './styles';


const BitCoinPopups = (props) => {
  const { classes, challengeIndex,level,setChallengeAnswer, challengeAnswer,btnDisabled,setSelectedAnswer,selectedAnswer } = props;

  useEffect(()=>{
    if(level ===2){
      if(challengeIndex === 1){
        setSelectedAnswer('3');
      }else if(challengeIndex === 2){
        setSelectedAnswer('1');
      }else if(challengeIndex === 3){
        setSelectedAnswer('4');
      }
    }
  },[challengeIndex]);

  const answerQuestion = (val) => {
    setChallengeAnswer(val.target.value);
  }

  return (
    <Typography variant="body1" component="div">
      { /* bitcoin XP questions  Popup */}
      {challengeIndex === 1 && (
        <div className={classes.completeActivity}>
          <div className={classes.completePopup}>
            <div className={classes.completePopupChallenge}>
              <h1>POWERUP CHALLENGE EXPLANATION</h1>
              <p className={classes.textLeftPara}>Use the following details to calculate Mary's federal income taxes:</p>
              <ul className={classes.bitUiList}>
                <li>Mary earns $50 an hour and worked 2,250 hours in 2020</li>
                <li>She is a single filer</li>
                <li>She has no adjustments</li>
              </ul>
              <table className={classes.federal}>
                <thead>
                  <tr>
                    <td>&nbsp;</td>
                    <td colspan="4" className={classes.textCenter}><strong>Mary's Federal Income Taxes</strong></td>
                    <td className={classes.textRight}><strong>Year 2020</strong></td>
                  </tr>
                  <tr>
                    <td colspan="6">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td colspan="4" className={classes.textRight}>Mary's Total Income</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td colspan="4">&nbsp;</td>
                    <td colspan="1" className={classNames(classes.textRight, classes.bdrBottom)}>Standard Deduction</td>
                    <td className={classNames(classes.textRight, classes.bdrBottom, classes.textRed)}>??</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td colspan="4" className={classes.textRight}><strong>Federal Taxable Income</strong></td>
                    <td className={classes.textRight}><strong>??</strong></td>
                  </tr>
                  <tr>
                    <td colspan="6">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td className={classes.textCenter}><strong>Rate</strong></td>
                    <td colspan="2" className={classes.textCenter}><strong>Income Ranges (Single)</strong></td>
                    <td className={classes.textCenter}><strong>Taxable Income</strong></td>
                    <td className={classes.textCenter}><strong>Taxes</strong></td>
                  </tr>
                </thead>
                <tbody className={classes.federalTbody}>
                  <tr>
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>10.00%</td>
                    <td className={classes.textRight}>$0.00</td>
                    <td className={classes.textRight}>$9,875.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>12.00%</td>
                    <td className={classes.textRight}>$9,875.00</td>
                    <td className={classes.textRight}>$40,125.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>22.00%</td>
                    <td className={classes.textRight}>$40,125.00</td>
                    <td className={classes.textRight}>$85,525.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                    <td></td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>24.00%</td>
                    <td className={classes.textRight}>$85,525.00</td>
                    <td className={classes.textRight}>$163,300.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>32.00%</td>
                    <td className={classes.textRight}>$163,300.00</td>
                    <td className={classes.textRight}>$207,350.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>35.00%</td>
                    <td className={classes.textRight}>$207,350.00</td>
                    <td className={classes.textRight}>$518,400.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>37.00%</td>
                    <td className={classes.textRight}>$518,400.00</td>
                    <td >&nbsp;</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="4">&nbsp;</td>
                    <td className={classes.textRight}><strong>??</strong></td>
                    <td className={classes.textRight}><strong>??</strong></td>
                  </tr>
                </tfoot>
              </table>
              <div className={classNames(classes.assetsOptions, "assets-row")}>
                <ul className={classes.exerciseOptions}>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="1" id="firstOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '1' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '1' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="firstOption">
                        $15,105.50
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="2" id="secondOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, (challengeAnswer === '2' && challengeAnswer === '' && classes.ageChecked), (challengeAnswer === '2' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="secondOption">
                        $16,555.50
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="3" disabled={btnDisabled} id="thirdOption" onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '3' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '3' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="thirdOption">
                        $18,103.50
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <input type="radio" name="equation" value="4" disabled={btnDisabled} id="fourthOption" onChange={(e) => answerQuestion(e)} />
                      <label className={classNames(classes.spanRadio, challengeAnswer === '4' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '4' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="fourthOption">
                        $20,103.50
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}


      {challengeIndex === 2 && (
        <div className={classes.completeActivity}>
          <div className={classes.completePopup}>
            <div className={classes.completePopupChallenge}>
              <h1>POWERUP CHALLENGE EXPLANATION</h1>
              <p className={classes.textLeftPara}>Use the following details to calculate Mary's federal income taxes:</p>
                <ul className={classes.bitUiList}>
                  <li>Mary earns $40 an hour and worked 2,000 hours in 2020</li>
                  <li>She is a married filer and her spouse earns $0 income in 2020</li>
                  <li>She has no adjustments</li>
                </ul>
              
              <table className={classes.federal}>
                <thead>
                  <tr>
                    <td>&nbsp;</td>
                    <td colspan="4" className={classes.textCenter}><strong>Mary's Federal Income Taxes</strong></td>
                    <td className={classes.textRight}><strong>Year 2020</strong></td>
                  </tr>
                  <tr>
                    <td colspan="6">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td colspan="4" className={classes.textRight}>Mary's Total Income</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td colspan="4">&nbsp;</td>
                    <td colspan="1" className={classNames(classes.textRight, classes.bdrBottom)}>Standard Deduction</td>
                    <td className={classNames(classes.textRight, classes.bdrBottom, classes.textRed)}>??</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td colspan="4" className={classes.textRight}><strong>Federal Taxable Income</strong></td>
                    <td className={classes.textRight}><strong>??</strong></td>
                  </tr>
                  <tr>
                    <td colspan="6">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td className={classes.textCenter}><strong>Rate</strong></td>
                    <td colspan="2" className={classes.textCenter}><strong>Income Ranges (Married)</strong></td>
                    <td className={classes.textCenter}><strong>Taxable Income</strong></td>
                    <td className={classes.textCenter}><strong>Taxes</strong></td>
                  </tr>
                </thead>
                <tbody className={classes.federalTbody}>
                  <tr>
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>10.00%</td>
                    <td className={classes.textRight}>$0.00</td>
                    <td className={classes.textRight}>$19,750.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>12.00%</td>
                    <td className={classes.textRight}>$19,750.00</td>
                    <td className={classes.textRight}>$80,250.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>22.00%</td>
                    <td className={classes.textRight}>$80,250.00</td>
                    <td className={classes.textRight}>$171,050.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                    <td></td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>24.00%</td>
                    <td className={classes.textRight}>$171,050.00</td>
                    <td className={classes.textRight}>$326,600.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>32.00%</td>
                    <td className={classes.textRight}>$326,600.00</td>
                    <td className={classes.textRight}>$414,700.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>35.00%</td>
                    <td className={classes.textRight}>$414,700.00</td>
                    <td className={classes.textRight}>$622,050.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>37.00%</td>
                    <td className={classes.textRight}>$622,050.00</td>
                    <td >&nbsp;</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="4">&nbsp;</td>
                    <td className={classes.textRight}><strong>??</strong></td>
                    <td className={classes.textRight}><strong>??</strong></td>
                  </tr>
                </tfoot>
              </table>
              <div className={classNames(classes.assetsOptions, "assets-row")}>
              <ul className={classes.exerciseOptions}>
              <li>
                <p>
                  <input type="radio" name="equation" value="1" id="firstOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                  <label className={classNames(classes.spanRadio, challengeAnswer === '1' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '1' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="firstOption">
                  $6,229.00
                  </label>
                </p>
              </li>
              <li>
                <p>
                  <input type="radio" name="equation" value="2" id="secondOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                  <label className={classNames(classes.spanRadio, (challengeAnswer === '2' && challengeAnswer === '' && classes.ageChecked), (challengeAnswer === '2' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="secondOption">
                  $7,555.00
                  </label>
                </p>
              </li>
              <li>
                <p>
                  <input type="radio" name="equation" value="3" disabled={btnDisabled} id="thirdOption" onChange={(e) => answerQuestion(e)} />
                  <label className={classNames(classes.spanRadio, challengeAnswer === '3' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '3' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="thirdOption">
                  $8,500.50
                  </label>
                </p>
              </li>
              <li>
                <p>
                  <input type="radio" name="equation" value="4" disabled={btnDisabled} id="fourthOption" onChange={(e) => answerQuestion(e)} />
                  <label className={classNames(classes.spanRadio, challengeAnswer === '4' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '4' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="fourthOption">
                  $10,103.50
                  </label>
                </p>
              </li>
            </ul>
              </div>

            </div>
          </div>
        </div>
      )}
      {challengeIndex === 3 && (
        <div className={classes.completeActivity}>
          <div className={classes.completePopup}>
            <div className={classes.completePopupChallenge}>
              <h1>POWERUP CHALLENGE EXPLANATION</h1>
              <p className={classes.textLeftPara}>Use the following details to calculate Mary's federal income taxes: </p>
                <ul className={classes.bitUiList}>
                  <li>Mary earns $100 an hour and worked 1,500 hours in 2020</li>
                  <li>She is a single filer</li>
                  <li>She has no adjustments</li>
                </ul>
             
              <table className={classes.federal}>
                <thead>
                  <tr>
                    <td>&nbsp;</td>
                    <td colspan="4" className={classes.textCenter}><strong>Mary's Federal Income Taxes</strong></td>
                    <td className={classes.textRight}><strong>Year 2020</strong></td>
                  </tr>
                  <tr>
                    <td colspan="6">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td colspan="4" className={classes.textRight}>Mary's Total Income</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td colspan="4">&nbsp;</td>
                    <td colspan="1" className={classNames(classes.textRight, classes.bdrBottom)}>Standard Deduction</td>
                    <td className={classNames(classes.textRight, classes.bdrBottom, classes.textRed)}>??</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td colspan="4" className={classes.textRight}><strong>Federal Taxable Income</strong></td>
                    <td className={classes.textRight}><strong>??</strong></td>
                  </tr>
                  <tr>
                    <td colspan="6">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td className={classes.textCenter}><strong>Rate</strong></td>
                    <td colspan="2" className={classes.textCenter}><strong>Income Ranges (Single)</strong></td>
                    <td className={classes.textCenter}><strong>Taxable Income</strong></td>
                    <td className={classes.textCenter}><strong>Taxes</strong></td>
                  </tr>
                </thead>
                <tbody className={classes.federalTbody}>
                  <tr>
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>10.00%</td>
                    <td className={classes.textRight}>$0.00</td>
                    <td className={classes.textRight}>$9,875.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>12.00%</td>
                    <td className={classes.textRight}>$9,875.00</td>
                    <td className={classes.textRight}>$40,125.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>22.00%</td>
                    <td className={classes.textRight}>$40,125.00</td>
                    <td className={classes.textRight}>$85,525.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                    <td></td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>24.00%</td>
                    <td className={classes.textRight}>$85,525.00</td>
                    <td className={classes.textRight}>$163,300.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>32.00%</td>
                    <td className={classes.textRight}>$163,300.00</td>
                    <td className={classes.textRight}>$207,350.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>35.00%</td>
                    <td className={classes.textRight}>$207,350.00</td>
                    <td className={classes.textRight}>$518,400.00</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                  <tr >
                    <td>&nbsp;</td>
                    <td className={classes.textRight}>37.00%</td>
                    <td className={classes.textRight}>$518,400.00</td>
                    <td >&nbsp;</td>
                    <td className={classes.textRight}>??</td>
                    <td className={classes.textRight}>??</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="4">&nbsp;</td>
                    <td className={classes.textRight}><strong>??</strong></td>
                    <td className={classes.textRight}><strong>??</strong></td>
                  </tr>
                </tfoot>
              </table>
              <div className={classNames(classes.assetsOptions, "assets-row")}>
              <ul className={classes.exerciseOptions}>
              <li>
                <p>
                  <input type="radio" name="equation" value="1" id="firstOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                  <label className={classNames(classes.spanRadio, challengeAnswer === '1' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '1' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="firstOption">
                  $15,105.50
                  </label>
                </p>
              </li>
              <li>
                <p>
                  <input type="radio" name="equation" value="2" id="secondOption" disabled={btnDisabled} onChange={(e) => answerQuestion(e)} />
                  <label className={classNames(classes.spanRadio, (challengeAnswer === '2' && challengeAnswer === '' && classes.ageChecked), (challengeAnswer === '2' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="secondOption">
                  $18,555.50
                  </label>
                </p>
              </li>
              <li>
                <p>
                  <input type="radio" name="equation" value="3" disabled={btnDisabled} id="thirdOption" onChange={(e) => answerQuestion(e)} />
                  <label className={classNames(classes.spanRadio, challengeAnswer === '3' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '3' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="thirdOption">
                  $20,103.50
                  </label>
                </p>
              </li>
              <li>
                <p>
                  <input type="radio" name="equation" value="4" disabled={btnDisabled} id="fourthOption" onChange={(e) => answerQuestion(e)} />
                  <label className={classNames(classes.spanRadio, challengeAnswer === '4' && challengeAnswer === '' && classes.ageChecked, (challengeAnswer === '4' && challengeAnswer !== '' && (challengeAnswer === selectedAnswer ? classes.radioGreen : classes.radioRed)))} htmlFor="fourthOption">
                  $27,103.50
                  </label>
                </p>
              </li>
            </ul>
              </div>

            </div>
          </div>
        </div>
      )}
    </Typography>
  );
};

BitCoinPopups.propTypes = {
  classes: PropTypes.object.isRequired,
  level:PropTypes.number.isRequired,
  setChallengeAnswer:PropTypes.func.isRequired,
  challengeAnswer:PropTypes.string.isRequired,
  btnDisabled:PropTypes.bool.isRequired,
  setSelectedAnswer:PropTypes.func.isRequired,
  selectedAnswer:PropTypes.string.isRequired

};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(BitCoinPopups);
