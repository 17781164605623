import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

const Homes1 = ( props ) => {
  const { classes, home, handleAssestObject, handleUpdatedObject, handleDefinationPopup} = props;

  const [isFocus, setIsFocus] = React.useState('');

  const updateValue = ( e, value, field, type ) => {
    const inputValues = {...handleAssestObject};
    let newvalue = 0;
    type === 'slider' ? newvalue = value : newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    inputValues[field] = newvalue;
    handleUpdatedObject(inputValues);
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>The value of your home is the price you would be able to sell it for today. Not sure the current value? Check out the links below:</p>
      <p className={ `${classes.existingSpace} ${classes.mb5}` }>
        <a href="https://www.zillow.com/how-much-is-my-home-worth/" target="_blank" rel="noreferrer">Zillow</a>
      </p>
      <p className={ classes.existingSpace }>
        <a href="https://www.redfin.com/what-is-my-home-worth" target="_blank" rel="noreferrer">REDFIN</a>
      </p>
      <p>Your <span className={ classes.definationTittle } onClick={ () => handleDefinationPopup( 'Home Basis' ) }>cost basis</span> is the price you paid for your home plus closing costs and major improvements made to the home (for example, replacing the roof or replacing the siding) Your basis in the home is important for calculating taxes when you go to sell the home.</p>
      <p className={ classes.existingSpace }> Appreciation is the increase in a property’s value from one year to the next. If you could sell your home this year for $500,000 and next year for $510,000, the appreciation was 2.0% ($10,000 / $500,000 = 2.0%). Type in your ZIP Code at <a target="_blank" href="https://www.zillow.com/home-values/" rel="noreferrer">this link</a> to see what home price appreciation has been in your area lately. </p>
      <p> Enter the details about your current home below. If you're not sure of the <span className={ classes.definationTittle } onClick={ () => handleDefinationPopup( 'Appreciation: Real Estate' ) }>appreciation</span> value, just leave the default value and continue. </p>
      <div className={classes.section4TableScroll}>
        <div className={ `${classes.section4Table} ${classes.sliderBottomSpace}` }>
          <div className={ classes.section4TableLeft }>
            <div className={ classes.section4TableLeftDiv }>
              <span className={ classes.section4TableLeftTitle }>Current Home Value</span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.homeValue() }
                  min={ 0 }
                  max={ 1000000 }
                  step={ 1 }
                  value={ home === 1 ? handleAssestObject.existig_home_current_value :  handleAssestObject[`existig_home_current_value_${home}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( home === 1 ? handleAssestObject.existig_home_current_value :  handleAssestObject[`existig_home_current_value_${home}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existig_home_current_value' :  `existig_home_current_value_${home}`, 'slider' ) }
                />
              </div>
            </div>
            <div className={ classes.section4TableLeftDiv }>
              <span className={ classes.section4TableLeftTitle }><span className={classes.textUnderline} onClick={ () => handleDefinationPopup( 'Home Basis' ) }>Basis</span></span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.homeValue() }
                  min={ 0 }
                  max={ 1000000 }
                  step={ 1 }
                  value={ home === 1 ? handleAssestObject.existig_home_basis_costs :  handleAssestObject[`existig_home_basis_costs_${home}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( home === 1 ? handleAssestObject.existig_home_basis_costs :  handleAssestObject[`existig_home_basis_costs_${home}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existig_home_basis_costs' :  `existig_home_basis_costs_${home}`, 'slider' ) }
                />
              </div>
            </div>
            <div className={ classes.section4TableLeftDiv }>
              <span className={ classes.section4TableLeftTitle }><span className={classes.textUnderline} onClick={ () => handleDefinationPopup( 'Appreciation: Real Estate' ) } >Appreciation</span></span>
              <div className={ classes.section4TableLeftSlide }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.businessArray() }
                  min={ 0 }
                  max={ 10 }
                  step={ 1 }
                  value={ home === 1 ? handleAssestObject.existing_home_annual_appreciation :  handleAssestObject[`existing_home_annual_appreciation_${home}`] }
                  valueLabelFormat={ `${commonFunctions.perFormatter( home === 1 ? handleAssestObject.existing_home_annual_appreciation :  handleAssestObject[`existing_home_annual_appreciation_${home}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existing_home_annual_appreciation' :  `existing_home_annual_appreciation_${home}`, 'slider' ) }
                />
              </div>
            </div>
          </div>
          <div className={ classes.section4TableRightOne }>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ home === 1 ? handleAssestObject.existig_home_current_value :  handleAssestObject[`existig_home_current_value_${home}`] } onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existig_home_current_value' : `existig_home_current_value_${home}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <span className={ classes.section4TableRightOneDoller }>$</span>
              <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ home === 1 ? handleAssestObject.existig_home_basis_costs :  handleAssestObject[`existig_home_basis_costs_${home}`] } onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existig_home_basis_costs' : `existig_home_basis_costs_${home}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              <NumberFormat
                customInput={ TextField }
                thousandSeparator
                value={ home === 1 ? handleAssestObject.existing_home_annual_appreciation :  handleAssestObject[`existing_home_annual_appreciation_${home}`] }
                decimalScale={isFocus!=='existing_home_annual_appreciation'?2:5}
                fixedDecimalScale={isFocus!=='existing_home_annual_appreciation'}
                onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existing_home_annual_appreciation' : `existing_home_annual_appreciation_${home}`, 'number' ) }
                onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_home_annual_appreciation') } }
                onBlur={emptyIsFocus} />
              <span className={ classes.textPer }>%</span>
            </div>
          </div>
          <div className={ `${classes.section4TableRightTwo} ${classes.noAfter}` }>
            <div className={ classes.section4TableRightOneDiv }>
              &nbsp;
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              &nbsp;
            </div>
            <div className={ classes.section4TableRightOneDiv }>
              &nbsp;
            </div>
          </div>
          <div className={ classes.sectionClear }></div>
        </div>
      </div>
      {/* <div className={ classes.sliderBottomSpaceNewOneScroll }>
        <div className={ `${classes.sliderBottomSpace} ${classes.sliderBottomSpaceNewOne}` }>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p>Current Home Value</p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.homeValue() }
                  min={ 0 }
                  max={ 1000000 }
                  step={ 1 }
                  value={ home === 1 ? handleAssestObject.existig_home_current_value :  handleAssestObject[`existig_home_current_value_${home}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( home === 1 ? handleAssestObject.existig_home_current_value :  handleAssestObject[`existig_home_current_value_${home}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existig_home_current_value' :  `existig_home_current_value_${home}`, 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat customInput={ TextField } thousandSeparator value={ home === 1 ? handleAssestObject.existig_home_current_value :  handleAssestObject[`existig_home_current_value_${home}`] } onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existig_home_current_value' : `existig_home_current_value_${home}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
              </div>
            </div>
            <div>
              <p onClick={ () => handleDefinationPopup( 'Home Basis' ) }><span className={classes.definationTittle}>Basis</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.homeValue() }
                  min={ 0 }
                  max={ 1000000 }
                  step={ 1 }
                  value={ home === 1 ? handleAssestObject.existig_home_basis_costs :  handleAssestObject[`existig_home_basis_costs_${home}`] }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( home === 1 ? handleAssestObject.existig_home_basis_costs :  handleAssestObject[`existig_home_basis_costs_${home}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existig_home_basis_costs' :  `existig_home_basis_costs_${home}`, 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat customInput={ TextField } thousandSeparator value={ home === 1 ? handleAssestObject.existig_home_basis_costs :  handleAssestObject[`existig_home_basis_costs_${home}`] } onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existig_home_basis_costs' : `existig_home_basis_costs_${home}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
              </div>
            </div>
            <div>
              <p onClick={ () => handleDefinationPopup( 'Appreciation: Real Estate' ) } ><span className={classes.definationTittle}>Appreciation</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.businessArray() }
                  min={ 0 }
                  max={ 10 }
                  step={ 1 }
                  value={ home === 1 ? handleAssestObject.existing_home_annual_appreciation :  handleAssestObject[`existing_home_annual_appreciation_${home}`] }
                  valueLabelFormat={ `${commonFunctions.perFormatter( home === 1 ? handleAssestObject.existing_home_annual_appreciation :  handleAssestObject[`existing_home_annual_appreciation_${home}`] )}` }
                  onChange={ ( e, value ) => updateValue( e, value, home === 1 ? 'existing_home_annual_appreciation' :  `existing_home_annual_appreciation_${home}`, 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                <NumberFormat customInput={ TextField } thousandSeparator value={ home === 1 ? handleAssestObject.existing_home_annual_appreciation :  handleAssestObject[`existing_home_annual_appreciation_${home}`] } onValueChange={ ( e ) => updateValue( e, '', home === 1 ? 'existing_home_annual_appreciation' : `existing_home_annual_appreciation_${home}`, 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
                %
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

Homes1.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( Homes1 );
