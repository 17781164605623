import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import classNames from 'classnames';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import tickMark from '../../../assets/img/finance-explained/email-templates/tick_mark.png';
import styles from './styles';

const InterestRatesandHomeBuying = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Interest Rates and Home Buying </li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />

                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/interest-home-buying.png "
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeadingTitle}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Interest Rates and Home Buying
                    </h3>
                  </div>

                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/interest-home-buying-187px.jpg "
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Sometimes it&apos;s hard to understand how big
                          headlines affect your personal finances. For example,
                          a major financial story in the news lately reads, "The
                          Federal Reserve is expected to raise interest rates
                          for the first time since 2018." What does that mean
                          for your financial life? Let&apos;s look at a specific
                          example of how rising interest rates can affect buying
                          a home.{' '}
                        </p>
                      </div>
                    </div>

                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          <h3 className={classes.paraTitle}>
                            Buying a home 101
                          </h3>
                          The largest financial transaction the average American
                          will make in their lifetime is buying a home. The
                          median sales price for a home at the end of 2021 was{' '}
                          <a
                            href="https://fred.stlouisfed.org/series/MSPNE"
                            target="_blank"
                            rel="noopener noreferrer">
                            $550,000 in the Northeast region
                          </a>{' '}
                          and{' '}
                          <a
                            href="https://fred.stlouisfed.org/series/MSPUS"
                            target="_blank"
                            rel="noopener noreferrer">
                            $408,000 nationwide
                          </a>
                          . This is a large purchase. It&apos;s not something
                          you can just pay with the cash in your wallet or put
                          on your credit card.
                        </p>
                        <p>
                          That&apos;s why a mortgage was invented. A mortgage is
                          a specific loan to help Americans buy homes. The
                          homebuyer needs to bring 10% to 20% of the price of
                          the home in cash and a mortgage (loan) from a
                          financial institution provides the rest of the cash
                          needed to make the purchase. Let&apos;s look at
                          different home buying scenarios and you'll see exactly
                          how interest rates play a pivotal role in the cost of
                          owning a home.
                        </p>
                        <p>
                          <h3 className={classes.paraTitle}>
                            Buying a $550,000 home
                          </h3>
                          Let&apos;s say you are planning to buy a $550,000 home
                          and considering a 10%, 15%, or 20% down payment. Your
                          down payment will determine how much you need to
                          borrow. When you buy a home, you'll also need to pay
                          closing costs. Don&apos;t worry about what{' '}
                          <a
                            href="https://www.investopedia.com/terms/c/closingcosts.asp"
                            target="_blank">
                            closing costs
                          </a>{' '}
                          are in this moment. Just know you'll need more cash
                          than a down payment to buy a home.
                        </p>
                        <p>
                          <div className={classes.feTexttableTwo}>
                            <table>
                              <thead>
                                <tr>
                                  <th>Cash Needed </th>
                                  <th>Scenario A </th>
                                  <th>Scenario B</th>
                                  <th>Scenario C</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Home Price </td>
                                  <td>$550,000 </td>
                                  <td>$550,000 </td>
                                  <td>$550,000</td>
                                </tr>
                                <tr>
                                  <td
                                    className={classNames(
                                      classes.rowItalic,
                                      classes.padL20,
                                    )}>
                                    Down Payment{' '}
                                  </td>
                                  <td className={classes.rowItalic}>10.0% </td>
                                  <td className={classes.rowItalic}>15.0% </td>
                                  <td className={classes.rowItalic}>20.0%</td>
                                </tr>
                                <tr>
                                  <td>Down Payment </td>
                                  <td>$55,000 </td>
                                  <td>$82,500 </td>
                                  <td>$110,000</td>
                                </tr>
                                <tr>
                                  <td
                                    className={classNames(
                                      classes.rowItalic,
                                      classes.padL20,
                                    )}>
                                    Closing Costs{' '}
                                  </td>
                                  <td className={classes.rowItalic}>3.0% </td>
                                  <td className={classes.rowItalic}>3.0% </td>
                                  <td className={classes.rowItalic}>3.0% </td>
                                </tr>
                                <tr>
                                  <td>Closing Costs </td>
                                  <td>$16,500 </td>
                                  <td>$16,500 </td>
                                  <td>$16,500 </td>
                                </tr>
                                <tr
                                  className={classNames(
                                    classes.rowWhite,
                                    classes.bdTop,
                                    classes.bdBottom,
                                  )}>
                                  <td>Total Cash Needed </td>
                                  <td>$71,500 </td>
                                  <td>$99,000 </td>
                                  <td>$126,500</td>
                                </tr>
                                <tr className={classes.rowBlue}>
                                  <td
                                    className={classNames(
                                      classes.rowItalic,
                                      classes.padL20,
                                    )}>
                                    Change{' '}
                                  </td>
                                  <td>--</td>
                                  <td>$27,500 </td>
                                  <td>$55,000</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </p>
                        <p>
                          Now let&apos;s look at how mortgage rates impact our
                          monthly payment for Scenario A, B and C.
                        </p>
                        <p>
                          <div className={classes.feTexttableTwo}>
                            <h3 className={classes.paraTitle}>
                              Scenario A - 10% Down Payment
                            </h3>
                            <table>
                              <thead>
                                <tr>
                                  <th>Scenario A </th>
                                  <th>3.5% Rate </th>
                                  <th>4.0% Rate </th>
                                  <th>4.5% Rate</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Home Price </td>
                                  <td>$550,000 </td>
                                  <td>$550,000 </td>
                                  <td>$550,000 </td>
                                </tr>
                                <tr>
                                  <td>Down Payment </td>
                                  <td>$55,000 </td>
                                  <td>$55,000 </td>
                                  <td>$55,000 </td>
                                </tr>
                                <tr>
                                  <td>Mortgage</td>
                                  <td>$495,000 </td>
                                  <td>$495,000 </td>
                                  <td>$495,000 </td>
                                </tr>
                                <tr>
                                  <td>Interest Rate </td>
                                  <td>3.5% </td>
                                  <td>4.0% </td>
                                  <td>4.5%</td>
                                </tr>
                                <tr>
                                  <td>Terms of the Loan </td>
                                  <td>30 yrs </td>
                                  <td>30 yrs </td>
                                  <td>30 yrs </td>
                                </tr>
                                <tr>
                                  <td>Monthly Payment </td>
                                  <td>$2,223 </td>
                                  <td>$2,363 </td>
                                  <td>$2,508</td>
                                </tr>
                                <tr className={classes.rowBlue}>
                                  <td
                                    className={classNames(
                                      classes.rowItalic,
                                      classes.padL20,
                                    )}>
                                    Monthly Change{' '}
                                  </td>
                                  <td className={classes.rowItalic}>-- </td>
                                  <td className={classes.rowItalic}>$140 </td>
                                  <td className={classes.rowItalic}>$285</td>
                                </tr>
                                <tr className={classes.rowBlue}>
                                  <td
                                    className={classNames(
                                      classes.rowItalic,
                                      classes.padL20,
                                    )}>
                                    Annual Change{' '}
                                  </td>
                                  <td className={classes.rowItalic}>-- </td>
                                  <td className={classes.rowItalic}>$1,685 </td>
                                  <td className={classes.rowItalic}>$3,424</td>
                                </tr>
                                <tr className={classes.rowWhite}>
                                  <td>Total Interest </td>
                                  <td>$305,198 </td>
                                  <td>$355,754 </td>
                                  <td>$407,913</td>
                                </tr>
                                <tr className={classes.rowBlue}>
                                  <td
                                    className={classNames(
                                      classes.rowItalic,
                                      classes.padL20,
                                    )}>
                                    Change
                                  </td>
                                  <td className={classes.rowItalic}>-- </td>
                                  <td className={classes.rowItalic}>
                                    $50,556{' '}
                                  </td>
                                  <td className={classes.rowItalic}>
                                    $102,716
                                  </td>
                                </tr>
                                <tr
                                  className={classNames(
                                    classes.rowWhite,
                                    classes.bdTop,
                                  )}>
                                  <td>
                                    Total Amount Repaid (Prinicipal & Interest){' '}
                                  </td>
                                  <td>$800,198 </td>
                                  <td>$850,754 </td>
                                  <td>$902,913</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </p>

                        <p>
                          <ul className={classes.feTextList}>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                In Scenario A, a 10% down payment requires a
                                $495,000 mortgage to buy the home.
                              </span>
                            </li>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                Moving from a 3.5% rate to a 4.0% rate costs
                                $140 more per month, $1,685 per year and $50,556
                                more in interest over the life of the loan.
                              </span>
                            </li>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                Moving from a 3.5% rate to a 4.5% rate costs
                                $285 more per month, $3,424 per year and
                                $102,716 more in interest over the life of the
                                loan.
                              </span>
                            </li>
                          </ul>
                        </p>

                        <p>
                          <div className={classes.feTexttableTwo}>
                            <h3 className={classes.paraTitle}>
                              Scenario B - 15% Down Payment
                            </h3>
                            <table>
                              <thead>
                                <tr>
                                  <th>Scenario B </th>
                                  <th>3.5% Rate </th>
                                  <th>4.0% Rate </th>
                                  <th>4.5% Rate</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Home Price </td>
                                  <td>$550,000 </td>
                                  <td>$550,000 </td>
                                  <td>$550,000 </td>
                                </tr>
                                <tr>
                                  <td>Down Payment </td>
                                  <td>$82,500 </td>
                                  <td>$82,500 </td>
                                  <td>$82,500 </td>
                                </tr>
                                <tr>
                                  <td>Mortgage </td>
                                  <td>$467,500 </td>
                                  <td>$467,500 </td>
                                  <td>$467,500 </td>
                                </tr>
                                <tr>
                                  <td>Interest Rate </td>
                                  <td>3.5% </td>
                                  <td>4.0% </td>
                                  <td>4.5% </td>
                                </tr>
                                <tr>
                                  <td>Terms of the Loan </td>
                                  <td>30 yrs </td>
                                  <td>30 yrs </td>
                                  <td>30 yrs </td>
                                </tr>
                                <tr>
                                  <td>Monthly Payment </td>
                                  <td>$2,099 </td>
                                  <td>$2,232 </td>
                                  <td>$2,369</td>
                                </tr>
                                <tr className={classes.rowBlue}>
                                  <td
                                    className={classNames(
                                      classes.rowItalic,
                                      classes.padL20,
                                    )}>
                                    Monthly Change{' '}
                                  </td>
                                  <td className={classes.rowItalic}>-- </td>
                                  <td className={classes.rowItalic}>$133 </td>
                                  <td className={classes.rowItalic}>$269</td>
                                </tr>
                                <tr className={classes.rowBlue}>
                                  <td
                                    className={classNames(
                                      classes.rowItalic,
                                      classes.padL20,
                                    )}>
                                    Annual Change{' '}
                                  </td>
                                  <td className={classes.rowItalic}>-- </td>
                                  <td className={classes.rowItalic}>$1,592 </td>
                                  <td className={classes.rowItalic}>$3,234</td>
                                </tr>
                                <tr className={classes.rowWhite}>
                                  <td>Total Interest </td>
                                  <td>$288,242 </td>
                                  <td>$355,990 </td>
                                  <td>$385,251</td>
                                </tr>
                                <tr className={classes.rowBlue}>
                                  <td
                                    className={classNames(
                                      classes.rowItalic,
                                      classes.padL20,
                                    )}>
                                    Change{' '}
                                  </td>
                                  <td className={classes.rowItalic}>-- </td>
                                  <td className={classes.rowItalic}>
                                    $47,748{' '}
                                  </td>
                                  <td className={classes.rowItalic}>$97,009</td>
                                </tr>
                                <tr
                                  className={classNames(
                                    classes.rowWhite,
                                    classes.bdTop,
                                  )}>
                                  <td>
                                    Total Amount Repaid (Prinicipal & Interest){' '}
                                  </td>
                                  <td>$755,742 </td>
                                  <td>$803,490 </td>
                                  <td>$852,751</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </p>
                        <p>
                          <ul className={classes.feTextList}>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                In Scenario B, a 15% down payment requires a
                                $467,500 mortgage to buy the home. More cash is
                                needed upfront than A, but there is a smaller
                                mortgage, lower monthly payment and lower cost
                                to own the home.
                              </span>
                            </li>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                Moving from a 3.5% rate to a 4.0% rate costs
                                $133 more per month, $1,592 per year and $47,748
                                more in interest over the life of the loan.
                              </span>
                            </li>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                Moving from a 3.5% rate to a 4.5% rate costs
                                $269 more per month, $3,234 per year and $97,009
                                more in interest over the life of the loan.
                              </span>
                            </li>
                          </ul>
                        </p>
                        <p>
                          <div className={classes.feTexttableTwo}>
                            <h3 className={classes.paraTitle}>
                              Scenario C - 20% Down Payment
                            </h3>
                            <table>
                              <thead>
                                <tr>
                                  <th>Scenario C </th>
                                  <th>3.5% Rate </th>
                                  <th>4.0% Rate </th>
                                  <th>4.5% Rate</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Home Price </td>
                                  <td>$550,000 </td>
                                  <td>$550,000 </td>
                                  <td>$550,000 </td>
                                </tr>
                                <tr>
                                  <td>Down Payment </td>
                                  <td>$110,000 </td>
                                  <td>$110,000 </td>
                                  <td>$110,000 </td>
                                </tr>
                                <tr>
                                  <td>Mortgage</td>
                                  <td>$440,000 </td>
                                  <td>$440,000 </td>
                                  <td>$440,000 </td>
                                </tr>
                                <tr>
                                  <td>Interest Rate </td>
                                  <td>3.5% </td>
                                  <td>4.0% </td>
                                  <td>4.5%</td>
                                </tr>
                                <tr>
                                  <td>Terms of the Loan </td>
                                  <td>30 yrs </td>
                                  <td>30 yrs </td>
                                  <td>30 yrs </td>
                                </tr>
                                <tr>
                                  <td>Monthly Payment </td>
                                  <td>$1,976 </td>
                                  <td>$2,101 </td>
                                  <td>$2,229</td>
                                </tr>
                                <tr className={classes.rowBlue}>
                                  <td
                                    className={classNames(
                                      classes.rowItalic,
                                      classes.padL20,
                                    )}>
                                    Monthly Change{' '}
                                  </td>
                                  <td className={classes.rowItalic}>-- </td>
                                  <td className={classes.rowItalic}>$125 </td>
                                  <td className={classes.rowItalic}>$254</td>
                                </tr>
                                <tr className={classes.rowBlue}>
                                  <td
                                    className={classNames(
                                      classes.rowItalic,
                                      classes.padL20,
                                    )}>
                                    Annual Change{' '}
                                  </td>
                                  <td className={classes.rowItalic}>-- </td>
                                  <td className={classes.rowItalic}>$1,498 </td>
                                  <td className={classes.rowItalic}>$3,043</td>
                                </tr>
                                <tr className={classes.rowWhite}>
                                  <td>Total Interest </td>
                                  <td>$271,287 </td>
                                  <td>$316,226 </td>
                                  <td>$362,590</td>
                                </tr>
                                <tr className={classes.rowBlue}>
                                  <td
                                    className={classNames(
                                      classes.rowItalic,
                                      classes.padL20,
                                    )}>
                                    Change
                                  </td>
                                  <td lassName={classes.rowItalic}>-- </td>
                                  <td lassName={classes.rowItalic}>$44,939 </td>
                                  <td lassName={classes.rowItalic}>$91,303</td>
                                </tr>
                                <tr
                                  className={classNames(
                                    classes.rowWhite,
                                    classes.bdTop,
                                  )}>
                                  <td>
                                    Total Amount Repaid (Prinicipal & Interest){' '}
                                  </td>
                                  <td>$711,287 </td>
                                  <td>$756,226 </td>
                                  <td>$802,590</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </p>

                        <p>
                          <ul className={classes.feTextList}>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                In Scenario C, a 20% down payment requires a
                                $440,000 mortgage to buy the home. More cash is
                                needed upfront than A and B, but there is a
                                smaller mortgage, lower monthly payment and
                                lower cost to own the home.
                              </span>
                            </li>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                Moving from a 3.5% rate to a 4.0% rate costs
                                $125 more per month, $1,498 per year and $44,939
                                more in interest over the life of the loan.
                              </span>
                            </li>
                            <li>
                              <figure>
                                <img src={tickMark} alt="" />
                              </figure>
                              <span>
                                Moving from a 3.5% rate to a 4.5% rate costs
                                $254 more per month, $3,043 per year and $91,303
                                more in interest over the life of the loan.
                              </span>
                            </li>
                          </ul>
                        </p>
                        <p>
                          When the Federal Reserve raises rates, the rates on
                          all loans including mortgages tend to increase as
                          well. When the interest rate on a mortgage is higher,
                          the monthly payment is higher and the cost of owning a
                          home increases. Check out current{' '}
                          <a
                            href="https://www.bankrate.com/mortgages/mortgage-rates/"
                            target="_blank"
                            rel="noopener noreferrer">
                            mortgage rates based on credit score here.
                          </a>{' '}
                        </p>
                        <p>
                          If you're planning on buying a home today, tomorrow or
                          in the next year, keep an eye on mortgage interest
                          rates as they will have a direct impact on how much
                          home you can afford.
                        </p>
                      </div>
                    </div>

                    <div className={classes.hitTapRow}>
                      <h3>Just hit the tape</h3>
                      <p>
                        <ul>
                          <li>
                            {' '}
                            <a
                              href="https://www.reuters.com/world/us/goldman-sachs-expecting-five-rate-hikes-this-year-2022-01-29/"
                              target="_blank"
                              rel="noopener noreferrer">
                              Goldman Sachs expecting five interest rate hikes
                              this year
                            </a>{' '}
                          </li>
                          <li>
                            {' '}
                            <a
                              href="https://www.glassdoor.com/Award/Best-Places-to-Work-LST_KQ0,19.htm"
                              target="_blank"
                              rel="noopener noreferrer">
                              Top 100 places to work in 2022 according to
                              Glassdoor
                            </a>{' '}
                          </li>
                          <li>
                            {' '}
                            <a
                              href="https://upcea.edu/new-research-answers-question-every-college-wants-to-know-why-do-students-leave-and-how-do-we-get-them-back/"
                              target="_blank"
                              rel="noopener noreferrer">
                              The number one reason students drop out of college
                              is money
                            </a>{' '}
                          </li>
                        </ul>
                      </p>
                      <p>
                        Get smarter about your money. Explore more financial
                        terms on our website in{' '}
                        <Link to="/finance-explained">Finance Explained.</Link>{' '}
                      </p>
                    </div>

                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
InterestRatesandHomeBuying.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InterestRatesandHomeBuying);
