import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import Loading from '../../ui/loading/loading';
import Gate from './gate';

// const ME_QUERY = loader( './../../../graphql/schema/user/me.graphql' );

/**
 * Render the gate component
 * @param {*} data logged In User
 */
const renderGateComponent = ( data, props ) => {
  const { component, layout, allowedRoles } = props;

  return (
    <Gate
      component={ component }
      layout={ layout }
      allowedRoles={ allowedRoles }
      loggedInUser={ data.me }
    />
  );
};

/**
 * Renders authenticated component
 *
 * @returns
 * @memberof Authenticated
 */
const renderAuthenticated = ( ) => <Loading theme="fullHeight" />;

/**
 * HOC to check if there is a user session.
 */
const Authenticated = ( props ) => ( renderAuthenticated( props ) );

renderGateComponent.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
  allowedRoles: PropTypes.arrayOf( PropTypes.string ).isRequired,
};

Authenticated.propTypes = {
  component: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired,
  allowedRoles: PropTypes.arrayOf( PropTypes.string ).isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance( Authenticated );
