import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import ReactTooltip from 'react-tooltip';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import * as commonFunctions from '../../../utilities/commonFunctions';
import * as moduleServices from '../../../calculations/modules-services';
import styles from '../../../screens/pwi-career-advancement/styles';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';

const arrayValuesMatching = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 3,
    scaledValue: 3,
    label: '3%',
  },
  {
    value: 6,
    scaledValue: 6,
    label: '6%',
  },
  {
    value: 9,
    scaledValue: 9,
    label: '9%',
  },
  {
    value: 12,
    scaledValue: 12,
    label: '12%',
  },
  {
    value: 15,
    scaledValue: 15,
    label: '15%',
  },
];
const HSASummary = ( props ) => {
  const {
    classes, handleCareerObject,  handleUpdatedObject,
  } = props;
  const [personalValue, setPeronalValue] = React.useState( 7.00 );
  // const [macthingValue, setMatchingValue] = React.useState( 7 );
  const [isFocus, setIsFocus] = React.useState('');

  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.hsa_annual_expected !== undefined ) {
      setPeronalValue( handleCareerObject.hsa_annual_expected );
    } else {
      updatedValues['hsa_annual_expected'] = 7.00;
    }
    handleUpdatedObject(updatedValues);
    // eslint-disable-next-line
    }, [] );

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'number' ) {
      newvalue = e.floatValue;
      newvalue = newvalue > 100 ? 100 : newvalue;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    setPeronalValue( newvalue );
    updatedValues[field] = newvalue;
    handleUpdatedObject(updatedValues);
  };

  const emptyIsFocus = () => {
    setIsFocus('')
  };

  return (
    <div className={ classes.contentBlock }>
      <p>How much do you expect to earn on your HSA contributions? Health savings accounts can be invested in any funds and the expected returns will vary based on your investment strategy. Do you plan on using a savings account or making long-term investments with your HSA?</p>
      <p>Enter your annual return below.</p>
      <div className={ classes.livingExpencesRow }>
        <div className={ classes.livingExpencesDiv }>
          <div className={ classes.livingExpences }>
            <div className={ `${classes.livingExpencesRangeFlex} ${classes.livingExpencesRangeFlexNewPensionCont} ${classes.mainFullBlock} ${classes.mainFullBlockFull}` }>
              <div className={ classes.rentGraphBlock }>
                <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangeFlexNew} ` }>
                  <p><span>Annual Expected Pre-Tax Return</span></p>
                  <div className={ classes.annuvalIncomeGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ arrayValuesMatching }
                      min={ 0 }
                      max={ 15 }
                      step={ 0.1 }
                      value={ personalValue }
                      valueLabelFormat={ `${commonFunctions.perFormatter( parseFloat( personalValue ).toFixed( 2 ) )}` }
                      onChange={ ( e, value ) => updateValue( e, value, 'hsa_annual_expected', 'slider' ) }
                    />
                  </div>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <div className={ `${classes.annualInput} ${classes.annualInputRight}` }>
                    <NumberFormat
                      customInput={ TextField }
                      thousandSeparator
                      value={ personalValue }
                      decimalScale={isFocus!=='hsa_annual_expected'?2:5}
                      fixedDecimalScale={isFocus!=='hsa_annual_expected'}
                      onValueChange={ ( e ) => updateValue( e, '', 'hsa_annual_expected', 'number' ) }
                      onFocus={ ( e ) => {
                        e.target.select();
                        setIsFocus('hsa_annual_expected')
                      } }
                      onBlur={emptyIsFocus}
                    />
                    <span>%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={ `${classes.heigherEducationSearch} ${classes.tutionFeesTable}` }>
        <div className={ `${classes.higherEducationStepFour} ${classes.tutionFeesTable70New}` }>
          <table cellPadding="0" cellSpacing="0">
            <thead class="">
              <tr className={ classes.trHead }>
                <td colspan="2" classname={classes.textCenter}>HSA Summary</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Beginning HSA Balance
                </td>
                <td>
                  {moduleServices.module17Data.furtherCareerHsaBeginningBalanceSummary !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerHsaBeginningBalanceSummary} prefix={moduleServices.module17Data.furtherCareerHsaBeginningBalanceSummary >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerHsaBeginningBalanceSummary < 0 && ')'} />
                  ) : ('$0')}
                </td>
              </tr>
              <tr>
                <td>
                  Total Contributions
                </td>
                <td>
                  <span>
                    {moduleServices.module17Data.furtherCareerHsaTotalContributionSummary !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerHsaTotalContributionSummary} prefix={moduleServices.module17Data.furtherCareerHsaTotalContributionSummary >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerHsaTotalContributionSummary < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  Total Earnings
                </td>
                <td>
                  <span>
                    {moduleServices.module17Data.furtherCareerHsaTotalEarningsSummary !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerHsaTotalEarningsSummary} prefix={moduleServices.module17Data.furtherCareerHsaTotalEarningsSummary >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerHsaTotalEarningsSummary < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
              </tr>
              <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                <td>
                  Ending HSA Balance
                </td>
                <td>
                  <span>                                            
                    {moduleServices.module17Data.furtherCareerHsaTotalEndingBalanceSummary !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerHsaTotalEndingBalanceSummary} prefix={moduleServices.module17Data.furtherCareerHsaTotalEndingBalanceSummary >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerHsaTotalEndingBalanceSummary < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

HSASummary.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  // handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( HSASummary );
