const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    width: '100%',
    boxSizing: 'border-box',
  },
  pricingContent: {
    padding: '100px 0 0 0',
    position: 'relative',
    textAlign: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '50px 0 0 0',
    },
    '& h2': {
      padding: '0 0 30px 0',
      margin: '0',
      fontFamily: '"MuseoSans-100"',
      fontSize: '48px',
      lineHeight: '55px',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '37px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
        lineHeight: '30px',
      },
    },
    '& h3': {
      padding: '0 0 60px 0',
      margin: '0',
      fontFamily: '"MuseoSans-100"',
      fontSize: '30px',
      lineHeight: '30px',
      position: 'relative',
      zIndex: '9',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        padding: '0 0 30px 0',
      }
    },
  },
  pricingImage: {
    textAlign: 'center',
    margin: '0 0 50px 0',
    '& img': {
      width: '447px',
      height: '100%',
      verticalAlign: 'middle',
      [theme.breakpoints.down( 'xs' )]: {
        width: 'auto',
        height: '270px',
      },
    },
  },
  affordablePlan: {
    background: theme.palette.common.greenlight,
    padding: '35px 55px',
    maxWidth: '1073px',
    // margin: '0 auto -475px auto',
    overflow: 'hidden',
    textAlign: 'left',
    position: 'relative',
    zIndex: '2',

    '& h4': {
      fontFamily: '"MuseoSans-500"',
      fontSize: '40px',
      lineHeight: '40px',
      color: theme.palette.common.white,
      margin: '0',
      padding: '0 0 20px 0',
      textAlign: 'center',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '35px',
        fontWeight: 'normal',
      },
      [theme.breakpoints.down( 'xs' )]: {
        lineHeight: '30px',
        fontSize: '25px',
        fontWeight: 'normal',
      },
    },
    '& p': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '26px',
      lineHeight: '36px',
      color: '#ffffff',
      padding: '0 0 0 0',
      margin: '0',
      '&:last-child': {
        padding: '0',
      },
      '& b': {
        fontWeight: 'normal',
        fontFamily: '"MuseoSans-500"',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '24px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        lineHeight: '30px',
        marginBottom: '10px',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '24px 30px',
      // margin: '0 auto -300px auto',
    },
  },
  downTriangle: {
    height: '405px',
    // bottom: '-403px',
    boxSizing: 'content-box',
    width: '50%',
    paddingLeft: '50%',
    paddingTop: '0',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '1',
    '& div': {
      marginLeft: '-800px',
      borderLeft: '800px solid transparent',
      borderRight: '800px solid transparent',
      borderTop: '125px solid #fbfdfd',
      width: '0',
      height: '0',
      marginTop: '0',
    },
  },
  peaceOfMind: {
    padding: '520px 0 50px 0',
    background: theme.palette.common.blue,
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '350px 0 50px 0',
    },
    '& h2': {
      color: theme.palette.common.white,
      padding: '0 0 40px 0',
      margin: '0',
      fontFamily: '"MuseoSans-300"',
      fontSize: '36px',
      lineHeight: '54px',
      textAlign: 'center',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '23px',
        lineHeight: '40px',
      },
    },
  },
  pricingBanner: {
    position: 'relative',
  },
  yourChoice: {
    paddingTop: '20px',
    '& h2': {
      fontSize: '36px',
      lineHeight: '60px',
      textAlign: 'center',
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.blue,
      margin: '20px 0px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '30px',
        lineHeight: '45px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
        lineHeight: '30px',
      },
    },
    '& p': {
      fontSize: '24px',
      lineHeight: '36px',
      fontFamily: '"MuseoSans-100"',
      color: theme.palette.common.blue,
      padding: '0 0px',
      margin: '0px auto',
      width: '82%',
      [theme.breakpoints.down( 'xs' )]: {
        padding: '0 10px',
        fontSize: '20px',
        lineHeight: '30px',
      },
    },
  },
  peaceRow: {
    '& h2': {
      '& span': {
        display: 'inline-block',
        width: '100%',
      },
    },
  },
  planPrices: {
    zIndex: '99',
    position: 'relative',
    textAlign: 'center',
    '& container': {
      margin: '0px',
    },
  },
  priceContainer: {
    padding: '0px 15px',
  },
  pricingTabs: {
    position: 'relative',
    margin: '50px 0px 0px 0px',
  },
  pricingBlock: {
    border: '1px solid #0069aa',
    borderRadius: '0',
    margin: '30px 10px 50px',
    background: theme.palette.common.white,
    webkitBoxShadow: '0 1px 1px rgba(0,0,0,.05)',
    boxShadow: '0 1px 1px rgba(0,0,0,.05)',
    boxSizing: 'border-box',
    // [theme.breakpoints.down( 'xs' )]: {
    //   margin: '0px 0px 20px',
    // },
  },
  pricingTitle: {
    position: 'relative',
    borderBottom: '1px solid #fff',
    background: '#0069aa',
    borderRadius: '0',
    padding: '10px 0',
    color: '#fff',
    fontSize: '27px',
    fontFamily: '"MuseoSans-300"',
  },
  triangleImg: {
    position: 'absolute',
    width: '100%',
    marginTop: '-250px',
    boxSizing: 'border-box',
    '& img': {
      height: '590px',
      verticalAlign: 'middle',
      width: '100%',
    },
  },
  faqSection: {
    position: 'relative',
    '& h2': {
      color: theme.palette.common.blue,
      textAlign: 'center',
    },
  },
  pricingBody: {
    paddingLeft: '30px',
    paddingRight: '30px',
    '& button': {
      background: '0 0',
      border: '2px solid #f69a34',
      color: '#f69a34',
      margin: '20px auto',
      display: 'block',
      fontSize: '18px',
      lineHeight: '18px',
      padding: '12px 15px',
      borderRadius: '8px',
      maxWidth: '200px',
      width: '100%',
      textTransform: 'uppercase',
      textDecoration: 'none',
      boxSizing: 'border-box',
      '&:hover': {
        background: '#f69a34',
        border: '2px solid #f69a34',
        color: theme.palette.common.white,
      },
    },
  },
  planPrice: {
    fontSize: '50px',
    color: '#000',
    margin: '20px',
    fontWeight: '700',
    fontFamily: '"MuseoSans-100"',
  },
  planText: {
    fontSize: '18px',
    fontFamily: '"MuseoSans-100"',
    color: theme.palette.common.black,
    '& span': {
      display: 'inline-block',
      width: '100%',
    },
  },
  pricingFaq: {
    padding: '50px 0px 70px 0px',
    '& h2': {
      fontSize: '30px',
      textAlign: 'center',
      color: theme.palette.common.blue,
      fontFamily: '"MuseoSans-500"',
      fontWeight: '500',
      margin: '20px auto 10px auto',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '23px',
        padding: '0',
        margin: '0px',
      },
    },
  },
  pricingTable: {
    width: '100%',
    marginBottom: '20px',
    border: 'none',
    borderSpacing: '0px',
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    background: '#fff',
    '& thead': {
      '& tr': {
        '& th': {
          '&:first-child': {
            width: '46%',
          },
          padding: '8px',
          lineHeight: '25px',
          fontSize: '20px',
          border: '1px solid #0069aa',
          color: theme.palette.common.white,
          background: '#0069aa',
          fontWeight: 'normal',
          textAlign: 'center',
          '& span': {
            display: 'inline-block',
            width: '100%',
          },
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '16px',
            wordBreak: 'break-all',
            padding: '6px',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          '&:nth-child(1)': {
            textAlign: 'left',
            padding: '13px 12px 0',
          },
          fontFamily: '"MuseoSans-300"',
          fontSize: '16px',
          color: '#323232',
          padding: '8px',
          lineHeight: '1.42857143',
          textAlign: 'center',
          border: '1px solid #b7b7b7',
          '& img': {
            width: '10px',
          },
        },
      },
    },
    '& tfoot': {
      '& td': {
        padding: '8px',
      },
    },
  },
  coloredRow: {
    '& td': {
      background: '#d1e4f0',
    },
  },

  buyButtons: {
    '& td': {
      '& button': {
        maxWidth: '190px',
        margin: '0',
        width: '100%',
        color: '#539cc9',
        border: '2px solid #539cc9',
        background: '#fff',
        marginTop: '20px',
        display: 'block',
        fontSize: '18px',
        lineHeight: '18px',
        padding: '18px',
        borderRadius: '8px',
        textAlign: 'center',
        textTransform: 'uppercase',
        textDecoration: 'none',
        '&:hover': {
          backgroundColor: '#539cc9',
          color: theme.palette.common.white,
        },
        [theme.breakpoints.down( 'sm' )]: {
          fontSize: '15px',
        },
        [theme.breakpoints.down( 'sm' )]: {
          fontSize: '12px',
          padding: '8px',
        },
      },
    },
  },
  pricingPara: {
    fontSize: '20px',
    fontFamily: '"MuseoSans-300"',
    lineHeight: '27px',
    display: 'inline-block',
    textAlign: 'left',
    '& span': {
      fontSize: '20px',
      fontFamily: '"MuseoSans-300"',
      lineHeight: '27px',
      display: 'inline-block',
      textAlign: 'left',
      color: theme.palette.common.blue,
    },
  },
  pricingOnline: {
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
    },
    '& img': {
      width: 'auto',
      maxWidth: '200px',
      maxHeight: '200px',
      marginRight: '30px',
    },
  },
  courseContnt: {
    textAlign: 'left',
    fontSize: '18px',
    paddingRight: '0',
    lineHeight: '27px',
    maxWidth: '455px',
    color: theme.palette.common.black,
    '& h2': {
      fontFamily: '"MuseoSans-300"',
      marginTop: '0',
      fontSize: '24px',
      color: theme.palette.common.blue,
      fontWeight: 'normal',
      '& sup': {
        fontSize: '10px',
        fontFamily: '"MuseoSans-300"',
      },
    },
  },
  tabHeader: {
    color: '#fff',
  },
  priceTabs: {
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '20px',
    },
    '& header': {
      position: 'relative',
      background: 'transparent',
      boxShadow: 'none',
      zIndex: '0',
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '1px',
        background: '#fff',
        bottom: '0px',
      },
    },
    '& flexContainer + span': {
      height: '0px',
      display: 'none',
    },
  },
  brochureBtn: {
    '& a': {
      maxWidth: '290px',
      backgroundColor: '#539cc9',
      border: '2px solid #539cc9',
      color: theme.palette.common.white,
      marginTop: '20px',
      display: 'block',
      fontSize: '18px',
      lineHeight: '18px',
      padding: '18px',
      borderRadius: '8px',
      textAlign: 'center',
      textTransform: 'uppercase',
      textDecoration: 'none',
      '&:hover': {
        border: '2px solid #539cc9',
        background: '#fff',
        color: '#539cc9',
      },
    },
  },
  contactAddress: {
    fontFamily: '"MuseoSans-100"',
    fontSize: '18px',
    marginTop: '20px',
    '& span': {
      width: '100%',
      display: 'inline-block',
    },
  },
  blueColor: {
    color: theme.palette.common.blue,
  },
  universeRow: {
    background: theme.palette.common.white,
    borderRadius: '10px 10px 6px 6px',
    boxSizing: 'border-box',
    padding: '0 15px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    minHeight: '700px',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'flex',
      '& > div': {
        width: '100%',
      },
    },
  },
  leftBlock: {
    width: '58%',
    paddingLeft: '0',
    display: 'block',
    padding: '60px 14px 20px 14px',
    textAlign: 'center',
    position: 'relative',
    borderRight: '0px solid #bcbcbc',
    [theme.breakpoints.down( 'xs' )]: {
      paddingTop: '20px',
    },
    '&:before': {
      content: '""',
      width: '2px',
      height: '320px',
      position: 'absolute',
      background: '#b7b7b7',
      right: '-2px',
      top: '0',
      [theme.breakpoints.down( 'xs' )]: {
        display: 'none',
      },
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 'auto',
      right: '-6px',
      width: '10px',
      height: '10px',
      top: '320px',
      borderRadius: '100%',
      background: '#b7b7b7',
      [theme.breakpoints.down( 'xs' )]: {
        display: 'none',
      },
    },
  },
  rightBlock: {
    width: '33%',
    margin: 'auto',
    textAlign: 'center',
    position: 'relative',
    borderRight: '0px solid #bcbcbc',
    paddingBottom: '20px',
    '& h4': {
      fontSize: '60px',
      lineHeight: '60px',
      color: theme.palette.common.black,
      margin: '0 0 8px 0',
      fontFamily: '"MuseoSans-100"',
      fontWeight: 'normal',
    },
    '& p': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '14px',
      lineHeight: '14px',
      margin: '0 0 25px 0',
      color: '#525252',
    },
  },
  pricingLogos: {
    marginTop: '50px',
    '& img': {
      marginRight: '0',
      maxWidth: '230px',
      maxHeight: '100%',
      verticalAlign: 'middle',
      width: 'auto',
    },
    '&:last-child': {
      '& img': {
        width: '127px',
      },
      '& p': {
        color: theme.palette.common.blue,
        fontSize: '27px',
        fontFamily: '"MuseoSans-300"',
        lineHeight: 'inherit',
      },
    },
  },
  quotesBlock: {
    '&:before': {
      content: '""',
      width: '2px',
      height: '160px',
      background: '#b7b7b7',
      position: 'absolute',
      right: '-3px',
      bottom: '0',
      [theme.breakpoints.down( 'xs' )]: {
        display: 'none',
      },
    },
    '&:after': {
      content: '""',
      width: '10px',
      height: '10px',
      background: '#b7b7b7',
      position: 'absolute',
      right: '-7px',
      bottom: '160px',
      borderRadius: '50%',
      [theme.breakpoints.down( 'xs' )]: {
        display: 'none',
      },
    },
  },
  faqContent: {
    marginTop: '30px',
    width: '80%',
    marginLeft: '10%',
    [theme.breakpoints.down( 'xs' )]: {
      width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  faqQuestion: {
    fontSize: '20px',
    fontFamily: '"MuseoSans-300"',
    padding: '0',
    color: '#000',
    fontWeight: 'normal',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
    },
  },
  faqDesc: {
    bordertop: '1px solid #ddd',
    fontSize: '16px',
    margin: '8px 0 0',
    '& a': {
      color: '#23527c',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'underline',
        color: '#F7941E',
      },
    },
  },
  faqBlock: {
    border: '1px solid #ddd',
    marginTop: '5px',
    borderRadius: '4px',
    position: 'relative',
    boxSizing: 'border-box',
    '& svg': {
      color: '#0069aa',
      width: '30px',
      height: '30px',
    },
    '& > div': {
      boxShadow: 'none',
    },
  },
  faqTitle: {
    '& i': {
      borderRadius: '50%',
      padding: '3px',
      fontSize: '20px',
    },
    '& > div': {
      minHeight: '68px !important',
      height: '68px',
      maxHeight: '68px',
    },
    '&:hover': {
      background: '#e5e5e5',
    },
  },
  faqDescription: {
    borderTop: '1px solid #ddd',
  },
  tabsIndvi: {
    minHeight: 'auto',
    '& > div > div': {
      display: 'block',
      borderBottom: '1px solid #fff',
      [theme.breakpoints.down( 'xs' )]: {
        overflowX: 'auto',
      },
      '& + span': {
        display: 'block',
        backgroundColor: '#1f96e0',
        width: '248px !important',
        borderLeft: '1px solid #fff',
        [theme.breakpoints.down( 'xs' )]: {
          width: '148px !important',
        },
      },
      '& button': {
        border: 'solid #fff',
        marginRight: '15px',
        borderBottom: '0',
        color: '#fff',
        fontSize: '20px',
        borderRadius: '15px 15px 0 0',
        position: 'relative',
        width: '250px',
        top: '0',
        borderWidth: '1px 1px 0 1px',
        minHeight: 'inherit',
        textTransform: 'inherit',
        padding: '0',
        opacity: '0.5',
        [theme.breakpoints.down( 'xs' )]: {
          width: '150px',
          fontSize: '13px',
          marginRight: '5px',
        },
      },
    },
  },
  SwipeTabsPanel: {
    '& > div': {
      padding: '0',
    },
  },
  leftPadding: {
    '& td:first-child': {
      paddingLeft: '25px !important',

    },
  },
  pricingTableOverflow: {
    overflow: 'auto',
    '& table': {
      minWidth: '800px',
    },
  },
  studentsLifeGridContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '50px',
    justifyContent: 'center',
    '& > div': {
        background: '#1f96e0',
        padding: '65px 40px !important',
        color: '#fff',
        '&:nth-child(2)': {
            background: '#0069aa',
            padding: '75px 40px !important',
        },
        '& p': {
          fontSize: '24px',
          fontFamily: 'MuseoSans-100',
          color: '#fff',
          textAlign: 'left',
          margin: '0',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '20px',
          },
          '& span': {
            display: 'block',
          },
          '& b':{
            fontFamily: 'MuseoSans-500',
            fontWeight: 'normal',
          }
        },
        '& font': {
          marginTop: '20px',
          fontSize: '18px',
          fontFamily: 'MuseoSans-100',
          display: 'block',
          textAlign: 'right',
          marginBottom: '10px',
          '& span': {
            display: 'block',
          }
          
        }
    }
  },
  powerUpImagesContainer: {
    display: 'flex',
    maxWidth: '80%',
    justifyContent: 'center',
    // alignItems: 'center',
    margin: '50px auto 50px',
    [theme.breakpoints.down( 'xs' )]: {
      margin: '20px auto 20px',
    },
    '& img': {
      width: '100%',
      maxWidth: '300px',
    }
  },
  affordablePlanListNew: {
    padding: '0',
    color: '#fff',
    fontFamily: '"MuseoSans-100"',
    // listStyle: 'inside',
    marginTop: '0',
    fontSize: '26px',
    paddingLeft: '50px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '20px',
      lineHeight: '30px',
      paddingLeft: '25px',
    },
    '& li':{
      marginBottom: '5px',
      '& b':{
        fontFamily: '"MuseoSans-500"',
        fontWeight: 'normal',
      }
    }
  },
  downloadBroucher: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    '& a': {
      fontSize: '25px',
       color: '#fff',
       display: 'flex',
       alignItems: 'center',
       '&:hover': {
          textDecoration: 'underline',
          color: '#f89b22',
        },
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '16px',
        },
        '& figure':{
          margin: '0px',
          lineHeight: '0px',
          '& img':{
            maxWidth: '230px',
            [theme.breakpoints.down( 'xs' )]: {
              maxWidth: '100%',
              width: '100%',
            }
          }
        },
        '& p':{
          display: 'inline-block',
          color: '#fff',
          textTransform: 'uppercase',
          fontSize: '25px',
          marginLeft: '20px',
          marginBottom: '0',
          marginTop: '0px',
        }
      }
    },
    // priceContainer: {
    //   display: 'flex',
    //   justifyContent: 'center',
    // },
    tableAdmin: {
      position: 'relative',
      zIndex: '9',
      '& table': {
        background: '#fff',
        minWidth: '100%',
        borderCollapse: 'collapse',
        border: '1px solid #ccc',
        '& th': {
          fontSize: '20px',
          fontWeight: '400',              
          textAlign: 'left',
          padding: '8px',
          border: '1px solid #0069aa',
          backgroundColor: '#0069aa',
          color: '#fff',
          fontFamily: '"MuseoSans-500"',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '16px',
            lineHeight: '22px',
          }
        },
        '& td': {
          fontSize: '16px',
          padding: '8px 27px',
          borderBottom: '1px solid #ccc',
          fontFamily: '"MuseoSans-100"',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '14px',
          }          
        }
      }
    },
    blueRowNew: {
      background: "#d1e4f0",
      '& td':{
        padding: '8px !important',
      }
    },
    studentSee: {
      background: '#84a84d',
      color: '#fff',
      fontSize: '24px',
      padding: '60px 0 40px',
      margin: '50px 0 40px',
      '& img': {
        width: '100%',
        maxWidth: '141px',
        marginTop: '80px',
        [theme.breakpoints.down( 'sm' )]: {
          marginTop: '20px',
        }
      },
      '& h3': {
        color: '#fff',
        textAlign: 'left',
        fontSize: '34px',
        marginTop: '0',
        marginBottom: '30px',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '25px',
        }
      },
      '& p': {
        fontSize: '24px',
        fontFamily: 'MuseoSans-100',
        marginBottom: '0',
        paddingBottom: '3px',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '20px',
          lineHeight: '30px',
        }
      },
      '& ul': {
        fontSize: '24px',
        fontFamily: 'MuseoSans-100',
        margin: '40px 0px',
        padding: '0',
        // listStyle: 'inside',
        "& li":{
          fontSize: '23px',
          fontFamily: 'MuseoSans-100',
          marginBottom: '5px',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '20px',
            lineHeight: '30px',
          }
        }
      }
    },
    personalFinance: {
      '& h3': {
        color: '#0069aa',
        margin: '20px auto 10px auto',
        fontSize: '36px',
        textAlign: 'center',
        fontFamily: '"MuseoSans-300"',
        marginBottom: '30px',
        marginTop: '60px',
        display: 'block',
        fontWeight: '400',
        [theme.breakpoints.down( 'sm' )]: {
          fontSize: '30px',
          lineHeight: '45px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '27px',
          lineHeight: '40px',
        },
      }
    },
    questionBoxFlex: {
      display: 'flex',
      maxWidth: '80%',
      margin: '0 auto',
      color: '#fff',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100%',
        display: 'block',
      },
      [theme.breakpoints.down( 'sm' )]: {
        maxWidth: '100%',
      },
      '& p':{
        fontSize: '20px',
        lineHeight: '30px',
      }
    },
    lightBlueBox: {
          fontSize: '24px',
    fontFamily: 'MuseoSans-100',
    padding: '25px',
    color: '#fff',
    width: '50%',
    position: 'relative',
    minHeight: '365px',
    background: '#1f96e0',
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      width: 'calc(100% - 40px)',
      padding: '20px',
    },
    '& font': {
      display: 'block',
    textAlign: 'right',
    fontSize: '18px',
    '& span': {
      display: 'block',
    }
    },
    '& img': {
          maxWidth: '30px',
    marginRight: '15px',
    }
    },
    darkBlueBox: {
          fontSize: '24px',
    fontFamily: 'MuseoSans-100',
    padding: '25px',
    color: '#fff',
    width: '50%',
    position: 'relative',
    minHeight: '365px',
    background: '#0069aa',
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      width: 'calc(100% - 40px)',
      padding: '20px',
    },
    '& font': {
      display: 'block',
    textAlign: 'right',
    fontSize: '18px',
    '& span': {
      display: 'block',
    }
    },
    '& img': {
          maxWidth: '30px',
    marginRight: '15px',
    }
  },
  studentsLifeGrid:{
    margin: '30px auto 0px',  
    width: '95%',
  },
  studentsBoxFlex:{
    display: 'flex',
    '& span':{
      display: 'inline-block',
      marginRight: '10px',
      '& img':{
        width: '20px',
      }
    },
    '& b':{
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    }
  },
  textBlock:{
    display: 'block',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'initial',
    }
  },
  planPricesBox:{
    width: '25%',
    margin: '0 auto',
    padding: '0px 15px',
    [theme.breakpoints.down( 'md' )]: {
      maxWidth: '285px',
      width: '100%',
    },
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: 'calc(285px - 30px)',
    }
  },
  powerUpImagesLeft:{
    padding: '0px 40px 0px 0px !important',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0px 0px 0px 0px !important',
    }
  },
  powerUpImagesRight:{
    padding: '0px 0px 0px 40px !important',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '30px 0px 0px 0px !important',
    }
  },
  studentSeeGrid:{
    display: 'flex',
    width: '90%',
    margin: '0 auto',
    '& > div':{
      '&:first-child':{
        [theme.breakpoints.down( 'xs' )]: {
          textAlign: 'center',
        }
      }
    }
  },
  textStrong:{
    fontFamily: 'MuseoSans-500',
    fontWeight: 'normal'
  },
  textItalic:{
    fontStyle: 'italic',
  },
  pl30:{
    paddingLeft: '30px !important',
  },
  textAnchor:{
    color: '#fff',
    transition: 'all 0.5s ease',
    textDecoration: 'underline',
    '&:hover':{
      color: '#f89b22',
    }
  },
  pl35:{
    display: 'block',
    paddingLeft: '33px',
  },
  questionBoxRow:{
    margin: '30px 10px 70px',
  },
  smallText:{
    fontSize: '16px',
    fontStyle: 'italic',
  },
  pb20:{
    paddingBottom: '20px !important',
  },
  planPricesBoxFlex:{
    display: 'flex',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
    }
  },
  planPricesSec:{
    position: 'relative',
    '&:before':{
      height: '130px',
      color: '#0069aa',
      position: 'absolute',
      bottom: '0',
      background: '#0069aa',
      content: '""',
      width: '100%',
      [theme.breakpoints.down( 'sm' )]: {
        bottom: '-50px',
      }
    }
  },
  pad8:{
    padding: '8px !important',
  },
  affordablePlanSec:{
    position: 'relative',
    background: '#0069aa',
    padding: '85px 0',
    [theme.breakpoints.down( 'md' )]: {
      padding: '50px 0',
    },
    '&:before':{
      height: '0',
      borderLeft: '600px solid transparent',
      borderRight: '600px solid transparent',
      borderTop: '190px solid #fff',
      position: 'absolute',
      left: '0',
      top: '-1px',
      content: '""',
      width: 'calc(100% - 1200px)',
      [theme.breakpoints.down( 'md' )]: {
        display: 'none',
      }
    }
  }
} );

export default styles;
