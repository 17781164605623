
export function homeSaleYear( ) {
  const homeSaleYear = [
    {
      value: 2019,
      scaledValue: 2019,
      label: '2019',
    },
    {
      value: 2021,
      scaledValue: 2021,
      label: '2021',
    },
    {
      value: 2023,
      scaledValue: 2023,
      label: '2023',
    },
    {
      value: 2025,
      scaledValue: 2025,
      label: '2025',
    },
    {
      value: 2027,
      scaledValue: 2027,
      label: '2027',
    },
    {
      value: 2029,
      scaledValue: 2029,
      label: '2029',
    },
    {
      value: 2031,
      scaledValue: 2031,
      label: '2031',
    },
    {
      value: 2033,
      scaledValue: 2033,
      label: '2033',
    },
  ];
  return homeSaleYear;
}
export function homeValue( ) {
  const homeValue = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 200000,
      scaledValue: 200000,
      label: '$200k',
    },
    {
      value: 400000,
      scaledValue: 400000,
      label: '$400k',
    },
    {
      value: 600000,
      scaledValue: 600000,
      label: '$600k',
    },
    {
      value: 800000,
      scaledValue: 800000,
      label: '$800k',
    },
    {
      value: 1000000,
      scaledValue: 1000000,
      label: '$1000k',
    },
  ];
  return homeValue;
}
export function vehicleOwn1Value( ) {
  const vehicleOwn1Value = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 30000,
      scaledValue: 30000,
      label: '$30k',
    },
    {
      value: 60000,
      scaledValue: 60000,
      label: '$60k',
    },
    {
      value: 90000,
      scaledValue: 90000,
      label: '$90k',
    },
    {
      value: 120000,
      scaledValue: 120000,
      label: '$120k',
    },
    {
      value: 150000,
      scaledValue: 150000,
      label: '$150k',
    },
  ];
  return vehicleOwn1Value;
}
export function vehicleOwn1Expenses( ) {
  const vehicleOwn1Expenses = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 200,
      scaledValue: 200,
      label: '$200',
    },
    {
      value: 400,
      scaledValue: 400,
      label: '$400',
    },
    {
      value: 600,
      scaledValue: 600,
      label: '$600',
    },
    {
      value: 800,
      scaledValue: 800,
      label: '$800',
    },
    {
      value: 1000,
      scaledValue: 1000,
      label: '$1k',
    },
  ];
  return vehicleOwn1Expenses;
}
export function vehicleOwn2Months( ) {
  const vehicleOwn2Months = [
    {
      value: 0,
      scaledValue: 0,
      label: '0 mos',
    },
    {
      value: 60,
      scaledValue: 60,
      label: '60 mos',
    },
    {
      value: 120,
      scaledValue: 120,
      label: '120 mos',
    },
    {
      value: 180,
      scaledValue: 180,
      label: '180 mos',
    },
    {
      value: 240,
      scaledValue: 240,
      label: '240 mos',
    },
    {
      value: 300,
      scaledValue: 300,
      label: '300 mos',
    },
  ];
  return vehicleOwn2Months;
}
export function vehicleOwn2Intrest( ) {
  const vehicleOwn2Intrest = [
    {
      value: 0,
      scaledValue: 0,
      label: '0%',
    },
    {
      value: 3,
      scaledValue: 3,
      label: '3%',
    },
    {
      value: 6,
      scaledValue: 6,
      label: '6%',
    },
    {
      value: 9,
      scaledValue: 9,
      label: '9%',
    },
    {
      value: 12,
      scaledValue: 12,
      label: '12%',
    },
    {
      value: 15,
      scaledValue: 15,
      label: '15%',
    },
  ];
  return vehicleOwn2Intrest;
}
export function maxCashArray( ) {
  const maxCashArray = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 10000,
      scaledValue: 10000,
      label: '$10k',
    },
    {
      value: 20000,
      scaledValue: 20000,
      label: '$20k',
    },
    {
      value: 30000,
      scaledValue: 30000,
      label: '$30k',
    },
    {
      value: 40000,
      scaledValue: 40000,
      label: '$40k',
    },
    {
      value: 50000,
      scaledValue: 50000,
      label: '$50k',
    },
  ];
  return maxCashArray;
}
export function businessArray( ) {
  const businessArray = [
    {
      value: 0,
      scaledValue: 0,
      label: '0%',
    },
    {
      value: 2.0,
      scaledValue: 2.0,
      label: '2.0%',
    },
    {
      value: 4.0,
      scaledValue: 4.0,
      label: '4.0%',
    },
    {
      value: 6.0,
      scaledValue: 6.0,
      label: '6.0%',
    },
    {
      value: 8.0,
      scaledValue: 8.0,
      label: '8.0%',
    },
    {
      value: 10.0,
      scaledValue: 10.0,
      label: '10.0%',
    },
  ];
  return businessArray;
}

export function loanArray( ) {
  const loanarray = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 30000,
      scaledValue: 30000,
      label: '$30k',
    },
    {
      value: 60000,
      scaledValue: 60000,
      label: '$60k',
    },
    {
      value: 90000,
      scaledValue: 90000,
      label: '$90k',
    },
    {
      value: 120000,
      scaledValue: 120000,
      label: '$120k',
    },
    {
      value: 150000,
      scaledValue: 150000,
      label: '$150k',
    },
  ];
  return loanarray;
}

export function carImageArray( ) {
  const CarArray = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 20000,
      scaledValue: 20000,
      label: '$20k',
    },
    {
      value: 40000,
      scaledValue: 40000,
      label: '$40k',
    },
    {
      value: 60000,
      scaledValue: 60000,
      label: '$60k',
    },
    {
      value: 80000,
      scaledValue: 80000,
      label: '$80k',
    },
    {
      value: 100000,
      scaledValue: 100000,
      label: '$100k',
    },
    {
      value: 120000,
      scaledValue: 120000,
      label: '$120k',
    },
    {
      value: 140000,
      scaledValue: 140000,
      label: '$140k',
    },
  ];
  return CarArray;
}

export function homeArray( ) {
  const homeArrayValues = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 200000,
      scaledValue: 200000,
      label: '$200k',
    },
    {
      value: 400000,
      scaledValue: 400000,
      label: '$400k',
    },
    {
      value: 600000,
      scaledValue: 600000,
      label: '$600k',
    },
    {
      value: 800000,
      scaledValue: 800000,
      label: '$800k',
    },
    {
      value: 1000000,
      scaledValue: 1000000,
      label: '$1,000k',
    },
  ];
  return homeArrayValues;
}

export function carValuearray( ) {
  const carValuearrayValues = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 400,
      scaledValue: 400,
      label: '$400',
    },
    {
      value: 800,
      scaledValue: 800,
      label: '$800',
    },
    {
      value: 1200,
      scaledValue: 1200,
      label: '$1.2k',
    },
    {
      value: 1600,
      scaledValue: 1600,
      label: '$1.6k',
    },
    {
      value: 2000,
      scaledValue: 2000,
      label: '$2k',
    },
  ];
  return carValuearrayValues;
}
export function rateArray( ) {
  const rateArrayValues = [
    {
      value: 0.00,
      scaledValue: 0.00,
      label: '0%',
    },
    {
      value: 3.00,
      scaledValue: 3.00,
      label: '3%',
    },
    {
      value: 6.00,
      scaledValue: 6.00,
      label: '6%',
    },
    {
      value: 9.00,
      scaledValue: 9.00,
      label: '9%',
    },
    {
      value: 12.00,
      scaledValue: 12.00,
      label: '12%',
    },
    {
      value: 15.00,
      scaledValue: 15.00,
      label: '15%',
    },

  ];
  return rateArrayValues;
}

export function interestRate( ) {
  const interestRateValues = [
    {
      value: 0.00,
      scaledValue: 0.00,
      label: '0%',
    },
    {
      value: 5.00,
      scaledValue: 5.00,
      label: '5%',
    },
    {
      value: 10.00,
      scaledValue: 10.00,
      label: '10%',
    },
    {
      value: 15.00,
      scaledValue: 15.00,
      label: '15%',
    },
    {
      value: 20.00,
      scaledValue: 20.00,
      label: '20%',
    },
    {
      value: 25.00,
      scaledValue: 25.00,
      label: '25%',
    },

  ];
  return interestRateValues;
}

export function interestCarIncome( ) {
  const interestRateValues = [
    {
      value: 0.00,
      scaledValue: 0.00,
      label: '0%',
    },
    {
      value: 2.00,
      scaledValue: 2.00,
      label: '2%',
    },
    {
      value: 4.00,
      scaledValue: 4.00,
      label: '4%',
    },
    {
      value: 6.00,
      scaledValue: 6.00,
      label: '6%',
    },
    {
      value: 8.00,
      scaledValue: 8.00,
      label: '8%',
    },
    {
      value: 10.00,
      scaledValue: 10.00,
      label: '10%',
    },
    {
      value: 12.00,
      scaledValue: 12.00,
      label: '12%',
    },
    {
      value: 14.00,
      scaledValue: 14.00,
      label: '14%',
    },

  ];
  return interestRateValues;
}

export function interestHomeIncome( ) {
  const interestRateValues = [
    {
      value: 0.00,
      scaledValue: 0.00,
      label: '0.00%',
    },
    {
      value: 6.00,
      scaledValue: 6.00,
      label: '6.00%',
    },
    {
      value: 12.00,
      scaledValue: 12.00,
      label: '12.00%',
    },
    {
      value: 18.00,
      scaledValue: 18.00,
      label: '18.00%',
    },
    {
      value: 24.00,
      scaledValue: 24.00,
      label: '24.00%',
    },
    {
      value: 30.00,
      scaledValue: 30.00,
      label: '30.00%',
    },
    {
      value: 36.00,
      scaledValue: 36.00,
      label: '36.00%',
    },
    {
      value: 42.00,
      scaledValue: 42.00,
      label: '42.00%',
    },

  ];
  return interestRateValues;
}

export function interestCarRate( ) {
  const interestRateValues = [
    {
      value: 0.00,
      scaledValue: 0.00,
      label: '0.0%',
    },
    {
      value: 1.50,
      scaledValue: 1.50,
      label: '1.5%',
    },
    {
      value: 3.00,
      scaledValue: 3.00,
      label: '3.0%',
    },
    {
      value: 4.50,
      scaledValue: 4.50,
      label: '4.5%',
    },
    {
      value: 6.00,
      scaledValue: 6.00,
      label: '6.0%',
    },
    {
      value: 7.50,
      scaledValue: 7.50,
      label: '7.5%',
    },
    {
      value: 9.00,
      scaledValue: 9.00,
      label: '9.0%',
    },
    {
      value: 10.50,
      scaledValue: 10.50,
      label: '10.5%',
    },

  ];
  return interestRateValues;
}

export function appreciationValues( ) {
  const appreciationArrayValues = [
    {
      value: 0.00,
      scaledValue: 0.00,
      label: '0%',
    },
    {
      value: 2.00,
      scaledValue: 5.00,
      label: '2%',
    },
    {
      value: 4.00,
      scaledValue: 4.00,
      label: '4%',
    },
    {
      value: 6.00,
      scaledValue: 6.00,
      label: '6%',
    },
    {
      value: 8.00,
      scaledValue: 8.00,
      label: '8%',
    },
    {
      value: 10.00,
      scaledValue: 10.00,
      label: '10%',
    },

  ];
  return appreciationArrayValues;
}

export function repaymentArray( ) {
  const repaymentArrayValues = [
    {
      value: 0,
      scaledValue: 0,
      label: '0 mos',
    },
    {
      value: 13,
      scaledValue: 13,
      label: '13 mos',
    },
    {
      value: 26,
      scaledValue: 26,
      label: '26 mos',
    },
    {
      value: 39,
      scaledValue: 39,
      label: '39 mos',
    },
    {
      value: 52,
      scaledValue: 52,
      label: '52 mos',
    },
    {
      value: 65,
      scaledValue: 65,
      label: '65 mos',
    },
    {
      value: 78,
      scaledValue: 78,
      label: '78 mos',
    },

  ];
  return repaymentArrayValues;
}

export function idealRepayment( ) {
  const idealRepaymentValues = [
    {
      value: 0,
      scaledValue: 0,
      label: '0 mos',
    },
    {
      value: 12,
      scaledValue: 12,
      label: '12 mos',
    },
    {
      value: 24,
      scaledValue: 24,
      label: '24 mos',
    },
    {
      value: 36,
      scaledValue: 36,
      label: '36 mos',
    },
    {
      value: 48,
      scaledValue: 48,
      label: '48 mos',
    },
    {
      value: 60,
      scaledValue: 60,
      label: '60 mos',
    },
    {
      value: 72,
      scaledValue: 72,
      label: '72 mos',
    },

  ];
  return idealRepaymentValues;
}

export function termLoanArray( ) {
  const termLoanArrayValues = [
    {
      value: 0,
      scaledValue: 0,
      label: '0 mos',
    },
    {
      value: 30,
      scaledValue: 30,
      label: '30 mos',
    },
    {
      value: 60,
      scaledValue: 60,
      label: '60 mos',
    },
    {
      value: 90,
      scaledValue: 90,
      label: '90 mos',
    },
    {
      value: 120,
      scaledValue: 120,
      label: '120 mos',
    },
    {
      value: 150,
      scaledValue: 150,
      label: '150 mos',
    },
    {
      value: 180,
      scaledValue: 180,
      label: '180 mos',
    },

  ];
  return termLoanArrayValues;
}

export function maturityValues( ) {
  const maturityArrayValues = [
    {
      value: 0,
      scaledValue: 0,
      label: '0 mos',
    },
    {
      value: 60,
      scaledValue: 60,
      label: '60 mos',
    },
    {
      value: 120,
      scaledValue: 120,
      label: '120 mos',
    },
    {
      value: 180,
      scaledValue: 180,
      label: '180 mos',
    },
    {
      value: 240,
      scaledValue: 240,
      label: '240 mos',
    },
    {
      value: 300,
      scaledValue: 300,
      label: '300 mos',
    },
  ];
  return maturityArrayValues;
}

export function monthsToMaturity( ) {
  const monthsToMaturityValues = [
    {
      value: 0,
      scaledValue: 0,
      label: '0 mos',
    },
    {
      value: 60,
      scaledValue: 60,
      label: '60 mos',
    },
    {
      value: 120,
      scaledValue: 120,
      label: '120 mos',
    },
    {
      value: 180,
      scaledValue: 180,
      label: '180 mos',
    },
    {
      value: 240,
      scaledValue: 240,
      label: '240 mos',
    },
    {
      value: 300,
      scaledValue: 300,
      label: '300 mos',
    },
    {
      value: 360,
      scaledValue: 360,
      label: '360 mos',
    },
  ];
  return monthsToMaturityValues;
}
export function PurcaseYearValues( ) {
  const purchaseYears = [
    {
      value: 2020,
      scaledValue: 2020,
      label: '2020',
    },
    {
      value: 2022,
      scaledValue: 2022,
      label: '2022',
    },
    {
      value: 2024,
      scaledValue: 2024,
      label: '2024',
    },
    {
      value: 2026,
      scaledValue: 2026,
      label: '2026',
    },
    {
      value: 2028,
      scaledValue: 2028,
      label: '2028',
    },
    {
      value: 2030,
      scaledValue: 2030,
      label: '2030',
    },
    {
      value: 2032,
      scaledValue: 2032,
      label: '2032',
    },
    {
      value: 2034,
      scaledValue: 2034,
      label: '2034',
    },
  ];
  return purchaseYears;
}

export function DestinationValues( ) {
  const destinationArray = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 200,
      scaledValue: 200,
      label: '$200',
    },
    {
      value: 400,
      scaledValue: 400,
      label: '$400',
    },
    {
      value: 600,
      scaledValue: 600,
      label: '$600',
    },
    {
      value: 800,
      scaledValue: 800,
      label: '$800',
    },
    {
      value: 1000,
      scaledValue: 1000,
      label: '$1,000',
    },
    {
      value: 1200,
      scaledValue: 1200,
      label: '$1,200',
    },
    {
      value: 1400,
      scaledValue: 1400,
      label: '$1,400',
    },
  ];
  return destinationArray;
}

export function DownPaymentArraay( ) {
  const destinationArray = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 500,
      scaledValue: 500,
      label: '$500',
    },
    {
      value: 1000,
      scaledValue: 1000,
      label: '$1,000',
    },
    {
      value: 1500,
      scaledValue: 1500,
      label: '$1,500',
    },
    {
      value: 2000,
      scaledValue: 2000,
      label: '$2,000',
    },
    {
      value: 2500,
      scaledValue: 2500,
      label: '$2,500',
    },
    {
      value: 3000,
      scaledValue: 3000,
      label: '$3,000',
    },
    {
      value: 3500,
      scaledValue: 3500,
      label: '$3,500',
    },
  ];
  return destinationArray;
}

export function optionArray( ) {
  const options = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 1000,
      scaledValue: 1000,
      label: '$1K',
    },
    {
      value: 2000,
      scaledValue: 2000,
      label: '$2k',
    },
    {
      value: 3000,
      scaledValue: 3000,
      label: '$3k',
    },
    {
      value: 4000,
      scaledValue: 4000,
      label: '$4k',
    },
    {
      value: 5000,
      scaledValue: 5000,
      label: '$5k',
    },
    {
      value: 6000,
      scaledValue: 6000,
      label: '$6k',
    },
    {
      value: 7000,
      scaledValue: 7000,
      label: '$7k',
    },
    {
      value: 8000,
      scaledValue: 8000,
      label: '$8k',
    },
  ];
  return options;
}

export function RegistartionArray( ) {
  const options = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 50,
      scaledValue: 50,
      label: '$50',
    },
    {
      value: 100,
      scaledValue: 100,
      label: '$100',
    },
    {
      value: 150,
      scaledValue: 150,
      label: '$150',
    },
    {
      value: 200,
      scaledValue: 200,
      label: '$200',
    },
    {
      value: 250,
      scaledValue: 250,
      label: '$250',
    },
    {
      value: 300,
      scaledValue: 300,
      label: '$300',
    },
    {
      value: 350,
      scaledValue: 350,
      label: '$350',
    },
  ];
  return options;
}

export function LeasePayment( ) {
  const options = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 100,
      scaledValue: 100,
      label: '$100',
    },
    {
      value: 200,
      scaledValue: 200,
      label: '$200',
    },
    {
      value: 300,
      scaledValue: 300,
      label: '$300',
    },
    {
      value: 400,
      scaledValue: 400,
      label: '$400',
    },
    {
      value: 500,
      scaledValue: 500,
      label: '$500',
    },
    {
      value: 600,
      scaledValue: 600,
      label: '$600',
    },
  ];
  return options;
}

export function DownPayment( ) {
  const options = [
    {
      value: 0,
      scaledValue: 0,
      label: '0% of Purchase Price',
    },
    {
      value: 20,
      scaledValue: 20,
      label: '20%',
    },
    {
      value: 40,
      scaledValue: 40,
      label: '40%',
    },
    {
      value: 60,
      scaledValue: 60,
      label: '60%',
    },
    {
      value: 80,
      scaledValue: 80,
      label: '80%',
    },
    {
      value: 100,
      scaledValue: 100,
      label: '100%',
    },
  ];
  return options;
}
