import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../../screens/pwi-family/styles";
import ValueLabelComponent, {
  PwiSlider,
} from "../../common/pwi/slider-custome-tool-tip";
import { multiplerValue } from "../../common/pwi/slider-functions";
import * as modulesServices from "../../../calculations/modules-services";
import NumberFormat from "react-number-format";

const followersMarks = [
  {
    value: 0,
    label: "0.0x",
  },
  {
    value: 0.5,
    label: "0.5x",
  },
  {
    value: 1.0,
    label: "1.0x",
  },
  {
    value: 1.5,
    label: "1.5x",
  },
  {
    value: 2.0,
    label: "2.0x",
  },
  {
    value: 2.5,
    label: "2.5x",
  },
];

const UpdatingCombinedBudgetThird = (props) => {
  const { classes, inputData, updateData } = props;

  const [inputs, setInputs] = React.useState();

  useEffect(() => {
    let inputValues = { ...inputs };
    let inputDetails = { ...inputData };
    if (inputData.rent_multiplier === undefined) {
      inputDetails["technology_multiplier"] = 1;
    }
    inputValues["technology_multiplier"] =
      inputDetails["technology_multiplier"];
    if (inputData.transportation_multiplier === undefined) {
      inputDetails["transportation_multiplier"] = 1;
    }
    inputValues["transportation_multiplier"] =
      inputDetails["transportation_multiplier"];
    if (inputData.miscellaneous_multiplier === undefined) {
      inputDetails["miscellaneous_multiplier"] = 1;
    }
    inputValues["miscellaneous_multiplier"] =
      inputDetails["miscellaneous_multiplier"];
    if (inputData.health_insurance_costs_multiplier === undefined) {
      inputDetails["health_insurance_costs_multiplier"] = 1;
    }
    inputValues["health_insurance_costs_multiplier"] =
      inputDetails["health_insurance_costs_multiplier"];
    if (inputData.health_insurance_premium_multiplier === undefined) {
      inputDetails["health_insurance_premium_multiplier"] = 1;
    }
    inputValues["health_insurance_premium_multiplier"] =
      inputDetails["health_insurance_premium_multiplier"];
    updateData("total_obj", inputDetails);
    setInputs(inputValues);
  }, []);

  const updateValue = (e, value, field, type) => {
    let inputDetails = { ...inputData };
    inputDetails[field] = value;
    let inputValues = { ...inputs };
    inputValues[field] = value;
    setInputs(inputValues);
    updateData("total_obj", inputDetails);
  };

  const individualLivingExpensesCalc = (label, from = "table") => {
    const incomeSheetData = modulesServices.incomeStatementCompleteData();
    let displayValue = 0;
    if (typeof incomeSheetData !== "undefined" && incomeSheetData !== "") {
      const startYear = parseInt(
        modulesServices.module10Data.marriege_year,
        10
      );
      if (
        typeof incomeSheetData[startYear] !== "undefined" &&
        incomeSheetData[startYear] !== "" &&
        typeof incomeSheetData[startYear] !== "undefined" &&
        incomeSheetData[startYear] !== "" &&
        typeof incomeSheetData[startYear]["livingExpenses"] !== "undefined" &&
        incomeSheetData[startYear]["livingExpenses"] !== "" &&
        typeof incomeSheetData[startYear]["livingExpenses"][label] !==
          "undefined" &&
        incomeSheetData[startYear]["livingExpenses"][label] !== ""
      ) {
        displayValue = -incomeSheetData[startYear]["livingExpenses"][label];
      }
    }
    if (from === "table") {
      return (
        <span className={displayValue < 0 && classes.dangerText}>
          <NumberFormat
            decimalScale={0}
            allowNegative={false}
            fixedDecimalScale
            displayType="text"
            thousandSeparator
            value={displayValue}
            prefix={displayValue >= 0 ? "$" : "($"}
            suffix={displayValue < 0 && ")"}
          />
        </span>
      );
    } else {
      return displayValue;
    }
  };

  return (
    <div className={classes.contentBlock}>
      <p>
        Enter your marriage multiples for your monthly living expenses below.
      </p>
      {inputs !== undefined && (
        <div className={classes.livingExpenses}>
          <div className={classes.expensesGroup}>
            <div className={classes.leftBlock}>
              <h3>&nbsp;</h3>
            </div>
            <div className={classes.rightBlock}>
              <div>
                <h3>Monthly Before</h3>
              </div>
              <div>
                <div>
                  <h3>Monthly After</h3>
                </div>
              </div>
              <div>
                <div>
                  <h3>Annual</h3>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.expensesGroup}>
            <div className={classes.leftBlock}>
              <div
                className={`${classes.expensesGrowth} ${classes.expensesGrowthNew}`}
              >
                <div>
                  <p>Technology</p>
                  <div
                    className={`${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}`}
                  >
                    <PwiSlider
                      value={inputs["technology_multiplier"]}
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      valueLabelFormat={multiplerValue}
                      min={0}
                      max={2.5}
                      step={0.1}
                      marks={followersMarks}
                      ValueLabelComponent={ValueLabelComponent}
                      onChange={(e, value) =>
                        updateValue(e, value, "technology_multiplier", "slider")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.rightBlock}>
              <div>
                <div className={classes.graphInputBlock}>
                  <NumberFormat
                    decimalScale={0}
                    fixedDecimalScale
                    displayType="text"
                    allowNegative={false}
                    thousandSeparator
                    value={
                      modulesServices.module10Data.familyTechnologyMultiplier
                    }
                    prefix={
                      modulesServices.module10Data.familyTechnologyMultiplier >=
                      0
                        ? "$"
                        : "($"
                    }
                    suffix={
                      modulesServices.module10Data.familyTechnologyMultiplier <
                        0 && ")"
                    }
                  />
                </div>
              </div>
              <div>
                <div className={classes.graphInputBlock}>
                  <NumberFormat
                    decimalScale={0}
                    fixedDecimalScale
                    displayType="text"
                    allowNegative={false}
                    thousandSeparator
                    value={
                      modulesServices.module10Data
                        .familyTechnologyMultiplierCalc
                    }
                    prefix={
                      modulesServices.module10Data
                        .familyTechnologyMultiplierCalc >= 0
                        ? "$"
                        : "($"
                    }
                    suffix={
                      modulesServices.module10Data
                        .familyTechnologyMultiplierCalc < 0 && ")"
                    }
                  />
                </div>
              </div>
              <div>
                <div className={classes.graphInputBlock}>
                  {individualLivingExpensesCalc("Technology")}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.expensesGroup}>
            <div className={classes.leftBlock}>
              <div
                className={`${classes.expensesGrowth} ${classes.expensesGrowthNew}`}
              >
                <div>
                  <p>Transportation</p>
                  <div
                    className={`${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}`}
                  >
                    <PwiSlider
                      value={inputs["transportation_multiplier"]}
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      valueLabelFormat={multiplerValue}
                      min={0}
                      max={2.5}
                      step={0.1}
                      marks={followersMarks}
                      ValueLabelComponent={ValueLabelComponent}
                      onChange={(e, value) =>
                        updateValue(
                          e,
                          value,
                          "transportation_multiplier",
                          "slider"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.rightBlock}>
              <div>
                <div className={classes.graphInputBlock}>
                  <NumberFormat
                    decimalScale={0}
                    fixedDecimalScale
                    displayType="text"
                    allowNegative={false}
                    thousandSeparator
                    value={
                      modulesServices.module10Data
                        .familyTransportationMultiplier
                    }
                    prefix={
                      modulesServices.module10Data
                        .familyTransportationMultiplier >= 0
                        ? "$"
                        : "($"
                    }
                    suffix={
                      modulesServices.module10Data
                        .familyTransportationMultiplier < 0 && ")"
                    }
                  />
                </div>
              </div>
              <div>
                <div className={classes.graphInputBlock}>
                  <NumberFormat
                    decimalScale={0}
                    fixedDecimalScale
                    displayType="text"
                    allowNegative={false}
                    thousandSeparator
                    value={
                      modulesServices.module10Data
                        .familyTransportationMultiplierCalc
                    }
                    prefix={
                      modulesServices.module10Data
                        .familyTransportationMultiplierCalc >= 0
                        ? "$"
                        : "($"
                    }
                    suffix={
                      modulesServices.module10Data
                        .familyTransportationMultiplierCalc < 0 && ")"
                    }
                  />
                </div>
              </div>
              <div>
                <div className={classes.graphInputBlock}>
                  {individualLivingExpensesCalc("Transportation")}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.expensesGroup}>
            <div className={classes.leftBlock}>
              <div
                className={`${classes.expensesGrowth} ${classes.expensesGrowthNew}`}
              >
                <div>
                  <p>Miscellaneous</p>
                  <div
                    className={`${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}`}
                  >
                    <PwiSlider
                      value={inputs["miscellaneous_multiplier"]}
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      valueLabelFormat={multiplerValue}
                      min={0}
                      max={2.5}
                      step={0.1}
                      marks={followersMarks}
                      ValueLabelComponent={ValueLabelComponent}
                      onChange={(e, value) =>
                        updateValue(
                          e,
                          value,
                          "miscellaneous_multiplier",
                          "slider"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.rightBlock}>
              <div>
                <div className={classes.graphInputBlock}>
                  <NumberFormat
                    decimalScale={0}
                    fixedDecimalScale
                    displayType="text"
                    allowNegative={false}
                    thousandSeparator
                    value={
                      modulesServices.module10Data.familyMiscellaneousMultiplier
                    }
                    prefix={
                      modulesServices.module10Data
                        .familyMiscellaneousMultiplier >= 0
                        ? "$"
                        : "($"
                    }
                    suffix={
                      modulesServices.module10Data
                        .familyMiscellaneousMultiplier < 0 && ")"
                    }
                  />
                </div>
              </div>
              <div>
                <div className={classes.graphInputBlock}>
                  <NumberFormat
                    decimalScale={0}
                    fixedDecimalScale
                    displayType="text"
                    allowNegative={false}
                    thousandSeparator
                    value={
                      modulesServices.module10Data
                        .familyMiscellaneousMultiplierCalc
                    }
                    prefix={
                      modulesServices.module10Data
                        .familyMiscellaneousMultiplierCalc >= 0
                        ? "$"
                        : "($"
                    }
                    suffix={
                      modulesServices.module10Data
                        .familyMiscellaneousMultiplierCalc < 0 && ")"
                    }
                  />
                </div>
              </div>
              <div>
                <div className={classes.graphInputBlock}>
                  {individualLivingExpensesCalc("Miscellaneous")}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.expensesGroup}>
            <div className={classes.leftBlock}>
              <div
                className={`${classes.expensesGrowth} ${classes.expensesGrowthNew}`}
              >
                <div>
                  <p>Healthcare Costs other than Premium</p>
                  <div
                    className={`${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}`}
                  >
                    <PwiSlider
                      value={inputs["health_insurance_costs_multiplier"]}
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      valueLabelFormat={multiplerValue}
                      min={0}
                      max={2.5}
                      step={0.1}
                      marks={followersMarks}
                      ValueLabelComponent={ValueLabelComponent}
                      onChange={(e, value) =>
                        updateValue(
                          e,
                          value,
                          "health_insurance_costs_multiplier",
                          "slider"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.rightBlock}>
              <div>
                <div className={classes.graphInputBlock}>
                  <NumberFormat
                    decimalScale={0}
                    fixedDecimalScale
                    displayType="text"
                    allowNegative={false}
                    thousandSeparator
                    value={
                      modulesServices.module10Data
                        .familyHealthInsuranceOutsidePremiumMultiplier
                    }
                    prefix={
                      modulesServices.module10Data
                        .familyHealthInsuranceOutsidePremiumMultiplier >= 0
                        ? "$"
                        : "($"
                    }
                    suffix={
                      modulesServices.module10Data
                        .familyHealthInsuranceOutsidePremiumMultiplier < 0 &&
                      ")"
                    }
                  />
                </div>
              </div>
              <div>
                <div className={classes.graphInputBlock}>
                  <NumberFormat
                    decimalScale={0}
                    fixedDecimalScale
                    displayType="text"
                    allowNegative={false}
                    thousandSeparator
                    value={
                      modulesServices.module10Data
                        .familyHealthInsuranceOutsidePremiumMultiplierCalc
                    }
                    prefix={
                      modulesServices.module10Data
                        .familyHealthInsuranceOutsidePremiumMultiplierCalc >= 0
                        ? "$"
                        : "($"
                    }
                    suffix={
                      modulesServices.module10Data
                        .familyHealthInsuranceOutsidePremiumMultiplierCalc <
                        0 && ")"
                    }
                  />
                </div>
              </div>
              <div>
                <div className={classes.graphInputBlock}>
                  {individualLivingExpensesCalc(
                    "Healthcare Costs other than Premium"
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.expensesGroup}>
            <div className={classes.leftBlock}>
              <div
                className={`${classes.expensesGrowth} ${classes.expensesGrowthNew}`}
              >
                <div>
                  <p>Health Insurance Premium</p>
                  <div
                    className={`${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}`}
                  >
                    <PwiSlider
                      value={inputs["health_insurance_premium_multiplier"]}
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      valueLabelFormat={multiplerValue}
                      min={0}
                      max={2.5}
                      step={0.1}
                      marks={followersMarks}
                      ValueLabelComponent={ValueLabelComponent}
                      onChange={(e, value) =>
                        updateValue(
                          e,
                          value,
                          "health_insurance_premium_multiplier",
                          "slider"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.rightBlock}>
              <div>
                <div className={classes.graphInputBlock}>
                  <NumberFormat
                    decimalScale={0}
                    fixedDecimalScale
                    displayType="text"
                    allowNegative={false}
                    thousandSeparator
                    value={
                      modulesServices.module10Data
                        .familyHealthInsurancePremiumMultiplier
                    }
                    prefix={
                      modulesServices.module10Data
                        .familyHealthInsurancePremiumMultiplier >= 0
                        ? "$"
                        : "($"
                    }
                    suffix={
                      modulesServices.module10Data
                        .familyHealthInsurancePremiumMultiplier < 0 && ")"
                    }
                  />
                </div>
              </div>
              <div>
                <div className={classes.graphInputBlock}>
                  <NumberFormat
                    decimalScale={0}
                    fixedDecimalScale
                    displayType="text"
                    allowNegative={false}
                    thousandSeparator
                    value={
                      modulesServices.module10Data
                        .familyHealthInsurancePremiumMultiplierCalc
                    }
                    prefix={
                      modulesServices.module10Data
                        .familyHealthInsurancePremiumMultiplierCalc >= 0
                        ? "$"
                        : "($"
                    }
                    suffix={
                      modulesServices.module10Data
                        .familyHealthInsurancePremiumMultiplierCalc < 0 && ")"
                    }
                  />
                </div>
              </div>
              <div>
                <div className={classes.graphInputBlock}>
                  {individualLivingExpensesCalc("Health Insurance Premium")}
                </div>
              </div>
            </div>
          </div>

          <div className={classes.expensesGroup}>
            <div className={classes.leftBlock}>
              <div
                className={`${classes.expensesGrowth} ${classes.expensesGrowthNew}`}
              >
                <div>
                  <h3>Total Living Expenses</h3>
                </div>
              </div>
            </div>
            <div className={classes.rightBlock}>
              <div>
                <div className={classes.graphInputBlock}>
                  <b>
                    <NumberFormat
                      decimalScale={0}
                      fixedDecimalScale
                      displayType="text"
                      allowNegative={false}
                      thousandSeparator
                      value={modulesServices.module10Data.sumOfFamilyMultiplier}
                      prefix={
                        modulesServices.module10Data.sumOfFamilyMultiplier >= 0
                          ? "$"
                          : "($"
                      }
                      suffix={
                        modulesServices.module10Data.sumOfFamilyMultiplier <
                          0 && ")"
                      }
                    />
                  </b>
                </div>
              </div>
              <div>
                <div className={classes.graphInputBlock}>
                  <b>
                    <NumberFormat
                      decimalScale={0}
                      fixedDecimalScale
                      displayType="text"
                      allowNegative={false}
                      thousandSeparator
                      value={
                        modulesServices.module10Data.sumOfFamilyMultiplierCalc
                      }
                      prefix={
                        modulesServices.module10Data
                          .sumOfFamilyMultiplierCalc >= 0
                          ? "$"
                          : "($"
                      }
                      suffix={
                        modulesServices.module10Data.sumOfFamilyMultiplierCalc <
                          0 && ")"
                      }
                    />
                  </b>
                </div>
              </div>
              <div>
                <div className={classes.graphInputBlock}>
                  <b>
                    <NumberFormat
                      decimalScale={0}
                      fixedDecimalScale
                      displayType="text"
                      allowNegative={false}
                      thousandSeparator
                      value={
                        -modulesServices.module10Data.marritalExpensesPaidMain
                      }
                      prefix={
                        -modulesServices.module10Data
                          .marritalExpensesPaidMain >= 0
                          ? "$"
                          : "($"
                      }
                      suffix={
                        -modulesServices.module10Data.marritalExpensesPaidMain <
                          0 && ")"
                      }
                    />
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

UpdatingCombinedBudgetThird.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};
export default withStyles(styles)(UpdatingCombinedBudgetThird);
