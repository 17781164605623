import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';
import { withRouter, Link } from 'react-router-dom';

const Roth401 = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>401(k) vs Roth 401(k)</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/401k-roth401k.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      401(k) vs Roth 401(k)
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/401k-roth401k-187px.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Employer-sponsored retirement plans such as a 401(k)
                          and Roth 401(k) are great ways to save and invest for
                          – you guessed it – your retirement. What’s the
                          difference between the two? But first, what’s an
                          employer-sponsored retirement plan?
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          An employer-sponsored retirement plan is a type of
                          benefit offered to employees usually at no cost. These
                          retirement plans are employer-sponsored investment
                          savings accounts which offer employees the opportunity
                          to save and invest a portion of each paycheck for
                          retirement.
                        </p>
                        <p>
                          The main difference between a 401(k) and a Roth 401(k)
                          is taxes. One of the benefits of a 401(k) is your
                          contributions are made with pre-tax dollars. Your
                          401(k) contributions lower your&nbsp;
                          <Link
                            to="/finance-explained/personal-income-taxes"
                            target="_blank">
                            taxable income
                          </Link>
                          . When you go to withdraw that money in retirement,
                          you will have to pay taxes at that time.
                        </p>
                        <p>
                          The benefit of a Roth 401(k) is your contributions are
                          made with after-tax dollars, which do not lower your
                          taxable income. Yes, you read that right. That’s a
                          benefit and I’ll tell you why. When you withdraw your
                          Roth 401(k) money each year in retirement, you would
                          pay no taxes. All the money you and your employers
                          contribute over the years would be yours – tax free.
                        </p>
                        <p>
                          Both the 401(k) and Roth 401(k) have contribution
                          limits currently set at $19,500. But that doesn’t
                          include your employer matching contributions. One of
                          the benefits of an employer retirement plan is your
                          employer typically offers to&nbsp;
                          <Link
                            to="/finance-explained/retirement-accounts"
                            target="_blank">
                            match your contributions
                          </Link>
                          .
                        </p>
                        <p>
                          If you think your tax rate will be higher in
                          retirement, which means you’ll be earning more income
                          then than you do now, you’d invest in a Roth 401(k).
                          Think about it. If my tax rate is going to be higher
                          later, would I want a retirement account such as a
                          401(k) that requires me to pay taxes? Or, would I want
                          a Roth 401(k) that allows me to withdraw money tax
                          free?
                        </p>
                        <p>
                          There are pros and cons to both. Prepare for
                          retirement by raising your awareness to and growing
                          your knowledge of the many different investment
                          savings accounts available to you. The most important
                          lesson?&nbsp;
                          <Link
                            to="/finance-explained/how-to-become-a-millionaire"
                            target="_blank">
                            The sooner you start, the better.
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
Roth401.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Roth401));
