function buyingAHomeSheetData( module1Data, module13Data, assumptionsData, mortgage1Data ) {
  // ModuleServices
  // Buying a Home Sheet

  let purchaseYearcondition = 0;
  if ( module13Data.year_of_purchase !== undefined && module13Data.year_of_purchase !== '' && module13Data.year_of_purchase  > 0) {
    purchaseYearcondition = module13Data.year_of_purchase;
  } else {
    purchaseYearcondition = 0;
  }
  /* Closing Costs */
  const closingCostsData = {};
  if ( purchaseYearcondition > 0 ) {

    // Appraisal Fee
    if(typeof module13Data.save_home_closing_costs_new_values !== undefined && module13Data.save_home_closing_costs_new_values === 'Yes' 
      && module13Data.closing_appraisal_fee !== undefined && module13Data.closing_appraisal_fee !== null) {
        closingCostsData['Appraisal Fee'] = module13Data.closing_appraisal_fee;
    } else {
        closingCostsData['Appraisal Fee'] = 500;
    }

    // Credit Report Fee
    if(typeof module13Data.save_home_closing_costs_new_values !== undefined && module13Data.save_home_closing_costs_new_values === 'Yes' 
      && module13Data.closing_credit_report_fee !== undefined && module13Data.closing_credit_report_fee !== null) {
        closingCostsData['Credit Report Fee'] = module13Data.closing_credit_report_fee;
    } else {
        closingCostsData['Credit Report Fee'] = 30;
    }

    // Closing Fee
    if(typeof module13Data.save_home_closing_costs_new_values !== undefined && module13Data.save_home_closing_costs_new_values === 'Yes' 
      && module13Data.closing_escrow_account !== undefined && module13Data.closing_escrow_account !== null) {
        closingCostsData['Title Closing/Escrow Fee'] = module13Data.closing_escrow_account;
    } else {
        closingCostsData['Title Closing/Escrow Fee'] = 400;
    }

    // Lenders Title Insurance
    closingCostsData['Lenders Title Insurance'] = ( 0.004 * module13Data.purchase_price );

    // Flood Determination
    if(typeof module13Data.save_home_closing_costs_new_values !== undefined && module13Data.save_home_closing_costs_new_values === 'Yes' 
      && module13Data.closing_flood_determination !== undefined && module13Data.closing_flood_determination !== null) {
        closingCostsData['Flood Determination'] = module13Data.closing_flood_determination;
    } else {
        closingCostsData['Flood Determination'] = 20;
    }

    // Tax Service Fee
    if(typeof module13Data.save_home_closing_costs_new_values !== undefined && module13Data.save_home_closing_costs_new_values === 'Yes' 
      && module13Data.closing_tax_service_fee !== undefined && module13Data.closing_tax_service_fee !== null) {
        closingCostsData['Tax Service Fee'] = module13Data.closing_tax_service_fee;
    } else {
        closingCostsData['Tax Service Fee'] = 75;
    }

    // Owners Title Insurance
	  if ( module13Data.titleInsuranceCalcs !== undefined ) {
      closingCostsData['Owners Title Insurance'] = module13Data.titleInsuranceCalcs;
    } else {
      closingCostsData['Owners Title Insurance'] = 0;
    }




    // Recording Mortgage
    let naturalHazard = 0;
    if(typeof module13Data.Mortage !== 'undefined' && module13Data.Mortage !== '') {
        if(typeof module13Data.save_home_closing_costs_new_values !== undefined && module13Data.save_home_closing_costs_new_values === 'Yes' &&  typeof module13Data.closing_recording_mortgage !== 'undefined') {
            naturalHazard = module13Data.closing_recording_mortgage;
        } else {
            const downAmount = (parseFloat(module13Data.down_payment_in_percentage) * module13Data.purchase_price) / 100
           naturalHazard = module13Data.purchase_price - parseFloat(downAmount);
           naturalHazard = 0.0007 * naturalHazard;
        }
    }   
    closingCostsData['Recording Mortgage'] = naturalHazard;

    if(module13Data.homeownerInsuranceCalcs !== undefined) {
      closingCostsData['Homeowners Insurance(3 months prepaid upfront)'] = ( module13Data.homeownerInsuranceCalcs ) * 0.25;
    } else {
      closingCostsData['Homeowners Insurance(3 months prepaid upfront)'] = 0;
    }

    // Transfer Taxes
    var closingCostTransferTaxes = 0;
    if(typeof module13Data.transfer_taxes_in_percentage !== 'undefined' && module13Data.transfer_taxes_in_percentage !== '') {
      closingCostTransferTaxes = module13Data.purchase_price * (module13Data.transfer_taxes_in_percentage/100);
    }
    closingCostsData['Transfer Taxes'] = closingCostTransferTaxes;


    // Processing Fee
    if(typeof module13Data.save_home_closing_costs_new_values !== undefined && module13Data.save_home_closing_costs_new_values === 'Yes' 
      && module13Data.closing_processing_fee !== undefined && module13Data.closing_processing_fee !== null) {
        closingCostsData['Processing Fee'] = module13Data.closing_processing_fee;
    } else {
        closingCostsData['Processing Fee'] = 500;
    }

      
    // UnderWriting Fee
    if(typeof module13Data.save_home_closing_costs_new_values !== undefined && module13Data.save_home_closing_costs_new_values === 'Yes' 
      && module13Data.closing_underwriting_fee !== undefined && module13Data.closing_underwriting_fee !== null) {
        closingCostsData['Underwriting Fee'] = module13Data.closing_underwriting_fee;
    } else {
        closingCostsData['Underwriting Fee'] = 500;
    }

    // Loan Acquisition Points
    if(module13Data.buyingAhomeLoanPointsCalcs !== undefined) {
      closingCostsData['Loan Acquisition Points'] = module13Data.buyingAhomeLoanPointsCalcs;
    } else {
      closingCostsData['Loan Acquisition Points'] = 0;
    }

    // Origination Points
	  if(module13Data.originationFeesCalcs !== undefined) {
      closingCostsData['Origination Points'] = module13Data.originationFeesCalcs;
    } else {
      closingCostsData['Origination Points'] = 0;
    }


    // Mortgage Interest(1month prepaid upfront)
    let closingCostPrePaidInterest = 0;
    if ( mortgage1Data !== undefined && mortgage1Data['Prepaid Interest'] !== '' ) {
      closingCostPrePaidInterest = mortgage1Data['Prepaid Interest'];
    }
    closingCostsData['Mortgage Interest(1month prepaid upfront)'] = closingCostPrePaidInterest;

    // Property Taxes(3 months prepaid upfront)
    let closingCostPropertyTax = 0;
    if ( module13Data.propertyTaxesCalcs !== undefined && module13Data.propertyTaxesCalcs !== '' ) {
      closingCostPropertyTax = ( module13Data.propertyTaxesCalcs ) * 0.25;
    }
    closingCostsData['Property Taxes(3 months prepaid upfront)'] = closingCostPropertyTax;

    // Wood Destroying Pest Inspection
    if(typeof module13Data.save_home_closing_costs_new_values !== undefined && module13Data.save_home_closing_costs_new_values === 'Yes' 
      && module13Data.closing_pest_Inspection !== undefined && module13Data.closing_pest_Inspection !== null) {
        closingCostsData['Wood Destroying Pest Inspection'] = module13Data.closing_pest_Inspection;
    } else {
        closingCostsData['Wood Destroying Pest Inspection'] = 250;
    }

    // Home Owners Association Transfer Fees
    if(typeof module13Data.save_home_closing_costs_new_values !== undefined && module13Data.save_home_closing_costs_new_values === 'Yes' 
      && module13Data.closing_home_owners_association_transfer_fees !== undefined && module13Data.closing_home_owners_association_transfer_fees !== null) {
        closingCostsData['Home Owners Association Transfer Fees'] = module13Data.closing_home_owners_association_transfer_fees;
    } else {
        closingCostsData['Home Owners Association Transfer Fees'] = 200;
    }

    // Others
    if(typeof module13Data.save_home_closing_costs_new_values !== undefined && module13Data.save_home_closing_costs_new_values === 'Yes' 
      && module13Data.closing_costs_others !== undefined && module13Data.closing_costs_others !== null) {
        closingCostsData['Others'] = module13Data.closing_costs_others;
    } else {
        closingCostsData['Others'] = 0;
    }
  } else {
    closingCostsData['Appraisal Fee'] = 0;
    closingCostsData['Credit Report Fee'] = 0;
    closingCostsData['Title Closing/Escrow Fee'] = 0;
    closingCostsData['Lenders Title Insurance'] = 0;
    closingCostsData['Flood Determination'] = 0;
    closingCostsData['Tax Service Fee'] = 0;
    closingCostsData['Owners Title Insurance'] = 0;
    closingCostsData['Recording Mortgage'] = 0;
    closingCostsData['Homeowners Insurance(3 months prepaid upfront)'] = 0;
    closingCostsData['Transfer Taxes'] = 0;
    closingCostsData['Processing Fee'] = 0;
    closingCostsData['Underwriting Fee'] = 0;
    closingCostsData['Loan Acquisition Points'] = 0;
    closingCostsData['Origination Points'] = 0;
    closingCostsData['Mortgage Interest(1month prepaid upfront)'] = 0;
    closingCostsData['Property Taxes(3 months prepaid upfront)'] = 0;
    closingCostsData['Wood Destroying Pest Inspection'] = 0;
    closingCostsData['Home Owners Association Transfer Fees'] = 0;
    closingCostsData.Others = 0;
  }
  // Total Initial Costs
  closingCostsData['Total Initial Costs'] = closingCostsData['Appraisal Fee']
            + closingCostsData['Credit Report Fee']
            + closingCostsData['Title Closing/Escrow Fee']
            // closingCostsData['Lenders Title Insurance'] +
            + closingCostsData['Flood Determination']
            + closingCostsData['Tax Service Fee']
            + closingCostsData['Owners Title Insurance']
            + closingCostsData['Recording Mortgage']
            + closingCostsData['Homeowners Insurance(3 months prepaid upfront)']
            + closingCostsData['Transfer Taxes']
            + closingCostsData['Processing Fee']
            + closingCostsData['Underwriting Fee']
            + closingCostsData['Loan Acquisition Points']
            + closingCostsData['Origination Points']
            + closingCostsData['Mortgage Interest(1month prepaid upfront)']
            + closingCostsData['Property Taxes(3 months prepaid upfront)']
            + closingCostsData['Wood Destroying Pest Inspection']
            + closingCostsData['Home Owners Association Transfer Fees']
            + closingCostsData.Others;

  /* Buying Expenses */
  const buyingExpenses = {};

  let sellYear = 0;
  sellYear = parseInt( module1Data.start_year, 10 ) + 14;
  // Purchase Year
  let purchaseYear = 0;
  
  if ( module13Data.year_of_purchase !== undefined && module13Data.year_of_purchase !== '' ) {
    purchaseYear = module13Data.year_of_purchase;
  }
  buyingExpenses['Purchase Year'] = purchaseYear;
  // Down Payment
  let downPayment = 0;
  if ( module13Data.downPaymentCalcs !== undefined && module13Data.downPaymentCalcs !== '' ) {
    downPayment = module13Data.downPaymentCalcs;
  }
  buyingExpenses['Down Payment'] = downPayment;

  // Closing Costs
  const closingCosts = closingCostsData['Total Initial Costs'];
  buyingExpenses['Closing Costs'] = closingCosts;

  // Fit Out Costs
  let fitOfCosts = 0;
  if ( module13Data.external_and_internal_fit_out_costs !== undefined && module13Data.external_and_internal_fit_out_costs !== '' ) {
    fitOfCosts = module13Data.external_and_internal_fit_out_costs;
  }
  buyingExpenses['Fit Out Costs'] = fitOfCosts;

  /* Cash Out */
  const cashOut = {};

  // Mortgage Payment
  let mortgagePayment = 0;
  // moduleServices.moreHighermonthlyPayment();
  // moduleServices.yearlyPayment();
  if ( module13Data.yearlyPayment !== undefined && module13Data.yearlyPayment !== '' ) {
    mortgagePayment = module13Data.yearlyPayment;
  }
  cashOut['Mortgage Payment'] = mortgagePayment;

  // Property Mortgage Insurance(PMI)
  let propertyMortgageInsurance = 0;
  if ( module13Data.privateMortgageInsuranceCalcs !== undefined && module13Data.privateMortgageInsuranceCalcs !== '' ) {
    propertyMortgageInsurance = module13Data.privateMortgageInsuranceCalcs;
  }
  cashOut['Private Mortgage Insurance (PMI)'] = propertyMortgageInsurance;

  // Insurance
  let insurance = 0;
  if ( module13Data.homeownerInsuranceCalcs !== undefined && module13Data.homeownerInsuranceCalcs !== '' ) {
    insurance = module13Data.homeownerInsuranceCalcs;
  }
  cashOut['Homeowners Insurance'] = insurance;

  // Maintenance
  let maintenance = 0;
  if ( module13Data.yearlyMaintenanceCalcs !== undefined && module13Data.yearlyMaintenanceCalcs !== '' ) {
    maintenance = module13Data.yearlyMaintenanceCalcs;
  }
  cashOut['Property Maintenance'] = maintenance;

  // Property Taxes
  let propertyTaxes = 0;
  if ( module13Data.propertyTaxesCalcs !== undefined && module13Data.propertyTaxesCalcs !== '' ) {
    propertyTaxes = module13Data.propertyTaxesCalcs;
  }
  cashOut['Property Taxes'] = propertyTaxes;

  // Association Fees
  let associationFees = 0;
  if ( module13Data.yearly_association_fees !== undefined && module13Data.yearly_association_fees !== '' ) {
    associationFees = module13Data.yearly_association_fees;
  }
  cashOut['Association Fees'] = associationFees;

  // Utilities
  let utilities = 0;
  if ( module13Data.homeMonthlyUtilitiesCalcs !== undefined && module13Data.homeMonthlyUtilitiesCalcs !== '' ) {
    utilities = module13Data.homeMonthlyUtilitiesCalcs;
  }
  cashOut.Utilities = utilities;

  // Property Tax Percentage
  let propertyTaxVal = 0;
  if ( module13Data.property_tax_in_percentage !== undefined && module13Data.property_tax_in_percentage !== '' ) {
    propertyTaxVal = module13Data.property_tax_in_percentage;
  } else {
    propertyTaxVal = 0;
  }
  cashOut['Property Tax'] = propertyTaxVal;

  // Annual Homeowner's Insurance
  let annualHomeOwnersTaxVal = 0;
  if ( module13Data.home_owners_insurance_in_percentage !== undefined && module13Data.home_owners_insurance_in_percentage !== '' ) {
    annualHomeOwnersTaxVal = module13Data.home_owners_insurance_in_percentage;
  } else {
    annualHomeOwnersTaxVal = 0;
  }
  cashOut['Annual Homeowner Insurance'] = annualHomeOwnersTaxVal;

  // Annual Maintenance
  let annualMaintenanceTaxVal = 0;
  if ( module13Data.yearly_maintanance_in_percentage !== undefined && module13Data.yearly_maintanance_in_percentage !== '' ) {
    annualMaintenanceTaxVal = module13Data.yearly_maintanance_in_percentage;
  } else {
    annualMaintenanceTaxVal = 0;
  }
  cashOut['Annual Maintenance'] = annualMaintenanceTaxVal;
  let year = parseInt( module1Data.start_year, 10 );
  const yearLimit = parseInt( module1Data.start_year, 10 ) + 30;
  /* Data1 */
  const data1 = {};
  let dataAssociationFees = 0;
  let dataInsurance = 0;
  let dataMaintenance = 0;
  let dataUtilities = 0;

  /* Data 2 */
  const data2 = {};

  /* Real Estate Value */
  const realEstateValue = {};
  let realEstateAssetValue = 0;

  // Purchase Price
  let purchasePrice = 0;
  if ( module13Data.purchase_price !== undefined && module13Data.purchase_price !== '' ) {
    purchasePrice = module13Data.purchase_price;
  } else {
    purchasePrice = 0;
  }

  // Depreciation
  const depreciation = 27.50;

  //  Asset Value
  let assetValueCal = 0;
  // let fitOfCostsVal = 0;
  if ( module13Data.fitOfCosts !== undefined ) {
    // fitOfCostsVal = module13Data.fitOfCosts;
  }
  assetValueCal = purchasePrice;
  // this.assetValueCal = assetValueCal;

  // Cal Value
  let calVal = 0;
  if ( assetValueCal > 0 ) {
    calVal = ( assetValueCal / depreciation );
  }

  let realEstateAccumulated = 0;
  // let k = 0;
  let realEstatePropertyTax = 0;
  const yearsListArr = [];
  let identifier = '';
  for ( year; year <= yearLimit; year += 1 ) {
    /* Real Estate Value */
    realEstateValue[year] = {};

    // Asset Value
    if ( year === purchaseYear ) {
      realEstateAssetValue = assetValueCal;
    } else {
		if ( year >= purchaseYear ) {
		  if ( module13Data.appreciation_in_percentage !== undefined && module13Data.appreciation_in_percentage !== '' ) {
			realEstateAssetValue *= ( 1 + ( module13Data.appreciation_in_percentage / 100 ) );
		  } else {
			realEstateAssetValue = 0;
		  }
		} else {
		  realEstateAssetValue = 0;
		}
	}
    realEstateValue[year]['Asset Value'] = realEstateAssetValue;

    // Property Tax

    /* if(k === 0) {
                    if(year === purchaseYear) {
                        realEstatePropertyTax = cashOut['Property Taxes'];
                    } else {
                        realEstatePropertyTax = 0;
                    }
                } else {
                    if(year === purchaseYear) {
                        realEstatePropertyTax = cashOut['Property Taxes'];
                    } else {
                        if(year >= purchaseYear) {
                            if ( assumptionsData !== undefined &&  assumptionsData[year] !== undefined &&  assumptionsData[year]['Expenses'] !== undefined) {
                                realEstatePropertyTax = realEstatePropertyTax * (assumptionsData[year]['Expenses'] / 100);
                            } else {
                                realEstatePropertyTax = realEstatePropertyTax;
                            }
                        } else {
                            realEstatePropertyTax = 0;
                        }
                    }
                } */
    if ( realEstateValue !== undefined && realEstateValue !== '' && realEstateValue[year]['Asset Value'] !== undefined && cashOut['Property Tax'] !== undefined && cashOut['Property Tax'] !== '' ) {
      realEstatePropertyTax = cashOut['Property Tax'] * ( realEstateValue[year]['Asset Value'] / 100 );
    } else {
      realEstatePropertyTax = 0;
    }
    realEstateValue[year]['Property Tax'] = realEstatePropertyTax;

    // Home
    let realEstateHome = 0;
    if ( realEstateValue[year]['Asset Value'] > 0 ) {
      realEstateHome = calVal;
    } else {
      realEstateHome = 0;
    }
    realEstateValue[year].Home = realEstateHome;

    // Accumulated
    realEstateAccumulated += realEstateHome;
    realEstateValue[year].Accumulated = realEstateAccumulated;

    /* Data 1 */
    data1[year] = {};

    // Dowm Payment
    let dataDownPayment = 0;
    if ( year === purchaseYear ) {
      dataDownPayment = downPayment;
    }
    data1[year]['Down Payment'] = dataDownPayment;

    // Fit Out Costs
    let dataFitOut = 0;
    if ( year === purchaseYear ) {
      dataFitOut = fitOfCosts;
    }
    data1[year]['Fit Out Costs'] = dataFitOut;

    // Closing Costs
    let dataClosingCost = 0;
    if ( year === purchaseYear ) {
      dataClosingCost = closingCosts;
    }
    data1[year]['Closing Costs'] = dataClosingCost;

    // Mortgage Payment
    let dataMortgagePayment = 0;
    if ( year >= purchaseYear ) {
      dataMortgagePayment = cashOut['Mortgage Payment'];
    }
    data1[year]['Mortgage Payment'] = dataMortgagePayment;

    // Private Mortgage Insurance
    let dataPrivateInsurance = 0;
    // moduleServices.privateMortgageInsuranceCalcs();
    if ( module13Data !== undefined
                     && module13Data.year_of_purchase !== undefined
                    && year >= module13Data.year_of_purchase ) {
      if ( mortgage1Data !== undefined
                         && mortgage1Data['Summation Data'] !== undefined
                         && mortgage1Data['Summation Data'][year] !== undefined
                         && mortgage1Data['Summation Data'][year].BeginningBalance !== undefined
                        && ( mortgage1Data['Summation Data'][year].BeginningBalance / realEstateValue[year]['Asset Value'] ) >= ( 80 / 100 ) ) {
        if ( module13Data !== undefined
                             && module13Data.privateMortgageInsuranceCalcs !== undefined ) {
          dataPrivateInsurance = module13Data.privateMortgageInsuranceCalcs;
        }
      }
    }
    data1[year]['Private Mortgage Insurance'] = dataPrivateInsurance;

    // Association Fees
    if ( year === purchaseYear ) {
      dataAssociationFees = associationFees;
    } else if ( year >= purchaseYear ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined && assumptionsData[year].Expenses !== undefined ) {
        dataAssociationFees *= ( assumptionsData[year].Expenses / 100 );
      } else {
        dataAssociationFees = 0;
      }
    } else {
      dataAssociationFees = 0;
    }
    data1[year]['Association Fees'] = dataAssociationFees;

    // Home Owners Insurance
    /* if(year === purchaseYear) {
                    dataInsurance = cashOut['Homeowners Insurance'];
                } else {
                    if(year >= purchaseYear) {
                        if ( assumptionsData !== undefined &&  assumptionsData[year] !== undefined &&  assumptionsData[year]['Expenses'] !== undefined) {
                            dataInsurance = dataInsurance * (assumptionsData[year]['Expenses'] / 100);
                        } else {
                            dataInsurance = 0;
                        }
                    } else {
                        dataInsurance = 0;
                    }
                } */
    if ( realEstateValue !== undefined && realEstateValue !== '' && realEstateValue[year]['Asset Value'] !== undefined && cashOut['Property Tax'] !== undefined && cashOut['Annual Homeowner Insurance'] !== '' ) {
      dataInsurance = cashOut['Annual Homeowner Insurance'] * ( realEstateValue[year]['Asset Value'] / 100 );
    } else {
      dataInsurance = 0;
    }
    data1[year]['Houseowners Insurance'] = dataInsurance;

    // Property Maintenance
    /* if(year === purchaseYear) {
                    dataMaintenance = maintenance;
                } else {
                    if(year >= purchaseYear) {
                        if ( assumptionsData !== undefined &&  assumptionsData[year] !== undefined &&  assumptionsData[year]['Expenses'] !== undefined) {
                            dataMaintenance = dataMaintenance * (assumptionsData[year]['Expenses'] / 100);
                        } else {
                            dataMaintenance = 0;
                        }
                    } else {
                        dataMaintenance = 0;
                    }
                } */
    if ( realEstateValue !== undefined && realEstateValue !== '' && realEstateValue[year]['Asset Value'] !== undefined && cashOut['Property Tax'] !== undefined && cashOut['Annual Maintenance'] !== '' ) {
      dataMaintenance = cashOut['Annual Maintenance'] * ( realEstateValue[year]['Asset Value'] / 100 );
    } else {
      dataMaintenance = 0;
    }
    data1[year]['Property Maintenance'] = dataMaintenance;

    // Utilities
    if ( year === purchaseYear ) {
      dataUtilities = utilities;
    } else if ( year >= purchaseYear ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined && assumptionsData[year].Expenses !== undefined ) {
        dataUtilities *= ( assumptionsData[year].Expenses / 100 );
      } else {
        dataUtilities = 0;
      }
    } else {
      dataUtilities = 0;
    }
    data1[year].Utilities = dataUtilities;

    // Property Taxes
    const dataPropertyTaxes = realEstateValue[year]['Property Tax'];
    data1[year]['Property Taxes'] = dataPropertyTaxes;

    // Net Cash Flow
    data1[year]['Net Cash Flow'] = -( parseFloat( data1[year]['Down Payment'] )
                    + parseFloat( data1[year]['Fit Out Costs'] )
                    + parseFloat( data1[year]['Closing Costs'] )
                    + parseFloat( data1[year]['Mortgage Payment'] )
                    + parseFloat( data1[year]['Private Mortgage Insurance'] )
                    + parseFloat( data1[year]['Association Fees'] )
                    + parseFloat( data1[year]['Houseowners Insurance'] )
                    + parseFloat( data1[year]['Property Maintenance'] )
                    + parseFloat( data1[year].Utilities )
                    + parseFloat( data1[year]['Property Taxes'] ) );
    // Identifier
    if ( ( purchaseYear <= year ) && sellYear >= year ) {
      if ( purchaseYear === year ) {
        identifier = 0;
      }
    } else {
      identifier = '';
    }

    // First Value
    if ( identifier === 0 ) {
      // ROIFirstValue = parseFloat(data1[year]['Net Cash Flow']);
      // reiStartYear = year;
      // reiEndYear = year;
      yearsListArr.push( year );
    } else if ( identifier > 0 && identifier !== '' ) {
      // Sum of ROI
      // ROISum = parseFloat(ROISum) + parseFloat(data1[year]['Net Cash Flow']);
      yearsListArr.push( year );
      // reiEndYear = year;
    }

    /* Data 2 */
    data2[year] = {};

    // Mortgage Interest
    let data2MortgageInterest = 0;
    if ( mortgage1Data !== undefined
                     && mortgage1Data['Summation Data'] !== undefined
                     && mortgage1Data['Summation Data'][year] !== undefined
                     && mortgage1Data['Summation Data'][year].LaggingInterest !== undefined ) {
      data2MortgageInterest = mortgage1Data['Summation Data'][year].LaggingInterest;
    } else {
      data2MortgageInterest = 0;
    }
    data2[year]['Mortgage Interest'] = data2MortgageInterest;

    // Mortgage Points
    let data2MortgagePoints = 0;
    if ( year === purchaseYear ) {
      data2MortgagePoints = closingCostsData['Loan Points'];
    } else {
      data2MortgagePoints = 0;
    }
    data2[year]['Points of Mortgage'] = data2MortgagePoints;

    // Prepaid Insurance
    let data2PrepaidInsurance = 0;
    if ( year === purchaseYear ) {
      data2PrepaidInsurance = closingCostsData['House Owners Insurance'];
    } else {
      data2PrepaidInsurance = 0;
    }
    data2[year]['Prepaid Home Owners Insurance'] = data2PrepaidInsurance;

    // Property Depreciation
    const data2PropertyDepreciation = realEstateValue[year].Home;
    data2[year]['Property Depreciation'] = data2PropertyDepreciation;

    // Property Maintenance
    data2[year]['Property Maintenance'] = data1[year]['Property Maintenance'];

    // Property Taxes
    data2[year]['Property Taxes'] = data1[year]['Property Taxes'];

    // Total Tax Deductions
    data2[year]['Total Tax Deductions'] = parseFloat( data2[year]['Mortgage Interest'] )
                + parseFloat( data2[year]['Points of Mortgage'] )
                + parseFloat( data2[year]['Prepaid Home Owners Insurance'] )
                + parseFloat( data2[year]['Property Depreciation'] )
                + parseFloat( data2[year]['Property Maintenance'] )
                + parseFloat( data2[year]['Property Taxes'] );
  }

  // To return Student Loan Complete Data
  function buyingAHomeCompleteData() {
    const buyingAHomeInfo = {};
    buyingAHomeInfo['Buying Expenses'] = buyingExpenses;
    buyingAHomeInfo['Cash Out'] = cashOut;
    buyingAHomeInfo.Data1 = data1;
    buyingAHomeInfo.Data2 = data2;
    buyingAHomeInfo['Real Estate'] = realEstateValue;
    buyingAHomeInfo['Closing Cost'] = closingCostsData;
    buyingAHomeInfo['Real Estate Asset Value'] = assetValueCal;
    buyingAHomeInfo['Years List'] = yearsListArr;
	//console.log('buyingAHomeInfo', buyingAHomeInfo);
    return buyingAHomeInfo;
  }
  return buyingAHomeCompleteData();
}
export default buyingAHomeSheetData;
