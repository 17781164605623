const styles = ( ) => ( {
  containerFluid: {
    maxWidth: '100%',
    padding: '30px',
    margin: '0px auto',
    background: '#014372',
    minHeight: '100vh',
    boxSizing: 'border-box',
  },
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  screenContainer: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  img: {
    verticalAlign: 'middle',
  },
  dashboardLogo: {
    width: '150px',
    '& a': {
      display: 'flex',
      '& img': {
        width: '100%',
      },
    },
  },
  powerUpLogo: {
    maxWidth: '410px',
    margin: '60px auto 30px auto',
    paddingBottom: '25px',
    paddingTop: '62px',
    lineHeight: '1.42857143',
    '& img': {
      width: '100%',
    },
  },
  welcomeScreen: {
    margin: '0 auto',
    boxSizing: 'border-box',
  },
  mainLogo: {
    maxWidth: '410px',
    margin: '60px auto 30px auto',
    boxSizing: 'border-box',
    paddingBottom: '20px',
    '& img': {
      width: '100%',
    },
  },
  introScreen1: {
    textAlign: 'center',
    color: '#fff',
    '& h3': {
      fontSize: '30px',
      color: '#fff',
      marginBottom: '25px',
      marginTop: '25px',
      fontWeight: 400,
    },
    '& p': {
      fontSize: '22px',
      marginBottom: '35px',
    },
  },
  nextButton: {
    background: '#cea347',
    border: '2px solid #fff',
    color: '#fff',
    padding: '7px 45px',
    borderRadius: '6px',
    textTransform: 'uppercase',
    marginTop: '50px',
    fontSize: '20px',
    lineHeight: '1.42857143',
    '&:hover': {
      background: '#d09924',
      border: '2px solid #d09924',
    },
  },
} );

export default styles;
