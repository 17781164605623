import searchIcon from '../../assets/img/sight/explorer_search_icon.png';
import moveIc from '../../assets/img/move_ic.png';

const styles = (theme) => ({
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  containerFluild: {
    maxWidth: '90%',
    padding: '0 15px',
    margin: '0px auto',
  },
  explorerHead: {
    background: '#84aa47',
    margin: '0',
    padding: '10px 0',
    color: theme.palette.common.white,
    '& container': {
      maxWidth: '90%',
    },
  },
  explorerTitle: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.1',
    flexWrap: 'wrap',
    '& h2': {
      margin: '0',
      padding: '0',
      color: theme.palette.common.white,
      position: 'relative',
      fontSize: '30px',
      fontWeight: '500',
      fontFamily: '"MuseoSans-500"',
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        textAlign: 'center',
      },
    },
  },
  relationYear: {
    width: '50%',
    textAlign: 'right',
    fontSize: '20px',
    fontWeight: '500',
    fontFamily: '"MuseoSans-300"',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '18px',
      marginTop: '10px',
      justifyContent: 'center',
    },
    '& p': {
      margin: '0px',
      marginLeft: '5px',
    },
  },
  infoIcon: {
    '& img': {
      marginTop: '3px',
      maxWidth: '20px',
      cursor: 'pointer',
    },
  },
  breadCrumbs: {
    background: '#f1f1f1',
    padding: '8px',
    display: 'inline-block',
    width: '100%',
    boxSizing: 'border-box',
  },
  breadCrumbsLeft: {
    background: theme.palette.common.white,
    padding: '8px',
    float: 'left',
    width: '70%',
    verticalAlign: 'middle',
    display: 'inline-block',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      float: 'none',
      display: 'flex',
    },
    '& span': {
      display: 'block',
      color: '#000',
      fontSize: '18px',
      lineHeight: '18px',
      border: '0',
      outline: '0',
      textDecoration: 'none',
      cursor: 'default',
      '&:after': {
        position: 'absolute',
        marginTop: '-5px',
        borderTop: '5px solid transparent',
        borderLeft: '8px solid #5a5a5a',
        borderBottom: '5px solid transparent',
        top: '50%',
        right: '0',
        content: '""',
      },
      '& img': {
        width: 'auto',
        height: '18px',
      },
    },
  },
  folderIcon: {
    width: '5%',
    float: 'left',
    '& ul': {
      display: 'inline-block',
      float: 'left',
      width: '100%',
      margin: '0',
      padding: '0',
      '& li': {
        position: 'relative',
        padding: '0 15px 0 3px',
        verticalAlign: 'middle',
        display: 'inline-block',
        '& a': {
          display: 'block',
          color: '#000',
          fontSize: '18px',
          lineHeight: '18px',
          border: '0',
          outline: '0',
          textDecoration: 'none',
          cursor: 'default',
          '&:after': {
            position: 'absolute',
            marginTop: '-5px',
            borderTop: '5px solid transparent',
            borderLeft: '8px solid #5a5a5a',
            borderBottom: '5px solid transparent',
            top: '50%',
            right: '0',
            content: '""',
          },
          '& img': {
            width: 'auto',
            height: '18px',
          },
        },
      },
    },
  },
  breadcrumbsList: {
    width: '95%',
    float: 'left',
    verticalAlign: 'middle',
    display: 'inline-block',
    '& ul': {
      display: 'inline-block',
      float: 'left',
      width: '100%',
      margin: '0',
      padding: '0',
      paddingLeft: '0',
      marginLeft: '-3px',
      '& li': {
        position: 'relative',
        padding: '0 20px 0 3px',
        verticalAlign: 'middle',
        display: 'inline-block',
        '& span': {
          display: 'block',
          color: '#000',
          fontSize: '18px',
          lineHeight: '18px',
          border: '0',
          outline: '0',
          textDecoration: 'none',
          cursor: 'pointer',
          '&:after': {
            position: 'absolute',
            marginTop: '-5px',
            marginRight: '5px',
            borderTop: '5px solid transparent',
            borderLeft: '8px solid #5a5a5a',
            borderBottom: '5px solid transparent',
            top: '50%',
            right: '0',
            content: '""',
          },
          [theme.breakpoints.down('md')]: {
            fontSize: '12px',
            paddingLeft: '11px',
          },
        },
        '&:last-child': {
          '& span': {
            cursor: 'default',
            pointerEvents: 'none',
            '&:after': {
              display: 'none',
            },
          },
        },
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '20px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  fullBreadcrumbsList: {
    width: '100%',
  },
  breadCrumbsRight: {
    display: 'inline-block',
    float: 'right',
    width: '25%',
    position: 'relative',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      float: 'none',
      margin: '20px 0px',
    },
  },
  searchInput: {
    width: '100%',
    height: '36px',
    lineHeight: '32px',
    color: '#000',
    border: '2px solid #dcdcdc',
    outline: '0',
    padding: '0 10px',
    fontStyle: 'italic',
    boxSizing: 'border-box',
    fontSize: '14px',
    '&::placeholder': {
      color: '#999',
    },
  },
  searchButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    width: '40px',
    height: '100%',
    backgroundImage: `url(${searchIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '25px',
    border: 'none',
    outline: '0',
    backgroundColor: 'transparent',
  },
  explorerMainContent: {
    padding: '2px 8px 8px 8px',
    background: '#f7f7f7',
    overflow: 'hidden',
    minHeight: 'calc(100vh - 301px)',
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
    },
    '& > div': {
      display: 'table',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        // minWidth: '1250px',
        minWidth: '1000px',
      },
    },
  },
  explorerContent: {
    width: '17.83%',
    position: 'relative',
    background: '#f7f7f7',
    borderRight: '1px solid #ebebeb',
    display: 'inline-block',
    float: 'left',
    paddingBottom: '12px',
    height: 'auto',
    boxSizing: 'border-box',
    '& h2': {
      background: '#5686d2',
      padding: '8px 20px',
      color: '#fff',
      margin: '0',
      fontSize: '20px',
      lineHeight: '20px',
      fontWeight: '500',
      fontFamily: '"MuseoSans-500"',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
      },
    },
    '& ul': {
      display: 'block',
      width: '100%',
      margin: '0',
      padding: '0',
      // height: '273px',
      '& li': {
        display: 'block',
        padding: '0',
        background: '#fff',
        borderBottom: '1px solid #ebebeb',
        position: 'relative',
        '& a': {
          display: 'flex',
          color: '#000',
          fontSize: '15px',
          lineHeight: '15px',
          padding: '8px',
          border: '2px solid transparent',
          minHeight: '38px',
          outline: '0',
          textDecoration: 'none',
          boxSizing: 'border-box',
          '& img': {
            width: '18px',
            height: '18px',
            margin: '0 7px 0 0',
            verticalAlign: 'middle',
          },
        },
        '& span': {
          display: 'flex',
          color: '#000',
          fontSize: '14px',
          lineHeight: '15px',
          padding: '8px',
          border: '2px solid transparent',
          minHeight: '38px',
          outline: '0',
          textDecoration: 'none',
          boxSizing: 'border-box',
          cursor: 'pointer',
          '& img': {
            width: '18px',
            height: '18px',
            margin: '0 7px 0 0',
            verticalAlign: 'middle',
            borderRadius: '50%',
          },
        },
      },
    },
  },
  sketchContent: {
    width: '17.83%',
    '& li': {
      '& a': {
        '& img': {
          width: '30px',
          height: '30px',
          margin: '-8px 7px -8px 0',
          borderRadius: '50%',
          maxWidth: 'inherit',
        },
      },
      '&:hover': {
        borderColor: '#99d9ea',
        background: '#99d9ea',
      },
      '& span': {
        '& img': {
          width: '30px !important',
          height: '30px !important',
        },
      },
    },
    '& ul': {
      maxHeight: '250px',
      overflowY: 'auto',
    },
  },
  modulesContent: {
    width: '17.83%',
    '& ul': {
      overflowY: 'auto',
    },
    '& li': {
      '&:hover': {
        borderColor: '#99d9ea',
        background: '#99d9ea',
      },
    },
  },
  scenariosContent: {
    border: 'none',
    width: '23.4%',
    [theme.breakpoints.down('md')]: {
      width: '22.4%',
    },
    '& li': {
      '&:hover': {
        borderColor: '#99d9ea',
        background: '#99d9ea',
      },
    },
  },
  infoImage: {
    width: '18px',
    height: '18px',
    verticalAlign: 'top',
    margin: '2px 0 0',
    display: 'inline-block',
    marginLeft: '5px',
    '& img': {
      marginTop: '0',
      maxWidth: 'inherit',
      cursor: 'pointer',
      width: '100%',
      height: 'auto',
      verticalAlign: 'top',
    },
  },
  addNewBtn: {
    background: '#f7f7f7',
    position: 'static',
    padding: '4px 10px',
    bottom: '0px',
    left: '0',
    boxSizing: 'border-box',
    width: '100%',
    // '@media (min-width: 1024px) and (max-width: 1399px)': {
    //   marginTop: '-20px',
    // },
    '& ul': {
      overflow: 'inherit',
      float: 'none',
      position: 'relative',
      display: 'flex',
      width: '100%',
      verticalAlign: 'top',
      height: 'auto',
      margin: '0',
      padding: '0',
      '& li': {
        display: 'inline-block',
        float: 'left',
        width: '50%',
        textAlign: 'left',
        background: '0 0',
        border: 'none',
        position: 'relative',
        padding: '0',
        '&:last-child': {
          textAlign: 'right',
        },
        '&:hover': {
          background: 'transparent',
          textTransform: 'capitalize',
        },
      },
    },
  },
  circlrBtn: {
    borderRadius: '100%',
    color: '#fff',
    fontSize: '20px',
    lineHeight: '28px',
    display: 'inline-block',
    width: '30px',
    minWidth: '30px',
    height: '30px',
    border: 'none',
    textAlign: 'center',
    padding: '0',
    position: 'relative',
    fontFamily: '"MuseoSans-500"',
    minHeight: 'inherit',
    textTransform: 'capitalize',
    '& span': {
      color: '#fff !important',
      fontSize: '20px !important',
      minHeight: 'inherit !important',
      lineHeight: '10px !important',
    },
  },
  minusBtn: {
    background: '#d9534f',
    '&:hover': {
      color: '#fff',
      background: '#d9534f',
    },
  },
  plusBtn: {
    background: '#006aaa',
  },
  loadBtn: {
    textTransform: 'uppercase',
    fontSize: '13px',
    padding: '7px 8px',
    backgroundColor: '#006aaa',
    borderRadius: '4px',
    border: 'none',
    color: '#fff',
    outline: '0',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#398439',
    },
  },
  smallText: {
    right: '10px',
    position: 'absolute',
    top: '50%',
    lineHeight: '14px',
    marginTop: '-8px',
    fontSize: '14px',
    zIndex: '99',
    cursor: 'pointer',
    color: '#000',
  },
  modalHeader: {
    padding: '10px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      margin: '0',
      lineHeight: '1.42857143',
      fontSize: '24px',
      color: '#0069aa',
      fontWeight: 'normal',
    },
  },
  modalBody: {
    position: 'relative',
    padding: '15px',
    '& p': {
      margin: '0px',
      fontSize: '14px',
    },
  },
  modalFooter: {
    padding: '15px',
    textAlign: 'right',
    borderTop: '1px solid #e5e5e5',
    width: '100%',
  },
  videoPopup: {
    padding: '0px',
  },
  btn: {
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    cursor: 'pointer',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '6px 5px',
    minWidth: '64px',
    textTransform: 'uppercase',
  },
  footerLoad: {
    backgroundColor: '#337ab7',
    borderColor: '#337ab7',
    color: '#fff',
    outline: '0',
    border: '0px',
    '&:hover': {
      backgroundColor: '#285e8c',
      borderColor: '#285e8c',
      outline: '0',
    },
  },
  footerCancel: {
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#ccc',
    marginLeft: '10px',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  tooltipInfo: {
    background: '#0069aa',
    fontSize: '14px',
    padding: '5px 10px !important',
    maxWidth: '325px',
    lineHeight: '18px',
    textAlign: 'left',
    zIndex: '9999',
    borderRadius: '4px',
    fontWeight: '400',
    opacity: '1 !Important',
    whiteSpace: 'pre-wrap',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '250px',
    },
  },
  senseBody: {
    backgroundColor: '#f7f7f7',
    padding: '15px',
    paddingBottom: '50px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    }
  },
  activeTab: {
    borderColor: ' #99d9ea',
    background: '#99d9ea',
    '& input': {
      width: '78%',
      height: '32px',
      padding: '0 8px',
      boxSizing: 'border-box',
      marginRight: '5px',
      fontFamily: 'inherit',
    },
  },
  impPopup: {
    padding: '0',
    boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    background: theme.palette.common.white,
    maxWidth: '600px',
    borderRadius: '6px',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      margin: '0 auto',
    },
    '& > div': {
      padding: '15px',
    },
    '& h3': {
      fontSize: '18px',
      color: '#0069aa',
      fontWeight: '500',
      borderBottom: '1px solid #e2e2e2',
      paddingBottom: '10px',
      margin: ' 0',
      lineHeight: '1.42857143',
    },
    '& p': {
      marginBottom: '0',
      color: '#333',
      lineHeight: '24px',
    },
  },
  impDiv: {
    padding: '15px 0 5px',
    display: 'flex',
    '& p': {
      padding: '0 0 0 24px',
      marginTop: 0,
      fontSize: '16px',
    },
    '& span': {
      paddingTop: '6px',
    },
  },
  impbuttonOkRedo: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        background: '#337ab7',
        borderColor: '#337ab7',
        color: '#fff',
        marginRight: '6px',
        '&:hover': {
          background: '#285e8c',
          borderColor: '#285e8c',
        },
      },
      '&:last-child': {
        color: '#fff',
        background: '#d9534f',
        borderColor: '#d9534f',
        '&:hover': {
          background: '#c9302c',
          borderColor: '#c9302c',
        },
      },
    },
  },
  impbuttonOkDefault: {
    '& button': {
      '&:last-child': {
        color: '#333',
        background: '#fff',
        border: '2px solid #ccc',
        '&:hover': {
          backgroundColor: '#e6e6e6',
          borderColor: '#ccc',
        },
      },
    },
  },
  noPadding: {
    '& p': {
      padding: 0,
    },
  },
  inActiveModule: {
    '& img': {
      filter: 'grayscale(100%) contrast(0%) brightness(100%) opacity(50%)',
    },
    '& span': {
      color: '#808080 !important',
    },
  },
  hideButton: {
    display: 'none',
  },
  manageBtn: {
    borderRadius: '4px',
    border: 'none',
    fontSize: '13px',
    padding: '0px',
    backgroundColor: '#006AAA',
    color: '#fff',
    '& span': {
      color: '#fff !important',
      minHeight: 'auto !important',
      lineHeight: '1.42857143 !important',
      fontSize: '13px !important',
      padding: '5px 8px !important',
    },
    '&:hover': {
      backgroundColor: '#006AAA',
    },
  },
  cancelBtn: {
    backgroundColor: '#d9534f',
    '&:hover': {
      backgroundColor: '#d9534f',
    },
  },
  dangerText: {
    color: '#bf0000',
    fontSize: '13.5px',
    '& span': {
      color: '#bf0000 !important',
      padding: '0 !important',
    },
  },
  saveFolder: {
    width: '25px',
    height: '25px',
    boxShadow: '0 0 3px 2px #d4d4d4',
    background: '#84AB48',
    borderRadius: '100%',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '3px',
      left: '8px',
      width: '6px',
      height: '13px',
      border: 'solid #ffffff',
      borderWidth: '0 2px 2px 0',
      transform: 'rotate(45deg)',
    },
  },
  MainMenuNew: {
    maxHeight: 'calc(100vh - 210px)',
    overflow: 'auto',
    // height: 'calc(332px - 32px) !important',
    height: 'calc(332px - 105px) !important',
    '& ul': {
      // padding: '0 26px',
      listStyle: 'none',
      margin: '0',
      '&  div': {
        '& li': {
          padding: '0 10px',
        },
      },
      '& li': {
        // marginBottom: '10px',
        '& a': {
          display: 'flex',
          alignItems: 'center',
          color: '#333',
          cursor: 'pointer',
          transiction: 'all 0.3s',
        },
        '& span': {
          paddingLeft: '14px',
          marginTop: '0px',
          display: 'inline-block',
          width: '100%',
        },
        '& figure': {
          margin: '0',
          '& img': {
            width: '30px',
            height: '30px',
            display: 'flex',
          },
        },
        '&:hover': {
          '& a': {
            color: 'orange',
            textDecoration: 'none',
          },
          '& span':{
            borderColor: '#99d9ea',
            background: '#99d9ea',
          },
        },
      },
    },
  },
  exploreOne: {
    width: '13.5%',
  },
  exploreTwo: {
    width: '13.5%',
  },
  exploreThird: {
    width: '13.5%',
  },
  sightPopupBlock: {
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    // height: 'calc(100vh - 120px)',
    maxHeight: 'calc(100vh - 120px)',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'initial',
    }
  },
  scrollMenu: {
    minHeight: '233px',
  },
  sightSavePopup: {
    '& > div > div': {
      width: '98%',
      maxWidth: '100%',
      margin: '30px auto',
    },
  },
  createNewFolder: {
    background: 'transparent',
    borderBottom: 'none !important',
    '& p': {
      margin: '0',
      padding: '0 23px !important',
      position: 'relative',
      '& input': {
        width: '100%',
        border: 'none',
        borderBottom: '1px solid #dcdcdc',
        background: '0 0',
        margin: '0 auto 11px',
        display: 'block',
        height: '50px !important',
        lineHeight: '50px',
        padding: '0 15px !important',
        outline: '0',
        fontSize: '16px',
        fontFamily: '"MuseoSans-300"',
        boxSizing: 'border-box',
        '&::placeholder': {
          color: '#0069aa',
        },
      },
    },
  },
  sightPage: {
    display: 'block',
  },
  dragTag:{
    backgroundImage: `url(${moveIc})`,
    borderRadius: '50%',
    height: '23px',
    position: 'absolute',
    top: '-8px',
    left: '-10px',
    width: '22px',
    zIndex: '99999',
    cursor: 'move',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#ccc',
    backgroundPosition: 'center',
    '& #myDiv header .active': {
      userSelect: "none",
    },
  }    
  
});

export default styles;
