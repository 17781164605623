import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
// import * as modulesServices from '../../calculations/modules-services';
import classNames from 'classnames';

const LongTermInvestment = ( props ) => {
  const { classes, handleAssestObject,handleUpdatedObject, handleDefinationPopup } = props;
  const [longTermInvestments, setLongTermInvestments] = React.useState({});
  const [total, setTotal] = React.useState(0);

  React.useEffect(()=>{
    
    if(handleAssestObject.long_term_managed_accounts >= 0 && handleAssestObject.long_term_other >= 0 && handleAssestObject.long_term_stocks_and_bonds >= 0 && handleAssestObject.long_term_etfs >= 0 && handleAssestObject.long_term_reits >= 0 && handleAssestObject.long_term_mutual_funds >= 0){
      const inputValues = {...handleAssestObject};
      inputValues.existing_total_long_term_investments = 
        parseInt(handleAssestObject.long_term_managed_accounts) +
        parseInt(handleAssestObject.long_term_stocks_and_bonds) +
        parseInt(handleAssestObject.long_term_etfs) +
        parseInt(handleAssestObject.long_term_mutual_funds) +
        parseInt(handleAssestObject.long_term_reits) +
        parseInt(handleAssestObject.long_term_other);
  
      handleUpdatedObject( inputValues );
      setTotal(inputValues.existing_total_long_term_investments);
    }
  }, [longTermInvestments])

  const handleChanges = (e, name) => {
    const inputValues = {...handleAssestObject};
    const v = e.floatValue !== undefined ? e.floatValue : 0;
    inputValues[name] = v;
    handleUpdatedObject( inputValues );
    setLongTermInvestments({...longTermInvestments, [name]: v})
  }
  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>
        <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." className={ classes.definationTittle } onClick={ () => handleDefinationPopup( 'Long-Term Investments' ) }>Long-term investments</span>
        {' '} are accounts that you do not intend to use in the short term, which is usually considered to be less
        than one year. For example, each month you invest a portion of your paycheck using an investment app on your phone. 
        Those investments are meant to grow over time and earn a higher return on investment.
      </p>
      <p className={ classes.existingSpace }>
        Think of long-term investments as building long-term wealth outside of your retirement accounts. 
        {' '} 
        <b>Enter the value for each of your accounts below.</b>
      </p>
      
      <div className={classNames(classes.tableBox, classes.tableBoxPWINew)}>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <th colSpan={ 2 }>Long-Term Investments</th>
          </thead>
          <tbody>
            <tr>
              <td><a href="https://www.investopedia.com/terms/m/managedaccount.asp" target="_blank"  rel="noreferrer">Managed Accounts</a></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale= {0} fixedDecimalScale thousandSeparator value={ handleAssestObject.long_term_managed_accounts } name="long_term_managed_accounts" onValueChange={ (e)=> handleChanges(e, 'long_term_managed_accounts') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td><a href="https://inkwiry.com/finance-explained/stocks-bonds" target="_blank"  rel="noreferrer">Stocks and Bonds</a></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField }  decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.long_term_stocks_and_bonds } name= "long_term_stocks_and_bonds" onValueChange={ (e)=> handleChanges(e, 'long_term_stocks_and_bonds') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td><a href="https://inkwiry.com/finance-explained/etf" target="_blank"  rel="noreferrer">Exchange Traded Funds (ETFs)</a></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.long_term_etfs } name= "long_term_etfs" onValueChange={ (e)=> handleChanges(e, 'long_term_etfs') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td><a href="/finance-explained/mutual-fund" ui-sref="html.mutual-fund" target="_blank"  rel="noreferrer">Mutual Funds</a></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.long_term_mutual_funds } name= "long_term_mutual_funds" onValueChange={ (e)=> handleChanges(e, 'long_term_mutual_funds') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td><a href="https://www.investopedia.com/terms/r/reit.asp" target="_blank" rel="noreferrer">REITs</a></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.long_term_reits } name= "long_term_reits" onValueChange={ (e)=> handleChanges(e, 'long_term_reits') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td><span>Other</span></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.long_term_other } name= "long_term_other" onValueChange={ (e)=> handleChanges(e, 'long_term_other') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr className={ classes.trFoot }>
              <td className={ classes.firstYearTutuin }>Total Long-Term Investments</td>
              <td>
                <div className={ classes.amountDollarRight }>
                  <span>
                    <NumberFormat value={ total } displayType="text" thousandSeparator prefix="$" />
                  </span>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

LongTermInvestment.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( LongTermInvestment );
