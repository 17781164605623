import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-vacation/styles';
import CareerSketchTimeline from '../ui/career-sketch-timeline/career-sketch-timeline';
import { timeline, sortTimeLine } from '../../calculations/time-line';
import * as modulesServices from '../../calculations/modules-services';
import _ from 'lodash';

const SpecialPurchaseTimelineTwo = ( props ) => {
  const {
    classes, inputData, updateData,
  } = props;

  const [vacationStartYear, setVacationStartYear] = React.useState( (inputData.third_start_year === undefined && inputData.third_start_year !== null ) ? 0 : inputData.third_start_year );
  const [vacationEndYear, setVacationEndYear] = React.useState( (inputData.third_end_year === undefined && inputData.third_end_year !== null) ? 0 : inputData.third_end_year );
  const [yearStartOptions, SetYearStartOptions] = React.useState( [] );
  const [yearEndOptions, SetYearEndOptions] = React.useState( [] );
  const [purchaseItem, setPurchaseItem ] = React.useState( inputData.second_purchase_item === undefined ? '' : inputData.second_purchase_item );

  const updateTimeLine = (startYearVal) => {
    const years = [];
    years.push(startYearVal);
    if(inputData.second_start_year !== undefined && inputData.second_start_year !== null && inputData.second_start_year !== '' && parseInt(inputData.second_start_year, 10) > 0) {
      years.push(inputData.second_start_year);
    }
    if(inputData.third_start_year !== undefined && inputData.third_start_year !== null && inputData.third_start_year !== '' && parseInt(inputData.third_start_year, 10) > 0) {
      years.push(inputData.third_start_year);
    }
    var start_year = '';
    if(years.length > 0) {
        start_year = Math.min.apply(Math,years);
    }
    let timelineData = [...timeline];
    const index = _.findIndex( timeline, { selectedModule: 'module11' } );
    if(index >= 0 && start_year !== '' && start_year > 0) {
      timelineData[index].startYear = start_year;
      timelineData[index].endYear = '';
      timelineData[index].imageSlug = 'active';
      timelineData[index].timelineOrder = start_year;
      sortTimeLine(timelineData);
    }
  }


  useEffect(()=>{
    let start_year = parseInt(modulesServices.module1Data.start_year);

    if(vacationStartYear !== undefined && vacationStartYear !== '' && parseInt(vacationStartYear, 10) > 0) {
        start_year = vacationStartYear;
    }
    if(start_year <=  0) {
      start_year = new Date().getFullYear();
    }
    const vacationEndYearOptions = [0];
    for (let i = start_year; i < parseInt(modulesServices.module1Data.start_year)+15; i+=1) {
        vacationEndYearOptions.push(i);
    }
    let endYearValue = vacationEndYear;
    SetYearEndOptions(vacationEndYearOptions);
    if(vacationEndYear !== undefined && vacationEndYear !== '' && vacationEndYear > 0) {
      if(vacationStartYear !== undefined && vacationStartYear !== '' && parseInt(vacationStartYear, 10) > 0) {
        const endIndex = vacationEndYearOptions.indexOf(parseInt(vacationEndYear, 10));
        if(endIndex === -1) {
          endYearValue = vacationEndYearOptions[1];
        }
      }
    } else {
      endYearValue = 0;
    }
    setVacationEndYear(endYearValue);

    let inputDataValues = {...inputData};
    inputDataValues['third_start_year'] = vacationStartYear;
    inputDataValues['third_end_year'] = endYearValue;
    inputDataValues['second_purchase_item'] =  purchaseItem;
    updateData('total_obj',inputDataValues);

    updateTimeLine(vacationStartYear);
    // eslint-disable-next-line
  },[vacationStartYear])

  useEffect( () => {
    const vacationStartYearOptions = [0];
    for (let i = parseInt(modulesServices.module1Data.start_year, 10); i < parseInt(modulesServices.module1Data.start_year, 10)+15; i+=1) {
        vacationStartYearOptions.push(i);
    }
    SetYearStartOptions(vacationStartYearOptions);
    // eslint-disable-next-line
  }, [] );

  const handleSpclPurchase = ( event ) => {
    setPurchaseItem(event.target.value);
    updateData('second_purchase_item' , event.target.value);    
  };

  const handleVacationStartYear = ( event ) => {
    setVacationStartYear( parseInt(event.target.value, 10) );
  };

  const handleVacationEndYear = ( event ) => {
    let vacationEnd = parseInt(event.target.value, 10);
    if(vacationStartYear !== 'undefined' && vacationStartYear !== '' && parseInt(vacationStartYear, 10) > 0 ) {
        if(vacationStartYear > 0 && vacationStartYear > vacationEnd) {
             vacationEnd = vacationStartYear;
        }
    } else {
      vacationEnd = 0;
    }
    updateTimeLine(vacationStartYear);
    setVacationEndYear(vacationEnd);
    updateData( 'third_end_year', vacationEnd );
  };

  return (
    <div className={ classes.contentBlock }>
      <p>There might be a second hobby or a second thing that you plan to spend consistent money on. Or, one big thing that you&apos;re planning for such as a luxury handbag, new snowboard, or a lift kit for your car.</p>
      <p>What&apos;s that second special purchase that you want to bring into your plan? Put a name to your special purchase and set the timeline below.</p>
      <div className={ classNames( classes.grayBox, classes.timelineGrayBox ) }>
        <div className={ classes.specialPurchase }>
          <span>
            Name of Special Purchase #2:
            <input type="text" value={ purchaseItem } onChange={ handleSpclPurchase } />
          </span>
        </div>
        <ul className={ classes.yearsList }>
          <li>
            <span>
              I plan to start making this special purchase in year
              {' '}
            </span>
            {' '}
            <select value={ vacationStartYear } onChange={ handleVacationStartYear }>
              {yearStartOptions.map( ( year ) => <option value={ year } key={ year }>{year}</option> )}
            </select>
            .
          </li>
          <li>
            <span>
              I plan to stop making this special purchase in year
              {' '}
            </span>
            {' '}
            <select value={ vacationEndYear } onChange={ handleVacationEndYear }>
              { ( yearEndOptions.length > 0 && yearEndOptions.map( ( year ) => <option value={ year } key={ year }>{year}</option> ) )}
            </select>
            .
          </li>
        </ul>
      </div>
      <CareerSketchTimeline moduleName="module11"/>

    </div>
  );
};

SpecialPurchaseTimelineTwo.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( SpecialPurchaseTimelineTwo );
