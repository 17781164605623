const styles = ( ) => ( {
  container: {
    height: '100vh',
    background: '#e9eff3',
  },
  succesPopup: {
    padding: '0 15px',
    paddingTop: '100px',
  },
  popupCheck: {
    width: '142px',
    height: '142px',
    border: '4px solid #008000',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '150px',
    color: '#008000',
    fontWight: '600',
    margin: '0 auto 30px auto',
    display: 'block',
    fontSize: '110px',
    position: 'relative',
    '& i::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '13px',
      left: '60px',
      width: '27px',
      height: '92px',
      border: 'solid #008000',
      borderWidth: '0 4px 4px 0',
      transform: 'rotate(45deg)',
    },
  },
  successPopupDesign: {
    background: '#fff',
    maxWidth: '460px',
    textAlign: 'center',
    borderRadius: '15px',
    padding: '25px',
    color: '#000',
    margin: '0 auto',
    '& h2': {
      color: '#000',
      fontSize: '28px',
      lineHeight: '35px',
      margin: '10px 10px 10px 0',
    },
    '& p': {
      fontSize: '22px',
      lineHeight: '30px',
      color: '#000000',
      margin: '0 0px 7px 0',
      '& a': {
        color: '#016aab',
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
          color: '#fd9840',
          textDecoration: 'none',
        },

      },
    },
  },

} );

export default styles;
