import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import classNames from 'classnames';
import styles from './styles';
import mcsTopImage from '../../../assets/img/mcs/level4_bg.png';
import logoImage from '../../../assets/img/mcs/my-career-sketch.png';
import logoInkwiry from '../../../assets/img/mcs/logo.png';

const WelcomeScreen = ( props ) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.mcsLevelOne }>
        <div className={ classes.topImage }>
          <img src={ mcsTopImage } alt="mcs" />
        </div>
        <div className={ classes.container }>
          <div className={ classes.headerRow }>
            <div className={ classes.mcsLogoImage }>
              <Link to="/">
                <img src={ logoImage } alt="logo" />
              </Link>
            </div>
            <div className={ classes.introductionMsg }>
              <h3>INTRODUCTION</h3>
            </div>
          </div>
          <div className={ classes.mcsTitle }>
            <h1>Welcome</h1>
          </div>
          <div className={ classes.mcsRow }>
            <div className={ classes.leftBlock }>
              <p className={ classes.subTitle }>At Inkwiry, we’re on a mission to bring financial life planning and financial freedom to all. And that means you!</p>
              <div className={ classes.descBlock }>
                <p>My Career Sketch can help you design a life you’ll love – working and playing to your full potential – where your career is fulfilling and you’re earning more than enough to pay the bills. Now is the time to dream big. When you design your own life, it’ll include all the most important details you require to shape your personal happiness and financial well-being.</p>
                <p>Would you rather wing it through life? Take your chances? Gamble with your future? That could work. Or not. </p>
                <p>The truth is, many of the decisions you make today have predictable outcomes. We’ll show you how the education and career decisions you make right now can shape your future. </p>
                <p>Come along and see how you can shape a life you’ll love.</p>

              </div>
            </div>
            <div className={ classes.rightBlock }>
              <div className={ classNames( classes.blueBlock, classes.blueWelcome ) }>
                <p>With a plan, you make things happen.</p>
                <p>Without a plan things happen to you.</p>
              </div>
            </div>
          </div>
          <div className={ classes.mcsRow }>
            <div className={ classes.leftBlock }>
              <p className={ classes.subTitle }>Let&apos;s Focus on Your Education and Career</p>
              <div className={ classes.descBlock }>
                <p>Have you ever been asked, “Where do you see yourself in 10 years?” and thought: How can I possibly answer that question? I don’t know where I&apos;ll be this weekend let alone 10 years from now! Believe it or not, you’re on your way to answering that question. After completing My Career Sketch, you’ll have a solid plan for your education and your career for the next 5, 10, 15 years.</p>
                <p>Other life events – like investing, buying a car, buying a home and doing all the fun things like taking vacations and spending time with family and friends – hinge on how well you’ve planned for your higher education and career. </p>
                <p>When you have a roadmap in place detailing stepping stones to produce the maximum revenue possible, you’re on the right track. However, if you don’t identify strategic milestones, or any viable next steps, you’re likely to miss out on your greatest source of future income.  </p>
                <p>In My Career Sketch, we’ll help you develop a vision for the future, and then transform that vision into specific goals and stepping stones for your career.</p>
                <p>You will be amazed when you see what is possible.</p>
                <p>Let&apos;s get started.</p>
              </div>
            </div>
            <div className={ classes.rightBlock }>
              <div className={ classes.blueBlock }>
                <p>HERE&apos;S WHAT WE&apos;LL COVER:</p>
                <ul>
                  <li>Smarter tomorrows</li>
                  <li>Your vision and your goals</li>
                  <li>Elements of a career sketch</li>
                  <li>Stepping stones in your career</li>
                  <li>Career planning</li>
                  <li>Higher education options</li>
                  <li>Advanced planning strategies</li>
                  <li>Next steps in your career sketch</li>
                </ul>
              </div>
            </div>
          </div>

          <div className={ classes.mcsRow }>
            <div className={ classes.leftBlock }>
              <p className={ classes.subTitle }>Let&apos;s Focus on Your Education and Career</p>
              <div className={ classes.descBlock }>
                <p>Have you ever been asked, “Where do you see yourself in 10 years?” and thought: How can I possibly answer that question? I don’t know where I&apos;ll be this weekend let alone 10 years from now! Believe it or not, you’re on your way to answering that question. After completing My Career Sketch, you’ll have a solid plan for your education and your career for the next 5, 10, 15 years.</p>
                <p>Other life events – like investing, buying a car, buying a home and doing all the fun things like taking vacations and spending time with family and friends – hinge on how well you’ve planned for your higher education and career. </p>
                <p>For example, if you graduate on time from a four-year college, you’re on track. However, if you don’t graduate in four years, you’ll sacrifice about $150k per extra year in school in additional tuition, fees and expenses, interest on loans, and forgone income.</p>
                <p>In My Career Sketch, we’ll help you develop a vision for the future, and then transform that vision into specific goals and stepping stones for your career.</p>
                <p>You will be amazed when you see what is possible.</p>
                <p>Let&apos;s get started.</p>
              </div>
            </div>
            <div className={ classes.rightBlock }>
              <div className={ classes.blueBlock }>
                <p>HERE&apos;S WHAT WE&apos;LL COVER:</p>
                <ul>
                  <li>Smarter tomorrows</li>
                  <li>Your vision and your goals</li>
                  <li>Elements of a career sketch</li>
                  <li>Stepping stones in your career</li>
                  <li>Career planning</li>
                  <li>Higher education options</li>
                  <li>Advanced planning strategies</li>
                  <li>Next steps in your career sketch</li>
                </ul>
              </div>
            </div>
          </div>

          <div className={ classes.startButton }>
            <button type="button">Continue</button>
          </div>
        </div>
        <div className={ classes.mcsFooter }>
          <div className={ classes.container }>
            <div className={ classes.footerRow }>
              <div className={ classes.copyRight }>
                <p>Copyright © 2022 // All rights reserved</p>
              </div>
              <div className={ classNames( classes.copyRight, classes.informedText ) }>
                <p>
                  Informed and Powered by
                  <a href="/"><img src={ logoInkwiry } alt="inkwiry" /></a>
                </p>
              </div>
            </div>
          </div>
          <div className={ classes.mcsFluid }>
            <p className={ classes.termLinks }>
              <a href="/termsofservice">Terms of Service</a>
&nbsp;//&nbsp;
              <a href="/privacy">Privacy Policy</a>
&nbsp;//&nbsp;
              <a href="/security">Security</a>
            </p>
          </div>
        </div>
      </div>
    </Typography>
  );
};

WelcomeScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( WelcomeScreen );
