import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
// import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import * as commonFunctions from '../../../utilities/commonFunctions';
import styles from '../../../screens/pwi-career-advancement/styles';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../../calculations/modules-services';
import CareerBottomTabs from './career-bottom-tab-graphs';

const arrayValuesMatching = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 4,
    scaledValue: 4,
    label: '4%',
  },
  {
    value: 8,
    scaledValue: 8,
    label: '8%',
  },
  {
    value: 12,
    scaledValue: 12,
    label: '12%',
  },
  {
    value: 16,
    scaledValue: 16,
    label: '16%',
  },
  {
    value: 20,
    scaledValue: 20,
    label: '20%',
  },
];
const SepContributions = ( props ) => {
  const {
    classes, handleCareerObject, handleDefinationPopup, handleUpdatedObject,
  } = props;
  const [beginingBalannce, setBeginingBal] = React.useState( 0 );
  // const [iconStatus, setIconStatus] = React.useState( false );
  const [isFocus, setIsFocus] = React.useState('');

  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.ira_personal_contribution !== undefined ) {
      setBeginingBal( handleCareerObject.ira_personal_contribution );
    } else {
      updatedValues['ira_personal_contribution'] = 0;
    }
    handleUpdatedObject(updatedValues);
  },[]);

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'number' ) {
      newvalue = e.floatValue > 100 ? 100 : e.floatValue;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    setBeginingBal( newvalue );
    updatedValues[field] =newvalue;
    handleUpdatedObject(updatedValues);
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p>
        SEP IRAs are usually for self-employed individuals or small business owners. SEP IRA contributions are made before taxes, just like 401(k) contributions.
      </p>
      <p>How much do you plan to contribute to your SEP IRA?</p>
      <div className={ classes.livingExpencesRow }>
        <div className={ classes.livingExpencesDiv }>
          <div className={ classes.livingExpences }>
            <div className={ `${classes.mainBlock} ${classes.livingExpencesRangeFlex} ${classes.livingExpencesRangeFlexNewPensionCont} ${classes.mainFullBlock} ${classes.mainFullBlockFull}` }>
              {/* <div className={ `${classes.annuvalIncomeTittle} ${classes.annuvalIncomeTittleSpace} ${classes.annuvalIncomeTittleFull}` }>
                {' '}
                <span>Monthly</span>
                {' '}
              </div> */}
              <div className={ classes.rentGraphBlock }>
                <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangeFlexNew} ` }>
                  <p><span>Personal Contribution as a % of Base Income(25% limit)</span></p>
                  <div className={ classes.annuvalIncomeGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ arrayValuesMatching }
                      min={ 0 }
                      max={ 20 }
                      step={ 1 }
                      value={ beginingBalannce }
                      valueLabelFormat={ `${commonFunctions.perFormatter(
                        beginingBalannce,
                      )}` }
                      onChange={ ( e, value ) => updateValue( e, value, 'ira_personal_contribution', 'slider' ) }
                    />
                  </div>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <div className={ `${classes.annualInput} ${classes.annualInputRight}` }>
                    <NumberFormat
                      customInput={ TextField }
                      thousandSeparator
                      value={ beginingBalannce }
                      decimalScale={isFocus!=='ira_personal_contribution'?2:5}
                      fixedDecimalScale={isFocus!=='ira_personal_contribution'}
                      onValueChange={ ( e ) => updateValue( e, '', 'ira_personal_contribution', 'number' ) }
                      onFocus={ ( e ) => {
                        e.target.select();
                        setIsFocus('ira_personal_contribution')
                      } }
                      onBlur={emptyIsFocus}
                    />
                    <span>%</span>
                  </div>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <div className={ `${classes.annualInput} ${classes.annualInputRight} ${classes.floatRight}` }>
                    {moduleServices.module5Data.advCareerSEPIRAPersonalContributionCalcs !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.advCareerSEPIRAPersonalContributionCalcs } prefix={ moduleServices.module5Data.advCareerSEPIRAPersonalContributionCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.advCareerSEPIRAPersonalContributionCalcs < 0 && ')' } />
                    ) : ( '$0' )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={ classes.retirementBottomPage }>
        <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockNew}` }>
          <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
            &nbsp;
          </div>
          <div className={ `${classes.annuvalIncomeTittle} ${classes.annuvalIncomeTittleSpace} ${classes.annuvalIncomeTittleSpaceXtra} ${classes.annuvalIncomeTittleFull}` }>
            {' '}
            <span>Monthly</span>
            {' '}
          </div><div className={ `${classes.annuvalIncomeTittle} ${classes.annuvalIncomeTittleSpace} ${classes.annuvalIncomeTittleSpaceXtra} ${classes.annuvalIncomeTittleFull}` }>
            {' '}
            <span>Annual</span>
            {' '}
          </div>
        </div>
        <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockNew}` }>
          <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
            <p className={ classes.textRight }>
              <span
                className={ classes.dottedText }
                aria-hidden="true"
                data-for="definationTitle"
                data-tip="Click for a definition."
                onClick={ () => handleDefinationPopup(
                  'Pension',
                ) }
              >
                Total SEP IRA Contributions
                <br />
                (
                {moduleServices.module5Data.maxHsaContributionByLawCareerPath !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.maxHsaContributionByLawCareerPath } prefix={ moduleServices.module5Data.maxHsaContributionByLawCareerPath >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.maxHsaContributionByLawCareerPath < 0 && ')' } />
                ) : ( '$0' )}
                {' '}
                max of lesser than 25% of Annul Income or $61,500 by law)
              </span>
            </p>
          </div>
          <div className={ classes.livingExpencesMothly }>
            <div className={ classes.annualInput }>
              <span>
                {moduleServices.module5Data.advCareerSEPIRAPersonalContributionCalcs !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.advCareerSEPIRAPersonalContributionCalcs / 12 } prefix={ moduleServices.module5Data.advCareerSEPIRAPersonalContributionCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.advCareerSEPIRAPersonalContributionCalcs < 0 && ')' } />
                ) : ( '$0' )}
              </span>
            </div>
          </div>
          <div className={ `${classes.livingExpencesMothly} ${classes.livingExpencesMothlyNoBefore}` }>
            <div className={ classes.annualInput }>

              <span>
                {moduleServices.module5Data.advCareerSEPIRAPersonalContributionCalcs !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.advCareerSEPIRAPersonalContributionCalcs } prefix={ moduleServices.module5Data.advCareerSEPIRAPersonalContributionCalcs >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.advCareerSEPIRAPersonalContributionCalcs < 0 && ')' } />
                ) : ( '$0' )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <CareerBottomTabs handleCareerObject={ handleCareerObject } />
    </div>

  );
};

SepContributions.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  // setPensionConfirmation: PropTypes.func.isRequired,
  // pensionConfirmation: PropTypes.bool.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( SepContributions );