import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import CareerSketchTimeline from '../ui/career-sketch-timeline/career-sketch-timeline';
import styles from '../../screens/pwi-car/styles';
import * as modulesServices from '../../calculations/modules-services';
import * as commonFunctions from '../../utilities/commonFunctions';

const idealRepaymentValues = [
  {
    value: 0,
    scaledValue: 0,
    label: '0 mos',
  },
  {
    value: 12,
    scaledValue: 12,
    label: '12 mos',
  },
  {
    value: 24,
    scaledValue: 24,
    label: '24 mos',
  },
  {
    value: 36,
    scaledValue: 36,
    label: '36 mos',
  },
  {
    value: 48,
    scaledValue: 48,
    label: '48 mos',
  },
  {
    value: 60,
    scaledValue: 60,
    label: '60 mos',
  },

];
const SecondCarLeaseSettingTimelines = (props) => {
  const { classes, handleBuyingCarObject, handleUpdatedObject } = props;
  const [leaseStartYear, setLeaseStartYear] = React.useState('');
  const [termLease, setTermLease] = React.useState(0);
  const [leaseYearList, setLeaseYearList] = React.useState([]);
  const [leaseTickValues, setLeaseTickvalues] = React.useState([]);


  useEffect(() => {
    const leasingObj = { ...handleBuyingCarObject };
    let purchaseYear = modulesServices.module1Data.start_year;

      const careerPathYears = [];
      if (modulesServices.module4Data.start_year && modulesServices.module4Data.start_year > 0) {
        careerPathYears.push(modulesServices.module4Data.start_year);
      }
      if (modulesServices.module5Data.start_year && modulesServices.module5Data.start_year > 0) {
        careerPathYears.push(modulesServices.module5Data.start_year);
      }
      if (modulesServices.module8Data.start_year && modulesServices.module8Data.start_year > 0) {
        careerPathYears.push(modulesServices.module8Data.start_year);
      }
      if (modulesServices.module17Data.start_year && modulesServices.module17Data.start_year > 0) {
        careerPathYears.push(modulesServices.module17Data.start_year);
      }
      if (careerPathYears.length >= 1) {
        careerPathYears.sort((a, b) => a - b);
        purchaseYear = careerPathYears[0];
      }

      if (purchaseYear === undefined) {
        purchaseYear = new Date().getFullYear();
      }
      let module1StartYear = modulesServices.module1Data.start_year;
      const yearsList = [];
      const yearsListTicksValues = [];
      for (let j = 0; j <= 14; j += 1) {
        yearsList.push(module1StartYear + j);
        if (j % 2 === 0) {
          yearsListTicksValues.push({
            value: module1StartYear + j,
            label: module1StartYear + j,
          });
        }
      }
      setLeaseYearList(yearsList);
      setLeaseTickvalues(yearsListTicksValues);
      if (handleBuyingCarObject.leasing_second_car_start_year === undefined || handleBuyingCarObject.leasing_second_car_start_year === 0) {
        setLeaseStartYear(purchaseYear);
        leasingObj.leasing_second_car_start_year = purchaseYear;
      } else {
        setLeaseStartYear(handleBuyingCarObject.leasing_second_car_start_year);
      }
      handleUpdatedObject(leasingObj);
      if (handleBuyingCarObject.leasing_second_car_number_of_years === undefined || handleBuyingCarObject.leasing_second_car_number_of_years === 0) {
        setTermLease(36);
      } else {
        setTermLease(handleBuyingCarObject.leasing_second_car_number_of_years);
      }

    if (handleBuyingCarObject.leasing_second_car_number_of_years === undefined) {
      setTermLease(36);
      leasingObj.leasing_second_car_number_of_years = 36;
    }

    // eslint-disable-next-line
  }, []);

  const handleLeaseStartSlider = (event, newValue) => {
    setLeaseStartYear(newValue);
    handleBuyingCarObject.leasing_second_car_start_year = newValue;
    handleUpdatedObject(handleBuyingCarObject);
  };

  const handleTermLeaseSlider = (event, newValue) => {
    setTermLease(newValue);
    handleBuyingCarObject.leasing_second_car_number_of_years = newValue;
    handleUpdatedObject(handleBuyingCarObject);
  };

  return (
    <div className={classes.contentBlock}>
      <p>Think of leasing a car like renting an apartment. In exchange for monthly payments, you get the right to use a car. But you do not own that car! This is the major difference between buying and leasing.</p>
      <p>When you buy, you own the car. When you lease, you rent the car.</p>
      <p>Leasing is more beneficial when you don&apos;t have enough cash to make a down payment, won&apos;t exceed the annual mileage limit, take good care of your vehicle, and want to drive a new car every few years.</p>
      <p>Equipped with a well-researched plan and an understanding of the numbers, you will be confident when you go to lease a car and have peace of mind with your finances. Let&apos;s build a strategy to lease your next car.</p>
      <CareerSketchTimeline />
      <div className={classes.groupAnnualBox}>
        <div className={classes.AnnualIncomeGrowth}>
          <div>
            <p><span>Lease Start Year</span></p>
            <div className={classes.annuvalIncomeGrowthGraph}>
              <PwiSlider
                value={leaseStartYear}
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay="auto"
                min={leaseYearList[0]}
                max={leaseYearList[leaseYearList.length - 1]}
                step={1}
                marks={leaseTickValues}
                ValueLabelComponent={ValueLabelComponent}
                onChange={handleLeaseStartSlider}
              />
            </div>
            <div className={classNames(classes.annuvalIncomeGrowthInput, classes.flexEnd)}>
              {leaseStartYear}

            </div>
          </div>
        </div>
        <div className={classes.AnnualIncomeGrowth}>
          <div>
            <p><span>Term of the Lease</span></p>
            <div className={classes.annuvalIncomeGrowthGraph}>
              <PwiSlider
                ValueLabelComponent={ValueLabelComponent}
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay="auto"
                marks={idealRepaymentValues}
                min={0}
                max={60}
                step={1}
                value={termLease}
                valueLabelFormat={`${commonFunctions.mosFormatter(termLease)}`}
                onChange={handleTermLeaseSlider}
              />
            </div>
            <div className={classNames(classes.annuvalIncomeGrowthInput, classes.flexEnd)}>
              {termLease}
              {' '}
              months
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SecondCarLeaseSettingTimelines.propTypes = {
  classes: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
};

export default withStyles(styles)(SecondCarLeaseSettingTimelines);
