import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const FederalStudentLoanRelief = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Federal Student Loan Relief</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/federal-student-loan-relief.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Federal Student Loan Relief
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/federal-student-loan-relief-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          <i>A Finance Explained Special Edition</i>
                        </p>
                        <p>
                          Congress just passed into law the largest stimulus
                          package in U.S. history – the CARES Act – to help you
                          manage your money during the COVID-19 crisis. The
                          CARES Act provides $2 trillion worth of financial
                          relief to individuals and businesses trying to weather
                          the coronavirus economic crisis. The CARES Act is an
                          880-page bill. I&apos;ll focus on one major aspect in
                          that bill – federal student loan relief.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          There are roughly 43 million federal student loan
                          borrowers in the U.S. Now, with the stimulus bill, you
                          do not have to make a payment on any of your federal
                          student loans through September 30, 2020. I&apos;ll
                          say that again just so you don&apos;t think it was a
                          typo.
                        </p>
                        <p>
                          <i>
                            You do not have to make a payment on any of your
                            federal student loans through September 30, 2020.
                          </i>
                        </p>
                        <p>
                          <b>What does this actually mean?</b>
                          <br />
                          You have the option to stop paying your federal
                          student loans. The government will not penalize you
                          with late fees, interest will not accrue, and your
                          credit score will be unaffected. If your federal
                          student loan balance is $10,000 today and you do not
                          make any payments until September 30, 2020, your
                          balance will be $10,000 on October 1, 2020.
                        </p>
                        <p>
                          <i>
                            Make sure you call and consult with your student
                            loan servicer before taking any actions on your
                            federal student loans.
                          </i>
                        </p>
                        <p>
                          <b>
                            What about{' '}
                            <a
                              href="https://studentaid.gov/manage-loans/forgiveness-cancellation"
                              target="_blank"
                              rel="noopener noreferrer">
                              student loan forgiveness
                            </a>
                            ?
                          </b>
                          <span className={classes.blockDisplay}>
                            If you choose to make no payments, the government
                            will still count this time period towards your
                            student loan forgiveness. Meaning, the government
                            will give you credit even though you are making no
                            payments.
                          </span>
                        </p>
                        <p>
                          <b>Can I keep paying my student loans?</b>
                          <span className={classes.blockDisplay}>
                            Yes! And there&apos;s a huge benefit for continuing
                            to make your monthly payment because your entire
                            payment will go towards repaying your balance.
                            Remember, student loan payments are comprised of two
                            parts: principal repayment and interest. Right now,
                            the government set interest rates on all federal
                            student loans to 0%. That means your entire payment
                            goes towards repaying your principal. This is a huge
                            benefit and can fast track your debt repayment!
                          </span>
                        </p>
                        <p>
                          <b>
                            Private student loans and FFEL loans are NOT
                            included
                          </b>
                          <span className={classes.blockDisplay}>
                            This bill only applies to federal student loans.
                            Private student loans and FFEL loans do not apply
                            since they are not owned by the government. If you
                            can&apos;t afford to make private student loan
                            payments, call your loan servicer and discuss what
                            options are available to you.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
FederalStudentLoanRelief.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FederalStudentLoanRelief);
