const style = {
  palette: {
    common: {
      pureBlack: '#000',
      black: '#333',
      grey: '#999',
      white: '#fff',
      blue: '#0069aa',
      darkBlue: '#0069aa',
      mediumBlue: '#1f96e0',
      orange: '#f89b22',
      green: '#83a947',
      awareBG: '#f59a34',
      awareBtn: '#f28735',
      red: '#ff0000',
      orangeDark: '#e57818',
      bluelight: '#539cc8',
      backlight: '#ccc',
      greenlight: '#84a84d',
      blacklightTwo: '#e7e8e8',
      darkGray: '#8a8a8a',
      graylight: '#D7D7D7',
      greenDark: '#799e41',
      bluelightTwo: '#d1e4f0',
      contactBlue: '#57b0e8',
      contactGray: '#f5f5f5',
      blueDark: '#3b81ad',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: 'MuseoSans-300',
    color: '#333',
    body1: {
      fontSize: 14,
      color: '#666',
      width: '100%',
    },
    h1: {
      fontSize: 40,
    },
    h2: {
      fontSize: 36,
    },
    h3: {
      fontSize: 25,
    },
    h4: {
      fontSize: 24,
      color: '#333',
    },
    h5: {
      fontSize: 18,
    },
    h6: {
      fontSize: 16,
    },
  },

};

export default style;
