import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import ImageMapper from 'react-image-mapper';
import Dialog from '@material-ui/core/Dialog';
import { withRouter } from 'react-router-dom';
import styles from './styles';
import navigationBanner from '../../assets/img/navigation-tool/navigation_tool_banner.png';
import navigationTool from '../../assets/img/navigation-tool/navigation_tool_img.jpg';

const NavigationTool = ( props ) => {
  const { classes, history } = props;
  const [dialogVisible, setDialogVisible] = useState( false );

  const MAP = {
    name: 'my-map',
    areas: [
      { name: '1', shape: 'poly', coords: [86, 72, 112, 79, 158, 92, 166, 117, 169, 134, 178, 165, 193, 152, 214, 142, 249, 132, 266, 130, 287, 95, 303, 64, 286, 35, 266, 0, 244, 1, 225, 3, 208, 7, 192, 11, 167, 20, 144, 30, 124, 43, 109, 52, 97, 63] },
      {
        name: '2', shape: 'poly', coords: [280, 0, 282, 6, 290, 20, 303, 41, 316, 64, 306, 82, 294, 104, 277, 131, 318, 140, 349, 155, 364, 168, 402, 159, 438, 149, 446, 114, 456, 77, 432, 56, 406, 38, 371, 20, 339, 9, 309, 4, 280, 1], href: '/connect',
      },
      {
        name: '3', shape: 'poly', coords: [373, 179, 385, 193, 392, 209, 401, 230, 405, 250, 406, 267, 420, 274, 444, 289, 466, 300, 472, 307, 482, 301, 495, 292, 504, 286, 518, 279, 527, 274, 535, 268, 537, 262, 537, 249, 535, 233, 532, 211, 528, 190, 520, 174, 511, 152, 501, 132, 490, 118, 481, 104, 471, 93, 466, 87, 459, 105, 456, 121, 450, 143, 446, 157, 424, 163, 398, 171, 375, 177], href: '/sense',
      },
      {
        name: '4', shape: 'poly', coords: [406, 279, 405, 294, 400, 313, 392, 332, 381, 349, 369, 365, 374, 387, 380, 408, 384, 423, 388, 438, 416, 445, 449, 454, 460, 457, 479, 438, 494, 414, 509, 389, 518, 367, 526, 350, 530, 326, 534, 311, 536, 279, 521, 288, 503, 297, 473, 315, 454, 305, 436, 295, 416, 284], href: '/life-event-library',
      },
      {
        name: '5', shape: 'poly', coords: [361, 374, 373, 417, 380, 446, 419, 455, 451, 465, 430, 484, 406, 499, 384, 511, 359, 521, 336, 528, 313, 533, 288, 536, 272, 537, 263, 523, 253, 503, 242, 485, 234, 473, 242, 457, 253, 439, 264, 422, 272, 407, 295, 405, 321, 395, 345, 383, 358, 375], href: '/carrer-sketch',
      },
      {
        name: '6', shape: 'poly', coords: [259, 406, 251, 422, 240, 442, 226, 464, 222, 473, 236, 496, 248, 514, 261, 536, 206, 530, 162, 515, 123, 494, 101, 477, 83, 460, 87, 438, 93, 414, 99, 388, 135, 379, 173, 369, 196, 385, 222, 399, 256, 405, 245, 405], href: '/spaces',
      },
      {
        name: '7', shape: 'poly', coords: [74, 451, 78, 430, 91, 380, 136, 367, 164, 361, 146, 328, 136, 307, 130, 273, 107, 259, 65, 235, 32, 254, 0, 272, 2, 300, 9, 336, 22, 375, 37, 406, 55, 432, 71, 450], href: '/timeline-sketcher',
      },
      {
        name: '8', shape: 'poly', coords: [1, 258, 36, 238, 66, 221, 100, 240, 131, 260, 141, 218, 169, 174, 163, 149, 156, 122, 150, 100, 127, 94, 102, 88, 78, 82, 48, 116, 25, 154, 11, 192, 5, 224, 1, 255], href: '/tutorials',
      },
    ],
  };
  const handleWatchQuikTourVideo = () => {
    setDialogVisible( true );
  };

  useEffect( () => {
    if ( dialogVisible === true ) {
      setTimeout( () => {
        const element = document.getElementById( 'bgvid' );
        if ( element.requestFullscreen ) {
          element.requestFullscreen();
        } else if ( element.mozRequestFullScreen ) {
          element.mozRequestFullScreen();
        } else if ( element.webkitRequestFullscreen ) {
          element.webkitRequestFullscreen();
        } else if ( element.msRequestFullscreen ) {
          element.msRequestFullscreen();
        }
        element.play();
      } );
    }
  }, [dialogVisible] );

  const clicked = ( area ) => {
    if ( area.name === '1' ) {
      setDialogVisible( true );
      handleWatchQuikTourVideo();
    } else {
      history.push( area.href );
    }
  };

  const handleWatchQuikTourVideoClose = () => {
    setDialogVisible( false );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.navigationPage }>
        <div className={ classes.bannerBlock }>
          <div className={ classes.bannerImage }>
            <img src={ navigationBanner } alt="" />
          </div>
          <div className={ classes.bannerCaption }>
            z
            <div className={ classes.bannerTitle }>
              <Typography variant="h3" component="h3">
                Navigate Your Path
                <span>to Financial Independence</span>
              </Typography>
            </div>
          </div>
        </div>
        <div className="container" />
        <div className={ classes.navigationContent }>
          <Typography variant="h2" component="h2">Get started quickly in Inkwiry.</Typography>
          <p className={ classes.navigationDesc }>Eight steps to finding your 5, 10, 15 year number.</p>
          <div className={ classes.navigationArrows }>
            <p>Just click on the images to begin your journey.</p>
            <div className={ classes.navigationCircle }>
              <ImageMapper
                src={ navigationTool }
                map={ MAP }
                width={ 1000 }
                className={ classes.circleImage }
                onClick={ ( area ) => clicked( area ) }
                active={ false }
              />
              <Dialog className={ classes.modalPopupVideo } onClose={ handleWatchQuikTourVideoClose } aria-labelledby="simple-dialog-title" open={ dialogVisible }>
                <div className={ classes.modalVideoInner }>
                  <button type="button" onClick={ handleWatchQuikTourVideoClose } className={ classes.closeIcon }><i className="la la-close" /></button>
                  <video width="100%" height="100%" id="bgvid" preload="auto" autoPlay="true">
                    <track kind="captions" />
                    <source src="https://s3.us-east-2.amazonaws.com/inkwiry-videos/teaser_full_03.mp4" type="video/mp4" caption="" />
                  </video>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

NavigationTool.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( NavigationTool );
