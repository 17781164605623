import tickMark from '../../../../assets/img/students-parents/tick_mark_white.png';
const styles = (theme ) => ( {
  completeActivity: {
    '& > div > div':{
      background: 'transparent',
      color: '#ffff'
    },
    '& > div > div > div': {
      background: '#004376',
      color: '#fff',
      padding: '15px 40px',
      boxSizing: 'border-box',
      border: '1px solid rgba(0,0,0,.2)',
      borderRadius: '6px',
      WebkitBoxShadow: '0 5px 15px rgb(0 0 0 / 50%)',
      boxShadow: '0 5px 15px rgb(0 0 0 / 50%)',
    },
  },
  completeActivityFive:{
    '& > div > div':{
      width: '1020px',
      maxWidth: '1020px',
      // width: '1020px',
      // maxWidth: '1020px',
      margin: '30px auto',
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
    '& > div > div > div > div': {
      padding: '30px 80px',
    }
  },
  completePopup: {
    padding: '15px 40px',
  },
  graphIcon: {
    width: '230px',
    marginRight: '20px',
    marginBottom: '10px',
    '& img': {
      width: '100%',
    },
  },
  xpIcon: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontSize: '31px',
      lineHeight: '1.2',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      fontWeight: '300',
      marginBottom: '10px',
      marginTop: '0',
    },
  },
  niceWork: {
    fontSize: '28px',
    padding: '20px 0px',
    fontWeight: '300',
    lineHeight: '30px',
    textAlign: 'center',
    margin: '0 auto 10px auto',
  },
  activityDesc: {
    fontSize: '22px',
    lineHeight: '30px',
    textAlign: 'center',
    marginBottom: '10px',
    marginTop: '0',
    '& span': {
      display: 'inline-block',
      width: '100%',
    },
  },
  footerButton: {
    textAlign: 'center',
    paddingTop: '30px',
    display: 'block',
    '& button': {
      backgroundColor: '#7aaa3c',
      borderColor: '#7aaa3c',
      textDecoration: 'none',
      padding: '10px 50px',
      fontSize: '20px',
      borderRadius: '4px',
      color: '#fff',
      textTransform: 'uppercase',
      minWidth: '64px',
      display: 'inline-block',
      border: '2px solid #7aaa3c',
      lineHeight: '30px',
      '&:hover': {
        backgroundColor: '#7aaa3c',
        border: '2px solid #d5d5d5',
      },
    },
  },
  rankIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    '& p': {
      fontSize: '31px',
      lineHeight: '1.2',
      textAlign: 'left',
      fontWeight: '300',
      marginBottom: '10px',
      marginTop: '0',
      '&:nth-child(2)': {
        paddingTop: '30px',
      },
    },
  },
  rankImg: {
    width: 'auto',
    marginRight: '20px',
    marginBottom: '10px',
    '& img': {
      width: '100%',
    },
  },
  completePopupLogo:{
    width: '300px',
    display: 'block',
    margin: '0 auto',
    '& img':{
      width: '100%',
      verticalAlign: 'middle',
    }
  },
  mr0:{
    margin: '0px',
  },
  completePopupChallenge:{
    '& h1':{
      color: '#fff',
      padding: '20px 0',
      fontSize: '36px',
      lineHeight: '1.2',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      fontWeight: '300',
      margin: '20px 0px 10px',
    },
    '& p':{
      textAlign: 'center',
      fontWeight: '300',
      fontSize: '22px',
      lineHeight: '30px',
      margin: '0px 0px 20px',
    }
  },
  bitUiList:{
    listStyle: 'none',
    padding: '0px',
    margin: '0px 0px 10px',
    '& li':{
      fontSize: '20px',
      background: `url(${tickMark}) no-repeat left 7px`,
      padding: '0 0 10px 20px',
      backgroundSize: '11px',
      lineHeight: '1.42857143',
    }
  },
  federal:{
    background: '#fff',
    color: '#000',
    width: '100%',
    border: '1px solid #000',
    fontSize: '18px',
    borderCollapse: 'collapse',
    '& thead':{
      '& tr':{
        '&:first-child':{
          background: '#0069aa',
          color: '#fff',
          fontSize: '18px',
        }
      }
    },
  },
  federalTbody:{
    '& tr':{
      '& td':{
        borderRight: '1px solid #000 !important',
        '&:first-child':{
          borderTop: 'none !important',
          width: '4%',
        },
        '&:nth-child(2)':{   
          border: '0 solid #000',
          borderLeft: '0 solid #000',
        },
        '&:nth-child(3)':{   
          border: '0 solid #000',
          borderLeft: '0 solid #000',
          borderRight: '0 solid #000',
        },
        '&:nth-child(4)':{   
          border: '0 solid #000',
          borderLeft: '0 solid #000',
        },
        '&:nth-child(5)':{   
          border: '0 solid #000',
          borderLeft: '0 solid #000',
        }
      },
      '&:first-child':{
        '& td':{
          borderTop: '1px solid #000 !important',
        }
      },
      '&:last-child':{
        '& td':{
          borderBottom: '1px solid #000!important',
          '&:first-child':{
            borderBottom: '0px solid #000 !important',
          }
        }
      }
    }
  },
  textLeftPara:{
    textAlign: 'left !important',
    fontSize: '20px !important',
    lineHeight: '30px !important',
    marginBottom: '20px',
  },
  textLeft:{
    textAlign: 'left !important',
  },
  textRight:{
    textAlign: 'right !important',
  },
  textCenter:{
    textAlign: 'center !important',
  },
  bdrBottom:{
    borderBottom: '1px solid #000',
  },
  textRed:{
    color: 'red',
  },
  assetsOptions:{
    '& ul':{
      margin: '0',
      padding: '10px 0 0',
      textAlign: 'center',
      width: '100%',
      listStyle: 'none',
      '& li':{
        padding: '0',
        textAlign: 'center',
        width: '24%',
        display: 'inline-block',
        verticalAlign: 'top',
        '& p':{
          fontSize: '20px',
          lineHeight: '30px',
          marginBottom: '20px',
          position: 'relative',
        }
      }
    }
  },
  modalFooter:{
    textAlign: 'right',
    padding: '15px',
    '& button':{
      padding: '7px 42px',
      fontSize: '20px',
      minWidth: '241px',
      textTransform: 'uppercase',
      border: '2px solid #84a84d',
      borderRadius: '10px',
      background: '#84a84d',
      color: '#fff',
      '&:hover':{
        backgroundColor: '#7aaa3c',
        border: '2px solid #d5d5d5',
      }
      // '&:first-child':{
      //   color: '#84a84d',
      //   background: '#fff',
      //   marginRight: '0px',
      // },
      // '&:last-child':{
      //   backgroundColor: '#84a84d',
      //   border: '2px solid #84a84d',
      //   color: '#fff',
        
      // }
    }
  },
  riskLadder: {
    padding: '30px 50px',
    // padding: '30px',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // width: '91.66666667%',
    // marginLeft: '8.3%',
    // marginRight: 'auto',
    // boxSizing: 'border-box',
    // [theme.breakpoints.down( 'xs' )]: {
    //   flexWrap: 'wrap',
    //   marginLeft: '0px',
    // },
    // '& ul': {
    //   listStyleType: 'none',
    //   width: '50%',
    //   padding: '0 15px',
    //   boxSizing: 'border-box',
    //   [theme.breakpoints.down( 'xs' )]: {
    //     width: '100%',
    //     paddingLeft: '0px',
    //   },
    //   '& li': {
    //     listStyleType: 'none',
    //   },
    // },
  },
  listedInvestments:{
    position: 'relative',
  },
  listedInvestmentsLeft:{
    position: 'relative',
    '& ul':{
      margin: '0px',
      padding: '0px',
      '& li':{
        borderRadius: '6px',
        height: 'calc(80px - 24px)',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        marginBottom: '5px',
        padding: '10px 0px 10px 30px',
      }
    }
  },
  dragOptionDetails: {
    width: '100%',
    padding: '0px 0px 0px 20px',
    margin: '0px',
    '& li': {
      height: '80px',
      display: 'flex',
      alignItems: 'center',
      verticalAlign: 'middle',
      marginTop: '0px',
      fontSize: '19px',
      paddingLeft: '20px',
      color: '#fff',
      marginBottom: '5px',
     
    },
  },
  riskOptions: {
    width: '100%',
    padding: '0px 0px 0px 20px',
    margin: '0px',
    '& li': {
      height: '80px',
      display: 'flex',
      alignItems: 'center',
      verticalAlign: 'middle',
      marginTop: '0px',
      fontSize: '19px',
      paddingLeft: '20px',
      color: '#fff',
      marginBottom: '5px',
      '&:hover': {
        background: '#0069aa',
        color: '#fff',
        borderRadius: '10px',
        cursor: 'pointer',
      },
    },
  },
  listBonds: {
    borderColor: '#006547',
  },
  fundsList: {
    borderColor: '#fff200',
  },
  stockList: {
    borderColor: '#f15a29',
  },
  investList: {
    borderColor: '#be1e2d',
  },
  greenCheck: {
    color: 'green',
    paddingLeft: '5px',
  },
  redCross: {
    color: 'red',
    paddingLeft: '5px',
  },
  risklist: {
    padding: '0 15px',
    '& div': {
      height: '100%',
      width: '100%',
      lineHeight: '1.42857143',
      display: 'flex',
      alignItems: 'center',
      verticalAlign: 'middle',
    },
  },
  percText:{
    position: 'absolute',
    left: '0',
    flexWrap: 'wrap',
    width: '72px',
    color: '#fff',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '18px',
  },
  risklist1: {
    borderLeft: '72px solid #4472c4',
    border: '2px solid #4472c4',
  },
  risklist2: {
    border: '2px solid #006547',
    borderLeft: '72px solid #006547',
  },
  risklist3: {
    border: '2px solid #fff200',
    borderLeft: '72px solid #fff200',
  },
  risklist4: {
    border: '2px solid #f15a29',
    borderLeft: '72px solid #f15a29',
  },
  risklist5: {
    border: '2px solid #be1e2d',
    borderLeft: '72px solid #be1e2d',
  },
  completePopupPowerup:{
    margin: '30px 0px',
  },
  popupPowerupPara:{
    fontSize: '20px !important',
    lineHeight: '30px !important',
    marginBottom: '20px !important',
    display: 'block !important',
    color: '#fff',
    '& a':{
      textDecoration: 'underline',
      color: '#fff',
      transition: 'all 0.5s ease 0s',
      '&:hover':{
        color: '#f89b23',
      }
    }
  },
  clickHere:{
    textDecoration: 'underline',
      color: '#fff',
      cursor:'pointer',
      transition: 'all 0.5s ease 0s',
      '&:hover':{
        color: '#f89b23',
      }
  },
  bitUiListPowerup:{
    '& li':{
      color: '#fff',
    }
  },
  exerciseOptionsPowerup:{
    '& li':{
      '& p':{
        '& label':{
          margin: '0px auto',
        }
      }
    }
  },
  completePopupTable:{
    padding: '0',
  },
  completePopupChallengeTable:{
    '& h3':{
      fontSize: '30px',
      margin: '20px 0px 20px',
      fontWeight: '300',
      color: '#fff',
    },
    '& table':{
      borderSpacing: '0',
      borderCollapse: 'collapse',
      width: '100%',
      '& thead':{
        '& tr':{
          background: '#4472c4',
          color: '#fff',
          borderTop: '2px solid #000',
          borderBottom: '2px solid #000',
          '& th':{
            fontSize: '18px',
            padding: '4px',
            verticalAlign: 'bottom',
            textAlign: 'left',
            '&:last-child':{
              textAlign: 'center',
            }
          }
        }
      },
      '& tbody':{
        '& tr':{
          color: '#000',
          '&:last-child':{
            borderBottom: '2px solid #000',
          },
          '&:nth-child(odd)':{
            background: '#e7e7e7',
          },
          '&:nth-child(even)':{
            background: '#fff',
          },
          '& td':{
            fontSize: '18px',
            padding: '4px',
          }
        }
      }
    }
  },
  textindent50:{
    paddingLeft: '50px !important',
  },
  // textRed:{
  //   color: '#f00',
  //   fontWeight: 'normal',
  //   fontFamily: 'MuseoSans-500',
  // },
  textBold:{
    fontWeight: 'normal',
    fontFamily: 'MuseoSans-500',
  },
  dragText:{
    fontSize: '18px',
    color: '#fff',
  },
  riskLadderCredit:{
    padding: '0',
  },
  gridRow:{
    margin: '0px -15px',
  },
  gridCol:{
    padding: '0px 15px !important',
  },
  dragTextRight:{
    '& p':{
      fontSize: '20px !important',
      lineHeight: '30px !important',
      margin: '0px 0px 20px',
      color: '#fff',
    }
  },
  pcQuestStatus:{
    color: '#fff',
    fontSize: '20px',
    fontWeight: '300',
    position: 'absolute',
    right: '280px',
    top: '10px',
  },
  bitUiListPopupEight:{
    listStyle: 'auto',
    '& li':{
      listStylePosition: 'inside',
      padding: '0 0 10px 0px',
      fontSize: '20px',
      color: '#fff',
      background: 'none',
    }
  },
  assetsOptionsGraph:{
    padding: '25px',
    background: '#fff',
    border: '1px solid #000',
    marginBottom: '10px',
  },
  riskLadderDragRow:{
    padding: '20px 0px',
  },
  listedInvestmentsLeftNew:{
    '& ul':{
      '& li':{
        border: '1px solid #ddd',
        borderRadius: '10px',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        padding: '5px',
        height: 'calc(80px - 12px)',
        marginBottom: '20px',
        fontSize: '16px',
        marginTop: '5px',
      }
    },
    '& label':{
      fontSize: '16px',
      color: '#fff',
    }
  },
  riskOptionsNew:{
    padding: '0px 0px 0px 15px',
    '& li':{
      height: 'auto',
      margin: '30px 0px 45px 0px',
      padding: '5px',
      paddingLeft: '5px',
      borderRadius: '10px',
      fontSize: '16px',
    }
  },
  dragTextNew:{
    borderRadius: '10px',
    padding: '5px',
    minHeight: '80px',
    marginTop: '5px',
    fontSize: '16px',
    color: '#fff',
  },
  dragDiv:{
    '& div':{
      width: '100%',
      height: '100%',
    }
  }
} );

export default styles;
