import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles';

function Component(props) {
  const { parent, scenarioOne, scenarioTwo, classes } = props;
  const getTypedField = (value, scenario) => {
    if (scenario === 'One') {
      switch (value.type) {
        case 'number':
          return (
            <NumberFormat
              decimalScale={0}
              allowNegative={false}
              className={classes.formInput}
              displayType="text"
              thousandSeparator
              value={scenarioOne[value.key]}
              prefix={scenarioOne[value.key] >= 0 ? value.prefix : '($'}
              suffix={
                scenarioOne[value.key] < 0 ? value.suffix + ')' : value.suffix
              }
            />
          );
          break;
        case 'ratio':
          return (
            <NumberFormat
              decimalScale={1}
              allowNegative={false}
              className={classes.formInput}
              displayType="text"
              thousandSeparator
              value={scenarioOne[value.key]}
              prefix={scenarioOne[value.key] >= 0 ? value.prefix : '('}
              suffix={
                scenarioOne[value.key] < 0 ? value.suffix + ')' : value.suffix
              }
            />
          );
          break;
        case 'date':
          return (
            <NumberFormat
              decimalScale={0}
              allowNegative={false}
              className={classes.formInput}
              displayType="text"
              value={scenarioOne[value.key]}
              prefix={scenarioOne[value.key] >= 0 ? value.prefix : '('}
              suffix={
                scenarioOne[value.key] < 0 ? value.suffix + ')' : value.suffix
              }
            />
          );
          break;
        case 'text':
          return scenarioOne[value.key];
        default:
          break;
      }
    } else if (scenario === 'Two') {
      switch (value.type) {
        case 'number':
          return (
            <NumberFormat
              decimalScale={0}
              allowNegative={false}
              className={classes.formInput}
              displayType="text"
              thousandSeparator
              value={scenarioTwo[value.key]}
              prefix={scenarioTwo[value.key] >= 0 ? value.prefix : '($'}
              suffix={
                scenarioTwo[value.key] < 0 ? value.suffix + ')' : value.suffix
              }
            />
          );
          break;
        case 'ratio':
          return (
            <NumberFormat
              decimalScale={1}
              allowNegative={false}
              className={classes.formInput}
              displayType="text"
              thousandSeparator
              value={scenarioTwo[value.key]}
              prefix={scenarioTwo[value.key] >= 0 ? value.prefix : '('}
              suffix={
                scenarioTwo[value.key] < 0 ? value.suffix + ')' : value.suffix
              }
            />
          );
          break;
        case 'date':
          return (
            <NumberFormat
              decimalScale={0}
              allowNegative={false}
              className={classes.formInput}
              displayType="text"
              value={scenarioTwo[value.key]}
              prefix={scenarioTwo[value.key] >= 0 ? value.prefix : '('}
              suffix={
                scenarioTwo[value.key] < 0 ? value.suffix + ')' : value.suffix
              }
            />
          );
          break;
        case 'text':
          return scenarioTwo[value.key];
        default:
          break;
      }
    } else {
      switch (value.type) {
        case 'number':
          return (
            <NumberFormat
              decimalScale={0}
              allowNegative={false}
              className={classes.formInput}
              displayType="text"
              thousandSeparator
              value={scenarioOne[value.key] - scenarioTwo[value.key]}
              prefix={
                scenarioOne[value.key] - scenarioTwo[value.key] >= 0
                  ? value.prefix
                  : '($'
              }
              suffix={
                scenarioOne[value.key] - scenarioTwo[value.key] < 0
                  ? value.suffix + ')'
                  : value.suffix
              }
            />
          );
          break;
        case 'ratio':
          return (
            <NumberFormat
              decimalScale={1}
              allowNegative={false}
              className={classes.formInput}
              displayType="text"
              thousandSeparator
              value={scenarioOne[value.key] - scenarioTwo[value.key]}
              prefix={
                scenarioOne[value.key] - scenarioTwo[value.key] >= 0
                  ? value.prefix
                  : '('
              }
              suffix={
                scenarioOne[value.key] - scenarioTwo[value.key] < 0
                  ? value.suffix + ')'
                  : value.suffix
              }
            />
          );
          break;
        case 'date':
          return (
            <NumberFormat
              decimalScale={0}
              allowNegative={false}
              className={classes.formInput}
              displayType="text"
              value={scenarioOne[value.key] - scenarioTwo[value.key]}
              prefix={
                scenarioOne[value.key] - scenarioTwo[value.key] >= 0
                  ? value.prefix
                  : '('
              }
              suffix={
                scenarioOne[value.key] - scenarioTwo[value.key] < 0
                  ? value.suffix + ')'
                  : value.suffix
              }
            />
          );
          break;
        case 'text':
          return <span>-</span>;
        default:
          break;
      }
    }
  };

  // const getDefaultValue = (type) => {
  //   switch (type) {
  //     case "number":
  //       return <span>$0</span>
  //       break;
  //     case "date":
  //       return <span>0</span>
  //       break;
  //     case "ratio":
  //       return <span>0%</span>
  //       break;
  //     case "text":
  //       return <span>-</span>
  //       break;
  //     default:
  //       break;
  //   }
  // }
  return (
    <div>
      <div>
        <h2>{parent.title}</h2>
      </div>
      <table className={classes.incomeLabels}>
        {parent.children.map((value) => (
          <tr>
            <td>
              <span>{value.label}</span>
            </td>
            <td>
              <span
                className={classNames(
                  scenarioOne[value.key] < 0 && classes.labelColor,
                )}>
                {scenarioOne.scenarioId !== '' &&
                scenarioOne.scenarioId !== null &&
                scenarioOne.scenarioId !== undefined &&
                scenarioOne[value.key] !== '' &&
                scenarioOne[value.key] !== null &&
                scenarioOne[value.key] !== undefined
                  ? getTypedField(value, 'One')
                  : ''}
              </span>
            </td>
            <td>
              <span
                className={classNames(
                  scenarioTwo[value.key] < 0 && classes.labelColor,
                )}>
                {scenarioTwo.scenarioId !== '' &&
                scenarioTwo.scenarioId !== null &&
                scenarioTwo.scenarioId !== undefined &&
                scenarioTwo[value.key] !== '' &&
                scenarioTwo[value.key] !== null &&
                scenarioTwo[value.key] !== undefined
                  ? getTypedField(value, 'Two')
                  : ''}
              </span>
            </td>
            <td>
              <span
                className={classNames(
                  scenarioOne[value.key] - scenarioTwo[value.key] < 0 &&
                    classes.labelColor,
                )}>
                {scenarioOne.scenarioId !== '' &&
                scenarioOne.scenarioId !== null &&
                scenarioOne.scenarioId !== undefined &&
                scenarioOne[value.key] !== '' &&
                scenarioOne[value.key] !== null &&
                scenarioOne[value.key] !== undefined &&
                scenarioTwo.scenarioId !== '' &&
                scenarioTwo.scenarioId !== null &&
                scenarioTwo.scenarioId !== undefined &&
                scenarioTwo[value.key] !== '' &&
                scenarioTwo[value.key] !== null &&
                scenarioTwo[value.key] !== undefined
                  ? getTypedField(value, '')
                  : ''}
              </span>
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
}

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  scenarioOne: PropTypes.object.isRequired,
  scenarioTwo: PropTypes.object.isRequired,
};
export default withStyles(styles)(Component);
