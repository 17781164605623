import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@material-ui/core';
import _ from 'lodash';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import styles from '../../../screens/pwi/buying-a-home/styles';
import CareerSketchTimeline from '../../ui/career-sketch-timeline/career-sketch-timeline';
import converyIntoThousands, { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as modulesServices from '../../../calculations/modules-services';
import { timeline, sortTimeLine } from '../../../calculations/time-line';

const mortgageFollowersMarks = [];
for ( let i = 0; i <= 100; i += 20 ) {
  mortgageFollowersMarks.push(
    {
      value: i,
      label: i !== 0 ? `${i}%` : '0% of Purchase Price',
    },
  );
}
const purchasePriceMarks = [
  {
    value: 0,
    label: '$0',
  },
  {
    value: 100000,
    label: '$100K',
  },
  {
    value: 200000,
    label: '$200K',
  },
  {
    value: 300000,
    label: '$300K',
  },
  {
    value: 400000,
    label: '$400K',
  },
  {
    value: 500000,
    label: '$500K',
  },
  {
    value: 600000,
    label: '$600K',
  },
  {
    value: 700000,
    label: '$700K',
  },
  {
    value: 800000,
    label: '$800K',
  },
];

const PurchasePriceDownPayment = ( props ) => {
  const {
    classes, inputData, updateData, handleDefinationPopup, setOpenWorkSheet
  } = props;

  const yearsList = [];
  const yearsListTicksValues = [];
  const year = modulesServices.module1Data.start_year !== undefined ? modulesServices.module1Data.start_year : new Date().getFullYear();
  for ( let j = 0; j <= 14; j += 1 ) {
    yearsList.push( year + j );
    if ( j % 2 === 0 ) {
      yearsListTicksValues.push( {
        value: year + j,
        label: year + j,
      } );
    }
  }

  const [yearOfPurchase, setYearOfPurchase] = React.useState( '' );
  const [purchasePrice, setPurchasePrice] = React.useState( 0 );
  const [downpayment, setDownpayment] = React.useState( 0 );
  const [downpaymentValue, setDownpaymentValue] = React.useState( 0 );
  const [mortgage, setMortgage] = React.useState( 0 );
  const [mortgageValue, setMortgageValue] = React.useState( 0 );

  useEffect( () => {
    let inputValues = {...inputData};
    if ( !( inputData.year_of_purchase !== undefined && inputData.year_of_purchase !== 0 ) ) {
      setYearOfPurchase( new Date().getFullYear() );
      inputValues['year_of_purchase'] = new Date().getFullYear();      
    } else {
      setYearOfPurchase( inputData.year_of_purchase );
    }

    let purchaseVal = 0;
    if ( inputData.purchase_price === undefined ) {
      purchaseVal = 400000;
      inputValues['purchase_price'] = purchaseVal;
    } else {
      purchaseVal = inputData.purchase_price;
    }
    setPurchasePrice( purchaseVal );
    // Down payment slider
    let down_payment = 0;

    if ( inputData.down_payment_in_percentage === undefined ) {
      if ( purchaseVal >= 0 ) {
        const down_payment_val = ( purchaseVal / 100 ) * 20;
        down_payment = ( down_payment_val / purchaseVal ) * 100;
      }
    } else {
      down_payment = inputData.down_payment_in_percentage;
    }

    inputValues['down_payment_in_percentage'] = down_payment;

    updateData('total_obj',inputValues);

    setDownpayment( down_payment );
    setMortgage( 100 - down_payment );
    const downpaymentVal = ( purchaseVal / 100 ) * down_payment;
    setDownpaymentValue( downpaymentVal );
    setMortgageValue( purchaseVal - downpaymentVal );
    // eslint-disable-next-line
}, [] );

  const updateTimeLine = (startYearVal) => {
    if(startYearVal > 0) {
      let timelineData = [...timeline];
      const index = _.findIndex( timeline, { selectedModule: 'module13' } );
      if(index >= 0) {
        timelineData[index].startYear = startYearVal;
        timelineData[index].endYear = '';
        timelineData[index].imageSlug = 'active';
        timelineData[index].timelineOrder = startYearVal;
        sortTimeLine(timelineData);
      }
    }
  }

  const updateValue = ( e, value, field, mainField, type ) => {
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = parseInt(e.target.value, 10);
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    switch ( field ) {
      case 'year_of_purchase': {
        setYearOfPurchase( newvalue );
        updateData( mainField, newvalue );
        updateTimeLine(newvalue)
        break;
      }
      case 'purchase_price': {
        setPurchasePrice( newvalue );
        updateData( mainField, newvalue );
        setDownpaymentValue( ( newvalue / 100 ) * downpayment );
        setMortgageValue( ( newvalue / 100 ) * mortgage );
        break;
      }
      case 'downpayment_slider': {
        setDownpayment( newvalue );
        setDownpaymentValue( ( purchasePrice / 100 ) * newvalue );
        setMortgage( 100 - newvalue );
        setMortgageValue( ( purchasePrice / 100 ) * ( 100 - newvalue ) );
        updateData( mainField, newvalue );
        break;
      }
      case 'downpayment': {
        if ( newvalue > purchasePrice ) {
          newvalue = purchasePrice;
        }
        setDownpaymentValue( newvalue );
        let downpaymentVal = 0;
        if ( purchasePrice > 0 ) {
          downpaymentVal = ( newvalue / purchasePrice ) * 100;
        }
        setDownpayment( downpaymentVal );
        setMortgage( 100 - downpaymentVal );
        setMortgageValue( ( purchasePrice / 100 ) * ( 100 - downpaymentVal ) );
        updateData( mainField, downpaymentVal );
        break;
      }
      case 'mortgage_slider': {
        setMortgage( newvalue );
        setMortgageValue( ( purchasePrice / 100 ) * newvalue );
        setDownpayment( 100 - newvalue );
        setDownpaymentValue( ( purchasePrice / 100 ) * ( 100 - newvalue ) );
        updateData( mainField, 100 - newvalue );
        break;
      }
      case 'mortgage': {
        if ( newvalue > purchasePrice ) {
          newvalue = purchasePrice;
        }
        setMortgageValue( newvalue );
        let mortgageVal = 0;
        if ( purchasePrice > 0 ) {
          mortgageVal = ( newvalue / purchasePrice ) * 100;
        }
        setMortgage( mortgageVal );
        setDownpayment( 100 - mortgageVal );
        setDownpaymentValue( ( purchasePrice / 100 ) * ( 100 - mortgageVal ) );
        updateData( mainField, 100 - mortgageVal );
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className={ classes.contentBlock }>
      <p>Buying a home is usually the largest purchase you will make in your life. That may seem daunting, but it doesn’t have to be. Equipped with a well-researched plan and a strategy to manage that asset, you will gain confidence in your decision and peace of mind with your finances.</p>
      <p>
        Buying a home starts with time. When do you plan on buying a home? Then, how much will that home cost? And, what percentage of the home will you pay for out of your own pocket,
        which is know as a
        {' '}
        <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Home Down Payment' ) }><span className={ classes.dottedBorder }>down payment?</span></span>
        {' '}
        {' '}
        The more you put down, the less you will have to borrow through a mortgage.
      </p>
      <CareerSketchTimeline moduleName="module13"/>
      <div className={ classes.purchaseSlideScroll }>
        <div className={ classes.purchaseSlide }>
          <div className={ classes.flexDisplayNew }>
            <div className={ classes.sliderFlex }>
              <div>
                <p>
                  Purchase Year
                </p>
                <div className={ classes.pwisliderInputs }>
                  <PwiSlider
                    value={ yearOfPurchase }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    min={ yearsList[0] }
                    max={ yearsList[yearsList.length - 1] }
                    step={ 1 }
                    marks={ yearsListTicksValues }
                    ValueLabelComponent={ ValueLabelComponent }
                    onChange={ ( e, value ) => updateValue( e, value, 'year_of_purchase', 'year_of_purchase', 'slider' ) }
                  />
                </div>
              </div>
            </div>
            <div className={ classNames( classes.sliderValues, classes.downValues ) }>
              <div>
                <span className={ classNames( classes.dollarSymbol, classes.hidden ) }>$</span>
                <select value={ yearOfPurchase } onChange={ ( e ) => updateValue( e, '', 'year_of_purchase', 'year_of_purchase', 'input' ) }>
                  {
                  yearsList.map( ( data ) => (
                    <option value={ data }>{data}</option>
                  ) )
                  }
                </select>
              </div>
            </div>
          </div>
          <div className={ classes.flexDisplayNew }>
            <div className={ classes.sliderFlex }>
              <div>
                <p>
                  <span>
                    Purchase Price
                  </span>
                </p>
                <div className={ classes.pwisliderInputs }>
                  <PwiSlider
                    value={ purchasePrice }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    valueLabelFormat={ converyIntoThousands }
                    min={ 0 }
                    max={ 800000 }
                    step={ 1000 }
                    marks={ purchasePriceMarks }
                    ValueLabelComponent={ ValueLabelComponent }
                    onChange={ ( e, value ) => updateValue( e, value, 'purchase_price', 'purchase_price', 'slider' ) }
                  />
                </div>
              </div>
            </div>
            <div className={ classNames( classes.sliderValues, classes.downValues ) }>
              <div>
                <span className={ classes.dollarSymbol }>$</span>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ purchasePrice } onValueChange={ ( e ) => updateValue( e, '', 'purchase_price', 'purchase_price', 'number' ) } onFocus={ ( e ) => e.target.select() } />
              </div>
            </div>
          </div>
          <div className={ classes.flexDisplayNew }>
            <div className={ classes.sliderFlex }>
              <div>
                <p>
                  <span>
                    Down Payment
                  </span>
                </p>
                <div className={ classes.pwisliderInputs }>
                  <PwiSlider
                    value={ downpayment }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    valueLabelFormat={ percentFormatWithTwoDecimal }
                    min={ 0 }
                    max={ 100 }
                    step={ 0.5 }
                    marks={ mortgageFollowersMarks }
                    ValueLabelComponent={ ValueLabelComponent }
                    onChange={ ( e, value ) => updateValue( e, value, 'downpayment_slider', 'down_payment_in_percentage', 'slider' ) }
                  />
                </div>
              </div>
            </div>
            <div className={ classNames( classes.sliderValues, classes.downValues ) }>
              <div>
                <span className={ classes.dollarSymbol }>$</span>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ downpaymentValue } onValueChange={ ( e ) => updateValue( e, '', 'downpayment', 'down_payment_in_percentage', 'number' ) } onFocus={ ( e ) => e.target.select() } />
              </div>
            </div>
          </div>
          <div className={ classNames( classes.flexDisplayNew, classes.mortgageHeight ) }>
            <div className={ classes.sliderFlex }>
              <div>
                <p>
                  <span>
                    Mortgage
                  </span>
                </p>
                <div className={ classes.pwisliderInputs }>
                  <PwiSlider
                    value={ mortgage }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    valueLabelFormat={ percentFormatWithTwoDecimal }
                    min={ 0 }
                    max={ 100 }
                    step={ 0.5 }
                    marks={ mortgageFollowersMarks }
                    ValueLabelComponent={ ValueLabelComponent }
                    onChange={ ( e, value ) => updateValue( e, value, 'mortgage_slider', 'down_payment_in_percentage', 'slider' ) }
                  />
                </div>
              </div>
            </div>
            <div className={ classNames( classes.sliderValues, classes.downValues ) }>
              <div>
                <span className={ classes.dollarSymbol }>$</span>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ mortgageValue } onValueChange={ ( e ) => updateValue( e, '', 'mortgage', 'down_payment_in_percentage', 'number' ) } onFocus={ ( e ) => e.target.select() } />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={ classes.summaryBtn }><button type="button" className={ classes.summaryWorksheet } onClick={()=>{setOpenWorkSheet(true)}}>Summary Worksheet</button></div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

PurchasePriceDownPayment.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  setOpenWorkSheet: PropTypes.func.isRequired,
};

export default withStyles( styles )( PurchasePriceDownPayment );
