function InputsSheet( module1Data, module9Data, incomeStatementnetData, cashFlowData, balanceSheetData, taxStatementData, investmentsData ) {
  const inputsSheetData = {};
  let startYear = 0;
  let endYear = 0;
  let tangibleAssetsValue = 0;
  let retirementAccountsValue = 0;
  let investmentsValue = 0;
  let cumulativeTaxesPaidValue = 0;
  let cumulativeInterestPaidValue = 0;
  let debttoIncomeRatioRatioValue = 0;
  let totalInterestExpensesDebt1 = 0;
  let monthlyLiquidityRatio = 0;
  let monthlyLiquidityRatio1 = 0;
  let monthlyLiquidityRatio2 = 0;
  let currentRatio = 0;
  let currentRatio1 = 0;
  let currentRatio2 = 0;
  let currentRatio3 = 0;
  let savingsRate = 0;
  let savingsRate1 = 0;
  let savingsRate2 = 0;
  let savingsRate4 = 0;
  let investmentsAssets = 0;
  let investmentsAssets1 = 0;
  let totalAssetstoTotalDebt = 0;
  let totalAssetstoTotalDebt1 = 0;
  let saolvencyRatio = 0;
  let saolvencyRatio1 = 0;
  let taxStatus = 0;
  let monthlyExpense1 = 0;
  let monthlyExpense2 = 0;
  let monthlyExpense3 = 0;
  let monthlyExpense4 = 0;
  let monthlyExpense5 = 0;
  let monthlyExpense = 0;
  let retirementContributions = 0;
  let shortTermContribution = 0;
  let longTermContribution = 0;
  if ( module1Data.start_year !== undefined && module1Data.start_year !== '' && balanceSheetData !== undefined && balanceSheetData !== '' ) {
    startYear = parseInt( module1Data.start_year, 10 );

    endYear = startYear + 15;
    for ( let i = startYear; i < endYear; i += 1 ) {
      /** *****************
         ** Balance Sheet **
         ****************** */
      if ( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[i] !== undefined && balanceSheetData[i].Assets !== undefined && balanceSheetData[i].Assets.Cash !== undefined ) {
        inputsSheetData[i] = {};
        inputsSheetData[i]['Balance Sheet'] = {};
        inputsSheetData[i]['Income Statement'] = {};
        inputsSheetData[i]['Tax Statement'] = {};
        inputsSheetData[i]['Cash Flow'] = {};
        inputsSheetData[i]['Tax - Interest Expenses'] = {};
        inputsSheetData[i]['Monthly Data'] = {};

        // Cash
        inputsSheetData[i]['Balance Sheet'].Cash = Math.round( balanceSheetData[i].Assets.Cash );

        // Tangible Assets
        tangibleAssetsValue = balanceSheetData[i].Assets['Auto Property']
          + balanceSheetData[i].Assets['Real Estate Property'];
        inputsSheetData[i]['Balance Sheet']['Other Tangible Assets'] = Math.round( tangibleAssetsValue );

        // Retirement Accounts
        retirementAccountsValue = balanceSheetData[i].Investments.for401k
          + balanceSheetData[i].Investments['Roth IRA']
          + balanceSheetData[i].Investments['Seph IRA']
          + balanceSheetData[i].Investments.Pension;
        inputsSheetData[i]['Balance Sheet']['Retirement Savings'] = Math.round( retirementAccountsValue );

        // Investments
        investmentsValue = balanceSheetData[i].Investments['Short-Term Investments']
          + balanceSheetData[i].Investments['Long-Term Investments'];
        inputsSheetData[i]['Balance Sheet'].Investments = Math.round( investmentsValue );

        // Liabilities
        inputsSheetData[i]['Balance Sheet'].Liabilities = Math.round( balanceSheetData[i]['Total Liabilities'] );

        // Net Worth
        inputsSheetData[i]['Balance Sheet']['Net Worth'] = Math.round( balanceSheetData[i]['Net Worth'] );
      } else {
        inputsSheetData[i]['Balance Sheet'].Cash = 0;
        inputsSheetData[i]['Balance Sheet']['Other Tangible Assets'] = 0;
        inputsSheetData[i]['Balance Sheet']['Retirement Savings'] = 0;
        inputsSheetData[i]['Balance Sheet'].Investments = 0;
        inputsSheetData[i]['Balance Sheet'].Liabilities = 0;
        inputsSheetData[i]['Balance Sheet']['Net Worth'] = 0;
      }
      /** *****************
         ** End Balance Sheet **
         ****************** */

      /** ********************
         ** Start Tax statement **
         ********************* */
      if ( taxStatementData !== undefined && taxStatementData !== '' && taxStatementData[i] !== undefined && taxStatementData[i] !== '' ) {
        // Total Gross Income (excluding Rental Income)
        inputsSheetData[i]['Tax Statement']['Total Gross Income (excluding Rental Income)'] = taxStatementData[i]['Gross Income']['Total Gross Income (excluding Rental Income)'];
        // Total Adjustments
        inputsSheetData[i]['Tax Statement']['Total Adjustments'] = -taxStatementData[i]['Total Adjustments'];

        // Adjusted Gross Income
        inputsSheetData[i]['Tax Statement']['Adjusted Gross Income'] = taxStatementData[i]['Adjusted Gross Income'];

        // REI Property Taxable Income (Passive Losses Taken)
        inputsSheetData[i]['Tax Statement']['REI Property Taxable Income (Passive Losses Taken)'] = taxStatementData[i]['REI Property Taxable Income (Passive Losses Taken)'];
        // Itemized Deductions or Standard Deduction
        inputsSheetData[i]['Tax Statement']['Itemized Deductions or Standard Deduction'] = -taxStatementData[i]['Total Deductions'];

        // Federal Taxable Income
        inputsSheetData[i]['Tax Statement']['Federal Taxable Income'] = taxStatementData[i]['Federal Taxable Income'];

        // Total Taxes before Credits
        inputsSheetData[i]['Tax Statement']['Total Taxes before Credits'] = taxStatementData[i]['Total Taxes'];

        // Total Tax Credits
        inputsSheetData[i]['Tax Statement']['Total Tax Credits'] = taxStatementData[i]['Total Tax Credits'];

        // Total Tax on Real Estate Sales
        inputsSheetData[i]['Tax Statement']['Total Tax on Real Estate Sales'] = taxStatementData[i]['Total Investment Property Sale'];

        // Total Taxes
        inputsSheetData[i]['Tax Statement']['Total Taxes'] = taxStatementData[i]['Net Income'];
      } else {
        inputsSheetData[i]['Tax Statement']['Total Gross Income (excluding Rental Income)'] = 0;
        inputsSheetData[i]['Tax Statement']['REI Property Taxable Income (Passive Losses Taken)'] = 0;
        inputsSheetData[i]['Tax Statement']['Total Adjustments'] = 0;
        inputsSheetData[i]['Tax Statement']['Adjusted Gross Income'] = 0;
        inputsSheetData[i]['Tax Statement']['Itemized Deductions or Standard Deduction'] = 0;
        inputsSheetData[i]['Tax Statement']['Federal Taxable Income'] = 0;
        inputsSheetData[i]['Tax Statement']['Total Taxes before Credits'] = 0;
        inputsSheetData[i]['Tax Statement']['Total Tax Credits'] = 0;
        inputsSheetData[i]['Tax Statement']['Total Tax on Real Estate Sales'] = 0;
        inputsSheetData[i]['Tax Statement']['Total Taxes'] = 0;
      }
      /** ********************
         ** End Tax statement **
         ********************* */

      /** ********************
         ** Income Statement **
         ********************* */
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== '' && incomeStatementnetData[i] !== undefined ) {
        // Gross Income
        inputsSheetData[i]['Income Statement']['Total Income'] = Math.round( incomeStatementnetData[i]['Total Income'] );

        // Taxes
        inputsSheetData[i]['Income Statement']['Total Taxes'] = Math.round( incomeStatementnetData[i]['Total Taxes'] );

        // Income after Taxes
        inputsSheetData[i]['Income Statement']['Income after Taxes'] = Math.round( incomeStatementnetData[i]['Income After Taxes'] );

        // Living Expenses
        inputsSheetData[i]['Income Statement']['Living Expenses'] = Math.round( incomeStatementnetData[i]['Total Living Expenses'] );

        // Car Expenses
        inputsSheetData[i]['Income Statement']['Car Expenses'] = Math.round( incomeStatementnetData[i]['Total Car Expenses'] );

        // Real Estate Expenses
        inputsSheetData[i]['Income Statement']['Real Estate Expenses'] = Math.round( incomeStatementnetData[i]['Total Real Estate Expenses'] );

        // Interest Expenses Loan Payments
        inputsSheetData[i]['Income Statement']['Loan Payments'] = Math.round( incomeStatementnetData[i]['Total Interest Expenses'] );

        // Tax Credits
        inputsSheetData[i]['Income Statement']['Total Expenses'] = Math.round( incomeStatementnetData[i]['Total Expenses'] );

        // Net Income
        inputsSheetData[i]['Income Statement']['Net Income'] = Math.round( incomeStatementnetData[i]['Net Income'] );
      } else {
        inputsSheetData[i]['Income Statement']['Total Income'] = 0;
        inputsSheetData[i]['Income Statement']['Total Taxes'] = 0;
        inputsSheetData[i]['Income Statement']['Income after Taxes'] = 0;
        inputsSheetData[i]['Income Statement']['Living Expenses'] = 0;
        inputsSheetData[i]['Income Statement']['Car Expenses'] = 0;
        inputsSheetData[i]['Income Statement']['Real Estate Expenses'] = 0;
        inputsSheetData[i]['Income Statement']['Loan Payments'] = 0;
        inputsSheetData[i]['Income Statement']['Total Expenses'] = 0;
        inputsSheetData[i]['Income Statement']['Net Income'] = 0;
      }

      /** *************
         ** Cash Flow **
         ************** */
      if ( cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[i] !== undefined ) {
        // Beginning Cash Balance
        inputsSheetData[i]['Cash Flow']['Beginning Cash Balance'] = Math.round( cashFlowData[i]['Beginning Cash Balance'] );

        // Cash from Operating Activities
        inputsSheetData[i]['Cash Flow']['Cash from Operating Activities'] = Math.round( cashFlowData[i]['Total Cash from Operating Activities'] );

        // Cash from Investing Activities
        inputsSheetData[i]['Cash Flow']['Cash from Investing Activities'] = Math.round( cashFlowData[i]['Total Cash from Investing Activities'] );

        // Cash from Financing Activities
        inputsSheetData[i]['Cash Flow']['Cash from Financing Activities'] = Math.round( cashFlowData[i]['Total Cash from Financing Activities'] );

        // Net Change in Cash
        inputsSheetData[i]['Cash Flow']['Net Change in Cash'] = Math.round( cashFlowData[i]['Net Change in Cash'] );

        // Ending Cash Surplus / (Need)
        inputsSheetData[i]['Cash Flow']['Ending Cash Surplus / (Need)'] = Math.round( cashFlowData[i]['Ending Cash Surplus'] );

        // Excess Cash Invested / (Drawn)
        let Val1 = 0;
        // if ( module1Data.max_cash_balance !== undefined && module1Data.max_cash_balance !== '' ) {
        //   Val1 = module1Data.max_cash_balance;
        // }
        let Val2 = 0;
        if ( module9Data.short_term_max_sti_balance_before_lt_investment !== undefined && module9Data.short_term_max_sti_balance_before_lt_investment !== '' ) {
          Val2 = module9Data.short_term_max_sti_balance_before_lt_investment;
        }
        inputsSheetData[i]['Cash Flow']['Excess Cash Invested / (Drawn)'] = Math.round(
          -( cashFlowData[i]['Cash Injection from Short-Term Investments']
                    + cashFlowData[i]['Cash Injection from Long-Term Investments'] )
                - ( cashFlowData[i]['Excess Cash Put into STI'][Val1]
                    + cashFlowData[i]['Excess STI Put into LTI'][Val2] ),
        );

        // Ending Cash Balance
        inputsSheetData[i]['Cash Flow']['Ending Cash Balance'] = Math.round( cashFlowData[i]['Ending Cash Balance'] );
      } else {
        inputsSheetData[i]['Cash Flow']['Beginning Cash Balance'] = 0;
        inputsSheetData[i]['Cash Flow']['Cash from Operating Activities'] = 0;
        inputsSheetData[i]['Cash Flow']['Cash from Investing Activities'] = 0;
        inputsSheetData[i]['Cash Flow']['Cash from Financing Activities'] = 0;
        inputsSheetData[i]['Cash Flow']['Net Change in Cash'] = 0;
        inputsSheetData[i]['Cash Flow']['Ending Cash Surplus / (Need)'] = 0;
        inputsSheetData[i]['Cash Flow']['Excess Cash Invested / (Drawn)'] = 0;
        inputsSheetData[i]['Cash Flow']['Ending Cash Balance'] = 0;
      }

      /** ***************************
         ** Tax & Interest Expenses **
         **************************** */
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== '' && incomeStatementnetData[i] !== undefined ) {
        if ( taxStatus === 0 ) {
          cumulativeTaxesPaidValue = inputsSheetData[i]['Tax Statement']['Total Taxes'];
        } else {
          cumulativeTaxesPaidValue += inputsSheetData[i]['Tax Statement']['Total Taxes'];
        }

        if ( taxStatus === 0 ) {
          cumulativeInterestPaidValue = inputsSheetData[i]['Income Statement']['Loan Payments'];
        } else {
          cumulativeInterestPaidValue += inputsSheetData[i]['Income Statement']['Loan Payments'];
        }
        taxStatus += 1;
        // Cumulative Taxes Paid
        inputsSheetData[i]['Tax - Interest Expenses']['Cumulative Taxes Paid'] = Math.round( cumulativeTaxesPaidValue );

        // Cumulative Interest Paid
        inputsSheetData[i]['Tax - Interest Expenses']['Cumulative Loan Payments'] = Math.round( cumulativeInterestPaidValue );
      } else {
        inputsSheetData[i]['Tax - Interest Expenses']['Cumulative Taxes Paid'] = 0;
        inputsSheetData[i]['Tax - Interest Expenses']['Cumulative Loan Payments'] = 0;
      }

      debttoIncomeRatioRatioValue = 0;
      totalInterestExpensesDebt1 = 0;
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== '' && incomeStatementnetData[i] !== undefined
          && incomeStatementnetData[i]['Total Interest Expenses'] !== '' ) {
        totalInterestExpensesDebt1 = incomeStatementnetData[i]['Total Interest Expenses'];
      }
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== '' && incomeStatementnetData[i] !== undefined && incomeStatementnetData[i]['Total Income'] !== undefined && incomeStatementnetData[i]['Total Income'] !== 0 ) {
        debttoIncomeRatioRatioValue = ( -( parseFloat( totalInterestExpensesDebt1 ) ) / parseFloat( incomeStatementnetData[i]['Total Income'] ) ) * 100;
      }
      // Debt-to-Income Ratio Ratio
      inputsSheetData[i]['Debt-to-Income Ratio Ratio'] = debttoIncomeRatioRatioValue;

      // Monthly Liquidity Ration value
      monthlyLiquidityRatio = 0;
      monthlyLiquidityRatio1 = 0;
      monthlyLiquidityRatio2 = 0;
      if ( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[i] !== undefined && balanceSheetData[i].Assets !== undefined && balanceSheetData[i].Assets.Cash !== '' ) {
        monthlyLiquidityRatio1 = balanceSheetData[i].Assets.Cash;
      }
      if ( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[i] !== undefined && balanceSheetData[i].Investments !== undefined && balanceSheetData[i].Investments['Short-Term Investments'] !== '' ) {
        monthlyLiquidityRatio2 = balanceSheetData[i].Investments['Short-Term Investments'];
      }
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
             && incomeStatementnetData[i] !== undefined
             && incomeStatementnetData[i]['Total Expenses'] !== undefined
            && incomeStatementnetData[i]['Total Expenses'] !== 0 ) {
        monthlyLiquidityRatio = ( parseFloat( monthlyLiquidityRatio1 ) + parseFloat( monthlyLiquidityRatio2 ) ) / -parseFloat( incomeStatementnetData[i]['Total Expenses'] / 12 );
      }
      if ( monthlyLiquidityRatio > 1000 ) {
        monthlyLiquidityRatio = 1000;
      }
      inputsSheetData[i]['Monthly Liquidity Ratio'] = monthlyLiquidityRatio;

      // Current Ratio  Value
      currentRatio = 0;
      currentRatio1 = 0;
      currentRatio2 = 0;
      currentRatio3 = 0;
      if ( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[i] !== undefined && balanceSheetData[i].Assets !== undefined && balanceSheetData[i].Assets.Cash !== '' ) {
        currentRatio1 = balanceSheetData[i].Assets.Cash;
      }

      if ( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[i] !== undefined && balanceSheetData[i].Investments !== undefined && balanceSheetData[i].Investments['Short-Term Investments'] !== '' ) {
        currentRatio2 = balanceSheetData[i].Investments['Short-Term Investments'];
      }

      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== '' && incomeStatementnetData[i] !== undefined && incomeStatementnetData[i]['Total Interest Expenses'] !== undefined && incomeStatementnetData[i]['Total Interest Expenses'] !== 0 ) {
        currentRatio3 = -incomeStatementnetData[i]['Total Interest Expenses'];
      }

      if ( parseFloat( currentRatio3 ) !== 0 ) {
        currentRatio = ( parseFloat( currentRatio1 ) + parseFloat( currentRatio2 ) ) / parseFloat( currentRatio3 );
      } else {
        currentRatio = 0;
      }
      if ( currentRatio > 1000 ) {
        currentRatio = 1000;
      }
      inputsSheetData[i]['Current Ratio'] = currentRatio;

      // Savings Rate Value
      savingsRate = 0;
      savingsRate1 = 0;
      savingsRate2 = 0;
      savingsRate4 = 0;
      if ( cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[i] !== undefined && cashFlowData[i]['Net Change in Cash'] !== undefined && cashFlowData[i]['Net Change in Cash'] !== '' ) {
        savingsRate1 = cashFlowData[i]['Net Change in Cash'];
      }
      if ( cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[i] !== undefined && cashFlowData[i]['Cash from Investing Activities'] !== undefined && cashFlowData[i]['Cash from Investing Activities']['Retirement Contributions'] !== undefined && cashFlowData[i]['Cash from Investing Activities']['Retirement Contributions'] !== '' ) {
        savingsRate4 = cashFlowData[i]['Cash from Investing Activities']['Retirement Contributions'];
      }
      if ( cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[i] !== undefined
             && cashFlowData[i]['Cash from Investing Activities'] !== undefined
             && cashFlowData[i]['Cash from Investing Activities']['Contribution to Investment Accounts'] !== undefined
            && cashFlowData[i]['Cash from Investing Activities']['Contribution to Investment Accounts'] !== '' ) {
        savingsRate2 = cashFlowData[i]['Cash from Investing Activities']['Contribution to Investment Accounts'];
      }

      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
             && incomeStatementnetData[i] !== undefined
             && incomeStatementnetData[i]['Income After Taxes'] !== undefined
            && incomeStatementnetData[i]['Income After Taxes'] !== 0 ) {
        savingsRate = ( ( parseFloat( savingsRate1 ) - parseFloat( savingsRate4 ) - parseFloat( savingsRate2 ) ) / parseFloat( incomeStatementnetData[i]['Income After Taxes'] ) ) * 100;
      }
      inputsSheetData[i]['Savings Rate'] = savingsRate;

      // Investment Assets to Total Assets Value
      investmentsAssets = 0;
      investmentsAssets1 = 0;
      if ( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[i] !== undefined && balanceSheetData[i]['Total Investments'] !== undefined && balanceSheetData[i]['Total Investments'] !== '' ) {
        investmentsAssets1 = balanceSheetData[i]['Total Investments'];
      }
      if ( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[i] !== undefined && balanceSheetData[i]['Total Assets'] !== undefined && balanceSheetData[i]['Total Assets'] !== '' ) {
        investmentsAssets = ( parseFloat( investmentsAssets1 ) / balanceSheetData[i]['Total Assets'] ) * 100;
      }
      inputsSheetData[i]['Investment Assets to Total Assets'] = investmentsAssets;

      // Total Assets to Total Debt
      totalAssetstoTotalDebt = 0;
      totalAssetstoTotalDebt1 = 0;
      if ( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[i] !== undefined && balanceSheetData[i]['Total Assets'] !== undefined && balanceSheetData[i]['Total Assets'] !== '' ) {
        totalAssetstoTotalDebt1 = balanceSheetData[i]['Total Assets'];
      }
      if ( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[i] !== undefined && balanceSheetData[i]['Total Liabilities'] !== undefined && balanceSheetData[i]['Total Liabilities'] !== '' ) {
        totalAssetstoTotalDebt = parseFloat( totalAssetstoTotalDebt1 ) / balanceSheetData[i]['Total Liabilities'];
      }
      if ( totalAssetstoTotalDebt > 1000 ) {
        totalAssetstoTotalDebt = 1000;
      }
      inputsSheetData[i]['Total Assets to Total Debt'] = totalAssetstoTotalDebt;

      // Solvency Ratio Value
      saolvencyRatio = 0;
      saolvencyRatio1 = 0;
      if ( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[i] !== undefined && balanceSheetData[i]['Net Worth'] !== undefined && balanceSheetData[i]['Net Worth'] !== '' ) {
        saolvencyRatio1 = balanceSheetData[i]['Net Worth'];
      }
      if ( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[i] !== undefined && balanceSheetData[i]['Total Assets'] !== undefined && balanceSheetData[i]['Total Assets'] !== '' ) {
        saolvencyRatio = parseFloat( saolvencyRatio1 ) / balanceSheetData[i]['Total Assets'];
      }
      if ( saolvencyRatio > 1000 ) {
        saolvencyRatio = 1000;
      }
      inputsSheetData[i]['Solvency Ratio'] = saolvencyRatio;

      /* Monthly calculations */
      // Monthly Income after Taxes
      inputsSheetData[i]['Monthly Data']['Monthly Income after Taxes'] = inputsSheetData[i]['Income Statement']['Income after Taxes'] / 12;

      // Monthly Expenses
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                       && incomeStatementnetData[i] !== undefined
                       && incomeStatementnetData[i]['Total Expenses'] !== undefined ) {
        monthlyExpense1 = incomeStatementnetData[i]['Total Expenses'];
      }
      if ( cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[i] !== undefined
                       && cashFlowData[i]['Cash from Investing Activities'] !== undefined ) {
        if ( cashFlowData[i]['Cash from Financing Activities']['Existing Credit Card Debt Principal Payback'] !== undefined ) {
          monthlyExpense2 = cashFlowData[i]['Cash from Financing Activities']['Existing Credit Card Debt Principal Payback'];
        }
        if ( cashFlowData[i]['Cash from Financing Activities']['Car Loans Principal Payback'] !== undefined ) {
          monthlyExpense3 = cashFlowData[i]['Cash from Financing Activities']['Car Loans Principal Payback'];
        }
        if ( cashFlowData[i]['Cash from Financing Activities']['Student Loans Principal Payback'] !== undefined ) {
          monthlyExpense4 = cashFlowData[i]['Cash from Financing Activities']['Student Loans Principal Payback'];
        }
        if ( cashFlowData[i]['Cash from Financing Activities']['Mortgages Principal Payback'] !== undefined ) {
          monthlyExpense5 = cashFlowData[i]['Cash from Financing Activities']['Mortgages Principal Payback'];
        }
      }
      monthlyExpense = monthlyExpense1 + monthlyExpense2 + monthlyExpense3 + monthlyExpense4 + monthlyExpense5;
      inputsSheetData[i]['Monthly Data']['Monthly Expenses'] = monthlyExpense / 12;

      // Monthly Retirement Contributions
      if ( cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[i] !== undefined
                       && cashFlowData[i]['Cash from Investing Activities'] !== undefined
           && cashFlowData[i]['Cash from Investing Activities']['Retirement Contributions'] !== undefined ) {
        retirementContributions = cashFlowData[i]['Cash from Investing Activities']['Retirement Contributions'] / 12;
      }
      inputsSheetData[i]['Monthly Data']['Monthly Retirement Contributions'] = retirementContributions;

      // Monthly Net Cash Flow for Savings and Investments
      inputsSheetData[i]['Monthly Data']['Monthly Net Cash Flow for Savings and Investments'] = inputsSheetData[i]['Monthly Data']['Monthly Income after Taxes'] + inputsSheetData[i]['Monthly Data']['Monthly Expenses'] + inputsSheetData[i]['Monthly Data']['Monthly Retirement Contributions'];

      // Monthly Short-Term Investment Contributions
      if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['Short-Term Investments'] !== undefined && investmentsData['Short-Term Investments'] !== '' && investmentsData['Short-Term Investments'][i] !== undefined && investmentsData['Short-Term Investments'][i] !== '' && investmentsData['Short-Term Investments'][i]['Cash Contibution'] !== undefined && investmentsData['Short-Term Investments'][i]['Cash Contibution'] !== '' ) {
        shortTermContribution = investmentsData['Short-Term Investments'][i]['Cash Contibution'] / 12;
      }
      inputsSheetData[i]['Monthly Data']['Monthly Short-Term Investment Contributions'] = shortTermContribution;

      // Monthly Long-Term Investment Contributions
      if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['Long-Term Investments'] !== undefined && investmentsData['Long-Term Investments'] !== '' && investmentsData['Long-Term Investments'][i] !== undefined && investmentsData['Long-Term Investments'][i] !== '' && investmentsData['Long-Term Investments'][i]['Cash Contibution'] !== undefined && investmentsData['Long-Term Investments'][i]['Cash Contibution'] !== '' ) {
        longTermContribution = investmentsData['Long-Term Investments'][i]['Cash Contibution'] / 12;
      }
      inputsSheetData[i]['Monthly Data']['Monthly Long-Term Investment Contributions'] = longTermContribution;

      // Monthly Net Cash Flow for Savings
      inputsSheetData[i]['Monthly Data']['Monthly Net Cash Flow for Savings'] = inputsSheetData[i]['Monthly Data']['Monthly Net Cash Flow for Savings and Investments'] - ( inputsSheetData[i]['Monthly Data']['Monthly Short-Term Investment Contributions'] + inputsSheetData[i]['Monthly Data']['Monthly Long-Term Investment Contributions'] );
    }
  }

  return inputsSheetData;
}
export default InputsSheet;
