import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { TextField } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import NumberFormat from 'react-number-format';
import { loader } from 'graphql.macro';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/higher-education/styles';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import _ from 'lodash';

const COMPARE_SCHOOL_DETAILS = loader('../../../graphql/schema/higher-education/getCompareSchoolDetails.graphql');

const CompareSchool = (props) => {
  const {
    classes, inputData, updateData, compareCollegeList, setLoading
  } = props;

  const [higherEducation, setHigherEducation] = React.useState({});
  const [searchResults, setSearchResults] = React.useState({});
  const [search, setSearch] = React.useState('');
  const [selectInput, setSelectInput] = React.useState('1');
  const [searchType, setSearchType] = React.useState('name');
  const [compareData, setCompareData] = React.useState({ '1': {}, '2': {} });

  const collegeObj = {
    act_average_scores: '--',
    admission_rate: '67%',
    after_federal_loans: '$19,159',
    avg_annual_cost: '$16,395 ',
    avg_federal_loans_amount: '$4,925',
    avg_gift_aid_amount: '$7,278',
    created_date: '2020-09-24 06:02:10',
    enrollment: '4,187',
    expected_monthly_payment: '$199',
    federal_loans_default_rate: '10.1%',
    first_federal_loans: '47.3%',
    first_gift_aid: '49%',
    freshman_retention_rate: '69%',
    future_earnings: '2.3x',
    graduation_rate: '45%',
    id: '2020',
    in_state_fee: '$16,917',
    location: '--',
    median_earnings: '$38,463',
    out_state_fee: '$20,194 ',
    range1: '$13,570',
    range2: '$14,121',
    range3: '$16,320',
    range4: '$18,965',
    range5: '$21,891',
    room_board: '$5,934 ',
    sat_averate_math_scores: '--',
    sat_averate_reading_scores: '--',
    school_name: 'All Colleges',
    school_type: 'college',
  };
  
  const careerSchoolObj = {
    act_average_scores: '--',
    admission_rate: '77%',
    after_federal_loans: '$9,864',
    avg_annual_cost: '$16,227',
    avg_federal_loans_amount: '$5,623',
    avg_gift_aid_amount: '$672',
    created_date: '2020-09-24 06:02:10',
    enrollment: '219',
    expected_monthly_payment: '$102',
    federal_loans_default_rate: '13.4%',
    first_federal_loans: '58.9%',
    first_gift_aid: '8.9%',
    freshman_retention_rate: '73%',
    future_earnings: '1.5x',
    graduation_rate: '69%',
    id: '2020',
    in_state_fee: '$16,411',
    location: '--',
    median_earnings: '$25,091',
    out_state_fee: '$16,411',
    range1: '$15,734',
    range2: '$16,703',
    range3: '$18,550',
    range4: '$20,745',
    range5: '$21,056',
    room_board: '$93',
    sat_averate_math_scores: '--',
    sat_averate_reading_scores: '--',
    school_name: 'All Career Schools',
    school_type: 'Career Schools',
  };

  const typeOfHeData = inputData.type_of_higher_education === 1 ? careerSchoolObj :collegeObj ;


  useEffect(() => {
    if (inputData.compareInputData !== undefined) {
      setHigherEducation(inputData.compareInputData);
    }
    if (inputData.compareData !== undefined) {
      setCompareData(inputData.compareData);
    }

    if (inputData.school !== undefined && inputData.school !== 'new') {
      if (inputData.name_of_college !== undefined && inputData.name_of_college !== '') {
        setSearch(inputData.name_of_college);
      }
    }
  }, []);
  useQuery(COMPARE_SCHOOL_DETAILS, {
    variables: {
      type: searchType,
      details: search,
      education_type: inputData.type_of_higher_education === 1 ? 'career' : 'college',
    },
    skip: search === '',
    fetchPolicy: 'no-cache',
    onCompleted(res) {
      setLoading(false);
      if (res !== undefined && res.getCompareSchoolDetails !== undefined) {
        let comparisonDisplayData = { ...compareData };
        comparisonDisplayData[selectInput.toString()] = res.getCompareSchoolDetails;
        setCompareData(comparisonDisplayData);
        updateData('compareData', comparisonDisplayData);

        let higherEducationData = { ...higherEducation };
        higherEducationData['compair_school_' + selectInput] = comparisonDisplayData[selectInput.toString()].school_name;
        setHigherEducation(higherEducationData);
      }
    },
  });



  const searchCompairSchool = (e, number) => {
    let higherEducationData = { ...higherEducation };
    higherEducationData['compair_school_' + number] = e.target.value;
    if (higherEducationData['compair_school_' + number].length >= 3 && compareCollegeList !== undefined && compareCollegeList.length > 0) {
      let searchData = _.filter(compareCollegeList, { 'school_type': inputData.type_of_higher_education === 1 ? 'career' : 'college' });
      searchData = _.filter(searchData, function (obj) {
        return obj.school_name.toLowerCase().indexOf(higherEducationData['compair_school_' + number].toLowerCase()) > -1;
      });
      let searchInputs = { ...searchResults };
      if (searchData.length > 0) {
        searchInputs[number] = searchData;
      } else {
        searchInputs[number] = [];
      }
      setSearchResults(searchInputs);
    }
    setHigherEducation(higherEducationData);
    updateData('compareInputData', higherEducationData);
  }

  const handleSearchSelect = (list, type) => {
    setLoading(true);
    setSearch(list.id);
    setSearchType('id');
    setSelectInput(type)
    let searchInputs = { ...searchResults };
    searchInputs[type] = [];
    setSearchResults(searchInputs);

    let higherEducationData = { ...higherEducation };
    higherEducationData['compair_school_' + type] = list.name;
    setHigherEducation(higherEducationData);
    updateData('compareInputData', higherEducationData);

  }

  return (

    <div className={classes.contentBlock}>

      <p>
        Before continuing, use Inkwiry&apos;s comparison tool to analyze your selection.
        Explore thousands of schools and see how they stack up by comparing them side-by-side.
      </p>

      <p className={classes.topGapNew}>
        <i>
          Selected School:
          {' '}
          {inputData.school_details !== undefined && inputData.school_details.school_name !== undefined && inputData.school_details.school_name}
        </i>
      </p>
      <div className={classes.inputSelectBoxNew}>
        <div>
          <TextField placeholder="Type the school name here" value={higherEducation['compair_school_1'] !== undefined ? higherEducation['compair_school_1'] : ''} onChange={(e) => { searchCompairSchool(e, '1') }} />
          {searchResults['1'] !== undefined && searchResults['1'].length > 0 && (
            <div className={classes.searchList}>
              <ul>
                {searchResults['1'] && searchResults['1'].map((list) => <li aria-hidden="true" onClick={() => handleSearchSelect(list, '1')}>{list.school_name}</li>)}
              </ul>
            </div>
          )}

        </div>
        <div>
          <TextField placeholder="Type the school name here" value={higherEducation['compair_school_2'] !== undefined ? higherEducation['compair_school_2'] : ''} onChange={(e) => { searchCompairSchool(e, '2') }} />
          {searchResults['2'] !== undefined && searchResults['2'].length > 0 && (
            <div className={classes.searchList}>
              <ul>
                {searchResults['2'] && searchResults['2'].map((list) => <li aria-hidden="true" onClick={() => handleSearchSelect(list, '2')}>{list.school_name}</li>)}
              </ul>
            </div>
          )}

        </div>
      </div>



      <div className={classes.heigherEducationSearch}>
        <div className={`${classes.responsiveTableNew} ${classes.compareSchoolSideBySide}`}>
          {((compareData['1'] !== undefined && compareData['1'].school_name !== undefined) || (compareData['2'] !== undefined && compareData['2'].school_name !== undefined)) && (
            <table cellPadding="0" cellSpacing="0">
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>Name of School</td>
                  <td>{compareData['1'] !== undefined && compareData['1'].school_name}</td>
                  <td>{typeOfHeData.school_name}</td>
                  <td>{compareData['2'] !== undefined && compareData['2'].school_name}</td>

                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Location</td>
                  <td>{compareData['1'].location !== undefined ? compareData['1'].location : ''}</td>
                  <td>{typeOfHeData.location}</td>
                  <td>{compareData['2'].location !== undefined ? compareData['2'].location : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The percentage of students that got accepted to the school calculated by dividing the number of accepted students by the number of total applicants." /></td>
                  <td>Admission Rate</td>
                  <td>{compareData['1'].admission_rate !== undefined ? compareData['1'].admission_rate : ''}</td>
                  <td>{typeOfHeData.admission_rate}</td>
                  <td>{compareData['2'].admission_rate !== undefined ? compareData['2'].admission_rate : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="Total number of undegraduate students" /></td>
                  <td>Enrollment</td>
                  <td>{compareData['1'].enrollment ? <NumberFormat value={compareData['1'].enrollment} decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator /> : ''}</td>
                  <td>{typeOfHeData.enrollment}</td>
                  <td>{compareData['2'].enrollment ? <NumberFormat value={compareData['2'].enrollment} decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator /> : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The percentage of students who graduated within 150% of the expected time to graduation. For example, for a 4-year degree, the graduation rate is calculated based on the number of students who graduated in six years. And, for a 2-year degree, the number of students who graduated in three years." /></td>
                  <td>Graduation Rate</td>
                  <td>{compareData['1'].graduation_rate ? compareData['1'].graduation_rate : ''}</td>
                  <td>{typeOfHeData.graduation_rate}</td>
                  <td>{compareData['2'].graduation_rate ? compareData['2'].graduation_rate : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The percentage of students who return after their first year." /></td>
                  <td>Freshman Retention Rate</td>
                  <td>{compareData['1'].freshman_retention_rate ? compareData['1'].freshman_retention_rate : ''}</td>
                  <td>{typeOfHeData.freshman_retention_rate}</td>
                  <td>{compareData['2'].freshman_retention_rate ? compareData['2'].freshman_retention_rate : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="50% of students attending this school scored in this range." /></td>
                  <td>SAT Average Math Scores</td>
                  <td>{compareData['1'].sat_averate_math_scores ? compareData['1'].sat_averate_math_scores : ''}</td>
                  <td>{typeOfHeData.sat_averate_math_scores}</td>
                  <td>{compareData['2'].sat_averate_math_scores ? compareData['2'].sat_averate_math_scores : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="50% of students attending this school scored in this range." /></td>
                  <td>SAT Average Reading Scores</td>
                  <td>{compareData['1'].sat_averate_reading_scores ? compareData['1'].sat_averate_reading_scores : ''}</td>
                  <td>{typeOfHeData.sat_averate_reading_scores}</td>
                  <td>{compareData['2'].sat_averate_reading_scores ? compareData['2'].sat_averate_reading_scores : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="50% of students attending this school scored in this range." /></td>
                  <td>ACT Average Scores</td>
                  <td>{compareData['1'].act_average_scores ? compareData['1'].act_average_scores : ''}</td>
                  <td>{typeOfHeData.act_average_scores}</td>
                  <td>{compareData['2'].act_average_scores ? compareData['2'].act_average_scores : ''}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>In-State Tuition & Fees</td>
                  <td>{compareData['1'].in_state_fee ? <NumberFormat value={compareData['1'].in_state_fee} decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                  <td>{typeOfHeData.in_state_fee}</td>
                  <td>{compareData['2'].in_state_fee ? <NumberFormat value={compareData['2'].in_state_fee} decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Out-of-State Tuition & Fees</td>
                  <td>{compareData['1'].out_state_fee ? <NumberFormat value={compareData['1'].out_state_fee} decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                  <td>{typeOfHeData.out_state_fee}</td>
                  <td>{compareData['2'].out_state_fee ? <NumberFormat value={compareData['2'].out_state_fee} decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Room & Board</td>
                  <td>{compareData['1'].room_board ? <NumberFormat value={compareData['1'].room_board} decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                  <td>{typeOfHeData.room_board}</td>
                  <td>{compareData['2'].room_board ? <NumberFormat value={compareData['2'].room_board} decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                </tr>
                <tr className={classes.compareDefaultColor}>
                  <td>
                    <img
                      src={infoIcon}
                      alt=""
                      data-for="compareSchool"
                      data-tip="Calculated as Median Earnings divided by Average Annual Cost. What does that mean? This ratio shows you how much income you can expect to make 10 years after you start school with respect to how much you are investing in school each year.
                This ratio is specific to Inkwiry and is a great comparative tool. For example, out of all the schools you're considering, which school has the best earnings to cost ratio?"
                    />
                  </td>
                  <td>Future Earnings / Average Cost</td>
                  <td>{compareData['1'].future_earnings ? compareData['1'].future_earnings : ''}</td>
                  <td>{typeOfHeData.future_earnings}</td>
                  <td>{compareData['2'].future_earnings ? compareData['2'].future_earnings : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The median earnings of students who received federal financial aid 10 years after entering the school. Important to note that this is not 10 years after graduation, but 10 years after the first year in school." /></td>
                  <td>Median Earnings (10 yrs after entry)</td>
                  <td>{compareData['1'].median_earnings ? compareData['1'].median_earnings : ''}</td>
                  <td>{typeOfHeData.median_earnings}</td>
                  <td>{compareData['2'].median_earnings ? compareData['2'].median_earnings : ''}</td>
                </tr>

                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The average net price for students who received federal financial aid. Net price is equal to the total cost of attendance (tuition and fees, room and board, books and supplies, and any other living expenses) for the entire year minus scholarships and grants." /></td>
                  <td>Average Annual Cost</td>
                  <td>{compareData['1'].avg_annual_cost ? compareData['1'].avg_annual_cost : ''}</td>
                  <td>{typeOfHeData.avg_annual_cost}</td>
                  <td>{compareData['2'].avg_annual_cost ? compareData['2'].avg_annual_cost : ''}</td>
                </tr>
                {
                <tr>
                  <td>&nbsp;</td>
                  <td>Average Annual Cost by Family Income</td>
                  <td>--</td>
                  <td>--</td>
                  <td>--</td>
                </tr>
                }
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The average net price for families who earn less than $30,000 per year. Net price is equal to the total cost of attendance (tuition and fees, room and board, books and supplies, and any other living expenses) for the entire year minus scholarships and grants." /></td>
                  <td className={classes.padding30pxRight}>$0 - $30,000</td>
                  <td>{compareData['1'].range1 ? compareData['1'].range1 : ''}</td>
                  <td>{typeOfHeData.range1}</td>
                  <td>{compareData['2'].range1 ? compareData['2'].range1 : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The average net price for families who earn between $30,000 and $48,000 per year. Net price is equal to the total cost of attendance (tuition and fees, room and board, books and supplies, and any other living expenses) for the entire year minus scholarships and grants." /></td>
                  <td className={classes.padding30pxRight}>$30,001 - $48,000</td>
                  <td>{compareData['1'].range2 ? compareData['1'].range2 : ''}</td>
                  <td>{typeOfHeData.range2}</td>
                  <td>{compareData['2'].range2 ? compareData['2'].range2 : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The average net price for families who earn between $48,000 and $75,000 per year. Net price is equal to the total cost of attendance (tuition and fees, room and board, books and supplies, and any other living expenses) for the entire year minus scholarships and grants." /></td>
                  <td className={classes.padding30pxRight}>$48,001 - $75,000</td>
                  <td>{compareData['1'].range3 ? compareData['1'].range3 : ''}</td>
                  <td>{typeOfHeData.range3}</td>
                  <td>{compareData['2'].range3 ? compareData['2'].range3 : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The average net price for families who earn between $75,000 and $110,000 per year. Net price is equal to the total cost of attendance (tuition and fees, room and board, books and supplies, and any other living expenses) for the entire year minus scholarships and grants." /></td>
                  <td className={classes.padding30pxRight}>$75,001 - $110,000</td>
                  <td>{compareData['1'].range4 ? compareData['1'].range4 : ''}</td>
                  <td>{typeOfHeData.range4}</td>
                  <td>{compareData['2'].range4 ? compareData['2'].range4 : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The average net price for families who earn more than $110,000 per year. Net price is equal to the total cost of attendance (tuition and fees, room and board, books and supplies, and any other living expenses) for the entire year minus scholarships and grants." /></td>
                  <td className={classes.padding30pxRight}>$110,001+</td>
                  <td>{compareData['1'].range5 ? compareData['1'].range5 : ''}</td>
                  <td>{typeOfHeData.range5}</td>
                  <td>{compareData['2'].range5 ? compareData['2'].range5 : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The percentage of first-time undergraduate students who received some amount of scholarships and grants in their first year." /></td>
                  <td>Students Receiving Gift Aid from School</td>
                  <td>{compareData['1'].first_gift_aid ? compareData['1'].first_gift_aid + '%' : ''}</td>
                  <td>{typeOfHeData.first_gift_aid}</td>
                  <td>{compareData['2'].first_gift_aid ? compareData['2'].first_gift_aid + '%' : ''}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td className={classes.padding30pxRight}>Average Amount of Gift Aid</td>
                  <td>{compareData['1'].avg_gift_aid_amount ? '$' + compareData['1'].avg_gift_aid_amount : '' }</td>
                  <td>{typeOfHeData.avg_gift_aid_amount}</td>
                  <td>{compareData['2'].avg_gift_aid_amount ? '$' + compareData['2'].avg_gift_aid_amount : '' }</td>
                </tr>

                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The percentage of first-time undergraduate students who received some amount of federal student loans in their first year." /></td>
                  <td>Students Receiving Federal Loans</td>
                  <td>{compareData['1'].first_federal_loans ? (compareData['1'].first_federal_loans + '%') : ''}</td>
                  <td>{typeOfHeData.first_federal_loans}</td>
                  <td>{compareData['2'].first_federal_loans ? (compareData['2'].first_federal_loans + '%') : ''}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td className={classes.padding30pxRight}>Average Federal Loans in First Year</td>
                  <td>{compareData['1'].avg_federal_loans_amount ? '$' + compareData['1'].avg_federal_loans_amount : ''}</td>
                  <td>{typeOfHeData.avg_federal_loans_amount}</td>
                  <td>{compareData['2'].avg_federal_loans_amount ? '$' + compareData['2'].avg_federal_loans_amount : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The median amount of federal student loans after a student graduates. Please note that this does not include any private loans or parent loans." /></td>
                  <td>Federal Student Loans after Graduation</td>
                  <td>{compareData['1'].after_federal_loans ? compareData['1'].after_federal_loans : ''}</td>
                  <td>{typeOfHeData.after_federal_loans}</td>
                  <td>{compareData['2'].after_federal_loans ? compareData['2'].after_federal_loans : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The expected monthly payment if the above federal student loans amount was repaid over 10 years with a 5.05% interest rate." /></td>
                  <td className={classes.padding30pxRight}>Expected Monthly Payment</td>
                  <td>{compareData['1'].expected_monthly_payment ? compareData['1'].expected_monthly_payment : ''}</td>
                  <td>{typeOfHeData.expected_monthly_payment}</td>
                  <td>{compareData['2'].expected_monthly_payment ? compareData['2'].expected_monthly_payment : ''}</td>
                </tr>
                <tr>
                  <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="This figure represents the percentage of federal student loan borrowers that began repaying their student loans in 2015 and have defaulted on those loans in 2015, 2016, or 2017. Default means a student loan borrower has not made a payment in 360 days or more." /></td>
                  <td className={classes.padding30pxRight}>Federal Student Loan Default Rate</td>
                  <td>{compareData['1'].federal_loans_default_rate ? compareData['1'].federal_loans_default_rate : ''}</td>
                  <td>{typeOfHeData.federal_loans_default_rate}</td>
                  <td>{compareData['2'].federal_loans_default_rate ? compareData['2'].federal_loans_default_rate : ''}</td>
                </tr>
              </tbody>
              <ReactTooltip id="compareSchool" place="top" effect="solid" className={classes.tooltipInfoTopNewText2} />
            </table>
          )}
        </div>

      </div>
    </div>
  );
};

CompareSchool.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  compareCollegeList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(CompareSchool);
